import { makeObservable,action, observable,computed, autorun } from "mobx"
import axios from "axios"
import { io } from 'socket.io-client'
import React from 'react'
import  { Redirect } from 'react-router-dom'
import MesureStore from "../Appointments/Mesures/MesureStore";

class BridgeStoreV2{
  @observable uid=null
  @observable socket=null

    constructor() {
        makeObservable(this)
    }
  @action
  updateAppointment(id){
    this.app_id = id
    if(this.socket != null){
      this.socket.emit("current_appointment",{machine: this.uid , id: id,s: "bv2js"})
    }else{
      setTimeout(() => this.updateAppointment(id), 250)
    }

  }

  init(id, socket){
    this.uid = id;
    this.socket = socket;
  }

  @action
  subscribeMesureStore(){
    this.updateAppointment(MesureStore.appointement_id)
    MesureStore.subscribe(this)
  }

}

let bridgeStorev2 = new BridgeStoreV2();
global.Bridgev2Store = bridgeStorev2;
export default bridgeStorev2;
