import {observer} from "mobx-react";
import React from "react";

@observer
class FundusDisplayVideoPanel extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    renderNoImage() {
        return (
            <span></span>
        )
    }

    renderImages() {
        const videos = this.props.videos;
        let chunks = [];
        for (let i=0; i<videos.length; i+=4){
            chunks.push(videos.slice(i, i+4))
        }
        return (
            <div className="card-panel black" style={{borderRadius: '30px'}}>
                {
                    chunks.map((chunk, i) => (
                        <div key={i} className="row card-panel black" style={{borderRadius: '30px'}}>
                            {chunk.map((video, index) => (
                                <div key={index} className={chunk.length == 1 ? "col s6 push-s3" : "col s" + (12 / chunk.length)}>
                                    <video  className="responsive-video" loop autoPlay controls muted>
                                      <source src={video} type="video/mp4" />
                                      Your browser does not support the video tag.
                                    </video>
                                </div>
                            ))
                            }
                        </div>
                    ))
                }

            </div>

        )
    }

    render() {
        if (this.props.videos.length == 0) {
            return (this.renderNoImage())
        } else  {
            return (this.renderImages())
        }
    }
}

export default FundusDisplayVideoPanel