import React from "react"


class ShowMesure extends React.Component {
  constructor(props) {
    super(props);
    this.click = this.click.bind(this)
    this.pachymetrie = this.pachymetrie.bind(this)
    this.state = {
      current_mesure_id: 0,
      mesure: []
    };
  }

  componentDidMount(){
    this.setState({
      current_mesure_id: 0,
      mesure: this.props.mesures[0]
    })
  }
  click(e){
    e.preventDefault()
    this.setState({
      current_mesure_id: e.target.id,
      mesure: this.props.mesures[e.target.id]
    })
  }
  mesure(key){
    try{
    let currentMesure = this.state.mesure
    if(this.state.mesure[key]==undefined || this.state.mesure[key]==""){
      for (var i = 0; i < this.props.mesures.length; i++) {
        var mesure = this.props.mesures[i][key]
        if(mesure != undefined && mesure != ""){
          return(
            <span className="blue-text">
              {mesure}
            </span>)
        }
      }
    }else{
      return(
        <span>
          {this.state.mesure[key]}
        </span>
        )
    }
  }
  catch{
    return(<span></span>)
  }
  }
  pachymetrie(){
    try{
      if(this.state.mesure["od_pachymetrie"] != undefined || this.state.mesure["og_pachymetrie"] != undefined){
        return(
        <tr>
          <td>pachymétrie</td>
          <td> {this.state.mesure.od_pachymetrie} </td>
          <td> {this.state.mesure.og_pachymetrie} </td>
        </tr>
        )
     }
    }
    catch{

    }
    return(<React.Fragment></React.Fragment>)
  }

  render () {

    let m = this.state.mesure
    return (
      <React.Fragment>
        <div className="row tabs-mesures-synthese">
          <div className="col s4">
            <div className="collection">
              {this.props.typedemesures.map((type,index)=>(
                  <a onClick={this.click} id={index} key={index} className={"collection-item "+(index==this.state.current_mesure_id ? "active" : "")}>{type.titre}</a>
                ))}
            </div>
          </div>
          <div className="col s8">
              <table className="striped bordered">
                  <tbody>
                    <tr>
                      <th>Titre</th>
                      <th>Droite</th>
                      <th>Gauche</th>
                    </tr>
                  </tbody>
                  <tbody>
                      <tr>
                        <td>Tension</td>
                        <td>{this.mesure("od_tension")}</td>
                        <td>{this.mesure("og_tension")}</td>
                      </tr>
                      <tr>
                        <td>Sphère</td>
                        <td>{this.mesure("od_sphere")}</td>
                        <td>{this.mesure("og_sphere")}</td>
                      </tr>
                      <tr>
                        <td>cylindre</td>
                        <td>{this.mesure("od_cylindre")}</td>
                        <td>{this.mesure("og_cylindre")}</td>
                      </tr>
                      <tr>
                        <td>axe</td>
                        <td>{this.mesure("od_axe")}</td>
                        <td>{this.mesure("og_axe")}</td>
                      </tr>
                      <tr>
                        <td>av loin</td>
                        <td>{this.mesure("od_av_loin")}</td>
                        <td>{this.mesure("og_av_loin")}</td>
                      </tr>
                      <tr>
                        <td>addition</td>
                        <td>{this.mesure("od_addition")}</td>
                        <td>{this.mesure("og_addition")}</td>
                      </tr>
                      <tr>
                        <td>parinaud</td>
                        <td>{this.mesure("od_parinaud")}</td>
                        <td>{this.mesure("og_parinaud")}</td>
                      </tr>

                      {this.pachymetrie()}

                  </tbody>
              </table>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ShowMesure
