export const SCREEN_SIZE = {
    "by_query": {
        "sm": "640px",
        "md": "768px",
        "lg": "1024px",
        "xl": "1280px",
        "2xl": "1536px"
    },
    "by_inner_window": [
        {"size": "sm", "pixels": 360},
        {"size": "md", "pixels": 768},
        {"size": "lg", "pixels": 1280},
    ]

}