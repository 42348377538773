import React, {Component} from "react";
import InputText from "../../../../Shared/UiKit/InputText";
import JustificationSelect from "../JustificationSelect";
import FontSelect from "../FontSelect";
import MarkdownEditor from "../../../../Shared/UiKit/MarkdownEditor";

export default class FooterBlockEdit extends Component {
    constructor(props) {
        super(props);
        this.changeText = this.changeText.bind(this)
        this.changeJustification = this.changeJustification.bind(this)
        this.changeWeight = this.changeWeight.bind(this)
        this.changeStyle = this.changeStyle.bind(this)
        this.changeSize = this.changeSize.bind(this)
        this.state = {
            data: {
                justify: "",
                weight: "",
                style: "",
                size: ""
            }
        }
    }

    componentDidMount() {
        this.setState({data: this.props.data})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props !== prevProps) {
            this.setState({data: this.props.data})
        }
    }

    changeText(value) {
        this.setState({
            data: Object.assign({}, this.state.data, {text: value})
        }, () => this.props.onChange(this.state.data))
    }

    changeJustification(justify) {
        this.setState({
            data: Object.assign({}, this.state.data, {justify: justify})
        }, () => this.props.onChange(this.state.data))
    }

    changeWeight(weight) {
        this.setState({
            data: Object.assign({}, this.state.data, {weight: weight})
        }, () => this.props.onChange(this.state.data))
    }

    changeStyle(style) {
        this.setState({
            data: Object.assign({}, this.state.data, {style: style})
        }, () => this.props.onChange(this.state.data))
    }

    changeSize(size) {
        this.setState({
            data: Object.assign({}, this.state.data, {size: size})
        }, () => this.props.onChange(this.state.data))
    }

    render() {
        if (this.state.data) {
            return (
                <div style={{width: "100%"}}>
                    <div className="valign-wrapper" style={{width: "100%", justifyContent: "center", gap: 10}}>
                        <FontSelect
                            size={this.state.data.size} weight={this.state.data.weight} style={this.state.data.style}
                            onWeightChange={(v) => this.changeWeight(v)} onSizeChange={(v) => this.changeSize(v)} onStyleChange={(v) => this.changeStyle(v)}
                        />
                        <JustificationSelect
                            justify={this.state.data.justify}
                            onChange={(v) => this.changeJustification(v)}
                        />
                    </div>
                    <h6>Texte</h6>
                    {this.state.data.text !== undefined ? <MarkdownEditor noImages defaultValue={this.state.data.text} onChange={(v) => this.changeText(v)}/> : null}
                </div>
            );
        } else return null;
    }
}