import CVStore from "./CVStore";
import React from "react";
import {Observer} from "mobx-react";
import StateButton from "../Shared/StateButton";
import Helper from "../Shared/Helper";

const ADRIRights = (props) => {

    return (
        <Observer>
            {() => <div>
                <div style={{width: "100%", display: "flex", justifyContent: "center", margin: 20}}>
                    <StateButton onClick={() => CVStore.adriRightsRequest(props.uid)}
                                 state={CVStore.adriRequestState} color="blue darken-2"
                                 icon="medical_information" text="Droits ADRI" side="left"
                                 centered/>
                </div>
                <div className="row center-align">
                    <div className="col s6" style={{padding: "2vh 2vw"}}>
                        <div style={{borderRadius: "10px"}} className="grey lighten-3">
                            <h6 style={{margin: 0, padding: "1vh"}}>Couverture obligatoire</h6>
                            <h2 style={{margin: 0, padding: "1vh"}}>{CVStore.adriRights.couv_obligatoire || "-"}</h2>
                        </div>
                    </div>
                    <div className="col s6" style={{padding: "2vh 2vw"}}>
                        <div style={{borderRadius: "10px"}} className="grey lighten-3">
                            <h6 style={{margin: 0, padding: "1vh"}}>Couverture complémentaire</h6>
                            <h2 style={{
                                margin: 0,
                                padding: "1vh"
                            }}>{CVStore.adriRights.couv_complementaire || "-"}</h2>
                        </div>
                    </div>
                    <div className="col s6" style={{padding: "2vh 2vw"}}>
                        <div style={{borderRadius: "10px"}} className="grey lighten-3">
                            <h6 style={{margin: 0, padding: "1vh"}}>Expiration</h6>
                            <h2 style={{
                                margin: 0,
                                padding: "1vh"
                            }}>{Helper.formatDate(CVStore.adriRights.couv_obligatoire_expiration) || "-"}</h2>
                        </div>
                    </div>
                    <div className="col s6" style={{padding: "2vh 2vw"}}>
                        <div style={{borderRadius: "10px"}} className="grey lighten-3">
                            <h6 style={{margin: 0, padding: "1vh"}}>Expiration</h6>
                            <h2 style={{
                                margin: 0,
                                padding: "1vh"
                            }}>{Helper.formatDate(CVStore.adriRights.couv_complementaire_expiration) || "-"}</h2>
                        </div>
                    </div>
                    <div className="col s12" style={{padding: "2vh 2vw"}}>
                        <div style={{borderRadius: "10px"}} className="grey lighten-3">
                            <h6 style={{margin: 0, padding: "1vh"}}>Code médecin traitant</h6>
                            <h2 style={{
                                margin: 0,
                                padding: "1vh"
                            }}>{CVStore.adriRights.mtCode || "-"}</h2>
                        </div>
                    </div>
                </div>
            </div>}
        </Observer>
    );

}

export default ADRIRights