import React from "react";
import axios from "axios";
import SummaryDetails from "./SummaryDetails";
import Loader from "../Shared/Loader";
import {RequestState} from "../Shared/StateHelper";
import ReactToPrint from "react-to-print";
import Helper from "../Shared/Helper";

const pageStyle = `
@media print {
   body {
       display: block;
       box-sizing: border-box;   
       margin: 20mm 15mm;
       height: auto;
       fontFamily: Arial, sans-serif;
   }
   @page {
      size: auto;   /* auto is the initial value */
      margin: 0;  /* this affects the margin in the printer settings */
   }
}`;

export default class PatientAlertSummary extends React.Component {
    constructor(props) {
        super(props);
        this.componentRef = React.createRef();
        this.state = {
            summary_data: null,
            request_state: RequestState.LOADING
        }
    }

    componentDidMount() {
        axios.get("/alert_summary", {params: {id: this.props.appointment_id}})
            .then((response) => {
                this.setState({summary_data: response.data["summary"], request_state: RequestState.SUCCESS}, () => {
                    setTimeout(() => window.status = "ready", 100)
                })
            })
            .catch((error) => {
                this.setState({request_state: RequestState.ERROR})
            })
    }

    renderConclusionText = () => {
        const formatList = (str) => str.replace(/\s*:\s*/g, ' :\n\t •  ').replace(/\s*\+\s*/g, '\n\t •  ')
        if (this.state.summary_data.conclusion_text) {
            return <div style={{marginBlock: 10, whiteSpace: "pre-wrap"}}>
                {formatList(this.state.summary_data.conclusion_text)}
            </div>
        }

        return <div>
            A la lecture des examens réalisés il apparait: une
            suspicion {this.state.summary_data["risks"]} <br/>
        </div>
    }

    renderBody = () => {
        const conclusion = this.state.summary_data?.conclusion_text
        if (this.props.message) return <>
            <p>{this.props.message}</p>
            {conclusion ?
                <div>
                    <h4 style={{marginBlock: 10}}>Conclusion : </h4>
                    <span style={{fontSize: "1.2em"}}>{conclusion}</span>
                </div>
                : null}
        </>

        return <div style={{margin: 20}}>Cher(e) Docteur, <br/>
            Dans le cadre d'un protocole de téléophtalmologie à la date
            du {Helper.formatDate(this.state.summary_data.date)},
            j'ai été amené à interpréter les données transmises
            par {this.state.summary_data["orthoptist_full_name"]}{this.props.brand === "WHITE" ? ", opticien" : ", orthoptiste"}. <br/>
            {this.renderConclusionText()}
            Ce patient est donc dirigé vers un ophtalmologiste qui pratiquera des examens
            complémentaires. <br/>
            {this.state.summary_data["override_lock"] ?
                "Cette prescription ne vous dispense pas de la" +
                "consultation d'un ophtalmologiste suite aux pathologies détectées" +
                "lors de l'analyse."
                : null
            }
            Bien cordialement, <br/>
        </div>
    }

    render() {
        if (this.state.requestState === RequestState.LOADING) return <div id="full-height" style={{padding: 100}}>
            <Loader wide/>
        </div>
        if (this.state.request_state === RequestState.ERROR) return <div className="center">Une erreur est
            survenue.
        </div>
        if (this.state.request_state === RequestState.SUCCESS) return (
            <div>
                <div className={this.props.print ? "" : "hide"}>
                    <ReactToPrint
                        trigger={() => {
                            return (
                                <a className="btn blue darken-2 z-depth-0"
                                   style={{position: "absolute", top: "5%", right: "5%"}}>
                                    Imprimer
                                </a>)
                        }}
                        content={() => this.componentRef.current}
                        documentTitle="Compte Rendu Alerte"
                        pageStyle={pageStyle}
                    >
                    </ReactToPrint>
                </div>
                <div ref={this.componentRef} style={{marginTop: "50px"}}>
                    <title>Récapitulatif du rendez-vous</title>
                    <div className="pdf-document" style={{width: "100%", display: "flex", flexDirection: "column"}}>
                        <div>
                            <div dangerouslySetInnerHTML={{__html: this.state.summary_data["header"]}}/>
                            <p/>
                            <p style={{textAlign: "center"}}>
                                {`Suite au rendez-vous de ${this.state.summary_data["patient"]["last_name"]} ${this.state.summary_data["patient"]["first_name"]}`}
                            </p>
                            {this.renderBody()}
                            <p/>
                            <div className="right-align">
                                {this.state.summary_data["signature"] ?
                                    <img src={this.state.summary_data["signature"]} height="150" alt=""/>
                                    : null
                                }
                            </div>
                            <SummaryDetails
                                images={this.state.summary_data["images"]}
                                measures_types={this.state.summary_data["measure_types"]}
                                measures={this.state.summary_data["measures"]}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}