import React from "react";

function PricesOverview({prices, fixed, variable}) {
    return <div style={{display: "flex", flexDirection: "column", padding: 20}}>
        <h4 className="center">Mes tarifs</h4>
        <h6>Les tarifs sont fixés par nos soins, aucune modification ne sera faite sans vous prévenir au préalable</h6>
        <h6 className="valign-wrapper">
            <i className="material-icons left blue-text text-darken-2">local_atm</i>
            Forfait : {fixed} €
        </h6>
        <h6 className="valign-wrapper"
            title={`Une part variable peut être appliquée pour diminuer ou augmenter la facture.
Cette somme est remise à 0 après chaque facture.
Toute modification de cette valeur ne se fera pas sans vous en informer au préalable.`}>
            <i className="material-icons left blue-text text-darken-2">price_change</i>
            Part variable : {variable} €
        </h6>
        <h4 className="center">Tarifs par acte</h4>
        <h6>Ce tableau recense vos gains pour chaque acte réalisé</h6>
        <table className="bordered striped highlight">
            <thead>
            <tr>
                <th>Acte</th>
                <th>Gains</th>
            </tr>
            </thead>
            <tbody>
            {prices.sort((a, b) => a.act_name > b.act_name).map((price, index) => <tr key={index}>
                <td>{price.act_name}</td>
                <td>{price.income.toFixed(2)} €</td>
            </tr>)}
            </tbody>
        </table>
    </div>
}

export default PricesOverview;