import React, {Component} from "react";
import EditButton from "../../../Shared/EditButton";
import axios from "axios";
import {toast} from "react-toastify";


export default class OphtalmologistOptions extends Component {
    constructor(props) {
        super(props);
        this.changePriorityPercent = this.changePriorityPercent.bind(this)
        this.getPriorityPercent = this.getPriorityPercent.bind(this)
        this.state = {
            priorityPercent: 0
        }
    }

    componentDidMount() {
        this.getPriorityPercent()
    }

    changePriorityPercent(v) {
        const percent = v ? parseFloat(v.replace(',', '.')).toFixed(2) : 0
        if (percent <= 100) {
            axios.patch(`/admin/parameters/ophtalmologists/${this.props.ophtalmologist_id}/priority`, {
                percent: parseFloat(percent)
            }).then((resp) => {
                this.setState({
                    priorityPercent: parseFloat(percent)
                })
            }).catch(err => {
                toast.error("Impossible de modifier cette valeur")
            })
        }
    }

    getPriorityPercent() {
        axios.get(`/admin/parameters/ophtalmologists/${this.props.ophtalmologist_id}/priority`).then((resp) => {
            this.setState({
                priorityPercent: resp.data.percent
            })
        }).catch(err => {
            toast.error("Impossible de charger le pourcentage de priorité")
        })
    }

    render() {
        return (
            <div className="card">
                <ul className="collection">
                    {/* BOUTON "MONTANT"*/}
                    <li className="collection-item valign-wrapper">
                        <span className="title">Pourcentage perçu par traitement prioritaire</span>
                        <div className="input-field right-align"
                             style={{marginLeft: "auto", marginTop: "0px", marginBottom: "0px", padding: "0px"}}>
                            <EditButton text={this.state.priorityPercent} suffix="%"
                                        onValidation={v => this.changePriorityPercent(v)}
                                        filter={(e) => {
                                            return e.target.value.match(/^\d*[.,]?\d{0,2}$/g)
                                        }}
                            />
                        </div>
                    </li>
                </ul>
            </div>

        )
    }
}