import {ArcElement, Chart as ChartJS, Legend, Tooltip} from "chart.js";
import React, {Component} from "react";
import {Doughnut} from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export default class StatDoughnut extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {
                datasets: [
                    {
                        backgroundColor: [
                            "#00e676",
                            "#69f0ae",
                            "#ffb74d",
                            "#f57c00",
                            "#dd2c00",
                        ],
                        borderWidth: 10,
                        borderRadius: 10,
                        cutout: "80%",
                        animation: {
                            animateRotate: false
                        }
                    },
                ],
            },
            options: {
                plugins: {
                    legend: {
                        display: false,
                        position: "bottom",
                        labels: {
                            usePointStyle: true,
                            font: {
                                weight: "bold"
                            }
                        }
                    }
                }
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.data.datasets[0].data !== this.props.data && prevState.data.labels !== this.props.labels) {
            this.setState({
                data: Object.assign({}, this.state.data, {
                    labels: this.props.labels,
                    datasets: [Object.assign({}, this.state.data.datasets[0], {
                        data: this.props.data
                    })]
                })
            })
        }
    }

    render() {
        return (
            <div>
                <h2 className="center-align grey-text text-darken-2"
                    style={{fontWeight: "bold", height: "50px"}}>{this.props.title}</h2>
                <Doughnut data={this.state.data} options={this.state.options}></Doughnut>
            </div>
        )
    }
}
