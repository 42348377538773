import {Colors} from "./ColorEnums";
import React from "react";


class ButtonRow extends React.Component {

    constructor(props) {
        super(props);
    }


    render() {
        return (
            <div style={{width: "fit-content", display: "flex", alignItems: "center", justifyContent:"center"}}>
                {this.props.colors.map((color, index) => {
                    return (
                        <div onClick={() => this.props.handleFilters(Colors[color])}
                             key={index}
                             style={{
                                 cursor: "pointer",
                                 display: "flex",
                                 justifyContent: "center",
                                 borderRadius: "40px",
                                 borderColor: "blue",
                                 border: "solid 1px",
                                 alignItems: "center",
                                 marginRight:"10px",
                                 backgroundColor: this.props.current_filters.includes(Colors[color]["gravity"]) ? "#AEEAFF" : "",
                                 padding: "10px"
                             }}>
                            <span style={{
                                height: "20px",
                                marginRight: "5px",
                                width: "20px",
                                borderRadius: "10px",
                                background: Colors[color]["code"]
                            }}
                            />
                            <div>{Colors[color]["name"]}</div>
                        </div>
                    )
                })}
                
            </div>
        )
    }
}

export default ButtonRow