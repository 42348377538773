import React from 'react';
import axios from "axios";
import RnmFormKeyGenerator from "./RnmFormKeyGenerator";

function SecurityConsole() {
  return (
      <div id="full-height" style={{display: "flex", flexDirection: "column", overflowY: "scroll",marginLeft: "15px"}}>
          <h3 className="valign-wrapper" style={{margin: 20}}><i className="material-icons left">key</i>
              Clé de sécurité formulaire RNM patient
          </h3>
          <div className="divider"/>
          <div style={{margin: 10}}>
              <RnmFormKeyGenerator/>
          </div>
          <div className="divider"/>
            <h3 className="valign-wrapper" style={{margin: 20}}><i className="material-icons left">gavel</i>
                Utilisateurs actuellement bannis </h3>
          <BannedUsers />




      </div>
  );
}

const BannedUsers = () => {
    const [bannedPatientsIps, setBannedPatientsIps] = React.useState([]);
    const [bannedPatientsBypassCodes, setBannedPatientsBypassCodes] = React.useState([]);

    const [bannedUsersIps, setBannedUsersIps] = React.useState([]);
    const [bannedUsersBypassCodes, setBannedUsersBypassCodes] = React.useState([]);
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('[name="csrf-token"]').content;
    const unban = (key,group) => {
        axios.delete('/admin/console/security/blacklisted_user', {params: {key: key,group: group}}).then(res => {
            loadBannedUsers()
        })
    }
    const loadBannedUsers = () => {
        axios.get('/admin/console/security/blacklisted_users')
            .then(res => {
                setBannedPatientsIps(res.data.blacklisted_patients_ips)
                setBannedPatientsBypassCodes(res.data.blacklisted_patients_bypass_codes)
                setBannedUsersIps(res.data.blacklisted_app_ips)
                setBannedUsersBypassCodes(res.data.blacklisted_app_bypass_codes)

            })
            .catch(err => {
                toast.error("Erreur lors du chargement des utilisateurs bannis")
            })
    }
    React.useEffect(() => {
        loadBannedUsers()
    }, [])
    return (
        <div>
           <table className="striped bordered">
                <thead>
                    <tr>
                        <th>IP</th>
                        <th>Code de bypass</th>
                        <th>Unban</th>
                    </tr>
                </thead>
                <tbody>
                {bannedPatientsIps.map((key, index) => {
                        return (
                            <tr key={index}>
                                <td>{key}</td>
                                <td>{bannedPatientsBypassCodes[index]}</td>
                                <td><a className="btn-flat" onClick={() => unban(key,"patient_blacklist")}><i className="material-icons">delete</i></a></td>
                            </tr>
                        )
                    })}
               <tr >
                     <th colSpan={3} style={{textAlign: "center"}}><b>Utilisateurs bannis</b></th>
               </tr>
                {bannedUsersIps.map((key, index) => {
                        return (
                            <tr key={index}>
                                <td>{key}</td>
                                <td>{bannedUsersBypassCodes[index]}</td>
                                <td><a className="btn-flat" onClick={() => unban(key,"app_blacklist")}><i className="material-icons">delete</i></a></td>
                            </tr>
                        )
                    })
                }
                </tbody>
           </table>
        </div>
    )
}

export default SecurityConsole;