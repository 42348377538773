import React, {useEffect, useState} from 'react';
import {UserStatus} from "../../../Shared/Enums/Enums";
import EditButton from "../../../Shared/EditButton";

function StatusButton(props) {
    const [status, setStatus] = useState(props.status)

    useEffect(() => {
        props.onChange(status)
    }, [status]);

    const statusAttributes = (status) => {
        switch (status) {
            case UserStatus.ACTIVE.value:
                return {iconColor: "green-text text-darken-2", icon: "fiber_manual_record"}
            case UserStatus.ARCHIVED.value:
                return {iconColor: "grey-text text-darken-2", icon: "archive"}
            case UserStatus.TEST.value:
                return {iconColor: "grey-text text-darken-2", icon: "manage_accounts"}
            default:
                return {iconColor: "red-text text-darken-2", icon: "lock"}
        }
    }

    if (!props.status) return null

    return <div>
        <EditButton button={<a className="btn-small btn-flat transparent">
            <i className={`material-icons ${statusAttributes(status).iconColor}`}>{statusAttributes(status).icon}</i>
        </a>}
                    text={UserStatus[status].name}
                    type="select" options={Object.values(UserStatus).map(status => status.name)}
                    onValidation={status => setStatus(Object.values(UserStatus).find(s => s.name === status).value)}
        />
    </div>
}

export default StatusButton;