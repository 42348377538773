import React, {useEffect, useState} from "react";
import axios from "axios";
import {Link,useParams} from "react-router-dom";
import moment from "moment";
import {Bar} from "react-chartjs-2";
export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Montants',
    },
  },
};
/*
this component will display all the statistics of an ophtalmologist, at the moment only elpased time on controle validate
 the data is ready to  add charts
 */
export default function OphtalmologistsStatistics() {
    axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('[name=csrf-token]').content;
    const [dataByOphtalmologist, setDataByOphtalmologist] = useState({});
    const min = 0;
    const max = 60;
    const  bins = 20;
    const binSize = (max - min) / bins;

    const labels = Array(bins).fill(0).map((_, index) => { return (index * binSize + min) + '-' + (index * binSize + min + binSize) + ' sec' })


    useEffect(() => {
        loadDatas()
    }, [])
    const loadDatas = () => {

        axios.get("/admin/console/ophtalmologist/statistics").then((response) => {
           var dataByOphtalmologist = {}
              response.data.forEach((data) => {
                  let ophtalmo= data.ophtalmologiste.id
                  if (dataByOphtalmologist[ophtalmo]===undefined) {
                      dataByOphtalmologist[ophtalmo] = {data: Array(bins).fill(0),nom: data.ophtalmologiste.nom, prenom: data.ophtalmologiste.prenom}
                  }
                  data.duration_ophtalmology_control /= 1000;
                  let index = Math.floor((data.duration_ophtalmology_control - min) / binSize)
                  if(index>=bins){
                        index=bins-1
                  }
                  dataByOphtalmologist[ophtalmo].data[index] += 1

              })

            setDataByOphtalmologist(dataByOphtalmologist)
        });
    }

    return(
        <div>
            <h3 className="valign-wrapper center" style={{margin: 20}}><i className="material-icons left">analytics</i>Statistiques des ophtalmologistes sur les 6 derniers mois</h3>
            <div className="">

                {Object.keys(dataByOphtalmologist).map((ophtalmo) => {
                    let data = dataByOphtalmologist[ophtalmo]
                    return(
                        <div key={ophtalmo} className={"row"}>
                            <h3>{data.nom} {data.prenom}</h3>
                            <div>
                            <Bar data={{
                                  labels: labels,
                                        datasets: [
                                            {
                                                label: 'Histogramme de répartition du temps de contrôle ophtalmologique',
                                                data: data.data,
                                                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                                            }
                                        ]
                            }}
                                 options={options} />
                            </div>
                        </div>
                    )
                })}
            </div>

        </div>
    )

}