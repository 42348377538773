import React from 'react';

function MonoStatDisplay(props) {

        return (
            <div className={`"card-panel white-text lighten-2 ${props.color}`}
                 id={props.link ? "clickable" : ""} onClick={() => {
                if (props.link) window.location.href = props.link
            }}
                 style={{
                     padding: "0 1vw",
                     height: "6vw",
                     minHeight: "70px",
                     borderRadius: "5px",
                     margin: "auto",
                     display: "flex",
                     alignItems: "center",
                     maxWidth: "550px"
                 }}>
                <div className="col s6 xl2 l2 valign-wrapper" style={{justifyContent: "center"}}>
                    <i className="material-icons" style={{fontSize: "4em"}}>{props.logo}</i>
                </div>
                <div className="center-align hide-on-med-and-down col xl7 l7" style={{margin: "0 auto"}}>
                    <h2 className="flow-text truncate">{props.label}</h2>
                </div>
                <div className="col s6 xl3 l3 center-align">
                    <h1 style={{fontSize: props.value.toString().length < 4 ? "4em" : "3em", margin: "0"}}>{props.value}</h1>
                </div>
            </div>
        )
}

MonoStatDisplay.defaultProps = {
    link: null,
    label: "",
    value: 0,
    logo: "edit_note"
}

export default MonoStatDisplay;