import React, {Component} from "react";

export default class ModalButton extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const elems = document.getElementById(this.props.id);
        M.Modal.init(elems, {});
    }

    componentWillUnmount() {
        const elems = document.getElementById(this.props.id);
        const instances = M.Modal.getInstance(elems);
        instances.destroy();
    }

    render() {
        return (
            <div>
                <a className={`btn blue darken-2 z-depth-0 modal-trigger ${this.props.color}`} data-target={this.props.id}
                   onClick={this.props.onClick}
                >
                    {this.props.text}
                </a>
                <div id={this.props.id} className="modal"
                     style={{height: `${this.props.height}`, width: `${this.props.width}`}}>
                    <div className="modal-content">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

ModalButton.defaultProps = {
    name: "",
    text: "",
    height: "",
    width: "",
    color: "",
    onClick: () => null
}