export const RequestState = {
    IDLE: "IDLE", // WAITING FOR REQUEST CALL
    LOADING: "LOADING",
    RELOADING: "RELOADING",
    ERROR: "ERROR",
    SUCCESS: "SUCCESS"
}

export const loading = (state) => {
    if ([RequestState.IDLE, RequestState.ERROR].includes(state)) return RequestState.LOADING
    else return RequestState.RELOADING
}

export const isLoading = (state) => {
    return [RequestState.LOADING, RequestState.RELOADING].includes(state)
}

export const hasFailed = (status) => {
    return [RequestState.ERROR, RequestState.RELOADING].includes(status)
}