import ReCAPTCHA from "react-google-recaptcha";
import React from "react";
function Recaptcha({invisible, handlerFunction, recaptcha_ref, site_key}) {
    if(!site_key) return (<div>Recaptcha site key not found</div>)
    if (invisible) return <ReCAPTCHA size="invisible" ref={recaptcha_ref} sitekey={site_key}/>
    else return <ReCAPTCHA sitekey={site_key} onChange={handlerFunction}/>

}

export default Recaptcha

Recaptcha.defaultProps = {
    handlerFunction: () => {}
}