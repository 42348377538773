import React, {Component} from "react";
import TemplateEdit from "../TemplateEdit";

export default class GroupBlockEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                blocks: []
            }
        }
    }

    componentDidMount() {
        this.setState({data: this.props.data})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props !== prevProps) {
            this.setState({data: this.props.data})
        }
    }

    onChange(template) {
        this.setState({data: {blocks: template.blocks}}, () => this.props.onChange(this.state.data))
    }

    render() {
        return (
            <div style={{width: "100%"}}>
                <TemplateEdit
                    template={{blocks: this.state.data.blocks, id: this.props.id}}
                    onChange={(template) => this.onChange(template)}
                    inGroup={true}
                />
            </div>
        );
    }
}