import React from "react"
import {observer} from "mobx-react"
import MesureStore from '../MesureStore'
import MultiChooseBox from "../../../Shared/MultiChooseBox";
import MesureTextBox from "./MesureTextBox";
import AvTrouStenopeique from "./AvTrouStenopeique"
import BrandedElement from "../../../Shared/BrandedElement";
import {isLocked, LockingElement} from "../../AppointmentValidation";
import {Mesures, Side, TypesMesures} from "../../../Shared/Enums/Enums";

const mesure_types = require("./MesureTypes.json")

@observer
class Formulaire extends React.Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this)
        this.set_od_tension = this.set_od_tension.bind(this)
        this.set_od_sphere = this.set_od_sphere.bind(this)
        this.set_od_cylindre = this.set_od_cylindre.bind(this)
        this.set_od_av_loin = this.set_od_av_loin.bind(this)
        this.set_od_axe = this.set_od_axe.bind(this)
        this.set_og_axe = this.set_og_axe.bind(this)
        this.set_od_addition = this.set_od_addition.bind(this)
        this.set_od_parinaud = this.set_od_parinaud.bind(this)
        this.set_od_pachymetrie = this.set_od_pachymetrie.bind(this)
        this.set_og_tension = this.set_og_tension.bind(this)
        this.set_og_sphere = this.set_og_sphere.bind(this)
        this.set_og_cylindre = this.set_og_cylindre.bind(this)
        this.set_og_av_loin = this.set_og_av_loin.bind(this)
        this.set_og_addition = this.set_og_addition.bind(this)
        this.set_og_parinaud = this.set_og_parinaud.bind(this)
        this.set_og_pachymetrie = this.set_og_pachymetrie.bind(this)
        this.formatMesures = this.formatMesures.bind(this)
        this.goToNextKeyboardEdit = this.goToNextKeyboardEdit.bind(this)

        this.state = {};
    }

    componentDidMount() {
        var elems = document.querySelectorAll('.collapsible');
        var instances = M.Collapsible.init(elems, {});
    }

    componentDidUpdate(prevProps) {
        if (prevProps.mesureName !== this.props.mesureName) {
            var elems = document.querySelectorAll('.collapsible');
            var instances = M.Collapsible.init(elems, {});
        }
    }

    submit(e) {
        e.preventDefault()
    }

    set_od_sphere(v) {
        const value = v === "-" ? "" : v
        MesureStore.set_od_sphere(value)
    }

    set_og_sphere(v) {
        const value = v === "-" ? "" : v
        MesureStore.set_og_sphere(value)
    }

    set_od_cylindre(v) {
        let value = parseFloat(v) * -1
        MesureStore.set_od_cylindre(isNaN(value) ? "" : value.toString())
    }

    set_og_cylindre(v) {
        let value = parseFloat(v) * -1
        MesureStore.set_og_cylindre(isNaN(value) ? "" : value.toString())
    }

    set_od_axe(v) {
        MesureStore.set_od_axe(v)
    }


    set_og_axe(v) {
        MesureStore.set_og_axe(v)
    }

    set_od_addition(v) {
        MesureStore.set_od_addition(v)
    }

    set_og_addition(v) {
        MesureStore.set_og_addition(v)
    }

    set_od_av_loin(v) {
        const value = v.match(/^\d+\.?\d*$/) ? parseFloat(v) / 10 : v
        MesureStore.set_od_av_loin(value.toString())
    }

    set_og_av_loin(v) {
        const value = v.match(/^\d+\.?\d*$/) ? parseFloat(v) / 10 : v
        MesureStore.set_og_av_loin(value.toString())
    }

    set_od_parinaud(v) {
        const str = v.replace("P", "")
        const value = parseInt(str) ? str : ""
        MesureStore.set_od_parinaud(value)
    }

    set_og_parinaud(v) {
        const str = v.replace("P", "")
        const value = parseInt(str) ? str : ""
        MesureStore.set_og_parinaud(value)
    }

    set_od_tension(e) {
        MesureStore.set_od_tension(e.target.value)
    }

    set_og_tension(e) {
        MesureStore.set_og_tension(e.target.value)
    }

    set_od_pachymetrie(e) {
        MesureStore.set_od_pachymetrie(e.target.value)
    }

    set_og_pachymetrie(e) {
        MesureStore.set_og_pachymetrie(e.target.value)
    }

    formatMesures(side) {
        return ""
        let sphere_sign = ""
        let sphere = ""
        let cylindre = ""
        let axe = ""
        let addition = ""
        let av_loin = ""
        let av_pres = ""
        switch (side) {
            case Side.LEFT:
                sphere_sign = MesureStore.og_sphere > 0 ? "+" : ""
                sphere = MesureStore.og_sphere === "" ? "_" : `${MesureStore.og_sphere}`
                cylindre = MesureStore.og_cylindre === "" ? "_" : `${MesureStore.og_cylindre}`
                axe = MesureStore.og_axe === "" ? "_°" : `${MesureStore.og_axe}°`
                addition = MesureStore.og_addition === "" ? "" : `add ${MesureStore.og_addition}`
                av_loin = MesureStore.og_av_loin === "" ? "" : `, ${MesureStore.og_av_loin.match(/^\d+[\.,]?\d*$/) ?
                    (parseFloat(MesureStore.og_av_loin) * 10).toString() + "/10" : MesureStore.og_av_loin}`
                av_pres = MesureStore.og_parinaud === "" ? "" : `, P${MesureStore.og_parinaud}`
                break
            case Side.RIGHT:
                sphere_sign = MesureStore.od_sphere > 0 ? "+" : ""
                sphere = MesureStore.od_sphere === "" ? "_" : `${MesureStore.od_sphere}`
                cylindre = MesureStore.od_cylindre === "" ? "_" : `${MesureStore.od_cylindre}`
                axe = MesureStore.od_axe === "" ? "_°" : `${MesureStore.od_axe}°`
                addition = MesureStore.od_addition === "" ? "" : `add ${MesureStore.od_addition}`
                av_loin = MesureStore.od_av_loin === "" ? "" : `, ${MesureStore.od_av_loin.match(/^\d+[\.,]?\d*$/) ?
                    (parseFloat(MesureStore.od_av_loin) * 10).toString() + "/10" : MesureStore.od_av_loin}`
                av_pres = MesureStore.od_parinaud === "" ? "" : `, P${MesureStore.od_parinaud}`
                break
        }
        av_loin = Number.isFinite(MesureStore.od_av_loin) ? (MesureStore.od_av_loin * 10).toString() + "/10" : av_loin
        return `${sphere_sign}${sphere} (${cylindre}) ${axe} ${addition}${av_loin}${av_pres}`

    }

    lastValue(value) {
        let data = MesureStore.lastData
        if (data === null) {

        } else {
            if (data[value] === null) {
                return ""
            } else {
                return data[value]
            }
        }
    }

    goToNextKeyboardEdit() {
        const collapsible = document.querySelectorAll('.collapsible')[0]
        M.Collapsible.getInstance(collapsible).close(0)
        M.Collapsible.getInstance(collapsible).open(1)
    }

    getMesureDisplayList = () => {
        if (!MesureStore.typedemesure) return []
        const mesure = MesureStore.typesdemesures.find(m => m.id === MesureStore.typedemesure)
        const {optional, mandatory} = mesure_types.find(type => TypesMesures[type.name] === mesure.titre)
        return [...mandatory, ...optional]
    }

    renderTension = (mesureDisplayList, force = false) => {
        const last_od_tension = this.lastValue("od_tension") < 0 ? "NM" : this.lastValue("od_tension")
        const last_og_tension = this.lastValue("og_tension") < 0 ? "NM" : this.lastValue("og_tension")
        if (!force && !mesureDisplayList.includes(Mesures.TENSION.value)) return null
        return <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
            gap: 30
        }}>
            <h5>D</h5>
            <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                <select value={MesureStore.od_tension} onChange={this.set_od_tension}
                        className="browser-default center"
                        data-cy="TensionDroite">
                    <option value="" disabled>Tension Droite</option>
                    <option value={-1}>NM</option>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57].map((data, index) => (
                        <option key={index} value={data}>{data}</option>
                    ))}
                </select>
                <label
                    className={"center " + (isLocked(LockingElement.RIGHT_TENSION) ? "red-text" : "")}>
                    Tension droite ({last_od_tension})
                </label>
            </div>
            <h5>Tension</h5>
            <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                <select value={MesureStore.og_tension} onChange={this.set_og_tension}
                        className="browser-default center"
                        data-cy="TensionGauche">
                    <option value="" disabled>Tension Gauche</option>
                    <option value={-1}>NM</option>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57].map((data, index) => (
                        <option key={index} value={data}>{data}</option>
                    ))}
                </select>
                <label
                    className={"center " + (isLocked(LockingElement.LEFT_TENSION) ? "red-text" : "")}>
                    Tension gauche ({last_og_tension})
                </label>
            </div>
            <h5>G</h5>
        </div>
    }

    renderPachymetry = (mesureDisplayList, force = false) => {
        if (!force && !mesureDisplayList.includes(Mesures.PACHYMETRY.value)) return null
        const od_last = this.lastValue("od_pachymetrie")
        const og_last = this.lastValue("og_pachymetrie")
        return <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
            gap: 30
        }}>
            <h5>D</h5>
            <div className="input-field outlined" style={{display: "flex", flexDirection: "column", gap: 10}}>

                <input type="number" className="center" value={MesureStore.od_pachymetrie}
                       onChange={this.set_od_pachymetrie}
                       data-cy="PachymetrieDroite"/>
                <label className="center">{od_last ? `Dernière valeur : ${od_last}` : ""} </label>
            </div>
            <h5>Pachymétrie</h5>
            <div className="input-field outlined" style={{display: "flex", flexDirection: "column", gap: 10}}>
                <input className="center" type="number" value={MesureStore.og_pachymetrie}
                       onChange={this.set_og_pachymetrie}
                       data-cy="PachymetrieGauche"/>
                <label className="center">{og_last ? `Dernière valeur : ${og_last}` : ""} </label>

            </div>
            <h5>G</h5>
        </div>
    }

    renderTextBoxes = (mesureDisplayList) => {
        const needs = [
            Mesures.SPHERE.value,
            Mesures.CYLINDER.value,
            Mesures.AXIS.value,
            Mesures.ADDITION.value,
            Mesures.ACUITY.value,
            Mesures.PARINAUD.value
        ]
        if (!mesureDisplayList.some(r => needs.includes(r))) return null
        return <>
            <li className="collection-item grey lighten-5" data-cy="RightEyeMeasureBox">
                <h5 style={{display: "flex", justifyContent: "center", marginBottom: 0}}>
                    <div><b>Œil droit</b> {`${this.formatMesures(Side.RIGHT)}`}</div>
                </h5>
                <MesureTextBox side={Side.RIGHT}
                               autofocus={true}
                               sphere={MesureStore.od_sphere} changeSphere={this.set_od_sphere}
                               cylindre={MesureStore.od_cylindre} changeCylindre={this.set_od_cylindre}
                               axe={MesureStore.od_axe} changeAxe={this.set_od_axe}
                               addition={MesureStore.od_addition} changeAddition={this.set_od_addition}
                               avLoin={MesureStore.od_av_loin} changeAvLoin={this.set_od_av_loin}
                               parinaud={MesureStore.od_parinaud} changeParinaud={this.set_od_parinaud}
                               lastValue={this.lastValue}
                               nextBoxSide={Side.LEFT.toString()}
                               av_required={isLocked(LockingElement.RIGHT_ACUITY)}
                               mesureDisplayList={mesureDisplayList}
                />
            </li>
            <li className="collection-item grey lighten-5" data-cy="LeftEyeMeasureBox">
                <h5 className="valign-wrapper"
                    style={{display: "flex", justifyContent: "center", marginBottom: 0}}>
                    <div><b>Œil gauche</b> {`${this.formatMesures(Side.LEFT)}`}</div>
                </h5>
                <MesureTextBox side={Side.LEFT}
                               autofocus={false}
                               sphere={MesureStore.og_sphere} changeSphere={this.set_og_sphere}
                               cylindre={MesureStore.og_cylindre} changeCylindre={this.set_og_cylindre}
                               axe={MesureStore.og_axe} changeAxe={this.set_og_axe}
                               addition={MesureStore.og_addition} changeAddition={this.set_og_addition}
                               avLoin={MesureStore.og_av_loin} changeAvLoin={this.set_og_av_loin}
                               parinaud={MesureStore.og_parinaud} changeParinaud={this.set_og_parinaud}
                               lastValue={this.lastValue}
                               nextBoxSide={Side.RIGHT.toString()}
                               av_required={isLocked(LockingElement.LEFT_ACUITY)}
                               mesureDisplayList={mesureDisplayList}
                />
            </li>
        </>
    }

    renderMultiChooseBoxes = (mesureDisplayList) => {
        const needs = [
            Mesures.SPHERE.value,
            Mesures.CYLINDER.value,
            Mesures.AXIS.value,
            Mesures.ADDITION.value,
            Mesures.ACUITY.value,
            Mesures.PARINAUD.value
        ]
        if (!mesureDisplayList.some(r => needs.includes(r))) return null
        return <>
            <h6 style={{display: "flex", justifyContent: "center", fontStyle: "italic"}}>
                Si vous rencontrez des soucis avec la saisie manuelle, vous pouvez utiliser la saisie
                contextuelle
                ci-dessous à la place.
            </h6>
            <ul className="collapsible popout">
                {/*################### OEIL DROIT ###################*/}
                <li>
                    <div className="collapsible-header valign-wrapper"
                         style={{padding: "0 1vw"}}
                         data-cy="RightEyeDisplayMeasure">
                        <i className="material-icons">remove_red_eye</i>
                        Œil droit &nbsp;
                        <br/>
                        <p style={{fontWeight: "bold"}}>{`${this.formatMesures(Side.RIGHT)}`}</p>
                    </div>
                    <div className="collapsible-body center-align" style={{paddingTop: "0px"}}>
                        <div className="row" style={{paddingTop: "2vh"}}>
                            {/*Sphère*/}
                            <MultiChooseBox onChoose={v => this.set_od_sphere(v)}
                                            type={Mesures.SPHERE}
                                            subtitle={this.lastValue("od_sphere") ? `Dernière mesure : ${this.lastValue("od_sphere")}` : ""}
                                            offset={25}
                                            values={[
                                                ["-"],
                                                ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25"],
                                                [".25", ".5", ".75"]
                                            ]}
                                            selectedValues={[
                                                MesureStore.od_sphere < 0 ? "-" : "",
                                                MesureStore.od_sphere.toString().split(".")[0].replace("-", ""),
                                                MesureStore.od_sphere.toString().split(".")[1] ? "." + MesureStore.od_sphere.toString().split(".")[1] : ""
                                            ]}
                                            mesureDisplayList={mesureDisplayList}
                                            hasValue={!["", null].includes(MesureStore.od_sphere.toString())}
                            />
                            {/*Cylindre*/}
                            <MultiChooseBox onChoose={v => this.set_od_cylindre(v)}
                                            type={Mesures.CYLINDER}
                                            subtitle={this.lastValue("od_cylindre") ? `Dernière mesure : ${this.lastValue("od_cylindre")}` : ""}
                                            offset={25}
                                            values={[
                                                ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
                                                [".25", ".5", ".75"]
                                            ]}
                                            selectedValues={[
                                                MesureStore.od_cylindre.toString().split(".")[0].replace("-", ""),
                                                MesureStore.od_cylindre.toString().split(".")[1] ? "." + MesureStore.od_cylindre.toString().split(".")[1] : ""
                                            ]}
                                            mesureDisplayList={mesureDisplayList}
                                            hasValue={!["", null].includes(MesureStore.od_cylindre)}
                            />
                            {/*Axe*/}
                            <MultiChooseBox onChoose={v => this.set_od_axe(v)}
                                            type={Mesures.AXIS}
                                            subtitle={this.lastValue("od_axe") ? `Dernière mesure : ${this.lastValue("od_axe")}` : ""}
                                            offset={25}
                                            pickOnlyOne={true}
                                            values={[
                                                ["0", "5", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55", "60", "65", "70", "75", "80", "85", "90", "95"],
                                                ["100", "105", "110", "115", "120", "125", "130", "135", "140", "145", "150", "155", "160", "165", "170", "175", "180"]
                                            ]}
                                            selectedValue={MesureStore.od_axe.toString()}
                                            mesureDisplayList={mesureDisplayList}
                                            hasValue={!["", null].includes(MesureStore.od_axe.toString())}
                            />
                            {/*Addition*/}
                            <MultiChooseBox onChoose={v => this.set_od_addition(v)}
                                            type={Mesures.ADDITION}
                                            subtitle={this.lastValue("od_addition") ? `Dernière mesure : ${this.lastValue("od_addition")}` : ""}
                                            offset={25}
                                            values={[
                                                ["0", "0.25", "0.5", "0.75", "1", "1.25", "1.5", "1.75", "2", "2.25", "2.5", "2.75", "3"],
                                            ]}
                                            selectedValues={[
                                                MesureStore.od_addition.toString()
                                            ]}
                                            mesureDisplayList={mesureDisplayList}
                                            hasValue={!["", null].includes(MesureStore.od_addition.toString())}
                            />
                            {/*AV Loin*/}
                            <MultiChooseBox onChoose={v => this.set_od_av_loin(v)}
                                            type={Mesures.ACUITY}
                                            subtitle={this.lastValue("od_av_loin") ? `Dernière mesure : ${this.lastValue("od_av_loin")}` : ""}
                                            offset={25}
                                            suffix=" /10"
                                            values={[
                                                ["16*", "12*", "10*", "9*", "8*", "7*", "6*", "5*", "4*", "3*", "2*", "1*", "0.5*",
                                                    "CDLM4M", "CDLM3M", "CDLM2M", "CDLM1M", "VBLM2M", "VBLM1M", "PL+", "PL-"],
                                            ]}
                                            selectedValues={[
                                                MesureStore.od_av_loin.toString().match(/^\d+\.?\d*$/) ? (MesureStore.od_av_loin * 10).toString() : MesureStore.od_av_loin.toString()
                                            ]}
                                            mesureDisplayList={mesureDisplayList}
                                            hasValue={!["", null].includes(MesureStore.od_av_loin.toString())}
                            />
                            {/*Parinaud*/}
                            <MultiChooseBox onChoose={v => this.set_od_parinaud(v)}
                                            type={Mesures.PARINAUD}
                                            subtitle={this.lastValue("od_parinaud") ? `Dernière mesure : ${this.lastValue("od_parinaud")}` : ""}
                                            offset={25}
                                            values={[
                                                ["P1.5", "P2", "P3", "P4", "P6", "P8", "P10", "P12", "P14"],
                                            ]}
                                            selectedValues={[
                                                "P" + MesureStore.od_parinaud.toString()
                                            ]}
                                            mesureDisplayList={mesureDisplayList}
                                            hasValue={!["", null].includes(MesureStore.od_parinaud.toString())}
                            />
                        </div>
                    </div>
                </li>
                {/*################### OEIL GAUCHE ###################*/}
                <li>
                    <div className="collapsible-header valign-wrapper"
                         style={{padding: "0 1vw"}}
                         data-cy="LeftEyeDisplayMeasure">
                        <i className="material-icons">remove_red_eye</i>
                        Œil gauche &nbsp;
                        <br/>
                        <p style={{fontWeight: "bold"}}>{`${this.formatMesures(Side.LEFT)}`}</p>
                    </div>
                    <div className="collapsible-body center-align">
                        <div className="row">
                            {/*Sphère*/}
                            <MultiChooseBox onChoose={v => this.set_og_sphere(v)}
                                            type={Mesures.SPHERE}
                                            subtitle={this.lastValue("og_sphere") ? `Dernière mesure : ${this.lastValue("og_sphere")}` : ""}
                                            offset={25}
                                            values={[
                                                ["-"],
                                                ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25"],
                                                [".25", ".5", ".75"]
                                            ]}
                                            selectedValues={[
                                                MesureStore.og_sphere < 0 ? "-" : "",
                                                MesureStore.og_sphere.toString().split(".")[0].replace("-", ""),
                                                MesureStore.og_sphere.toString().split(".")[1] ? "." + MesureStore.og_sphere.toString().split(".")[1] : ""
                                            ]}
                                            mesureDisplayList={mesureDisplayList}
                                            hasValue={!["", null].includes(MesureStore.og_sphere.toString())}
                            />
                            {/*Cylindre*/}
                            <MultiChooseBox onChoose={v => this.set_og_cylindre(v)}
                                            type={Mesures.CYLINDER}
                                            subtitle={this.lastValue("og_cylindre") ? `Dernière mesure : ${this.lastValue("og_cylindre")}` : ""}
                                            offset={25}
                                            values={[
                                                ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
                                                [".25", ".5", ".75"]
                                            ]}
                                            selectedValues={[
                                                MesureStore.og_cylindre.toString().split(".")[0].replace("-", ""),
                                                MesureStore.og_cylindre.toString().split(".")[1] ? "." + MesureStore.og_cylindre.toString().split(".")[1] : ""
                                            ]}
                                            mesureDisplayList={mesureDisplayList}
                                            hasValue={!["", null].includes(MesureStore.og_cylindre.toString())}
                            />
                            {/*Axe*/}
                            <MultiChooseBox onChoose={v => this.set_og_axe(v)}
                                            type={Mesures.AXIS}
                                            subtitle={this.lastValue("og_axe") ? `Dernière mesure : ${this.lastValue("og_axe")}` : ""}
                                            offset={25}
                                            pickOnlyOne={true}
                                            values={[
                                                ["0", "5", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55", "60", "65", "70", "75", "80", "85", "90", "95"],
                                                ["100", "105", "110", "115", "120", "125", "130", "135", "140", "145", "150", "155", "160", "165", "170", "175", "180"]
                                            ]}
                                            selectedValue={MesureStore.og_axe.toString()}
                                            mesureDisplayList={mesureDisplayList}
                                            hasValue={!["", null].includes(MesureStore.og_axe.toString())}
                            />
                            {/*Addition*/}
                            <MultiChooseBox onChoose={v => this.set_og_addition(v)}
                                            type={Mesures.ADDITION}
                                            subtitle={this.lastValue("og_addition") ? `Dernière mesure : ${this.lastValue("og_addition")}` : ""}
                                            offset={25}
                                            values={[
                                                ["0", "0.25", "0.5", "0.75", "1", "1.25", "1.5", "1.75", "2", "2.25", "2.5", "2.75", "3"],
                                            ]}
                                            selectedValues={[
                                                MesureStore.og_addition.toString()
                                            ]}
                                            mesureDisplayList={mesureDisplayList}
                                            hasValue={!["", null].includes(MesureStore.og_addition.toString())}
                            />
                            {/*AV Loin*/}
                            <MultiChooseBox onChoose={v => this.set_og_av_loin(v)}
                                            type={Mesures.ACUITY}
                                            subtitle={this.lastValue("og_av_loin") ? `Dernière mesure : ${this.lastValue("og_av_loin")}` : ""}
                                            offset={25}
                                            suffix=" /10"
                                            values={[
                                                ["16*", "12*", "10*", "9*", "8*", "7*", "6*", "5*", "4*", "3*", "2*", "1*", "0.5*",
                                                    "CDLM4M", "CDLM3M", "CDLM2M", "CDLM1M", "VBLM2M", "VBLM1M", "PL+", "PL-"],
                                            ]}
                                            selectedValues={[
                                                MesureStore.og_av_loin.toString().match(/^\d+\.?\d*$/) ? (MesureStore.og_av_loin * 10).toString() : MesureStore.og_av_loin.toString()
                                            ]}
                                            mesureDisplayList={mesureDisplayList}
                                            hasValue={!["", null].includes(MesureStore.og_av_loin.toString())}
                            />
                            {/*Parinaud*/}
                            <MultiChooseBox onChoose={v => this.set_og_parinaud(v)}
                                            type={Mesures.PARINAUD}
                                            subtitle={this.lastValue("og_parinaud") ? `Dernière mesure : ${this.lastValue("og_parinaud")}` : ""}
                                            offset={25}
                                            values={[
                                                ["P1.5", "P2", "P3", "P4", "P6", "P8", "P10", "P12", "P14"],
                                            ]}
                                            selectedValues={[
                                                "P" + MesureStore.og_parinaud.toString()
                                            ]}
                                            mesureDisplayList={mesureDisplayList}
                                            hasValue={!["", null].includes(MesureStore.og_parinaud.toString())}
                            />
                        </div>
                    </div>
                </li>
            </ul>
        </>
    }

    render() {
        const mesureDisplayList = this.getMesureDisplayList()
        const has_tension = MesureStore.od_tension || MesureStore.og_tension
        const has_pachy = MesureStore.od_pachymetrie || MesureStore.og_pachymetrie
        if (!mesureDisplayList || mesureDisplayList.length === 0) return null
        return (
            <React.Fragment>
                <h2 className="center" style={{
                    margin: 0,
                    paddingBottom: "1vh"
                }}>{this.props.mesureName ? this.props.mesureName : "Fiche"}</h2>
                <ul className="collection" style={{borderRadius: "10px"}}>
                    {this.renderTextBoxes(mesureDisplayList)}
                    {MesureStore.need_trou_stenopeique ? (
                        <li className="collection-item grey lighten-5">
                            <AvTrouStenopeique/>
                        </li>) : null}
                    <BrandedElement temeoo>
                        {mesureDisplayList.some(item => [Mesures.PACHYMETRY.value, Mesures.TENSION.value].includes(item)) || has_tension || has_pachy ?
                            <li className="collection-item grey lighten-5">
                                {/*################### TENSION/PARINAUD ###################*/}
                                <div>
                                    {this.renderTension(mesureDisplayList, has_tension)}
                                    {this.renderPachymetry(mesureDisplayList, has_pachy)}
                                </div>
                            </li> : null}
                    </BrandedElement>
                </ul>
                {this.renderMultiChooseBoxes(mesureDisplayList)}
            </React.Fragment>
        );
    }
}

export default Formulaire
