import React from 'react';
import regex from "../../Shared/Validation/Regex";

function Messages({messages}) {

    const renderMessageCard = (message) => {
        const name = message.sent_by_me ? 'Moi' : message.sender_name;
        const text = regex.safeUrlReplace(message.text)
        if (message.sent_by_me) {
            return <div key={message.id} style={{marginLeft: "auto", maxWidth: "80%", minWidth: "20%"}}>
                <label>{name}</label>
                <div className="card green lighten-3 z-depth-0"
                     style={{display: "flex", flexDirection: "column", gap: 10, borderRadius: "10px 10px 0 10px"}}>
                    <div className="valign-wrapper" style={{flexWrap: "wrap", whiteSpace: "pre-wrap"}}>{text}</div>
                    <label style={{alignSelf: "flex-end"}}>{message.date}</label>
                </div>
            </div>
        }
        return <div key={message.id} style={{maxWidth: "80%", minWidth: "20%", marginRight: "auto"}}>
            <label>{name}</label>
            <h6 className="card blue lighten-3 z-depth-0"
                style={{display: "flex", flexDirection: "column", gap: 10, borderRadius: "10px 10px 10px 0"}}>
                <div className="valign-wrapper" style={{flexWrap: "wrap", whiteSpace: "pre-wrap"}}>{text}</div>
                <label style={{alignSelf: "flex-end"}}>{message.date}</label>
            </h6>
        </div>
    }

    return <div style={{display: "flex", flexDirection: "column", gap: 10}}>
        {messages.map(message => renderMessageCard(message))}
    </div>
}

export default Messages;