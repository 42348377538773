import React, { Component } from 'react';
import {observer} from "mobx-react"
import BridgeStore from "./BridgeStore"
import Connect from "./Connect/Connect"
import Machines from "./Dashboard/Machines"
import AddMachine from "./Dashboard/AddMachine"
import Strips from "../Shared/Strips"

@observer
export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    BridgeStore.init(props.bridge_uid,props.api_token,props.email,props.id)
    this.handleScrollToElement = this.handleScrollToElement.bind(this)

    this.myRef = React.createRef();
    this.state = {
      addMachine: false
    };
  }


  componentDidUpdate() {
    this.handleScrollToElement();
  }

  handleScrollToElement() {
  //  this.myRef.current.scrollIntoView({behavior: "smooth"}); // c est quoi cette horreur , en react on evite ce genre de tricks, essaie plutot d utilsier des state pour au pire cacher des trucs
  }

  render() {
    if(BridgeStore.loading){
      return(<div></div>)
    }
    if(BridgeStore.uid == null){
      return(
            <div>
                <Connect></Connect>
            </div>
            )
    }
    BridgeStore.pingCurrent() //mouais pas foufou de faire comme ca , mais ca va faire le taf
    if(BridgeStore.my_machine_status==0){
      return(
        <div>
          <Strips>white mini</Strips>
          <h1 className="center"> Etablissement du contact </h1>
          <div className="container">
            <p className="center"> Nous contactons le boitier, si celui-ci à changé pous pouvez en ajouter un nouveau</p>
            <p className="center"> <a onClick={(e)=>BridgeStore.uid=null} className="btn btn-large red white-text"> <i className="material-icons left">delete</i> Se déconnecter du boitier actuel</a></p>
          </div>
        </div>
      )
    }
    return (
      <div>
            <div>
              <Strips>white mini</Strips>
              {this.state.addMachine ? (
                <div ref={this.myRef}>
                <AddMachine db_machines={this.props.db_machines} db_machines_samba={this.props.db_machines_samba}/>
                </div>
              ) : (

                <div className={"center"}>
                  <div>
                    <Machines/>
                  </div>
                <a className={"btn btn-large"} style={{margin:10}} onClick={(e)=>{this.setState({addMachine: true})}}> <i className="material-icons right">add</i>Ajouter une machine</a>
                </div>
              )}
            </div>
      </div>
    );
  }
}
