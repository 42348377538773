import React from "react"
import axios from "axios"
import MesureStore from '../MesureStore'
import moment from "moment/moment";
import PatientSideBar from "../../../patient_data/SideBar/PatientSideBar";

const _ = require('lodash');

class CommentaireLibre extends React.Component {
    constructor(props) {
        super(props);
        this.handeWriting = this.handeWriting.bind(this)
        this.cleanData = this.cleanData.bind(this)
        this.handePrivateWriting = this.handePrivateWriting.bind(this)
        this.save = this.save.bind(this)
        this.saveAuto = _.debounce(this.save, 1000)
        this.state = {
            comment: "",
            private_comment: "",
            needToSave: true
        };
    }

    cleanData(e) {
        e.preventDefault()
        this.setState({comment: "", needToSave: true})
    }

    handeWriting(e) {
        this.setState({comment: e.target.value, needToSave: true})
        this.saveAuto()
    }

    handePrivateWriting(e) {
        this.setState({private_comment: e.target.value, needToSave: true})
        this.saveAuto()
    }

    save(e) {
        if (e !== undefined) e.preventDefault()
        this.setState({needToSave: false})
        axios.post("/api/appointement/saveCommentaireLibre", {
            text: this.state.comment,
            private_comment: this.state.private_comment,
            appointment_id: MesureStore.appointement_id
        }).then((data) => {
            this.setState({needToSave: false})
            MesureStore.anamnesis = this.state.comment
        })
    }

    componentDidMount() {
        axios.get("/api/appointement/CommentaireLibre", {params: {appointment_id: MesureStore.appointement_id}}).then((data) => {
            this.setState({comment: data.data[0], private_comment: data.data[1], needToSave: false})
        })
    }

    setMeeting(e) {
        e.preventDefault()
        axios.post("/api/appointement/addMeeting", {appointment_id: MesureStore.appointement_id}).then((data) => {
            alert("Meeting organisé.")
        })
    }

    renderComments() {
        return (
            <React.Fragment>
                <div className="row grey white">
                    <div className="col s10">
                        <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                            <h2 className="center">Anamnèse</h2>
                            <form>
                                <div className="input-field col s12 center">
                  <textarea id="com-libre" onChange={this.handeWriting} value={this.state.comment}
                            data-cy="FreeCommentTextArea"
                            className="row card-panel blue lighten-5" style={{
                      height: "20vh",
                      border: "none",
                      outline: "none",
                      borderTop: "thick solid #32a1ce",
                      marginTop: "0px",
                      borderRadius: "0px 0px 30px 30px",
                      resize: "none"
                  }}></textarea>
                                    <label htmlFor="com-libre"></label>
                                </div>
                            </form>
                            <form className="col s12">
                                <h2 className="center"> Commentaire privé </h2>
                                <p>Ce commentaire n'est pas transmis à l'opthalmologue</p>
                                <div className="input-field col s12 center">
                  <textarea id="com-libre-priv" onChange={this.handePrivateWriting} value={this.state.private_comment}
                            className="row card-panel blue lighten-5"
                            data-cy="PrivateCommentTextArea"
                            style={{
                                height: "20vh",
                                border: "none",
                                outline: "none",
                                borderTop: "thick solid #32a1ce",
                                marginTop: "0px",
                                borderRadius: "0px 0px 30px 30px",
                                resize: "none"
                            }}></textarea>
                                    <label htmlFor="com-libre-priv"></label>
                                </div>
                            </form>
                        </div>
                        {/*<div className="row">*/}
                        {/*  <div className="col s10 offset-s1">*/}
                        {/*    <p> Beta preview </p>*/}
                        {/*    <p> Organiser une téléconsulation, à n'utiliser qu'en accord avec votre ophtalmoliste lecteur</p>*/}
                        {/*    <p><a href="" className="btn green white-text" onClick={this.setMeeting} >Ajouter une téléconsulation</a></p>*/}
                        {/*  </div>*/}
                        {/*</div>*/}
                    </div>

                    <div className="col s2">
                        <h5 className="center">Actions</h5>
                        {this.state.needToSave == true ? (
                            <p className="center">
                                <a onClick={this.save}
                                   data-cy="SaveCommentsButton"
                                   className="blue darken-2 z-depth-0 btn-large">
                                    <i className="material-icons left">save</i>Save
                                </a>
                            </p>
                        ) : (
                            <p className="center"><a onClick={this.save}
                                                     className="disabled blue darken-2 z-depth-0 btn-large"><i
                                className="material-icons left">save</i>Save</a></p>

                        )}
                        <p className="center">
                            <i>
                                Sauvegarde auto activée
                            </i>
                        </p>
                        <p className="center">
                            <a onClick={this.cleanData}
                               className=" red darken-2 z-depth-0 btn-large"
                               data-cy="DeleteCommentsButton">
                                <i className="material-icons left">delete</i>Vider
                            </a>
                        </p>


                    </div>
                </div>
            </React.Fragment>
        );
    }

    render() {
        return <div className="row" style={{height: "100%", padding: 20, gap: 20}}>
            <div className="col s3" style={{height: "100%", paddingTop: 35}}>
                <PatientSideBar autosave patient={MesureStore.patient} onHistoryChange={(history) => {
                    MesureStore.patient.history = history;
                }}/>
            </div>
            <div className="col s9">
                {this.renderComments()}
            </div>
        </div>
    }
}

export default CommentaireLibre
