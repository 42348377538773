import React, {Component} from "react";
import {Sort} from "./Enums/Enums";

export default class Sorter extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <a className="btn-flat" onClick={this.props.onClick} style={{textTransform: "none", fontWeight:"bolder"}}>
                <i className={`material-icons right ${this.props.state === Sort.NONE ? "hide" : ""}`}>
                    {this.props.state === Sort.UP ? "expand_less" : "expand_more"}
                </i>{this.props.children}
            </a>
        )
    }
}

Sorter.defaultProps= {
    state: Sort.NONE
}