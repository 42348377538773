import React from 'react';
import axios from "axios";
import {toast} from "react-toastify";
import QRCode from "qrcode.react";
import {CENTRE_OPHTALMO_URL} from "../../../Shared/Helper";

function RnmFormKeyGenerator() {
    const [string, setString] = React.useState("");
    const [key, setKey] = React.useState("");

    const generateKey = () => {
        if (!string.trim()) return
        axios.get('/admin/console/security/cypher', {params: {string: string}})
            .then(res => {
                setKey(res.data.cyphered)
            })
            .catch(err => {
                toast.error("Erreur lors de la génération de la clé")
            })
    }

    const toClipboard = (str) => {
        navigator.clipboard.writeText(str).then(() => {
            toast.success("Clé copiée dans le presse papier")
        }, () => {
            toast.error("Erreur lors de la copie de la clé")
        })
    }


    const renderLink = () => {
        if (!key) return null
        const link = `${CENTRE_OPHTALMO_URL}/rnm?key=${key}`
        return <div style={{display: "flex", flexDirection: "column", alignItems: "center", gap: 10}}>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <a className="btn-flat" onClick={() => toClipboard(link)}><i className="material-icons">link</i></a>
                <h6 style={{lineBreak: "anywhere", margin: 0}}>Clé : {link}</h6>
            </div>
            <QRCode value={link}/>
        </div>


    }


    return (
        <div>
            <div className="row" style={{alignItems: "center", gap: 20}}>
                <div className="col m12 l6">
                    <h5>Entrer un mail :</h5>
                    <div style={{display: "flex", gap: 10, alignItems: "center"}}>
                        <div className="input-field outlined" style={{width: "100%"}}>
                            <input type="text" placeholder="Email" value={string || ""}
                                   onChange={(e) => setString(e.target.value)}/>
                        </div>
                        <div>
                            <a className={`btn-large blue darken-2 z-depth-0 ${!string.trim() ? "disabled" : ""}`}
                               onClick={generateKey}>Générer</a>
                        </div>
                    </div>
                </div>
                <div className="col m12 l6">
                    {renderLink()}
                </div>

            </div>
        </div>
    );
}

export default RnmFormKeyGenerator;