import React, { Component } from 'react';
import BridgeStore from "../BridgeStore"
import {observer} from "mobx-react"

@observer
export default class Statut extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    BridgeStore.socket.emit("knocknockMachine",{machine: props.uid})
  }
  render() {
    return (
      <div>
        {BridgeStore.avaiable_images.includes(this.props.uid) ? (
            <h2 className="center bridge-statut bridge-Disponible">Actif</h2>
          ) : (
            <h2 className="center bridge-statut bridge-Indisponible">Inactif</h2>

          )}
      </div>
    );
  }
}
