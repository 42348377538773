import React from 'react';

function EmptyConversation() {

    return <div id="full-height" style={{display: "flex", flexDirection: "column", paddingTop: 200}}>
        <h1 className="center" style={{marginBlock: 10}}>Aucune conversation n'a été sélectionnée</h1>
        <p className="center">Sélectionner une nouvelle conversation et commencez à discuter avec vos collègues !</p>
    </div>
}

export default EmptyConversation;