import React, {useEffect} from 'react';
import axios from "axios";
import Helper from "../../Shared/Helper";
import MessengerStore from "../MessengerStore";
import {toast} from "react-toastify";

const NewConversationModal = ({onValidation, onCancel}) => {
    const [selectedUserId, setSelectedUserId] = React.useState(null);
    const users = MessengerStore.office_users

    useEffect(() => {
        getUsers()
    }, []);

    const getUsers = () => {
        axios.get('messenger/office_users').then(res => {
            MessengerStore.office_users = res.data.users;
            setSelectedUserId(res.data.users[0].id)
        }).catch(_ => {
            toast.error("Une erreur est survenue lors du chargement des utilisateurs")
        })
    }

    return <>
        <div className="modal-content">
            <h6>Nouvelle conversation avec :</h6>
            <select className="browser-default" value={selectedUserId ?? ""}
                    onChange={e => setSelectedUserId(e.target.value)}>
                {users.map(user => {
                    return <option key={`user_choose_${user.id}`} value={user.id}>{Helper.formatName(user.prenom, user.nom)}</option>
                })}
            </select>
        </div>
        <div className="modal-footer valign-wrapper" style={{justifyContent: "space-evenly"}}>
            <a className="btn red darken-2 z-depth-0 nav-back-btn" onClick={() => onCancel()}>
                <i className="material-icons">close</i>
            </a>
            <a className="btn blue darken-2 z-depth-0 nav-action-btn"
               onClick={() => onValidation(selectedUserId)}>
                <i className="material-icons">done</i>
            </a>
        </div>
    </>
}

export default NewConversationModal;