import React, {useState} from "react";
import r from "../../../Shared/Validation/Regex";
import Tooltip from "../../../Shared/Tootip";
import {toast} from "react-toastify";
import axios from "axios"
import {Role} from "../../../Shared/Enums/Scope";
import {JOB} from "../../../Shared/Helper";
import ScopedElement from "../../../Shared/ScopedElement";
import BrandedElement from "../../../Shared/BrandedElement";

export default function PersonalInformationPanel(props) {

    let csrfToken = document.querySelector("meta[name='csrf-token']").content
    axios.defaults.headers.common['X-CSRF-Token'] = csrfToken

    const [rpps, setRpps] = useState(props.rpps_number == null ? "" : props.rpps_number);
    const [billing_number, setBillingNumber] = useState(props.billing_number == null ? "" : props.billing_number);
    const [phone_number, setPhoneNumber] = useState(props.phone_number == null ? "" : props.phone_number);
    const [first_name, setFirstName] = useState(props.first_name === null ? "" : props.first_name)
    const [last_name, setLastName] = useState(props.last_name === null ? "" : props.last_name)
    const [invoiceLabel, setInvoiceLabel] = useState(props.last_name === null ? "" : props.invoice_label)
    const [full_name, setFullName] = useState(props.fullname === null ? "" : props.full_name)

    const FIELDS = [
        {
            category_title: "Vos informations personnelles",
            cy_data: "PersonalInformations",
            size: 45,
            user_types: [Role.ORTHOPTIST, Role.OPHTALMOLOGIST, Role.EXTERN],
            fields: [
                {
                    title: "Prénom",
                    var_name: first_name,
                    setter: setFirstName,
                    cy_data: "FirstName",
                    id: "first_name",
                    validator_func: () => true,
                    tooltip_info: "Veuillez saisir votre prénom.",
                    error_rendering: renderFirstNameError,
                    user_types: [Role.ORTHOPTIST, Role.OPHTALMOLOGIST]
                },
                {
                    title: "Nom",
                    var_name: last_name,
                    setter: setLastName,
                    cy_data: "LastName",
                    id: "last_name",
                    validator_func: () => true,
                    tooltip_info: "Veuillez saisir votre nom.",
                    error_rendering: renderLastNameError,
                    user_types: [Role.ORTHOPTIST, Role.OPHTALMOLOGIST]
                },
                {
                    title: "Libellé de facturation",
                    var_name: invoiceLabel,
                    setter: setInvoiceLabel,
                    cy_data: "InvoiceLabel",
                    id: "invoice_label",
                    description: "Le libellé de facturation est le nom qui apparaitra sur vos factures. S'il n'est pas spécifié, le nom et le prénom apparaitront à la place.",
                    validator_func: () => true,
                    tooltip_info: "",
                    error_rendering: () => null,
                    user_types: [Role.ORTHOPTIST]
                },
                {
                    title: "N° de téléphone",
                    var_name: phone_number,
                    setter: setPhoneNumber,
                    cy_data: "PhoneNumber",
                    id: "phone_number",
                    validator_func: r.validatePhoneNumber,
                    tooltip_info: "Veuillez saisir votre numéro de téléphone.",
                    error_rendering: renderPhoneNumberError,
                    user_types: [Role.ORTHOPTIST]
                },
                {
                    title: "Nom complet",
                    var_name: full_name,
                    setter: setFullName,
                    cy_data: "FullName",
                    id: "full_name",
                    validator_func: ()=> true,
                    tooltip_info: "Veuillez saisir votre nom et prénom.",
                    error_rendering: renderFullNameError,
                    user_types: [Role.EXTERN]
                },
            ]
        },
        {
            category_title: "Vos informations de facturations",
            size: 55,
            cy_data: "BillInfos",
            hideOnOptician: true,
            user_types: [Role.ORTHOPTIST, Role.OPHTALMOLOGIST],
            fields: [
                {
                    title: "N° RPPS (Répertoire Partagé des Professionels de Santé).",
                    var_name: rpps,
                    setter: setRpps,
                    cy_data: "RPPS",
                    id: "rpps",
                    validator_func: r.validateRpps,
                    tooltip_info: "Veuillez saisir votre numéro RPPS composé de 11 chiffres.",
                    error_rendering: renderRppsError,
                    user_types: [Role.ORTHOPTIST, Role.OPHTALMOLOGIST]
                },
                {
                    title: "N° de facturation ADELI",
                    var_name: billing_number,
                    setter: setBillingNumber,
                    cy_data: "ADELI",
                    id: "adeli",
                    validator_func: r.validateAdeli,
                    tooltip_info: "Veuillez saisir votre numéro de facturation Adeli.",
                    error_rendering: renderAdeliError,
                    user_types: [Role.ORTHOPTIST, Role.OPHTALMOLOGIST]
                }
            ]
        }
    ]

    function updateInfos() {
        let data
        switch (props.user_type){
            case Role.ORTHOPTIST:
                data = {
                    rpps: rpps,
                    billing_number: billing_number,
                    phone_number: phone_number,
                    first_name: first_name,
                    last_name: last_name,
                    invoice_label: invoiceLabel
                }
                break
            case Role.OPHTALMOLOGIST:
                data = {
                    rpps: rpps,
                    billing_number: billing_number,
                    first_name: first_name,
                    last_name: last_name
                }
                break
            case Role.EXTERN :
                data = {full_name: full_name }
                break

            default:
                data = {}
        }
        axios.post(`${props.url_prefix}/administration/update`,
            data)
            .then((_) => toast.success("Informations sauvegardées."))
            .catch((_) => toast.error("Erreur lors de l'enregistrement."))
    }

    function isValidToSubmit() {
        switch(props.user_type){
            case Role.ORTHOPTIST:
                if (!first_name) return false
                if (!last_name) return false
                if (!phone_number || !r.validatePhoneNumber(phone_number)) return false
                if (rpps && !r.validateRpps(rpps)) return false
                if (billing_number && !r.validateAdeli(billing_number)) return false
                break

            case Role.OPHTALMOLOGIST:
                if (!first_name) return false
                if (!last_name) return false
                if (rpps && !r.validateRpps(rpps)) return false
                if (billing_number && !r.validateAdeli(billing_number)) return false
                break
            case Role.EXTERN:
                if (!full_name) return false
                break
        }
        return true
    }

    function formatSubmitTooltip() {
        let initial_text = "Veuillez compléter les éléments manquants pour enregistrer:"
        switch(props.user_type){
            case Role.ORTHOPTIST:
                if (!last_name) initial_text += "\n-Votre nom de famille"
                if (!first_name) initial_text += "\n-Votre prénom"
                if (!phone_number) initial_text += "\n-Votre numéro de téléphone"
                if (!r.validatePhoneNumber(phone_number)) initial_text += "\n-Un numéro de téléphone valide"
                if (rpps && !r.validateRpps(rpps)) initial_text += "\n-Un numéro RPPS valide à 11 chiffres"
                if (billing_number && !r.validateAdeli(billing_number)) initial_text += "\n-Un numéro Adeli valide à 9 chiffres"
                break

            case Role.OPHTALMOLOGIST:
                if (!last_name) initial_text += "\n-Votre nom de famille"
                if (!first_name) initial_text += "\n-Votre prénom"
                if (rpps && !r.validateRpps(rpps)) initial_text += "\n-Un numéro RPPS valide à 11 chiffres"
                if (billing_number && !r.validateAdeli(billing_number)) initial_text += "\n-Un numéro Adeli valide à 9 chiffres"
                break
            case Role.EXTERN:
                if(!full_name) initial_text += "\nVotre nom et prénom"
                break

            default:

        }

        return initial_text
    }

    function renderLastNameError() {
        if (!last_name) return (
            <div className="red-text"
                 data-cy="MissingLastName"
                 style={{display: "flex", alignItems: "center", gap: "0.5vw"}}>
                <i className="material-icons">error</i>Un nom de famille est requis.
            </div>
        )
    }

    function renderFirstNameError() {
        if (!first_name) return (
            <div className="red-text"
                 data-cy="MissingFirstName"
                 style={{display: "flex", alignItems: "center", gap: "0.5vw"}}>
                <i className="material-icons">error</i>Un prénom est requis.
            </div>
        )
        return null
    }

    function renderFullNameError() {
        if (!full_name) return (
            <div className="red-text"
                 data-cy="MissingFullName"
                 style={{display: "flex", alignItems: "center", gap: "0.5vw"}}>
                <i className="material-icons">error</i>Votre nom et prénom sont requis.
            </div>
        )
        return null
    }

    function renderPhoneNumberError() {
        if (!phone_number) return (
            <div className="red-text"
                 data-cy="MissingPhoneNumber"
                 style={{display: "flex", alignItems: "center", gap: "0.5vw"}}>
                <i className="material-icons">error</i>Un numéro de téléphone est requis.
            </div>
        )
        if (!r.validatePhoneNumber(phone_number)) return (
            <div className="red-text"
                 data-cy="WrongPhoneNumber"
                 style={{display: "flex", alignItems: "center", gap: "1vw"}}>
                <i className="material-icons">error</i>Numéro de téléphone non valide.
            </div>
        )
        return null
    }

    function renderAdeliError() {
        if (!billing_number) return null
        if (!r.validateAdeli(billing_number)) return (
            <div className="red-text"
                 data-cy="WrongAdeli"
                 style={{display: "flex", alignItems: "center", gap: "0.5vw"}}>
                <i className="material-icons">error</i>Votre numéro Adeli doit être composé de 9 chiffres.
            </div>
        )
        return null
    }

    function renderRppsError() {
        if (!rpps) return null
        if (!r.validateRpps(rpps)) return (
            <div className="red-text"
                 data-cy="WrongRpps"
                 style={{display: "flex", alignItems: "center", gap: "0.5vw"}}>
                <i className="material-icons">error</i>Votre numéro RPPS doit être composé de 11 chiffres.
            </div>
        )
        return null
    }

    const renderHelpIcon = (text) => {
        if(!text) return null
        return <Tooltip id="help_icon" position="bottom" text={text}>
            <i className="material-icons" style={{cursor: "default"}}>help</i>
        </Tooltip>
    }

    return (
        <div className="container"
             style={{width: "100%", padding: 20, height: "100%", display: "flex", flexDirection: "column", gap: 10}}>
            <h1 className="center" style={{margin: "20px 0 0 0"}}>Modifications de vos informations</h1>
            {FIELDS.map((category, category_index) => {
                if (!category.user_types.includes(props.user_type)) return null
                return (
                    <BrandedElement temeoo optician={category.hideOnOptician}>
                        <div key={`option_category_${category_index}`} style={{display: "flex", flexDirection: "column", gap: 20}}>
                            <h2 data-cy={category.cy_data}>{category.category_title}</h2>
                            {category.fields.map((field, field_index) => {
                                if (!field.user_types.includes(props.user_type)) return null
                                return (
                                    <div key={`option_${field_index}`}
                                         style={{
                                             display: "flex",
                                             alignItems: "center",
                                             width: "100%", gap: 10
                                         }}>
                                        <Tooltip id={`administrator_${field.id}`}
                                                 position="right"
                                                 text={field.tooltip_info}>
                                            <div
                                                className="input-field outlined "
                                                style={{marginRight: "2vw", minWidth: `${category.size}%`}}>
                                                <input
                                                    data-cy={field.cy_data}
                                                    id={field.id}
                                                    type="text"
                                                    defaultValue={field.var_name}
                                                    onChange={(e) => field.setter(e.target.value)}
                                                    className="validate"
                                                />
                                                <label htmlFor={field.var_name}>{field.title}</label>
                                            </div>
                                        </Tooltip>
                                        {renderHelpIcon(field.description)}
                                        {field.error_rendering()}
                                    </div>
                                )
                            })}
                        </div>
                    </BrandedElement>
                )
            })}
            <div>

                {isValidToSubmit() ?
                    <a className="btn blue darken-2 z-depth-0"
                       onClick={updateInfos}
                       data-cy="EnabledSaveButton"
                    >Enregistrer mes modifications</a>
                    :
                    <Tooltip id="missing_info_tooltip" position="right" text={formatSubmitTooltip()}>
                        <div style={{width: "fit-content", height: "fit-content"}} data-cy="DisabledSaveToolTip">
                            <a className="btn blue darken-2 z-depth-0 disabled"
                               style={{margin: "auto"}}
                               onClick={updateInfos}
                            >Enregistrer mes modifications</a>
                        </div>
                    </Tooltip>
                }
            </div>


        </div>
    )
}