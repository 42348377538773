import React, {Component} from "react";
import DisplayText from "./DisplayText";
import DisplayInput from "./DisplayInput";
import DisplayFooter from "./DisplayFooter";
import DisplayHeader from "./DisplayHeader";
import axios from "axios";
import ReactToPrint from "react-to-print";
import DisplayDivider from "./DisplayDivider";
import DisplayGroup from "./DisplayGroup";
import MesureStore from "../../../Appointments/Mesures/MesureStore";
import {RequestState} from "../../../Shared/StateHelper";
import Loader from "../../../Shared/Loader";
import Helper from "../../../Shared/Helper";
import PrescriptionStore from "../../../Appointments/PrescriptionStore";
import {ReportTokens} from "../../../Shared/Enums/Reports";
import DisplayImage from "./DisplayImage";

const _ = require('lodash');
const pageStyle = `
@media print {
   body {
       display: block;
       box-sizing: border-box;   
       padding: 0 15mm;
       height: auto;
       fontFamily: Arial, sans-serif;
       width: 100%;
       margin: 0
   }
   @page {
        margin: 40px 20px;
        size: auto;   /* auto is the initial value */
   }
   img {
        max-width: 100%;
        max-height: 100%;
   }
   #unbreakable {
        display: grid;
        page-break-inside: avoid;
        * {
            display: grid;
            page-break-inside: avoid;
        }
    }
}
`;


export default class DisplayBlocks extends Component {

    constructor(props) {
        super(props);
        this.renderBlock = this.renderBlock.bind(this)
        this.display = this.display.bind(this)
        this.debouncedSetValues = _.debounce(this.setValues, 1000)
        this.state = {
            appointment_id: undefined,
            template_id: undefined,
            values: {},
            display: <div/>,
            hiddenBlocks: [],
            status: RequestState.LOADING,
            template: undefined
        }
    }

    componentDidMount() {
        if (this.props.template) {
            this.setState({
                appointment_id: this.props.appointment_id,
                template_id: this.props.template.id
            }, () => this.getValues())
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.template && prevProps.template &&
            (this.props.template.id !== prevProps.template.id) || this.props.appointment_id !== prevProps.appointment_id) {
            this.setState({
                status: RequestState.LOADING,
                appointment_id: this.props.appointment_id,
                template_id: this.props.template.id
            }, () => this.getValues())
        }
        if (this.props.template && prevProps.template && this.props.template.blocks !== prevProps.template.blocks) {
            this.setState({template: this.props.template})
        }
        if (this.props.hiddenBlocks !== this.state.hiddenBlocks && this.props.hiddenBlocks !== prevProps.hiddenBlocks) {
            this.debouncedSetValues()
        }
        if (this.state.values !== prevState.values) this.setValuesFromDefault()
    }

    componentWillUnmount() {
        this.debouncedSetValues.flush()
    }

    getValues() {
        if (this.state.appointment_id !== undefined && this.state.template_id !== undefined) {
            axios.get("/orthoptistes/reports/values", {
                params: {
                    appointment_id: this.state.appointment_id,
                    template_id: this.state.template_id
                }
            }).then((resp) => {
                this.setState({
                    values: resp.data.values,
                    hiddenBlocks: resp.data.hiddenBlocks,
                    template: {blocks: resp.data.template},
                }, () => {
                    this.setValuesFromDefault()
                    this.props.setHiddenBlocks(resp.data.hiddenBlocks || [])
                })
            })
        } else this.setState({status: RequestState.SUCCESS})
    }

    setValues() {
        if (this.state.appointment_id !== undefined && this.state.template_id !== undefined) {
            axios.patch("/orthoptistes/reports/values", {
                appointment_id: this.state.appointment_id,
                template_id: this.state.template_id,
                values: this.state.values,
                hiddenBlocks: this.props.hiddenBlocks
            }).then((resp) => {
            })
        }
    }

    // runs through all inputs, and if there is a default text, and no value, set the value to the default text (formatted if needed)
    setValuesFromDefault() {
        let newValues = {...this.state.values}

        const computeBlock = (block) => {
            if (block.type === "GROUP") {
                block.data.blocks.forEach((block) => {
                    computeBlock(block)
                })
            }
            if (block.type === "INPUT") {
                const inputs = block.data
                inputs.forEach((input) => {
                    if (input.type !== "TEXT" || !input.default_text) return
                    let value = this.state.values[input.id]
                    if (!value) newValues[input.id] = this.formatText(input.default_text)
                })
            }
        }

        const template = this.state.template
        if (!template) return
        template.blocks.forEach((block) => {
            if (block.type === "GROUP") {
                block.data.blocks.forEach((block) => {
                    computeBlock(block)
                })
            } else computeBlock(block)

        })
        if (JSON.stringify(newValues) === JSON.stringify(this.state.values)) this.setState({status: RequestState.SUCCESS})
        else this.setState({values: newValues, status: RequestState.SUCCESS}, () => this.setValues())
    }

    computeValue = (token, value) => {
        return token.format(value)
    }

    formatText(text) {
        if (!this.props.appointment_pending) return text
        if (text) {
            const tokens = Object.values(ReportTokens).filter((token) => text.includes(token.token))
            tokens.forEach((token) => {
                const mesure = MesureStore.existingMesures.find((mesure) => mesure.titre === token.mesure_type)
                let value = mesure ? mesure[token.value] : ""
                value = this.computeValue(token, value)
                text = text.replace(new RegExp(token.token, "g"), value || "")
            })
            return text.replace(/!date!/g, MesureStore.appointment_date)
                .replace(/!nom_patient!/g, Helper.formatName(MesureStore.patient.prenom, MesureStore.patient.nom))
                .replace(/!prescription!/g, PrescriptionStore.prescriptionText)
                .replace(/!anamnese!/g, MesureStore.anamnesis)
                .replace(/!orthoptic_text!/g, MesureStore.orthoptic_text)
                .replace(/!private_comment!/g, MesureStore.private_comment)
                .replace(/!patient_history!/g, MesureStore.patient.history)
        } else return ""
    }


    onChange(values) {
        this.setState({values: values}, () => this.debouncedSetValues())
    }

    renderBlock(block) {
        if (this.props.hiddenBlocks.includes(block.id)) return null;
        const types = {
            "HEADER": <DisplayHeader block={block}/>,
            "INPUT": <DisplayInput
                appointment_pending={this.props.appointment_pending}
                block={block} values={this.state.values}
                onChange={(values) => this.onChange(values)}/>,
            "TEXT": <DisplayText block={block}/>,
            "FOOTER": <DisplayFooter block={block}/>,
            "DIVIDER": <DisplayDivider block={block}/>,
            "IMAGE": <DisplayImage block={block}/>,
            "GROUP": <DisplayGroup block={block}
                                   hiddenBlocks={this.props.hiddenBlocks}
                                   values={this.state.values}
                                   onChange={(values) => this.onChange(values)}
                                   appointment_pending={this.props.appointment_pending}
            />,
        }
        return types[block.type] || null
    }

    compute(block) {
        if (this.props.hiddenBlocks.includes(block.id)) return null;
        switch (block.type) {
            case "HEADER":
                return DisplayHeader.preview(block)
            case "INPUT":
                return DisplayInput.preview(block, this.state.values, {
                    patient: MesureStore.patient, appointment: {date: MesureStore.appointment_date}
                })
            case "TEXT":
                return DisplayText.preview(block)
            case "FOOTER":
                return DisplayFooter.preview(block)
            case "DIVIDER":
                return DisplayDivider.preview(block)
            case "IMAGE":
                return DisplayImage.preview(block)
            case "GROUP":
                return DisplayGroup.preview(block, this.state.hiddenBlocks, this.state.values, {
                    patient: MesureStore.patient, appointment: {date: MesureStore.appointment_date}
                })
        }
    }

    display() {
        if (this.state.template && this.state.template.blocks) {
            return <div className="valign-wrapper"
                        style={{
                            width: "21cm",
                            height: "100%",
                            justifyContent: "center",
                            fontSize: "12px",
                            fontFamily: "Arial"
                        }}
                        ref={el => (this.toPrint = el)}>
                <div style={{width: "90%", height: "90%", display: "flex", flexDirection: "column"}}>
                    {this.state.template.blocks.map((block, index) =>
                        <div key={index} style={{marginTop: `${block.type === "FOOTER" ? "auto" : ""}`}}>
                            {this.compute(block)}
                        </div>
                    )}
                </div>
            </div>
        } else return <div ref={el => (this.toPrint = el)}/>;
    }

    renderPrinter = () => {
        return (
            <ReactToPrint
                pageStyle={pageStyle}
                documentTitle={this.state.template.name}
                trigger={() => <p className="center"
                                  style={{width: "100%", backgroundColor: "#fbfbfd"}}><a
                    className="blue darken-2 z-depth-0 btn-small"><i
                    className="material-icons left">print</i>Imprimer</a></p>}
                content={() => this.toPrint}
            />
        )
    }

    render() {
        if (this.state.status === RequestState.LOADING) return <div style={{margin: 20}}><Loader/></div>
        if (this.state.template) {
            return <div>
                <div className="valign-wrapper" style={{justifyContent: "center"}}>
                    <div className={`${this.props.appointment_id === undefined ? "hide" : ""}`}>
                        {this.renderPrinter()}
                    </div>
                </div>
                <div className="card transparent "
                     style={{
                         width: "210mm",
                         minHeight: "297mm",
                         padding: 0,
                         fontSize: "12px",
                         fontFamily: "Arial"
                     }}>
                    <div className="valign-wrapper"
                         style={{width: "100%", height: "100%", justifyContent: "center"}}>
                        <div style={{width: "90%", display: "flex", flexDirection: "column", padding: "30px 0"}}>
                            {this.state.template.blocks ? (this.state.template.blocks.map((block, index) =>
                                <div id="block" key={index}
                                     style={{marginTop: `${block.type === "FOOTER" ? "auto" : ""}`}}
                                     onClick={() => this.props.onBlockClick(block)}>
                                    {this.renderBlock(block)}
                                </div>
                            )) : (<div/>)}
                        </div>
                    </div>
                </div>
                <div className="hide">
                    {this.display()}
                </div>
            </div>
        } else return null
    }

    static computeSize(size) {
        if (size === "small") {
            return "0.8em"
        } else if (size === "large") {
            return "1.2em"
        } else return ""
    }
}

DisplayBlocks.defaultProps = {
    values: {},
    hiddenBlocks: [],
    appointment_id: undefined,
    template_id: undefined,
    appointment_pending: false
}