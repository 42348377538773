import React, {Component} from "react";
import OphtalmologistOptions from "./OphtalmologistOptions";


export default class OphtalmologistParameters extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        return (
            <div>
                {/* Options de ..."*/}
                <div className="row">
                    <div className="col s6 offset-s3">
                        <h3 className="center-align">
                            {`Paramètres de ${Helper.toCamelCase(this.props.ophtalmologist.prenom)} ${this.props.ophtalmologist.nom.toUpperCase()}`}</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col s6">
                        <OphtalmologistOptions ophtalmologist_id={this.props.ophtalmologist.id}/>
                    </div>
                </div>
            </div>

        )
    }
}