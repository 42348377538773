import React from "react";
import axios from "axios";
import {toast} from "react-toastify";
import Validator from "../../Shared/Validator";
import MesureStore from "../../Appointments/Mesures/MesureStore";
import {Observer} from "mobx-react";

function BypassConstraintsButton() {
    const bypassConstraints = (reason = undefined) => {
        axios.patch(`/api/appointment/bypass`, {
            appointment_id: MesureStore.appointement_id,
            reason: reason
        }).then((response) => {
            const text = response.data.bypassed ? "ignorées" : "réappliquées"
            toast.success(`Les contraintes sont désormais ${text} pour cet examen.`);
                MesureStore.constraints_bypassed = response.data.bypassed
        }).catch((error) => {
            if (error.response.status === 400) return toast.error("La raison doit contenir au moins 15 caractères.")
            toast.error("Une erreur est survenue lors de la mise à jour des contraintes.")
        })
    }

    const render = () => {
        if (MesureStore.constraints_bypassed) return <a className="btn-small blue darken-2 z-depth-0 truncate"
                                                        title="Rétablir les régles"
                                                        onClick={() => bypassConstraints()}>
            <i className="material-icons left">lock</i>
            Réappliquer les contraintes
        </a>

        return  <Validator
            id={`bypass_constraints_${MesureStore.appointement_id}`}
            onValidation={(v) => bypassConstraints(v)}
            text="Voulez-vous vraiment ignorer les contraintes ?"
            detail="Les contraintes imposées sont mises en place pour assurer une relecture optimale de vos dossiers.
            En les ignorant, il est possible que l'ophtalmologiste lecteur refuse la relecture par manque d'information.
            Merci de nous donner la raison de votre choix. En 15 caractères minimum."
            validationColor="black" validationText="Ignorer les contraintes"
            validationIcon="lock_open" abortColor="blue darken-2" abortIcon="close"
            abortText="Annuler"
            input
        >
            <a className={`btn-small black z-depth-0`} title="Ignorer les règles qui empêchent de terminer un examen">
                <i className="material-icons left">lock_open</i>
                Ignorer les contraintes
            </a>
        </Validator>
    }



    return <Observer>
        {() => render()}
    </Observer>
}

export default BypassConstraintsButton;