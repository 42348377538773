import React from "react"
import axios from "axios"
import PrintAcces from "./PrintAcces"
import ReactToPrint from 'react-to-print';

import {toast} from 'react-toastify';


class Prescription extends React.Component {
    constructor(props) {
        super(props);
        this.handeWriting = this.handeWriting.bind(this)
        this.sendComment = this.sendComment.bind(this)
        this.vider = this.vider.bind(this)
        this.state = {
            text: '',
            loading: false,
            saved: false,
            email: this.props.patient.email,
            telephone: this.props.patient.telephone,
            rpatient: {},
            key: {},
            prescription: {}
        };
    }


    vider(e) {
        e.preventDefault()
        this.setState({text: ""})
    }

    handeWriting(e) {
        this.setState({text: e.target.value})
    }


    sendComment(e) {
        e.preventDefault()
        axios.post("/api/secretariat/prescription/save", {
            id: this.props.patient.id,
            text: this.state.text,
            email: this.state.email,
            telephone: this.state.telephone
        }).then((data) => {
            toast.success('Prescription enregistrée')

        })
    }

    componentDidMount() {
        this.setState({text: this.props.comment})
    }


    render() {
        return (
            <React.Fragment>
                <div className="row" style={{gap: 10, margin: 20}}>
                    <div className="col s9">
                        <div className="row">
                            <div className="input-field col s12 outlined">
                                <textarea id="textarea1" rows="50" cols="20" style={{height: "60vh", padding: 20}}
                                          onChange={this.handeWriting} value={this.state.text}/>
                                <label htmlFor="textarea1">Prescription</label>
                            </div>
                        </div>
                    </div>
                    <div className="col s3" style={{display: "flex", gap: 10}}>
                        <div style={{display: "flex", flexDirection: "column", gap: 10, width: "100%", justifyContent: "center"}}>
                            <h5 className="center">{Helper.formatName(this.props.patient.prenom, this.props.patient.nom)}</h5>
                            <div className="input-field col s12 outlined">
                                <input type="text" value={this.state.telephone}
                                       onChange={(e) => this.setState({telephone: e.target.value})}/>
                                <label htmlFor="email" className="active">Téléphone</label>
                            </div>
                            <div className="input-field col s12 outlined">
                                <input type="email" value={this.state.email}
                                       onChange={(e) => this.setState({email: e.target.value})}/>
                                <label htmlFor="email" className="active">Email</label>
                            </div>
                        </div>
                        <div style={{display: "flex", flexDirection: "column", width: 250, justifyContent: "center"}}>
                            <a onClick={this.sendComment} className="btn blue darken-2 z-depth-0"><i
                                className="material-icons left">send</i>Sauver</a>
                            <a onClick={this.vider} className="btn red darken-2 z-depth-0"><i
                                className="material-icons left">delete</i>Vider</a>
                            {this.state.saved ? (
                                <ReactToPrint
                                    trigger={() => <p className=""><a
                                        className="btn green darken-2 z-depth-0"><i
                                        className="material-icons left">print</i>Cle d'accès</a></p>}
                                    content={() => this.componentRefAcces}
                                />

                            ) : (<a href="" className="btn disabled"><i className="material-icons left">print</i>Imprimer</a>)}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
            ;

    }
}

export default Prescription
