import React, {Component} from "react";
import OphtalmologistParameters from "./OphtalmologistParameters";
import Helper from "../../../Shared/Helper";


export default class SelectOphtalmo extends Component {
    constructor(props) {
        super(props);
        this.renderSelector = this.renderSelector.bind(this)
        this.renderOptions = this.renderOptions.bind(this)
        this.backToSelector = this.backToSelector.bind(this)
        this.filter = this.filter.bind(this)
        this.state = {
            selectedId: -1,
            filter: ""
        }
    }

    backToSelector() {
        this.setState({
            selectedId: -1,
            filter: ""
        })
    }

    filter(ophts) {
        return ophts.filter(opht => {
            return (opht.prenom != null && opht.nom != null) && (opht.prenom.toLowerCase().includes(this.state.filter) || opht.nom.toLowerCase().includes(this.state.filter))
        })
    }

    renderSelector() {
        return (
            <div>
                <div className="row">
                    <div className="col s6 offset-s3">
                        <div className="input-field">
                            <i className="material-icons prefix">account_circle</i>
                            <input id="icon_prefix" type="text" className="validate"
                                   onChange={(e) => this.setState({filter: e.target.value.toLowerCase()})}/>
                            <label htmlFor="icon_prefix">Rechercher</label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col s6 offset-s3">
                        <table className="striped">
                            <tbody>
                            {
                                this.filter(this.props.ophtalmologists).map((opht, index) =>
                                    <tr key={index}>
                                        <td>{Helper.toCamelCase(opht.prenom)}</td>
                                        <td>{opht.nom.toUpperCase()}</td>
                                        <td className="right-align">
                                            <a className="waves-effect waves-light btn-flat grey-text"
                                               onClick={() => this.setState({selectedId: opht.id})}><i
                                            className="material-icons left">settings</i></a>
                                        </td>
                                    </tr>
                                )
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        )
    }

    renderOptions() {
        const selectedOpht = this.props.ophtalmologists.filter((o) => o.id === this.state.selectedId)[0]
        if(selectedOpht) {
            return (
                <div>
                    <a className="btn-flat btn-large waves-effect black-text transparent" onClick={this.backToSelector}><i
                    className="material-icons left">chevron_left</i>retour</a>
                    <OphtalmologistParameters ophtalmologist={selectedOpht} />
                </div>
            )
        }
    }

    render() {
        return (
            <div className="container" style={{paddingTop: "3vh"}}>
                {
                    this.state.selectedId < 0 ?
                        (this.renderSelector())
                        :
                        (this.renderOptions())
                }
            </div>

        )
    }
}
