import React, {Component} from "react";
import DisplayBlocks from "./DisplayBlocks";
import DisplayHeader from "./DisplayHeader";
import DisplayInput from "./DisplayInput";
import DisplayText from "./DisplayText";
import DisplayFooter from "./DisplayFooter";
import DisplayDivider from "./DisplayDivider";

export default class DisplayGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: {}
        }
    }

    componentDidMount() {
        this.setState({block: this.props.block})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props !== prevProps) {
            this.setState({block: this.props.block})
        }
    }

    onChange(values) {
        this.props.onChange(values)
    }

    renderBlock(block) {
        if (this.props.hiddenBlocks.includes(block.id)) return null;
        const types = {
            "HEADER": <DisplayHeader block={block}/>,
            "INPUT": <DisplayInput
                appointment_pending={this.props.appointment_pending}
                block={block} values={this.props.values}
                onChange={(values) => this.onChange(values)}/>,
            "TEXT": <DisplayText block={block}/>,
            "FOOTER": <DisplayFooter block={block}/>,
            "DIVIDER": <DisplayDivider block={block}/>,
            "GROUP": <DisplayGroup block={block} appointment_pending={this.props.appointment_pending}/>,
        }
        return types[block.type] || null
    }

    render() {
        if (this.state.block.data) {
            return (
                this.state.block.data.blocks.map((block, index) =>
                    <div key={index}>{this.renderBlock(block)}</div>
                )
            )
        } else return null
    }

    static compute(block, hiddenBlocks, values, metadata) {
        if (hiddenBlocks.includes(block.id)) return null;
        switch (block.type) {
            case "HEADER":
                return DisplayHeader.preview(block)
            case "INPUT":
                return DisplayInput.preview(block, values, metadata)
            case "TEXT":
                return DisplayText.preview(block)
            case "FOOTER":
                return DisplayFooter.preview(block)
            case "DIVIDER":
                return DisplayDivider.preview(block)
            case "GROUP":
                return DisplayGroup.preview(block, hiddenBlocks, values, metadata)
        }
    }

    static preview(block, hiddenBlocks, values, metadata) {
        if (block.data) {
            return <div id="unbreakable">
                {block.data.blocks.map((block, index) => <div key={index}>
                            {this.compute(block, hiddenBlocks, values, metadata)}
                        </div>
                    )}
            </div>

        } else return null
    }
}