import React from 'react';
import EditComments from "./EditComments";
import MesureStore from "../../MesureStore";
import EditPatientHistory from "./EditPatientHistory";
import {Observer} from "mobx-react";
import AppointmentsHistory from "./AppointmentsHistory";
import TeletransmissionController from "./Teletransmission/TeletransmissionController";
import BorderedDisplay from "../../../../Shared/UiKit/BorderedDisplay";
import {Role} from "../../../../Shared/Enums/Scope";
import ScopedElement from "../../../../Shared/ScopedElement";


function AppointmentSideBar() {
    const [expanded, setExpanded] = React.useState(true);
    const [loading, setLoading] = React.useState(false);

    if (!expanded) return <div className="card grey lighten-5"
                               style={{height: "100%", width: "fit-content", borderRadius: 0, padding: 0}}>
        <a className="btn-small btn-flat z-depth-0 transparent" onClick={() => setExpanded(true)}>
            <i className="material-icons">menu_open</i>
        </a>
        <ScopedElement scope={[Role.CV_USER]}>
            <TeletransmissionController retracted/>
        </ScopedElement>
    </div>

    return <Observer>
        {() =>
            <div className="card grey lighten-5 appointment-sidebar"
                 style={{height: "100%", width: 300, borderRadius: 0, padding: 0, overflow: "clip"}}>
                <div style={{display: "flex"}}>
                    <div style={{margin: "5px 0 0 5px"}}>
                        <i className={`rotating material-icons grey-text ${loading ? "" : "hide"}`}>sync</i>
                    </div>
                    <a className="btn-small btn-flat z-depth-0 transparent" style={{marginLeft: "auto"}}
                       onClick={() => setExpanded(false)}>
                        <i className="material-icons">close</i>
                    </a>
                </div>
                <div style={{paddingInline: 10, overflowY: "scroll", height: "100%"}}>
                    <ScopedElement scope={[Role.CV_USER]}>
                        <div style={{paddingBlock: 10}}>
                            <BorderedDisplay text={"Télétransmission"}>
                                <TeletransmissionController/>
                            </BorderedDisplay>
                        </div>
                    </ScopedElement>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 10,
                        marginBlock: "0 45px",
                        paddingBlock: "10px 0"
                    }}>
                        <EditPatientHistory patient={MesureStore.patient}
                                            loading={() => setLoading(true)}
                                            loaded={() => setLoading(false)}
                        />
                        <EditComments
                            loading={() => setLoading(true)}
                            loaded={() => setLoading(false)}
                        />
                        <AppointmentsHistory/>
                    </div>
                </div>
            </div>
        }
    </Observer>
}

export default AppointmentSideBar;