import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios'
import moment from "moment"
import {JOB} from "../../Shared/Helper";

export default class Apointments extends Component {
  constructor(props) {
    super(props)
    this.loadData = this.loadData.bind(this)
    this.fetchMoreData = this.fetchMoreData.bind(this)
    this.handleCheck = this.handleCheck.bind(this)
    this.state = {
      page: 1,
      data: [],
      checked: [],
      last_checked: null
    },
    this.loadData(1);
  }
  loadData(page) {
    axios.get("/externes/rapports/appointements?page="+page).then((data)=>{
      this.setState({
        page: page + 1,
        data: this.state.data.concat(data.data)
      })
    })
  }
  handleCheck(e){
    let id = parseInt(e.target.dataset.id)
    let checked = !this.state.checked.includes(id)

    if(e.nativeEvent.shiftKey && this.state.last_checked != null && checked){
        let start = Math.min(this.state.last_checked, id)
        let end = Math.max(this.state.last_checked, id)
        let checked = this.state.checked
        for(const data of this.state.data){
            if(data.id >= start && data.id <= end && data.status_controle > 1 && !checked.includes(data.id)){
                checked.push(data.id)
            }
        }
        this.setState({
            checked: checked
        } )
        return;
    }
    this.setState({
            checked: checked ?  this.state.checked.concat(id) : this.state.checked.filter((i) => i != id),
            last_checked: checked ? id : null
    })


  }

  fetchMoreData(){
    this.loadData(this.state.page)
  }

  render() {
    return (
      <div>
          {this.state.checked.length > 1 ? (
            <div className="row">
                <a href={"/externes/rapports/bulk/"+this.state.checked.join("-")} className="btn">
                    <i className="material-icons left">print</i>
                    imprimer {this.state.checked.length} rapports
                </a>
            </div>
          ) : null
          }
        <InfiniteScroll
          dataLength={this.state.data.length}
          next={this.fetchMoreData}
          hasMore={true}
          loader={<h4 className="center">...</h4>}
        >
        <table className="striped bordered">
          <thead>
            <tr>
              <th> </th>
              <th> Date </th>
              <th> Patient prénom </th>
              <th> Patient nom</th>
              <th> {JOB}</th>
              <th> Statut</th>
              <th><i className="material-icons">download</i></th>

            </tr>
          </thead>
          <tbody>
              {this.state.data.map((i, index) => (
                <tr key={index}>
                  { i.status_controle > 1 ? (
                    <td>
                      <label style={{width: "30px",display: "block",margin: "auto"}}>
                        <input type="checkbox" data-id={i.id} onChange={this.handleCheck} checked={
                            this.state.checked.includes(i.id)
                        } />
                        <span> </span>
                      </label>
                    </td>
                    ) : (
                        <td></td>
                    )
                  }
                  <td>{moment(i.start).format("DD-MM-YYYY h:mm")}</td>
                  <td>
                  <a href={"/patients/"+i.patient.id} target="_blank">
                    {i.patient.prenom}
                  </a>
                  </td>
                  <td>
                  <a href={"/patients/"+i.patient.id} target="_blank">
                    {i.patient.nom}
                  </a>
                  </td>
                  <td>{i.orthoptiste.prenom} {i.orthoptiste.nom}</td>
                  <td><StatusApp statut={i.status_controle} unlock={i.override_lock} /></td>
                  <td>
                    {i.status_controle > 1 ? (
                      <a target="_blank" href={"/externes/rapports/show/"+i.id}>Imprimer</a>
                    ) : (
                      <span></span>
                    )}

                  </td>
                </tr>
              ))}

          </tbody>

        </table>
        </InfiniteScroll>

      </div>
    );
  }
}


export class StatusApp extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {statut,unlock} = this.props;
  if(statut ==0){

    return (
     <React.Fragment>
      <i className="material-icons left">announcement</i> N/A
     </React.Fragment>
    );
  }else if(statut==1){
        return (
     <React.Fragment>
      <i className="material-icons left orange-text">pending</i> En cours de validation
     </React.Fragment>
    );
  }else if(statut==2){
        return (
     <React.Fragment>
      <i className="material-icons left green-text">done</i> Approuvé et accessible
     </React.Fragment>
    );
  }else{
    return (
     <React.Fragment>
      <i className="material-icons left red-text">report_problem</i> Non approuvé et accessible
      {unlock ? (
          <span> mais débloqué</span>
        ) : (
          <span></span>
        )}
     </React.Fragment>
    );
  }
}

}
