import axios from "axios";
import React, {useEffect} from "react";
import {RequestState} from "../../../Shared/StateHelper";

function useMeasures(appointment_id, all_measures) {
    const [measures, setMeasures] = React.useState([])
    const [requestState, setRequestState] = React.useState(RequestState.IDLE)

    useEffect(() => {
        get_measures()
    }, []);

    const get_measures = () => {
        setRequestState(RequestState.LOADING)
        axios.get("/api/mesures", {params: {appointment_id: appointment_id}}).then((response) => {
            const measures = response.data.map(measure => {
                return {...measure, name: all_measures.find(m => m.id === measure.typedemesure_id).titre}
            })
            setMeasures(measures)
            setRequestState(RequestState.SUCCESS)
        }).catch((error) => setRequestState(RequestState.ERROR))
    }

    return {measures, measure_request_state: requestState}
}

export default useMeasures;