import React, {useEffect} from 'react';
import ScopedElement from "../../../../Shared/ScopedElement";
import {Role} from "../../../../Shared/Enums/Scope";
import axios from "axios";
import {toast} from "react-toastify";
import BorderedDisplay from "../../../../Shared/UiKit/BorderedDisplay";
import {UserPermissions, UserRights} from "../../../../Shared/Enums/Enums";
import EditButton from "../../../../Shared/EditButton";
import Validator from "../../../../Shared/Validator";
import RestrictedElement from "../../../../Shared/RestrictedElement";
import RightsStore from "../../RightsStore";
import Error from "../../../../Shared/Error";

function RightsAndRoles() {
    const [roles, setRoles] = React.useState([]);
    const [onEditRole, setOnEditRole] = React.useState({})
    const [onEditRight, setOnEditRight] = React.useState({})

    useEffect(() => {
        get_roles()
    }, []);

    const get_roles = () => {
        axios.get("/admin/console/users/role/all").then((response) => {
            setRoles(response.data);
        })
    }

    const create_role = () => {
        axios.put('/admin/console/users/role', {
            role: {name: document.getElementById("role_name_input").value}
        }).then((resp) => {
            setRoles([...roles, resp.data])
        }).catch((err) => {
            if (err.response.status === 400) {
                toast.error("Ce nom existe déjà")
            } else if (err.response.status === 401) {
                toast.error("Vous n'avez pas les droits pour effectuer cette action")
            } else toast.error("Impossible de créer le role")
        })
    }

    const update_role = (role) => {
        axios.patch(`/admin/console/users/role/${role.id}`, {
            role: role
        }).then((resp) => {
            setRoles(roles.map(role => role.id === resp.data.id ? resp.data : role))
        }).catch((err) => {
            if (err.response.status === 401) {
                toast.error("Vous n'avez pas les droits pour effectuer cette action")
            } else toast.error("Impossible de mettre a jour le role")
        })
    }

    const delete_role = (role) => {
        axios.delete(`/admin/console/users/role/${role.id}`).then((resp) => {
            setRoles(roles.filter(role => role.id !== resp.data.id))
        }).catch((err) => {
            if (err.response.status === 401) {
                toast.error("Vous n'avez pas les droits pour effectuer cette action")
            } else toast.error("Impossible de supprimer le role")
        })
    }

    const create_right = (role, right) => {
        axios.put(`/admin/console/users/role/${role.id}/right`, {
            right: right
        }).then((resp) => {
            setRoles(roles.map(role => role.id === resp.data.id ? resp.data : role))
        }).catch((err) => {
            if (err.response.status === 401) {
                toast.error("Vous n'avez pas les droits pour effectuer cette action")
            } else toast.error("Impossible de créer le droit")
        })
    }
    const update_right = (right) => {
        axios.patch(`/admin/console/users/role/right/${right.id}`, {
            right: right
        }).then((resp) => {
            setRoles(roles.map(role => role.id === resp.data.id ? resp.data : role))
        }).catch((err) => {
            if (err.response.status === 401) {
                toast.error("Vous n'avez pas les droits pour effectuer cette action")
            } else toast.error("Impossible de mettre a jour le droit")
        })
    }
    const delete_right = (right) => {
        axios.delete(`/admin/console/users/role/right/${right.id}`).then((resp) => {
            setRoles(roles.map(role => role.id === resp.data.id ? resp.data : role))
        }).catch((err) => {
            if (err.response.status === 401) {
                toast.error("Vous n'avez pas les droits pour effectuer cette action")
            } else toast.error("Impossible de supprimer le droit")
        })
    }


    const renderRightAdd = (role) => {
        if (onEditRole !== role) return <RestrictedElement field={UserRights.ADMIN}
                                                           permission={UserPermissions.READ_WRITE}>
            <a className="btn-small blue darken-2 z-depth-0"
               onClick={() => {
                   setOnEditRole(role)
                   setOnEditRight({})
               }}
               style={{width: "100%", margin: 0}}>
                <i className="material-icons">add</i>
            </a>
        </RestrictedElement>

        return <div style={{display: "flex", flexDirection: "column", gap: 10}}>
            <div style={{display: "flex", gap: 10}}>
                <select className="browser-default" value={onEditRight.field || ""}
                        onChange={(e) => setOnEditRight({...onEditRight, field: e.target.value})}>
                    <option value="" disabled>Choisir une champ</option>
                    {Object.values(UserRights).map((right, index) => {
                        return <option key={right.value} value={right.value}>{right.name}</option>
                    })}
                </select>
                <select className="browser-default" value={onEditRight.permission || ""}
                        onChange={(e) => setOnEditRight({...onEditRight, permission: e.target.value})}>
                    <option value="" disabled>Choisir une permission</option>
                    {Object.values(UserPermissions).map((permission, index) => {
                        return <option key={permission.value} value={permission.value}>{permission.name}</option>
                    })}
                </select>
            </div>
            <div style={{display: "flex", gap: 10}}>
                <a className="btn-small green darken-2 z-depth-0"
                   onClick={() => create_right(role, onEditRight)}
                   style={{width: "100%", margin: 0}}>
                    <i className="material-icons">done</i></a>
                <a className="btn-small red darken-2 z-depth-0"
                   onClick={() => setOnEditRole({})}
                   style={{width: "100%", margin: 0}}>
                    <i className="material-icons">close</i></a>
            </div>

        </div>
    }

    const renderRights = (role) => {
        return <BorderedDisplay text={"Droits"}>
            <div style={{display: "flex", flexDirection: "column", gap: 20}}>
                <ul className="" style={{margin: 0, padding: "0 10px", width: "100%"}}>
                    {role.rights.length > 0 ? role.rights.map((right, index) => {
                        return <li key={index} style={{display: "flex", width: "100%", alignItems: "center"}}>
                            <div style={{width: "100%", margin: 0}}>
                                <span>{UserRights[right.field]?.name}</span>
                            </div>
                            <div style={{width: "300px", margin: 0}}>
                                <EditButton text={UserPermissions[right.permission]?.name} type="select"
                                            disabled={!RightsStore.can_do(UserRights.ADMIN, UserPermissions.WRITE_ONLY)}
                                            options={Object.values(UserPermissions).map((permission, index) => permission.name)}
                                            onValidation={v => update_right({
                                                ...right,
                                                permission: Object.values(UserPermissions).find(p => p.name === v).value
                                            })} minWidth="100%" flat/>
                            </div>
                            <RestrictedElement field={UserRights.ADMIN} permission={UserPermissions.READ_WRITE}>
                                <a className="btn-flat red-text test-darken-2 z-depth-0"
                                   onClick={() => delete_right(right)}>
                                    <i className="material-icons">delete</i>
                                </a>
                            </RestrictedElement>
                        </li>
                    }) : <h6>Aucun droit n'est configuré</h6>}
                </ul>
                {renderRightAdd(role)}
            </div>
        </BorderedDisplay>
    }

    const renderRole = (role) => {
        return (
            <div className="collection-item transparent" key={role.id}
                 style={{display: "flex", width: "100%", gap: 20}}>
                <div style={{width: "250px", margin: 0}}>
                    <EditButton disabled={!RightsStore.can_do(UserRights.ADMIN, UserPermissions.WRITE_ONLY)}
                                text={role.name} onValidation={v => update_role({...role, name: v})} minWidth="100%"
                                flat/>
                </div>
                <div style={{width: "100%", margin: 0}}>
                    {renderRights(role)}
                </div>
                <div className="valign-wrapper">
                    <RestrictedElement field={UserRights.ADMIN} permission={UserPermissions.READ_WRITE}>
                        <Validator onValidation={() => delete_role(role)}
                                   text="T'es sûr de toi ?"
                                   detail="Ça marchera pas si y a des utilisateurs avec ce rôle t'façon"
                                   validationText="Supprimer" validationColor="red darken-2" validationIcon="delete"
                                   abortColor="black darken-2" abortIcon="cancel" abortText="Annuler"
                                   id="validate-intents"
                        >
                            <a className="btn-flat red-text test-darken-2 z-depth-0">
                                <i className="material-icons">delete</i>
                            </a>
                        </Validator>
                    </RestrictedElement>
                </div>
            </div>
        )
    }

    if (!RightsStore.can_do(UserRights.ADMIN, UserPermissions.READ_ONLY)) return <div id="full-height"
                                                                                      style={{margin: 50}}>
        <Error errorText="Vous n'avez pas les droits pour accéder à cette page"/>
    </div>

    return <div id="full-height"
                style={{display: "flex", flexDirection: "column", gap: 20, padding: 20}}>
        <h3 className="valign-wrapper" style={{margin: 20}}>
            <i className="material-icons left">admin_panel_settings</i>
            Droits d'utilisation
        </h3>
        <div className="divider"/>
        <div className="transparent" style={{display: "flex", flexDirection: "column", height: "80%"}}>
            <RestrictedElement field={UserRights.ADMIN} permission={UserPermissions.WRITE_ONLY}>
                <div className="valign-wrapper" style={{justifyContent: "center", gap: 10}}>
                    <div className="input-field outlined">
                        <input placeholder="Nom du nouveau rôle" id="role_name_input"/>
                    </div>
                    <a className="btn blue darken-2 z-depth-0"
                       onClick={create_role}>
                        <i className="material-icons left">add</i>
                        Nouveau
                    </a>
                </div>
            </RestrictedElement>
            <div className="collection"
                 style={{borderRadius: 5, height: "100%", overflowY: "scroll", scrollbarGutter: "stable"}}>
                {roles.map((internal_user) => {
                    return renderRole(internal_user)
                })}
            </div>
        </div>

    </div>
}


export default RightsAndRoles;