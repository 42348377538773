import React from "react";
import {RequestState} from "./StateHelper";

function StateButton(props) {

    const renderState = () => {
        switch (props.state) {
            case RequestState.RELOADING:
            case RequestState.LOADING:
                return <div className="preloader-wrapper small active">
                    <div className="spinner-layer spinner-blue-only">
                        <div className="circle-clipper left">
                            <div className="circle"></div>
                        </div>
                        <div className="gap-patch">
                            <div className="circle"></div>
                        </div>
                        <div className="circle-clipper right">
                            <div className="circle"></div>
                        </div>
                    </div>
                </div>
            case RequestState.ERROR:
                return <i className="material-icons red-text">error</i>
            case RequestState.SUCCESS:
                return <i className="material-icons green-text">done</i>
            default:
                return null;
        }
    }

    return (
        <div className="valign-wrapper">
            {props.centered ? <div style={{padding: "0 1vw", width: "80px"}}/> : <div/>}
            <a className={`btn z-depth-0 ${props.color || ""} ${props.disabled ? "disabled" : ""}`}
               onClick={props.onClick}
               data-cy={props.data_cy}>
                <i className={`material-icons ${props.side}`}>{props.icon}</i>
                {props.text}</a>
            <div className="valign-wrapper" style={{padding: "0 1vw", width: "80px"}}>
                {renderState()}
            </div>
        </div>
    );
}

StateButton.defaultProps = {
    centered: false,
    disabled: false
}
export default StateButton