import React from 'react';
import EditButton from "../../../../Shared/EditButton";
import BorderedDisplay from "../../../../Shared/UiKit/BorderedDisplay";

function OfficeGeneralOptions({office, onOfficeChange}) {
    return (
        <div>
            <BorderedDisplay text="Information supplémentaires">
                <ul className="collection" style={{borderColor: "transparent"}}>
                    <li className="collection-item valign-wrapper" style={{borderColor: "transparent"}}>
                        <span className="title">Prix leasing</span>
                        <div className="input-field right-align"
                             style={{marginLeft: "auto", marginTop: "0px", marginBottom: "0px", padding: "0px"}}>
                            <EditButton text={parseFloat(office.leasing_price)/100} suffix="€"
                                        onValidation={(v) => onOfficeChange({...office, leasing_price: v})}
                                        filter={(e) => e.target.value.match(/^\d*[.,]?\d{0,2}$/g)}
                            />
                        </div>
                    </li>
                </ul>
            </BorderedDisplay>
        </div>
    );
}

export default OfficeGeneralOptions;