import React from "react"
import axios from "axios"
import Popup from "reactjs-popup";
import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {observer} from "mobx-react"
import MesureStore from '../MesureStore'

@observer
class NavisPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: "",
            open: false,
            source: ""
        };
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.refuseData = this.refuseData.bind(this);
        this.validateData = this.validateData.bind(this);
    }

    openModal() {
        this.setState({open: true});
    }

    closeModal() {
        this.setState({open: false});
    }

    validateData(e) {
        e.preventDefault()
        axios.post("/orthoptistes/navis/validateData", {
            id: this.state.id,
            appointement: this.props.appointement.id
        }).then((data) => {
            this.setState({
                open: false,
                id: ""
            })
            MesureStore.hasImages()
        })

    }

    refuseData(e) {
        e.preventDefault()
        this.setState({
            id: "",
            open: false
        })
    }

    componentDidMount() {
        var that = this;
        if (this.props.orthoptiste.navis_id != undefined || this.props.orthoptiste.navis_id != "") {
            App.navis = App.cable.subscriptions.create({channel: 'NavisChannel', id: this.props.orthoptiste.navis_id}, {
                received: function (data) {
                    data = data.data
                    console.log("receiving", data)
                    // document.location.reload(true);
                    if (data.type == "pending") {
                        that.setState({open: true, id: data.id, source: data.from})
                    }
                    if (data.type == "receive") {
                        if (data.reload == "mesures") {
                            MesureStore.refresh()
                        } else {
                            toast.success("Authorisations d'importation accordées", {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true
                            });
                            this.props.updateFO()
                        }
                    }
                }
            });
        }
    }

    componentWillUnmount() {
        if (this.props.orthoptiste.navis_id != undefined || this.props.orthoptiste.navis_id != "") {
            try {
                App.navis.unsubscribe()
            } catch {
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <Popup modal closeOnDocumentClick open={this.state.open} onClose={this.closeModal}>
                    <div className="card col s12" style={{backgroundColor: "white"}}>
                        <h1 className="center">Réception d'images</h1>
                        <p className="center">Une demande d'envoi de données depuis un(e) {this.state.source} a été
                            réceptionnée, faut il l'importer dans ce dossier ? </p>
                        <div className="container">
                            <div className="row">
                                <div className="col s6" style={{display: "flex", justifyContent: "center"}}>
                                    <a onClick={this.refuseData} className="btn red white-text"><i
                                        className="material-icons left">delete_sweep</i>Refuser</a>
                                </div>
                                <div className="col s6" style={{display: "flex", justifyContent: "center"}}>
                                    <a onClick={this.validateData} className="btn green white-text"><i
                                        className="material-icons left">done</i>Valider</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Popup>
            </React.Fragment>
        )

    }

}

export default NavisPopup
