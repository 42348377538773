import React from "react";

function Anamnesis({appointement}) {
    return (
        <div>
            <div style={{marginTop: "2vh", textAlign: 'center'}}>
                {appointement.comment === "" ? (
                    <div style={{marginBottom: '100px', fontStyle: "italic"}}>
                        <span>Pas d'anamnèse</span>
                    </div>
                ) : (
                    <div style={{height: "140px", overflowY: 'scroll', marginBottom: '40px'}}>
                        <span>{appointement.comment}</span>
                    </div>
                )}
            </div>
            <div style={{textAlign: 'center', marginTop: "30px"}}>
                {appointement.diabete === true || appointement.glaucome_familiaux === true || appointement.cortisone === true ?
                    (<span style={{color: "red", fontSize: "2em"}}>Pathologie !</span>)
                    :
                    (<span style={{fontStyle: "italic"}}>Aucune pathologie detectée.</span>)}
            </div>
        </div>
    )
}

export default Anamnesis;