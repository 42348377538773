import React, {useEffect} from 'react';
import NewConversationModal from "./NewConversationModal";
import {useNavigate} from "react-router-dom";
import MessengerStore from "../MessengerStore";
import axios from "axios";
import {toast} from "react-toastify";

const NewConversationButton = () => {
    const id = "new-conversation-modal";
    const nav = useNavigate();
    let csrfToken = document.querySelector("meta[name='csrf-token']").content
    axios.defaults.headers.common['X-CSRF-Token'] = csrfToken

    useEffect(() => {
        if(!MessengerStore.currentConversationId) return
        nav(`/${MessengerStore.currentConversationId ?? ""}`)
    }, [MessengerStore.currentConversationId]);


    useEffect(() => {
        const elems = document.getElementById(id);
        const instance = M.Modal.init(elems, {});
        return () => instance.destroy();
    }, []);

    const closeModal = () => {
        const elems = document.getElementById(id);
        const instance = M.Modal.getInstance(elems, {});
        instance?.close();
    }

    const newConversation = (selectedUserId) => {
        axios.put("/messenger/conversations/new", {user_id: selectedUserId}).then((response) => {
            MessengerStore.newConversation(response.data.conversation)
            closeModal()
        }).catch((error) => {
            if (error.response.status !== 406) {
                toast.error("Impossible de créer la conversation")
            }
            closeModal()
        })
    }


    return <>
        <a className="btn-small blue darken-2 z-depth-0 nav-action-btn modal-trigger"
           data-target={id}>
            <i className="material-icons">add</i>
        </a>
        <div className="modal" id={id}>
            <NewConversationModal onValidation={newConversation} onCancel={closeModal}/>
        </div>
    </>
}

export default NewConversationButton;