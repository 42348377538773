import React, {Component} from 'react';
import ShowConversation from "./ShowConversation"
import SendMessage from "./SendMessage"
import Popup from "./Popup"
import MessagesStore from "./MessagesStore";

export default class Ophtalmologiste extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        MessagesStore.loadMessages(this.props.orthoptiste_id, this.props.appointement_id)
        MessagesStore.messagesRead(this.props.orthoptiste_id, this.props.appointement_id)
    }

    render() {
        return (
            <div>
                <Popup target={this.props.orthoptiste_id}/>
                <SendMessage type="ophtalmologiste" appointement_id={this.props.appointement_id}/>
                <ShowConversation target="ophtalmologiste" target_id={this.props.orthoptiste_id}
                                  appointement_id={this.props.appointement_id}/>
            </div>
        );
    }
}
