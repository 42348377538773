import React from 'react';
import PaiementsErrors from "./PaiementsErrors";

function PaiementErrorsConsole() {
    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            <h3 className="valign-wrapper" style={{margin: 20}}><i className="material-icons left">error</i>Erreurs de paiements
            </h3>
            <div className="divider"/>
            <div style={{ margin: 20}}>
                <PaiementsErrors/>
            </div>
        </div>
    );
}

export default PaiementErrorsConsole;