import React from "react"
import PropTypes from "prop-types"
import axios from "axios"
export default class AddExterne extends React.Component {
  constructor(props) {
    super(props);
    this.saveAgenda = this.saveAgenda.bind(this)
    this.submit = this.submit.bind(this)
    this.state = {
      clicked: false,
      data: [],
      loading: true,
      done: false,
      email: "",
      pass: "",
      nom: "",
      cabinet_id: this.props.cabinet_id
    };
  }
  saveAgenda(e){
    e.preventDefault()
    let id=e.target.id
    let cabinet = this.state.data[id]
  }
  componentDidMount(){
    M.Modal._count = 0;

    var elems = document.querySelectorAll('.modal');
    var instances = M.Modal.init(elems, {});
  }
  submit(e){
    e.preventDefault()
    axios.post("/admin/externes/create",{
      cabinet: this.state.cabinet_id,
      externe: {
        email: this.state.email,
        password: this.state.pass,
        fullname: this.state.nom
      }}).then((data)=>{
      location.reload()
    })
  }
  render () {
    return (
      <React.Fragment>
        <div>
            <a onClick={this.loadData} className="waves-effect waves-light btn modal-trigger" href="#modal1">Ajouter un accès lecture seule</a>
            <div id="modal1" className="modal">
              <div className="modal-content">
                <h4 className="center">Veuillez remplir le formulaire</h4>
                <form action="" onSubmit={this.submit}>
                  <div className="container">
                    <div className="input-field">
                        <input type="email" value={this.state.email} onChange={(e)=>this.setState({email: e.target.value})}/>
                        <label htmlFor="email">Email</label>
                    </div>
                    <div className="input-field">
                        <input type="text" id="prenom" value={this.state.nom} onChange={(e)=>this.setState({nom: e.target.value})}/>
                        <label htmlFor="prenom">Nom et prénom</label>
                    </div>
                    <div className="input-field">
                        <input type="text" id="pass" value={this.state.pass} onChange={(e)=>this.setState({pass: e.target.value})}/>
                        <label htmlFor="pass">Mot de passe</label>
                    </div>
                      <button className="btn waves-effect waves-light" type="submit" name="action">Valider
                      <i className="material-icons right">send</i>
                      </button>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <a href="#!" className="modal-close waves-effect waves-green btn-flat">Annuler</a>
              </div>
            </div>
        </div>
      </React.Fragment>
    );
  }
}

