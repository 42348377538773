import React, {useEffect, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import socket from "../../../../Shared/socket";


export default function SocketsDebugger() {
    const [events, setEvents] = useState([])
    const [orthoptists, setOrthoptists] = useState([])

    useEffect(() => {
        loadOrthoptists();
    }, [])
    const loadOrthoptists = () => {
        axios.get("/admin/console/devs/sockets/orthoptistes_bridges").then(response => {
            setOrthoptists(response.data)
        })
    }
    const subscribe = (e) => {
        setEvents([])
        socket.offAny()
        const index = e.target.value;
        const orthoptist = orthoptists[index];
        socket.emit('machineID', {machine: orthoptist.bridge.uid})
        socket.onAny((...args) => {
            if (!orthoptist.bridge.uid) return
            setEvents(prevEvents => {
                if (prevEvents.length > 50) prevEvents.pop()
                return [JSON.stringify(args), ...prevEvents]
            });
        })
        toast.success(`Connecté à la socket de ${orthoptist.nom} Bridge uid: ${orthoptist.bridge.uid.substring(0, 14)}...`)

    }

    const choose_bridge = () => {
        return (
            <div className="input-field col s12">
                <select className={"browser-default"} defaultValue="" onChange={subscribe}>
                    <option value="" disabled>Choose the bridge</option>
                    {orthoptists.map((orthoptist, index) => {
                        return <option key={index}
                                       value={index}>{orthoptist.prenom} {orthoptist.nom} | {orthoptist.bridge.uid} </option>
                    })}
                </select>
            </div>
        )
    }
    return (
        <div>
            <h3 className="valign-wrapper center" style={{margin: 20}}><i className="material-icons left">analytics</i>Debug
                Sockets</h3>
            <div className="row">
                {choose_bridge()}
            </div>
            <div className="divider"/>
            <div style={{margin: 20}}>
                {events.map((event, index) => {
                    return (
                        <div key={index}>
                            <pre>[ {index} ] {event}</pre>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}