import React, {Component} from "react";
import axios from "axios";

const moment = require('moment');

export default class DifferentialSimulation extends Component {

    constructor(props) {
        super(props)
        this.handleDate = this.handleDate.bind(this)
        this.fetch = this.fetch.bind(this)
        this.state = {
            start_date: moment(),
            end_date: moment().add(1, "days"),
            bills: [],
            totalAPayerOrthoptiste: 0,
            totalPourTemeoo: 0,
            montantTotal: 0
        }
        this.fetch()
    }

    handleDate(e) {
        this.setState({[e.target.dataset.input]: moment(e.target.value, "YYYY-MM-DD")})

    }

    fetch() {
        axios.get("/admin/paiements/orthoptistes/bills/" + this.props.orthoptiste_id, {
            params: {
                start_date: this.state.start_date,
                end_date: this.state.end_date
            }
        }).then((data) => {
            this.setState({bills: data.data})
            let totalAPayerOrthoptiste = 0
            let totalPourTemeoo = 0
            data.data.map((bill) => {
                totalAPayerOrthoptiste += bill.bill.appt_ortho_to_pay
                totalPourTemeoo += bill.bill.appt_platform_earnings
            })
            const total = data.data.reduce((acc, appt) => {
                return acc + appt.actepossibles[0].prix
            }, 0)
            this.setState({
                totalPourTemeoo: totalPourTemeoo,
                totalAPayerOrthoptiste: totalAPayerOrthoptiste,
                montantTotal: total
            })
        })
    }

    render() {
        return (
            <div>
                <div className="row container">
                    <div className="col s4">
                        <input type="date" data-input={"start_date"} onChange={this.handleDate}
                               value={this.state.start_date.format("YYYY-MM-DD")}></input>
                    </div>
                    <div className="col s4">
                        <input type="date" data-input={"end_date"} onChange={this.handleDate}
                               value={this.state.end_date.format("YYYY-MM-DD")}></input>
                    </div>
                    <div className="col s3">
                        <a className="btn" onClick={(e) => {
                            e.preventDefault();
                            this.fetch()
                        }}> Valider </a>
                    </div>
                </div>
                <p> Attention, ceci ne tient pas compte de frais fixes </p>
                <ul>
                    <li>Total à payer orthoptiste : {this.state.totalAPayerOrthoptiste / 100.0} € (ceci est en euro)
                    </li>
                    <li>Total pour temeoo : {this.state.totalPourTemeoo / 100.0} € (ceci est en euro)</li>
                    <li>Total des actes : {this.state.montantTotal} € (ceci est en euro)</li>
                </ul>

                <ul>
                    {this.state.bills.map((appt) => {
                        return (<li>
                            <div className="row center">
                                <div className="col s4">
                                    A payer ortho {appt.bill.appt_ortho_to_pay / 100.0}€
                                </div>
                                <div className="col s4">
                                    Argent pour temeoo {appt.bill.appt_platform_earnings / 100.0}€
                                </div>
                                <div className="col s4">
                                    {appt.actepossibles[0].titre} {appt.actepossibles[0].prix}€
                                </div>
                            </div>
                        </li>)
                    })}
                </ul>
            </div>
        )
    }

}