import React from "react";
import EarningsFromOrthoptist from "./EarningsFromOrthoptist";
import {NavLink, Route, HashRouter as Router, Routes} from "react-router-dom";
import GeneralEarnings from "./GeneralEarnings";

const OphtalmologistMonitoring = ({orthoptists}) => {

    return <Router>
        <ul className="tabs tabs-fixed-width transparent">
            <li className="tab col s2">
                <NavLink to={"/"}>Revenus généraux</NavLink>
            </li>
            <li className="tab col s2">
                <NavLink to={"/detail"}>Détail</NavLink>
            </li>
        </ul>
        <Routes>
            <Route path='/' element={<GeneralEarnings/>}/>
            <Route path='/detail' element={<EarningsFromOrthoptist orthoptists={orthoptists}/>}/>
            <Route path='/detail/:id' element={<EarningsFromOrthoptist orthoptists={orthoptists}/>}/>
        </Routes>
    </Router>
}

export default OphtalmologistMonitoring;