import React from "react";
import RightsStore from "./RightsStore";
import Loader from "../../Shared/Loader";
import Error from "../../Shared/Error";
import RestrictedElement from "../../Shared/RestrictedElement";
import BorderedDisplay from "../../Shared/UiKit/BorderedDisplay";
import {UserPermissions, UserRights} from "../../Shared/Enums/Enums";
import {Observer} from "mobx-react";


const _ = require("lodash")
function PricingSettings({icon, title, tableRender, categories, createCategory, addAct}) {
    const render = () => {
        if (RightsStore.can_do(UserRights.BILLING, UserPermissions.READ_ONLY) === undefined) {
            return <div id="full-height"
                        style={{margin: 50}}>
                <Loader text=""/>
            </div>
        }
        if (!RightsStore.can_do(UserRights.BILLING, UserPermissions.READ_ONLY)) {
            return <div id="full-height"
                        style={{margin: 50}}>
                <Error errorText="Vous n'avez pas les droits pour accéder à cette page"/>
            </div>
        }
        if (!categories) return <div id="full-height"
                                     style={{margin: 50}}>
            <Loader text=""/>
        </div>

        return (
            <div id="full-height" style={{display: "flex", flexDirection: "column", overflowY: "scroll"}}>
                <h3 className="valign-wrapper" style={{margin: 20}}><i className="material-icons left">{icon}</i>{title}</h3>
                <div className="divider"/>
                <div style={{margin: 20}}>
                    <RestrictedElement field={UserRights.BILLING} permission={UserPermissions.WRITE_ONLY}>
                        <div className="row" style={{gap: 20}}>
                            <div className="col s12 l6">
                                <BorderedDisplay text="Créer catégorie">
                                    <div className="valign-wrapper" style={{gap: 10}}>
                                        <div className="input-field outlined" style={{width: "100%"}}>
                                            <input type="text" id="new_category_input"
                                                   placeholder="Nom de la catégorie"/>
                                        </div>
                                        <a className="btn blue darken-2 z-depth-0" onClick={createCategory}>
                                            Créer
                                        </a>
                                    </div>
                                </BorderedDisplay>
                            </div>
                            <div className="col s12 l6">
                                <BorderedDisplay text="Ajouter acte">
                                    <div className="valign-wrapper" style={{gap: 10}}>
                                        <div className="input-field outlined" style={{width: "100%"}}>
                                            <input type="text" id="select_act_autocomplete" className="autocomplete"
                                                   placeholder="Rechercher un acte"/>
                                        </div>
                                        <a className="btn blue darken-2 z-depth-0" onClick={addAct}>
                                            Ajouter
                                        </a>
                                    </div>
                                </BorderedDisplay>
                            </div>
                        </div>
                    </RestrictedElement>
                    <div style={{margin: 20, border: "thin solid lightgray", borderRadius: 20, padding: 10}}>
                        {tableRender}
                    </div>
                </div>
            </div>
        );
    }

    return <Observer>
        {() => render()}
    </Observer>


}

export default PricingSettings