import React, {useEffect, useState} from "react";
import axios from "axios";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import moment from "moment";
import InfiniteScroll from 'react-infinite-scroll-component';


export default function UserStatuses(){
    const [page, setPage] = useState(1);
    const [orthoptistes, setOrthoptistes] = useState([]);
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        loadData(page);
    }, []);
    const loadData = (page)=> {
        axios.get("/admin/console/bills/orthoptistes_statuses?page="+page).then((data)=>{
            setOrthoptistes(orthoptistes.concat(data.data))
            if(data.data.length === 0){
                setHasMore(false);
            }
        })
        setPage(page + 1);
    }
    const fetchMoreData = () => {
        loadData(page);
    }
    return (
        <div>
            <InfiniteScroll
                dataLength={orthoptistes.length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={<h4 className="center">Chargement...</h4>}
            >
                <table className="striped bordered">
                    <thead>
                    <tr>
                        <th> ID </th>
                        <th> Nom </th>
                        <th> Etat orthoptiste</th>
                        <th> Etat ophtalmologiste</th>
                        <th> Derniers paiements non réussis</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {orthoptistes.map((i, index) => (
                        <tr key={index}>
                            <td>{i.id}</td>
                            <td>{i.prenom} {i.nom}</td>
                            <td>{i.customer_id === null || i.customer_id=="" ? (
                                <span>
                                    <i className="material-icons red-text left">warning</i>orthoptiste non configuré
                                </span>
                            ) : (
                                <span>
                                    <i className="material-icons green-text left">done</i>orthoptiste configuré
                                </span>

                            )}</td>
                            <td>{i.ophtalmologiste !== undefined  && (i.ophtalmologiste.stripe_user_id === undefined || i.ophtalmologiste.stripe_user_id=="") ? (
                                <span>
                                    <i className="material-icons red-text left">warning</i>
                                    <b>[{i.ophtalmologiste.id}] {i.ophtalmologiste.prenom} {i.ophtalmologiste.nom} non config </b>
                                </span>
                            ) : (
                                <span>
                                    {i.ophtalmologiste === undefined ? (
                                        <span>
                                            <i className="material-icons red-text left">warning</i>

                                            <b className="red-text">Orthoptiste orphelin</b>
                                        </span>
                                    ) : (
                                        <span>
                                            <i className="material-icons green-text left">done</i>

                                            <b>{i.ophtalmologiste.prenom} {i.ophtalmologiste.nom} configuré</b>
                                        </span>
                                    )}
                                </span>
                            )}</td>

                            <td>
                                <ul>
                                    {i.paiements.map((p, index) => (
                                        <li key={index}>
                                                <span>
                                                    <i className="material-icons orange-text left">warning</i>
                                                    <b className="orange-text">{p.status}</b>  Paiement du {moment(p.created_at).format("DD-MM-YYYY")}  en attente pour {p.totalOrthoptiste / 100.0}€
                                                </span>
                                        </li>
                                    ))}
                                </ul>
                            </td>
                            <td><Link to={"/paiements/statuses/users/"+i.id }><i className="material-icons">info</i></Link></td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </InfiniteScroll>




        </div>
    )
}