import React, {useEffect} from "react";
import {isLoading, loading, RequestState} from "../Shared/StateHelper";
import StateButton from "../Shared/StateButton";
import moment from "moment/moment";
import BorderedDisplay from "../Shared/UiKit/BorderedDisplay";
import socket from "../Shared/socket";

function ADRIRightsButton(props) {
    const {bridge_uid, user_id, patient} = props;
    const [state, setState] = React.useState(RequestState.IDLE);
    const [rights, setRights] = React.useState({
        couv_obligatoire: "",
        couv_obligatoire_expiration: "",
        couv_complementaire: "",
        couv_complementaire_expiration: "",
        // MT = Médecin traitant
        mtCode: "",
    });
    const refState = React.useRef(state);

    useEffect(() => {
        refState.current = state;
    }, [state])

    const openSocket = (uid) => {
        socket.emit("machineID", {machine: uid});
    }

    const requestAdriRights = () => {
        if (isLoading(state)) return
        setState(loading(state))
        openSocket(bridge_uid);
        socket.emit("adriRightsRequest", {
            machine: bridge_uid,
            data: {
                patient_firstname: patient.firstname.toUpperCase(),
                patient_lastname: patient.lastname.toUpperCase(),
                patient_birthday: Helper.formatDate(patient.birthday, "DDMMYYYY"),
                patient_ssn: patient.ssn,
                patient_regime_code: patient.regime_code,
                patient_fund_code: patient.fund_code,
                patient_centre_code: patient.centre_code
            }
        })
        socket.on("adriRightsResponse", (resp) => handleResponse(resp))
        setTimeout(() => {
            if (isLoading(refState.current)) setState(RequestState.ERROR)
        }, 60 * 1000)
    }

    const handleResponse = (resp) => {
        if (resp.status === "success") {
            setState(RequestState.SUCCESS)
            setRights({
                couv_obligatoire: resp.data.sCouvAMO,
                couv_obligatoire_expiration: moment(resp.data.sDateFinAMO, "YYYYMMDD").format("DD/MM/YYYY").toString(),
                couv_complementaire: resp.data.sCouvAMC,
                couv_complementaire_expiration: moment(resp.data.sDateFinAMC, "YYYYMMDD").format("DD/MM/YYYY").toString(),
                // MT = Médecin traitant
                mtCode: resp.data.nCodeDeclarationMT,
            })
            socket.off("adriRightsResponse");
        } else {
            setState(RequestState.ERROR)
        }
    }

    const displayRights = () => {
        return (
            <div className="row center-align">
                <div className="col s6" style={{padding: "2vh 2vw"}}>
                    <div style={{borderRadius: "10px"}} className="grey lighten-3">
                        <h6 style={{margin: 0, padding: "1vh"}}>Couverture obligatoire</h6>
                        <h2 style={{margin: 0, padding: "1vh"}}>{rights.couv_obligatoire || "-"}</h2>
                    </div>
                </div>
                <div className="col s6" style={{padding: "2vh 2vw"}}>
                    <div style={{borderRadius: "10px"}} className="grey lighten-3">
                        <h6 style={{margin: 0, padding: "1vh"}}>Couverture complémentaire</h6>
                        <h2 style={{
                            margin: 0,
                            padding: "1vh"
                        }}>{rights.couv_complementaire || "-"}</h2>
                    </div>
                </div>
                <div className="col s6" style={{padding: "2vh 2vw"}}>
                    <div style={{borderRadius: "10px"}} className="grey lighten-3">
                        <h6 style={{margin: 0, padding: "1vh"}}>Expiration</h6>
                        <h2 style={{
                            margin: 0,
                            padding: "1vh"
                        }}>{Helper.formatDate(rights.couv_obligatoire_expiration) || "-"}</h2>
                    </div>
                </div>
                <div className="col s6" style={{padding: "2vh 2vw"}}>
                    <div style={{borderRadius: "10px"}} className="grey lighten-3">
                        <h6 style={{margin: 0, padding: "1vh"}}>Expiration</h6>
                        <h2 style={{
                            margin: 0,
                            padding: "1vh"
                        }}>{Helper.formatDate(rights.couv_complementaire_expiration) || "-"}</h2>
                    </div>
                </div>
                <div className="col s12" style={{padding: "2vh 2vw"}}>
                    <div style={{borderRadius: "10px"}} className="grey lighten-3">
                        <h6 style={{margin: 0, padding: "1vh"}}>Code médecin traitant</h6>
                        <h2 style={{
                            margin: 0,
                            padding: "1vh"
                        }}>{rights.mtCode || "-"}</h2>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <BorderedDisplay text="Droits ADRI" color="grey lighten-4">
            <div style={{display: "flex", flexDirection: "column"}}>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <StateButton centered text='Droits ADRI' color="blue darken-2" side="left" icon="rss_feed"
                                 state={state}
                                 onClick={requestAdriRights}/>
                </div>
                {rights ? (displayRights()) : (<div/>)}
            </div>
        </BorderedDisplay>

    );
}

export default ADRIRightsButton