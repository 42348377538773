import React from "react";
import AppointmentList from "./AppointmentList";

function TeletransmissionConsole({orthoptists}) {
    return (
        <div id="full-height" style={{display: "flex", flexDirection: "column", overflowY: "scroll"}}>
            <h3 className="valign-wrapper" style={{margin: 20}}><i className="material-icons left">description</i>Edition de feuilles de soin
            </h3>
            <div className="divider"/>
            <div style={{marginLeft: 2}}>
                <AppointmentList orthoptists={orthoptists}/>
            </div>
            {/*<div className="divider"/>*/}
            {/*<h3 className="valign-wrapper" style={{marginLeft: 20}}><i className="material-icons left">visibility</i>Orthoptistes</h3>*/}
            {/*<div className="divider"/>*/}
        </div>
    );
}

export default TeletransmissionConsole