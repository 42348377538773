import React, {useEffect} from 'react';
import CarteVitaleButton from "./CarteVitaleButton";
import ADRIButton from "./ADRIButton";
import CVStore from "../../../../../CarteVitale/CVStore";
import MesureStore from "../../../MesureStore";
import BridgeStoreV2 from "../../../../../BridgeV2/BridgeStoreV2";
import {observer} from "mobx-react";
import FSEButton from "./FSEButton";

const TeletransmissionController = observer(({disabled, retracted}) => {

    useEffect(() => {
        CVStore.appointment_id = MesureStore.appointement_id
        CVStore.patient = MesureStore.patient
        mount()

        return () => unmount()
    }, []);

    const mount = () => {
        CVStore.connect(BridgeStoreV2.uid)
        CVStore.subscribe()
    }

    const unmount = () => {
        CVStore.unsubscribe()
        CVStore.disconnect()
    }


    if (disabled) return null
    return <div style={{display: "flex", flexDirection: "column", gap: 5, justifyContent: "center", paddingInline: 5}}>
            <CarteVitaleButton retracted={retracted}/>
            <ADRIButton retracted={retracted}/>
            <FSEButton retracted={retracted}/>
        </div>
});

TeletransmissionController.defaultProps = {
    disabled: false,
    retracted: false
};

export default TeletransmissionController;