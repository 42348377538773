import React, { Component } from 'react';
import {observer} from "mobx-react"
import BridgeStore from "../BridgeStore"
import Status from "../Dashboard/ListMachines/Status";

@observer
export default class Machines extends Component {
  constructor(props) {
    super(props);
    this.loadMachines = this.loadMachines.bind(this)
    this.state = {
      machines: [],
      active_usb: [],
      samba_machines:[],
      local_ip:null,
      url: {}
    };
    this.loadMachines()
  }
  loadMachines(){
    BridgeStore.get_url()
    BridgeStore.updateMachines()
    setTimeout(()=>{
      this.setState({
        active_usb: BridgeStore.connected_usbs,
        machines: BridgeStore.machines,
        samba_machines: BridgeStore.samba_machines,
        local_ip: BridgeStore.local_ip,
        url : BridgeStore.url
      })
    },3000);
  }


  render() {
    return (
        <div>
          <div className="container">
            {this.state.machines.length == 0 && this.state.samba_machines == 0? (
                <div>
                  <h1 className="center"> Aucune machine enregistré </h1>
                </div>
            ): (
              <div className="row">
                <h1 className="center"> Machines déjà enregistrées </h1>
                {this.state.machines.map((machine)=>(
                  <div className="col s6" key={machine["hwid"]}>
                    <div className="card white">
                      <div className="card-content dark-text">
                        <p>Marque : {machine["marque"]}  <br/></p>
                        <p>Modèle : {machine["modele"]}</p>
                        <p>Connexion : Usb</p>
                        <p>Adresse ip du pi : 192.168.9.1</p>
                        <p></p>
                        <p><br/></p>
                        <p><br/></p>

                        <Status hwid={machine["hwid"]}/>

                      </div>
                      <div className="card-action">
                        <a onClick={(e)=>BridgeStore.deleteMachine(machine["hwid"])}>Retirer cette machine</a>
                      </div>
                    </div>
                  </div>
              ))}
              {this.state.samba_machines.map((machine)=>(
                  <div className="col s6" key={machine["id"]}>
                    <div className="card white registred">
                      <div className="card-content black-text">
                        <p>Marque : {machine["marque"]}  <br/></p>
                        <p>Modele : {machine["modele"]}</p>
                        <p>Nom du dossier : {machine["id"]}</p>
                        <p>Connexion : Dossier partagé</p>
                        <p>Adresse ip du pi : 192.168.9.1</p>
                        <p>Adresse ip passerelle : {this.state.local_ip}</p>
                        <p><a href={this.state.url[machine["modele"]]}>Tuto vidéo</a></p>
                        <b><h2 className="center bridge-statut bridge-Disponible">Actuellement connécté</h2></b>
                        <p></p>
                      </div>
                      <div className="card-action">
                        <a onClick={(e)=>BridgeStore.deleteMachineSamba(machine["id"])}>Retirer cette machine</a>
                      </div>
                    </div>
                  </div>
              ))}
            </div>)}

          </div>

      </div>
    );
  }
}
