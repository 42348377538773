import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import React from "react";
import {Bar} from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);


function BarChart(props) {
    const data = props.data

    const options = {
        responsive: true,
        barThickness: "10",
        borderRadius: "10",
        height: "100%",
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false
                }
            },
            y: {
                grid: {
                    display: false
                }
            }
        },
        plugins: {
            legend: {
                display: props.withLegend,
                position: 'top',
            },
            title: {
                display: true,
                text: props.legend,
            },
        },
    }

    return (
        <div style={{height: "100%"}}>
            <Bar data={data} options={options} style={{height: "100%"}}/>
            <h2 className="center-align grey-text text-darken-2 "
                style={{fontWeight: "bold"}}>{props.title}</h2>
        </div>
    )
}

export default BarChart;
