import socket from "../../../../../../Shared/socket";
import {displayMedocError} from "../../../../../../Shared/Enums/ErrorCodes";
import moment from "moment";

const useADRIRightsRequest = () => {

    const sendADRIRightsRequest = (uid, patient) => {
        return new Promise((resolve, reject) => {
            if (!uid) return reject("No UID provided")
            socket.emit("adriRightsRequest", {
                machine: uid,
                data: {
                    patient_firstname: patient.prenom.toUpperCase(),
                    patient_lastname: patient.nom.toUpperCase(),
                    patient_birthday: Helper.formatDate(patient.birthday, "DDMMYYYY"),
                    patient_ssn: patient.num_carte_vitale,
                    patient_regime_code: patient.regime_code,
                    patient_fund_code: patient.fund_code,
                    patient_centre_code: patient.centre_code
                }
            })

            const timeout = setTimeout(() => {
                reject("Timeout")
            }, 60 * 1000)

            const responseHandler = (resp) => {
                if (resp.status === "success") {
                    console.debug("Received ADRI rights data: ", resp.data);
                    const rights = {
                        couv_obligatoire: resp.data.sCouvAMO,
                        couv_obligatoire_expiration: moment(resp.data.sDateFinAMO, "YYYYMMDD").format("DD/MM/YYYY").toString(),
                        couv_complementaire: resp.data.sCouvAMC,
                        couv_complementaire_expiration: moment(resp.data.sDateFinAMC, "YYYYMMDD").format("DD/MM/YYYY").toString(),
                        // MT = Médecin traitant
                        mtCode: resp.data.nCodeDeclarationMT
                    }
                    resolve(rights)
                    socket.off("adriRightsResponse", responseHandler);
                } else {
                    console.debug("Error on ADRI rights retrieving with error: ", displayMedocError(resp.errorCode))
                    reject(resp)
                    clearTimeout(timeout)
                }
            }
            socket.on("adriRightsResponse", responseHandler);
        })
    }

    return {sendADRIRightsRequest}
}

export default useADRIRightsRequest;