import axios from "axios";

class Preferences  {
    async get(entries = undefined) {
        let preferences = undefined
        await axios.get("/users/preferences", {params: {entries: entries}}).then((response) => {
            preferences = response.data.preferences;
        });
        return preferences;
    }

    async set(preferences) {
        let res = undefined
        await axios.patch("/users/preferences", preferences).then((response) => {
            res = response.data.preferences;
        });
        return res;
    }
}

const preferences = new Preferences();
export default preferences;