import React from "react"
import axios from "axios"
import {observer} from "mobx-react"
import {toast} from 'react-toastify';

import MesureStore from '../MesureStore'
import PrescriptionStore from "../../PrescriptionStore"


@observer
class Courriel extends React.Component {
  constructor(props) {
    super(props);
    this.handeWriting = this.handeWriting.bind(this)
    this.onEditorStateChange = this.onEditorStateChange.bind(this)
    this.sendEmail = this.sendEmail.bind(this)
    this.vider = this.vider.bind(this)
    this.formulePolitesse = this.formulePolitesse.bind(this)
    this.addPatientInformations = this.addPatientInformations.bind(this)
    this.state = {
      email: "",
      text: '',
      sujet: ""

    };
  }

  onEditorStateChange(editorState){
    this.setState({
    });
  }
  vider(e){
    e.preventDefault()
    this.setState({text: ""})
  }
  handeWriting(e){
    this.setState({text: e.target.value})
  }
  addPatientInformations(e){
    e.preventDefault()
    this.setState({
      text: "\n Je vous contacte au sujet de "+MesureStore.patient.prenom+" "+MesureStore.patient.nom+", né le "+ MesureStore.patient.birthday+" \n Contactable au téléphone: "+MesureStore.patient.telephone+" \n ou par email : "+MesureStore.patient.email+"\n" +this.state.text
    })
  }
  sendEmail(e){
    e.preventDefault()
    axios.post("/api/sendEmail",this.state).then((data)=>{
    toast.success('🦄 Message envoyé', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
    });
    })
  }
  formulePolitesse(e){
    e.preventDefault()
    if(MesureStore.orthoptiste.prenom != undefined){
      this.setState({
        text: this.state.text += "<p> Cordialement "+MesureStore.orthoptiste.prenom+ " "+MesureStore.orthoptiste.nom+"</p>"
      })
    }

  }
  componentDidMount(){
    this.setState({text: PrescriptionStore.prescriptionText})
    // this.formulePolitesse()
  }
  render () {
        const { editorState } = this.state;

    return (
      <React.Fragment>
      <div className="container">
      <h1 className="center">Courriel</h1>
        <div className="row">
          <div className="col s9">
            <form action="">
              <div className="">
                <div className="row">
                  <div className="col s6 input-field">
                    <input type="email"  id="email" value={this.state.email} onChange={(e)=>this.setState({email: e.target.value})} />
                    <label htmlFor="email"> Email destinataire</label>
                  </div>
                  <div className="col s6">
                    <select defaultValue="" onChange={(e)=>this.setState({email: e.target.value})} className="browser-default" id="">
                      <option value="" disabled>Email favoris</option>
                      <option value={MesureStore.patient.email}>{MesureStore.patient.email}</option>
                    </select>
                  </div>
                </div>
                 <div className="input-field">
                  <input type="text" id="sujet" value={this.state.sujet} onChange={(e)=>this.setState({sujet: e.target.value})} />
                  <label htmlFor="sujet"> Sujet</label>
                </div>
                <div className="input-field">
                  <div className="row">
                    <div className="input-field col s12">
                      <textarea id="textarea1" rows="50" cols="50" style={{height: "60vh"}} onChange={this.handeWriting} value={this.state.text} className=""></textarea>
                      <label htmlFor="textarea1"></label>
                      </div>
                    </div>
                </div>
              </div>

            </form>
          </div>
          <div className="col s3">
          <p className=""><a onClick={this.sendEmail} className="waves-effect blue waves-light btn"><i className="material-icons left">send</i>Envoyer</a></p>
          <p className=""><a onClick={this.vider} className="waves-effect red waves-light btn"><i className="material-icons left">delete</i>Vider</a></p>
          <p className=""><a onClick={this.addPatientInformations} className="waves-effect green waves-light btn"><i className="material-icons left">account_circle</i>Prof. Patient</a></p>
          {/*<p className=""><a onClick={this.formulePolitesse} className="waves-effect green waves-light btn"><i className="material-icons left">gesture</i>Signature</a></p> */}
          </div>
        </div>
      </div>
      </React.Fragment>
    );
  }
}

export default Courriel
