import React, {Component} from "react";
import DatePicker from "./DatePicker";

export default class EditButton extends Component {
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this)
        this.onChange = this.onChange.bind(this)
        this.state = {
            editing: false,
            txtEdit: ""
        }
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside(e) {
        if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(e.target)) {
            this.setState({editing: false})
            this.props.onValidation(this.state.txtEdit)
        }
    }

    onClick(e) {
        this.setState({
            editing: true,
            txtEdit: this.props.text ?? ""
        })
    }

    onChange(e) {
        if (this.props.filter(e)) {
            this.setState({
                txtEdit: e.target.value
            })
        }
    }

    renderTextInput = () => {
        return <div className="input-field outlined">
            <input value={this.state.txtEdit} type="text" className="validate center"
                   onFocus={(e) => e.target.select()}
                   style={{
                       minWidth: this.props.minWidth,
                       width: this.state.txtEdit.length * 10 || 100,
                       marginTop: "0px",
                       marginBottom: "0px"
                   }}
                   ref={this.wrapperRef}
                   autoFocus={true}
                   onChange={(e) => {
                       if (this.props.filter(e)) this.setState({txtEdit: e.target.value})
                   }}
                   onKeyDown={(e) => {
                       if (e.key === "Enter") {
                           this.setState({editing: false})
                           this.props.onValidation(this.state.txtEdit)
                       }
                   }}
            />
        </div>
    }

    renderSelect = () => {
        return <div>
            <select className="browser-default" value={this.state.txtEdit} onChange={this.onChange}
                    ref={this.wrapperRef}>
                {this.props.options.map((option, i) => {
                    return <option key={i} value={option}>{option}</option>
                })}
            </select>
        </div>
    }

    renderDatePicker = () => {
        return <div>
            <input type="date"
                   value={this.state.txtEdit}
                   onChange={this.onChange}
                   ref={this.wrapperRef}
            />
        </div>
    }

    renderInput = () => {
        switch (this.props.type) {
            case "select":
                return this.renderSelect()
            case "date":
                return this.renderDatePicker()
            default:
                return this.renderTextInput()
        }
    }

    render() {
        if (!this.state.editing && !this.props.children) {
            if (this.props.button) return React.cloneElement(
                this.props.button,
                Object.assign({}, this.props.button.props, {onClick: this.onClick})
            )
            return (
                <a className={this.props.flat ? `${!this.props.disabled || "disabled"} black-text btn-flat`
                    :
                    `${!this.props.disabled || "disabled"} ${this.props.color} btn ${this.props.textColor}-text z-depth-0`}
                   style={{minWidth: this.props.minWidth}}
                   onClick={this.onClick}>{this.props.text}{this.props.suffix}</a>
            )
        } else if (!this.state.editing && this.props.children) {
            return React.Children.map(this.props.children, child => {
                if (React.isValidElement(child)) {
                    return React.cloneElement(child, Object.assign({}, child.props, {
                        onClick: this.onClick,
                        style: {
                            ...child.props.style,
                            cursor: "pointer",
                            border: "thin solid gray",
                            borderRadius: "5px",
                            padding: "5px",
                            backgroundColor: "white"
                        }
                    }));
                }
            })
        } else {
            return this.renderInput()
        }
    }
}

EditButton.defaultProps = {
    color: "blue darken-2",
    textColor: "white",
    minWidth: "80px",
    suffix: "",
    type: "input",
    options: [],
    flat: false,
    disabled: false,
    button: null,
    filter: (e) => {
        return true
    },
}