import React, {useEffect, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import moment from "moment";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Montants',
    },
  },
};

export const optionsAlerts = {
  plugins: {
    title: {
      display: true,
      text: 'Proportion état des consultations',
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};


export default function PaiementsStatistics(){
    const [data, setData] = useState([]);
    const [alerts, setAlerts] = useState([]);
    const [orthoptistes, setOrthoptistes] = useState([]);



    const loadAlerts = () => {
        axios.get("/admin/console/summary/alerts").then((data)=>{
            data = data.data;
            let result = [];
            for(const [ortho_id,orthoptist] of Object.entries(data.orthoptistes)){
                result = [...result, {
                    id: ortho_id,
                    orthoptiste: orthoptist[0].nom + " " + orthoptist[0].prenom,
                    chart: {
                        labels: data.dates,
                        datasets: [
                            {
                                label: 'Total consultations',
                                data: data.dates.map((date) => data.total_consults[date+"_"+ortho_id] ? data.total_consults[date+"_"+ortho_id] : 0),
                                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                            },
                            {
                                label: 'Total consultations alertes signalées',
                                data: data.dates.map((date) => data.total_alerts_by_orthoptist[date+"_"+ortho_id] ? data.total_alerts_by_orthoptist[date+"_"+ortho_id] : 0),
                                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                            },
                            {
                                label: 'Total consultations alertes effectives',
                                data: data.dates.map((date) => data.total_alerts[date+"_"+ortho_id] ? data.total_alerts[date+"_"+ortho_id] : 0),
                                backgroundColor: 'rgba(75, 23, 192, 0.5)',
                            }
                        ],
                    },
                }]
            }
            setAlerts(result)
        })
    }
    const loadPaiements = () => {
        axios.get("/admin/console/summary/paiements").then((data)=>{
            data = data.data.data
            let output = []
            let entries = Object.entries(data)
            for(const key of entries){
                let paiements_orthoptiste = key[1]
                output= [...output, {
                    id: paiements_orthoptiste[0].orthoptiste_id,
                    orthoptiste: paiements_orthoptiste[0].nom + " "+ paiements_orthoptiste[0].prenom,
                    chart: {
                      labels: paiements_orthoptiste.map((paiement) => moment(paiement.from).format("MM/YYYY")),
                      datasets: [
                        {
                          label: 'Montant payé par l\'orthoptiste',
                          data: paiements_orthoptiste.map((paiement) => paiement.totalOrthoptiste/100.0),
                          backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        },
                        {
                          label: "montant temeoo",
                          data: paiements_orthoptiste.map((paiement) => paiement.totalPlateforme/100.0),
                          backgroundColor: 'rgba(53, 162, 235, 0.5)',
                        }
                        ],
                    },
                }];
            }
            setData(output)
        })
            .catch(
            (err) => {
                toast.error("Impossible de charger les paiements")
            }
        )
    }

    return (
        <div>
            <div className="row">
                <div className="col s3 offset-s3">
                    <a className="btn" onClick={(e)=>{
                        e.preventDefault();
                        loadAlerts();
                        setData([]);
                        return;
                    }
                    }>Statistiques alertes</a>
                </div>
                <div className="col s3">
                    <a className="btn" onClick={(e)=>{
                        e.preventDefault();
                        loadPaiements();
                        setAlerts([]);
                        return;
                    }}>Statistiques paiements</a>
                </div>
            </div>
            {data.map((line) => {
                return (
                    <div key={line.id}>
                        <h1>{line.orthoptiste}</h1>
                        <Bar data={line.chart} options={options} />
                    </div>
                )
            })}
            {alerts.map((line) => {
                return (
                    <div key={line.id}>
                        <h1>{line.orthoptiste}</h1>
                        <Bar data={line.chart} options={optionsAlerts} />
                    </div>
                )
            })}

        </div>
    )
}