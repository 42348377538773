
export default [{label: "5-MONONITRATE D'ISOSORBIDE ETHYPHARM LP"},
{label: "A"},
{label: "A B I"},
{label: "A.C.T.H. BOIRON"},
{label: "A.D.N. BOIRON"},
{label: "A.R.N. BOIRON"},
{label: "ABACAVIR ARROW"},
{label: "ABACAVIR DEXTREG"},
{label: "ABACAVIR SANDOZ"},
{label: "ABACAVIR VIATRIS"},
{label: "ABACAVIR/LAMIVUDINE ACCORD"},
{label: "ABACAVIR/LAMIVUDINE ARROW"},
{label: "ABACAVIR/LAMIVUDINE BIOGARAN"},
{label: "ABACAVIR/LAMIVUDINE DEXTREG"},
{label: "ABACAVIR/LAMIVUDINE EG"},
{label: "ABACAVIR/LAMIVUDINE MYLAN"},
{label: "ABACAVIR/LAMIVUDINE PHAROS"},
{label: "ABACAVIR/LAMIVUDINE SANDOZ"},
{label: "ABACAVIR/LAMIVUDINE TEVA"},
{label: "ABACAVIR/LAMIVUDINE VIATRIS"},
{label: "ABACAVIR/LAMIVUDINE ZENTIVA"},
{label: "ABACAVIR/LAMIVUDINE/ZIDOVUDINE VIATRIS"},
{label: "ABASAGLAR"},
{label: "ABBOTICINE"},
{label: "ABCYS"},
{label: "ABECMA"},
{label: "ABELCET"},
{label: "ABELITE"},
{label: "ABELMOSCHUS BOIRON"},
{label: "ABENOX"},
{label: "ABEREL"},
{label: "ABEVMY"},
{label: "ABIES CANADENSIS BOIRON"},
{label: "ABIES NIGRA BOIRON"},
{label: "ABIES PECTINATA BOIRON"},
{label: "ABIES PECTINATA LEHNING"},
{label: "ABIES PECTINATA WELEDA"},
{label: "ABILIFY"},
{label: "ABILIFY MAINTENA"},
{label: "ABIRATERONE ACCORD"},
{label: "ABIRATERONE ACETATE ZYDUS"},
{label: "ABIRATERONE AET"},
{label: "ABIRATERONE ARROW"},
{label: "ABIRATERONE BIOGARAN"},
{label: "ABIRATERONE CIPLA"},
{label: "ABIRATERONE CRISTERS"},
{label: "ABIRATERONE EG"},
{label: "ABIRATERONE EVOLUGEN"},
{label: "ABIRATERONE KRKA"},
{label: "ABIRATERONE MYLAN"},
{label: "ABIRATERONE QILU"},
{label: "ABIRATERONE REDDY PHARMA"},
{label: "ABIRATERONE SANDOZ"},
{label: "ABIRATERONE SUN"},
{label: "ABIRATERONE SYNTHON"},
{label: "ABIRATERONE TEVA"},
{label: "ABIRATERONE ZENTIVA"},
{label: "ABRAXANE"},
{label: "ABROTANUM BOIRON"},
{label: "ABROTANUM LEHNING"},
{label: "ABROTANUM WELEDA"},
{label: "ABSEAMED"},
{label: "ABSINTHIUM BOIRON"},
{label: "ABSINTHIUM LEHNING"},
{label: "ABSTRAL"},
{label: "ABUFENE"},
{label: "ACADIONE"},
{label: "ACAL"},
{label: "ACALYPHA INDICA BOIRON"},
{label: "ACAMPROSATE BIOGARAN"},
{label: "ACAMPROSATE REF"},
{label: "ACAMPROSATE SET"},
{label: "ACAMPROSATE VIATRIS"},
{label: "ACARBOSE ARROW"},
{label: "ACARBOSE ARROW LAB"},
{label: "ACARBOSE BAYER"},
{label: "ACARBOSE BIOGARAN"},
{label: "ACARBOSE EG"},
{label: "ACARBOSE SANDOZ"},
{label: "ACARBOSE VIATRIS"},
{label: "ACARBOSE ZENTIVA"},
{label: "ACARIZAX"},
{label: "ACCOFIL"},
{label: "ACCUSOL"},
{label: "ACEBUTOLOL ALMUS"},
{label: "ACEBUTOLOL ALTER"},
{label: "ACEBUTOLOL APOTEX"},
{label: "ACEBUTOLOL APOTEX FRANCE"},
{label: "ACEBUTOLOL ARROW"},
{label: "ACEBUTOLOL ARROW LAB"},
{label: "ACEBUTOLOL BIOGALENIQUE"},
{label: "ACEBUTOLOL BIOGARAN"},
{label: "ACEBUTOLOL CRISTERS"},
{label: "ACEBUTOLOL EG"},
{label: "ACEBUTOLOL ENIREX"},
{label: "ACEBUTOLOL G GAM"},
{label: "ACEBUTOLOL QUALIMED"},
{label: "ACEBUTOLOL RANBAXY"},
{label: "ACEBUTOLOL RATIOPHARM"},
{label: "ACEBUTOLOL REF"},
{label: "ACEBUTOLOL SANDOZ"},
{label: "ACEBUTOLOL TEVA"},
{label: "ACEBUTOLOL THERAPLIX"},
{label: "ACEBUTOLOL VIATRIS"},
{label: "ACEBUTOLOL ZENTIVA"},
{label: "ACEBUTOLOL ZENTIVA LP"},
{label: "ACEBUTOLOL ZYDUS"},
{label: "ACECLOFENAC ACCORD"},
{label: "ACECLOFENAC BIOGARAN"},
{label: "ACECLOFENAC EG"},
{label: "ACECLOFENAC EG LABO"},
{label: "ACECLOFENAC MYLAN PHARMA"},
{label: "ACECLOFENAC QUALIHEALTH"},
{label: "ACECLOFENAC QUALIMED"},
{label: "ACECLOFENAC VIATRIS"},
{label: "ACETANILIDUM BOIRON"},
{label: "ACETATE DE CYPROTERONE ARROW"},
{label: "ACETATE DE CYPROTERONE EG"},
{label: "ACETATE DE CYPROTERONE GNR"},
{label: "ACETATE DE CYPROTERONE HAREFIELD PHARMA ASSOCIATES"},
{label: "ACETATE DE CYPROTERONE REGIOMEDICA"},
{label: "ACETATE DE CYPROTERONE SANDOZ"},
{label: "ACETATE DE CYPROTERONE TEVA"},
{label: "ACETATE DE CYPROTERONE/ETHINYLESTRADIOL HAREFIELD"},
{label: "ACETATE DE CYPROTERONE/ETHINYLESTRADIOL HAREFIELD PHARMA ASSOCIATES"},
{label: "ACETATE DE FLECAINIDE MEDA PHARMA L.P."},
{label: "ACETATE DE FLECAÏNIDE MEDA PHARMA L.P."},
{label: "ACETATE DE MEDROXYPROGESTERONE UNION INTERPHARMACEUTIQUE ATLANTIQUE"},
{label: "ACETATE DE TERLIPRESSINE EVER PHARMA"},
{label: "ACETICUM ACIDUM BOIRON"},
{label: "ACETICUM ACIDUM LEHNING"},
{label: "ACETONUM BOIRON"},
{label: "ACETYLCISTEINE HEXAL SANTE"},
{label: "ACETYLCYSTEINE ABBOTT FRANCE"},
{label: "ACETYLCYSTEINE ARROW"},
{label: "ACETYLCYSTEINE BGR CONSEIL"},
{label: "ACETYLCYSTEINE BIOGARAN CONSEIL"},
{label: "ACETYLCYSTEINE BOUCHARA RECORDATI"},
{label: "ACETYLCYSTEINE EG"},
{label: "ACETYLCYSTEINE EG LABO CONSEIL"},
{label: "ACETYLCYSTEINE G GAM"},
{label: "ACETYLCYSTEINE HERMES"},
{label: "ACETYLCYSTEINE IBSA"},
{label: "ACETYLCYSTEINE MYLAN"},
{label: "ACETYLCYSTEINE PHARMEXON"},
{label: "ACETYLCYSTEINE SANDOZ"},
{label: "ACETYLCYSTEINE SANDOZ CONSEIL"},
{label: "ACETYLCYSTEINE TEVA"},
{label: "ACETYLCYSTEINE TEVA CONSEIL"},
{label: "ACETYLCYSTEINE UPSA"},
{label: "ACETYLCYSTEINE UPSAMEDICA"},
{label: "ACETYLCYSTEINE VIATRIS CONSEIL"},
{label: "ACETYLCYSTEINE ZENTIVA CONSEIL"},
{label: "ACETYLLEUCINE BIOGARAN"},
{label: "ACETYLLEUCINE KELLER PHARMA"},
{label: "ACETYLLEUCINE TEVA"},
{label: "ACETYLLEUCINE VIATRIS"},
{label: "ACETYLLEUCINE ZENTIVA"},
{label: "ACIBANDROS"},
{label: "ACICLOVIR ACCORD"},
{label: "ACICLOVIR AGEPHA"},
{label: "ACICLOVIR AGUETTANT"},
{label: "ACICLOVIR ALMUS"},
{label: "ACICLOVIR ALTER"},
{label: "ACICLOVIR APOTEX"},
{label: "ACICLOVIR APOTEX FRANCE"},
{label: "ACICLOVIR ARROW"},
{label: "ACICLOVIR ARROW CONSEIL"},
{label: "ACICLOVIR ARROW GENERIQUES"},
{label: "ACICLOVIR ARROW LAB"},
{label: "ACICLOVIR BGR"},
{label: "ACICLOVIR BIOGALENIQUE"},
{label: "ACICLOVIR BIOGARAN"},
{label: "ACICLOVIR BIOGARAN CONSEIL"},
{label: "ACICLOVIR CRISTERS"},
{label: "ACICLOVIR CRISTERS CONSEIL"},
{label: "ACICLOVIR DAKOTA PHARM"},
{label: "ACICLOVIR EG"},
{label: "ACICLOVIR EG LABO"},
{label: "ACICLOVIR EVOLUGEN"},
{label: "ACICLOVIR GENEVRIER"},
{label: "ACICLOVIR GLAXOSMITHKLINE SANTE GRAND PUBLIC"},
{label: "ACICLOVIR GONNON"},
{label: "ACICLOVIR HIKMA"},
{label: "ACICLOVIR HOSPIRA"},
{label: "ACICLOVIR IVAX"},
{label: "ACICLOVIR MERCK"},
{label: "ACICLOVIR MYLAN"},
{label: "ACICLOVIR NORIDEM"},
{label: "ACICLOVIR OPIH FRANCE"},
{label: "ACICLOVIR PAUCOURT"},
{label: "ACICLOVIR QUALIMED"},
{label: "ACICLOVIR RANBAXY"},
{label: "ACICLOVIR RATIOPHARM"},
{label: "ACICLOVIR RPG"},
{label: "ACICLOVIR SAINT GERMAIN"},
{label: "ACICLOVIR SANDOZ"},
{label: "ACICLOVIR SANDOZ CONSEIL"},
{label: "ACICLOVIR SUBSTIPHARM"},
{label: "ACICLOVIR TEVA"},
{label: "ACICLOVIR TEVA CONSEIL"},
{label: "ACICLOVIR TEVA PHARMA"},
{label: "ACICLOVIR TEVA SANTE"},
{label: "ACICLOVIR VIATRIS"},
{label: "ACICLOVIR VIATRIS CONSEIL"},
{label: "ACICLOVIR ZENTIVA"},
{label: "ACICLOVIR ZYDUS"},
{label: "ACIDE ACETYLSALICYLIQUE ACTAVIS"},
{label: "ACIDE ACETYLSALICYLIQUE BAYER"},
{label: "ACIDE ACETYLSALICYLIQUE IBSA"},
{label: "ACIDE ACETYLSALICYLIQUE KRKA"},
{label: "ACIDE ACETYLSALICYLIQUE OPENING PHARMA"},
{label: "ACIDE ACETYLSALICYLIQUE VIATRIS"},
{label: "ACIDE ACÉTYLSALICYLIQUE EG LABO CONSEIL"},
{label: "ACIDE ALENDRONIQUE ACTAVIS"},
{label: "ACIDE ALENDRONIQUE ALMUS"},
{label: "ACIDE ALENDRONIQUE ALTER"},
{label: "ACIDE ALENDRONIQUE ARROW"},
{label: "ACIDE ALENDRONIQUE ARROW LAB"},
{label: "ACIDE ALENDRONIQUE BIOGALENIQUE"},
{label: "ACIDE ALENDRONIQUE BIOGARAN"},
{label: "ACIDE ALENDRONIQUE CRISTERS"},
{label: "ACIDE ALENDRONIQUE EDEST"},
{label: "ACIDE ALENDRONIQUE EG"},
{label: "ACIDE ALENDRONIQUE EVOLUGEN"},
{label: "ACIDE ALENDRONIQUE PFIZER"},
{label: "ACIDE ALENDRONIQUE PHR LAB"},
{label: "ACIDE ALENDRONIQUE QUALIHEALTH"},
{label: "ACIDE ALENDRONIQUE QUALIMED"},
{label: "ACIDE ALENDRONIQUE RANBAXY"},
{label: "ACIDE ALENDRONIQUE RATIOPHARM"},
{label: "ACIDE ALENDRONIQUE RPG"},
{label: "ACIDE ALENDRONIQUE SANDOZ"},
{label: "ACIDE ALENDRONIQUE TEVA"},
{label: "ACIDE ALENDRONIQUE TEVA SANTE"},
{label: "ACIDE ALENDRONIQUE VIATRIS"},
{label: "ACIDE ALENDRONIQUE WINTHROP"},
{label: "ACIDE ALENDRONIQUE ZENTIVA"},
{label: "ACIDE ALENDRONIQUE/CHOLECALCIFEROL (VITAMINE D3) CRISTERS"},
{label: "ACIDE ALENDRONIQUE/CHOLECALCIFEROL (VITAMINE D3) EG"},
{label: "ACIDE ALENDRONIQUE/CHOLECALCIFEROL (VITAMINE D3) EVOLUGEN"},
{label: "ACIDE ALENDRONIQUE/CHOLECALCIFEROL (VITAMINE D3) ZENTIVA"},
{label: "ACIDE ALENDRONIQUE/VITAMINE D3 ALTIEX"},
{label: "ACIDE ALENDRONIQUE/VITAMINE D3 ARROW"},
{label: "ACIDE ALENDRONIQUE/VITAMINE D3 BGR"},
{label: "ACIDE ALENDRONIQUE/VITAMINE D3 TEVA SANTE"},
{label: "ACIDE ALENDRONIQUE/VITAMINE D3 VIATRIS"},
{label: "ACIDE ALENDRONIQUE/VITD3 SANDOZ"},
{label: "ACIDE ASCORBIQUE ASPARTATES DE POTASSIUM ET DE MAGNESIUM ALPHARMA"},
{label: "ACIDE CARGLUMIQUE TILLOMED"},
{label: "ACIDE CARGLUMIQUE WAYMADE"},
{label: "ACIDE DESOXYRIBONUCLEIQUE HP GNR"},
{label: "ACIDE FOLINIQUE TEVA"},
{label: "ACIDE FOLIQUE ARROW"},
{label: "ACIDE FOLIQUE BIOGARAN"},
{label: "ACIDE FOLIQUE CCD"},
{label: "ACIDE FOLIQUE TORRENT"},
{label: "ACIDE FOLIQUE VIATRIS"},
{label: "ACIDE FUSIDIQUE ARROW"},
{label: "ACIDE FUSIDIQUE BGR"},
{label: "ACIDE FUSIDIQUE BIOGARAN"},
{label: "ACIDE FUSIDIQUE EG"},
{label: "ACIDE FUSIDIQUE RATIOPHARM"},
{label: "ACIDE FUSIDIQUE SANDOZ"},
{label: "ACIDE FUSIDIQUE VIATRIS"},
{label: "ACIDE FUSIDIQUE ZENTIVA"},
{label: "ACIDE GADOTERIQUE BAYER HEALTHCARE"},
{label: "ACIDE GADOTERIQUE GUERBET"},
{label: "ACIDE IBANDRONIQUE ALTISO"},
{label: "ACIDE IBANDRONIQUE ARROW"},
{label: "ACIDE IBANDRONIQUE AVANSOR PHARMA"},
{label: "ACIDE IBANDRONIQUE AXOREL"},
{label: "ACIDE IBANDRONIQUE BLUEFISH"},
{label: "ACIDE IBANDRONIQUE CHEMI"},
{label: "ACIDE IBANDRONIQUE CLEXNI"},
{label: "ACIDE IBANDRONIQUE EG"},
{label: "ACIDE IBANDRONIQUE GENTHON"},
{label: "ACIDE IBANDRONIQUE ISOMED"},
{label: "ACIDE IBANDRONIQUE LICONSA"},
{label: "ACIDE IBANDRONIQUE MYLAN"},
{label: "ACIDE IBANDRONIQUE MYPLIX"},
{label: "ACIDE IBANDRONIQUE NIALEX"},
{label: "ACIDE IBANDRONIQUE PHARMAKI GENERICS"},
{label: "ACIDE IBANDRONIQUE QUIVER"},
{label: "ACIDE IBANDRONIQUE RATIOPHARM"},
{label: "ACIDE IBANDRONIQUE SANDOZ"},
{label: "ACIDE IBANDRONIQUE SUN"},
{label: "ACIDE IBANDRONIQUE SYNTHON"},
{label: "ACIDE IBANDRONIQUE TEVA"},
{label: "ACIDE IBANDRONIQUE TORRENT"},
{label: "ACIDE IBANDRONIQUE ZENTIVA"},
{label: "ACIDE IBANDRONIQUE ZYDUS"},
{label: "ACIDE MYCOPHENOLIQUE ACCORD"},
{label: "ACIDE MYCOPHENOLIQUE SANDOZ"},
{label: "ACIDE PARA AMINO HIPPURIQUE"},
{label: "ACIDE PIPEMIDIQUE BIOGALENIQUE"},
{label: "ACIDE PIPEMIDIQUE RPG"},
{label: "ACIDE PIPEMIDIQUE SPECIA"},
{label: "ACIDE SALICYLIQUE ALCON"},
{label: "ACIDE TIAPROFENIQUE ARROW"},
{label: "ACIDE TIAPROFENIQUE B.R.M."},
{label: "ACIDE TIAPROFENIQUE EG"},
{label: "ACIDE TIAPROFENIQUE RPG"},
{label: "ACIDE TIAPROFENIQUE TEVA"},
{label: "ACIDE TIAPROFENIQUE ZENTIVA"},
{label: "ACIDE TILUDRONIQUE SANOFI PHARMA"},
{label: "ACIDE TRANEXAMIQUE ACCORD"},
{label: "ACIDE TRANEXAMIQUE AGUETTANT"},
{label: "ACIDE TRANEXAMIQUE ARROW"},
{label: "ACIDE TRANEXAMIQUE STRAGEN"},
{label: "ACIDE TRANEXAMIQUE VIATRIS"},
{label: "ACIDE TRANEXAMIQUE VIATRIS SANTE"},
{label: "ACIDE URSODESOXYCHOLIQUE ARROW"},
{label: "ACIDE URSODESOXYCHOLIQUE BIOGARAN"},
{label: "ACIDE URSODESOXYCHOLIQUE GRINDEKS"},
{label: "ACIDE URSODESOXYCHOLIQUE SUBSTIPHARM"},
{label: "ACIDE URSODESOXYCHOLIQUE TEVA"},
{label: "ACIDE URSODESOXYCHOLIQUE VIATRIS"},
{label: "ACIDE ZOLEDRONIQUE ACTAVIS"},
{label: "ACIDE ZOLEDRONIQUE AGILA"},
{label: "ACIDE ZOLEDRONIQUE ALTAN"},
{label: "ACIDE ZOLEDRONIQUE ALTISO"},
{label: "ACIDE ZOLEDRONIQUE ARROW"},
{label: "ACIDE ZOLEDRONIQUE AUTHOU"},
{label: "ACIDE ZOLEDRONIQUE BIOGARAN"},
{label: "ACIDE ZOLEDRONIQUE CIPLA"},
{label: "ACIDE ZOLEDRONIQUE EG"},
{label: "ACIDE ZOLEDRONIQUE FAIR-MED"},
{label: "ACIDE ZOLEDRONIQUE FRESENIUS KABI"},
{label: "ACIDE ZOLEDRONIQUE HOSPIRA"},
{label: "ACIDE ZOLEDRONIQUE MEDAC"},
{label: "ACIDE ZOLEDRONIQUE MYLAN"},
{label: "ACIDE ZOLEDRONIQUE MYLAN PHARMA"},
{label: "ACIDE ZOLEDRONIQUE PANPHARMA"},
{label: "ACIDE ZOLEDRONIQUE PFIZER"},
{label: "ACIDE ZOLEDRONIQUE PHARMAKI GENERICS"},
{label: "ACIDE ZOLEDRONIQUE RANBAXY"},
{label: "ACIDE ZOLEDRONIQUE RESOLUTE"},
{label: "ACIDE ZOLEDRONIQUE ROL"},
{label: "ACIDE ZOLEDRONIQUE SANDOZ"},
{label: "ACIDE ZOLEDRONIQUE SEACROSS"},
{label: "ACIDE ZOLEDRONIQUE SUN"},
{label: "ACIDE ZOLEDRONIQUE SYNTHON"},
{label: "ACIDE ZOLEDRONIQUE TEVA"},
{label: "ACIDE ZOLEDRONIQUE TEVA PHARMA"},
{label: "ACIDE ZOLEDRONIQUE TILLOMED"},
{label: "ACIDE ZOLEDRONIQUE TORRENT"},
{label: "ACIDE ZOLEDRONIQUE VIATRIS"},
{label: "ACIDE ZOLEDRONIQUE ZENTIVA"},
{label: "ACIDE ZOLEDRONIQUE ZENTIVA LAB"},
{label: "ACIDRINE"},
{label: "ACIDUM PHOSPHORICUM COMPLEXE N°5"},
{label: "ACIDUM PHOSPHORICUM COMPOSE"},
{label: "ACLASTA"},
{label: "ACLOSONE"},
{label: "ACLOTINE"},
{label: "ACNELINE"},
{label: "ACODENE"},
{label: "ACODORON"},
{label: "ACOMPLIA"},
{label: "ACONITUM FEROX BOIRON"},
{label: "ACONITUM NAPELLUS BOIRON"},
{label: "ACONITUM NAPELLUS LEHNING"},
{label: "ACONITUM NAPELLUS WELEDA"},
{label: "ACORSPRAY"},
{label: "ACSET"},
{label: "ACT-HIB"},
{label: "ACTAEA RACEMOSA BOIRON"},
{label: "ACTAEA RACEMOSA DECOCTE WELEDA"},
{label: "ACTAEA RACEMOSA FERRIER"},
{label: "ACTAEA RACEMOSA LEHNING"},
{label: "ACTAEA SPICATA BOIRON"},
{label: "ACTAEA SPICATA LEHNING"},
{label: "ACTAEA SPICATA WELEDA"},
{label: "ACTAPULGITE"},
{label: "ACTEBRAL"},
{label: "ACTEE A GRAPPES IPRAD"},
{label: "ACTELSAR HCT"},
{label: "ACTHEANE"},
{label: "ACTI"},
{label: "ACTIBIL"},
{label: "ACTICARBINE"},
{label: "ACTIFED"},
{label: "ACTIFED CONJONCTIVITE ALLERGIQUE"},
{label: "ACTIFED EXPECTORANT"},
{label: "ACTIFED LP RHINITE ALLERGIQUE"},
{label: "ACTIFED RHUME"},
{label: "ACTIFED RHUME JOUR ET NUIT"},
{label: "ACTIFED TOUX SECHE"},
{label: "ACTIFEDCAPS ALLERGIE CETIRIZINE"},
{label: "ACTIGASTRE"},
{label: "ACTILYSE"},
{label: "ACTIPHOS"},
{label: "ACTIQ"},
{label: "ACTISANE"},
{label: "ACTISANE CONSTIPATION OCCASIONNELLE"},
{label: "ACTISANE DIGESTION"},
{label: "ACTISANE FATIGUE PASSAGERE"},
{label: "ACTISANE HEMORROIDES JAMBES LOURDES"},
{label: "ACTISANE NERVOSITE"},
{label: "ACTISANE TROUBLES DU SOMMEIL"},
{label: "ACTISITE"},
{label: "ACTISKENAN"},
{label: "ACTISOUFRE"},
{label: "ACTITONIC ADULTES"},
{label: "ACTITONIC ENFANTS"},
{label: "ACTIVAROL"},
{label: "ACTIVELLE"},
{label: "ACTIVIR"},
{label: "ACTIVOX"},
{label: "ACTIVOX EXPECTORANT ADULTES"},
{label: "ACTIVOX EXPECTORANT LIERRE"},
{label: "ACTIVOX EXPECTORANT LIERRE SANS SUCRE"},
{label: "ACTIVOX MAUX DE GORGE ERYSIMUM AROME MENTHE EUCALYPTUS SANS SUCRE"},
{label: "ACTIVOX MAUX DE GORGE ERYSIMUM AROME MIEL CITRON SANS SUCRE"},
{label: "ACTIVOX RHUME PELARGONIUM"},
{label: "ACTIVOX TOUX SECHE"},
{label: "ACTOCAN"},
{label: "ACTONEL"},
{label: "ACTONELCOMBI"},
{label: "ACTOS"},
{label: "ACTOSOLV"},
{label: "ACTRAPID"},
{label: "ACTRAPID FLEXPEN"},
{label: "ACTRAPID INNOLET"},
{label: "ACTRAPID NOVOLET"},
{label: "ACTRAPID PENFILL"},
{label: "ACTRON"},
{label: "ACTRONEFFIX"},
{label: "ACTYNOX"},
{label: "ACUILIX"},
{label: "ACUITEL"},
{label: "ACULAR"},
{label: "ACUPAN"},
{label: "ACURIME"},
{label: "ACUSPOT"},
{label: "ACUSPOT 5%"},
{label: "ACYDLACT"},
{label: "ADAKVEO"},
{label: "ADALATE"},
{label: "ADANCOR"},
{label: "ADAPALENE TEVA"},
{label: "ADAPALENE ZENTIVA"},
{label: "ADARTREL"},
{label: "ADASUVE"},
{label: "ADCETRIS"},
{label: "ADCIRCA"},
{label: "ADDIGRIP"},
{label: "ADEMPAS"},
{label: "ADENA C"},
{label: "ADENOCYNESINE"},
{label: "ADENOSCAN"},
{label: "ADENOSINE ACCORD"},
{label: "ADENOSINE BAXTER"},
{label: "ADENOSINE HIKMA"},
{label: "ADENOSINE MEDISOL"},
{label: "ADENURIC"},
{label: "ADENYL"},
{label: "ADEPAL"},
{label: "ADEROLIO"},
{label: "ADIAZINE"},
{label: "ADIXON"},
{label: "ADONIS VERNALIS"},
{label: "ADONIS VERNALIS BOIRON"},
{label: "ADONIS VERNALIS LEHNING"},
{label: "ADONTEX"},
{label: "ADOPORT"},
{label: "ADOUMYLASE"},
{label: "ADRENALINE A"},
{label: "ADRENALINE AGUETTANT"},
{label: "ADRENALINE BRADEX"},
{label: "ADRENALINE COOPER"},
{label: "ADRENALINE ETHYPHARM"},
{label: "ADRENALINE MEDA"},
{label: "ADRENALINE RENAUDIN"},
{label: "ADRENALINUM BOIRON"},
{label: "ADRENALINUM LEHNING"},
{label: "ADRENOXYL"},
{label: "ADREVIEW [123I]"},
{label: "ADRI GYL"},
{label: "ADRIBLASTINE"},
{label: "ADRIGYL"},
{label: "ADROVANCE"},
{label: "ADTRALZA"},
{label: "ADULAIRE BOIRON"},
{label: "ADVAGRAF"},
{label: "ADVATE"},
{label: "ADVIL"},
{label: "ADVILCAPS"},
{label: "ADVILEFF"},
{label: "ADVILGEL"},
{label: "ADVILMED"},
{label: "ADVILMED ENFANTS ET NOURRISSONS"},
{label: "ADYNOVI"},
{label: "AERIUS"},
{label: "AERIVIO SPIROMAX"},
{label: "AEROCID"},
{label: "AERODIOL"},
{label: "AEROLINE"},
{label: "AEROLINE AUTOHALER"},
{label: "AERRANE"},
{label: "AESCULUS COMPLEXE N°103"},
{label: "AESCULUS COMPOSE"},
{label: "AESCULUS GLABRA BOIRON"},
{label: "AESCULUS HIPPOCASTANUM"},
{label: "AESCULUS HIPPOCASTANUM BOIRON"},
{label: "AESCULUS HIPPOCASTANUM FERRIER"},
{label: "AESCULUS HIPPOCASTANUM LEHNING"},
{label: "AESCULUS HIPPOCASTANUM WELEDA"},
{label: "AETHIOPS MERCURIALIS MINERALIS BOIRON"},
{label: "AETHIOPS MERCURIALIS MINERALIS LEHNING"},
{label: "AETHUSA CYNAPIUM BOIRON"},
{label: "AETHUSA CYNAPIUM LEHNING"},
{label: "AETHUSA CYNAPIUM WELEDA"},
{label: "AETOXISCLEROL"},
{label: "AETOXISCLEROL 2% (40"},
{label: "AETOXISCLEROL 3% (60"},
{label: "AFERADOL"},
{label: "AFFERA"},
{label: "AFFINEX MINCEUR"},
{label: "AFINITOR"},
{label: "AFLAVIS"},
{label: "AFLURIA"},
{label: "AFRA"},
{label: "AFSTYLA"},
{label: "AFTAB"},
{label: "AFTAGEL"},
{label: "AFTOSIUM"},
{label: "AGARICUS BULBOSUS BOIRON"},
{label: "AGARICUS BULBOSUS LEHNING"},
{label: "AGARICUS BULBOSUS WELEDA"},
{label: "AGARICUS MUSCARIUS BOIRON"},
{label: "AGARICUS MUSCARIUS LEHNING"},
{label: "AGARICUS MUSCARIUS WELEDA"},
{label: "AGAVE AMERICANA BOIRON"},
{label: "AGENERASE"},
{label: "AGICOR"},
{label: "AGIOCUR"},
{label: "AGIOLAX"},
{label: "AGNUS CASTUS BOIRON"},
{label: "AGNUS CASTUS LEHNING"},
{label: "AGNUS CASTUS TEINTURE MERE BOIRON"},
{label: "AGNUS CASTUS WELEDA"},
{label: "AGOMELATINE BIOGARAN"},
{label: "AGOMELATINE CRISTERS"},
{label: "AGOMELATINE EG"},
{label: "AGOMELATINE NEURAXPHARM"},
{label: "AGOMELATINE PHARMAKI GENERICS"},
{label: "AGOMELATINE REDDY PHARMA"},
{label: "AGOMELATINE TEVA"},
{label: "AGOMELATINE VIATRIS"},
{label: "AGOMELATINE ZENTIVA"},
{label: "AGOMÉLATINE HCS"},
{label: "AGRAM"},
{label: "AGRAPHIS NUTANS BOIRON"},
{label: "AGRAPHIS NUTANS LEHNING"},
{label: "AGRASTAT"},
{label: "AGREAL"},
{label: "AGRIMONIA EUPATORIA BOIRON"},
{label: "AGRIPPAL"},
{label: "AGYRAX"},
{label: "AIGLONYL"},
{label: "AILANTHUS GLANDULOSA BOIRON"},
{label: "AILANTHUS GLANDULOSA LEHNING"},
{label: "AIMOVIG"},
{label: "AINSCRID"},
{label: "AIRFLUSAL FORSPIRO"},
{label: "AIROMIR AUTOHALER"},
{label: "AJOVY"},
{label: "AKEEGA"},
{label: "AKINDOL FOURNIER"},
{label: "AKINETON L.P."},
{label: "AKIS"},
{label: "AKLIEF"},
{label: "AKYNZEO"},
{label: "ALAIRGIX ALLERGIE CETIRIZINE"},
{label: "ALAIRGIX RHINITE ALLERGIQUE CROMOGLICATE DE SODIUM"},
{label: "ALBATRAN"},
{label: "ALBENDAZOLE GSK"},
{label: "ALBEY VENIN D'ABEILLE"},
{label: "ALBEY VENIN DE GUEPE"},
{label: "ALBU-RES"},
{label: "ALBUMAN"},
{label: "ALBUMINE HUMAINE BAXTER"},
{label: "ALBUMINE HUMAINE BAXTER BIOSCIENCE"},
{label: "ALBUMINE HUMAINE BIOTEST"},
{label: "ALBUMINE HUMAINE CSL BEHRING"},
{label: "ALBUNORM"},
{label: "ALBUREX"},
{label: "ALBUTEIN"},
{label: "ALCAPHOR"},
{label: "ALCHEMILLA VULGARIS BOIRON"},
{label: "ALCOOL A 70° A.C.S."},
{label: "ALCOOL A USAGE MEDICAL GILBERT"},
{label: "ALCOOL CAMPHRE GIFRER"},
{label: "ALCOOL GLYCERINE A.C.S."},
{label: "ALCOOL MODIFIE API"},
{label: "ALCOOL MODIFIE COOPER"},
{label: "ALCOOL MODIFIE EVOLUPHARM"},
{label: "ALCOOL MODIFIE GIFRER"},
{label: "ALCOR ADULTES"},
{label: "ALDACTAZINE"},
{label: "ALDACTONE"},
{label: "ALDALIX"},
{label: "ALDARA"},
{label: "ALDEMYL"},
{label: "ALDIREK"},
{label: "ALDOMET"},
{label: "ALDOSTERONE BOIRON"},
{label: "ALDURAZYME"},
{label: "ALDYZINE"},
{label: "ALECENSA"},
{label: "ALENDRONATE TEVA"},
{label: "ALEPSAL"},
{label: "ALERDUAL"},
{label: "ALERGOLIBER"},
{label: "ALETRIS FARINOSA BOIRON"},
{label: "ALETRIS FARINOSA LEHNING"},
{label: "ALEVE"},
{label: "ALEVETABS"},
{label: "ALFA-AMYLASE BIOGARAN CONSEIL"},
{label: "ALFA-AMYLASE TOP PHARM"},
{label: "ALFACALCIDOL THERAMEX"},
{label: "ALFALASTIN"},
{label: "ALFALFA BOIRON"},
{label: "ALFALFA FERRIER"},
{label: "ALFALFA LEHNING"},
{label: "ALFATIL"},
{label: "ALFATIL L.P."},
{label: "ALFATIL LP"},
{label: "ALFENCE"},
{label: "ALFENTANIL KALCEKS"},
{label: "ALFUZOSINE ALTER LP"},
{label: "ALFUZOSINE BIOGARAN L.P."},
{label: "ALFUZOSINE CRISTERS LP"},
{label: "ALFUZOSINE CRISTERS PHARMA LP"},
{label: "ALFUZOSINE EG L.P."},
{label: "ALFUZOSINE EVOLUGEN L.P."},
{label: "ALFUZOSINE G GAM L.P."},
{label: "ALFUZOSINE MYLAN LP"},
{label: "ALFUZOSINE PFIZER LP"},
{label: "ALFUZOSINE QUALIMED L.P."},
{label: "ALFUZOSINE RANBAXY L.P."},
{label: "ALFUZOSINE RATIOPHARM L.P."},
{label: "ALFUZOSINE SANDOZ L.P."},
{label: "ALFUZOSINE SANOFI SYNTHELABO FRANCE"},
{label: "ALFUZOSINE TEVA L.P."},
{label: "ALFUZOSINE TEVA SANTE L.P."},
{label: "ALFUZOSINE VIATRIS L.P."},
{label: "ALFUZOSINE WINTHROP"},
{label: "ALFUZOSINE WINTHROP LP"},
{label: "ALFUZOSINE ZENTIVA LP"},
{label: "ALFUZOSINE ZYDUS L.P."},
{label: "ALGESAL BAUME"},
{label: "ALGESAL SURACTIVE"},
{label: "ALGIBAUME"},
{label: "ALGICALM"},
{label: "ALGIFENE"},
{label: "ALGINATE DE SODIUM / BICARBONATE DE SODIUM BIOGARAN"},
{label: "ALGINATE DE SODIUM / BICARBONATE DE SODIUM EG"},
{label: "ALGINATE DE SODIUM / BICARBONATE DE SODIUM FRILAB"},
{label: "ALGINATE DE SODIUM / BICARBONATE DE SODIUM MOTIMA"},
{label: "ALGINATE DE SODIUM / BICARBONATE DE SODIUM TEVA"},
{label: "ALGINATE DE SODIUM / BICARBONATE DE SODIUM UNITHER"},
{label: "ALGINATE DE SODIUM/ BICARBONATE DE SODIUM/ CARBONATE DE CALCIUM SANOFI AVENTIS"},
{label: "ALGINATE DE SODIUM/BICARBONATE DE SODIUM ARROW"},
{label: "ALGINATE DE SODIUM/BICARBONATE DE SODIUM ARROW LAB"},
{label: "ALGINATE DE SODIUM/BICARBONATE DE SODIUM BGR"},
{label: "ALGINATE DE SODIUM/BICARBONATE DE SODIUM BIOGARAN CONSEIL"},
{label: "ALGINATE DE SODIUM/BICARBONATE DE SODIUM CRISTERS"},
{label: "ALGINATE DE SODIUM/BICARBONATE DE SODIUM EG"},
{label: "ALGINATE DE SODIUM/BICARBONATE DE SODIUM EG LABO CONSEIL"},
{label: "ALGINATE DE SODIUM/BICARBONATE DE SODIUM H3 SANTE"},
{label: "ALGINATE DE SODIUM/BICARBONATE DE SODIUM LABORATOIRE X.O"},
{label: "ALGINATE DE SODIUM/BICARBONATE DE SODIUM PHARMASTICK"},
{label: "ALGINATE DE SODIUM/BICARBONATE DE SODIUM SANDOZ"},
{label: "ALGINATE DE SODIUM/BICARBONATE DE SODIUM SANDOZ CONSEIL"},
{label: "ALGINATE DE SODIUM/BICARBONATE DE SODIUM VIATRIS"},
{label: "ALGINATE DE SODIUM/BICARBONATE DE SODIUM VIATRIS CONSEIL"},
{label: "ALGINATE DE SODIUM/BICARBONATE DE SODIUM WINTHROP"},
{label: "ALGINATE DE SODIUM/BICARBONATE DE SODIUM ZENTIVA"},
{label: "ALGINIC"},
{label: "ALGISEDAL"},
{label: "ALGISPIR"},
{label: "ALGITEX"},
{label: "ALGO-NEVRITON"},
{label: "ALGOCRATINE A L'ASPIRINE"},
{label: "ALGODOL"},
{label: "ALGODOL CAFEINE"},
{label: "ALGOPHYTUM"},
{label: "ALGORHINOL"},
{label: "ALGOSTASE"},
{label: "ALGOTROPYL"},
{label: "ALGUES (FUCUS) BOIRON"},
{label: "ALIMTA"},
{label: "ALIPASE"},
{label: "ALIZEM"},
{label: "ALKA SELTZER"},
{label: "ALKENIDE"},
{label: "ALKERAN"},
{label: "ALKOCORTENBIOFORM"},
{label: "ALKONATREM"},
{label: "ALKOSALEN"},
{label: "ALKOTAR"},
{label: "ALLERGA"},
{label: "ALLERGEFON"},
{label: "ALLERGEFON LYOC"},
{label: "ALLERGIFLASH"},
{label: "ALLERGOCOMOD"},
{label: "ALLERGODIL"},
{label: "ALLEVAPIL"},
{label: "ALLI"},
{label: "ALLIUM CEPA BOIRON"},
{label: "ALLIUM CEPA COMPOSE"},
{label: "ALLIUM CEPA LEHNING"},
{label: "ALLIUM CEPA WELEDA"},
{label: "ALLIUM SATIVUM BOIRON"},
{label: "ALLIUM SATIVUM LEHNING"},
{label: "ALLIUM URSINUM BOIRON"},
{label: "ALLIUM URSINUM WELEDA"},
{label: "ALLOCHRYSINE"},
{label: "ALLOFERINE"},
{label: "ALLOPTREX"},
{label: "ALLOPURINOL ARROW"},
{label: "ALLOPURINOL ARROW LAB"},
{label: "ALLOPURINOL BIOGALENIQUE"},
{label: "ALLOPURINOL BIOGARAN"},
{label: "ALLOPURINOL BMS"},
{label: "ALLOPURINOL CRISTERS"},
{label: "ALLOPURINOL EG"},
{label: "ALLOPURINOL ISOMED"},
{label: "ALLOPURINOL IVAX"},
{label: "ALLOPURINOL MYLAN PHARMA"},
{label: "ALLOPURINOL PHR LAB"},
{label: "ALLOPURINOL RATIOPHARM"},
{label: "ALLOPURINOL SANDOZ"},
{label: "ALLOPURINOL SET"},
{label: "ALLOPURINOL SUN"},
{label: "ALLOPURINOL TEVA"},
{label: "ALLOPURINOL VIATRIS"},
{label: "ALLOPURINOL WYVERN MEDICAL"},
{label: "ALLOPURINOL ZENTIVA"},
{label: "ALLOPURINOL ZYDUS"},
{label: "ALLOXANUM BOIRON"},
{label: "ALLURENE"},
{label: "ALLUZIENCE"},
{label: "ALMA"},
{label: "ALMACALM"},
{label: "ALMAPNEUMOL"},
{label: "ALMATUSSIL ADULTES"},
{label: "ALMIDE"},
{label: "ALMOGRAN"},
{label: "ALMOTREX"},
{label: "ALMOTRIPTAN ARROW"},
{label: "ALMOTRIPTAN BIOGARAN"},
{label: "ALMOTRIPTAN EG"},
{label: "ALMOTRIPTAN MYPLIX"},
{label: "ALMOTRIPTAN SANDOZ"},
{label: "ALMOTRIPTAN TEVA"},
{label: "ALMOTRIPTAN VIATRIS"},
{label: "ALMOTRIPTAN ZENTIVA"},
{label: "ALMOTRIPTAN ZYDUS"},
{label: "ALNUS GLUTINOSA BOIRON"},
{label: "ALODONT"},
{label: "ALOE BOIRON"},
{label: "ALOE COMPOSE"},
{label: "ALOE LEHNING"},
{label: "ALOE WELEDA"},
{label: "ALOFISEL"},
{label: "ALONEST"},
{label: "ALOPEXY"},
{label: "ALOPLASTINE"},
{label: "ALOPLASTINE ICHTHYOLEE"},
{label: "ALORA"},
{label: "ALOSTELLE 2%"},
{label: "ALOSTIL"},
{label: "ALOXI"},
{label: "ALPAGELLE"},
{label: "ALPHA RENOL"},
{label: "ALPHABUX"},
{label: "ALPHACAINE N"},
{label: "ALPHACAINE SP"},
{label: "ALPHACHYMOTRYPSINE CHOAY"},
{label: "ALPHACUTANEE"},
{label: "ALPHADERM"},
{label: "ALPHAGAN"},
{label: "ALPHEXINE"},
{label: "ALPHEXINE LP"},
{label: "ALPHINTERN"},
{label: "ALPHOSYL"},
{label: "ALPRAZOLAM ALTER"},
{label: "ALPRAZOLAM ARROW"},
{label: "ALPRAZOLAM ARROW LAB"},
{label: "ALPRAZOLAM BIOGARAN"},
{label: "ALPRAZOLAM CRISTERS"},
{label: "ALPRAZOLAM DCI PHARMA"},
{label: "ALPRAZOLAM EG"},
{label: "ALPRAZOLAM EVOLUGEN"},
{label: "ALPRAZOLAM EVOLUGEN PHARMA"},
{label: "ALPRAZOLAM G GAM"},
{label: "ALPRAZOLAM GNR"},
{label: "ALPRAZOLAM HCS"},
{label: "ALPRAZOLAM ISOMED"},
{label: "ALPRAZOLAM QUIVER"},
{label: "ALPRAZOLAM RPG"},
{label: "ALPRAZOLAM SANDOZ"},
{label: "ALPRAZOLAM TEVA"},
{label: "ALPRAZOLAM VIATRIS"},
{label: "ALPRAZOLAM WINTHROP"},
{label: "ALPRAZOLAM ZENTIVA"},
{label: "ALPRAZOLAM ZYDUS"},
{label: "ALPRAZOLAM ZYDUS FRANCE"},
{label: "ALPRESS L.P."},
{label: "ALPROLIX"},
{label: "ALPROSTADIL INTSEL CHIMOS"},
{label: "ALPROSTADIL PANPHARMA"},
{label: "ALPROSTADIL SICOR"},
{label: "ALPROSTADIL UNION INTERPHARMACEUTIQUE ATLANTIQUE"},
{label: "ALPURIC"},
{label: "ALQUOSAL"},
{label: "ALTARGO"},
{label: "ALTEA"},
{label: "ALTEIS"},
{label: "ALTEISDUO"},
{label: "ALTERGENE"},
{label: "ALTHAEA OFFICINALIS BOIRON"},
{label: "ALTIM"},
{label: "ALTIZIDE SPIRONOLACTONE RPG"},
{label: "ALUCTYL"},
{label: "ALUMINA BOIRON"},
{label: "ALUMINA LEHNING"},
{label: "ALUMINA WELEDA"},
{label: "ALUMINIUM HYDROXYDE/SILICE RPG"},
{label: "ALUMINIUM MAGNESIUM GIFRER BARBEZAT"},
{label: "ALUMINIUM OLIGOSOL"},
{label: "ALUNBRIG"},
{label: "ALUPENT"},
{label: "ALUPHO"},
{label: "ALUTARD"},
{label: "ALUTARD EMBALLAGE DE DEBUT DE TRAITEMENT (1"},
{label: "ALUTARD VENIN DE GUEPE VESPULA SPP."},
{label: "ALUTARD VENIN DABEILLE APIS MELLIFERA"},
{label: "ALVERINE/SIMETICONE A.C.P"},
{label: "ALVERINE/SIMETICONE EURATLANTIC COSMEPHARM"},
{label: "ALVERINE/SIMETICONE MAYOLY SPINDLER"},
{label: "ALVESCO"},
{label: "ALVOGYL"},
{label: "ALYMSYS"},
{label: "ALYOSTAL PRICK CONTROLE NEGATIF"},
{label: "ALYOSTAL PRICK CONTROLE POSITIF"},
{label: "ALYOSTAL VENIN D'ABEILLE APIS MELLIFERA"},
{label: "ALYOSTAL VENIN DE GUEPE POLISTES"},
{label: "ALYOSTAL VENIN DE GUEPE VESPULA"},
{label: "ALYRANE"},
{label: "ALZIKANA"},
{label: "AMANITA COMPLEXE N°75"},
{label: "AMARANCE"},
{label: "AMAREL"},
{label: "AMBISOME"},
{label: "AMBRA GRISEA BOIRON"},
{label: "AMBRA GRISEA LEHNING"},
{label: "AMBRA GRISEA WELEDA"},
{label: "AMBRISENTAN ACCORD"},
{label: "AMBRISENTAN ARROW"},
{label: "AMBRISENTAN BLUEFISH"},
{label: "AMBRISENTAN EG"},
{label: "AMBRISENTAN MYLAN"},
{label: "AMBRISENTAN REDDY PHARMA"},
{label: "AMBRISENTAN STRAGEN"},
{label: "AMBRISENTAN TEVA"},
{label: "AMBRISENTAN ZENTIVA"},
{label: "AMBROSIA ARTEMISIAEFOLIA BOIRON"},
{label: "AMBROXOL AEROCID"},
{label: "AMBROXOL ARROW"},
{label: "AMBROXOL BIOGARAN"},
{label: "AMBROXOL BIOGARAN CONSEIL"},
{label: "AMBROXOL BOEHRINGER INGELHEIM FRANCE"},
{label: "AMBROXOL BOEHRINGER INGELHEIM L.P."},
{label: "AMBROXOL EG LABO CONSEIL"},
{label: "AMBROXOL G GAM"},
{label: "AMBROXOL IVAX"},
{label: "AMBROXOL MYLAN"},
{label: "AMBROXOL RATIOPHARM"},
{label: "AMBROXOL SANDOZ"},
{label: "AMBROXOL SANDOZ CONSEIL"},
{label: "AMBROXOL SFIP"},
{label: "AMBROXOL TEVA CONSEIL"},
{label: "AMBROXOL VIATRIS CONSEIL"},
{label: "AMBROXOL WINTHROP"},
{label: "AMBROXOL ZYDUS"},
{label: "AMELGEN"},
{label: "AMELUZ"},
{label: "AMERSCAN DMSA"},
{label: "AMERSCAN PENTETATE II"},
{label: "AMERSCAN TM MEDRONATE TM II"},
{label: "AMETYCINE"},
{label: "AMGEVITA"},
{label: "AMGLIDIA"},
{label: "AMICIC"},
{label: "AMIDAL"},
{label: "AMIDAL COLLUSPRAY"},
{label: "AMIFAMPRIDINE SERB"},
{label: "AMIFAMPRIDINE SUBSTIPHARM"},
{label: "AMIKACINE AGUETTANT"},
{label: "AMIKACINE B. BRAUN"},
{label: "AMIKACINE BMS"},
{label: "AMIKACINE KABI"},
{label: "AMIKACINE PHARMANOVA"},
{label: "AMIKACINE SANDOZ"},
{label: "AMIKACINE VIATRIS"},
{label: "AMIKACINE WINTHROP"},
{label: "AMIKLIN"},
{label: "AMILORIDE HYDROCHLOROTHIAZIDE TEVA"},
{label: "AMINCITEX"},
{label: "AMINOMIX"},
{label: "AMINOPHYLLINE RENAUDIN"},
{label: "AMINOPLASMAL"},
{label: "AMINOSTAB"},
{label: "AMINOSTERIL"},
{label: "AMINOSTYL"},
{label: "AMINOVEN"},
{label: "AMINOVENOS"},
{label: "AMIODARONE ACTAVIS"},
{label: "AMIODARONE AGUETTANT"},
{label: "AMIODARONE ALMUS"},
{label: "AMIODARONE ALTER"},
{label: "AMIODARONE ARROW"},
{label: "AMIODARONE ARROW LAB"},
{label: "AMIODARONE BIOGARAN"},
{label: "AMIODARONE CRISTERS"},
{label: "AMIODARONE DAKOTA"},
{label: "AMIODARONE EG"},
{label: "AMIODARONE G GAM"},
{label: "AMIODARONE ISOMED"},
{label: "AMIODARONE LEURQUIN MEDIOLANUM"},
{label: "AMIODARONE QUALIMED"},
{label: "AMIODARONE RATIOPHARM"},
{label: "AMIODARONE SANDOZ"},
{label: "AMIODARONE SUN"},
{label: "AMIODARONE TEVA"},
{label: "AMIODARONE TRADIPHAR"},
{label: "AMIODARONE VIATRIS"},
{label: "AMIODARONE ZENTIVA"},
{label: "AMIODARONE ZYDUS"},
{label: "AMIODIREX"},
{label: "AMIPED"},
{label: "AMIRIOX"},
{label: "AMISULPRIDE ACTAVIS"},
{label: "AMISULPRIDE ALTER"},
{label: "AMISULPRIDE ARROW"},
{label: "AMISULPRIDE ARROW LAB"},
{label: "AMISULPRIDE AUDEN"},
{label: "AMISULPRIDE BIOGARAN"},
{label: "AMISULPRIDE CRISTERS"},
{label: "AMISULPRIDE DAKOTA PHARM"},
{label: "AMISULPRIDE DEXTREG"},
{label: "AMISULPRIDE EG"},
{label: "AMISULPRIDE G GAM"},
{label: "AMISULPRIDE GERDA"},
{label: "AMISULPRIDE IVAX"},
{label: "AMISULPRIDE MG PHARMA"},
{label: "AMISULPRIDE MYLAN"},
{label: "AMISULPRIDE QUALIMED"},
{label: "AMISULPRIDE RATIOPHARM"},
{label: "AMISULPRIDE RPG"},
{label: "AMISULPRIDE SANDOZ"},
{label: "AMISULPRIDE SANOFI SYNTHELABO FRANCE"},
{label: "AMISULPRIDE SG-PHARM"},
{label: "AMISULPRIDE STRAGEN"},
{label: "AMISULPRIDE TEVA"},
{label: "AMISULPRIDE VIATRIS"},
{label: "AMISULPRIDE WYVERN"},
{label: "AMISULPRIDE WYVERN MEDICAL"},
{label: "AMISULPRIDE ZENTIVA"},
{label: "AMISULPRIDE ZYDUS"},
{label: "AMITRIPTYLINE SUBSTIPHARM"},
{label: "AMLODILATE"},
{label: "AMLODIPINE / VALSARTAN SFIP"},
{label: "AMLODIPINE AENORASIS"},
{label: "AMLODIPINE ALMUS"},
{label: "AMLODIPINE ALTER"},
{label: "AMLODIPINE ARROW"},
{label: "AMLODIPINE ARROW GENERIQUES"},
{label: "AMLODIPINE BGR"},
{label: "AMLODIPINE BIOGARAN"},
{label: "AMLODIPINE BOUCHARA-RECORDATI"},
{label: "AMLODIPINE CEVIDRA"},
{label: "AMLODIPINE CHEMICAL FARMA"},
{label: "AMLODIPINE CRISTERS"},
{label: "AMLODIPINE DCI PHARMA"},
{label: "AMLODIPINE EG"},
{label: "AMLODIPINE ENIREX"},
{label: "AMLODIPINE EVOLUGEN"},
{label: "AMLODIPINE EVOLUGEN PHARMA"},
{label: "AMLODIPINE GERDA"},
{label: "AMLODIPINE GNR"},
{label: "AMLODIPINE IBD3"},
{label: "AMLODIPINE ISOMED"},
{label: "AMLODIPINE KRKA D.D"},
{label: "AMLODIPINE MG PHARMA"},
{label: "AMLODIPINE MYLAN PHARMA"},
{label: "AMLODIPINE PHR LAB"},
{label: "AMLODIPINE QUALIHEALTH"},
{label: "AMLODIPINE QUALIMED"},
{label: "AMLODIPINE QUIVER"},
{label: "AMLODIPINE RATIOPHARM"},
{label: "AMLODIPINE SALEM FRANCE"},
{label: "AMLODIPINE SANDOZ"},
{label: "AMLODIPINE SUN"},
{label: "AMLODIPINE TEVA"},
{label: "AMLODIPINE VIATRIS"},
{label: "AMLODIPINE VIATRIS GENERIQUES"},
{label: "AMLODIPINE ZENTIVA"},
{label: "AMLODIPINE ZYDUS"},
{label: "AMLODIPINE/ATORVASTATINE VIATRIS GENERIQUES"},
{label: "AMLODIPINE/VALSARTAN ACCORD"},
{label: "AMLODIPINE/VALSARTAN ARROW"},
{label: "AMLODIPINE/VALSARTAN BIOGARAN"},
{label: "AMLODIPINE/VALSARTAN CRISTERS"},
{label: "AMLODIPINE/VALSARTAN EG"},
{label: "AMLODIPINE/VALSARTAN EVOLUGEN"},
{label: "AMLODIPINE/VALSARTAN KRKA"},
{label: "AMLODIPINE/VALSARTAN MYLAN"},
{label: "AMLODIPINE/VALSARTAN SANDOZ"},
{label: "AMLODIPINE/VALSARTAN TEVA"},
{label: "AMLODIPINE/VALSARTAN TEVA SANTE"},
{label: "AMLODIPINE/VALSARTAN ZENTIVA"},
{label: "AMLODIPINE/VALSARTAN ZYDUS"},
{label: "AMLOKAPS"},
{label: "AMLOR"},
{label: "AMLOTENSAN"},
{label: "AMMI VISNAGA BOIRON"},
{label: "AMMI VISNAGA WELEDA"},
{label: "AMMONAPS"},
{label: "AMMONIUM CARBONICUM BOIRON"},
{label: "AMMONIUM CARBONICUM LEHNING"},
{label: "AMMONIUM MURIATICUM BOIRON"},
{label: "AMMONIUM MURIATICUM LEHNING"},
{label: "AMMONIUM PHOSPHORICUM BOIRON"},
{label: "AMMONIUM PHOSPHORICUM LEHNING"},
{label: "AMODEX"},
{label: "AMOROLFINE ARROW"},
{label: "AMOROLFINE AUTHOU"},
{label: "AMOROLFINE BGR"},
{label: "AMOROLFINE BIOGARAN"},
{label: "AMOROLFINE BIOGARAN CONSEIL"},
{label: "AMOROLFINE CLEXNI"},
{label: "AMOROLFINE CLL"},
{label: "AMOROLFINE CLL PHARMA"},
{label: "AMOROLFINE CRISTERS"},
{label: "AMOROLFINE EG 5%"},
{label: "AMOROLFINE EG LABO CONSEIL"},
{label: "AMOROLFINE ISOMED 5%"},
{label: "AMOROLFINE PIERRE FABRE"},
{label: "AMOROLFINE PIERRE FABRE DERMATOLOGIE"},
{label: "AMOROLFINE RATIOPHARM 5%"},
{label: "AMOROLFINE SANDOZ 5%"},
{label: "AMOROLFINE SANDOZ CONSEIL"},
{label: "AMOROLFINE SET"},
{label: "AMOROLFINE SG-PHARM"},
{label: "AMOROLFINE SUBSTILAB"},
{label: "AMOROLFINE SUBSTIPHARM"},
{label: "AMOROLFINE TEVA 5%"},
{label: "AMOROLFINE URGO 5%"},
{label: "AMOROLFINE VIATRIS"},
{label: "AMOROLFINE VIATRIS CONSEIL"},
{label: "AMOROLFINE ZENTIVA"},
{label: "AMOROLFINE ZYDUS"},
{label: "AMOXICILLINE / ACIDE CLAVULANIQUE VIATRIS"},
{label: "AMOXICILLINE ACIDE CLAVULANIQUE ALMUS"},
{label: "AMOXICILLINE ACIDE CLAVULANIQUE ARROW"},
{label: "AMOXICILLINE ACIDE CLAVULANIQUE GSK"},
{label: "AMOXICILLINE ACIDE CLAVULANIQUE PANPHARMA"},
{label: "AMOXICILLINE ACIDE CLAVULANIQUE SANDOZ"},
{label: "AMOXICILLINE ACIDE CLAVULANIQUE SMITHKLINE BEECHAM"},
{label: "AMOXICILLINE ACIDE CLAVULANIQUE TEVA"},
{label: "AMOXICILLINE ACIDE CLAVULANIQUE ZYDUS FRANCE"},
{label: "AMOXICILLINE ACTAVIS"},
{label: "AMOXICILLINE ALMUS"},
{label: "AMOXICILLINE ALMUS PHARMA"},
{label: "AMOXICILLINE ALTER"},
{label: "AMOXICILLINE ARROW"},
{label: "AMOXICILLINE ARROW LAB"},
{label: "AMOXICILLINE AUROBINDO"},
{label: "AMOXICILLINE AUVEX"},
{label: "AMOXICILLINE BAILLY-CREAT"},
{label: "AMOXICILLINE BGR"},
{label: "AMOXICILLINE BIOGALENIQUE"},
{label: "AMOXICILLINE BIOGARAN"},
{label: "AMOXICILLINE BIOSTABILEX"},
{label: "AMOXICILLINE BMS"},
{label: "AMOXICILLINE BOUCHARA RECORDATI"},
{label: "AMOXICILLINE CENTRIENT"},
{label: "AMOXICILLINE CRISTERS"},
{label: "AMOXICILLINE DEXTREG"},
{label: "AMOXICILLINE EG"},
{label: "AMOXICILLINE EG LABO"},
{label: "AMOXICILLINE ELAIAPHARM"},
{label: "AMOXICILLINE EVOLUGEN"},
{label: "AMOXICILLINE FERLUX"},
{label: "AMOXICILLINE GENEVAR"},
{label: "AMOXICILLINE GNR"},
{label: "AMOXICILLINE GSK"},
{label: "AMOXICILLINE GSL"},
{label: "AMOXICILLINE KLEOS PHARMA"},
{label: "AMOXICILLINE KRKA"},
{label: "AMOXICILLINE MERCK GENERICS FRANCE HOLDING"},
{label: "AMOXICILLINE MICROLABS"},
{label: "AMOXICILLINE MYLAN"},
{label: "AMOXICILLINE MYLAN GENERIQUES"},
{label: "AMOXICILLINE MYLAN PHARMA"},
{label: "AMOXICILLINE NIALEX"},
{label: "AMOXICILLINE OPIH FRANCE"},
{label: "AMOXICILLINE PANPHARMA"},
{label: "AMOXICILLINE PIERRE FABRE"},
{label: "AMOXICILLINE PROGRAPHARM"},
{label: "AMOXICILLINE QUALIMED"},
{label: "AMOXICILLINE RATIOPHARM"},
{label: "AMOXICILLINE REF"},
{label: "AMOXICILLINE RPG"},
{label: "AMOXICILLINE SANDOZ"},
{label: "AMOXICILLINE SET"},
{label: "AMOXICILLINE SOLVAY PHARMA"},
{label: "AMOXICILLINE SUN"},
{label: "AMOXICILLINE TEVA"},
{label: "AMOXICILLINE TEVA PHARMA"},
{label: "AMOXICILLINE TEVA SANTE"},
{label: "AMOXICILLINE VIAREF"},
{label: "AMOXICILLINE VIATRIS"},
{label: "AMOXICILLINE WINTHROP"},
{label: "AMOXICILLINE ZENTIVA"},
{label: "AMOXICILLINE ZENTIVA LAB"},
{label: "AMOXICILLINE ZYDUS"},
{label: "AMOXICILLINE ZYDUS FRANCE"},
{label: "AMOXICILLINE-ACIDE CLAVULANIQUE QUALIMED"},
{label: "AMOXICILLINE/ACIDE CLAVULANIQUE ACTAVIS"},
{label: "AMOXICILLINE/ACIDE CLAVULANIQUE ALMUS"},
{label: "AMOXICILLINE/ACIDE CLAVULANIQUE ARROW"},
{label: "AMOXICILLINE/ACIDE CLAVULANIQUE ARROW LAB"},
{label: "AMOXICILLINE/ACIDE CLAVULANIQUE BGR"},
{label: "AMOXICILLINE/ACIDE CLAVULANIQUE BIOGARAN"},
{label: "AMOXICILLINE/ACIDE CLAVULANIQUE BIOSTABILEX"},
{label: "AMOXICILLINE/ACIDE CLAVULANIQUE CRISTERS"},
{label: "AMOXICILLINE/ACIDE CLAVULANIQUE EG"},
{label: "AMOXICILLINE/ACIDE CLAVULANIQUE EG LABO-LABORATOIRES EUROGENERICS"},
{label: "AMOXICILLINE/ACIDE CLAVULANIQUE GENERES"},
{label: "AMOXICILLINE/ACIDE CLAVULANIQUE GLAXOSMITHKLINE"},
{label: "AMOXICILLINE/ACIDE CLAVULANIQUE GSK"},
{label: "AMOXICILLINE/ACIDE CLAVULANIQUE KABI"},
{label: "AMOXICILLINE/ACIDE CLAVULANIQUE MEDIPHA SANTE"},
{label: "AMOXICILLINE/ACIDE CLAVULANIQUE PANPHARMA"},
{label: "AMOXICILLINE/ACIDE CLAVULANIQUE QUALIMED"},
{label: "AMOXICILLINE/ACIDE CLAVULANIQUE RANBAXY"},
{label: "AMOXICILLINE/ACIDE CLAVULANIQUE RATIOPHARM"},
{label: "AMOXICILLINE/ACIDE CLAVULANIQUE SANDOZ"},
{label: "AMOXICILLINE/ACIDE CLAVULANIQUE SUN"},
{label: "AMOXICILLINE/ACIDE CLAVULANIQUE TEVA"},
{label: "AMOXICILLINE/ACIDE CLAVULANIQUE VIATRIS"},
{label: "AMOXICILLINE/ACIDE CLAVULANIQUE ZENTIVA"},
{label: "AMOXICILLINE/ACIDE CLAVULANIQUE ZENTIVA K.S"},
{label: "AMOXICILLINE/ACIDE CLAVULANIQUE ZENTIVA LAB"},
{label: "AMOXICILLINE/ACIDE CLAVULANIQUE ZYDUS"},
{label: "AMOXICILLINE/ACIDE CLAVULANIQUE ZYDUS FRANCE"},
{label: "AMOXINE"},
{label: "AMOXIREX"},
{label: "AMPECYCLAL"},
{label: "AMPELOPSIS QUINQUEFOLIA BOIRON"},
{label: "AMPHOCYCLINE"},
{label: "AMPHOSCA ORCHITYN"},
{label: "AMPHOSCA OVARYN"},
{label: "AMPICILLINE DAKOTA PHARM"},
{label: "AMPICILLINE PANPHARMA"},
{label: "AMPICILLINE PANPHARMA ENFANTS"},
{label: "AMPICILLINE RPG"},
{label: "AMPICILLINE RPG ENFANTS ET NOURRISSONS"},
{label: "AMPICILLINE ZYDUS"},
{label: "AMPLAMIL"},
{label: "AMPLIVAL"},
{label: "AMSALYO"},
{label: "AMSIDINE"},
{label: "AMSPARITY"},
{label: "AMUKINE"},
{label: "AMVUTTRA"},
{label: "AMYCOR"},
{label: "AMYCOR ONYCHOSET"},
{label: "AMYGDALES BOIRON"},
{label: "AMYGDOL"},
{label: "AMYGDORECTOL ADULTES"},
{label: "AMYGDORECTOL ENFANTS"},
{label: "AMYLIUM NITROSUM BOIRON"},
{label: "AMYLIUM NITROSUM LEHNING"},
{label: "AMYLMETACRESOL/ ALCOOL DICHLOROBENZYLIQUE BIOGARAN CONSEIL"},
{label: "AMYLMETACRESOL/ ALCOOL DICHLOROBENZYLIQUE MYLAN CONSEIL"},
{label: "AMYLMETACRESOL/ALCOOL DICHLOROBENZYLIQUE BIOGARAN CONSEIL"},
{label: "AMYLMETACRESOL/ALCOOL DICHLOROBENZYLIQUE VIATRIS CONSEIL"},
{label: "AMYLMETACRESOL/ALCOOL DICHLOROBENZYLIQUE/LIDOCAINE BIOGARAN CONSEIL"},
{label: "AMYLODIASTASE"},
{label: "AMYVID"},
{label: "AMYXEDYL"},
{label: "ANACARDIUM OCCIDENTALE BOIRON"},
{label: "ANACARDIUM ORIENTALE BOIRON"},
{label: "ANACARDIUM ORIENTALE LEHNING"},
{label: "ANACARDIUM ORIENTALE WELEDA"},
{label: "ANACINE"},
{label: "ANACORYL"},
{label: "ANAFRANIL"},
{label: "ANAGALLIS ARVENSIS"},
{label: "ANAGALLIS ARVENSIS BOIRON"},
{label: "ANAGALLIS ARVENSIS LEHNING"},
{label: "ANAGREG"},
{label: "ANAGRELIDE ARROW"},
{label: "ANAGRELIDE BIOGARAN"},
{label: "ANAGRELIDE EG"},
{label: "ANAGRELIDE MYLAN"},
{label: "ANAGRELIDE SANDOZ"},
{label: "ANAGRELIDE TEVA"},
{label: "ANAGRELIDE ZENTIVA"},
{label: "ANAHELP"},
{label: "ANAKIT"},
{label: "ANANDRON"},
{label: "ANANTHERUM MURICATUM BOIRON"},
{label: "ANAPEN"},
{label: "ANASTRAZOLE DELBERT"},
{label: "ANASTROZOLE ACCORD"},
{label: "ANASTROZOLE ACTAVIS"},
{label: "ANASTROZOLE ALMUS"},
{label: "ANASTROZOLE ALTER"},
{label: "ANASTROZOLE ARROW"},
{label: "ANASTROZOLE ARROW LAB"},
{label: "ANASTROZOLE BIOGARAN"},
{label: "ANASTROZOLE BIOSTABILEX"},
{label: "ANASTROZOLE BLUEFISH"},
{label: "ANASTROZOLE CHEMICAL FARMA"},
{label: "ANASTROZOLE CLL PHARMA"},
{label: "ANASTROZOLE CRISTERS"},
{label: "ANASTROZOLE EBEWE"},
{label: "ANASTROZOLE EG"},
{label: "ANASTROZOLE EVOLUGEN"},
{label: "ANASTROZOLE ISOMED"},
{label: "ANASTROZOLE KABI"},
{label: "ANASTROZOLE KRKA"},
{label: "ANASTROZOLE MYLAN"},
{label: "ANASTROZOLE NICHE GENERICS"},
{label: "ANASTROZOLE PHR LAB"},
{label: "ANASTROZOLE QUALIMED"},
{label: "ANASTROZOLE RATIOPHARM"},
{label: "ANASTROZOLE REMEDICA"},
{label: "ANASTROZOLE SANDOZ"},
{label: "ANASTROZOLE SUBSTIPHARM"},
{label: "ANASTROZOLE SUN"},
{label: "ANASTROZOLE TEVA"},
{label: "ANASTROZOLE VIATRIS"},
{label: "ANASTROZOLE ZENTIVA"},
{label: "ANASTROZOLE ZYDUS"},
{label: "ANAUSIN METOCLOPRAMIDE"},
{label: "ANAVASTOR"},
{label: "ANAXERYL"},
{label: "ANCHUSA OFFICINALIS BOIRON"},
{label: "ANCOTIL"},
{label: "ANDOZAC"},
{label: "ANDRACTIM"},
{label: "ANDROCUR"},
{label: "ANDROGEL"},
{label: "ANDROTARDYL"},
{label: "ANELIE"},
{label: "ANESDERM"},
{label: "ANETENA"},
{label: "ANETH NATURA MEDICA"},
{label: "ANEXATE"},
{label: "ANFALOXYL"},
{label: "ANGELICA ARCHANGELICA BOIRON"},
{label: "ANGELICA ARCHANGELICA DECOCTE WELEDA"},
{label: "ANGELICA ARCHANGELICA LEHNING"},
{label: "ANGELICA SYLVESTRIS BOIRON"},
{label: "ANGELIQ"},
{label: "ANGI SPRAY"},
{label: "ANGI-SPRAY MAL DE GORGE CHLORHEXIDINE/LIDOCAÏNE"},
{label: "ANGI-SPRAY MAL DE GORGE HEXAMIDINE/TETRACAINE"},
{label: "ANGIOCIS."},
{label: "ANGIOGRAFINE"},
{label: "ANGIOLONG LP"},
{label: "ANGIOPHTAL"},
{label: "ANGIOX"},
{label: "ANGIPAX"},
{label: "ANGITRINE LP"},
{label: "ANGUSTA"},
{label: "ANGUSTURA VERA BOIRON"},
{label: "ANGUSTURA VERA LEHNING"},
{label: "ANISUM STELLATUM BOIRON"},
{label: "ANOLAN"},
{label: "ANOPIDAL"},
{label: "ANOREINE"},
{label: "ANOREX"},
{label: "ANORO ELLIPTA"},
{label: "ANSATIPINE"},
{label: "ANTADYS"},
{label: "ANTAFATE"},
{label: "ANTAGOSAN"},
{label: "ANTALCALM"},
{label: "ANTALFATE"},
{label: "ANTALNOX"},
{label: "ANTALVIC ADULTES"},
{label: "ANTALYRE"},
{label: "ANTARENE"},
{label: "ANTARENE CODEINE"},
{label: "ANTASOL"},
{label: "ANTASPIRINE"},
{label: "ANTEBOR"},
{label: "ANTHEMIS NOBILIS BOIRON"},
{label: "ANTHIA"},
{label: "ANTHRACINUM BOIRON"},
{label: "ANTHRANOL"},
{label: "ANTHYLLINE"},
{label: "ANTI - H"},
{label: "ANTI-HEMORROIDAIRE CASSENNE"},
{label: "ANTIBIO SYNALAR"},
{label: "ANTIBIOTREX"},
{label: "ANTIBIOTULLE LUMIERE"},
{label: "ANTICOR DU JUIF ERRANT"},
{label: "ANTICOR DU MASSIF DE CHARTREUSE"},
{label: "ANTIGONE"},
{label: "ANTIGRIPPINE A L'ASPIRINE ETAT GRIPPAL"},
{label: "ANTIGRIPPINE AU PARACETAMOL ETAT GRIPPAL"},
{label: "ANTIMONIUM ARSENICOSUM BOIRON"},
{label: "ANTIMONIUM ARSENICOSUM WELEDA"},
{label: "ANTIMONIUM CRUDUM BOIRON"},
{label: "ANTIMONIUM CRUDUM LEHNING"},
{label: "ANTIMONIUM IODATUM BOIRON"},
{label: "ANTIMONIUM METALLICUM 6DH WELEDA"},
{label: "ANTIMONIUM METALLICUM BOIRON"},
{label: "ANTIMONIUM METALLICUM LEHNING"},
{label: "ANTIMONIUM METALLICUM WELEDA"},
{label: "ANTIMONIUM SULFURATUM AUREUM BOIRON"},
{label: "ANTIMONIUM SULFURATUM AUREUM WELEDA"},
{label: "ANTIMONIUM TARTARICUM BOIRON"},
{label: "ANTIMONIUM TARTARICUM LEHNING"},
{label: "ANTIMONIUM TARTARICUM WELEDA"},
{label: "ANTINERVEUX LESOURD"},
{label: "ANTIPHLOGISTINE"},
{label: "ANTISEPTIQUE CALMANTE"},
{label: "ANTISTAX"},
{label: "ANTURAN"},
{label: "ANUSOL"},
{label: "ANXEMIL"},
{label: "ANXIETUM"},
{label: "ANXORAL"},
{label: "ANXYREX"},
{label: "ANY"},
{label: "ANZEMET"},
{label: "AODRIN"},
{label: "AORTE BOIRON"},
{label: "AOTAL"},
{label: "APACEF"},
{label: "APAISYL"},
{label: "APAISYLGEL"},
{label: "APAROXAL"},
{label: "APATITE WELEDA"},
{label: "APEGMONE"},
{label: "APER ODDI"},
{label: "APEXXNAR"},
{label: "APHILAN"},
{label: "APHILAN DEMANGEAISONS"},
{label: "APHLOINE P"},
{label: "APHTORAL"},
{label: "APIDRA"},
{label: "APIDRA SOLOSTAR"},
{label: "APILAXE"},
{label: "APIREX"},
{label: "APIS MELLIFICA BOIRON"},
{label: "APIS MELLIFICA LEHNING"},
{label: "APIS MELLIFICA WELEDA"},
{label: "APIUM DULCE BOIRON"},
{label: "APIUM GRAVEOLENS BOIRON"},
{label: "APIXABAN BIOGARAN"},
{label: "APIXABAN EDEST"},
{label: "APIXABAN EG"},
{label: "APIXABAN KRKA"},
{label: "APIXABAN NEWLINE PHARMA"},
{label: "APIXABAN PHARMAKI"},
{label: "APIXABAN SANDOZ"},
{label: "APIXABAN TEVA"},
{label: "APIXABAN TEVA SANTE"},
{label: "APIXABAN VIATRIS"},
{label: "APIXABAN ZYDUS"},
{label: "APOCYNUM CANNABINUM BOIRON"},
{label: "APOGO"},
{label: "APOKINON"},
{label: "APOMORPHINE ARCHIMEDES PHARMA FRANCE"},
{label: "APOMORPHINE BIOGARAN"},
{label: "APOMORPHINE EVER PHARMA"},
{label: "APOMORPHINE KALCEKS"},
{label: "APOMORPHINE VIATRIS"},
{label: "APORTEX"},
{label: "APOTEL"},
{label: "APRANAX"},
{label: "APREPITANT ACCORD"},
{label: "APREPITANT ARROW"},
{label: "APREPITANT BIOGARAN"},
{label: "APREPITANT CRISTERS"},
{label: "APREPITANT EG"},
{label: "APREPITANT EVOLUGEN"},
{label: "APREPITANT QILU"},
{label: "APREPITANT REDDY PHARMA"},
{label: "APREPITANT SANDOZ"},
{label: "APREPITANT TEVA"},
{label: "APREPITANT VIATRIS"},
{label: "APREPITANT ZENTIVA"},
{label: "APREXEVO"},
{label: "APROKAM"},
{label: "APROVEL"},
{label: "APSOR"},
{label: "APTIVUS"},
{label: "APULCO"},
{label: "APURONE"},
{label: "AQUA MARINA BOIRON"},
{label: "AQUA MARINA WELEDA"},
{label: "AQUALARM"},
{label: "AQUAREST"},
{label: "AQUILEGIA VULGARIS BOIRON"},
{label: "ARACYTINE"},
{label: "ARALIA RACEMOSA BOIRON"},
{label: "ARALIA RACEMOSA LEHNING"},
{label: "ARANEA DIADEMA BOIRON"},
{label: "ARANEA DIADEMA LEHNING"},
{label: "ARANEA DIADEMA WELEDA"},
{label: "ARANESP"},
{label: "ARAVA"},
{label: "ARBUTUS ANDRACHNE BOIRON"},
{label: "ARBUTUS UNEDO BOIRON"},
{label: "ARCALION"},
{label: "ARCOXIA"},
{label: "AREDIA"},
{label: "ARESTAL"},
{label: "ARGANOVA"},
{label: "ARGATROBAN ACCORD"},
{label: "ARGEMONE MEXICANA BOIRON"},
{label: "ARGENT NATIF BOIRON"},
{label: "ARGENTITE WELEDA"},
{label: "ARGENTUM COMPLEXE N°98"},
{label: "ARGENTUM METALLICUM BOIRON"},
{label: "ARGENTUM METALLICUM LEHNING"},
{label: "ARGENTUM METALLICUM WELEDA"},
{label: "ARGENTUM NITRICUM BOIRON"},
{label: "ARGENTUM NITRICUM FERRIER"},
{label: "ARGENTUM NITRICUM LEHNING"},
{label: "ARGENTUM NITRICUM WELEDA"},
{label: "ARGICILLINE"},
{label: "ARGININE GLUCOSE VEYRON"},
{label: "ARGININE GLUTAMIQUE SERB"},
{label: "ARGININE VEYRON"},
{label: "ARGINOTRI-B"},
{label: "ARGYROPHEDRINE"},
{label: "ARGYROPHEDRINE ADULTES"},
{label: "ARHEMAPECTINE"},
{label: "ARHUMYL"},
{label: "ARICEPT"},
{label: "ARIDOL"},
{label: "ARIELLE"},
{label: "ARIKAYCE LIPOSOMAL"},
{label: "ARIMIDEX"},
{label: "ARIPIPRAZOLE ACCORD"},
{label: "ARIPIPRAZOLE ALMUS"},
{label: "ARIPIPRAZOLE ARROW"},
{label: "ARIPIPRAZOLE BGR"},
{label: "ARIPIPRAZOLE BIOGARAN"},
{label: "ARIPIPRAZOLE CRISTERS"},
{label: "ARIPIPRAZOLE EG"},
{label: "ARIPIPRAZOLE EVOLUGEN"},
{label: "ARIPIPRAZOLE HEC PHARM"},
{label: "ARIPIPRAZOLE KRKA"},
{label: "ARIPIPRAZOLE MEDIPHA"},
{label: "ARIPIPRAZOLE MYLAN"},
{label: "ARIPIPRAZOLE MYLAN PHARMA"},
{label: "ARIPIPRAZOLE PHARMAKI GENERICS"},
{label: "ARIPIPRAZOLE RANBAXY"},
{label: "ARIPIPRAZOLE SANDOZ"},
{label: "ARIPIPRAZOLE TEVA"},
{label: "ARIPIPRAZOLE VIATRIS"},
{label: "ARIPIPRAZOLE ZENTIVA"},
{label: "ARIPIPRAZOLE ZYDUS"},
{label: "ARISTOLOCHIA CLEMATITIS BOIRON"},
{label: "ARISTOLOCHIA CLEMATITIS LEHNING"},
{label: "ARIXTRA"},
{label: "ARKOCYST"},
{label: "ARKOFUSETTES ANGELIQUE FRUIT"},
{label: "ARKOFUSETTES ANIS VERT"},
{label: "ARKOFUSETTES ARTICHAUT"},
{label: "ARKOFUSETTES ASPERULE ODORANTE"},
{label: "ARKOFUSETTES AUBEPINE"},
{label: "ARKOFUSETTES AUBIER DE TILLEUL"},
{label: "ARKOFUSETTES BARDANE"},
{label: "ARKOFUSETTES BOLDO"},
{label: "ARKOFUSETTES BOUILLON BLANC"},
{label: "ARKOFUSETTES BOULEAU"},
{label: "ARKOFUSETTES BOURDAINE"},
{label: "ARKOFUSETTES BRUYERE"},
{label: "ARKOFUSETTES CASSIS"},
{label: "ARKOFUSETTES CHIENDENT"},
{label: "ARKOFUSETTES COQUELICOT"},
{label: "ARKOFUSETTES CYNORRHODON"},
{label: "ARKOFUSETTES D'AIRELLE MYRTILLE"},
{label: "ARKOFUSETTES DE MAUVE SAUVAGE"},
{label: "ARKOFUSETTES DE THE VERT"},
{label: "ARKOFUSETTES DE VIGNE ROUGE"},
{label: "ARKOFUSETTES EUCALYPTUS"},
{label: "ARKOFUSETTES FENOUIL"},
{label: "ARKOFUSETTES FRENE"},
{label: "ARKOFUSETTES FUMETERRE"},
{label: "ARKOFUSETTES HAMAMELIS"},
{label: "ARKOFUSETTES HARPAGOPHYTUM"},
{label: "ARKOFUSETTES HOUBLON"},
{label: "ARKOFUSETTES KOLA"},
{label: "ARKOFUSETTES LAMIER BLANC"},
{label: "ARKOFUSETTES LAVANDE"},
{label: "ARKOFUSETTES LIERRE TERRESTRE"},
{label: "ARKOFUSETTES MARRONNIER D'INDE (ECORCE)"},
{label: "ARKOFUSETTES MATE"},
{label: "ARKOFUSETTES MELILOT"},
{label: "ARKOFUSETTES MELISSE"},
{label: "ARKOFUSETTES MILLEFEUILLE"},
{label: "ARKOFUSETTES OLIVIER"},
{label: "ARKOFUSETTES ORTHOSIPHON"},
{label: "ARKOFUSETTES PASSIFLORE"},
{label: "ARKOFUSETTES PENSEE SAUVAGE"},
{label: "ARKOFUSETTES PILOSELLE"},
{label: "ARKOFUSETTES PISSENLIT"},
{label: "ARKOFUSETTES REINE DES PRES"},
{label: "ARKOFUSETTES ROMARIN"},
{label: "ARKOFUSETTES SAUGE D'ESPAGNE"},
{label: "ARKOFUSETTES SAULE"},
{label: "ARKOFUSETTES SENE"},
{label: "ARKOFUSETTES STYLE DE MAIS"},
{label: "ARKOFUSETTES SUREAU ECORCE"},
{label: "ARKOFUSETTES THYM"},
{label: "ARKOFUSETTES VALERIANE"},
{label: "ARKOFUSETTES VERGERETTE DU CANADA"},
{label: "ARKOGELULES ALOES"},
{label: "ARKOGELULES ANGELIQUE"},
{label: "ARKOGELULES ARKORODON"},
{label: "ARKOGELULES ARMOISE"},
{label: "ARKOGELULES ARTICHAUT"},
{label: "ARKOGELULES AUBEPINE"},
{label: "ARKOGELULES AUBIER DE TILLEUL"},
{label: "ARKOGELULES BALLOTE"},
{label: "ARKOGELULES BARDANE"},
{label: "ARKOGELULES BOLDO"},
{label: "ARKOGELULES BOUILLON BLANC"},
{label: "ARKOGELULES BOULEAU"},
{label: "ARKOGELULES BOURDAINE"},
{label: "ARKOGELULES BOURGEONS DE PIN"},
{label: "ARKOGELULES BRUYERE"},
{label: "ARKOGELULES BUSSEROLE"},
{label: "ARKOGELULES CAMILINE"},
{label: "ARKOGELULES CASSIS"},
{label: "ARKOGELULES CHARBON VEGETAL"},
{label: "ARKOGELULES CHARDON MARIE"},
{label: "ARKOGELULES CHIENDENT"},
{label: "ARKOGELULES COMBRETUM"},
{label: "ARKOGELULES COQUELICOT"},
{label: "ARKOGELULES CYPRES"},
{label: "ARKOGELULES ELEUTHEROCOQUE"},
{label: "ARKOGELULES ESCHSCHOLTZIA"},
{label: "ARKOGELULES EUCALYPTUS"},
{label: "ARKOGELULES FENOUIL"},
{label: "ARKOGELULES FENUGREC"},
{label: "ARKOGELULES FRAGON"},
{label: "ARKOGELULES FRENE"},
{label: "ARKOGELULES FUCUS"},
{label: "ARKOGELULES FUMETERRE"},
{label: "ARKOGELULES GINSENG"},
{label: "ARKOGELULES GOMME DE CAROUBE"},
{label: "ARKOGELULES GUARANA"},
{label: "ARKOGELULES HAMAMELIS"},
{label: "ARKOGELULES HARPAGOPHYTON"},
{label: "ARKOGELULES HOUBLON"},
{label: "ARKOGELULES HUILE DE FOIE DE MORUE"},
{label: "ARKOGELULES KARAYA"},
{label: "ARKOGELULES KOLA"},
{label: "ARKOGELULES LAMIER BLANC"},
{label: "ARKOGELULES LAVANDE"},
{label: "ARKOGELULES LIERRE TERRESTRE"},
{label: "ARKOGELULES MARRONNIER D'INDE"},
{label: "ARKOGELULES MARRUBE BLANC"},
{label: "ARKOGELULES MATE"},
{label: "ARKOGELULES MAUVE"},
{label: "ARKOGELULES MELILOT"},
{label: "ARKOGELULES MELISSE"},
{label: "ARKOGELULES MILLEFEUILLE"},
{label: "ARKOGELULES MILLEPERTUIS"},
{label: "ARKOGELULES MYRTILLE BAIE"},
{label: "ARKOGELULES MYRTILLE FEUILLE"},
{label: "ARKOGELULES OLIVIER"},
{label: "ARKOGELULES ORTHOSIPHON"},
{label: "ARKOGELULES ORTIE"},
{label: "ARKOGELULES PAPAYER"},
{label: "ARKOGELULES PARTENELLE"},
{label: "ARKOGELULES PASSIFLORE"},
{label: "ARKOGELULES PENSEE SAUVAGE"},
{label: "ARKOGELULES PETITE CENTAUREE"},
{label: "ARKOGELULES PILOSELLE"},
{label: "ARKOGELULES PISSENLIT"},
{label: "ARKOGELULES PRELE"},
{label: "ARKOGELULES QUEUES DE CERISE"},
{label: "ARKOGELULES RACINE D'ORTIE"},
{label: "ARKOGELULES RACINE DE FENOUIL"},
{label: "ARKOGELULES REINE DES PRES"},
{label: "ARKOGELULES ROMARIN"},
{label: "ARKOGELULES SAUGE D'ESPAGNE"},
{label: "ARKOGELULES SAULE"},
{label: "ARKOGELULES SENE"},
{label: "ARKOGELULES SOLIDAGE"},
{label: "ARKOGELULES STYLE DE MAIS"},
{label: "ARKOGELULES THE VERT"},
{label: "ARKOGELULES THYM"},
{label: "ARKOGELULES VALERIANE"},
{label: "ARKOGELULES VERGERETTE DU CANADA"},
{label: "ARKOGELULES VIGNE ROUGE"},
{label: "ARKOLAX"},
{label: "ARKOLINE"},
{label: "ARKOPHYTUM"},
{label: "ARKOVITAL C"},
{label: "ARKOVITAL VITAMINE E"},
{label: "ARMAG"},
{label: "ARMISARTE"},
{label: "ARMOISE NATURA MEDICA"},
{label: "ARMOPHYLLINE L P"},
{label: "ARNICA COMPLEXE N°1"},
{label: "ARNICA DU SAINT-BERNARD"},
{label: "ARNICA MONTANA"},
{label: "ARNICA MONTANA 20DH WELEDA"},
{label: "ARNICA MONTANA BOIRON"},
{label: "ARNICA MONTANA FERRIER"},
{label: "ARNICA MONTANA LEHNING"},
{label: "ARNICA MONTANA LEHNING TEINTURE MERE"},
{label: "ARNICA MONTANA TEINTURE MERE BOIRON"},
{label: "ARNICA MONTANA TEINTURE MERE WELEDA"},
{label: "ARNICA MONTANA WELEDA"},
{label: "ARNICAGEL"},
{label: "ARNICALME"},
{label: "ARNICAN"},
{label: "ARNIGEL"},
{label: "ARNITROSIUM"},
{label: "AROLAC"},
{label: "AROMABYL"},
{label: "AROMASINE"},
{label: "AROMASOL"},
{label: "AROVIT"},
{label: "ARPARIAL"},
{label: "ARPHOS"},
{label: "ARPLEXAM"},
{label: "ARSACOL"},
{label: "ARSENIC TRIOXIDE ACCORD"},
{label: "ARSENIC TRIOXIDE MEDAC"},
{label: "ARSENIC TRIOXIDE MYLAN"},
{label: "ARSENIC TRIOXYDE ARROW"},
{label: "ARSENICUM ALBUM BOIRON"},
{label: "ARSENICUM ALBUM FERRIER"},
{label: "ARSENICUM ALBUM LEHNING"},
{label: "ARSENICUM ALBUM WELEDA"},
{label: "ARSENICUM IODATUM BOIRON"},
{label: "ARSENICUM IODATUM LEHNING"},
{label: "ARSENICUM IODATUM WELEDA"},
{label: "ARSENICUM METALLICUM BOIRON"},
{label: "ARSIQUINOFORME"},
{label: "ARSOBAL"},
{label: "ART"},
{label: "ARTANE"},
{label: "ARTELAC"},
{label: "ARTEMISIA ABSINTHIUM"},
{label: "ARTEMISIA VULGARIS BOIRON"},
{label: "ARTEMISIA VULGARIS LEHNING"},
{label: "ARTENSIUM"},
{label: "ARTERASE"},
{label: "ARTERE BOIRON"},
{label: "ARTERE CORONAIRE BOIRON"},
{label: "ARTERE WELEDA"},
{label: "ARTEX"},
{label: "ARTHRISONE"},
{label: "ARTHRITISANE"},
{label: "ARTHRO-DRAINOL"},
{label: "ARTHROCINE"},
{label: "ARTHRODONT"},
{label: "ARTHROFLORINE"},
{label: "ARTICADENT 1/100"},
{label: "ARTICADENT 1/200"},
{label: "ARTICERINE"},
{label: "ARTICHAUT APHLOIA PYROLE CILFA DEVELOPPEMENT"},
{label: "ARTICHAUT BOIRON"},
{label: "ARTICHAUT ETHYPHARM"},
{label: "ARTICHAUT NATURA MEDICA"},
{label: "ARTINIBSA"},
{label: "ARTIREM"},
{label: "ARTISIAL"},
{label: "ARTISS"},
{label: "ARTIVAL"},
{label: "ARTIZ"},
{label: "ARTOTEC"},
{label: "ARTRALGON"},
{label: "ARTROPHYTUM"},
{label: "ARUM DRACUNCULUS BOIRON"},
{label: "ARUM MACULATUM BOIRON"},
{label: "ARUM TRIPHYLLUM BOIRON"},
{label: "ARUM TRIPHYLLUM COMPOSE BOIRON"},
{label: "ARUM TRIPHYLLUM LEHNING"},
{label: "ARUM TRIPHYLLUM WELEDA"},
{label: "ARUNDO DONAX BOIRON"},
{label: "ARUNDO DONAX LEHNING"},
{label: "ARUNDO DONAX WELEDA"},
{label: "ARVACORAM"},
{label: "ARVENUM"},
{label: "ARZERRA"},
{label: "ASA FOETIDA BOIRON"},
{label: "ASA FOETIDA LEHNING"},
{label: "ASA FOETIDA WELEDA"},
{label: "ASAD POLLENS"},
{label: "ASARUM EUROPAEUM BOIRON"},
{label: "ASASANTINE L.P."},
{label: "ASCABIOL"},
{label: "ASCLEPIAS TUBEROSA BOIRON"},
{label: "ASCLEPIAS VINCETOXICUM BOIRON"},
{label: "ASCOFER"},
{label: "ASCORBATE DE CALCIUM RICHARD"},
{label: "ASCORBATE DE CALCIUM RICHARD ADULTES"},
{label: "ASCORTONYL"},
{label: "ASEMBIX"},
{label: "ASIMINA TRILOBA BOIRON"},
{label: "ASMABEC CLICKHALER"},
{label: "ASMANEX TWISTHALER"},
{label: "ASMASAL CLICKHALER"},
{label: "ASMELOR NOVOLIZER"},
{label: "ASPARAGUS OFFICINALIS BOIRON"},
{label: "ASPAVELI"},
{label: "ASPEGIC"},
{label: "ASPEGIC ADULTES"},
{label: "ASPEGIC CODEINE"},
{label: "ASPEGIC ENFANTS"},
{label: "ASPEGIC INJECTABLE"},
{label: "ASPEGIC NOURRISSONS"},
{label: "ASPERGILLUS BRONCHIALIS BOIRON"},
{label: "ASPERGILLUS NIGER BOIRON"},
{label: "ASPERULA ODORATA BOIRON"},
{label: "ASPIDIUM FILIX MAS BOIRON"},
{label: "ASPIRANGE"},
{label: "ASPIRINE"},
{label: "ASPIRINE ARROW"},
{label: "ASPIRINE BAYER"},
{label: "ASPIRINE CODEINE CAFEINE COOPER"},
{label: "ASPIRINE CODEINE GIFRER"},
{label: "ASPIRINE DU RHONE"},
{label: "ASPIRINE DU RHONE VITAMINE C"},
{label: "ASPIRINE DU RHÔNE"},
{label: "ASPIRINE LAFRAN"},
{label: "ASPIRINE MERCK MEDICATION FAMILIALE"},
{label: "ASPIRINE MYLAN"},
{label: "ASPIRINE OBERLIN"},
{label: "ASPIRINE PIERRE FABRE"},
{label: "ASPIRINE PROTECT"},
{label: "ASPIRINE RATIO"},
{label: "ASPIRINE RATIOPHARM"},
{label: "ASPIRINE RICHARD"},
{label: "ASPIRINE SAUBA"},
{label: "ASPIRINE SOLUBLE BOUCHARA RECORDATI"},
{label: "ASPIRINE TEVA"},
{label: "ASPIRINE THERAPLIX"},
{label: "ASPIRINE UPSA"},
{label: "ASPIRINE UPSA TAMPONNEE EFFERVESCENTE"},
{label: "ASPIRINE UPSA VITAMINEE C TAMPONNEE EFFERVESCENTE"},
{label: "ASPIRINE VITAMINE C ARROW CONSEIL"},
{label: "ASPIRINE VITAMINEE B1 C CRISTERS"},
{label: "ASPIRIPHARM"},
{label: "ASPIRISUCRE"},
{label: "ASPRADOL"},
{label: "ASPRO"},
{label: "ASPRO CAFEINE"},
{label: "ASPRO EFFERVESCENT"},
{label: "ASPRO ETAT GRIPPAL"},
{label: "ASPROFEN"},
{label: "ASPROFLASH"},
{label: "ASSAGIX"},
{label: "ASSOCIATION OESTRO PROGESTATIVE BIPHASIQUE ETHINYLESTRADIOL LEVONORGESTREL AYERST 30-150"},
{label: "ASSOCIATION OESTRO PROGESTATIVE ETHINYLESTRADIOL LEVONORGESTREL AYERST"},
{label: "ASSOCIATION OESTRO PROGESTATIVE ETHINYLESTRADIOL NORGESTREL AYERST"},
{label: "ASTACUS FLUVIATILIS BOIRON"},
{label: "ASTARIOR"},
{label: "ASTERIAS RUBENS BOIRON"},
{label: "ASTERLUNA"},
{label: "ASTERLUNA CONTINU"},
{label: "ASTHENOR"},
{label: "ASTHMASEDINE"},
{label: "ASTRIX"},
{label: "ASTYL"},
{label: "ATACAND"},
{label: "ATANTO"},
{label: "ATARAX"},
{label: "ATAZANAVIR ARROW"},
{label: "ATAZANAVIR BIOGARAN"},
{label: "ATAZANAVIR KRKA"},
{label: "ATAZANAVIR MYLAN"},
{label: "ATAZANAVIR SANDOZ"},
{label: "ATAZANAVIR TEVA"},
{label: "ATAZANAVIR ZENTIVA"},
{label: "ATEBEMYXINE"},
{label: "ATEBLOX"},
{label: "ATECTURA BREEZHALER"},
{label: "ATEDE"},
{label: "ATENATIV"},
{label: "ATENOLOL"},
{label: "ATENOLOL ACCORD HEALTHCARE"},
{label: "ATENOLOL ACTAVIS"},
{label: "ATENOLOL ACTAVIS FRANCE"},
{label: "ATENOLOL APOTEX FRANCE"},
{label: "ATENOLOL ARROW"},
{label: "ATENOLOL ARROW LAB"},
{label: "ATENOLOL BIOGARAN"},
{label: "ATENOLOL EG"},
{label: "ATENOLOL EVOLUGEN"},
{label: "ATENOLOL QUALIMED"},
{label: "ATENOLOL SANDOZ"},
{label: "ATENOLOL SFDB"},
{label: "ATENOLOL SUN"},
{label: "ATENOLOL TEVA"},
{label: "ATENOLOL VIATRIS"},
{label: "ATENOLOL WINTHROP"},
{label: "ATENOLOL ZENTIVA"},
{label: "ATENOLOL ZYDUS"},
{label: "ATENOLOL/NIFEDIPINE BIOGARAN"},
{label: "ATENOLOL/NIFEDIPINE SANDOZ"},
{label: "ATENOLOL/NIFEDIPINE VIATRIS"},
{label: "ATEPADENE"},
{label: "ATGAM"},
{label: "ATHYMIL"},
{label: "ATIMOS"},
{label: "ATOLINKA"},
{label: "ATORCIUM"},
{label: "ATORDIRVA"},
{label: "ATOREL"},
{label: "ATORVAMAG"},
{label: "ATORVASTATIN PHARMATHEN"},
{label: "ATORVASTATINE ACCORD"},
{label: "ATORVASTATINE ACTAVIS"},
{label: "ATORVASTATINE ACTAVIS FRANCE"},
{label: "ATORVASTATINE ALMUS"},
{label: "ATORVASTATINE ALMUS PHARMA"},
{label: "ATORVASTATINE ALTER"},
{label: "ATORVASTATINE ARROW"},
{label: "ATORVASTATINE ARROW GENERIQUES"},
{label: "ATORVASTATINE ARROW LAB"},
{label: "ATORVASTATINE BGR"},
{label: "ATORVASTATINE BIOGARAN"},
{label: "ATORVASTATINE BLUEFISH"},
{label: "ATORVASTATINE CRISTERS"},
{label: "ATORVASTATINE CRISTERS PHARMA"},
{label: "ATORVASTATINE DEXCEL"},
{label: "ATORVASTATINE DEXTREG"},
{label: "ATORVASTATINE EG"},
{label: "ATORVASTATINE EG LABO"},
{label: "ATORVASTATINE EVOLUGEN"},
{label: "ATORVASTATINE EVOLUGEN PHARMA"},
{label: "ATORVASTATINE GNR"},
{label: "ATORVASTATINE HCS"},
{label: "ATORVASTATINE HEC PHARM"},
{label: "ATORVASTATINE ISOMED"},
{label: "ATORVASTATINE KRKA"},
{label: "ATORVASTATINE LABORATOIRES ALTER"},
{label: "ATORVASTATINE LICONSA"},
{label: "ATORVASTATINE MYLAN"},
{label: "ATORVASTATINE MYLAN PHARMA"},
{label: "ATORVASTATINE PHARMAKI GENERICS"},
{label: "ATORVASTATINE PHR LAB"},
{label: "ATORVASTATINE QUALIMED"},
{label: "ATORVASTATINE RANBAXY PHARMACIE GENERIQUES"},
{label: "ATORVASTATINE RATIO"},
{label: "ATORVASTATINE RATIOPHARM"},
{label: "ATORVASTATINE RPG"},
{label: "ATORVASTATINE SANDOZ"},
{label: "ATORVASTATINE SUN"},
{label: "ATORVASTATINE TEVA"},
{label: "ATORVASTATINE TEVA SANTE"},
{label: "ATORVASTATINE TORRENT"},
{label: "ATORVASTATINE VIATRIS"},
{label: "ATORVASTATINE VIATRIS GENERIQUES"},
{label: "ATORVASTATINE ZENTIVA"},
{label: "ATORVASTATINE ZENTIVA LAB"},
{label: "ATORVASTATINE ZYDUS"},
{label: "ATORVASTATINE ZYDUS FRANCE"},
{label: "ATOSIBAN ACCORD"},
{label: "ATOSIBAN ALTAN"},
{label: "ATOSIBAN EVER PHARMA"},
{label: "ATOSIBAN STRAGEN"},
{label: "ATOSIBAN SUN"},
{label: "ATOUCLINE"},
{label: "ATOUXX ADULTES"},
{label: "ATOUXX TOUX SECHE ADULTES"},
{label: "ATOVAQUONE BIOGARAN"},
{label: "ATOVAQUONE/PROGUANIL ALFASIGMA"},
{label: "ATOVAQUONE/PROGUANIL BIOGARAN"},
{label: "ATOVAQUONE/PROGUANIL EG"},
{label: "ATOVAQUONE/PROGUANIL SANDOZ"},
{label: "ATOVAQUONE/PROGUANIL TEVA"},
{label: "ATOVAQUONE/PROGUANIL VIATRIS"},
{label: "ATOVAQUONE/PROGUANIL ZENTIVA"},
{label: "ATOVASTATIN PHARMATHEN"},
{label: "ATRACURIUM HIKMA"},
{label: "ATRACURIUM HOSPIRA"},
{label: "ATRACURIUM KALCEKS"},
{label: "ATRACURIUM MYLAN"},
{label: "ATRIANCE"},
{label: "ATRICAN"},
{label: "ATRIDOX"},
{label: "ATRIPLA"},
{label: "ATROPINE"},
{label: "ATROPINE (SULFATE) AGUETTANT"},
{label: "ATROPINE (SULFATE) LAVOISIER"},
{label: "ATROPINE (SULFATE) PHARMACIE CENTRALE DES ARMEES"},
{label: "ATROPINE A"},
{label: "ATROPINE ALCON"},
{label: "ATROPINE CHIBRET"},
{label: "ATROPINE SULFATE ACCORD"},
{label: "ATROPINE SULFATE AGUETTANT"},
{label: "ATROPINE SULFATE BIOSEDRA"},
{label: "ATROPINE SULFATE NORIDEM"},
{label: "ATROPINE SULFATE RENAUDIN"},
{label: "ATROPINUM BOIRON"},
{label: "ATROVENT"},
{label: "ATROVENT ADULTES"},
{label: "ATROVENT ENFANTS"},
{label: "ATROVENT NASAL"},
{label: "ATRYN"},
{label: "ATURGYL"},
{label: "ATUSSIL EUCALYPTOL"},
{label: "ATUXANE"},
{label: "AUBAGIO"},
{label: "AUBENOL"},
{label: "AUBEPINE"},
{label: "AUBEPINE BOIRON"},
{label: "AUBEPINE INFUSETTE LES BONS PRODUCTEURS"},
{label: "AUBEPINE NATURA MEDICA"},
{label: "AUBIER DE TILLEUL EXTRAIT VITAFLOR"},
{label: "AUGMENTIN"},
{label: "AURENA"},
{label: "AUREOMYCINE BOIRON"},
{label: "AUREOMYCINE COOPER"},
{label: "AUREOMYCINE EVANS"},
{label: "AUREOMYCINE MONOT"},
{label: "AUREOMYCINE SARBACH"},
{label: "AURICULARUM"},
{label: "AURIGOUTTE"},
{label: "AURISTAN"},
{label: "AURUM IODATUM BOIRON"},
{label: "AURUM METALLICUM BOIRON"},
{label: "AURUM METALLICUM LEHNING"},
{label: "AURUM METALLICUM WELEDA"},
{label: "AURUM MURIATICUM BOIRON"},
{label: "AURUM MURIATICUM LEHNING"},
{label: "AURUM MURIATICUM NATRONATUM BOIRON"},
{label: "AURUM MURIATICUM NATRONATUM LEHNING"},
{label: "AURUM MURIATICUM NATRONATUM WELEDA"},
{label: "AUTESS"},
{label: "AUTOPLASME VAILLANT"},
{label: "AUXERGYL D3"},
{label: "AUXITRANS"},
{label: "AVADENE"},
{label: "AVAFORTAN A LA NORAMIDOPYRINE"},
{label: "AVAGLIM"},
{label: "AVAMYS"},
{label: "AVANDAMET"},
{label: "AVANDIA"},
{label: "AVASTIN"},
{label: "AVATAK"},
{label: "AVAXIM"},
{label: "AVENA SATIVA BOIRON"},
{label: "AVENA SATIVA FERRIER"},
{label: "AVENA SATIVA LEHNING"},
{label: "AVENA SATIVA TEINTURE MERE BOIRON"},
{label: "AVENA SATIVA TEINTURE MERE WELEDA"},
{label: "AVENA SATIVA WELEDA"},
{label: "AVENOC"},
{label: "AVIAIRE BOIRON"},
{label: "AVIAIRE LEHNING"},
{label: "AVIBON"},
{label: "AVISIGEL"},
{label: "AVLOCARDYL"},
{label: "AVLOCARDYL L.P."},
{label: "AVODART"},
{label: "AVONEX"},
{label: "AVRALON"},
{label: "AVVA"},
{label: "AXADIL LP"},
{label: "AXANUM"},
{label: "AXE CORTICO-HYPOTHALAMIQUE BOIRON"},
{label: "AXELER"},
{label: "AXEPIM"},
{label: "AXIAGO"},
{label: "AXITINIB EG"},
{label: "AXITINIB SANDOZ"},
{label: "AXOLAC"},
{label: "AXONYL"},
{label: "AXOVEINYL"},
{label: "AXUMIN"},
{label: "AXXYMDOL"},
{label: "AXYMPA"},
{label: "AYBINTIO"},
{label: "AYUPILE"},
{label: "AYVAKYT"},
{label: "AZACITIDINE ACCORD"},
{label: "AZACITIDINE ARROW"},
{label: "AZACITIDINE BETAPHARM"},
{label: "AZACITIDINE EVER PHARMA"},
{label: "AZACITIDINE HIKMA"},
{label: "AZACITIDINE LORIEN"},
{label: "AZACITIDINE MYLAN"},
{label: "AZACITIDINE SANDOZ"},
{label: "AZACITIDINE TILLOMED"},
{label: "AZACITIDINE ZENTIVA"},
{label: "AZACTAM"},
{label: "AZADOSE"},
{label: "AZALIA"},
{label: "AZANTAC"},
{label: "AZANTAC INJECTABLE"},
{label: "AZARGA"},
{label: "AZATHIOPRINE EG"},
{label: "AZATHIOPRINE MYLAN"},
{label: "AZATHIOPRINE TEVA"},
{label: "AZATHIOPRINE VIATRIS"},
{label: "AZEDAVIT"},
{label: "AZEFLU"},
{label: "AZELACOLL"},
{label: "AZELASTINE CHLORHYDRATE/FLUTICASONE PROPIONATE VIATRIS"},
{label: "AZELASTINE PLANTIER"},
{label: "AZELASTINE SARGET"},
{label: "AZELASTINE SARGET PHARMA"},
{label: "AZEMA"},
{label: "AZILECT"},
{label: "AZINC COMPLEXE"},
{label: "AZITHROMYCINE ARROW"},
{label: "AZITHROMYCINE ARROW LAB"},
{label: "AZITHROMYCINE BGR"},
{label: "AZITHROMYCINE BIOGARAN"},
{label: "AZITHROMYCINE BLUEPHARMA"},
{label: "AZITHROMYCINE CLL PHARMA"},
{label: "AZITHROMYCINE CRISTERS"},
{label: "AZITHROMYCINE CRISTERS PHARMA"},
{label: "AZITHROMYCINE CTRS"},
{label: "AZITHROMYCINE EG"},
{label: "AZITHROMYCINE EVOLUGEN"},
{label: "AZITHROMYCINE KRKA"},
{label: "AZITHROMYCINE MONODOSE SANDOZ"},
{label: "AZITHROMYCINE PFIZER"},
{label: "AZITHROMYCINE PHR LAB"},
{label: "AZITHROMYCINE QUALIMED"},
{label: "AZITHROMYCINE RATIO"},
{label: "AZITHROMYCINE RATIOPHARM"},
{label: "AZITHROMYCINE SANDOZ"},
{label: "AZITHROMYCINE TEVA"},
{label: "AZITHROMYCINE TEVA MONODOSE"},
{label: "AZITHROMYCINE TONIPHARM"},
{label: "AZITHROMYCINE VIATRIS"},
{label: "AZITHROMYCINE WINTHROP"},
{label: "AZITHROMYCINE ZENTIVA"},
{label: "AZITHROMYCINE ZYDUS"},
{label: "AZONUTRIL"},
{label: "AZOPT"},
{label: "AZULENE"},
{label: "AZURITE BOIRON"},
{label: "AZYM"},
{label: "AZYTER"},
{label: "AZZALURE"},
{label: "B CHABRE"},
{label: "B O P"},
{label: "B.A.L."},
{label: "BACAMPICINE"},
{label: "BACICOLINE A LA BACITRACINE"},
{label: "BACITHRAX"},
{label: "BACITRACINE MARTINET"},
{label: "BACLOCUR"},
{label: "BACLOFENE AGUETTANT"},
{label: "BACLOFENE EG"},
{label: "BACLOFENE NEURAXPHARM"},
{label: "BACLOFENE SUN"},
{label: "BACLOFENE WINTHROP"},
{label: "BACLOFENE ZENTIVA"},
{label: "BACOCIL"},
{label: "BACTEOMYCINE"},
{label: "BACTERIX"},
{label: "BACTIFURYL"},
{label: "BACTISUBTIL"},
{label: "BACTIVON"},
{label: "BACTOX"},
{label: "BACTRIM"},
{label: "BACTRIM ENFANTS"},
{label: "BACTRIM FORTE"},
{label: "BACTRIM FORTE I M"},
{label: "BACTROBAN"},
{label: "BADIAGA BOIRON"},
{label: "BADIAGA COMPLEXE N°47"},
{label: "BADIAGA LEHNING"},
{label: "BAGRIZIDINE"},
{label: "BAIN DE BOUCHE CITO A"},
{label: "BAIN DE BOUCHE LIPHA"},
{label: "BALANCA"},
{label: "BALANCACONTINU"},
{label: "BALCOGA"},
{label: "BALLOTE NATURA MEDICA"},
{label: "BALSAMIC"},
{label: "BALSAMORHINOL"},
{label: "BALSAMUM PERUVIANUM BOIRON"},
{label: "BALSEPTOL"},
{label: "BALSOFUMINE MENTHOLEE"},
{label: "BALSOFUMINE SIMPLE"},
{label: "BALSOLENE"},
{label: "BAMBUSA BOIRON"},
{label: "BAMBUSA WELEDA"},
{label: "BAPTISIA TINCTORIA BOIRON"},
{label: "BAPTISIA TINCTORIA LEHNING"},
{label: "BAQSIMI"},
{label: "BARACLUDE"},
{label: "BARDANE ETHYPHARM"},
{label: "BARDANE NATURA MEDICA"},
{label: "BAREXAL"},
{label: "BARITEKAL"},
{label: "BARNETIL"},
{label: "BARNY L.P."},
{label: "BARYTA CARBONICA BOIRON"},
{label: "BARYTA CARBONICA LEHNING"},
{label: "BARYTA CARBONICA WELEDA"},
{label: "BARYTA IODATA BOIRON"},
{label: "BARYTA MURIATICA BOIRON"},
{label: "BARYTA MURIATICA LEHNING"},
{label: "BARYTINE BOIRON"},
{label: "BASCELLEX"},
{label: "BASDENE"},
{label: "BASEAL"},
{label: "BASILICUM COMPLEXE N°96"},
{label: "BASIRON"},
{label: "BATREVAC"},
{label: "BAUMALINE"},
{label: "BAUME AGATHOL"},
{label: "BAUME ALGIPAN"},
{label: "BAUME AROMA"},
{label: "BAUME BENGUE"},
{label: "BAUME CUROMAL"},
{label: "BAUME DES PYRENEES"},
{label: "BAUME KAMOL"},
{label: "BAUME LE THERMOGENE"},
{label: "BAUME MIRIGA"},
{label: "BAUME NARDENE"},
{label: "BAUME NOTRE DAME"},
{label: "BAUME PICOT"},
{label: "BAUME SAINT BERNARD"},
{label: "BAUME THOMAS"},
{label: "BAVENCIO"},
{label: "BAXOGAR"},
{label: "BAYPEN"},
{label: "BAYPEN ENFANTS ET NOURRISSONS"},
{label: "BAYPEN NOURRISSONS"},
{label: "BAYPRESS"},
{label: "BCG-MEDAC"},
{label: "BEAGYNE"},
{label: "BEBEGEL"},
{label: "BEBIA"},
{label: "BECEVITOL"},
{label: "BECILAN"},
{label: "BECLO-RHINO"},
{label: "BECLOJET"},
{label: "BECLOMETASONE"},
{label: "BECLOMETASONE CHIESI"},
{label: "BECLOMETASONE GSK"},
{label: "BECLOMETASONE INNOVATA BIOMED CLICKHALER"},
{label: "BECLOMETASONE TEVA"},
{label: "BECLOMETASONE TEVA SANTE"},
{label: "BECLOMETASONE URGO"},
{label: "BECLOMETASONE/FORMOTEROL VIATRIS 100/6"},
{label: "BECLONE"},
{label: "BECLOSPIN"},
{label: "BECLOSPRAY"},
{label: "BECODISKS"},
{label: "BECONASE"},
{label: "BECOTIDE"},
{label: "BECOTIDE ROTACAPS"},
{label: "BECOZYME"},
{label: "BEDELIX"},
{label: "BEFIZAL"},
{label: "BEFIZAL L.P."},
{label: "BEFLAVINE"},
{label: "BEFOR ROTACAPS"},
{label: "BEFOR ROTADISKS"},
{label: "BEKUNIS"},
{label: "BELAMYLE"},
{label: "BELANETTE"},
{label: "BELARA"},
{label: "BELARACONTINU"},
{label: "BELAXSEN"},
{label: "BELIVAIR EXPECTORANT LIERRE"},
{label: "BELIVAIR RHUME PELARGONIUM"},
{label: "BELIVON"},
{label: "BELKYRA"},
{label: "BELLADONNA"},
{label: "BELLADONNA BOIRON"},
{label: "BELLADONNA FERRIER"},
{label: "BELLADONNA LEHNING"},
{label: "BELLADONNA WELEDA"},
{label: "BELLIS PERENNIS BOIRON"},
{label: "BELLIS PERENNIS LEHNING"},
{label: "BELLOC"},
{label: "BELUSTINE"},
{label: "BEMEDREX EASYHALER"},
{label: "BEMFOLA"},
{label: "BENADON"},
{label: "BENADRYL"},
{label: "BENAQ"},
{label: "BENAZEPRIL ARROW"},
{label: "BENAZEPRIL EG"},
{label: "BENAZEPRIL HYDROCHLOROTHIAZIDE EG"},
{label: "BENAZEPRIL HYDROCHLOROTHIAZIDE MEDA"},
{label: "BENAZEPRIL HYDROCHLOROTHIAZIDE TEVA SANTE"},
{label: "BENAZEPRIL HYDROCHLOROTHIAZIDE WINTHROP"},
{label: "BENAZEPRIL MEDA"},
{label: "BENAZEPRIL MYLAN"},
{label: "BENAZEPRIL TEVA SANTE"},
{label: "BENAZEPRIL WINTHROP"},
{label: "BENAZEPRIL/HYDROCHLOROTHIAZIDE ARROW"},
{label: "BENDAMUSTINE ACCORD"},
{label: "BENDAMUSTINE AMNEAL"},
{label: "BENDAMUSTINE BAXTER"},
{label: "BENDAMUSTINE CHEMI"},
{label: "BENDAMUSTINE DR. REDDYS"},
{label: "BENDAMUSTINE EG"},
{label: "BENDAMUSTINE FRESENIUS KABI"},
{label: "BENDAMUSTINE GNR"},
{label: "BENDAMUSTINE HIKMA"},
{label: "BENDAMUSTINE MEDAC"},
{label: "BENDAMUSTINE MYLAN"},
{label: "BENDAMUSTINE PHARMAKI GENERICS"},
{label: "BENDAMUSTINE REDDY PHARMA"},
{label: "BENDAMUSTINE SANDOZ"},
{label: "BENEFIX"},
{label: "BENEMIDE"},
{label: "BENEPALI"},
{label: "BENERVA"},
{label: "BENFLUOREX MYLAN"},
{label: "BENFLUOREX QUALIMED"},
{label: "BENLYSTA"},
{label: "BENTIFEN"},
{label: "BENTOS"},
{label: "BENTOS OPHTADOSES"},
{label: "BENYLIN"},
{label: "BENZALKONIUM BOUCHARA RECORDATI"},
{label: "BENZALKONIUM CHLORHEXIDINE MENARINI"},
{label: "BENZATHINE BENZYLPENICILLINE PANPHARMA"},
{label: "BENZATHINE BENZYLPENICILLINE SANDOZ"},
{label: "BENZO-GYNOESTRYL"},
{label: "BENZO-GYNOESTRYL RETARD"},
{label: "BENZODODECINIUM CHIBRET"},
{label: "BENZOE BOIRON"},
{label: "BENZOICUM ACIDUM BOIRON"},
{label: "BENZOICUM ACIDUM LEHNING"},
{label: "BENZOICUM ACIDUM WELEDA"},
{label: "BENZOLUM BOIRON"},
{label: "BENZYDAMINE A.C.R.A.F."},
{label: "BENZYDAMINE ACRAF"},
{label: "BENZYDAMINE HUMEX"},
{label: "BENZYDAMINE URGO"},
{label: "BEOVU"},
{label: "BEPANTHEN"},
{label: "BEPANTHENE"},
{label: "BERBERIS AQUIFOLIUM BOIRON"},
{label: "BERBERIS AQUIFOLIUM LEHNING"},
{label: "BERBERIS COMPLEXE N°83"},
{label: "BERBERIS VULGARIS"},
{label: "BERBERIS VULGARIS BOIRON"},
{label: "BERBERIS VULGARIS LEHNING"},
{label: "BERINERT"},
{label: "BERIPLAST"},
{label: "BERMEXEX"},
{label: "BEROCCA"},
{label: "BEROCCA SANS SUCRE"},
{label: "BEROMUN"},
{label: "BEROTEC"},
{label: "BERTEND"},
{label: "BESPONSA"},
{label: "BESREMI"},
{label: "BETA ADALATE"},
{label: "BETADERMYL"},
{label: "BETADINE"},
{label: "BETADINE 1%"},
{label: "BETADINE ALCOOLIQUE"},
{label: "BETADINE DERMIQUE"},
{label: "BETADINE GYNECOLOGIQUE"},
{label: "BETADINE SCRUB"},
{label: "BETADINE TULLE 10%"},
{label: "BETAFACT"},
{label: "BETAFERON"},
{label: "BETAFITE BOIRON"},
{label: "BETAGAN"},
{label: "BETAHISTINE (DICHLORHYDRATE) CRISTERS"},
{label: "BETAHISTINE ABBOTT"},
{label: "BETAHISTINE ACCORD"},
{label: "BETAHISTINE ACTAVIS"},
{label: "BETAHISTINE ARROW"},
{label: "BETAHISTINE ARROW GENERIQUES"},
{label: "BETAHISTINE BIOGARAN"},
{label: "BETAHISTINE BOUCHARA RECORDATI"},
{label: "BETAHISTINE CRISTERS"},
{label: "BETAHISTINE CYATHUS"},
{label: "BETAHISTINE EG"},
{label: "BETAHISTINE EG LABO"},
{label: "BETAHISTINE G GAM"},
{label: "BETAHISTINE GNR"},
{label: "BETAHISTINE IVAX"},
{label: "BETAHISTINE MYLAN"},
{label: "BETAHISTINE NORDIC PHARMA"},
{label: "BETAHISTINE PALBIAN"},
{label: "BETAHISTINE PFIZER"},
{label: "BETAHISTINE PHARMA 2B"},
{label: "BETAHISTINE QUALIMED"},
{label: "BETAHISTINE QUIVER"},
{label: "BETAHISTINE RANBAXY"},
{label: "BETAHISTINE RATIOPHARM"},
{label: "BETAHISTINE REF"},
{label: "BETAHISTINE RPG"},
{label: "BETAHISTINE SANDOZ"},
{label: "BETAHISTINE TEVA"},
{label: "BETAHISTINE VIATRIS"},
{label: "BETAHISTINE WINTHROP"},
{label: "BETAHISTINE ZENTIVA"},
{label: "BETAHISTINE ZYDUS"},
{label: "BETAHISTINE ZYDUS FRANCE"},
{label: "BETAMAZE"},
{label: "BETAMETHASONE ARROW"},
{label: "BETAMETHASONE BIOGARAN"},
{label: "BETAMETHASONE DIPROPIONATE SCHERING-PLOUGH"},
{label: "BETAMETHASONE EG"},
{label: "BETAMETHASONE ORGANON"},
{label: "BETAMETHASONE SCHERING-PLOUGH"},
{label: "BETAMETHASONE ZENTIVA"},
{label: "BETANOL"},
{label: "BETASELEN"},
{label: "BETASEPTIC"},
{label: "BETASEPTIC DERMIQUE"},
{label: "BETASEPTIC SCRUB"},
{label: "BETASERC"},
{label: "BETATOP"},
{label: "BETAXOLOL ALCON"},
{label: "BETAXOLOL BIOGARAN"},
{label: "BETAXOLOL EG"},
{label: "BETAXOLOL MG PHARMA"},
{label: "BETAXOLOL PMCS"},
{label: "BETAXOLOL SANDOZ"},
{label: "BETAXOLOL TEVA SANTE"},
{label: "BETAXOLOL VIATRIS"},
{label: "BETESIL"},
{label: "BETIME"},
{label: "BETMIGA"},
{label: "BETNESALIC"},
{label: "BETNESOL"},
{label: "BETNEVAL"},
{label: "BETNEVAL LOTION"},
{label: "BETNEVAL NEOMYCINE"},
{label: "BETNEVAL NEOMYCINE LOTION"},
{label: "BETNEVAL SCALP"},
{label: "BETONICA OFFICINALIS BOIRON"},
{label: "BETONICA OFFICINALIS WELEDA"},
{label: "BETOPTIC"},
{label: "BETRIPHOS C"},
{label: "BETULA ALBA"},
{label: "BETULA BOIRON"},
{label: "BETULA LEHNING"},
{label: "BEVITINE"},
{label: "BEXAROTENE CIPLA"},
{label: "BEXSERO"},
{label: "BEXTRA"},
{label: "BEYFORTUS"},
{label: "BEZAFIBRATE ACTAVIS"},
{label: "BEZAFIBRATE ACTAVIS L.P."},
{label: "BI CITROL"},
{label: "BI MISSILOR"},
{label: "BI PROFENID LP"},
{label: "BI TILDIEM L.P."},
{label: "BI-QUI-NOL ADULTES"},
{label: "BI-QUI-NOL ENFANTS"},
{label: "BIACORT"},
{label: "BIAFINE"},
{label: "BIAFINEACT"},
{label: "BIBOVEL"},
{label: "BICAFLAC ELECTROLYTES SOLUTION AVEC POTASSIUM"},
{label: "BICAFLAC ELECTROLYTES SOLUTION SANS POTASSIUM"},
{label: "BICAFLAC SOLUTION TAMPON"},
{label: "BICAFRES"},
{label: "BICALUTAMIDE ACCORD"},
{label: "BICALUTAMIDE ACTAVIS"},
{label: "BICALUTAMIDE ALMUS"},
{label: "BICALUTAMIDE ALTER"},
{label: "BICALUTAMIDE ARROW"},
{label: "BICALUTAMIDE ARROW LAB"},
{label: "BICALUTAMIDE BIOGARAN"},
{label: "BICALUTAMIDE BIOORGANICS"},
{label: "BICALUTAMIDE CRISTERS"},
{label: "BICALUTAMIDE CRISTERS PHARMA"},
{label: "BICALUTAMIDE DCI PHARMA"},
{label: "BICALUTAMIDE EG"},
{label: "BICALUTAMIDE EVOLUGEN"},
{label: "BICALUTAMIDE GNR"},
{label: "BICALUTAMIDE HEXAL"},
{label: "BICALUTAMIDE ISOMED"},
{label: "BICALUTAMIDE KABI"},
{label: "BICALUTAMIDE MYLAN PHARMA"},
{label: "BICALUTAMIDE PHR LAB"},
{label: "BICALUTAMIDE QUALIMED"},
{label: "BICALUTAMIDE RANBAXY"},
{label: "BICALUTAMIDE RATIOPHARM"},
{label: "BICALUTAMIDE SANDOZ"},
{label: "BICALUTAMIDE SUN"},
{label: "BICALUTAMIDE TEVA"},
{label: "BICALUTAMIDE VIATRIS"},
{label: "BICALUTAMIDE WINTHROP"},
{label: "BICALUTAMIDE ZENTIVA"},
{label: "BICALUTAMIDE ZYDUS"},
{label: "BICALUTAMIDE ZYDUS FRANCE"},
{label: "BICAPHARM"},
{label: "BICARBONATE DE SODIUM"},
{label: "BICARBONATE DE SODIUM A"},
{label: "BICARBONATE DE SODIUM B.BRAUN"},
{label: "BICARBONATE DE SODIUM BAXTER A"},
{label: "BICARBONATE DE SODIUM COOPER"},
{label: "BICARBONATE DE SODIUM COOPER A"},
{label: "BICARBONATE DE SODIUM GIFRER"},
{label: "BICARBONATE DE SODIUM LAVOISIER"},
{label: "BICARBONATE DE SODIUM RENAUDIN"},
{label: "BICARBONATE DE SODIUM VIAFLO"},
{label: "BICASTAD"},
{label: "BICAVERA"},
{label: "BICIRKAN"},
{label: "BICLARIC"},
{label: "BICLINOCILLINE IM"},
{label: "BICLOTYMOL URGO"},
{label: "BICLOTYMOL URGO MENTHE"},
{label: "BICLOTYMOL URGO ORANGE"},
{label: "BICNU"},
{label: "BIFIDIGENE"},
{label: "BIFIX"},
{label: "BIFLUORID"},
{label: "BIFUGEL"},
{label: "BIGARADIER NATURA MEDICA"},
{label: "BIGONIST"},
{label: "BIJUVA"},
{label: "BIKTARVY"},
{label: "BILASKA"},
{label: "BILASTINE ARROW"},
{label: "BILASTINE BIOGARAN"},
{label: "BILASTINE EG"},
{label: "BILASTINE TEVA"},
{label: "BILASTINE VIATRIS"},
{label: "BILASTINE ZENTIVA"},
{label: "BILICANTE"},
{label: "BILINUM"},
{label: "BILINUM COMPLEXE N°113"},
{label: "BILLEROL"},
{label: "BILTRICIDE"},
{label: "BIMATOPROST BIOGARAN"},
{label: "BIMATOPROST EG"},
{label: "BIMATOPROST GENETIC"},
{label: "BIMATOPROST MEDIPHA SANTE"},
{label: "BIMATOPROST MICRO LABS"},
{label: "BIMATOPROST SANDOZ"},
{label: "BIMATOPROST TEVA"},
{label: "BIMATOPROST TEVA SANTE"},
{label: "BIMATOPROST TIMOLOL BIOGARAN"},
{label: "BIMATOPROST VIATRIS"},
{label: "BIMATOPROST ZIMED SANS CONSERVATEUR"},
{label: "BIMATOPROST/TIMOLOL BGR"},
{label: "BIMATOPROST/TIMOLOL EG"},
{label: "BIMATOPROST/TIMOLOL EG LABO"},
{label: "BIMATOPROST/TIMOLOL MICRO LABS"},
{label: "BIMATOPROST/TIMOLOL PHARMABIDE"},
{label: "BIMATOPROST/TIMOLOL SANDOZ"},
{label: "BIMATOPROST/TIMOLOL TEVA"},
{label: "BIMATOPROST/TIMOLOL VIATRIS"},
{label: "BIMATOPROST/TIMOLOL ZENTIVA"},
{label: "BIMZELX"},
{label: "BINOCRIT"},
{label: "BIO CUIVRE MANGANESE"},
{label: "BIO LANCYL"},
{label: "BIO SELENIUM"},
{label: "BIOCADEXTRO"},
{label: "BIOCALYPTOL"},
{label: "BIOCALYPTOL A LA PHOLCODINE"},
{label: "BIOCALYPTOL A LA PHOLCODINE ADULTES"},
{label: "BIOCALYPTOL A LA PHOLCODINE ENFANTS"},
{label: "BIOCARDE"},
{label: "BIOCIDAN"},
{label: "BIOCOL"},
{label: "BIODALGIC"},
{label: "BIODICAINE"},
{label: "BIOFAGAN AROME ABRICOT"},
{label: "BIOFAGAN AROME CITRON"},
{label: "BIOFAGAN AROME ORANGE"},
{label: "BIOFAGAN AROME POMME VERTE"},
{label: "BIOGAZE"},
{label: "BIOGRASTIM"},
{label: "BIOLACTYL LYOPHILISE"},
{label: "BIOMAG AGRUMES"},
{label: "BIOMOX"},
{label: "BIONOLYTE G10"},
{label: "BIONOLYTE G10 PERFUFLEX"},
{label: "BIONOLYTE G5"},
{label: "BIONOLYTE G5 PERFUFLEX"},
{label: "BIOPEG"},
{label: "BIORGASEPT"},
{label: "BIOSTIM"},
{label: "BIOSTIM ENFANTS"},
{label: "BIOTINE BAYER"},
{label: "BIOTONE"},
{label: "BIOVEINAL ELIXIR"},
{label: "BIOXYOL"},
{label: "BIPENICILLINE"},
{label: "BIPENICILLINE 2/3"},
{label: "BIPENICILLINE 4/6 PANPHARMA"},
{label: "BIPENICILLINE 4/6 SPECIA"},
{label: "BIPENICILLINE SPECIA"},
{label: "BIPERIDYS"},
{label: "BIPERIDYSFLASH"},
{label: "BIPHEDRINE AQUEUSE"},
{label: "BIPHEDRINE HUILEUSE"},
{label: "BIPHOZYL"},
{label: "BIPRETERAX"},
{label: "BIRODOGYL"},
{label: "BISACODYL ARROW CONSEIL"},
{label: "BISACODYL BIOGARAN CONSEIL"},
{label: "BISACODYL CRISTERS"},
{label: "BISACODYL EDR PHARMA"},
{label: "BISACODYL EG LABO CONSEIL"},
{label: "BISACODYL SANDOZ CONSEIL"},
{label: "BISACODYL VIATRIS CONSEIL"},
{label: "BISACODYL VIATRIS PHARMA"},
{label: "BISACODYL ZENTIVA CONSEIL"},
{label: "BISEPTINE"},
{label: "BISEPTINESCRUB"},
{label: "BISEPTINESPRAID"},
{label: "BISMUQUINOL"},
{label: "BISMURECTOL ADULTES"},
{label: "BISMURECTOL ENFANTS"},
{label: "BISMUTH OLIGOSOL"},
{label: "BISMUTHUM BOIRON"},
{label: "BISMUTHUM LEHNING"},
{label: "BISMUTHUM METALLICUM BOIRON"},
{label: "BISMUTHUM WELEDA"},
{label: "BISOCE"},
{label: "BISOLVOMYCINE"},
{label: "BISOLVON"},
{label: "BISOPROLOL ACCORD HEALTHCARE"},
{label: "BISOPROLOL ACTAVIS"},
{label: "BISOPROLOL ACTAVIS FRANCE"},
{label: "BISOPROLOL ALMUS"},
{label: "BISOPROLOL ALPHARMA"},
{label: "BISOPROLOL ARROW"},
{label: "BISOPROLOL ARROW LAB"},
{label: "BISOPROLOL AUROBINDO"},
{label: "BISOPROLOL BGR"},
{label: "BISOPROLOL BIOGARAN"},
{label: "BISOPROLOL BIOGLAN"},
{label: "BISOPROLOL BIOSTABILEX"},
{label: "BISOPROLOL CRISTERS"},
{label: "BISOPROLOL CRISTERS PHARMA"},
{label: "BISOPROLOL EG"},
{label: "BISOPROLOL ENIREX"},
{label: "BISOPROLOL EVOLUGEN"},
{label: "BISOPROLOL G GAM"},
{label: "BISOPROLOL HYDROCHLOROTHIAZIDE EVOLUGEN"},
{label: "BISOPROLOL ISOMED"},
{label: "BISOPROLOL KRKA"},
{label: "BISOPROLOL MYLAN"},
{label: "BISOPROLOL MYLAN PHARMA"},
{label: "BISOPROLOL NICHE"},
{label: "BISOPROLOL NOR"},
{label: "BISOPROLOL QUALIMED"},
{label: "BISOPROLOL QUALIMED GENERIQUES"},
{label: "BISOPROLOL QUIVER"},
{label: "BISOPROLOL QUIVER LAB"},
{label: "BISOPROLOL RANBAXY"},
{label: "BISOPROLOL RATIOPHARM"},
{label: "BISOPROLOL REF"},
{label: "BISOPROLOL SANDOZ"},
{label: "BISOPROLOL SUN"},
{label: "BISOPROLOL TEVA"},
{label: "BISOPROLOL TEVA SANTE"},
{label: "BISOPROLOL VIATRIS"},
{label: "BISOPROLOL VIATRIS SANTE"},
{label: "BISOPROLOL ZENTIVA"},
{label: "BISOPROLOL ZENTIVA K.S"},
{label: "BISOPROLOL ZENTIVA LAB"},
{label: "BISOPROLOL ZYDUS"},
{label: "BISOPROLOL ZYDUS FRANCE"},
{label: "BISOPROLOL/HYDROCHLOROTHIAZIDE ARROW"},
{label: "BISOPROLOL/HYDROCHLOROTHIAZIDE ARROW LAB"},
{label: "BISOPROLOL/HYDROCHLOROTHIAZIDE BIOGARAN"},
{label: "BISOPROLOL/HYDROCHLOROTHIAZIDE CRISTERS"},
{label: "BISOPROLOL/HYDROCHLOROTHIAZIDE EG"},
{label: "BISOPROLOL/HYDROCHLOROTHIAZIDE MYLAN"},
{label: "BISOPROLOL/HYDROCHLOROTHIAZIDE QUALIMED"},
{label: "BISOPROLOL/HYDROCHLOROTHIAZIDE RATIOPHARM"},
{label: "BISOPROLOL/HYDROCHLOROTHIAZIDE SANDOZ"},
{label: "BISOPROLOL/HYDROCHLOROTHIAZIDE TEVA"},
{label: "BISOPROLOL/HYDROCHLOROTHIAZIDE TEVA SANTE"},
{label: "BISOPROLOL/HYDROCHLOROTHIAZIDE VIATRIS"},
{label: "BISOPROLOL/HYDROCHLOROTHIAZIDE ZENTIVA"},
{label: "BITIFRIN"},
{label: "BIVALIRUDINE ACCORD"},
{label: "BIVALIRUDINE CIPLA"},
{label: "BIVENON"},
{label: "BLACA"},
{label: "BLACKOIDS DU DOCTEUR MEUR"},
{label: "BLATTA ORIENTALIS BOIRON"},
{label: "BLATTA ORIENTALIS LEHNING"},
{label: "BLENDE BOIRON"},
{label: "BLENREP"},
{label: "BLEOMYCINE ACCORD"},
{label: "BLEOMYCINE BELLON"},
{label: "BLEOMYCINE TEVA"},
{label: "BLEU PATENTE V SODIQUE GUERBET"},
{label: "BLINCYTO"},
{label: "BLISSEL"},
{label: "BLOONIS"},
{label: "BLUCINEL"},
{label: "BLUNACYR"},
{label: "BOCATRIOL"},
{label: "BOCEAL"},
{label: "BOCOUTURE"},
{label: "BOLCITOL"},
{label: "BOLDO BOIRON"},
{label: "BOLDO INFUSETTE LES BONS PRODUCTEURS"},
{label: "BOLDO LEHNING"},
{label: "BOLDO WELEDA"},
{label: "BOLDOFLORINE"},
{label: "BOLDOFLORINE N°"},
{label: "BOLDOFLORINE N°2"},
{label: "BOLDOFLORINE N°2 DOULEUR DIGESTIVE"},
{label: "BOLDOLAXINE"},
{label: "BOLINAN"},
{label: "BONASOL"},
{label: "BONDRONAT"},
{label: "BONEFURBIT"},
{label: "BONOPTIL"},
{label: "BONTEFEL"},
{label: "BONVIVA"},
{label: "BOOSTRIX"},
{label: "BOOSTRIXTETRA"},
{label: "BORAX / ACIDE BORIQUE ARROW"},
{label: "BORAX / ACIDE BORIQUE EG"},
{label: "BORAX BOIRON"},
{label: "BORAX LEHNING"},
{label: "BORAX WELEDA"},
{label: "BORAX/ACIDE BORIQUE BGR"},
{label: "BORAX/ACIDE BORIQUE BIOGARAN"},
{label: "BORAX/ACIDE BORIQUE BIOGARAN CONSEIL"},
{label: "BORAX/ACIDE BORIQUE VIATRIS"},
{label: "BORAX/ACIDE BORIQUE ZENTIVA"},
{label: "BORIBEL MINCEUR"},
{label: "BORIBEL N°"},
{label: "BORIBEL N°1 DOULEURS ARTICULAIRES"},
{label: "BORIBEL N°10 DEPURATIVE"},
{label: "BORIBEL N°11 DIURETIQUE"},
{label: "BORIBEL N°11 RENALE"},
{label: "BORIBEL N°12 JAMBES LOURDES"},
{label: "BORIBEL N°13 PURGATIVE"},
{label: "BORIBEL N°14 MAL DE TETE"},
{label: "BORIBEL N°17 TROUBLES URINAIRES"},
{label: "BORIBEL N°2"},
{label: "BORIBEL N°2 PECTORALE"},
{label: "BORIBEL N°3 PALPITATIONS"},
{label: "BORIBEL N°4 LAXATIVE"},
{label: "BORIBEL N°5 TONIFLOR"},
{label: "BORIBEL N°6"},
{label: "BORIBEL N°6 DIGESTIVE"},
{label: "BORIBEL N°7 BILIAIRE"},
{label: "BORIBEL N°7 HEPATIQUE"},
{label: "BORIBEL N°8"},
{label: "BORIBEL N°8 SEDATIVE"},
{label: "BORIBEL N°9"},
{label: "BORIBEL N°9 MINCEUR"},
{label: "BORIPHARM N°1"},
{label: "BORIPHARM N°12"},
{label: "BORIPHARM N°15"},
{label: "BORIPHARM N°22"},
{label: "BORIPHARM N°23"},
{label: "BORIPHARM N°3"},
{label: "BORIPHARM N°31"},
{label: "BORIPHARM N°41"},
{label: "BORIPHARM N°48"},
{label: "BORNITE BOIRON"},
{label: "BOROCLARINE"},
{label: "BOROSTYROL"},
{label: "BORRAGO OFFICINALIS BOIRON"},
{label: "BORTEZOMIB ACCORD"},
{label: "BORTEZOMIB ARROW"},
{label: "BORTEZOMIB BAXTER"},
{label: "BORTEZOMIB EBEWE"},
{label: "BORTEZOMIB EG"},
{label: "BORTEZOMIB EVER PHARMA"},
{label: "BORTEZOMIB FRESENIUS KABI"},
{label: "BORTEZOMIB GENTHON"},
{label: "BORTEZOMIB HOSPIRA"},
{label: "BORTEZOMIB KRKA"},
{label: "BORTEZOMIB MEDAC"},
{label: "BORTEZOMIB MYLAN"},
{label: "BORTEZOMIB OHRE PHARMA"},
{label: "BORTEZOMIB PHARMAKI GENERICS"},
{label: "BORTEZOMIB REDDY PHARMA"},
{label: "BORTEZOMIB SANDOZ"},
{label: "BORTEZOMIB STRAGEN"},
{label: "BORTEZOMIB SUN"},
{label: "BORTEZOMIB TEVA"},
{label: "BORTEZOMIB TILLOMED"},
{label: "BORTEZOMIB VIATRIS"},
{label: "BORTEZOMIB WAVERLEY PHARMA EUROPE LIMITED"},
{label: "BORTEZOMIB ZENTIVA"},
{label: "BORTEZOMIB ZYDUS"},
{label: "BOSENTAN ACCORD"},
{label: "BOSENTAN AMNEAL"},
{label: "BOSENTAN ARROW"},
{label: "BOSENTAN BIOGARAN"},
{label: "BOSENTAN CIPLA"},
{label: "BOSENTAN EG"},
{label: "BOSENTAN GEN.ORPH"},
{label: "BOSENTAN MEDIPHA"},
{label: "BOSENTAN PHARMAKI GENERICS"},
{label: "BOSENTAN SANDOZ"},
{label: "BOSENTAN STRAGEN"},
{label: "BOSENTAN SUN"},
{label: "BOSENTAN TEVA"},
{label: "BOSENTAN VIATRIS"},
{label: "BOSENTAN ZENTIVA"},
{label: "BOSULIF"},
{label: "BOTHROPS LANCEOLATUS BOIRON"},
{label: "BOTOX"},
{label: "BOUILLON BLANC NATURA MEDICA"},
{label: "BOURDAINE BOIRON"},
{label: "BOURDAINE EXTRAIT VITAFLOR"},
{label: "BOVISTA GIGANTEA BOIRON"},
{label: "BOVISTA GIGANTEA LEHNING"},
{label: "BRADYL"},
{label: "BRAFTOVI"},
{label: "BRALTUS"},
{label: "BRASSICA NAPUS BOIRON"},
{label: "BRASSICA OLERACEA BOIRON"},
{label: "BREAKYL"},
{label: "BREAKYL START"},
{label: "BRETARIS GENUAIR"},
{label: "BRETYLATE"},
{label: "BREVIBLOC"},
{label: "BREVOXYL"},
{label: "BREXIN"},
{label: "BREYANZI"},
{label: "BRIAZIDE"},
{label: "BRICANYL"},
{label: "BRICANYL L.P."},
{label: "BRICANYL TURBUHALER"},
{label: "BRIDATEC"},
{label: "BRIDION"},
{label: "BRIEM"},
{label: "BRILIQUE"},
{label: "BRIMAZED"},
{label: "BRIMICA GENUAIR"},
{label: "BRIMONIDINE ALLERGAN"},
{label: "BRIMONIDINE BIOGARAN"},
{label: "BRIMONIDINE CHAUVIN"},
{label: "BRIMONIDINE EG"},
{label: "BRIMONIDINE GNR"},
{label: "BRIMONIDINE MYLAN"},
{label: "BRIMONIDINE OMNIVISION"},
{label: "BRIMONIDINE QUALIMED"},
{label: "BRIMONIDINE RATIOPHARM"},
{label: "BRIMONIDINE SANDOZ"},
{label: "BRIMONIDINE TEVA"},
{label: "BRIMONIDINE/TIMOLOL BIOGARAN"},
{label: "BRIMONIDINE/TIMOLOL EG"},
{label: "BRIMONIDINE/TIMOLOL MYLAN"},
{label: "BRIMONIDINE/TIMOLOL SANDOZ"},
{label: "BRIMONIDINE/TIMOLOL TEVA"},
{label: "BRINAVESS"},
{label: "BRINEURA"},
{label: "BRINTELLIX"},
{label: "BRINZOLAMIDE ARROW"},
{label: "BRINZOLAMIDE BIOGARAN"},
{label: "BRINZOLAMIDE CRISTERS"},
{label: "BRINZOLAMIDE EG"},
{label: "BRINZOLAMIDE SANDOZ"},
{label: "BRINZOLAMIDE TEVA"},
{label: "BRINZOLAMIDE VIATRIS"},
{label: "BRINZOLAMIDE/TIMOLOL EG"},
{label: "BRINZOLAMIDE/TIMOLOL VIATRIS"},
{label: "BRISPER"},
{label: "BRISTAMOX"},
{label: "BRISTOPEN"},
{label: "BRISTOPEN ENFANTS ET NOURRISSONS"},
{label: "BRITANE"},
{label: "BRIVIACT"},
{label: "BROMAZEPAM ARROW"},
{label: "BROMAZEPAM BIOGARAN"},
{label: "BROMAZEPAM CRISTERS"},
{label: "BROMAZEPAM EG"},
{label: "BROMAZEPAM EXPANPHARM"},
{label: "BROMAZEPAM HEXAL"},
{label: "BROMAZEPAM ISOMED"},
{label: "BROMAZEPAM OPIH"},
{label: "BROMAZEPAM PHARMY II"},
{label: "BROMAZEPAM QUALIMED"},
{label: "BROMAZEPAM RATIOPHARM"},
{label: "BROMAZEPAM RPG"},
{label: "BROMAZEPAM SANDOZ"},
{label: "BROMAZEPAM TEVA"},
{label: "BROMAZEPAM TEVA CLASSICS"},
{label: "BROMAZEPAM TEVA PHARMA"},
{label: "BROMAZEPAM VIATRIS"},
{label: "BROMAZEPAM ZENTIVA"},
{label: "BROMAZEPAM ZYDUS"},
{label: "BROMHYDRATE D'HOMATROPINE FAURE"},
{label: "BROMHYDRATE DE DEXTROMETHORPHANE BOUCHARA RECORDATI"},
{label: "BROMHYDRATE DE SCOPOLAMINE RENAUDIN"},
{label: "BROMOCRIPTINE WINTHROP"},
{label: "BROMOCRIPTINE ZENTIVA"},
{label: "BROMUM BOIRON"},
{label: "BROMUM LEHNING"},
{label: "BROMUM WELEDA"},
{label: "BROMURE DE PINAVERIUM SOLVAY PHARMA"},
{label: "BRONCALENE ADULTES"},
{label: "BRONCALENE ADULTES SANS SUCRE"},
{label: "BRONCALENE ENFANTS"},
{label: "BRONCALENE NOURRISSONS"},
{label: "BRONCATHIOL ENFANTS"},
{label: "BRONCATHIOL EXPECTORANT ADULTES"},
{label: "BRONCHES BOIRON"},
{label: "BRONCHES WELEDA"},
{label: "BRONCHEX"},
{label: "BRONCHITOL"},
{label: "BRONCHOCYST ADULTES"},
{label: "BRONCHOCYST ENFANTS"},
{label: "BRONCHODERMINE"},
{label: "BRONCHODERMINE ADULTES"},
{label: "BRONCHODERMINE ENFANTS"},
{label: "BRONCHODERMINE NOURRISSONS"},
{label: "BRONCHODORON"},
{label: "BRONCHODUAL"},
{label: "BRONCHOIDS"},
{label: "BRONCHOKOD"},
{label: "BRONCHOKOD ADULTES"},
{label: "BRONCHOKOD ENFANTS"},
{label: "BRONCHOKOD SANS SUCRE ENFANTS"},
{label: "BRONCHOLACTOL"},
{label: "BRONCHORECTINE AU CITRAL ADULTES"},
{label: "BRONCHORECTINE AU CITRAL ENFANTS"},
{label: "BRONCHORECTINE AU CITRAL NOURRISSONS"},
{label: "BRONCHOSPRAY"},
{label: "BRONCHOTONINE"},
{label: "BRONCHY ADULTES"},
{label: "BRONCHY ENFANTS"},
{label: "BRONCHY NOURRISSONS"},
{label: "BRONCHYTEINE ADULTES"},
{label: "BRONCHYTEINE ENFANTS ET NOURRISSONS"},
{label: "BRONCLAIR"},
{label: "BRONCOCLAR"},
{label: "BRONCOCLAR ADULTE"},
{label: "BRONCOCLAR ENFANTS"},
{label: "BRONCOL"},
{label: "BRONCORINOL ADULTES TOUX SECHE PHOLCODINE"},
{label: "BRONCORINOL ETATS GRIPPAUX"},
{label: "BRONCORINOL EXPECTORANT"},
{label: "BRONCORINOL GORGE IRRITEE"},
{label: "BRONCORINOL MAUX DE GORGE"},
{label: "BRONCORINOL RHINITES"},
{label: "BRONCORINOL RHUME"},
{label: "BRONCORINOL TOUX SECHE"},
{label: "BRONCORINOL TOUX SECHE ADULTES"},
{label: "BRONCORINOL TOUX SECHE ENFANTS"},
{label: "BRONILIDE"},
{label: "BRONKIREX SANS SUCRE"},
{label: "BRONPAX"},
{label: "BRONPHYTO"},
{label: "BRONTUX"},
{label: "BRONWEL"},
{label: "BROPULMON"},
{label: "BRUFEN"},
{label: "BRUFEN L.P."},
{label: "BRUKINSA"},
{label: "BRULEX"},
{label: "BRULUCERIUM"},
{label: "BRUMAIRE"},
{label: "BRYONIA BOIRON"},
{label: "BRYONIA LEHNING"},
{label: "BRYONIA WELEDA"},
{label: "BRYOPHYLLUM CALYCINUM BOIRON"},
{label: "BRYOPHYLLUM CALYCINUM WELEDA"},
{label: "BUCANEST"},
{label: "BUCANEST ADRENALINE AU 1/100"},
{label: "BUCANEST ADRENALINEE"},
{label: "BUCASEPT"},
{label: "BUCCACLEAN"},
{label: "BUCCAWALTER"},
{label: "BUCCOBET"},
{label: "BUCCOLAM"},
{label: "BUCCOSOIN"},
{label: "BUDENOSIDE AEROCID"},
{label: "BUDESONIDE AEROCID"},
{label: "BUDESONIDE ARROW"},
{label: "BUDESONIDE BIOGARAN"},
{label: "BUDESONIDE CRISTERS"},
{label: "BUDESONIDE EG"},
{label: "BUDESONIDE EVOLUGEN"},
{label: "BUDESONIDE H3 SANTE"},
{label: "BUDESONIDE ISOMED"},
{label: "BUDESONIDE LABORATORIOS LICONSA"},
{label: "BUDESONIDE LICONSA"},
{label: "BUDESONIDE SANDOZ"},
{label: "BUDESONIDE TEVA"},
{label: "BUDESONIDE VIATRIS"},
{label: "BUDESONIDE ZENTIVA"},
{label: "BUDESONIDE/FORMOTEROL SANDOZ FORSPIRO"},
{label: "BUDFOR"},
{label: "BUFAL"},
{label: "BUFEXAMAC COOPER"},
{label: "BUFEXAMAC NOVALIS"},
{label: "BUFLOMEDIL ACTAVIS"},
{label: "BUFLOMEDIL ARROW"},
{label: "BUFLOMEDIL BIOGARAN"},
{label: "BUFLOMEDIL CRISTERS"},
{label: "BUFLOMEDIL EG"},
{label: "BUFLOMEDIL G GAM"},
{label: "BUFLOMEDIL GNR"},
{label: "BUFLOMEDIL L LAFON"},
{label: "BUFLOMEDIL L.LAFON"},
{label: "BUFLOMEDIL MERCK"},
{label: "BUFLOMEDIL MYLAN"},
{label: "BUFLOMEDIL PAN MEDICA"},
{label: "BUFLOMEDIL QUALIMED"},
{label: "BUFLOMEDIL RATIOPHARM"},
{label: "BUFLOMEDIL RPG"},
{label: "BUFLOMEDIL SANDOZ"},
{label: "BUFLOMEDIL SUBSTIPHARM"},
{label: "BUFLOMEDIL TEVA"},
{label: "BUFLOMEDIL WINTHROP"},
{label: "BUFLOMEDIL ZYDUS"},
{label: "BULBINUM  BOIRON"},
{label: "BULBINUM WELEDA"},
{label: "BUNOPTIL"},
{label: "BUPARID"},
{label: "BUPENSAN"},
{label: "BUPIFORAN"},
{label: "BUPIFORAN ADRENALINE"},
{label: "BUPIVACAINE ACCORD"},
{label: "BUPIVACAINE ADRENALINE MERCK"},
{label: "BUPIVACAINE ADRENALINE QUALIMED (25"},
{label: "BUPIVACAINE ADRENALINE QUALIMED (50"},
{label: "BUPIVACAINE AGUETTANT"},
{label: "BUPIVACAINE B BRAUN"},
{label: "BUPIVACAINE CHLORHYDRATE QUALIMED"},
{label: "BUPIVACAINE MYLAN"},
{label: "BUPIVACAINE NORIDEM"},
{label: "BUPIVACAINE POUR RACHIANESTHESIE AGUETTANT"},
{label: "BUPIVACAINE VIATRIS"},
{label: "BUPRADEX"},
{label: "BUPRENORPHINE ABBOTT"},
{label: "BUPRENORPHINE ARROW"},
{label: "BUPRENORPHINE ARROW GENERIQUES"},
{label: "BUPRENORPHINE BIOGARAN"},
{label: "BUPRENORPHINE CRISTERS"},
{label: "BUPRENORPHINE EG"},
{label: "BUPRENORPHINE ETHYPHARM"},
{label: "BUPRENORPHINE SANDOZ"},
{label: "BUPRENORPHINE SUN"},
{label: "BUPRENORPHINE TEVA"},
{label: "BUPRENORPHINE TORRENT"},
{label: "BUPRENORPHINE VIATRIS"},
{label: "BUPRENORPHINE/NALOXONE ARROW"},
{label: "BUPRENORPHINE/NALOXONE G.L. PHARMA"},
{label: "BUPRENORPHINE/NALOXONE VIATRIS"},
{label: "BURINEX"},
{label: "BURINEX AMILORIDE"},
{label: "BUSCOPAN"},
{label: "BUSILVEX"},
{label: "BUSPAR"},
{label: "BUSPIRONE BMS"},
{label: "BUSPIRONE MYLAN PHARMA"},
{label: "BUSPIRONE SANDOZ"},
{label: "BUSPIRONE TEVA"},
{label: "BUSPIRONE VIATRIS"},
{label: "BUSULFAN ACCORD"},
{label: "BUSULFAN FRESENIUS KABI"},
{label: "BUSULFAN KOANAA"},
{label: "BUSULFAN MYLAN"},
{label: "BUSULFAN TILLOMED"},
{label: "BUTAZOLIDINE"},
{label: "BUTIGEN"},
{label: "BUTIX"},
{label: "BUTOBARBITAL DIPHARMA"},
{label: "BUTOID"},
{label: "BUTOVENT PULVINAL"},
{label: "BUVENTOL EASYHALER"},
{label: "BUVIDAL"},
{label: "BUXUS SEMPERVIRENS BOIRON"},
{label: "BUXUS SEMPERVIRENS LEHNING"},
{label: "BYANNLI"},
{label: "BYDUREON"},
{label: "BYETTA"},
{label: "BYLVAY"},
{label: "C TONIC"},
{label: "CABASER"},
{label: "CABAZITAXEL ACCORD"},
{label: "CABAZITAXEL EG"},
{label: "CABAZITAXEL EVER PHARMA"},
{label: "CABAZITAXEL FRESENIUS KABI"},
{label: "CABAZITAXEL MEDAC"},
{label: "CABAZITAXEL MYLAN"},
{label: "CABAZITAXEL REDDY PHARMA"},
{label: "CABAZITAXEL SANDOZ"},
{label: "CABAZITAXEL TEVA PHARMA"},
{label: "CABAZITAXEL TEVA SANTE"},
{label: "CABAZITAXEL TILLOMED"},
{label: "CABAZITAXEL VIATRIS"},
{label: "CABAZITAXEL ZENTIVA"},
{label: "CABERGOLINE SANDOZ"},
{label: "CABERGOLINE TEVA"},
{label: "CABESOL"},
{label: "CABILOU"},
{label: "CABLIVI"},
{label: "CABOMETYX"},
{label: "CACAO BOIRON"},
{label: "CACIT"},
{label: "CACIT VITAMINE D3"},
{label: "CACTUS GRANDIFLORUS BOIRON"},
{label: "CACTUS GRANDIFLORUS LEHNING"},
{label: "CACTUS GRANDIFLORUS WELEDA"},
{label: "CADDERA"},
{label: "CADELIUS"},
{label: "CADENS"},
{label: "CADITAR"},
{label: "CADMIUM METALLICUM BOIRON"},
{label: "CADMIUM SULFURICUM BOIRON"},
{label: "CADMIUM SULFURICUM LEHNING"},
{label: "CADUET"},
{label: "CAELYX PEGYLATED LIPOSOMAL"},
{label: "CAESIUM MURIATICUM BOIRON"},
{label: "CAFEINE AGUETTANT"},
{label: "CAJUPUTUM BOIRON"},
{label: "CAL- MAG- NA"},
{label: "CALADIUM SEGUINUM BOIRON"},
{label: "CALADIUM SEGUINUM LEHNING"},
{label: "CALCAREA CARBONICA OSTREARUM BOIRON"},
{label: "CALCAREA CARBONICA OSTREARUM FERRIER"},
{label: "CALCAREA CARBONICA OSTREARUM LEHNING"},
{label: "CALCAREA CARBONICA OSTREARUM WELEDA"},
{label: "CALCAREA COMPOSE BOIRON"},
{label: "CALCAREA FLUORICA BOIRON"},
{label: "CALCAREA FLUORICA LEHNING"},
{label: "CALCAREA FLUORICA WELEDA"},
{label: "CALCAREA IODATA BOIRON"},
{label: "CALCAREA IODATA LEHNING"},
{label: "CALCAREA IODATA WELEDA"},
{label: "CALCAREA PHOSPHORICA BOIRON"},
{label: "CALCAREA PHOSPHORICA LEHNING"},
{label: "CALCAREA PHOSPHORICA WELEDA"},
{label: "CALCAREA PICRATA BOIRON"},
{label: "CALCAREA SILICICA BOIRON"},
{label: "CALCAREA SULFURICA BOIRON"},
{label: "CALCAREA SULFURICA LEHNING"},
{label: "CALCAREA SULFURICA WELEDA"},
{label: "CALCIBRONAT"},
{label: "CALCIDIA"},
{label: "CALCIDIA ENFANTS"},
{label: "CALCIDOSE"},
{label: "CALCIDOSE VITAMINE D3"},
{label: "CALCIFEDIOL FAES"},
{label: "CALCIFEDIOL GERDA"},
{label: "CALCIFEDIOL RPG"},
{label: "CALCIFLUOR"},
{label: "CALCIFORM"},
{label: "CALCIFORTE"},
{label: "CALCIFORTE VITAMINE D3"},
{label: "CALCIGAUROL"},
{label: "CALCIGAUROL VITAMINE D3"},
{label: "CALCIPARINE"},
{label: "CALCIPARINE SOUS CUTANEE"},
{label: "CALCIPOTRIOL SANDOZ"},
{label: "CALCIPOTRIOL/BETAMETHASONE MEDIPHA SANTE"},
{label: "CALCIPOTRIOL/BETAMETHASONE RATIOPHARM"},
{label: "CALCIPOTRIOL/BETAMETHASONE SANDOZ"},
{label: "CALCIPOTRIOL/BETAMETHASONE TEVA"},
{label: "CALCIPRAT"},
{label: "CALCIPRAT VITAMINE D3"},
{label: "CALCITONINE CEDIAT"},
{label: "CALCITONINE GNR"},
{label: "CALCITONINE L.LAFON"},
{label: "CALCITONINE PHARMY II"},
{label: "CALCITONINE SANDOZ"},
{label: "CALCIUM - VITAMINE D3 GNR"},
{label: "CALCIUM ALKO"},
{label: "CALCIUM ARROW"},
{label: "CALCIUM CHLORURE B BRAUN"},
{label: "CALCIUM CHLORURE BRUNEAU"},
{label: "CALCIUM CHLORURE COOPER"},
{label: "CALCIUM EDETATE DE SODIUM SERB"},
{label: "CALCIUM EXPANSCIENCE"},
{label: "CALCIUM FOURNIER"},
{label: "CALCIUM GLUCONATE"},
{label: "CALCIUM HERMES"},
{label: "CALCIUM LEVURE SEROLAM"},
{label: "CALCIUM SANDOZ"},
{label: "CALCIUM SEROLAM"},
{label: "CALCIUM SORBISTERIT"},
{label: "CALCIUM VIATRIS"},
{label: "CALCIUM VITAMINE D3 ARROW"},
{label: "CALCIUM VITAMINE D3 BIOGARAN"},
{label: "CALCIUM VITAMINE D3 BOUCHARA RECORDATI"},
{label: "CALCIUM VITAMINE D3 CALCIMED"},
{label: "CALCIUM VITAMINE D3 EFERVIT"},
{label: "CALCIUM VITAMINE D3 EG"},
{label: "CALCIUM VITAMINE D3 EXPANSCIENCE"},
{label: "CALCIUM VITAMINE D3 FOURNIER"},
{label: "CALCIUM VITAMINE D3 HERMES"},
{label: "CALCIUM VITAMINE D3 HERMEVIT"},
{label: "CALCIUM VITAMINE D3 LAPHAL"},
{label: "CALCIUM VITAMINE D3 LAPHAL DEVELOPPEMENT"},
{label: "CALCIUM VITAMINE D3 MEDA"},
{label: "CALCIUM VITAMINE D3 PROPHARM'AIX INDUSTRIE"},
{label: "CALCIUM VITAMINE D3 RANBAXY"},
{label: "CALCIUM VITAMINE D3 RATIOPHARM"},
{label: "CALCIUM VITAMINE D3 RICHELET"},
{label: "CALCIUM VITAMINE D3 SANDOZ"},
{label: "CALCIUM VITAMINE D3 SOCOPHARM"},
{label: "CALCIUM VITAMINE D3 TEVA"},
{label: "CALCIUM VITAMINE D3 VIATRIS"},
{label: "CALCIUM/VITAMINE D3 MEDA PHARMA"},
{label: "CALCIUM/VITAMINE D3 TEVA SANTE"},
{label: "CALCIUM/VITAMINE D3 ZYDUS"},
{label: "CALCOS VITAMINE D3"},
{label: "CALDEVITA"},
{label: "CALDINE"},
{label: "CALEGOM VITAMINE D3"},
{label: "CALENDORON"},
{label: "CALENDULA BOIRON"},
{label: "CALENDULA COMPLEXE N°89"},
{label: "CALENDULA OFFICINALIS BOIRON"},
{label: "CALENDULA OFFICINALIS FERRIER"},
{label: "CALENDULA OFFICINALIS LEHNING"},
{label: "CALENDULA OFFICINALIS LEHNING TEINTURE MERE"},
{label: "CALENDULA OFFICINALIS TEINTURE MERE BOIRON"},
{label: "CALENDULA OFFICINALIS TEINTURE MERE LEHNING"},
{label: "CALENDULA OFFICINALIS TEINTURE MERE WELEDA"},
{label: "CALENDULA OFFICINALIS WELEDA"},
{label: "CALENDULENE"},
{label: "CALMADERM"},
{label: "CALMAFIT ADULTES"},
{label: "CALMAFIT ENFANTS"},
{label: "CALMAFIT TISANE SOMMEIL"},
{label: "CALMICORT"},
{label: "CALMIFLORINE"},
{label: "CALMIPHASE"},
{label: "CALMIXENE"},
{label: "CALMODIGER"},
{label: "CALMODINE"},
{label: "CALMOROIDE"},
{label: "CALMOSEDYL ENFANTS"},
{label: "CALMOTISAN"},
{label: "CALNOVA"},
{label: "CALOTROPIS GIGANTEA BOIRON"},
{label: "CALPEROS"},
{label: "CALPEROS D3"},
{label: "CALPRIMUM"},
{label: "CALQUENCE"},
{label: "CALSCAN"},
{label: "CALSCORBAT ADULTES"},
{label: "CALSCORBAT ENFANTS"},
{label: "CALSOMES"},
{label: "CALSYN"},
{label: "CALTHA PALUSTRIS BOIRON"},
{label: "CALTRATE"},
{label: "CALTRATE VITAMINE D3"},
{label: "CALYPTOL INHALANT"},
{label: "CAMCOLIT"},
{label: "CAMILIA"},
{label: "CAMPEL"},
{label: "CAMPHOCALYPTOL ADULTES"},
{label: "CAMPHOCALYPTOL BISMUTH ADULTES"},
{label: "CAMPHOCALYPTOL NOURRISSONS"},
{label: "CAMPHOCALYPTOL SIMPLE ADULTES"},
{label: "CAMPHOCALYPTOL SIMPLE ENFANTS"},
{label: "CAMPHOCALYPTOL SIMPLE NOURRISSONS"},
{label: "CAMPHODIONYL"},
{label: "CAMPHOPNEUMINE"},
{label: "CAMPHOPNEUMINE ADULTES"},
{label: "CAMPHOPNEUMINE ENFANTS"},
{label: "CAMPHOPNEUMINE NOURRISSONS"},
{label: "CAMPHORA BOIRON"},
{label: "CAMPHORA LEHNING"},
{label: "CAMPHORA WELEDA"},
{label: "CAMPHRICE DU CANADA"},
{label: "CAMPRAL"},
{label: "CAMPTO"},
{label: "CANATINE"},
{label: "CANCIDAS"},
{label: "CANDAZOL"},
{label: "CANDERSARTAN/HYDROCHLOROTHIAZIDE LICONSA"},
{label: "CANDESARTAN ARROW"},
{label: "CANDESARTAN ARROW GENERIQUES"},
{label: "CANDESARTAN ARROW LAB"},
{label: "CANDESARTAN BIOGARAN"},
{label: "CANDESARTAN CRISTERS"},
{label: "CANDESARTAN CRISTERS PHARMA"},
{label: "CANDESARTAN EG"},
{label: "CANDESARTAN EVOLUGEN"},
{label: "CANDESARTAN HCS"},
{label: "CANDESARTAN HYDROCHLOROTHIAZIDE RATIOPHARM"},
{label: "CANDESARTAN ISOMED"},
{label: "CANDESARTAN MYLAN"},
{label: "CANDESARTAN QUALIMED"},
{label: "CANDESARTAN RANBAXY"},
{label: "CANDESARTAN RATIO"},
{label: "CANDESARTAN RATIOPHARM"},
{label: "CANDESARTAN RATIOPHARM GENERIQUES"},
{label: "CANDESARTAN SANDOZ"},
{label: "CANDESARTAN TEVA"},
{label: "CANDESARTAN TEVA SANTE"},
{label: "CANDESARTAN VIATRIS"},
{label: "CANDESARTAN ZENTIVA"},
{label: "CANDESARTAN ZYDUS"},
{label: "CANDESARTAN/HYDROCHLOROTHIAZIDE ARROW"},
{label: "CANDESARTAN/HYDROCHLOROTHIAZIDE ARROW LAB"},
{label: "CANDESARTAN/HYDROCHLOROTHIAZIDE BIOGARAN"},
{label: "CANDESARTAN/HYDROCHLOROTHIAZIDE CRISTERS"},
{label: "CANDESARTAN/HYDROCHLOROTHIAZIDE EG"},
{label: "CANDESARTAN/HYDROCHLOROTHIAZIDE EG LABO"},
{label: "CANDESARTAN/HYDROCHLOROTHIAZIDE EVOLUGEN"},
{label: "CANDESARTAN/HYDROCHLOROTHIAZIDE HCS"},
{label: "CANDESARTAN/HYDROCHLOROTHIAZIDE ISOMED"},
{label: "CANDESARTAN/HYDROCHLOROTHIAZIDE KRKA"},
{label: "CANDESARTAN/HYDROCHLOROTHIAZIDE MYLAN PHARMA"},
{label: "CANDESARTAN/HYDROCHLOROTHIAZIDE QUALIMED"},
{label: "CANDESARTAN/HYDROCHLOROTHIAZIDE RANBAXY"},
{label: "CANDESARTAN/HYDROCHLOROTHIAZIDE RATIOPHARM"},
{label: "CANDESARTAN/HYDROCHLOROTHIAZIDE SANDOZ"},
{label: "CANDESARTAN/HYDROCHLOROTHIAZIDE TEVA"},
{label: "CANDESARTAN/HYDROCHLOROTHIAZIDE TEVA PHARMA"},
{label: "CANDESARTAN/HYDROCHLOROTHIAZIDE TEVA SANTE"},
{label: "CANDESARTAN/HYDROCHLOROTHIAZIDE VIATRIS"},
{label: "CANDESARTAN/HYDROCHLOROTHIAZIDE ZENTIVA"},
{label: "CANDESARTAN/HYDROCHLOROTHIAZIDE ZYDUS"},
{label: "CANEPHRON"},
{label: "CANESTEN"},
{label: "CANOL"},
{label: "CANTABILINE"},
{label: "CANTADRILL"},
{label: "CANTALENE"},
{label: "CANTEINE"},
{label: "CANTHARIS BOIRON"},
{label: "CANTHARIS LEHNING"},
{label: "CANTHARIS WELEDA"},
{label: "CANTIL"},
{label: "CANUGEL"},
{label: "CAPECITABINE ACCORD"},
{label: "CAPECITABINE ARROW"},
{label: "CAPECITABINE BIOGARAN"},
{label: "CAPECITABINE CRISTERS"},
{label: "CAPECITABINE EG"},
{label: "CAPECITABINE FAIR-MED"},
{label: "CAPECITABINE FRESENIUS KABI"},
{label: "CAPECITABINE KOANAA"},
{label: "CAPECITABINE REDDY PHARMA"},
{label: "CAPECITABINE SANDOZ"},
{label: "CAPECITABINE SUN"},
{label: "CAPECITABINE TEVA"},
{label: "CAPECITABINE TIEFENBACHER"},
{label: "CAPECITABINE VIATRIS"},
{label: "CAPECITABINE ZENTIVA"},
{label: "CAPERGYL"},
{label: "CAPIPRO"},
{label: "CAPRELSA"},
{label: "CAPSIC"},
{label: "CAPSICUM ANNUUM BOIRON"},
{label: "CAPSICUM ANNUUM LEHNING"},
{label: "CAPSICUM ANNUUM WELEDA"},
{label: "CAPSICUM BEIERSDORF"},
{label: "CAPSION"},
{label: "CAPSOLINE AUBEPINE"},
{label: "CAPSOLINE FUCUS"},
{label: "CAPSOLINE KARKADE"},
{label: "CAPSOLINE PASSIFLORE"},
{label: "CAPSOLINE PRELE"},
{label: "CAPSOLINE REINE DES PRES"},
{label: "CAPSYL"},
{label: "CAPTEA"},
{label: "CAPTOLANE"},
{label: "CAPTOPRIL APOTEX"},
{label: "CAPTOPRIL APOTEX FRANCE"},
{label: "CAPTOPRIL ARROW"},
{label: "CAPTOPRIL BIOGALENIQUE"},
{label: "CAPTOPRIL BIOGARAN"},
{label: "CAPTOPRIL BIOSTABILEX"},
{label: "CAPTOPRIL BMS"},
{label: "CAPTOPRIL BMS GENERIQUES"},
{label: "CAPTOPRIL CRISTERS"},
{label: "CAPTOPRIL EG"},
{label: "CAPTOPRIL GENEVAR"},
{label: "CAPTOPRIL HYDROCHLOROTHIAZIDE ARROW"},
{label: "CAPTOPRIL HYDROCHLOROTHIAZIDE SANDOZ"},
{label: "CAPTOPRIL HYDROCHLOTHIAZIDE G GAM"},
{label: "CAPTOPRIL QUALIMED"},
{label: "CAPTOPRIL RATIOPHARM"},
{label: "CAPTOPRIL SANDOZ"},
{label: "CAPTOPRIL TEVA"},
{label: "CAPTOPRIL TEVA PHARMA"},
{label: "CAPTOPRIL VIATRIS"},
{label: "CAPTOPRIL ZENTIVA"},
{label: "CAPTOPRIL ZYDUS"},
{label: "CAPTOPRIL/HYDROCHLOROTHIAZIDE ACTAVIS"},
{label: "CAPTOPRIL/HYDROCHLOROTHIAZIDE BIOGARAN"},
{label: "CAPTOPRIL/HYDROCHLOROTHIAZIDE CRISTERS"},
{label: "CAPTOPRIL/HYDROCHLOROTHIAZIDE EG"},
{label: "CAPTOPRIL/HYDROCHLOROTHIAZIDE PHARMAKI GENERICS"},
{label: "CAPTOPRIL/HYDROCHLOROTHIAZIDE RATIOPHARM"},
{label: "CAPTOPRIL/HYDROCHLOROTHIAZIDE TEVA"},
{label: "CAPTOPRIL/HYDROCHLOROTHIAZIDE VIATRIS"},
{label: "CAPTOPRIL/HYDROCHLOROTHIAZIDE ZENTIVA"},
{label: "CARBACTIVE"},
{label: "CARBAGLU"},
{label: "CARBAMAZEPINE GNR LP"},
{label: "CARBAMAZEPINE NOVARTIS PHARMA"},
{label: "CARBAMAZEPINE SANDOZ L.P."},
{label: "CARBAMAZEPINE TEVA"},
{label: "CARBAMAZEPINE VIATRIS L.P."},
{label: "CARBAMAZEPINE WINTHROP"},
{label: "CARBAMAZEPINE WINTHROP L.P."},
{label: "CARBASALATE DE CALCIUM UPSA"},
{label: "CARBEM"},
{label: "CARBETOCINE AGUETTANT"},
{label: "CARBIMAZOLE LIME"},
{label: "CARBO ANIMALIS BOIRON"},
{label: "CARBO ANIMALIS LEHNING"},
{label: "CARBO VEGETABILIS BOIRON"},
{label: "CARBO VEGETABILIS LEHNING"},
{label: "CARBO VEGETABILIS WELEDA"},
{label: "CARBO-CORT"},
{label: "CARBO-DOME"},
{label: "CARBOCAINE"},
{label: "CARBOCISTEINE ARROW"},
{label: "CARBOCISTEINE BIOGARAN"},
{label: "CARBOCISTEINE BIOGARAN CONSEIL"},
{label: "CARBOCISTEINE CLARIX"},
{label: "CARBOCISTEINE COOPER"},
{label: "CARBOCISTEINE DELALANDE"},
{label: "CARBOCISTEINE EG"},
{label: "CARBOCISTEINE EG LABO CONSEIL"},
{label: "CARBOCISTEINE H2 PHARMA"},
{label: "CARBOCISTEINE H2 PHARMA 2% ENFANTS SANS SUCRE"},
{label: "CARBOCISTEINE HEXAL SANTE"},
{label: "CARBOCISTEINE IVAX"},
{label: "CARBOCISTEINE LABORATOIRE UNITHER"},
{label: "CARBOCISTEINE QUALIMED"},
{label: "CARBOCISTEINE RATIOPHARM CONSEIL"},
{label: "CARBOCISTEINE RPG"},
{label: "CARBOCISTEINE RPG ADULTE"},
{label: "CARBOCISTEINE RPG NOURRISSON ET ENFANT"},
{label: "CARBOCISTEINE RPG SANS SUCRE ADULTE"},
{label: "CARBOCISTEINE RPG SANS SUCRE NOURRISSON ET ENFANT"},
{label: "CARBOCISTEINE SANDOZ CONSEIL"},
{label: "CARBOCISTEINE SANOFI SYNTHELABO OTC"},
{label: "CARBOCISTEINE SANOFI-AVENTIS FRANCE"},
{label: "CARBOCISTEINE TEVA CONSEIL"},
{label: "CARBOCISTEINE THERAPLIX"},
{label: "CARBOCISTEINE UNISTICK"},
{label: "CARBOCISTEINE UNITHER PHARMACEUTICALS"},
{label: "CARBOCISTEINE UPSA"},
{label: "CARBOCISTEINE URGO"},
{label: "CARBOCISTEINE URGO 5% ADULTES SANS SUCRE"},
{label: "CARBOCISTEINE VIATRIS CONSEIL"},
{label: "CARBOCISTEINE ZENTIVA CONSEIL"},
{label: "CARBODIPHA"},
{label: "CARBOLEVURE ADULTES"},
{label: "CARBOLEVURE ENFANTS"},
{label: "CARBOLICUM ACIDUM BOIRON"},
{label: "CARBOMERE CHAUVIN"},
{label: "CARBOMERE EUROPHTA"},
{label: "CARBOMIX"},
{label: "CARBOMUCIL"},
{label: "CARBONAPHTINE PECTINEE"},
{label: "CARBONEUM SULFURATUM BOIRON"},
{label: "CARBONEX"},
{label: "CARBOPHAGIX"},
{label: "CARBOPHOS"},
{label: "CARBOPLATINE ACCORD"},
{label: "CARBOPLATINE AGUETTANT"},
{label: "CARBOPLATINE ARROW"},
{label: "CARBOPLATINE CTRS"},
{label: "CARBOPLATINE HOSPIRA"},
{label: "CARBOPLATINE INTSEL CHIMOS"},
{label: "CARBOPLATINE KABI"},
{label: "CARBOPLATINE MEDAC"},
{label: "CARBOPLATINE MEDIPHA SANTE"},
{label: "CARBOPLATINE QUALIMED"},
{label: "CARBOPLATINE SANDOZ"},
{label: "CARBOPLATINE SUN"},
{label: "CARBOPLATINE TEVA"},
{label: "CARBOPLATINE VIATRIS"},
{label: "CARBOPLATINE WINTHROP"},
{label: "CARBOSYLANE"},
{label: "CARBOSYLANE ENFANT"},
{label: "CARBOSYMAG"},
{label: "CARBOXELLE"},
{label: "CARBUNITHER"},
{label: "CARDASA"},
{label: "CARDELENE L.P."},
{label: "CARDENSIEL"},
{label: "CARDEPA"},
{label: "CARDIAZIDINE"},
{label: "CARDIL L.P."},
{label: "CARDINE BOIRON"},
{label: "CARDINE WELEDA"},
{label: "CARDIOCALM"},
{label: "CARDIOCOR"},
{label: "CARDIOGEN-82"},
{label: "CARDIOLITE."},
{label: "CARDIOMIBI"},
{label: "CARDIOQUINE"},
{label: "CARDIOQUINE L P"},
{label: "CARDIOQUINE L.P."},
{label: "CARDIORYTHMINE"},
{label: "CARDIOSOLUPSAN"},
{label: "CARDIOXANE"},
{label: "CARDOVAR"},
{label: "CARDURAN"},
{label: "CARDUUS MARIANUS BOIRON"},
{label: "CARDUUS MARIANUS FERRIER"},
{label: "CARDUUS MARIANUS LEHNING"},
{label: "CARDUUS MARIANUS WELEDA"},
{label: "CARENCYL"},
{label: "CARIBAN"},
{label: "CARICA PAPAYA BOIRON"},
{label: "CARLIN"},
{label: "CARLYTENE"},
{label: "CARMIN DINDIGO PROVINGO"},
{label: "CARMUSTINE ACCORD"},
{label: "CARMUSTINE ACCORDPHARMA"},
{label: "CARMUSTINE ARROW"},
{label: "CARMUSTINE OBVIUS"},
{label: "CARMYNE"},
{label: "CARNERETO"},
{label: "CAROMINTHE"},
{label: "CARPILO"},
{label: "CARTEABAK"},
{label: "CARTEOL"},
{label: "CARTEOL L.P."},
{label: "CARTEOLOL CHAUVIN"},
{label: "CARTIFIT"},
{label: "CARTILAGE DE CONJUGAISON BOIRON"},
{label: "CARTILAGO BOIRON"},
{label: "CARTILAGO WELEDA"},
{label: "CARTREX"},
{label: "CARUDOL"},
{label: "CARUM CARVI BOIRON"},
{label: "CARUPI"},
{label: "CARVECORAL"},
{label: "CARVEDILOL ARROW"},
{label: "CARVEDILOL BGR"},
{label: "CARVEDILOL BIOGARAN"},
{label: "CARVEDILOL EG"},
{label: "CARVEDILOL PFIZER"},
{label: "CARVEDILOL SANDOZ"},
{label: "CARVEDILOL TEVA"},
{label: "CARVEDILOL TEVA SANTE"},
{label: "CARVEDILOL VIATRIS"},
{label: "CARVEDILOL WINTHROP"},
{label: "CARVEDILOL WÖRWAG"},
{label: "CARVEDILOL ZENTIVA"},
{label: "CARVYKTI"},
{label: "CARYOLYSINE"},
{label: "CASCARA ARDEVAL"},
{label: "CASCARA SAGRADA BOIRON"},
{label: "CASCARA SAGRADA LEHNING"},
{label: "CASCARILLA BOIRON"},
{label: "CASENLAX"},
{label: "CASODEX"},
{label: "CASPOFUNGINE ACCORD"},
{label: "CASPOFUNGINE ALTAN"},
{label: "CASPOFUNGINE B. BRAUN"},
{label: "CASPOFUNGINE EG"},
{label: "CASPOFUNGINE FRESENIUS KABI"},
{label: "CASPOFUNGINE HIKMA"},
{label: "CASPOFUNGINE LORIEN"},
{label: "CASPOFUNGINE NEGMA"},
{label: "CASPOFUNGINE NORIDEM"},
{label: "CASPOFUNGINE PANPHARMA"},
{label: "CASPOFUNGINE REDDY PHARMA"},
{label: "CASPOFUNGINE SANDOZ"},
{label: "CASPOFUNGINE STRAGEN"},
{label: "CASPOFUNGINE SUN"},
{label: "CASPOFUNGINE TEVA"},
{label: "CASPOFUNGINE TEVA SANTE"},
{label: "CASPOFUNGINE TILLOMED"},
{label: "CASPOFUNGINE VIATRIS"},
{label: "CASPOFUNGINE XELLIA"},
{label: "CASSIS BOIRON"},
{label: "CASSIS NATURA MEDICA"},
{label: "CASTANEA VESCA BOIRON"},
{label: "CASTOR EQUI BOIRON"},
{label: "CATABEX"},
{label: "CATACOL"},
{label: "CATALGINE"},
{label: "CATALGINE ADULTES"},
{label: "CATALGINE ENFANTS ET NOURRISSONS"},
{label: "CATALGINE NORMALE"},
{label: "CATAPLASME A L'ACETOTARTRATE D'ALUMINE DEFRESNE"},
{label: "CATAPRESSAN"},
{label: "CATAPRESSAN A LIBERATION PROLONGEE"},
{label: "CATARIDOL"},
{label: "CATARSTAT"},
{label: "CAULOPHYLLUM THALICTROIDES BOIRON"},
{label: "CAULOPHYLLUM THALICTROIDES LEHNING"},
{label: "CAULOPHYLLUM THALICTROIDES WELEDA"},
{label: "CAUSTICUM BOIRON"},
{label: "CAUSTICUM LEHNING"},
{label: "CAUSTICUM WELEDA"},
{label: "CAUSTINERF ARSENICAL"},
{label: "CAUSTINERF SANS ARSENIC"},
{label: "CAVERJECT"},
{label: "CAVERJECTDUAL"},
{label: "CAYSTON"},
{label: "CEA-SCAN"},
{label: "CEANOTHUS AMERICANUS SICCUM BOIRON"},
{label: "CEANOTHUS AMERICANUS SICCUM LEHNING"},
{label: "CEANOTHUS AMERICANUS SICCUM WELEDA"},
{label: "CEBEDEXACOL"},
{label: "CEBEMYXINE"},
{label: "CEBENICOL"},
{label: "CEBERA"},
{label: "CEBERA ENFANTS"},
{label: "CEBESINE"},
{label: "CEBEVIR"},
{label: "CEBUTID"},
{label: "CEBUTID LP"},
{label: "CEDACONDA"},
{label: "CEDILANIDE"},
{label: "CEDIXEN"},
{label: "CEDRON BOIRON"},
{label: "CEDRON LEHNING"},
{label: "CEFACET"},
{label: "CEFACIDAL"},
{label: "CEFACLOR BIOGARAN"},
{label: "CEFACLOR DEXO"},
{label: "CEFACLOR DEXO LP"},
{label: "CEFACLOR G GAM"},
{label: "CEFACLOR GNR"},
{label: "CEFACLOR MYLAN"},
{label: "CEFACLOR QUALIMED"},
{label: "CEFACLOR RANBAXY"},
{label: "CEFACLOR RPG"},
{label: "CEFACLOR RPG LP"},
{label: "CEFACLOR SANDOZ"},
{label: "CEFACLOR TEVA"},
{label: "CEFACLOR TEVA CLASSICS"},
{label: "CEFACLOR TEVA PHARMA"},
{label: "CEFADROXIL"},
{label: "CEFADROXIL APOTEX"},
{label: "CEFADROXIL APOTEX FRANCE"},
{label: "CEFADROXIL BIOGARAN"},
{label: "CEFADROXIL BMS"},
{label: "CEFADROXIL BMS GENERIQUES"},
{label: "CEFADROXIL CEHEL PHARMA"},
{label: "CEFADROXIL CLL PHARMA"},
{label: "CEFADROXIL EG"},
{label: "CEFADROXIL G GAM"},
{label: "CEFADROXIL GNR"},
{label: "CEFADROXIL IVAX"},
{label: "CEFADROXIL MYLAN"},
{label: "CEFADROXIL RATIOPHARM"},
{label: "CEFADROXIL SANDOZ"},
{label: "CEFADROXIL SUBSTIPHARM"},
{label: "CEFADROXIL TEVA"},
{label: "CEFADROXIL ZYDUS"},
{label: "CEFALEXINE RPG"},
{label: "CEFALINE HAUTH"},
{label: "CEFALINE PYRAZOLE A LA NORAMIDOPYRINE"},
{label: "CEFALOJECT"},
{label: "CEFALOTINE GLAXO"},
{label: "CEFALOTINE MYLAN"},
{label: "CEFALOTINE PANPHARMA"},
{label: "CEFALOTINE QUALIMED"},
{label: "CEFAMANDOLE FLAVELAB"},
{label: "CEFAMANDOLE MEDIFA K24"},
{label: "CEFAMANDOLE MYLAN"},
{label: "CEFAMANDOLE PANPHARMA"},
{label: "CEFAMANDOLE PHARMANOVA"},
{label: "CEFAMIG"},
{label: "CEFAPEROS"},
{label: "CEFAPYRINE"},
{label: "CEFATRIZINE ACS DOBFAR GENERICS"},
{label: "CEFATRIZINE ARROW"},
{label: "CEFATRIZINE BIOGARAN"},
{label: "CEFATRIZINE EG"},
{label: "CEFATRIZINE IVAX"},
{label: "CEFATRIZINE MEDIFA K"},
{label: "CEFATRIZINE MYLAN"},
{label: "CEFATRIZINE QUALIMED"},
{label: "CEFATRIZINE SANDOZ"},
{label: "CEFATRIZINE TEVA"},
{label: "CEFAXYL"},
{label: "CEFAZOLINE FLAVELAB"},
{label: "CEFAZOLINE HOSPIRA"},
{label: "CEFAZOLINE LDP-LABORATORIOS TORLAN"},
{label: "CEFAZOLINE MERCK"},
{label: "CEFAZOLINE NORIDEM"},
{label: "CEFAZOLINE PANPHARMA"},
{label: "CEFAZOLINE QILU"},
{label: "CEFAZOLINE QUALIMED"},
{label: "CEFAZOLINE VIATRIS"},
{label: "CEFEPIME ACCORD"},
{label: "CEFEPIME AUROBINDO"},
{label: "CEFEPIME DOTOPHARMA"},
{label: "CEFEPIME MYLAN"},
{label: "CEFEPIME NORIDEM"},
{label: "CEFEPIME PANPHARMA"},
{label: "CEFEPIME PFIZER"},
{label: "CEFEPIME STRAGEN"},
{label: "CEFEPIME SUBSTIPHARM"},
{label: "CEFEPIME VIATRIS"},
{label: "CEFETIC ENFANTS ET NOURRISSONS"},
{label: "CEFIREX"},
{label: "CEFIXIME ACTAVIS"},
{label: "CEFIXIME ACTAVIS ENFANTS"},
{label: "CEFIXIME ALMUS"},
{label: "CEFIXIME ARROW"},
{label: "CEFIXIME ARROW ENFANTS"},
{label: "CEFIXIME ARROW LAB"},
{label: "CEFIXIME BIOGARAN"},
{label: "CEFIXIME CRISTERS"},
{label: "CEFIXIME EG"},
{label: "CEFIXIME EG LABO"},
{label: "CEFIXIME EVOLUGEN"},
{label: "CEFIXIME INNFARM"},
{label: "CEFIXIME MYLAN"},
{label: "CEFIXIME NECLIFE"},
{label: "CEFIXIME PFIZER"},
{label: "CEFIXIME QUALIMED"},
{label: "CEFIXIME RANBAXY"},
{label: "CEFIXIME RATIOPHARM"},
{label: "CEFIXIME TEVA"},
{label: "CEFIXIME VIATRIS"},
{label: "CEFIXIME ZENTIVA"},
{label: "CEFIXIME ZYDUS"},
{label: "CEFIZOX"},
{label: "CEFMENOXIME TAKEDA"},
{label: "CEFOBIS"},
{label: "CEFOTAXIME ABBOTT"},
{label: "CEFOTAXIME CLL PHARMA"},
{label: "CEFOTAXIME DAKOTA PHARM"},
{label: "CEFOTAXIME G GAM"},
{label: "CEFOTAXIME NORIDEM"},
{label: "CEFOTAXIME PANPHARMA"},
{label: "CEFOTAXIME QUALIMED"},
{label: "CEFOTAXIME ROUSSEL DIAMANT"},
{label: "CEFOTAXIME VIATRIS"},
{label: "CEFOTAXIME WINTHROP"},
{label: "CEFOXITINE HOSPIRA"},
{label: "CEFOXITINE LDP-LABORATORIOS TORLAN"},
{label: "CEFOXITINE PANPHARMA"},
{label: "CEFOXITINE SUBSTIPHARM"},
{label: "CEFPODOXIME ACTAVIS"},
{label: "CEFPODOXIME ALMUS"},
{label: "CEFPODOXIME ARROW"},
{label: "CEFPODOXIME ARROW ENFANTS ET NOURRISSONS"},
{label: "CEFPODOXIME ARROW GENERIQUES ENFANTS ET NOURRISSONS"},
{label: "CEFPODOXIME ARROW LAB"},
{label: "CEFPODOXIME BGR"},
{label: "CEFPODOXIME BIOGALENIQUE"},
{label: "CEFPODOXIME BIOGARAN"},
{label: "CEFPODOXIME BIOGARAN ENFANTS ET NOURRISSONS"},
{label: "CEFPODOXIME CRISTERS"},
{label: "CEFPODOXIME EG"},
{label: "CEFPODOXIME EG ENFANTS ET NOURRISSONS"},
{label: "CEFPODOXIME EG LABO"},
{label: "CEFPODOXIME EG LABO LABORATOIRE EUROGENERICS"},
{label: "CEFPODOXIME EVOLUGEN"},
{label: "CEFPODOXIME ISOMED"},
{label: "CEFPODOXIME ISOMED ENFANTS ET NOURRISSONS"},
{label: "CEFPODOXIME MYLAN"},
{label: "CEFPODOXIME PFIZER"},
{label: "CEFPODOXIME QUALIHEALTH"},
{label: "CEFPODOXIME QUALIMED"},
{label: "CEFPODOXIME QUALIMED ENFANTS ET NOURRISSONS"},
{label: "CEFPODOXIME RATIOPHARM"},
{label: "CEFPODOXIME REF"},
{label: "CEFPODOXIME RPG"},
{label: "CEFPODOXIME SANDOZ"},
{label: "CEFPODOXIME SANDOZ ENFANTS ET NOURRISSONS"},
{label: "CEFPODOXIME SUN"},
{label: "CEFPODOXIME TEVA"},
{label: "CEFPODOXIME TEVA ENFANTS ET NOURRISSONS"},
{label: "CEFPODOXIME VENIPHARM ENFANTS ET NOURRISSONS"},
{label: "CEFPODOXIME VIATRIS"},
{label: "CEFPODOXIME ZENTIVA"},
{label: "CEFPODOXIME ZENTIVA ENFANTS ET NOURRISSONS"},
{label: "CEFPODOXIME ZYDUS"},
{label: "CEFRADINE BOUCHARA-RECORDATI"},
{label: "CEFRADINE GNR"},
{label: "CEFRADINE PANPHARMA"},
{label: "CEFRADINE ZYDUS"},
{label: "CEFROM"},
{label: "CEFTAZIDIME ACTAVIS"},
{label: "CEFTAZIDIME AGUETTANT"},
{label: "CEFTAZIDIME ARROW"},
{label: "CEFTAZIDIME ARROW ENFANTS ET NOURRISSONS"},
{label: "CEFTAZIDIME EG"},
{label: "CEFTAZIDIME EG ENFANTS ET NOURRISSONS"},
{label: "CEFTAZIDIME HOSPIRA"},
{label: "CEFTAZIDIME K24 PHARMACEUTICALS"},
{label: "CEFTAZIDIME KABI"},
{label: "CEFTAZIDIME MIP"},
{label: "CEFTAZIDIME NIALEX"},
{label: "CEFTAZIDIME PANMEDICA"},
{label: "CEFTAZIDIME PANMEDICA ENFANTS ET NOURRISSONS"},
{label: "CEFTAZIDIME PANPHARMA"},
{label: "CEFTAZIDIME PANPHARMA ENFANTS ET NOURRISSONS"},
{label: "CEFTAZIDIME QILU"},
{label: "CEFTAZIDIME SANDOZ"},
{label: "CEFTAZIDIME SANDOZ ENFANTS ET NOURRISSONS"},
{label: "CEFTAZIDIME SUBSTIPHARM"},
{label: "CEFTAZIDIME TEVA"},
{label: "CEFTAZIDIME VIATRIS"},
{label: "CEFTAZIDIME VIATRIS ENFANTS ET NOURRISSONS"},
{label: "CEFTAZIDIME WINTHROP"},
{label: "CEFTRIAXONE ABBOTT"},
{label: "CEFTRIAXONE ACTAVIS"},
{label: "CEFTRIAXONE AGUETTANT"},
{label: "CEFTRIAXONE ALMUS"},
{label: "CEFTRIAXONE ARROW"},
{label: "CEFTRIAXONE BIOGARAN"},
{label: "CEFTRIAXONE CLL PHARMA"},
{label: "CEFTRIAXONE CRISTERS"},
{label: "CEFTRIAXONE DAKOTA PHARM"},
{label: "CEFTRIAXONE EG"},
{label: "CEFTRIAXONE EVOLUGEN"},
{label: "CEFTRIAXONE G GAM"},
{label: "CEFTRIAXONE GNR"},
{label: "CEFTRIAXONE GONNON"},
{label: "CEFTRIAXONE HOSPIRA"},
{label: "CEFTRIAXONE IBD3 PHARMA CONSULTING"},
{label: "CEFTRIAXONE IVAX"},
{label: "CEFTRIAXONE KABI"},
{label: "CEFTRIAXONE KALCEKS"},
{label: "CEFTRIAXONE MEDIFA K24"},
{label: "CEFTRIAXONE PANPHARMA"},
{label: "CEFTRIAXONE QILU"},
{label: "CEFTRIAXONE QUALIMED"},
{label: "CEFTRIAXONE RATIOPHARM"},
{label: "CEFTRIAXONE REF"},
{label: "CEFTRIAXONE RPG"},
{label: "CEFTRIAXONE SANDOZ"},
{label: "CEFTRIAXONE SG PHARM"},
{label: "CEFTRIAXONE SG-PHARM"},
{label: "CEFTRIAXONE SUBSTIPHARM"},
{label: "CEFTRIAXONE SUN"},
{label: "CEFTRIAXONE TABUGEN"},
{label: "CEFTRIAXONE TEVA"},
{label: "CEFTRIAXONE TEVA CLASSICS"},
{label: "CEFTRIAXONE TEVA SANTE"},
{label: "CEFTRIAXONE TRADIPHAR"},
{label: "CEFTRIAXONE VIATRIS"},
{label: "CEFTRIAXONE WINTHROP"},
{label: "CEFTRIAXONE ZENTIVA"},
{label: "CEFTRIAXONE ZYDUS"},
{label: "CEFUROXIME ACTAVIS"},
{label: "CEFUROXIME ARROW"},
{label: "CEFUROXIME BGR"},
{label: "CEFUROXIME BIOGARAN"},
{label: "CEFUROXIME CRISTERS"},
{label: "CEFUROXIME CTRS"},
{label: "CEFUROXIME EG"},
{label: "CEFUROXIME FLAVELAB"},
{label: "CEFUROXIME HELM"},
{label: "CEFUROXIME IBD3 PHARMA CONSULTING"},
{label: "CEFUROXIME IREX"},
{label: "CEFUROXIME KABI"},
{label: "CEFUROXIME KRKA"},
{label: "CEFUROXIME MYLAN"},
{label: "CEFUROXIME MYLAN PHARMA"},
{label: "CEFUROXIME ORCHID EUROPE"},
{label: "CEFUROXIME PANPHARMA"},
{label: "CEFUROXIME PAUCOURT"},
{label: "CEFUROXIME PFIZER"},
{label: "CEFUROXIME QUALIMED"},
{label: "CEFUROXIME RANBAXY"},
{label: "CEFUROXIME RATIOPHARM"},
{label: "CEFUROXIME RPG"},
{label: "CEFUROXIME SAINT-GERMAIN"},
{label: "CEFUROXIME SANDOZ"},
{label: "CEFUROXIME TABUGEN"},
{label: "CEFUROXIME TEVA"},
{label: "CEFUROXIME VIATRIS"},
{label: "CEFUROXIME ZENTIVA"},
{label: "CEFUROXIME ZYDUS"},
{label: "CEGFILA"},
{label: "CELANCE"},
{label: "CELEBREX"},
{label: "CELECOXIB ALTER"},
{label: "CELECOXIB ARROW"},
{label: "CELECOXIB ARROW LAB"},
{label: "CELECOXIB BIOGARAN"},
{label: "CELECOXIB CRISTERS"},
{label: "CELECOXIB EG"},
{label: "CELECOXIB HCS"},
{label: "CELECOXIB MICROLABS"},
{label: "CELECOXIB MYLAN"},
{label: "CELECOXIB MYLAN PHARMA"},
{label: "CELECOXIB PHARMAKI GENERICS"},
{label: "CELECOXIB RANBAXY"},
{label: "CELECOXIB SANDOZ"},
{label: "CELECOXIB TEVA"},
{label: "CELECOXIB VIATRIS"},
{label: "CELECOXIB VIATRIS GENERIQUES"},
{label: "CELECOXIB ZENTIVA"},
{label: "CELECOXIB ZYDUS"},
{label: "CELECTOL"},
{label: "CELESTAMINE"},
{label: "CELESTENE"},
{label: "CELESTENE CHRONODOSE"},
{label: "CELESTODERM"},
{label: "CELESTODERM RELAIS"},
{label: "CELIPROLOL ACTAVIS"},
{label: "CELIPROLOL ALMUS"},
{label: "CELIPROLOL ARROW"},
{label: "CELIPROLOL BIOGARAN"},
{label: "CELIPROLOL CRISTERS"},
{label: "CELIPROLOL EG"},
{label: "CELIPROLOL ENIREX"},
{label: "CELIPROLOL EVOLUGEN"},
{label: "CELIPROLOL GERDA"},
{label: "CELIPROLOL GNR"},
{label: "CELIPROLOL QUALIMED"},
{label: "CELIPROLOL RATIOPHARM"},
{label: "CELIPROLOL RPG"},
{label: "CELIPROLOL SANDOZ"},
{label: "CELIPROLOL SET"},
{label: "CELIPROLOL SUN"},
{label: "CELIPROLOL TEVA"},
{label: "CELIPROLOL VIATRIS"},
{label: "CELIPROLOL ZENTIVA"},
{label: "CELIPROLOL ZYDUS"},
{label: "CELIPTAL"},
{label: "CELIPTIUM"},
{label: "CELLCEPT"},
{label: "CELLTOP"},
{label: "CELLUFLUID"},
{label: "CELLUVISC"},
{label: "CELOCURINE"},
{label: "CELSENTRI"},
{label: "CELSIOR"},
{label: "CELVAPAN"},
{label: "CEMAFLAVONE"},
{label: "CENCARAN"},
{label: "CENCHRIS CONTORTRIX BOIRON"},
{label: "CENTAUREA CYANUS BOIRON"},
{label: "CEPAGRAT"},
{label: "CEPAZINE"},
{label: "CEPEVIT"},
{label: "CEPHALANDRA INDICA BOIRON"},
{label: "CEPHALGAN"},
{label: "CEPHOROMITAN"},
{label: "CEPHYL"},
{label: "CEPLENE"},
{label: "CEPOREXINE"},
{label: "CEPORINE"},
{label: "CEPROTIN"},
{label: "CERAT INALTERABLE ROCHE POSAY"},
{label: "CERAZETTE"},
{label: "CERDELGA"},
{label: "CEREBELLUM BOIRON"},
{label: "CEREBELLUM WELEDA"},
{label: "CEREBRAX"},
{label: "CEREBRINUM BOIRON"},
{label: "CEREBROL"},
{label: "CERESTAB"},
{label: "CERETEC"},
{label: "CEREZYME"},
{label: "CERIS"},
{label: "CERNEVIT"},
{label: "CERNITOL"},
{label: "CERTICAN"},
{label: "CERTOPARINE ASPEN"},
{label: "CERUBIDINE"},
{label: "CERULYSE"},
{label: "CERUMENOL"},
{label: "CERVAGEME"},
{label: "CERVARIX"},
{label: "CERVILANE"},
{label: "CERVOXAN"},
{label: "CETAFEN"},
{label: "CETAVLEX AQUEUX"},
{label: "CETAVLON"},
{label: "CETHEXONIUM PHENYLTOLOXAMINE MENARINI"},
{label: "CETINOR"},
{label: "CETIRIZINE ACTAVIS"},
{label: "CETIRIZINE ALMUS"},
{label: "CETIRIZINE ARROW"},
{label: "CETIRIZINE ARROW CONSEIL"},
{label: "CETIRIZINE ARROW LAB"},
{label: "CETIRIZINE BIOGARAN"},
{label: "CETIRIZINE BIOGARAN CONSEIL"},
{label: "CETIRIZINE CLL PHARMA"},
{label: "CETIRIZINE CRISTERS"},
{label: "CETIRIZINE CRISTERS CONSEIL"},
{label: "CETIRIZINE CRISTERS PHARMA"},
{label: "CETIRIZINE DELALANDE"},
{label: "CETIRIZINE EG"},
{label: "CETIRIZINE EG LABO CONSEIL"},
{label: "CETIRIZINE EVOLUGEN"},
{label: "CETIRIZINE GALENIX"},
{label: "CETIRIZINE GNR"},
{label: "CETIRIZINE ISOMED"},
{label: "CETIRIZINE NEPENTHES"},
{label: "CETIRIZINE PHR LAB"},
{label: "CETIRIZINE QUALIMED"},
{label: "CETIRIZINE QUALIMED GENERIQUES"},
{label: "CETIRIZINE QUIVER"},
{label: "CETIRIZINE RANBAXY MEDICATION OFFICINALE"},
{label: "CETIRIZINE RATIOPHARM"},
{label: "CETIRIZINE RATIOPHARM CONSEIL"},
{label: "CETIRIZINE RPG"},
{label: "CETIRIZINE SALEM FRANCE"},
{label: "CETIRIZINE SANDOZ"},
{label: "CETIRIZINE SANDOZ CONSEIL"},
{label: "CETIRIZINE TEVA"},
{label: "CETIRIZINE TEVA CLASSICS"},
{label: "CETIRIZINE TEVA CONSEIL"},
{label: "CETIRIZINE TEVA PHARMA"},
{label: "CETIRIZINE TEVA SANTE"},
{label: "CETIRIZINE TEVA SANTE CONSEIL"},
{label: "CETIRIZINE UCB"},
{label: "CETIRIZINE UCB HEALTHCARE"},
{label: "CETIRIZINE VIATRIS"},
{label: "CETIRIZINE VIATRIS CONSEIL"},
{label: "CETIRIZINE ZENTIVA"},
{label: "CETIRIZINE ZYDUS"},
{label: "CETIRIZINE ZYDUS FRANCE"},
{label: "CETOGLUTARAN ADULTE"},
{label: "CETOLOG"},
{label: "CETOR"},
{label: "CETORNAN"},
{label: "CETRAFLUX"},
{label: "CETRAPHYLLINE"},
{label: "CETRAPHYLLINE A ACTION PROLONGEE"},
{label: "CETRARIA COMPLEXE N°61"},
{label: "CETRARIA ISLANDICA"},
{label: "CETRARIA ISLANDICA BOIRON"},
{label: "CETRAXAL"},
{label: "CETRIMIDE ALCOOLIQUE PIERRE FABRE"},
{label: "CETRIMIDE CONCENTRE PIERRE FABRE"},
{label: "CETROTIDE"},
{label: "CETSIM"},
{label: "CETSIM L P"},
{label: "CETSIM ROTACAPS"},
{label: "CETSIM ROTADISKS"},
{label: "CETYLPYRIDINIUM / LYSOZYME BIOGARAN CONSEIL"},
{label: "CETYLPYRIDINIUM/LYSOZYME ARROW CONSEIL"},
{label: "CEVENFACTA"},
{label: "CEVIDOL"},
{label: "CEZIBOE"},
{label: "CHABERTRY"},
{label: "CHALCOPYRITE BOIRON"},
{label: "CHALCOPYRITE WELEDA"},
{label: "CHAMOMILLA VULGARIS"},
{label: "CHAMOMILLA VULGARIS BOIRON"},
{label: "CHAMOMILLA VULGARIS FERRIER"},
{label: "CHAMOMILLA VULGARIS LEHNING"},
{label: "CHAMOMILLA VULGARIS WELEDA"},
{label: "CHAMPIX"},
{label: "CHARBENZO LAFRAN"},
{label: "CHARBON ACTIVE BOUCHARA RECORDATI"},
{label: "CHARBON ACTIVE NORIT"},
{label: "CHARBON DE BELLOC"},
{label: "CHARBON DIMETICONE CRISTERS"},
{label: "CHARBON VEGETAL BOIRON"},
{label: "CHARLIEU ANTI-POUX"},
{label: "CHEIRANTHUS CHEIRI BOIRON"},
{label: "CHELATRAN"},
{label: "CHELIDONIUM COMPOSE"},
{label: "CHELIDONIUM MAJUS"},
{label: "CHELIDONIUM MAJUS BOIRON"},
{label: "CHELIDONIUM MAJUS LEHNING"},
{label: "CHELONE GLABRA BOIRON"},
{label: "CHENODEOXYCHOLIC ACID LEADIANT"},
{label: "CHENODEX"},
{label: "CHENOPODIUM ANTHELMINTICUM BOIRON"},
{label: "CHIBRO ATROPINE"},
{label: "CHIBRO CADRON"},
{label: "CHIBRO PILOCARPINE"},
{label: "CHIBRO-PILOCARPINE"},
{label: "CHIBRO-PROSCAR"},
{label: "CHIBROXINE"},
{label: "CHIMAPHILA UMBELLATA BOIRON"},
{label: "CHIMAPHILA UMBELLATA LEHNING"},
{label: "CHIMAPHILA UMBELLATA WELEDA"},
{label: "CHINA REGIA BOIRON"},
{label: "CHINA RUBRA"},
{label: "CHINA RUBRA BOIRON"},
{label: "CHINA RUBRA LEHNING"},
{label: "CHININUM ARSENICOSUM BOIRON"},
{label: "CHININUM SULFURICUM BOIRON"},
{label: "CHININUM SULFURICUM LEHNING"},
{label: "CHINSON"},
{label: "CHIONANTHUS VIRGINIANA BOIRON"},
{label: "CHIONANTHUS VIRGINIANA LEHNING"},
{label: "CHIROCAÏNE"},
{label: "CHLORALUM BOIRON"},
{label: "CHLORAMINOPHENE"},
{label: "CHLORAMMONIC"},
{label: "CHLORAMPHENICOL"},
{label: "CHLORAPREP"},
{label: "CHLORAPREP COLORE"},
{label: "CHLORHEX-A-MYL"},
{label: "CHLORHEXIDINE ALCOOLIQUE COLOREE GILBERT"},
{label: "CHLORHEXIDINE ALCOOLIQUE GILBERT HEALTHCARE"},
{label: "CHLORHEXIDINE AQUEUSE COLOREE STERILE GILBERT A"},
{label: "CHLORHEXIDINE AQUEUSE STERILE GILBERT"},
{label: "CHLORHEXIDINE AQUEUSE STERILE GILBERT A"},
{label: "CHLORHEXIDINE ARROW"},
{label: "CHLORHEXIDINE ARROW CONSEIL"},
{label: "CHLORHEXIDINE BIOGARAN"},
{label: "CHLORHEXIDINE COLGATE"},
{label: "CHLORHEXIDINE DIGLUCONATE / ALCOOL ISOPROPYLIQUE GILBERT HEALTHCARE"},
{label: "CHLORHEXIDINE ET TETRACAINE PIERRE FABRE"},
{label: "CHLORHEXIDINE FAURE"},
{label: "CHLORHEXIDINE GLUCONATE SIMAPHAC"},
{label: "CHLORHEXIDINE TEVA CONSEIL"},
{label: "CHLORHEXIDINE VIATRIS"},
{label: "CHLORHEXIDINE/BENZALKONIUM/ALCOOL BENZYLIQUE BIOGARAN"},
{label: "CHLORHEXIDINE/BENZALKONIUM/ALCOOL BENZYLIQUE BIOGARAN CONSEIL"},
{label: "CHLORHEXIDINE/BENZALKONIUM/ALCOOL BENZYLIQUE EG LABO CONSEIL"},
{label: "CHLORHEXIDINE/BENZALKONIUM/ALCOOL BENZYLIQUE VIATRIS CONSEIL"},
{label: "CHLORHEXIDINE/CHLOROBUTANOL ARROW"},
{label: "CHLORHEXIDINE/CHLOROBUTANOL BIOGARAN"},
{label: "CHLORHEXIDINE/CHLOROBUTANOL BIOGARAN CONSEIL"},
{label: "CHLORHEXIDINE/CHLOROBUTANOL EG"},
{label: "CHLORHEXIDINE/CHLOROBUTANOL LIBERTY PHARMA"},
{label: "CHLORHEXIDINE/CHLOROBUTANOL PHR LAB"},
{label: "CHLORHEXIDINE/CHLOROBUTANOL RATIOPHARM"},
{label: "CHLORHEXIDINE/CHLOROBUTANOL SANDOZ"},
{label: "CHLORHEXIDINE/CHLOROBUTANOL TEVA"},
{label: "CHLORHEXIDINE/CHLOROBUTANOL TEVA CONSEIL"},
{label: "CHLORHEXIDINE/CHLOROBUTANOL VIATRIS"},
{label: "CHLORHEXIDINE/CHLOROBUTANOL ZENTIVA"},
{label: "CHLORHEXIDINE/LIDOCAINE CHEMINEAU"},
{label: "CHLORHYDRATE D'ALFUZOSINE ROBERT ET CARRIERE"},
{label: "CHLORHYDRATE D'AMILORIDE HYDROCHLOROTHIAZIDE RPG"},
{label: "CHLORHYDRATE D'AMILORIDE RPG"},
{label: "CHLORHYDRATE D'AMILORIDE/HYDROCHLOROTHIAZIDE GENEVAR"},
{label: "CHLORHYDRATE D'ARTICAINE"},
{label: "CHLORHYDRATE D'HYDROQUINIDINE NOVALIS L.P."},
{label: "CHLORHYDRATE D'OXYBUPROCAINE THEA"},
{label: "CHLORHYDRATE DE BENZYDAMINE A.C.R.A.F."},
{label: "CHLORHYDRATE DE BUPIVACAINE DAKOTA PHARM"},
{label: "CHLORHYDRATE DE BUPIVACAINE QUALIMED"},
{label: "CHLORHYDRATE DE DILTIAZEM PANMEDICA"},
{label: "CHLORHYDRATE DE DILTIAZEM RATIOPHARM"},
{label: "CHLORHYDRATE DE DILTIAZEM SUBSTIPHARM"},
{label: "CHLORHYDRATE DE DILTIAZEM SYNTHELABO"},
{label: "CHLORHYDRATE DE DILTIAZEM VIATRIS"},
{label: "CHLORHYDRATE DE DIPIVEPHRINE ALCON"},
{label: "CHLORHYDRATE DE DOXORUBICINE DAKOTA PHARM"},
{label: "CHLORHYDRATE DE FENSPIRIDE ARDIX"},
{label: "CHLORHYDRATE DE FENSPIRIDE BIOPHARMA"},
{label: "CHLORHYDRATE DE FENSPIRIDE EUTHERAPIE"},
{label: "CHLORHYDRATE DE LABETALOL PAUCOURT"},
{label: "CHLORHYDRATE DE LIDOCAINE RACHIANESTHESIE"},
{label: "CHLORHYDRATE DE LIDOCAINE RENAUDIN"},
{label: "CHLORHYDRATE DE LOPERAMIDE CILAG"},
{label: "CHLORHYDRATE DE LOPERAMIDE PIERRE FABRE SANTE"},
{label: "CHLORHYDRATE DE MEBEVERINE MYLAN"},
{label: "CHLORHYDRATE DE METHADONE ASSISTANCE PUBLIQUE-HOPITAUX DE PARIS"},
{label: "CHLORHYDRATE DE METOCLOPRAMIDE RENAUDIN"},
{label: "CHLORHYDRATE DE METOCLOPRAMIDE RICHARD"},
{label: "CHLORHYDRATE DE MINOCYCLINE CYANAMID"},
{label: "CHLORHYDRATE DE MOEXIPRIL SCHWARZ PHARMA"},
{label: "CHLORHYDRATE DE MOXISYLYTE PLANTIER"},
{label: "CHLORHYDRATE DE PROCAINE AGUETANT"},
{label: "CHLORHYDRATE DE PROCAINE BIOSTABILEX"},
{label: "CHLORHYDRATE DE PROCAINE COOPER"},
{label: "CHLORHYDRATE DE PROCAINE LAVOISIER"},
{label: "CHLORHYDRATE DE PYRIDOXINE RENAUDIN"},
{label: "CHLORHYDRATE DE SEVELAMER WAYMADE"},
{label: "CHLORHYDRATE DE THIAMINE RENAUDIN"},
{label: "CHLORHYDRATE DE TRAMADOL SOLYMES"},
{label: "CHLORHYDRATE DARTICAINE SEPTODONT"},
{label: "CHLORHYDRATE DISOPRENALINE XILMAC"},
{label: "CHLORHYDRATE TRAMADOL SOLYMES"},
{label: "CHLORMADINONE MYLAN"},
{label: "CHLORMADINONE MYLAN GENERIQUES"},
{label: "CHLORMADINONE QUALIMED"},
{label: "CHLORMADINONE SANDOZ"},
{label: "CHLORMADINONE TEVA"},
{label: "CHLORMADINONE THERAMEX"},
{label: "CHLORMADINONE VIATRIS"},
{label: "CHLORMADINONE/ETHINYLESTRADIOL ACTAVIS"},
{label: "CHLORMADINONE/ETHINYLESTRADIOL PIERRE FABRE MEDICAMENT"},
{label: "CHLOROFORMIUM BOIRON"},
{label: "CHLOROMAGNESION"},
{label: "CHLOROPHYLLINE CUIVRIQUE HYDROSOLUBLE SMITHKLINE BEECHAM PHARMACIE SANTE"},
{label: "CHLORUMAGENE"},
{label: "CHLORURE D'INDIUM (111"},
{label: "CHLORURE D'INDIUM [111"},
{label: "CHLORURE DE CALCIUM"},
{label: "CHLORURE DE CALCIUM AGUETTANT"},
{label: "CHLORURE DE MAGNESIUM"},
{label: "CHLORURE DE MAGNESIUM COOPER"},
{label: "CHLORURE DE METHACHOLINE PANPHARMA"},
{label: "CHLORURE DE METHYLTHIONINIUM PROVEBLUE"},
{label: "CHLORURE DE POTASSIUM"},
{label: "CHLORURE DE POTASSIUM B. BRAUN"},
{label: "CHLORURE DE POTASSIUM BIOSEDRA"},
{label: "CHLORURE DE POTASSIUM COOPER"},
{label: "CHLORURE DE POTASSIUM FRESENIUS"},
{label: "CHLORURE DE POTASSIUM KABI"},
{label: "CHLORURE DE POTASSIUM LAVOISIER"},
{label: "CHLORURE DE POTASSIUM PROAMP"},
{label: "CHLORURE DE POTASSIUM RENAUDIN"},
{label: "CHLORURE DE SODIUM"},
{label: "CHLORURE DE SODIUM B.BRAUN"},
{label: "CHLORURE DE SODIUM BAXTER"},
{label: "CHLORURE DE SODIUM BRUNEAU"},
{label: "CHLORURE DE SODIUM COOPER"},
{label: "CHLORURE DE SODIUM FRESENIUS"},
{label: "CHLORURE DE SODIUM FRESENIUS MEDICAL CARE"},
{label: "CHLORURE DE SODIUM GRIFOLS"},
{label: "CHLORURE DE SODIUM GUERBET"},
{label: "CHLORURE DE SODIUM HYPERTONIQUE"},
{label: "CHLORURE DE SODIUM HYPERTONIQUE AGUETTANT"},
{label: "CHLORURE DE SODIUM HYPERTONIQUE FRESENIUS"},
{label: "CHLORURE DE SODIUM PERFUFLAC"},
{label: "CHLORURE DE SODIUM PERFUFLEX"},
{label: "CHLORURE DE SODIUM PHARMACIE CENTRALE DES ARMEES"},
{label: "CHLORURE DE SODIUM PHYSIOFLEX"},
{label: "CHLORURE DE SODIUM PROAMP"},
{label: "CHLORURE DE SODIUM SMART DOSE"},
{label: "CHLORURE DE THALLIUM"},
{label: "CHLORURE DE THALLIUM [201"},
{label: "CHLORURE DE THALLIUM [TI 201] BRISTOL MYERS SQUIBB"},
{label: "CHLORURE DE TROSPIUM MADAUS"},
{label: "CHLORYDRATE D'HEPTAMINOL LIBERTY PHARMA"},
{label: "CHOLARTYL"},
{label: "CHOLECALCIFEROL ALEPT"},
{label: "CHOLECALCIFEROL ARROW"},
{label: "CHOLECALCIFEROL BIOGARAN"},
{label: "CHOLECALCIFEROL CRINEX"},
{label: "CHOLECALCIFEROL CRISTERS"},
{label: "CHOLECALCIFEROL EG"},
{label: "CHOLECALCIFEROL LABORATOIRE X.O"},
{label: "CHOLECALCIFEROL SANDOZ"},
{label: "CHOLECALCIFEROL SUBSTIPHARM"},
{label: "CHOLECALCIFEROL TEVA"},
{label: "CHOLECALCIFEROL UVE"},
{label: "CHOLECALCIFEROL VIATRIS"},
{label: "CHOLECALCIFEROL ZENTIVA"},
{label: "CHOLEDIAM"},
{label: "CHOLEODORON"},
{label: "CHOLEOPEPTINE CHOLINE"},
{label: "CHOLESTAGEL"},
{label: "CHOLESTERINUM BOIRON"},
{label: "CHOLESTERINUM LEHNING"},
{label: "CHOLIB"},
{label: "CHOLURSO"},
{label: "CHONDROCELECT"},
{label: "CHONDROITINE SULFATE PIERRE FABRE"},
{label: "CHONDROSULF"},
{label: "CHONDROSULF SANS SUCRE"},
{label: "CHONDROÏTINE SULFATE DEXTREG"},
{label: "CHONDROÏTINE SULFATE IBSA"},
{label: "CHONTRAL"},
{label: "CHOPHYTOL"},
{label: "CHROMAPLAIE"},
{label: "CHROMARGON"},
{label: "CHROMATE"},
{label: "CHROMATE DE SODIUM"},
{label: "CHROMATE [51"},
{label: "CHRONADALATE L.P."},
{label: "CHRONASPIRINE"},
{label: "CHRONEXAN"},
{label: "CHRONO-INDOCID"},
{label: "CHRONOLAX"},
{label: "CHRONOLAX BEBE"},
{label: "CHRONOTROPHIR"},
{label: "CHRONOVERA"},
{label: "CHRYSOLITHE WELEDA"},
{label: "CHYMODIACTINE"},
{label: "CIALIS"},
{label: "CIAMBRA"},
{label: "CIAZIL"},
{label: "CIBACALCINE"},
{label: "CIBACENE"},
{label: "CIBADREX"},
{label: "CIBENZOLINE SEARLE"},
{label: "CIBINQO"},
{label: "CIBLOR"},
{label: "CICADERMA"},
{label: "CICATRYL"},
{label: "CICHORIUM INTYBUS"},
{label: "CICLETANINE BIOGARAN"},
{label: "CICLETANINE TEVA"},
{label: "CICLETANINE VIATRIS"},
{label: "CICLOPIROX BIOGARAN"},
{label: "CICLOPIROX MYLAN GENERIQUES"},
{label: "CICLOPIROX OLAMINE ARROW"},
{label: "CICLOPIROX OLAMINE ARROW 1%"},
{label: "CICLOPIROX OLAMINE BIOGARAN"},
{label: "CICLOPIROX OLAMINE GERDA"},
{label: "CICLOPIROX OLAMINE PIERRE FABRE"},
{label: "CICLOPIROX OLAMINE POLICHEM"},
{label: "CICLOPIROX OLAMINE SANDOZ"},
{label: "CICLOPIROX OLAMINE TEVA"},
{label: "CICLOPIROX OLAMINE VIATRIS"},
{label: "CICLOPIROX SUBSTIPHARM"},
{label: "CICLOPIROX TEVA"},
{label: "CICLOPIROX VENIPHARM"},
{label: "CICLOPIROX VIATRIS"},
{label: "CICLOTAB"},
{label: "CICUTA VIROSA BOIRON"},
{label: "CICUTA VIROSA LEHNING"},
{label: "CIDAPEX"},
{label: "CIDERMEX"},
{label: "CIDOFOVIR TILLOMED"},
{label: "CIELLA"},
{label: "CIFLOX"},
{label: "CILAZAPRIL ROCHE"},
{label: "CILAZAPRIL TEVA"},
{label: "CILEDRO"},
{label: "CILEST"},
{label: "CILFRA"},
{label: "CILOXADEX"},
{label: "CILOXAN"},
{label: "CIMEHEXAL"},
{label: "CIMETIDINE APOTEX"},
{label: "CIMETIDINE APOTEX FRANCE"},
{label: "CIMETIDINE ARROW"},
{label: "CIMETIDINE ENTERIS"},
{label: "CIMETIDINE EUROGALENA"},
{label: "CIMETIDINE G GAM"},
{label: "CIMETIDINE GIFRER"},
{label: "CIMETIDINE GNR"},
{label: "CIMETIDINE GSK"},
{label: "CIMETIDINE MERCK GENERICS FRANCE HOLDING"},
{label: "CIMETIDINE MONOT"},
{label: "CIMETIDINE MURAT"},
{label: "CIMETIDINE MYLAN"},
{label: "CIMETIDINE MYLAN PHARMA"},
{label: "CIMETIDINE RATIOPHARM"},
{label: "CIMETIDINE RPG"},
{label: "CIMETIDINE TEVA"},
{label: "CIMETIDINE TEVA PHARMA"},
{label: "CIMIPAX"},
{label: "CIMZIA"},
{label: "CINA BOIRON"},
{label: "CINA LEHNING"},
{label: "CINA WELEDA"},
{label: "CINABRE WELEDA"},
{label: "CINACALCET ACCORD"},
{label: "CINACALCET ACCORDPHARMA"},
{label: "CINACALCET ARROW"},
{label: "CINACALCET BGR"},
{label: "CINACALCET BIOGARAN"},
{label: "CINACALCET CRISTERS"},
{label: "CINACALCET EG"},
{label: "CINACALCET EVOLUGEN"},
{label: "CINACALCET GNR"},
{label: "CINACALCET MEDICE"},
{label: "CINACALCET MYLAN"},
{label: "CINACALCET QILU"},
{label: "CINACALCET REDDY PHARMA"},
{label: "CINACALCET SANDOZ"},
{label: "CINACALCET TEVA"},
{label: "CINACALCET ZENTIVA"},
{label: "CINERARIA MARITIMA BOIRON"},
{label: "CINERARIA MARITIMA LEHNING"},
{label: "CINERARIA MARITIMA WELEDA"},
{label: "CINNABARIS BOIRON"},
{label: "CINNABARIS WELEDA"},
{label: "CINNAMOMUM ZEYLANICUM BOIRON"},
{label: "CINQAERO"},
{label: "CINRYZE"},
{label: "CIPOZEL"},
{label: "CIPRALAN"},
{label: "CIPROBERT"},
{label: "CIPROFIBRATE AMDIPHARM"},
{label: "CIPROFIBRATE ARROW"},
{label: "CIPROFIBRATE BIOGARAN"},
{label: "CIPROFIBRATE MYLAN"},
{label: "CIPROFIBRATE NOR"},
{label: "CIPROFIBRATE QUALIMED"},
{label: "CIPROFIBRATE RATIOPHARM"},
{label: "CIPROFIBRATE REF"},
{label: "CIPROFIBRATE RPG"},
{label: "CIPROFIBRATE SANDOZ"},
{label: "CIPROFIBRATE TEVA"},
{label: "CIPROFIBRATE ZENTIVA"},
{label: "CIPROFLOXACINE ACCORD"},
{label: "CIPROFLOXACINE AGUETTANT"},
{label: "CIPROFLOXACINE ALCON"},
{label: "CIPROFLOXACINE ALMUS"},
{label: "CIPROFLOXACINE ALTER"},
{label: "CIPROFLOXACINE ARROW"},
{label: "CIPROFLOXACINE ARROW GENERIQUES"},
{label: "CIPROFLOXACINE BIOGALENIQUE"},
{label: "CIPROFLOXACINE BIOGARAN"},
{label: "CIPROFLOXACINE CRISTERS"},
{label: "CIPROFLOXACINE CRISTERS PHARMA"},
{label: "CIPROFLOXACINE CTRS"},
{label: "CIPROFLOXACINE DAKOTA PHARM"},
{label: "CIPROFLOXACINE EG"},
{label: "CIPROFLOXACINE EVOLUGEN"},
{label: "CIPROFLOXACINE G GAM"},
{label: "CIPROFLOXACINE HOSPIRA"},
{label: "CIPROFLOXACINE INTSEL CHIMOS"},
{label: "CIPROFLOXACINE IVAX"},
{label: "CIPROFLOXACINE KABI"},
{label: "CIPROFLOXACINE MEDICAL TECHNOLOGIES"},
{label: "CIPROFLOXACINE MYLAN"},
{label: "CIPROFLOXACINE PANMEDICA"},
{label: "CIPROFLOXACINE PANPHARMA"},
{label: "CIPROFLOXACINE PFIZER"},
{label: "CIPROFLOXACINE QUALIMED"},
{label: "CIPROFLOXACINE QUIVER"},
{label: "CIPROFLOXACINE RANBAXY"},
{label: "CIPROFLOXACINE RATIOPHARM"},
{label: "CIPROFLOXACINE SANDOZ"},
{label: "CIPROFLOXACINE SERVIPHARM"},
{label: "CIPROFLOXACINE TEVA"},
{label: "CIPROFLOXACINE VIATRIS"},
{label: "CIPROFLOXACINE WINTHROP"},
{label: "CIPROFLOXACINE ZENTIVA"},
{label: "CIPROFLOXACINE ZYDUS"},
{label: "CIPROXINA"},
{label: "CIRCADIN"},
{label: "CIRCLET"},
{label: "CIRCULARHOIDE"},
{label: "CIRCULARINE"},
{label: "CIRCULATOR"},
{label: "CIRCULONE"},
{label: "CIRKALM"},
{label: "CIRKAN"},
{label: "CIRKAN A LA PREDNACINOLONE"},
{label: "CIRKULIN"},
{label: "CISATRACURIUM ACCORD"},
{label: "CISATRACURIUM ACCORDPHARMA"},
{label: "CISATRACURIUM ACTAVIS"},
{label: "CISATRACURIUM HOSPIRA"},
{label: "CISATRACURIUM KABI"},
{label: "CISATRACURIUM KALCEKS"},
{label: "CISATRACURIUM MYLAN"},
{label: "CISATRACURIUM PFIZER"},
{label: "CISATRACURIUM SANDOZ"},
{label: "CISATRACURIUM TEVA"},
{label: "CISNAF"},
{label: "CISPLATINE ACCORD"},
{label: "CISPLATINE ASTA MEDICA"},
{label: "CISPLATINE CIPLA"},
{label: "CISPLATINE DAKOTA PHARM"},
{label: "CISPLATINE DELBERT"},
{label: "CISPLATINE EBEWE"},
{label: "CISPLATINE HIKMA"},
{label: "CISPLATINE KABI"},
{label: "CISPLATINE MERCK"},
{label: "CISPLATINE MYLAN"},
{label: "CISPLATINE MYLAN PHARMA"},
{label: "CISPLATINE PFIZER"},
{label: "CISPLATINE SANDOZ"},
{label: "CISPLATINE TEVA"},
{label: "CISPLATINE VIATRIS"},
{label: "CISPLATYL"},
{label: "CISTUS CANADENSIS BOIRON"},
{label: "CITALOPRAM ACTAVIS"},
{label: "CITALOPRAM ALMUS"},
{label: "CITALOPRAM ALTER"},
{label: "CITALOPRAM ARROW"},
{label: "CITALOPRAM ARROW LAB"},
{label: "CITALOPRAM BIOGARAN"},
{label: "CITALOPRAM BLUEFISH"},
{label: "CITALOPRAM CRISTERS"},
{label: "CITALOPRAM DELBERT"},
{label: "CITALOPRAM EG"},
{label: "CITALOPRAM EVOLUGEN"},
{label: "CITALOPRAM GNR"},
{label: "CITALOPRAM IBD3 PHARMA CONSULTING"},
{label: "CITALOPRAM INTERNATIONAL DRUG DEVELOPMENT"},
{label: "CITALOPRAM ISOMED"},
{label: "CITALOPRAM LUNDBECK"},
{label: "CITALOPRAM QUALIMED"},
{label: "CITALOPRAM RANBAXY"},
{label: "CITALOPRAM RPG"},
{label: "CITALOPRAM SANDOZ"},
{label: "CITALOPRAM SUN"},
{label: "CITALOPRAM TEVA"},
{label: "CITALOPRAM TEVA SANTE"},
{label: "CITALOPRAM VIATRIS"},
{label: "CITALOPRAM ZENTIVA"},
{label: "CITALOPRAM ZYDUS"},
{label: "CITANEST"},
{label: "CITAP"},
{label: "CITICOLINE PANPHARMA"},
{label: "CITICOLINE PHARMANOVA"},
{label: "CITRAFLEET"},
{label: "CITRARGININE"},
{label: "CITRATE D'ERBIUM [169"},
{label: "CITRATE D'YTTRIUM [90 Y] CIS"},
{label: "CITRATE DE BETAINE ARROW"},
{label: "CITRATE DE BETAINE BIOGARAN"},
{label: "CITRATE DE BETAINE BIOGARAN CONSEIL"},
{label: "CITRATE DE BETAINE CITRON UPSA"},
{label: "CITRATE DE BETAINE CRISTERS"},
{label: "CITRATE DE BETAINE EG"},
{label: "CITRATE DE BETAINE GIFRER"},
{label: "CITRATE DE BETAINE MADAUS"},
{label: "CITRATE DE BETAINE MYLAN"},
{label: "CITRATE DE BETAINE RATIOPHARM CONSEIL"},
{label: "CITRATE DE BETAINE SANDOZ"},
{label: "CITRATE DE BETAINE SANDOZ CONSEIL"},
{label: "CITRATE DE BETAINE SET"},
{label: "CITRATE DE BETAINE TEVA CONSEIL"},
{label: "CITRATE DE BETAINE UPSA"},
{label: "CITRATE DE CAFEINE COOPER"},
{label: "CITRATE DE GALLIUM [67"},
{label: "CITRATE DE GALLIUM-(67"},
{label: "CITRATE DIACIDE DE SODIUM GMD"},
{label: "CITROCHOLINE"},
{label: "CITRUS DECUMANA BOIRON"},
{label: "CITRUS LIMONUM BOIRON"},
{label: "CITRUS VULGARIS BOIRON"},
{label: "CIVARON"},
{label: "CIVIGEL"},
{label: "CLADRIBINE JANSSEN-CILAG"},
{label: "CLAFORAN"},
{label: "CLAIRODERMYL"},
{label: "CLAIRYG"},
{label: "CLAMOXYL"},
{label: "CLARADOL"},
{label: "CLARADOL CAFEINE"},
{label: "CLARADOL CODEINE"},
{label: "CLARADOL NOURRISSONS"},
{label: "CLARAFEN"},
{label: "CLARAGINE"},
{label: "CLARAMID"},
{label: "CLARAMID ENFANTS"},
{label: "CLAREAL"},
{label: "CLARELUX"},
{label: "CLARINASE LP"},
{label: "CLARINASE REPETABS"},
{label: "CLARISCAN"},
{label: "CLARITHROMYCINE ABBOTT"},
{label: "CLARITHROMYCINE ACCORD"},
{label: "CLARITHROMYCINE ALMUS"},
{label: "CLARITHROMYCINE ARROW"},
{label: "CLARITHROMYCINE AUVEX"},
{label: "CLARITHROMYCINE BIOGARAN"},
{label: "CLARITHROMYCINE BIOSTABILEX"},
{label: "CLARITHROMYCINE BIPHAR"},
{label: "CLARITHROMYCINE EG"},
{label: "CLARITHROMYCINE EVOLUGEN"},
{label: "CLARITHROMYCINE GNR"},
{label: "CLARITHROMYCINE HCS"},
{label: "CLARITHROMYCINE HEC"},
{label: "CLARITHROMYCINE HEC PHARM"},
{label: "CLARITHROMYCINE HEXAL"},
{label: "CLARITHROMYCINE MYLAN"},
{label: "CLARITHROMYCINE MYLAN PHARMA"},
{label: "CLARITHROMYCINE PFIZER"},
{label: "CLARITHROMYCINE QUALIMED"},
{label: "CLARITHROMYCINE RATIOPHARM"},
{label: "CLARITHROMYCINE RPG"},
{label: "CLARITHROMYCINE SANDOZ"},
{label: "CLARITHROMYCINE SUN"},
{label: "CLARITHROMYCINE TEVA"},
{label: "CLARITHROMYCINE TEVA SANTE"},
{label: "CLARITHROMYCINE VENIPHARM"},
{label: "CLARITHROMYCINE VIATRIS"},
{label: "CLARITHROMYCINE ZYDUS"},
{label: "CLARITYNDUO"},
{label: "CLARITYNE"},
{label: "CLARITYNSOLO"},
{label: "CLARIX EXPECTORANT CARBOCISTEINE"},
{label: "CLARIX OROSOL"},
{label: "CLARIX TOUX SECHE CODETHYLINE"},
{label: "CLARIX TOUX SECHE DEXTROMETHORPHANE"},
{label: "CLARIX TOUX SECHE DEXTROMETHORPHANE MEPYRAMINE ADULTES"},
{label: "CLARIX TOUX SECHE DEXTROMETHORPHANE MEPYRAMINE GUAIFENESINE ENFANTS"},
{label: "CLARIX TOUX SECHE PHOLCODINE ERYSIMUM ADULTES"},
{label: "CLARIX TOUX SECHE PHOLCODINE ERYSIMUM ADULTES SANS SUCRE"},
{label: "CLAROPTINE"},
{label: "CLASTOBAN"},
{label: "CLAVENTIN"},
{label: "CLAVENTIN ENFANTS NOURRISSONS ET NOUVEAUX NES"},
{label: "CLAVENTIN PREMATURES"},
{label: "CLEMATIS ERECTA BOIRON"},
{label: "CLEMATIS ERECTA LEHNING"},
{label: "CLEMATIS ERECTA WELEDA"},
{label: "CLEMATIS VITALBA BOIRON"},
{label: "CLEMATIS VITALBA LEHNING"},
{label: "CLEREGIL ADULTES"},
{label: "CLEREGIL ENFANTS"},
{label: "CLERIDIUM"},
{label: "CLEVIPREX"},
{label: "CLIAVIST"},
{label: "CLIDAR"},
{label: "CLIFAN"},
{label: "CLIMARA"},
{label: "CLIMASTON"},
{label: "CLIMAXOL"},
{label: "CLIMEDETTE"},
{label: "CLIMENE"},
{label: "CLIMESTA"},
{label: "CLIMODIENE"},
{label: "CLINALIANE"},
{label: "CLINDAFLUID 1%"},
{label: "CLINDAMYCINE / PEROXYDE DE BENZOYLE JGL"},
{label: "CLINDAMYCINE AGUETTANT"},
{label: "CLINDAMYCINE KABI"},
{label: "CLINDAMYCINE MIP"},
{label: "CLINDAMYCINE SANDOZ"},
{label: "CLINDAMYCINE STRAGEN FRANCE"},
{label: "CLINDAMYCINE VIATRIS"},
{label: "CLINDAMYCINE VILLERTON"},
{label: "CLINIMIX N12 G20"},
{label: "CLINIMIX N12 G20 E"},
{label: "CLINIMIX N14 G30"},
{label: "CLINIMIX N14 G30 E"},
{label: "CLINIMIX N17 G35"},
{label: "CLINIMIX N17 G35 E"},
{label: "CLINIMIX N9 G15 E"},
{label: "CLINOLEIC"},
{label: "CLINOMEL N4 -"},
{label: "CLINOMEL N5 -"},
{label: "CLINOMEL N6"},
{label: "CLINOMEL N7-"},
{label: "CLIPTOL"},
{label: "CLIVARINE"},
{label: "CLOBETASOL SUBSTIPHARM"},
{label: "CLOBEX"},
{label: "CLODRONATE DE SODIUM G GAM"},
{label: "CLODRONATE DE SODIUM SANDOZ"},
{label: "CLOFARABINE ACCORD"},
{label: "CLOFARABINE ARROW"},
{label: "CLOFARABINE KOANAA"},
{label: "CLOFARABINE TILLOMED"},
{label: "CLOFARABINE VIATRIS"},
{label: "CLOFIBRAL"},
{label: "CLOFIBRIDE BOUCHARA"},
{label: "CLOMID"},
{label: "CLOMIPRAMINE (CHLORHYDRATE) MYLAN"},
{label: "CLOMIPRAMINE DEFIANTE"},
{label: "CLOMIPRAMINE QUALIMED"},
{label: "CLOMIPRAMINE RPG"},
{label: "CLOMIPRAMINE SANDOZ"},
{label: "CLOMIPRAMINE TEVA"},
{label: "CLOMIPRAMINE VIATRIS"},
{label: "CLOMIPRAMINE ZYDUS"},
{label: "CLONAROL"},
{label: "CLONAZONE"},
{label: "CLONIDINE CHLORHYDRATE RPG"},
{label: "CLONIDINE RPG"},
{label: "CLONIDRON"},
{label: "CLOPIDOGREL ACCORD"},
{label: "CLOPIDOGREL ACTAVIS"},
{label: "CLOPIDOGREL ALCHEMIA"},
{label: "CLOPIDOGREL ALET"},
{label: "CLOPIDOGREL ALMUS"},
{label: "CLOPIDOGREL ALMUS PHARMA"},
{label: "CLOPIDOGREL ALTER"},
{label: "CLOPIDOGREL ARROW"},
{label: "CLOPIDOGREL ARROW GENERIQUES"},
{label: "CLOPIDOGREL ARROW LAB"},
{label: "CLOPIDOGREL BGR"},
{label: "CLOPIDOGREL BIOGARAN"},
{label: "CLOPIDOGREL BIOSTABILEX"},
{label: "CLOPIDOGREL BIPHAR"},
{label: "CLOPIDOGREL BOUCHARA-RECORDATI"},
{label: "CLOPIDOGREL CADUCEUS PHARMA"},
{label: "CLOPIDOGREL CRISTERS"},
{label: "CLOPIDOGREL EG"},
{label: "CLOPIDOGREL EG LABO"},
{label: "CLOPIDOGREL ESP PHARMA"},
{label: "CLOPIDOGREL EVOLUGEN"},
{label: "CLOPIDOGREL FARMA APS"},
{label: "CLOPIDOGREL HEC PHARM"},
{label: "CLOPIDOGREL ISOMED"},
{label: "CLOPIDOGREL IWA"},
{label: "CLOPIDOGREL KRKA"},
{label: "CLOPIDOGREL KRKA D.D."},
{label: "CLOPIDOGREL MIDAS"},
{label: "CLOPIDOGREL MYLAN"},
{label: "CLOPIDOGREL MYLAN PHARMA"},
{label: "CLOPIDOGREL NUCLEUS"},
{label: "CLOPIDOGREL OPENING PHARMA"},
{label: "CLOPIDOGREL PFIZER"},
{label: "CLOPIDOGREL PHARMATHEN"},
{label: "CLOPIDOGREL PHR LAB"},
{label: "CLOPIDOGREL QUALIMED"},
{label: "CLOPIDOGREL RATIOPHARM"},
{label: "CLOPIDOGREL REF"},
{label: "CLOPIDOGREL RPG"},
{label: "CLOPIDOGREL SANDOZ"},
{label: "CLOPIDOGREL SPECIFAR"},
{label: "CLOPIDOGREL SUN"},
{label: "CLOPIDOGREL TAW PHARMA"},
{label: "CLOPIDOGREL TEVA"},
{label: "CLOPIDOGREL TEVA PHARMA"},
{label: "CLOPIDOGREL TEVA SANTE"},
{label: "CLOPIDOGREL USV EUROPE"},
{label: "CLOPIDOGREL VIATRIS"},
{label: "CLOPIDOGREL WOCKHARDT"},
{label: "CLOPIDOGREL WYETH"},
{label: "CLOPIDOGREL ZENTIVA"},
{label: "CLOPIDOGREL ZF"},
{label: "CLOPIDOGREL ZYDUS"},
{label: "CLOPIDOGREL/ACIDE ACETYLSALICYLIQUE BILLEV"},
{label: "CLOPIDOGREL/ACIDE ACETYLSALICYLIQUE MYLAN"},
{label: "CLOPIDOHELM"},
{label: "CLOPIMED"},
{label: "CLOPIXOL"},
{label: "CLOPIXOL A ACTION SEMI-PROLONGEE"},
{label: "CLOPIXOL ACTION PROLONGEE"},
{label: "CLOPVAL"},
{label: "CLORANAUTINE"},
{label: "CLORAZEPATE ZENTIVA"},
{label: "CLOROTEKAL"},
{label: "CLOSALIS"},
{label: "CLOTRIMAZOLE EG LABO CONSEIL 1%"},
{label: "CLOTTAFACT"},
{label: "CLOXACILLINE ARROW"},
{label: "CLOXACILLINE BIOGARAN"},
{label: "CLOXACILLINE EG"},
{label: "CLOXACILLINE PANPHARMA"},
{label: "CLOXACILLINE SANDOZ"},
{label: "CLOXACILLINE STRAGEN"},
{label: "CLOXACILLINE SUBSTIPHARM"},
{label: "CLOXACILLINE VIATRIS"},
{label: "CLOXACILLINE ZENTIVA"},
{label: "CLOXYPEN"},
{label: "CLOZAPINE ACCORD"},
{label: "CLOZAPINE ARISTO"},
{label: "CLOZAPINE ARROW"},
{label: "CLOZAPINE BIOGARAN"},
{label: "CLOZAPINE CHIESI"},
{label: "CLOZAPINE EG"},
{label: "CLOZAPINE EG LABO"},
{label: "CLOZAPINE TEVA"},
{label: "CLOZAPINE VIATRIS"},
{label: "CLOZAPINE ZENTIVA"},
{label: "CLOZIVIC"},
{label: "CLUPEIDOL"},
{label: "CNICUS BENEDICTUS BOIRON"},
{label: "CO-RENITEC"},
{label: "COABESART"},
{label: "COAGADEX"},
{label: "COALTAR SAPONINE LE BEUF"},
{label: "COANHYVA"},
{label: "COAPROVEL"},
{label: "COBALT OLIGOSOL"},
{label: "COBALTUM METALLICUM BOIRON"},
{label: "COBALTUM METALLICUM WELEDA"},
{label: "COBANZYME"},
{label: "COCA BOIRON"},
{label: "COCA LEHNING"},
{label: "COCCULINE"},
{label: "COCCULUS INDICUS BOIRON"},
{label: "COCCULUS INDICUS LEHNING"},
{label: "COCCULUS INDICUS WELEDA"},
{label: "COCCUS CACTI BOIRON"},
{label: "COCCUS CACTI LEHNING"},
{label: "COCCUS CACTI WELEDA"},
{label: "COCHLEARIA ARMORACIA BOIRON"},
{label: "COCHLEARIA ARMORACIA LEHNING"},
{label: "COCHLEARIA OFFICINALIS BOIRON"},
{label: "COCHLEARIA OFFICINALIS WELEDA"},
{label: "COCYNTAL"},
{label: "CODATUX"},
{label: "CODATUX ADULTES"},
{label: "CODATUX ENFANTS"},
{label: "CODATUX NOURRISSONS"},
{label: "CODEDRILL SANS SUCRE"},
{label: "CODEINE - ERYSIMUM MAYOLY-SPINDLER"},
{label: "CODEINE BMS ENFANTS"},
{label: "CODENFAN"},
{label: "CODETHYLINE HOUDE"},
{label: "CODETRICINE"},
{label: "CODETRICINE SANS SUCRE"},
{label: "CODETRICINE TETRACAINE A L'ACEROLA"},
{label: "CODETRICINE VITAMINE C"},
{label: "CODIOPIN"},
{label: "CODOLIPRANE"},
{label: "CODOLIPRANE ADULTES"},
{label: "CODORZOCOLL"},
{label: "CODOTUSSYL"},
{label: "CODOTUSSYL ADULTES"},
{label: "CODOTUSSYL ENFANTS"},
{label: "CODOTUSSYL EXPECTORANT"},
{label: "CODOTUSSYL EXPECTORANT ACETYLCYSTEINE"},
{label: "CODOTUSSYL EXPECTORANT ADULTES"},
{label: "CODOTUSSYL MAUX DE GORGE"},
{label: "CODOTUSSYL MAUX DE GORGE SANS SUCRE"},
{label: "CODOTUSSYL TOUX SECHE ADULTES"},
{label: "CODOTUSSYL TOUX SECHE ENFANTS"},
{label: "CODOTUSSYL TOUX SECHE PENTOXYVERINE"},
{label: "COFACT"},
{label: "COFFEA CRUDA BOIRON"},
{label: "COFFEA CRUDA LEHNING"},
{label: "COFFEA CRUDA WELEDA"},
{label: "COFFEA TOSTA BOIRON"},
{label: "COFFEA TOSTA WELEDA"},
{label: "COGITUM"},
{label: "COGNEX"},
{label: "COHARTAN"},
{label: "COHESAN"},
{label: "COKENZEN"},
{label: "COLARINE"},
{label: "COLATANOCOLL"},
{label: "COLCHICINE OPOCALCIUM"},
{label: "COLCHICUM AUTUMNALE"},
{label: "COLCHICUM AUTUMNALE BOIRON"},
{label: "COLCHICUM AUTUMNALE LEHNING"},
{label: "COLCHIMAX"},
{label: "COLD CREAM NATUREL ROCHE POSAY"},
{label: "COLD CREAM SALICYLE ROCHE POSAY"},
{label: "COLESTYRAMINE BIOGALENIQUE"},
{label: "COLESTYRAMINE RPG"},
{label: "COLESTYRAMINE SANS SUCRE RPG"},
{label: "COLIBACILLINUM BOIRON"},
{label: "COLIBACILLINUM LEHNING"},
{label: "COLICORT"},
{label: "COLIMYCINE"},
{label: "COLIMYCINE 500.000 U.I."},
{label: "COLIOMEGA"},
{label: "COLISTIMETHATE SODIQUE ALTAN"},
{label: "COLISTIMETHATE SODIQUE AMDIPHARM"},
{label: "COLISTIMETHATE SODIQUE AMDIPHARM 1.000.000 U.I."},
{label: "COLISTIMETHATE SODIQUE MEDAC"},
{label: "COLISTIMETHATE SODIQUE PANPHARMA 1.000.000 U.I."},
{label: "COLISTIMETHATE SODIQUE PANPHARMA 2.000.000 U.I."},
{label: "COLISTIMETHATE SODIQUE PANPHARMA 3.000.000 U.I."},
{label: "COLISTIMETHATE SODIQUE XELLIA"},
{label: "COLISTINE SARBACH 1.000.000 U.I."},
{label: "COLITISANE"},
{label: "COLITOFALK"},
{label: "COLLAFILM"},
{label: "COLLAZOLE"},
{label: "COLLINSONIA CANADENSIS BOIRON"},
{label: "COLLINSONIA CANADENSIS LEHNING"},
{label: "COLLINSONIA CANADENSIS WELEDA"},
{label: "COLLU HEXTRIL"},
{label: "COLLU SEC"},
{label: "COLLUBLEU"},
{label: "COLLUDOL"},
{label: "COLLUNIUM"},
{label: "COLLUNOSOL"},
{label: "COLLUNOVAR"},
{label: "COLLUNOVAR SEC"},
{label: "COLLUPRESSINE"},
{label: "COLLUSPRAY"},
{label: "COLLUSTAN"},
{label: "COLLUVOXYL"},
{label: "COLLYRE BLEU FORT LAITER"},
{label: "COLLYRE BLEU LAITER"},
{label: "COLLYRE LUMIERE"},
{label: "COLLYREX"},
{label: "COLOBREATHE"},
{label: "COLOBUTINE"},
{label: "COLOCARB"},
{label: "COLOCYNTHIS BOIRON"},
{label: "COLOCYNTHIS LEHNING"},
{label: "COLOCYNTHIS WELEDA"},
{label: "COLOFOAM"},
{label: "COLOKIT"},
{label: "COLOMBO BOIRON"},
{label: "COLOMENTHE"},
{label: "COLON BOIRON"},
{label: "COLON WELEDA"},
{label: "COLOPEG"},
{label: "COLOPEG AROMATISE"},
{label: "COLOPEG HYPOSODE"},
{label: "COLOPRIV"},
{label: "COLOPTEN"},
{label: "COLPERMIN"},
{label: "COLPOSEPTINE"},
{label: "COLPOTROPHINE"},
{label: "COLPRONE"},
{label: "COLTOWAN"},
{label: "COLTRAMYL"},
{label: "COLVANO"},
{label: "COMBANTRIN"},
{label: "COMBIGAN"},
{label: "COMBIVENT"},
{label: "COMBIVIR"},
{label: "COMBODART"},
{label: "COMETRIQ"},
{label: "COMIRNATY"},
{label: "COMIRNATY ORIGINAL/OMICRON BA.1 (15/15"},
{label: "COMIRNATY ORIGINAL/OMICRON BA.4-5 (15/15"},
{label: "COMIRNATY ORIGINAL/OMICRON BA.4-5 (5/5"},
{label: "COMPEEDMED"},
{label: "COMPENSAL"},
{label: "COMPETACT"},
{label: "COMPLEVEN"},
{label: "COMPRALFENE"},
{label: "COMPRALGYL"},
{label: "COMPRALSOL"},
{label: "COMTAN"},
{label: "CONBRIZA"},
{label: "CONCERTA LP"},
{label: "CONCHIOLINUM BOIRON"},
{label: "CONDURANGO BOIRON"},
{label: "CONDYLINE"},
{label: "CONEBILOX"},
{label: "CONFEROPORT"},
{label: "CONFIDEX"},
{label: "CONFLICTAN"},
{label: "CONFOCALM"},
{label: "CONFODIGEST"},
{label: "CONFODIUR"},
{label: "CONFOLAX"},
{label: "CONFOVEIN"},
{label: "CONGLOMERAT BOIRON"},
{label: "CONIUM MACULATUM BOIRON"},
{label: "CONIUM MACULATUM LEHNING"},
{label: "CONIUM MACULATUM WELEDA"},
{label: "CONSTELLA"},
{label: "CONSTRILIA"},
{label: "CONTALAX"},
{label: "CONTRACIDE"},
{label: "CONTRACNE"},
{label: "CONTRAMAL"},
{label: "CONTRAMAL LP"},
{label: "CONTRATHION"},
{label: "CONTRE-COUPS DE L'ABBE PERDRIGEON"},
{label: "CONVALLARIA MAJALIS"},
{label: "CONVALLARIA MAJALIS BOIRON"},
{label: "CONVALLARIA MAJALIS LEHNING"},
{label: "CONVAMEO"},
{label: "CONVERSIREX"},
{label: "CONVOLVULUS ARVENSIS BOIRON"},
{label: "CONVULINE"},
{label: "CONYDIX"},
{label: "COOLMES"},
{label: "COOLMETEC"},
{label: "COPAXONE"},
{label: "COPAXONE PEN"},
{label: "COPEGUS"},
{label: "COPIKTRA"},
{label: "COPRALGIR"},
{label: "COPRILLANA"},
{label: "COPTIN"},
{label: "COQUELUSEDAL ADULTES"},
{label: "COQUELUSEDAL ENFANTS"},
{label: "COQUELUSEDAL NOURRISSONS"},
{label: "COQUELUSEDAL PARACETAMOL"},
{label: "COQUELUSEDAL PARACETAMOL ENFANTS"},
{label: "COQUELUSEDAL PARACETAMOL NOURRISSONS"},
{label: "CORALLIUM RUBRUM BOIRON"},
{label: "CORALLIUM RUBRUM LEHNING"},
{label: "CORALLIUM RUBRUM WELEDA"},
{label: "CORAMINE GLUCOSE"},
{label: "CORAZED 1%"},
{label: "CORBILTA"},
{label: "CORBIONAX"},
{label: "CORDARONE"},
{label: "CORDIPATCH"},
{label: "CORDITRINE"},
{label: "COREINE"},
{label: "CORET"},
{label: "CORGARD"},
{label: "CORICIDE LE DIABLE"},
{label: "CORICIDE RODELL"},
{label: "CORLENTOR"},
{label: "CORLOPAM"},
{label: "CORMAGNESIN"},
{label: "CORONAL"},
{label: "CORONARINE"},
{label: "COROTROPE"},
{label: "CORRECTOL"},
{label: "CORRIDUM"},
{label: "CORRODINE"},
{label: "CORTAN"},
{label: "CORTANCYL"},
{label: "CORTAPAISYL"},
{label: "CORTEX CEREBRAL BOIRON"},
{label: "CORTEX CEREBRAL WELEDA"},
{label: "CORTEX SURRENAL BOIRON"},
{label: "CORTEXAN FRAMYCETINE"},
{label: "CORTEXYL ADULTE"},
{label: "CORTEXYL ENFANTS"},
{label: "CORTIBIOTIQUE"},
{label: "CORTICETINE"},
{label: "CORTICOTULLE LUMIERE"},
{label: "CORTIFRA"},
{label: "CORTIMENT"},
{label: "CORTISAL"},
{label: "CORTISEDERMYL"},
{label: "CORTISONE BOIRON"},
{label: "CORTISONE ROUSSEL"},
{label: "CORTOFLAM"},
{label: "CORVASAL"},
{label: "CORVASAL INTRACORONAIRE"},
{label: "CORVASAL INTRAVEINEUX"},
{label: "CORVERT"},
{label: "CORYZALIA"},
{label: "COSADON"},
{label: "COSENTYX"},
{label: "COSIDIME"},
{label: "COSIMPREL"},
{label: "COSMEGEN"},
{label: "COSMOFER"},
{label: "COSOPT"},
{label: "COSPIRINE"},
{label: "COSTEC"},
{label: "COTAMOX"},
{label: "COTAREG"},
{label: "COTELLIC"},
{label: "COTEOULA"},
{label: "COTEVETEN"},
{label: "COTRIATEC"},
{label: "COTRIMOXAZOLE DAKOTA PHARM"},
{label: "COTRIMOXAZOLE NOURRISSONS ET ENFANTS RPG"},
{label: "COTRIMOXAZOLE RPG"},
{label: "COTRIMOXAZOLE RPG ADULTES"},
{label: "COTRIMOXAZOLE TEVA"},
{label: "COTRONAK"},
{label: "COUMADINE"},
{label: "COVALAN"},
{label: "COVATINE"},
{label: "COVERAM"},
{label: "COVERSYL"},
{label: "COZAAR"},
{label: "CRATAEGUS"},
{label: "CRATAEGUS COMPLEXE N°15"},
{label: "CRATAEGUS GMET"},
{label: "CRATAEGUS OXYACANTHA"},
{label: "CRATAEGUS OXYACANTHA BOIRON"},
{label: "CRATAEGUS OXYACANTHA FERRIER"},
{label: "CRATAEGUS OXYACANTHA LEHNING"},
{label: "CRATAEGUS OXYACANTHA TEINTURE MERE BOIRON"},
{label: "CRAZOLUC"},
{label: "CREDIGYNE"},
{label: "CREME ANTICORS"},
{label: "CREME AU CALENDULA"},
{label: "CREME DE DALIBOUR COOPER"},
{label: "CREME DES TROIS FLEURS D'ORIENT"},
{label: "CREME RAP"},
{label: "CREON"},
{label: "CRESEMBA"},
{label: "CRESNISOL N10"},
{label: "CRESNISOL N10E"},
{label: "CRESNISOL N18"},
{label: "CRESNISOL N24"},
{label: "CRESOLUM BOIRON"},
{label: "CRESOPHENE"},
{label: "CRESTOR"},
{label: "CRIABLY"},
{label: "CRIFENE"},
{label: "CRINONE"},
{label: "CRINORMIN"},
{label: "CRISTAL ADULTES"},
{label: "CRISTAL ENFANTS"},
{label: "CRISTAL NOURRISSONS"},
{label: "CRISTANYL"},
{label: "CRISTOPAL"},
{label: "CRIXIVAN"},
{label: "CRIXO"},
{label: "CROCUS SATIVUS BOIRON"},
{label: "CROCUS SATIVUS LEHNING"},
{label: "CROMABAK"},
{label: "CROMADOSES"},
{label: "CROMEDIL"},
{label: "CROMOCOLL"},
{label: "CROMOFREE"},
{label: "CROMOGLICATE DE SODIUM BIOGARAN"},
{label: "CROMOGLICATE DE SODIUM GENEVRIER 2%"},
{label: "CROMOGLICATE DE SODIUM LAMEPLAST"},
{label: "CROMOGLICATE DE SODIUM SANDOZ CONSEIL"},
{label: "CROMOGLICATE DE SODIUM UNITHER"},
{label: "CROMOGLICATE DE SODIUM VIATRIS"},
{label: "CROMOGLICATE PIERRE FABRE"},
{label: "CROMOGLYCATE CHAUVIN"},
{label: "CROMOGLYCATE DE SODIUM RPG"},
{label: "CROMOLUX"},
{label: "CROMOPTIC"},
{label: "CROMORHINOL"},
{label: "CROMOSOFT"},
{label: "CRONOMIR"},
{label: "CROTALUS HORRIDUS BOIRON"},
{label: "CROTALUS HORRIDUS LEHNING"},
{label: "CROTALUS HORRIDUS WELEDA"},
{label: "CROTON TIGLIUM BOIRON"},
{label: "CROTON TIGLIUM LEHNING"},
{label: "CROTON TIGLIUM WELEDA"},
{label: "CRYSVITA"},
{label: "CUBICIN"},
{label: "CUCURBITA PEPO BOIRON"},
{label: "CUENCA"},
{label: "CUFENCE"},
{label: "CUIVRE MICROSOL"},
{label: "CUIVRE OLIGOSOL"},
{label: "CUIVRE-OR-ARGENT OLIGOSOL"},
{label: "CUPRESSUS SEMPERVIRENS BOIRON"},
{label: "CUPRESSUS SEMPERVIRENS LEHNING"},
{label: "CUPRIOR"},
{label: "CUPRUM ACETICUM BOIRON"},
{label: "CUPRUM ACETICUM LEHNING"},
{label: "CUPRUM ACETICUM WELEDA"},
{label: "CUPRUM ARSENICOSUM BOIRON"},
{label: "CUPRUM METALLICUM BOIRON"},
{label: "CUPRUM METALLICUM FERRIER"},
{label: "CUPRUM METALLICUM LEHNING"},
{label: "CUPRUM METALLICUM WELEDA"},
{label: "CUPRUM OXYDATUM NIGRUM BOIRON"},
{label: "CUPRUM SULFURICUM BOIRON"},
{label: "CUPRUM SULFURICUM WELEDA"},
{label: "CURACNE"},
{label: "CURARE BOIRON"},
{label: "CURASPOT"},
{label: "CURASTEN"},
{label: "CURAVEN"},
{label: "CURCUMA LONGA BOIRON"},
{label: "CURCUMA XANTHORRHIZA"},
{label: "CUREPAR"},
{label: "CURETHYL"},
{label: "CURICHROME"},
{label: "CUROPLUS"},
{label: "CUROSURF"},
{label: "CUROXIME"},
{label: "CUTACNYL"},
{label: "CUTADIS"},
{label: "CUTAQUIG"},
{label: "CUTERPES"},
{label: "CUTIPHILE"},
{label: "CUTIRENYL"},
{label: "CUTISAN"},
{label: "CUTISAN HYDROCORTISONE"},
{label: "CUVITRU"},
{label: "CUXANORM"},
{label: "CYAMEMAZINE ARROW"},
{label: "CYAMEMAZINE BIOGARAN"},
{label: "CYAMEMAZINE MICRO LABS"},
{label: "CYAMEMAZINE VIATRIS"},
{label: "CYANOCOBALAMINE RENAUDIN"},
{label: "CYANOCOBALAMINE [57"},
{label: "CYANOKIT"},
{label: "CYCLADOL"},
{label: "CYCLAMEN EUROPAEUM BOIRON"},
{label: "CYCLAMEN EUROPAEUM LEHNING"},
{label: "CYCLAMEN EUROPAEUM WELEDA"},
{label: "CYCLEANE"},
{label: "CYCLERGINE"},
{label: "CYCLO"},
{label: "CYCLOBRAL"},
{label: "CYCLODYNON"},
{label: "CYCLOPHOSPHAMIDE ACCORD"},
{label: "CYCLOPHOSPHAMIDE REDDY PHARMA"},
{label: "CYCLOPHOSPHAMIDE SANDOZ"},
{label: "CYCLOREL"},
{label: "CYCLOSPASMOL"},
{label: "CYCLOTERIAM"},
{label: "CYLTEZO"},
{label: "CYMBALTA"},
{label: "CYMEVAN"},
{label: "CYNARA SCOLYMUS BOIRON"},
{label: "CYNARA SCOLYMUS LEHNING"},
{label: "CYNARA SCOLYMUS WELEDA"},
{label: "CYNAROL"},
{label: "CYNOMEL"},
{label: "CYNT"},
{label: "CYNURIL"},
{label: "CYPRES NATURA MEDICA"},
{label: "CYPRIPEDIUM PUBESCENS BOIRON"},
{label: "CYPRIPEDIUM PUBESCENS LEHNING"},
{label: "CYPROMIX"},
{label: "CYPROPHARM"},
{label: "CYPROTERONE BIOGARAN"},
{label: "CYPROTERONE ETHINYLESTRADIOL QUALIMED"},
{label: "CYPROTERONE MYLAN"},
{label: "CYPROTERONE ZENTIVA"},
{label: "CYPROTERONE/ETHINYLESTRADIOL BAYER"},
{label: "CYPROTERONE/ETHINYLESTRADIOL DCI PHARMA"},
{label: "CYPROTERONE/ETHINYLESTRADIOL EG"},
{label: "CYPROTERONE/ETHINYLESTRADIOL MYLAN"},
{label: "CYPROTERONE/ETHINYLESTRADIOL RANBAXY"},
{label: "CYPROTERONE/ETHINYLESTRADIOL RATIOPHARM"},
{label: "CYPROTERONE/ETHINYLESTRADIOL SANDOZ"},
{label: "CYPROTERONE/ETHINYLESTRADIOL TEVA"},
{label: "CYPROTERONE/ETHINYLESTRADIOL ZENTIVA"},
{label: "CYPROTERONE/ETHINYLESTRADIOL ZYDUS"},
{label: "CYRAMZA"},
{label: "CYRDANAX"},
{label: "CYSTADANE"},
{label: "CYSTADROPS"},
{label: "CYSTAGON"},
{label: "CYSTICHOL"},
{label: "CYSTIDOSE"},
{label: "CYSTINE / VITAMINE B6 BIOGARAN CONSEIL"},
{label: "CYSTINE / VITAMINE B6 PHR LAB"},
{label: "CYSTINE B6 BAILLEUL"},
{label: "CYSTINE BAILLEUL"},
{label: "CYTARABINE ACCORD"},
{label: "CYTARABINE EBEWE"},
{label: "CYTARABINE EG"},
{label: "CYTARABINE KABI"},
{label: "CYTARABINE MYLAN"},
{label: "CYTARABINE SANDOZ"},
{label: "CYTARABINE UNION INTERPHARMACEUTIQUE ATLANTIQUE"},
{label: "CYTARBEL"},
{label: "CYTEAL"},
{label: "CYTEMBENA"},
{label: "CYTOSTIMULINE ADULTES"},
{label: "CYTOSTIMULINE ENFANTS"},
{label: "CYTOTEC"},
{label: "D.T.COQ."},
{label: "DABIGATRAN ETEXILATE BIOGARAN"},
{label: "DABIGATRAN ETEXILATE EG LABO"},
{label: "DABIGATRAN ETEXILATE TOWA PHARMACEUTICAL EUROPE"},
{label: "DACARBAZINE HOSPIRA"},
{label: "DACARBAZINE LIPOMED"},
{label: "DACARBAZINE MEDAC"},
{label: "DACARBAZINE TEVA"},
{label: "DACOGEN"},
{label: "DACPLAT"},
{label: "DACRYNE"},
{label: "DACRYOBORALINE"},
{label: "DACRYOLARMES"},
{label: "DACRYOSEPT"},
{label: "DACRYOSERUM"},
{label: "DACRYUM"},
{label: "DACRYUMSPRAY"},
{label: "DACTYLIS GLOMERATA BOIRON"},
{label: "DACUDOSES"},
{label: "DAFALGAN"},
{label: "DAFALGAN ADULTES"},
{label: "DAFALGAN CODEINE"},
{label: "DAFALGANCAPS"},
{label: "DAFALGANHOP"},
{label: "DAFALGANTABS"},
{label: "DAFLON"},
{label: "DAILY"},
{label: "DAIVOBET"},
{label: "DAIVONEX"},
{label: "DAKIN COOPER STABILISE"},
{label: "DAKLINZA"},
{label: "DAKTACORT"},
{label: "DAKTARIN"},
{label: "DAKTARIN INJECTABLE"},
{label: "DALACINE"},
{label: "DALACINE T TOPIC"},
{label: "DALACINE TOPIC"},
{label: "DALFEINE"},
{label: "DALMENE"},
{label: "DAMALGO"},
{label: "DAMIANA BOIRON"},
{label: "DAMIANA LEHNING"},
{label: "DANATROL"},
{label: "DANTRIUM"},
{label: "DANTRIUM INTRAVEINEUX"},
{label: "DAONIL"},
{label: "DAONIL FAIBLE"},
{label: "DAPTOMYCINE ACCORD"},
{label: "DAPTOMYCINE ACCORDPHARMA"},
{label: "DAPTOMYCINE BAXTER"},
{label: "DAPTOMYCINE FOSUN PHARMA"},
{label: "DAPTOMYCINE HIKMA"},
{label: "DAPTOMYCINE HOSPIRA"},
{label: "DAPTOMYCINE MEDAC"},
{label: "DAPTOMYCINE NORIDEM"},
{label: "DAPTOMYCINE REDDY PHARMA"},
{label: "DAPTOMYCINE XELLIA"},
{label: "DARKINAL"},
{label: "DARUNAVIR ACCORD"},
{label: "DARUNAVIR ARROW"},
{label: "DARUNAVIR BIOGARAN"},
{label: "DARUNAVIR EG"},
{label: "DARUNAVIR KRKA"},
{label: "DARUNAVIR KRKA D.D."},
{label: "DARUNAVIR LAURUS"},
{label: "DARUNAVIR MYLAN"},
{label: "DARUNAVIR REDDY PHARMA"},
{label: "DARUNAVIR SANDOZ"},
{label: "DARUNAVIR TEVA"},
{label: "DARUNAVIR ZENTIVA"},
{label: "DARZALEX"},
{label: "DASATINIB BIOGARAN"},
{label: "DASATINIB EG"},
{label: "DASATINIB KRKA"},
{label: "DASATINIB SANDOZ"},
{label: "DASATINIB TEVA"},
{label: "DASATINIB TEVA SANTE"},
{label: "DASATINIB TIEFENBACHER"},
{label: "DASATINIB TILLOMED"},
{label: "DASATINIB VIATRIS"},
{label: "DASATINIB ZENTIVA"},
{label: "DASCAGEL"},
{label: "DASERGIN"},
{label: "DASSELTA"},
{label: "DATAMICINE"},
{label: "DATRIBINO"},
{label: "DATSCAN"},
{label: "DAUNOXOME"},
{label: "DAURISMO"},
{label: "DAVICILLINE"},
{label: "DAXAS"},
{label: "DAZEN"},
{label: "DAZOTEC"},
{label: "DEBAR ADULTES"},
{label: "DEBDOX"},
{label: "DEBRICALM"},
{label: "DEBRIDAT"},
{label: "DEBRIDAT ENFANT"},
{label: "DEBRUMYL"},
{label: "DECADRON"},
{label: "DECALOGIFLOX"},
{label: "DECAN"},
{label: "DECAPEPTYL"},
{label: "DECAPEPTYL L.P."},
{label: "DECAPEPTYL LP"},
{label: "DECONTRACTYL"},
{label: "DECONTRACTYL BAUME"},
{label: "DECTANCYL"},
{label: "DECTOVA"},
{label: "DEDROGYL"},
{label: "DEEDERM"},
{label: "DEFANYL"},
{label: "DEFERASIROX ABDIFARMA"},
{label: "DEFERASIROX ABDIFARMAI"},
{label: "DEFERASIROX ACCORD"},
{label: "DEFERASIROX ARROW"},
{label: "DEFERASIROX BIOGARAN"},
{label: "DEFERASIROX EG"},
{label: "DEFERASIROX MYLAN"},
{label: "DEFERASIROX PHARMATHEN"},
{label: "DEFERASIROX SANDOZ"},
{label: "DEFERASIROX TEVA"},
{label: "DEFERASIROX ZENTIVA"},
{label: "DEFERIPRONE ARROW"},
{label: "DEFERIPRONE LIPOMED"},
{label: "DEFEROXAMINE TEVA"},
{label: "DEFEROXAMINE TEVA UK"},
{label: "DEFILTRAN"},
{label: "DEFIRIN"},
{label: "DEFIRINSPRAY"},
{label: "DEFITELIO"},
{label: "DEFLAMOL"},
{label: "DEHYDROEMETINE ROCHE"},
{label: "DELABARRE"},
{label: "DELASMIL L.P."},
{label: "DELBIASE"},
{label: "DELENTE"},
{label: "DELIDOSE"},
{label: "DELIPROCT"},
{label: "DELLOVA"},
{label: "DELPRIM"},
{label: "DELSTRIGO"},
{label: "DELTASTATIN"},
{label: "DELTAZINE"},
{label: "DELTIUS"},
{label: "DELTYBA"},
{label: "DELURSAN"},
{label: "DEMECLOCYCLINE ACETLAB"},
{label: "DEMETIL"},
{label: "DEMILOS"},
{label: "DENGVAXIA"},
{label: "DENISOLINE"},
{label: "DENORAL"},
{label: "DENORAL ADULTES"},
{label: "DENORAL ENFANTS"},
{label: "DENSICAL"},
{label: "DENSICAL VITAMINE D3"},
{label: "DENTEX"},
{label: "DENTINEA"},
{label: "DENTOBAUME"},
{label: "DEPAKINE"},
{label: "DEPAKINE CHRONO"},
{label: "DEPAKOTE"},
{label: "DEPAMIDE"},
{label: "DEPE"},
{label: "DEPO MEDROL"},
{label: "DEPO PRODASONE"},
{label: "DEPO PROVERA"},
{label: "DEPO-MEDROL"},
{label: "DEPO-PRODASONE"},
{label: "DEPOCYTE"},
{label: "DEPRENYL"},
{label: "DEPURATIF DES ALPES"},
{label: "DEPURATIF PARNEL"},
{label: "DEPURATIF RICHELET"},
{label: "DEPURATUM"},
{label: "DERICHEM"},
{label: "DERINOX"},
{label: "DERMABAZ"},
{label: "DERMACHROME"},
{label: "DERMACIDE"},
{label: "DERMAFUSONE"},
{label: "DERMASPRAID ANTISEPTIQUE"},
{label: "DERMASPRAID DEMANGEAISON"},
{label: "DERMASPRAY CONTUSIONS"},
{label: "DERMATRANS"},
{label: "DERMAZOL"},
{label: "DERMESTRIL"},
{label: "DERMESTRIL SEPTEM"},
{label: "DERMICLONE"},
{label: "DERMO SULFURYL"},
{label: "DERMO-6"},
{label: "DERMO-DRAINOL"},
{label: "DERMOBACTER"},
{label: "DERMOCALM"},
{label: "DERMOCUIVRE"},
{label: "DERMOFENAC DEMANGEAISONS"},
{label: "DERMOPHIL INDIEN LEVRES"},
{label: "DERMOPHIL INDIEN MAINS"},
{label: "DERMOPHIL INDIEN ROSE LEVRES"},
{label: "DERMORELLE"},
{label: "DERMOVAL"},
{label: "DEROXAT"},
{label: "DESARENA"},
{label: "DESBLY"},
{label: "DESCOVY"},
{label: "DESERNIL"},
{label: "DESFERAL"},
{label: "DESFLURANE PIRAMAL"},
{label: "DESINTEX"},
{label: "DESINTEX CHOLINE"},
{label: "DESINTEX INFANTILE"},
{label: "DESIOL"},
{label: "DESLORATADINE ACTAVIS"},
{label: "DESLORATADINE ALMUS"},
{label: "DESLORATADINE ARROW"},
{label: "DESLORATADINE ARROW GENERIQUES"},
{label: "DESLORATADINE ARROW LAB"},
{label: "DESLORATADINE BIOGARAN"},
{label: "DESLORATADINE CRISTERS"},
{label: "DESLORATADINE DISPHAR"},
{label: "DESLORATADINE EG"},
{label: "DESLORATADINE EG LABO"},
{label: "DESLORATADINE EVOLUGEN"},
{label: "DESLORATADINE KRKA"},
{label: "DESLORATADINE MYLAN PHARMA"},
{label: "DESLORATADINE PHR LAB"},
{label: "DESLORATADINE REF"},
{label: "DESLORATADINE SANDOZ"},
{label: "DESLORATADINE SET"},
{label: "DESLORATADINE SUN"},
{label: "DESLORATADINE TEVA SANTE"},
{label: "DESLORATADINE TORRENT"},
{label: "DESLORATADINE URGO"},
{label: "DESLORATADINE VIATRIS"},
{label: "DESLORATADINE VIATRIS SANTE"},
{label: "DESLORATADINE VJ-PHARM"},
{label: "DESLORATADINE ZENTIVA"},
{label: "DESLORATADINE ZYDUS"},
{label: "DESMOGALEN"},
{label: "DESMOPRESSINE FERRING"},
{label: "DESMOPRESSINE RANBAXY"},
{label: "DESMOPRESSINE RPG"},
{label: "DESOBEL"},
{label: "DESOCORT"},
{label: "DESOGESTREL ARROW"},
{label: "DESOGESTREL BIOGARAN"},
{label: "DESOGESTREL CHEMICAL FARMA"},
{label: "DESOGESTREL CRISTERS"},
{label: "DESOGESTREL EG"},
{label: "DESOGESTREL ETHINYLESTRADIOL DISTRIRETI"},
{label: "DESOGESTREL ETHINYLESTRADIOL ELKA"},
{label: "DESOGESTREL ETHINYLESTRADIOL IDD"},
{label: "DESOGESTREL ETHINYLESTRADIOL IDETEC"},
{label: "DESOGESTREL ETHINYLESTRADIOL QUILL"},
{label: "DESOGESTREL ETHINYLESTRADIOL TURNER"},
{label: "DESOGESTREL FAMY CARE"},
{label: "DESOGESTREL MEDIPHA"},
{label: "DESOGESTREL MSD"},
{label: "DESOGESTREL MYLAN PHARMA"},
{label: "DESOGESTREL SANDOZ"},
{label: "DESOGESTREL TEVA"},
{label: "DESOGESTREL VIATRIS SANTE"},
{label: "DESOGESTREL ZENTIVA"},
{label: "DESOGESTREL/ETHINYLESTRADIOL BIOGARAN"},
{label: "DESOGESTREL/ETHINYLESTRADIOL BIOGARANCONTINU"},
{label: "DESOGESTREL/ETHINYLESTRADIOL CRISTERS"},
{label: "DESOGESTREL/ETHINYLESTRADIOL EG"},
{label: "DESOGESTREL/ETHINYLESTRADIOL TEVA"},
{label: "DESOGESTREL/ETHINYLESTRADIOL VIATRIS"},
{label: "DESOGESTREL/ETHINYLESTRADIOL ZENTIVA"},
{label: "DESOMEDINE"},
{label: "DESONIDE RPG"},
{label: "DESOPHARM"},
{label: "DESOPOP"},
{label: "DESOWEN"},
{label: "DESURAL"},
{label: "DESURIC"},
{label: "DESYL"},
{label: "DETENSIEL"},
{label: "DETICENE"},
{label: "DETOXALGINE"},
{label: "DETRUSITOL"},
{label: "DETRUSITOL L.P."},
{label: "DETTOLLINN"},
{label: "DETTOLPRO"},
{label: "DETURGYLONE"},
{label: "DEVALENE"},
{label: "DEVITASOL ARSENICAL"},
{label: "DEXADERME KEFRANE"},
{label: "DEXAFREE"},
{label: "DEXAGRANE"},
{label: "DEXAMBUTOL"},
{label: "DEXAMBUTOL-INH"},
{label: "DEXAMETHASONE CHAUVIN"},
{label: "DEXAMETHASONE DAKOTA PHARM"},
{label: "DEXAMETHASONE EIGNAPHARMA"},
{label: "DEXAMETHASONE KALCEKS"},
{label: "DEXAMETHASONE KRKA"},
{label: "DEXAMETHASONE MEDISOL"},
{label: "DEXAMETHASONE MERCK-CLEVENOT"},
{label: "DEXAMETHASONE NEOMYCINE MERCK-CLEVENOT"},
{label: "DEXAMETHASONE PANPHARMA"},
{label: "DEXAMETHASONE PHOSPHATE LEURQUIN MEDIOLANUM"},
{label: "DEXAMETHASONE VIATRIS"},
{label: "DEXAP"},
{label: "DEXATOPIA"},
{label: "DEXDOR"},
{label: "DEXEF"},
{label: "DEXERYLCARE"},
{label: "DEXFENFLURAMINE SERVIER"},
{label: "DEXIR ADULTE"},
{label: "DEXIR ENFANT"},
{label: "DEXLANSOPRAZOLE TAKEDA"},
{label: "DEXLIQ"},
{label: "DEXMEDETOMIDINE ACCORD"},
{label: "DEXMEDETOMIDINE ALTAN"},
{label: "DEXMEDETOMIDINE B. BRAUN"},
{label: "DEXMEDETOMIDINE BAXTER"},
{label: "DEXMEDETOMIDINE EVER PHARMA"},
{label: "DEXMEDETOMIDINE KABI"},
{label: "DEXMEDETOMIDINE KALCEKS"},
{label: "DEXMEDETOMIDINE LORIEN"},
{label: "DEXMEDETOMIDINE TEVA"},
{label: "DEXMÉDÉTOMIDINE VIATRIS"},
{label: "DEXOCOL"},
{label: "DEXPANTHENOL ARROW"},
{label: "DEXPANTHENOL ARROW CONSEIL"},
{label: "DEXPANTHENOL BAILLEUL"},
{label: "DEXPANTHENOL BIOGARAN CONSEIL"},
{label: "DEXPANTHENOL CRISTERS"},
{label: "DEXPANTHENOL EG LABO CONSEIL"},
{label: "DEXPANTHENOL SANDOZ CONSEIL"},
{label: "DEXPANTHENOL SG-PHARM"},
{label: "DEXPANTHENOL VIATRIS"},
{label: "DEXPANTHENOL ZENTIVA CONSEIL"},
{label: "DEXPARACE"},
{label: "DEXTOMA"},
{label: "DEXTOMA-CARAMEL"},
{label: "DEXTRAN SORBITOL B. BRAUN"},
{label: "DEXTRAN SORBITOL BRUNEAU"},
{label: "DEXTRARINE PHENYLBUTAZONE"},
{label: "DEXTRION G"},
{label: "DEXTROCIDINE"},
{label: "DEXTROMETHORPHANE (BROMHYDRATE DE) BIOGALENIQUE ADULTES"},
{label: "DEXTROMETHORPHANE (BROMHYDRATE DE) BIOGALENIQUE ENFANTS"},
{label: "DEXTROMETHORPHANE ARROW"},
{label: "DEXTROMETHORPHANE BIOGARAN"},
{label: "DEXTROMETHORPHANE BOUCHARA RECORDATI"},
{label: "DEXTROMETHORPHANE ELERTE"},
{label: "DEXTROMETHORPHANE GNR"},
{label: "DEXTROMETHORPHANE LABORATOIRE UNITHER"},
{label: "DEXTROMETHORPHANE MYLAN"},
{label: "DEXTROMETHORPHANE PIERRE FABRE SANTE"},
{label: "DEXTROMETHORPHANE RECKITT BENCKISER TOUX SECHE"},
{label: "DEXTROMETHORPHANE UNISTICK"},
{label: "DEXTROMETHORPHANE UNITHER"},
{label: "DEXTROPROPOXYPHENE PARACETAMOL ACTAVIS"},
{label: "DEXTROPROPOXYPHENE PARACETAMOL ALMUS"},
{label: "DEXTROPROPOXYPHENE PARACETAMOL ALTER"},
{label: "DEXTROPROPOXYPHENE PARACETAMOL ARROW"},
{label: "DEXTROPROPOXYPHENE PARACETAMOL BIOGALENIQUE"},
{label: "DEXTROPROPOXYPHENE PARACETAMOL BIOGARAN"},
{label: "DEXTROPROPOXYPHENE PARACETAMOL CRISTERS"},
{label: "DEXTROPROPOXYPHENE PARACETAMOL DCI PHARMA"},
{label: "DEXTROPROPOXYPHENE PARACETAMOL EG"},
{label: "DEXTROPROPOXYPHENE PARACETAMOL G GAM"},
{label: "DEXTROPROPOXYPHENE PARACETAMOL ISOMED"},
{label: "DEXTROPROPOXYPHENE PARACETAMOL IVAX"},
{label: "DEXTROPROPOXYPHENE PARACETAMOL MYLAN"},
{label: "DEXTROPROPOXYPHENE PARACETAMOL QUALIMED"},
{label: "DEXTROPROPOXYPHENE PARACETAMOL RATIOPHARM"},
{label: "DEXTROPROPOXYPHENE PARACETAMOL RPG"},
{label: "DEXTROPROPOXYPHENE PARACETAMOL SANDOZ"},
{label: "DEXTROPROPOXYPHENE PARACETAMOL SODEPHAR"},
{label: "DEXTROPROPOXYPHENE PARACETAMOL SUBSTIPHARM"},
{label: "DEXTROPROPOXYPHENE PARACETAMOL TEVA"},
{label: "DEXTROPROPOXYPHENE PARACETAMOL THERAPLIX"},
{label: "DEXTROPROPOXYPHENE PARACETAMOL WINTHROP"},
{label: "DEXTROPROPOXYPHENE PARACETAMOL ZYDUS"},
{label: "DEXTROPROPOXYPHENE-PARACETAMOL B.R.M."},
{label: "DEXTROPROPOXYPHENE/PARACETAMOL/CAFEINE ALMUS"},
{label: "DEXTROPROPOXYPHENE/PARACETAMOL/CAFEINE ALTER"},
{label: "DEXTROPROPOXYPHENE/PARACETAMOL/CAFEINE ARROW"},
{label: "DEXTROPROPOXYPHENE/PARACETAMOL/CAFEINE BIOGARAN"},
{label: "DEXTROPROPOXYPHENE/PARACETAMOL/CAFEINE EG"},
{label: "DEXTROPROPOXYPHENE/PARACETAMOL/CAFEINE G GAM"},
{label: "DEXTROPROPOXYPHENE/PARACETAMOL/CAFEINE ISOMED"},
{label: "DEXTROPROPOXYPHENE/PARACETAMOL/CAFEINE MYLAN"},
{label: "DEXTROPROPOXYPHENE/PARACETAMOL/CAFEINE MYLAN PHARMA"},
{label: "DEXTROPROPOXYPHENE/PARACETAMOL/CAFEINE QUALIMED"},
{label: "DEXTROPROPOXYPHENE/PARACETAMOL/CAFEINE RATIOPHARM"},
{label: "DEXTROPROPOXYPHENE/PARACETAMOL/CAFEINE RPG"},
{label: "DEXTROPROPOXYPHENE/PARACETAMOL/CAFEINE SANDOZ"},
{label: "DEXTROPROPOXYPHENE/PARACETAMOL/CAFEINE TEVA"},
{label: "DEXTROPROPOXYPHENE/PARACETAMOL/CAFEINE WINTHROP"},
{label: "DEXTROPROPOXYPHENE/PARACETAMOL/CAFEINE ZYDUS"},
{label: "DEXTROREF"},
{label: "DEXTUSSIL"},
{label: "DI ACTANE"},
{label: "DI ANTALVIC ADULTES"},
{label: "DI DOLKO"},
{label: "DI-ANTALVIC"},
{label: "DI-HYDAN"},
{label: "DIABAMYL"},
{label: "DIABENE"},
{label: "DIABEX"},
{label: "DIABINESE"},
{label: "DIACARDYNE L.P."},
{label: "DIACEREINE ACTAVIS"},
{label: "DIACEREINE ARROW"},
{label: "DIACEREINE BIOGARAN"},
{label: "DIACEREINE CRISTERS"},
{label: "DIACEREINE EG"},
{label: "DIACEREINE EVOLUGEN"},
{label: "DIACEREINE GENODEX"},
{label: "DIACEREINE MAZAL"},
{label: "DIACEREINE MYLAN"},
{label: "DIACEREINE NEGMA"},
{label: "DIACEREINE PHARMA"},
{label: "DIACEREINE QUALIMED"},
{label: "DIACEREINE RANBAXY"},
{label: "DIACEREINE RATIOPHARM"},
{label: "DIACEREINE REF"},
{label: "DIACEREINE SANDOZ"},
{label: "DIACEREINE SET"},
{label: "DIACEREINE SUBSTILAB"},
{label: "DIACEREINE SUBSTIPHARM"},
{label: "DIACEREINE TEVA"},
{label: "DIACEREINE TRB CHEMEDICA"},
{label: "DIACEREINE ZENTIVA"},
{label: "DIACEREINE ZYDUS"},
{label: "DIACOMIT"},
{label: "DIACOR L.P."},
{label: "DIACOR LP"},
{label: "DIACTANE"},
{label: "DIADUPSAN"},
{label: "DIAFURYL"},
{label: "DIAFUSOR"},
{label: "DIALAMIL"},
{label: "DIALGIREX"},
{label: "DIALYSE PERITONEALE CONTINUE AMBULATOIRE"},
{label: "DIAMICRON"},
{label: "DIAMORIL"},
{label: "DIAMOX"},
{label: "DIANE"},
{label: "DIANEAL PD1 GLUCOSE"},
{label: "DIANEAL PD4 GLUCOSE"},
{label: "DIANECTAN"},
{label: "DIAPHRAGME BOIRON"},
{label: "DIARALIA"},
{label: "DIAREFON"},
{label: "DIAREGAN"},
{label: "DIARETYL"},
{label: "DIARFIX"},
{label: "DIARLAC"},
{label: "DIARSED"},
{label: "DIASEPTYL"},
{label: "DIASPASMYL PEDIATRIQUE"},
{label: "DIASTABOL"},
{label: "DIASTROLIB"},
{label: "DIATISAN"},
{label: "DIAZEM L.P."},
{label: "DIAZEMULS"},
{label: "DIAZEPAM ACCORD"},
{label: "DIAZEPAM ARROW"},
{label: "DIAZEPAM CHOAY"},
{label: "DIAZEPAM RENAUDIN"},
{label: "DIAZEPAM TEVA"},
{label: "DIBERLEPIDINE"},
{label: "DIBIOCOL"},
{label: "DICAGEL ADULTE"},
{label: "DICAGEL ENFANT"},
{label: "DICETEL"},
{label: "DICHLORHYDRATE DE CETIRIZINE VEDIM"},
{label: "DICHLORHYDRATE DE METOCLOPRAMIDE PANPHARMA"},
{label: "DICHLORHYDRATE DE METOCLOPRAMIDE PHARMANOVA"},
{label: "DICLAMINE"},
{label: "DICLOCIL"},
{label: "DICLOFENAC ACTAVIS"},
{label: "DICLOFENAC ARROW"},
{label: "DICLOFENAC ARROW CONSEIL"},
{label: "DICLOFENAC ARROW GENERIQUES"},
{label: "DICLOFENAC BGR"},
{label: "DICLOFENAC BIOGARAN"},
{label: "DICLOFENAC BIOGARAN LP"},
{label: "DICLOFENAC BIOSTABILEX"},
{label: "DICLOFENAC CHEMINEAU"},
{label: "DICLOFENAC CRISTERS"},
{label: "DICLOFENAC CRISTERS CONSEIL"},
{label: "DICLOFENAC DELBERT"},
{label: "DICLOFENAC EG"},
{label: "DICLOFENAC EG LABO 1%"},
{label: "DICLOFENAC EG LABO CONSEIL"},
{label: "DICLOFENAC G GAM"},
{label: "DICLOFENAC GENEVRIER"},
{label: "DICLOFENAC GNR"},
{label: "DICLOFENAC IVAX"},
{label: "DICLOFENAC LIDERLENS"},
{label: "DICLOFENAC MYLAN"},
{label: "DICLOFENAC NEPENTHES"},
{label: "DICLOFENAC OPIH"},
{label: "DICLOFENAC PHARMAKI"},
{label: "DICLOFENAC RANBAXY MEDICATION OFFICINALE"},
{label: "DICLOFENAC RATIO"},
{label: "DICLOFENAC RATIOPHARM"},
{label: "DICLOFENAC RATIOPHARM CONSEIL"},
{label: "DICLOFENAC REF"},
{label: "DICLOFENAC RENAUDIN"},
{label: "DICLOFENAC RPG"},
{label: "DICLOFENAC SANDOZ"},
{label: "DICLOFENAC SANDOZ CONSEIL"},
{label: "DICLOFENAC SANOFI-AVENTIS FRANCE"},
{label: "DICLOFENAC SODIQUE BIOGALENIQUE"},
{label: "DICLOFENAC SODIQUE MISOPROSTOL CARDEL"},
{label: "DICLOFENAC SODIQUE OPIH"},
{label: "DICLOFENAC SODIQUE OPIH ENFANTS"},
{label: "DICLOFENAC SODIQUE RPG"},
{label: "DICLOFENAC SODIQUE RPG ENFANT"},
{label: "DICLOFENAC SODIQUE RPG L.P."},
{label: "DICLOFENAC STRAGEL"},
{label: "DICLOFENAC STRAGEN FRANCE"},
{label: "DICLOFENAC SUBSTIPHARM"},
{label: "DICLOFENAC TEVA"},
{label: "DICLOFENAC TEVA CLASSICS"},
{label: "DICLOFENAC TEVA CONSEIL"},
{label: "DICLOFENAC TEVA SANTE CONSEIL"},
{label: "DICLOFENAC URGO"},
{label: "DICLOFENAC URGO 2%"},
{label: "DICLOFENAC VENIPHARM LP"},
{label: "DICLOFENAC VIATRIS"},
{label: "DICLOFENAC VIATRIS LP"},
{label: "DICLOFENAC VIATRIS SANTE"},
{label: "DICLOFENAC ZENTIVA"},
{label: "DICLOFENAC ZYDUS"},
{label: "DICLOGEL"},
{label: "DICODIN L.P."},
{label: "DICYNONE"},
{label: "DIDANOSINE ARROW"},
{label: "DIDRONEL"},
{label: "DIENCEPHALE BOIRON"},
{label: "DIENILLE CONTINU"},
{label: "DIENOGEST LABORATOIRES MAJORELLE"},
{label: "DIERGOSPRAY"},
{label: "DIFFERINE"},
{label: "DIFFU-K"},
{label: "DIFICLIR"},
{label: "DIFRAREL"},
{label: "DIFRAREL E"},
{label: "DIFTAVAX"},
{label: "DIGACID"},
{label: "DIGAOL"},
{label: "DIGEDRYL"},
{label: "DIGEFIT"},
{label: "DIGEFLASH"},
{label: "DIGESTAL"},
{label: "DIGESTIVAL"},
{label: "DIGESTOBOL"},
{label: "DIGESTODORON"},
{label: "DIGIDOT"},
{label: "DIGITALINE NATIVELLE"},
{label: "DIGITALIS PURPUREA"},
{label: "DIGITALIS PURPUREA BOIRON"},
{label: "DIGITALIS PURPUREA LEHNING"},
{label: "DIGOXINE NATIVELLE"},
{label: "DIGOXINE NATIVELLE ADULTES"},
{label: "DIGOXINE NATIVELLE PEDIATRIQUE"},
{label: "DIHYDROERGOTAMINE AMDIPHARM"},
{label: "DIHYDROERGOTAMINE GNR"},
{label: "DIHYDROERGOTAMINE MONAL"},
{label: "DIHYDROERGOTAMINE ZAMBON"},
{label: "DILANTIN"},
{label: "DILAR"},
{label: "DILATRANE"},
{label: "DILAUDID"},
{label: "DILIGEST"},
{label: "DILOSPIR"},
{label: "DILRENE"},
{label: "DILRENE L.P."},
{label: "DILTIAZEM ADOH LP"},
{label: "DILTIAZEM ARROW LP"},
{label: "DILTIAZEM BGR LP"},
{label: "DILTIAZEM BIOGALENIQUE"},
{label: "DILTIAZEM BIOGARAN"},
{label: "DILTIAZEM BIOGARAN L.P."},
{label: "DILTIAZEM BIOGARAN LP"},
{label: "DILTIAZEM CRISTERS"},
{label: "DILTIAZEM CRISTERS L.P."},
{label: "DILTIAZEM CRISTERS LP"},
{label: "DILTIAZEM EG L.P."},
{label: "DILTIAZEM G GAM LP"},
{label: "DILTIAZEM IVAX L P"},
{label: "DILTIAZEM MYLAN GENERIQUES L.P."},
{label: "DILTIAZEM MYLAN PHARMA LP"},
{label: "DILTIAZEM PAN PHARMA"},
{label: "DILTIAZEM RATIOPHARM L.P."},
{label: "DILTIAZEM RPG"},
{label: "DILTIAZEM RPG LP"},
{label: "DILTIAZEM SANDOZ"},
{label: "DILTIAZEM SANDOZ LP"},
{label: "DILTIAZEM TEVA"},
{label: "DILTIAZEM TEVA L.P."},
{label: "DILTIAZEM TEVA SANTE LP"},
{label: "DILTIAZEM VIATRIS L.P."},
{label: "DILTIAZEM VIATRIS LP"},
{label: "DILTIAZEM WINTHROP L.P."},
{label: "DILTIAZEM ZENTIVA L.P."},
{label: "DILTIAZEM ZENTIVA LAB LP"},
{label: "DILTIAZEM ZYDUS"},
{label: "DILTIAZEM ZYDUS LP"},
{label: "DILUANT GLYCERO SALIN DES STALLERGENES"},
{label: "DILUANT H.S.A."},
{label: "DILUANT PHYSIOLOGIQUE PHENOLE"},
{label: "DILUANT POUR ALLERGENES"},
{label: "DIMEGAN"},
{label: "DIMETANE ADULTES"},
{label: "DIMETANE EXPECTORANT ENFANTS"},
{label: "DIMETANE SANS SUCRE"},
{label: "DIMETHYL FUMARATE ACCORD"},
{label: "DIMETHYL FUMARATE BIOGARAN"},
{label: "DIMETHYL FUMARATE EG"},
{label: "DIMETHYL FUMARATE MYLAN"},
{label: "DIMETHYL FUMARATE NEURAXPHARM"},
{label: "DIMETHYL FUMARATE POLPHARMA"},
{label: "DIMETHYL FUMARATE ZENTIVA"},
{label: "DIMETHYL FUMARATE ZYDUS"},
{label: "DIMETHYLFUMARATE TEVA"},
{label: "DIMETRUM"},
{label: "DIMOTAPP EXPECTORANT"},
{label: "DIMTRUZIC"},
{label: "DINACODE"},
{label: "DINACODE ADULTES"},
{label: "DINACODE ENFANTS"},
{label: "DINACODE NOURRISSONS"},
{label: "DINAL"},
{label: "DINCILEZAN"},
{label: "DININTEL"},
{label: "DINITRATE D'ISOSORBIDE IREX L.P."},
{label: "DINITRATE D'ISOSORBIDE IREX."},
{label: "DINITRATE D'ISOSORBIDE MYLAN L.P."},
{label: "DINITRATE D'ISOSORBIDE RPG"},
{label: "DINITRATE D'ISOSORBIDE RPG L.P."},
{label: "DINITRATE D'ISOSORBIDE RPG LP"},
{label: "DINITRATE D'ISOSORBIDE WINTHROP L.P."},
{label: "DINORTES"},
{label: "DIO"},
{label: "DIOALGO"},
{label: "DIOPARINE"},
{label: "DIOPSIDE BOIRON"},
{label: "DIORALYTE"},
{label: "DIOSCOREA VILLOSA BOIRON"},
{label: "DIOSCOREA VILLOSA LEHNING"},
{label: "DIOSMECTITE BEAUFOUR"},
{label: "DIOSMECTITE IPSEN"},
{label: "DIOSMECTITE IPSEN CONSUMER HEALTHCARE"},
{label: "DIOSMECTITE IPSEN PHARMA"},
{label: "DIOSMECTITE VIATRIS"},
{label: "DIOSMIL"},
{label: "DIOSMINE ARROW"},
{label: "DIOSMINE ARROW CONSEIL"},
{label: "DIOSMINE BIOGARAN CONSEIL"},
{label: "DIOSMINE BIOSTABILEX"},
{label: "DIOSMINE CRISTERS"},
{label: "DIOSMINE DCI PHARMA"},
{label: "DIOSMINE EG"},
{label: "DIOSMINE EG LABO CONSEIL"},
{label: "DIOSMINE G GAM"},
{label: "DIOSMINE HEXAL SANTE"},
{label: "DIOSMINE INNOTHERA"},
{label: "DIOSMINE IVAX"},
{label: "DIOSMINE MERCK GENERIQUES"},
{label: "DIOSMINE MYLAN"},
{label: "DIOSMINE MYLAN PHARMA"},
{label: "DIOSMINE QUALIMED"},
{label: "DIOSMINE RANBAXY"},
{label: "DIOSMINE RATIO"},
{label: "DIOSMINE RATIOPHARM"},
{label: "DIOSMINE RATIOPHARM CONSEIL"},
{label: "DIOSMINE RPG"},
{label: "DIOSMINE SANDOZ"},
{label: "DIOSMINE SANDOZ CONSEIL"},
{label: "DIOSMINE SET"},
{label: "DIOSMINE SUN"},
{label: "DIOSMINE TEVA CONSEIL"},
{label: "DIOSMINE THERAPLIX"},
{label: "DIOSMINE VIATRIS CONSEIL"},
{label: "DIOSMINE ZENTIVA CONSEIL"},
{label: "DIOSMINE ZYDUS"},
{label: "DIOVENOR"},
{label: "DIPENTUM"},
{label: "DIPEPTIVEN"},
{label: "DIPHANTE"},
{label: "DIPHAR"},
{label: "DIPHTERICUM BOIRON"},
{label: "DIPHTEROTOXINUM BOIRON"},
{label: "DIPINEX"},
{label: "DIPIPERON"},
{label: "DIPOROS"},
{label: "DIPRIVAN"},
{label: "DIPROLENE"},
{label: "DIPROPIONATE DE BECLOMETASONE 3M FRANCE AUTOHALER"},
{label: "DIPROPIONATE DE BECLOMETASONE LEMOINE"},
{label: "DIPROPIONATE DE BECLOMETASONE O.P.I.H."},
{label: "DIPROSALIC"},
{label: "DIPROSEPT"},
{label: "DIPROSONE"},
{label: "DIPROSONE NEOMYCINE"},
{label: "DIPROSTENE"},
{label: "DISALGYL"},
{label: "DISCASE"},
{label: "DISCASE 6.000 U"},
{label: "DISCI CERVICALES BOIRON"},
{label: "DISCI LUMBALES BOIRON"},
{label: "DISCI THORACALES BOIRON"},
{label: "DISCOTRINE"},
{label: "DISOPYRAMIDE RPG"},
{label: "DISOPYRAMIDE RPG L.P."},
{label: "DISPROL ENFANTS"},
{label: "DISQUES CORICIDES POUR CORS"},
{label: "DISQUES CORICIDES POUR DURILLONS"},
{label: "DISQUES CORICIDES POUR OEIL DE PERDRIX"},
{label: "DISQUES VERTEBRAUX BOIRON"},
{label: "DISQUES VERTEBRAUX WELEDA"},
{label: "DISSOLVUROL"},
{label: "DISTILBENE"},
{label: "DISULONE"},
{label: "DITHIOL"},
{label: "DITHIOL ADULTES"},
{label: "DITHIOL ENFANTS"},
{label: "DITROPAN"},
{label: "DIURESANE"},
{label: "DIURESIL"},
{label: "DIURITEX"},
{label: "DIVALCOTE"},
{label: "DIVANE"},
{label: "DIVARIUS"},
{label: "DIVICONTE"},
{label: "DIVINA"},
{label: "DIVISEQ"},
{label: "DOBUTAMINE AGUETTANT"},
{label: "DOBUTAMINE BAXTER"},
{label: "DOBUTAMINE DAKOTA PHARM"},
{label: "DOBUTAMINE FAULDING"},
{label: "DOBUTAMINE GENTHON"},
{label: "DOBUTAMINE HIKMA"},
{label: "DOBUTAMINE HOSPIRA"},
{label: "DOBUTAMINE MYLAN"},
{label: "DOBUTAMINE PANPHARMA"},
{label: "DOBUTAMINE QUALIMED"},
{label: "DOBUTAMINE SILCAR"},
{label: "DOBUTAMINE SUN"},
{label: "DOBUTAMINE VIATRIS"},
{label: "DOBUTAMINE WINTHROP"},
{label: "DOBUTREX"},
{label: "DOCETAXEL ACCORD"},
{label: "DOCETAXEL AQVIDA"},
{label: "DOCETAXEL ARROW"},
{label: "DOCETAXEL DONG-A"},
{label: "DOCETAXEL EBEWE"},
{label: "DOCETAXEL EG"},
{label: "DOCETAXEL HIKMA"},
{label: "DOCETAXEL HOSPIRA"},
{label: "DOCETAXEL INTAS PHARMACEUTICALS"},
{label: "DOCETAXEL KABI"},
{label: "DOCETAXEL KOANAA"},
{label: "DOCETAXEL MYLAN"},
{label: "DOCETAXEL NORDIC"},
{label: "DOCETAXEL PFIZER"},
{label: "DOCETAXEL PHARMAKI GENERICS"},
{label: "DOCETAXEL RATIOPHARM"},
{label: "DOCETAXEL REGIOMEDICA"},
{label: "DOCETAXEL SANDOZ"},
{label: "DOCETAXEL SEACROSS"},
{label: "DOCETAXEL SIGILLATA"},
{label: "DOCETAXEL TEVA"},
{label: "DOCETAXEL TEVA GENERICS"},
{label: "DOCETAXEL TILLOMED"},
{label: "DOCETAXEL ZENTIVA"},
{label: "DOCTRIL"},
{label: "DODECAVIT"},
{label: "DOGMATIL"},
{label: "DOGMATIL SANS SUCRE"},
{label: "DOLAL"},
{label: "DOLANTAL"},
{label: "DOLCIDIUM"},
{label: "DOLEAN"},
{label: "DOLENIO"},
{label: "DOLFLASH"},
{label: "DOLGIT"},
{label: "DOLI ETAT GRIPPAL PARACETAMOL/VITAMINE C/PHENIRAMINE"},
{label: "DOLI MAL DE GORGE HEXAMIDINE/TETRACAINE"},
{label: "DOLIALLERGIE CROMOGLICATE DE SODIUM"},
{label: "DOLIALLERGIE LORATADINE"},
{label: "DOLICHOS PRURIENS BOIRON"},
{label: "DOLICHOS PRURIENS LEHNING"},
{label: "DOLICHOS PRURIENS WELEDA"},
{label: "DOLIDERMIL"},
{label: "DOLIDON"},
{label: "DOLIFEBRIL"},
{label: "DOLIFLORINE"},
{label: "DOLIPRANE"},
{label: "DOLIPRANE ADULTES"},
{label: "DOLIPRANE CODEINE"},
{label: "DOLIPRANECAPS"},
{label: "DOLIPRANELIQUIZ"},
{label: "DOLIPRANEORODOZ"},
{label: "DOLIPRANETABS"},
{label: "DOLIPRANEVITAMINEC"},
{label: "DOLIPRO"},
{label: "DOLIPRO ADULTES"},
{label: "DOLIRELAX"},
{label: "DOLIRHUME AUX HUILES ESSENTIELLES"},
{label: "DOLIRHUME PARACETAMOL ET PSEUDOEPHEDRINE"},
{label: "DOLIRHUME THIOPHENECARBOXYLATE"},
{label: "DOLIRHUMEPRO PARACETAMOL"},
{label: "DOLISEDAL"},
{label: "DOLISTAMINE"},
{label: "DOLITRAVEL"},
{label: "DOLKO"},
{label: "DOLKO ENFANTS"},
{label: "DOLKO NOURRISSONS"},
{label: "DOLOBIS"},
{label: "DOLODENT"},
{label: "DOLODERM"},
{label: "DOLOGYNE"},
{label: "DOLONAC"},
{label: "DOLOPAX"},
{label: "DOLOSAL"},
{label: "DOLOSOFT"},
{label: "DOLOSPASMYL"},
{label: "DOLOTEC"},
{label: "DOLPAX"},
{label: "DOLPYC"},
{label: "DOLSTIC"},
{label: "DOMAPEX ADULTES"},
{label: "DOMAPEX ENFANTS"},
{label: "DOMETA"},
{label: "DOMISTAN"},
{label: "DOMISTAN A LA VITAMINE F"},
{label: "DOMPERIDONE ACTAVIS"},
{label: "DOMPERIDONE ALMUS"},
{label: "DOMPERIDONE ALTER"},
{label: "DOMPERIDONE ARROW"},
{label: "DOMPERIDONE ATHENA PHARMACEUTIQUES"},
{label: "DOMPERIDONE AUROBINDO"},
{label: "DOMPERIDONE BIOGARAN"},
{label: "DOMPERIDONE CRISTERS"},
{label: "DOMPERIDONE DAKOTA PHARM"},
{label: "DOMPERIDONE EG"},
{label: "DOMPERIDONE GENODEX"},
{label: "DOMPERIDONE IVAX"},
{label: "DOMPERIDONE JANSSEN CILAG"},
{label: "DOMPERIDONE MYLAN"},
{label: "DOMPERIDONE PIERRE FABRE"},
{label: "DOMPERIDONE PIERRE FABRE MEDICAMENT"},
{label: "DOMPERIDONE QUALIMED"},
{label: "DOMPERIDONE RATIOPHARM"},
{label: "DOMPERIDONE RPG"},
{label: "DOMPERIDONE SANDOZ"},
{label: "DOMPERIDONE SG-PHARM"},
{label: "DOMPERIDONE SOLUCAL"},
{label: "DOMPERIDONE SUBSTIPHARM"},
{label: "DOMPERIDONE TEVA"},
{label: "DOMPERIDONE VIATRIS"},
{label: "DOMPERIDONE ZENTIVA"},
{label: "DOMPERIDONE ZYDUS"},
{label: "DONASERT"},
{label: "DONCEF"},
{label: "DONEPEZIL ACCORD"},
{label: "DONEPEZIL ACTAVIS"},
{label: "DONEPEZIL ALFRED E. TIEFENBACHER"},
{label: "DONEPEZIL ALTER"},
{label: "DONEPEZIL ARROW"},
{label: "DONEPEZIL ARROW GENERIQUES"},
{label: "DONEPEZIL AXOREL"},
{label: "DONEPEZIL BIOGARAN"},
{label: "DONEPEZIL BLUEFISH"},
{label: "DONEPEZIL CLEXNI"},
{label: "DONEPEZIL CRISTERS"},
{label: "DONEPEZIL CRISTERS10"},
{label: "DONEPEZIL EG"},
{label: "DONEPEZIL ETHYPHARM"},
{label: "DONEPEZIL EVOLUGEN"},
{label: "DONEPEZIL FARMALIDER"},
{label: "DONEPEZIL GALENIX"},
{label: "DONEPEZIL IPCA"},
{label: "DONEPEZIL KRKA"},
{label: "DONEPEZIL MEDIPHA"},
{label: "DONEPEZIL MEDIPHA SANTE"},
{label: "DONEPEZIL MYLAN"},
{label: "DONEPEZIL PFIZER"},
{label: "DONEPEZIL PHARMAKI GENERICS"},
{label: "DONEPEZIL QUALIMED"},
{label: "DONEPEZIL RANBAXY"},
{label: "DONEPEZIL RATIOPHARM"},
{label: "DONEPEZIL SANDOZ"},
{label: "DONEPEZIL SANOVEL"},
{label: "DONEPEZIL SET"},
{label: "DONEPEZIL SUBSTIPHARM"},
{label: "DONEPEZIL SYNTHON"},
{label: "DONEPEZIL TEVA"},
{label: "DONEPEZIL TEVA SANTE"},
{label: "DONEPEZIL TORRENT"},
{label: "DONEPEZIL VIATRIS"},
{label: "DONEPEZIL WIN MEDICA"},
{label: "DONEPEZIL ZENTIVA"},
{label: "DONEPEZIL ZYDUS"},
{label: "DONORMYL"},
{label: "DONTOPIVALONE"},
{label: "DOPACARD"},
{label: "DOPACEPTIN"},
{label: "DOPACIS"},
{label: "DOPAMINE"},
{label: "DOPAMINE AGUETTANT"},
{label: "DOPAMINE BOIRON"},
{label: "DOPAMINE MYLAN"},
{label: "DOPAMINE NATIVELLE"},
{label: "DOPAMINE PIERRE FABRE"},
{label: "DOPAMINE RENAUDIN"},
{label: "DOPAMINE VIATRIS"},
{label: "DOPAVIEW"},
{label: "DOPERGINE"},
{label: "DOPRAM"},
{label: "DOPS"},
{label: "DOPTELET"},
{label: "DORCALM"},
{label: "DORIBAX"},
{label: "DORMIAMED"},
{label: "DORMICALM"},
{label: "DORMIPLANT"},
{label: "DORMIREL"},
{label: "DORSILON"},
{label: "DORTIRUS"},
{label: "DORUBIPHA"},
{label: "DORZOCOLL"},
{label: "DORZOLAMIDE ALFRED E. TIEFENBACHER"},
{label: "DORZOLAMIDE ARROW"},
{label: "DORZOLAMIDE BGR"},
{label: "DORZOLAMIDE BIOGARAN"},
{label: "DORZOLAMIDE CHAUVIN"},
{label: "DORZOLAMIDE CHIBRET"},
{label: "DORZOLAMIDE CRISTERS"},
{label: "DORZOLAMIDE EG"},
{label: "DORZOLAMIDE FDC PHARMA"},
{label: "DORZOLAMIDE MICROLABS"},
{label: "DORZOLAMIDE RATIOPHARM"},
{label: "DORZOLAMIDE TEVA"},
{label: "DORZOLAMIDE TIMOLOL CHAUVIN"},
{label: "DORZOLAMIDE TIMOLOL NEITUM"},
{label: "DORZOLAMIDE VIATRIS"},
{label: "DORZOLAMIDE/TIMOLOL ARROW"},
{label: "DORZOLAMIDE/TIMOLOL BGR"},
{label: "DORZOLAMIDE/TIMOLOL BIOGARAN"},
{label: "DORZOLAMIDE/TIMOLOL CRISTERS"},
{label: "DORZOLAMIDE/TIMOLOL EG"},
{label: "DORZOLAMIDE/TIMOLOL FDC PHARMA"},
{label: "DORZOLAMIDE/TIMOLOL MICRO LABS"},
{label: "DORZOLAMIDE/TIMOLOL MICROLABS"},
{label: "DORZOLAMIDE/TIMOLOL POS"},
{label: "DORZOLAMIDE/TIMOLOL QUALIMED"},
{label: "DORZOLAMIDE/TIMOLOL SANDOZ"},
{label: "DORZOLAMIDE/TIMOLOL STULLN"},
{label: "DORZOLAMIDE/TIMOLOL TEVA"},
{label: "DORZOLAMIDE/TIMOLOL VIATRIS"},
{label: "DORZOLAMIDE/TIMOLOL ZENTIVA"},
{label: "DORZOSTILL"},
{label: "DOSISEPTINE"},
{label: "DOSISEPTINE COLOREE"},
{label: "DOSOXYGENEE"},
{label: "DOSSIFIL"},
{label: "DOSTINEX"},
{label: "DOSTRADIXINOL"},
{label: "DOTAGITA"},
{label: "DOTAREM"},
{label: "DOVATO"},
{label: "DOVPRELA"},
{label: "DOXAZOSINE ARROW LP"},
{label: "DOXAZOSINE BERAL"},
{label: "DOXAZOSINE PFIZER LP"},
{label: "DOXAZOSINE SUBSTIPHARM LP"},
{label: "DOXAZOSINE TEVA LP"},
{label: "DOXAZOSINE VIATRIS LP"},
{label: "DOXERGAN"},
{label: "DOXIUM"},
{label: "DOXORUBICINE ACCORD"},
{label: "DOXORUBICINE ARROW"},
{label: "DOXORUBICINE BAXTER"},
{label: "DOXORUBICINE CHLORHYDRATE PIERRE FABRE MEDICAMENT"},
{label: "DOXORUBICINE EBEWE"},
{label: "DOXORUBICINE EG"},
{label: "DOXORUBICINE G GAM"},
{label: "DOXORUBICINE INTSEL CHIMOS"},
{label: "DOXORUBICINE MEDIPHA SANTE"},
{label: "DOXORUBICINE MYPLIX"},
{label: "DOXORUBICINE QUALIFARMA"},
{label: "DOXORUBICINE TEVA"},
{label: "DOXORUBICINE WINTHROP"},
{label: "DOXY"},
{label: "DOXYCLINE PLANTIER"},
{label: "DOXYCYCLINE ARROW"},
{label: "DOXYCYCLINE BERAL"},
{label: "DOXYCYCLINE BIOGARAN"},
{label: "DOXYCYCLINE G GAM"},
{label: "DOXYCYCLINE MARIE-JOSE PLOTKINE"},
{label: "DOXYCYCLINE MYLAN PHARMA"},
{label: "DOXYCYCLINE PANPHARMA"},
{label: "DOXYCYCLINE PFIZER"},
{label: "DOXYCYCLINE PHARMACIE CENTRALE DES ARMEES"},
{label: "DOXYCYCLINE PROGRAPHARM"},
{label: "DOXYCYCLINE RATIOPHARM"},
{label: "DOXYCYCLINE SANDOZ"},
{label: "DOXYCYCLINE TEVA"},
{label: "DOXYCYCLINE VIATRIS"},
{label: "DOXYGRAM"},
{label: "DOXYLAMINE ARROW CONSEIL"},
{label: "DOXYLAMINE BIOGARAN CONSEIL"},
{label: "DOXYLAMINE CRISTERS"},
{label: "DOXYLAMINE EG LABO"},
{label: "DOXYLAMINE KRKA"},
{label: "DOXYLAMINE SANDOZ CONSEIL"},
{label: "DOXYLAMINE TEVA CONSEIL"},
{label: "DOXYLAMINE VIATRIS CONSEIL"},
{label: "DOXYLAMINE ZENTIVA CONSEIL"},
{label: "DOXYLETS"},
{label: "DOXYLIS"},
{label: "DOXYPALU"},
{label: "DOZURSO"},
{label: "DRAFORA"},
{label: "DRAGEE LAXATIVE PACHAUT"},
{label: "DRAGEES FUCA"},
{label: "DRAGEES VAUBAN"},
{label: "DRAGEES VEGETALES REX"},
{label: "DRAINACTIL"},
{label: "DRAINOBYL"},
{label: "DRAINOVUROL"},
{label: "DRAMAMINE"},
{label: "DRAXMIBI"},
{label: "DRILL"},
{label: "DRILL ALLERGIE CETIRIZINE"},
{label: "DRILL CITRON MENTHE"},
{label: "DRILL ENROUEMENT"},
{label: "DRILL ENROUEMENT SANS SUCRE"},
{label: "DRILL EXPECTORANT ADULTES"},
{label: "DRILL EXPECTORANT SANS SUCRE ADULTE"},
{label: "DRILL EXPECTORANT SANS SUCRE ADULTES"},
{label: "DRILL MAUX DE GORGE"},
{label: "DRILL MIEL CITRON SANS SUCRE"},
{label: "DRILL MIEL ROSAT"},
{label: "DRILL RHINITES"},
{label: "DRILL RHUME"},
{label: "DRILL RHUME PARACETAMOL CHLORPHENAMINE"},
{label: "DRILL SANS SUCRE"},
{label: "DRILL SANS SUCRE ANIS MENTHE"},
{label: "DRILL SANS SUCRE SUREAU LITCHI"},
{label: "DRILL TOUX SECHE ADULTES"},
{label: "DRILL TOUX SECHE DEXTROMETHORPHANE ADULTES"},
{label: "DRILL TOUX SECHE DEXTROMETHORPHANE ENFANTS"},
{label: "DRIPTANE"},
{label: "DRODELPITRABER"},
{label: "DROLEPTAN"},
{label: "DROPERIDOL AGUETTANT"},
{label: "DROPERIDOL ARROW"},
{label: "DROPERIDOL HIKMA"},
{label: "DROPERIDOL KALCEKS"},
{label: "DROPERIDOL PANPHARMA"},
{label: "DROPIZAL"},
{label: "DROSDOLA"},
{label: "DROSERA BOIRON"},
{label: "DROSERA COMPLEXE N°64"},
{label: "DROSERA LEHNING"},
{label: "DROSERA WELEDA"},
{label: "DROSETUX"},
{label: "DROSPIBEL"},
{label: "DROSPIL"},
{label: "DROSPIRENONE/ETHINYLESTRADIOL VIATRIS"},
{label: "DROSPIRENONE/ETHINYLESTRADIOL VIATRIS CONTINU"},
{label: "DROSPIRÉNONE/ÉTHINYLESTRADIOL MYLAN GENERIQUES CONTINU"},
{label: "DROSPIRÉNONE/ÉTHINYLESTRADIOL MYLAN PHARMA"},
{label: "DROVELIS"},
{label: "DRYGEN"},
{label: "DRYMIS WINTERI BOIRON"},
{label: "DRYOPTERIS FILIX-MAS"},
{label: "DRYTEC"},
{label: "DT POLIO MERIEUX"},
{label: "DTPASCINT."},
{label: "DTVAX"},
{label: "DUACT"},
{label: "DUAGENE"},
{label: "DUAKLIR GENUAIR"},
{label: "DUALAIR"},
{label: "DUALKOPT"},
{label: "DUAMENTIN"},
{label: "DUAVIVE"},
{label: "DUCASE"},
{label: "DUCHESS"},
{label: "DUGRESSA"},
{label: "DUKORAL"},
{label: "DULCAMARA BOIRON"},
{label: "DULCAMARA LEHNING"},
{label: "DULCAMARA WELEDA"},
{label: "DULCIBLEU"},
{label: "DULCICORTINE"},
{label: "DULCIDRINE"},
{label: "DULCILARMES"},
{label: "DULCIPHAK"},
{label: "DULCIVEN"},
{label: "DULCIVERA"},
{label: "DULCOLAX"},
{label: "DULOXETINE ACCORD"},
{label: "DULOXETINE ALMUS"},
{label: "DULOXETINE ALTER"},
{label: "DULOXETINE ARROW"},
{label: "DULOXETINE ASPHALION"},
{label: "DULOXETINE BIOGARAN"},
{label: "DULOXETINE CRISTERS"},
{label: "DULOXETINE EG"},
{label: "DULOXETINE EVOLUGEN"},
{label: "DULOXETINE HCS"},
{label: "DULOXETINE LESVI"},
{label: "DULOXETINE MYLAN"},
{label: "DULOXETINE PHARMAKI GENERICS"},
{label: "DULOXETINE SANDOZ"},
{label: "DULOXETINE SUBSTIPHARM"},
{label: "DULOXETINE TEVA"},
{label: "DULOXETINE TEVA SANTE"},
{label: "DULOXETINE TILLOMED"},
{label: "DULOXETINE VIATRIS"},
{label: "DULOXETINE ZENTIVA"},
{label: "DULOXETINE ZYDUS"},
{label: "DULTAVAX"},
{label: "DUMICOAT"},
{label: "DUODENUM BOIRON"},
{label: "DUODOPA"},
{label: "DUOFILM"},
{label: "DUOPLAVIN"},
{label: "DUORESP SPIROMAX"},
{label: "DUOTRAV"},
{label: "DUOVA"},
{label: "DUPHALAC"},
{label: "DUPHASTON"},
{label: "DUPIXENT"},
{label: "DURANEST"},
{label: "DURAPHAT"},
{label: "DUROGESIC"},
{label: "DUSPATALIN"},
{label: "DUTASTERIDE ACCORD"},
{label: "DUTASTERIDE ARROW"},
{label: "DUTASTERIDE ARROW LAB"},
{label: "DUTASTERIDE BIOGARAN 0.5"},
{label: "DUTASTERIDE BIPHAR"},
{label: "DUTASTERIDE CRISTERS"},
{label: "DUTASTERIDE EG"},
{label: "DUTASTERIDE EVOLUGEN"},
{label: "DUTASTERIDE GALENICUM HEALTH"},
{label: "DUTASTERIDE KRKA"},
{label: "DUTASTERIDE MYLAN GENERIQUES"},
{label: "DUTASTERIDE MYLAN PHARMA"},
{label: "DUTASTERIDE SANDOZ"},
{label: "DUTASTERIDE TEVA"},
{label: "DUTASTERIDE TEVA PHARMA"},
{label: "DUTASTERIDE TEVA SANTE"},
{label: "DUTASTERIDE VIATRIS"},
{label: "DUTASTERIDE ZENTIVA LAB"},
{label: "DUTASTERIDE/TAMSULOSINE ACCORD"},
{label: "DUTASTERIDE/TAMSULOSINE ARROW"},
{label: "DUTASTERIDE/TAMSULOSINE BIOGARAN"},
{label: "DUTASTERIDE/TAMSULOSINE EG"},
{label: "DUTASTERIDE/TAMSULOSINE SANDOZ"},
{label: "DUTASTERIDE/TAMSULOSINE TEVA"},
{label: "DUTASTERIDE/TAMSULOSINE VIATRIS"},
{label: "DUTASTERIDE/TAMSULOSINE ZENTIVA"},
{label: "DUTREBIS"},
{label: "DUVADILAN"},
{label: "DUXIL"},
{label: "DYMISTA"},
{label: "DYNABAC"},
{label: "DYNAMISAN"},
{label: "DYNASCORBINE"},
{label: "DYNASTAT"},
{label: "DYNAVITAL COBALT"},
{label: "DYNAVITAL CUIVRE"},
{label: "DYNAVITAL FLUOR"},
{label: "DYNAVITAL MAGNESIUM"},
{label: "DYNAVITAL MANGANESE"},
{label: "DYNAVITAL SOUFRE"},
{label: "DYNAVITAL ZINC"},
{label: "DYNEPO"},
{label: "DYNERVAL H.P."},
{label: "DYNEXAN"},
{label: "DYNEXANGIVAL"},
{label: "DYSALFA"},
{label: "DYSCRASITE WELEDA"},
{label: "DYSKINEBYL"},
{label: "DYSPAGON"},
{label: "DYSPNE INHAL"},
{label: "DYSPORT"},
{label: "DZUVEO"},
{label: "EAU OXYGENEE"},
{label: "EAU OXYGENEE GIFRER"},
{label: "EAU OXYGENEE STABILISEE CODEX"},
{label: "EAU POUR PREPARATION INJECTABLE BIOSEDRA"},
{label: "EAU POUR PREPARATIONS INJECTABLES AGUETTANT"},
{label: "EAU POUR PREPARATIONS INJECTABLES B BRAUN"},
{label: "EAU POUR PREPARATIONS INJECTABLES B. BRAUN"},
{label: "EAU POUR PREPARATIONS INJECTABLES BAXTER"},
{label: "EAU POUR PREPARATIONS INJECTABLES BECTON DICKINSON"},
{label: "EAU POUR PREPARATIONS INJECTABLES BIOLUZ"},
{label: "EAU POUR PREPARATIONS INJECTABLES BIOLUZ REUNION"},
{label: "EAU POUR PREPARATIONS INJECTABLES COOPER"},
{label: "EAU POUR PREPARATIONS INJECTABLES CSL BEHRING"},
{label: "EAU POUR PREPARATIONS INJECTABLES FRESENIUS"},
{label: "EAU POUR PREPARATIONS INJECTABLES GILBERT"},
{label: "EAU POUR PREPARATIONS INJECTABLES KABI"},
{label: "EAU POUR PREPARATIONS INJECTABLES LAVOISIER"},
{label: "EAU POUR PREPARATIONS INJECTABLES MACOSOL"},
{label: "EAU POUR PREPARATIONS INJECTABLES PROAMP"},
{label: "EAU POUR PREPARATIONS INJECTABLES RENAUDIN"},
{label: "EAU POUR PREPARATIONS INJECTABLES VIAFLO"},
{label: "EAU STERILE APYROGENE BAXTER"},
{label: "EBASTINE ALMIRALL"},
{label: "EBASTINE ALTER"},
{label: "EBASTINE ARROW"},
{label: "EBASTINE ARROW LAB"},
{label: "EBASTINE BIOGARAN"},
{label: "EBASTINE BROWN & BURK"},
{label: "EBASTINE CRISTERS"},
{label: "EBASTINE EG"},
{label: "EBASTINE INOPHARM"},
{label: "EBASTINE MEDIPHA"},
{label: "EBASTINE SANDOZ"},
{label: "EBASTINE TEVA"},
{label: "EBASTINE VIATRIS"},
{label: "EBASTINE ZENTIVA"},
{label: "EBASTINE ZYDUS"},
{label: "EBEOXAL"},
{label: "EBERTHINUM BOIRON"},
{label: "EBILFUMIN"},
{label: "EBIXA"},
{label: "EBONDE"},
{label: "EBONTAN"},
{label: "EBOUDA"},
{label: "EBVALLO"},
{label: "ECALTA"},
{label: "ECANSYA"},
{label: "ECAZIDE"},
{label: "ECBALLIUM ELATERIUM BOIRON"},
{label: "ECBIRIO"},
{label: "ECHINACEA ANGUSTIFOLIA BOIRON"},
{label: "ECHINACEA ANGUSTIFOLIA FERRIER"},
{label: "ECHINACEA ANGUSTIFOLIA LEHNING"},
{label: "ECHINACEA ANGUSTIFOLIA TEINTURE MERE BOIRON"},
{label: "ECHINACEA ANGUSTIFOLIA WELEDA"},
{label: "ECHINACEE POURPRE HUMEXPHYTO"},
{label: "ECHOVIST"},
{label: "ECLARAN"},
{label: "ECOBEC"},
{label: "ECOFLORE"},
{label: "ECONAZOLE ARROW"},
{label: "ECONAZOLE ARROW L.P."},
{label: "ECONAZOLE BIOGARAN"},
{label: "ECONAZOLE BIOGARAN L.P."},
{label: "ECONAZOLE CRISTERS L.P."},
{label: "ECONAZOLE EG"},
{label: "ECONAZOLE EG L.P."},
{label: "ECONAZOLE GENERES"},
{label: "ECONAZOLE JANSSEN"},
{label: "ECONAZOLE LABORATOIRE X.O"},
{label: "ECONAZOLE NITRATE JANSSEN"},
{label: "ECONAZOLE QUALIMED L.P."},
{label: "ECONAZOLE RATIOPHARM L.P."},
{label: "ECONAZOLE RPG"},
{label: "ECONAZOLE SANDOZ"},
{label: "ECONAZOLE SANDOZ L.P."},
{label: "ECONAZOLE TEVA"},
{label: "ECONAZOLE TEVA L.P."},
{label: "ECONAZOLE TEVA SANTE"},
{label: "ECONAZOLE VIATRIS"},
{label: "ECONAZOLE VIATRIS 1%"},
{label: "ECONAZOLE VIATRIS L.P."},
{label: "ECONAZOLE ZENTIVA LP"},
{label: "ECONAZOLE ZYDUS L.P."},
{label: "ECOVITAMINE B12"},
{label: "EDARBI"},
{label: "EDETATE DE CHROME"},
{label: "EDETATE DE CHROME [51"},
{label: "EDEX"},
{label: "EDICIS"},
{label: "EDICLONE"},
{label: "EDIREL"},
{label: "EDISTON"},
{label: "EDLUAR"},
{label: "EDOFLO"},
{label: "EDUCTYL ADULTES"},
{label: "EDUCTYL ENFANTS"},
{label: "EDURANT"},
{label: "EFALITH"},
{label: "EFAVIRENZ ARROW"},
{label: "EFAVIRENZ CRISTERS"},
{label: "EFAVIRENZ DEXTREG"},
{label: "EFAVIRENZ HEXAL"},
{label: "EFAVIRENZ SANDOZ"},
{label: "EFAVIRENZ TEVA"},
{label: "EFAVIRENZ VIATRIS"},
{label: "EFAVIRENZ ZENTIVA"},
{label: "EFAVIRENZ/EMTRICITABINE/TENOFOVIR DISOPROXIL ACCORD"},
{label: "EFAVIRENZ/EMTRICITABINE/TENOFOVIR DISOPROXIL ARROW"},
{label: "EFAVIRENZ/EMTRICITABINE/TENOFOVIR DISOPROXIL BIOGARAN"},
{label: "EFAVIRENZ/EMTRICITABINE/TENOFOVIR DISOPROXIL EG"},
{label: "EFAVIRENZ/EMTRICITABINE/TENOFOVIR DISOPROXIL KRKA"},
{label: "EFAVIRENZ/EMTRICITABINE/TENOFOVIR DISOPROXIL MYLAN"},
{label: "EFAVIRENZ/EMTRICITABINE/TENOFOVIR DISOPROXIL PHAROS"},
{label: "EFAVIRENZ/EMTRICITABINE/TENOFOVIR DISOPROXIL SANDOZ"},
{label: "EFAVIRENZ/EMTRICITABINE/TENOFOVIR DISOPROXIL TEVA"},
{label: "EFAVIRENZ/EMTRICITABINE/TENOFOVIR DISOPROXIL ZENTIVA"},
{label: "EFDEGE"},
{label: "EFFACNE"},
{label: "EFFALA"},
{label: "EFFEDERM"},
{label: "EFFENTORA"},
{label: "EFFER K"},
{label: "EFFERALGAN"},
{label: "EFFERALGAN FRAISE"},
{label: "EFFERALGAN FRUITS ROUGES"},
{label: "EFFERALGAN PAMPLEMOUSSE"},
{label: "EFFERALGAN VITAMINE C"},
{label: "EFFERALGANISO"},
{label: "EFFERALGANMED"},
{label: "EFFERALGANMED PEDIATRIQUE"},
{label: "EFFERDOL"},
{label: "EFFERVITAL"},
{label: "EFFEXOR"},
{label: "EFFEXOR L.P."},
{label: "EFFICORT HYDROPHILE"},
{label: "EFFICORT LIPOPHILE"},
{label: "EFFIDOSE ARTICHAUT"},
{label: "EFFIDOSE AUBEPINE"},
{label: "EFFIDOSE BARDANE"},
{label: "EFFIDOSE CASSIS"},
{label: "EFFIDOSE FUCUS"},
{label: "EFFIDOSE MARRON D'INDE"},
{label: "EFFIDOSE MELILOT"},
{label: "EFFIDOSE MELISSE"},
{label: "EFFIDOSE ORTIE"},
{label: "EFFIDOSE PASSIFLORE"},
{label: "EFFIDOSE PISSENLIT"},
{label: "EFFIDOSE PRELE"},
{label: "EFFIDOSE REINE DES PRES"},
{label: "EFFIDOSE VALERIANE"},
{label: "EFFILEVO"},
{label: "EFFILEVOCONTINU"},
{label: "EFFITARYL"},
{label: "EFFIZINC"},
{label: "EFFORTIL"},
{label: "EFICAL"},
{label: "EFICAL VITAMINE D3"},
{label: "EFIENT"},
{label: "EFIMAG"},
{label: "EFLUELDA"},
{label: "EFMODY"},
{label: "EFRACEA"},
{label: "EFRYL ETAT GRIPPAL"},
{label: "EFRYL RHUME"},
{label: "EFUDIX"},
{label: "EGATEN"},
{label: "EGERY"},
{label: "EGOTONYL ADULTES"},
{label: "EGOTONYL ENFANTS"},
{label: "EKLIRA GENUAIR"},
{label: "EKTOGAN"},
{label: "ELADOXY"},
{label: "ELANVITE C"},
{label: "ELAPRASE"},
{label: "ELAPS CORALLINUS BOIRON"},
{label: "ELASE"},
{label: "ELASTOCAPSIL"},
{label: "ELASTOTHERM"},
{label: "ELAVIL"},
{label: "ELBFAXIN LP"},
{label: "ELDISINE"},
{label: "ELEBRATO ELLIPTA"},
{label: "ELENAT"},
{label: "ELEPREP"},
{label: "ELETRIPTAN ARROW"},
{label: "ELETRIPTAN BGR"},
{label: "ELETRIPTAN BIOGARAN"},
{label: "ELETRIPTAN EG"},
{label: "ELETRIPTAN MEDIPHA"},
{label: "ELETRIPTAN NOR"},
{label: "ELETRIPTAN PFIZER"},
{label: "ELETRIPTAN PHARMCONSUL"},
{label: "ELETRIPTAN QUIVER"},
{label: "ELETRIPTAN REF"},
{label: "ELETRIPTAN SET"},
{label: "ELETRIPTAN VIATRIS"},
{label: "ELETRIPTAN ZENTIVA"},
{label: "ELETRIPTAN ZYDUS"},
{label: "ELEVIT VITAMINE B9"},
{label: "ELGYDIUM"},
{label: "ELIDEL"},
{label: "ELIGARD"},
{label: "ELINDRA"},
{label: "ELIQUIS"},
{label: "ELISOR"},
{label: "ELIXIR BONJEAN"},
{label: "ELIXIR DE BOULEAU WELEDA"},
{label: "ELIXIR DE TERPINE A"},
{label: "ELIXIR GREZ"},
{label: "ELIXIR PAREGORIQUE GIFRER A"},
{label: "ELIXIR PAREGORIQUE LIPHA"},
{label: "ELIXIR SPARK"},
{label: "ELLAONE"},
{label: "ELLEACNELLE"},
{label: "ELLEOGESTE"},
{label: "ELLURA"},
{label: "ELMIRON"},
{label: "ELOCOME"},
{label: "ELOCTA"},
{label: "ELOHES"},
{label: "ELONVA"},
{label: "ELOSALIC"},
{label: "ELOVENT TWISTHALER"},
{label: "ELOXATINE"},
{label: "ELSEP"},
{label: "ELSTABYA"},
{label: "ELUDRIL"},
{label: "ELUDRILPERIO"},
{label: "ELUDRILPRO"},
{label: "ELUMATIC III 2-20"},
{label: "ELUMIBUS"},
{label: "ELUMOTA"},
{label: "ELUSANES AFFINEX"},
{label: "ELUSANES AIL"},
{label: "ELUSANES ALGUES BRUNES ALOES"},
{label: "ELUSANES ARTICHAUT"},
{label: "ELUSANES AUBEPINE"},
{label: "ELUSANES BARDANE"},
{label: "ELUSANES BOLDO"},
{label: "ELUSANES BUSSEROLE"},
{label: "ELUSANES CASSIS"},
{label: "ELUSANES CHARBON VEGETAL"},
{label: "ELUSANES ESCHSCHOLTZIA"},
{label: "ELUSANES FENUGREC"},
{label: "ELUSANES FRAGON"},
{label: "ELUSANES FRENE"},
{label: "ELUSANES FUCUS"},
{label: "ELUSANES FUMETERRE"},
{label: "ELUSANES GATTILIER"},
{label: "ELUSANES GINSENG"},
{label: "ELUSANES GRANDE CAMOMILLE"},
{label: "ELUSANES HAMAMELIS"},
{label: "ELUSANES HARPAGOPHYTON"},
{label: "ELUSANES KAOLIN"},
{label: "ELUSANES KOLA"},
{label: "ELUSANES LITHIABYL"},
{label: "ELUSANES MAIS"},
{label: "ELUSANES MARRONNIER D'INDE"},
{label: "ELUSANES MARRUBE BLANC"},
{label: "ELUSANES MATE"},
{label: "ELUSANES MELILOT"},
{label: "ELUSANES MILLEPERTUIS"},
{label: "ELUSANES NATUDOR"},
{label: "ELUSANES ORTHOSIPHON"},
{label: "ELUSANES ORTIE"},
{label: "ELUSANES ORTIE RACINE"},
{label: "ELUSANES PASSIFLORE"},
{label: "ELUSANES PENSEE SAUVAGE"},
{label: "ELUSANES PHYTOFIBRE"},
{label: "ELUSANES PILOSELLE"},
{label: "ELUSANES PRELE DES CHAMPS"},
{label: "ELUSANES QUINQUINA"},
{label: "ELUSANES REINE DES PRES"},
{label: "ELUSANES SALICAIRE"},
{label: "ELUSANES SAULE"},
{label: "ELUSANES STARPHYT"},
{label: "ELUSANES THE VERT"},
{label: "ELUSANES VALERIANE"},
{label: "ELUSANES VIGNE ROUGE"},
{label: "ELUTEC"},
{label: "ELVORINE"},
{label: "ELYZOL"},
{label: "ELZONRIS"},
{label: "EMADINE"},
{label: "EMAPANT"},
{label: "EMEND"},
{label: "EMERADE"},
{label: "EMERJENKIR"},
{label: "EMGALITY"},
{label: "EMGE LUMIERE"},
{label: "EMLA"},
{label: "EMLAPATCH"},
{label: "EMMAGEL"},
{label: "EMMARAM"},
{label: "EMPLICITI"},
{label: "EMSELEX"},
{label: "EMTRICITABINE TENOFOVIR DISOPROXIL MYLAN"},
{label: "EMTRICITABINE/TENOFOVIR DISOPROXIL ACCORD"},
{label: "EMTRICITABINE/TENOFOVIR DISOPROXIL AMNEAL"},
{label: "EMTRICITABINE/TENOFOVIR DISOPROXIL ARROW"},
{label: "EMTRICITABINE/TENOFOVIR DISOPROXIL BIOGARAN"},
{label: "EMTRICITABINE/TENOFOVIR DISOPROXIL CIPLA"},
{label: "EMTRICITABINE/TENOFOVIR DISOPROXIL DEXTREG"},
{label: "EMTRICITABINE/TENOFOVIR DISOPROXIL EG"},
{label: "EMTRICITABINE/TENOFOVIR DISOPROXIL KRKA"},
{label: "EMTRICITABINE/TENOFOVIR DISOPROXIL KRKA D.D."},
{label: "EMTRICITABINE/TENOFOVIR DISOPROXIL SANDOZ"},
{label: "EMTRICITABINE/TENOFOVIR DISOPROXIL TEVA"},
{label: "EMTRICITABINE/TENOFOVIR DISOPROXIL VENIPHARM"},
{label: "EMTRICITABINE/TENOFOVIR DISOPROXIL ZENTIVA"},
{label: "EMTRICITABINE/TENOFOVIR DISOPROXIL ZENTIVA LAB"},
{label: "EMTRICITABINE/TENOFOVIR DISOPROXIL ZYDUS"},
{label: "EMTRIVA"},
{label: "EMUDAR ENFANTS ET NOURRISSONS"},
{label: "EMYLIF"},
{label: "ENALAPRIL ACCORD"},
{label: "ENALAPRIL ACTAVIS"},
{label: "ENALAPRIL ARROW"},
{label: "ENALAPRIL ARROW LAB"},
{label: "ENALAPRIL BIOGARAN"},
{label: "ENALAPRIL BIOSTABILEX"},
{label: "ENALAPRIL CHIBRET"},
{label: "ENALAPRIL CRISTERS"},
{label: "ENALAPRIL DCI PHARMA"},
{label: "ENALAPRIL EG"},
{label: "ENALAPRIL EVOLUGEN"},
{label: "ENALAPRIL MSD"},
{label: "ENALAPRIL MYLAN"},
{label: "ENALAPRIL NOR"},
{label: "ENALAPRIL PHR LAB"},
{label: "ENALAPRIL QUALIMED"},
{label: "ENALAPRIL RANBAXY"},
{label: "ENALAPRIL RATIOPHARM"},
{label: "ENALAPRIL RPG"},
{label: "ENALAPRIL SANDOZ"},
{label: "ENALAPRIL TEVA"},
{label: "ENALAPRIL TEVA SANTE"},
{label: "ENALAPRIL VIATRIS"},
{label: "ENALAPRIL ZENTIVA"},
{label: "ENALAPRIL ZYDUS"},
{label: "ENALAPRIL/HYDROCHLOROTHIAZIDE ARROW"},
{label: "ENALAPRIL/HYDROCHLOROTHIAZIDE ARROW LAB"},
{label: "ENALAPRIL/HYDROCHLOROTHIAZIDE BIOGARAN"},
{label: "ENALAPRIL/HYDROCHLOROTHIAZIDE BIOSTABILEX"},
{label: "ENALAPRIL/HYDROCHLOROTHIAZIDE CRISTERS"},
{label: "ENALAPRIL/HYDROCHLOROTHIAZIDE EG"},
{label: "ENALAPRIL/HYDROCHLOROTHIAZIDE G GAM"},
{label: "ENALAPRIL/HYDROCHLOROTHIAZIDE MYLAN PHARMA"},
{label: "ENALAPRIL/HYDROCHLOROTHIAZIDE PHR LAB"},
{label: "ENALAPRIL/HYDROCHLOROTHIAZIDE QUALIMED"},
{label: "ENALAPRIL/HYDROCHLOROTHIAZIDE RATIOPHARM"},
{label: "ENALAPRIL/HYDROCHLOROTHIAZIDE RPG"},
{label: "ENALAPRIL/HYDROCHLOROTHIAZIDE SANDOZ"},
{label: "ENALAPRIL/HYDROCHLOROTHIAZIDE TEVA"},
{label: "ENALAPRIL/HYDROCHLOROTHIAZIDE VENIPHARM"},
{label: "ENALAPRIL/HYDROCHLOROTHIAZIDE VIATRIS"},
{label: "ENALAPRIL/HYDROCHLOROTHIAZIDE ZENTIVA"},
{label: "ENALAPRIL/LERCANIDIPINE ARROW"},
{label: "ENALAPRIL/LERCANIDIPINE BIOGARAN"},
{label: "ENALAPRIL/LERCANIDIPINE BOUCHARA-RECORDATI"},
{label: "ENALAPRIL/LERCANIDIPINE CRISTERS"},
{label: "ENALAPRIL/LERCANIDIPINE EG"},
{label: "ENALAPRIL/LERCANIDIPINE KRKA"},
{label: "ENALAPRIL/LERCANIDIPINE MICROLABS"},
{label: "ENALAPRIL/LERCANIDIPINE SANDOZ"},
{label: "ENALAPRIL/LERCANIDIPINE TEVA"},
{label: "ENALAPRIL/LERCANIDIPINE VENIPHARM"},
{label: "ENALAPRIL/LERCANIDIPINE VIATRIS"},
{label: "ENALAPRIL/LERCANIDIPINE ZENTIVA"},
{label: "ENALASCOPE"},
{label: "ENALAVIS"},
{label: "ENALSAN"},
{label: "ENALURETIC"},
{label: "ENANTONE LP"},
{label: "ENANTYUM"},
{label: "ENBREL"},
{label: "ENCEOL L.P."},
{label: "ENCEPHABOL"},
{label: "ENCEPUR"},
{label: "ENDHOMETROL"},
{label: "ENDIUM"},
{label: "ENDOBULINE"},
{label: "ENDOCALM"},
{label: "ENDOLIPIDE"},
{label: "ENDOLUCINBETA"},
{label: "ENDOPANCRINE"},
{label: "ENDOPANCRINE PROTAMINE CRISTALLISEE NPH"},
{label: "ENDOPANCRINE ZINC PROTAMINE"},
{label: "ENDOREM"},
{label: "ENDOTELON"},
{label: "ENDOTELON JAMBES LOURDES"},
{label: "ENDOTINE"},
{label: "ENDOVELA"},
{label: "ENDOXAN"},
{label: "ENERDY"},
{label: "ENERGITUM"},
{label: "ENERZAIR BREEZHALER"},
{label: "ENFLURANE MEDEVA EUROPE"},
{label: "ENGERIX B"},
{label: "ENHERTU"},
{label: "ENIFBERT"},
{label: "ENJAYMO"},
{label: "ENO SEL DE FRUIT"},
{label: "ENOSITO"},
{label: "ENOXACINE PHARMEXOR"},
{label: "ENOXAPARINE ARROW"},
{label: "ENOXAPARINE BECAT"},
{label: "ENOXAPARINE BIOGARAN"},
{label: "ENOXAPARINE CRUSIA"},
{label: "ENOXAPARINE LEDRAXEN"},
{label: "ENOXAPARINE TEVA"},
{label: "ENOXOR"},
{label: "ENSPRYNG"},
{label: "ENSTILAR"},
{label: "ENTACAPONE ARROW"},
{label: "ENTACAPONE EG"},
{label: "ENTACAPONE VIATRIS"},
{label: "ENTALGINE"},
{label: "ENTECAVIR ACCORD"},
{label: "ENTECAVIR ARROW"},
{label: "ENTECAVIR BIOGARAN"},
{label: "ENTECAVIR CRISTERS"},
{label: "ENTECAVIR EG"},
{label: "ENTECAVIR EVOLUGEN"},
{label: "ENTECAVIR HCS"},
{label: "ENTECAVIR MYLAN"},
{label: "ENTECAVIR PHARMATHEN"},
{label: "ENTECAVIR REDDY PHARMA"},
{label: "ENTECAVIR SANDOZ"},
{label: "ENTECAVIR TEVA"},
{label: "ENTECAVIR ZENTIVA K.S."},
{label: "ENTECAVIR ZYDUS"},
{label: "ENTECET"},
{label: "ENTEROCALM"},
{label: "ENTEROCOCCINUM BOIRON"},
{label: "ENTEROPATHYL"},
{label: "ENTOCORT"},
{label: "ENTONOX"},
{label: "ENTRESTO"},
{label: "ENTYVIO"},
{label: "ENVARSUS"},
{label: "ENYGLID"},
{label: "ENZYMICINE"},
{label: "EOSINAL"},
{label: "EOSINE AQUEUSE"},
{label: "EOSINE AQUEUSE A"},
{label: "EOSINE AQUEUSE BIOSEDRA"},
{label: "EOSINE AQUEUSE COOPER"},
{label: "EOSINE AQUEUSE GIFRER ET BARBEZAT A"},
{label: "EOSINE AQUEUSE NEITUM"},
{label: "EOSINE AQUEUSE STERILE A"},
{label: "EOSINE AQUEUSE STERILE TEVA"},
{label: "EOSINE COOPER"},
{label: "EOSINE FAURE A"},
{label: "EPAXAL"},
{label: "EPCLUSA"},
{label: "EPERZAN"},
{label: "EPHEDRA VULGARIS BOIRON"},
{label: "EPHEDRAFEINE"},
{label: "EPHEDRINE AGUETTANT"},
{label: "EPHEDRINE ARROW"},
{label: "EPHEDRINE RENAUDIN"},
{label: "EPHEDROIDES"},
{label: "EPHEDROMEL"},
{label: "EPHEIODINE"},
{label: "EPHELIA"},
{label: "EPHENAZYL"},
{label: "EPHYDION"},
{label: "EPHYDROL"},
{label: "EPHYNAL"},
{label: "EPIBACTIS"},
{label: "EPIDERMINE"},
{label: "EPIDUO"},
{label: "EPIDYOLEX"},
{label: "EPINITRIL"},
{label: "EPIPEN"},
{label: "EPIPHANE"},
{label: "EPIPHYSE BOIRON"},
{label: "EPIPHYSE WELEDA"},
{label: "EPIRUBICINE ACCORD"},
{label: "EPIRUBICINE ACTAVIS"},
{label: "EPIRUBICINE ALTISO"},
{label: "EPIRUBICINE ARROW"},
{label: "EPIRUBICINE CHEMICAL FARMA"},
{label: "EPIRUBICINE EBEWE"},
{label: "EPIRUBICINE EG"},
{label: "EPIRUBICINE GENEPHARM"},
{label: "EPIRUBICINE HIKMA"},
{label: "EPIRUBICINE HOSPIRA"},
{label: "EPIRUBICINE INTSEL CHIMOS"},
{label: "EPIRUBICINE KABI"},
{label: "EPIRUBICINE MEDAC"},
{label: "EPIRUBICINE MEDIPHA SANTE"},
{label: "EPIRUBICINE MYLAN PHARMA"},
{label: "EPIRUBICINE MYPLIX"},
{label: "EPIRUBICINE PANMEDICA"},
{label: "EPIRUBICINE PANPHARMA"},
{label: "EPIRUBICINE RATIOPHARM"},
{label: "EPIRUBICINE TEVA"},
{label: "EPIRUBICINE TEVA CLASSICS"},
{label: "EPIRUBICINE VIATRIS"},
{label: "EPIRUBICINE WINTHROP"},
{label: "EPITHEA"},
{label: "EPITOMAX"},
{label: "EPITOPIC"},
{label: "EPIVIR"},
{label: "EPLERATHEN"},
{label: "EPLERENONE ACCORD"},
{label: "EPLERENONE ARROW"},
{label: "EPLERENONE BIOGARAN"},
{label: "EPLERENONE CRISTERS"},
{label: "EPLERENONE EG"},
{label: "EPLERENONE KRKA"},
{label: "EPLERENONE SANDOZ"},
{label: "EPLERENONE TEVA"},
{label: "EPLERENONE VIATRIS"},
{label: "EPLERENONE VIATRIS GENERIQUES"},
{label: "EPLERENONE ZENTIVA"},
{label: "EPLERENONE ZYDUS"},
{label: "EPOPROSTENOL ARROW"},
{label: "EPOPROSTENOL INTSEL CHIMOS"},
{label: "EPOPROSTENOL PANPHARMA"},
{label: "EPOPROSTENOL SANDOZ"},
{label: "EPORATIO"},
{label: "EPOSIN"},
{label: "EPPY"},
{label: "EPREX"},
{label: "EPTAVIT"},
{label: "EPTIFIBATIDE ACCORD"},
{label: "EPURAM"},
{label: "EQUANIL"},
{label: "EQUILIUM"},
{label: "EQUISETUM ARVENSE"},
{label: "EQUISETUM ARVENSE BOIRON"},
{label: "EQUISETUM HIEMALE BOIRON"},
{label: "EQUISETUM HIEMALE LEHNING"},
{label: "EQWILATE"},
{label: "ERACINE"},
{label: "ERAPYL"},
{label: "ERAZABAN"},
{label: "ERBITUX"},
{label: "ERCEFURYL"},
{label: "ERCEVIT"},
{label: "ERELZI"},
{label: "ERGAMISOLE"},
{label: "ERGIX"},
{label: "ERGIX ADULTES TOUX SECHE"},
{label: "ERGIX ENFANTS TOUX SECHE"},
{label: "ERGIX EXPECTORANT ADULTES"},
{label: "ERGIX MAL DE GORGE"},
{label: "ERGIX RHUME"},
{label: "ERGODOSE"},
{label: "ERIDAN"},
{label: "ERIGERON CANADENSIS BOIRON"},
{label: "ERIGERON CANADENSIS LEHNING"},
{label: "ERIODICTYON CALIFORNICUM BOIRON"},
{label: "ERITRETIN"},
{label: "ERITROTEC."},
{label: "ERIVEDGE"},
{label: "ERLEADA"},
{label: "ERLOTINIB BIOGARAN"},
{label: "ERLOTINIB EG"},
{label: "ERLOTINIB KOANAA"},
{label: "ERLOTINIB KRKA"},
{label: "ERLOTINIB SANDOZ"},
{label: "ERLOTINIB TEVA"},
{label: "ERLOTINIB TEVA FRANCE"},
{label: "ERLOTINIB TEVA SANTE"},
{label: "ERLOTINIB VIATRIS"},
{label: "ERLOTINIB ZENTIVA"},
{label: "ERTAPENEM ACS DOBFAR"},
{label: "ERTAPENEM ARROW"},
{label: "ERTAPENEM FRESENIUS KABI"},
{label: "ERTAPENEM HIKMA"},
{label: "ERTAPENEM PANPHARMA"},
{label: "ERTAPENEM SUN"},
{label: "ERVEBO"},
{label: "ERWINASE"},
{label: "ERY"},
{label: "ERY ENFANTS"},
{label: "ERY NOURRISSONS"},
{label: "ERYACNE"},
{label: "ERYASE"},
{label: "ERYCOCCI"},
{label: "ERYFLUID"},
{label: "ERYLIK"},
{label: "ERYNGIUM AQUATICUM BOIRON"},
{label: "ERYNGIUM CAMPESTRE BOIRON"},
{label: "ERYSIMUM OFFICINALE BOIRON"},
{label: "ERYSIMUM PIERRE FABRE MEDICAMENT"},
{label: "ERYSOIN"},
{label: "ERYTHEDERMYL"},
{label: "ERYTHROCINE"},
{label: "ERYTHROGEL"},
{label: "ERYTHROGRAM"},
{label: "ERYTHROMYCINE BAILLEUL"},
{label: "ERYTHROMYCINE BIORGA"},
{label: "ERYTHROMYCINE DAKOTA PHARM"},
{label: "ERYTHROMYCINE MYLAN"},
{label: "ERYTHROMYCINE PANPHARMA"},
{label: "ERYTHROMYCINE QUALIMED"},
{label: "ERYTHROMYCINE STRAGEN"},
{label: "ERYTHROTON"},
{label: "ESBERIVEN"},
{label: "ESBERIVEN FORT"},
{label: "ESBRIET"},
{label: "ESCAPELLE"},
{label: "ESCHSCHOLTZIA CALIFORNICA BOIRON"},
{label: "ESCHSCHOLTZIA CALIFORNICA FERRIER"},
{label: "ESCHSCHOLTZIA CALIFORNICA LEHNING"},
{label: "ESCHSCHOLTZIA CALIFORNICA WELEDA"},
{label: "ESCINOGEL"},
{label: "ESCITALOPRAM ALMUS"},
{label: "ESCITALOPRAM APS ALSTER PHARMA SERVICE"},
{label: "ESCITALOPRAM ARROW"},
{label: "ESCITALOPRAM ARROW LAB"},
{label: "ESCITALOPRAM AUTHOU"},
{label: "ESCITALOPRAM BIOGARAN"},
{label: "ESCITALOPRAM BIPHAR"},
{label: "ESCITALOPRAM CRISTERS"},
{label: "ESCITALOPRAM CRISTERS PHARMA"},
{label: "ESCITALOPRAM EG"},
{label: "ESCITALOPRAM EVOLUGEN"},
{label: "ESCITALOPRAM EVOLUGEN PHARMA"},
{label: "ESCITALOPRAM HEC PHARM"},
{label: "ESCITALOPRAM KRKA"},
{label: "ESCITALOPRAM MYLAN GENERIQUES"},
{label: "ESCITALOPRAM MYPLIX"},
{label: "ESCITALOPRAM PHARMATHEN"},
{label: "ESCITALOPRAM RATIOPHARM"},
{label: "ESCITALOPRAM SANDOZ"},
{label: "ESCITALOPRAM SET"},
{label: "ESCITALOPRAM SUN"},
{label: "ESCITALOPRAM TEVA"},
{label: "ESCITALOPRAM VIATRIS"},
{label: "ESCITALOPRAM ZENTIVA"},
{label: "ESCITALOPRAM ZENTIVA LAB"},
{label: "ESCITALOPRAM ZYDUS"},
{label: "ESECARMINE"},
{label: "ESGEBERTRATHINO"},
{label: "ESIDREX"},
{label: "ESKAZOLE"},
{label: "ESKERIELE"},
{label: "ESKESIA"},
{label: "ESKETAMINE EUROCEPT"},
{label: "ESKETAMINE PFIZER"},
{label: "ESKETAMINE RENAUDIN"},
{label: "ESLICARBAZEPINE ACETATE ACCORD"},
{label: "ESLICARBAZEPINE ACETATE MYLAN"},
{label: "ESLICARBAZEPINE ARROW"},
{label: "ESLICARBAZEPINE DZ"},
{label: "ESLICARBAZEPINE MEDITOP"},
{label: "ESMERON"},
{label: "ESMOCARD"},
{label: "ESMOLOL CHLORHYDRATE NORIDEM"},
{label: "ESMYA"},
{label: "ESOCIFAR"},
{label: "ESOMEPRAZOLE ACCORD"},
{label: "ESOMEPRAZOLE ACTAVIS"},
{label: "ESOMEPRAZOLE ALMUS"},
{label: "ESOMEPRAZOLE ALTER"},
{label: "ESOMEPRAZOLE ALTISO"},
{label: "ESOMEPRAZOLE ARROW"},
{label: "ESOMEPRAZOLE ARROW LAB"},
{label: "ESOMEPRAZOLE ASTRAZENECA"},
{label: "ESOMEPRAZOLE BIOGARAN"},
{label: "ESOMEPRAZOLE BIOGARAN CONSEIL"},
{label: "ESOMEPRAZOLE CIPLA"},
{label: "ESOMEPRAZOLE CRISTERS"},
{label: "ESOMEPRAZOLE CRISTERS PHARMA"},
{label: "ESOMEPRAZOLE EG"},
{label: "ESOMEPRAZOLE EG LABO"},
{label: "ESOMEPRAZOLE ETHYPHARM"},
{label: "ESOMEPRAZOLE EVOLUGEN"},
{label: "ESOMEPRAZOLE EVOLUGEN PHARMA"},
{label: "ESOMEPRAZOLE GNR"},
{label: "ESOMEPRAZOLE HCS"},
{label: "ESOMEPRAZOLE HETERO EUROPE"},
{label: "ESOMEPRAZOLE IBD3 PHARMA CONSULTING"},
{label: "ESOMEPRAZOLE LABORATOIRE X.O"},
{label: "ESOMEPRAZOLE MEDIPHA"},
{label: "ESOMEPRAZOLE MYLAN"},
{label: "ESOMEPRAZOLE MYLAN PHARMA"},
{label: "ESOMEPRAZOLE MYPLIX"},
{label: "ESOMEPRAZOLE NEWLINE PHARMA"},
{label: "ESOMEPRAZOLE PHARMATHEN"},
{label: "ESOMEPRAZOLE PHR"},
{label: "ESOMEPRAZOLE QUALIFARMA"},
{label: "ESOMEPRAZOLE QUALIMED"},
{label: "ESOMEPRAZOLE RATIOPHARM"},
{label: "ESOMEPRAZOLE REF"},
{label: "ESOMEPRAZOLE RENANTOS"},
{label: "ESOMEPRAZOLE RPG"},
{label: "ESOMEPRAZOLE SANDOZ"},
{label: "ESOMEPRAZOLE SUN"},
{label: "ESOMEPRAZOLE SUN PHARMA"},
{label: "ESOMEPRAZOLE TEVA"},
{label: "ESOMEPRAZOLE TEVA PHARMA"},
{label: "ESOMEPRAZOLE TEVA SANTE"},
{label: "ESOMEPRAZOLE TILLOMED"},
{label: "ESOMEPRAZOLE TORRENT PHARMA"},
{label: "ESOMEPRAZOLE VIATRIS"},
{label: "ESOMEPRAZOLE VIATRIS CONSEIL"},
{label: "ESOMEPRAZOLE ZENTIVA"},
{label: "ESOMEPRAZOLE ZYDUS"},
{label: "ESOMETORR"},
{label: "ESOZ"},
{label: "ESPERAL"},
{label: "ESPEROCT"},
{label: "ESPIZENE CONTINU"},
{label: "ESSENCE ALGERIENNE"},
{label: "ESTALIS"},
{label: "ESTERASINE"},
{label: "ESTERS ETHYLIQUES D'ACIDES OMEGA"},
{label: "ESTERS ETHYLIQUES D'ACIDES OMEGA-3 IBSA"},
{label: "ESTERS ETHYLIQUES D'ACIDES OMEGA-3 LOMEGAR"},
{label: "ESTERS ETHYLIQUES D'ACIDES OMEGA-3 LYPIAD"},
{label: "ESTERS ETHYLIQUES D'ACIDES OMEGA-3 TEVA"},
{label: "ESTERS ETHYLIQUES D'ACIDES OMEGA-3 VEGARDIN"},
{label: "ESTERS ETHYLIQUES DACIDES OMEGA-3 STRIDES"},
{label: "ESTIMA"},
{label: "ESTOMAC BOIRON"},
{label: "ESTOMAC WELEDA"},
{label: "ESTRACYT"},
{label: "ESTRADERM TTS"},
{label: "ESTRADIOL BESINS"},
{label: "ESTRADIOL EXELTIS"},
{label: "ESTRADIOL G GAM"},
{label: "ESTRADIOL JANSSEN"},
{label: "ESTRADIOL MERCK"},
{label: "ESTRADIOL NORETHISTERONE NOVARTIS"},
{label: "ESTRADIOL NOVARTIS"},
{label: "ESTRADIOL PIERRE FABRE SANTE"},
{label: "ESTRADIOL RPG"},
{label: "ESTRADIOL SANDOZ"},
{label: "ESTRADIOL TTS SANDOZ"},
{label: "ESTRADIOL VALERATE IPRAD"},
{label: "ESTRAHEXAL"},
{label: "ESTRAMAT"},
{label: "ESTRAPATCH"},
{label: "ESTREVA"},
{label: "ESTRING"},
{label: "ESTRIOL JANSSEN LP"},
{label: "ESTROFEM"},
{label: "ESTROGYNE L P"},
{label: "ESTROTEP"},
{label: "ESTULIC"},
{label: "ETAIN STANNEUX MEDIAM"},
{label: "ETANORDEN"},
{label: "ETARTILEN"},
{label: "ETHER COOPER"},
{label: "ETHER GIFRER"},
{label: "ETHIBERTRADROBEL"},
{label: "ETHIGETODENBERT"},
{label: "ETHINYLESTRADIOL / DROSPIRENONE BESINS"},
{label: "ETHINYLESTRADIOL / LEVONORGESTREL TEVA"},
{label: "ETHINYLESTRADIOL DROSPIRENONE SCHERING"},
{label: "ETHINYLESTRADIOL/DROSPIRENONE BESINS CONTINU"},
{label: "ETHINYLESTRADIOL/DROSPIRENONE BESINS INTERNATIONAL"},
{label: "ETHINYLESTRADIOL/DROSPIRENONE BGR"},
{label: "ETHINYLESTRADIOL/DROSPIRENONE BGRCONTINU"},
{label: "ETHINYLESTRADIOL/DROSPIRENONE BIOGARAN"},
{label: "ETHINYLESTRADIOL/DROSPIRENONE BIOGARANCONTINU"},
{label: "ETHINYLESTRADIOL/DROSPIRENONE CRISTERS"},
{label: "ETHINYLESTRADIOL/DROSPIRENONE CRISTERS CONTINU"},
{label: "ETHINYLESTRADIOL/DROSPIRENONE CRISTERS PHARMA CONTINU"},
{label: "ETHINYLESTRADIOL/DROSPIRENONE GNR"},
{label: "ETHINYLESTRADIOL/DROSPIRENONE GNRPHARMA"},
{label: "ETHINYLESTRADIOL/DROSPIRENONE LEON FARMA CONTINU"},
{label: "ETHINYLESTRADIOL/DROSPIRENONE MITHRA"},
{label: "ETHINYLESTRADIOL/DROSPIRENONE MITHRA CONTINU"},
{label: "ETHINYLESTRADIOL/DROSPIRENONE SANDOZ"},
{label: "ETHINYLESTRADIOL/DROSPIRENONE TEVA"},
{label: "ETHINYLESTRADIOL/DROSPIRENONE TEVA PHARMA"},
{label: "ETHINYLESTRADIOL/DROSPIRENONE TEVA SANTE CONTINU"},
{label: "ETHINYLESTRADIOL/ETONOGESTREL BIOGARAN"},
{label: "ETHINYLESTRADIOL/ETONOGESTREL KAPPLERPHARMA"},
{label: "ETHINYLESTRADIOL/ETONOGESTREL SANDOZ"},
{label: "ETHINYLESTRADIOL/ETONOGESTREL ZENTIVA"},
{label: "ETHINYLOESTRADIOL EFFIK"},
{label: "ETHMOZINE"},
{label: "ETHYLICUM BOIRON"},
{label: "ETHYOL"},
{label: "ETICARDYNE L.P."},
{label: "ETIDRONATE DE SODIUM G GAM"},
{label: "ETIDRONATE DISODIQUE PROCTER & GAMBLE PHARMACEUTICALS"},
{label: "ETIDRONATE MYLAN"},
{label: "ETIDRONATE SANDOZ"},
{label: "ETIFOXINE EG"},
{label: "ETIFOXINE SUBSTIPHARM"},
{label: "ETILEFRINE S.E.R.B."},
{label: "ETIOVEN"},
{label: "ETIZOL"},
{label: "ETOMIDATE LIPURO"},
{label: "ETONOGESTREL/ETHINYLESTRADIOL VIATRIS"},
{label: "ETOPOPHOS"},
{label: "ETOPOSIDE ACCORD"},
{label: "ETOPOSIDE COOPER"},
{label: "ETOPOSIDE HIKMA"},
{label: "ETOPOSIDE KABI"},
{label: "ETOPOSIDE MEDAC"},
{label: "ETOPOSIDE PIERRE FABRE MEDICAMENT"},
{label: "ETOPOSIDE RATIO"},
{label: "ETOPOSIDE RATIOPHARM"},
{label: "ETOPOSIDE SANDOZ"},
{label: "ETOPOSIDE SERB"},
{label: "ETOPOSIDE TEVA"},
{label: "ETOPOSIDE VIATRIS"},
{label: "ETOPOSIDE WINTHROP"},
{label: "ETORICOXIB ARROW"},
{label: "ETORICOXIB BIOGARAN"},
{label: "ETORICOXIB KRKA"},
{label: "ETORICOXIB RIC"},
{label: "ETORICOXIB SANDOZ"},
{label: "ETORICOXIB VIATRIS"},
{label: "ETORICOXIB ZYDUS FRANCE"},
{label: "ETORING"},
{label: "EUBINE"},
{label: "EUCALCIC"},
{label: "EUCALMIL"},
{label: "EUCALYBROL"},
{label: "EUCALYBROL BISMUTH ADULTES"},
{label: "EUCALYBROL BISMUTH ENFANTS"},
{label: "EUCALYPTINE ADULTES"},
{label: "EUCALYPTINE ENFANTS"},
{label: "EUCALYPTINE LE BRUN"},
{label: "EUCALYPTINE LE BRUN ADULTES"},
{label: "EUCALYPTINE LE BRUN ENFANTS"},
{label: "EUCALYPTINE LE BRUN NOURRISSONS"},
{label: "EUCALYPTINE PARACETAMOL ADULTES"},
{label: "EUCALYPTINE PARACETAMOL ENFANTS"},
{label: "EUCALYPTINE PHOLCODINE ADULTES"},
{label: "EUCALYPTINE PHOLCODINE ENFANTS"},
{label: "EUCALYPTOSPIRINE ADULTES"},
{label: "EUCALYPTOSPIRINE ENFANTS"},
{label: "EUCALYPTOSPIRINE NOURRISSONS"},
{label: "EUCALYPTUS GLOBULUS BOIRON"},
{label: "EUCALYPTUS GLOBULUS LEHNING"},
{label: "EUCALYPTUS GLOBULUS WELEDA"},
{label: "EUCALYPTUS NATURA MEDICA"},
{label: "EUCARDIC"},
{label: "EUCARYL"},
{label: "EUCOL"},
{label: "EUCOL-1000"},
{label: "EUCREAS"},
{label: "EUCRINONE"},
{label: "EUCTAN"},
{label: "EUDION"},
{label: "EUGENIA JAMBOLANA BOIRON"},
{label: "EUGENIA JAMBOSA BOIRON"},
{label: "EUGENIA JAMBOSA LEHNING"},
{label: "EUGLUCAN"},
{label: "EULEXINE"},
{label: "EUPANTOL"},
{label: "EUPATORIUM CANNABINUM BOIRON"},
{label: "EUPATORIUM PERFOLIATUM BOIRON"},
{label: "EUPATORIUM PERFOLIATUM LEHNING"},
{label: "EUPATORIUM PERFOLIATUM WELEDA"},
{label: "EUPATORIUM PURPUREUM BOIRON"},
{label: "EUPHON"},
{label: "EUPHON MENTHOL"},
{label: "EUPHON SANS SUCRE"},
{label: "EUPHONYLL EXPECTORANT ADULTES"},
{label: "EUPHONYLL MAL DE GORGE"},
{label: "EUPHONYLL MAUX DE GORGE CETYLPYRIDINIUM LIDOCAINE SANS SUCRE"},
{label: "EUPHONYLL TOUX SECHE DEXTROMETORPHANE"},
{label: "EUPHORBIA LATHYRIS BOIRON"},
{label: "EUPHORBIA PILULIFERA BOIRON"},
{label: "EUPHORBIA RESINIFERA BOIRON"},
{label: "EUPHORBIA SILVATICA BOIRON"},
{label: "EUPHORBIUM COMPLEXE N°88"},
{label: "EUPHRASIA 3DH WELEDA"},
{label: "EUPHRASIA COMPLEXE N°115"},
{label: "EUPHRASIA OFFICINALIS BOIRON"},
{label: "EUPHRASIA OFFICINALIS LEHNING"},
{label: "EUPHRASIA OFFICINALIS WELEDA"},
{label: "EUPHYLLINE ENFANTS ET NOURRISSONS LP"},
{label: "EUPHYLLINE L A"},
{label: "EUPHYLLINE L.A."},
{label: "EUPHYPERTUIS"},
{label: "EUPHYTOSE"},
{label: "EUPNERON"},
{label: "EUPRESSYL"},
{label: "EUPRESSYL LP"},
{label: "EURARTESIM"},
{label: "EURAX"},
{label: "EURAXSEPTI"},
{label: "EURAXVIR"},
{label: "EURELIX"},
{label: "EUROBIOL"},
{label: "EURONAC"},
{label: "EUSAPRIM ADULTES"},
{label: "EUSAPRIM ENFANTS"},
{label: "EUSAPRIM FORT"},
{label: "EUSAPRIM FORT I.M."},
{label: "EUSAPRIM NOURRISSONS ET ENFANTS"},
{label: "EUSAPRIM PERFUSION I.V."},
{label: "EUTHYRAL"},
{label: "EUVANOL INHALATION"},
{label: "EUVANOL SPRAY"},
{label: "EVAFILM"},
{label: "EVALOTAB ENFANTS ET NOURRISSONS"},
{label: "EVANECIA"},
{label: "EVAPAUSE"},
{label: "EVARREST"},
{label: "EVENITY"},
{label: "EVEPAR"},
{label: "EVEROLIMUS ARROW"},
{label: "EVEROLIMUS BIOGARAN"},
{label: "EVEROLIMUS CRISTERS"},
{label: "EVEROLIMUS EG"},
{label: "EVEROLIMUS HCS"},
{label: "EVEROLIMUS PHARMAKI GENERICS"},
{label: "EVEROLIMUS REDDY PHARMA"},
{label: "EVEROLIMUS SANDOZ"},
{label: "EVEROLIMUS SYNTHON"},
{label: "EVEROLIMUS TEVA"},
{label: "EVEROLIMUS VIATRIS"},
{label: "EVEROLIMUS ZENTIVA"},
{label: "EVICEL"},
{label: "EVIPLERA"},
{label: "EVISTA"},
{label: "EVKEEZA"},
{label: "EVOLIS"},
{label: "EVOLTRA"},
{label: "EVONYL SENE"},
{label: "EVONYMUS EUROPAEUS BOIRON"},
{label: "EVOTAZ"},
{label: "EVRA"},
{label: "EVRENZO"},
{label: "EVRYSDI"},
{label: "EVUSHELD"},
{label: "EX'AIL VITAMINE P"},
{label: "EXACOR"},
{label: "EXACYL"},
{label: "EXANTA"},
{label: "EXCEDRINIL"},
{label: "EXEBERTANEDEL"},
{label: "EXEDIEM"},
{label: "EXEDIEMED"},
{label: "EXELON"},
{label: "EXEMALABA"},
{label: "EXEMESTANE ACCORD"},
{label: "EXEMESTANE ACTAVIS"},
{label: "EXEMESTANE ARROW"},
{label: "EXEMESTANE BGR"},
{label: "EXEMESTANE BIOGARAN"},
{label: "EXEMESTANE CHANELLE MEDICAL"},
{label: "EXEMESTANE CHEMICAL FARMA"},
{label: "EXEMESTANE CLEXNI"},
{label: "EXEMESTANE CRISTERS"},
{label: "EXEMESTANE EG"},
{label: "EXEMESTANE FARMALIDER"},
{label: "EXEMESTANE HELM"},
{label: "EXEMESTANE HIKMA"},
{label: "EXEMESTANE INTAS"},
{label: "EXEMESTANE MYPLIX"},
{label: "EXEMESTANE NIALEX"},
{label: "EXEMESTANE OPENING PHARMA"},
{label: "EXEMESTANE PFIZER"},
{label: "EXEMESTANE QUALIMED"},
{label: "EXEMESTANE RANBAXY"},
{label: "EXEMESTANE RATIO"},
{label: "EXEMESTANE RATIOPHARM GENERIQUES"},
{label: "EXEMESTANE SANDOZ"},
{label: "EXEMESTANE SYNTHON HISPANIA"},
{label: "EXEMESTANE TEVA"},
{label: "EXEMESTANE VIATRIS"},
{label: "EXEMESTANE ZENTIVA"},
{label: "EXEMESTANE ZENTIVA LAB"},
{label: "EXEMESTANE ZYDUS"},
{label: "EXEMESTANE ZYDUS FRANCE"},
{label: "EXFORGE"},
{label: "EXFORGE HCT"},
{label: "EXJADE"},
{label: "EXLUTON"},
{label: "EXOCINE"},
{label: "EXOFENE"},
{label: "EXOLISE"},
{label: "EXOMUC"},
{label: "EXOMUC NOURRISSON"},
{label: "EXOSEPTOPLIX"},
{label: "EXOTOUX"},
{label: "EXPANDOX"},
{label: "EXPANFEN"},
{label: "EXTAVIA"},
{label: "EXTENCILLINE"},
{label: "EXTOVYL"},
{label: "EXTRAIT AQUEUX D'ARTICHAUT (FEUILLES)"},
{label: "EXTRAIT AQUEUX D'AUBIER DE TILLEUL SUPERDIET"},
{label: "EXTRAIT AQUEUX DE BOULEAU SUPERDIET"},
{label: "EXTRAIT AQUEUX DE CASSIS SUPERDIET"},
{label: "EXTRAIT AQUEUX DE FUMETERRE SUPERDIET"},
{label: "EXTRAIT AQUEUX DE PASSIFLORE SUPERDIET"},
{label: "EXTRAIT AQUEUX DE PISSENLIT SUPERDIET"},
{label: "EXTRAIT AQUEUX DE PRELE SUPERDIET"},
{label: "EXTRAIT AQUEUX SEC DE SOUCI UPSA CONSEIL"},
{label: "EXTRAIT BUVABLE DE PILOSELLE PIERRE FABRE SANTE"},
{label: "EXTRAIT PURIFIE DE PEPIN DE RAISIN BIOGARAN CONSEIL"},
{label: "EXTRANASE"},
{label: "EXTRANEAL"},
{label: "EXTRANEAL CLEAR-FLEX"},
{label: "EXUBERA"},
{label: "EXUFOT"},
{label: "EXVIERA"},
{label: "EYLEA"},
{label: "EZETIMIBE ACCORD"},
{label: "EZETIMIBE ALMUS"},
{label: "EZETIMIBE ALTER"},
{label: "EZETIMIBE ARISTO"},
{label: "EZETIMIBE ARROW"},
{label: "EZETIMIBE ASTRON"},
{label: "EZETIMIBE BIOGARAN"},
{label: "EZETIMIBE CRISTERS"},
{label: "EZETIMIBE CRISTERS PHARMA"},
{label: "EZETIMIBE EG"},
{label: "EZETIMIBE EVOLUGEN"},
{label: "EZETIMIBE KRKA"},
{label: "EZETIMIBE MICROLABS"},
{label: "EZETIMIBE MSD"},
{label: "EZETIMIBE SANDOZ"},
{label: "EZETIMIBE TEVA"},
{label: "EZETIMIBE TEVA SANTE"},
{label: "EZETIMIBE TORRENT"},
{label: "EZETIMIBE VIATRIS"},
{label: "EZETIMIBE ZENTIVA"},
{label: "EZETIMIBE ZYDUS"},
{label: "EZETIMIBE/SIMVASTATINE ACCORD"},
{label: "EZETIMIBE/SIMVASTATINE ALMUS"},
{label: "EZETIMIBE/SIMVASTATINE ALTER"},
{label: "EZETIMIBE/SIMVASTATINE ARROW"},
{label: "EZETIMIBE/SIMVASTATINE BIOGARAN"},
{label: "EZETIMIBE/SIMVASTATINE CRISTERS"},
{label: "EZETIMIBE/SIMVASTATINE CRISTERS PHARMA"},
{label: "EZETIMIBE/SIMVASTATINE EG"},
{label: "EZETIMIBE/SIMVASTATINE EVOLUGEN"},
{label: "EZETIMIBE/SIMVASTATINE KRKA"},
{label: "EZETIMIBE/SIMVASTATINE SANDOZ"},
{label: "EZETIMIBE/SIMVASTATINE TEVA"},
{label: "EZETIMIBE/SIMVASTATINE TEVA SANTE"},
{label: "EZETIMIBE/SIMVASTATINE VIATRIS"},
{label: "EZETIMIBE/SIMVASTATINE ZENTIVA"},
{label: "EZETIMIBE/SIMVASTATINE ZYDUS"},
{label: "EZETROL"},
{label: "EZORAN"},
{label: "FABIANA IMBRICATA BOIRON"},
{label: "FABRAZYME"},
{label: "FACTANE"},
{label: "FACTEUR VII LFB"},
{label: "FACTEUR WILLEBRAND LFB"},
{label: "FAGOPYRUM ESCULENTUM BOIRON"},
{label: "FAGOPYRUM ESCULENTUM LEHNING"},
{label: "FAGUS SILVATICA BOIRON"},
{label: "FAMCICLOVIR TEVA"},
{label: "FAMENPAX"},
{label: "FAMOROL"},
{label: "FAMOTIDINE EG"},
{label: "FAMOTIDINE G GAM"},
{label: "FAMOTIDINE MYLAN"},
{label: "FAMOTIDINE TEVA"},
{label: "FAMOTIDINE TEVA CLASSICS"},
{label: "FAMPRIDINE ARROW LP"},
{label: "FAMPRIDINE EG LP"},
{label: "FAMPRIDINE INTAS LP"},
{label: "FAMPRIDINE MICRO LABS LP"},
{label: "FAMPRIDINE SANDOZ LP"},
{label: "FAMPRIDINE SUN LP"},
{label: "FAMPRIDINE TEVA LP"},
{label: "FAMPRIDINE ZENTIVA LP"},
{label: "FAMPYRA"},
{label: "FANASIL"},
{label: "FANSIDAR"},
{label: "FARESTON"},
{label: "FARLUTAL"},
{label: "FARMORUBICINE"},
{label: "FARYDAK"},
{label: "FASENRA"},
{label: "FASIGYNE"},
{label: "FASLODEX"},
{label: "FASTENYL"},
{label: "FASTURTEC"},
{label: "FAVINT"},
{label: "FAVIRAB"},
{label: "FAVYND"},
{label: "FAZOL"},
{label: "FAZOL G"},
{label: "FDG"},
{label: "FEBRALGINE"},
{label: "FEBRECTOL"},
{label: "FEBRECTOL ADULTES"},
{label: "FEBRECTOL ENFANTS"},
{label: "FEBRECTOL NOURRISSONS"},
{label: "FEBREO"},
{label: "FEBRIPAX"},
{label: "FEBUXOSTAT ACCORD"},
{label: "FEBUXOSTAT ALMUS"},
{label: "FEBUXOSTAT BIOGARAN"},
{label: "FEBUXOSTAT CRISTERS"},
{label: "FEBUXOSTAT EG"},
{label: "FEBUXOSTAT EVOLUGEN"},
{label: "FEBUXOSTAT KRKA"},
{label: "FEBUXOSTAT MYLAN"},
{label: "FEBUXOSTAT SANDOZ"},
{label: "FEBUXOSTAT TEVA SANTE"},
{label: "FEBUXOSTAT TIEFENBACHER"},
{label: "FEBUXOSTAT VIVANTA"},
{label: "FEBUXOSTAT ZENTIVA"},
{label: "FEBUXOSTAT ZENTIVA LAB"},
{label: "FEBUXOSTAT ZYDUS"},
{label: "FEBUXOSTAT ZYDUS FRANCE"},
{label: "FEDELBERNIL"},
{label: "FEGENOR"},
{label: "FEIBA"},
{label: "FELDENE"},
{label: "FELDENE DISPERSIBLE"},
{label: "FELDSPATH QUADRATIQUE BOIRON"},
{label: "FELIXITA"},
{label: "FELODIPINE SANDOZ L.P."},
{label: "FELODIPINE WINTHROP L.P."},
{label: "FELODIPINE ZENTIVA L.P."},
{label: "FELSAN"},
{label: "FEMADEXIL"},
{label: "FEMARA"},
{label: "FEMELIS"},
{label: "FEMI"},
{label: "FEMIPLEXE LEHNING"},
{label: "FEMOSIA"},
{label: "FEMRING"},
{label: "FEMSEPT"},
{label: "FEMSEPTCOMBI"},
{label: "FEMSEPTEVO"},
{label: "FENATHOL"},
{label: "FENDRIX"},
{label: "FENFLURAMINE SERVIER"},
{label: "FENFLURAMINE SERVIER A ACTION PROLONGEE"},
{label: "FENIPSORIAN"},
{label: "FENISTIL"},
{label: "FENOCOR"},
{label: "FENOFIBRATE ACTAVIS"},
{label: "FENOFIBRATE ALMUS"},
{label: "FENOFIBRATE ALTER"},
{label: "FENOFIBRATE ARROW"},
{label: "FENOFIBRATE ARROW GENERIQUES"},
{label: "FENOFIBRATE ARROW LAB"},
{label: "FENOFIBRATE BIOGARAN"},
{label: "FENOFIBRATE BOUCHARA RECORDATI"},
{label: "FENOFIBRATE CEHEL PHARMA"},
{label: "FENOFIBRATE CLL PHARMA"},
{label: "FENOFIBRATE CRISTERS"},
{label: "FENOFIBRATE CRISTERS PHARMA"},
{label: "FENOFIBRATE DEBAT CARDIO"},
{label: "FENOFIBRATE EG"},
{label: "FENOFIBRATE EG LABO"},
{label: "FENOFIBRATE ENIREX"},
{label: "FENOFIBRATE ETHYPHARM"},
{label: "FENOFIBRATE EXPANPHARM"},
{label: "FENOFIBRATE FOURNIER"},
{label: "FENOFIBRATE G GAM"},
{label: "FENOFIBRATE GALENIX"},
{label: "FENOFIBRATE IVAX"},
{label: "FENOFIBRATE MEDIPHA SANTE"},
{label: "FENOFIBRATE MYLAN"},
{label: "FENOFIBRATE QUALIMED"},
{label: "FENOFIBRATE RATIO"},
{label: "FENOFIBRATE RATIOPHARM"},
{label: "FENOFIBRATE RPG"},
{label: "FENOFIBRATE SANDOZ"},
{label: "FENOFIBRATE SUN"},
{label: "FENOFIBRATE SYNKEM"},
{label: "FENOFIBRATE TEVA"},
{label: "FENOFIBRATE TEVA CLASSICS"},
{label: "FENOFIBRATE TEVA SANTE"},
{label: "FENOFIBRATE VIATRIS"},
{label: "FENOFIBRATE ZENTIVA"},
{label: "FENOFIBRATE ZYDUS"},
{label: "FENOUIL FRUIT INFUSETTE LES BONS PRODUCTEURS"},
{label: "FENOUIL RACINE NATURA MEDICA"},
{label: "FENOX"},
{label: "FENPROPOREX ACTION PROLONGEE DEGLAUDE"},
{label: "FENSPIRIDE (CHLORHYDRATE) SERVIER"},
{label: "FENTANYL AGUETTANT"},
{label: "FENTANYL ARROW"},
{label: "FENTANYL BGR"},
{label: "FENTANYL BIOGARAN"},
{label: "FENTANYL DAKOTA PHARM"},
{label: "FENTANYL EG"},
{label: "FENTANYL ETHYPHARM"},
{label: "FENTANYL LABORATOIRES LAVIPHARM"},
{label: "FENTANYL LAVIPHARM"},
{label: "FENTANYL NYCOMED"},
{label: "FENTANYL PANPHARMA"},
{label: "FENTANYL PIRAMAL"},
{label: "FENTANYL RANBAXY"},
{label: "FENTANYL RENAUDIN"},
{label: "FENTANYL SANDOZ"},
{label: "FENTANYL TEVA"},
{label: "FENTANYL TEVA SANTE"},
{label: "FENTANYL VIATRIS"},
{label: "FENTANYL ZENTIVA"},
{label: "FENTICONAZOLE BAILLEUL"},
{label: "FENTICONAZOLE SUBSTIPHARM"},
{label: "FENUGRENE"},
{label: "FENYVE"},
{label: "FER AP-HP"},
{label: "FER ARROW"},
{label: "FER BAXTER"},
{label: "FER FERREUX SCHWARZ PHARMA"},
{label: "FER HERMES"},
{label: "FER PANPHARMA"},
{label: "FER PIERRE FABRE MEDICAMENT"},
{label: "FER SANDOZ"},
{label: "FER TEVA PHARMA"},
{label: "FER VIATRIS"},
{label: "FERINJECT"},
{label: "FERO-GRAD VITAMINE C"},
{label: "FERRIPROX"},
{label: "FERRISAT"},
{label: "FERROSTRANE"},
{label: "FERRUM COMPLEXE N°29"},
{label: "FERRUM METALLICUM BOIRON"},
{label: "FERRUM METALLICUM LEHNING"},
{label: "FERRUM METALLICUM WELEDA"},
{label: "FERRUM MURIATICUM BOIRON"},
{label: "FERRUM MURIATICUM WELEDA"},
{label: "FERRUM PHOSPHORICUM / FERRI PHOSPHAS LEHNING"},
{label: "FERRUM PHOSPHORICUM / FERROSO-FERRI PHOSPHAS BOIRON"},
{label: "FERRUM PHOSPHORICUM WELEDA"},
{label: "FERRUM PICRICUM BOIRON"},
{label: "FERRUM SIDEREUM WELEDA"},
{label: "FERRUM-QUARTZ WELEDA"},
{label: "FERTIFOL"},
{label: "FERTILINE"},
{label: "FERTISTARTKIT"},
{label: "FERULA COMMUNIS BOIRON"},
{label: "FERVEX ADULTES"},
{label: "FERVEX ADULTES FRAMBOISE"},
{label: "FERVEX ADULTES SANS SUCRE"},
{label: "FERVEX ENFANTS SANS SUCRE"},
{label: "FERVEX RHUME"},
{label: "FERVEXRHUME"},
{label: "FESOTERODINE ARROW LP"},
{label: "FESOTERODINE BIOGARAN LP"},
{label: "FESOTERODINE CRISTERS LP"},
{label: "FESOTERODINE EG LP"},
{label: "FESOTERODINE TEVA LP"},
{label: "FESOTERODINE ZENTIVA LP"},
{label: "FETCROJA"},
{label: "FEUILLE DE SAULE"},
{label: "FEUILLE DE SAULE CORICIDE VERRUCIDE LIQUIDE"},
{label: "FEUILLE DE SAULE TOUT PRET COR"},
{label: "FEUILLE DE SAULE TOUT PRET DURILLON"},
{label: "FEXOFENADINE AVENTIS"},
{label: "FEXOFENADINE BIOGARAN"},
{label: "FEXOFENADINE CIPLA"},
{label: "FEXOFENADINE TEVA"},
{label: "FEXOFENADINE VIATRIS"},
{label: "FEXOFENADINE ZENTIVA"},
{label: "FIASP"},
{label: "FIASP FLEXTOUCH"},
{label: "FIASP PENFILL"},
{label: "FIASP PUMPCART"},
{label: "FIBERFORM"},
{label: "FIBERICAN"},
{label: "FIBORAN"},
{label: "FIBROGAMMIN"},
{label: "FIBROVEIN"},
{label: "FIBRYGA"},
{label: "FICARIA RANUNCULOIDES BOIRON"},
{label: "FICUS CARICA"},
{label: "FICUS RELIGIOSA BOIRON"},
{label: "FILAE"},
{label: "FILGASTRIM AMGEN"},
{label: "FILGRASTIM RATIOPHARM"},
{label: "FILIBON"},
{label: "FILIOP"},
{label: "FILSUVEZ"},
{label: "FINACEA"},
{label: "FINALGON"},
{label: "FINASTERIDE ACCORD"},
{label: "FINASTERIDE ACTAVIS"},
{label: "FINASTERIDE ALMUS"},
{label: "FINASTERIDE ALTER"},
{label: "FINASTERIDE AMAROX"},
{label: "FINASTERIDE ARROW"},
{label: "FINASTERIDE ARROW GENERIQUES"},
{label: "FINASTERIDE ARROW LAB"},
{label: "FINASTERIDE BAILLEUL"},
{label: "FINASTERIDE BGR"},
{label: "FINASTERIDE BIOGARAN"},
{label: "FINASTERIDE CRISTERS"},
{label: "FINASTERIDE DCI PHARMA"},
{label: "FINASTERIDE EG"},
{label: "FINASTERIDE HEXAL"},
{label: "FINASTERIDE ISOMED"},
{label: "FINASTERIDE MYLAN PHARMA"},
{label: "FINASTERIDE NIO"},
{label: "FINASTERIDE PFIZER"},
{label: "FINASTERIDE PHR LAB"},
{label: "FINASTERIDE QUALIMED"},
{label: "FINASTERIDE RATIOPHARM"},
{label: "FINASTERIDE SANDOZ"},
{label: "FINASTERIDE SUN"},
{label: "FINASTERIDE TEVA"},
{label: "FINASTERIDE VIATRIS"},
{label: "FINASTERIDE ZENTIVA"},
{label: "FINASTERIDE ZYDUS"},
{label: "FINGOLIMOD ACCORD"},
{label: "FINGOLIMOD ARROW"},
{label: "FINGOLIMOD BIOGARAN"},
{label: "FINGOLIMOD BLUEPHARMA"},
{label: "FINGOLIMOD EDEST"},
{label: "FINGOLIMOD EG"},
{label: "FINGOLIMOD EVOLUGEN"},
{label: "FINGOLIMOD GENEPHARM"},
{label: "FINGOLIMOD HCS"},
{label: "FINGOLIMOD KOANAA"},
{label: "FINGOLIMOD LABORATORIOS LICONSA"},
{label: "FINGOLIMOD LICONSA"},
{label: "FINGOLIMOD MYLAN"},
{label: "FINGOLIMOD PHARMAKI"},
{label: "FINGOLIMOD PHARMATHEN"},
{label: "FINGOLIMOD REDDY PHARMA"},
{label: "FINGOLIMOD SANDOZ"},
{label: "FINGOLIMOD SUN"},
{label: "FINGOLIMOD TEVA"},
{label: "FINGOLIMOD ZENTIVA"},
{label: "FINGOLIMOD ZYDUS"},
{label: "FINHAIR"},
{label: "FINIDOL"},
{label: "FINTEPLA"},
{label: "FIRAZYR"},
{label: "FIRDAPSE"},
{label: "FIRINADER"},
{label: "FIRMAGON"},
{label: "FITACNOL"},
{label: "FITAXAL"},
{label: "FITOFEBRIL"},
{label: "FITONIC"},
{label: "FITOREINAL"},
{label: "FIVASA"},
{label: "FIXAPOST"},
{label: "FIXICAL"},
{label: "FIXICAL VITAMINE D3"},
{label: "FIXORINOX"},
{label: "FJALLIRONE"},
{label: "FLAGENTYL"},
{label: "FLAGENTYL ENFANTS"},
{label: "FLAGYL"},
{label: "FLAMMACERIUM"},
{label: "FLAMMAZINE"},
{label: "FLANID"},
{label: "FLAREX"},
{label: "FLATISTINE"},
{label: "FLAVAN"},
{label: "FLAVIASTASE"},
{label: "FLAVONOIDES ZENTIVA CONSEIL"},
{label: "FLAVONOÏDES VIATRIS CONSEIL"},
{label: "FLAVOQUINE"},
{label: "FLAXEDIL"},
{label: "FLEBERCADELNIDE LP"},
{label: "FLEBOGAMMA DIF"},
{label: "FLEBOSMIL"},
{label: "FLECAINE"},
{label: "FLECAINIDE ARROW"},
{label: "FLECAINIDE ARROW LAB"},
{label: "FLECAINIDE AUROBINDO"},
{label: "FLECAINIDE BIOGARAN"},
{label: "FLECAINIDE BIOGARAN LP"},
{label: "FLECAINIDE DISPHAR L.P."},
{label: "FLECAINIDE MICROLABS"},
{label: "FLECAINIDE MYLAN PHARMA"},
{label: "FLECAINIDE QUALIHEALTH"},
{label: "FLECAINIDE QUALIMED"},
{label: "FLECAINIDE RANBAXY"},
{label: "FLECAINIDE RPG"},
{label: "FLECAINIDE TEVA"},
{label: "FLECAINIDE TEVA LP"},
{label: "FLECAINIDE VIATRIS"},
{label: "FLECAINIDE VIATRIS LP"},
{label: "FLECAINIDE ZENTIVA LP"},
{label: "FLECAÏNE L.P."},
{label: "FLECAÏNIDE EG"},
{label: "FLECAÏNIDE SANDOZ"},
{label: "FLECTOR"},
{label: "FLECTOR TISSUGEL"},
{label: "FLECTOR TISSUGEL HEPARINE"},
{label: "FLECTOREFFIGEL"},
{label: "FLECTORTISSUGELEP"},
{label: "FLEMOXINE"},
{label: "FLETAGEX"},
{label: "FLEXBUMIN"},
{label: "FLEXEA"},
{label: "FLEXICONES"},
{label: "FLEXIROX"},
{label: "FLEXOGYNE"},
{label: "FLEXYESS"},
{label: "FLISINT"},
{label: "FLIXABI"},
{label: "FLIXONASE"},
{label: "FLIXOTIDE"},
{label: "FLIXOTIDE DISKUS"},
{label: "FLIXOTIDE ROTADISK"},
{label: "FLIXOVATE"},
{label: "FLODIL L.P."},
{label: "FLOGAR"},
{label: "FLOGENCYL"},
{label: "FLOLAN"},
{label: "FLORALAC"},
{label: "FLORGYNAL"},
{label: "FLOROFLATINE"},
{label: "FLORUX"},
{label: "FLOTAVIS"},
{label: "FLOXAMAR"},
{label: "FLOXINOV"},
{label: "FLOXYFRAL"},
{label: "FLUAD TETRA"},
{label: "FLUANXOL"},
{label: "FLUANXOL L P"},
{label: "FLUANXOL LP"},
{label: "FLUARIX"},
{label: "FLUARIXTETRA"},
{label: "FLUBENDAZOLE CILAG"},
{label: "FLUBILAR"},
{label: "FLUBILAR ENFANTS"},
{label: "FLUCALYPTOL TOUX SECHE PHOLCODINE"},
{label: "FLUCELVAX TETRA"},
{label: "FLUCIS"},
{label: "FLUCLOXACILLINE ARROW"},
{label: "FLUCON"},
{label: "FLUCONAZOLE ACTAVIS"},
{label: "FLUCONAZOLE AGUETTANT"},
{label: "FLUCONAZOLE ALMUS"},
{label: "FLUCONAZOLE ARROW"},
{label: "FLUCONAZOLE ARROW LAB"},
{label: "FLUCONAZOLE BERAL MONODOSE"},
{label: "FLUCONAZOLE BIOGARAN"},
{label: "FLUCONAZOLE CRISTERS"},
{label: "FLUCONAZOLE DAKOTA PHARM"},
{label: "FLUCONAZOLE EG"},
{label: "FLUCONAZOLE EVOLUGEN"},
{label: "FLUCONAZOLE G GAM"},
{label: "FLUCONAZOLE IBD3 PHARMA CONSULTING"},
{label: "FLUCONAZOLE ISOMED"},
{label: "FLUCONAZOLE KABI"},
{label: "FLUCONAZOLE MEDICAL TECHNOLOGIES"},
{label: "FLUCONAZOLE MYLAN"},
{label: "FLUCONAZOLE MYLAN PHARMA"},
{label: "FLUCONAZOLE NORIDEM ENTERPRISES"},
{label: "FLUCONAZOLE PANPHARMA"},
{label: "FLUCONAZOLE PFIZER"},
{label: "FLUCONAZOLE QUALIMED"},
{label: "FLUCONAZOLE QUALIMED GENERIQUES"},
{label: "FLUCONAZOLE RANBAXY"},
{label: "FLUCONAZOLE RATIOPHARM"},
{label: "FLUCONAZOLE REDIBAG"},
{label: "FLUCONAZOLE RENAUDIN"},
{label: "FLUCONAZOLE SANDOZ"},
{label: "FLUCONAZOLE TEVA"},
{label: "FLUCONAZOLE TEVA CLASSICS"},
{label: "FLUCONAZOLE TEVA SANTE"},
{label: "FLUCONAZOLE VENIPHARM"},
{label: "FLUCONAZOLE VIATRIS"},
{label: "FLUCONAZOLE WINTHROP"},
{label: "FLUCONAZOLE ZENTIVA"},
{label: "FLUCONAZOLE ZYDUS"},
{label: "FLUCOPHARM"},
{label: "FLUCORTAC"},
{label: "FLUCTINE"},
{label: "FLUDARA"},
{label: "FLUDARABINE ACCORD"},
{label: "FLUDARABINE ARROW"},
{label: "FLUDARABINE EBEWE"},
{label: "FLUDARABINE MYLAN"},
{label: "FLUDARABINE PHOSPHATE BAYER SANTE"},
{label: "FLUDARABINE PHOSPHATE HOSPIRA"},
{label: "FLUDARABINE TEVA"},
{label: "FLUDESOXYGLUCOSE (18F) CIS BIO INTERNATIONAL"},
{label: "FLUDESOXYGLUCOSE (18F) CURIUM"},
{label: "FLUDESOXYGLUCOSE (18F) SYNEKTIK"},
{label: "FLUDESOXYGLUCOSE-(18F) CYCLOPHARMA"},
{label: "FLUDEX"},
{label: "FLUDITEC"},
{label: "FLUDITEC ENFANTS"},
{label: "FLUDITEC ETAT GRIPPAL"},
{label: "FLUDOUCIL"},
{label: "FLUDROCORTISONE ACETATE ACCORD"},
{label: "FLUDROCORTISONE DULCIS"},
{label: "FLUENZ"},
{label: "FLUENZ TETRA"},
{label: "FLUICIL"},
{label: "FLUIDABAK"},
{label: "FLUIDEINE"},
{label: "FLUIMUCIL"},
{label: "FLUIMUCIL EXPECTORANT ACETYLCYSTEINE"},
{label: "FLUIMUCIL NOURRISSON"},
{label: "FLUIMUCIL TOUX SECHE DEXTROMETHORPHANE"},
{label: "FLUISEDAL"},
{label: "FLUISEDAL SANS PROMETHAZINE"},
{label: "FLUMACH"},
{label: "FLUMAZENIL AGUETTANT"},
{label: "FLUMAZENIL B.BRAUN"},
{label: "FLUMAZENIL BAXTER"},
{label: "FLUMAZENIL HIKMA"},
{label: "FLUMAZENIL KABI"},
{label: "FLUMAZENIL MYLAN PHARMA"},
{label: "FLUMAZENIL NORDIC PHARMA"},
{label: "FLUMAZENIL PANPHARMA"},
{label: "FLUMAZENIL TEVA"},
{label: "FLUMAZENIL VIATRIS"},
{label: "FLUMAZENIL WINTHROP"},
{label: "FLUNARIZINE CILAG"},
{label: "FLUNIR"},
{label: "FLUNITRAZEPAM MERCK"},
{label: "FLUNITRAZEPAM RPG"},
{label: "FLUOCAL"},
{label: "FLUOCARIL"},
{label: "FLUOCARIL BI FLUORE"},
{label: "FLUOCARIL BI-FLUORE"},
{label: "FLUOCARIL BIFLUORE"},
{label: "FLUOCARIL MENTHE"},
{label: "FLUOCARIL SANS MENTHOL"},
{label: "FLUOCYNE"},
{label: "FLUODONTYL"},
{label: "FLUOGEL"},
{label: "FLUOGUM SANS SUCRE AROME FRUITS ROUGES"},
{label: "FLUOGUM SANS SUCRE AROME MENTHE"},
{label: "FLUON"},
{label: "FLUOPATE"},
{label: "FLUOPLEXE LEHNING"},
{label: "FLUOR BRISTOL-MYERS SQUIBB"},
{label: "FLUOR CRINEX"},
{label: "FLUOR GALEPHAR"},
{label: "FLUOR IN"},
{label: "FLUOR MICROSOL"},
{label: "FLUOR OLIGOSOL"},
{label: "FLUORESCEINE FAURE"},
{label: "FLUORESCEINE SODIQUE FAURE"},
{label: "FLUORESCITE"},
{label: "FLUOREX"},
{label: "FLUORICUM ACIDUM BOIRON"},
{label: "FLUORICUM ACIDUM LEHNING"},
{label: "FLUORICUM ACIDUM WELEDA"},
{label: "FLUORIDENT"},
{label: "FLUORITE WELEDA"},
{label: "FLUORO-URACILE MEDA PHARMA"},
{label: "FLUOROCHOL"},
{label: "FLUOROCHOLINE (18F)"},
{label: "FLUOROCHOLINE (18F) SYNEKTIK"},
{label: "FLUOROCHOLINE [18 F] CIS BIO INTERNATIONAL"},
{label: "FLUOROURACILE ACCORD"},
{label: "FLUOROURACILE DAKOTA PHARM"},
{label: "FLUOROURACILE EBEWE"},
{label: "FLUOROURACILE MEDAC"},
{label: "FLUOROURACILE MYLAN"},
{label: "FLUOROURACILE PFIZER"},
{label: "FLUOROURACILE QUALIMED"},
{label: "FLUOROURACILE SANDOZ"},
{label: "FLUOROURACILE SERB"},
{label: "FLUOROURACILE TEVA"},
{label: "FLUORURE DE CALCIUM CRINEX"},
{label: "FLUOSELGINE"},
{label: "FLUOSEPT"},
{label: "FLUOSTEROL"},
{label: "FLUOTHANE"},
{label: "FLUOXETINE ACCORD"},
{label: "FLUOXETINE ACTAVIS"},
{label: "FLUOXETINE ALMUS"},
{label: "FLUOXETINE ALTER"},
{label: "FLUOXETINE AMDIPHARM"},
{label: "FLUOXETINE ARROW"},
{label: "FLUOXETINE ARROW LAB"},
{label: "FLUOXETINE BELAMONT"},
{label: "FLUOXETINE BIOGARAN"},
{label: "FLUOXETINE BOUCHARA RECORDATI"},
{label: "FLUOXETINE CRISTERS"},
{label: "FLUOXETINE CRISTERS PHARMA"},
{label: "FLUOXETINE EG"},
{label: "FLUOXETINE ELSA"},
{label: "FLUOXETINE EVOLUGEN"},
{label: "FLUOXETINE G GAM"},
{label: "FLUOXETINE GERDA"},
{label: "FLUOXETINE GNR"},
{label: "FLUOXETINE ISOMED"},
{label: "FLUOXETINE LBR"},
{label: "FLUOXETINE LILLY"},
{label: "FLUOXETINE MICROLABS"},
{label: "FLUOXETINE MYLAN PHARMA"},
{label: "FLUOXETINE O.P.I.H FRANCE"},
{label: "FLUOXETINE PHR LAB"},
{label: "FLUOXETINE PINEWOOD"},
{label: "FLUOXETINE QUALIMED"},
{label: "FLUOXETINE RANBAXY"},
{label: "FLUOXETINE RATIOPHARM"},
{label: "FLUOXETINE RPG"},
{label: "FLUOXETINE SANDOZ"},
{label: "FLUOXETINE SET"},
{label: "FLUOXETINE SUBSTIPHARM"},
{label: "FLUOXETINE TEVA"},
{label: "FLUOXETINE TEVA SANTE"},
{label: "FLUOXETINE VIATRIS"},
{label: "FLUOXETINE ZENTIVA"},
{label: "FLUOXETINE ZYDUS"},
{label: "FLUOXYMESTERONE UNION INTERPHARMACEUTIQUE ATLANTIQUE"},
{label: "FLUPPE"},
{label: "FLURBIPROFENE SANDOZ CONSEIL"},
{label: "FLURBIPROFENE THERAMEX"},
{label: "FLURBIPROFENE URGO"},
{label: "FLUSTALESS L.P."},
{label: "FLUSTIMEX"},
{label: "FLUSTIMEX ENFANTS"},
{label: "FLUSTIMEX JOUR ET NUIT"},
{label: "FLUTAMIDE ARROW"},
{label: "FLUTAMIDE BIOGARAN"},
{label: "FLUTAMIDE BMS"},
{label: "FLUTAMIDE CRISTERS"},
{label: "FLUTAMIDE EG"},
{label: "FLUTAMIDE G GAM"},
{label: "FLUTAMIDE IVAX"},
{label: "FLUTAMIDE MYLAN"},
{label: "FLUTAMIDE TEVA"},
{label: "FLUTENSIF"},
{label: "FLUTIFORM"},
{label: "FLUVALUX ADULTES"},
{label: "FLUVALUX ENFANTS"},
{label: "FLUVALUX JOUR ET NUIT"},
{label: "FLUVARAN"},
{label: "FLUVASTATINE ACCORD LP"},
{label: "FLUVASTATINE ACTAVIS FRANCE L.P."},
{label: "FLUVASTATINE ACTAVIS L.P."},
{label: "FLUVASTATINE ARROW"},
{label: "FLUVASTATINE ARROW L.P."},
{label: "FLUVASTATINE BIOGARAN"},
{label: "FLUVASTATINE BIOGARAN LP"},
{label: "FLUVASTATINE CEVIDRA"},
{label: "FLUVASTATINE CEVIDRA L.P."},
{label: "FLUVASTATINE CRISTERS L.P."},
{label: "FLUVASTATINE EG"},
{label: "FLUVASTATINE EG L.P."},
{label: "FLUVASTATINE EVOLUGEN"},
{label: "FLUVASTATINE EVOLUGEN L.P."},
{label: "FLUVASTATINE G GAM"},
{label: "FLUVASTATINE ISOMED"},
{label: "FLUVASTATINE ISOMED L.P."},
{label: "FLUVASTATINE MYLAN LP"},
{label: "FLUVASTATINE OPENING PHARMA L.P."},
{label: "FLUVASTATINE QUALIMED"},
{label: "FLUVASTATINE QUALIMED L.P."},
{label: "FLUVASTATINE RANBAXY L.P."},
{label: "FLUVASTATINE RATIOPHARM"},
{label: "FLUVASTATINE RATIOPHARM L.P."},
{label: "FLUVASTATINE SANDOZ"},
{label: "FLUVASTATINE SANDOZ LP"},
{label: "FLUVASTATINE TORRENT"},
{label: "FLUVASTATINE VIATRIS"},
{label: "FLUVASTATINE ZENTIVA"},
{label: "FLUVASTATINE ZENTIVA L.P."},
{label: "FLUVASTATINE ZYDUS"},
{label: "FLUVASTATINE ZYDUS L.P."},
{label: "FLUVERMAL"},
{label: "FLUVIC ENFANTS"},
{label: "FLUVIC SANS SUCRE ENFANTS"},
{label: "FLUVIRINE"},
{label: "FLUVOXAMINE ABBOTT"},
{label: "FLUVOXAMINE ARROW"},
{label: "FLUVOXAMINE EG"},
{label: "FLUVOXAMINE SANDOZ"},
{label: "FLUVOXAMINE TEVA"},
{label: "FLUVOXAMINE VIATRIS"},
{label: "FLUXIVAL"},
{label: "FOCETRIA"},
{label: "FOENUM GRAECUM BOIRON"},
{label: "FOLINATE DE CALCIUM AGUETTANT"},
{label: "FOLINATE DE CALCIUM ALTAN"},
{label: "FOLINATE DE CALCIUM ASTA MEDICA"},
{label: "FOLINATE DE CALCIUM BELLON"},
{label: "FOLINATE DE CALCIUM EBEWE"},
{label: "FOLINATE DE CALCIUM EG"},
{label: "FOLINATE DE CALCIUM HIKMA"},
{label: "FOLINATE DE CALCIUM MERCK"},
{label: "FOLINATE DE CALCIUM MYLAN"},
{label: "FOLINATE DE CALCIUM SANDOZ"},
{label: "FOLINATE DE CALCIUM TEVA"},
{label: "FOLINATE DE CALCIUM WINTHROP"},
{label: "FOLINATE DE CALCIUM ZENTIVA"},
{label: "FOLINORAL"},
{label: "FOLLICULINUM BOIRON"},
{label: "FOLLICULINUM LEHNING"},
{label: "FOLTENE ANTI POUX"},
{label: "FOMEPIZOLE AP-HP"},
{label: "FOMEPIZOLE SERB"},
{label: "FOMICYT"},
{label: "FONCITRIL"},
{label: "FONDAPARINUX SODIQUE ASPEN"},
{label: "FONDAPARINUX SODIQUE MYLAN"},
{label: "FONDAPARINUX SODIQUE REDDY PHARMA"},
{label: "FONDERMA"},
{label: "FONDIGYL"},
{label: "FONDIREL"},
{label: "FONGAMIL"},
{label: "FONGAREX"},
{label: "FONGERYL"},
{label: "FONGIDERM"},
{label: "FONGILEINE"},
{label: "FONLIPOL"},
{label: "FONMIG"},
{label: "FONTEX"},
{label: "FONX"},
{label: "FONZYLANE"},
{label: "FORADIL"},
{label: "FORCICAL"},
{label: "FORCICAL VITAMINE D3"},
{label: "FORENE"},
{label: "FORLAX"},
{label: "FORLAXLIB ADULTE"},
{label: "FORMESTANE NOVARTIS PHARMA"},
{label: "FORMICA RUFA BOIRON"},
{label: "FORMICA RUFA COMPOSE BOIRON"},
{label: "FORMICA RUFA LEHNING"},
{label: "FORMICA RUFA WELEDA"},
{label: "FORMICUM ACIDUM BOIRON"},
{label: "FORMICUM ACIDUM LEHNING"},
{label: "FORMICUM ACIDUM WELEDA"},
{label: "FORMOAIR"},
{label: "FORMOCARBINE"},
{label: "FORMODUAL 100/6"},
{label: "FORMODUAL 200/6"},
{label: "FORMODUAL NEXTHALER"},
{label: "FORMOTEROL BIOGARAN"},
{label: "FORMOTEROL EG"},
{label: "FORMOTEROL VIATRIS"},
{label: "FORMOTEROL ZENTIVA"},
{label: "FORMULE"},
{label: "FORMULE AA BAXTER"},
{label: "FORMULE AB BAXTER"},
{label: "FORMULE AC BAXTER"},
{label: "FORNEGA"},
{label: "FORSTEO"},
{label: "FORTACIN"},
{label: "FORTAL"},
{label: "FORTIGEL"},
{label: "FORTOVASE"},
{label: "FORTRANS"},
{label: "FORTUM"},
{label: "FORTUMSET"},
{label: "FORTZAAR"},
{label: "FORXIGA"},
{label: "FOSAMAX"},
{label: "FOSATROL"},
{label: "FOSAVANCE"},
{label: "FOSCAN"},
{label: "FOSCARNET KABI"},
{label: "FOSCARNET MEDISOL"},
{label: "FOSCARNET TILLOMED"},
{label: "FOSCAVIR"},
{label: "FOSFOCINE"},
{label: "FOSFOMYCINE ACTAVIS ADULTES"},
{label: "FOSFOMYCINE ARROW ADULTES"},
{label: "FOSFOMYCINE BIOGARAN"},
{label: "FOSFOMYCINE CRISTERS"},
{label: "FOSFOMYCINE EG ADULTES"},
{label: "FOSFOMYCINE EVOLUGEN"},
{label: "FOSFOMYCINE MYLAN"},
{label: "FOSFOMYCINE PANPHARMA"},
{label: "FOSFOMYCINE RATIOPHARM ADULTES"},
{label: "FOSFOMYCINE SANDOZ ADULTES"},
{label: "FOSFOMYCINE SUBSTIPHARM"},
{label: "FOSFOMYCINE SUN ADULTES"},
{label: "FOSFOMYCINE TEVA ADULTES"},
{label: "FOSFOMYCINE TEVA SANTE ADULTES"},
{label: "FOSFOMYCINE URGO ADULTES"},
{label: "FOSFOMYCINE VIATRIS ADULTES"},
{label: "FOSFOMYCINE ZAMBON"},
{label: "FOSFOMYCINE ZENTIVA ADULTES"},
{label: "FOSFOMYCINE ZYDUS"},
{label: "FOSFOPHARM ADULTES"},
{label: "FOSINOPRIL ALLARD"},
{label: "FOSINOPRIL ARROW"},
{label: "FOSINOPRIL ARROW GENERIQUES"},
{label: "FOSINOPRIL ARROW LAB"},
{label: "FOSINOPRIL BIOGARAN"},
{label: "FOSINOPRIL CRISTERS"},
{label: "FOSINOPRIL DCI PHARMA"},
{label: "FOSINOPRIL EG"},
{label: "FOSINOPRIL HYDROCHLOROTHIAZIDE ARROW"},
{label: "FOSINOPRIL HYDROCHLOROTHIAZIDE EG"},
{label: "FOSINOPRIL HYDROCHLOROTHIAZIDE ZENTIVA"},
{label: "FOSINOPRIL MYLAN"},
{label: "FOSINOPRIL PFIZER"},
{label: "FOSINOPRIL QUALIMED"},
{label: "FOSINOPRIL RANBAXY"},
{label: "FOSINOPRIL RPG"},
{label: "FOSINOPRIL TEVA"},
{label: "FOSINOPRIL ZENTIVA"},
{label: "FOSINOPRIL/HYDROCHLOROTHIAZIDE ARROW LAB"},
{label: "FOSINOPRIL/HYDROCHLOROTHIAZIDE BIOGARAN"},
{label: "FOSINOPRIL/HYDROCHLOROTHIAZIDE CRISTERS"},
{label: "FOSINOPRIL/HYDROCHLOROTHIAZIDE TEVA"},
{label: "FOSINOPRIL/HYDROCHLOROTHIAZIDE VIATRIS"},
{label: "FOSOMEL"},
{label: "FOSOMELPERI"},
{label: "FOSPHENYTOINE ARROW"},
{label: "FOSRENOL"},
{label: "FOSTIMON"},
{label: "FOSTIMONKIT"},
{label: "FOSTROFEMGE"},
{label: "FOTIVDA"},
{label: "FOZIRETIC"},
{label: "FOZITEC"},
{label: "FRACTAL"},
{label: "FRACTAL L.P."},
{label: "FRACTION FLAVONOIQUE PURIFIEE MYLAN PHARMA"},
{label: "FRACTION FLAVONOIQUE PURIFIEE STRAGEN PHARMA"},
{label: "FRAGARIA VESCA BOIRON"},
{label: "FRAGIPREL"},
{label: "FRAGMINE"},
{label: "FRAKIDEX"},
{label: "FRAMYCETINE DEXAMETHASONE CHAUVIN"},
{label: "FRAMYCETINE PHENYLMERCURE GLAXOSMITHKLINE SANTE GRAND PUBLIC"},
{label: "FRAMYXONE"},
{label: "FRAXINOL"},
{label: "FRAXINUS AMERICANA BOIRON"},
{label: "FRAXINUS AMERICANA LEHNING"},
{label: "FRAXIPARINE"},
{label: "FRAXODI"},
{label: "FRENE BOIRON"},
{label: "FRENE INFUSETTE LES BONS PRODUCTEURS"},
{label: "FROBEN"},
{label: "FROBEN L.P."},
{label: "FROVATRIPTAN BIOGARAN"},
{label: "FROVATRIPTAN CLEXNI"},
{label: "FROVATRIPTAN TEVA"},
{label: "FROVATRIPTAN VIATRIS"},
{label: "FRUBIOSE VITAMINE D"},
{label: "FRUCOL"},
{label: "FRUCTINES AU PICOSULFATE DE SODIUM"},
{label: "FUCA FRÊNE"},
{label: "FUCA FUCUS"},
{label: "FUCA REINE DES PRES"},
{label: "FUCIDINE"},
{label: "FUCIDINE ENFANTS"},
{label: "FUCIDINE NOURRISSONS"},
{label: "FUCITHALMIC"},
{label: "FUCUS COMPLEXE N°111"},
{label: "FUCUS CRISPUS BOIRON"},
{label: "FUCUS ETHYPHARM"},
{label: "FUCUS VESICULOSUS BOIRON"},
{label: "FUCUS VESICULOSUS FERRIER"},
{label: "FUCUS VESICULOSUS LEHNING"},
{label: "FUGENOL"},
{label: "FULCINE"},
{label: "FULPHILA"},
{label: "FULVESTRANT ACCORD"},
{label: "FULVESTRANT ARROW"},
{label: "FULVESTRANT BIOGARAN"},
{label: "FULVESTRANT EG"},
{label: "FULVESTRANT EVER PHARMA"},
{label: "FULVESTRANT FARMALAN"},
{label: "FULVESTRANT HIKMA"},
{label: "FULVESTRANT INTAS"},
{label: "FULVESTRANT MYLAN"},
{label: "FULVESTRANT REDDY PHARMA"},
{label: "FULVESTRANT SANDOZ"},
{label: "FULVESTRANT SUN"},
{label: "FULVESTRANT TEVA"},
{label: "FULVESTRANT ZENTIVA"},
{label: "FUMAFER"},
{label: "FUMARATE DE FORMOTEROL NOVARTIS PHARMA"},
{label: "FUMARIA OFFICINALIS BOIRON"},
{label: "FUMARIA OFFICINALIS LEHNING"},
{label: "FUMYTIL"},
{label: "FUNALYX"},
{label: "FUNGIZONE"},
{label: "FUNGIZONE LOTION"},
{label: "FUNGIZONE NOURRISSONS ET ENFANTS"},
{label: "FUNGOLINE"},
{label: "FUNGSTER"},
{label: "FUOGLAIR NOURRISSONS"},
{label: "FUORHINOSE NOURRISSONS"},
{label: "FUOTUX ADULTES"},
{label: "FURADANTINE"},
{label: "FURADOINE"},
{label: "FUROATE DE MOMETASONE SCHERING PLOUGH"},
{label: "FUROSEMIDE ACCORD"},
{label: "FUROSEMIDE ARROW"},
{label: "FUROSEMIDE BIOGARAN"},
{label: "FUROSEMIDE DAKOTA"},
{label: "FUROSEMIDE DAKOTA PHARM"},
{label: "FUROSEMIDE EG"},
{label: "FUROSEMIDE ENIREX"},
{label: "FUROSEMIDE EVOLUGEN"},
{label: "FUROSEMIDE G GAM"},
{label: "FUROSEMIDE KABI"},
{label: "FUROSEMIDE KALCEKS"},
{label: "FUROSEMIDE LAVOISIER"},
{label: "FUROSEMIDE METAZIREX"},
{label: "FUROSEMIDE RANBAXY"},
{label: "FUROSEMIDE RATIOPHARM"},
{label: "FUROSEMIDE RENAUDIN"},
{label: "FUROSEMIDE RPG"},
{label: "FUROSEMIDE SANDOZ"},
{label: "FUROSEMIDE TEVA"},
{label: "FUROSEMIDE TEVA PHARMA B.V."},
{label: "FUROSEMIDE TRANQUIREX"},
{label: "FUROSEMIDE VIATRIS"},
{label: "FUROSEMIDE ZENTIVA"},
{label: "FUROSEMIDE ZYDUS"},
{label: "FUSAFUNGINE ARDIX"},
{label: "FUSAFUNGINE BIOGARAN"},
{label: "FUSAFUNGINE BIOPHARMA"},
{label: "FUSIDATE DE SODIUM BIOGARAN"},
{label: "FUSIDATE DE SODIUM ESSENTIAL PHARMA"},
{label: "FUSIDATE DE SODIUM IDD"},
{label: "FUSIDATE DE SODIUM PIERRE FABRE"},
{label: "FUSIDATE DE SODIUM SPIRIG"},
{label: "FUSIDATE DE SODIUM VIATRIS"},
{label: "FUSIDATE DE SODIUM ZENTIVA"},
{label: "FUZEON"},
{label: "FYBOGEL"},
{label: "FYBRANTA"},
{label: "FYCOMPA"},
{label: "FYREMADEL"},
{label: "FÉBUXOSTAT ARROW"},
{label: "FÉSOTÉRODINE TEVA LP"},
{label: "GABACET"},
{label: "GABAPENTINE ACTAVIS"},
{label: "GABAPENTINE ALTER"},
{label: "GABAPENTINE ARROW"},
{label: "GABAPENTINE ARROW GENERIQUES"},
{label: "GABAPENTINE BIOGARAN"},
{label: "GABAPENTINE CRISTERS"},
{label: "GABAPENTINE CRISTERS PHARMA"},
{label: "GABAPENTINE CTRS"},
{label: "GABAPENTINE EG"},
{label: "GABAPENTINE EG LABO"},
{label: "GABAPENTINE EVOLUGEN"},
{label: "GABAPENTINE G GAM"},
{label: "GABAPENTINE GNR"},
{label: "GABAPENTINE IBD3 PHARMA CONSULTING"},
{label: "GABAPENTINE IPCA"},
{label: "GABAPENTINE IVAX"},
{label: "GABAPENTINE MG PHARMA"},
{label: "GABAPENTINE MYLAN"},
{label: "GABAPENTINE MYLAN PHARMA"},
{label: "GABAPENTINE QUALIMED"},
{label: "GABAPENTINE RANBAXY"},
{label: "GABAPENTINE RATIOPHARM"},
{label: "GABAPENTINE ROSEMONT"},
{label: "GABAPENTINE RPG"},
{label: "GABAPENTINE SANDOZ"},
{label: "GABAPENTINE SUBSTIPHARM"},
{label: "GABAPENTINE SUN"},
{label: "GABAPENTINE TEVA"},
{label: "GABAPENTINE TEVA SANTE"},
{label: "GABAPENTINE VIATRIS"},
{label: "GABAPENTINE VIATRIS GENERIQUES"},
{label: "GABAPENTINE ZENTIVA"},
{label: "GABAPENTINE ZYDUS"},
{label: "GABAPENTINE ZYDUS FRANCE"},
{label: "GABITRIL"},
{label: "GABRENE"},
{label: "GADOGRAF"},
{label: "GADOPENTETATE DE DIMEGLUMINE INSIGHT AGENTS"},
{label: "GADOVIST"},
{label: "GADOVISTAUTO"},
{label: "GADOVISTMANUEL"},
{label: "GAIACOL VACHERON"},
{label: "GAIARSOL"},
{label: "GALACTOGIL"},
{label: "GALAFIN"},
{label: "GALAFOLD"},
{label: "GALANTAMINE ARROW LAB LP"},
{label: "GALANTAMINE ARROW LP"},
{label: "GALANTAMINE BIOGARAN LP"},
{label: "GALANTAMINE CRISTERS LP"},
{label: "GALANTAMINE EG LP"},
{label: "GALANTAMINE KRKA LP"},
{label: "GALANTAMINE MYLAN LP"},
{label: "GALANTAMINE MYLAN PHARMA LP"},
{label: "GALANTAMINE SANDOZ LP"},
{label: "GALANTAMINE TEVA LP"},
{label: "GALANTAMINE TEVA SANTE LP"},
{label: "GALANTAMINE ZENTIVA LAB LP"},
{label: "GALANTAMINE ZYDUS LP"},
{label: "GALANTHEN LP"},
{label: "GALEMA LP"},
{label: "GALENE BOIRON"},
{label: "GALENE WELEDA"},
{label: "GALIRENE"},
{label: "GALIUM APARINE BOIRON"},
{label: "GALIUM APARINE LEHNING"},
{label: "GALLIAD"},
{label: "GALLIAPHARM"},
{label: "GALVUS"},
{label: "GAMBROSOL"},
{label: "GAMCEL"},
{label: "GAMMA-OH"},
{label: "GAMMAGARD"},
{label: "GAMMANORM"},
{label: "GAMMATETANOS"},
{label: "GAMUNEX"},
{label: "GANCICLOVIR MYLAN"},
{label: "GANCICLOVIR SANDOZ"},
{label: "GANFORT"},
{label: "GANGLIONS LYMPHATIQUES BOIRON"},
{label: "GANIDAN"},
{label: "GANIRELIX EG"},
{label: "GANIRELIX GEDEON RICHTER"},
{label: "GANIRELIX THERAMEX"},
{label: "GANZED"},
{label: "GAOPATHYL"},
{label: "GAOPTOL"},
{label: "GAOSEDAL"},
{label: "GAOSEDAL CODEINE"},
{label: "GARASPIRINE"},
{label: "GARDASIL"},
{label: "GARDENAL"},
{label: "GARDRINE"},
{label: "GARGYL"},
{label: "GARNIERITE BOIRON"},
{label: "GASTER GALLI BOIRON"},
{label: "GASTERINE"},
{label: "GASTRALGINE"},
{label: "GASTRALUGEL"},
{label: "GASTRAMINE"},
{label: "GASTREX"},
{label: "GASTRIC EXPANPHARM"},
{label: "GASTRILAX"},
{label: "GASTROBAR"},
{label: "GASTROBUL"},
{label: "GASTROCALM"},
{label: "GASTROCYNESINE"},
{label: "GASTROGRAFINE"},
{label: "GASTROLENA ADULTES"},
{label: "GASTROLENA ENFANTS"},
{label: "GASTROLENA SORBITOL ADULTES"},
{label: "GASTROLENA SORBITOL ENFANTS"},
{label: "GASTROLYTE"},
{label: "GASTROMEP"},
{label: "GASTROPAX"},
{label: "GASTROPHYLE"},
{label: "GASTROPULGITE"},
{label: "GASTROSEDYL"},
{label: "GASTROTISANE"},
{label: "GASTROWELL LOPERAMIDE"},
{label: "GASTROZEPINE"},
{label: "GATOURIL"},
{label: "GAVISCON"},
{label: "GAVISCON CITRON"},
{label: "GAVISCON MENTHE"},
{label: "GAVISCON NOURRISSONS"},
{label: "GAVISCONELL FRUITS DE LA PASSION SANS SUCRE"},
{label: "GAVISCONELL MENTHE FRAICHE"},
{label: "GAVISCONELL MENTHE SANS SUCRE"},
{label: "GAVISCONELL SANS SUCRE FRAISE"},
{label: "GAVISCONELL SANS SUCRE MENTHE"},
{label: "GAVISCONPRO"},
{label: "GAVISCONPRO MENTHE"},
{label: "GAVRETO"},
{label: "GAZYVARO"},
{label: "GCFORM"},
{label: "GECAINE"},
{label: "GEFITINIB ACCORD"},
{label: "GEFITINIB ARROW"},
{label: "GEFITINIB BIOGARAN"},
{label: "GEFITINIB CRISTERS"},
{label: "GEFITINIB EG"},
{label: "GEFITINIB ETHYPHARM"},
{label: "GEFITINIB GENTHON"},
{label: "GEFITINIB HELM"},
{label: "GEFITINIB KRKA"},
{label: "GEFITINIB MYLAN"},
{label: "GEFITINIB SANDOZ"},
{label: "GEFITINIB TEVA"},
{label: "GEFITINIB ZENTIVA"},
{label: "GEILOZEN"},
{label: "GEL A L'ACETOTARTRATE D'ALUMINE DEFRESNE"},
{label: "GEL DE CALAMINE THERICA"},
{label: "GEL LARMES"},
{label: "GEL PHAN"},
{label: "GEL RECTOPANBILINE"},
{label: "GELASER"},
{label: "GELASPAN"},
{label: "GELDENE"},
{label: "GELDOLOR"},
{label: "GELISTROL"},
{label: "GELOFUSINE"},
{label: "GELOGASTRINE"},
{label: "GELOPANBILINE"},
{label: "GELOPECTOSE"},
{label: "GELOTAMIDE"},
{label: "GELOTANIN"},
{label: "GELOX"},
{label: "GELSEMIUM BOIRON"},
{label: "GELSEMIUM COMPLEXE N°70"},
{label: "GELSEMIUM FERRIER"},
{label: "GELSEMIUM LEHNING"},
{label: "GELSEMIUM SEMPERVIRENS"},
{label: "GELTIM LP"},
{label: "GELUCYSTINE"},
{label: "GELUFENE"},
{label: "GELULE LAXATIVE DELPECH"},
{label: "GELUMALINE(AU PARACETAMOL)"},
{label: "GELUPRANE"},
{label: "GELURAP"},
{label: "GELUSIL"},
{label: "GELUSOFT"},
{label: "GELUTROPHYL"},
{label: "GEMCIRENA"},
{label: "GEMCITABINE ACCORD"},
{label: "GEMCITABINE ARROW"},
{label: "GEMCITABINE BIOSTABILEX"},
{label: "GEMCITABINE CHEMICAL FARMA"},
{label: "GEMCITABINE DERMAPHARM"},
{label: "GEMCITABINE EBEWE"},
{label: "GEMCITABINE EBEWE PHARMA FRANCE"},
{label: "GEMCITABINE EG"},
{label: "GEMCITABINE FAIR-MED HEALTHCARE"},
{label: "GEMCITABINE GNR"},
{label: "GEMCITABINE HETERO"},
{label: "GEMCITABINE HIKMA"},
{label: "GEMCITABINE HOSPIRA"},
{label: "GEMCITABINE IBD3"},
{label: "GEMCITABINE IBIGEN"},
{label: "GEMCITABINE KABI"},
{label: "GEMCITABINE MARTINDALE PHARMA"},
{label: "GEMCITABINE MEDAC"},
{label: "GEMCITABINE MYLAN"},
{label: "GEMCITABINE MYLAN PHARMA"},
{label: "GEMCITABINE RATIOPHARM"},
{label: "GEMCITABINE SANDOZ"},
{label: "GEMCITABINE SUN"},
{label: "GEMCITABINE SUN PHARMACEUTICAL INDUSTRIES EUROPE"},
{label: "GEMCITABINE TEVA"},
{label: "GEMCITABINE WINTHROP"},
{label: "GEMTIBA"},
{label: "GEMZAR"},
{label: "GENAC"},
{label: "GENATIB"},
{label: "GENATROPINE"},
{label: "GENCEBOK"},
{label: "GENCIFRICE BAUME PREMIERES DENTS"},
{label: "GENESERINE"},
{label: "GENISTA SCOPARIA BOIRON"},
{label: "GENISTA SCOPARIA WELEDA"},
{label: "GENISTA TINCTORIA BOIRON"},
{label: "GENOLA"},
{label: "GENOPTOL"},
{label: "GENOSCOPOLAMINE"},
{label: "GENOTHYL"},
{label: "GENOTONORM"},
{label: "GENOTONORM KABIQUICK"},
{label: "GENOTONORM KABIVIAL"},
{label: "GENOTONORM MINIQUICK"},
{label: "GENTACORTE"},
{label: "GENTALLINE"},
{label: "GENTAMICINE B. BRAUN"},
{label: "GENTAMICINE CHAUVIN"},
{label: "GENTAMICINE DAKOTA PHARM"},
{label: "GENTAMICINE FAURE"},
{label: "GENTAMICINE LEURQUIN"},
{label: "GENTAMICINE PANPHARMA"},
{label: "GENTAMICINE QUALIMED"},
{label: "GENTAMICINE SANDOZ"},
{label: "GENTAMICINE SARBACH"},
{label: "GENTASONE"},
{label: "GENTIANA LUTEA"},
{label: "GENTIANA LUTEA BOIRON"},
{label: "GENTIANA LUTEA LEHNING"},
{label: "GENTOGRAM"},
{label: "GENTOPTINE"},
{label: "GENTOREX"},
{label: "GENTYLAN"},
{label: "GENVOYA"},
{label: "GERAM"},
{label: "GERAM INJECTABLE"},
{label: "GERANIUM MACULATUM BOIRON"},
{label: "GERANIUM ROBERTIANUM BOIRON"},
{label: "GEREL"},
{label: "GERIMAX"},
{label: "GERMOSE"},
{label: "GEROUSIA"},
{label: "GESPHARM"},
{label: "GESTODENE ETHINYLESTRADIOL ARROW"},
{label: "GESTODENE ETHINYLESTRADIOL GNR"},
{label: "GESTODENE ETHINYLESTRADIOL SANDOZ"},
{label: "GESTODENE-ETHINYLESTRADIOL TEVA"},
{label: "GESTODENE/ETHINYLESTRADIOL ACTAVIS"},
{label: "GESTODENE/ETHINYLESTRADIOL ARROW"},
{label: "GESTODENE/ETHINYLESTRADIOL BGR"},
{label: "GESTODENE/ETHINYLESTRADIOL BIOGARAN"},
{label: "GESTODENE/ETHINYLESTRADIOL CHEMICAL FARMA"},
{label: "GESTODENE/ETHINYLESTRADIOL CRISTERS"},
{label: "GESTODENE/ETHINYLESTRADIOL EG"},
{label: "GESTODENE/ETHINYLESTRADIOL EG LABO"},
{label: "GESTODENE/ETHINYLESTRADIOL IDETECH"},
{label: "GESTODENE/ETHINYLESTRADIOL JELFA PHARMACEUTICAL COMPANY"},
{label: "GESTODENE/ETHINYLESTRADIOL RANBAXY"},
{label: "GESTODENE/ETHINYLESTRADIOL RATIOPHARM"},
{label: "GESTODENE/ETHINYLESTRADIOL SANDOZ"},
{label: "GESTODENE/ETHINYLESTRADIOL STRAGEN FRANCE"},
{label: "GESTODENE/ETHINYLESTRADIOL TEVA"},
{label: "GESTODENE/ETHINYLESTRADIOL VIATRIS"},
{label: "GESTODENE/ETHINYLESTRADIOL ZENTIVA"},
{label: "GESTODENE/ETHINYLESTRADIOL ZYDUS"},
{label: "GESTORAL"},
{label: "GEUM URBANUM"},
{label: "GEUM URBANUM BOIRON"},
{label: "GEVATRAN"},
{label: "GEVRAL"},
{label: "GEVRAL A LA VITAMINE D"},
{label: "GHEMAXAN"},
{label: "GHRYVELIN"},
{label: "GIBITER EASYHALER"},
{label: "GILENYA"},
{label: "GINKGO ARROW CONSEIL"},
{label: "GINKGO BILOBA BOIRON"},
{label: "GINKGO BILOBA FERRIER"},
{label: "GINKGO BILOBA LEHNING"},
{label: "GINKGO BILOBA WELEDA"},
{label: "GINKGO BIOGARAN"},
{label: "GINKGO DEXTREG"},
{label: "GINKGO EG LABO CONSEIL"},
{label: "GINKGO HCS"},
{label: "GINKGO ZENTIVA"},
{label: "GINKMONGO"},
{label: "GINKOGINK"},
{label: "GINKOPLANT"},
{label: "GINKOR"},
{label: "GINKOR FORT"},
{label: "GINKOR PROCTO"},
{label: "GINKORGEL"},
{label: "GINSANA"},
{label: "GINSENG ALPHA"},
{label: "GINSENG ARIK"},
{label: "GINSENG BOIRON"},
{label: "GINSENG ETHYPHARM"},
{label: "GINSENG NATURA MEDICA"},
{label: "GINSENG PLANTES ET MEDECINES"},
{label: "GINSENG PONROY"},
{label: "GIOTRIF"},
{label: "GIRLAMEN L.P."},
{label: "GIVALEX"},
{label: "GIVLAARI"},
{label: "GLATIRAMER VIATRIS"},
{label: "GLAUCADRINE"},
{label: "GLAUCONIE BOIRON"},
{label: "GLAUCOSTAT"},
{label: "GLECHOMA HEDERACEA BOIRON"},
{label: "GLEPARK"},
{label: "GLIADEL"},
{label: "GLIBENCLAMIDE ARROW"},
{label: "GLIBENCLAMIDE BIOGARAN"},
{label: "GLIBENCLAMIDE EG"},
{label: "GLIBENCLAMIDE RANBAXY"},
{label: "GLIBENCLAMIDE RATIOPHARM"},
{label: "GLIBENCLAMIDE SANDOZ"},
{label: "GLIBENCLAMIDE TEVA"},
{label: "GLIBENCLAMIDE VIATRIS"},
{label: "GLIBENESE"},
{label: "GLICAVENI"},
{label: "GLICLAZIDE ACTAVIS"},
{label: "GLICLAZIDE ALTER"},
{label: "GLICLAZIDE ARROW"},
{label: "GLICLAZIDE ARROW GENERIQUES"},
{label: "GLICLAZIDE ARROW LAB"},
{label: "GLICLAZIDE BGR"},
{label: "GLICLAZIDE BIOGARAN"},
{label: "GLICLAZIDE CHEMICAL FARMA"},
{label: "GLICLAZIDE CRISTERS"},
{label: "GLICLAZIDE EG"},
{label: "GLICLAZIDE EG LABO"},
{label: "GLICLAZIDE EG LABO LABORATOIRES EUROGENERICS"},
{label: "GLICLAZIDE EVOLUGEN"},
{label: "GLICLAZIDE GNR"},
{label: "GLICLAZIDE IBD3 SET"},
{label: "GLICLAZIDE ISOMED"},
{label: "GLICLAZIDE IVAX"},
{label: "GLICLAZIDE KRKA"},
{label: "GLICLAZIDE MILGEN"},
{label: "GLICLAZIDE MYLAN PHARMA"},
{label: "GLICLAZIDE PHARMANUI"},
{label: "GLICLAZIDE PIERRE FABRE"},
{label: "GLICLAZIDE QUALIMED"},
{label: "GLICLAZIDE QUIVER"},
{label: "GLICLAZIDE RANBAXY PHARMACIE GENERIQUES"},
{label: "GLICLAZIDE RATIOPHARM"},
{label: "GLICLAZIDE RPG"},
{label: "GLICLAZIDE SANDOZ"},
{label: "GLICLAZIDE SERVIER"},
{label: "GLICLAZIDE SUN"},
{label: "GLICLAZIDE TEVA"},
{label: "GLICLAZIDE VIATRIS"},
{label: "GLICLAZIDE ZENTIVA"},
{label: "GLICLAZIDE ZYDUS"},
{label: "GLICLAZIDE ZYDUS FRANCE"},
{label: "GLIGAMUS"},
{label: "GLIMEPIRIDE ACCORD HEALTHCARE"},
{label: "GLIMEPIRIDE ACTAVIS"},
{label: "GLIMEPIRIDE ALMUS"},
{label: "GLIMEPIRIDE ALTER"},
{label: "GLIMEPIRIDE ANDISSA"},
{label: "GLIMEPIRIDE ARROW"},
{label: "GLIMEPIRIDE ARROW GENERIQUES"},
{label: "GLIMEPIRIDE BGR"},
{label: "GLIMEPIRIDE CHEMICAL FARMA"},
{label: "GLIMEPIRIDE CRISTERS"},
{label: "GLIMEPIRIDE EG"},
{label: "GLIMEPIRIDE EVOLUGEN"},
{label: "GLIMEPIRIDE G GAM"},
{label: "GLIMEPIRIDE IBD3 PHARMA CONSULTING"},
{label: "GLIMEPIRIDE MYLAN"},
{label: "GLIMEPIRIDE QUALIHEALTH"},
{label: "GLIMEPIRIDE QUALIMED"},
{label: "GLIMEPIRIDE QUIVER"},
{label: "GLIMEPIRIDE RANBAXY"},
{label: "GLIMEPIRIDE RATIO"},
{label: "GLIMEPIRIDE RATIOPHARM"},
{label: "GLIMEPIRIDE RATIOPHARM GENERIQUES"},
{label: "GLIMEPIRIDE SANDOZ"},
{label: "GLIMEPIRIDE SANWIN"},
{label: "GLIMEPIRIDE TEVA"},
{label: "GLIMEPIRIDE TEVA SANTE"},
{label: "GLIMEPIRIDE VIATRIS"},
{label: "GLIMEPIRIDE WINTHROP"},
{label: "GLIMEPIRIDE ZENTIVA"},
{label: "GLIMEPIRIDE ZYDUS"},
{label: "GLIMEZIT"},
{label: "GLIOLAN"},
{label: "GLIPIZIDE MYLAN"},
{label: "GLIVEC"},
{label: "GLOBISINE ADULTES"},
{label: "GLOBISINE ENFANTS"},
{label: "GLONOINUM BOIRON"},
{label: "GLONOINUM LEHNING"},
{label: "GLONOINUM WELEDA"},
{label: "GLOREPINAD"},
{label: "GLOSSITHIASE"},
{label: "GLOTTYL"},
{label: "GLUCAGEN"},
{label: "GLUCAGEN KIT"},
{label: "GLUCANTIME"},
{label: "GLUCIDION G"},
{label: "GLUCIDION LACTATE"},
{label: "GLUCIDORAL"},
{label: "GLUCINAN"},
{label: "GLUCOLESS"},
{label: "GLUCONATE DE CALCIUM"},
{label: "GLUCONATE DE CALCIUM A"},
{label: "GLUCONATE DE CALCIUM COOPER"},
{label: "GLUCONATE DE CALCIUM LAVOISIER"},
{label: "GLUCONATE DE CALCIUM PROAMP"},
{label: "GLUCONATE DE CALCIUM RENAUDIN"},
{label: "GLUCONATE DE CHLORHEXIDINE ALCOOLIQUE A"},
{label: "GLUCONATE DE CHLORHEXIDINE ALCOOLIQUE GIFRER A"},
{label: "GLUCONATE DE CHLORHEXIDINE BIOGALENIQUE"},
{label: "GLUCONATE DE CHLORHEXIDINE CRISTERS"},
{label: "GLUCONATE DE CHLORHEXIDINE DEXO"},
{label: "GLUCONATE DE CHLORHEXIDINE GIFRER"},
{label: "GLUCONATE DE CHLORHEXIDINE H3 SANTE"},
{label: "GLUCONATE DE CHLORHEXIDINE PHARMEXOR"},
{label: "GLUCOPHAGE"},
{label: "GLUCOR"},
{label: "GLUCOSAMINE VENIPHARM"},
{label: "GLUCOSE"},
{label: "GLUCOSE AGUETTANT"},
{label: "GLUCOSE B BRAUN"},
{label: "GLUCOSE B. BRAUN"},
{label: "GLUCOSE B.BRAUN"},
{label: "GLUCOSE BAXTER"},
{label: "GLUCOSE BIOSEDRA"},
{label: "GLUCOSE COOPER"},
{label: "GLUCOSE FRESENIUS"},
{label: "GLUCOSE FRESENIUS MEDICAL CARE"},
{label: "GLUCOSE LAVOISIER"},
{label: "GLUCOSE PERFUFLEX"},
{label: "GLUCOSE PHARMACIE CENTRALE DES ARMEES"},
{label: "GLUCOSE PHYSIOFLEX"},
{label: "GLUCOSE RENAUDIN"},
{label: "GLUCOSIS."},
{label: "GLUCOTEP"},
{label: "GLUCOTRACE"},
{label: "GLUCOVANCE"},
{label: "GLURIAD"},
{label: "GLUSCAN"},
{label: "GLUTAMAG VITAMINE"},
{label: "GLUTAMICA"},
{label: "GLUTAMINOL B6"},
{label: "GLUTRIL"},
{label: "GLYBERA"},
{label: "GLYCELAX ADULTE"},
{label: "GLYCELAX ENFANT"},
{label: "GLYCELAX NOURRISSON"},
{label: "GLYCERINE COOPER"},
{label: "GLYCERINUM BOIRON"},
{label: "GLYCEROL/VASELINE/PARAFFINE ARROW"},
{label: "GLYCEROL/VASELINE/PARAFFINE BGR"},
{label: "GLYCEROL/VASELINE/PARAFFINE BIOGARAN"},
{label: "GLYCEROL/VASELINE/PARAFFINE CRISTERS"},
{label: "GLYCEROL/VASELINE/PARAFFINE EG"},
{label: "GLYCEROL/VASELINE/PARAFFINE MG PHARMA"},
{label: "GLYCEROL/VASELINE/PARAFFINE PIERRE FABRE SANTE"},
{label: "GLYCEROL/VASELINE/PARAFFINE REF"},
{label: "GLYCEROL/VASELINE/PARAFFINE SANDOZ"},
{label: "GLYCEROL/VASELINE/PARAFFINE SUBSTIPHARM"},
{label: "GLYCEROL/VASELINE/PARAFFINE TEVA"},
{label: "GLYCEROL/VASELINE/PARAFFINE VIATRIS"},
{label: "GLYCEROL/VASELINE/PARAFFINE ZENTIVA"},
{label: "GLYCEROL/VASELINE/PARAFFINE ZYDUS"},
{label: "GLYCEROTONE"},
{label: "GLYCO-THYMOLINE"},
{label: "GLYCOBYL"},
{label: "GLYCONOR"},
{label: "GLYCYRRHIZA GLABRA BOIRON"},
{label: "GLYDIUM"},
{label: "GLYDO"},
{label: "GLYMAGEL"},
{label: "GLYPOLIX"},
{label: "GLYPRESSINE"},
{label: "GLYVAPA"},
{label: "GLYVENOL"},
{label: "GLYXAMBI"},
{label: "GNAPHALIUM DIOICUM BOIRON"},
{label: "GNAPHALIUM POLYCEPHALUM BOIRON"},
{label: "GNAPHALIUM POLYCEPHALUM LEHNING"},
{label: "GODFRIN"},
{label: "GOMENOL"},
{label: "GOMENOL ONGUENT"},
{label: "GOMENOL RECTAL ADULTES"},
{label: "GOMENOL RECTAL ENFANTS"},
{label: "GOMENOL SOLUBLE"},
{label: "GOMENOLEO"},
{label: "GONADOTROPHINE CHORIONIQUE ENDO"},
{label: "GONADOTROPHINE CHORIONIQUE IBSA"},
{label: "GONAL-F"},
{label: "GONAPEPTYL"},
{label: "GONDRIL"},
{label: "GONIOSOL"},
{label: "GONOTOXINUM BOIRON"},
{label: "GOPTEN"},
{label: "GORDIAN"},
{label: "GOSEGYNE"},
{label: "GOSERELINE ASTRAZENECA"},
{label: "GOSSYPIUM HERBACEUM BOIRON"},
{label: "GOUTTES AUX ESSENCES"},
{label: "GRABASID"},
{label: "GRAFALON"},
{label: "GRAIN D'EVIAN"},
{label: "GRAINS DE VALS"},
{label: "GRAMIDIL"},
{label: "GRAMIRHINE"},
{label: "GRANIONS D'ARGENT"},
{label: "GRANIONS D'OR"},
{label: "GRANIONS D'URANIUM"},
{label: "GRANIONS DE BISMUTH"},
{label: "GRANIONS DE CUIVRE"},
{label: "GRANIONS DE LITHIUM"},
{label: "GRANIONS DE MAGNESIUM"},
{label: "GRANIONS DE MANGANESE"},
{label: "GRANIONS DE SELENIUM"},
{label: "GRANIONS DE SOUFRE"},
{label: "GRANIONS DE ZINC"},
{label: "GRANISETRON ACTAVIS"},
{label: "GRANISETRON B BRAUN"},
{label: "GRANISETRON MEDIS"},
{label: "GRANISETRON MYLAN"},
{label: "GRANISETRON SANDOZ"},
{label: "GRANISETRON TEVA"},
{label: "GRANOCYTE"},
{label: "GRANPIDAM"},
{label: "GRANUDOXY"},
{label: "GRANUPAS"},
{label: "GRANUSORBOL"},
{label: "GRANWELL"},
{label: "GRAPHITES BOIRON"},
{label: "GRAPHITES LEHNING"},
{label: "GRAPHITES WELEDA"},
{label: "GRASTOFIL"},
{label: "GRASUSTEK"},
{label: "GRATIOLA OFFICINALIS BOIRON"},
{label: "GRAZAX"},
{label: "GREPID"},
{label: "GRES ROSE BOIRON"},
{label: "GRINAZOLE"},
{label: "GRINDELIA BOIRON"},
{label: "GRINDELIA LEHNING"},
{label: "GRIPGUARD"},
{label: "GRIPPONYL"},
{label: "GRISEFULINE"},
{label: "GRIVALON"},
{label: "GRUMABIX"},
{label: "GRYSTELLA"},
{label: "GUAIACUM BOIRON"},
{label: "GUAIACUM LEHNING"},
{label: "GUAIFENESINE LACHARTRE ENFANTS"},
{label: "GUARAMATE"},
{label: "GUARANA BOIRON"},
{label: "GUETHURAL"},
{label: "GUIOX"},
{label: "GURONSAN"},
{label: "GUTRON"},
{label: "GYALME"},
{label: "GYDRELLE"},
{label: "GYMISO"},
{label: "GYN HYDRALIN"},
{label: "GYNEFLASH"},
{label: "GYNERGENE CAFEINE"},
{label: "GYNESCAL"},
{label: "GYNO DAKTARIN"},
{label: "GYNO PEVARYL"},
{label: "GYNO TROSYD"},
{label: "GYNO-PEVARYL"},
{label: "GYNO-PEVARYL LP"},
{label: "GYNO-TROSYD"},
{label: "GYNOFILM"},
{label: "GYNOFOSTROME"},
{label: "GYNOGYNE"},
{label: "GYNOMYK"},
{label: "GYNOPLIX"},
{label: "GYNOPURA"},
{label: "GYNOPURA L.P."},
{label: "GYNOSPASMINE"},
{label: "HAEMACCEL"},
{label: "HAEMOPRESSIN"},
{label: "HAFAXIN LP"},
{label: "HALAVEN"},
{label: "HALCION"},
{label: "HALDOL"},
{label: "HALDOL DECANOAS"},
{label: "HALDOL FAIBLE"},
{label: "HALFAN"},
{label: "HALIVITE"},
{label: "HALLS MENTHO LYPTUS"},
{label: "HALOG"},
{label: "HALOG NEOMYCINE"},
{label: "HALOPERIDOL RATIOPHARM"},
{label: "HALOPERIDOL RENAUDIN"},
{label: "HALOPROL RATIOPHARM"},
{label: "HALOTESTIN"},
{label: "HALOTHANE BELAMONT"},
{label: "HALOTHANE TROFIELD"},
{label: "HAMAMELIDE P"},
{label: "HAMAMELIS BOIRON"},
{label: "HAMAMELIS COMPOSE BOIRON"},
{label: "HAMAMELIS ETHYPHARM"},
{label: "HAMAMELIS FERRIER"},
{label: "HAMAMELIS INFUSETTE LES BONS PODUCTEURS"},
{label: "HAMAMELIS LEHNING"},
{label: "HANTRUX"},
{label: "HARMONET"},
{label: "HARPACINE"},
{label: "HARPAGESIC"},
{label: "HARPAGON"},
{label: "HARPAGOPHYTON DIAPHARM"},
{label: "HARPAGOPHYTON ETHYPHARM"},
{label: "HARPAGOPHYTUM BOIRON"},
{label: "HARPAGOPHYTUM FERRIER"},
{label: "HARPAGOPHYTUM LEHNING"},
{label: "HARPAGOPHYTUM PONROY"},
{label: "HARPAGOPHYTUM WELEDA"},
{label: "HARPAGOREL"},
{label: "HARPASALEN"},
{label: "HARVONI"},
{label: "HAVLANE"},
{label: "HAVRIX"},
{label: "HAVRIX ADULTES"},
{label: "HAVRIX NOURRISSONS ET ENFANTS"},
{label: "HAXIFAL"},
{label: "HB VAX DNA"},
{label: "HBVAXPRO"},
{label: "HEAFUSINE"},
{label: "HEC"},
{label: "HEDERA HELIX BOIRON"},
{label: "HEDERA HELIX LEHNING"},
{label: "HEDETUS"},
{label: "HEGOR SHAMPOOING ANTIPARASITAIRE"},
{label: "HEKLA LAVA BOIRON"},
{label: "HELIANTHUS ANNUUS BOIRON"},
{label: "HELICIDINE"},
{label: "HELICOBACTER TEST INFAI"},
{label: "HELIKIT"},
{label: "HELIXATE"},
{label: "HELIXATE NEXGEN"},
{label: "HELLEBORUS NIGER"},
{label: "HELLEBORUS NIGER BOIRON"},
{label: "HELLEBORUS NIGER LEHNING"},
{label: "HELMIN"},
{label: "HELMINTOX"},
{label: "HELONIAS COMPOSE BOIRON"},
{label: "HELONIAS DIOICA BOIRON"},
{label: "HELONIAS DIOICA LEHNING"},
{label: "HEMAGENE TAILLEUR"},
{label: "HEMANGIOL"},
{label: "HEMATITE BOIRON"},
{label: "HEMATITE WELEDA"},
{label: "HEMATOCIS."},
{label: "HEMEDONINE"},
{label: "HEMI-DAONIL"},
{label: "HEMICORT"},
{label: "HEMIGOXINE NATIVELLE"},
{label: "HEMIPRALON L P"},
{label: "HEMLIBRA"},
{label: "HEMOBIEF"},
{label: "HEMOCLAR"},
{label: "HEMODEX"},
{label: "HEMOFIL M"},
{label: "HEMOLEVEN"},
{label: "HEMOLUOL"},
{label: "HEMOR PHARM"},
{label: "HEMORROGEL"},
{label: "HEMOSOL B0"},
{label: "HEPACHOLINE"},
{label: "HEPACLEM"},
{label: "HEPADIAL"},
{label: "HEPAGRUME"},
{label: "HEPANEPHROL"},
{label: "HEPAR SULFUR (HEPAR SULFURIS CALCAREUM) BOIRON"},
{label: "HEPAR SULFUR LEHNING"},
{label: "HEPAR SULFUR WELEDA"},
{label: "HEPAREXINE"},
{label: "HEPARGITOL"},
{label: "HEPARINE"},
{label: "HEPARINE CALCIQUE DAKOTA PHARM"},
{label: "HEPARINE CALCIQUE DAKOTA PHARM 12.500 U.I./0"},
{label: "HEPARINE CALCIQUE DAKOTA PHARM 20.000 U.I./0"},
{label: "HEPARINE CALCIQUE DAKOTA PHARM 25.000 U.I./1"},
{label: "HEPARINE CALCIQUE DAKOTA PHARM 5.000 U.I/0"},
{label: "HEPARINE CALCIQUE PANPHARMA"},
{label: "HEPARINE CALCIQUE PANPHARMA 12.500 UI/0"},
{label: "HEPARINE CALCIQUE PANPHARMA 20.000 UI/0"},
{label: "HEPARINE CALCIQUE PANPHARMA 25.000 UI/1"},
{label: "HEPARINE CALCIQUE PANPHARMA 5.000 UI/0"},
{label: "HEPARINE CALCIQUE PANPHARMA 7.500 UI/0"},
{label: "HEPARINE CHOAY"},
{label: "HEPARINE LEO"},
{label: "HEPARINE ROCHE"},
{label: "HEPARINE SODIQUE DAKOTA PHARM"},
{label: "HEPARINE SODIQUE LEO"},
{label: "HEPARINE SODIQUE LEURQUIN MEDIOLANUM"},
{label: "HEPARINE SODIQUE PANPHARMA"},
{label: "HEPARINE SOUS CUTANEE ROCHE"},
{label: "HEPASANE"},
{label: "HEPATATE"},
{label: "HEPATINE BOIRON"},
{label: "HEPATINE WELEDA"},
{label: "HEPATO-DRAINOL"},
{label: "HEPATO-SOLUTINES"},
{label: "HEPATOCIS"},
{label: "HEPATOFLORINE"},
{label: "HEPATOREX"},
{label: "HEPATOSTIMULINE"},
{label: "HEPATOUM"},
{label: "HEPATOUM BALLONNEMENTS DIGESTIFS"},
{label: "HEPATOUM BRULURES D'ESTOMAC"},
{label: "HEPATRAN"},
{label: "HEPAX"},
{label: "HEPCLUDEX"},
{label: "HEPSERA"},
{label: "HEPT A MYL"},
{label: "HEPTAMINOL H2 PHARMA"},
{label: "HEPTAN INJECTABLE"},
{label: "HEPTYLATE DE TESTOSTERONE S.E.R.P."},
{label: "HERACLEUM SPHONDYLIUM BOIRON"},
{label: "HERBESAN"},
{label: "HERBESAN INSTANTANE"},
{label: "HERBION EXPECTORANT LIERRE"},
{label: "HERCEPTIN"},
{label: "HERPESEDERMYL"},
{label: "HERPEVIR 5%"},
{label: "HERVEA"},
{label: "HERZUMA"},
{label: "HESTA"},
{label: "HESTERIL"},
{label: "HEXABRIX"},
{label: "HEXAGRIP VITAMINE C"},
{label: "HEXALENSE"},
{label: "HEXALYSE"},
{label: "HEXAMIDINE GILBERT"},
{label: "HEXAMIDINE MYLAN"},
{label: "HEXAMIDINE RATIOPHARM"},
{label: "HEXAMIDINE URGO"},
{label: "HEXAPHLOGIN"},
{label: "HEXAPNEUMINE"},
{label: "HEXAPNEUMINE ADULTES"},
{label: "HEXAPNEUMINE ENFANTS"},
{label: "HEXAPNEUMINE NOURRISSONS"},
{label: "HEXAQUINE"},
{label: "HEXAQUINE ADULTES"},
{label: "HEXARHUME"},
{label: "HEXASEPTINE"},
{label: "HEXASPRAY"},
{label: "HEXASPRAY FRUITS EXOTIQUES"},
{label: "HEXASTAT"},
{label: "HEXATRIONE"},
{label: "HEXAVAC"},
{label: "HEXETIDINE BIOGARAN CONSEIL"},
{label: "HEXETIDINE EG LABO CONSEIL"},
{label: "HEXETIDINE SANDOZ CONSEIL"},
{label: "HEXETIDINE TEVA"},
{label: "HEXETIDINE VIATRIS CONSEIL"},
{label: "HEXICALM"},
{label: "HEXICID"},
{label: "HEXO-IMOTRYL"},
{label: "HEXOMEDINE"},
{label: "HEXOMEDINE TRANSCUTANEE"},
{label: "HEXTRIL"},
{label: "HEXTRIL MENTHE"},
{label: "HEXVIX"},
{label: "HEXYON"},
{label: "HIBERIX"},
{label: "HIBEST"},
{label: "HIBIDIL"},
{label: "HIBISCRUB 4%"},
{label: "HIBISPRINT"},
{label: "HIBITANE"},
{label: "HIBITANE CHAMP"},
{label: "HICONBEN"},
{label: "HICONCIL"},
{label: "HIDONAC"},
{label: "HINARTUM"},
{label: "HIPPOPHAN"},
{label: "HIPPOVENO"},
{label: "HIROBRIZ BREEZHALER"},
{label: "HIRUCREME"},
{label: "HIRUDOL"},
{label: "HIRUPROCT"},
{label: "HISMANAL"},
{label: "HISTAMINUM BOIRON"},
{label: "HISTAMINUM LEHNING"},
{label: "HISTAPAISYL"},
{label: "HISTOFLUINE P"},
{label: "HIVID"},
{label: "HIZENTRA"},
{label: "HOANG NAN BOIRON"},
{label: "HOLARRHENA ANTIDYSENTERICA BOIRON"},
{label: "HOLGYEME"},
{label: "HOLOCLAR"},
{label: "HOLOXAN"},
{label: "HOMEOAFTYL"},
{label: "HOMEOCYST"},
{label: "HOMEODIGEO"},
{label: "HOMEODRAIN"},
{label: "HOMEOFORTIL"},
{label: "HOMEOGENE"},
{label: "HOMEOMUNYL"},
{label: "HOMEONORMYL"},
{label: "HOMEOPLASMINE"},
{label: "HOMEOPTIC"},
{label: "HOMEOQUINTYL"},
{label: "HOMEOREGUL"},
{label: "HOMEORUB"},
{label: "HOMEORYL"},
{label: "HOMEOTABAC"},
{label: "HOMEOTONYL"},
{label: "HOMEOVOX"},
{label: "HORMOCOQ"},
{label: "HOSPASOL145"},
{label: "HOSPASOL167"},
{label: "HUBERIGINE"},
{label: "HUILE CAMPHREE GIFRER"},
{label: "HUILE DE FOIE DE MORUE COOPER"},
{label: "HUILE DE PARAFFINE A L'ESSENCE DE BASILIC GIFRER"},
{label: "HUILE DE PARAFFINE COOPER"},
{label: "HUILE DE PARAFFINE GIFRER"},
{label: "HUILE DE PARAFFINE GILBERT"},
{label: "HUILE DE RICIN COOPER"},
{label: "HUILE DE RICIN DU LABORATOIRE DE LA TISANE PROVENCALE"},
{label: "HUILE DE RICIN GIFRER"},
{label: "HUILE ESSENTIELLE DE LAVANDE SCHWABE"},
{label: "HUILE ESSENTIELLE DE MENTHE SERVIER"},
{label: "HUILE GOMENOLEE"},
{label: "HUKYNDRA"},
{label: "HULIO"},
{label: "HUMALOG"},
{label: "HUMALOG BASAL"},
{label: "HUMALOG MIX"},
{label: "HUMALOG MIX25"},
{label: "HUMALOG MIX50"},
{label: "HUMALOG NPL"},
{label: "HUMALOG PEN"},
{label: "HUMALOG-HUMAJECT"},
{label: "HUMEGON"},
{label: "HUMEX"},
{label: "HUMEX ADULTES EXPECTORANT"},
{label: "HUMEX ADULTES TOUX SECHE DEXTROMETHORPHANE"},
{label: "HUMEX ADULTES TOUX SECHE DEXTROMETHORPHANE SANS SUCRE"},
{label: "HUMEX ADULTES TOUX SECHE PHOLCODINE"},
{label: "HUMEX ALLERGIE CETIRIZINE"},
{label: "HUMEX ALLERGIE LORATADINE"},
{label: "HUMEX CONJONCTIVITE ALLERGIQUE"},
{label: "HUMEX ENFANTS EXPECTORANT CARBOCISTEINE"},
{label: "HUMEX ENFANTS TOUX SECHE DEXTROMETHORPHANE"},
{label: "HUMEX ENFANTS TOUX SECHE PHOLCODINE"},
{label: "HUMEX EXPECTORANT CARBOCISTEINE"},
{label: "HUMEX EXPECTORANT SANS SUCRE"},
{label: "HUMEX FOURNIER"},
{label: "HUMEX GORGE IRRITEE LIDOCAINE"},
{label: "HUMEX INHALER"},
{label: "HUMEX MAL DE GORGE BICLOTYMOL"},
{label: "HUMEX MAL DE GORGE LIDOCAINE/ALCOOL DICHLOROBENZYLIQUE/AMYLMETACRESOL"},
{label: "HUMEX MAL DE GORGE LIDOCAINE/BENZALKONIUM"},
{label: "HUMEX MAL DE GORGE SANS SUCRE"},
{label: "HUMEX RHINITE ALLERGIQUE"},
{label: "HUMEX RHUME"},
{label: "HUMEX RHUME DES FOINS A LA BECLOMETASONE"},
{label: "HUMEX TOUX SECHE OXOMEMAZINE"},
{label: "HUMEXLIB ETAT GRIPPAL PARACETAMOL/VITAMINE C/PHENIRAMINE"},
{label: "HUMEXLIB PARACETAMOL CHLORPHENAMINE"},
{label: "HUMIRA"},
{label: "HUMORYL"},
{label: "HUMOXAL"},
{label: "HUMULUS LUPULUS RECENS WELEDA"},
{label: "HUMULUS LUPULUS SICCUM BOIRON"},
{label: "HURA BRASILIENSIS BOIRON"},
{label: "HUVANOF"},
{label: "HYALGAN"},
{label: "HYALURONIDASE CHOAY"},
{label: "HYCAMTIN"},
{label: "HYDAGELAN"},
{label: "HYDERGINE"},
{label: "HYDRACORT"},
{label: "HYDRAFUCA"},
{label: "HYDRALARM"},
{label: "HYDRALIN"},
{label: "HYDRAPERF"},
{label: "HYDRASTIS CANADENSIS"},
{label: "HYDRASTIS CANADENSIS BOIRON"},
{label: "HYDRASTIS CANADENSIS LEHNING"},
{label: "HYDRASTIS COMPOSE BOIRON"},
{label: "HYDREA"},
{label: "HYDROCHLOROTHIAZIDE ARROW"},
{label: "HYDROCHLOROTHIAZIDE EG"},
{label: "HYDROCLONAZONE"},
{label: "HYDROCORTANCYL"},
{label: "HYDROCORTISONE LEURQUIN"},
{label: "HYDROCORTISONE PANPHARMA"},
{label: "HYDROCORTISONE ROUSSEL"},
{label: "HYDROCORTISONE SUBSTIPHARM"},
{label: "HYDROCORTISONE UPJOHN"},
{label: "HYDROCOTYLE ASIATICA BOIRON"},
{label: "HYDROCOTYLE ASIATICA LEHNING"},
{label: "HYDROCYANICUM ACIDUM BOIRON"},
{label: "HYDROS G"},
{label: "HYDROSARPAN"},
{label: "HYDROSOL POLYVITAMINE B.O.N"},
{label: "HYDROSOL POLYVITAMINE B.O.N."},
{label: "HYDROSOL POLYVITAMINE PHARMADEVELOPPEMENT"},
{label: "HYDROSOL POLYVITAMINE ROCHE"},
{label: "HYDROTALCITE BAYER"},
{label: "HYDROXO"},
{label: "HYDROXYCARBAMIDE SANDOZ"},
{label: "HYDROXYDES D'ALUMINIUM ET DE MAGNESIUM ARROW CONSEIL"},
{label: "HYDROXYDES D'ALUMINIUM ET DE MAGNESIUM SANDOZ CONSEIL"},
{label: "HYDROXYPROGESTERONE RETARD THERAMEX"},
{label: "HYDROXYZINE ARROW"},
{label: "HYDROXYZINE BIOGARAN"},
{label: "HYDROXYZINE BLUEFISH"},
{label: "HYDROXYZINE CRISTERS"},
{label: "HYDROXYZINE EG"},
{label: "HYDROXYZINE RENAUDIN"},
{label: "HYDROXYZINE SANDOZ"},
{label: "HYDROXYZINE TEVA"},
{label: "HYDROXYZINE VIATRIS"},
{label: "HYDROXYZINE ZENTIVA"},
{label: "HYGROTON"},
{label: "HYOSCYAMUS NIGER BOIRON"},
{label: "HYOSCYAMUS NIGER LEHNING"},
{label: "HYOSCYAMUS NIGER WELEDA"},
{label: "HYPERAMINE"},
{label: "HYPERHES"},
{label: "HYPERICUM COMPLEXE N°26"},
{label: "HYPERICUM PERFORATUM BOIRON"},
{label: "HYPERICUM PERFORATUM FERRIER"},
{label: "HYPERICUM PERFORATUM LEHNING"},
{label: "HYPERICUM PERFORATUM WELEDA"},
{label: "HYPERIUM"},
{label: "HYPERIZEN"},
{label: "HYPNASMINE ADULTES"},
{label: "HYPNASMINE ENFANTS"},
{label: "HYPNEINE"},
{label: "HYPNOMIDATE"},
{label: "HYPNOVEL"},
{label: "HYPOLOC"},
{label: "HYPOPHYSINE BOIRON"},
{label: "HYPOPHYSINE L.A. BOIRON"},
{label: "HYPOPHYSINE L.P. BOIRON"},
{label: "HYPOPHYSINE WELEDA"},
{label: "HYPOSTAMINE"},
{label: "HYPOSULFENE"},
{label: "HYPOTHALAMUS BOIRON"},
{label: "HYPOTHALAMUS WELEDA"},
{label: "HYQVIA"},
{label: "HYRIMOZ"},
{label: "HYTACAND"},
{label: "HYTRINE"},
{label: "HYZAAR"},
{label: "IASOCHOLINE"},
{label: "IASOFLU"},
{label: "IBANDROMYLAN"},
{label: "IBERIS AMARA BOIRON"},
{label: "IBIS"},
{label: "IBITAZINA"},
{label: "IBLIAS"},
{label: "IBRANCE"},
{label: "IBUALGIC"},
{label: "IBUCAPS"},
{label: "IBUFEN"},
{label: "IBUFETUM"},
{label: "IBUHEXAL"},
{label: "IBUPRADOLL"},
{label: "IBUPROFENE"},
{label: "IBUPROFENE ACCORD"},
{label: "IBUPROFENE ACTAVIS"},
{label: "IBUPROFENE AGI PHARMA"},
{label: "IBUPROFENE ALMUS"},
{label: "IBUPROFENE ALTISO"},
{label: "IBUPROFENE ARROW"},
{label: "IBUPROFENE ARROW CONSEIL"},
{label: "IBUPROFENE ARROW LAB"},
{label: "IBUPROFENE ATHENA PHARMACEUTIQUES"},
{label: "IBUPROFENE B. BRAUN"},
{label: "IBUPROFENE BANNER"},
{label: "IBUPROFENE BIO VALYS"},
{label: "IBUPROFENE BIOGARAN"},
{label: "IBUPROFENE BIOGARAN CONSEIL"},
{label: "IBUPROFENE BOEHRINGER INGELHEIM"},
{label: "IBUPROFENE CEDIAT MEDIFFUSION"},
{label: "IBUPROFENE CHANELLE"},
{label: "IBUPROFENE CHEMINEAU"},
{label: "IBUPROFENE CRISTERS"},
{label: "IBUPROFENE CRISTERS CONSEIL"},
{label: "IBUPROFENE DERMOGEN"},
{label: "IBUPROFENE DU SAINT BERNARD"},
{label: "IBUPROFENE EG"},
{label: "IBUPROFENE EG LABO CONSEIL"},
{label: "IBUPROFENE ELAIAPHARM"},
{label: "IBUPROFENE ETHYPHARM"},
{label: "IBUPROFENE ETHYPHARM L.P.300"},
{label: "IBUPROFENE EVOLUGEN"},
{label: "IBUPROFENE EVOLUGEN PHARMA"},
{label: "IBUPROFENE FARMALIDER"},
{label: "IBUPROFENE G GAM"},
{label: "IBUPROFENE GALEPHAR"},
{label: "IBUPROFENE GIBAUD"},
{label: "IBUPROFENE ISOMED"},
{label: "IBUPROFENE JOLLY JATEL"},
{label: "IBUPROFENE MEDIFFUSION"},
{label: "IBUPROFENE MEDOCHEMIE"},
{label: "IBUPROFENE MSD"},
{label: "IBUPROFENE MYLAN"},
{label: "IBUPROFENE MYLAN CONSEIL"},
{label: "IBUPROFENE NEPENTHES"},
{label: "IBUPROFENE NOR"},
{label: "IBUPROFENE OBERLIN"},
{label: "IBUPROFENE OPENING PHARMA"},
{label: "IBUPROFENE PHARMADEV"},
{label: "IBUPROFENE PRODILAB"},
{label: "IBUPROFENE QUALIMED"},
{label: "IBUPROFENE RANBAXY"},
{label: "IBUPROFENE RANBAXY MEDICATION OFFICINALE"},
{label: "IBUPROFENE RATIOPHARM"},
{label: "IBUPROFENE RATIOPHARM CONSEIL"},
{label: "IBUPROFENE REDDY PHARMA"},
{label: "IBUPROFENE REF"},
{label: "IBUPROFENE RPG"},
{label: "IBUPROFENE SANDOZ"},
{label: "IBUPROFENE SANDOZ CONSEIL"},
{label: "IBUPROFENE SANOFI SYNTHELABO OTC"},
{label: "IBUPROFENE SET"},
{label: "IBUPROFENE STRIDES"},
{label: "IBUPROFENE TEVA"},
{label: "IBUPROFENE TEVA CLASSICS"},
{label: "IBUPROFENE TEVA CONSEIL"},
{label: "IBUPROFENE TEVA SANTE"},
{label: "IBUPROFENE UNION INTERPHARMACEUTIQUE ATLANTIQUE"},
{label: "IBUPROFENE UPJOHN"},
{label: "IBUPROFENE UPSA"},
{label: "IBUPROFENE URGO"},
{label: "IBUPROFENE VIATRIS"},
{label: "IBUPROFENE VIATRIS CONSEIL"},
{label: "IBUPROFENE VIATRIS SANTE"},
{label: "IBUPROFENE WYETH SANTE FAMILIALE"},
{label: "IBUPROFENE ZENTIVA"},
{label: "IBUPROFENE ZENTIVA CONSEIL"},
{label: "IBUPROFENE ZENTIVA K.S"},
{label: "IBUPROFENE ZENTIVA LAB"},
{label: "IBUPROFENE ZF"},
{label: "IBUPROFENE ZYDUS"},
{label: "IBUPROFENE ZYDUS FRANCE"},
{label: "IBUTABS"},
{label: "IBUTOP"},
{label: "ICANDRA"},
{label: "ICATIBANT ACCORD"},
{label: "ICATIBANT AGUETTANT"},
{label: "ICATIBANT FRESENIUS"},
{label: "ICATIBANT GP PHARM"},
{label: "ICATIBANT PHARMAKI"},
{label: "ICATIBANT PIRAMAL"},
{label: "ICATIBANT REDDY PHARMA"},
{label: "ICATIBANT UNIVERSAL FARMA"},
{label: "ICATIBANT VIATRIS"},
{label: "ICATIBANT ZENTIVA"},
{label: "ICAVEX"},
{label: "ICAZ LP"},
{label: "ICECA"},
{label: "ICLUSIG"},
{label: "ICODIAL"},
{label: "IDACIO"},
{label: "IDARAC"},
{label: "IDARUBICINE ACCORD"},
{label: "IDARUBICINE MYLAN"},
{label: "IDARUBICINE SANDOZ"},
{label: "IDARUBICINE TEVA"},
{label: "IDEFIRIX"},
{label: "IDEKERR"},
{label: "IDELVION"},
{label: "IDEOLAXYL"},
{label: "IDEOS"},
{label: "IDROCOL"},
{label: "IDRYLINE"},
{label: "IDUVIRAN"},
{label: "IFFEZA"},
{label: "IFIRMACOMBI"},
{label: "IFIRMASTA"},
{label: "IFOSFAMIDE EG"},
{label: "IFYLTAN"},
{label: "IG VENA"},
{label: "IGNATIA AMARA BOIRON"},
{label: "IGNATIA AMARA FERRIER"},
{label: "IGNATIA AMARA LEHNING"},
{label: "IGNATIA AMARA WELEDA"},
{label: "IKARAN"},
{label: "IKARAN L.P."},
{label: "IKERVIS"},
{label: "IKOREL"},
{label: "ILARIS"},
{label: "ILEX AQUIFOLIUM BOIRON"},
{label: "ILEX PARAGUARIENSIS BOIRON"},
{label: "ILIADINE"},
{label: "ILOMEDINE"},
{label: "ILOPROST TEVA"},
{label: "ILOPROST ZENTIVA"},
{label: "ILTRIA"},
{label: "ILUMETRI"},
{label: "ILUVIEN"},
{label: "IMASLAV L.P."},
{label: "IMATINIB ACCORD"},
{label: "IMATINIB ACTAVIS"},
{label: "IMATINIB ARROW LAB"},
{label: "IMATINIB BGR"},
{label: "IMATINIB CIPLA"},
{label: "IMATINIB CRISTERS"},
{label: "IMATINIB EG"},
{label: "IMATINIB FRESENIUS KABI"},
{label: "IMATINIB GRINDEKS"},
{label: "IMATINIB HCS"},
{label: "IMATINIB KOANAA"},
{label: "IMATINIB MEDAC"},
{label: "IMATINIB MEDIPHA"},
{label: "IMATINIB RANBAXY"},
{label: "IMATINIB REDDY PHARMA"},
{label: "IMATINIB REF"},
{label: "IMATINIB SANDOZ"},
{label: "IMATINIB SYNTHON HISPANIA"},
{label: "IMATINIB TEVA"},
{label: "IMATINIB VIATRIS"},
{label: "IMATINIB ZENTIVA"},
{label: "IMATINIB ZYDUS"},
{label: "IMAVER"},
{label: "IMBRUVICA"},
{label: "IMCIVREE"},
{label: "IMEGUL"},
{label: "IMETH"},
{label: "IMFINZI"},
{label: "IMIGRANE"},
{label: "IMIJECT"},
{label: "IMIPENEM CILASTATINE GERDA"},
{label: "IMIPENEM CILASTATINE HOSPIRA"},
{label: "IMIPENEM CILASTATINE HOSPIRA FRANCE"},
{label: "IMIPENEM CILASTATINE PANPHARMA"},
{label: "IMIPENEM CILASTATINE RATIOPHARM"},
{label: "IMIPENEM CILASTATINE STRAVENCON"},
{label: "IMIPENEM CILASTATINE TEVA"},
{label: "IMIPENEM CILASTATINE VIATRIS"},
{label: "IMIPENEM/CILASTATINE ACS DOBFAR"},
{label: "IMIPENEM/CILASTATINE ARROW"},
{label: "IMIPENEM/CILASTATINE ELC"},
{label: "IMIPENEM/CILASTATINE HIKMA"},
{label: "IMIPENEM/CILASTATINE KABI"},
{label: "IMIPENEM/CILASTATINE SANDOZ"},
{label: "IMIPENEM/CILASTATINE SUBSTIPHARM"},
{label: "IMIPENEM/CILASTATINE SUN"},
{label: "IMIZINE"},
{label: "IMJUDO"},
{label: "IMLYGIC"},
{label: "IMMU ORR"},
{label: "IMMU-PHILUS"},
{label: "IMMUCYST"},
{label: "IMMUGRIP"},
{label: "IMMUNOGLOBULINE EQUINE TETANIQUE PASTEUR"},
{label: "IMMUNOGLOBULINE HUMAINE DE L'HEPATITE B LFB"},
{label: "IMNOVID"},
{label: "IMOALLEVIA"},
{label: "IMOCUR ENFANTS"},
{label: "IMODIUM"},
{label: "IMODIUMCAPS"},
{label: "IMODIUMDUO"},
{label: "IMODIUMLINGUAL"},
{label: "IMODIUMLIQUICAPS"},
{label: "IMOGAM RAGE"},
{label: "IMONOGAS"},
{label: "IMOSEPTYL"},
{label: "IMOSSEL"},
{label: "IMOSSELDUO"},
{label: "IMOVANE"},
{label: "IMOVAX OREILLONS"},
{label: "IMOVAX POLIO"},
{label: "IMPLICOR"},
{label: "IMPORTAL"},
{label: "IMPORTAL ENFANTS"},
{label: "IMPORTAL JEUNES ENFANTS"},
{label: "IMRALDI"},
{label: "IMUDON"},
{label: "IMUKIN"},
{label: "IMUREL"},
{label: "IMVANEX"},
{label: "INCITAL"},
{label: "INCIVO"},
{label: "INCRELEX"},
{label: "INCRUSE ELLIPTA"},
{label: "INDAMIREX"},
{label: "INDAPAMIDE ARDIX"},
{label: "INDAPAMIDE ARROW"},
{label: "INDAPAMIDE BIOGARAN"},
{label: "INDAPAMIDE EG LP"},
{label: "INDAPAMIDE ISOMED LP"},
{label: "INDAPAMIDE KRKA LP"},
{label: "INDAPAMIDE MURAT"},
{label: "INDAPAMIDE MYLAN"},
{label: "INDAPAMIDE QUALIMED L.P."},
{label: "INDAPAMIDE RANBAXY LP"},
{label: "INDAPAMIDE RATIOPHARM LP"},
{label: "INDAPAMIDE SANDOZ LP"},
{label: "INDAPAMIDE TEVA LP"},
{label: "INDAPAMIDE TEVA SANTE LP"},
{label: "INDAPAMIDE VIATRIS LP"},
{label: "INDAPAMIDE ZENTIVA LP"},
{label: "INDAPAMIDE ZYDUS LP"},
{label: "INDATEN"},
{label: "INDIARAL"},
{label: "INDIGO BOIRON"},
{label: "INDOBIOTIC"},
{label: "INDOCID"},
{label: "INDOCOLLYRE"},
{label: "INDOCONTIN L P"},
{label: "INDOLACINE"},
{label: "INDOMET RATIOPHARM"},
{label: "INDOMETACINE MSD"},
{label: "INDOMETACINE PIERRE FABRE MEDICAMENT"},
{label: "INDOMETAN"},
{label: "INDORMYL"},
{label: "INDUCTOR"},
{label: "INDUCTOS"},
{label: "INEGY"},
{label: "INERPAN"},
{label: "INEUROPE"},
{label: "INEXIUM"},
{label: "INFANRIX"},
{label: "INFANRIXQUINTA"},
{label: "INFANRIXTETRA"},
{label: "INFERGEN"},
{label: "INFIBRAN"},
{label: "INFLACED"},
{label: "INFLECTRA"},
{label: "INFLUDO"},
{label: "INFLUENZINUM BOIRON"},
{label: "INFLUENZINUM FERRIER"},
{label: "INFLUENZINUM LEHNING"},
{label: "INFLUVAC"},
{label: "INFLUVAC ENFANT"},
{label: "INFLUVAC TETRA"},
{label: "INFRACYANINE"},
{label: "INFUSANE AU SENE"},
{label: "INFUSANES ORTHOSIPHON"},
{label: "INFUSEX D"},
{label: "INFUSEX E"},
{label: "INFUSEX K"},
{label: "INFUSEX L"},
{label: "INFUSEX O"},
{label: "INFUSEX P"},
{label: "INFUSEX S"},
{label: "INFUSEX T"},
{label: "INHALESENS AU MENTHOL"},
{label: "INHALEUR"},
{label: "INHAXOL"},
{label: "INHAZIL"},
{label: "INHIXA"},
{label: "INIPEPSIA"},
{label: "INIPOMP"},
{label: "INITIA"},
{label: "INLYTA"},
{label: "INNOBRANDUO"},
{label: "INNOHEP"},
{label: "INNOLYRE"},
{label: "INNOVAIR 100/6"},
{label: "INNOVAIR 200/6"},
{label: "INNOVAIR NEXTHALER"},
{label: "INOCOR"},
{label: "INODEVA"},
{label: "INODAIL"},
{label: "INOFER"},
{label: "INOLAXINE"},
{label: "INOMAX"},
{label: "INONGAN"},
{label: "INORIAL"},
{label: "INOTYOL"},
{label: "INOVELON"},
{label: "INREBIC"},
{label: "INSADOL"},
{label: "INSIDON"},
{label: "INSOMNYL ADULTES"},
{label: "INSOMNYL ENFANTS"},
{label: "INSPRA"},
{label: "INSTANYL"},
{label: "INSTILLAGEL"},
{label: "INSULATARD"},
{label: "INSULATARD FLEXPEN"},
{label: "INSULATARD INNOLET"},
{label: "INSULATARD NOVOLET"},
{label: "INSULATARD NPH"},
{label: "INSULATARD NPH NOVOLET"},
{label: "INSULATARD NPH PENFILL"},
{label: "INSULATARD PENFILL"},
{label: "INSULINE ASPARTE SANOFI"},
{label: "INSULINE LISPRO SANOFI"},
{label: "INSUMAN BASAL"},
{label: "INSUMAN BASAL SOLOSTAR"},
{label: "INSUMAN COMB"},
{label: "INSUMAN IMPLANTABLE"},
{label: "INSUMAN INFUSAT"},
{label: "INSUMAN RAPID"},
{label: "INSUPLANT"},
{label: "INTANZA"},
{label: "INTEGRILIN"},
{label: "INTELAXINE"},
{label: "INTELENCE"},
{label: "INTELOR"},
{label: "INTERCRON"},
{label: "INTERCYTON"},
{label: "INTERCYTON PROCTO"},
{label: "INTESTIN GRELE BOIRON"},
{label: "INTETRIX"},
{label: "INTRAIT DE MARRON D'INDE 'P'"},
{label: "INTRALGIS"},
{label: "INTRALIPIDE"},
{label: "INTRAROSA"},
{label: "INTRATECT"},
{label: "INTRINSA"},
{label: "INTRONA"},
{label: "INTUNIV"},
{label: "INULA HELENIUM BOIRON"},
{label: "INVANZ"},
{label: "INVEGA"},
{label: "INVICORP"},
{label: "INVIRASE"},
{label: "INVOKANA"},
{label: "INZOLFI"},
{label: "IOBENGUANE [131 I]"},
{label: "IOBENGUANE [131 I] POUR THERAPIE MALLINCKRODT FRANCE"},
{label: "IOBENGUANE [131 I] PRETHERAPEUTIQUE CIS BIO INTERNATIONAL"},
{label: "IOBENGUANE [131-I] POUR THERAPIE BRISTOL MYERS SQUIBB"},
{label: "IODAPS"},
{label: "IODENCE"},
{label: "IODOHIPPURATE DE SODIUM [131 I]"},
{label: "IODOHIPPURATE [123-I] DE SODIUM BRIOSTOL MYERS SQUIBB"},
{label: "IODOPENGHA"},
{label: "IODOSORB"},
{label: "IODUM BOIRON"},
{label: "IODUM LEHNING"},
{label: "IODUM WELEDA"},
{label: "IODURE (123 I) DE SODIUM CURIUMPHARMA"},
{label: "IODURE (131 I) DE SODIUM CIS BIO INTERNATIONAL"},
{label: "IODURE (131 I) DE SODIUM POUR THERAPIE CURIUMPHARMA 37-7"},
{label: "IODURE (131I) DE SODIUM"},
{label: "IODURE DE POTASSIUM PHARMACIE CENTRALE DES ARMEES"},
{label: "IODURE DE POTASSIUM SERB"},
{label: "IODURE [123 I] DE SODIUM"},
{label: "IODURE [131 I]"},
{label: "IODURE [131 I] DE SODIUM CIS BIO INTERNATIONAL"},
{label: "IODURE [131 I] DE SODIUM POUR DIAGNOSTIC"},
{label: "IODURE(123I)"},
{label: "IOFLUPANE (123I) ROTOP"},
{label: "IOHEXOL INTERPHARMA PRAHA"},
{label: "IOMERON"},
{label: "IONARTHROL"},
{label: "IONIMAG"},
{label: "IONITAN"},
{label: "IONOKA G"},
{label: "IONOVEN"},
{label: "IONSYS"},
{label: "IOPAMIDOL BRACCO"},
{label: "IOPAMIGITA"},
{label: "IOPAMIRON"},
{label: "IOPIDINE"},
{label: "IPECA BOIRON"},
{label: "IPECA COMPLEXE N°65"},
{label: "IPECA COMPOSE"},
{label: "IPECA DECOCTE WELEDA"},
{label: "IPECA LEHNING"},
{label: "IPERTEN"},
{label: "IPRAALOX"},
{label: "IPRAFEINE"},
{label: "IPRATROGEN"},
{label: "IPRATROPIUM AGUETTANT ADULTES"},
{label: "IPRATROPIUM AGUETTANT ENFANT"},
{label: "IPRATROPIUM ALMUS"},
{label: "IPRATROPIUM ALMUS PHARMA"},
{label: "IPRATROPIUM ARCHIE SAMUEL"},
{label: "IPRATROPIUM ARROW"},
{label: "IPRATROPIUM ARROW ADULTES"},
{label: "IPRATROPIUM BOEHRINGER INGELHEIM FRANCE ADULTES"},
{label: "IPRATROPIUM BOEHRINGER INGELHEIM FRANCE ENFANTS"},
{label: "IPRATROPIUM CRISTERS"},
{label: "IPRATROPIUM EG"},
{label: "IPRATROPIUM SANDOZ"},
{label: "IPRATROPIUM TEVA"},
{label: "IPRATROPIUM VIATRIS"},
{label: "IPRATROPIUM ZENTIVA"},
{label: "IPRAVENT"},
{label: "IRBESARTAN ACCORD"},
{label: "IRBESARTAN ACTAVIS FRANCE"},
{label: "IRBESARTAN ALTER"},
{label: "IRBESARTAN ARROW"},
{label: "IRBESARTAN ARROW GENERIQUES"},
{label: "IRBESARTAN ARROW LAB"},
{label: "IRBESARTAN BGR"},
{label: "IRBESARTAN BIOGARAN"},
{label: "IRBESARTAN CRISTERS"},
{label: "IRBESARTAN CRISTERS PHARMA"},
{label: "IRBESARTAN DEXCEL"},
{label: "IRBESARTAN EG"},
{label: "IRBESARTAN EVOLUGEN"},
{label: "IRBESARTAN EXCALIBUR PHARMA"},
{label: "IRBESARTAN HYDROCHLOROTHIAZIDE TEVA"},
{label: "IRBESARTAN HYDROCHLOROTHIAZIDE ZENTIVA"},
{label: "IRBESARTAN ISOMED"},
{label: "IRBESARTAN MYLAN"},
{label: "IRBESARTAN OPENING PHARMA"},
{label: "IRBESARTAN PFIZER"},
{label: "IRBESARTAN PHR LAB"},
{label: "IRBESARTAN QUIVER"},
{label: "IRBESARTAN RATIOPHARM"},
{label: "IRBESARTAN SANDOZ"},
{label: "IRBESARTAN TECNIMEDE"},
{label: "IRBESARTAN TEVA"},
{label: "IRBESARTAN USV EUROPE"},
{label: "IRBESARTAN VIATRIS"},
{label: "IRBESARTAN VOCATE"},
{label: "IRBESARTAN ZENTIVA"},
{label: "IRBESARTAN ZYDUS"},
{label: "IRBESARTAN/HYDROCHLOROTHIAZIDE ALTER"},
{label: "IRBESARTAN/HYDROCHLOROTHIAZIDE ARROW"},
{label: "IRBESARTAN/HYDROCHLOROTHIAZIDE ARROW GENERIQUES"},
{label: "IRBESARTAN/HYDROCHLOROTHIAZIDE BIOGARAN"},
{label: "IRBESARTAN/HYDROCHLOROTHIAZIDE CRISTERS"},
{label: "IRBESARTAN/HYDROCHLOROTHIAZIDE EG"},
{label: "IRBESARTAN/HYDROCHLOROTHIAZIDE EVOLUGEN"},
{label: "IRBESARTAN/HYDROCHLOROTHIAZIDE EVOLUGEN PHARMA"},
{label: "IRBESARTAN/HYDROCHLOROTHIAZIDE MYLAN"},
{label: "IRBESARTAN/HYDROCHLOROTHIAZIDE MYLAN PHARMA"},
{label: "IRBESARTAN/HYDROCHLOROTHIAZIDE PHARMAKI GENERICS"},
{label: "IRBESARTAN/HYDROCHLOROTHIAZIDE RANBAXY"},
{label: "IRBESARTAN/HYDROCHLOROTHIAZIDE RATIOPHARM"},
{label: "IRBESARTAN/HYDROCHLOROTHIAZIDE REF"},
{label: "IRBESARTAN/HYDROCHLOROTHIAZIDE SANDOZ"},
{label: "IRBESARTAN/HYDROCHLOROTHIAZIDE VIATRIS"},
{label: "IRBESARTAN/HYDROCHLOROTHIAZIDE ZYDUS"},
{label: "IRESSA"},
{label: "IRICLAIR"},
{label: "IRICRYN"},
{label: "IRIDIUM METALLICUM BOIRON"},
{label: "IRIMED"},
{label: "IRINOTECAN ACCORD"},
{label: "IRINOTECAN ARROW"},
{label: "IRINOTECAN CHEMICAL FARMA"},
{label: "IRINOTECAN DISPERSUS"},
{label: "IRINOTECAN EBEWE"},
{label: "IRINOTECAN EBEWE PHARMA FRANCE"},
{label: "IRINOTECAN EG"},
{label: "IRINOTECAN FRESENIUS"},
{label: "IRINOTECAN HIKMA"},
{label: "IRINOTECAN HOSPIRA"},
{label: "IRINOTECAN IBIGEN"},
{label: "IRINOTECAN KABI"},
{label: "IRINOTECAN MEDAC"},
{label: "IRINOTECAN MYLAN PHARMA"},
{label: "IRINOTECAN PFIZER"},
{label: "IRINOTECAN RATIOPHARM"},
{label: "IRINOTECAN SANDOZ"},
{label: "IRINOTECAN SEACROSS"},
{label: "IRINOTECAN SERVIPHARM"},
{label: "IRINOTECAN SUN"},
{label: "IRINOTECAN TEVA"},
{label: "IRINOTECAN TEVA SANTE"},
{label: "IRINOTECAN VIATRIS"},
{label: "IRIS TENAX BOIRON"},
{label: "IRIS VERSICOLOR BOIRON"},
{label: "IRIS VERSICOLOR LEHNING"},
{label: "IRIS VERSICOLOR WELEDA"},
{label: "IRRI POS"},
{label: "IRRISEDERMYL"},
{label: "IRS"},
{label: "ISALIA"},
{label: "ISDENIX LP"},
{label: "ISENTRESS"},
{label: "ISEPALLINE"},
{label: "ISEPAMICINE SCHERING-PLOUGH"},
{label: "ISIMIG"},
{label: "ISKEDYL"},
{label: "ISKEDYL FORT"},
{label: "ISO-LARMES"},
{label: "ISOBAR"},
{label: "ISOCARD"},
{label: "ISOCHRON"},
{label: "ISODRIL PHENYLEPHRINE"},
{label: "ISOFEBRIL VITAMINE C"},
{label: "ISOFLURANE BELAMONT"},
{label: "ISOFLURANE MEDEVA EUROPE"},
{label: "ISOFLURANE NICHOLAS PIRAMAL INDIA"},
{label: "ISOFUNDINE"},
{label: "ISOLFAN"},
{label: "ISONIAZIDE LAVOISIER"},
{label: "ISOPEDIA"},
{label: "ISOPRENALINE A"},
{label: "ISOPRENALINE CHLORHYDRATE TILLOMED"},
{label: "ISOPRINOSINE"},
{label: "ISOPTINE"},
{label: "ISOPTINE L.P."},
{label: "ISOPTO CARBACHOL"},
{label: "ISOPTO PILOCARPINE"},
{label: "ISOPTO-HOMATROPINE"},
{label: "ISOPTO-PILOCARPINE"},
{label: "ISORYTHM"},
{label: "ISORYTHM L.P."},
{label: "ISOSORBIDE (DINITRATE D') RATIOPHARM"},
{label: "ISOSORBIDE (DINITRATE D') RPG"},
{label: "ISOSORBIDE MEDISOL"},
{label: "ISOSORBIDE RPG"},
{label: "ISOSORBIDE TEVA LP"},
{label: "ISOTONYL BETAINE"},
{label: "ISOTRETINOINE ACNETRAIT"},
{label: "ISOTRETINOINE BIOGARAN"},
{label: "ISOTRETINOINE EG LABO"},
{label: "ISOTRETINOINE MYLAN"},
{label: "ISOTRETINOINE QUALIMED"},
{label: "ISOTRETINOINE RATIOPHARM"},
{label: "ISOTREX"},
{label: "ISOVIST"},
{label: "ISOVOL"},
{label: "ISPAGHUL ROTTAPHARM"},
{label: "ISPAGHUL TROUETTE PERRET"},
{label: "ISTAMYL"},
{label: "ISTENDO"},
{label: "ISTOPEN"},
{label: "ISTURISA"},
{label: "ISUDRINE"},
{label: "ISUPREL"},
{label: "ITAX"},
{label: "ITEM"},
{label: "ITRACONAZOLE HEXAL"},
{label: "ITRACONAZOLE SANDOZ"},
{label: "ITRACONAZOLE TEVA"},
{label: "ITRACONAZOLE VIATRIS"},
{label: "ITRACONAZOLE WINTHROP"},
{label: "ITRIZOLE"},
{label: "ITULAZAX"},
{label: "IVABRADINE ACCORD"},
{label: "IVABRADINE ALTER"},
{label: "IVABRADINE ARROW"},
{label: "IVABRADINE BGR"},
{label: "IVABRADINE BIOGARAN"},
{label: "IVABRADINE CHANELLE MEDICAL"},
{label: "IVABRADINE CRISTERS"},
{label: "IVABRADINE EG"},
{label: "IVABRADINE EIGNAPHARMA"},
{label: "IVABRADINE KRKA"},
{label: "IVABRADINE PHARMATHEN"},
{label: "IVABRADINE SANDOZ"},
{label: "IVABRADINE SYNTHON"},
{label: "IVABRADINE TEVA"},
{label: "IVABRADINE TEVA SANTE"},
{label: "IVABRADINE VIATRIS"},
{label: "IVABRADINE ZENTIVA"},
{label: "IVABRADINE ZYDUS"},
{label: "IVELIP"},
{label: "IVEMEND"},
{label: "IVEPAQUE"},
{label: "IVERMECTINE ARROW"},
{label: "IVERMECTINE ARROW LAB"},
{label: "IVERMECTINE BIOGARAN"},
{label: "IVERMECTINE CRISTERS"},
{label: "IVERMECTINE EG"},
{label: "IVERMECTINE LICONSA"},
{label: "IVERMECTINE PHARMAKI GENERICS"},
{label: "IVERMECTINE PIERRE FABRE"},
{label: "IVERMECTINE SANDOZ"},
{label: "IVERMECTINE SIGILLATA"},
{label: "IVERMECTINE SUBSTIPHARM"},
{label: "IVERMECTINE TEVA"},
{label: "IVERMECTINE VIATRIS"},
{label: "IVERMECTINE ZENTIVA"},
{label: "IVHEBEX"},
{label: "IVOZALL"},
{label: "IXEL"},
{label: "IXENSE"},
{label: "IXIARO"},
{label: "IXPRIM"},
{label: "IZALGI"},
{label: "IZBA"},
{label: "IZEANE"},
{label: "IZILOX"},
{label: "IZINOVA"},
{label: "IZIXATE"},
{label: "JABORANDI BOIRON"},
{label: "JABORANDI LEHNING"},
{label: "JACARANDA CAROBA BOIRON"},
{label: "JADELLE"},
{label: "JAKAVI"},
{label: "JALAPA BOIRON"},
{label: "JALRA"},
{label: "JAMYLENE"},
{label: "JANUMET"},
{label: "JANUVIA"},
{label: "JARDIANCE"},
{label: "JASMINE"},
{label: "JASMINELLE"},
{label: "JASMINELLECONTINU"},
{label: "JASPE VERT BOIRON"},
{label: "JAVLOR"},
{label: "JAYDESS"},
{label: "JCOVDEN"},
{label: "JECOPEPTOL"},
{label: "JEMPERLI"},
{label: "JENOVERINE"},
{label: "JENTADUETO"},
{label: "JEQUIRITY BOIRON"},
{label: "JETREA"},
{label: "JEVTANA"},
{label: "JEXT"},
{label: "JINARC"},
{label: "JIVI"},
{label: "JONCTUM"},
{label: "JONESIA ASOKA BOIRON"},
{label: "JORVEZA"},
{label: "JOSACINE"},
{label: "JOSACINE DISPERSIBLE"},
{label: "JOSACINE DISPERSIBLE ENFANTS"},
{label: "JOSAMYCINE PROPIONATE ASTELLAS PHARMA"},
{label: "JOSIR L.P."},
{label: "JOUVENCE DE L'ABBE SOURY"},
{label: "JOUVENCE DE LABBE SOURY"},
{label: "JUGLANS CINEREA BOIRON"},
{label: "JUGLANS REGIA BOIRON"},
{label: "JUGLANS REGIA LEHNING"},
{label: "JULUCA"},
{label: "JUNALIZA"},
{label: "JUNIMIN"},
{label: "JUNIPERUS COMMUNIS BOIRON"},
{label: "JUNIPERUS COMMUNIS LEHNING"},
{label: "JUNIPERUS OXYCEDRUS BOIRON"},
{label: "JUSTICIA ADHATODA BOIRON"},
{label: "JUSTOR"},
{label: "JYSELECA"},
{label: "KABIKINASE"},
{label: "KABIMIX"},
{label: "KABIVEN"},
{label: "KACELRIC SANS SUCRE"},
{label: "KADCYLA"},
{label: "KADRIGINE"},
{label: "KAFTRIO"},
{label: "KALEORID LP"},
{label: "KALETRA"},
{label: "KALETRA (80"},
{label: "KALI PHOS COMPLEXE N°100"},
{label: "KALIALE"},
{label: "KALILACTASOL"},
{label: "KALINOX"},
{label: "KALIUM ARSENICOSUM BOIRON"},
{label: "KALIUM BICHROMICUM BOIRON"},
{label: "KALIUM BICHROMICUM FERRIER"},
{label: "KALIUM BICHROMICUM LEHNING"},
{label: "KALIUM BICROMICUM WELEDA"},
{label: "KALIUM BROMATUM BOIRON"},
{label: "KALIUM BROMATUM LEHNING"},
{label: "KALIUM BROMATUM WELEDA"},
{label: "KALIUM CARBONICUM BOIRON"},
{label: "KALIUM CARBONICUM LEHNING"},
{label: "KALIUM CARBONICUM WELEDA"},
{label: "KALIUM CHLORICUM BOIRON"},
{label: "KALIUM IODATUM BOIRON"},
{label: "KALIUM IODATUM LEHNING"},
{label: "KALIUM IODATUM WELEDA"},
{label: "KALIUM MURIATICUM BOIRON"},
{label: "KALIUM MURIATICUM LEHNING"},
{label: "KALIUM MURIATICUM WELEDA"},
{label: "KALIUM NITRICUM BOIRON"},
{label: "KALIUM NITRICUM LEHNING"},
{label: "KALIUM PHOSPHORICUM BOIRON"},
{label: "KALIUM PHOSPHORICUM LEHNING"},
{label: "KALIUM PHOSPHORICUM WELEDA"},
{label: "KALIUM SULFURICUM BOIRON"},
{label: "KALIUM SULFURICUM LEHNING"},
{label: "KALIUM SULFURICUM WELEDA"},
{label: "KALMIA COMPLEXE N°18"},
{label: "KALMIA LATIFOLIA BOIRON"},
{label: "KALMIA LATIFOLIA LEHNING"},
{label: "KALMIA LATIFOLIA WELEDA"},
{label: "KALOBA"},
{label: "KALOSSO"},
{label: "KALYDECO"},
{label: "KAMILLO"},
{label: "KAMILLOSAN"},
{label: "KAMYCINE"},
{label: "KANEURON"},
{label: "KANJINTI"},
{label: "KANOKAD"},
{label: "KANUMA"},
{label: "KAOBROL"},
{label: "KAOLOGEAIS"},
{label: "KAOMUTH"},
{label: "KAPANEK"},
{label: "KAPANOL L.P."},
{label: "KAPRUVIA"},
{label: "KARAYAL"},
{label: "KARBIS"},
{label: "KARDEGIC"},
{label: "KAREGUL"},
{label: "KARELYNE"},
{label: "KARNODYL"},
{label: "KASKADIL"},
{label: "KASTLUTEMON"},
{label: "KAYEXALATE"},
{label: "KEAL"},
{label: "KEFANDOL"},
{label: "KEFLIN"},
{label: "KEFORAL"},
{label: "KEFORT"},
{label: "KEFZOL"},
{label: "KELOCYANOR"},
{label: "KELSEF"},
{label: "KENACORT RETARD"},
{label: "KENALCOL"},
{label: "KENDIX"},
{label: "KENGREXAL"},
{label: "KENOMON"},
{label: "KENTERA"},
{label: "KENZEN"},
{label: "KEPIVANCE"},
{label: "KEPPRA"},
{label: "KERAFILM"},
{label: "KERATYL"},
{label: "KERLIDEX"},
{label: "KERLOCAL"},
{label: "KERLONE"},
{label: "KERSTIPON"},
{label: "KESIMPTA"},
{label: "KESSAR"},
{label: "KESTIN"},
{label: "KESTINLYO"},
{label: "KETALAR"},
{label: "KETAMINE AGUETTANT"},
{label: "KETAMINE PANPHARMA"},
{label: "KETAMINE RENAUDIN"},
{label: "KETAZED"},
{label: "KETEK"},
{label: "KETESSE"},
{label: "KETOCONAZOLE ARROW"},
{label: "KETOCONAZOLE BIOGARAN"},
{label: "KETOCONAZOLE BIOGARAN 2%"},
{label: "KETOCONAZOLE CRISTERS"},
{label: "KETOCONAZOLE EG"},
{label: "KETOCONAZOLE HRA"},
{label: "KETOCONAZOLE RANBAXY"},
{label: "KETOCONAZOLE RATIOPHARM"},
{label: "KETOCONAZOLE SANDOZ"},
{label: "KETOCONAZOLE SG PHARM"},
{label: "KETOCONAZOLE TEVA"},
{label: "KETOCONAZOLE VIATRIS"},
{label: "KETOCONAZOLE ZENTIVA"},
{label: "KETOCONAZOLE ZYDUS"},
{label: "KETODERM"},
{label: "KETODERM 2%"},
{label: "KETOLIUM"},
{label: "KETOPROFENE AGI PHARMA"},
{label: "KETOPROFENE AGUETTANT"},
{label: "KETOPROFENE ARROW"},
{label: "KETOPROFENE ARROW L.P."},
{label: "KETOPROFENE ARROW LP"},
{label: "KETOPROFENE BGR"},
{label: "KETOPROFENE BIOGALENIQUE"},
{label: "KETOPROFENE BIOGARAN"},
{label: "KETOPROFENE BIOGARAN LP"},
{label: "KETOPROFENE CEDIAT MEDIFFUSION"},
{label: "KETOPROFENE CRISTERS"},
{label: "KETOPROFENE CRISTERS LP"},
{label: "KETOPROFENE EG"},
{label: "KETOPROFENE EG LP"},
{label: "KETOPROFENE ETHYPHARM LP"},
{label: "KETOPROFENE G GAM LP"},
{label: "KETOPROFENE GNR LP"},
{label: "KETOPROFENE IVAX L.P."},
{label: "KETOPROFENE MACOSOL"},
{label: "KETOPROFENE MEDIFFUSION"},
{label: "KETOPROFENE MEDISOL"},
{label: "KETOPROFENE MENARINI"},
{label: "KETOPROFENE MYLAN"},
{label: "KETOPROFENE MYLAN LP"},
{label: "KETOPROFENE MYLAN PHARMA"},
{label: "KETOPROFENE NOR"},
{label: "KETOPROFENE NOR LP"},
{label: "KETOPROFENE PHARMY II"},
{label: "KETOPROFENE QUALIHEALTH"},
{label: "KETOPROFENE QUALIMED"},
{label: "KETOPROFENE RATIO"},
{label: "KETOPROFENE RATIOPHARM"},
{label: "KETOPROFENE RPG"},
{label: "KETOPROFENE RPG LP"},
{label: "KETOPROFENE SANDOZ"},
{label: "KETOPROFENE SANDOZ LP"},
{label: "KETOPROFENE SET LP"},
{label: "KETOPROFENE SUN LP"},
{label: "KETOPROFENE TEVA"},
{label: "KETOPROFENE TEVA LP"},
{label: "KETOPROFENE TEVA SANTE"},
{label: "KETOPROFENE THERAPLIX LP"},
{label: "KETOPROFENE VIATRIS LP"},
{label: "KETOPROFENE WINTHROP"},
{label: "KETOPROFENE ZENTIVA LP"},
{label: "KETOPROFENE ZYDUS"},
{label: "KETOPROFENE/OMEPRAZOLE LABORATOIRE X.O"},
{label: "KETOSTERIL"},
{label: "KETOTIFENE G GAM"},
{label: "KETREL"},
{label: "KETUM"},
{label: "KETUM L P"},
{label: "KEVZARA"},
{label: "KEYTRUDA"},
{label: "KIDARGOL"},
{label: "KIDIAVEN G15%"},
{label: "KIDIAVEN G20%"},
{label: "KIDIAVEN G25%"},
{label: "KIDROLASE"},
{label: "KIGABEQ"},
{label: "KILATIM"},
{label: "KILIPSO 2%"},
{label: "KIMMTRAK"},
{label: "KINERET"},
{label: "KINOCYSTON"},
{label: "KINOX"},
{label: "KINPEYGO"},
{label: "KINTUXIL"},
{label: "KINUREA-H"},
{label: "KIOVIG"},
{label: "KIPOS"},
{label: "KIRAVEM"},
{label: "KISPLYX"},
{label: "KISQALI"},
{label: "KITACI"},
{label: "KIVEXA"},
{label: "KIVINOCILAR"},
{label: "KIVIZIDIALE"},
{label: "KLEAN-PREP"},
{label: "KLIOGEST"},
{label: "KLIPAL"},
{label: "KLISYRI"},
{label: "KLODIGAN"},
{label: "KLYSTON"},
{label: "KOFLEN"},
{label: "KOGENATE"},
{label: "KOGENATE BAYER"},
{label: "KOLA ASTIER"},
{label: "KOLA BOIRON"},
{label: "KOLATON"},
{label: "KOLBAM"},
{label: "KOLOSSORB"},
{label: "KOMBOGLYZE"},
{label: "KOREC"},
{label: "KORETIC"},
{label: "KOSELUGO"},
{label: "KOTTAGE LP"},
{label: "KOVALTRY"},
{label: "KREDEX"},
{label: "KRENOSIN"},
{label: "KREOSOTUM BOIRON"},
{label: "KREOSOTUM COMPLEXE N°62"},
{label: "KREOSOTUM LEHNING"},
{label: "KRONALIS"},
{label: "KRYPTOGAS"},
{label: "KRYPTOSCAN"},
{label: "KRYSTEXXA"},
{label: "KUVAN"},
{label: "KWAI"},
{label: "KYLEENA"},
{label: "KYMRIAH"},
{label: "KYNMOBI"},
{label: "KYNO"},
{label: "KYNTHEUM"},
{label: "KYPROLIS"},
{label: "KYTRIL"},
{label: "L"},
{label: "L-THYROXIN HENNING"},
{label: "L-THYROXINE SERB"},
{label: "L107"},
{label: "L114"},
{label: "L52"},
{label: "L72"},
{label: "LABIAMEO"},
{label: "LAC CANINUM BOIRON"},
{label: "LAC DEFLORATUM BOIRON"},
{label: "LACCODERME A L'HUILE DE CADE"},
{label: "LACCODERME ACIDE SALICYLIQUE"},
{label: "LACCODERME DALIBOUR"},
{label: "LACCODERME GOUDRON DE HOUILLE"},
{label: "LACHESIS COMPLEXE N°122"},
{label: "LACHESIS MUTUS BOIRON"},
{label: "LACHESIS MUTUS LEHNING"},
{label: "LACHESIS MUTUS WELEDA"},
{label: "LACHNANTHES TINCTORIA BOIRON"},
{label: "LACIDIPINE BIOGARAN"},
{label: "LACIDIPINE MYLAN PHARMA"},
{label: "LACIDIPINE RIVOPHARM"},
{label: "LACIPIL"},
{label: "LACOSAMIDE ACCORD"},
{label: "LACOSAMIDE ARROW"},
{label: "LACOSAMIDE BIOGARAN"},
{label: "LACOSAMIDE EG"},
{label: "LACOSAMIDE FRESENIUS KABI"},
{label: "LACOSAMIDE G.L. PHARMA"},
{label: "LACOSAMIDE HAMELN"},
{label: "LACOSAMIDE HELM AG"},
{label: "LACOSAMIDE HIKMA"},
{label: "LACOSAMIDE SANDOZ"},
{label: "LACOSAMIDE STRAGEN"},
{label: "LACOSAMIDE TEVA"},
{label: "LACOSAMIDE VIATRIS"},
{label: "LACOSAMIDE VIVANTA"},
{label: "LACOSAMIDE ZENTIVA"},
{label: "LACOSAMIDE ZYDUS"},
{label: "LACRIFLUID"},
{label: "LACRIGEL"},
{label: "LACRINORM"},
{label: "LACRISERT"},
{label: "LACRYADEX"},
{label: "LACRYPOS"},
{label: "LACRYVISC"},
{label: "LACTEOL"},
{label: "LACTICUM ACIDUM BOIRON"},
{label: "LACTICUM ACIDUM LEHNING"},
{label: "LACTINETTE"},
{label: "LACTUCA SATIVA BOIRON"},
{label: "LACTUCA VIROSA BOIRON"},
{label: "LACTUFLOR"},
{label: "LACTULADE"},
{label: "LACTULOSE ARROW"},
{label: "LACTULOSE BIOGALENIQUE"},
{label: "LACTULOSE BIOGARAN"},
{label: "LACTULOSE BIPHAR"},
{label: "LACTULOSE BOUCHARA RECORDATI"},
{label: "LACTULOSE BOUCHARD"},
{label: "LACTULOSE EG"},
{label: "LACTULOSE FRESENIUS"},
{label: "LACTULOSE G GAM"},
{label: "LACTULOSE GIFRER"},
{label: "LACTULOSE GNR"},
{label: "LACTULOSE H2 PHARMA"},
{label: "LACTULOSE H3 SANTE"},
{label: "LACTULOSE IVAX"},
{label: "LACTULOSE LABORATOIRES DE THERAPEUTIQUE MODERNE"},
{label: "LACTULOSE LTM"},
{label: "LACTULOSE MARCOFINA"},
{label: "LACTULOSE MERCK"},
{label: "LACTULOSE MIP"},
{label: "LACTULOSE MYLAN"},
{label: "LACTULOSE NOR"},
{label: "LACTULOSE O.P.I.H."},
{label: "LACTULOSE O.P.I.H. FRANCE"},
{label: "LACTULOSE PHARMEXOR"},
{label: "LACTULOSE QUALIMED"},
{label: "LACTULOSE RATIOPHARM"},
{label: "LACTULOSE RPG"},
{label: "LACTULOSE SANDOZ"},
{label: "LACTULOSE TEVA"},
{label: "LACTULOSE VIATRIS"},
{label: "LACTULOSE VIATRIS SANTE"},
{label: "LACTULOSE WINTHROP"},
{label: "LACTULOSE ZENTIVA"},
{label: "LACTULOSE ZYDUS"},
{label: "LADIVIA"},
{label: "LAEVOLAC"},
{label: "LAEVOLAC AROME PRUNE"},
{label: "LAFAMME"},
{label: "LAIT DE MAGNESIE PHILLIPS"},
{label: "LAKA"},
{label: "LAKRIMONT"},
{label: "LAMALINE"},
{label: "LAMICSTART"},
{label: "LAMICTAL"},
{label: "LAMIDERM"},
{label: "LAMINARIA DIGITATA BOIRON"},
{label: "LAMISIL"},
{label: "LAMISILATE"},
{label: "LAMISILATE MONODOSE"},
{label: "LAMISILDERMGEL"},
{label: "LAMISILSPRAY"},
{label: "LAMISILSPRAY 1%"},
{label: "LAMIUM ALBUM BOIRON"},
{label: "LAMIVUDINE ARROW"},
{label: "LAMIVUDINE NORPHARM REGULATORY SERVICES"},
{label: "LAMIVUDINE SANDOZ"},
{label: "LAMIVUDINE TEVA PHARMA B.V."},
{label: "LAMIVUDINE VIATRIS"},
{label: "LAMIVUDINE ZIDOVUDINE STRAVENCON"},
{label: "LAMIVUDINE ZIDOVUDINE ZENTIVA"},
{label: "LAMIVUDINE/ZIDOVUDINE ACCORD"},
{label: "LAMIVUDINE/ZIDOVUDINE ARROW"},
{label: "LAMIVUDINE/ZIDOVUDINE CRISTERS"},
{label: "LAMIVUDINE/ZIDOVUDINE SANDOZ"},
{label: "LAMIVUDINE/ZIDOVUDINE TEVA"},
{label: "LAMIVUDINE/ZIDOVUDINE VIATRIS"},
{label: "LAMOROFANE"},
{label: "LAMOTRIGINE ARROW"},
{label: "LAMOTRIGINE ARROW LAB"},
{label: "LAMOTRIGINE AUROBINDO"},
{label: "LAMOTRIGINE BIOGALENIQUE"},
{label: "LAMOTRIGINE BIOGARAN"},
{label: "LAMOTRIGINE EG"},
{label: "LAMOTRIGINE GSK"},
{label: "LAMOTRIGINE IVAX"},
{label: "LAMOTRIGINE MYLAN"},
{label: "LAMOTRIGINE PAUCOURT"},
{label: "LAMOTRIGINE PFIZER"},
{label: "LAMOTRIGINE QUALIMED"},
{label: "LAMOTRIGINE QUIVER"},
{label: "LAMOTRIGINE RANBAXY"},
{label: "LAMOTRIGINE RATIOPHARM"},
{label: "LAMOTRIGINE SAINT GERMAIN"},
{label: "LAMOTRIGINE SANDOZ"},
{label: "LAMOTRIGINE TEVA"},
{label: "LAMOTRIGINE VIATRIS"},
{label: "LAMPRENE"},
{label: "LAMPSANA COMMUNIS BOIRON"},
{label: "LAMZEDE"},
{label: "LANBERPRADELZOLE"},
{label: "LANCINAL"},
{label: "LANGORAN INJECTABLE"},
{label: "LANGORAN L.P."},
{label: "LANGORAN LP"},
{label: "LANOFENE"},
{label: "LANSINOH"},
{label: "LANSOPRAZOLE ALMUS"},
{label: "LANSOPRAZOLE ALMUS PHARMA"},
{label: "LANSOPRAZOLE ARROW"},
{label: "LANSOPRAZOLE ARROW LAB"},
{label: "LANSOPRAZOLE BGR"},
{label: "LANSOPRAZOLE BIOGARAN"},
{label: "LANSOPRAZOLE CRISTERS"},
{label: "LANSOPRAZOLE EG"},
{label: "LANSOPRAZOLE EVOLUGEN"},
{label: "LANSOPRAZOLE ISOMED"},
{label: "LANSOPRAZOLE KRKA"},
{label: "LANSOPRAZOLE LICONSA"},
{label: "LANSOPRAZOLE MYLAN PHARMA"},
{label: "LANSOPRAZOLE PHR LAB"},
{label: "LANSOPRAZOLE QUALIMED"},
{label: "LANSOPRAZOLE QUALIMED GENERIQUES"},
{label: "LANSOPRAZOLE RANBAXY"},
{label: "LANSOPRAZOLE RATIOPHARM"},
{label: "LANSOPRAZOLE REGULATORY PHARMA NET"},
{label: "LANSOPRAZOLE SALVAT"},
{label: "LANSOPRAZOLE SANDOZ"},
{label: "LANSOPRAZOLE TAKEDA"},
{label: "LANSOPRAZOLE TEVA"},
{label: "LANSOPRAZOLE TEVA SANTE"},
{label: "LANSOPRAZOLE VIATRIS"},
{label: "LANSOPRAZOLE ZENTIVA"},
{label: "LANSOPRAZOLE ZENTIVA LAB"},
{label: "LANSOPRAZOLE ZYDUS"},
{label: "LANSOPRAZOLE ZYDUS FRANCE"},
{label: "LANSOYL FRAMBOISE"},
{label: "LANSOYL ORANGE"},
{label: "LANSOYL SANS SUCRE"},
{label: "LANSOYL SANS SUCRE (11"},
{label: "LANSOYL SUPPOSITOIRES A LA GLYCERINE ADULTES"},
{label: "LANTADIN"},
{label: "LANTHANE VIATRIS"},
{label: "LANTUS"},
{label: "LANTUS OPTISET"},
{label: "LANTUS SOLOSTAR"},
{label: "LANUMEEL"},
{label: "LANVIS"},
{label: "LANZOR"},
{label: "LAPATINIB PHAROS"},
{label: "LAPIS ALBUS BOIRON"},
{label: "LAPIS CANCRI SILICICUM WELEDA"},
{label: "LAPPA MAJOR BOIRON"},
{label: "LAPPA MAJOR LEHNING"},
{label: "LAPPA MINOR BOIRON"},
{label: "LARGACTIL"},
{label: "LARIAM"},
{label: "LARMABAK"},
{label: "LARMES ARTIFICIELLES MARTINET"},
{label: "LARODOPA"},
{label: "LAROFERON"},
{label: "LAROSCORBINE"},
{label: "LAROSCORBINE ENFANTS"},
{label: "LAROSCORBINE SANS SUCRE"},
{label: "LAROXYL"},
{label: "LARTRUVO"},
{label: "LASILIX"},
{label: "LASILIX FAIBLE"},
{label: "LASILIX RETARD"},
{label: "LASILIX SPECIAL"},
{label: "LATANOPROST ACTAVIS"},
{label: "LATANOPROST ARROW"},
{label: "LATANOPROST BGR"},
{label: "LATANOPROST BIOGARAN"},
{label: "LATANOPROST BREATH"},
{label: "LATANOPROST CHAUVIN"},
{label: "LATANOPROST CHEMICAL FARMA"},
{label: "LATANOPROST CRISTERS"},
{label: "LATANOPROST EG"},
{label: "LATANOPROST FDC PHARMA"},
{label: "LATANOPROST MYLAN"},
{label: "LATANOPROST NIALEX"},
{label: "LATANOPROST NORIDEM"},
{label: "LATANOPROST OMNIVISION"},
{label: "LATANOPROST PHARMABIDE"},
{label: "LATANOPROST POS"},
{label: "LATANOPROST RANBAXY"},
{label: "LATANOPROST RATIOPHARM"},
{label: "LATANOPROST SANDOZ"},
{label: "LATANOPROST TEVA"},
{label: "LATANOPROST TEVA SANTE"},
{label: "LATANOPROST VIATRIS"},
{label: "LATANOPROST VIATRIS GENERIQUES"},
{label: "LATANOPROST ZENTIVA"},
{label: "LATANOPROST ZYDUS"},
{label: "LATANOPROST/TIMOLOL ARROW"},
{label: "LATANOPROST/TIMOLOL BIOGARAN"},
{label: "LATANOPROST/TIMOLOL CRISTERS"},
{label: "LATANOPROST/TIMOLOL EG"},
{label: "LATANOPROST/TIMOLOL FDC PHARMA"},
{label: "LATANOPROST/TIMOLOL MYLAN"},
{label: "LATANOPROST/TIMOLOL PHARMAKI GENERICS"},
{label: "LATANOPROST/TIMOLOL PHARMATHEN"},
{label: "LATANOPROST/TIMOLOL SANDOZ"},
{label: "LATANOPROST/TIMOLOL TEVA"},
{label: "LATANOPROST/TIMOLOL VIATRIS"},
{label: "LATANOPROST/TIMOLOL ZENTIVA"},
{label: "LATANOPROST/TIMOLOL ZYDUS"},
{label: "LATAZED"},
{label: "LATHYRUS SATIVUS BOIRON"},
{label: "LATIMPOS"},
{label: "LATRODECTUS MACTANS BOIRON"},
{label: "LATUDA"},
{label: "LAURETTE"},
{label: "LAUROCERASUS BOIRON"},
{label: "LAUROCERASUS LEHNING"},
{label: "LAUROCERASUS WELEDA"},
{label: "LAUROMACROGOL"},
{label: "LAUVIR"},
{label: "LAVANDULA SPICA BOIRON"},
{label: "LAVANDULA VERA BOIRON"},
{label: "LAVEMENT FLEET PRET A L'EMPLOI"},
{label: "LAVEMENT PHOSPHATES ADULTES"},
{label: "LAVEMENT PHOSPHATES ENFANTS"},
{label: "LAVENTAIR ELLIPTA"},
{label: "LAXADOU"},
{label: "LAXAFIT ADULTES"},
{label: "LAXAFIT ENFANTS"},
{label: "LAXAMALT"},
{label: "LAXARON"},
{label: "LAXASAN"},
{label: "LAXATIF PACHAUT ADULTES"},
{label: "LAXATIF PACHAUT ENFANTS"},
{label: "LAXATIF RICHELET"},
{label: "LAXATISANE"},
{label: "LAXILO"},
{label: "LAXOSOFT"},
{label: "LAZULITE BOIRON"},
{label: "LEACHIM"},
{label: "LECIGIMON"},
{label: "LECROLYN"},
{label: "LECTAZIB"},
{label: "LECTIL"},
{label: "LEDAGA"},
{label: "LEDERFOLINE"},
{label: "LEDERNA"},
{label: "LEDERTREXATE"},
{label: "LEDUM COMPLEXE N°81"},
{label: "LEDUM PALUSTRE BOIRON"},
{label: "LEDUM PALUSTRE LEHNING"},
{label: "LEDUM PALUSTRE WELEDA"},
{label: "LEELOO"},
{label: "LEELOO CONTINU"},
{label: "LEFLUNOMIDE ARROW"},
{label: "LEFLUNOMIDE BIOGARAN"},
{label: "LEFLUNOMIDE EG"},
{label: "LEFLUNOMIDE MEDAC"},
{label: "LEFLUNOMIDE MYLAN"},
{label: "LEFLUNOMIDE RATIOPHARM"},
{label: "LEFLUNOMIDE VIATRIS"},
{label: "LEFLUNOMIDE ZENTIVA"},
{label: "LEGALON"},
{label: "LEGGER"},
{label: "LELONG CONTUSIONS"},
{label: "LELONG IRRITATIONS"},
{label: "LEMNA MINOR BOIRON"},
{label: "LEMONAI"},
{label: "LEMTRADA"},
{label: "LENALIDOMIDE ACCORD"},
{label: "LENALIDOMIDE ACINO"},
{label: "LENALIDOMIDE ARROW"},
{label: "LENALIDOMIDE CIPLA"},
{label: "LENALIDOMIDE EG"},
{label: "LENALIDOMIDE FRESENIUS KABI"},
{label: "LENALIDOMIDE GRINDEKS"},
{label: "LENALIDOMIDE HELM"},
{label: "LENALIDOMIDE KRKA"},
{label: "LENALIDOMIDE MYLAN"},
{label: "LENALIDOMIDE PIRAMAL"},
{label: "LENALIDOMIDE REDDY PHARMA"},
{label: "LENALIDOMIDE SANDOZ"},
{label: "LENALIDOMIDE STRAGEN"},
{label: "LENALIDOMIDE TEVA"},
{label: "LENALIDOMIDE ZENTIVA"},
{label: "LENCYA"},
{label: "LENIA"},
{label: "LENIBERCARDIPE"},
{label: "LENICALM"},
{label: "LENITRAL"},
{label: "LENITRAL INJECTABLE"},
{label: "LENITRAL PERCUTANE"},
{label: "LENOSCINT"},
{label: "LENOXE"},
{label: "LENTARON"},
{label: "LENVIMA"},
{label: "LEODRINE"},
{label: "LEPIDOLITE BOIRON"},
{label: "LEPONEX"},
{label: "LEPSITAM"},
{label: "LEPTAX"},
{label: "LEPTICUR"},
{label: "LEPTICUR PARK"},
{label: "LEPTOPROL"},
{label: "LEQVIO"},
{label: "LERCAN"},
{label: "LERCANIDIPINE ACCORD"},
{label: "LERCANIDIPINE ALMUS"},
{label: "LERCANIDIPINE ARROW"},
{label: "LERCANIDIPINE ARROW GENERIQUES"},
{label: "LERCANIDIPINE BIOGARAN"},
{label: "LERCANIDIPINE BOUCHARA-RECORDATI"},
{label: "LERCANIDIPINE CRISTERS"},
{label: "LERCANIDIPINE EG"},
{label: "LERCANIDIPINE EG LABO"},
{label: "LERCANIDIPINE EVOLUGEN"},
{label: "LERCANIDIPINE ISOMED"},
{label: "LERCANIDIPINE MYLAN PHARMA"},
{label: "LERCANIDIPINE OPENING PHARMA"},
{label: "LERCANIDIPINE PHR LAB"},
{label: "LERCANIDIPINE QUALIMED"},
{label: "LERCANIDIPINE QUALIMED GENERIQUES"},
{label: "LERCANIDIPINE RANBAXY PHARMACIE GENERIQUES"},
{label: "LERCANIDIPINE SANDOZ"},
{label: "LERCANIDIPINE SFDB"},
{label: "LERCANIDIPINE SUN"},
{label: "LERCANIDIPINE TEVA"},
{label: "LERCANIDIPINE TEVA SANTE"},
{label: "LERCANIDIPINE TORRENT"},
{label: "LERCANIDIPINE VIATRIS"},
{label: "LERCANIDIPINE ZENTIVA"},
{label: "LERCANIDIPINE ZYDUS"},
{label: "LERCAPRESS"},
{label: "LERGYPAX"},
{label: "LESCOL"},
{label: "LESCOL L.P."},
{label: "LESPENEPHRYL"},
{label: "LETROMAN"},
{label: "LETROZOLE ACCORD HEALTHCARE"},
{label: "LETROZOLE ACTAVIS"},
{label: "LETROZOLE ALMUS"},
{label: "LETROZOLE ALTER"},
{label: "LETROZOLE ARROW"},
{label: "LETROZOLE ARROW LAB"},
{label: "LETROZOLE AXOREL"},
{label: "LETROZOLE BIOGARAN"},
{label: "LETROZOLE BLUEFISH"},
{label: "LETROZOLE CHEMICAL FARMA"},
{label: "LETROZOLE CRISTERS"},
{label: "LETROZOLE CRISTERS PHARMA"},
{label: "LETROZOLE EG"},
{label: "LETROZOLE EVOLUGEN"},
{label: "LETROZOLE FRESENIUS"},
{label: "LETROZOLE ISOMED"},
{label: "LETROZOLE KABI"},
{label: "LETROZOLE MEDIPHA SANTE"},
{label: "LETROZOLE PHR LAB"},
{label: "LETROZOLE QUALIMED"},
{label: "LETROZOLE RANBAXY"},
{label: "LETROZOLE RATIOPHARM"},
{label: "LETROZOLE REGIOMEDICA"},
{label: "LETROZOLE SANDOZ 2.5"},
{label: "LETROZOLE SYNTHON"},
{label: "LETROZOLE TECNIMEDE"},
{label: "LETROZOLE TECNIMEDE SOCIEDADE TECNICO MEDICINAL"},
{label: "LETROZOLE TEVA"},
{label: "LETROZOLE TORRENT"},
{label: "LETROZOLE VIATRIS"},
{label: "LETROZOLE ZENTIVA"},
{label: "LETROZOLE ZYDUS"},
{label: "LETYBO"},
{label: "LEUCO-4"},
{label: "LEUCOCIANIDOL SUBSTIPHARM"},
{label: "LEUCODININE B"},
{label: "LEUCOMAX"},
{label: "LEUKOSCAN"},
{label: "LEUPRORELINE ZENTIVA"},
{label: "LEUSTATINE"},
{label: "LEVACT"},
{label: "LEVEMIR FLEXPEN"},
{label: "LEVEMIR INNOLET"},
{label: "LEVEMIR PENFILL"},
{label: "LEVETIRACETAM ACCORD"},
{label: "LEVETIRACETAM ACTAVIS"},
{label: "LEVETIRACETAM ACTAVIS FRANCE"},
{label: "LEVETIRACETAM ACTAVIS GROUP"},
{label: "LEVETIRACETAM AGUETTANT"},
{label: "LEVETIRACETAM ALTAN"},
{label: "LEVETIRACETAM ALTER"},
{label: "LEVETIRACETAM ARROW"},
{label: "LEVETIRACETAM ARROW LAB"},
{label: "LEVETIRACETAM BIOGARAN"},
{label: "LEVETIRACETAM BRILLPHARMA"},
{label: "LEVETIRACETAM CRISTERS"},
{label: "LEVETIRACETAM CRISTERS PHARMA"},
{label: "LEVETIRACETAM EG"},
{label: "LEVETIRACETAM EG LABO"},
{label: "LEVETIRACETAM EVOLUGEN"},
{label: "LEVETIRACETAM HIKMA"},
{label: "LEVETIRACETAM HOSPIRA"},
{label: "LEVETIRACETAM KRKA"},
{label: "LEVETIRACETAM MYLAN"},
{label: "LEVETIRACETAM MYLAN GENERIQUES"},
{label: "LEVETIRACETAM MYPLIX"},
{label: "LEVETIRACETAM NIALEX"},
{label: "LEVETIRACETAM NORIDEM"},
{label: "LEVETIRACETAM NORPHARM REGULATORY SERVICES"},
{label: "LEVETIRACETAM PHARMATHEN"},
{label: "LEVETIRACETAM PHAROS"},
{label: "LEVETIRACETAM RANBAXY"},
{label: "LEVETIRACETAM REF"},
{label: "LEVETIRACETAM SANDOZ"},
{label: "LEVETIRACETAM SANOVEL"},
{label: "LEVETIRACETAM SET"},
{label: "LEVETIRACETAM STRAGEN"},
{label: "LEVETIRACETAM SUN"},
{label: "LEVETIRACETAM SYNTHON"},
{label: "LEVETIRACETAM TEVA"},
{label: "LEVETIRACETAM TILLOMED"},
{label: "LEVETIRACETAM TORRENT"},
{label: "LEVETIRACETAM VIATRIS"},
{label: "LEVETIRACETAM WOCKHARDT"},
{label: "LEVETIRACETAM ZENTIVA"},
{label: "LEVETIRACETAM ZENTIVA LAB"},
{label: "LEVETIRACETAM ZYDUS"},
{label: "LEVICO WELEDA"},
{label: "LEVIDCEN"},
{label: "LEVISTICUM OFFICINALE"},
{label: "LEVISTICUM OFFICINALE BOIRON"},
{label: "LEVITRA"},
{label: "LEVMENTIN"},
{label: "LEVOBUNOLOL ALCON"},
{label: "LEVOBUPIVACAINE ALTAN"},
{label: "LEVOBUPIVACAINE KABI"},
{label: "LEVOBUPIVACAINE MEDAC"},
{label: "LEVOBUPIVACAINEMEDAC"},
{label: "LEVOCARNIL"},
{label: "LEVOCARNITINE AGUETTANT"},
{label: "LEVOCARNITINE TURBODIET"},
{label: "LEVOCARNITINE UNI-PHARMA"},
{label: "LEVOCETIRA"},
{label: "LEVOCETIRIZINE ARROW"},
{label: "LEVOCETIRIZINE BIOGARAN"},
{label: "LEVOCETIRIZINE CRISTERS"},
{label: "LEVOCETIRIZINE EG"},
{label: "LEVOCETIRIZINE EVOLUGEN"},
{label: "LEVOCETIRIZINE KRKA"},
{label: "LEVOCETIRIZINE LA COLINA"},
{label: "LEVOCETIRIZINE NIALEX"},
{label: "LEVOCETIRIZINE RANBAXY"},
{label: "LEVOCETIRIZINE RATIOPHARM"},
{label: "LEVOCETIRIZINE SANDOZ"},
{label: "LEVOCETIRIZINE TEVA"},
{label: "LEVOCETIRIZINE URGO"},
{label: "LEVOCETIRIZINE VIATRIS"},
{label: "LEVOCETIRIZINE ZENTIVA"},
{label: "LEVOCETIRIZINE ZYDUS"},
{label: "LEVODOPA BENSERAZIDE TEVA"},
{label: "LEVODOPA CARBIDOPA TEVA"},
{label: "LEVODOPA CARBIDOPA TEVA LP"},
{label: "LEVODOPA/BENSERAZIDE TEVA"},
{label: "LEVODOPA/CARBIDOPA ACCORD LP"},
{label: "LEVODOPA/CARBIDOPA MYLAN"},
{label: "LEVODOPA/CARBIDOPA/ENTACAPONE ACCORD"},
{label: "LEVODOPA/CARBIDOPA/ENTACAPONE ARROW"},
{label: "LEVODOPA/CARBIDOPA/ENTACAPONE BIOGARAN"},
{label: "LEVODOPA/CARBIDOPA/ENTACAPONE EG"},
{label: "LEVODOPA/CARBIDOPA/ENTACAPONE TEVA"},
{label: "LEVODOPA/CARBIDOPA/ENTACAPONE VIATRIS"},
{label: "LEVOFLOXACINE ACCORD HEALTHCARE"},
{label: "LEVOFLOXACINE ACTAVIS"},
{label: "LEVOFLOXACINE ALMUS"},
{label: "LEVOFLOXACINE ARROW"},
{label: "LEVOFLOXACINE ARROW LAB"},
{label: "LEVOFLOXACINE AUROBINDO"},
{label: "LEVOFLOXACINE B.BRAUN"},
{label: "LEVOFLOXACINE BIOGARAN"},
{label: "LEVOFLOXACINE BLUEFISH"},
{label: "LEVOFLOXACINE CLEXNI"},
{label: "LEVOFLOXACINE CRISTERS"},
{label: "LEVOFLOXACINE DELBERT"},
{label: "LEVOFLOXACINE EG"},
{label: "LEVOFLOXACINE EVOLUGEN"},
{label: "LEVOFLOXACINE HOSPIRA"},
{label: "LEVOFLOXACINE IBD3 PHARMA CONSULTING"},
{label: "LEVOFLOXACINE INFOMED"},
{label: "LEVOFLOXACINE ISOMED"},
{label: "LEVOFLOXACINE KABI"},
{label: "LEVOFLOXACINE KRKA"},
{label: "LEVOFLOXACINE MYLAN"},
{label: "LEVOFLOXACINE NIALEX"},
{label: "LEVOFLOXACINE NORPHARM REGULATORY SERVICES"},
{label: "LEVOFLOXACINE PFIZER"},
{label: "LEVOFLOXACINE PHR LAB"},
{label: "LEVOFLOXACINE QUALIMED"},
{label: "LEVOFLOXACINE RATIOPHARM"},
{label: "LEVOFLOXACINE SANDOZ"},
{label: "LEVOFLOXACINE SANOVEL"},
{label: "LEVOFLOXACINE SUN"},
{label: "LEVOFLOXACINE TEVA"},
{label: "LEVOFLOXACINE TORRENT PHARMA"},
{label: "LEVOFLOXACINE VIATRIS"},
{label: "LEVOFLOXACINE ZENTIVA"},
{label: "LEVOFLOXACINE ZYDUS"},
{label: "LEVOFOLINATE DE CALCIUM DCI PHARMA"},
{label: "LEVOFOLINATE DE CALCIUM HOSPIRA"},
{label: "LEVOFOLINATE DE CALCIUM WINTHROP"},
{label: "LEVOFOLINATE DE CALCIUM ZENTIVA"},
{label: "LEVOFOLINATE DE SODIUM MEDAC"},
{label: "LEVOFREE"},
{label: "LEVONELLE"},
{label: "LEVONORGESTREL BIOGARAN"},
{label: "LEVONORGESTREL CRISTERS"},
{label: "LEVONORGESTREL EG"},
{label: "LEVONORGESTREL HRA"},
{label: "LEVONORGESTREL SANDOZ"},
{label: "LEVONORGESTREL TEVA"},
{label: "LEVONORGESTREL VIATRIS"},
{label: "LEVONORGESTREL ZENTIVA"},
{label: "LEVONORGESTREL/ETHINYLESTRADIOL BIOGARAN"},
{label: "LEVONORGESTREL/ETHINYLESTRADIOL BIOGARANCONTINU"},
{label: "LEVONORGESTREL/ETHINYLESTRADIOL CRISTERS"},
{label: "LEVONORGESTREL/ETHINYLESTRADIOL EG"},
{label: "LEVONORGESTREL/ETHINYLESTRADIOL LEON FARMA"},
{label: "LEVONORGESTREL/ETHINYLESTRADIOL LEON FARMA CONTINU"},
{label: "LEVONORGESTREL/ETHINYLESTRADIOL MYLAN PHARMA"},
{label: "LEVONORGESTREL/ETHINYLESTRADIOL REF"},
{label: "LEVONORGESTREL/ETHINYLESTRADIOL RICHTER"},
{label: "LEVONORGESTREL/ETHINYLESTRADIOL SANDOZ"},
{label: "LEVONORGESTREL/ETHINYLESTRADIOL ZENTIVA"},
{label: "LEVOPHTA"},
{label: "LEVOSIMENDAN ACCORD"},
{label: "LEVOSIMENDAN ALTAN"},
{label: "LEVOSIMENDAN KABI"},
{label: "LEVOSIMENDAN KALCEKS 2.5"},
{label: "LEVOSIMENDAN PHARMIDEA"},
{label: "LEVOSOLO"},
{label: "LEVOTHYROX"},
{label: "LEVOTHYROXINE ABDI FARMA"},
{label: "LEVOTHYROXINE BIOGARAN"},
{label: "LEVOTHYROXINE HELM AG"},
{label: "LEVOTHYROXINE RATIOPHARM"},
{label: "LEVOTONINE"},
{label: "LEVOVIST"},
{label: "LEVRIX"},
{label: "LEVULOSE"},
{label: "LEVULOSE AGUETTANT"},
{label: "LEVULOSE B.BRAUN"},
{label: "LEVULOSE BIOSEDRA"},
{label: "LEVULOSE COOPER"},
{label: "LEVULOSE HYPERTONIQUE AGUETTANT"},
{label: "LEVULOSE HYPERTONIQUE BIOSEDRA"},
{label: "LEVULOSE HYPERTONIQUE MERAM"},
{label: "LEVUNIQUE"},
{label: "LEVURE OR GRIMBERG"},
{label: "LEXENU"},
{label: "LEXOMIL"},
{label: "LEXTEMY"},
{label: "LEZARIBE"},
{label: "LHRH FERRING"},
{label: "LIBMELDY 2-10"},
{label: "LIBRADOL"},
{label: "LIBRADOL CAFEINE"},
{label: "LIBRAX"},
{label: "LIBRIUM"},
{label: "LIBTAYO"},
{label: "LICOBANDRONAT"},
{label: "LICREASE"},
{label: "LIDBREE"},
{label: "LIDENE"},
{label: "LIDOCAINE"},
{label: "LIDOCAINE A"},
{label: "LIDOCAINE AGUETTANT"},
{label: "LIDOCAINE ARROW"},
{label: "LIDOCAINE CHLORHYDRATE RENAUDIN"},
{label: "LIDOCAINE/PRILOCAINE AGUETTANT"},
{label: "LIDOCAINE/PRILOCAINE BIOGARAN"},
{label: "LIDOCAINE/PRILOCAINE ZENTIVA"},
{label: "LIDOCARSEN"},
{label: "LIDOCAÏNE"},
{label: "LIDOCAÏNE ACCORD"},
{label: "LIDOCAÏNE GRÜNENTHAL"},
{label: "LIDOCAÏNE KABI"},
{label: "LIDOCAÏNE/PRILOCAÏNE BIO VALYS"},
{label: "LIDOCAÏNE/PRILOCAÏNE TEVA"},
{label: "LIDOGEL"},
{label: "LIDRIAN"},
{label: "LIERRE GRIMPANT BIOGARAN CONSEIL SANS SUCRE"},
{label: "LIERRE GRIMPANT EG LABO CONSEIL SANS SUCRE"},
{label: "LIERRE GRIMPANT H2 PHARMA ADULTES"},
{label: "LIERRE GRIMPANT H4 PHARMA"},
{label: "LIERRE GRIMPANT H4 PHARMA ADULTES"},
{label: "LIERRE GRIMPANT HUMEXPHYTO SANS SUCRE"},
{label: "LIERRE GRIMPANT LIBERTY PHARMA"},
{label: "LIERRE GRIMPANT PHYTOCLARIX SANS SUCRE"},
{label: "LIERRE GRIMPANT PHYTOPHARM"},
{label: "LIERRE GRIMPANT SANOFI SANS SUCRE"},
{label: "LIERRE GRIMPANT VIATRIS CONSEIL SANS SUCRE"},
{label: "LIERRE SANOFI"},
{label: "LIERRE TERRESTRE LES BONS PRODUCTEURS"},
{label: "LIFMIOR"},
{label: "LIFOG"},
{label: "LIGAMENT VERTEBRAL BOIRON"},
{label: "LIGAMENTS BOIRON"},
{label: "LIGUSTRUM VULGARE BOIRON"},
{label: "LIKOZAM"},
{label: "LILADROS"},
{label: "LILADROSCONTINU"},
{label: "LILIUM ALBUM BOIRON"},
{label: "LILIUM TIGRINUM BOIRON"},
{label: "LILIUM TIGRINUM WELEDA"},
{label: "LILLYPEN PROFIL 10.100 UI/1"},
{label: "LILLYPEN PROFIL 20.100 UI/1"},
{label: "LILLYPEN PROFIL 30.100 UI/1"},
{label: "LILLYPEN PROFIL 40.100 UI/1"},
{label: "LILLYPEN PROFIL 50.100 UI/1"},
{label: "LILLYPEN PROTAMINE ISOPHANE NPH"},
{label: "LILLYPEN RAPIDE"},
{label: "LILOR"},
{label: "LINAGLIPTINE ZENTIVA"},
{label: "LINCOCINE"},
{label: "LINDILANE"},
{label: "LINEA"},
{label: "LINEZOLIDE ACCORD"},
{label: "LINEZOLIDE ARROW"},
{label: "LINEZOLIDE B. BRAUN"},
{label: "LINEZOLIDE DR. REDDYS"},
{label: "LINEZOLIDE EG"},
{label: "LINEZOLIDE ELC"},
{label: "LINEZOLIDE FRESENIUS KABI"},
{label: "LINEZOLIDE HIKMA"},
{label: "LINEZOLIDE HOSPIRA FRANCE"},
{label: "LINEZOLIDE KABI"},
{label: "LINEZOLIDE KRKA"},
{label: "LINEZOLIDE MYLAN"},
{label: "LINEZOLIDE OHRE PHARMA"},
{label: "LINEZOLIDE PANPHARMA"},
{label: "LINEZOLIDE PFIZER"},
{label: "LINEZOLIDE PHARMAKI GENERICS"},
{label: "LINEZOLIDE SANDOZ"},
{label: "LINEZOLIDE STRAGEN"},
{label: "LINEZOLIDE TEVA"},
{label: "LINEZOLIDE VIATRIS"},
{label: "LINIBON"},
{label: "LINIMENT HOLL"},
{label: "LINUM USITATISSIMUM BOIRON"},
{label: "LINÉZOLIDE KRKA"},
{label: "LIORESAL"},
{label: "LIPANOR"},
{label: "LIPANTHYL"},
{label: "LIPERCOSYL"},
{label: "LIPICLAIR"},
{label: "LIPIDEM"},
{label: "LIPILFEN"},
{label: "LIPILO"},
{label: "LIPIOCIS"},
{label: "LIPIODOL ULTRA-FLUIDE"},
{label: "LIPIPHARM"},
{label: "LIPIREX"},
{label: "LIPOFEINE"},
{label: "LIPOROSA"},
{label: "LIPOSIC"},
{label: "LIPOTHYMAL"},
{label: "LIPOVEN"},
{label: "LIPPIZA"},
{label: "LIPTRUZET"},
{label: "LIPUR"},
{label: "LIQUEMINE"},
{label: "LIQUIFILM LARMES ARTIFICIELLES"},
{label: "LISINOPRIL ACTAVIS"},
{label: "LISINOPRIL ARROW"},
{label: "LISINOPRIL ARROW LAB"},
{label: "LISINOPRIL BIOGARAN"},
{label: "LISINOPRIL CRISTERS"},
{label: "LISINOPRIL DEXO"},
{label: "LISINOPRIL EG"},
{label: "LISINOPRIL EG LABO"},
{label: "LISINOPRIL EG LABO-LABORATOIRES EUROGENERICS"},
{label: "LISINOPRIL G GAM"},
{label: "LISINOPRIL HYDROCHLOROTHIAZIDE ALTER"},
{label: "LISINOPRIL MYLAN PHARMA"},
{label: "LISINOPRIL NORDIC PHARMA"},
{label: "LISINOPRIL PFIZER"},
{label: "LISINOPRIL QUALIMED"},
{label: "LISINOPRIL QUALIMED GENERIQUES"},
{label: "LISINOPRIL RANBAXY"},
{label: "LISINOPRIL RATIOPHARM"},
{label: "LISINOPRIL RPG"},
{label: "LISINOPRIL SANDOZ"},
{label: "LISINOPRIL TEVA"},
{label: "LISINOPRIL VENIPHARM"},
{label: "LISINOPRIL VIATRIS"},
{label: "LISINOPRIL WINTHROP"},
{label: "LISINOPRIL ZENTIVA"},
{label: "LISINOPRIL ZYDUS"},
{label: "LISINOPRIL/HYDROCHLOROTHIAZIDE ARROW"},
{label: "LISINOPRIL/HYDROCHLOROTHIAZIDE ARROW LAB"},
{label: "LISINOPRIL/HYDROCHLOROTHIAZIDE BIOGARAN"},
{label: "LISINOPRIL/HYDROCHLOROTHIAZIDE EG"},
{label: "LISINOPRIL/HYDROCHLOROTHIAZIDE MYLAN PHARMA"},
{label: "LISINOPRIL/HYDROCHLOROTHIAZIDE QUALIMED"},
{label: "LISINOPRIL/HYDROCHLOROTHIAZIDE RANBAXY"},
{label: "LISINOPRIL/HYDROCHLOROTHIAZIDE RATIOPHARM"},
{label: "LISINOPRIL/HYDROCHLOROTHIAZIDE SANDOZ"},
{label: "LISINOPRIL/HYDROCHLOROTHIAZIDE TEVA"},
{label: "LISINOPRIL/HYDROCHLOROTHIAZIDE VIATRIS"},
{label: "LISINOPRIL/HYDROCHLOROTHIAZIDE ZENTIVA"},
{label: "LISINOPRIL/HYDROCHLOROTHIAZIDE ZYDUS"},
{label: "LISVY"},
{label: "LITAK"},
{label: "LITHIAGEL"},
{label: "LITHINAL"},
{label: "LITHIODERM"},
{label: "LITHIUM CARBONICUM BOIRON"},
{label: "LITHIUM CARBONICUM LEHNING"},
{label: "LITHIUM CARBONICUM WELEDA"},
{label: "LITHIUM MICROSOL"},
{label: "LITHIUM OLIGOSOL"},
{label: "LITHOL"},
{label: "LITOSMIL"},
{label: "LITOXOL"},
{label: "LIVENSA"},
{label: "LIVIAL"},
{label: "LIVMARLI"},
{label: "LIVOGIVA"},
{label: "LIVOSTIN"},
{label: "LIVTENCITY"},
{label: "LIXIANA"},
{label: "LIZOLOROL"},
{label: "LOBAMINE CYSTEINE"},
{label: "LOBELIA CARDINALIS BOIRON"},
{label: "LOBELIA ERINUS BOIRON"},
{label: "LOBELIA INFLATA BOIRON"},
{label: "LOBELIA INFLATA LEHNING"},
{label: "LOBELIA INFLATA WELEDA"},
{label: "LOBERASNAT"},
{label: "LOCABIOTAL"},
{label: "LOCACID"},
{label: "LOCACORTENE"},
{label: "LOCALONE"},
{label: "LOCAMETZ"},
{label: "LOCAPRED"},
{label: "LOCATOP"},
{label: "LOCERYL"},
{label: "LOCERYLPRO"},
{label: "LOCOID"},
{label: "LOCOIDEN"},
{label: "LODALES"},
{label: "LODIARID"},
{label: "LODINE"},
{label: "LODINE LP"},
{label: "LODOTRA"},
{label: "LODOXAL"},
{label: "LODOZ"},
{label: "LOFTYL"},
{label: "LOGECINE"},
{label: "LOGIFLOX"},
{label: "LOGIMAX"},
{label: "LOGIRENE"},
{label: "LOGROTON"},
{label: "LOGRYX"},
{label: "LOJUXTA"},
{label: "LOKELMA"},
{label: "LOLISTREL"},
{label: "LOLISTREL CONTINU"},
{label: "LOLIUM TEMULENTUM BOIRON"},
{label: "LOMEXIN"},
{label: "LOMOL"},
{label: "LOMUDAL"},
{label: "LOMUSOL"},
{label: "LOMUSTINE MEDAC"},
{label: "LONGACOR"},
{label: "LONGALGIC"},
{label: "LONICERA CAPRIFOLIUM BOIRON"},
{label: "LONOTEN"},
{label: "LONQUEX"},
{label: "LONSURF"},
{label: "LONVECT"},
{label: "LOPERAMIDE (CHLORHYDRATE) GIFRER"},
{label: "LOPERAMIDE ALMUS"},
{label: "LOPERAMIDE ARROW"},
{label: "LOPERAMIDE ARROW CONSEIL"},
{label: "LOPERAMIDE ARROW GENERIQUES"},
{label: "LOPERAMIDE ARROW LAB"},
{label: "LOPERAMIDE BGR CONSEIL"},
{label: "LOPERAMIDE BIOGARAN"},
{label: "LOPERAMIDE BIOGARAN CONSEIL"},
{label: "LOPERAMIDE CHLORHYDRATE SCAT"},
{label: "LOPERAMIDE CILFA"},
{label: "LOPERAMIDE CRISTERS"},
{label: "LOPERAMIDE EG"},
{label: "LOPERAMIDE EG CONSEIL"},
{label: "LOPERAMIDE EVOLUGEN"},
{label: "LOPERAMIDE GNR"},
{label: "LOPERAMIDE IDP"},
{label: "LOPERAMIDE LYOC"},
{label: "LOPERAMIDE NEPENTHES"},
{label: "LOPERAMIDE NOR"},
{label: "LOPERAMIDE PHARMYGIENE-SCAT"},
{label: "LOPERAMIDE REF"},
{label: "LOPERAMIDE SANDOZ"},
{label: "LOPERAMIDE SANDOZ CONSEIL"},
{label: "LOPERAMIDE SUN"},
{label: "LOPERAMIDE SYNTHELABO OTC"},
{label: "LOPERAMIDE TEVA"},
{label: "LOPERAMIDE TEVA CLASSICS"},
{label: "LOPERAMIDE TEVA CONSEIL"},
{label: "LOPERAMIDE UPSA"},
{label: "LOPERAMIDE URGO"},
{label: "LOPERAMIDE VIAREF"},
{label: "LOPERAMIDE VIATRIS"},
{label: "LOPERAMIDE VIATRIS CONSEIL"},
{label: "LOPERAMIDE WINTHROP"},
{label: "LOPERAMIDE ZENTIVA"},
{label: "LOPERAMIDE ZENTIVA CONSEIL"},
{label: "LOPERAMIDE ZYDUS"},
{label: "LOPERAMIDE ZYDUS FRANCE"},
{label: "LOPHOPHYTUM LEANDRI BOIRON"},
{label: "LOPINAVIR RITONAVIR RANBAXY"},
{label: "LOPINAVIR/RITONAVIR DEXTREG"},
{label: "LOPINAVIR/RITONAVIR MYLAN"},
{label: "LOPINAVIR/RITONAVIR RANBAXY"},
{label: "LOPINAVIR/RITONAVIR SANDOZ"},
{label: "LOPRAZOLAM AMDIPHARM"},
{label: "LOPRESSOR"},
{label: "LOPRESSOR INJECTABLE"},
{label: "LOPRESSOR L P"},
{label: "LOPRESSOR L.P."},
{label: "LOPRIL"},
{label: "LORAMYC"},
{label: "LORATADINE ACTAVIS FRANCE"},
{label: "LORATADINE ALMUS"},
{label: "LORATADINE ARROW"},
{label: "LORATADINE ARROW CONSEIL"},
{label: "LORATADINE BIOGARAN"},
{label: "LORATADINE BIOGARAN CONSEIL"},
{label: "LORATADINE CRISTERS"},
{label: "LORATADINE EG"},
{label: "LORATADINE G GAM"},
{label: "LORATADINE GNR"},
{label: "LORATADINE MYLAN"},
{label: "LORATADINE NEPENTHES"},
{label: "LORATADINE PHARMAKI GENERICS"},
{label: "LORATADINE RANBAXY"},
{label: "LORATADINE RATIOPHARM"},
{label: "LORATADINE RATIOPHARM CONSEIL"},
{label: "LORATADINE SANDOZ"},
{label: "LORATADINE SANDOZ CONSEIL"},
{label: "LORATADINE SCHERING PLOUGH"},
{label: "LORATADINE SCHERING-PLOUGH"},
{label: "LORATADINE TEVA"},
{label: "LORATADINE TEVA CONSEIL"},
{label: "LORATADINE TORRENT"},
{label: "LORATADINE VIATRIS"},
{label: "LORATADINE VIATRIS CONSEIL"},
{label: "LORATADINE ZENTIVA"},
{label: "LORATADINE ZF"},
{label: "LORATADINE ZYDUS"},
{label: "LORATADINE ZYDUS FRANCE"},
{label: "LORAX"},
{label: "LORAZEPAM APOTEX FRANCE"},
{label: "LORAZEPAM AYERST"},
{label: "LORAZEPAM BIOGARAN"},
{label: "LORAZEPAM REF"},
{label: "LORAZEPAM VIATRIS"},
{label: "LORAZEPAM XILMAC"},
{label: "LORMETAZEPAM ARROW"},
{label: "LORPITAN"},
{label: "LORVIQUA"},
{label: "LOSADRON"},
{label: "LOSARTAN ACCORD"},
{label: "LOSARTAN ACTAVIS"},
{label: "LOSARTAN ALMUS"},
{label: "LOSARTAN ALTER"},
{label: "LOSARTAN ARROW"},
{label: "LOSARTAN ARROW LAB"},
{label: "LOSARTAN BGR"},
{label: "LOSARTAN BIOGALENIQUE"},
{label: "LOSARTAN BIOGARAN"},
{label: "LOSARTAN CRISTERS"},
{label: "LOSARTAN CRISTERS PHARMA"},
{label: "LOSARTAN EG"},
{label: "LOSARTAN EG LABO"},
{label: "LOSARTAN ENIREX"},
{label: "LOSARTAN EVOLUGEN"},
{label: "LOSARTAN GENERES"},
{label: "LOSARTAN HYDROCHLOROTHIAZIDE ALTER"},
{label: "LOSARTAN HYDROCHLOROTHIAZIDE RANBAXY"},
{label: "LOSARTAN IBD3 PHARMA CONSULTING"},
{label: "LOSARTAN INTAS"},
{label: "LOSARTAN ISOMED"},
{label: "LOSARTAN KRKA"},
{label: "LOSARTAN LICONSA"},
{label: "LOSARTAN MSD"},
{label: "LOSARTAN MYLAN"},
{label: "LOSARTAN MYLAN PHARMA"},
{label: "LOSARTAN PFIZER"},
{label: "LOSARTAN PHR LAB"},
{label: "LOSARTAN QUALIMED"},
{label: "LOSARTAN QUALIMED GENERIQUES"},
{label: "LOSARTAN RANBAXY"},
{label: "LOSARTAN RATIOPHARM"},
{label: "LOSARTAN REF"},
{label: "LOSARTAN RPG"},
{label: "LOSARTAN SANDOZ"},
{label: "LOSARTAN SPECIFAR"},
{label: "LOSARTAN TEVA"},
{label: "LOSARTAN TORRENT"},
{label: "LOSARTAN VENIPHARM"},
{label: "LOSARTAN VIATRIS"},
{label: "LOSARTAN WINTHROP"},
{label: "LOSARTAN WOCKHARDT"},
{label: "LOSARTAN ZENTIVA"},
{label: "LOSARTAN ZENTIVA LAB"},
{label: "LOSARTAN ZYDUS"},
{label: "LOSARTAN/HYDROCHLOROTHIAZIDE ACTAVIS"},
{label: "LOSARTAN/HYDROCHLOROTHIAZIDE ARROW"},
{label: "LOSARTAN/HYDROCHLOROTHIAZIDE ARROW LAB"},
{label: "LOSARTAN/HYDROCHLOROTHIAZIDE BGR"},
{label: "LOSARTAN/HYDROCHLOROTHIAZIDE BIOGARAN"},
{label: "LOSARTAN/HYDROCHLOROTHIAZIDE BLUEFISH"},
{label: "LOSARTAN/HYDROCHLOROTHIAZIDE BOUCHARA-RECORDATI"},
{label: "LOSARTAN/HYDROCHLOROTHIAZIDE CRISTERS"},
{label: "LOSARTAN/HYDROCHLOROTHIAZIDE CRISTERS PHARMA"},
{label: "LOSARTAN/HYDROCHLOROTHIAZIDE EG"},
{label: "LOSARTAN/HYDROCHLOROTHIAZIDE EG 100/12"},
{label: "LOSARTAN/HYDROCHLOROTHIAZIDE EG LABO"},
{label: "LOSARTAN/HYDROCHLOROTHIAZIDE EVOLUGEN"},
{label: "LOSARTAN/HYDROCHLOROTHIAZIDE IBD3 PHARMA CONSULTING"},
{label: "LOSARTAN/HYDROCHLOROTHIAZIDE ISOMED"},
{label: "LOSARTAN/HYDROCHLOROTHIAZIDE KRKA"},
{label: "LOSARTAN/HYDROCHLOROTHIAZIDE LICONSA"},
{label: "LOSARTAN/HYDROCHLOROTHIAZIDE MYLAN PHARMA"},
{label: "LOSARTAN/HYDROCHLOROTHIAZIDE MYPLIX"},
{label: "LOSARTAN/HYDROCHLOROTHIAZIDE OPENING PHARMA"},
{label: "LOSARTAN/HYDROCHLOROTHIAZIDE PFIZER"},
{label: "LOSARTAN/HYDROCHLOROTHIAZIDE PHR LAB"},
{label: "LOSARTAN/HYDROCHLOROTHIAZIDE QUALIMED"},
{label: "LOSARTAN/HYDROCHLOROTHIAZIDE QUALIMED GENERIQUES"},
{label: "LOSARTAN/HYDROCHLOROTHIAZIDE RATIO"},
{label: "LOSARTAN/HYDROCHLOROTHIAZIDE RATIOPHARM"},
{label: "LOSARTAN/HYDROCHLOROTHIAZIDE RPG"},
{label: "LOSARTAN/HYDROCHLOROTHIAZIDE SANDOZ"},
{label: "LOSARTAN/HYDROCHLOROTHIAZIDE TEVA"},
{label: "LOSARTAN/HYDROCHLOROTHIAZIDE VENIPHARM"},
{label: "LOSARTAN/HYDROCHLOROTHIAZIDE VIATRIS"},
{label: "LOSARTAN/HYDROCHLOROTHIAZIDE VIATRIS SANTE"},
{label: "LOSARTAN/HYDROCHLOROTHIAZIDE WINTHROP"},
{label: "LOSARTAN/HYDROCHLOROTHIAZIDE ZENTIVA"},
{label: "LOSARTAN/HYDROCHLOROTHIAZIDE ZENTIVA LAB"},
{label: "LOSARTAN/HYDROCHLOROTHIAZIDE ZYDUS"},
{label: "LOSARTAN/HYDROCHLOROTHIAZIDE ZYDUS FRANCE"},
{label: "LOSASPES"},
{label: "LOSATHIA"},
{label: "LOSEC"},
{label: "LOSEGAR"},
{label: "LOTANBERSAR"},
{label: "LOTORIN"},
{label: "LOTREVE L.P."},
{label: "LOVAPHARM"},
{label: "LOVAVULO"},
{label: "LOVENOX"},
{label: "LOVTELAR"},
{label: "LOXAPAC"},
{label: "LOXELZA"},
{label: "LOXEN"},
{label: "LOXEN LP"},
{label: "LUADEI"},
{label: "LUBENTYL"},
{label: "LUBENTYL A LA MAGNESIE"},
{label: "LUCENTIS"},
{label: "LUCIDRIL"},
{label: "LUCRIN"},
{label: "LUDEAL"},
{label: "LUDIOMIL"},
{label: "LULINA"},
{label: "LUMALGON"},
{label: "LUMALGON ADULTES"},
{label: "LUMALIA"},
{label: "LUMBALGINE"},
{label: "LUMEBLUE"},
{label: "LUMIFUREX"},
{label: "LUMIGAN"},
{label: "LUMINITY"},
{label: "LUMIRELAX"},
{label: "LUMIREM"},
{label: "LUMITENS"},
{label: "LUMOBRY"},
{label: "LUMYKRAS"},
{label: "LUNSUMIO"},
{label: "LUOSTYL"},
{label: "LUPKYNIS"},
{label: "LUSDUNA"},
{label: "LUTATHERA"},
{label: "LUTEINUM BOIRON"},
{label: "LUTEINUM WELEDA"},
{label: "LUTENYL"},
{label: "LUTERAN"},
{label: "LUTIONEX"},
{label: "LUTRELEF"},
{label: "LUTUSA 1%"},
{label: "LUVERIS"},
{label: "LUXTURNA"},
{label: "LUZADEL"},
{label: "LYCAMED"},
{label: "LYCAON"},
{label: "LYCOPODIUM CLAVATUM BOIRON"},
{label: "LYCOPODIUM CLAVATUM LEHNING"},
{label: "LYCOPODIUM CLAVATUM WELEDA"},
{label: "LYCOPUS BOIRON"},
{label: "LYCOPUS WELEDA"},
{label: "LYKASTY"},
{label: "LYMECYCLINE ARROW"},
{label: "LYMECYCLINE NRIM"},
{label: "LYMECYCLINE TORRENT"},
{label: "LYMPHOGLOBULINE"},
{label: "LYMPHOSEEK"},
{label: "LYNPARZA"},
{label: "LYO BIFIDUS"},
{label: "LYODENAL"},
{label: "LYOGINSENG"},
{label: "LYOMIGRAL"},
{label: "LYOMINCIL"},
{label: "LYOPHYTUM"},
{label: "LYOSALIX"},
{label: "LYPTOCODINE"},
{label: "LYRELLE"},
{label: "LYRICA"},
{label: "LYSAKARE"},
{label: "LYSANXIA"},
{label: "LYSIMED"},
{label: "LYSO"},
{label: "LYSOCALMSPRAY"},
{label: "LYSOCLINE"},
{label: "LYSODREN"},
{label: "LYSOFON"},
{label: "LYSOPAINE"},
{label: "LYSOPAÏNE MAUX DE GORGE AMBROXOL CASSIS"},
{label: "LYSOPAÏNE MAUX DE GORGE AMBROXOL CITRON"},
{label: "LYSOPAÏNE MAUX DE GORGE AMBROXOL MENTHE"},
{label: "LYSOPAÏNE MAUX DE GORGE CETYLPYRIDINIUM LYSOZYME FRAISE SANS SUCRE"},
{label: "LYSOPAÏNE MAUX DE GORGE CETYLPYRIDINIUM LYSOZYME MIEL CITRON SANS SUCRE"},
{label: "LYSOPAÏNE MAUX DE GORGE CETYLPYRIDINIUM LYSOZYME SANS SUCRE"},
{label: "LYSORYNX"},
{label: "LYTHRUM SALICARIA BOIRON"},
{label: "LYTOS"},
{label: "LYUMJEV"},
{label: "LYXUMIA"},
{label: "LÉVOSIMENDAN TILLOMED"},
{label: "M-M-RVAXPRO"},
{label: "MAALOX BALLONNEMENTS HYDROXYDE D'ALUMINIUM/HYDROXYDE DE MAGNESIUM/DIMETICONE"},
{label: "MAALOX DIGESTION DIFFICILE BOLDINE/SULFATE DE SODIUM ANHYDRE/PHOSPHATE MONOSODIQUE ANHYDRE"},
{label: "MAALOX MAUX D'ESTOMAC HYDROXYDE D'ALUMINIUM/HYDROXYDE DE MAGNESIUM"},
{label: "MAALOX REFLUX ALGINATE DE SODIUM/BICARBONATE DE SODIUM MENTHE"},
{label: "MAB"},
{label: "MAB BALLONNEMENTS"},
{label: "MAB MAUX D'ESTOMAC"},
{label: "MABCAMPATH"},
{label: "MABELIO"},
{label: "MABTHERA"},
{label: "MACI"},
{label: "MACROGOL"},
{label: "MACROGOL ASTIER"},
{label: "MACROGOL BIOGARAN"},
{label: "MACTADANYL"},
{label: "MACTUPI"},
{label: "MACUGEN"},
{label: "MADECASSOL"},
{label: "MADECASSOL NEOMYCINE HYDROCORTISONE"},
{label: "MADECASSOL TULGRAS"},
{label: "MADIZO"},
{label: "MAG"},
{label: "MAGINJECTABLE"},
{label: "MAGLID"},
{label: "MAGN EFT"},
{label: "MAGNE B6"},
{label: "MAGNEBI"},
{label: "MAGNEGITA"},
{label: "MAGNESIA BOROCITRICA BOIRON"},
{label: "MAGNESIA CARBONICA BOIRON"},
{label: "MAGNESIA CARBONICA LEHNING"},
{label: "MAGNESIA CARBONICA WELEDA"},
{label: "MAGNESIA MURIATICA BOIRON"},
{label: "MAGNESIA MURIATICA LEHNING"},
{label: "MAGNESIA MURIATICA WELEDA"},
{label: "MAGNESIA PHOSPHORICA BOIRON"},
{label: "MAGNESIA PHOSPHORICA LEHNING"},
{label: "MAGNESIA PHOSPHORICA ROCAL"},
{label: "MAGNESIA PHOSPHORICA WELEDA"},
{label: "MAGNESIA SULFURICA BOIRON"},
{label: "MAGNESIA SULFURICA LEHNING"},
{label: "MAGNESIE COMPOSEE"},
{label: "MAGNESIE DECHOSAL"},
{label: "MAGNESIE PHILLIPS"},
{label: "MAGNESIE SAN PELLEGRINO ANISEE"},
{label: "MAGNESIE SAN PELLEGRINO SANS ANIS"},
{label: "MAGNESITE WELEDA"},
{label: "MAGNESIUM (PIDOLATE) RPG"},
{label: "MAGNESIUM (THIOSULFATE) GNR"},
{label: "MAGNESIUM ARROW"},
{label: "MAGNESIUM CHLORURE RENAUDIN"},
{label: "MAGNESIUM GLYCOCOLLE LAFARGE"},
{label: "MAGNESIUM HERMES"},
{label: "MAGNESIUM LEVURE SEROLAM"},
{label: "MAGNESIUM METALLICUM BOIRON"},
{label: "MAGNESIUM MICROSOL"},
{label: "MAGNESIUM MONAL"},
{label: "MAGNESIUM OLIGOSOL"},
{label: "MAGNESIUM SEROLAM"},
{label: "MAGNESIUM VITAMINE B6 MYLAN PHARMA"},
{label: "MAGNESIUM/VITAMINE B6 BIOGARAN"},
{label: "MAGNESIUM/VITAMINE B6 BIOGARAN CONSEIL"},
{label: "MAGNESIUM/VITAMINE B6 QUALIMED"},
{label: "MAGNESIUM/VITAMINE B6 SANDOZ CONSEIL"},
{label: "MAGNESIUM/VITAMINE B6 VIATRIS CONSEIL"},
{label: "MAGNESIUM/VITAMINE B6 WINTHROP"},
{label: "MAGNESPASMYL"},
{label: "MAGNEVIE B6"},
{label: "MAGNEVIST"},
{label: "MAGNEVIST ENTERAL"},
{label: "MAGNOGENE"},
{label: "MAGNOLIA GRANDIFLORA BOIRON"},
{label: "MAGNOVIT"},
{label: "MAGYEAST"},
{label: "MAINTELYTE"},
{label: "MAIVAL"},
{label: "MAJEPTIL"},
{label: "MALARONE"},
{label: "MALATHION FERLUX"},
{label: "MALCESSE"},
{label: "MALGIS"},
{label: "MALIDONE"},
{label: "MALOCIDE"},
{label: "MALTOFER"},
{label: "MALUS COMMUNIS BOIRON"},
{label: "MALVA MOSCHATA BOIRON"},
{label: "MAMELLINE BOIRON"},
{label: "MANDOCARBINE"},
{label: "MANDRAGORA OFFICINARUM"},
{label: "MANDRAGORA OFFICINARUM BOIRON"},
{label: "MANGANESE COBALT MICROSOL"},
{label: "MANGANESE CUIVRE MICROSOL"},
{label: "MANGANESE OLIGOSOL"},
{label: "MANGANESE-COBALT OLIGOSOL"},
{label: "MANGANESE-CUIVRE OLIGOSOL"},
{label: "MANGANESE-CUIVRE-COBALT OLIGOSOL"},
{label: "MANGANUM ACETICUM BOIRON"},
{label: "MANGANUM ACETICUM LEHNING"},
{label: "MANGANUM METALLICUM BOIRON"},
{label: "MANGANUM METALLICUM LEHNING"},
{label: "MANGAPLEXE LEHNING"},
{label: "MANICOL"},
{label: "MANIDIPINE ARROW"},
{label: "MANIDIPINE BIOGARAN"},
{label: "MANIDIPINE EG"},
{label: "MANIDIPINE ERREKAPPA EUROTERAPICI"},
{label: "MANIDIPINE FIDIA"},
{label: "MANIDIPINE QUALIMED"},
{label: "MANIDIPINE RATIOPHARM"},
{label: "MANIDIPINE SANDOZ"},
{label: "MANIDIPINE TEVA"},
{label: "MANIDIPINE VIATRIS"},
{label: "MANIDIPINE ZENTIVA"},
{label: "MANIDIPINE ZYDUS"},
{label: "MANNITOL"},
{label: "MANNITOL A"},
{label: "MANNITOL AGUETTANT"},
{label: "MANNITOL B. BRAUN"},
{label: "MANNITOL BAXTER"},
{label: "MANNITOL BIOSEDRA"},
{label: "MANNITOL COOPER"},
{label: "MANNITOL LAVOISIER"},
{label: "MANTADIX"},
{label: "MAPAKNA LP"},
{label: "MARBRE SACCHAROIDE BOIRON"},
{label: "MARCAINE"},
{label: "MARCAINE RACHIANESTHESIE"},
{label: "MARESSI"},
{label: "MARGA"},
{label: "MARIPER"},
{label: "MARIXINO"},
{label: "MARRON D'INDE BOIRON"},
{label: "MARRON D'INDE ETHYPHARM"},
{label: "MARRONDIN"},
{label: "MARRUBIUM VULGARE BOIRON"},
{label: "MARSILID"},
{label: "MARTIGENE"},
{label: "MARZINE"},
{label: "MATEVER"},
{label: "MATIGA"},
{label: "MATRIFEN"},
{label: "MAVENCLAD"},
{label: "MAVIRET"},
{label: "MAXAIR"},
{label: "MAXAIR AUTOHALER"},
{label: "MAXALT"},
{label: "MAXALTLYO"},
{label: "MAXEPA"},
{label: "MAXI TYRO"},
{label: "MAXICAINE"},
{label: "MAXIDEX"},
{label: "MAXIDROL"},
{label: "MAXILASE BACITRACINE"},
{label: "MAXILASE MAUX DE GORGE ALPHA-AMYLASE"},
{label: "MAXOMAT"},
{label: "MAYAB LP"},
{label: "MAYZENT"},
{label: "MAZALVIX"},
{label: "MAZART"},
{label: "MEBEVERINE BIOGARAN"},
{label: "MEBEVERINE BIPHAR"},
{label: "MEBEVERINE EG"},
{label: "MEBEVERINE MYLAN"},
{label: "MEBEVERINE QUALIMED"},
{label: "MEBEVERINE SANDOZ"},
{label: "MEBEVERINE SOLVAY PHARMA"},
{label: "MEBEVERINE TEVA"},
{label: "MEBEVERINE ZENTIVA"},
{label: "MEBEVERINE ZYDUS"},
{label: "MECIR L.P."},
{label: "MECORT"},
{label: "MECTIZAN"},
{label: "MEDIALIPIDE"},
{label: "MEDIALIPOVEN"},
{label: "MEDIATENSYL"},
{label: "MEDIATENSYL LP"},
{label: "MEDIATOR"},
{label: "MEDIBRONC ADULTES"},
{label: "MEDIBRONC ENFANTS"},
{label: "MEDIFINE"},
{label: "MEDIFLOR N°11 DRAINEUR RENAL ET DIGESTIF"},
{label: "MEDIFLOR N°14 CALMANTE - TROUBLE DU SOMMEIL"},
{label: "MEDIFLOR N°7 CONTRE LA CONSTIPATION PASSAGERE"},
{label: "MEDIKAPS"},
{label: "MEDIKINET"},
{label: "MEDIMEL MEDICINAL ANTI-ASTHENIQUE"},
{label: "MEDIMEL MEDICINAL VALERIANE MELISSE"},
{label: "MEDIPEL"},
{label: "MEDITAMIDE"},
{label: "MEDIVEINE"},
{label: "MEDIZOLTAN"},
{label: "MEDNUTRIFLEX E"},
{label: "MEDNUTRIFLEX LIPIDE G 120/N"},
{label: "MEDNUTRIFLEX OMEGA E"},
{label: "MEDNUTRIFLEX OMEGA G 120/N"},
{label: "MEDOCOCCINE"},
{label: "MEDROCIS"},
{label: "MEDROL"},
{label: "MEDULLINE BOIRON"},
{label: "MEDULLINE WELEDA"},
{label: "MEDULLO-SURRENALE BOIRON"},
{label: "MEDULOSS BOIRON"},
{label: "MEDULOSS WELEDA"},
{label: "MEFALGOL"},
{label: "MEFOXIN"},
{label: "MEGABYL"},
{label: "MEGACE"},
{label: "MEGAMAG"},
{label: "MEGAMAGNESIA"},
{label: "MEGAMYLASE"},
{label: "MEGAVIX"},
{label: "MEGIMIDE"},
{label: "MEHTYLINE"},
{label: "MEKINIST"},
{label: "MEKOLL"},
{label: "MEKTOVI"},
{label: "MELADININE"},
{label: "MELAGATRAN ASTRAZENECA"},
{label: "MELATONINE ARROW LP"},
{label: "MELATONINE NOXAREM"},
{label: "MELAXIB"},
{label: "MELAXOSE"},
{label: "MELDASPIN"},
{label: "MELIANE"},
{label: "MELILOT NATURA MEDICA"},
{label: "MELILOTUS OFFICINALIS BOIRON"},
{label: "MELILOTUS OFFICINALIS LEHNING"},
{label: "MELISSA OFFICINALIS BOIRON"},
{label: "MELISSA OFFICINALIS WELEDA"},
{label: "MELISSE ETHYPHARM"},
{label: "MELISSE INFUSETTE LES BONS PRODUCTEURS"},
{label: "MELISSE NATURA MEDICA"},
{label: "MELITRO"},
{label: "MELIVAL"},
{label: "MELLERIL"},
{label: "MELODIA"},
{label: "MELOXICAM BIOGARAN"},
{label: "MELOXICAM EG"},
{label: "MELOXICAM NOR"},
{label: "MELOXICAM PFIZER"},
{label: "MELOXICAM SANDOZ"},
{label: "MELOXICAM TEVA"},
{label: "MELOXICAM ZENTIVA"},
{label: "MELPHALAN ACCORD"},
{label: "MELPHALAN AMRING FARMA"},
{label: "MELPHALAN MEDAC"},
{label: "MELPHALAN SUN"},
{label: "MELPHALAN TILLOMED"},
{label: "MELYTAVIS"},
{label: "MEMANTINE ACCORD"},
{label: "MEMANTINE ALMUS"},
{label: "MEMANTINE ALTER"},
{label: "MEMANTINE ARROW"},
{label: "MEMANTINE ARROW LAB"},
{label: "MEMANTINE BIOGARAN"},
{label: "MEMANTINE CRISTERS"},
{label: "MEMANTINE EG"},
{label: "MEMANTINE EVOLUGEN"},
{label: "MEMANTINE GLENMARK"},
{label: "MEMANTINE GOIBELA"},
{label: "MEMANTINE GRINDEKS"},
{label: "MEMANTINE HCS"},
{label: "MEMANTINE LEK"},
{label: "MEMANTINE MYLAN"},
{label: "MEMANTINE NIALEX"},
{label: "MEMANTINE PHARMAKI GENERICS"},
{label: "MEMANTINE PHARMASCOPE"},
{label: "MEMANTINE RANBAXY"},
{label: "MEMANTINE SANDOZ"},
{label: "MEMANTINE SFIP"},
{label: "MEMANTINE STRAGEN"},
{label: "MEMANTINE SYNTHON"},
{label: "MEMANTINE TEVA"},
{label: "MEMANTINE TEVA SANTE"},
{label: "MEMANTINE VENIPHARM"},
{label: "MEMANTINE ZENTIVA"},
{label: "MEMANTINE ZYDUS FRANCE"},
{label: "MEMOREEL"},
{label: "MEMPRACID"},
{label: "MENAELLE"},
{label: "MENCEVAX"},
{label: "MENELRI"},
{label: "MENINGITEC"},
{label: "MENINVACT"},
{label: "MENINVACTKIT"},
{label: "MENJUGATE"},
{label: "MENJUGATEKIT10"},
{label: "MENOLIANE"},
{label: "MENOMUNE"},
{label: "MENOPUR"},
{label: "MENOREST"},
{label: "MENQUADFI"},
{label: "MENSIFEM"},
{label: "MENSUOSEDYL"},
{label: "MENTHA ARVENSIS BOIRON"},
{label: "MENTHA PIPERITA BOIRON"},
{label: "MENTHA VIRIDIS BOIRON"},
{label: "MENTHOL EUCALYPTOL THYMOL GAIACOL TERPINOL GLAXOSMITHKLINE SANTE GRAND PUBLIC"},
{label: "MENTHOL EUCALYPTOL THYMOL GAIACOL TERPINOL GLAXOSMITHKLINE SANTE GRAND PUBLIC SANS SUCRE"},
{label: "MENTHOL EUCALYPTUS MENTHE POIVREE GLAXOSMITHKLINE SANTE GRAND PUBLIC"},
{label: "MENVEO"},
{label: "MENYANTHES TRIFOLIATA BOIRON"},
{label: "MENYANTHES TRIFOLIATA LEHNING"},
{label: "MEOROXX"},
{label: "MEPACT"},
{label: "MEPACYL"},
{label: "MEPHITIS PUTORIUS BOIRON"},
{label: "MEPIVACAINE ACCORD"},
{label: "MEPIVACAINE B. BRAUN"},
{label: "MEPIVACAINE NORMOGEN"},
{label: "MEPROBAMATE MERCK"},
{label: "MEPROBAMATE RICHARD"},
{label: "MEPRONIZINE"},
{label: "MEPSEVII"},
{label: "MEQUITAZINE RPG"},
{label: "MERALOPS"},
{label: "MERCALM"},
{label: "MERCILON"},
{label: "MERCRYL"},
{label: "MERCRYL SOLUTION MOUSSANTE"},
{label: "MERCRYLSPRAY"},
{label: "MERCUR SOL"},
{label: "MERCURIALIS PERENNIS BOIRON"},
{label: "MERCURIUS BI-IODATUS BOIRON"},
{label: "MERCURIUS BI-IODATUS LEHNING"},
{label: "MERCURIUS BI-IODATUS WELEDA"},
{label: "MERCURIUS CORROSIVUS BOIRON"},
{label: "MERCURIUS CORROSIVUS LEHNING"},
{label: "MERCURIUS CYANATUS BOIRON"},
{label: "MERCURIUS CYANATUS WELEDA"},
{label: "MERCURIUS DULCIS BOIRON"},
{label: "MERCURIUS DULCIS LEHNING"},
{label: "MERCURIUS DULCIS WELEDA"},
{label: "MERCURIUS PROTO-IODATUS BOIRON"},
{label: "MERCURIUS PROTO-IODATUS LEHNING"},
{label: "MERCURIUS SOLUBILIS BOIRON"},
{label: "MERCURIUS SOLUBILIS LEHNING"},
{label: "MERCURIUS SOLUBILIS WELEDA"},
{label: "MERCURIUS VIVUS BOIRON"},
{label: "MERCURIUS VIVUS LEHNING"},
{label: "MERCURIUS VIVUS WELEDA"},
{label: "MERCUROBUTOL LAURYLSULFATE DE SODIUM MENARINI FRANCE"},
{label: "MEREPRINE"},
{label: "MERFENE"},
{label: "MERIGEST"},
{label: "MERIMONO"},
{label: "MERONEM"},
{label: "MEROPENEM ACCORD"},
{label: "MEROPENEM ACS DOBFAR"},
{label: "MEROPENEM ARROW"},
{label: "MEROPENEM ARROW LAB"},
{label: "MEROPENEM BRADEX"},
{label: "MEROPENEM GERDA"},
{label: "MEROPENEM HIKMA"},
{label: "MEROPENEM KABI"},
{label: "MEROPENEM KALCEKS"},
{label: "MEROPENEM MEDIPHA SANTE"},
{label: "MEROPENEM MYLAN"},
{label: "MEROPENEM PANPHARMA"},
{label: "MEROPENEM QILU"},
{label: "MEROPENEM RPG"},
{label: "MEROPENEM SANDOZ"},
{label: "MEROPENEM SERVICE-PHARMA"},
{label: "MEROPENEM STERISCIENCE"},
{label: "MEROPENEM SUBSTIPHARM"},
{label: "MEROPENEM VENUS PHARMA"},
{label: "MERSEPTYL"},
{label: "MESALAZINE DELBERT L.P."},
{label: "MESALAZINE MAYOLY"},
{label: "MESNA EG"},
{label: "MESNA PANPHARMA"},
{label: "MESOCAÏNE"},
{label: "MESTACINE"},
{label: "MESTINON"},
{label: "MESTINON LP"},
{label: "METACUPROL"},
{label: "METAFLU 2.0"},
{label: "METALDEHYDUM BOIRON"},
{label: "METALYSE"},
{label: "METASPIRINE"},
{label: "METASPIRINE VITAMINE C"},
{label: "METASTRON"},
{label: "METATRACE FDG"},
{label: "METEOSPASMYL"},
{label: "METEOXANE"},
{label: "METFOGAMMA"},
{label: "METFORMINE ACCORD"},
{label: "METFORMINE ACTAVIS"},
{label: "METFORMINE ACTAVIS FRANCE"},
{label: "METFORMINE ALMUS"},
{label: "METFORMINE ALTER"},
{label: "METFORMINE ARROW"},
{label: "METFORMINE ARROW GENERIQUES"},
{label: "METFORMINE ARROW LAB"},
{label: "METFORMINE BGR"},
{label: "METFORMINE BIOCHEMIE"},
{label: "METFORMINE BIOGALENIQUE"},
{label: "METFORMINE BIOGARAN"},
{label: "METFORMINE BLUEFISH"},
{label: "METFORMINE CERA"},
{label: "METFORMINE CLL PHARMA"},
{label: "METFORMINE CRISTERS"},
{label: "METFORMINE EG"},
{label: "METFORMINE EG LABO"},
{label: "METFORMINE EMGE"},
{label: "METFORMINE EVOLUGEN"},
{label: "METFORMINE G GAM"},
{label: "METFORMINE GERMANIA"},
{label: "METFORMINE GLIBENCLAMIDE MERCK SANTE"},
{label: "METFORMINE GNR"},
{label: "METFORMINE GONNON"},
{label: "METFORMINE HEXAL"},
{label: "METFORMINE ISOMED"},
{label: "METFORMINE LAURUS"},
{label: "METFORMINE LIPHA"},
{label: "METFORMINE MERCK SANTE"},
{label: "METFORMINE MSD"},
{label: "METFORMINE MYLAN"},
{label: "METFORMINE NOR"},
{label: "METFORMINE PFIZER"},
{label: "METFORMINE PHARMCONSUL"},
{label: "METFORMINE PHR LAB"},
{label: "METFORMINE PIERRE FABRE"},
{label: "METFORMINE QUALIHEALTH"},
{label: "METFORMINE QUALIMED"},
{label: "METFORMINE QUALIMED GENERIQUES"},
{label: "METFORMINE RANBAXY"},
{label: "METFORMINE RATIOPHARM"},
{label: "METFORMINE ROSEMONT"},
{label: "METFORMINE RPG"},
{label: "METFORMINE SANDOZ"},
{label: "METFORMINE SERVIPHARM"},
{label: "METFORMINE SET"},
{label: "METFORMINE SUN"},
{label: "METFORMINE TEVA"},
{label: "METFORMINE TORRENT"},
{label: "METFORMINE VENIPHARM"},
{label: "METFORMINE VIATRIS"},
{label: "METFORMINE WINTHROP"},
{label: "METFORMINE ZENTIVA"},
{label: "METFORMINE ZENTIVA K.S."},
{label: "METFORMINE ZYDUS"},
{label: "METFORMINE ZYDUS FRANCE"},
{label: "METFORMINE/GLIBENCLAMIDE SANDOZ"},
{label: "METHADONE AP-HP"},
{label: "METHERGIN"},
{label: "METHEXAL"},
{label: "METHIOCIL"},
{label: "METHOCARBAMOL ALPHARMA"},
{label: "METHOCARBAMOL ARROW"},
{label: "METHOTREXATE ACCORD"},
{label: "METHOTREXATE ASTA MEDICA"},
{label: "METHOTREXATE BELLON"},
{label: "METHOTREXATE BIODIM"},
{label: "METHOTREXATE ORION"},
{label: "METHOTREXATE PHARMAKI GENERICS"},
{label: "METHOTREXATE QUALIMED"},
{label: "METHOTREXATE SANDOZ"},
{label: "METHOTREXATE TEVA"},
{label: "METHOTREXATE UNION INTERPHARMACEUTIQUE ATLANTIQUE"},
{label: "METHOTREXATE VIATRIS"},
{label: "METHOXSALENE G.L. PHARMA"},
{label: "METHOXSALENE MACOPHARMA"},
{label: "METHOXYFLURANE MEDICAL DEVELOPMENTS NED"},
{label: "METHYL-GAG"},
{label: "METHYLDOPA DAKOTA PHARM"},
{label: "METHYLDOPA MSD"},
{label: "METHYLDOPA RPG"},
{label: "METHYLPHENIDATE ARROW LP"},
{label: "METHYLPHENIDATE BIOGARAN LP"},
{label: "METHYLPHENIDATE EG LP"},
{label: "METHYLPHENIDATE RUBIO"},
{label: "METHYLPHENIDATE SANDOZ LP"},
{label: "METHYLPHENIDATE TEVA LP"},
{label: "METHYLPHENIDATE VIATRIS LP"},
{label: "METHYLPREDNISOLONE DAKOTA PHARM"},
{label: "METHYLPREDNISOLONE FAULDING"},
{label: "METHYLPREDNISOLONE HIKMA"},
{label: "METHYLPREDNISOLONE QUALIMED"},
{label: "METHYLPREDNISOLONE VIATRIS"},
{label: "METITANE"},
{label: "METOCALCIUM"},
{label: "METOCLOPRAMIDE GNR"},
{label: "METOCLOPRAMIDE NORIDEM"},
{label: "METOCLOPRAMIDE PHARMANOVA"},
{label: "METOCLOPRAMIDE SANDOZ"},
{label: "METOCLOPRAMIDE VIATRIS"},
{label: "METOJECT"},
{label: "METOPIMAZINE ARROW"},
{label: "METOPIMAZINE ARROW CONSEIL"},
{label: "METOPIMAZINE BIOGARAN"},
{label: "METOPIMAZINE BIOGARAN CONSEIL"},
{label: "METOPIMAZINE NEITUM"},
{label: "METOPIMAZINE VENIPHARM"},
{label: "METOPIRONE"},
{label: "METOPROLOL ACCORD"},
{label: "METOPROLOL ARROW"},
{label: "METOPROLOL ARROW LAB"},
{label: "METOPROLOL SANDOZ"},
{label: "METOPROLOL VIATRIS"},
{label: "METOTAB"},
{label: "METROCOL"},
{label: "METRODINE HP"},
{label: "METROGENE"},
{label: "METRONIDAZOLE ARROW"},
{label: "METRONIDAZOLE B BRAUN"},
{label: "METRONIDAZOLE B.BRAUN A"},
{label: "METRONIDAZOLE BAILLY-CREAT"},
{label: "METRONIDAZOLE BAXTER"},
{label: "METRONIDAZOLE BIOGARAN"},
{label: "METRONIDAZOLE BIOGLAN"},
{label: "METRONIDAZOLE BIOSEDRA"},
{label: "METRONIDAZOLE DAKOTA PHARM"},
{label: "METRONIDAZOLE LAVOISIER"},
{label: "METRONIDAZOLE MACO PHARMA"},
{label: "METRONIDAZOLE MEDA PHARMA"},
{label: "METRONIDAZOLE MERAM"},
{label: "METRONIDAZOLE MYLAN"},
{label: "METRONIDAZOLE NORIDEM"},
{label: "METRONIDAZOLE SG-PHARM"},
{label: "METRONIDAZOLE SUBSTIPHARM"},
{label: "METRONIDAZOLE UNILABO"},
{label: "METVIXIA"},
{label: "METYRAPONE HRA PHARMA"},
{label: "MEXILETINE AP-HP"},
{label: "MEXITIL"},
{label: "MEZAVANT LP"},
{label: "MEZEREUM BOIRON"},
{label: "MEZEREUM LEHNING"},
{label: "MEZEREUM WELEDA"},
{label: "MIACALCIC"},
{label: "MIANSERINE ARROW"},
{label: "MIANSERINE BIOGARAN"},
{label: "MIANSERINE CRISTERS"},
{label: "MIANSERINE DISTRIRETI"},
{label: "MIANSERINE EG"},
{label: "MIANSERINE IVAX"},
{label: "MIANSERINE NOR"},
{label: "MIANSERINE QUALIMED"},
{label: "MIANSERINE RATIOPHARM"},
{label: "MIANSERINE RPG"},
{label: "MIANSERINE SANDOZ"},
{label: "MIANSERINE TEVA"},
{label: "MIANSERINE VIATRIS"},
{label: "MIANSERINE ZENTIVA"},
{label: "MIBG [123 I]"},
{label: "MIBITEC"},
{label: "MICAFUNGINE HIKMA"},
{label: "MICAFUNGINE LORIEN"},
{label: "MICAFUNGINE REIG JOFRE"},
{label: "MICAFUNGINE STRAGEN"},
{label: "MICAFUNGINE TEVA"},
{label: "MICAFUNGINE VIATRIS"},
{label: "MICAFUNGINE XELLIA"},
{label: "MICARDIS"},
{label: "MICARDISPLUS"},
{label: "MICLAST"},
{label: "MICONARIUM"},
{label: "MICONAZOLE CILAG"},
{label: "MICONAZOLE NITRATE GNR"},
{label: "MICROCIS"},
{label: "MICRODOINE"},
{label: "MICROKALEORID L P"},
{label: "MICROLAX BEBE SORBITOL CITRATE ET LAURILSULFOACETATE DE SODIUM"},
{label: "MICROLAX MACROGOL"},
{label: "MICROLAX SORBITOL CITRATE ET LAURILSULFOACETATE DE SODIUM"},
{label: "MICROPAKINE L.P."},
{label: "MICROPAQUE"},
{label: "MICROPAQUE COLON"},
{label: "MICROPAQUE H.D. ORAL"},
{label: "MICROPAQUE SCANNER"},
{label: "MICROPAQUE SCANNER OESOPHAGE"},
{label: "MICROPHTA"},
{label: "MICRORECTAL A LA GLYCERINE"},
{label: "MICROSCLEROL"},
{label: "MICROTRAST"},
{label: "MICROVAL"},
{label: "MICTASOL"},
{label: "MIDAZOLAM ACCORD"},
{label: "MIDAZOLAM AGUETTANT"},
{label: "MIDAZOLAM ARROW"},
{label: "MIDAZOLAM B. BRAUN"},
{label: "MIDAZOLAM BAXTER"},
{label: "MIDAZOLAM HAMELN"},
{label: "MIDAZOLAM INTSEL CHIMOS"},
{label: "MIDAZOLAM KALCEKS"},
{label: "MIDAZOLAM LICONSA"},
{label: "MIDAZOLAM PANPHARMA"},
{label: "MIDAZOLAM RENAUDIN"},
{label: "MIDAZOLAM SANDOZ"},
{label: "MIDAZOLAM SUN"},
{label: "MIDAZOLAM VIATRIS"},
{label: "MIDAZOLAM WINTHROP"},
{label: "MIDODRINE ARROW"},
{label: "MIDODRINE BIOGARAN"},
{label: "MIDODRINE GERDA"},
{label: "MIDODRINE SUBSTIPHARM"},
{label: "MIDODRINE TEVA"},
{label: "MIDODRINE TILLOMED"},
{label: "MIDODRINE VIATRIS"},
{label: "MIDODRINE ZENTIVA"},
{label: "MIDY CALCIUM VITAMINE C"},
{label: "MIDY VITAMINE C"},
{label: "MIFEGYNE"},
{label: "MIFEPRISTONE EXELGYN"},
{label: "MIFFEE"},
{label: "MIFLASONE"},
{label: "MIFLONIL"},
{label: "MIFLONIL BREEZHALER"},
{label: "MIGLUCAN"},
{label: "MIGLUSTAT ACCORD"},
{label: "MIGLUSTAT BLUEFISH"},
{label: "MIGLUSTAT DIPHARMA"},
{label: "MIGLUSTAT GEN.ORPH"},
{label: "MIGPRIV"},
{label: "MIGRALGINE"},
{label: "MIGROF"},
{label: "MIGROFLYO"},
{label: "MIGWELL"},
{label: "MIKELAN"},
{label: "MIKICORT"},
{label: "MILAVAK"},
{label: "MILAXULOSE"},
{label: "MILDAC"},
{label: "MILEVONI"},
{label: "MILLEFOLIUM BOIRON"},
{label: "MILLEFOLIUM LEHNING"},
{label: "MILLEFOLIUM WELEDA"},
{label: "MILLEPERTUIS PILEJE"},
{label: "MILLIGYNON"},
{label: "MILNACIPRAN ARROW"},
{label: "MILNACIPRAN BIOGARAN"},
{label: "MILNACIPRAN EG"},
{label: "MILNACIPRAN MYLAN"},
{label: "MILNACIPRAN NEURAXPHARM"},
{label: "MILNACIPRAN PHARMAKI GENERICS"},
{label: "MILNACIPRAN PIERRE FABRE"},
{label: "MILNACIPRAN VENIPHARM"},
{label: "MILRINONE CARINOPHARM"},
{label: "MILRINONE MEDAC"},
{label: "MILRINONE NEGMA"},
{label: "MILRINONE PANPHARMA"},
{label: "MILRINONE STRAGEN"},
{label: "MILRINONE TILLOMED"},
{label: "MILTEX"},
{label: "MIMPARA"},
{label: "MINALFENE"},
{label: "MINCIFIT"},
{label: "MINCIFIT GOUT CASSIS"},
{label: "MINCIFIT GOUT MANGUE"},
{label: "MINCIFIT GOÛT PÊCHE ABRICOT"},
{label: "MINCIFIT TISANE MINCEUR"},
{label: "MINCIFLORINE"},
{label: "MINERVA"},
{label: "MINESSE"},
{label: "MINI-SINTROM"},
{label: "MINIDIAB"},
{label: "MINIDRIL"},
{label: "MINIPHASE"},
{label: "MINIPRESS"},
{label: "MINIRIN"},
{label: "MINIRIN SPRAY"},
{label: "MINIRINMELT"},
{label: "MINIVEN"},
{label: "MINJUVI"},
{label: "MINOCYCLINE BIOGARAN"},
{label: "MINOCYCLINE CEHEL PHARMA"},
{label: "MINOCYCLINE EG"},
{label: "MINOCYCLINE IVAX"},
{label: "MINOCYCLINE MYLAN"},
{label: "MINOCYCLINE RPG"},
{label: "MINOCYCLINE SANDOZ"},
{label: "MINOCYCLINE TEVA"},
{label: "MINOCYCLINE WINTHROP"},
{label: "MINOLIS"},
{label: "MINOXIDIL BAILLEUL"},
{label: "MINOXIDIL BIOGARAN CONSEIL"},
{label: "MINOXIDIL COOPER"},
{label: "MINOXIDIL CS"},
{label: "MINOXIDIL IG PHARMA CONSULT"},
{label: "MINOXIDIL JOHNSON & JOHNSON"},
{label: "MINOXIDIL RPG"},
{label: "MINOXIDIL SANDOZ CONSEIL"},
{label: "MINOXIDIL SINCLAIR"},
{label: "MINOXIDIL TEVA CONSEIL"},
{label: "MINOXIDIL VIATRIS CONSEIL"},
{label: "MINOXIDIL ZYDUS"},
{label: "MINTEC"},
{label: "MINTEXED"},
{label: "MINTEZOL"},
{label: "MINULET"},
{label: "MIOCHOLE"},
{label: "MIOREL"},
{label: "MIOSTAT"},
{label: "MIPHABILOBA"},
{label: "MIRACORTEN"},
{label: "MIRANOVA"},
{label: "MIRAPEXIN"},
{label: "MIRCERA"},
{label: "MIRENA"},
{label: "MIRLETTE"},
{label: "MIRPRESOC"},
{label: "MIRTAZAPINE ACTAVIS"},
{label: "MIRTAZAPINE ALMUS"},
{label: "MIRTAZAPINE ALTER"},
{label: "MIRTAZAPINE ARROW"},
{label: "MIRTAZAPINE ARROW GENERIQUES"},
{label: "MIRTAZAPINE BIOGARAN"},
{label: "MIRTAZAPINE BLUEFISH"},
{label: "MIRTAZAPINE CEFT"},
{label: "MIRTAZAPINE CLL PHARMA"},
{label: "MIRTAZAPINE CRISTERS"},
{label: "MIRTAZAPINE DCI PHARMA"},
{label: "MIRTAZAPINE EG"},
{label: "MIRTAZAPINE ETHYPHARM"},
{label: "MIRTAZAPINE GNR"},
{label: "MIRTAZAPINE MYLAN"},
{label: "MIRTAZAPINE MYLAN PHARMA"},
{label: "MIRTAZAPINE PFIZER"},
{label: "MIRTAZAPINE QUALIMED"},
{label: "MIRTAZAPINE RANBAXY"},
{label: "MIRTAZAPINE RATIOPHARM"},
{label: "MIRTAZAPINE SANDOZ"},
{label: "MIRTAZAPINE TEVA"},
{label: "MIRTAZAPINE TEVA SANTE"},
{label: "MIRTAZAPINE VIATRIS"},
{label: "MIRTAZAPINE WINTHROP"},
{label: "MIRTAZAPINE ZENTIVA"},
{label: "MIRTAZAPINE ZYDUS"},
{label: "MIRTINU"},
{label: "MIRVASO"},
{label: "MISODEL"},
{label: "MISOLFA"},
{label: "MISOONE"},
{label: "MISSILOR 750.000 UI/125"},
{label: "MISSONNETTE"},
{label: "MISTALINE"},
{label: "MITHRACINE INTRAVEINEUSE"},
{label: "MITOCORTYL DEMANGEAISONS"},
{label: "MITOFONGYL"},
{label: "MITOMYCINE ACCORD"},
{label: "MITOMYCINE MEDAC"},
{label: "MITOMYCINE SUBSTIPHARM"},
{label: "MITOSYL DEMANGEAISONS"},
{label: "MITOSYL IRRITATIONS"},
{label: "MITOXANTRONE ACCORD"},
{label: "MITOXANTRONE EBEWE"},
{label: "MITOXANTRONE MAYNE"},
{label: "MITOXANTRONE MYLAN"},
{label: "MITOXANTRONE SANDOZ"},
{label: "MITOXANTRONE TEVA"},
{label: "MIVACRON"},
{label: "MIXTARD"},
{label: "MIZOCLER"},
{label: "MIZOLLEN"},
{label: "MOBIC"},
{label: "MOCLAMINE"},
{label: "MOCLOBEMIDE G GAM"},
{label: "MODAFINIL ARROW"},
{label: "MODAFINIL BIOGARAN"},
{label: "MODAFINIL CEPHALON"},
{label: "MODAFINIL EFARMES"},
{label: "MODAFINIL EG"},
{label: "MODAFINIL MYLAN"},
{label: "MODAFINIL ORCHID EUROPE"},
{label: "MODAFINIL QUALIX"},
{label: "MODAFINIL TEVA"},
{label: "MODAFINIL VIATRIS"},
{label: "MODAMIDE"},
{label: "MODANE"},
{label: "MODECATE"},
{label: "MODEIP"},
{label: "MODERATAN"},
{label: "MODIGRAF"},
{label: "MODIODAL"},
{label: "MODITEN"},
{label: "MODITEN ACTION PROLONGEE"},
{label: "MODIXIS"},
{label: "MODOPAR"},
{label: "MODOPAR L.P."},
{label: "MODUCREN"},
{label: "MODULITE"},
{label: "MODULON"},
{label: "MODULON ENFANT ET NOURRISSON"},
{label: "MODURETIC"},
{label: "MODUSTATINE"},
{label: "MOEX"},
{label: "MOGADON"},
{label: "MOLAGAR"},
{label: "MOLSIDOMINE ACTAVIS"},
{label: "MOLSIDOMINE ALTER"},
{label: "MOLSIDOMINE ARROW"},
{label: "MOLSIDOMINE BIOGARAN"},
{label: "MOLSIDOMINE CARDEL"},
{label: "MOLSIDOMINE CRISTERS"},
{label: "MOLSIDOMINE EG"},
{label: "MOLSIDOMINE G GAM"},
{label: "MOLSIDOMINE IVAX"},
{label: "MOLSIDOMINE MEDIOLANUM"},
{label: "MOLSIDOMINE MYLAN"},
{label: "MOLSIDOMINE NOR"},
{label: "MOLSIDOMINE QUALIMED"},
{label: "MOLSIDOMINE RATIOPHARM"},
{label: "MOLSIDOMINE RPG"},
{label: "MOLSIDOMINE SANDOZ"},
{label: "MOLSIDOMINE TEVA"},
{label: "MOLSIDOMINE ZENTIVA"},
{label: "MOLSIDOMINE ZYDUS"},
{label: "MOLYBDENE INJECTABLE A"},
{label: "MOMETASONE ALTER"},
{label: "MOMETASONE ARROW"},
{label: "MOMETASONE BIOGARAN"},
{label: "MOMETASONE CRISTERS"},
{label: "MOMETASONE EG"},
{label: "MOMETASONE GLENMARK GENERICS"},
{label: "MOMETASONE LA COLINA"},
{label: "MOMETASONE SANDOZ"},
{label: "MOMETASONE SCHERING PLOUGH"},
{label: "MOMETASONE SUBSTIPHARM"},
{label: "MOMETASONE TEVA"},
{label: "MOMETASONE VIATRIS"},
{label: "MOMETASONE ZENTIVA"},
{label: "MOMINTOM"},
{label: "MOMORDICA BALSAMINA BOIRON"},
{label: "MONAZITE BOIRON"},
{label: "MONAZOL"},
{label: "MONBERLUDELKA"},
{label: "MONEVA"},
{label: "MONICOR"},
{label: "MONICOR L P"},
{label: "MONICOR L.P."},
{label: "MONILIA ALBICANS BOIRON"},
{label: "MONILIA ALBICANS LEHNING"},
{label: "MONO TILDIEM LP"},
{label: "MONOALGIC L.P."},
{label: "MONOCID"},
{label: "MONOCLATE"},
{label: "MONOCLATE P"},
{label: "MONOCLINE"},
{label: "MONOCRIXO L.P."},
{label: "MONOFLOCET"},
{label: "MONOKETO"},
{label: "MONONINE"},
{label: "MONONITRATE D'ISOSORBIDE RPG LP"},
{label: "MONONITRATE D'ISOSORBIDE TEVA"},
{label: "MONOOX"},
{label: "MONOPROST"},
{label: "MONOSEPT"},
{label: "MONOSORB"},
{label: "MONOTARD"},
{label: "MONOTRAMAL L.P."},
{label: "MONOVAX"},
{label: "MONOVER"},
{label: "MONOXANE"},
{label: "MONOXYDE D'AZOTE MESSER"},
{label: "MONOXYDE DAZOTE SOL FRANCE"},
{label: "MONOZECLAR"},
{label: "MONTANA"},
{label: "MONTASE"},
{label: "MONTEFRANC"},
{label: "MONTEK 10-40"},
{label: "MONTELUKAST ACCORD"},
{label: "MONTELUKAST ACCORD HEALTHCARE"},
{label: "MONTELUKAST ACTAVIS"},
{label: "MONTELUKAST ALMUS"},
{label: "MONTELUKAST ALTER"},
{label: "MONTELUKAST ARROW"},
{label: "MONTELUKAST ARROW GENERIQUES"},
{label: "MONTELUKAST ARROW LAB"},
{label: "MONTELUKAST AUTHOU"},
{label: "MONTELUKAST BGR"},
{label: "MONTELUKAST BIOGARAN"},
{label: "MONTELUKAST CLEXNI"},
{label: "MONTELUKAST CRISTERS"},
{label: "MONTELUKAST CRISTERS PHARMA"},
{label: "MONTELUKAST DELBERT"},
{label: "MONTELUKAST EG"},
{label: "MONTELUKAST EVOLUGEN"},
{label: "MONTELUKAST FAIR-MED"},
{label: "MONTELUKAST GEDEON RICHTER"},
{label: "MONTELUKAST KRKA"},
{label: "MONTELUKAST MYPLIX"},
{label: "MONTELUKAST PHR LAB"},
{label: "MONTELUKAST QUALIMED"},
{label: "MONTELUKAST RATIOPHARM"},
{label: "MONTELUKAST SANDOZ"},
{label: "MONTELUKAST SPECIFAR"},
{label: "MONTELUKAST TEVA"},
{label: "MONTELUKAST TORRENT"},
{label: "MONTELUKAST UNIMARK"},
{label: "MONTELUKAST VIATRIS"},
{label: "MONTELUKAST ZENTIVA"},
{label: "MONTELUKAST ZENTIVA LAB"},
{label: "MONTELUKAST ZYDUS"},
{label: "MONURIL"},
{label: "MONURIL ADULTES"},
{label: "MOPRAL"},
{label: "MOPRALPRO"},
{label: "MORISLAND"},
{label: "MORPHINE (CHLORHYDRATE) AGUETTANT"},
{label: "MORPHINE (CHLORHYDRATE) AP-HP"},
{label: "MORPHINE (CHLORHYDRATE) COOPER"},
{label: "MORPHINE (CHLORHYDRATE) LAVOISIER"},
{label: "MORPHINE (CHLORHYDRATE) MERAM"},
{label: "MORPHINE (CHLORHYDRATE) PHARMACIE CENTRALE DES ARMEES"},
{label: "MORPHINE (CHLORHYDRATE) RENAUDIN"},
{label: "MORPHINE (SULFATE) LAVOISIER"},
{label: "MORPHINE (SULFATE) RENAUDIN"},
{label: "MORPHINE AGUETTANT"},
{label: "MORPHINE FAULDING"},
{label: "MORPHINE RENAUDIN"},
{label: "MORPHINE STADA L.P."},
{label: "MOSCHUS BOIRON"},
{label: "MOSCHUS LEHNING"},
{label: "MOSCONTIN"},
{label: "MOSCONTIN L.P."},
{label: "MOSIL"},
{label: "MOTEC"},
{label: "MOTILIUM"},
{label: "MOTILIUMLINGUAL"},
{label: "MOTILIUMTABS"},
{label: "MOTILYO"},
{label: "MOTRIN"},
{label: "MOTRIREX"},
{label: "MOUNJARO"},
{label: "MOUSTICREME"},
{label: "MOVENTIG"},
{label: "MOVICOL"},
{label: "MOVICOL CHOCOLAT"},
{label: "MOVICOL ENFANTS"},
{label: "MOVICOL ORANGE"},
{label: "MOVICOL SANS AROME"},
{label: "MOVICOLIB"},
{label: "MOVIPREP"},
{label: "MOVIPREP ORANGE"},
{label: "MOVYMIA"},
{label: "MOXIFLOXACINE ACINO"},
{label: "MOXIFLOXACINE ARROW"},
{label: "MOXIFLOXACINE BIOGARAN"},
{label: "MOXIFLOXACINE EG"},
{label: "MOXIFLOXACINE KABI"},
{label: "MOXIFLOXACINE KRKA"},
{label: "MOXIFLOXACINE SANDOZ"},
{label: "MOXIFLOXACINE TEVA"},
{label: "MOXIFLOXACINE VIATRIS"},
{label: "MOXONIDINE BIOGARAN"},
{label: "MOXONIDINE EG"},
{label: "MOXONIDINE SANDOZ"},
{label: "MOXONIDINE TEVA"},
{label: "MOXONIDINE TEVA SANTE"},
{label: "MOXONIDINE VIATRIS"},
{label: "MOXYDAR"},
{label: "MOZOBIL"},
{label: "MUBUCHO"},
{label: "MUCICLAR"},
{label: "MUCINUM"},
{label: "MUCINUM A L'EXTRAIT DE CASCARA"},
{label: "MUCIPULGITE"},
{label: "MUCITUX"},
{label: "MUCIVITAL"},
{label: "MUCIVITAL AROMATISE"},
{label: "MUCOCIL"},
{label: "MUCODRILL"},
{label: "MUCOFLUID"},
{label: "MUCOLATOR"},
{label: "MUCOLATOR ENFANTS ET NOURRISSONS"},
{label: "MUCOLYSE"},
{label: "MUCOMYST"},
{label: "MUCOMYST NOURRISSONS"},
{label: "MUCOPLEXIL"},
{label: "MUCOPRET"},
{label: "MUCOR MUCEDO BOIRON"},
{label: "MUCOSODINE"},
{label: "MUCOTHIOL"},
{label: "MUCOTROPHIR SANS SUCRE ADULTES"},
{label: "MUCOTROPHIR SANS SUCRE ENFANTS ET NOURRISSONS"},
{label: "MULTAQ"},
{label: "MULTENE"},
{label: "MULTIBIC"},
{label: "MULTIBIC SANS POTASSIUM"},
{label: "MULTICROM"},
{label: "MULTICROM UNIDOSES"},
{label: "MULTIHANCE"},
{label: "MULTILARM"},
{label: "MULTINAX"},
{label: "MULTIONS"},
{label: "MULTIVITAMINES BAYER"},
{label: "MULTIVITAMINES BAYER ENFANTS"},
{label: "MULTIVITAMINES MERCK"},
{label: "MULTIVITAMINES SOLVAY PHARMA"},
{label: "MULTIVITAMINES UPSA"},
{label: "MULTIVITAMINES WYETH LEDERLE"},
{label: "MUNDOSON"},
{label: "MUNTEL"},
{label: "MUPHORAN"},
{label: "MUPIDERM"},
{label: "MUPIROCINE ARROW"},
{label: "MUPIROCINE BIOGARAN"},
{label: "MUPIROCINE DEXTREG 2%"},
{label: "MUPIROCINE EG 2%"},
{label: "MUPIROCINE PIERRE FABRE 2%"},
{label: "MUPIROCINE SANDOZ"},
{label: "MUPIROCINE SUBSTI-LABO"},
{label: "MUPIROCINE VIATRIS"},
{label: "MUQUEUSE NASALE BOIRON"},
{label: "MUQUEUSE RHINO-PHARYNGEE BOIRON"},
{label: "MUQUEUSE SINUSALE BOIRON"},
{label: "MUQUEUSE SINUSALE WELEDA"},
{label: "MUREX PURPUREA BOIRON"},
{label: "MURIATICUM ACIDUM BOIRON"},
{label: "MURIATICUM ACIDUM LEHNING"},
{label: "MUSCLE STRIE BOIRON"},
{label: "MUSE"},
{label: "MUTAGRIP"},
{label: "MUTESA"},
{label: "MUXOL"},
{label: "MVABEA"},
{label: "MVASI"},
{label: "MYALEPTA"},
{label: "MYAMBUTOL"},
{label: "MYCAMINE"},
{label: "MYCO ULTRALAN"},
{label: "MYCOAPAISYL"},
{label: "MYCODECYL"},
{label: "MYCOHYDRALIN"},
{label: "MYCOLOG"},
{label: "MYCONAIL"},
{label: "MYCOPHENOLATE MOFETIL ACCORD"},
{label: "MYCOPHENOLATE MOFETIL ACCORD HEALTHCARE"},
{label: "MYCOPHENOLATE MOFETIL ACTAVIS"},
{label: "MYCOPHENOLATE MOFETIL ALKEM PHARMA"},
{label: "MYCOPHENOLATE MOFETIL ARCHIE SAMUEL"},
{label: "MYCOPHENOLATE MOFETIL ARROW"},
{label: "MYCOPHENOLATE MOFETIL BIOGARAN"},
{label: "MYCOPHENOLATE MOFETIL EBEWE"},
{label: "MYCOPHENOLATE MOFETIL EG"},
{label: "MYCOPHENOLATE MOFETIL INTAS PHARMACEUTICALS"},
{label: "MYCOPHENOLATE MOFETIL MYLAN"},
{label: "MYCOPHENOLATE MOFETIL MYLAN PHARMA"},
{label: "MYCOPHENOLATE MOFETIL QUALIMED"},
{label: "MYCOPHENOLATE MOFETIL QUALIMED GENERIQUES"},
{label: "MYCOPHENOLATE MOFETIL RANBAXY"},
{label: "MYCOPHENOLATE MOFETIL RATIO"},
{label: "MYCOPHENOLATE MOFETIL RATIOPHARM"},
{label: "MYCOPHENOLATE MOFETIL RATIOPHARM GENERIQUES"},
{label: "MYCOPHENOLATE MOFETIL SANDOZ"},
{label: "MYCOPHENOLATE MOFETIL TEVA"},
{label: "MYCOPHENOLATE MOFETIL TILLOMED"},
{label: "MYCOPHENOLATE MOFETIL VIATRIS"},
{label: "MYCOPHENOLATE MOFETIL ZENTIVA"},
{label: "MYCOPHENOLATE MOFETIL ZYDUS"},
{label: "MYCOSEDERMYL"},
{label: "MYCOSKIN"},
{label: "MYCOSTATINE"},
{label: "MYCOSTER"},
{label: "MYDRANE"},
{label: "MYDRIASERT"},
{label: "MYDRIATICUM"},
{label: "MYFENAX"},
{label: "MYFORTIC"},
{label: "MYK"},
{label: "MYLERAN"},
{label: "MYLEUGYNE"},
{label: "MYLEUGYNE L.P."},
{label: "MYLOTARG"},
{label: "MYNOCINE"},
{label: "MYNOGYNE"},
{label: "MYOCET"},
{label: "MYOLASTAN"},
{label: "MYOPLEGE"},
{label: "MYOVIEW"},
{label: "MYOVITON"},
{label: "MYOZYME"},
{label: "MYRELEZ L.P."},
{label: "MYRICA CERIFERA BOIRON"},
{label: "MYRISTICA SEBIFERA BOIRON"},
{label: "MYRISTICA SEBIFERA WELEDA"},
{label: "MYRRHA BOIRON"},
{label: "MYRRHA WELEDA"},
{label: "MYRRHIS ODORATA BOIRON"},
{label: "MYRTICOL"},
{label: "MYRTINE ENFANTS"},
{label: "MYRTINE INHALANTE"},
{label: "MYRTINE NOURRISSONS"},
{label: "MYRTINE REVULSIVE"},
{label: "MYRTUS COMMUNIS BOIRON"},
{label: "MYSILDECARD"},
{label: "MYSOLINE"},
{label: "MYSTECLINE"},
{label: "MYTELASE"},
{label: "MYWY"},
{label: "N-OBLIVON"},
{label: "NAABAK"},
{label: "NAAXIA"},
{label: "NAAXIAFREE"},
{label: "NABECQ"},
{label: "NABUCOX"},
{label: "NABUMETONE TILLOMED"},
{label: "NABUTIL"},
{label: "NACLOF"},
{label: "NADROPARINE CALCIQUE ASPEN"},
{label: "NADROPARINE CALCIQUE GSK"},
{label: "NAEMIS"},
{label: "NAFERYL"},
{label: "NAFOLINE"},
{label: "NAFTAZONE INNOTHERA"},
{label: "NAFTIDROFURYL ALTER"},
{label: "NAFTIDROFURYL BIOGARAN"},
{label: "NAFTIDROFURYL EG"},
{label: "NAFTIDROFURYL GENEVAR"},
{label: "NAFTIDROFURYL IVAX"},
{label: "NAFTIDROFURYL MYLAN"},
{label: "NAFTIDROFURYL MYLAN GENERIQUES"},
{label: "NAFTIDROFURYL RANBAXY"},
{label: "NAFTIDROFURYL RATIOPHARM"},
{label: "NAFTIDROFURYL REF"},
{label: "NAFTIDROFURYL RPG"},
{label: "NAFTIDROFURYL TEVA"},
{label: "NAFTIDROFURYL ZENTIVA"},
{label: "NAFTILUX"},
{label: "NAGEMID"},
{label: "NAGLAZYME"},
{label: "NAIWANEL"},
{label: "NAJA TRIPUDIANS BOIRON"},
{label: "NAKAEL"},
{label: "NAKION G"},
{label: "NALADOR"},
{label: "NALBUPHINE AGUETTANT"},
{label: "NALBUPHINE MYLAN"},
{label: "NALBUPHINE RENAUDIN"},
{label: "NALBUPHINE SERB"},
{label: "NALCRON"},
{label: "NALGESIC"},
{label: "NALGOFAN"},
{label: "NALONE"},
{label: "NALOREX"},
{label: "NALORPHINE"},
{label: "NALOXONE ACCORD"},
{label: "NALOXONE ADAPT PHARMA"},
{label: "NALOXONE AGUETTANT"},
{label: "NALOXONE CHLORHYDRATE QUALIMED"},
{label: "NALOXONE FAULDING"},
{label: "NALOXONE VIATRIS"},
{label: "NALPAIN"},
{label: "NALSCUE"},
{label: "NALTREXONE ACCORD"},
{label: "NALTREXONE VIATRIS"},
{label: "NAMTAZ LP"},
{label: "NAMUSCLA"},
{label: "NANOCIS"},
{label: "NANOCOLL"},
{label: "NANOGAM"},
{label: "NAPHTALINUM BOIRON"},
{label: "NAPHTALINUM LEHNING"},
{label: "NAPROSYNE"},
{label: "NAPROXENE CASSENNE"},
{label: "NAPROXENE NYCOMED PHARMA"},
{label: "NAPROXENE RPG"},
{label: "NAPROXENE SODIQUE ARROW"},
{label: "NAPROXENE SODIQUE EG"},
{label: "NAPROXENE SODIQUE TEVA"},
{label: "NAPROXENE TEVA"},
{label: "NARAMIG"},
{label: "NARATRIPTAN ACTAVIS"},
{label: "NARATRIPTAN ARROW"},
{label: "NARATRIPTAN BIOGARAN"},
{label: "NARATRIPTAN EG"},
{label: "NARATRIPTAN MYLAN"},
{label: "NARATRIPTAN ORCHID EUROPE"},
{label: "NARATRIPTAN QUALIMED"},
{label: "NARATRIPTAN RANBAXY"},
{label: "NARATRIPTAN SANDOZ"},
{label: "NARATRIPTAN TEVA"},
{label: "NARATRIPTAN USV EUROPE"},
{label: "NARATRIPTAN ZENTIVA"},
{label: "NARATRIPTAN ZYDUS"},
{label: "NARAVELA"},
{label: "NARBALEK"},
{label: "NARCAN"},
{label: "NARCAN NEONATAL"},
{label: "NARCOZEP"},
{label: "NAROPEINE"},
{label: "NASACORT"},
{label: "NASALIDE"},
{label: "NASAMIDE"},
{label: "NASONEX"},
{label: "NASTURTIUM OFFICINALE BOIRON"},
{label: "NATEAD"},
{label: "NATECAL VITAMINE D3"},
{label: "NATI-K"},
{label: "NATICALM"},
{label: "NATIROSE"},
{label: "NATISEDINE"},
{label: "NATISPRAY"},
{label: "NATPAR"},
{label: "NATRIXAM"},
{label: "NATRUM CARBONICUM BOIRON"},
{label: "NATRUM CARBONICUM LEHNING"},
{label: "NATRUM CARBONICUM WELEDA"},
{label: "NATRUM MURIATICUM BOIRON"},
{label: "NATRUM MURIATICUM LEHNING"},
{label: "NATRUM MURIATICUM NATURALE WELEDA"},
{label: "NATRUM PHOSPHORICUM BOIRON"},
{label: "NATRUM PHOSPHORICUM LEHNING"},
{label: "NATRUM PHOSPHORICUM WELEDA"},
{label: "NATRUM SULFURICUM BOIRON"},
{label: "NATRUM SULFURICUM LEHNING"},
{label: "NATRUM SULFURICUM WELEDA"},
{label: "NATULAN"},
{label: "NATXIN"},
{label: "NAUSETUM"},
{label: "NAUSICALM"},
{label: "NAUSICALM ADULTES"},
{label: "NAUTAMINE"},
{label: "NAVELBINE"},
{label: "NAVIDOXIN"},
{label: "NAVIDOXINE"},
{label: "NAVOBAN"},
{label: "NAXESTAN"},
{label: "NAXOGYN"},
{label: "NAZINETTE DU DOCTEUR GILBERT"},
{label: "NAZINETTE DU DR GILBERT"},
{label: "NAZOPHYL"},
{label: "NEARTHRIN"},
{label: "NEBCINE"},
{label: "NEBIDO"},
{label: "NEBILOX"},
{label: "NEBIVOLOL ACTAVIS"},
{label: "NEBIVOLOL ALMUS"},
{label: "NEBIVOLOL AMAROX"},
{label: "NEBIVOLOL APOTEX"},
{label: "NEBIVOLOL ARROW"},
{label: "NEBIVOLOL ARROW GENERIQUES"},
{label: "NEBIVOLOL ARROW LAB"},
{label: "NEBIVOLOL BIOGARAN"},
{label: "NEBIVOLOL CRISTERS"},
{label: "NEBIVOLOL EG"},
{label: "NEBIVOLOL EVOLUGEN"},
{label: "NEBIVOLOL GLENMARK GENERICS"},
{label: "NEBIVOLOL ISOMED"},
{label: "NEBIVOLOL KRKA"},
{label: "NEBIVOLOL QUALIMED"},
{label: "NEBIVOLOL SANDOZ"},
{label: "NEBIVOLOL SUN"},
{label: "NEBIVOLOL TEVA"},
{label: "NEBIVOLOL TEVA SANTE"},
{label: "NEBIVOLOL VIATRIS"},
{label: "NEBIVOLOL VIATRIS SANTE"},
{label: "NEBIVOLOL ZENTIVA"},
{label: "NEBIVOLOL ZYDUS"},
{label: "NEBIVOLOL/HYDROCHLOROTHIAZIDE BIOGARAN"},
{label: "NEBIVOLOL/HYDROCHLOROTHIAZIDE CRISTERS"},
{label: "NEBIVOLOL/HYDROCHLOROTHIAZIDE MYLAN"},
{label: "NEBIVOLOL/HYDROCHLOROTHIAZIDE TEVA"},
{label: "NEBIVOLOL/HYDROCHLOROTHIAZIDE VIATRIS"},
{label: "NEBIVOLOL/HYDROCHLOROTHIAZIDE ZENTIVA"},
{label: "NECE PEN"},
{label: "NECRO-AZUR"},
{label: "NECYRANE"},
{label: "NEDOCROMIL DE SODIUM RPG"},
{label: "NEDOCROMIL SODIQUE RPG"},
{label: "NEFOPAM BIOGARAN"},
{label: "NEFOPAM MEDISOL"},
{label: "NEFOPAM MICRO LABS"},
{label: "NEFOPAM PANPHARMA"},
{label: "NEFOPAM VIATRIS"},
{label: "NEGABAN"},
{label: "NEGATOL"},
{label: "NEGMAPEN"},
{label: "NEGRAM"},
{label: "NEGRAM FORTE ADULTES"},
{label: "NEISVAC"},
{label: "NELKITE"},
{label: "NELMAUBERT"},
{label: "NEMDATINE"},
{label: "NEMESINE"},
{label: "NEO PERGONAL"},
{label: "NEO-CODION"},
{label: "NEO-CODION ADULTES"},
{label: "NEO-CODION ENFANTS"},
{label: "NEO-CODION NOURRISSONS"},
{label: "NEO-MERCAZOLE"},
{label: "NEOCLARITYN"},
{label: "NEOCONES"},
{label: "NEOCUPRUM"},
{label: "NEODUCYL"},
{label: "NEOFORDEX"},
{label: "NEOMYCINE DIAMANT"},
{label: "NEOPARYL FRAMYCETINE"},
{label: "NEOPECTYL ENFANTS"},
{label: "NEOPHAKOL"},
{label: "NEORABIS"},
{label: "NEORAL"},
{label: "NEORECORMON"},
{label: "NEORECORMON MULTIDOSE"},
{label: "NEORHINE"},
{label: "NEOSPECT"},
{label: "NEOSTIGMINE OPI"},
{label: "NEOSTIGMINE RENAUDIN"},
{label: "NEOSYNEPHRINE"},
{label: "NEOSYNEPHRINE AP-HP"},
{label: "NEOSYNEPHRINE BADRIAL"},
{label: "NEOSYNEPHRINE CHIBRET"},
{label: "NEOXIDIL"},
{label: "NEPETA CATARIA BOIRON"},
{label: "NEPEXTO"},
{label: "NEPHRAMINE"},
{label: "NEPRESSOL"},
{label: "NEREDIEM"},
{label: "NERF OPTIQUE BOIRON"},
{label: "NERF OPTIQUE WELEDA"},
{label: "NERF PARASYMPATHIQUE BOIRON"},
{label: "NERF PARASYMPATHIQUE WELEDA"},
{label: "NERF SCIATIQUE BOIRON"},
{label: "NERF SCIATIQUE WELEDA"},
{label: "NERF SYMPATHIQUE BOIRON"},
{label: "NERFS BOIRON"},
{label: "NERISALIC"},
{label: "NERISONE"},
{label: "NERISONE C"},
{label: "NERISONE GRAS"},
{label: "NERIUM OLEANDER BOIRON"},
{label: "NERIUM OLEANDER LEHNING"},
{label: "NERLYNX"},
{label: "NERTHEC"},
{label: "NERVOPAX"},
{label: "NERVOXYL"},
{label: "NESDONAL"},
{label: "NESTOSYL"},
{label: "NETAXEN"},
{label: "NETDOSE"},
{label: "NETILMICINE SCHERING-PLOUGH"},
{label: "NETROMICINE"},
{label: "NETUX"},
{label: "NEULASTA"},
{label: "NEULEPTIL"},
{label: "NEUPOGEN"},
{label: "NEUPRO"},
{label: "NEURACEQ"},
{label: "NEURIPLEGE"},
{label: "NEUROBLOC"},
{label: "NEUROCALCIUM"},
{label: "NEURODORON"},
{label: "NEUROFLORINE"},
{label: "NEUROLITE"},
{label: "NEUROLITHIUM"},
{label: "NEURONTIN"},
{label: "NEUROPAX"},
{label: "NEUROSTHENOL"},
{label: "NEUROTENSYL"},
{label: "NEUTRAVERA GLUCOSE"},
{label: "NEUTRICID"},
{label: "NEUTROSES"},
{label: "NEVANAC"},
{label: "NEVEROL"},
{label: "NEVIRAPINE ACCORD"},
{label: "NEVIRAPINE ACCORD LP"},
{label: "NEVIRAPINE ARROW"},
{label: "NEVIRAPINE ARROW LAB"},
{label: "NEVIRAPINE ARROW LP"},
{label: "NEVIRAPINE BIOGARAN"},
{label: "NEVIRAPINE CRISTERS"},
{label: "NEVIRAPINE CRISTERS LP"},
{label: "NEVIRAPINE DEXTREG LP"},
{label: "NEVIRAPINE DISPHAR"},
{label: "NEVIRAPINE SANDOZ"},
{label: "NEVIRAPINE SANDOZ LP"},
{label: "NEVIRAPINE STRAVENCON"},
{label: "NEVIRAPINE TEVA"},
{label: "NEVIRAPINE TEVA LP"},
{label: "NEVIRAPINE VIATRIS"},
{label: "NEVIRAPINE VIATRIS L.P."},
{label: "NEVIRAPINE ZENTIVA"},
{label: "NEVRIBAUME"},
{label: "NEVROSTHENINE GLYCOCOLLE FREYSSINGE"},
{label: "NEXAVAR"},
{label: "NEXEN"},
{label: "NEXIUM CONTROL"},
{label: "NEXOBRID"},
{label: "NEXOCET"},
{label: "NEXPLANON"},
{label: "NEXPOVIO"},
{label: "NEXVIADYME"},
{label: "NEZOPIL"},
{label: "NGENLA"},
{label: "NIAMIDE"},
{label: "NIAOULINOL"},
{label: "NIASPAN LP"},
{label: "NIBIOL"},
{label: "NICABATE"},
{label: "NICAN"},
{label: "NICARDIPINE AGUETTANT"},
{label: "NICARDIPINE ARROW"},
{label: "NICARDIPINE LABORATOIRE X.O LP"},
{label: "NICCOLUM METALLICUM BOIRON"},
{label: "NICERGOLINE BIOGARAN"},
{label: "NICERGOLINE EG"},
{label: "NICERGOLINE HOECHST HOUDE"},
{label: "NICERGOLINE IVAX"},
{label: "NICERGOLINE MYLAN"},
{label: "NICERGOLINE PHARMACIA & UPJOHN"},
{label: "NICERGOLINE QUALIMED"},
{label: "NICERGOLINE RANBAXY"},
{label: "NICERGOLINE ROGER BELLON LYOC"},
{label: "NICERGOLINE RPG LYOC"},
{label: "NICERGOLINE TEVA"},
{label: "NICKEL-COBALT OLIGOSOL"},
{label: "NICOBION"},
{label: "NICOGUM MENTHE SANS SUCRE"},
{label: "NICOGUM REGLISSE-MENTHE SANS SUCRE"},
{label: "NICOGUM SANS SUCRE"},
{label: "NICOPASS"},
{label: "NICOPASS MENTHE FRAICHEUR"},
{label: "NICOPASS REGLISSE MENTHE"},
{label: "NICOPASSLIB MENTHE FRAICHEUR"},
{label: "NICOPATCH TTS"},
{label: "NICOPATCHDERM"},
{label: "NICOPATCHLIB"},
{label: "NICOPRIVE"},
{label: "NICORANDIL ALMUS"},
{label: "NICORANDIL ARROW"},
{label: "NICORANDIL BIOGARAN"},
{label: "NICORANDIL CLEXNI"},
{label: "NICORANDIL DEXCEL"},
{label: "NICORANDIL DEXCEL PHARMA"},
{label: "NICORANDIL EG"},
{label: "NICORANDIL SANDOZ"},
{label: "NICORANDIL SET"},
{label: "NICORANDIL TEVA"},
{label: "NICORANDIL VIATRIS"},
{label: "NICORANDIL ZENTIVA"},
{label: "NICORANDIL ZYDUS"},
{label: "NICORETTE"},
{label: "NICORETTE FRUITS"},
{label: "NICORETTE INHALEUR"},
{label: "NICORETTE MENTHE"},
{label: "NICORETTE MENTHE CANNELLE"},
{label: "NICORETTE MENTHE FRAICHE"},
{label: "NICORETTE MENTHE GLACIALE"},
{label: "NICORETTE MICROTAB"},
{label: "NICORETTE MICROTAB CITRON"},
{label: "NICORETTE ORANGE"},
{label: "NICORETTESKIN"},
{label: "NICORETTESPRAY"},
{label: "NICORETTESPRAY FRUITS ROUGES"},
{label: "NICOSAN"},
{label: "NICOTINAMIDE RENAUDIN"},
{label: "NICOTINE ATP"},
{label: "NICOTINE EG"},
{label: "NICOTINE EG FRUIT"},
{label: "NICOTINE EG LABO CITRON"},
{label: "NICOTINE EG LABO MENTHE"},
{label: "NICOTINE EG MENTHE"},
{label: "NICOTINE ENORAMA FRUITMINT"},
{label: "NICOTINE ENORAMA MINT"},
{label: "NICOTINE EUROGENERICS CITRON"},
{label: "NICOTINE EUROGENERICS MENTHE"},
{label: "NICOTINE MONAL FRUIT"},
{label: "NICOTINE MONAL MENTHE"},
{label: "NICOTINE OMEGA PHARMA FRANCE"},
{label: "NICOTINE PIERRE FABRE MEDICAMENT"},
{label: "NICOTINE PIERRE FABRE MEDICAMENT MENTHE FRAICHEUR"},
{label: "NICOTINE PIERRE FABRE MEDICAMENT MENTHE SANS SUCRE"},
{label: "NICOTINE PIERRE FABRE MEDICAMENT REGLISSE MENTHE"},
{label: "NICOTINE PIERRE FABRE MEDICAMENT REGLISSE MENTHE SANS SUCRE"},
{label: "NICOTINE PIERRE FABRE MEDICAMENT SANS SUCRE"},
{label: "NICOTINE PIERRE FABRE SANTE"},
{label: "NICOTINE ZYMA"},
{label: "NICOTINE ZYMA MENTHE"},
{label: "NICOTINELL FRUIT"},
{label: "NICOTINELL FRUIT EXOTIQUE"},
{label: "NICOTINELL MENTHE"},
{label: "NICOTINELL MENTHE FRAICHEUR"},
{label: "NICOTINELL MENTHE POIVREE"},
{label: "NICOTINELL REGLISSE"},
{label: "NICOTINELL TTS"},
{label: "NICOTINELLCLASSIC"},
{label: "NICOTINHELP"},
{label: "NICOTROL"},
{label: "NIDREL"},
{label: "NIFEDIPINE ARROW L.P."},
{label: "NIFEDIPINE EG L.P."},
{label: "NIFEDIPINE G GAM"},
{label: "NIFEDIPINE G GAM L.P."},
{label: "NIFEDIPINE GNR L.P."},
{label: "NIFEDIPINE MYLAN LP"},
{label: "NIFEDIPINE RANBAXY L.P."},
{label: "NIFEDIPINE RATIOPHARM"},
{label: "NIFEDIPINE RATIOPHARM LP"},
{label: "NIFEDIPINE RPG"},
{label: "NIFEDIPINE RPG LP"},
{label: "NIFEDIPINE SANDOZ L.P."},
{label: "NIFEDIPINE TEVA CLASSICS LP"},
{label: "NIFEDIPINE TEVA L.P."},
{label: "NIFEDIPINE VIATRIS L.P."},
{label: "NIFEDIREX L.P."},
{label: "NIFELAT"},
{label: "NIFEXINE"},
{label: "NIFLUGEL"},
{label: "NIFLURIL"},
{label: "NIFLURIL ADULTES"},
{label: "NIFLURIL ENFANTS"},
{label: "NIFURON"},
{label: "NIFUROXAZIDE ARROW"},
{label: "NIFUROXAZIDE BIOGARAN"},
{label: "NIFUROXAZIDE BIOGARAN CONSEIL"},
{label: "NIFUROXAZIDE BOUCHARA RECORDATI"},
{label: "NIFUROXAZIDE CRISTERS"},
{label: "NIFUROXAZIDE EG"},
{label: "NIFUROXAZIDE EG LABO CONSEIL"},
{label: "NIFUROXAZIDE G GAM"},
{label: "NIFUROXAZIDE H3 SANTE"},
{label: "NIFUROXAZIDE JOLLY JATEL"},
{label: "NIFUROXAZIDE JOLLY-JATEL"},
{label: "NIFUROXAZIDE LAFARGE"},
{label: "NIFUROXAZIDE MYLAN"},
{label: "NIFUROXAZIDE MYLAN 4%"},
{label: "NIFUROXAZIDE PHR LAB"},
{label: "NIFUROXAZIDE QUALIMED"},
{label: "NIFUROXAZIDE RATIOPHARM"},
{label: "NIFUROXAZIDE RATIOPHARM CONSEIL"},
{label: "NIFUROXAZIDE RPG"},
{label: "NIFUROXAZIDE SANDOZ"},
{label: "NIFUROXAZIDE SANDOZ CONSEIL"},
{label: "NIFUROXAZIDE SANOFI-AVENTIS"},
{label: "NIFUROXAZIDE SUBSTIPHARM"},
{label: "NIFUROXAZIDE TEVA"},
{label: "NIFUROXAZIDE TEVA CONSEIL"},
{label: "NIFUROXAZIDE URGO"},
{label: "NIFUROXAZIDE WINTHROP"},
{label: "NIFUROXAZIDE ZENTIVA"},
{label: "NIFUROXAZIDE ZYDUS"},
{label: "NIGELLA DAMASCENA BOIRON"},
{label: "NIGELLA SATIVA BOIRON"},
{label: "NIGRANTYL"},
{label: "NIGUFO"},
{label: "NILEVAR"},
{label: "NIMBEX"},
{label: "NIMENRIX"},
{label: "NIMESULIDE CEHEL PHARMA"},
{label: "NIMESULIDE EG"},
{label: "NIMESULIDE TEVA"},
{label: "NIMODIPINE ALTAN"},
{label: "NIMODIPINE NORDIC PHARMA"},
{label: "NIMOTOP"},
{label: "NIMVASTID"},
{label: "NINLARO"},
{label: "NIPENT"},
{label: "NIQUITIN"},
{label: "NIQUITIN FRUITS TROPICAUX"},
{label: "NIQUITIN MENTHE DOUCE"},
{label: "NIQUITIN MENTHE FRAICHE"},
{label: "NIQUITIN MENTHE GLACIALE"},
{label: "NIQUITINCQUE"},
{label: "NIQUITINMINIS CERISE"},
{label: "NIQUITINMINIS MENTHE FRAÎCHE"},
{label: "NIQUITINMINIS ORANGE"},
{label: "NIRVA"},
{label: "NISACALM"},
{label: "NISAPULVOL"},
{label: "NISASEPTOL"},
{label: "NISASOL"},
{label: "NISIS"},
{label: "NISISCO"},
{label: "NISOLDIPINE BAYER PHARMA L.P."},
{label: "NITISINONE DIPHARMA"},
{label: "NITISINONE MDK"},
{label: "NITRATE D'ARGENT"},
{label: "NITRATE D'ECONAZOLE JANSSEN DEPOT LP"},
{label: "NITRATE DE MICONAZOLE CILAG"},
{label: "NITRATE DE MICONAZOLE ZYDUS"},
{label: "NITRATE DARGENT COOPER"},
{label: "NITRENDIPINE SCHWARZ PHARMA"},
{label: "NITRENDIPINE TEVA"},
{label: "NITRENDIPINE VIATRIS"},
{label: "NITRENDIPINE ZYDUS"},
{label: "NITRIATE"},
{label: "NITRICUM ACIDUM BOIRON"},
{label: "NITRICUM ACIDUM LEHNING"},
{label: "NITRICUM ACIDUM WELEDA"},
{label: "NITRIDERM TTS"},
{label: "NITRODEX CHRONULES"},
{label: "NITRODUR"},
{label: "NITROL VERRUES"},
{label: "NITRONAL"},
{label: "NITRONALSPRAY"},
{label: "NITYR"},
{label: "NIVABETOL"},
{label: "NIVAQUINE"},
{label: "NIVESTIM"},
{label: "NIVOLUMAB BMS"},
{label: "NIWINUR"},
{label: "NIZATIDINE TEVA"},
{label: "NIZAXID"},
{label: "NIZORAL"},
{label: "NOBACTAIR"},
{label: "NOBACTER"},
{label: "NOBLIGAN"},
{label: "NOBRIUM ROCHE"},
{label: "NOCASIO"},
{label: "NOCDURNA"},
{label: "NOCERTONE"},
{label: "NOCTAMIDE"},
{label: "NOCTAVAL"},
{label: "NOCTIUM"},
{label: "NOCTRAN"},
{label: "NOCTURIN"},
{label: "NOCTYL"},
{label: "NOCVALENE ADULTES"},
{label: "NOCVALENE ENFANT"},
{label: "NODEX ADULTES"},
{label: "NODEX ENFANT"},
{label: "NODEX ENFANTS"},
{label: "NOLPAZA"},
{label: "NOLVADEX"},
{label: "NOMEGESTROL ACETATE/ESTRADIOL VIATRIS"},
{label: "NOMEGESTROL ARROW"},
{label: "NOMEGESTROL BIOGARAN"},
{label: "NOMEGESTROL EG"},
{label: "NOMEGESTROL RATIOPHARM"},
{label: "NOMEGESTROL SANDOZ"},
{label: "NOMEGESTROL TEVA"},
{label: "NOMEGESTROL VIATRIS"},
{label: "NOMEGESTROL ZENTIVA"},
{label: "NOMIGRANE"},
{label: "NONAN"},
{label: "NOOSTAN"},
{label: "NOOTROPYL"},
{label: "NOPALU"},
{label: "NOPIRINE VICARIO"},
{label: "NOPRON ENFANTS"},
{label: "NORADRENALINE (TARTRATE) AGUETTANT"},
{label: "NORADRENALINE (TARTRATE) KABI"},
{label: "NORADRENALINE (TARTRATE) RENAUDIN"},
{label: "NORADRENALINE HOSPIRA"},
{label: "NORADRENALINE QUALIMED"},
{label: "NORADRENALINE RENAUDIN"},
{label: "NORADRENALINE TARTRATE KALCEKS"},
{label: "NORADRENALINE TARTRATE RENAUDIN"},
{label: "NORADRENALINE TARTRATE SUN"},
{label: "NORADRENALINE VIATRIS"},
{label: "NORBILINE"},
{label: "NORCHOLESTEROL IODE [131I] CIS BIO INTERNATIONAL"},
{label: "NORCODA"},
{label: "NORCURON"},
{label: "NORDAZ"},
{label: "NORDIMET"},
{label: "NORDITROPINE"},
{label: "NORDITROPINE FLEXPRO"},
{label: "NORDITROPINE NORDIFLEX"},
{label: "NORDITROPINE PENSET"},
{label: "NORDITROPINE SIMPLEXX"},
{label: "NORFLOXACINE ARROW"},
{label: "NORFLOXACINE BIOGARAN"},
{label: "NORFLOXACINE CRISTERS"},
{label: "NORFLOXACINE EG"},
{label: "NORFLOXACINE MYLAN"},
{label: "NORFLOXACINE QUALIMED"},
{label: "NORFLOXACINE RANBAXY"},
{label: "NORFLOXACINE RATIOPHARM"},
{label: "NORFLOXACINE SANDOZ"},
{label: "NORFLOXACINE TEVA"},
{label: "NORFLOXACINE ZENTIVA"},
{label: "NORGAGIL"},
{label: "NORGALAX"},
{label: "NORISTERAT"},
{label: "NORLEVO"},
{label: "NORLUTEN"},
{label: "NORMACOL"},
{label: "NORMACOL A LA BOURDAINE"},
{label: "NORMACOL A LA BOURDAINE SANS SUCRE"},
{label: "NORMACOL A LA DIPROPYLINE"},
{label: "NORMACOL LAVEMENT ADULTES"},
{label: "NORMACOL LAVEMENT ENFANTS"},
{label: "NORMAFIBE"},
{label: "NORMISON"},
{label: "NORMOGASTRYL"},
{label: "NORMOL"},
{label: "NORMOSANG"},
{label: "NOROXINE"},
{label: "NORPLANT"},
{label: "NORPROLAC"},
{label: "NORQUENTIEL"},
{label: "NORSEDCOMBI"},
{label: "NORSET"},
{label: "NORVIR"},
{label: "NOSTRIL"},
{label: "NOTAXO"},
{label: "NOTEZINE"},
{label: "NOTGAZ"},
{label: "NOTGAZ ENFANTS"},
{label: "NOTRINO ADULTES"},
{label: "NOTRINO ENFANTS ET NOURRISSONS"},
{label: "NOVACETOL (ASPIRINE PARACETAMOL)"},
{label: "NOVADERMINE"},
{label: "NOVAFOLINE"},
{label: "NOVALGINE"},
{label: "NOVALUX"},
{label: "NOVANTICA 20/150"},
{label: "NOVANTICA 30/150"},
{label: "NOVANTRONE"},
{label: "NOVAPAMYL L.P."},
{label: "NOVATREX"},
{label: "NOVAZAM"},
{label: "NOVESINE"},
{label: "NOVIRAB"},
{label: "NOVOBEDOUZE"},
{label: "NOVOCODYL ADULTES SANS SUCRE"},
{label: "NOVOCODYL ENFANTS"},
{label: "NOVODIL"},
{label: "NOVOEIGHT"},
{label: "NOVOFEMME"},
{label: "NOVOMIX"},
{label: "NOVOMYXINE"},
{label: "NOVONORM"},
{label: "NOVOPTINE"},
{label: "NOVOPULMON NOVOLIZER"},
{label: "NOVORAPID"},
{label: "NOVORAPID FLEXPEN"},
{label: "NOVORAPID INNOLET"},
{label: "NOVORAPID NOVOLET"},
{label: "NOVORAPID PENFILL"},
{label: "NOVORAPID PUMPCART"},
{label: "NOVOSEVEN"},
{label: "NOVOTHIRTEEN"},
{label: "NOVYCONT"},
{label: "NOXAFIL"},
{label: "NOXAP"},
{label: "NOXIBEN"},
{label: "NOXYFLEX"},
{label: "NOYADA"},
{label: "NOZINAN"},
{label: "NP100 PREMATURES AP-HP"},
{label: "NP2 ENFANTS AP-HP"},
{label: "NPLATE"},
{label: "NUBAIN"},
{label: "NUBEQA"},
{label: "NUCALA"},
{label: "NUCEIVA"},
{label: "NUCTALON"},
{label: "NUEDEXTA"},
{label: "NUJOL"},
{label: "NULOJIX"},
{label: "NUMETAH G13% E PREMATURES"},
{label: "NUMETAH G13%E"},
{label: "NUMETAH G16%E"},
{label: "NUMETAH G19%E"},
{label: "NUREFLEX"},
{label: "NUREFLEX L.P."},
{label: "NUROFEN"},
{label: "NUROFEN RHUME"},
{label: "NUROFENCAPS"},
{label: "NUROFENFEM"},
{label: "NUROFENFLASH"},
{label: "NUROFENPLAST"},
{label: "NUROFENPRO"},
{label: "NUROFENPRO ENFANTS"},
{label: "NUROFENPRO ENFANTS ET NOURRISSONS SANS SUCRE"},
{label: "NUROFENTABS"},
{label: "NUROFLASH"},
{label: "NUTRIFLEX G125/N4"},
{label: "NUTRIFLEX G150/N6"},
{label: "NUTRIFLEX G240/N10"},
{label: "NUTRIFLEX G240/N10/E"},
{label: "NUTRIFLEX G80/N5"},
{label: "NUTRIGENE"},
{label: "NUTRILAMINE"},
{label: "NUTRINEAL PD4 A"},
{label: "NUTRIVISC"},
{label: "NUTROPINAQ"},
{label: "NUTRYELT"},
{label: "NUVARING"},
{label: "NUVAXOVID"},
{label: "NUWIQ"},
{label: "NUX MOSCHATA BOIRON"},
{label: "NUX MOSCHATA LEHNING"},
{label: "NUX MOSCHATA WELEDA"},
{label: "NUX VOMICA BOIRON"},
{label: "NUX VOMICA COMPLEXE N°49"},
{label: "NUX VOMICA COMPOSE"},
{label: "NUX VOMICA FERRIER"},
{label: "NUX VOMICA LEHNING"},
{label: "NUX VOMICA WELEDA"},
{label: "NYCKTERINIA CAPENSIS BOIRON"},
{label: "NYCTO"},
{label: "NYMPHAEA ALBA BOIRON"},
{label: "NYMPHAEA LUTEA BOIRON"},
{label: "NYOGEL LP"},
{label: "NYOLOL"},
{label: "NYVEPRIA"},
{label: "NYXOID"},
{label: "OBEFLORINE"},
{label: "OBIZUR"},
{label: "OBSIDIENNE BOIRON"},
{label: "OCADRIK L.P."},
{label: "OCALIVA"},
{label: "OCCITUX"},
{label: "OCIMUM BASILICUM BOIRON"},
{label: "OCIMUM CANUM BOIRON"},
{label: "OCREVUS"},
{label: "OCTAFIX"},
{label: "OCTAGAM"},
{label: "OCTALBINE"},
{label: "OCTANATE"},
{label: "OCTANATE LV"},
{label: "OCTAPLASLG"},
{label: "OCTAPLEX"},
{label: "OCTEGRA"},
{label: "OCTIM"},
{label: "OCTOFENE"},
{label: "OCTREOSCAN"},
{label: "OCTREOTIDE ARROW"},
{label: "OCTREOTIDE HOSPIRA"},
{label: "OCTREOTIDE KABI"},
{label: "OCTREOTIDE SANDOZ"},
{label: "OCTREOTIDE SUN"},
{label: "OCTREOTIDE TEVA"},
{label: "OCTREOTIDE TEVA LP"},
{label: "OCUFEN"},
{label: "OCUGEL"},
{label: "ODDIBIL"},
{label: "ODEFSEY"},
{label: "ODOMZO"},
{label: "ODRIK"},
{label: "OEDIEN"},
{label: "OENANTHE CROCATA BOIRON"},
{label: "OENANTHE CROCATA COMPLEXE N°78"},
{label: "OENANTHE CROCATA LEHNING"},
{label: "OENOTHERA BIENNIS BOIRON"},
{label: "OESCLIM"},
{label: "OESOMAT NOURRISSON"},
{label: "OESTRADIOL RETARD THERAMEX"},
{label: "OESTRODOSE"},
{label: "OESTROGEL"},
{label: "OFEV"},
{label: "OFLOCET"},
{label: "OFLOXACINE AGUETTANT"},
{label: "OFLOXACINE ALMUS"},
{label: "OFLOXACINE ARROW"},
{label: "OFLOXACINE BIOGARAN"},
{label: "OFLOXACINE CRISTERS"},
{label: "OFLOXACINE EG"},
{label: "OFLOXACINE EVOLUGEN"},
{label: "OFLOXACINE G GAM"},
{label: "OFLOXACINE IVAX"},
{label: "OFLOXACINE MYLAN"},
{label: "OFLOXACINE NORDIC PHARMA"},
{label: "OFLOXACINE PFIZER"},
{label: "OFLOXACINE QUALIMED"},
{label: "OFLOXACINE RATIOPHARM"},
{label: "OFLOXACINE RPG"},
{label: "OFLOXACINE SANDOZ"},
{label: "OFLOXACINE SANDOZ MONODOSE"},
{label: "OFLOXACINE SET"},
{label: "OFLOXACINE SUN"},
{label: "OFLOXACINE TEVA"},
{label: "OFLOXACINE VIATRIS"},
{label: "OFLOXACINE ZENTIVA"},
{label: "OFRAXID"},
{label: "OFRAXID ENFANTS ET NOURRISSONS"},
{label: "OFRIRI"},
{label: "OFTALLEN"},
{label: "OGAST"},
{label: "OGASTORO"},
{label: "OGENYX"},
{label: "OGIVRI"},
{label: "OGYLINE"},
{label: "OKEDI"},
{label: "OKIMUS"},
{label: "OKITASK"},
{label: "OLAMY"},
{label: "OLANSEK"},
{label: "OLANZAPINE ACCORD"},
{label: "OLANZAPINE ACTAVIS"},
{label: "OLANZAPINE ALTER"},
{label: "OLANZAPINE ARCHIE SAMUEL"},
{label: "OLANZAPINE ARROW"},
{label: "OLANZAPINE ARROW GENERIQUES"},
{label: "OLANZAPINE AUROBINDO"},
{label: "OLANZAPINE BGR"},
{label: "OLANZAPINE BIOGARAN"},
{label: "OLANZAPINE BLUEFISH"},
{label: "OLANZAPINE CRISTERS"},
{label: "OLANZAPINE CRISTERS PHARMA"},
{label: "OLANZAPINE EG"},
{label: "OLANZAPINE EVOLUGEN"},
{label: "OLANZAPINE IDL"},
{label: "OLANZAPINE ISOMED"},
{label: "OLANZAPINE LILLY"},
{label: "OLANZAPINE MYLAN"},
{label: "OLANZAPINE MYLAN GENERIQUES"},
{label: "OLANZAPINE OPENING PHARMA"},
{label: "OLANZAPINE PFIZER"},
{label: "OLANZAPINE QUALIMED"},
{label: "OLANZAPINE RANBAXY"},
{label: "OLANZAPINE RATIOPHARM"},
{label: "OLANZAPINE REG EUROPE"},
{label: "OLANZAPINE SANDOZ"},
{label: "OLANZAPINE SANOVEL HIOLDING"},
{label: "OLANZAPINE SANOVEL HOLDING"},
{label: "OLANZAPINE SUN"},
{label: "OLANZAPINE SYNTHON"},
{label: "OLANZAPINE TEVA"},
{label: "OLANZAPINE VIATRIS"},
{label: "OLANZAPINE ZENTIVA"},
{label: "OLANZAPINE ZENTIVA LAB"},
{label: "OLANZAPINE ZYDUS"},
{label: "OLANZAPINE ZYDUS FRANCE"},
{label: "OLATALIN"},
{label: "OLBETAM"},
{label: "OLCAM"},
{label: "OLEA EUROPAEA"},
{label: "OLEA EUROPAEA BOIRON"},
{label: "OLEUNOR N5E"},
{label: "OLEUNOR N7E"},
{label: "OLEUNOR N9E"},
{label: "OLFANOV"},
{label: "OLIBANUM BOIRON"},
{label: "OLIBANUM WELEDA"},
{label: "OLICLINOMEL N 4-550"},
{label: "OLICLINOMEL N 4-550 E"},
{label: "OLICLINOMEL N 5-800"},
{label: "OLICLINOMEL N 5-800 E"},
{label: "OLICLINOMEL N 6-900"},
{label: "OLICLINOMEL N 6-900 E"},
{label: "OLICLINOMEL N 7-1000"},
{label: "OLICLINOMEL N 8-800"},
{label: "OLICLINOMEL N7-1000 E"},
{label: "OLIGO ESSENTIELS CUIVRE"},
{label: "OLIGO ESSENTIELS LITHIUM"},
{label: "OLIGO ESSENTIELS MAGNESIUM"},
{label: "OLIGOCURE"},
{label: "OLIGODERM"},
{label: "OLIGOGRANUL COBALT"},
{label: "OLIGOGRANUL CUIVRE"},
{label: "OLIGOGRANUL CUIVRE OR ARGENT"},
{label: "OLIGOGRANUL FLUOR"},
{label: "OLIGOGRANUL LITHIUM"},
{label: "OLIGOGRANUL MAGNESIUM"},
{label: "OLIGOGRANUL MANGANESE"},
{label: "OLIGOGRANUL MANGANESE COBALT"},
{label: "OLIGOGRANUL MANGANESE CUIVRE"},
{label: "OLIGOGRANUL MANGANESE CUIVRE COBALT"},
{label: "OLIGOGRANUL NICKEL-COBALT"},
{label: "OLIGOGRANUL PHOSPHORE"},
{label: "OLIGOGRANUL POTASSIUM"},
{label: "OLIGOGRANUL SELENIUM"},
{label: "OLIGOGRANUL SOUFRE"},
{label: "OLIGOGRANUL ZINC"},
{label: "OLIGOGRANUL ZINC-CUIVRE"},
{label: "OLIGOGRANUL ZINC-NICKEL-COBALT"},
{label: "OLIGOSTIM ALUMINIUM"},
{label: "OLIGOSTIM COBALT"},
{label: "OLIGOSTIM CUIVRE"},
{label: "OLIGOSTIM CUIVRE OR ARGENT"},
{label: "OLIGOSTIM FLUOR"},
{label: "OLIGOSTIM LITHIUM"},
{label: "OLIGOSTIM MAGNESIUM"},
{label: "OLIGOSTIM MANGANESE"},
{label: "OLIGOSTIM MANGANESE COBALT"},
{label: "OLIGOSTIM MANGANESE CUIVRE"},
{label: "OLIGOSTIM MANGANESE CUIVRE COBALT"},
{label: "OLIGOSTIM PHOSPHORE"},
{label: "OLIGOSTIM POTASSIUM"},
{label: "OLIGOSTIM SELENIUM"},
{label: "OLIGOSTIM SOUFRE"},
{label: "OLIGOSTIM ZINC"},
{label: "OLIGOSTIM ZINC CUIVRE"},
{label: "OLIGOSTIM ZINC NICKEL COBALT"},
{label: "OLIMEL N12"},
{label: "OLIMEL N12E"},
{label: "OLIMEL N5E"},
{label: "OLIMEL N7"},
{label: "OLIMEL N7E"},
{label: "OLIMEL N9"},
{label: "OLIMEL N9E"},
{label: "OLIVALAX"},
{label: "OLIVENITE WELEDA"},
{label: "OLIVIASE"},
{label: "OLMES"},
{label: "OLMESARTAN MEDOXOMIL ZYDUS"},
{label: "OLMESARTAN MEDOXOMIL/HYDROCHLOROTHIAZIDE ZYDUS"},
{label: "OLMETEC"},
{label: "OLMIFON"},
{label: "OLUMIANT"},
{label: "OLYSIO"},
{label: "OMACOR"},
{label: "OMEGAVEN"},
{label: "OMEGEN"},
{label: "OMELENE"},
{label: "OMEPCID"},
{label: "OMEPIREX"},
{label: "OMEPRAZOLE"},
{label: "OMEPRAZOLE ACCORD"},
{label: "OMEPRAZOLE ACTAVIS"},
{label: "OMEPRAZOLE ADS PHARMA"},
{label: "OMEPRAZOLE AGUETTANT"},
{label: "OMEPRAZOLE ALMUS"},
{label: "OMEPRAZOLE ALS"},
{label: "OMEPRAZOLE ALTER"},
{label: "OMEPRAZOLE ARROW"},
{label: "OMEPRAZOLE ARROW GENERIQUES"},
{label: "OMEPRAZOLE ARROW LAB"},
{label: "OMEPRAZOLE ASTRAZENECA"},
{label: "OMEPRAZOLE BAYER"},
{label: "OMEPRAZOLE BIOGARAN"},
{label: "OMEPRAZOLE BIOGARAN CONSEIL"},
{label: "OMEPRAZOLE BIOSTABILEX"},
{label: "OMEPRAZOLE BOUCHARA RECORDATI"},
{label: "OMEPRAZOLE BOUCHARA-RECORDATI"},
{label: "OMEPRAZOLE CRISTERS"},
{label: "OMEPRAZOLE CRISTERS LAB"},
{label: "OMEPRAZOLE CRISTERS PHARMA"},
{label: "OMEPRAZOLE DISTRIQUIMICA"},
{label: "OMEPRAZOLE EG"},
{label: "OMEPRAZOLE EVOLUGEN"},
{label: "OMEPRAZOLE EVOLUGEN PHARMA"},
{label: "OMEPRAZOLE G GAM"},
{label: "OMEPRAZOLE GNR"},
{label: "OMEPRAZOLE HEXAL"},
{label: "OMEPRAZOLE ISOMED"},
{label: "OMEPRAZOLE KRKA"},
{label: "OMEPRAZOLE LABORATOIRES BOUCHARA-RECORDATI"},
{label: "OMEPRAZOLE LBR"},
{label: "OMEPRAZOLE MG PHARMA"},
{label: "OMEPRAZOLE MYLAN"},
{label: "OMEPRAZOLE MYLAN CONSEIL"},
{label: "OMEPRAZOLE MYLAN GENERIQUES"},
{label: "OMEPRAZOLE NEPENTHES"},
{label: "OMEPRAZOLE NEPENTHES CONSEIL"},
{label: "OMEPRAZOLE NEWLINE PHARMA"},
{label: "OMEPRAZOLE NORIDEM"},
{label: "OMEPRAZOLE ORSADE"},
{label: "OMEPRAZOLE PHR LAB"},
{label: "OMEPRAZOLE QUALIMED"},
{label: "OMEPRAZOLE QUALIMED GENERIQUES"},
{label: "OMEPRAZOLE QUIVER"},
{label: "OMEPRAZOLE RATIO"},
{label: "OMEPRAZOLE RATIOPHARM"},
{label: "OMEPRAZOLE RPG"},
{label: "OMEPRAZOLE SANDOZ"},
{label: "OMEPRAZOLE SANDOZ CONSEIL"},
{label: "OMEPRAZOLE SERVIPHARM"},
{label: "OMEPRAZOLE SET"},
{label: "OMEPRAZOLE SUBSTILAB"},
{label: "OMEPRAZOLE SUBSTIPHARM"},
{label: "OMEPRAZOLE TEVA"},
{label: "OMEPRAZOLE TEVA CONSEIL"},
{label: "OMEPRAZOLE TEVA PHARMA"},
{label: "OMEPRAZOLE TEVA SANTE"},
{label: "OMEPRAZOLE UQFAR"},
{label: "OMEPRAZOLE VIATRIS"},
{label: "OMEPRAZOLE VIATRIS CONSEIL"},
{label: "OMEPRAZOLE ZENTIVA"},
{label: "OMEPRAZOLE ZENTIVA LAB"},
{label: "OMEPRAZOLE ZYDUS"},
{label: "OMEPRAZOLE ZYDUS FRANCE"},
{label: "OMERIS"},
{label: "OMEXEL L.P."},
{label: "OMEZELIS"},
{label: "OMIX LP"},
{label: "OMNIPAQUE"},
{label: "OMNISCAN"},
{label: "OMNITROPE"},
{label: "OMVOH"},
{label: "ONBREZ BREEZHALER"},
{label: "ONCASPAR"},
{label: "ONCELON L.P."},
{label: "ONCOVIN"},
{label: "ONCTOSE"},
{label: "ONCTOSE HYDROCORTISONE"},
{label: "ONDANSETRON ACCORD"},
{label: "ONDANSETRON AGUETTANT"},
{label: "ONDANSETRON ARROW"},
{label: "ONDANSETRON B BRAUN"},
{label: "ONDANSETRON BIOGARAN"},
{label: "ONDANSETRON CRISTERS"},
{label: "ONDANSETRON CTRS"},
{label: "ONDANSETRON EG"},
{label: "ONDANSETRON ETHYPHARM"},
{label: "ONDANSETRON GENERES"},
{label: "ONDANSETRON GSK"},
{label: "ONDANSETRON HIKMA"},
{label: "ONDANSETRON KABI"},
{label: "ONDANSETRON MAYNE"},
{label: "ONDANSETRON MERCK GENERIQUES"},
{label: "ONDANSETRON MYLAN PHARMA"},
{label: "ONDANSETRON PAUCOURT"},
{label: "ONDANSETRON PFIZER"},
{label: "ONDANSETRON QUALIHEALTH"},
{label: "ONDANSETRON QUALIMED"},
{label: "ONDANSETRON RANBAXY"},
{label: "ONDANSETRON RATIOPHARM"},
{label: "ONDANSETRON RENAUDIN"},
{label: "ONDANSETRON SAINT GERMAIN"},
{label: "ONDANSETRON SANDOZ"},
{label: "ONDANSETRON TEVA"},
{label: "ONDANSETRON VENIPHARM"},
{label: "ONDANSETRON VIATRIS"},
{label: "ONDANSETRON WINTHROP"},
{label: "ONDANSETRON ZENTIVA"},
{label: "ONDANSETRON ZYDUS"},
{label: "ONDASETRON MYLAN PHARMA"},
{label: "ONDEVA"},
{label: "ONDEXXYA"},
{label: "ONEZYP"},
{label: "ONGLYZA"},
{label: "ONIVYDE"},
{label: "ONONIS SPINOSA BOIRON"},
{label: "ONOSMODIUM VIRGINICUM BOIRON"},
{label: "ONPATTRO"},
{label: "ONSENAL"},
{label: "ONTOZRY"},
{label: "ONTRUZANT"},
{label: "ONUREG"},
{label: "ONYCOSOL"},
{label: "ONYTEC"},
{label: "OPALGYNE"},
{label: "OPATANOL"},
{label: "OPDIVO"},
{label: "OPDUALAG"},
{label: "OPGENRA"},
{label: "OPHTACALM"},
{label: "OPHTACALMFREE"},
{label: "OPHTACOLLYRE"},
{label: "OPHTADIL"},
{label: "OPHTALEX"},
{label: "OPHTALIA A LA MAUVE"},
{label: "OPHTALMINE"},
{label: "OPHTANET"},
{label: "OPHTASILOXANE"},
{label: "OPHTASONE"},
{label: "OPHTERGINE"},
{label: "OPHTESIC"},
{label: "OPHTIDINE"},
{label: "OPHTIM"},
{label: "OPIDUR L.P."},
{label: "OPIUM BOIRON"},
{label: "OPIUM LEHNING"},
{label: "OPO VEINOGENE"},
{label: "OPOBYL"},
{label: "OPRYMEA"},
{label: "OPSUMIT"},
{label: "OPTAFLU"},
{label: "OPTALIDON A LA NORAMIDOPYRINE"},
{label: "OPTAMINE"},
{label: "OPTICRON"},
{label: "OPTICRON UNIDOSE"},
{label: "OPTIDESO"},
{label: "OPTIDRIL"},
{label: "OPTIJECT"},
{label: "OPTIKINZY"},
{label: "OPTILIX"},
{label: "OPTILOVA"},
{label: "OPTIMARK"},
{label: "OPTIMIZETTE"},
{label: "OPTINESSE"},
{label: "OPTIRAY"},
{label: "OPTISON"},
{label: "OPTOQUINIDINE"},
{label: "OPTOVIT"},
{label: "OPTREX"},
{label: "OPTRUMA"},
{label: "OPUNTIA VULGARIS BOIRON"},
{label: "OPVERO"},
{label: "OPZELURA"},
{label: "OR NATIF BOIRON"},
{label: "ORABLOC"},
{label: "ORACEFAL"},
{label: "ORACILLINE"},
{label: "ORACLONE"},
{label: "ORALAIR"},
{label: "ORALFENE"},
{label: "ORALGAN"},
{label: "ORAMORPH"},
{label: "ORAMORPH LP"},
{label: "ORANTINE L.P."},
{label: "ORAP"},
{label: "ORAQIX"},
{label: "ORATHRICINE"},
{label: "ORATIKA"},
{label: "ORAVERSE"},
{label: "ORAVIR"},
{label: "ORBEC"},
{label: "ORBEC ROTACAPS"},
{label: "ORBEC ROTADISKS"},
{label: "ORBENINE"},
{label: "ORCHITINUM BOIRON"},
{label: "ORDINATOR"},
{label: "ORDIPHA"},
{label: "ORELOX"},
{label: "ORELOX ENFANTS ET NOURRISSONS"},
{label: "ORENCIA"},
{label: "ORFADIN"},
{label: "ORFIBU"},
{label: "ORGALUTRAN"},
{label: "ORGAMETRIL"},
{label: "ORGARAN"},
{label: "ORGASULINE 30/70"},
{label: "ORGASULINE N.P.H."},
{label: "ORGASULINE NPH"},
{label: "ORGASULINE RAPIDE"},
{label: "ORGASULINE RAPIDE NOURRISSONS"},
{label: "ORGOVYX"},
{label: "ORIEMBO"},
{label: "ORIEMBOSC"},
{label: "ORIGANUM MAJORANA BOIRON"},
{label: "ORIGANUM MAJORANA WELEDA"},
{label: "ORIGANUM VULGARE BOIRON"},
{label: "ORIIODOL"},
{label: "ORIMETENE"},
{label: "ORKAMBI"},
{label: "ORLADEYO"},
{label: "ORLISTAT EG"},
{label: "ORLISTAT SANDOZ"},
{label: "ORLISTAT TEVA"},
{label: "ORLISTAT TEVA CONSEIL"},
{label: "ORLIZEN"},
{label: "ORLIZEN ADULTES"},
{label: "ORMANDYL"},
{label: "ORNICETIL"},
{label: "ORNIDAZOLE SERB"},
{label: "ORNIDYL"},
{label: "ORNITAINE"},
{label: "ORNITHOGALUM UMBELLATUM BOIRON"},
{label: "OROBUPRE"},
{label: "OROCAL"},
{label: "OROCAL VITAMINE D3"},
{label: "OROFLUCO"},
{label: "OROKEN"},
{label: "OROKEN ENFANTS"},
{label: "OROKEN NOURRISSONS"},
{label: "OROLANPHA"},
{label: "OROMAG"},
{label: "OROMEDINE APHTE"},
{label: "OROMONE"},
{label: "OROMYLASE"},
{label: "OROPERIDYS"},
{label: "OROPIVALONE BACITRACINE"},
{label: "OROSEPTOL LYSOZYME"},
{label: "OROSEPTOL MAUX DE GORGE"},
{label: "OROZAMUDOL"},
{label: "OROZOLIPHAX"},
{label: "ORPHACOL"},
{label: "ORPIMENT BOIRON"},
{label: "ORTHO-NOVUM 1/35"},
{label: "ORTHOCLONE OKT3"},
{label: "ORTHOGASTRINE"},
{label: "ORTHOSIPHON BOIRON"},
{label: "ORTHOSIPHON ETHYPHARM"},
{label: "ORTHOSIPHON STAMINEUS BOIRON"},
{label: "ORTREL"},
{label: "ORYLMYTE"},
{label: "OSAFLEXAN"},
{label: "OSAGIL"},
{label: "OSCILLOCOCCINUM"},
{label: "OSELTAMIVIR HEC PHARM"},
{label: "OSELTAMIVIR PG"},
{label: "OSELTAMIVIR ZENTIVA"},
{label: "OSFOLATE"},
{label: "OSIGRAFT"},
{label: "OSLIF BREEZHALER"},
{label: "OSMOGEL"},
{label: "OSMOLAX"},
{label: "OSMOTAN G"},
{label: "OSMOTOL"},
{label: "OSMUNDA REGALIS BOIRON"},
{label: "OSOMOL"},
{label: "OSPEN"},
{label: "OSPERZO"},
{label: "OSSEANS VITAMINE D3"},
{label: "OSSEINE BOIRON"},
{label: "OSSEOR"},
{label: "OSSICUM VITAMINE D3"},
{label: "OSSOPAN"},
{label: "OSTEOCAL"},
{label: "OSTEOCAL D3"},
{label: "OSTEOCALCIDIA"},
{label: "OSTEOCIS"},
{label: "OSTEOCUR"},
{label: "OSTEOCYNESINE"},
{label: "OSTEPAM"},
{label: "OSTONIC"},
{label: "OSTRAM"},
{label: "OSTRAM VITAMINE D3"},
{label: "OSVAREN"},
{label: "OTALGOL"},
{label: "OTEZLA"},
{label: "OTIPAX"},
{label: "OTOFA"},
{label: "OTOLYSINE"},
{label: "OTOMIDE"},
{label: "OTONOL"},
{label: "OTORALGYL"},
{label: "OTORALGYL A LA PHENYLEPHRINE"},
{label: "OTOWAXOL"},
{label: "OTRASEL"},
{label: "OTRIFLU"},
{label: "OTYLOL"},
{label: "OVALEAP"},
{label: "OVANON"},
{label: "OVARINUM BOIRON"},
{label: "OVARINUM WELEDA"},
{label: "OVESTIN"},
{label: "OVITRELLE"},
{label: "OVULASTEN"},
{label: "OVULES SEDO-HEMOSTATIQUES DU DOCTEUR JOUVE"},
{label: "OXACEPROL MARION MERRELL"},
{label: "OXACILLINE PANPHARMA"},
{label: "OXADILENE"},
{label: "OXALICUM ACIDUM BOIRON"},
{label: "OXALICUM ACIDUM LEHNING"},
{label: "OXALIPLATINE ACCORD"},
{label: "OXALIPLATINE ARROW"},
{label: "OXALIPLATINE ARROW LAB"},
{label: "OXALIPLATINE CHEMICAL FARMA"},
{label: "OXALIPLATINE DAKOTA PHARM"},
{label: "OXALIPLATINE EBEWE"},
{label: "OXALIPLATINE EG"},
{label: "OXALIPLATINE HIKMA"},
{label: "OXALIPLATINE HOSPIRA"},
{label: "OXALIPLATINE INTAS PHARMACEUTICALS"},
{label: "OXALIPLATINE KABI"},
{label: "OXALIPLATINE MARTINDALE PHARMA"},
{label: "OXALIPLATINE MEDAC"},
{label: "OXALIPLATINE MYLAN"},
{label: "OXALIPLATINE NIALEX"},
{label: "OXALIPLATINE NUCLEUS"},
{label: "OXALIPLATINE PLIVA"},
{label: "OXALIPLATINE RATIOPHARM"},
{label: "OXALIPLATINE SANDOZ"},
{label: "OXALIPLATINE STRAGEN"},
{label: "OXALIPLATINE SUN"},
{label: "OXALIPLATINE TEVA"},
{label: "OXALIPLATINE VIATRIS"},
{label: "OXALIPLATINE WINTHROP"},
{label: "OXAMED"},
{label: "OXAPLAT"},
{label: "OXARUTIS LP"},
{label: "OXAZEPAM"},
{label: "OXAZEPAM AYERST"},
{label: "OXBRYTA"},
{label: "OXCARBAZEPINE QUALIMED"},
{label: "OXCARBAZEPINE SANDOZ"},
{label: "OXCARBAZEPINE TEVA"},
{label: "OXCARBAZEPINE VIATRIS"},
{label: "OXEOL"},
{label: "OXERVATE"},
{label: "OXETIAN"},
{label: "OXICONAZOLE ROCHE"},
{label: "OXINATE D'INDIUM [111"},
{label: "OXIS TURBUHALER"},
{label: "OXLUMO"},
{label: "OXOGLURATE D'ORNITHINE CHIESI"},
{label: "OXOGLURATE D'ORNITHINE MYLAN GENERIQUES"},
{label: "OXOGLURATE D'ORNITHINE QUALIMED"},
{label: "OXOGLURATE DORNITHINE BIOGARAN"},
{label: "OXOGLURATE DORNITHINE MEDISOL"},
{label: "OXOGLURATE DORNITHINE MYLAN"},
{label: "OXOMEMAZINE ARROW"},
{label: "OXOMEMAZINE BIOGARAN"},
{label: "OXOMEMAZINE BIOGARAN CONSEIL"},
{label: "OXOMEMAZINE CLARIX"},
{label: "OXOMEMAZINE CRISTERS"},
{label: "OXOMEMAZINE EG"},
{label: "OXOMEMAZINE H2 PHARMA"},
{label: "OXOMEMAZINE H3 SANTE"},
{label: "OXOMEMAZINE SANDOZ"},
{label: "OXOMEMAZINE SANDOZ CONSEIL"},
{label: "OXOMEMAZINE TEVA"},
{label: "OXOMEMAZINE UPSA"},
{label: "OXOMEMAZINE VIATRIS"},
{label: "OXOMEMAZINE ZENTIVA"},
{label: "OXOMEMAZINE ZENTIVA LAB"},
{label: "OXSYNIA LP"},
{label: "OXYBATE DE SODIUM KALCEKS"},
{label: "OXYBATE DE SODIUM MYLAN"},
{label: "OXYBATE DE SODIUM REIG JOFRE"},
{label: "OXYBOLDINE"},
{label: "OXYBOLDINE CITRON SANS SUCRE"},
{label: "OXYBUTININE BMS"},
{label: "OXYBUTYNINE ACCORD"},
{label: "OXYBUTYNINE BIOGARAN"},
{label: "OXYBUTYNINE CRISTERS"},
{label: "OXYBUTYNINE EG"},
{label: "OXYBUTYNINE GENEVRIER"},
{label: "OXYBUTYNINE GNR"},
{label: "OXYBUTYNINE JANSSEN-CILAG L.P."},
{label: "OXYBUTYNINE JANSSEN-CILAG L.P.10"},
{label: "OXYBUTYNINE MILGEN"},
{label: "OXYBUTYNINE RPG"},
{label: "OXYBUTYNINE SANDOZ"},
{label: "OXYBUTYNINE TEVA"},
{label: "OXYBUTYNINE VIATRIS"},
{label: "OXYBUTYNINE ZYDUS"},
{label: "OXYCALM"},
{label: "OXYCARDIN"},
{label: "OXYCODONE ACCORD LP"},
{label: "OXYCODONE AGUETTANT"},
{label: "OXYCODONE ARROW"},
{label: "OXYCODONE BIOGARAN LP"},
{label: "OXYCODONE DELBERT"},
{label: "OXYCODONE EG LP"},
{label: "OXYCODONE ETHYPHARM LP"},
{label: "OXYCODONE KALCEKS"},
{label: "OXYCODONE MEDAC"},
{label: "OXYCODONE PHARMADYNE"},
{label: "OXYCODONE REDDY PHARMA"},
{label: "OXYCODONE RENAUDIN"},
{label: "OXYCODONE SANDOZ"},
{label: "OXYCODONE TORRENT"},
{label: "OXYCODONE VIATRIS LP"},
{label: "OXYCODONE/NALOXONE ETHYPHARM LP"},
{label: "OXYCONTIN LP"},
{label: "OXYDE MERCURIQUE JAUNE"},
{label: "OXYGENE MEDICAL AIR LIQUIDE SANTE FRANCE"},
{label: "OXYGENE MEDICAL LIQUIDE B.T.G."},
{label: "OXYGENE MEDICINAL AIR LIQUIDE ANTILLES GUYANE"},
{label: "OXYGENE MEDICINAL AIR LIQUIDE REUNION"},
{label: "OXYGENE MEDICINAL AIR LIQUIDE SANTE FRANCE"},
{label: "OXYGENE MEDICINAL AIR PRODUCTS MEDICAL"},
{label: "OXYGENE MEDICINAL B.T.G."},
{label: "OXYGENE MEDICINAL LABODAL"},
{label: "OXYGENE MEDICINAL LINDE HEALTHCARE"},
{label: "OXYGENE MEDICINAL LIQUIDE AIR LIQUIDE ANTILLES GUYANE"},
{label: "OXYGENE MEDICINAL LIQUIDE AIR LIQUIDE REUNION"},
{label: "OXYGENE MEDICINAL LIQUIDE AIR LIQUIDE SANTE FRANCE"},
{label: "OXYGENE MEDICINAL LIQUIDE AIR PRODUCTS MEDICAL"},
{label: "OXYGENE MEDICINAL LIQUIDE LINDE HEALTHCARE"},
{label: "OXYGENE MEDICINAL LIQUIDE MESSER"},
{label: "OXYGENE MEDICINAL LIQUIDE MESSER FRANCE"},
{label: "OXYGENE MEDICINAL LIQUIDE OXYCURE"},
{label: "OXYGENE MEDICINAL LIQUIDE S.O.L."},
{label: "OXYGENE MEDICINAL LIQUIDE SAGA MEDICAL"},
{label: "OXYGENE MEDICINAL LIQUIDE SOGAL"},
{label: "OXYGENE MEDICINAL LIQUIDE SOGIG"},
{label: "OXYGENE MEDICINAL LIQUIDE SOL FRANCE"},
{label: "OXYGENE MEDICINAL MESSER"},
{label: "OXYGENE MEDICINAL MESSER FRANCE"},
{label: "OXYGENE MEDICINAL OXYCURE"},
{label: "OXYGENE MEDICINAL SAGA MEDICAL"},
{label: "OXYGENE MEDICINAL SOGAL"},
{label: "OXYGENE MEDICINAL SOGIG"},
{label: "OXYGENE MEDICINAL SOL FRANCE"},
{label: "OXYMETAZOLINE BAYER"},
{label: "OXYMETAZOLINE URGO"},
{label: "OXYNORM"},
{label: "OXYNORMORO"},
{label: "OXYNOX"},
{label: "OXYPEROL"},
{label: "OXYPHAR"},
{label: "OXYPLASTINE"},
{label: "OXYPRONAL LP"},
{label: "OXYQUALES LP"},
{label: "OXYTETRACYCLINE BERAL"},
{label: "OXYTHYOL"},
{label: "OXYTOCINE EVER PHARMA"},
{label: "OXYTOCINE GRINDEKS"},
{label: "OXYTOCINE MEDISOL"},
{label: "OXYTOCINE PANPHARMA"},
{label: "OYAVAS"},
{label: "OZALIN"},
{label: "OZAWADE"},
{label: "OZEMPIC"},
{label: "OZIDIA"},
{label: "OZOLEMMA"},
{label: "OZOTHINE"},
{label: "OZOTHINE A LA DIPROPHYLLINE"},
{label: "OZOTHINE A LA DIPROPHYLLINE ADULTES"},
{label: "OZOTHINE A LA DIPROPHYLLINE ENFANTS"},
{label: "OZOTHINE ADULTES"},
{label: "OZOTHINE ENFANTS"},
{label: "OZOTHINE NOURRISSONS"},
{label: "OZURDEX"},
{label: "P-TOLUENEDIAMINE SMARTPRACTICE"},
{label: "P-VEINOS"},
{label: "PABAL"},
{label: "PABASUN"},
{label: "PACILIA"},
{label: "PACLIMED"},
{label: "PACLITAXEL AHCL"},
{label: "PACLITAXEL ARROW"},
{label: "PACLITAXEL BRISTOL-MYERS SQUIBB"},
{label: "PACLITAXEL CHEMICAL FARMA"},
{label: "PACLITAXEL CIPLA"},
{label: "PACLITAXEL CTRS"},
{label: "PACLITAXEL EG"},
{label: "PACLITAXEL HOSPIRA"},
{label: "PACLITAXEL KABI"},
{label: "PACLITAXEL MYLAN"},
{label: "PACLITAXEL RATIOPHARM"},
{label: "PACLITAXEL SANDOZ"},
{label: "PACLITAXEL SIGMA TAU"},
{label: "PACLITAXEL TEVA"},
{label: "PACLITAXEL VIATRIS"},
{label: "PACLITAXEL WINTHROP"},
{label: "PACLITAXELSANDOZ"},
{label: "PACLIVIS"},
{label: "PACQFOR"},
{label: "PADCEV"},
{label: "PADERYL"},
{label: "PAEONIA OFFICINALIS BOIRON"},
{label: "PAEONIA OFFICINALIS LEHNING"},
{label: "PAIN RAMET ACIDE"},
{label: "PALANDRA"},
{label: "PALEXIA"},
{label: "PALEXIA LP"},
{label: "PALFIUM"},
{label: "PALFORZIA"},
{label: "PALIPERIDONE BIOGARAN"},
{label: "PALIPERIDONE JANSSEN"},
{label: "PALIPERIDONE TEVA"},
{label: "PALISON"},
{label: "PALIURUS ACULEATUS BOIRON"},
{label: "PALIURYL"},
{label: "PALLADIUM METALLICUM BOIRON"},
{label: "PALLADIUM METALLICUM LEHNING"},
{label: "PALLADONE L.P."},
{label: "PALMI"},
{label: "PALMIER DE FLORIDE MYLAN"},
{label: "PALMIER DE FLORIDE PHARMATOKA"},
{label: "PALMIER DE FLORIDE PHYTOCON"},
{label: "PALONOSETRON ACCORD"},
{label: "PALONOSETRON KALCEKS"},
{label: "PALPIPAX"},
{label: "PALUDRINE"},
{label: "PALYNZIQ"},
{label: "PAMEZOL"},
{label: "PAMIDRONATE ACTAVIS"},
{label: "PAMIDRONATE DE SODIUM AGUETTANT"},
{label: "PAMIDRONATE DE SODIUM HOSPIRA"},
{label: "PAMIDRONATE DE SODIUM MERCK"},
{label: "PAMIDRONATE DE SODIUM MYLAN"},
{label: "PAMIDRONATE DE SODIUM NOVARTIS PHARMA"},
{label: "PAMIDRONATE DE SODIUM RATIOPHARM"},
{label: "PAMIDRONATE DE SODIUM TEVA"},
{label: "PAMINJECT"},
{label: "PANADOL"},
{label: "PANADOL CAFEINE"},
{label: "PANADOL CODEINE EFFERVESCENT"},
{label: "PANADOL SANS SUCRE"},
{label: "PANAKO"},
{label: "PANAX GINSENG DECOCTE WELEDA"},
{label: "PANCREAL KIRCHNER"},
{label: "PANCREINE BOIRON"},
{label: "PANCREINE WELEDA"},
{label: "PANCRELASE"},
{label: "PANCRISTOLE"},
{label: "PANCURONIUM FAULDING"},
{label: "PANDEMRIX"},
{label: "PANENZA"},
{label: "PANFUREX"},
{label: "PANFURYL"},
{label: "PANLIPAL"},
{label: "PANNOGEL"},
{label: "PANOS"},
{label: "PANOTILE"},
{label: "PANOXYL"},
{label: "PANRETIN"},
{label: "PANSEMENT CORICIDE POUR CORS"},
{label: "PANSORAL"},
{label: "PANTEC"},
{label: "PANTESTONE"},
{label: "PANTIPP"},
{label: "PANTODOS"},
{label: "PANTOGULDEN"},
{label: "PANTOLOC CONTROL"},
{label: "PANTONYCO"},
{label: "PANTONYCOMED"},
{label: "PANTOPRAZOLE ACCORD"},
{label: "PANTOPRAZOLE ACILIB"},
{label: "PANTOPRAZOLE ACTAVIS FRANCE"},
{label: "PANTOPRAZOLE ALMUS"},
{label: "PANTOPRAZOLE ALTER"},
{label: "PANTOPRAZOLE ALTISO"},
{label: "PANTOPRAZOLE ARROW"},
{label: "PANTOPRAZOLE ARROW CONSEIL"},
{label: "PANTOPRAZOLE ARROW GENERIQUES"},
{label: "PANTOPRAZOLE ARROW LAB"},
{label: "PANTOPRAZOLE BGR"},
{label: "PANTOPRAZOLE BIOGARAN"},
{label: "PANTOPRAZOLE BLUEFISH"},
{label: "PANTOPRAZOLE BOUCHARA-RECORDATI"},
{label: "PANTOPRAZOLE CRISTERS"},
{label: "PANTOPRAZOLE CRISTERS PHARMA"},
{label: "PANTOPRAZOLE EG"},
{label: "PANTOPRAZOLE EG LABO"},
{label: "PANTOPRAZOLE EVOLUGEN"},
{label: "PANTOPRAZOLE HIKMA"},
{label: "PANTOPRAZOLE INTERDOS PHARMA"},
{label: "PANTOPRAZOLE ISOMED"},
{label: "PANTOPRAZOLE ISOMED CONSEIL"},
{label: "PANTOPRAZOLE KALCEKS"},
{label: "PANTOPRAZOLE KRKA"},
{label: "PANTOPRAZOLE MARTINDALE PHARMA"},
{label: "PANTOPRAZOLE MAYOLY SPINDLER"},
{label: "PANTOPRAZOLE MEDA PHARMA"},
{label: "PANTOPRAZOLE MYLAN"},
{label: "PANTOPRAZOLE MYLAN CONSEIL"},
{label: "PANTOPRAZOLE MYLAN GENERIQUES"},
{label: "PANTOPRAZOLE MYLAN PHARMA"},
{label: "PANTOPRAZOLE NYCOMED"},
{label: "PANTOPRAZOLE PHR LAB"},
{label: "PANTOPRAZOLE QUALIHEALTH"},
{label: "PANTOPRAZOLE QUALIMED"},
{label: "PANTOPRAZOLE QUALIMED GENERIQUES"},
{label: "PANTOPRAZOLE RATIOPHARM"},
{label: "PANTOPRAZOLE RATIOPHARM CONSEIL"},
{label: "PANTOPRAZOLE REIG JOFRE"},
{label: "PANTOPRAZOLE RPG"},
{label: "PANTOPRAZOLE RPN INNOVATION"},
{label: "PANTOPRAZOLE SANDOZ"},
{label: "PANTOPRAZOLE SANDOZ CONSEIL"},
{label: "PANTOPRAZOLE SUN"},
{label: "PANTOPRAZOLE SUN PHARMA"},
{label: "PANTOPRAZOLE SUN PHARMA CONSEIL"},
{label: "PANTOPRAZOLE TECNIMEDE - SOCIEDADE TECNICO-MEDICINAL"},
{label: "PANTOPRAZOLE TEVA"},
{label: "PANTOPRAZOLE TEVA CONSEIL"},
{label: "PANTOPRAZOLE TEVA SANTE"},
{label: "PANTOPRAZOLE TILLOMED"},
{label: "PANTOPRAZOLE VIATRIS"},
{label: "PANTOPRAZOLE VIATRIS CONSEIL"},
{label: "PANTOPRAZOLE ZENTIVA"},
{label: "PANTOPRAZOLE ZF"},
{label: "PANTOPRAZOLE ZYDUS"},
{label: "PANTOPRAZOLE ZYDUS FRANCE"},
{label: "PANTOZOL CONTROL"},
{label: "PANXEOL"},
{label: "PANZYGA"},
{label: "PAOSONELLE"},
{label: "PAPAINE TROUETTE PERRET"},
{label: "PAPAVER RHOEAS BOIRON"},
{label: "PAPAVERINE CHLORHYDRATE AGUETTANT"},
{label: "PAPAVERINE RENAUDIN"},
{label: "PAPAVERINE SERB"},
{label: "PAPCLAIR"},
{label: "PAPS"},
{label: "PAR"},
{label: "PARA PLUS"},
{label: "PARA SPECIAL POUX"},
{label: "PARACAMF"},
{label: "PARACETAMOL"},
{label: "PARACETAMOL ACCORD"},
{label: "PARACETAMOL ACCORD HEALTHCARE"},
{label: "PARACETAMOL ACTAVIS"},
{label: "PARACETAMOL ADARE"},
{label: "PARACETAMOL AGUETTANT"},
{label: "PARACETAMOL AHCL"},
{label: "PARACETAMOL ALMUS"},
{label: "PARACETAMOL ALMUS PHARMA"},
{label: "PARACETAMOL ALTER"},
{label: "PARACETAMOL ARROW"},
{label: "PARACETAMOL ARROW CONSEIL"},
{label: "PARACETAMOL ARROW LAB"},
{label: "PARACETAMOL B. BRAUN"},
{label: "PARACETAMOL BAILLY-CREAT"},
{label: "PARACETAMOL BANNER"},
{label: "PARACETAMOL BAXTER"},
{label: "PARACETAMOL BIOFIDES"},
{label: "PARACETAMOL BIOGALENIQUE"},
{label: "PARACETAMOL BIOGARAN"},
{label: "PARACETAMOL BIOGARAN CONSEIL"},
{label: "PARACETAMOL BMS"},
{label: "PARACETAMOL CAFEINE GALPHARM"},
{label: "PARACETAMOL CAFEINE THERAPLIX"},
{label: "PARACETAMOL CEHEL PHARMA"},
{label: "PARACETAMOL CEPHALON"},
{label: "PARACETAMOL CHLORPHENAMINE VITAMINE C CLARIX"},
{label: "PARACETAMOL CLL PHARMA"},
{label: "PARACETAMOL CODEINE ARROW"},
{label: "PARACETAMOL CODEINE BIOGALENIQUE"},
{label: "PARACETAMOL CODEINE BIOGARAN"},
{label: "PARACETAMOL CODEINE BMS"},
{label: "PARACETAMOL CODEINE BRISTOL LABORATORIES"},
{label: "PARACETAMOL CODEINE BRISTOL-MYERS SQUIBB"},
{label: "PARACETAMOL CODEINE CRISTERS"},
{label: "PARACETAMOL CODEINE EG"},
{label: "PARACETAMOL CODEINE G GAM"},
{label: "PARACETAMOL CODEINE GNR"},
{label: "PARACETAMOL CODEINE IVAX"},
{label: "PARACETAMOL CODEINE MYLAN"},
{label: "PARACETAMOL CODEINE REF"},
{label: "PARACETAMOL CODEINE SANDOZ"},
{label: "PARACETAMOL CODEINE TEVA"},
{label: "PARACETAMOL CODEINE THERAPLIX"},
{label: "PARACETAMOL CODEINE U.P.S.A."},
{label: "PARACETAMOL CODEINE UNITHER"},
{label: "PARACETAMOL CRISTERS"},
{label: "PARACETAMOL CRISTERS PHARMA"},
{label: "PARACETAMOL CUVE"},
{label: "PARACETAMOL DAKOTA PHARM"},
{label: "PARACETAMOL DISPHAR"},
{label: "PARACETAMOL E PHARMA"},
{label: "PARACETAMOL EFFERVESCENT UPSA"},
{label: "PARACETAMOL EG"},
{label: "PARACETAMOL EG LABO - LABORATOIRES EUROGENERICS"},
{label: "PARACETAMOL EG LABO CONSEIL"},
{label: "PARACETAMOL ETHYPHARM"},
{label: "PARACETAMOL EUROGALENA"},
{label: "PARACETAMOL EVOLUGEN"},
{label: "PARACETAMOL FARMALID"},
{label: "PARACETAMOL FARMALIDER"},
{label: "PARACETAMOL FOURNIER"},
{label: "PARACETAMOL G GAM"},
{label: "PARACETAMOL GATTEFOSSE"},
{label: "PARACETAMOL GENMED"},
{label: "PARACETAMOL GENODEX"},
{label: "PARACETAMOL GNR"},
{label: "PARACETAMOL GRUNENTHAL"},
{label: "PARACETAMOL HOSPIRA"},
{label: "PARACETAMOL IPRAD"},
{label: "PARACETAMOL ISOMED"},
{label: "PARACETAMOL ISOMED SANTE"},
{label: "PARACETAMOL KABI"},
{label: "PARACETAMOL KRKA"},
{label: "PARACETAMOL LABORATOIRES SMB"},
{label: "PARACETAMOL LYOC"},
{label: "PARACETAMOL MACOSOL"},
{label: "PARACETAMOL MAZAL"},
{label: "PARACETAMOL MEDIPHA"},
{label: "PARACETAMOL MONOT ENFANTS"},
{label: "PARACETAMOL MONOT NOURRISSONS"},
{label: "PARACETAMOL MYLAN"},
{label: "PARACETAMOL MYLAN PHARMA"},
{label: "PARACETAMOL NEOGEN"},
{label: "PARACETAMOL NEOGENUS"},
{label: "PARACETAMOL NEPENTHES"},
{label: "PARACETAMOL NOR"},
{label: "PARACETAMOL NORDIC"},
{label: "PARACETAMOL NORIDEM"},
{label: "PARACETAMOL OFFILINK"},
{label: "PARACETAMOL PANPHARMA"},
{label: "PARACETAMOL PROGRAPHARM"},
{label: "PARACETAMOL PSEUDOEPHEDRINE VITAMINE C GLAXOSMITHKLINE SANTE GRAND PUBLIC"},
{label: "PARACETAMOL QUALIMED"},
{label: "PARACETAMOL RANBAXY"},
{label: "PARACETAMOL RANBAXY MEDICATION OFFICINALE"},
{label: "PARACETAMOL RATIO"},
{label: "PARACETAMOL RATIOPHARM"},
{label: "PARACETAMOL RENAUDIN"},
{label: "PARACETAMOL RPG"},
{label: "PARACETAMOL SANDOZ"},
{label: "PARACETAMOL SANDOZ CONSEIL"},
{label: "PARACETAMOL SANOFI-SYNTHELABO OTC"},
{label: "PARACETAMOL SET"},
{label: "PARACETAMOL SMITHKLINE BEECHAM ENFANT ET NOURRISSON"},
{label: "PARACETAMOL SUBSTI-LABO"},
{label: "PARACETAMOL SUBSTILAB"},
{label: "PARACETAMOL SUBSTIPHARM"},
{label: "PARACETAMOL SUN"},
{label: "PARACETAMOL TEVA"},
{label: "PARACETAMOL TEVA CONSEIL"},
{label: "PARACETAMOL TEVA PHARMA"},
{label: "PARACETAMOL TEVA SANTE"},
{label: "PARACETAMOL THERAPLIX"},
{label: "PARACETAMOL TORRENT"},
{label: "PARACETAMOL UNITHER PHARMACEUTICALS"},
{label: "PARACETAMOL UPSA"},
{label: "PARACETAMOL VEYRON FROMENT"},
{label: "PARACETAMOL VIAFLO"},
{label: "PARACETAMOL VIATRIS"},
{label: "PARACETAMOL VIATRIS CONSEIL"},
{label: "PARACETAMOL VITAMINE C RPG"},
{label: "PARACETAMOL VJ-PHARM"},
{label: "PARACETAMOL WINTHROP"},
{label: "PARACETAMOL ZENTIVA"},
{label: "PARACETAMOL ZENTIVA CONSEIL"},
{label: "PARACETAMOL ZENTIVA K.S."},
{label: "PARACETAMOL ZENTIVA LAB"},
{label: "PARACETAMOL ZYDUS"},
{label: "PARACETAMOL-CHLORPHENAMINE-PHENYLPROPANOLAMINE GNR"},
{label: "PARACETAMOL/CAFEINE CHANELLE"},
{label: "PARACETAMOL/CAFEINE PHARMACLAN"},
{label: "PARACETAMOL/CAFEINE/CODEINE ARROW"},
{label: "PARACETAMOL/CAFEINE/CODEINE BIOGARAN"},
{label: "PARACETAMOL/CAFEINE/CODEINE VIATRIS"},
{label: "PARACETAMOL/CHLORPHENAMINE URGO"},
{label: "PARACETAMOL/CINEOLE MONOT"},
{label: "PARACETAMOL/CODEINE ACCORD"},
{label: "PARACETAMOL/CODEINE ALTISO"},
{label: "PARACETAMOL/CODEINE BIOGARAN"},
{label: "PARACETAMOL/CODEINE PIERRE FABRE"},
{label: "PARACETAMOL/CODEINE TEVA"},
{label: "PARACETAMOL/CODEINE VIATRIS"},
{label: "PARACETAMOL/POUDRE D'OPIUM MYLAN"},
{label: "PARACETAMOL/VITAMINE C /PHENIRAMINE IBD3"},
{label: "PARACETAMOL/VITAMINE C/PHENIRAMINE BMS ADULTES SANS SUCRE"},
{label: "PARACETAMOL/VITAMINE C/PHENIRAMINE BYMEDIS PHARMA"},
{label: "PARACETAMOL/VITAMINE C/PHENIRAMINE IBD3 PHARMA CONSULTING"},
{label: "PARACETAMOL/VITAMINE C/PHENIRAMINE IBD3 PHARMA CONSULTING SANS SUCRE"},
{label: "PARACETAMOL/VITAMINE C/PHENIRAMINE MYLAN CONSEIL"},
{label: "PARACETAMOL/VITAMINE C/PHENIRAMINE SANDOZ CONSEIL"},
{label: "PARACETAMOL/VITAMINE C/PHENIRAMINE UPSA ADULTES SANS SUCRE"},
{label: "PARACETAMOL/VITAMINE C/PHENIRAMINE URGO"},
{label: "PARACETAMOL/VITAMINE C/PHENIRAMINE VIATRIS CONSEIL SANS SUCRE"},
{label: "PARACETAMOL/VITAMINE C/PSEUDOEPHEDRINE UPSA"},
{label: "PARADENTOSE FLUID"},
{label: "PARADENTOSE PASTE"},
{label: "PARAGRIPPE"},
{label: "PARALYOC"},
{label: "PARAMINAN"},
{label: "PARAPLATINE"},
{label: "PARAPSYLLIUM"},
{label: "PARASIDOSE"},
{label: "PARATABS"},
{label: "PARATADOL"},
{label: "PARATERAX"},
{label: "PARATHYROIDINUM BOIRON"},
{label: "PARATHYROIDINUM WELEDA"},
{label: "PARATYPHOIDINUM B BOIRON"},
{label: "PARDOXILE"},
{label: "PAREGORINE"},
{label: "PAREGORIQUE LAFRAN"},
{label: "PAREIRA BRAVA BOIRON"},
{label: "PARFENAC"},
{label: "PARFENOIDE"},
{label: "PARGINE"},
{label: "PARIET"},
{label: "PARIETARIA OFFICINALIS BOIRON"},
{label: "PARIS QUADRIFOLIA BOIRON"},
{label: "PARIS QUADRIFOLIA LEHNING"},
{label: "PARKAZOLE ADULTES"},
{label: "PARKAZOLE ENFANTS"},
{label: "PARKAZOLE FORT"},
{label: "PARKAZOLE NOURRISSONS ET ENFANTS"},
{label: "PARKINANE L.P."},
{label: "PARKINANE LP"},
{label: "PARKIPAN"},
{label: "PARLAX"},
{label: "PARLAX COMPOSE"},
{label: "PARLODEL"},
{label: "PAROCLINE"},
{label: "PARODONMED"},
{label: "PAROEX"},
{label: "PAROTIDINUM BOIRON"},
{label: "PAROXETINE ALMUS"},
{label: "PAROXETINE ALTER"},
{label: "PAROXETINE ARROW"},
{label: "PAROXETINE BIOGARAN"},
{label: "PAROXETINE BLUEPHARMA"},
{label: "PAROXETINE CRISTERS"},
{label: "PAROXETINE DCI PHARMA"},
{label: "PAROXETINE EG"},
{label: "PAROXETINE EVOLUGEN"},
{label: "PAROXETINE G GAM"},
{label: "PAROXETINE GSK"},
{label: "PAROXETINE ISOMED"},
{label: "PAROXETINE KIRON"},
{label: "PAROXETINE MYLAN PHARMA"},
{label: "PAROXETINE PAUCOURT"},
{label: "PAROXETINE PFIZER"},
{label: "PAROXETINE PHR LAB"},
{label: "PAROXETINE QUALIHEALTH"},
{label: "PAROXETINE QUALIMED"},
{label: "PAROXETINE RATIOPHARM"},
{label: "PAROXETINE RPG"},
{label: "PAROXETINE SAINT-GERMAIN"},
{label: "PAROXETINE SANDOZ"},
{label: "PAROXETINE SUN"},
{label: "PAROXETINE TEVA"},
{label: "PAROXETINE TEVA SANTE"},
{label: "PAROXETINE TORRENT"},
{label: "PAROXETINE VIATRIS"},
{label: "PAROXETINE ZENTIVA"},
{label: "PAROXETINE ZYDUS"},
{label: "PARSABIV"},
{label: "PARTUROL L.P."},
{label: "PASSEDYL ENFANTS ET NOURRISSONS"},
{label: "PASSIFLORA COMPLEXE N°57"},
{label: "PASSIFLORA COMPOSE"},
{label: "PASSIFLORA INCARNATA BOIRON"},
{label: "PASSIFLORA INCARNATA FERRIER"},
{label: "PASSIFLORA INCARNATA LEHNING"},
{label: "PASSIFLORA INCARNATA TEINTURE MERE BOIRON"},
{label: "PASSIFLORA INCARNATA WELEDA"},
{label: "PASSIFLORE BOIRON"},
{label: "PASSIFLORE ETHYPHARM"},
{label: "PASSIFLORE EXTRAIT VITAFLOR"},
{label: "PASSIFLORE INFUSETTE LES BONS PRODUCTEURS"},
{label: "PASSIFLORE NATURA MEDICA"},
{label: "PASSIFLORE PARTIES AERIENNES VITAFLOR"},
{label: "PASSIFLORE VALERIANE ARKOMEDIKA"},
{label: "PASSIFLORINE"},
{label: "PASSINEVRYL"},
{label: "PASSIONAL"},
{label: "PAST AIL"},
{label: "PASTILLES MBC"},
{label: "PASTILLES MEDICINALES VICKS MENTHOL EUCALYPTUS"},
{label: "PASTILLES MEDICINALES VICKS MENTHOL EUCALYPTUS CITRON"},
{label: "PASTILLES MONLEON"},
{label: "PASTILLES RICHELET"},
{label: "PASTILLES SALMON"},
{label: "PASTISEROL"},
{label: "PAT"},
{label: "PATE A L'EAU LA ROCHE POSAY"},
{label: "PATE SUISSE"},
{label: "PATENTEX"},
{label: "PATES BALSAMIQUES BOUCHARA RECORDATI"},
{label: "PATES BAUDRY"},
{label: "PATES DE REGLISSE AU SAMBUCUS"},
{label: "PATES PECTORALES LHF"},
{label: "PATES PECTORALES OBERLIN"},
{label: "PATREX"},
{label: "PAVTIDE DISKUS"},
{label: "PAVULON"},
{label: "PAXELADINE"},
{label: "PAXELADINE NOCTEE"},
{label: "PAXENE"},
{label: "PAXISTOL"},
{label: "PAXLOVID"},
{label: "PAXOPILE"},
{label: "PAYNOCIL"},
{label: "PAZENIR"},
{label: "PAZOPANIB BIOGARAN"},
{label: "PAZOPANIB STADA"},
{label: "PEAU BOIRON"},
{label: "PEBARON"},
{label: "PECFENT"},
{label: "PECTIPAR"},
{label: "PECTO-BRONCOL ADULTES"},
{label: "PECTO-BRONCOL ENFANTS"},
{label: "PECTO-BRONCOL NOURRISSONS"},
{label: "PECTOBRONCOL ENFANTS"},
{label: "PECTOCALM ADULTES SANS SUCRE"},
{label: "PECTOCALM ENFANTS SANS SUCRE"},
{label: "PECTOCINAL BISMUTHE ADULTES"},
{label: "PECTODERME"},
{label: "PECTOFLORINE"},
{label: "PECTOIDS"},
{label: "PECTOLEM"},
{label: "PECTOMUCIL"},
{label: "PECTORAL RICHELET SANS SUCRE"},
{label: "PECTOSAN ADULTES"},
{label: "PECTOTUSSYL"},
{label: "PEDEA"},
{label: "PEDI FONGIC"},
{label: "PEDIACEL"},
{label: "PEDIAVEN AP-HP G15"},
{label: "PEDIAVEN AP-HP G20"},
{label: "PEDIAVEN AP-HP G25"},
{label: "PEDIAVEN AP-HP NOUVEAU-NE"},
{label: "PEDIAVEN AP-HP NOUVEAU-NE SANS OLIGO-ELEMENTS"},
{label: "PEDIAZOLE"},
{label: "PEDIFONGIC"},
{label: "PEDIMYCOSE"},
{label: "PEDITRACE"},
{label: "PEFLACINE"},
{label: "PEFLACINE MONODOSE"},
{label: "PEGA ADULTES"},
{label: "PEGA ENFANTS"},
{label: "PEGASYS"},
{label: "PEGINTRON"},
{label: "PELARGONIUM ODORATISSIMUM BOIRON"},
{label: "PELARGONIUM PHYTOCLARIX"},
{label: "PELGRAZ"},
{label: "PELMEG"},
{label: "PELZONT"},
{label: "PEMAZYRE"},
{label: "PEMETREXED ACCORD"},
{label: "PEMETREXED ARROW"},
{label: "PEMETREXED BIOORGANICS"},
{label: "PEMETREXED EG"},
{label: "PEMETREXED EVER PHARMA"},
{label: "PEMETREXED FRESENIUS KABI"},
{label: "PEMETREXED GNR"},
{label: "PEMETREXED HOSPIRA"},
{label: "PEMETREXED KRKA"},
{label: "PEMETREXED LILLY"},
{label: "PEMETREXED MEDAC"},
{label: "PEMETREXED MEDIPHA"},
{label: "PEMETREXED MYLAN PHARMA"},
{label: "PEMETREXED PFIZER"},
{label: "PEMETREXED PHARMAKI GENERICS"},
{label: "PEMETREXED REDDY PHARMA"},
{label: "PEMETREXED SANDOZ"},
{label: "PEMETREXED STRAGEN"},
{label: "PEMETREXED SUN"},
{label: "PEMETREXED TEVA"},
{label: "PEMETREXED VIATRIS"},
{label: "PEMETREXED ZENTIVA"},
{label: "PENGLOBE"},
{label: "PENICILLINE DIAMANT"},
{label: "PENICILLINE G"},
{label: "PENICILLINE G PANPHARMA"},
{label: "PENICILLINE G POTASSIQUE SOLVAY PHARMA"},
{label: "PENICILLINE G SARBACH"},
{label: "PENICILLINE G SARBACH 10.000.000 UI"},
{label: "PENICILLINUM BOIRON"},
{label: "PENICILLIUM CANDIDUM BOIRON"},
{label: "PENICILLIUM NOTATUM BOIRON"},
{label: "PENSEE SAUVAGE INFUSETTE LES BONS PRODUCTEURS"},
{label: "PENT-"},
{label: "PENTACARINAT"},
{label: "PENTACIS"},
{label: "PENTACOQ"},
{label: "PENTAMIDINE TILLOMED"},
{label: "PENTASA"},
{label: "PENTASTARCH"},
{label: "PENTAVAC"},
{label: "PENTAXIM"},
{label: "PENTETATE D'INDIUM [111"},
{label: "PENTHROX"},
{label: "PENTICORT"},
{label: "PENTOFLUX LP"},
{label: "PENTOTHAL"},
{label: "PENTOTHAL KIT"},
{label: "PENTOXIFYLLINE APOTEX FRANCE LP"},
{label: "PENTOXIFYLLINE APOTEX LP"},
{label: "PENTOXIFYLLINE BIOGARAN LP"},
{label: "PENTOXIFYLLINE EG LP"},
{label: "PENTOXIFYLLINE MYLAN LP"},
{label: "PENTOXIFYLLINE QUALIMED LP"},
{label: "PENTOXIFYLLINE RATIOPHARM LP"},
{label: "PENTOXIFYLLINE RPG L.P."},
{label: "PENTOXIFYLLINE SANDOZ LP"},
{label: "PENTOXIFYLLINE SET LP"},
{label: "PENTOXIFYLLINE TEVA L.P."},
{label: "PENTOXIFYLLINE TEVA SANTE L.P."},
{label: "PENTOXYVERINE CLARIX"},
{label: "PENTOXYVERINE THERAPLIX"},
{label: "PENTRYL"},
{label: "PEPCIDAC"},
{label: "PEPCIDDUO"},
{label: "PEPDINE"},
{label: "PEPDINE IV"},
{label: "PEPSANE"},
{label: "PEPSOVAL"},
{label: "PEPTAVLON"},
{label: "PERABACTICEL"},
{label: "PERACEL"},
{label: "PERCLUSONE"},
{label: "PERCUTAFEINE"},
{label: "PERCUTALGINE"},
{label: "PERCUTALGINE SPRAY"},
{label: "PEREFLAT"},
{label: "PERENAN"},
{label: "PERFALGAN"},
{label: "PERFALGAN NOURRISSONS ET ENFANTS"},
{label: "PERFANE"},
{label: "PERFOLATE"},
{label: "PERFUSAN B"},
{label: "PERGOGREEN"},
{label: "PERGOLIDE EG"},
{label: "PERGOLIDE TEVA"},
{label: "PERGOTIME"},
{label: "PERGOVERIS"},
{label: "PERGOVERIS (300 UI +"},
{label: "PERGOVERIS (450 UI +"},
{label: "PERGOVERIS (900 UI +"},
{label: "PERIACTINE"},
{label: "PERICAL"},
{label: "PERIDAMOL"},
{label: "PERIDYS"},
{label: "PERIFAZO"},
{label: "PERIKABIVEN"},
{label: "PERINDOPRI/INDAPAMIDE KRKA"},
{label: "PERINDOPRIL ACTAVIS"},
{label: "PERINDOPRIL ARGININE BIOGARAN"},
{label: "PERINDOPRIL ARGININE EG"},
{label: "PERINDOPRIL ARGININE HCS"},
{label: "PERINDOPRIL ARGININE QUALIMED"},
{label: "PERINDOPRIL ARGININE SERVIER"},
{label: "PERINDOPRIL ARGININE VIATRIS"},
{label: "PERINDOPRIL ARGININE ZENTIVA"},
{label: "PERINDOPRIL ARGININE/AMLODIPINE BGR"},
{label: "PERINDOPRIL ARGININE/AMLODIPINE BIOGARAN"},
{label: "PERINDOPRIL ARGININE/AMLODIPINE HCS"},
{label: "PERINDOPRIL ARGININE/AMLODIPINE SERVIER"},
{label: "PERINDOPRIL ARGININE/INDAPAMIDE BIOGARAN"},
{label: "PERINDOPRIL ARGININE/INDAPAMIDE HCS"},
{label: "PERINDOPRIL ARGININE/INDAPAMIDE QUALIMED"},
{label: "PERINDOPRIL ARGININE/INDAPAMIDE SERVIER"},
{label: "PERINDOPRIL ARGININE/INDAPAMIDE VIATRIS"},
{label: "PERINDOPRIL ARROW"},
{label: "PERINDOPRIL ARROW GENERIQUES"},
{label: "PERINDOPRIL ARROW LAB"},
{label: "PERINDOPRIL BIOGARAN"},
{label: "PERINDOPRIL CRISTERS"},
{label: "PERINDOPRIL DCI PHARMA"},
{label: "PERINDOPRIL EG"},
{label: "PERINDOPRIL EVOLUGEN"},
{label: "PERINDOPRIL GENERES"},
{label: "PERINDOPRIL INTERNATIONAL DRUG DEVELOPMENT"},
{label: "PERINDOPRIL ISOMED"},
{label: "PERINDOPRIL KRKA"},
{label: "PERINDOPRIL MYLAN"},
{label: "PERINDOPRIL MYLAN PHARMA"},
{label: "PERINDOPRIL PFIZER"},
{label: "PERINDOPRIL PHR LAB"},
{label: "PERINDOPRIL RATIOPHARM"},
{label: "PERINDOPRIL SANDOZ"},
{label: "PERINDOPRIL TERT-BUTYLAMINE/AMLODIPINE ARROW"},
{label: "PERINDOPRIL TERT-BUTYLAMINE/AMLODIPINE CRISTERS"},
{label: "PERINDOPRIL TERT-BUTYLAMINE/AMLODIPINE EG"},
{label: "PERINDOPRIL TERT-BUTYLAMINE/AMLODIPINE MYLAN"},
{label: "PERINDOPRIL TERT-BUTYLAMINE/AMLODIPINE PHARMAKI"},
{label: "PERINDOPRIL TEVA SANTE"},
{label: "PERINDOPRIL TOSILATE CRISTERS"},
{label: "PERINDOPRIL TOSILATE PHARMAKI GENERICS"},
{label: "PERINDOPRIL TOSILATE TEVA"},
{label: "PERINDOPRIL TOSILATE/INDAPAMIDE TEVA"},
{label: "PERINDOPRIL VENIPHARM"},
{label: "PERINDOPRIL VIATRIS"},
{label: "PERINDOPRIL ZENTIVA"},
{label: "PERINDOPRIL ZYDUS"},
{label: "PERINDOPRIL/AMLODIPINE HCS"},
{label: "PERINDOPRIL/AMLODIPINE KRKA"},
{label: "PERINDOPRIL/AMLODIPINE ZENTIVA"},
{label: "PERINDOPRIL/INDAPAMIDE ARROW"},
{label: "PERINDOPRIL/INDAPAMIDE ARROW LAB"},
{label: "PERINDOPRIL/INDAPAMIDE BIOGARAN"},
{label: "PERINDOPRIL/INDAPAMIDE EG"},
{label: "PERINDOPRIL/INDAPAMIDE EG LABO"},
{label: "PERINDOPRIL/INDAPAMIDE GLENMARK GENERICS"},
{label: "PERINDOPRIL/INDAPAMIDE ISOMED"},
{label: "PERINDOPRIL/INDAPAMIDE KRKA"},
{label: "PERINDOPRIL/INDAPAMIDE MYLAN PHARMA"},
{label: "PERINDOPRIL/INDAPAMIDE QUALIMED"},
{label: "PERINDOPRIL/INDAPAMIDE RATIOPHARM"},
{label: "PERINDOPRIL/INDAPAMIDE SANDOZ"},
{label: "PERINDOPRIL/INDAPAMIDE TEVA"},
{label: "PERINDOPRIL/INDAPAMIDE VIATRIS"},
{label: "PERINDOPRIL/INDAPAMIDE ZENTIVA"},
{label: "PERINDOPRIL/INDAPAMIDE ZENTIVA LAB"},
{label: "PERINDOPRIL/INDAPAMIDE ZYDUS"},
{label: "PERINDOPRIL/INDAPAMIDE ZYDUS FRANCE"},
{label: "PERINUTRIFLEX E"},
{label: "PERINUTRIFLEX LIPIDE G 64/N"},
{label: "PERINUTRIFLEX OMEGA E"},
{label: "PERIOCHIP"},
{label: "PERIOGARD"},
{label: "PERIOLEUNOR N4E"},
{label: "PERIOLIMEL N4E"},
{label: "PERISOC"},
{label: "PERISTALTINE"},
{label: "PERJETA"},
{label: "PERKOD"},
{label: "PERLEANE"},
{label: "PERLINRING"},
{label: "PERMANGANATE DE POTASSIUM LAFRAN"},
{label: "PERMIXON"},
{label: "PERMUCYL"},
{label: "PERNAZENE"},
{label: "PERSANTINE"},
{label: "PERTOFRAN"},
{label: "PERTUDORON"},
{label: "PERUBORE"},
{label: "PERUBORE INHALATION"},
{label: "PERUBORE MAL DE GORGE"},
{label: "PERUBORE RHINITE"},
{label: "PERYNELLA"},
{label: "PERZAVIS"},
{label: "PETASITES OFFICINALIS BOIRON"},
{label: "PETHIDINE RENAUDIN"},
{label: "PETIT HOUX BOIRON"},
{label: "PETITES PILULES CARTERS"},
{label: "PETROLEUM BOIRON"},
{label: "PETROLEUM LEHNING"},
{label: "PETROLEUM WELEDA"},
{label: "PETROSELINUM SATIVUM BOIRON"},
{label: "PEVADERM DEMANGEAISONS"},
{label: "PEVARYL"},
{label: "PEVARYL 1%"},
{label: "PEVISONE"},
{label: "PEXID"},
{label: "PHAEVA"},
{label: "PHAKAN"},
{label: "PHALGINE ADULTES"},
{label: "PHAPAX"},
{label: "PHARMACILLINE"},
{label: "PHARMADOSE MERCURESCEINE"},
{label: "PHARMAKOD"},
{label: "PHARMALGEN"},
{label: "PHARMASTIM"},
{label: "PHARMATEX"},
{label: "PHARMATON"},
{label: "PHARYNGINE A LA VITAMINE C"},
{label: "PHARYNX BOIRON"},
{label: "PHASEOLUS VULGARIS BOIRON"},
{label: "PHEBURANE"},
{label: "PHELINUN"},
{label: "PHELLANDRIUM AQUATICUM BOIRON"},
{label: "PHELLANDRIUM AQUATICUM LEHNING"},
{label: "PHENERGAN"},
{label: "PHENOBARBITAL RICHARD"},
{label: "PHENOBARBITALUM BOIRON"},
{label: "PHENORO"},
{label: "PHENYLBUTAZONE NOVARTIS PHARMA"},
{label: "PHENYLEPHRINE AGUETTANT"},
{label: "PHENYLEPHRINE ALTAN"},
{label: "PHENYLEPHRINE BLEU DE METHYLENE SMITHKLINE BEECHAM SANTE ET HYGIENE"},
{label: "PHENYLEPHRINE MEDAC"},
{label: "PHENYLEPHRINE RENAUDIN"},
{label: "PHENYLEPHRINE UNIMEDIC"},
{label: "PHENYLPROPANOLAMINE (CHLORHYDRATE) CHLORPHENAMINE (MALEATE) THERANOL"},
{label: "PHESGO"},
{label: "PHISODERM"},
{label: "PHISOMAIN"},
{label: "PHIZOE"},
{label: "PHLEBOCREME"},
{label: "PHLEBOGEL"},
{label: "PHLEBOSTASIN"},
{label: "PHLEBOSUP"},
{label: "PHLOROGLUCINOL ACINO"},
{label: "PHLOROGLUCINOL ARROW"},
{label: "PHLOROGLUCINOL ARROW LAB"},
{label: "PHLOROGLUCINOL BGR"},
{label: "PHLOROGLUCINOL BIOGARAN"},
{label: "PHLOROGLUCINOL BIOGARAN CONSEIL"},
{label: "PHLOROGLUCINOL CHEMICAL FARMA"},
{label: "PHLOROGLUCINOL CRISTERS"},
{label: "PHLOROGLUCINOL EG"},
{label: "PHLOROGLUCINOL ELC"},
{label: "PHLOROGLUCINOL G GAM"},
{label: "PHLOROGLUCINOL HORUS PHARMA"},
{label: "PHLOROGLUCINOL ISOMED"},
{label: "PHLOROGLUCINOL QUALIMED"},
{label: "PHLOROGLUCINOL RATIOPHARM"},
{label: "PHLOROGLUCINOL REF"},
{label: "PHLOROGLUCINOL RPG"},
{label: "PHLOROGLUCINOL SANDOZ"},
{label: "PHLOROGLUCINOL SANDOZ CONSEIL"},
{label: "PHLOROGLUCINOL TEVA"},
{label: "PHLOROGLUCINOL TEVA SANTE"},
{label: "PHLOROGLUCINOL VENIPHARM"},
{label: "PHLOROGLUCINOL VIATRIS"},
{label: "PHLOROGLUCINOL ZENTIVA"},
{label: "PHLOROGLUCINOL/TRIMETHYLPHLOROGLUCINOL ACINO"},
{label: "PHLOROGLUCINOL/TRIMETHYLPHLOROGLUCINOL ARROW"},
{label: "PHLOROGLUCINOL/TRIMETHYLPHLOROGLUCINOL CEPHALON"},
{label: "PHLOROGLUCINOL/TRIMETHYLPHLOROGLUCINOL CHEMICAL FARMA"},
{label: "PHLOROGLUCINOL/TRIMETHYLPHLOROGLUCINOL IDL"},
{label: "PHOCYTAN"},
{label: "PHOLCODINE BIOGARAN"},
{label: "PHOLCODINE CENTRAPHARM"},
{label: "PHOLCODINE DELALANDE ADULTES"},
{label: "PHOLCODINE GLAXOSMITHKLINE SANTE GRAND PUBLIC SANS SUCRE"},
{label: "PHOLCODINE WINTHROP ADULTES"},
{label: "PHOLCODYL"},
{label: "PHOLCODYL SANS SUCRE"},
{label: "PHOLCONES ADULTES"},
{label: "PHOLCONES BISMUTH ADULTES"},
{label: "PHOLCONES BISMUTH ENFANTS"},
{label: "PHOLCONES ENFANTS"},
{label: "PHOLCOSEDAL ADULTES"},
{label: "PHOSCLEINE"},
{label: "PHOSEX"},
{label: "PHOSOFORME"},
{label: "PHOSPHALUGEL"},
{label: "PHOSPHAROME"},
{label: "PHOSPHATE DIPOTASSIQUE RENAUDIN"},
{label: "PHOSPHATE MONOPOTASSIQUE RENAUDIN"},
{label: "PHOSPHATE SODIQUE DE DEXAMETHASONE QUALIMED"},
{label: "PHOSPHATE [32 P] DE SODIUM"},
{label: "PHOSPHAZYL"},
{label: "PHOSPHOCHOLINE"},
{label: "PHOSPHOLINE IODIDE"},
{label: "PHOSPHONEUROS"},
{label: "PHOSPHORE ALKO"},
{label: "PHOSPHORE OLIGOSOL"},
{label: "PHOSPHORICUM ACIDUM BOIRON"},
{label: "PHOSPHORICUM ACIDUM LEHNING"},
{label: "PHOSPHORICUM ACIDUM WELEDA"},
{label: "PHOSPHORUS BOIRON"},
{label: "PHOSPHORUS LEHNING"},
{label: "PHOSPHORUS WELEDA"},
{label: "PHOSPHOSORB"},
{label: "PHOTOBARR"},
{label: "PHOTOFRIN"},
{label: "PHOXILIUM"},
{label: "PHYBAG"},
{label: "PHYSALIS ALKEKENGI BOIRON"},
{label: "PHYSIOGINE"},
{label: "PHYSIOMYCINE"},
{label: "PHYSIONEAL"},
{label: "PHYSIOS"},
{label: "PHYSIOSTAT"},
{label: "PHYSIOTENS"},
{label: "PHYSIOTOP"},
{label: "PHYSOSTIGMA VENENOSUM BOIRON"},
{label: "PHYSOSTIGMA VENENOSUM LEHNING"},
{label: "PHYTACIS."},
{label: "PHYTAT DB"},
{label: "PHYTELIA DIGESTION"},
{label: "PHYTELIA DOULEURS ARTICULAIRES"},
{label: "PHYTELIA JAMBES LOURDES"},
{label: "PHYTELIA NERVOSITE"},
{label: "PHYTELIA SOMMEIL"},
{label: "PHYTELIA TONIQUE"},
{label: "PHYTEMAG"},
{label: "PHYTOCALM"},
{label: "PHYTOFLUIDE GINSENG"},
{label: "PHYTOLACCA DECANDRA"},
{label: "PHYTOLACCA DECANDRA BOIRON"},
{label: "PHYTOLACCA DECANDRA LEHNING"},
{label: "PHYTOMELIS"},
{label: "PHYTOPHOR"},
{label: "PHYTOTUX"},
{label: "PIASCLEDINE"},
{label: "PICATO"},
{label: "PICOPREP"},
{label: "PICOSALAX"},
{label: "PICRICUM ACIDUM BOIRON"},
{label: "PICRICUM ACIDUM LEHNING"},
{label: "PIDOLATE DE MAGNESIUM RATIOPHARM"},
{label: "PIENETT CORICIDE"},
{label: "PIERA"},
{label: "PIFELTRO"},
{label: "PILAREN"},
{label: "PILO"},
{label: "PILOBLOQ"},
{label: "PILOCARPINE"},
{label: "PILOCARPINE CHIBRET"},
{label: "PILOGEL"},
{label: "PILOSELLE BOIRON"},
{label: "PILOSURYL"},
{label: "PILULES DUPUIS"},
{label: "PILULES PINK"},
{label: "PIMPINELLA ANISUM BOIRON"},
{label: "PINAVERIUM ARROW"},
{label: "PINAVERIUM BIOGARAN"},
{label: "PINAVERIUM BIPHAR"},
{label: "PINAVERIUM EG"},
{label: "PINAVERIUM QUALIMED"},
{label: "PINAVERIUM QUIVER"},
{label: "PINAVERIUM RANBAXY"},
{label: "PINAVERIUM SANDOZ"},
{label: "PINAVERIUM TEVA"},
{label: "PINAVERIUM TEVA SANTE"},
{label: "PINAVERIUM VENIPHARM"},
{label: "PINAVERIUM VIATRIS"},
{label: "PINAVERIUM WINTHROP"},
{label: "PINDIONE"},
{label: "PINORHINOL"},
{label: "PINUS MONTANA"},
{label: "PINUS SYLVESTRIS BOIRON"},
{label: "PIPER METHYSTICUM BOIRON"},
{label: "PIPERACILLINE DAKOTA PHARM"},
{label: "PIPERACILLINE G GAM"},
{label: "PIPERACILLINE MEDIFA -K24"},
{label: "PIPERACILLINE MYLAN"},
{label: "PIPERACILLINE PANPHARMA"},
{label: "PIPERACILLINE TAZOBACTAM ACTAVIS"},
{label: "PIPERACILLINE TAZOBACTAM GERDA"},
{label: "PIPERACILLINE TAZOBACTAM RATIOPHARM"},
{label: "PIPERACILLINE TAZOBACTAM TEVA"},
{label: "PIPERACILLINE TAZOBACTAM VIATRIS"},
{label: "PIPERACILLINE/TAZOBACTAM ARROW"},
{label: "PIPERACILLINE/TAZOBACTAM EG"},
{label: "PIPERACILLINE/TAZOBACTAM HOSPIRA"},
{label: "PIPERACILLINE/TAZOBACTAM KABI"},
{label: "PIPERACILLINE/TAZOBACTAM KALCEKS"},
{label: "PIPERACILLINE/TAZOBACTAM PANPHARMA"},
{label: "PIPERACILLINE/TAZOBACTAM PHAGECON"},
{label: "PIPERACILLINE/TAZOBACTAM QILU"},
{label: "PIPERACILLINE/TAZOBACTAM SANDOZ"},
{label: "PIPERACILLINE/TAZOBACTAM TEVA SANTE"},
{label: "PIPERACILLINE/TAZOBACTAM TILLOMED"},
{label: "PIPERAX"},
{label: "PIPERAZINE GIFRER BARBEZAT"},
{label: "PIPIOL"},
{label: "PIPORTIL"},
{label: "PIPORTIL L4"},
{label: "PIPRAM FORT"},
{label: "PIPRINE"},
{label: "PIPTAL"},
{label: "PIQRAY"},
{label: "PIRACETAM ARROW"},
{label: "PIRACETAM BIOGARAN"},
{label: "PIRACETAM EG"},
{label: "PIRACETAM EXPANSCIENCE"},
{label: "PIRACETAM GNR"},
{label: "PIRACETAM IVAX"},
{label: "PIRACETAM MYLAN"},
{label: "PIRACETAM QUALIMED"},
{label: "PIRACETAM RATIOPHARM"},
{label: "PIRACETAM RPG"},
{label: "PIRACETAM SANDOZ"},
{label: "PIRACETAM TEVA"},
{label: "PIRACETAM UCB"},
{label: "PIRACETAM WINTHROP"},
{label: "PIRACETAM ZYDUS"},
{label: "PIRBUTEROL MEDA PHARMA AUTOHALER"},
{label: "PIRFENIDONE ACCORD"},
{label: "PIRFENIDONE APS"},
{label: "PIRFENIDONE BIOGARAN"},
{label: "PIRFENIDONE EG"},
{label: "PIRFENIDONE SANDOZ"},
{label: "PIRFENIDONE TEVA"},
{label: "PIRFENIDONE ZENTIVA"},
{label: "PIRFÉNIDONE VIATRIS"},
{label: "PIRIBEDIL ARDIX"},
{label: "PIRIBEDIL BIOGARAN"},
{label: "PIRILENE"},
{label: "PIROTEC"},
{label: "PIROXICAM BERAL"},
{label: "PIROXICAM BIOGARAN"},
{label: "PIROXICAM BMS"},
{label: "PIROXICAM CRISTERS"},
{label: "PIROXICAM EG"},
{label: "PIROXICAM ELAIAPHARM"},
{label: "PIROXICAM EXPANPHARM"},
{label: "PIROXICAM FOURNIER"},
{label: "PIROXICAM G GAM"},
{label: "PIROXICAM GNR"},
{label: "PIROXICAM INNOTECH INTERNATIONAL"},
{label: "PIROXICAM INNOTHERA"},
{label: "PIROXICAM IVAX"},
{label: "PIROXICAM MERCK"},
{label: "PIROXICAM MYLAN"},
{label: "PIROXICAM PALBIAN"},
{label: "PIROXICAM PFIZER"},
{label: "PIROXICAM PFIZER DISPERSIBLE"},
{label: "PIROXICAM RATIOPHARM"},
{label: "PIROXICAM RPG"},
{label: "PIROXICAM SANDOZ"},
{label: "PIROXICAM TEVA"},
{label: "PIROXICAM TEVA PHARMA"},
{label: "PIROXICAM VIATRIS"},
{label: "PIROXICAM WINTHROP"},
{label: "PIROXICAM ZYDUS"},
{label: "PIROXICAM-RATIOPHARM"},
{label: "PISSENLIT BOIRON"},
{label: "PISSENLIT ETHYPHARM"},
{label: "PITAVASTATINE KOWA PHARMACEUTICAL EUROPE"},
{label: "PITELOL"},
{label: "PIVALONE"},
{label: "PIVALONE NEOMYCINE"},
{label: "PIX LIQUIDA BOIRON"},
{label: "PIX LIQUIDA LEHNING"},
{label: "PIXCYCLIC"},
{label: "PIXUVRI"},
{label: "PLACENTINE BOIRON"},
{label: "PLACYNOX 50% / 50%"},
{label: "PLANCEO"},
{label: "PLANOR"},
{label: "PLANPHYLLINE"},
{label: "PLANTAGO MAJOR BOIRON"},
{label: "PLANTAGO MAJOR LEHNING"},
{label: "PLANTAIN LANCÉOLÉ SANOFI"},
{label: "PLAQUENIL"},
{label: "PLASMACAIR"},
{label: "PLASMAGEL"},
{label: "PLASMAGEL DESODE SORBITOL"},
{label: "PLASMALYTE"},
{label: "PLASMALYTE ET GLUCOSE"},
{label: "PLASMALYTE VIAFLO"},
{label: "PLASMAVOLUME"},
{label: "PLASMION"},
{label: "PLASMOHES"},
{label: "PLASTENAN"},
{label: "PLATANUS BOIRON"},
{label: "PLATINA BOIRON"},
{label: "PLATINA LEHNING"},
{label: "PLATINOL"},
{label: "PLAVIX"},
{label: "PLEGISOL"},
{label: "PLEGRIDY"},
{label: "PLENADREN"},
{label: "PLENAXIS"},
{label: "PLENESIA NERVOSITE"},
{label: "PLENVU"},
{label: "PLENYL"},
{label: "PLERIXAFOR ACCORD"},
{label: "PLERIXAFOR ARROW"},
{label: "PLERIXAFOR SEACROSS"},
{label: "PLERIXAFOR TEVA"},
{label: "PLERIXAFOR TILLOMED"},
{label: "PLERIXAFOR ZENTIVA"},
{label: "PLETAL"},
{label: "PLETARGA"},
{label: "PLEXIUM LEHNING"},
{label: "PLEXUS SOLAIRE BOIRON"},
{label: "PLIAGLIS"},
{label: "PLITICAN"},
{label: "PLITICAN ENFANTS ET NOURRISSONS"},
{label: "PLODILEGOR"},
{label: "PLUMBUM IODATUM BOIRON"},
{label: "PLUMBUM MELLITUM WELEDA"},
{label: "PLUMBUM METALLICUM BOIRON"},
{label: "PLUMBUM METALLICUM LEHNING"},
{label: "PLUMBUM METALLICUM WELEDA"},
{label: "PLUREXID"},
{label: "PLURIBIASE"},
{label: "PLURIFACTOR"},
{label: "PLURINATRYL"},
{label: "PLUVICTO"},
{label: "PNEUMASEPTIC ADULTES"},
{label: "PNEUMO"},
{label: "PNEUMOCLAR ENFANTS"},
{label: "PNEUMOGEINE"},
{label: "PNEUMOGENOL"},
{label: "PNEUMOPAN"},
{label: "PNEUMOPLASME A L'HISTAMINE"},
{label: "PNEUMOPLASME GRAND MODELE"},
{label: "PNEUMOPLASME PETIT MODELE"},
{label: "PNEUMOREL"},
{label: "PNEUMOVAX"},
{label: "PNU-IMUNE"},
{label: "PO"},
{label: "PODOPHYLLINUM BOIRON"},
{label: "PODOPHYLLUM PELTATUM BOIRON"},
{label: "PODOPHYLLUM PELTATUM LEHNING"},
{label: "PODOPHYLLUM PELTATUM WELEDA"},
{label: "POLARAMINE"},
{label: "POLARAMINE PECTORAL"},
{label: "POLARAMINE REPETABS"},
{label: "POLERY ADULTES"},
{label: "POLERY ADULTES SANS SUCRE"},
{label: "POLERY ENFANTS"},
{label: "POLIODINE"},
{label: "POLIODINE DERMIQUE"},
{label: "POLIODINE SCRUB"},
{label: "POLIODINE SOLUTION DERMIQUE"},
{label: "POLIVY"},
{label: "POLLENS BOIRON"},
{label: "POLLENS LEHNING"},
{label: "POLMINOL"},
{label: "POLY-KARAYA"},
{label: "POLYDEXA"},
{label: "POLYFRA"},
{label: "POLYGASTRYL"},
{label: "POLYGONUM AVICULARE BOIRON"},
{label: "POLYGYNAX"},
{label: "POLYGYNAX VIRGO"},
{label: "POLYIONIQUE B"},
{label: "POLYIONIQUE B46 AP-HP"},
{label: "POLYIONIQUE B66 AP-HP"},
{label: "POLYIONIQUE FORMULE 1A G5 BAXTER"},
{label: "POLYIONIQUE FORMULE 2A G10 BAXTER"},
{label: "POLYIONIQUE G"},
{label: "POLYMERAM"},
{label: "POLYMYXINE B SULFATE PFIZER"},
{label: "POLYPIRINE"},
{label: "POLYRHINIUM"},
{label: "POLYSILANE DELALANDE"},
{label: "POLYSILANE NOURRISSONS JOULLIE"},
{label: "POLYSILANE UPSA"},
{label: "POLYSTYRENE SULFONATE DE SODIUM SUBSTIPHARM"},
{label: "POLYSTYRENE SULFONATE DE SODIUM ZENTIVA"},
{label: "POLYTONYL ADULTES"},
{label: "POLYTONYL ENFANTS"},
{label: "POLYVIDONE IODEE"},
{label: "POLYVITAMINES ET OLIGOELEMENTS LEDERLE"},
{label: "POLYVITAMINES ET OLIGOELEMENTS VITAMINE C"},
{label: "POMMADE A L'ARGENT COLLOIDAL A"},
{label: "POMMADE ARNICA TM 4% BOIRON"},
{label: "POMMADE AU CALENDULA LHF"},
{label: "POMMADE AU CALENDULA PAR DIGESTION"},
{label: "POMMADE GALLA"},
{label: "POMMADE LELONG"},
{label: "POMMADE M.O. COCHON"},
{label: "POMMADE MAURICE"},
{label: "POMMADE SOCA"},
{label: "PONAXEM"},
{label: "PONDERAL"},
{label: "PONDERAL LONGUE ACTION"},
{label: "PONDOCIL"},
{label: "PONSTYL"},
{label: "PONVORY"},
{label: "POPULUS CANDICANS BOIRON"},
{label: "POPULUS TREMULA BOIRON"},
{label: "POSACONAZOLE ACCORD"},
{label: "POSACONAZOLE AHCL"},
{label: "POSACONAZOLE ALTAN"},
{label: "POSACONAZOLE ARROW"},
{label: "POSACONAZOLE EG"},
{label: "POSACONAZOLE FRESENIUS KABI"},
{label: "POSACONAZOLE REDDY PHARMA"},
{label: "POSACONAZOLE STRAGEN"},
{label: "POSACONAZOLE TEVA"},
{label: "POSACONAZOLE TILLOMED"},
{label: "POSACONAZOLE VIATRIS"},
{label: "POSACONAZOLE VIVANTA"},
{label: "POSACONAZOLE ZENTIVA"},
{label: "POSEBOR"},
{label: "POSEXONA"},
{label: "POSICYCLINE"},
{label: "POSIFORLID"},
{label: "POSINE"},
{label: "POST-PENGHA"},
{label: "POTACTASOL"},
{label: "POTASSION"},
{label: "POTASSIUM CHLORURE"},
{label: "POTASSIUM CHLORURE BIOLUZ"},
{label: "POTASSIUM H2 PHARMA"},
{label: "POTASSIUM LIBERTY PHARMA"},
{label: "POTASSIUM OLIGOSOL"},
{label: "POTELIGEO"},
{label: "POTHOS FOETIDUS BOIRON"},
{label: "POUMON HISTAMINE BOIRON"},
{label: "POUMON HISTAMINE LEHNING"},
{label: "POUPAME"},
{label: "POVANYL"},
{label: "POVIDONE IODEE CAIR LGL"},
{label: "POVIDONE IODEE CAIR LGL MOUSSANTE"},
{label: "POVIDONE IODEE CHAUVIN"},
{label: "POVIDONE IODEE MYLAN"},
{label: "POVIDONE IODEE MYLAN 10%"},
{label: "POVIDONE IODEE MYLAN MOUSSANTE"},
{label: "POVIDONE IODEE TEVA"},
{label: "POVIDONE IODEE UNITHER"},
{label: "POVIDONE MERCK MEDICATION FAMILIALE"},
{label: "PRACTALIX"},
{label: "PRADAXA"},
{label: "PRADICAINE CORBADRINE"},
{label: "PRADICAINE SANS VASOCONSTRICTEUR"},
{label: "PRAFSIA"},
{label: "PRAGMAREL"},
{label: "PRALUENT"},
{label: "PRAMILET"},
{label: "PRAMIPEXOLE ACCORD"},
{label: "PRAMIPEXOLE ACTAVIS"},
{label: "PRAMIPEXOLE ACTAVIS FRANCE"},
{label: "PRAMIPEXOLE ARROW"},
{label: "PRAMIPEXOLE ARROW APS"},
{label: "PRAMIPEXOLE ARROW LAB"},
{label: "PRAMIPEXOLE AXOREL"},
{label: "PRAMIPEXOLE BIOGARAN"},
{label: "PRAMIPEXOLE BLUEFISH"},
{label: "PRAMIPEXOLE EG"},
{label: "PRAMIPEXOLE MEDIS"},
{label: "PRAMIPEXOLE MITHRIDATUM"},
{label: "PRAMIPEXOLE MYLAN"},
{label: "PRAMIPEXOLE MYPLIX"},
{label: "PRAMIPEXOLE NIALEX"},
{label: "PRAMIPEXOLE QUALIMED"},
{label: "PRAMIPEXOLE RANBAXY"},
{label: "PRAMIPEXOLE RATIOPHARM"},
{label: "PRAMIPEXOLE SANDOZ"},
{label: "PRAMIPEXOLE SANDOZ LP"},
{label: "PRAMIPEXOLE TEVA"},
{label: "PRAMIPEXOLE TEVA LP"},
{label: "PRAMIPEXOLE VIATRIS"},
{label: "PRAMIPEXOLE ZENTIVA"},
{label: "PRAMIPEXOLE ZENTIVA LP"},
{label: "PRAMIPEXOLE ZYDUS"},
{label: "PRAMISPES"},
{label: "PRAMOCAINE CHLORHYDRATE BIOGARAN CONSEIL 1%"},
{label: "PRAMPHAR"},
{label: "PRANDILEX"},
{label: "PRANFIL"},
{label: "PRANTAL"},
{label: "PRANTAL REPETABS"},
{label: "PRASUGREL ARROW"},
{label: "PRASUGREL BGR"},
{label: "PRASUGREL BIOGARAN"},
{label: "PRASUGREL CRISTERS"},
{label: "PRASUGREL EG"},
{label: "PRASUGREL EG LABO"},
{label: "PRASUGREL KRKA"},
{label: "PRASUGREL MYLAN"},
{label: "PRASUGREL SANDOZ"},
{label: "PRASUGREL TEVA"},
{label: "PRASUGREL ZENTIVA"},
{label: "PRASUGREL ZENTIVA LAB"},
{label: "PRASUGREL ZYDUS"},
{label: "PRAVADUAL"},
{label: "PRAVAFENIX"},
{label: "PRAVASTATINE ACCORD"},
{label: "PRAVASTATINE ACTAVIS"},
{label: "PRAVASTATINE ALMUS"},
{label: "PRAVASTATINE ALS"},
{label: "PRAVASTATINE ALTER"},
{label: "PRAVASTATINE APOTEX"},
{label: "PRAVASTATINE ARROW"},
{label: "PRAVASTATINE ARROW GENERIQUES"},
{label: "PRAVASTATINE BIOGARAN"},
{label: "PRAVASTATINE BIOSTABILEX"},
{label: "PRAVASTATINE BOUCHARA RECORDATI"},
{label: "PRAVASTATINE CRISTERS"},
{label: "PRAVASTATINE CRISTERS PHARMA"},
{label: "PRAVASTATINE EG"},
{label: "PRAVASTATINE EVOLUGEN"},
{label: "PRAVASTATINE EVOLUGEN PHARMA"},
{label: "PRAVASTATINE G GAM"},
{label: "PRAVASTATINE GENERES"},
{label: "PRAVASTATINE GERDA"},
{label: "PRAVASTATINE GNR"},
{label: "PRAVASTATINE HEXAL"},
{label: "PRAVASTATINE INTERNATIONAL DRUG DEVELOPMENT"},
{label: "PRAVASTATINE ISOMED"},
{label: "PRAVASTATINE LBR"},
{label: "PRAVASTATINE MYLAN PHARMA"},
{label: "PRAVASTATINE PFIZER"},
{label: "PRAVASTATINE PHR LAB"},
{label: "PRAVASTATINE QUALIMED"},
{label: "PRAVASTATINE QUALIMED GENERIQUES"},
{label: "PRAVASTATINE QUIVER"},
{label: "PRAVASTATINE RANBAXY"},
{label: "PRAVASTATINE RATIO"},
{label: "PRAVASTATINE RATIOPHARM"},
{label: "PRAVASTATINE RPG"},
{label: "PRAVASTATINE SANDOZ"},
{label: "PRAVASTATINE SUN"},
{label: "PRAVASTATINE TEVA"},
{label: "PRAVASTATINE TEVA SANTE"},
{label: "PRAVASTATINE TORLAN"},
{label: "PRAVASTATINE UPSA CONSEIL"},
{label: "PRAVASTATINE VIATRIS"},
{label: "PRAVASTATINE ZENTIVA"},
{label: "PRAVASTATINE ZYDUS"},
{label: "PRAXBIND"},
{label: "PRAXILENE"},
{label: "PRAXINOR"},
{label: "PRAZEPAM ARROW"},
{label: "PRAZEPAM BIOGARAN"},
{label: "PRAZEPAM EG"},
{label: "PRAZEPAM EG LABO"},
{label: "PRAZINIL"},
{label: "PRE PAR"},
{label: "PRE-PAR"},
{label: "PRECALIDE"},
{label: "PRECALIDE DISKUS"},
{label: "PRECALIDE ROTADISK"},
{label: "PRECOLZAC"},
{label: "PRECORAN L.P."},
{label: "PRECYCLAN"},
{label: "PREDALGIC"},
{label: "PREDESIC ADRENALINE 1/100"},
{label: "PREDESIC ADRENALINE 1/200"},
{label: "PREDNISOLONE ARROW"},
{label: "PREDNISOLONE BIOGARAN"},
{label: "PREDNISOLONE CRISTERS"},
{label: "PREDNISOLONE DOMPE"},
{label: "PREDNISOLONE EG"},
{label: "PREDNISOLONE IVAX"},
{label: "PREDNISOLONE LABORATOIRE SUBSTIPHARM"},
{label: "PREDNISOLONE QUALIMED"},
{label: "PREDNISOLONE RATIOPHARM"},
{label: "PREDNISOLONE RPG"},
{label: "PREDNISOLONE SANDOZ"},
{label: "PREDNISOLONE SET"},
{label: "PREDNISOLONE SUBSTIPHARM"},
{label: "PREDNISOLONE SUBSTIPHARM DEVELOPPEMENT"},
{label: "PREDNISOLONE SUN"},
{label: "PREDNISOLONE TEVA"},
{label: "PREDNISOLONE VIATRIS"},
{label: "PREDNISOLONE WINTHROP"},
{label: "PREDNISOLONE ZENTIVA"},
{label: "PREDNISOLONE ZENTIVA LAB"},
{label: "PREDNISONE ARROW"},
{label: "PREDNISONE BIOGARAN"},
{label: "PREDNISONE CRISTERS"},
{label: "PREDNISONE EG"},
{label: "PREDNISONE IREX"},
{label: "PREDNISONE IVAX"},
{label: "PREDNISONE QUALIMED"},
{label: "PREDNISONE RATIOPHARM"},
{label: "PREDNISONE REF"},
{label: "PREDNISONE RICHARD"},
{label: "PREDNISONE SANDOZ"},
{label: "PREDNISONE SG PHARM"},
{label: "PREDNISONE SUBSTILAB"},
{label: "PREDNISONE TEVA"},
{label: "PREDNISONE VIATRIS"},
{label: "PREDNISONE ZENTIVA"},
{label: "PREDUX"},
{label: "PREDYL"},
{label: "PREFAGYL"},
{label: "PREFAGYL MENTHE"},
{label: "PREFAMINT"},
{label: "PREFAMONE CHRONULES"},
{label: "PREFINE"},
{label: "PREGABALINE ACCORD"},
{label: "PREGABALINE ALTER"},
{label: "PREGABALINE ARROW"},
{label: "PREGABALINE ARROW LAB"},
{label: "PREGABALINE BIOGARAN"},
{label: "PREGABALINE CRISTERS"},
{label: "PREGABALINE CRISTERS PHARMA"},
{label: "PREGABALINE EG"},
{label: "PREGABALINE EG LABO"},
{label: "PREGABALINE EVOLUGEN"},
{label: "PREGABALINE EVOLUGEN PHARMA"},
{label: "PREGABALINE HCS"},
{label: "PREGABALINE LAURUS"},
{label: "PREGABALINE LIQMEDS"},
{label: "PREGABALINE MEDIPHA SANTE"},
{label: "PREGABALINE MYLAN"},
{label: "PREGABALINE MYLAN PHARMA"},
{label: "PREGABALINE PFIZER"},
{label: "PREGABALINE SANDOZ"},
{label: "PREGABALINE SANDOZ GMBH"},
{label: "PREGABALINE TEVA"},
{label: "PREGABALINE TEVA SANTE"},
{label: "PREGABALINE TILLOMED"},
{label: "PREGABALINE ZENTIVA"},
{label: "PREGABALINE ZENTIVA K.S."},
{label: "PREGABALINE ZYDUS"},
{label: "PRELE DES CHAMPS BOIRON"},
{label: "PRELE DES CHAMPS ETHYPHARM"},
{label: "PRELE INFUSETTE LES BONS PRODUCTEURS"},
{label: "PRELE NATURA MEDICA"},
{label: "PRELINIUM"},
{label: "PREMARIN"},
{label: "PREMELLA"},
{label: "PREMELLA CYCLE"},
{label: "PREMENS"},
{label: "PREMIDAN ADULTES"},
{label: "PREMIDAN ENFANTS"},
{label: "PREMINOR"},
{label: "PREMIQUE"},
{label: "PREMIQUE CYCLE"},
{label: "PRENOXAD"},
{label: "PREOTACT"},
{label: "PREPACOL"},
{label: "PREPARATION A LA COLOSCOPIE B.BRAUN"},
{label: "PREPARATION H"},
{label: "PREPARATION H VEINOTONIC"},
{label: "PREPIDIL INTRACERVICAL"},
{label: "PREPULSID"},
{label: "PREPULSID ADULTES"},
{label: "PREPULSID ENFANTS ET NOURRISSONS"},
{label: "PRESSICAINE A SANS VASOCONSTRICTEUR"},
{label: "PRESSICAINE FLUID"},
{label: "PRESSICAINE N"},
{label: "PRESTOLE"},
{label: "PRETERAX"},
{label: "PRETERVAL"},
{label: "PREVENAR"},
{label: "PREVIGRIP"},
{label: "PREVISCAN"},
{label: "PREVYMIS"},
{label: "PREXATE"},
{label: "PREXIDINE"},
{label: "PREZISTA"},
{label: "PRIALT"},
{label: "PRIDAX"},
{label: "PRILIGY"},
{label: "PRIMACAINE ADRENALINE"},
{label: "PRIMACAINE ADRENALINE 1/100000"},
{label: "PRIMACAINE ADRENALINE 1/200000"},
{label: "PRIMADRILL"},
{label: "PRIMALAN"},
{label: "PRIMENE"},
{label: "PRIMOLINE LP"},
{label: "PRIMOLUT-NOR"},
{label: "PRIMOVIST"},
{label: "PRIMPERAN"},
{label: "PRIMPERAN ENFANTS"},
{label: "PRIMPERAN ENFANTS ET NOURRISSONS"},
{label: "PRIMPERAN NOURRISSONS ET ENFANTS"},
{label: "PRIMPEROXANE"},
{label: "PRIMULA OBCONICA BOIRON"},
{label: "PRIMYXINE"},
{label: "PRINACTIZIDE"},
{label: "PRINASE"},
{label: "PRINCI B"},
{label: "PRINIVIL"},
{label: "PRINZIDE"},
{label: "PRIODERM"},
{label: "PRIORIX"},
{label: "PRIORIX-TETRA"},
{label: "PRISMASOL"},
{label: "PRISTAM"},
{label: "PRISTINAMYCINE RPG"},
{label: "PRITOR"},
{label: "PRITORPLUS"},
{label: "PRIVATUX ADULTES"},
{label: "PRIVATUX ENFANTS"},
{label: "PRIVIGEN"},
{label: "PRO DAFALGAN"},
{label: "PRO-DAFALGAN"},
{label: "PROALLERGODIL"},
{label: "PROAMPI"},
{label: "PROCALMIL"},
{label: "PROCORALAN"},
{label: "PROCTIM"},
{label: "PROCTOCORT"},
{label: "PROCTOLOG"},
{label: "PROCTYL"},
{label: "PROCUTA"},
{label: "PROCYSBI"},
{label: "PRODASONE"},
{label: "PRODILANTIN"},
{label: "PRODINAN"},
{label: "PROFASI"},
{label: "PROFEMIGR"},
{label: "PROFENID"},
{label: "PROFENID L P"},
{label: "PROFENID LP"},
{label: "PROFLOR"},
{label: "PROFLUINE"},
{label: "PROGESTAN"},
{label: "PROGESTERONE BESINS"},
{label: "PROGESTERONE BIOGARAN"},
{label: "PROGESTERONE QUALIMED"},
{label: "PROGESTERONE RETARD PHARLON"},
{label: "PROGESTERONE SANDOZ"},
{label: "PROGESTERONE TEVA"},
{label: "PROGESTERONE VIATRIS"},
{label: "PROGESTERONUM BOIRON"},
{label: "PROGESTERONUM LEHNING"},
{label: "PROGESTOGEL"},
{label: "PROGESTOSOL"},
{label: "PROGIRON"},
{label: "PROGLICEM"},
{label: "PROGRAF"},
{label: "PROGYNOVA"},
{label: "PROHANCE"},
{label: "PROINULINE SERB"},
{label: "PROKINYL L.P."},
{label: "PROLAIR"},
{label: "PROLAIR AUTOHALER"},
{label: "PROLASTIN"},
{label: "PROLEUKIN"},
{label: "PROLIA"},
{label: "PROMEGESTONE RPG"},
{label: "PROMETHAZINE (CHLORHYDRATE DE)/BROMOLACTOBIONATE DE CALCIUM MERCK"},
{label: "PROMETHAZINE (CHLORHYDRATE DE)/GUAIFENESINE EVANS MEDICAL FRANCE"},
{label: "PROMINCIL"},
{label: "PROMIT"},
{label: "PROMOTIL"},
{label: "PROMUCOL ADULTES"},
{label: "PRONTADOL ADULTES"},
{label: "PRONTALFEN"},
{label: "PRONTALGINE"},
{label: "PROPACETAMOL MYLAN"},
{label: "PROPACETAMOL QUALIMED"},
{label: "PROPAFENONE ACCORD"},
{label: "PROPAFENONE VIATRIS"},
{label: "PROPECIA"},
{label: "PROPESS"},
{label: "PROPINE"},
{label: "PROPIOCINE"},
{label: "PROPIONATE DE FLUTICASONE GLAXOSMITHKLINE SANTE GRAND PUBLIC"},
{label: "PROPIONATE DE FLUTICASONE TEVA"},
{label: "PROPIONATE DE FLUTICASONE/SALMETEROL ARROW AIRMASTER"},
{label: "PROPIONATE DE FLUTICASONE/SALMETEROL BGR"},
{label: "PROPIONATE DE FLUTICASONE/SALMETEROL BIOGARAN AIRMASTER"},
{label: "PROPIONATE DE FLUTICASONE/SALMETEROL EG AIRMASTER"},
{label: "PROPIONATE DE FLUTICASONE/SALMETEROL GSK"},
{label: "PROPIONATE DE FLUTICASONE/SALMETEROL ORION"},
{label: "PROPIONATE DE FLUTICASONE/SALMETEROL SANDOZ"},
{label: "PROPIONATE DE FLUTICASONE/SALMETEROL TEVA"},
{label: "PROPIONATE DE FLUTICASONE/SALMETEROL VIATRIS"},
{label: "PROPIONATE DE FLUTICASONE/SALMETEROL ZENTIVA"},
{label: "PROPIONATE DE SODIUM CHIBRET"},
{label: "PROPOFAN"},
{label: "PROPOFOL BAXTER"},
{label: "PROPOFOL BIOQ"},
{label: "PROPOFOL DAKOTA PHARM"},
{label: "PROPOFOL EG"},
{label: "PROPOFOL FRESENIUS"},
{label: "PROPOFOL HOSPIRA"},
{label: "PROPOFOL KABI"},
{label: "PROPOFOL LIPURO"},
{label: "PROPOFOL MYLAN"},
{label: "PROPOFOL PANPHARMA"},
{label: "PROPOLIS WELEDA"},
{label: "PROPRANOLOL ACCORD"},
{label: "PROPRANOLOL ACETLAB"},
{label: "PROPRANOLOL ARROW"},
{label: "PROPRANOLOL BIOGARAN"},
{label: "PROPRANOLOL EG"},
{label: "PROPRANOLOL TEVA"},
{label: "PROPRANOLOL TEVA L P"},
{label: "PROPYLEX"},
{label: "PROQUAD"},
{label: "PRORHINEL"},
{label: "PRORHINEL MAL DE GORGE"},
{label: "PRORHINEL RHUME"},
{label: "PROSOFT"},
{label: "PROSPAN"},
{label: "PROSPAN SANS SUCRE"},
{label: "PROSPIRIL TWISTHALER"},
{label: "PROSTADIREX"},
{label: "PROSTALFA"},
{label: "PROSTANORM"},
{label: "PROSTATE BOIRON"},
{label: "PROSTATEP"},
{label: "PROSTIGMINE"},
{label: "PROSTINE E2"},
{label: "PROSTINE VR"},
{label: "PROTAMINE CHLORHYDRATE"},
{label: "PROTAMINE CHOAY"},
{label: "PROTAMINE SULFATE FOURNIER"},
{label: "PROTANGIX"},
{label: "PROTELOS"},
{label: "PROTEOSULFAN"},
{label: "PROTEUS VULGARIS BOIRON"},
{label: "PROTEXEL"},
{label: "PROTHIADEN"},
{label: "PROTHYL"},
{label: "PROTINUTRIL"},
{label: "PROTIRELINE ROCHE"},
{label: "PROTOPIC"},
{label: "PROTOTAPEN"},
{label: "PROTOVIT"},
{label: "PROTOVIT ENFANTS"},
{label: "PROTOXYDE D'AZOTE MEDICINAL AIR LIQUIDE SANTE FRANCE"},
{label: "PROTOXYDE D'AZOTE MEDICINAL AIR LIQUIDE SANTÉ FRANCE"},
{label: "PROTOXYDE D'AZOTE MEDICINAL AIR PRODUCTS MEDICAL"},
{label: "PROTOXYDE D'AZOTE MEDICINAL LINDE HEALTHCARE"},
{label: "PROTOXYDE D'AZOTE MEDICINAL MESSER FRANCE"},
{label: "PROTOXYDE D'AZOTE MEDICINAL SAGA MEDICAL"},
{label: "PROTOXYDE D'AZOTE MEDICINAL SOL FRANCE"},
{label: "PROURSAN"},
{label: "PROVAMES"},
{label: "PROVENCALE N°"},
{label: "PROVENCALE N°4"},
{label: "PROVENOL"},
{label: "PROVERA"},
{label: "PROVISACOR"},
{label: "PROXALYOC"},
{label: "PROYL"},
{label: "PROZAC"},
{label: "PRUNIER D'AFRIQUE ARROW"},
{label: "PRUNIER D'AFRIQUE MYLAN GENERIQUES"},
{label: "PRUNINE"},
{label: "PRUNOMUCIL"},
{label: "PRUNUS SPINOSA BOIRON"},
{label: "PRUNUS SPINOSA LEHNING"},
{label: "PRUNUS SPINOSA WELEDA"},
{label: "PRURITUS"},
{label: "PSEUDOPHAGE"},
{label: "PSORADERM"},
{label: "PSYLANE"},
{label: "PSYLIA"},
{label: "PSYLLIUM FUCA"},
{label: "PSYLLIUM LANGLEBERT"},
{label: "PTELEA TRIFOLIATA BOIRON"},
{label: "PULMADOL ENFANTS"},
{label: "PULMADOL NOURRISSONS"},
{label: "PULMAX"},
{label: "PULMICORT"},
{label: "PULMICORT TURBUHALER"},
{label: "PULMINE BOIRON"},
{label: "PULMINE WELEDA"},
{label: "PULMOBAILLY"},
{label: "PULMOCALM"},
{label: "PULMOCIS"},
{label: "PULMOCOD"},
{label: "PULMODEXANE"},
{label: "PULMOFLUIDE ENFANTS"},
{label: "PULMOFLUIDE NOURRISSONS"},
{label: "PULMOFLUIDE SIMPLE"},
{label: "PULMOLL MELANGE INHALANT"},
{label: "PULMOSERUM"},
{label: "PULMOSODYL ADULTE"},
{label: "PULMOTEC"},
{label: "PULMOZYME"},
{label: "PULPARTHROL"},
{label: "PULPERYL"},
{label: "PULPISEDOL"},
{label: "PULPOMIXINE"},
{label: "PULSAREM"},
{label: "PULSATILLA BOIRON"},
{label: "PULSATILLA FERRIER"},
{label: "PULSATILLA LEHNING"},
{label: "PULSATILLA WELEDA"},
{label: "PULVO"},
{label: "PUMARIX"},
{label: "PUNICA GRANATUM BOIRON"},
{label: "PURAYA"},
{label: "PURCASCYL"},
{label: "PUREGON"},
{label: "PURINETHOL"},
{label: "PURIVIST"},
{label: "PURSENNIDE"},
{label: "PUTRESCINUM BOIRON"},
{label: "PYGMAL"},
{label: "PYLERA"},
{label: "PYOCEFAL"},
{label: "PYOREDOL"},
{label: "PYOREX"},
{label: "PYOSTACINE"},
{label: "PYRADOL"},
{label: "PYRALVEX"},
{label: "PYRANTEL (EMBONATE) BERAL"},
{label: "PYRANTEL BERAL"},
{label: "PYRAX"},
{label: "PYREFLOR"},
{label: "PYRETHANE A LA NORAMIDOPYRINE"},
{label: "PYRETHRUM BOIRON"},
{label: "PYRIDOSTIGMINE LIME"},
{label: "PYRIDOSTIGMINE PHARMACIE CENTRALE DES ARMEES"},
{label: "PYRIDOXINE CHLORHYDRATE"},
{label: "PYRITE DE FER BOIRON"},
{label: "PYRITE DE FER WELEDA"},
{label: "PYROGENIUM BOIRON"},
{label: "PYROGENIUM LEHNING"},
{label: "PYROLUSITE BOIRON"},
{label: "PYRUKYND"},
{label: "QARZIBA"},
{label: "QDENGA"},
{label: "QIADE"},
{label: "QINLOCK"},
{label: "QLAIRA"},
{label: "QTERN"},
{label: "QUADRAMET"},
{label: "QUADRASA"},
{label: "QUASYM"},
{label: "QUASYM L.P."},
{label: "QUATOR"},
{label: "QUERCUS"},
{label: "QUESTRAN"},
{label: "QUETIAPINE ACCORD HEALTHCARE LP"},
{label: "QUETIAPINE ACCORD LP"},
{label: "QUETIAPINE ARROW LAB LP"},
{label: "QUETIAPINE ARROW LP"},
{label: "QUETIAPINE BILLEV LP"},
{label: "QUETIAPINE BIOGARAN LP"},
{label: "QUETIAPINE BIOSTABILEX LP"},
{label: "QUETIAPINE CRISTERS LP"},
{label: "QUETIAPINE EG LP"},
{label: "QUETIAPINE EVOLUGEN LP"},
{label: "QUETIAPINE HEC PHARM"},
{label: "QUETIAPINE KRKA"},
{label: "QUETIAPINE KRKA LP"},
{label: "QUETIAPINE MEDIPHA SANTE"},
{label: "QUETIAPINE MYLAN PHARMA LP"},
{label: "QUETIAPINE NIALEX LP"},
{label: "QUETIAPINE REGIOMEDICA"},
{label: "QUETIAPINE REGIOMEDICA 25/100"},
{label: "QUETIAPINE REGIOMEDICA 25/100/200"},
{label: "QUETIAPINE SANDOZ LP"},
{label: "QUETIAPINE SUN LP"},
{label: "QUETIAPINE TEVA LP"},
{label: "QUETIAPINE VIATRIS LP"},
{label: "QUETIAPINE ZENTIVA LP"},
{label: "QUIETILINE"},
{label: "QUIETIVAL"},
{label: "QUIETUDE"},
{label: "QUINAPRIL ARROW"},
{label: "QUINAPRIL BIOGARAN"},
{label: "QUINAPRIL EG"},
{label: "QUINAPRIL HYDROCHLOROTHIAZIDE EG"},
{label: "QUINAPRIL MYLAN"},
{label: "QUINAPRIL SANDOZ"},
{label: "QUINAPRIL TEVA"},
{label: "QUINAPRIL WINTHROP"},
{label: "QUINAPRIL ZENTIVA"},
{label: "QUINAPRIL/HYDROCHLOROTHIAZIDE ARROW"},
{label: "QUINAPRIL/HYDROCHLOROTHIAZIDE ARROW LAB"},
{label: "QUINAPRIL/HYDROCHLOROTHIAZIDE MYLAN"},
{label: "QUINAPRIL/HYDROCHLOROTHIAZIDE SANDOZ"},
{label: "QUINAPRIL/HYDROCHLOROTHIAZIDE TEVA SANTE"},
{label: "QUINAPRIL/HYDROCHLOROTHIAZIDE ZENTIVA"},
{label: "QUINIDURULE"},
{label: "QUINIMAX"},
{label: "QUININE CHLORHYDRATE LAFRAN"},
{label: "QUININE RENAUDIN"},
{label: "QUININE SULFATE LAFRAN"},
{label: "QUININE VITAMINE C GRAND"},
{label: "QUINOCARBINE"},
{label: "QUINODIS"},
{label: "QUINOFORME"},
{label: "QUINOFREE"},
{label: "QUINSAIR"},
{label: "QUINTALENE ADULTES"},
{label: "QUINTALENE ENFANTS"},
{label: "QUINTASA"},
{label: "QUINTONINE"},
{label: "QUINTOPAN"},
{label: "QUITADRILL"},
{label: "QUITAXON"},
{label: "QUIXIL"},
{label: "QUODIXOR"},
{label: "QUOFENIX"},
{label: "QUOMEM L.P.150"},
{label: "QUOTANE"},
{label: "QUOTIVIT O E"},
{label: "QUTENZA"},
{label: "QVAR AUTOHALER"},
{label: "QVARPRESS"},
{label: "QVARSPRAY"},
{label: "R"},
{label: "R.O.R."},
{label: "R.O.R. VAX"},
{label: "RABEPRAZOLE ACCORD"},
{label: "RABEPRAZOLE ADS PHARMA"},
{label: "RABEPRAZOLE ALMUS"},
{label: "RABEPRAZOLE ALTER"},
{label: "RABEPRAZOLE ARROW"},
{label: "RABEPRAZOLE ARROW GENERIQUES"},
{label: "RABEPRAZOLE AUTHOU"},
{label: "RABEPRAZOLE BGR"},
{label: "RABEPRAZOLE BIOGARAN"},
{label: "RABEPRAZOLE CRISTERS"},
{label: "RABEPRAZOLE CRISTERS PHARMA"},
{label: "RABEPRAZOLE EG"},
{label: "RABEPRAZOLE EG LABO"},
{label: "RABEPRAZOLE EVOLUGEN"},
{label: "RABEPRAZOLE ISOMED"},
{label: "RABEPRAZOLE KRKA"},
{label: "RABEPRAZOLE MYLAN"},
{label: "RABEPRAZOLE MYLAN PHARMA"},
{label: "RABEPRAZOLE NIALEX"},
{label: "RABEPRAZOLE PFIZER"},
{label: "RABEPRAZOLE PHR LAB"},
{label: "RABEPRAZOLE RATIO"},
{label: "RABEPRAZOLE SANDOZ"},
{label: "RABEPRAZOLE TEVA"},
{label: "RABEPRAZOLE TORRENT PHARMA"},
{label: "RABEPRAZOLE VIATRIS"},
{label: "RABEPRAZOLE ZENTIVA"},
{label: "RABEPRAZOLE ZYDUS"},
{label: "RABIPUR"},
{label: "RACECADOTRIL ARROW"},
{label: "RACECADOTRIL BGR"},
{label: "RACECADOTRIL BIOGARAN"},
{label: "RACECADOTRIL BIOGARAN CONSEIL"},
{label: "RACECADOTRIL GERDA"},
{label: "RACECADOTRIL REF"},
{label: "RACECADOTRIL SET"},
{label: "RACECADOTRIL SUBSTIPHARM"},
{label: "RACECADOTRIL ZENTIVA"},
{label: "RADELUMIN"},
{label: "RADIOGARDASE"},
{label: "RADIOPAQUE AROMATISE"},
{label: "RADIOSELECTAN URINAIRE"},
{label: "RADIOSELECTAN URINAIRE ET VASCULAIRE"},
{label: "RADIUM BROMATUM BOIRON"},
{label: "RAGON"},
{label: "RAGWIZAX"},
{label: "RALOPHARM"},
{label: "RALOXIFENE ARROW"},
{label: "RALOXIFENE BIOGARAN"},
{label: "RALOXIFENE CRISTERS"},
{label: "RALOXIFENE EG"},
{label: "RALOXIFENE RATIOPHARM"},
{label: "RALOXIFENE SANDOZ"},
{label: "RALOXIFENE SYNTHON"},
{label: "RALOXIFENE TEVA"},
{label: "RALOXIFENE VIATRIS"},
{label: "RALOXIFENE ZENTIVA"},
{label: "RAMAVIS"},
{label: "RAMET DALIBOUR"},
{label: "RAMIKIT"},
{label: "RAMIPRIL / HYDROCHLOROTHIAZIDE BIOGARAN"},
{label: "RAMIPRIL ALMUS"},
{label: "RAMIPRIL ALTER"},
{label: "RAMIPRIL ARROW"},
{label: "RAMIPRIL ARROW GENERIQUES"},
{label: "RAMIPRIL ARROW LAB"},
{label: "RAMIPRIL BIOGARAN"},
{label: "RAMIPRIL BOUCHARA RECORDATI"},
{label: "RAMIPRIL CRISTERS"},
{label: "RAMIPRIL CRISTERS PHARMA"},
{label: "RAMIPRIL DCI PHARMA"},
{label: "RAMIPRIL EG"},
{label: "RAMIPRIL EVOLUGEN"},
{label: "RAMIPRIL G GAM"},
{label: "RAMIPRIL GENERES"},
{label: "RAMIPRIL ISOMED"},
{label: "RAMIPRIL KRKA"},
{label: "RAMIPRIL MEDIS"},
{label: "RAMIPRIL MERCK"},
{label: "RAMIPRIL MERCK GENERIQUES"},
{label: "RAMIPRIL PFIZER"},
{label: "RAMIPRIL PHR LAB"},
{label: "RAMIPRIL QUALIHEALTH"},
{label: "RAMIPRIL QUALIMED"},
{label: "RAMIPRIL QUIVER"},
{label: "RAMIPRIL RANBAXY"},
{label: "RAMIPRIL RATIOPHARM"},
{label: "RAMIPRIL SANDOZ"},
{label: "RAMIPRIL TEVA"},
{label: "RAMIPRIL TEVA SANTE"},
{label: "RAMIPRIL TORRENT"},
{label: "RAMIPRIL VENIPHARM"},
{label: "RAMIPRIL VIATRIS"},
{label: "RAMIPRIL WINTHROP"},
{label: "RAMIPRIL ZENTIVA"},
{label: "RAMIPRIL ZYDUS"},
{label: "RAMIPRIL ZYDUS FRANCE"},
{label: "RAMIPRIL/HYDROCHLOROTHIAZIDE ARROW"},
{label: "RAMIPRIL/HYDROCHLOROTHIAZIDE EG"},
{label: "RAMIPRIL/HYDROCHLOROTHIAZIDE SANDOZ"},
{label: "RAMIPRIL/HYDROCHLOROTHIAZIDE TEVA"},
{label: "RAMIPRIL/HYDROCHLOROTHIAZIDE VIATRIS"},
{label: "RAMIPRIL/HYDROCHLOROTHIAZIDE ZENTIVA"},
{label: "RAMODAR"},
{label: "RANA BUFO BOIRON"},
{label: "RANEXA"},
{label: "RANIPLEX"},
{label: "RANIPLEX INJECTABLE"},
{label: "RANITIDINE"},
{label: "RANITIDINE ACCORD"},
{label: "RANITIDINE ACCORD HEALTHCARE"},
{label: "RANITIDINE APOTEX FRANCE"},
{label: "RANITIDINE ARROW"},
{label: "RANITIDINE BIOGALENIQUE"},
{label: "RANITIDINE BIOGARAN"},
{label: "RANITIDINE COLOMER"},
{label: "RANITIDINE DCI PHARMA"},
{label: "RANITIDINE DISPHAR"},
{label: "RANITIDINE EG"},
{label: "RANITIDINE HERMES"},
{label: "RANITIDINE IVAX"},
{label: "RANITIDINE MYLAN"},
{label: "RANITIDINE MYLAN PHARMA"},
{label: "RANITIDINE NORDIC PHARMA"},
{label: "RANITIDINE PAUCOURT"},
{label: "RANITIDINE QUALIMED"},
{label: "RANITIDINE RANBAXY"},
{label: "RANITIDINE RATIO"},
{label: "RANITIDINE RATIOPHARM"},
{label: "RANITIDINE REDDY"},
{label: "RANITIDINE RPG"},
{label: "RANITIDINE SAINT-GERMAIN"},
{label: "RANITIDINE SANDOZ"},
{label: "RANITIDINE SUBSTIPHARM"},
{label: "RANITIDINE TEVA"},
{label: "RANITIDINE WINTHROP"},
{label: "RANITIDINE ZENTIVA"},
{label: "RANITIDINE ZYDUS"},
{label: "RANIVISIO"},
{label: "RANIX"},
{label: "RANUNCULUS BULBOSUS BOIRON"},
{label: "RANUNCULUS BULBOSUS LEHNING"},
{label: "RANUNCULUS BULBOSUS WELEDA"},
{label: "RANUNCULUS COMPLEXE N°79"},
{label: "RANUNCULUS SCELERATUS BOIRON"},
{label: "RAPAMUNE"},
{label: "RAPHANUS S POTIER"},
{label: "RAPHANUS SATIVUS NIGER BOIRON"},
{label: "RAPHANUS SATIVUS NIGER LEHNING"},
{label: "RAPHANUS SATIVUS NIGER WELEDA"},
{label: "RAPIBLOC"},
{label: "RAPIFEN"},
{label: "RAPILYSIN"},
{label: "RAPISCAN"},
{label: "RAPLIXA"},
{label: "RAPTIVA"},
{label: "RAPYDAN"},
{label: "RASAGILINE ACCORD"},
{label: "RASAGILINE ALTER"},
{label: "RASAGILINE APS"},
{label: "RASAGILINE ARROW"},
{label: "RASAGILINE AUTHOU"},
{label: "RASAGILINE BIOGARAN"},
{label: "RASAGILINE CRISTERS"},
{label: "RASAGILINE EG"},
{label: "RASAGILINE EVOLUGEN"},
{label: "RASAGILINE FARMALIDER"},
{label: "RASAGILINE KRKA"},
{label: "RASAGILINE MYLAN"},
{label: "RASAGILINE NEURAXPHARM"},
{label: "RASAGILINE NEWLINE PHARMA"},
{label: "RASAGILINE RATIOPHARM"},
{label: "RASAGILINE RIC"},
{label: "RASAGILINE SANDOZ"},
{label: "RASAGILINE TEVA"},
{label: "RASAGILINE WELDING"},
{label: "RASAGILINE ZENTIVA"},
{label: "RASAGILINE ZYDUS"},
{label: "RASILAMLO"},
{label: "RASILEZ"},
{label: "RASILEZ HCT"},
{label: "RASITRIO"},
{label: "RATANHIA BOIRON"},
{label: "RATANHIA LEHNING"},
{label: "RATIBAN"},
{label: "RATIOCID"},
{label: "RATIOGRASTIM"},
{label: "RAUBASINE DIHYDROERGOCRISTINE PIERRE FABRE"},
{label: "RAUBASINE MESILATE DE DIHYDROERGOCRISTINE GENEVAR"},
{label: "RAUWOLFIA SERPENTINA BOIRON"},
{label: "RAVICTI"},
{label: "RAXAR"},
{label: "RAXONE"},
{label: "RAYVOW"},
{label: "REACTINE"},
{label: "REANUTRIFLEX"},
{label: "REANUTRIFLEX E"},
{label: "REANUTRIFLEX LIPIDE G 144/N"},
{label: "REANUTRIFLEX LIPIDE G 144/N 8/E"},
{label: "REANUTRIFLEX OMEGA"},
{label: "REANUTRIFLEX OMEGA E"},
{label: "REANUTRIFLEX OMEGA G 144/N 8/E"},
{label: "REBETOL"},
{label: "REBIF"},
{label: "REBLOZYL"},
{label: "RECABINIP"},
{label: "RECARBRIO"},
{label: "RECHOLAN"},
{label: "RECIVIT"},
{label: "RECOMBINATE"},
{label: "RECOSTIM"},
{label: "RECTOGEL"},
{label: "RECTOGESIC"},
{label: "RECTOLAX"},
{label: "RECTOMARTIN ADULTES"},
{label: "RECTOPANBILINE ADULTES"},
{label: "RECTOPHEDROL NOURRISSONS"},
{label: "RECTOPLEXIL"},
{label: "RECTOQUOTANE"},
{label: "RECTOVALONE"},
{label: "REDOXON"},
{label: "REDULIGNE"},
{label: "REDUPSAN"},
{label: "REFACTO AF"},
{label: "REFIXIA"},
{label: "REFLEX-SPRAY"},
{label: "REFLUDAN"},
{label: "REFRESH"},
{label: "REGAINE"},
{label: "REGIOCIT"},
{label: "REGKIRONA"},
{label: "REGON"},
{label: "REGRANEX"},
{label: "REGULCONTINU"},
{label: "REIN CORTEX BOIRON"},
{label: "REIN MEDULLAIRE BOIRON"},
{label: "REINE DES PRES BOIRON"},
{label: "REINE DES PRES ETHYPHARM"},
{label: "REINE DES PRES INFUSETTE LES BONS PRODUCTEURS"},
{label: "REINE DES PRES NATURA MEDICA"},
{label: "REKAMBYS"},
{label: "REKOVELLE"},
{label: "RELAN"},
{label: "RELAXINE"},
{label: "RELAXODDI"},
{label: "RELENZA"},
{label: "RELERT"},
{label: "RELISTOR"},
{label: "RELPAX"},
{label: "RELVAR ELLIPTA"},
{label: "RELVENE"},
{label: "RELVENE GEL"},
{label: "REMEX"},
{label: "REMICADE"},
{label: "REMIFENTANIL ARROW"},
{label: "REMIFENTANIL B BRAUN"},
{label: "REMIFENTANIL HOSPIRA"},
{label: "REMIFENTANIL KABI"},
{label: "REMIFENTANIL PFIZER"},
{label: "REMIFENTANIL TEVA"},
{label: "REMIFENTANIL VIATRIS"},
{label: "REMINYL"},
{label: "REMINYL L.P."},
{label: "REMODULIN"},
{label: "REMOPLEXE LEHNING"},
{label: "REMOVAB"},
{label: "REMSIMA"},
{label: "RENAGEL"},
{label: "RENAPUR LP"},
{label: "RENESE"},
{label: "RENINE BOIRON"},
{label: "RENINE WELEDA"},
{label: "RENITEC"},
{label: "RENNAXT"},
{label: "RENNIE"},
{label: "RENNIE DEFLATINE SANS SUCRE"},
{label: "RENNIE EFFERVESCENT"},
{label: "RENNIE LIQUIDE"},
{label: "RENNIE MENTHE GLACIALE SANS SUCRE"},
{label: "RENNIE ORANGE"},
{label: "RENNIE REFLUXINE"},
{label: "RENNIE SANS SUCRE"},
{label: "RENNIECHEW"},
{label: "RENNIEDUO"},
{label: "RENNIELIQUO SANS SUCRE"},
{label: "RENOCIS"},
{label: "RENORMAX"},
{label: "RENVELA"},
{label: "REOPRO"},
{label: "REORYTHMIN"},
{label: "REPAGLINIDE ACCORD"},
{label: "REPAGLINIDE ARROW"},
{label: "REPAGLINIDE ARROW GENERIQUES"},
{label: "REPAGLINIDE ARROW LAB"},
{label: "REPAGLINIDE BIOGARAN"},
{label: "REPAGLINIDE CRISTERS"},
{label: "REPAGLINIDE EG"},
{label: "REPAGLINIDE EVOLUGEN"},
{label: "REPAGLINIDE KRKA"},
{label: "REPAGLINIDE MYLAN GENERIQUES"},
{label: "REPAGLINIDE RANBAXY"},
{label: "REPAGLINIDE RATIOPHARM"},
{label: "REPAGLINIDE SANDOZ"},
{label: "REPAGLINIDE SPECIFAR"},
{label: "REPAGLINIDE TEVA"},
{label: "REPAGLINIDE TORRENT"},
{label: "REPAGLINIDE VIATRIS"},
{label: "REPAGLINIDE ZENTIVA"},
{label: "REPAGLINIDE ZYDUS"},
{label: "REPAGLUC"},
{label: "REPARIL"},
{label: "REPATHA"},
{label: "REPEVAX"},
{label: "REPLAGAL"},
{label: "REPLENS"},
{label: "REPTILASE"},
{label: "REQUIP"},
{label: "REQUIP LP"},
{label: "RESCUEFLOW"},
{label: "RESCULA"},
{label: "RESELIP"},
{label: "RESIKALI"},
{label: "RESITUNE"},
{label: "RESIVIT"},
{label: "RESOLOR"},
{label: "RESPILENE ADULTES"},
{label: "RESPILENE ENFANTS"},
{label: "RESPREEZA"},
{label: "RESTORVOL"},
{label: "RESTRICAL ESTRAGON"},
{label: "RESTRICAL NOISETTE"},
{label: "RETACNYL"},
{label: "RETACRIT"},
{label: "RETIN A"},
{label: "RETINOVA"},
{label: "RETITOP"},
{label: "RETRONE"},
{label: "RETROVIR"},
{label: "RETSEVMO"},
{label: "REVASC"},
{label: "REVATIO"},
{label: "REVAXIS"},
{label: "REVERPLEG"},
{label: "REVESTIVE"},
{label: "REVIA"},
{label: "REVINOL"},
{label: "REVINTY ELLIPTA"},
{label: "REVITALOSE"},
{label: "REVITALOSE SANS SUCRE"},
{label: "REVLIMID"},
{label: "REVOLADE"},
{label: "REVULSOLINE"},
{label: "REXORT"},
{label: "REXORUBIA"},
{label: "REYATAZ"},
{label: "REZALL"},
{label: "REZOLSTA"},
{label: "RHAMNUS FRANGULA BOIRON"},
{label: "RHEOBRAL"},
{label: "RHEOFLUX"},
{label: "RHEOMACRODEX CHLORURE SODIQUE"},
{label: "RHEOMACRODEX GLUCOSE"},
{label: "RHEOMACRODEX SORBITOL"},
{label: "RHESONATIV"},
{label: "RHEUMON"},
{label: "RHINAAXIA"},
{label: "RHINACTYL"},
{label: "RHINADVIL MAUX DE GORGE TIXOCORTOL/CHLORHEXIDINE"},
{label: "RHINADVIL RHUME IBUPROFENE/PSEUDOEPHEDRINE"},
{label: "RHINADVILCAPS RHUME IBUPROFENE/PSEUDOEPHEDRINE"},
{label: "RHINADVILTAB"},
{label: "RHINAL"},
{label: "RHINALAIR L.P."},
{label: "RHINALLERGY"},
{label: "RHINALYOC"},
{label: "RHINAMIDE"},
{label: "RHINASPIR"},
{label: "RHINATHIOL"},
{label: "RHINATHIOL ADULTES TOUX SECHE"},
{label: "RHINATHIOL ENFANTS TOUX SECHE"},
{label: "RHINATHIOL EXPECTORANT CARBOCISTEINE"},
{label: "RHINATHIOL MAUX DE GORGE"},
{label: "RHINATHIOL PROMETHAZINE"},
{label: "RHINATHIOL RHUME"},
{label: "RHINATUX"},
{label: "RHINE INFANTILE"},
{label: "RHINEA"},
{label: "RHINEDRINE"},
{label: "RHINIBAR"},
{label: "RHINIREX"},
{label: "RHINISIL"},
{label: "RHINO BLACHE"},
{label: "RHINO SULFURYL"},
{label: "RHINO-BEBE"},
{label: "RHINOBIOTAL"},
{label: "RHINOCEANE"},
{label: "RHINOCORT"},
{label: "RHINOCORT TURBUHALER"},
{label: "RHINOFEB"},
{label: "RHINOFEBRAL"},
{label: "RHINOFEBRAL JOUR ET NUIT"},
{label: "RHINOFEBRAL SANS SUCRE"},
{label: "RHINOFLUIMUCIL"},
{label: "RHINOMAXIL"},
{label: "RHINORUM"},
{label: "RHINOTROPHYL"},
{label: "RHINUREFLEX"},
{label: "RHINYL"},
{label: "RHODODENDRON CHRYSANTHUM BOIRON"},
{label: "RHODODENDRON CHRYSANTHUM LEHNING"},
{label: "RHODODENDRON FERRUGINEUM BOIRON"},
{label: "RHODODENDRON FERRUGINEUM LEHNING"},
{label: "RHODODENDRON FERRUGINEUM WELEDA"},
{label: "RHODONITE BOIRON"},
{label: "RHOPHYLAC"},
{label: "RHUBARBE LAFRAN"},
{label: "RHUMAGRIP"},
{label: "RHUMIL"},
{label: "RHUS AROMATICA BOIRON"},
{label: "RHUS TOXICODENDRON BOIRON"},
{label: "RHUS TOXICODENDRON COMPLEXE N°80"},
{label: "RHUS TOXICODENDRON COMPOSE"},
{label: "RHUS TOXICODENDRON FERRIER"},
{label: "RHUS TOXICODENDRON LEHNING"},
{label: "RHUS TOXICODENDRON WELEDA"},
{label: "RHUS VERNIX BOIRON"},
{label: "RHUSEDAL"},
{label: "RIABAL ENFANTS"},
{label: "RIAMET"},
{label: "RIASTAP"},
{label: "RIBATRAN"},
{label: "RIBAVIRINE ARROW"},
{label: "RIBAVIRINE BIOGARAN"},
{label: "RIBAVIRINE IDD"},
{label: "RIBAVIRINE MYLAN"},
{label: "RIBAVIRINE MYLAN PHARMA"},
{label: "RIBAVIRINE SANDOZ"},
{label: "RIBAVIRINE TEVA"},
{label: "RIBAVIRINE TEVA PHARMA BV"},
{label: "RIBAVIRINE VIATRIS"},
{label: "RIBAVIRINE ZENTIVA"},
{label: "RIBAVIRINE ZYDUS FRANCE"},
{label: "RIBAVOX"},
{label: "RIBES NIGRUM"},
{label: "RIBES NIGRUM MACERAT GLYCERINE"},
{label: "RIBIOCIL"},
{label: "RIBOMUNYL"},
{label: "RIBOSOFOL"},
{label: "RICINUS COMMUNIS BOIRON"},
{label: "RICINUS COMMUNIS LEHNING"},
{label: "RICINUS COMMUNIS WELEDA"},
{label: "RICINUS COMPOSE"},
{label: "RICRIDENE"},
{label: "RICRIDENE ENFANTS"},
{label: "RIDAURAN"},
{label: "RIFADINE"},
{label: "RIFADINE IV"},
{label: "RIFAMYCINE CHIBRET"},
{label: "RIFATER"},
{label: "RIFINAH"},
{label: "RILMENIDINE ACTAVIS"},
{label: "RILMENIDINE ALTER"},
{label: "RILMENIDINE ARROW"},
{label: "RILMENIDINE BIOGARAN"},
{label: "RILMENIDINE BIOPROJET"},
{label: "RILMENIDINE CRISTERS"},
{label: "RILMENIDINE EG"},
{label: "RILMENIDINE EVOLUGEN"},
{label: "RILMENIDINE IDL"},
{label: "RILMENIDINE QUALIMED"},
{label: "RILMENIDINE RATIOPHARM"},
{label: "RILMENIDINE SANDOZ"},
{label: "RILMENIDINE SUN"},
{label: "RILMENIDINE TEVA"},
{label: "RILMENIDINE VIATRIS"},
{label: "RILMENIDINE ZENTIVA"},
{label: "RILMENIDINE ZYDUS"},
{label: "RILUTEK"},
{label: "RILUZOLE ARROW"},
{label: "RILUZOLE BIOGARAN"},
{label: "RILUZOLE EG"},
{label: "RILUZOLE OPENING PHARMA"},
{label: "RILUZOLE PMCS"},
{label: "RILUZOLE SANDOZ"},
{label: "RILUZOLE SUN"},
{label: "RILUZOLE TEVA"},
{label: "RILUZOLE VIATRIS"},
{label: "RILUZOLE ZENTIVA"},
{label: "RIMACTAN"},
{label: "RIMACTAN IV"},
{label: "RIMACTAZID"},
{label: "RIMCURE"},
{label: "RIMENDIA"},
{label: "RIMETAZE"},
{label: "RIMEXEL"},
{label: "RIMIFON"},
{label: "RIMSTAR"},
{label: "RINAZINE"},
{label: "RINDER"},
{label: "RINGER AGUETTANT"},
{label: "RINGER B. BRAUN MELSUNGEN"},
{label: "RINGER BIOSEDRA"},
{label: "RINGER BRUNEAU"},
{label: "RINGER COOPER"},
{label: "RINGER FRESENIUS"},
{label: "RINGER LACTATE AGUETTANT"},
{label: "RINGER LACTATE B BRAUN"},
{label: "RINGER LACTATE B. BRAUN"},
{label: "RINGER LACTATE BAXTER"},
{label: "RINGER LACTATE BIOLUZ"},
{label: "RINGER LACTATE ET GLUCOSE"},
{label: "RINGER LACTATE FRESENIUS"},
{label: "RINGER LACTATE FRESENIUS KABI FRANCE"},
{label: "RINGER LACTATE GLUCOSE"},
{label: "RINGER LACTATE GLUCOSE A"},
{label: "RINGER LACTATE LAVOISIER"},
{label: "RINGER LACTATE MACOPHARMA"},
{label: "RINGER LACTATE PERFUFLEX"},
{label: "RINGER LACTATE PHARMACIE CENTRALE DES ARMEES"},
{label: "RINGER LACTATE VIAFLO"},
{label: "RINGER LAVOISIER"},
{label: "RINGER MACO PHARMA"},
{label: "RINGER VIAFLO"},
{label: "RINOCLENIL"},
{label: "RINUREL"},
{label: "RINUTAN"},
{label: "RINVOQ"},
{label: "RIOPAN"},
{label: "RISEDRONATE ACCORD"},
{label: "RISEDRONATE ACTAVIS"},
{label: "RISEDRONATE AHCL"},
{label: "RISEDRONATE ALTER"},
{label: "RISEDRONATE ALTISO"},
{label: "RISEDRONATE ARROW"},
{label: "RISEDRONATE AUROBINDO"},
{label: "RISEDRONATE BGR"},
{label: "RISEDRONATE BIOGARAN"},
{label: "RISEDRONATE BLUEFISH"},
{label: "RISEDRONATE CIPLA"},
{label: "RISEDRONATE CRISTERS"},
{label: "RISEDRONATE CRISTERS PHARMA"},
{label: "RISEDRONATE EG"},
{label: "RISEDRONATE EVOLUGEN"},
{label: "RISEDRONATE ISOMED"},
{label: "RISEDRONATE MYLAN PHARMA"},
{label: "RISEDRONATE MYPLIX"},
{label: "RISEDRONATE NIALEX"},
{label: "RISEDRONATE NOR"},
{label: "RISEDRONATE OPENING PHARMA"},
{label: "RISEDRONATE PFIZER"},
{label: "RISEDRONATE PHARMANEL"},
{label: "RISEDRONATE QUALIMED"},
{label: "RISEDRONATE RANBAXY"},
{label: "RISEDRONATE RATIOPHARM"},
{label: "RISEDRONATE RPG"},
{label: "RISEDRONATE SANDOZ"},
{label: "RISEDRONATE TEVA SANTE"},
{label: "RISEDRONATE VIATRIS"},
{label: "RISEDRONATE WARNER CHILCOTT"},
{label: "RISEDRONATE ZENTIVA"},
{label: "RISEDRONATE ZENTIVA K.S."},
{label: "RISEDRONATE ZENTIVA LAB"},
{label: "RISEDRONATE ZYDUS"},
{label: "RISEDRONATE ZYDUS FRANCE"},
{label: "RISEGARTHEN"},
{label: "RISETAR"},
{label: "RISORDAN"},
{label: "RISORDAN L.P."},
{label: "RISORDAN LP"},
{label: "RISPERDAL"},
{label: "RISPERDALCONSTA L.P."},
{label: "RISPERDALORO"},
{label: "RISPERIDONE ALMUS"},
{label: "RISPERIDONE ALTER"},
{label: "RISPERIDONE ARROW"},
{label: "RISPERIDONE ARROW LAB"},
{label: "RISPERIDONE BIOGALENIQUE"},
{label: "RISPERIDONE BIOGARAN"},
{label: "RISPERIDONE CLL PHARMA"},
{label: "RISPERIDONE CRISTERS"},
{label: "RISPERIDONE DCI PHARMA"},
{label: "RISPERIDONE EG"},
{label: "RISPERIDONE EVOLUGEN"},
{label: "RISPERIDONE GNR"},
{label: "RISPERIDONE MEDIS"},
{label: "RISPERIDONE MYLAN"},
{label: "RISPERIDONE MYLAN PHARMA"},
{label: "RISPERIDONE OPENING PHARMA"},
{label: "RISPERIDONE PANPHARMA"},
{label: "RISPERIDONE PFIZER"},
{label: "RISPERIDONE QUALIMED"},
{label: "RISPERIDONE QUALIMED GENERIQUES"},
{label: "RISPERIDONE RANBAXY"},
{label: "RISPERIDONE RATIO"},
{label: "RISPERIDONE RATIOPHARM"},
{label: "RISPERIDONE RPG"},
{label: "RISPERIDONE SANDOZ"},
{label: "RISPERIDONE TEVA"},
{label: "RISPERIDONE TEVA L.P."},
{label: "RISPERIDONE TEVA SANTE"},
{label: "RISPERIDONE VENIPHARM"},
{label: "RISPERIDONE VIATRIS"},
{label: "RISPERIDONE ZENTIVA"},
{label: "RISPERIDONE ZYDUS"},
{label: "RITALINE"},
{label: "RITALINE L.P."},
{label: "RITONAVIR ARROW"},
{label: "RITONAVIR DEXTREG"},
{label: "RITONAVIR MYLAN"},
{label: "RIVAROXABAN ABDI"},
{label: "RIVAROXABAN ALMUS"},
{label: "RIVAROXABAN ALTER"},
{label: "RIVAROXABAN APC"},
{label: "RIVAROXABAN ARROW"},
{label: "RIVAROXABAN BAYER"},
{label: "RIVAROXABAN BGR"},
{label: "RIVAROXABAN BIOGARAN"},
{label: "RIVAROXABAN BLUEPHARMA"},
{label: "RIVAROXABAN EG"},
{label: "RIVAROXABAN EVOLUGEN"},
{label: "RIVAROXABAN GNR"},
{label: "RIVAROXABAN HEC PHARM"},
{label: "RIVAROXABAN HUAHAI"},
{label: "RIVAROXABAN HWI PHARMA SERVICES"},
{label: "RIVAROXABAN INTAS"},
{label: "RIVAROXABAN KRKA"},
{label: "RIVAROXABAN MICRO LABS"},
{label: "RIVAROXABAN MYLAN"},
{label: "RIVAROXABAN MYLAN KIT DINITIATION DE TRAITEMENT"},
{label: "RIVAROXABAN PHARMAKI"},
{label: "RIVAROXABAN POLPHARMA"},
{label: "RIVAROXABAN REDDY PHARMA"},
{label: "RIVAROXABAN SANDOZ"},
{label: "RIVAROXABAN TEVA"},
{label: "RIVAROXABAN ZENTIVA"},
{label: "RIVASTIGMINE ACTAVIS"},
{label: "RIVASTIGMINE ARROW"},
{label: "RIVASTIGMINE BIOGARAN"},
{label: "RIVASTIGMINE EG"},
{label: "RIVASTIGMINE EG LABO"},
{label: "RIVASTIGMINE MEDIPHA SANTE"},
{label: "RIVASTIGMINE QUALIMED"},
{label: "RIVASTIGMINE SANDOZ"},
{label: "RIVASTIGMINE SYNTHON"},
{label: "RIVASTIGMINE TEVA"},
{label: "RIVASTIGMINE TEVA SANTE"},
{label: "RIVASTIGMINE TORRENT"},
{label: "RIVASTIGMINE VIATRIS"},
{label: "RIVASTIGMINE WINTHROP"},
{label: "RIVASTIGMINE ZENTIVA"},
{label: "RIVASTIGMINE ZYDUS"},
{label: "RIVOTRIL"},
{label: "RIXATHON"},
{label: "RIXIMYO"},
{label: "RIXUBIS"},
{label: "RIZATRIPTAN ACTAVIS"},
{label: "RIZATRIPTAN ARROW"},
{label: "RIZATRIPTAN ARROW GENERIQUES"},
{label: "RIZATRIPTAN ARROW LAB"},
{label: "RIZATRIPTAN AUROBINDO"},
{label: "RIZATRIPTAN AUTHOU"},
{label: "RIZATRIPTAN BIOGARAN"},
{label: "RIZATRIPTAN EG"},
{label: "RIZATRIPTAN GLENMARK"},
{label: "RIZATRIPTAN MYLAN"},
{label: "RIZATRIPTAN NIALEX"},
{label: "RIZATRIPTAN SANDOZ"},
{label: "RIZATRIPTAN TEVA"},
{label: "RIZATRIPTAN TORRENT"},
{label: "RIZATRIPTAN VIATRIS"},
{label: "RIZLASOL"},
{label: "RIZMOIC"},
{label: "RIZOGAL"},
{label: "ROACCUTANE"},
{label: "ROACTEMRA"},
{label: "ROBAMAG"},
{label: "ROBINIA PSEUDO-ACACIA BOIRON"},
{label: "ROBINIA PSEUDO-ACACIA LEHNING"},
{label: "ROCALTROL"},
{label: "ROCEPHINE"},
{label: "ROCGEL"},
{label: "ROCKLES"},
{label: "ROCLANDA"},
{label: "ROCMALINE"},
{label: "ROCTAVIAN"},
{label: "ROCURONIUM AGUETTANT"},
{label: "ROCURONIUM B.BRAUN"},
{label: "ROCURONIUM HIKMA"},
{label: "ROCURONIUM HOSPIRA"},
{label: "ROCURONIUM KABI"},
{label: "ROCURONIUM KALCEKS"},
{label: "ROCURONIUM NORIDEM"},
{label: "ROCURONIUM PFIZER"},
{label: "ROCURONIUM TEVA"},
{label: "ROCURONIUM VIATRIS"},
{label: "RODOGYL"},
{label: "ROFERON-A"},
{label: "ROFLUAL"},
{label: "ROHYPNOL"},
{label: "ROLACTONE MICROFINE"},
{label: "ROLUFTA ELLIPTA"},
{label: "ROMARENE"},
{label: "ROMARIN NATURA MEDICA"},
{label: "ROMARINEX"},
{label: "ROMILAR"},
{label: "ROMILAR ENFANTS"},
{label: "RONALOL"},
{label: "RONAPREVE"},
{label: "ROPINIROLE ACCORD"},
{label: "ROPINIROLE ARROW"},
{label: "ROPINIROLE ARROW GENERIQUES"},
{label: "ROPINIROLE ARROW LP"},
{label: "ROPINIROLE BIOGARAN"},
{label: "ROPINIROLE BIOGARAN LP"},
{label: "ROPINIROLE EG"},
{label: "ROPINIROLE EG LP"},
{label: "ROPINIROLE GENERIMEX"},
{label: "ROPINIROLE GLENMARCK GENERICS"},
{label: "ROPINIROLE GNR"},
{label: "ROPINIROLE GSK"},
{label: "ROPINIROLE JENSON PHARMACEUTICAL SERVICES"},
{label: "ROPINIROLE KRKA LP"},
{label: "ROPINIROLE MYLAN PHARMA LP"},
{label: "ROPINIROLE OPENING PHARMA"},
{label: "ROPINIROLE PAUCOURT"},
{label: "ROPINIROLE PAUCOURT LP"},
{label: "ROPINIROLE QUALIMED"},
{label: "ROPINIROLE RANBAXY"},
{label: "ROPINIROLE RATIOPHARM"},
{label: "ROPINIROLE SAINT-GERMAIN"},
{label: "ROPINIROLE SANDOZ"},
{label: "ROPINIROLE SANDOZ LP"},
{label: "ROPINIROLE STADA LP"},
{label: "ROPINIROLE TEVA"},
{label: "ROPINIROLE TEVA LP"},
{label: "ROPINIROLE VIATRIS"},
{label: "ROPINIROLE WINTHROP"},
{label: "ROPINIROLE ZENTIVA LP"},
{label: "ROPINIROLE ZYDUS"},
{label: "ROPIVACAINE ACTAVIS"},
{label: "ROPIVACAINE ALTAN"},
{label: "ROPIVACAINE ARROW"},
{label: "ROPIVACAINE B BRAUN"},
{label: "ROPIVACAINE B.BRAUN"},
{label: "ROPIVACAINE HOSPIRA"},
{label: "ROPIVACAINE INFOMED"},
{label: "ROPIVACAINE IWA CONSULTING"},
{label: "ROPIVACAINE KABI"},
{label: "ROPIVACAINE NORIDEM"},
{label: "ROPIVACAINE PHARMATHEN"},
{label: "ROPIVACAINE RATIOPHARM"},
{label: "ROPIVACAINE READYFUSOR"},
{label: "ROPIVACAINE RENAUDIN"},
{label: "ROPIVACAINE SANDOZ"},
{label: "ROPIVACAINE TEVA"},
{label: "ROPIVACAINE VIATRIS"},
{label: "ROPIVACAÏNE KABI"},
{label: "ROSA CANINA"},
{label: "ROSA CANINA BOIRON"},
{label: "ROSAMPLINE"},
{label: "ROSICED"},
{label: "ROSMARINUS OFFICINALIS"},
{label: "ROSMARINUS OFFICINALIS BOIRON"},
{label: "ROSMARINUS OFFICINALIS WELEDA"},
{label: "ROSUVASTATINE ACCORD"},
{label: "ROSUVASTATINE ALMUS"},
{label: "ROSUVASTATINE ALTER"},
{label: "ROSUVASTATINE ARROW"},
{label: "ROSUVASTATINE BGR"},
{label: "ROSUVASTATINE BIOGARAN"},
{label: "ROSUVASTATINE CENTRIENT"},
{label: "ROSUVASTATINE CRISTERS"},
{label: "ROSUVASTATINE EG"},
{label: "ROSUVASTATINE EVOLUGEN"},
{label: "ROSUVASTATINE GALENICUM"},
{label: "ROSUVASTATINE HEC PHARM"},
{label: "ROSUVASTATINE KRKA"},
{label: "ROSUVASTATINE MACLEODS"},
{label: "ROSUVASTATINE SANDOZ"},
{label: "ROSUVASTATINE SIGILLATA"},
{label: "ROSUVASTATINE SUN"},
{label: "ROSUVASTATINE TEVA"},
{label: "ROSUVASTATINE TEVA SANTE"},
{label: "ROSUVASTATINE UMEDICA"},
{label: "ROSUVASTATINE VIATRIS"},
{label: "ROSUVASTATINE VIVANTA"},
{label: "ROSUVASTATINE ZENTIVA"},
{label: "ROSUVASTATINE ZYDUS"},
{label: "ROSUVASTATINE ZYDUS FRANCE"},
{label: "ROSUVASTATINE/EZETIMIBE MYLAN"},
{label: "ROSUVASTATINECENTRIENT"},
{label: "ROTARIX"},
{label: "ROTATEQ"},
{label: "ROUVAX"},
{label: "ROVALCYTE"},
{label: "ROVAMYCINE"},
{label: "ROVAMYCINE GRANDS ENFANTS"},
{label: "ROVAMYCINE NOURRISSONS"},
{label: "ROVAMYCINE NOURRISSONS ET ENFANTS"},
{label: "ROVIGON"},
{label: "ROWASA"},
{label: "ROXAGAST LP"},
{label: "ROXET L.P."},
{label: "ROXITHROMYCINE ACTAVIS"},
{label: "ROXITHROMYCINE ARROW"},
{label: "ROXITHROMYCINE BIOGARAN"},
{label: "ROXITHROMYCINE EG"},
{label: "ROXITHROMYCINE G GAM"},
{label: "ROXITHROMYCINE IVAX"},
{label: "ROXITHROMYCINE MG PHARMA"},
{label: "ROXITHROMYCINE MYLAN"},
{label: "ROXITHROMYCINE NOR"},
{label: "ROXITHROMYCINE QUALIMED"},
{label: "ROXITHROMYCINE RANBAXY"},
{label: "ROXITHROMYCINE RATIOPHARM"},
{label: "ROXITHROMYCINE SANDOZ"},
{label: "ROXITHROMYCINE TEVA"},
{label: "ROXITHROMYCINE WINTHROP"},
{label: "ROXITHROMYCINE ZENTIVA"},
{label: "ROXITHROMYCINE ZYDUS"},
{label: "ROZACREME"},
{label: "ROZAGEL"},
{label: "ROZE"},
{label: "ROZEX"},
{label: "ROZLYTREK"},
{label: "RUBIA TINCTORIA BOIRON"},
{label: "RUBIEDOL"},
{label: "RUBITAVIS"},
{label: "RUBOZINC"},
{label: "RUBRACA"},
{label: "RUCONEST"},
{label: "RUDI ROUVAX"},
{label: "RUDIVAX"},
{label: "RUFOL"},
{label: "RUKOBIA"},
{label: "RULID"},
{label: "RUMAFIT"},
{label: "RUMEX CRISPUS BOIRON"},
{label: "RUMEX CRISPUS LEHNING"},
{label: "RUMEX CRISPUS WELEDA"},
{label: "RUMEX PATIENTIA BOIRON"},
{label: "RUMICINE"},
{label: "RUPATADINE ARROW"},
{label: "RUPATADINE BIOGARAN"},
{label: "RUPATADINE BOUCHARA-RECORDATI"},
{label: "RUPATADINE EG"},
{label: "RUPATADINE J. URIACH"},
{label: "RUPATADINE VIATRIS"},
{label: "RUPATADINE ZENTIVA"},
{label: "RUPTALGOR"},
{label: "RUPTON"},
{label: "RUPTON CHRONULES"},
{label: "RUSCOL"},
{label: "RUSCUS ACULEATUS BOIRON"},
{label: "RUTA GRAVEOLENS BOIRON"},
{label: "RUTA GRAVEOLENS LEHNING"},
{label: "RUTA GRAVEOLENS WELEDA"},
{label: "RUTOVINCINE"},
{label: "RUXIENCE"},
{label: "RXULTI"},
{label: "RYALTRIS"},
{label: "RYBELSUS"},
{label: "RYBREVANT"},
{label: "RYBRILA"},
{label: "RYDAPT"},
{label: "RYEQO"},
{label: "RYLIGENCY"},
{label: "RYMPHYSIA"},
{label: "RYTHMODAN"},
{label: "RYTHMOL"},
{label: "RYZODEG"},
{label: "SABADILLA LEHNING"},
{label: "SABADILLA OFFICINARUM BOIRON"},
{label: "SABADILLA WELEDA"},
{label: "SABAL SERRULATA BOIRON"},
{label: "SABAL SERRULATA COMPOSE"},
{label: "SABAL SERRULATA LEHNING"},
{label: "SABAL SERRULATA TEINTURE MERE BOIRON"},
{label: "SABAL SERRULATA WELEDA"},
{label: "SABINA BOIRON"},
{label: "SABINA LEHNING"},
{label: "SABORO"},
{label: "SABRIL"},
{label: "SACCHARUM LACTIS BOIRON"},
{label: "SACNEL"},
{label: "SACOLENE"},
{label: "SACOLENE PEDIATRIQUE"},
{label: "SAFLUTAN"},
{label: "SAGAMUCOL ADULTES"},
{label: "SAGAMUCOL ENFANTS"},
{label: "SAGATOUX"},
{label: "SAINTBOIS ADULTES"},
{label: "SAINTBOIS ENFANTS"},
{label: "SAIZEN"},
{label: "SAIZEN CLICKEASY"},
{label: "SALAGEN"},
{label: "SALAZOPYRINE"},
{label: "SALBUMOL"},
{label: "SALBUMOL FORT"},
{label: "SALBUPULVINAL"},
{label: "SALBUTAMOL ARROW"},
{label: "SALBUTAMOL GSK"},
{label: "SALBUTAMOL INNOVATA BIOMED CLICKHALER"},
{label: "SALBUTAMOL NORIDEM"},
{label: "SALBUTAMOL PAUCOURT"},
{label: "SALBUTAMOL RENAUDIN"},
{label: "SALBUTAMOL SAINT GERMAIN"},
{label: "SALBUTAMOL TEVA"},
{label: "SALBUTAMOL VIATRIS"},
{label: "SALBUTINE"},
{label: "SALBUTINE L P"},
{label: "SALBUTINE ROTACAPS"},
{label: "SALBUTINE ROTADISKS"},
{label: "SALFLUAIR EASYHALER"},
{label: "SALGYDAL A LA NORAMIDOPYRINE"},
{label: "SALGYDAL A LA NORAMIDOPYRINE ADULTES"},
{label: "SALGYDAL A LA NORAMIDOPYRINE ENFANTS"},
{label: "SALIBRA ADULTES"},
{label: "SALIBRA CHOLINE"},
{label: "SALIBRA ENFANTS"},
{label: "SALICAIRINE"},
{label: "SALICYLICUM ACIDUM BOIRON"},
{label: "SALIPRAN"},
{label: "SALIREL"},
{label: "SALIREL DISKUS"},
{label: "SALIX ALBA BOIRON"},
{label: "SALMESON"},
{label: "SALVACYL L.P."},
{label: "SALVIA OFFICINALIS"},
{label: "SALVIA OFFICINALIS BOIRON"},
{label: "SAMBUCUS NIGRA BOIRON"},
{label: "SAMBUCUS NIGRA LEHNING"},
{label: "SAMBUCUS NIGRA WELEDA"},
{label: "SAMSCA"},
{label: "SANCUSO"},
{label: "SANDIMMUN"},
{label: "SANDOGLOBULINE"},
{label: "SANDOSTATINE"},
{label: "SANDOSTATINE L.P."},
{label: "SANGUINARIA CANADENSIS"},
{label: "SANGUINARIA CANADENSIS BOIRON"},
{label: "SANGUINARIA CANADENSIS LEHNING"},
{label: "SANGUINARINA NITRICA BOIRON"},
{label: "SANICULA EUROPAEA BOIRON"},
{label: "SANMIGRAN"},
{label: "SANOGYL AU FLUOR AROME BANANE"},
{label: "SANOGYL AU FLUOR AROME CERISE"},
{label: "SANOGYL AU FLUOR AROME CITRON"},
{label: "SANOGYL AU FLUOR AROME FRAISE"},
{label: "SANOGYL AU FLUOR AROME MENTHE"},
{label: "SANOGYL BIFLUOR"},
{label: "SANOGYL BLANC FLUOR"},
{label: "SANRUBINE"},
{label: "SANTAHERBA"},
{label: "SANTAMED"},
{label: "SANTAMED N9"},
{label: "SANTANE"},
{label: "SANTANE A4"},
{label: "SANTANE C"},
{label: "SANTANE D5"},
{label: "SANTANE F10"},
{label: "SANTANE H7"},
{label: "SANTANE R8"},
{label: "SANTANE V3"},
{label: "SANTURIL"},
{label: "SAPHNELO"},
{label: "SAPONARIA COMPOSE"},
{label: "SAPONARIA OFFICINALIS BOIRON"},
{label: "SAPRAMOL"},
{label: "SAPROPTERINE ARROW"},
{label: "SAPROPTERINE DIPHARMA"},
{label: "SAPROPTERINE TEVA"},
{label: "SARCLISA"},
{label: "SARCOLACTICUM ACIDUM BOIRON"},
{label: "SARGEMAG"},
{label: "SARGENOR"},
{label: "SARGENOR A LA VITAMINE C"},
{label: "SARGENOR A LA VITAMINE C SANS SUCRE"},
{label: "SARGENOR SANS SUCRE"},
{label: "SARGEPIRINE"},
{label: "SARGO"},
{label: "SARGRAMOSTIM LEDERLE"},
{label: "SARIDON"},
{label: "SAROPIDE"},
{label: "SAROTEX"},
{label: "SARSAPARILLA BOIRON"},
{label: "SARSAPARILLA LEHNING"},
{label: "SARVIT A LA LYSINE"},
{label: "SATIVEX"},
{label: "SAUBACHROME"},
{label: "SAUGE ETHYPHARM"},
{label: "SAVARINE"},
{label: "SAVENE"},
{label: "SAWIS"},
{label: "SAXAGLIPTINE SANDOZ"},
{label: "SAXENDA"},
{label: "SAXIFRAGA GRANULATA BOIRON"},
{label: "SAYANA"},
{label: "SAYANAPRESS"},
{label: "SCANDONEST"},
{label: "SCARLENE"},
{label: "SCEFID"},
{label: "SCEMBLIX"},
{label: "SCHERITONIN"},
{label: "SCHOUM"},
{label: "SCILLA MARITIMA BOIRON"},
{label: "SCINTIMUN"},
{label: "SCLEREMO IV"},
{label: "SCLEROCALCINE"},
{label: "SCMED"},
{label: "SCOBUREN"},
{label: "SCOPODERM TTS"},
{label: "SCOPOLAMINE BUTYLBROMURE KALCEKS"},
{label: "SCOPOLAMINE COOPER"},
{label: "SCORODITE WELEDA"},
{label: "SCROFULARIA COMPOSE"},
{label: "SCROFULARIA NODOSA BOIRON"},
{label: "SCROFULARIA NODOSA LEHNING"},
{label: "SCUTELLARIA LATERIFLORA BOIRON"},
{label: "SEASONIQUE"},
{label: "SEBIPROX"},
{label: "SEBIVO"},
{label: "SEBRANE MAUX DE GORGE"},
{label: "SEBRANE RHUME"},
{label: "SEBRANE TOUX SECHE"},
{label: "SECALE CORNUTUM BOIRON"},
{label: "SECALE CORNUTUM LEHNING"},
{label: "SECALIP"},
{label: "SECNIDAZOLE ETHYPHARM"},
{label: "SECNOL"},
{label: "SECTRAL"},
{label: "SECTRAL LP"},
{label: "SEDACOLLYRE"},
{label: "SEDACOLLYRE CETHEXONIUM"},
{label: "SEDACOLLYRE CETYLPYRIDINIUM"},
{label: "SEDALANDE"},
{label: "SEDALOZIA"},
{label: "SEDALTANE"},
{label: "SEDAPLAIE"},
{label: "SEDAPULPE"},
{label: "SEDARENE"},
{label: "SEDARENE ADULTES"},
{label: "SEDARENE ENFANTS"},
{label: "SEDARTRYL"},
{label: "SEDASPIR"},
{label: "SEDATEX"},
{label: "SEDATIF PC"},
{label: "SEDATIF TIBER"},
{label: "SEDERMYL"},
{label: "SEDINAX"},
{label: "SEDOCARENA"},
{label: "SEDOFLOR"},
{label: "SEDONALGINE"},
{label: "SEDOPAL"},
{label: "SEDOPHON PECTORAL SANS SUCRE ADULTES"},
{label: "SEDOPTIC"},
{label: "SEDORRHOIDE CRISE HEMORROIDAIRE"},
{label: "SEDORRHOIDE VEINOTONIQUE"},
{label: "SEDUM ACRE BOIRON"},
{label: "SEEBRI BREEZHALER"},
{label: "SEGENOA"},
{label: "SEGLOR"},
{label: "SEGLOR LYOC"},
{label: "SEGLUROMET"},
{label: "SELEGILINE BIOGARAN"},
{label: "SELEGILINE BIOGLAN"},
{label: "SELEGILINE CLL PHARMA"},
{label: "SELEGILINE CRISTERS"},
{label: "SELEGILINE LEURQUIN"},
{label: "SELEGILINE MILGEN"},
{label: "SELEGILINE MYLAN"},
{label: "SELEGILINE ORION"},
{label: "SELEGILINE QUALIMED"},
{label: "SELEGILINE RATIOPHARM"},
{label: "SELEGILINE TEVA"},
{label: "SELENION"},
{label: "SELENIUM COMPLEXE N°99"},
{label: "SELENIUM INJECTABLE"},
{label: "SELENIUM METALLICUM BOIRON"},
{label: "SELENIUM METALLICUM LEHNING"},
{label: "SELENIUM MICROSOL"},
{label: "SELENIUM OLIGOSOL"},
{label: "SELENIUM PANPHARMA"},
{label: "SELEXID"},
{label: "SELGINE"},
{label: "SELINCRO"},
{label: "SELLIREX"},
{label: "SELOKEN"},
{label: "SELOKEN L P"},
{label: "SELOZOK LP"},
{label: "SELS ANDREWS"},
{label: "SELS MAGNESIES MAXIM"},
{label: "SELSUN"},
{label: "SELUTRIO"},
{label: "SEMAP"},
{label: "SEMGLEE"},
{label: "SEMPAVOX"},
{label: "SEMPERVIVUM TECTORUM BOIRON"},
{label: "SEMPREX"},
{label: "SENE DE BELLOC"},
{label: "SENEGA BOIRON"},
{label: "SENEGA LEHNING"},
{label: "SENNA BOIRON"},
{label: "SENNA LEHNING"},
{label: "SENNA WELEDA"},
{label: "SENOKOT"},
{label: "SENOPHILE"},
{label: "SENSIVISION AU MELILOT"},
{label: "SENSIVISION AU PLANTAIN"},
{label: "SEPIA COMPLEXE N°20"},
{label: "SEPIA COMPOSE BOIRON"},
{label: "SEPIA OFFICINALIS BOIRON"},
{label: "SEPIA OFFICINALIS LEHNING"},
{label: "SEPIA OFFICINALIS WELEDA"},
{label: "SEPTANEST"},
{label: "SEPTEAL"},
{label: "SEPTIAPAISYL"},
{label: "SEPTICHROME"},
{label: "SEPTILINE"},
{label: "SEPTISEPT"},
{label: "SEPTISOL"},
{label: "SEPTIVON"},
{label: "SEPTIVONCARE ANTISEPTIQUE CHLORHEXIDINE"},
{label: "SEPTOMIXINE"},
{label: "SEPTOPAL"},
{label: "SEQUOIA GIGANTEA BOIRON"},
{label: "SERC"},
{label: "SERDOLECT"},
{label: "SERECOR"},
{label: "SERENIVAL"},
{label: "SERENYL"},
{label: "SEREPROSTA"},
{label: "SERESTA"},
{label: "SERETIDE"},
{label: "SERETIDE DISKUS"},
{label: "SEREVENT"},
{label: "SEREVENT DISKUS"},
{label: "SERIEL"},
{label: "SERILIA"},
{label: "SERMION"},
{label: "SERMION LYOC"},
{label: "SERODOSES"},
{label: "SEROPLEX"},
{label: "SEROPLEXORO"},
{label: "SEROPRAM"},
{label: "SERORHINOL"},
{label: "SEROTONINUM BOIRON"},
{label: "SEROXYDAR"},
{label: "SERSEMIC"},
{label: "SERTACONAZOLE BESINS"},
{label: "SERTACONAZOLE SUBSTIPHARM"},
{label: "SERTRALINE ACTAVIS"},
{label: "SERTRALINE ALMUS"},
{label: "SERTRALINE ALS"},
{label: "SERTRALINE ALTER"},
{label: "SERTRALINE ARROW"},
{label: "SERTRALINE ARROW LAB"},
{label: "SERTRALINE AUROBINDO"},
{label: "SERTRALINE BERAL"},
{label: "SERTRALINE BIOGARAN"},
{label: "SERTRALINE CLL PHARMA"},
{label: "SERTRALINE CRISTERS"},
{label: "SERTRALINE CRISTERS PHARMA"},
{label: "SERTRALINE EG"},
{label: "SERTRALINE EG LABO"},
{label: "SERTRALINE EVOLUGEN"},
{label: "SERTRALINE G GAM"},
{label: "SERTRALINE ISOMED"},
{label: "SERTRALINE MYLAN"},
{label: "SERTRALINE O.P.I.H. FRANCE"},
{label: "SERTRALINE PFIZER"},
{label: "SERTRALINE PHARMA LICENCE"},
{label: "SERTRALINE PHARMAKI GENERICS"},
{label: "SERTRALINE PHR LAB"},
{label: "SERTRALINE QUALIMED"},
{label: "SERTRALINE RATIOPHARM"},
{label: "SERTRALINE RPG"},
{label: "SERTRALINE SANDOZ"},
{label: "SERTRALINE TEVA"},
{label: "SERTRALINE VIATRIS"},
{label: "SERTRALINE VIATRIS GENERIQUES"},
{label: "SERTRALINE ZENTIVA"},
{label: "SERTRALINE ZYDUS"},
{label: "SERTRALINE ZYDUS FRANCE"},
{label: "SERUM ANTICOLIBACILLAIRE BOIRON"},
{label: "SERUM ANTICOLIBACILLAIRE LEHNING"},
{label: "SERUM ANTIDIPHTERIQUE PASTEUR 10.000 UI"},
{label: "SERUM D'ANGUILLE BOIRON"},
{label: "SERUM DE YERSIN BOIRON"},
{label: "SERUM DE YERSIN LEHNING"},
{label: "SERUM EQUI BOIRON"},
{label: "SETOFILM"},
{label: "SEVE DE BOULEAU BOIRON"},
{label: "SEVELAMER CARBONATE ARROW"},
{label: "SEVELAMER CARBONATE BIOGARAN"},
{label: "SEVELAMER CARBONATE EG"},
{label: "SEVELAMER CARBONATE MYLAN"},
{label: "SEVELAMER CARBONATE MYLAN PHARMA"},
{label: "SEVELAMER CARBONATE SANDOZ"},
{label: "SEVELAMER CARBONATE TEVA"},
{label: "SEVELAMER CARBONATE ZENTIVA"},
{label: "SEVIKAR"},
{label: "SEVOFLURANE BAXTER"},
{label: "SEVOFLURANE MYLAN"},
{label: "SEVORANE"},
{label: "SEVREDOL"},
{label: "SHAK"},
{label: "SHINGRIX"},
{label: "SIALANAR"},
{label: "SIBELIUM"},
{label: "SIBNAYAL"},
{label: "SIBUTRAL"},
{label: "SICAZINE"},
{label: "SICCAFLUID"},
{label: "SIEGESBECKIA ORIENTALIS BOIRON"},
{label: "SIFROL"},
{label: "SIGIMEX"},
{label: "SIGNIFOR"},
{label: "SIKLOS"},
{label: "SILDENAFIL ACCORD"},
{label: "SILDENAFIL ACTAVIS"},
{label: "SILDENAFIL AJANTA PHARMA"},
{label: "SILDENAFIL ALMUS"},
{label: "SILDENAFIL ARISTO"},
{label: "SILDENAFIL ARROW"},
{label: "SILDENAFIL ARROW LAB"},
{label: "SILDENAFIL BGR"},
{label: "SILDENAFIL BIOGARAN"},
{label: "SILDENAFIL CLEXNI"},
{label: "SILDENAFIL CRISTERS"},
{label: "SILDENAFIL CRISTERS PHARMA"},
{label: "SILDENAFIL ECLONIRTA"},
{label: "SILDENAFIL EG"},
{label: "SILDENAFIL GENEVRIER"},
{label: "SILDENAFIL HEC PHARM"},
{label: "SILDENAFIL MYLAN PHARMA"},
{label: "SILDENAFIL NIALEX"},
{label: "SILDENAFIL PFIZER"},
{label: "SILDENAFIL PHARMAKI GENERICS"},
{label: "SILDENAFIL QUIVER"},
{label: "SILDENAFIL RANBAXY"},
{label: "SILDENAFIL SANDOZ"},
{label: "SILDENAFIL STRAGEN"},
{label: "SILDENAFIL TEVA"},
{label: "SILDENAFIL TEVA SANTE"},
{label: "SILDENAFIL TORRENT"},
{label: "SILDENAFIL VIATRIS"},
{label: "SILDENAFIL VIATRIS GENERIQUES"},
{label: "SILDENAFIL WEST PHARMA"},
{label: "SILDENAFIL ZENTIVA"},
{label: "SILDENAFIL ZYDUS"},
{label: "SILICEA BOIRON"},
{label: "SILICEA COMPLEXE N°11"},
{label: "SILICEA FERRIER"},
{label: "SILICEA LEHNING"},
{label: "SILICEA NATURALE (QUARTZ) WELEDA"},
{label: "SILICEA WELEDA"},
{label: "SILICONYL"},
{label: "SILIGAZ"},
{label: "SILIPRELE"},
{label: "SILKIS"},
{label: "SILODOSINE ACCORD"},
{label: "SILODOSINE ARROW"},
{label: "SILODOSINE BIOGARAN"},
{label: "SILODOSINE EG"},
{label: "SILODOSINE KRKA"},
{label: "SILODOSINE RECORDATI"},
{label: "SILODOSINE SANDOZ"},
{label: "SILODOSINE TEVA"},
{label: "SILODOSINE TIEFENBACHER"},
{label: "SILODOSINE VIATRIS"},
{label: "SILODOSINE VIVANTA"},
{label: "SILODOSINE ZENTIVA"},
{label: "SILODYX"},
{label: "SILOMAT"},
{label: "SIMALUMAG"},
{label: "SIMBRINZA"},
{label: "SIMETICONE PHARMADEV"},
{label: "SIMIC"},
{label: "SIMPONI"},
{label: "SIMULECT"},
{label: "SIMVASTATINE ACCORD HEALTHCARE"},
{label: "SIMVASTATINE ACTAVIS"},
{label: "SIMVASTATINE ACTAVIS FRANCE"},
{label: "SIMVASTATINE ALMUS"},
{label: "SIMVASTATINE ALTER"},
{label: "SIMVASTATINE ARROW"},
{label: "SIMVASTATINE ARROW LAB"},
{label: "SIMVASTATINE BIOGARAN"},
{label: "SIMVASTATINE BIOORGANICS"},
{label: "SIMVASTATINE BIOSTABILEX"},
{label: "SIMVASTATINE BLUEFISH PHARMACEUTICAL AB"},
{label: "SIMVASTATINE BOUCHARA RECORDATI"},
{label: "SIMVASTATINE BOUCHARA-RECORDATI"},
{label: "SIMVASTATINE CRISTERS"},
{label: "SIMVASTATINE CRISTERS PHARMA"},
{label: "SIMVASTATINE EG"},
{label: "SIMVASTATINE EVOLUGEN"},
{label: "SIMVASTATINE FARMAPROJECTS"},
{label: "SIMVASTATINE G GAM"},
{label: "SIMVASTATINE GENERES"},
{label: "SIMVASTATINE GERDA"},
{label: "SIMVASTATINE ISOMED"},
{label: "SIMVASTATINE IVAX"},
{label: "SIMVASTATINE LBR"},
{label: "SIMVASTATINE MYLAN"},
{label: "SIMVASTATINE MYLAN PHARMA"},
{label: "SIMVASTATINE PFIZER"},
{label: "SIMVASTATINE PHR LAB"},
{label: "SIMVASTATINE PSI SUPPLY"},
{label: "SIMVASTATINE QUALIHEALTH"},
{label: "SIMVASTATINE QUALIMED"},
{label: "SIMVASTATINE RANBAXY"},
{label: "SIMVASTATINE RATIO"},
{label: "SIMVASTATINE RATIOPHARM"},
{label: "SIMVASTATINE REF"},
{label: "SIMVASTATINE ROSEMONT"},
{label: "SIMVASTATINE RPG"},
{label: "SIMVASTATINE SANDOZ"},
{label: "SIMVASTATINE SET"},
{label: "SIMVASTATINE SUBSTIPHARM"},
{label: "SIMVASTATINE SYNTHON"},
{label: "SIMVASTATINE TEVA"},
{label: "SIMVASTATINE TEVA PHARMA"},
{label: "SIMVASTATINE VIATRIS"},
{label: "SIMVASTATINE ZENTIVA"},
{label: "SIMVASTATINE ZYDUS"},
{label: "SIMVASTATINE ZYDUS FRANCE"},
{label: "SINAPIS NIGRA BOIRON"},
{label: "SINAPISME RIGOLLOT"},
{label: "SINCLAV"},
{label: "SINEMET"},
{label: "SINEMET LP"},
{label: "SINEQUAN"},
{label: "SINETRAV"},
{label: "SINEX"},
{label: "SINGULAIR"},
{label: "SINOVULON 20/150"},
{label: "SINOVULON 30/150"},
{label: "SINTROM"},
{label: "SINUFIX"},
{label: "SINUPHYL"},
{label: "SINUSITOL"},
{label: "SINUSPAX"},
{label: "SINUSPRAY"},
{label: "SIPRALEX"},
{label: "SIPRALEXORO"},
{label: "SIROCTID"},
{label: "SIROP BAYER AU THIOCOL ET AU PANTHENOL"},
{label: "SIROP DES VOSGES"},
{label: "SIROP DES VOSGES TOUX SECHE"},
{label: "SIROP MANCEAU"},
{label: "SIROP PETER'S"},
{label: "SIROP TEYSSEDRE"},
{label: "SIRTURO"},
{label: "SIRUCAP"},
{label: "SISEROL"},
{label: "SISEROL DISKUS"},
{label: "SITAGLIPTINE ACCORD"},
{label: "SITAGLIPTINE ADAMED"},
{label: "SITAGLIPTINE ALMUS"},
{label: "SITAGLIPTINE ALTER"},
{label: "SITAGLIPTINE ARROW"},
{label: "SITAGLIPTINE BGR"},
{label: "SITAGLIPTINE BIOGARAN"},
{label: "SITAGLIPTINE CRISTERS"},
{label: "SITAGLIPTINE EG"},
{label: "SITAGLIPTINE EVOLUGEN"},
{label: "SITAGLIPTINE GNR"},
{label: "SITAGLIPTINE GRINDEKS"},
{label: "SITAGLIPTINE HCS"},
{label: "SITAGLIPTINE LICONSA"},
{label: "SITAGLIPTINE MEDANA"},
{label: "SITAGLIPTINE SANDOZ"},
{label: "SITAGLIPTINE TEVA"},
{label: "SITAGLIPTINE TEVA SANTE"},
{label: "SITAGLIPTINE VIATRIS"},
{label: "SITAGLIPTINE ZENTIVA"},
{label: "SITAGLIPTINE ZYDUS"},
{label: "SITAGLIPTINE/CHLORHYDRATE DE METFORMINE MYLAN"},
{label: "SITAGLIPTINE/METFORMINE ALTER"},
{label: "SITAGLIPTINE/METFORMINE ARROW LAB"},
{label: "SITAGLIPTINE/METFORMINE BGR"},
{label: "SITAGLIPTINE/METFORMINE BIOGARAN"},
{label: "SITAGLIPTINE/METFORMINE CRISTERS"},
{label: "SITAGLIPTINE/METFORMINE EG"},
{label: "SITAGLIPTINE/METFORMINE EVOLUGEN"},
{label: "SITAGLIPTINE/METFORMINE GNR"},
{label: "SITAGLIPTINE/METFORMINE GRINDEKS"},
{label: "SITAGLIPTINE/METFORMINE KRKA"},
{label: "SITAGLIPTINE/METFORMINE LICONSA"},
{label: "SITAGLIPTINE/METFORMINE POLPHARMA"},
{label: "SITAGLIPTINE/METFORMINE REDDY PHARMA"},
{label: "SITAGLIPTINE/METFORMINE SANDOZ"},
{label: "SITAGLIPTINE/METFORMINE TEVA"},
{label: "SITAGLIPTINE/METFORMINE ZENTIVA"},
{label: "SITAGLIPTINE/METFORMINE ZYDUS"},
{label: "SITAVIG"},
{label: "SITOR"},
{label: "SIVEXTRO"},
{label: "SIXMO"},
{label: "SKELID"},
{label: "SKENAN L.P."},
{label: "SKIACOL"},
{label: "SKILARENCE"},
{label: "SKINOREN"},
{label: "SKUDEXUM"},
{label: "SKYRIZI"},
{label: "SKYSONA 2-30 ×"},
{label: "SLENYTO"},
{label: "SLIMUM"},
{label: "SLINDA"},
{label: "SMECTA"},
{label: "SMECTA PEDIATRIQUE"},
{label: "SMECTALIA"},
{label: "SMOFKABIVEN"},
{label: "SMOFKABIVEN E"},
{label: "SMOFKABIVEN E PROTEINE"},
{label: "SMOFKABIVEN PROTEINE"},
{label: "SMOFLIPID"},
{label: "SODIUM BICARBONATE"},
{label: "SODIUM BICARBONATE BIOLUZ"},
{label: "SODIUM BICARBONATE HYPERTONIQUE A"},
{label: "SODIUM CHLORURE"},
{label: "SODIUM CHLORURE A"},
{label: "SODIUM CHLORURE SAUTER"},
{label: "SODIUM CROMOGLYCATE O.P.I.H."},
{label: "SOELINE"},
{label: "SOFRAMYCINE"},
{label: "SOFRAMYCINE HYDROCORTISONE"},
{label: "SOFRAMYCINE NAPHAZOLINE"},
{label: "SOFREX"},
{label: "SOFTACORT"},
{label: "SOGACIN"},
{label: "SOGROYA"},
{label: "SOJOURN 100%"},
{label: "SOKER"},
{label: "SOLACY ADULTES"},
{label: "SOLACY PEDIATRIQUE"},
{label: "SOLANUM CAROLINENSE BOIRON"},
{label: "SOLANUM LYCOPERSICUM BOIRON"},
{label: "SOLANUM LYCOPERSICUM WELEDA"},
{label: "SOLANUM MALACOXYLON BOIRON"},
{label: "SOLANUM MALACOXYLON LEHNING"},
{label: "SOLANUM NIGRUM BOIRON"},
{label: "SOLANUM TUBEROSUM BOIRON"},
{label: "SOLARAZE"},
{label: "SOLASKIL"},
{label: "SOLEXA"},
{label: "SOLIAN"},
{label: "SOLIDAGO VIRGA AUREA BOIRON"},
{label: "SOLIDAGO VIRGA AUREA LEHNING"},
{label: "SOLIDAGO VIRGA AUREA WELEDA"},
{label: "SOLIFENACINE ACCORD"},
{label: "SOLIFENACINE ALTISO"},
{label: "SOLIFENACINE ARROW"},
{label: "SOLIFENACINE BESINS"},
{label: "SOLIFENACINE BGR"},
{label: "SOLIFENACINE BIOGARAN"},
{label: "SOLIFENACINE CRISTERS"},
{label: "SOLIFENACINE EG"},
{label: "SOLIFENACINE EVOLUGEN"},
{label: "SOLIFENACINE HCS"},
{label: "SOLIFENACINE HEC PHARM"},
{label: "SOLIFENACINE LESVI"},
{label: "SOLIFENACINE MITHRA"},
{label: "SOLIFENACINE QILU"},
{label: "SOLIFENACINE SANDOZ"},
{label: "SOLIFENACINE TEVA"},
{label: "SOLIFENACINE VIATRIS"},
{label: "SOLIFENACINE ZENTIVA"},
{label: "SOLIFENACINE ZYDUS"},
{label: "SOLIRIS"},
{label: "SOLISPASM"},
{label: "SOLMIN"},
{label: "SOLMUCOL"},
{label: "SOLPREDONE"},
{label: "SOLU TUSSYL"},
{label: "SOLUBACTER"},
{label: "SOLUBACTINE"},
{label: "SOLUBILOPTINE"},
{label: "SOLUCAMPHRE"},
{label: "SOLUCHROM"},
{label: "SOLUCORT"},
{label: "SOLUDACTONE"},
{label: "SOLUDECADRON"},
{label: "SOLUFEN"},
{label: "SOLUMAG"},
{label: "SOLUMEDROL"},
{label: "SOLUPRED"},
{label: "SOLUPRICK CONTROLE NEGATIF"},
{label: "SOLUPRICK CONTROLE POSITIF"},
{label: "SOLUPRICK SQ"},
{label: "SOLUPSAN"},
{label: "SOLUPSAN NOURRISSONS ET ENFANTS"},
{label: "SOLURUTINE PAPAVERINE"},
{label: "SOLUTE PHYSIOLOGIQUE PHENOLE LAVOISIER"},
{label: "SOLUTION A"},
{label: "SOLUTION ALCOOLIQUE DE MERCURESCEINE GIFRER"},
{label: "SOLUTION AQUEUSE DE MERCURESCEINE GIFRER"},
{label: "SOLUTION D'ACIDE ASCORBIQUE"},
{label: "SOLUTION D'ACIDE LACTIQUE ISOTONIQUE A"},
{label: "SOLUTION D'ACIDE PICRIQUE BOUCHARA RECORDATI"},
{label: "SOLUTION D'IODURE [131 I] DE SODIUM POUR MARQUAGE"},
{label: "SOLUTION FORMULE"},
{label: "SOLUTION GYNECOLOGIQUE PHARMATEX"},
{label: "SOLUTION INJECTABLE"},
{label: "SOLUTION INJECTABLE D'OLIGO-ELEMENTS AGUETTANT ENFANTS ET NOURRISSONS"},
{label: "SOLUTION INJECTABLE DE BICARBONATE DE SODIUM A"},
{label: "SOLUTION INJECTABLE DE CHLORURE DE SODIUM"},
{label: "SOLUTION INJECTABLE DE CHLORURE DE SODIUM A"},
{label: "SOLUTION INJECTABLE DE GLUCOSE"},
{label: "SOLUTION INJECTABLE DE GLUCOSE A"},
{label: "SOLUTION INJECTABLE DE RINGER BAXTER"},
{label: "SOLUTION INJECTABLE DE RINGER GLUCOSE"},
{label: "SOLUTION INJECTABLE DE RINGER LACTATE BAXTER"},
{label: "SOLUTION INJECTABLE DE SODIUM CHLORURE"},
{label: "SOLUTION INJECTABLE GLUCOSE"},
{label: "SOLUTION INJECTABLE HYPERTONIQUE DE BICARBONATE DE SODIUM A"},
{label: "SOLUTION INJECTABLE HYPERTONIQUE DE GLUCOSE A"},
{label: "SOLUTION IONIQUE CONCENTREE"},
{label: "SOLUTION POLYONIQUE GLUCOSEE COOPER N°1"},
{label: "SOLUTION POLYONIQUE GLUCOSEE COOPER N°2"},
{label: "SOLUTION POLYONIQUE GLUCOSEE COOPER N°3"},
{label: "SOLUTION POLYONIQUE GLUCOSEE COOPER N°4"},
{label: "SOLUTION POLYONIQUE GLUCOSEE COOPER N°5"},
{label: "SOLUTION PRETE A L'EMPLOI D.P.C.A."},
{label: "SOLUTION PRETE A L'EMPLOI H F"},
{label: "SOLUTION PRETE A L'EMPLOI HF"},
{label: "SOLUTION STAGO DILUEE"},
{label: "SOLUTONIM"},
{label: "SOLUTRICINE"},
{label: "SOLUTRICINE ADULTE EXPECTORANT"},
{label: "SOLUTRICINE MAUX DE GORGE"},
{label: "SOLUTRICINE MAUX DE GORGE BICLOTYMOL MENTHE"},
{label: "SOLUTRICINE MAUX DE GORGE BICLOTYMOL ORANGE"},
{label: "SOLUTRICINE MAUX DE GORGE SANS SUCRE"},
{label: "SOLUTRICINE MAUX DE GORGE SANS SUCRE EUCALYPTOL MENTHOL"},
{label: "SOLUTRICINE MAUX DE GORGE TETRACAINE"},
{label: "SOLUTRICINE MAUX DE GORGE TETRACAÏNE"},
{label: "SOLUTUSSYL"},
{label: "SOLUVIT"},
{label: "SOMAKIT TOC"},
{label: "SOMATOSTATINE EUMEDICA"},
{label: "SOMATOSTATINE VIATRIS"},
{label: "SOMATROPINE BEECHAM"},
{label: "SOMATULINE L.P."},
{label: "SOMAVERT"},
{label: "SOMMIERES"},
{label: "SOMNIDORON"},
{label: "SONATA"},
{label: "SONDELBAY"},
{label: "SONOVUE"},
{label: "SOOLANTRA"},
{label: "SOPHIDONE L.P."},
{label: "SOPHTAL"},
{label: "SOPHTALINE"},
{label: "SOPROL"},
{label: "SORAFENIB ACCORD"},
{label: "SORAFENIB BIOGARAN"},
{label: "SORAFENIB EG"},
{label: "SORAFENIB PHARMAKI GENERICS"},
{label: "SORAFENIB SANDOZ"},
{label: "SORAFENIB TEVA"},
{label: "SORAFENIB VIATRIS"},
{label: "SORAFENIB ZENTIVA"},
{label: "SORBILENE"},
{label: "SORBITOL"},
{label: "SORBITOL APPLIPHARM"},
{label: "SORBITOL B.BRAUN"},
{label: "SORBITOL BETAINE ACETYLMETHIONINE CILFA DEVELOPPEMENT"},
{label: "SORBITOL BIOSEDRA"},
{label: "SORBITOL DELALANDE"},
{label: "SORBITOL DELALANDE MENTHE"},
{label: "SORBITOL H2 PHARMA"},
{label: "SORBITOL HYPERTONIQUE"},
{label: "SORBITOL HYPERTONIQUE BIOSEDRA"},
{label: "SORBITOL/CITRATE DE SODIUM/LAURILSULFOACETATE DE SODIUM JOHNSON & JOHNSON"},
{label: "SORIATANE"},
{label: "SOSEGAN"},
{label: "SOTALEX"},
{label: "SOTALOL ALMUS"},
{label: "SOTALOL ARROW"},
{label: "SOTALOL ARROW LAB"},
{label: "SOTALOL BIOGARAN"},
{label: "SOTALOL BMS"},
{label: "SOTALOL CERA"},
{label: "SOTALOL CRISTERS"},
{label: "SOTALOL EG"},
{label: "SOTALOL HEXAL"},
{label: "SOTALOL IVAX"},
{label: "SOTALOL RANBAXY"},
{label: "SOTALOL RATIOPHARM"},
{label: "SOTALOL RPG"},
{label: "SOTALOL SANDOZ"},
{label: "SOTALOL TEVA"},
{label: "SOTALOL TEVA PHARMA"},
{label: "SOTALOL VIATRIS"},
{label: "SOTALOL ZENTIVA"},
{label: "SOUFRE MICROSOL"},
{label: "SOUFRE OLIGOSOL"},
{label: "SOVALDI"},
{label: "SPACYR"},
{label: "SPAGULAX"},
{label: "SPAGULAX AU CITRATE DE POTASSIUM"},
{label: "SPAGULAX AU SORBITOL"},
{label: "SPAGULAX MUCILAGE PUR"},
{label: "SPAGULAX SANS SUCRE"},
{label: "SPANOR"},
{label: "SPARK"},
{label: "SPARTOCINE"},
{label: "SPASFON"},
{label: "SPASFON LYOC"},
{label: "SPASMAG"},
{label: "SPASMAG INJECTABLE"},
{label: "SPASMATECH"},
{label: "SPASMAVERINE"},
{label: "SPASMIDENAL"},
{label: "SPASMINE"},
{label: "SPASMINE ADULTES"},
{label: "SPASMINE ENFANTS"},
{label: "SPASMOCALM"},
{label: "SPASMODEX"},
{label: "SPASMOSEDINE"},
{label: "SPASSIREX"},
{label: "SPECIAFOLDINE"},
{label: "SPECIAL ONGLES INCARNES"},
{label: "SPECILLINE G"},
{label: "SPECIREP"},
{label: "SPECTRILA"},
{label: "SPECYTON CARTILAGE PARATHYROIDE"},
{label: "SPEDIFEN"},
{label: "SPEDRA"},
{label: "SPEVIGO"},
{label: "SPEVIN"},
{label: "SPHEROX 10-70"},
{label: "SPICLINE"},
{label: "SPIFEN"},
{label: "SPIGELIA ANTHELMIA BOIRON"},
{label: "SPIGELIA ANTHELMIA LEHNING"},
{label: "SPIGELIA ANTHELMIA WELEDA"},
{label: "SPIKEVAX"},
{label: "SPIKEVAX BIVALENT ORIGINAL/OMICRON BA.1 (50"},
{label: "SPIKEVAX BIVALENT ORIGINAL/OMICRON BA.4-5"},
{label: "SPIKEVAX BIVALENT ORIGINAL/OMICRON BA.4-5 (50"},
{label: "SPINAFOL"},
{label: "SPINRAZA"},
{label: "SPIOLTO RESPIMAT"},
{label: "SPIR"},
{label: "SPIRAEA ULMARIA BOIRON"},
{label: "SPIRAMYCINE BELLON"},
{label: "SPIRAMYCINE BIOGARAN"},
{label: "SPIRAMYCINE CRISTERS"},
{label: "SPIRAMYCINE EG"},
{label: "SPIRAMYCINE METRONIDAZOLE ZENTIVA"},
{label: "SPIRAMYCINE RPG"},
{label: "SPIRAMYCINE RPG GRANDS ENFANTS"},
{label: "SPIRAMYCINE RPG NOURRISSONS"},
{label: "SPIRAMYCINE RPG NOURRISSONS ET ENFANTS"},
{label: "SPIRAMYCINE SANDOZ"},
{label: "SPIRAMYCINE TEVA"},
{label: "SPIRAMYCINE THERAPLIX"},
{label: "SPIRAMYCINE VIATRIS"},
{label: "SPIRAMYCINE/METRONIDAZOLE ACTAVIS"},
{label: "SPIRAMYCINE/METRONIDAZOLE AGI PHARMA 750.000 UI/125"},
{label: "SPIRAMYCINE/METRONIDAZOLE ALMUS"},
{label: "SPIRAMYCINE/METRONIDAZOLE ARROW"},
{label: "SPIRAMYCINE/METRONIDAZOLE ARROW 750.000 UI/125"},
{label: "SPIRAMYCINE/METRONIDAZOLE BIOGARAN"},
{label: "SPIRAMYCINE/METRONIDAZOLE CEHEL PHARMA"},
{label: "SPIRAMYCINE/METRONIDAZOLE CLL PHARMA"},
{label: "SPIRAMYCINE/METRONIDAZOLE CRISTERS"},
{label: "SPIRAMYCINE/METRONIDAZOLE CRISTERS 750.000 UI/125"},
{label: "SPIRAMYCINE/METRONIDAZOLE EG"},
{label: "SPIRAMYCINE/METRONIDAZOLE EG 750.000 UI/125"},
{label: "SPIRAMYCINE/METRONIDAZOLE G GAM"},
{label: "SPIRAMYCINE/METRONIDAZOLE G GAM 750.000 UI/125"},
{label: "SPIRAMYCINE/METRONIDAZOLE LA COLINA 750.000 UI/125"},
{label: "SPIRAMYCINE/METRONIDAZOLE MILGEN 750.000 UI/125"},
{label: "SPIRAMYCINE/METRONIDAZOLE PIERRE FABRE MEDICAMENT 750.000 UI/125"},
{label: "SPIRAMYCINE/METRONIDAZOLE QUALIMED"},
{label: "SPIRAMYCINE/METRONIDAZOLE RATIOPHARM"},
{label: "SPIRAMYCINE/METRONIDAZOLE SANDOZ"},
{label: "SPIRAMYCINE/METRONIDAZOLE SANDOZ 750.000 UI/125"},
{label: "SPIRAMYCINE/METRONIDAZOLE SOPHIALE 750.000 UI/125"},
{label: "SPIRAMYCINE/METRONIDAZOLE SUBSTIPHARM"},
{label: "SPIRAMYCINE/METRONIDAZOLE SUN"},
{label: "SPIRAMYCINE/METRONIDAZOLE TEVA"},
{label: "SPIRAMYCINE/METRONIDAZOLE TEVA 750.000 UI/125"},
{label: "SPIRAMYCINE/METRONIDAZOLE VIATRIS"},
{label: "SPIRAMYCINE/METRONIDAZOLE VIATRIS 750.000 UI/125"},
{label: "SPIRAMYCINE/METRONIDAZOLE ZYDUS"},
{label: "SPIRITUS QUERCUS GLANDIUM BOIRON"},
{label: "SPIRIVA"},
{label: "SPIRIVA RESPIMAT"},
{label: "SPIROCTAN"},
{label: "SPIROCTAZINE"},
{label: "SPIROLEPT"},
{label: "SPIRONOLACTONE"},
{label: "SPIRONOLACTONE ACTAVIS"},
{label: "SPIRONOLACTONE ALTIZIDE ARROW"},
{label: "SPIRONOLACTONE ALTIZIDE BIOGARAN"},
{label: "SPIRONOLACTONE ALTIZIDE CARDEL"},
{label: "SPIRONOLACTONE ALTIZIDE EG"},
{label: "SPIRONOLACTONE ALTIZIDE IVAX"},
{label: "SPIRONOLACTONE ALTIZIDE PFIZER"},
{label: "SPIRONOLACTONE ALTIZIDE SANDOZ"},
{label: "SPIRONOLACTONE ALTIZIDE TEVA"},
{label: "SPIRONOLACTONE ALTIZIDE VIATRIS"},
{label: "SPIRONOLACTONE ALTIZIDE ZENTIVA"},
{label: "SPIRONOLACTONE ARROW"},
{label: "SPIRONOLACTONE ARROW GENERIQUES"},
{label: "SPIRONOLACTONE ARROW LAB"},
{label: "SPIRONOLACTONE BIOGARAN"},
{label: "SPIRONOLACTONE CARDEL"},
{label: "SPIRONOLACTONE CRISTERS"},
{label: "SPIRONOLACTONE EG"},
{label: "SPIRONOLACTONE FUROSEMIDE SEARLE"},
{label: "SPIRONOLACTONE G GAM"},
{label: "SPIRONOLACTONE GENODEX"},
{label: "SPIRONOLACTONE GNR"},
{label: "SPIRONOLACTONE IVAX"},
{label: "SPIRONOLACTONE MICROFINE RATIOPHARM"},
{label: "SPIRONOLACTONE PFIZER"},
{label: "SPIRONOLACTONE RATIOPHARM"},
{label: "SPIRONOLACTONE RPG"},
{label: "SPIRONOLACTONE SANDOZ"},
{label: "SPIRONOLACTONE SUBSTI-LABO"},
{label: "SPIRONOLACTONE SUBSTIPHARM"},
{label: "SPIRONOLACTONE TEVA"},
{label: "SPIRONOLACTONE VIATRIS"},
{label: "SPIRONOLACTONE ZENTIVA"},
{label: "SPIRONOLACTONE ZYDUS"},
{label: "SPIRONOLACTONE-ALTIZIDE RATIOPHARM"},
{label: "SPIRONONE"},
{label: "SPIROPHAR"},
{label: "SPITADERM"},
{label: "SPLENINE BOIRON"},
{label: "SPLENINE WELEDA"},
{label: "SPLENOCARBINE"},
{label: "SPONGIA TOSTA BOIRON"},
{label: "SPONGIA TOSTA LEHNING"},
{label: "SPONGIA TOSTA WELEDA"},
{label: "SPORANOX"},
{label: "SPORILINE"},
{label: "SPORTENINE"},
{label: "SPOTOF"},
{label: "SPRALGY"},
{label: "SPRAVATO"},
{label: "SPRAY"},
{label: "SPRAY PAX"},
{label: "SPREGAL"},
{label: "SPREOR"},
{label: "SPRYCEL"},
{label: "SQUAD"},
{label: "SRILANE"},
{label: "SRIVASSO"},
{label: "ST"},
{label: "STABLON"},
{label: "STACOMIS"},
{label: "STAGID"},
{label: "STALEVO"},
{label: "STAMARIL"},
{label: "STAMICIS"},
{label: "STANNUM METALLICUM BOIRON"},
{label: "STANNUM METALLICUM LEHNING"},
{label: "STANNUM METALLICUM WELEDA"},
{label: "STAPHYLOCOCCINUM BOIRON"},
{label: "STAPHYLOMYCINE"},
{label: "STAPHYLOMYCINE NOURRISSONS ET ENFANTS"},
{label: "STAPHYLOTOXINUM BOIRON"},
{label: "STAPHYSAGRIA BOIRON"},
{label: "STAPHYSAGRIA LEHNING"},
{label: "STAPHYSAGRIA WELEDA"},
{label: "STAPOROS"},
{label: "STAPRESSIAL"},
{label: "STAQUIS"},
{label: "STAREM ADULTES"},
{label: "STARLIX"},
{label: "STARTON"},
{label: "STASIFLUC"},
{label: "STAVET ADULTES"},
{label: "STAVET ENFANTS ET NOURRISSONS"},
{label: "STEDIRIL"},
{label: "STEFELA"},
{label: "STEGLATRO"},
{label: "STEGLUJAN"},
{label: "STELADERM"},
{label: "STELARA"},
{label: "STELLAVIT"},
{label: "STELLAVIT SANS SUCRE"},
{label: "STEOVESS"},
{label: "STEOZOL"},
{label: "STERDEX"},
{label: "STERIDOSE"},
{label: "STERILENE"},
{label: "STERILLIUM"},
{label: "STERIMYCINE"},
{label: "STERLANE"},
{label: "STEROGYL"},
{label: "STHASIN LP"},
{label: "STIBINE BOIRON"},
{label: "STIBINE WELEDA"},
{label: "STICTA PULMONARIA BOIRON"},
{label: "STICTA PULMONARIA LEHNING"},
{label: "STICTA PULMONARIA WELEDA"},
{label: "STILAMINE"},
{label: "STILLA"},
{label: "STILLARGOL"},
{label: "STILLINGIA SYLVATICA BOIRON"},
{label: "STILNOX"},
{label: "STIMOL"},
{label: "STIMU GH"},
{label: "STIMU-ACTH"},
{label: "STIMUFEND"},
{label: "STIMUPLEX"},
{label: "STIMYCINE"},
{label: "STIPRHUME"},
{label: "STIRIEL"},
{label: "STIVANE"},
{label: "STIVARGA"},
{label: "STODAL"},
{label: "STODALINE SANS SUCRE"},
{label: "STOM-ANTIBA"},
{label: "STOMACALM"},
{label: "STOMACID"},
{label: "STOMACID MAUX D'ESTOMAC"},
{label: "STOMAX"},
{label: "STOMEDINE"},
{label: "STOPHENYL"},
{label: "STORINYL"},
{label: "STRAMONIUM BOIRON"},
{label: "STRAMONIUM LEHNING"},
{label: "STRAMONIUM WELEDA"},
{label: "STRATENE"},
{label: "STRATTERA"},
{label: "STREFEN"},
{label: "STREFENSPRAY"},
{label: "STRENSIQ"},
{label: "STREPSILS CITRON SANS SUCRE"},
{label: "STREPSILS EXPECTORANT"},
{label: "STREPSILS FRAISE SANS SUCRE"},
{label: "STREPSILS LIDOCAINE"},
{label: "STREPSILS MENTHE GLACIALE"},
{label: "STREPSILS MIEL CITRON"},
{label: "STREPSILS ORANGE VITAMINE C"},
{label: "STREPSILS TOUX SECHE"},
{label: "STREPSILSPRAY"},
{label: "STREPTASE"},
{label: "STREPTOCOCCINUM BOIRON"},
{label: "STREPTOMYCINE PANPHARMA"},
{label: "STREPTOMYCINE SARBACH"},
{label: "STREPTOMYCINE SPECIA"},
{label: "STREPTOMYCINUM BOIRON"},
{label: "STRESAM"},
{label: "STRESSDORON"},
{label: "STRIADYNE"},
{label: "STRIANT L.P."},
{label: "STRIASCAN"},
{label: "STRIBILD"},
{label: "STRIVERDI RESPIMAT"},
{label: "STROMECTOL"},
{label: "STRONTIUM CARBONICUM BOIRON"},
{label: "STRONTIUM IODATUM BOIRON"},
{label: "STROPHANTHUS HISPIDUS BOIRON"},
{label: "STRUCTOCAL VITAMINE D3"},
{label: "STRUCTOFLEX"},
{label: "STRUCTOKABIVEN"},
{label: "STRUCTOKABIVEN E"},
{label: "STRUCTOLIPIDE"},
{label: "STRUCTUM"},
{label: "STRYCHNINUM BOIRON"},
{label: "STYLOCHROME"},
{label: "SUBCUVIA"},
{label: "SUBOLIGO COBALT"},
{label: "SUBOLIGO CUIVRE"},
{label: "SUBOLIGO CUIVRE OR ARGENT"},
{label: "SUBOLIGO LITHIUM"},
{label: "SUBOLIGO MAGNESIUM"},
{label: "SUBOLIGO MANGANESE"},
{label: "SUBOLIGO MANGANESE COBALT"},
{label: "SUBOLIGO MANGANESE CUIVRE"},
{label: "SUBOLIGO MANGANESE-CUIVRE-COBALT"},
{label: "SUBOLIGO NICKEL COBALT"},
{label: "SUBOLIGO PHOSPHORE"},
{label: "SUBOLIGO POTASSIUM"},
{label: "SUBOLIGO SELENIUM"},
{label: "SUBOLIGO SOUFRE"},
{label: "SUBOLIGO ZINC"},
{label: "SUBOLIGO ZINC-NICKEL-COBALT"},
{label: "SUBOXONE"},
{label: "SUBROXINE"},
{label: "SUBSIPIL"},
{label: "SUBSOL"},
{label: "SUBSOL SANS POTASSIUM"},
{label: "SUBSTICHOL"},
{label: "SUBUTEX"},
{label: "SUCCESSIA"},
{label: "SUCCICAPTAL"},
{label: "SUCCINICUM ACIDUM BOIRON"},
{label: "SUCCINIMIDE PHARBIOL"},
{label: "SUCRALFATE APOTEX"},
{label: "SUCRALFATE APOTEX FRANCE"},
{label: "SUCRALFATE GNR"},
{label: "SUCRALFATE RPG"},
{label: "SUCRALFATE TEVA"},
{label: "SUDAFED"},
{label: "SUFENTA"},
{label: "SUFENTANIL DAKOTA PHARM"},
{label: "SUFENTANIL EUROCEPT"},
{label: "SUFENTANIL HAMELN"},
{label: "SUFENTANIL KALCEKS"},
{label: "SUFENTANIL PANPHARMA"},
{label: "SUFENTANIL RENAUDIN"},
{label: "SUFENTANIL SANDOZ"},
{label: "SUFENTANIL VIATRIS"},
{label: "SUGAMMADEX ASPEN"},
{label: "SUGAMMADEX BIOSYN"},
{label: "SUGAMMADEX CIPLA"},
{label: "SUGAMMADEX EG"},
{label: "SUGAMMADEX FRESENIUS KABI"},
{label: "SUGAMMADEX GALENICUM"},
{label: "SUGAMMADEX JUTA PHARMA"},
{label: "SUGAMMADEX MYLAN"},
{label: "SUGAMMADEX NORIDEM"},
{label: "SUGAMMADEX PANPHARMA"},
{label: "SUGAMMADEX PROVEPHARM"},
{label: "SUGAMMADEX QILU"},
{label: "SUGAMMADEX REDDY PHARMA"},
{label: "SUGAMMADEX REIG JOFRE"},
{label: "SUGAMMADEX STRAGEN"},
{label: "SUGAMMADEX SUN"},
{label: "SUGAMMADEX TEVA"},
{label: "SUGAMMADEX TILLOMED"},
{label: "SUGAMMADEX ZENTIVA"},
{label: "SUGIM"},
{label: "SULBACTAM BERAL"},
{label: "SULFA-BLEU"},
{label: "SULFADIAR"},
{label: "SULFAGUANIDINE LAFRAN"},
{label: "SULFAMETHOXAZOLE TRIMETHOPRIME PANPHARMA ADULTES"},
{label: "SULFAMETHOXAZOLE TRIMETHOPRIME PANPHARMA NOURRISSONS ET ENFANTS"},
{label: "SULFARLEM"},
{label: "SULFARLEM S"},
{label: "SULFATE D'ATROPINE"},
{label: "SULFATE D'ATROPINE BIOSEDRA"},
{label: "SULFATE D'ATROPINE COOPER"},
{label: "SULFATE DE DUBOISINE A"},
{label: "SULFATE DE MAGNESIUM AGUETTANT"},
{label: "SULFATE DE MAGNESIUM ALTAN"},
{label: "SULFATE DE MAGNESIUM COOPER"},
{label: "SULFATE DE MAGNESIUM LAVOISIER"},
{label: "SULFATE DE MAGNESIUM MERAM"},
{label: "SULFATE DE MAGNESIUM PROAMP"},
{label: "SULFATE DE MAGNESIUM RENAUDIN"},
{label: "SULFATE DE MORPHINE ETHYPHARM"},
{label: "SULFATE DE MORPHINE ETHYPHARM L.P."},
{label: "SULFATE DE MORPHINE ETHYPHARM LP"},
{label: "SULFINPYRAZONE THERAMEX"},
{label: "SULFOBROMOPHTALEINE SODIQUE S.E.R.B."},
{label: "SULFORGAN ADULTES"},
{label: "SULFORGAN ENFANTS"},
{label: "SULFUR BOIRON"},
{label: "SULFUR COMPLEXE N°12"},
{label: "SULFUR FERRIER"},
{label: "SULFUR IODATUM BOIRON"},
{label: "SULFUR IODATUM LEHNING"},
{label: "SULFUR IODATUM WELEDA"},
{label: "SULFUR LEHNING"},
{label: "SULFUR WELEDA"},
{label: "SULFURE DE RHENIUM [186"},
{label: "SULFURICUM ACIDUM BOIRON"},
{label: "SULFURICUM ACIDUM LEHNING"},
{label: "SULFURICUM ACIDUM WELEDA"},
{label: "SULFURYL"},
{label: "SULFURYL INHALANT"},
{label: "SULINDAC MSD"},
{label: "SULIQUA"},
{label: "SULPIRIDE G GAM"},
{label: "SULPIRIDE GENEVAR"},
{label: "SULPIRIDE GNR"},
{label: "SULPIRIDE IVAX"},
{label: "SULPIRIDE SANDOZ"},
{label: "SULPIRIDE TEVA"},
{label: "SULPIRIDE VIATRIS"},
{label: "SULPIRIDE ZENTIVA"},
{label: "SULPIRIDE ZYDUS"},
{label: "SULTOPRIDE IREX"},
{label: "SULTOPRIDE PANPHARMA"},
{label: "SULTOPRIDE PHARMANOVA"},
{label: "SUMATRIPTAN ACTAVIS"},
{label: "SUMATRIPTAN ARROW"},
{label: "SUMATRIPTAN DCI PHARMA"},
{label: "SUMATRIPTAN G GAM"},
{label: "SUMATRIPTAN MEDIS"},
{label: "SUMATRIPTAN PFIZER"},
{label: "SUMATRIPTAN SANDOZ"},
{label: "SUMATRIPTAN SUN"},
{label: "SUMATRIPTAN TEVA"},
{label: "SUMATRIPTAN WINTHROP"},
{label: "SUMAVEL"},
{label: "SUMBUL BOIRON"},
{label: "SUNITINIB ACCORD"},
{label: "SUNITINIB BIOGARAN"},
{label: "SUNITINIB BLUEPHARMA INDUSTRIA"},
{label: "SUNITINIB CIPLA"},
{label: "SUNITINIB EG"},
{label: "SUNITINIB EGIS"},
{label: "SUNITINIB KOANAA"},
{label: "SUNITINIB KRKA"},
{label: "SUNITINIB MYLAN"},
{label: "SUNITINIB SANDOZ"},
{label: "SUNITINIB TEVA"},
{label: "SUNITINIB VIATRIS"},
{label: "SUNITINIB ZENTIVA"},
{label: "SUNITINIB ZYDUS"},
{label: "SUNLENCA"},
{label: "SUNOSI"},
{label: "SUP-RHINITE"},
{label: "SUPADOL"},
{label: "SUPPANGYL BISMUTHE ADULTES"},
{label: "SUPPANGYL BISMUTHE ENFANTS"},
{label: "SUPPLIVEN"},
{label: "SUPPOMALINE"},
{label: "SUPPOSIRTAL"},
{label: "SUPPOSIRTAL ADULTES"},
{label: "SUPPOSITOIRE A LA GLYCERINE ADULTES GIPHAR GROUPE"},
{label: "SUPPOSITOIRE A LA GLYCERINE ADULTES UNITHERA"},
{label: "SUPPOSITOIRE A LA GLYCERINE CENTRAPHARM ADULTES"},
{label: "SUPPOSITOIRE A LA GLYCERINE CENTRAPHARM ENFANTS"},
{label: "SUPPOSITOIRE A LA GLYCERINE CENTRAPHARM NOURRISSONS"},
{label: "SUPPOSITOIRE A LA GLYCERINE ENFANTS GIFRER"},
{label: "SUPPOSITOIRE A LA GLYCERINE GIFRER ADULTES"},
{label: "SUPPOSITOIRE A LA GLYCERINE GIFRER NOURRISSONS"},
{label: "SUPPOSITOIRE A LA GLYCERINE GILBERT ENFANTS"},
{label: "SUPPOSITOIRE A LA GLYCERINE GILBERT NOURRISSONS"},
{label: "SUPPOSITOIRE A LA GLYCERINE IREX ADULTES"},
{label: "SUPPOSITOIRE A LA GLYCERINE JOUVEINAL ENFANTS"},
{label: "SUPPOSITOIRE A LA GLYCERINE MAYOLY SPINDLER ADULTES"},
{label: "SUPPOSITOIRE A LA GLYCERINE MAYOLY SPINDLER ENFANTS"},
{label: "SUPPOSITOIRE A LA GLYCERINE MAYOLY SPINDLER NOURRISSONS"},
{label: "SUPPOSITOIRE A LA GLYCERINE PHARMOCCITAN ADULTES"},
{label: "SUPPOSITOIRE A LA GLYCERINE PRIMASOIN ADULTES"},
{label: "SUPPOSITOIRE A LA GLYCERINE PRIMASOIN ENFANTS"},
{label: "SUPPOSITOIRE A LA GLYCERINE PRIMASOIN NOURRISSONS"},
{label: "SUPPOSITOIRE A LA GLYCERINE SARBACH ENFANTS"},
{label: "SUPPOSITOIRE A LA GLYCERINE SARBACH NOURRISSON"},
{label: "SUPPOSITOIRE A LA GLYCERINE SAUBA ADULTES"},
{label: "SUPPOSITOIRE A LA GLYCERINE SAUBA ENFANTS"},
{label: "SUPPOSITOIRE A LA GLYCERINE SAUBA NOURRISSONS"},
{label: "SUPPOSITOIRES A LA GLYCERINE APPLIPHARM ADULTES"},
{label: "SUPPOSITOIRES A LA GLYCERINE APPLIPHARM ENFANTS"},
{label: "SUPPOSITOIRES A LA GLYCERINE COOPER ADULTES"},
{label: "SUPPOSITOIRES A LA GLYCERINE EVOLUPHARM ADULTES"},
{label: "SUPPOSITOIRES A LA GLYCERINE GILBERT ADULTES"},
{label: "SUPPOSITOIRES A LA GLYCERINE JOUVEINAL NOURRISSONS"},
{label: "SUPPOSITOIRES A LA GLYCERINE MONOT ADULTES"},
{label: "SUPPOSITOIRES A LA GLYCERINE MONOT ENFANTS"},
{label: "SUPPOSITOIRES A LA GLYCERINE MONOT NOURRISSONS"},
{label: "SUPPOSITOIRES A LA GLYCERINE PHARMOCCITAN ENFANTS"},
{label: "SUPPOSITOIRES A LA GLYCERINE SARBACH ADULTES"},
{label: "SUPPOSITOIRES A LA GLYCERINE ZAMBON FRANCE ADULTES"},
{label: "SUPPOSITOIRES A LA GLYCERINE ZAMBON FRANCE ENFANTS"},
{label: "SUPPOSITOIRES A LA GLYCERINE ZAMBON FRANCE NOURRISSONS"},
{label: "SUPRADYNE"},
{label: "SUPRALOX"},
{label: "SUPRANE"},
{label: "SUPRECUR"},
{label: "SUPREFACT"},
{label: "SUPRISTOL ADULTES"},
{label: "SURBRONC"},
{label: "SURBRONC EXPECTORANT AMBROXOL"},
{label: "SURBRONC EXPECTORANT AMBROXOL SANS SUCRE"},
{label: "SURBRONC TOUX SECHE DEXTROMETHORPHANE"},
{label: "SURELEN ADULTES"},
{label: "SURELEN ENFANTS"},
{label: "SUREPTIL"},
{label: "SURFEXO NEONATAL"},
{label: "SURFORTAN"},
{label: "SURGAM"},
{label: "SURGASTRIL"},
{label: "SURGESTONE"},
{label: "SURMONTIL"},
{label: "SURQUINA"},
{label: "SURRENINE BOIRON"},
{label: "SURRENINE WELEDA"},
{label: "SURVANTA"},
{label: "SURVITINE"},
{label: "SUSTIVA"},
{label: "SUTENT"},
{label: "SUVALAN"},
{label: "SUVREZA"},
{label: "SUXAMETHONIUM AGUETTANT"},
{label: "SUXAMETHONIUM ETHYPHARM"},
{label: "SUXAMETHONIUM PANPHARMA"},
{label: "SVELTUP"},
{label: "SYALINE SPRAY"},
{label: "SYCREST"},
{label: "SYLVANT"},
{label: "SYLVIANE"},
{label: "SYMBICORT RAPIHALER 100/3"},
{label: "SYMBICORT RAPIHALER 200/6"},
{label: "SYMBICORT TURBUHALER"},
{label: "SYMBICORT TURBUHALER 400/12"},
{label: "SYMKEVI"},
{label: "SYMMETREL"},
{label: "SYMPANEUROL"},
{label: "SYMPASANE"},
{label: "SYMPATHYL"},
{label: "SYMPAVAGOL"},
{label: "SYMPHORICARPUS RACEMOSUS BOIRON"},
{label: "SYMPHYTUM"},
{label: "SYMPHYTUM OFFICINALE BOIRON"},
{label: "SYMPHYTUM OFFICINALIS LEHNING"},
{label: "SYMTUZA"},
{label: "SYNACTHENE"},
{label: "SYNACTHENE RETARD"},
{label: "SYNAGIS"},
{label: "SYNALAR"},
{label: "SYNALAR GRAS"},
{label: "SYNALAR NEOMYCINE"},
{label: "SYNAREL"},
{label: "SYNAZE"},
{label: "SYNCORTYL"},
{label: "SYNEDIL"},
{label: "SYNEDIL FORT"},
{label: "SYNERCID"},
{label: "SYNERGON"},
{label: "SYNFLORIX"},
{label: "SYNJARDY"},
{label: "SYNTHAMIN"},
{label: "SYNTHODENT"},
{label: "SYNTHOFLEX"},
{label: "SYNTHOL"},
{label: "SYNTOCINON"},
{label: "SYNTOFENE"},
{label: "SYRINX"},
{label: "SYSTEN"},
{label: "SYSTRAL"},
{label: "T A C"},
{label: "T A O"},
{label: "T POLIO MERIEUX"},
{label: "T.A.O."},
{label: "T.R.E. BOIRON"},
{label: "T.R.E. WELEDA"},
{label: "T.S.H. BOIRON"},
{label: "T.T.D B3 B4"},
{label: "TABACUM BOIRON"},
{label: "TABACUM COMPOSE BOIRON"},
{label: "TABACUM LEHNING"},
{label: "TABACUM WELEDA"},
{label: "TABAPASS"},
{label: "TABAZUR"},
{label: "TABKOLA"},
{label: "TABLETTES ANTI H"},
{label: "TABLETTES DOPS"},
{label: "TABOXEA LP"},
{label: "TABRECTA"},
{label: "TACHOSIL"},
{label: "TACROLIMUS EG"},
{label: "TACROLIMUS EG LP"},
{label: "TACROLIMUS MYLAN"},
{label: "TACROLIMUS TEVA"},
{label: "TACTUO"},
{label: "TADALAFIL ACCORD"},
{label: "TADALAFIL ALMUS"},
{label: "TADALAFIL ALTER"},
{label: "TADALAFIL ARROW"},
{label: "TADALAFIL BIOGARAN"},
{label: "TADALAFIL CRISTERS"},
{label: "TADALAFIL CRISTERS PHARMA"},
{label: "TADALAFIL EG"},
{label: "TADALAFIL EG LABO"},
{label: "TADALAFIL EVOLUGEN"},
{label: "TADALAFIL EVOLUGEN PHARMA"},
{label: "TADALAFIL GNR"},
{label: "TADALAFIL GOIBELA"},
{label: "TADALAFIL HEC PHARM"},
{label: "TADALAFIL KRKA"},
{label: "TADALAFIL LILLY"},
{label: "TADALAFIL MYLAN"},
{label: "TADALAFIL QUIVER"},
{label: "TADALAFIL SANDOZ"},
{label: "TADALAFIL SFIP"},
{label: "TADALAFIL SUBSTIPHARM"},
{label: "TADALAFIL SUN"},
{label: "TADALAFIL TEVA"},
{label: "TADALAFIL TEVA PHARMA"},
{label: "TADALAFIL TEVA SANTE"},
{label: "TADALAFIL VIATRIS SANTE"},
{label: "TADALAFIL ZENTIVA"},
{label: "TADALAFIL ZYDUS"},
{label: "TADENAN"},
{label: "TADIM"},
{label: "TAFINLAR"},
{label: "TAGAMET"},
{label: "TAGRISSO"},
{label: "TAHOR"},
{label: "TAKADOL"},
{label: "TAKETIAM"},
{label: "TAKHZYRO"},
{label: "TAKROZEM"},
{label: "TALIDAT"},
{label: "TALMANCO"},
{label: "TALMIRMAS"},
{label: "TALOXA"},
{label: "TALTZ"},
{label: "TALVIDOL"},
{label: "TALZENNA"},
{label: "TAMARINE"},
{label: "TAMIFLU"},
{label: "TAMIK"},
{label: "TAMOFENE"},
{label: "TAMOLAC"},
{label: "TAMOXIFENE ACTAVIS"},
{label: "TAMOXIFENE ARROW"},
{label: "TAMOXIFENE BIOGALENIQUE"},
{label: "TAMOXIFENE BIOGARAN"},
{label: "TAMOXIFENE EG"},
{label: "TAMOXIFENE G GAM"},
{label: "TAMOXIFENE RATIOPHARM"},
{label: "TAMOXIFENE RPG"},
{label: "TAMOXIFENE SANDOZ"},
{label: "TAMOXIFENE TEVA"},
{label: "TAMOXIFENE TILLOMED"},
{label: "TAMOXIFENE VIATRIS"},
{label: "TAMOXIFENE ZYDUS"},
{label: "TAMPON PHARMATEX"},
{label: "TAMSULOSINE ACTAVIS LP"},
{label: "TAMSULOSINE ALMUS L.P."},
{label: "TAMSULOSINE ARROW LAB LP"},
{label: "TAMSULOSINE ARROW LP"},
{label: "TAMSULOSINE BIOGARAN L.P."},
{label: "TAMSULOSINE BIOGARAN LP"},
{label: "TAMSULOSINE CRISTERS LP"},
{label: "TAMSULOSINE DCI PHARMA LP"},
{label: "TAMSULOSINE EG L.P."},
{label: "TAMSULOSINE EG LABO LP"},
{label: "TAMSULOSINE EG LP"},
{label: "TAMSULOSINE EVOLUGEN L.P."},
{label: "TAMSULOSINE G GAM LP"},
{label: "TAMSULOSINE GENERES LP"},
{label: "TAMSULOSINE GERDA L.P."},
{label: "TAMSULOSINE GNR LP"},
{label: "TAMSULOSINE ISOMED LP 0.4"},
{label: "TAMSULOSINE KRKA LP"},
{label: "TAMSULOSINE MERCK GENERIQUES L.P."},
{label: "TAMSULOSINE QUALIHEALTH LP"},
{label: "TAMSULOSINE QUALIMED L.P."},
{label: "TAMSULOSINE RATIO 0.4"},
{label: "TAMSULOSINE RATIOPHARM LP"},
{label: "TAMSULOSINE RPG LP"},
{label: "TAMSULOSINE SANDOZ LP"},
{label: "TAMSULOSINE TEVA L.P."},
{label: "TAMSULOSINE TEVA LP"},
{label: "TAMSULOSINE VIATRIS L.P."},
{label: "TAMSULOSINE VIATRIS LP"},
{label: "TAMSULOSINE ZENTIVA LAB LP"},
{label: "TAMSULOSINE ZENTIVA LP"},
{label: "TAMSULOSINE ZENTIVA LP 0.4"},
{label: "TAMSULOSINE ZYDUS FRANCE LP"},
{label: "TANACETUM VULGARE BOIRON"},
{label: "TANAKAN"},
{label: "TANATRIL"},
{label: "TANAVISTAN"},
{label: "TANDEMACT"},
{label: "TANGANIL"},
{label: "TANGANIL LYOC"},
{label: "TANGANILPRO"},
{label: "TAPCLOB"},
{label: "TAPENTADOL LICONSA LP"},
{label: "TAPTIQOM"},
{label: "TARAXACUM DENS LEONIS BOIRON"},
{label: "TARAXACUM DENS LEONIS FERRIER"},
{label: "TARAXACUM DENS LEONIS LEHNING"},
{label: "TARAXACUM DENS LEONIS WELEDA"},
{label: "TARCEVA"},
{label: "TARDYFERON"},
{label: "TARDYFERON B9"},
{label: "TAREG"},
{label: "TARENTULA COMPLEXE N°71"},
{label: "TARENTULA HISPANA BOIRON"},
{label: "TARENTULA HISPANA LEHNING"},
{label: "TARGAXAN"},
{label: "TARGINACT"},
{label: "TARGOCID"},
{label: "TARGRETIN"},
{label: "TARKA LP"},
{label: "TARTRATE DE SYNEPHRINE/GLUCONATE DE CHLORHEXIDINE BOEHRINGER INGELHEIM"},
{label: "TASIGNA"},
{label: "TASMAR"},
{label: "TAUMEA"},
{label: "TAVANIC"},
{label: "TAVECOGYL"},
{label: "TAVLESSE"},
{label: "TAVNEOS"},
{label: "TAVOTAXAM"},
{label: "TAXESPIRA"},
{label: "TAXOFIT"},
{label: "TAXOL"},
{label: "TAXOTERE"},
{label: "TAXUS BACCATA BOIRON"},
{label: "TAZOCILLINE"},
{label: "TCAPS"},
{label: "TEALINE"},
{label: "TEASLIM"},
{label: "TEATROIS"},
{label: "TEBOFORTAN"},
{label: "TECARTUS"},
{label: "TECENTRIQ"},
{label: "TECEOS"},
{label: "TECFIDERA"},
{label: "TECHNESCAN DMSA"},
{label: "TECHNESCAN DTPA"},
{label: "TECHNESCAN HDP"},
{label: "TECHNESCAN LYOMAA"},
{label: "TECHNESCAN MAG"},
{label: "TECHNESCAN SESTAMIBI"},
{label: "TECOVIRIMAT SIGA"},
{label: "TECTASIM"},
{label: "TECVAYLI"},
{label: "TEDAROL"},
{label: "TEDRALAN"},
{label: "TEDRALAN L.P."},
{label: "TEGELINE"},
{label: "TEGLUTIK"},
{label: "TEGRETOL"},
{label: "TEGRETOL L.P."},
{label: "TEGSEDI"},
{label: "TEICOPLANINE ALTAN"},
{label: "TEICOPLANINE BRADEX"},
{label: "TEICOPLANINE HIKMA"},
{label: "TEICOPLANINE VIATRIS"},
{label: "TEINTURE D'ARNICA BOUCHARA RECORDATI"},
{label: "TEINTURE D'ARNICA COOPER"},
{label: "TEINTURE D'ARNICA GIFRER"},
{label: "TEINTURE D'ARNICA GILBERT"},
{label: "TEINTURE D'ARNICA PHARMADOSE"},
{label: "TEINTURE D'IODE GIFRER"},
{label: "TEKCIS 2-50"},
{label: "TEKLADIS"},
{label: "TEKTROTYD"},
{label: "TELEBRIX"},
{label: "TELEBRIX GASTRO (300"},
{label: "TELEBRIX HYSTERO (250"},
{label: "TELFAST"},
{label: "TELLIMED"},
{label: "TELLURIUM METALLICUM BOIRON"},
{label: "TELMISARTAN ACTAVIS"},
{label: "TELMISARTAN ALTER"},
{label: "TELMISARTAN APS ALSTER PHARMA SERVICE"},
{label: "TELMISARTAN ARROW"},
{label: "TELMISARTAN BIOGARAN"},
{label: "TELMISARTAN CRISTERS"},
{label: "TELMISARTAN EG"},
{label: "TELMISARTAN MYLAN"},
{label: "TELMISARTAN QUALIMED"},
{label: "TELMISARTAN RANBAXY"},
{label: "TELMISARTAN RATIOPHARM"},
{label: "TELMISARTAN RATIOPHARM GENERIQUES"},
{label: "TELMISARTAN SANDOZ"},
{label: "TELMISARTAN TEVA"},
{label: "TELMISARTAN TEVA SANTE"},
{label: "TELMISARTAN VIATRIS"},
{label: "TELMISARTAN ZENTIVA"},
{label: "TELMISARTAN ZYDUS"},
{label: "TELMISARTAN/AMLODIPINE ARROW"},
{label: "TELMISARTAN/HYDROCHLOROTHIAZIDE ACCORD"},
{label: "TELMISARTAN/HYDROCHLOROTHIAZIDE APS ALSTER PHARMA SERVICE"},
{label: "TELMISARTAN/HYDROCHLOROTHIAZIDE ARROW"},
{label: "TELMISARTAN/HYDROCHLOROTHIAZIDE BIOGARAN"},
{label: "TELMISARTAN/HYDROCHLOROTHIAZIDE CHEMO IBERICA"},
{label: "TELMISARTAN/HYDROCHLOROTHIAZIDE CRISTERS"},
{label: "TELMISARTAN/HYDROCHLOROTHIAZIDE EG"},
{label: "TELMISARTAN/HYDROCHLOROTHIAZIDE LICONSA"},
{label: "TELMISARTAN/HYDROCHLOROTHIAZIDE RATIOPHARM"},
{label: "TELMISARTAN/HYDROCHLOROTHIAZIDE SANDOZ"},
{label: "TELMISARTAN/HYDROCHLOROTHIAZIDE TEVA"},
{label: "TELMISARTAN/HYDROCHLOROTHIAZIDE TEVA SANTE"},
{label: "TELMISARTAN/HYDROCHLOROTHIAZIDE VIATRIS"},
{label: "TELMISARTAN/HYDROCHLOROTHIAZIDE ZENTIVA"},
{label: "TELMISARTAN/HYDROCHLOROTHIAZIDE ZYDUS"},
{label: "TELOMENS"},
{label: "TELORUTIL"},
{label: "TELZIR"},
{label: "TEMERIT"},
{label: "TEMERITDUO"},
{label: "TEMESTA"},
{label: "TEMETEX"},
{label: "TEMETEX CHLORQUINALDOL"},
{label: "TEMETEX GRAS"},
{label: "TEMGESIC"},
{label: "TEMODAL"},
{label: "TEMOMEDAC"},
{label: "TEMOZOLOMIDE ACCORD"},
{label: "TEMOZOLOMIDE ARROW"},
{label: "TEMOZOLOMIDE RATIOPHARM"},
{label: "TEMOZOLOMIDE SANDOZ"},
{label: "TEMOZOLOMIDE SUN"},
{label: "TEMOZOLOMIDE TEVA"},
{label: "TEMOZOLOMIDE VIATRIS"},
{label: "TEMPODIA"},
{label: "TEMSIROLIMUS ACCORD"},
{label: "TENDOL"},
{label: "TENDON BOIRON"},
{label: "TENKASI"},
{label: "TENOFOVIR DISOPROXIL ACCORD"},
{label: "TENOFOVIR DISOPROXIL ARROW"},
{label: "TENOFOVIR DISOPROXIL BIOGARAN"},
{label: "TENOFOVIR DISOPROXIL DEXTREG"},
{label: "TENOFOVIR DISOPROXIL EG"},
{label: "TENOFOVIR DISOPROXIL MYLAN"},
{label: "TENOFOVIR DISOPROXIL SANDOZ"},
{label: "TENOFOVIR DISOPROXIL TEVA"},
{label: "TENOFOVIR DISOPROXIL VENIPHARM"},
{label: "TENOFOVIR DISOPROXIL ZENTIVA"},
{label: "TENOFOVIR DISOPROXIL ZYDUS"},
{label: "TENORDATE"},
{label: "TENORETIC"},
{label: "TENORMINE"},
{label: "TENOXICAM SOCIETE CHIMIQUE ROCHE"},
{label: "TENSIONORME"},
{label: "TENSTATEN"},
{label: "TENUATE DOSPAN"},
{label: "TEOULA"},
{label: "TEPADINA"},
{label: "TERALITHE"},
{label: "TERALITHE LP"},
{label: "TERAZOSINE BIOGARAN"},
{label: "TERAZOSINE MYLAN"},
{label: "TERAZOSINE RATIOPHARM"},
{label: "TERAZOSINE TEVA"},
{label: "TERBINAFINE"},
{label: "TERBINAFINE ACTAVIS FRANCE"},
{label: "TERBINAFINE ALMUS"},
{label: "TERBINAFINE ALTER"},
{label: "TERBINAFINE APOTEX"},
{label: "TERBINAFINE ARROW"},
{label: "TERBINAFINE ARROW GENERIQUES"},
{label: "TERBINAFINE ARROW LAB"},
{label: "TERBINAFINE BAILLEUL"},
{label: "TERBINAFINE BIOGARAN"},
{label: "TERBINAFINE CRISTERS"},
{label: "TERBINAFINE CRISTERS PHARMA"},
{label: "TERBINAFINE DCI PHARMA"},
{label: "TERBINAFINE EG"},
{label: "TERBINAFINE EVOLUGEN"},
{label: "TERBINAFINE GNR"},
{label: "TERBINAFINE INTENDIS"},
{label: "TERBINAFINE ISOMED"},
{label: "TERBINAFINE LICONSA"},
{label: "TERBINAFINE MEDIPHA SANTE"},
{label: "TERBINAFINE MYLAN PHARMA"},
{label: "TERBINAFINE ORCHID EUROPE"},
{label: "TERBINAFINE PFIZER"},
{label: "TERBINAFINE PIERRE FABRE"},
{label: "TERBINAFINE QUALIMED"},
{label: "TERBINAFINE RANBAXY"},
{label: "TERBINAFINE RATIOPHARM"},
{label: "TERBINAFINE SANDOZ"},
{label: "TERBINAFINE TEVA"},
{label: "TERBINAFINE VIATRIS"},
{label: "TERBINAFINE VIATRIS 1%"},
{label: "TERBINAFINE ZENTIVA"},
{label: "TERBINAFINE ZYDUS"},
{label: "TERBUTALINE APO.GE"},
{label: "TERBUTALINE ARROW"},
{label: "TERBUTALINE BIOGARAN"},
{label: "TERBUTALINE SANDOZ"},
{label: "TERBUTALINE VIATRIS"},
{label: "TERCIAN"},
{label: "TERCODINE"},
{label: "TEREBINTHINA BOIRON"},
{label: "TEREBYO"},
{label: "TERFENADINE RPG"},
{label: "TERFENADINE TEVA"},
{label: "TERFLUZINE"},
{label: "TERGYNAN"},
{label: "TERIFLUNOMIDE BGR"},
{label: "TERIFLUNOMIDE BIOGARAN"},
{label: "TERIFLUNOMIDE EG"},
{label: "TERIFLUNOMIDE HCS"},
{label: "TERIFLUNOMIDE MYLAN"},
{label: "TERIFLUNOMIDE VIVANTA"},
{label: "TERIFLUNOMIDE ZENTIVA"},
{label: "TERIPARATIDE BIOGARAN"},
{label: "TERIPARATIDE TEVA"},
{label: "TERIPARATIDE VIATRIS"},
{label: "TERIPARATIDE WELDING"},
{label: "TERLIPRESSINE ALTAN"},
{label: "TERLIPRESSINE REDDY PHARMA"},
{label: "TERLIPRESSINE SUN"},
{label: "TERLOMEXIN"},
{label: "TERNEURINE"},
{label: "TERPEX"},
{label: "TERPINE COOPER"},
{label: "TERPINE DES MONTS DORE"},
{label: "TERPINE GIFRER"},
{label: "TERPINE GONNON"},
{label: "TERPINE MENTHOL AMYLEINE GLAXOSMITHKLINE SANTE GRAND PUBLIC"},
{label: "TERPINE MONOT"},
{label: "TERPONE"},
{label: "TERPONE ADULTES"},
{label: "TERPONE ENFANTS"},
{label: "TERPONE NOURRISSONS"},
{label: "TERRAMYCINE"},
{label: "TERRAMYCINE SOLU RETARD"},
{label: "TERROSA"},
{label: "TERSIGAT100"},
{label: "TESLASCAN"},
{label: "TEST RESPIRATOIRE A L'UREE MARQUEE"},
{label: "TESTAVAN"},
{label: "TESTOGEL"},
{label: "TESTOPATCH"},
{label: "TESTOPOISE"},
{label: "TESTOSTERONE FERRING"},
{label: "TESTOSTERONE LILLY"},
{label: "TESTOSTERONE PIERRE FABRE MEDICAMENT"},
{label: "TESTOSTERONE PROPIONATE BOIRON"},
{label: "TETAGLOBULINE"},
{label: "TETAGRIP"},
{label: "TETANEA"},
{label: "TETAVAX"},
{label: "TETMODIS"},
{label: "TETRACAINE"},
{label: "TETRACOQ"},
{label: "TETRACT-HIB"},
{label: "TETRACYCLINE DIAMANT"},
{label: "TETRACYCLINE RPG"},
{label: "TETRAGYNON"},
{label: "TETRALYSAL"},
{label: "TETRANASE"},
{label: "TETRAVAC-ACELLULAIRE"},
{label: "TETRAXIM"},
{label: "TETRAZEPAM ALMUS"},
{label: "TETRAZEPAM ARROW"},
{label: "TETRAZEPAM BIOGARAN"},
{label: "TETRAZEPAM CRISTERS"},
{label: "TETRAZEPAM EG"},
{label: "TETRAZEPAM G GAM"},
{label: "TETRAZEPAM MYLAN"},
{label: "TETRAZEPAM QUALIMED"},
{label: "TETRAZEPAM RATIOPHARM"},
{label: "TETRAZEPAM RPG"},
{label: "TETRAZEPAM SANDOZ"},
{label: "TETRAZEPAM TEVA"},
{label: "TETRAZEPAM ZENTIVA"},
{label: "TETRAZEPAM ZYDUS"},
{label: "TEUCRIUM MARUM BOIRON"},
{label: "TEUCRIUM MARUM LEHNING"},
{label: "TEUCRIUM SCORODONIA BOIRON"},
{label: "TEUCRIUM SCORODONIA WELEDA"},
{label: "TEVAGRASTIM"},
{label: "TEVETEN"},
{label: "TEXODIL"},
{label: "TEYSUNO"},
{label: "TEZSPIRE"},
{label: "THAIS"},
{label: "THAISSEPT"},
{label: "THALAMUS BOIRON"},
{label: "THALIDOMIDE ACCORD"},
{label: "THALIDOMIDE BMS"},
{label: "THALIDOMIDE KOANAA"},
{label: "THALLIUM ACETICUM BOIRON"},
{label: "THALLIUM CHLORURE [201"},
{label: "THALLIUM METALLICUM BOIRON"},
{label: "THALSOR"},
{label: "THAMACETAT"},
{label: "THE VERT BOIRON"},
{label: "THE VERT ETHYPHARM"},
{label: "THEINOL"},
{label: "THELIN"},
{label: "THEODEL"},
{label: "THEOLAIR"},
{label: "THEOLAIR L P"},
{label: "THEOLAIR L.P."},
{label: "THEOLAIR MONOPRISE LP"},
{label: "THEOPHYLLINE"},
{label: "THEOPHYLLINE BRUNEAU L.P."},
{label: "THEOPHYLLINE ETHYPHARM LP"},
{label: "THEOSTAT"},
{label: "THEPRUBICINE"},
{label: "THERA-FLUOR"},
{label: "THERACAP131"},
{label: "THERAFLUOR"},
{label: "THERALENE"},
{label: "THERALENE PECTORAL NOURRISSONS"},
{label: "THERASOLV"},
{label: "THERIDION CURRASSAVICUM BOIRON"},
{label: "THEVIER"},
{label: "THIAMAZOLE UNI-PHARMA"},
{label: "THIOCOLCHICOSIDE ALMUS"},
{label: "THIOCOLCHICOSIDE ALTER"},
{label: "THIOCOLCHICOSIDE ARROW"},
{label: "THIOCOLCHICOSIDE BIOGARAN"},
{label: "THIOCOLCHICOSIDE CEDIAT MEDIFFUSION"},
{label: "THIOCOLCHICOSIDE CRISTERS"},
{label: "THIOCOLCHICOSIDE DAIICHI SANKYO"},
{label: "THIOCOLCHICOSIDE EG"},
{label: "THIOCOLCHICOSIDE FORNET"},
{label: "THIOCOLCHICOSIDE GNR"},
{label: "THIOCOLCHICOSIDE PHARMY II"},
{label: "THIOCOLCHICOSIDE QUALIMED"},
{label: "THIOCOLCHICOSIDE QUIVER"},
{label: "THIOCOLCHICOSIDE RANBAXY"},
{label: "THIOCOLCHICOSIDE RATIOPHARM"},
{label: "THIOCOLCHICOSIDE SANDOZ"},
{label: "THIOCOLCHICOSIDE TEVA"},
{label: "THIOCOLCHICOSIDE VIATRIS"},
{label: "THIOCOLCHICOSIDE ZENTIVA"},
{label: "THIOPECTOL ADULTES"},
{label: "THIOPECTOL ENFANTS"},
{label: "THIOPECTOL NOURRISSONS"},
{label: "THIOPENTAL PANPHARMA"},
{label: "THIOPENTAL VUAB"},
{label: "THIOPHENICOL"},
{label: "THIOPHEOL"},
{label: "THIOPHEOL ADULTES"},
{label: "THIOPHEOL ENFANTS"},
{label: "THIOPON"},
{label: "THIOPON BALSAMIQUE"},
{label: "THIOPON PANTOTHENIQUE ADULTES"},
{label: "THIOPON PANTOTHENIQUE ENFANTS"},
{label: "THIOSEDAL"},
{label: "THIOSINAMINUM BOIRON"},
{label: "THIOTEPA FRESENIUS KABI"},
{label: "THIOTEPA GENOPHARM"},
{label: "THIOTEPA MEDAC"},
{label: "THIOTEPA RIEMSER"},
{label: "THIOVALONE"},
{label: "THIRIAL"},
{label: "THUYA OCCIDENTALIS BOIRON"},
{label: "THUYA OCCIDENTALIS FERRIER"},
{label: "THUYA OCCIDENTALIS LEHNING"},
{label: "THUYA OCCIDENTALIS TEINTURE MERE BOIRON"},
{label: "THUYA OCCIDENTALIS WELEDA"},
{label: "THYM INFUSETTE LES BONS PRODUCTEURS"},
{label: "THYMANAX"},
{label: "THYMEXIA"},
{label: "THYMOGLOBULINE"},
{label: "THYMULINE BOIRON"},
{label: "THYMULINE LEHNING"},
{label: "THYMUS SERPYLLUM BOIRON"},
{label: "THYMUSINE BOIRON"},
{label: "THYMUSINE WELEDA"},
{label: "THYROFIX"},
{label: "THYROGEN"},
{label: "THYROIDEA BOIRON"},
{label: "THYROIDEA WELEDA"},
{label: "THYRONA"},
{label: "THYROZOL"},
{label: "TIADILON"},
{label: "TIANEPTINE BGR"},
{label: "TIANEPTINE BIOGARAN"},
{label: "TIANEPTINE LICONSA"},
{label: "TIANEPTINE VIATRIS"},
{label: "TIANEPTINE ZYDUS"},
{label: "TIAPRIDAL"},
{label: "TIAPRIDE GNR"},
{label: "TIAPRIDE MERCK"},
{label: "TIAPRIDE PAN PHARMA"},
{label: "TIAPRIDE PANPHARMA"},
{label: "TIAPRIDE RENAUDIN"},
{label: "TIAPRIDE SANDOZ"},
{label: "TIAPRIDE VIATRIS"},
{label: "TIAPRIDE WINTHROP"},
{label: "TIBERAL"},
{label: "TIBIFINE"},
{label: "TIBOLONE CCD"},
{label: "TIBOLONE SANDOZ"},
{label: "TIBOLONE TEVA"},
{label: "TIBOLONE VIATRIS"},
{label: "TIBURON"},
{label: "TICAGRELOR BIOGARAN"},
{label: "TICAGRELOR EG"},
{label: "TICAGRELOR KRKA"},
{label: "TICAGRELOR MICRO LABS"},
{label: "TICAGRELOR SANDOZ"},
{label: "TICAGRELOR VIATRIS"},
{label: "TICAGRELOR ZENTIVA"},
{label: "TICARPEN"},
{label: "TICAVATE"},
{label: "TICLID"},
{label: "TICLOMED"},
{label: "TICLOPIDINE ARROW"},
{label: "TICLOPIDINE EG"},
{label: "TICLOPIDINE GENEVAR"},
{label: "TICLOPIDINE HEXAL"},
{label: "TICLOPIDINE IVAX"},
{label: "TICLOPIDINE MYLAN"},
{label: "TICLOPIDINE QUALIMED"},
{label: "TICLOPIDINE RATIOPHARM"},
{label: "TICLOPIDINE SANDOZ"},
{label: "TICLOPIDINE TEVA"},
{label: "TICOVAC"},
{label: "TIENAM"},
{label: "TIFIBINE"},
{label: "TIFIVAL"},
{label: "TIFIX"},
{label: "TIFOMYCINE"},
{label: "TIGASON"},
{label: "TIGECYCLINE ACCORD"},
{label: "TIGECYCLINE FRESENIUS KABI"},
{label: "TIGECYCLINE HIKMA"},
{label: "TIGECYCLINE PIRAMAL"},
{label: "TIGECYCLINE VIATRIS"},
{label: "TIGREAT"},
{label: "TILADE"},
{label: "TILADE SYNCRONER"},
{label: "TILARIN"},
{label: "TILAVIST"},
{label: "TILCOTIL"},
{label: "TILDIEM"},
{label: "TILDIEM L P"},
{label: "TILIA EUROPAEA BOIRON"},
{label: "TILIA TOMENTOSA"},
{label: "TILIA TOMENTOSA MACERAT GLYCERINE 1DH BOIRON"},
{label: "TILLHEPO"},
{label: "TIMABAK"},
{label: "TIMACOR"},
{label: "TIMIFIT"},
{label: "TIMOCOMOD"},
{label: "TIMOFEROL"},
{label: "TIMOFLUID"},
{label: "TIMOLOL ALCON"},
{label: "TIMOLOL CHAUVIN"},
{label: "TIMOLOL IMMEDICA"},
{label: "TIMOLOL SANDOZ"},
{label: "TIMOLOL TEVA"},
{label: "TIMOPTOL"},
{label: "TIMOPTOL L.P."},
{label: "TIMOPTOL LP"},
{label: "TIMOSOPT"},
{label: "TIMPILO"},
{label: "TINSET"},
{label: "TIOBLIS"},
{label: "TIORFAN"},
{label: "TIORFANOR"},
{label: "TIORFAST"},
{label: "TIOTROPIUM BIOGARAN EOLIP"},
{label: "TIOTROPIUM VIATRIS"},
{label: "TIPUREX"},
{label: "TIREGAN"},
{label: "TIROFIBAN ALTAN"},
{label: "TIROFIBAN MEDAC"},
{label: "TISANE BORKOU"},
{label: "TISANE CENTAURIA"},
{label: "TISANE CISBEY"},
{label: "TISANE D'ARS"},
{label: "TISANE DE SANTE"},
{label: "TISANE DE TOURAINE"},
{label: "TISANE DES ALPES DE RECH"},
{label: "TISANE DES FAMILLES"},
{label: "TISANE DIGESTIVE DOLISOS"},
{label: "TISANE DU MONT SAINTE ODILE N°5 LAXATIVE"},
{label: "TISANE DU VIEIL ARDENNAIS"},
{label: "TISANE FRANKLIN"},
{label: "TISANE GARFIELD"},
{label: "TISANE HEPATIQUE DE HOERDT"},
{label: "TISANE LAXATIVE DOLISOS"},
{label: "TISANE LAXATIVE H&S"},
{label: "TISANE LAXATIVE VITAFLOR"},
{label: "TISANE MEDIFLOR N°1 MINCEUR"},
{label: "TISANE MEDIFLOR N°12 JAMBES LOURDES"},
{label: "TISANE MEDIFLOR N°2 DOULEURS ARTICULAIRES"},
{label: "TISANE MEDIFLOR N°3 DIGESTIVE"},
{label: "TISANE MEDIFLOR N°4 DIURETIQUE"},
{label: "TISANE MEDIFLOR N°5 HEPATIQUE"},
{label: "TISANE MEDIFLOR N°8 PECTORALE D'ALSACE"},
{label: "TISANE MEXICAINE"},
{label: "TISANE NERVA"},
{label: "TISANE ORIENTALE SOKER"},
{label: "TISANE PHLEBOSEDOL"},
{label: "TISANE PROVENCALE"},
{label: "TISANE PROVENCALE N°"},
{label: "TISANE PROVENCALE N°1"},
{label: "TISANE PROVENCALE N°3"},
{label: "TISANE PROVENCALE N°5"},
{label: "TISANE PROVENCALE N°6 DOULEURS ARTICULAIRES"},
{label: "TISANE SAINT LUC"},
{label: "TISANE SAINT URBAIN"},
{label: "TISANE SAINTE VICTOIRE"},
{label: "TISANE SVELTA"},
{label: "TISANE UREX"},
{label: "TISSEEL"},
{label: "TISSU CAPILLAIRE BOIRON"},
{label: "TISSUCOL KIT"},
{label: "TITANORAL"},
{label: "TITANOREINE"},
{label: "TITANOREINE A LA LIDOCAINE"},
{label: "TIVICAY"},
{label: "TIXAIR"},
{label: "TIXOCORTOL ARROW 1%"},
{label: "TIXOCORTOL BIOGARAN"},
{label: "TIXOCORTOL EG 1%"},
{label: "TIXOCORTOL H4 PHARMA 1%"},
{label: "TIXOCORTOL LIBERTY PHARMA 1%"},
{label: "TIXOCORTOL TEVA"},
{label: "TIXOCORTOL VIATRIS 1%"},
{label: "TIXOCORTOL ZENTIVA"},
{label: "TIXTAR"},
{label: "TOBI"},
{label: "TOBI PODHALER"},
{label: "TOBRABACT"},
{label: "TOBRACOLL"},
{label: "TOBRADEX"},
{label: "TOBRAMYCINE B. BRAUN"},
{label: "TOBRAMYCINE MEDAC"},
{label: "TOBRAMYCINE MYLAN"},
{label: "TOBRAMYCINE QUALIMED"},
{label: "TOBRAMYCINE RPG"},
{label: "TOBRAMYCINE SUN"},
{label: "TOBRAMYCINE ZENTIVA"},
{label: "TOBREX"},
{label: "TOBREX L.P."},
{label: "TOCLASE EXPECTORANT"},
{label: "TOCLASE TOUX SECHE SANS SUCRE"},
{label: "TOCO"},
{label: "TOCOGESTAN"},
{label: "TOCOLION"},
{label: "TOCOMINE"},
{label: "TOCOPA"},
{label: "TOCOPHEROL CRISTERS"},
{label: "TOCOPHEROL NIVERPHARM"},
{label: "TOCOPHEROL TEVA"},
{label: "TOCTINO"},
{label: "TODEXAL"},
{label: "TOFRANIL"},
{label: "TOGAL"},
{label: "TOKIMARICILE"},
{label: "TOLAK"},
{label: "TOLERANE"},
{label: "TOLEXINE"},
{label: "TOLRESO"},
{label: "TOLTERODINE ACCORD"},
{label: "TOLTERODINE PFIZER"},
{label: "TOLTERODINE PFIZER L.P."},
{label: "TOLTERODINE TEVA"},
{label: "TOLTERYA"},
{label: "TOLUCOMBI"},
{label: "TOLURA"},
{label: "TOLVAPTAN TEVA"},
{label: "TOMUDEX"},
{label: "TONACTIL"},
{label: "TONIBRAL ADULTES"},
{label: "TONIBRAL ENFANTS"},
{label: "TONICALCIUM ADULTES"},
{label: "TONICALCIUM ENFANTS"},
{label: "TONICYL FER"},
{label: "TONIGIN"},
{label: "TONIKOLA"},
{label: "TONILAX"},
{label: "TONIVENE"},
{label: "TOOKAD"},
{label: "TOP MAG"},
{label: "TOPAAL"},
{label: "TOPALGIC"},
{label: "TOPALGIC LP"},
{label: "TOPALKAN"},
{label: "TOPEX"},
{label: "TOPFENA"},
{label: "TOPFENA L P"},
{label: "TOPICORTE"},
{label: "TOPIFRAM"},
{label: "TOPILAR"},
{label: "TOPIQUE DU JUIF ERRANT"},
{label: "TOPIRAMATE ACCORD"},
{label: "TOPIRAMATE ACTAVIS"},
{label: "TOPIRAMATE ARROW"},
{label: "TOPIRAMATE ARROW GENERIQUES"},
{label: "TOPIRAMATE ARROW LAB"},
{label: "TOPIRAMATE BGR"},
{label: "TOPIRAMATE BIOGARAN"},
{label: "TOPIRAMATE BLUEFISH"},
{label: "TOPIRAMATE DELBERT"},
{label: "TOPIRAMATE EG"},
{label: "TOPIRAMATE GLENMARCK GENERICS"},
{label: "TOPIRAMATE GLENMARK GENERICS"},
{label: "TOPIRAMATE NIALEX"},
{label: "TOPIRAMATE OPENING PHARMA"},
{label: "TOPIRAMATE PFIZER"},
{label: "TOPIRAMATE PHARMATHEN"},
{label: "TOPIRAMATE QUALIMED"},
{label: "TOPIRAMATE RANBAXY"},
{label: "TOPIRAMATE SANDOZ"},
{label: "TOPIRAMATE SUN"},
{label: "TOPIRAMATE TEVA"},
{label: "TOPIRAMATE VENIPHARM"},
{label: "TOPIRAMATE VIATRIS"},
{label: "TOPIRAMATE ZYDUS"},
{label: "TOPISCAB"},
{label: "TOPITAREN"},
{label: "TOPLEXIL"},
{label: "TOPOCALC"},
{label: "TOPOTECAN ACTAVIS"},
{label: "TOPOTECAN HOSPIRA"},
{label: "TOPOTECAN KABI"},
{label: "TOPOTECAN MEDAC"},
{label: "TOPOTECAN MYLAN"},
{label: "TOPOTECAN OPENING PHARMA"},
{label: "TOPOTECAN SANDOZ"},
{label: "TOPOTECAN TEVA"},
{label: "TOPOTECANE ACCORD"},
{label: "TOPOTECANE ACCORD HEALTHCARE"},
{label: "TOPOTECANE FAIR-MED HEALTHCARE"},
{label: "TOPOTECANE KABI"},
{label: "TOPOTECANE MARTINDALE PHARMA"},
{label: "TOPREC"},
{label: "TOPSYNE"},
{label: "TOPSYNE A P G"},
{label: "TOPSYNE NEOMYCINE"},
{label: "TOREM"},
{label: "TORENTAL"},
{label: "TORENTAL L.P."},
{label: "TORENTAL LP"},
{label: "TORISEL"},
{label: "TOSSAREL"},
{label: "TOT'HEMA"},
{label: "TOTAMINE CONCENTRE"},
{label: "TOTAMINE CONCENTRE GLUCIDIQUE"},
{label: "TOTAMINE GLUCIDIQUE"},
{label: "TOTAPEN"},
{label: "TOTELLE"},
{label: "TOUJEO"},
{label: "TOURMALINE LITHIQUE BOIRON"},
{label: "TOVIAZ"},
{label: "TOXICARB"},
{label: "TRABECTEDINE EVER PHARMA"},
{label: "TRABECTEDINE SUN"},
{label: "TRABECTEDINE TEVA"},
{label: "TRACHYL"},
{label: "TRACHYTE BOIRON"},
{label: "TRACITRANS"},
{label: "TRACLEER"},
{label: "TRACRIUM"},
{label: "TRACTOCILE"},
{label: "TRACUTIL"},
{label: "TRADOLORIS"},
{label: "TRAGLASIN"},
{label: "TRAITEMENT SPAD"},
{label: "TRAJENTA"},
{label: "TRALZARO"},
{label: "TRAMADOL ACTAVIS"},
{label: "TRAMADOL ACTAVIS L.P."},
{label: "TRAMADOL ALMUS"},
{label: "TRAMADOL ARROW"},
{label: "TRAMADOL ARROW L.P."},
{label: "TRAMADOL ARROW LAB"},
{label: "TRAMADOL BGR L.P."},
{label: "TRAMADOL BIOGARAN"},
{label: "TRAMADOL BIOGARAN LP"},
{label: "TRAMADOL BIOSTABILEX LP"},
{label: "TRAMADOL CEHEL"},
{label: "TRAMADOL CLL PHARMA"},
{label: "TRAMADOL CRISTERS LP"},
{label: "TRAMADOL DAKOTA PHARM"},
{label: "TRAMADOL DELBERT L.P."},
{label: "TRAMADOL DISPHAR"},
{label: "TRAMADOL EG"},
{label: "TRAMADOL EG L.P."},
{label: "TRAMADOL EVOLUGEN"},
{label: "TRAMADOL EVOLUGEN L.P."},
{label: "TRAMADOL HCS"},
{label: "TRAMADOL IVAX"},
{label: "TRAMADOL KRKA LP"},
{label: "TRAMADOL LAVOISIER"},
{label: "TRAMADOL LEURQUIN MEDIOLANUM"},
{label: "TRAMADOL MILGEN"},
{label: "TRAMADOL MYLAN"},
{label: "TRAMADOL MYLAN GENERIQUES LP"},
{label: "TRAMADOL MYLAN LP"},
{label: "TRAMADOL MYLAN PHARMA LP"},
{label: "TRAMADOL PARACETAMOL EVOLUGEN"},
{label: "TRAMADOL QUALIMED"},
{label: "TRAMADOL REF L.P."},
{label: "TRAMADOL RUBIEPHARM"},
{label: "TRAMADOL SANDOZ"},
{label: "TRAMADOL SANDOZ L.P."},
{label: "TRAMADOL TEVA"},
{label: "TRAMADOL TEVA L.P."},
{label: "TRAMADOL TEVA SANTE"},
{label: "TRAMADOL VIATRIS"},
{label: "TRAMADOL VIATRIS LP"},
{label: "TRAMADOL ZENTIVA"},
{label: "TRAMADOL ZENTIVA LP"},
{label: "TRAMADOL ZF LP"},
{label: "TRAMADOL ZYDUS"},
{label: "TRAMADOL ZYDUS L.P."},
{label: "TRAMADOL ZYDUS LP"},
{label: "TRAMADOL/PARACETAMOL ACCORD"},
{label: "TRAMADOL/PARACETAMOL ALPEX"},
{label: "TRAMADOL/PARACETAMOL ARROW"},
{label: "TRAMADOL/PARACETAMOL ARROW GENERIQUES"},
{label: "TRAMADOL/PARACETAMOL ARROW LAB"},
{label: "TRAMADOL/PARACETAMOL BAILLY-CREAT"},
{label: "TRAMADOL/PARACETAMOL BGR"},
{label: "TRAMADOL/PARACETAMOL BIPHAR"},
{label: "TRAMADOL/PARACETAMOL CRISTERS"},
{label: "TRAMADOL/PARACETAMOL EG"},
{label: "TRAMADOL/PARACETAMOL EG LABO"},
{label: "TRAMADOL/PARACETAMOL EVOLUGEN PHARMA"},
{label: "TRAMADOL/PARACETAMOL GRUNENTHAL"},
{label: "TRAMADOL/PARACETAMOL ISOMED"},
{label: "TRAMADOL/PARACETAMOL IXPREFF"},
{label: "TRAMADOL/PARACETAMOL KRKA"},
{label: "TRAMADOL/PARACETAMOL MYLAN"},
{label: "TRAMADOL/PARACETAMOL PHR LAB"},
{label: "TRAMADOL/PARACETAMOL SANDOZ"},
{label: "TRAMADOL/PARACETAMOL SUBSTIPHARM"},
{label: "TRAMADOL/PARACETAMOL SUN"},
{label: "TRAMADOL/PARACETAMOL TEVA 37.5"},
{label: "TRAMADOL/PARACETAMOL TEVA SANTE"},
{label: "TRAMADOL/PARACETAMOL VENIPHARM"},
{label: "TRAMADOL/PARACETAMOL VIATRIS"},
{label: "TRAMADOL/PARACETAMOL ZALDEFF"},
{label: "TRAMADOL/PARACETAMOL ZENTIVA"},
{label: "TRAMADOL/PARACETAMOL ZYDUS"},
{label: "TRAMADOL/PARACETAMOL ZYDUS FRANCE"},
{label: "TRAMELENE"},
{label: "TRAMISAL"},
{label: "TRANDATE"},
{label: "TRANDOLAPRIL ACTAVIS"},
{label: "TRANDOLAPRIL ARROW"},
{label: "TRANDOLAPRIL BIOGARAN"},
{label: "TRANDOLAPRIL EG"},
{label: "TRANDOLAPRIL PFIZER"},
{label: "TRANDOLAPRIL QUALIMED"},
{label: "TRANDOLAPRIL RATIOPHARM"},
{label: "TRANDOLAPRIL SANDOZ"},
{label: "TRANDOLAPRIL TEVA"},
{label: "TRANDOLAPRIL VIATRIS"},
{label: "TRANETIZ"},
{label: "TRANQUILIMAG"},
{label: "TRANQUITAL"},
{label: "TRANSACALM"},
{label: "TRANSBILIX (30"},
{label: "TRANSIDEAL"},
{label: "TRANSILANE"},
{label: "TRANSILANE SANS SUCRE"},
{label: "TRANSIPEG"},
{label: "TRANSIPEGLIB"},
{label: "TRANSITOL"},
{label: "TRANSLARNA"},
{label: "TRANSMER"},
{label: "TRANSODDI"},
{label: "TRANSULOSE"},
{label: "TRANSVERCID"},
{label: "TRANXENE"},
{label: "TRASEDAL"},
{label: "TRASICOR"},
{label: "TRASICOR RETARD"},
{label: "TRASIPRESSOL"},
{label: "TRASITENSINE"},
{label: "TRASYLOL"},
{label: "TRAUMALGYL"},
{label: "TRAVATAN"},
{label: "TRAVOPROST ARROW"},
{label: "TRAVOPROST BGR"},
{label: "TRAVOPROST BIOGARAN"},
{label: "TRAVOPROST CRISTERS"},
{label: "TRAVOPROST EG"},
{label: "TRAVOPROST EG LABO"},
{label: "TRAVOPROST MYLAN PHARMA"},
{label: "TRAVOPROST SANDOZ"},
{label: "TRAVOPROST TEVA"},
{label: "TRAVOPROST TEVA SANTE"},
{label: "TRAVOPROST VIATRIS"},
{label: "TRAVOPROST ZENTIVA"},
{label: "TRAVOPROST/TIMOLOL ARROW"},
{label: "TRAVOPROST/TIMOLOL BIOGARAN"},
{label: "TRAVOPROST/TIMOLOL CRISTERS"},
{label: "TRAVOPROST/TIMOLOL EG"},
{label: "TRAVOPROST/TIMOLOL SANDOZ"},
{label: "TRAVOPROST/TIMOLOL TEVA"},
{label: "TRAVOPROST/TIMOLOL VIATRIS"},
{label: "TRAVOPROST/TIMOLOL ZENTIVA"},
{label: "TRAZEC"},
{label: "TRAZIMERA"},
{label: "TRECONDI"},
{label: "TREDAPTIVE"},
{label: "TREDEMINE"},
{label: "TRELEGY ELLIPTA"},
{label: "TREMFYA"},
{label: "TRENTADIL"},
{label: "TRENTOVLANE"},
{label: "TREOSULFAN TILLOMED"},
{label: "TREPOSUVI"},
{label: "TREPROSTINIL REDDY PHARMA"},
{label: "TREPROSTINIL TILLOMED"},
{label: "TRESIBA"},
{label: "TRETINOINE KEFRANE"},
{label: "TREVACLYN"},
{label: "TREVICTA"},
{label: "TREVILOR"},
{label: "TREVILOR L.P."},
{label: "TRH FERRING"},
{label: "TRI MINULET"},
{label: "TRIACANA"},
{label: "TRIACEFAN"},
{label: "TRIAFEMI"},
{label: "TRIAMINIC"},
{label: "TRIATEC"},
{label: "TRIATEC FAIBLE"},
{label: "TRIATECKIT"},
{label: "TRIAXELER"},
{label: "TRIAXIS"},
{label: "TRICILEST"},
{label: "TRICLOCARBAN OMEGA PHARMA"},
{label: "TRICORLIX"},
{label: "TRICOSTERIL CORIPEL CORS"},
{label: "TRICOSTERIL CORIPEL DURILLONS"},
{label: "TRIDESONIT"},
{label: "TRIDIGESTINE HEPATOUM"},
{label: "TRIELLA"},
{label: "TRIENTINE WAYMADE"},
{label: "TRIESENCE"},
{label: "TRIFIDE LP"},
{label: "TRIFLUCAN"},
{label: "TRIFLURIDINE CHAUVIN"},
{label: "TRIFOLIUM PRATENSE BOIRON"},
{label: "TRIGEMAX"},
{label: "TRIGLISTAB"},
{label: "TRIGLYSAL"},
{label: "TRIGONIST"},
{label: "TRIHERPINE"},
{label: "TRIHEXY"},
{label: "TRILEPTAL"},
{label: "TRILIFAN RETARD"},
{label: "TRILLIUM PENDULUM BOIRON"},
{label: "TRIMADIAZ ANTRIMA"},
{label: "TRIMADIAZ ANTRIMA NOURRISSONS ET ENFANTS"},
{label: "TRIMBOW"},
{label: "TRIMEBUTINE (MALEATE) RPG"},
{label: "TRIMEBUTINE ACTAVIS"},
{label: "TRIMEBUTINE ALMUS"},
{label: "TRIMEBUTINE ALTER"},
{label: "TRIMEBUTINE ARROW"},
{label: "TRIMEBUTINE ARROW GENERIQUES"},
{label: "TRIMEBUTINE BIOGARAN"},
{label: "TRIMEBUTINE BIOGARAN CONSEIL"},
{label: "TRIMEBUTINE CRISTERS"},
{label: "TRIMEBUTINE EG"},
{label: "TRIMEBUTINE EVOLUGEN"},
{label: "TRIMEBUTINE EXPANPHARM"},
{label: "TRIMEBUTINE G GAM"},
{label: "TRIMEBUTINE GENEVAR"},
{label: "TRIMEBUTINE GNR"},
{label: "TRIMEBUTINE ISOMED"},
{label: "TRIMEBUTINE MALEATE BOUCHARD"},
{label: "TRIMEBUTINE MEDISOL"},
{label: "TRIMEBUTINE PFIZER"},
{label: "TRIMEBUTINE PFIZER ENFANT ET NOURRISSON"},
{label: "TRIMEBUTINE RATIO"},
{label: "TRIMEBUTINE RATIOPHARM"},
{label: "TRIMEBUTINE RPG"},
{label: "TRIMEBUTINE SANDOZ"},
{label: "TRIMEBUTINE SET"},
{label: "TRIMEBUTINE SG PHARM"},
{label: "TRIMEBUTINE SG-PHARM"},
{label: "TRIMEBUTINE SUBSTIPHARM"},
{label: "TRIMEBUTINE SUN"},
{label: "TRIMEBUTINE TEVA"},
{label: "TRIMEBUTINE TEVA SANTE"},
{label: "TRIMEBUTINE VIATRIS"},
{label: "TRIMEBUTINE VIATRIS CONSEIL"},
{label: "TRIMEBUTINE ZENTIVA"},
{label: "TRIMEBUTINE ZYDUS"},
{label: "TRIMEDIC"},
{label: "TRIMETAZIDINE ACTAVIS"},
{label: "TRIMETAZIDINE ARROW"},
{label: "TRIMETAZIDINE BGR"},
{label: "TRIMETAZIDINE BIOGARAN"},
{label: "TRIMETAZIDINE BIOPHARMA"},
{label: "TRIMETAZIDINE CARDEL"},
{label: "TRIMETAZIDINE CLL PHARMA"},
{label: "TRIMETAZIDINE CRISTERS"},
{label: "TRIMETAZIDINE EG"},
{label: "TRIMETAZIDINE EUTHERAPIE"},
{label: "TRIMETAZIDINE G GAM"},
{label: "TRIMETAZIDINE IGEN"},
{label: "TRIMETAZIDINE IPSOR"},
{label: "TRIMETAZIDINE ISOMED"},
{label: "TRIMETAZIDINE IVAX"},
{label: "TRIMETAZIDINE MERCK GENERICS FRANCE HOLDING"},
{label: "TRIMETAZIDINE MILGEN"},
{label: "TRIMETAZIDINE MYLAN"},
{label: "TRIMETAZIDINE NOVALIS"},
{label: "TRIMETAZIDINE QUALIMED"},
{label: "TRIMETAZIDINE QUIVER"},
{label: "TRIMETAZIDINE RATIOPHARM"},
{label: "TRIMETAZIDINE REF"},
{label: "TRIMETAZIDINE RPG"},
{label: "TRIMETAZIDINE SANDOZ"},
{label: "TRIMETAZIDINE SERVIER"},
{label: "TRIMETAZIDINE SUBSTIPHARM"},
{label: "TRIMETAZIDINE TEVA"},
{label: "TRIMETAZIDINE ZENTIVA"},
{label: "TRIMETAZIDINE ZYDUS"},
{label: "TRIMEVENI"},
{label: "TRINARA"},
{label: "TRINIPATCH"},
{label: "TRINITRINE CAFEINEE DUBOIS"},
{label: "TRINITRINE LALEUF"},
{label: "TRINITRINE PIERRE FABRE"},
{label: "TRINITRINE SIMPLE LALEUF"},
{label: "TRINITRINE VIATRIS"},
{label: "TRINORDIOL"},
{label: "TRIOGENE"},
{label: "TRIPERIDOL"},
{label: "TRIPHOSMAG"},
{label: "TRIPLIXAM"},
{label: "TRIPLIXAM 2.5"},
{label: "TRIQUILAR"},
{label: "TRISENOX"},
{label: "TRISEQUENS"},
{label: "TRISEVIKAR"},
{label: "TRISOLVIT"},
{label: "TRISORALENE"},
{label: "TRITICUM REPENS BOIRON"},
{label: "TRIUMEQ"},
{label: "TRIVASTAL©"},
{label: "TRIVASTAL© INJECTABLE"},
{label: "TRIVE"},
{label: "TRIVERAM"},
{label: "TRIXEO AEROSPHERE"},
{label: "TRIZIVIR"},
{label: "TROBALT"},
{label: "TROBICINE"},
{label: "TRODELVY"},
{label: "TROFOSEPTINE"},
{label: "TROGARZO"},
{label: "TROLAMINE BIOGARAN"},
{label: "TROLAMINE BIOGARAN CONSEIL"},
{label: "TROLAMINE PHARMA DEVELOPPEMENT"},
{label: "TROLISE"},
{label: "TROLOVOL"},
{label: "TROMBOVAR"},
{label: "TRONOTHANE"},
{label: "TROPAEOLUM MAJUS BOIRON"},
{label: "TROPHICREME"},
{label: "TROPHIDERM"},
{label: "TROPHIGIL"},
{label: "TROPHIRES ADULTES"},
{label: "TROPHIRES COMPOSE ADULTES"},
{label: "TROPHIRES COMPOSE ENFANTS"},
{label: "TROPHIRES COMPOSE NOURRISSONS"},
{label: "TROPHIRES ENFANTS"},
{label: "TROPHIRES NOURRISSONS"},
{label: "TROPHYSAN GLUCIDIQUE AROMATISE"},
{label: "TROPHYSAN L GLUCIDIQUE"},
{label: "TROPHYSAN L-SIMPLE"},
{label: "TROSPIPHARM"},
{label: "TROSYD"},
{label: "TROUSSE POUR LA PREPARATION DE LA SOLUTION INJECTABLE RADIOACTIVE DE METHYLENE DIPHOSPHONATE"},
{label: "TROXERUTINE"},
{label: "TROXERUTINE ALTER"},
{label: "TROXERUTINE ARROW"},
{label: "TROXERUTINE BIOGARAN CONSEIL"},
{label: "TROXERUTINE EG"},
{label: "TROXERUTINE ETHYPHARM"},
{label: "TROXERUTINE G GAM"},
{label: "TROXERUTINE HEXAL SANTE"},
{label: "TROXERUTINE MAZAL"},
{label: "TROXERUTINE MERCK GENERIQUES"},
{label: "TROXERUTINE MYLAN"},
{label: "TROXERUTINE NOR"},
{label: "TROXERUTINE QUALIMED"},
{label: "TROXERUTINE RPG"},
{label: "TROXERUTINE SANDOZ CONSEIL"},
{label: "TROXERUTINE SET"},
{label: "TROXERUTINE TEVA CONSEIL"},
{label: "TROXERUTINE URPAC-ASTIER"},
{label: "TRUBERZI"},
{label: "TRUDEXA"},
{label: "TRUE TEST"},
{label: "TRUE TEST PANEL"},
{label: "TRULICITY"},
{label: "TRUMENBA"},
{label: "TRUSOPT"},
{label: "TRUVADA"},
{label: "TRUXIMA"},
{label: "TRYDONIS"},
{label: "TSOLUDOSE"},
{label: "TUBERCULINE AVENTIS PASTEUR"},
{label: "TUBERCULINE PURIFIEE MONOTEST"},
{label: "TUBERCULINUM BOIRON"},
{label: "TUBERCULINUM LEHNING"},
{label: "TUBERCULINUM RESIDUUM BOIRON"},
{label: "TUBERTEST"},
{label: "TUBIBERMIDE"},
{label: "TUKYSA"},
{label: "TULLE GRAS LUMIERE"},
{label: "TUROX"},
{label: "TUSSICALM ADULTES"},
{label: "TUSSICALM ENFANTS"},
{label: "TUSSIDANE"},
{label: "TUSSIDORON"},
{label: "TUSSILAGO FARFARA BOIRON"},
{label: "TUSSILENE"},
{label: "TUSSILENE ADULTE"},
{label: "TUSSILENE ENFANT"},
{label: "TUSSIPAX"},
{label: "TUSSISEDAL"},
{label: "TUSSONYL"},
{label: "TUXIUM"},
{label: "TWICOR"},
{label: "TWINJECT"},
{label: "TWINRIX ADULTE"},
{label: "TWINRIX ENFANT"},
{label: "TWYNSTA"},
{label: "TYAVAX"},
{label: "TYBOST"},
{label: "TYGACIL"},
{label: "TYLENOL"},
{label: "TYLENOL ENFANTS"},
{label: "TYLOFEN"},
{label: "TYPHERIX"},
{label: "TYPHIM"},
{label: "TYRCINE"},
{label: "TYROGORGE"},
{label: "TYROTHRICINE LABORATOIRES DE THERAPEUTIQUE MODERNE"},
{label: "TYROTHRICINE TETRACAÏNE COOPER"},
{label: "TYROTRICYL"},
{label: "TYSABRI"},
{label: "TYVERB"},
{label: "TYZINE"},
{label: "UBISTESIN ADRENALINEE"},
{label: "UBISTESIN ADRENALINEE 1/400"},
{label: "UBIT"},
{label: "UCEDANE"},
{label: "UFT"},
{label: "ULCAR"},
{label: "ULCILANE"},
{label: "ULEXITE BOIRON"},
{label: "ULFON"},
{label: "ULFON LYOC"},
{label: "ULIPRISTAL ACETATE ACCORD"},
{label: "ULIPRISTAL ACETATE BIOGARAN"},
{label: "ULIPRISTAL ACETATE EG"},
{label: "ULIPRISTAL ACETATE EXELTIS"},
{label: "ULIPRISTAL ACETATE HELM"},
{label: "ULIPRISTAL ACETATE VIATRIS"},
{label: "ULIPRISTAL ACETATE ZENTIVA"},
{label: "ULMUS CAMPESTRIS BOIRON"},
{label: "ULTACITE"},
{label: "ULTARCORLENE"},
{label: "ULTIBRO BREEZHALER"},
{label: "ULTIVA"},
{label: "ULTOMIRIS"},
{label: "ULTRA-LEVURE"},
{label: "ULTRACAINE"},
{label: "ULTRADERME"},
{label: "ULTRAFLORE"},
{label: "ULTRALAN"},
{label: "ULTRAPROCT"},
{label: "ULTRATARD"},
{label: "ULTRATECHNEKOW FM"},
{label: "ULTRAVIST"},
{label: "UMATROPE"},
{label: "UMULINE"},
{label: "UMULINE NPH"},
{label: "UMULINE NPH KWIKPEN"},
{label: "UMULINE PROFIL"},
{label: "UMULINE PROFIL 10."},
{label: "UMULINE PROFIL 10.100"},
{label: "UMULINE PROFIL 10.100 UI/1"},
{label: "UMULINE PROFIL 20.100"},
{label: "UMULINE PROFIL 40.100 UI/"},
{label: "UMULINE PROFIL 40.100 UI/1"},
{label: "UMULINE PROFIL 50.100 UI/1"},
{label: "UMULINE PROTAMINE ISOPHANE"},
{label: "UMULINE RAPIDE"},
{label: "UMULINE RAPIDE KWIKPEN"},
{label: "UMULINE ZINC"},
{label: "UMULINE ZINC COMPOSE"},
{label: "UN ALFA"},
{label: "UN-ALFA"},
{label: "UNACIM INJECTABLE"},
{label: "UNACIM INJECTABLE ENFANTS NOURRISSONS"},
{label: "UNACIM INJECTABLE NOURRISSONS"},
{label: "UNASERT"},
{label: "UNGUISAL"},
{label: "UNIBACTER"},
{label: "UNICORDIUM"},
{label: "UNIDOSE STALLERGENES DERMATOPHAGOIDES PTERONYSSINUS"},
{label: "UNIFLOX"},
{label: "UNIFLUID"},
{label: "UNILARM"},
{label: "UNIPEXIL"},
{label: "UNIPHRINE"},
{label: "UNISEPTINE"},
{label: "UNIVAGIL LP"},
{label: "UPFEN"},
{label: "UPLIZNA"},
{label: "UPRIMA"},
{label: "UPSADEX ADULTES"},
{label: "UPSADEX ENFANTS"},
{label: "UPSALGIN"},
{label: "UPSAPHOS"},
{label: "UPSARINE"},
{label: "UPSTAZA"},
{label: "UPTRAVI"},
{label: "URANIUM NITRICUM BOIRON"},
{label: "URAPIDIL ARROW LP"},
{label: "URAPIDIL BIOGARAN LP"},
{label: "URAPIDIL EG LP"},
{label: "URAPIDIL KALCEKS"},
{label: "URAPIDIL NORDIC PHARMA"},
{label: "URAPIDIL STRAGEN"},
{label: "URAPIDIL STRAGEN FRANCE LP"},
{label: "URAPIDIL STRAGEN LP"},
{label: "URAPIDIL SUBSTIPHARM LP"},
{label: "URAPIDIL TEVA LP"},
{label: "URAPIDIL VIATRIS"},
{label: "URAPIDIL VIATRIS LP"},
{label: "URAPIDIL ZENTIVA LP"},
{label: "URARTHONE"},
{label: "URASEPTINE ROGIER"},
{label: "URBANYL"},
{label: "UREALYS"},
{label: "UREE BOIRON"},
{label: "UREMIASE"},
{label: "URFAMYCINE"},
{label: "URGOCOR CORICIDE"},
{label: "URICOZYME"},
{label: "URICUM ACIDUM BOIRON"},
{label: "URICUM ACIDUM LEHNING"},
{label: "URIDEAL"},
{label: "URIDOZ"},
{label: "URION"},
{label: "URISPAS"},
{label: "URIVESC"},
{label: "UROFAST"},
{label: "UROKINASE CHOAY"},
{label: "UROMIL"},
{label: "UROMITEXAN"},
{label: "UROPHYTUM"},
{label: "UROREC"},
{label: "UROSIPHON"},
{label: "UROTISAN"},
{label: "UROTRATE"},
{label: "UROTROPINE LAFRAN"},
{label: "URSOLVAN"},
{label: "URTICA DIOICA BOIRON"},
{label: "URTICA DIOICA WELEDA"},
{label: "URTICA URENS BOIRON"},
{label: "URTICA URENS LEHNING"},
{label: "URTICA URENS WELEDA"},
{label: "USNEA BARBATA BOIRON"},
{label: "UTEPLEX"},
{label: "UTERINE BOIRON"},
{label: "UTROGESTAN"},
{label: "UVA URSI BOIRON"},
{label: "UVA URSI COMPLEXE N°9"},
{label: "UVA URSI LEHNING"},
{label: "UVACNYL"},
{label: "UVADEX"},
{label: "UVECAPS"},
{label: "UVEDOSE"},
{label: "UVELINE"},
{label: "UVESTEROL D"},
{label: "UVESTEROL VITAMINE A.D.E.C."},
{label: "UVICOL"},
{label: "UVIMAG B6"},
{label: "V.A.B LEHNING"},
{label: "V.A.B. BOIRON"},
{label: "VABLYS"},
{label: "VABOREM"},
{label: "VABYSMO"},
{label: "VACCIN BCG"},
{label: "VACCIN BCG INSTITUT MERIEUX"},
{label: "VACCIN BCG PASTEUR INTRADERMIQUE"},
{label: "VACCIN COVID-19 VALNEVA"},
{label: "VACCIN D.T.C.P. PASTEUR"},
{label: "VACCIN D.T.P. PASTEUR"},
{label: "VACCIN GENHEVAC B PASTEUR"},
{label: "VACCIN HEPATITE A AVENTIS PASTEUR MSD"},
{label: "VACCIN MENINGOCOCCIQUE A+C POLYOSIDIQUE"},
{label: "VACCIN OREILLONS AVENTIS PASTEUR MSD"},
{label: "VACCIN PNEUMOCOCCIQUE POLYOSIDIQUE AVENTIS PASTEUR MSD"},
{label: "VACCIN PNEUMOCOCCIQUE SARBACH"},
{label: "VACCIN POLIOMYELITIQUE ORAL"},
{label: "VACCIN POLIOMYELITIQUE ORAL BIVALENT TYPES"},
{label: "VACCIN RABIQUE INACTIVE MERIEUX"},
{label: "VACCIN RABIQUE PASTEUR"},
{label: "VACCIN T.P. PASTEUR"},
{label: "VACCIN TETANIQUE PASTEUR"},
{label: "VACCINOTOXINUM BOIRON"},
{label: "VADILEX"},
{label: "VAFOTENA LP"},
{label: "VAGIFEM"},
{label: "VAGIRUX"},
{label: "VAGOCAINE"},
{label: "VAGRAN"},
{label: "VAGRECOR"},
{label: "VALACICLOVIR ACTAVIS"},
{label: "VALACICLOVIR ALMUS"},
{label: "VALACICLOVIR ALTER"},
{label: "VALACICLOVIR ARROW"},
{label: "VALACICLOVIR ARROW GENERIQUES"},
{label: "VALACICLOVIR AUROBINDO"},
{label: "VALACICLOVIR BIOGARAN"},
{label: "VALACICLOVIR BLUEFISH"},
{label: "VALACICLOVIR BOUCHARA-RECORDATI"},
{label: "VALACICLOVIR CRISTERS"},
{label: "VALACICLOVIR CRISTERS PHARMA"},
{label: "VALACICLOVIR DISPHAR"},
{label: "VALACICLOVIR EG"},
{label: "VALACICLOVIR EG LABO"},
{label: "VALACICLOVIR ENIREX"},
{label: "VALACICLOVIR EVOLUGEN"},
{label: "VALACICLOVIR GSK"},
{label: "VALACICLOVIR ISOMED"},
{label: "VALACICLOVIR JENSON PHARMACEUTICAL SERVICES"},
{label: "VALACICLOVIR LBR"},
{label: "VALACICLOVIR MYLAN"},
{label: "VALACICLOVIR NIALEX"},
{label: "VALACICLOVIR PFIZER"},
{label: "VALACICLOVIR PHARMAKI GENERICS"},
{label: "VALACICLOVIR PHR LAB"},
{label: "VALACICLOVIR QUALIMED"},
{label: "VALACICLOVIR RATIOPHARM"},
{label: "VALACICLOVIR REF"},
{label: "VALACICLOVIR SANDOZ"},
{label: "VALACICLOVIR TEVA"},
{label: "VALACICLOVIR TORRENT"},
{label: "VALACICLOVIR VIATRIS"},
{label: "VALACICLOVIR ZENTIVA"},
{label: "VALACICLOVIR ZYDUS"},
{label: "VALCADINO"},
{label: "VALCOTE"},
{label: "VALDA CITRON"},
{label: "VALDA EXPECTORANT SANS SUCRE"},
{label: "VALDA PIN"},
{label: "VALDA SEPTOL"},
{label: "VALDA TOUX SECHE ADULTES"},
{label: "VALDOXAN"},
{label: "VALERBE"},
{label: "VALERIANA OFFICINALIS BOIRON"},
{label: "VALERIANA OFFICINALIS FERRIER"},
{label: "VALERIANA OFFICINALIS LEHNING"},
{label: "VALERIANA OFFICINALIS WELEDA"},
{label: "VALERIANE BOIRON"},
{label: "VALERIANE ETHYPHARM"},
{label: "VALERIANE PACHAUT"},
{label: "VALERIANE VEMEDIA"},
{label: "VALGANCICLOVIR ACCORD"},
{label: "VALGANCICLOVIR ARROW"},
{label: "VALGANCICLOVIR BIOGARAN"},
{label: "VALGANCICLOVIR CIPLA"},
{label: "VALGANCICLOVIR CRISTERS"},
{label: "VALGANCICLOVIR EG"},
{label: "VALGANCICLOVIR MEDIPHA"},
{label: "VALGANCICLOVIR PHARMATHEN"},
{label: "VALGANCICLOVIR SANDOZ"},
{label: "VALGANCICLOVIR TEVA"},
{label: "VALGANCICLOVIR VIATRIS"},
{label: "VALGANCICLOVIR WELDING"},
{label: "VALGANCICLOVIR ZENTIVA"},
{label: "VALGORENE"},
{label: "VALINOR"},
{label: "VALIUM"},
{label: "VALIUM MICELLES MIXTES"},
{label: "VALIUM ROCHE"},
{label: "VALLCLARA"},
{label: "VALNEURAL"},
{label: "VALPROATE DE SODIUM AGUETTANT"},
{label: "VALPROATE DE SODIUM ALTER L.P."},
{label: "VALPROATE DE SODIUM ARROW"},
{label: "VALPROATE DE SODIUM ARROW L.P."},
{label: "VALPROATE DE SODIUM ARROW LAB"},
{label: "VALPROATE DE SODIUM BIOGARAN L.P."},
{label: "VALPROATE DE SODIUM DAKOTA PHARM"},
{label: "VALPROATE DE SODIUM EG L.P."},
{label: "VALPROATE DE SODIUM GNR L.P."},
{label: "VALPROATE DE SODIUM QUALIMED L.P."},
{label: "VALPROATE DE SODIUM RPG L.P."},
{label: "VALPROATE DE SODIUM SANDOZ L.P."},
{label: "VALPROATE DE SODIUM TEVA L.P."},
{label: "VALPROATE DE SODIUM TEVA SANTE L.P."},
{label: "VALPROATE DE SODIUM VIATRIS L.P."},
{label: "VALPROATE DE SODIUM ZENTIVA"},
{label: "VALPROATE DE SODIUM ZENTIVA L.P."},
{label: "VALPROATE DE SODIUM-ACIDE VALPROIQUE DELALANDE L.P."},
{label: "VALPROATE DE SODIUM-ACIDE VALPROIQUE SANOFI-SYNTHELABO FRANCE L.P."},
{label: "VALSANOMED"},
{label: "VALSARTAN / HYDROCHLOROTHIAZIDE SANDOZ"},
{label: "VALSARTAN AHCL"},
{label: "VALSARTAN ALTER"},
{label: "VALSARTAN ARROW"},
{label: "VALSARTAN ARROW GENERIQUES"},
{label: "VALSARTAN ARROW LAB"},
{label: "VALSARTAN AUROBINDO"},
{label: "VALSARTAN BIOGARAN"},
{label: "VALSARTAN BRAMIX"},
{label: "VALSARTAN CRISTERS"},
{label: "VALSARTAN DEXCEL"},
{label: "VALSARTAN EG"},
{label: "VALSARTAN EVOLUGEN"},
{label: "VALSARTAN HYDROCHLOROTHIAZIDE BIOGARAN"},
{label: "VALSARTAN HYDROCHLOROTHIAZIDE EVOLUGEN"},
{label: "VALSARTAN HYDROCHLOROTHIAZIDE LICONSA"},
{label: "VALSARTAN HYDROCHLOROTHIAZIDE OPENING PHARMA"},
{label: "VALSARTAN HYDROCHLOROTHIAZIDE ZENTIVA LAB"},
{label: "VALSARTAN HYDROCHLOROTHIAZIDE ZYDUS"},
{label: "VALSARTAN ISOMED"},
{label: "VALSARTAN KRKA"},
{label: "VALSARTAN MYLAN"},
{label: "VALSARTAN OPENING PHARMA"},
{label: "VALSARTAN PFIZER"},
{label: "VALSARTAN PHARMAKI GENERICS"},
{label: "VALSARTAN PHR LAB"},
{label: "VALSARTAN RANBAXY"},
{label: "VALSARTAN RATIOPHARM"},
{label: "VALSARTAN REDDY PHARMA"},
{label: "VALSARTAN SANDOZ"},
{label: "VALSARTAN SUBSTIPHARM"},
{label: "VALSARTAN TECNIMEDE - SOCIEDADE TECNICO-MEDICINAL"},
{label: "VALSARTAN TEVA"},
{label: "VALSARTAN VENIPHARM"},
{label: "VALSARTAN VIATRIS"},
{label: "VALSARTAN ZENTIVA"},
{label: "VALSARTAN ZYDUS"},
{label: "VALSARTAN ZYDUS FRANCE"},
{label: "VALSARTAN/HYDROCHLOROTHIAZIDE AHCL"},
{label: "VALSARTAN/HYDROCHLOROTHIAZIDE ARROW"},
{label: "VALSARTAN/HYDROCHLOROTHIAZIDE ARROW GENERIQUES"},
{label: "VALSARTAN/HYDROCHLOROTHIAZIDE ARROW LAB"},
{label: "VALSARTAN/HYDROCHLOROTHIAZIDE CRISTERS"},
{label: "VALSARTAN/HYDROCHLOROTHIAZIDE EG"},
{label: "VALSARTAN/HYDROCHLOROTHIAZIDE EVOLUGEN"},
{label: "VALSARTAN/HYDROCHLOROTHIAZIDE HELM"},
{label: "VALSARTAN/HYDROCHLOROTHIAZIDE KRKA"},
{label: "VALSARTAN/HYDROCHLOROTHIAZIDE MYLAN"},
{label: "VALSARTAN/HYDROCHLOROTHIAZIDE PHR LAB"},
{label: "VALSARTAN/HYDROCHLOROTHIAZIDE RANBAXY"},
{label: "VALSARTAN/HYDROCHLOROTHIAZIDE RATIOPHARM"},
{label: "VALSARTAN/HYDROCHLOROTHIAZIDE SANDOZ"},
{label: "VALSARTAN/HYDROCHLOROTHIAZIDE TEVA"},
{label: "VALSARTAN/HYDROCHLOROTHIAZIDE VIATRIS"},
{label: "VALSARTAN/HYDROCHLOROTHIAZIDE ZENTIVA"},
{label: "VALSARTAN/HYDROCHLOROTHIAZIDE ZYDUS"},
{label: "VALSARTAN/HYDROCHLOROTHIAZIDE ZYDUS FRANCE"},
{label: "VALSARTAN/HYDROCHLOROTIAZIDE TEVA"},
{label: "VALSIMIA"},
{label: "VAMADRID"},
{label: "VAMINE"},
{label: "VAMINE GLUCOSE"},
{label: "VAMINE N"},
{label: "VAMINOLACT"},
{label: "VAMINOVUM"},
{label: "VANADIUM METALLICUM BOIRON"},
{label: "VANCOCINE"},
{label: "VANCOMYCINE HIKMA"},
{label: "VANCOMYCINE HOSPIRA"},
{label: "VANCOMYCINE KABI"},
{label: "VANCOMYCINE LEO"},
{label: "VANCOMYCINE MEDIPHA SANTE"},
{label: "VANCOMYCINE MIP"},
{label: "VANCOMYCINE MYLAN PHARMA"},
{label: "VANCOMYCINE QUALIMED"},
{label: "VANCOMYCINE SANDOZ"},
{label: "VANCOMYCINE STRIDES"},
{label: "VANCOMYCINE TEVA"},
{label: "VANCOMYCINE VIATRIS"},
{label: "VANCOMYCINETEVA"},
{label: "VANILLA PLANIFOLIA BOIRON"},
{label: "VANILONE"},
{label: "VANILONE INFANTILE"},
{label: "VANIQA"},
{label: "VANISORBYL"},
{label: "VANSIL"},
{label: "VANTAS"},
{label: "VANTOBRA"},
{label: "VAPO-MYRTOL"},
{label: "VAQTA"},
{label: "VARDENAFIL ACCORD"},
{label: "VARDENAFIL BIOGARAN"},
{label: "VARDENAFIL GOIBELA"},
{label: "VARDENAFIL KRKA"},
{label: "VARDENAFIL PHARMAKI GENERICS"},
{label: "VARDENAFIL SANDOZ"},
{label: "VARDENAFIL TEVA SANTE"},
{label: "VARDENAFIL VIATRIS"},
{label: "VARESOL"},
{label: "VARGATEF"},
{label: "VARILRIX"},
{label: "VARIQUEL"},
{label: "VARIVAX"},
{label: "VARNOLINE"},
{label: "VARNOLINE CONTINU"},
{label: "VARUBY"},
{label: "VASCOCITROL"},
{label: "VASCULAT"},
{label: "VASCULOCIS"},
{label: "VASCULOGENE"},
{label: "VASCUNORMYL"},
{label: "VASELINE A L'OXYDE DE ZINC"},
{label: "VASELINE CAMPHREE MONOT"},
{label: "VASELINE GOMENOLEE"},
{label: "VASELINE MONOT"},
{label: "VASELINE OFFICINALE COOPER"},
{label: "VASELINE PURE CODEX"},
{label: "VASELINE STERILISEE COOPER"},
{label: "VASOBRAL"},
{label: "VASOVIST"},
{label: "VASTAREL"},
{label: "VASTEN"},
{label: "VASTIFLUV L.P."},
{label: "VATOUD"},
{label: "VAXCHORA"},
{label: "VAXELIS"},
{label: "VAXIGRIP"},
{label: "VAXIGRIP ENFANTS"},
{label: "VAXIGRIPTETRA"},
{label: "VAXNEUVANCE"},
{label: "VAXZEVRIA"},
{label: "VAZKEPA"},
{label: "VECALMYS"},
{label: "VECTARION"},
{label: "VECTARION INJECTABLE"},
{label: "VECTIBIX"},
{label: "VECTRINE"},
{label: "VEDFA"},
{label: "VEDROP"},
{label: "VEGADEINE ADULTES"},
{label: "VEGANINE"},
{label: "VEGEBOM"},
{label: "VEGEBOM DU DR MIOT"},
{label: "VEGELAX"},
{label: "VEGETAL RICHELET"},
{label: "VEGETOSERUM ADULTES"},
{label: "VEGETOSERUM ENFANTS"},
{label: "VEGZELMA"},
{label: "VEINAMITOL"},
{label: "VEINAMITOL HEMORROÏDES"},
{label: "VEINAMITOL JAMBES LOURDES"},
{label: "VEINARTAN"},
{label: "VEINATOP"},
{label: "VEINE BOIRON"},
{label: "VEINE WELEDA"},
{label: "VEINERGY"},
{label: "VEINEVA"},
{label: "VEINOBIASE"},
{label: "VEINOCURE"},
{label: "VEINOFIT"},
{label: "VEINOGENE"},
{label: "VEINOPHYTUM"},
{label: "VEINORHOIDE"},
{label: "VEINOSIUM"},
{label: "VEINOSTASE"},
{label: "VEINOTEX"},
{label: "VEINOTONYL"},
{label: "VEKLURY"},
{label: "VELAC"},
{label: "VELBE"},
{label: "VELBIENNE"},
{label: "VELCADE"},
{label: "VELETRI"},
{label: "VELITEN"},
{label: "VELMETIA"},
{label: "VELOSULIN"},
{label: "VELOSULINE"},
{label: "VELOSULINE HM"},
{label: "VELOXSOL"},
{label: "VELPHORO"},
{label: "VEMLIDY"},
{label: "VENACLAR"},
{label: "VENCLYXTO"},
{label: "VENERGOT"},
{label: "VENIRENE"},
{label: "VENLAFAXINE ACTAVIS"},
{label: "VENLAFAXINE ACTAVIS LP"},
{label: "VENLAFAXINE ALMUS"},
{label: "VENLAFAXINE ALMUS LP"},
{label: "VENLAFAXINE ALTER LP"},
{label: "VENLAFAXINE ARROW"},
{label: "VENLAFAXINE ARROW GENERIQUES LP"},
{label: "VENLAFAXINE ARROW LP"},
{label: "VENLAFAXINE BIOGALENIQUE"},
{label: "VENLAFAXINE BIOGARAN"},
{label: "VENLAFAXINE BIOGARAN LP"},
{label: "VENLAFAXINE BIPHAR"},
{label: "VENLAFAXINE BLUEFISH LP"},
{label: "VENLAFAXINE CRISTERS LP"},
{label: "VENLAFAXINE DAKOTA LP"},
{label: "VENLAFAXINE DOCPHARMA LP"},
{label: "VENLAFAXINE EG"},
{label: "VENLAFAXINE EG LABO LP"},
{label: "VENLAFAXINE EG LP"},
{label: "VENLAFAXINE EVOLUGEN LP"},
{label: "VENLAFAXINE EVOLUGEN PHARMA LP"},
{label: "VENLAFAXINE GNR LP"},
{label: "VENLAFAXINE HCS L.P."},
{label: "VENLAFAXINE IBD3"},
{label: "VENLAFAXINE IBD3 PHARMA CONSULTING LP"},
{label: "VENLAFAXINE IBD3 SET"},
{label: "VENLAFAXINE IBD3 SET LP"},
{label: "VENLAFAXINE ISOMED LP"},
{label: "VENLAFAXINE JJ AMMANN LP"},
{label: "VENLAFAXINE KRKA L.P."},
{label: "VENLAFAXINE LICONSA LP"},
{label: "VENLAFAXINE MEDIPHA SANTE LP"},
{label: "VENLAFAXINE MYLAN"},
{label: "VENLAFAXINE MYLAN LP"},
{label: "VENLAFAXINE MYLAN PHARMA LP"},
{label: "VENLAFAXINE NIALEX LP"},
{label: "VENLAFAXINE OPENING PHARMA"},
{label: "VENLAFAXINE PFIZER L.P."},
{label: "VENLAFAXINE PHARMA-LICENCE LP"},
{label: "VENLAFAXINE QUALIMED"},
{label: "VENLAFAXINE QUALIMED LP"},
{label: "VENLAFAXINE RANBAXY"},
{label: "VENLAFAXINE RANBAXY LP"},
{label: "VENLAFAXINE RATIO LP"},
{label: "VENLAFAXINE RATIOPHARM"},
{label: "VENLAFAXINE RATIOPHARM LP"},
{label: "VENLAFAXINE RPG"},
{label: "VENLAFAXINE SANDOZ"},
{label: "VENLAFAXINE SANDOZ LP"},
{label: "VENLAFAXINE SUN LP"},
{label: "VENLAFAXINE TEVA"},
{label: "VENLAFAXINE TEVA L.P."},
{label: "VENLAFAXINE TEVA LP"},
{label: "VENLAFAXINE TEVA SANTE L.P."},
{label: "VENLAFAXINE VIATRIS"},
{label: "VENLAFAXINE VIATRIS LP"},
{label: "VENLAFAXINE WINTHROP"},
{label: "VENLAFAXINE ZENTIVA LP"},
{label: "VENLAFAXINE ZYDUS"},
{label: "VENLAFAXINE ZYDUS FRANCE LP"},
{label: "VENLAFAXINE ZYDUS LP"},
{label: "VENOFER"},
{label: "VENORUTON"},
{label: "VENTADUR L P"},
{label: "VENTAVIS"},
{label: "VENTICOLL"},
{label: "VENTILASTIN NOVOLIZER"},
{label: "VENTIZOLVE"},
{label: "VENTODISKS"},
{label: "VENTOLINE"},
{label: "VENTOLINE ROTACAPS"},
{label: "VENYL"},
{label: "VEPESIDE"},
{label: "VERADAY"},
{label: "VERALYDON"},
{label: "VERAMOX"},
{label: "VERAPAMIL BIOGARAN L.P."},
{label: "VERAPAMIL EG L.P."},
{label: "VERAPAMIL G GAM"},
{label: "VERAPAMIL IVAX L.P."},
{label: "VERAPAMIL MYLAN L.P."},
{label: "VERAPAMIL PHAROS LP"},
{label: "VERAPAMIL RATIOPHARM"},
{label: "VERAPAMIL RATIOPHARM LP"},
{label: "VERAPAMIL SANDOZ"},
{label: "VERAPAMIL SANDOZ L.P."},
{label: "VERAPAMIL TEVA"},
{label: "VERAPAMIL TEVA L. P."},
{label: "VERAPAMIL TEVA L.P."},
{label: "VERAPAMIL TEVA SANTE L.P."},
{label: "VERAPAMIL VIATRIS"},
{label: "VERAPAMIL VIATRIS LP"},
{label: "VERAPAMIL WINTHROP L.P."},
{label: "VERAPAMIL/TRANDOLAPRIL BIPHAR LP"},
{label: "VERAPAMIL/TRANDOLAPRIL MYLAN LP"},
{label: "VERASEAL"},
{label: "VERASKIN"},
{label: "VERATRAN"},
{label: "VERATRUM ALBUM"},
{label: "VERATRUM ALBUM BOIRON"},
{label: "VERATRUM VIRIDE BOIRON"},
{label: "VERATRUM VIRIDE LEHNING"},
{label: "VERBASCUM THAPSUS BOIRON"},
{label: "VERBASCUM THAPSUS LEHNING"},
{label: "VERCOLVERTINE"},
{label: "VERCYTE"},
{label: "VEREGEN"},
{label: "VERICARDINE"},
{label: "VERKAZIA"},
{label: "VERMIFUGE CHIARINI"},
{label: "VERMIFUGE SORIN"},
{label: "VERMINTEL"},
{label: "VERMOX"},
{label: "VERONICA OFFICINALIS"},
{label: "VERONICA OFFICINALIS BOIRON"},
{label: "VERORAB"},
{label: "VERORABVAX"},
{label: "VERQUVO"},
{label: "VERRUCOSAL"},
{label: "VERRUFILM"},
{label: "VERRULIA"},
{label: "VERRULYSE-METHIONINE"},
{label: "VERRUPAN"},
{label: "VERSATIS"},
{label: "VERSED"},
{label: "VERTEBRA BOIRON"},
{label: "VERTEBRES CERVICALES BOIRON"},
{label: "VERTEBRES DORSALES BOIRON"},
{label: "VERTEBRES LOMBAIRES BOIRON"},
{label: "VERTEBRES SACREES BOIRON"},
{label: "VERUTAL"},
{label: "VERZENIOS"},
{label: "VESADOL"},
{label: "VESANOID"},
{label: "VESICARE"},
{label: "VESICULE BILIAIRE BOIRON"},
{label: "VESIPATEX"},
{label: "VESPA CRABRO WELEDA"},
{label: "VESSIE BOIRON"},
{label: "VEXOL"},
{label: "VEYBIROL TYROTHRICINE"},
{label: "VEYVONDI"},
{label: "VFEND"},
{label: "VIABORPAX"},
{label: "VIAGRA"},
{label: "VIALEBEX"},
{label: "VIANI"},
{label: "VIANI DISKUS"},
{label: "VIANT"},
{label: "VIBALGAN"},
{label: "VIBATIV"},
{label: "VIBERVALDEL"},
{label: "VIBRAMYCINE"},
{label: "VIBRAMYCINE N"},
{label: "VIBRAVEINEUSE"},
{label: "VIBTIL"},
{label: "VIBURNUM OPULUS BOIRON"},
{label: "VIBURNUM PRUNIFOLIUM BOIRON"},
{label: "VICKS"},
{label: "VICKS EXPECTORANT AMBROXOL"},
{label: "VICKS EXPECTORANT GUAIFENESINE"},
{label: "VICKS INHALER"},
{label: "VICKS RHUME"},
{label: "VICKS SIROP PECTORAL"},
{label: "VICKS TOUX SECHE"},
{label: "VICKS TOUX SECHE DEXTROMETHORPHANE"},
{label: "VICKS VAPORUB"},
{label: "VICKS VAPOSYRUP EXPECTORANT"},
{label: "VICKS VAPOSYRUP TOUX SECHE"},
{label: "VICKS VAPOSYRUP TOUX SECHE ENFANT"},
{label: "VICTAN"},
{label: "VICTOZA"},
{label: "VICTRELIS"},
{label: "VIDAZA"},
{label: "VIDEO-COLLYRE"},
{label: "VIDEX"},
{label: "VIDORA"},
{label: "VIDPREVTYN BETA"},
{label: "VIEKIRAX"},
{label: "VIGNE ROUGE BOIRON"},
{label: "VIGNE ROUGE ETHYPHARM"},
{label: "VIGNE ROUGE FEUILLES VITAFLOR"},
{label: "VIGNE ROUGE INFUSETTE LES BONS PRODUCTEURS"},
{label: "VIGNE ROUGE NATURA MEDICA"},
{label: "VIGNE ROUGE YES PHARMA"},
{label: "VIGNOREL"},
{label: "VIKELA"},
{label: "VILDAGLIPTINE ACCORD"},
{label: "VILDAGLIPTINE ARROW"},
{label: "VILDAGLIPTINE BIOGARAN"},
{label: "VILDAGLIPTINE EG"},
{label: "VILDAGLIPTINE INTAS"},
{label: "VILDAGLIPTINE KRKA"},
{label: "VILDAGLIPTINE PHARMAKI GENERICS"},
{label: "VILDAGLIPTINE SANDOZ"},
{label: "VILDAGLIPTINE TEVA"},
{label: "VILDAGLIPTINE VIATRIS"},
{label: "VILDAGLIPTINE ZENTIVA"},
{label: "VILDAGLIPTINE ZYDUS"},
{label: "VILDAGLIPTINE/METFORMINE ALTER"},
{label: "VILDAGLIPTINE/METFORMINE ARROW"},
{label: "VILDAGLIPTINE/METFORMINE BIOGARAN"},
{label: "VILDAGLIPTINE/METFORMINE CHLORHYDRATE ACCORD"},
{label: "VILDAGLIPTINE/METFORMINE EG"},
{label: "VILDAGLIPTINE/METFORMINE KRKA"},
{label: "VILDAGLIPTINE/METFORMINE PHARMAKI GENERICS"},
{label: "VILDAGLIPTINE/METFORMINE SANDOZ"},
{label: "VILDAGLIPTINE/METFORMINE TEVA"},
{label: "VILDAGLIPTINE/METFORMINE VIATRIS"},
{label: "VILDAGLIPTINE/METFORMINE ZENTIVA"},
{label: "VILPROST"},
{label: "VIMIZIM"},
{label: "VIMPAT"},
{label: "VIMPAT 50/100/150/200"},
{label: "VINBLASTINE TEVA"},
{label: "VINCA"},
{label: "VINCA MINOR BOIRON"},
{label: "VINCA MINOR LEHNING"},
{label: "VINCA MINOR WELEDA"},
{label: "VINCABRAIN"},
{label: "VINCAFOR"},
{label: "VINCARUTINE"},
{label: "VINCIMAX L P"},
{label: "VINCRISTINE FAULDING"},
{label: "VINCRISTINE HOSPIRA"},
{label: "VINCRISTINE INTAS"},
{label: "VINCRISTINE PIERRE FABRE"},
{label: "VINCRISTINE PIERRE FABRE MEDICAMENT"},
{label: "VINCRISTINE SANDOZ"},
{label: "VINCRISTINE TEVA"},
{label: "VINCRISTINE TEVA CLASSICS"},
{label: "VINODRAN"},
{label: "VINORELBINE ACCORD"},
{label: "VINORELBINE ALTISO"},
{label: "VINORELBINE ARROW"},
{label: "VINORELBINE AUTHOU"},
{label: "VINORELBINE CLEXNI"},
{label: "VINORELBINE EBEWE"},
{label: "VINORELBINE FAULDING"},
{label: "VINORELBINE HOSPIRA"},
{label: "VINORELBINE MARTINDALE PHARMA"},
{label: "VINORELBINE MEDAC"},
{label: "VINORELBINE MYLAN"},
{label: "VINORELBINE NORDIC PHARMA"},
{label: "VINORELBINE PIERRE FABRE"},
{label: "VINORELBINE SANDOZ"},
{label: "VINORELBINE TEVA"},
{label: "VINORELBINE WINTHROP"},
{label: "VINTENE"},
{label: "VIOLA ODORATA BOIRON"},
{label: "VIOLA TRICOLOR BOIRON"},
{label: "VIOLA TRICOLOR LEHNING"},
{label: "VIOXX"},
{label: "VIPDOMET"},
{label: "VIPERA REDI BOIRON"},
{label: "VIPERA REDI LEHNING"},
{label: "VIPERA TORVA BOIRON"},
{label: "VIPERFAV"},
{label: "VIPIDIA"},
{label: "VIRA A"},
{label: "VIRA M.P."},
{label: "VIRACEPT"},
{label: "VIRAFERON"},
{label: "VIRAFERONPEG"},
{label: "VIRAMUNE"},
{label: "VIREAD"},
{label: "VIRGAN"},
{label: "VIRLIX"},
{label: "VIROPHTA"},
{label: "VISANNE"},
{label: "VISCERALGINE"},
{label: "VISCERALGINE FORTE A LA NORAMIDOPYRINE"},
{label: "VISCOTIOL"},
{label: "VISCUM ALBUM BOIRON"},
{label: "VISCUM ALBUM LEHNING"},
{label: "VISCUM ALBUM WELEDA"},
{label: "VISINE"},
{label: "VISIODOSE"},
{label: "VISIOLYRE"},
{label: "VISIPAQUE"},
{label: "VISKALDIX"},
{label: "VISKEN"},
{label: "VISKEN QUINZE"},
{label: "VISTABEL"},
{label: "VISTIDE"},
{label: "VISUDYNE"},
{label: "VITA"},
{label: "VITA-DERMACIDE"},
{label: "VITABACT"},
{label: "VITABLEU"},
{label: "VITACEMIL"},
{label: "VITACIC"},
{label: "VITAGRIPPE"},
{label: "VITALENS"},
{label: "VITALGINE"},
{label: "VITALIPIDE ADULTES"},
{label: "VITALIPIDE ENFANTS"},
{label: "VITALOGINK"},
{label: "VITALYOC ADULTES"},
{label: "VITALYOC ENFANTS"},
{label: "VITAMINE A DULCIS"},
{label: "VITAMINE A FAURE"},
{label: "VITAMINE A PROVEPHARM"},
{label: "VITAMINE B"},
{label: "VITAMINE B1 AGUETTANT"},
{label: "VITAMINE B1 ARROW CONSEIL"},
{label: "VITAMINE B1 B6 BAYER"},
{label: "VITAMINE B12 AGUETTANT"},
{label: "VITAMINE B12 BAYER"},
{label: "VITAMINE B12 CHAUVIN"},
{label: "VITAMINE B12 DELAGRANGE"},
{label: "VITAMINE B12 EUROPHTA"},
{label: "VITAMINE B12 GERDA"},
{label: "VITAMINE B12 HORUS"},
{label: "VITAMINE B12 HORUS PHARMA"},
{label: "VITAMINE B12 LAVOISIER"},
{label: "VITAMINE B12 THEA"},
{label: "VITAMINE B6 AGUETTANT"},
{label: "VITAMINE B6 ARROW"},
{label: "VITAMINE C"},
{label: "VITAMINE C ARROW"},
{label: "VITAMINE C BAYER"},
{label: "VITAMINE C BOEHRINGER INGELHEIM"},
{label: "VITAMINE C CUBE BIOGALENIQUE"},
{label: "VITAMINE C FAURE"},
{label: "VITAMINE C LABORATOIRES NUTRISANTÉ"},
{label: "VITAMINE C MYLAN"},
{label: "VITAMINE C OBERLIN"},
{label: "VITAMINE C PHARMAKI GENERICS"},
{label: "VITAMINE C PRODILAB"},
{label: "VITAMINE C SALVER"},
{label: "VITAMINE C SAUTER ENFANTS"},
{label: "VITAMINE C SAUTER SANS SUCRE"},
{label: "VITAMINE C UPSA"},
{label: "VITAMINE C UPSA EFFERVESCENTE"},
{label: "VITAMINE D3 BON"},
{label: "VITAMINE E BIORGA"},
{label: "VITAMINE E MIDY"},
{label: "VITAMINE E MYLAN"},
{label: "VITAMINE E PROVEPHARM"},
{label: "VITAMINE E SANDOZ"},
{label: "VITAMINE E SUBSTIPHARM"},
{label: "VITAMINE E ZYDUS"},
{label: "VITAMINE K1"},
{label: "VITAMINE K1 CHEPLAPHARM"},
{label: "VITAMINE K1 DELAGRANGE"},
{label: "VITAMINE K1 ROCHE"},
{label: "VITAMINE PP AGUETTANT"},
{label: "VITAMINES C"},
{label: "VITANGO"},
{label: "VITAPHAKOL"},
{label: "VITAROL"},
{label: "VITAROS"},
{label: "VITARUTINE"},
{label: "VITASCORBOL"},
{label: "VITASCORBOL SANS SUCRE ENFANTS"},
{label: "VITASCORBOL SANS SUCRE TAMPONNE"},
{label: "VITASCORBOL SOLUBLE"},
{label: "VITASCORBOL TAMPONNE"},
{label: "VITASEDINE"},
{label: "VITASEPTINE"},
{label: "VITASEPTOL"},
{label: "VITATHION"},
{label: "VITAZINC"},
{label: "VITEKTA"},
{label: "VITELLINATE D'ARGENT"},
{label: "VITHEPA"},
{label: "VITIS VINIFERA"},
{label: "VITIS VINIFERA BOIRON"},
{label: "VITRAKVI"},
{label: "VITRAVENE"},
{label: "VIVACIDOL"},
{label: "VIVAGLOBIN"},
{label: "VIVALAN"},
{label: "VIVAMAG"},
{label: "VIVAMYNE ADULTES"},
{label: "VIVAMYNE ENFANTS"},
{label: "VIVAMYNE MULTI"},
{label: "VIVAMYNE TONIQUE"},
{label: "VIVANZA"},
{label: "VIVAXIM"},
{label: "VIVELLEDOT"},
{label: "VIVENE"},
{label: "VIVIANITE WELEDA"},
{label: "VIVOTIF"},
{label: "VIZAMYL"},
{label: "VIZARSIN"},
{label: "VIZILATAN"},
{label: "VIZIMACO"},
{label: "VIZIMPRO"},
{label: "VIZITRAV"},
{label: "VOCABRIA"},
{label: "VOCADYS"},
{label: "VOGALENE"},
{label: "VOGALENE LYOC"},
{label: "VOGALIB"},
{label: "VOKANAMET"},
{label: "VOLDAL"},
{label: "VOLDAL ENFANTS"},
{label: "VOLDAL LP"},
{label: "VOLIBRIS"},
{label: "VOLMA"},
{label: "VOLTAFLEX"},
{label: "VOLTAMICINE"},
{label: "VOLTARENACTIGO"},
{label: "VOLTARENDOLO"},
{label: "VOLTARENE"},
{label: "VOLTARENE EMULGEL"},
{label: "VOLTARENE LP"},
{label: "VOLTARENOPHTA"},
{label: "VOLTARENOPHTABAK"},
{label: "VOLTARENPLAST"},
{label: "VOLTARENSPE 1%"},
{label: "VOLULYTE"},
{label: "VOLUVEN"},
{label: "VONA"},
{label: "VONCENTO"},
{label: "VOQUILY"},
{label: "VORAXAZE"},
{label: "VORICONAZOLE ACCORD"},
{label: "VORICONAZOLE APOTEX"},
{label: "VORICONAZOLE ARROW"},
{label: "VORICONAZOLE BIOGARAN"},
{label: "VORICONAZOLE EG"},
{label: "VORICONAZOLE FOSUN PHARMA"},
{label: "VORICONAZOLE FRESENIUS KABI"},
{label: "VORICONAZOLE GLENMARK"},
{label: "VORICONAZOLE HIKMA"},
{label: "VORICONAZOLE MYLAN"},
{label: "VORICONAZOLE PANPHARMA"},
{label: "VORICONAZOLE PFIZER"},
{label: "VORICONAZOLE PHARMAKI GENERICS"},
{label: "VORICONAZOLE REDDY PHARMA"},
{label: "VORICONAZOLE SANDOZ"},
{label: "VORICONAZOLE STRAGEN"},
{label: "VORICONAZOLE TEVA"},
{label: "VORICONAZOLE TORRENT"},
{label: "VORICONAZOLE VIATRIS"},
{label: "VORICONAZOLE ZENTIVA"},
{label: "VOSEVI"},
{label: "VOTRIENT"},
{label: "VOTUBIA"},
{label: "VOXPAX"},
{label: "VOXZOGO"},
{label: "VPRIV"},
{label: "VULCASE"},
{label: "VUMERITY"},
{label: "VUNEXIN"},
{label: "VYDURA"},
{label: "VYEPTI"},
{label: "VYNDAQEL"},
{label: "VYTORIN"},
{label: "VYVGART"},
{label: "VYXEOS LIPOSOMAL"},
{label: "W"},
{label: "W146"},
{label: "W149"},
{label: "W164"},
{label: "W169"},
{label: "W171"},
{label: "W221"},
{label: "W244"},
{label: "W253"},
{label: "W270"},
{label: "W278"},
{label: "W282"},
{label: "W301"},
{label: "W306"},
{label: "W313"},
{label: "W314"},
{label: "W329"},
{label: "W344"},
{label: "W350"},
{label: "W358"},
{label: "W366"},
{label: "W374"},
{label: "W390"},
{label: "W410"},
{label: "W418"},
{label: "W434"},
{label: "W436"},
{label: "W462"},
{label: "W464"},
{label: "W472"},
{label: "W518"},
{label: "W524"},
{label: "W538"},
{label: "W580"},
{label: "W582"},
{label: "W584"},
{label: "W586"},
{label: "W616"},
{label: "W642"},
{label: "W721"},
{label: "W724"},
{label: "W746"},
{label: "W770"},
{label: "W771"},
{label: "W776"},
{label: "W788"},
{label: "W796"},
{label: "W858"},
{label: "W876"},
{label: "W882"},
{label: "W904"},
{label: "W926"},
{label: "W932"},
{label: "W962"},
{label: "W964"},
{label: "WAKIX"},
{label: "WARTEC"},
{label: "WAYLIVRA"},
{label: "WEGOVY"},
{label: "WELLCOPRIM"},
{label: "WELLVONE"},
{label: "WILFACTIN"},
{label: "WILSTART"},
{label: "WILZIN"},
{label: "WINDEZE"},
{label: "WYNZORA"},
{label: "WYSTAMM"},
{label: "WYTENS"},
{label: "X PREP"},
{label: "XADAGO"},
{label: "XAGRID"},
{label: "XAIRA"},
{label: "XALACOM"},
{label: "XALATAN"},
{label: "XALKORI"},
{label: "XALUPRINE"},
{label: "XAMIOL"},
{label: "XANAX"},
{label: "XANTALFENE"},
{label: "XANTHIUM L P"},
{label: "XANTHIUM L.P."},
{label: "XARELTO"},
{label: "XATALIN"},
{label: "XATRAL"},
{label: "XATRAL LP"},
{label: "XECAINE"},
{label: "XEFO"},
{label: "XEFOACU"},
{label: "XELCIP"},
{label: "XELEVIA"},
{label: "XELJANZ"},
{label: "XELODA"},
{label: "XEMBIFY"},
{label: "XENAZINE"},
{label: "XENETIX"},
{label: "XENICAL"},
{label: "XENID"},
{label: "XENID ENFANTS"},
{label: "XENID L P"},
{label: "XENON [133"},
{label: "XENPOZYME"},
{label: "XEOMIN"},
{label: "XEPLION"},
{label: "XERCLEAR"},
{label: "XERMELO"},
{label: "XEROQUEL LP"},
{label: "XEVUDY"},
{label: "XGEVA"},
{label: "XIAPEX"},
{label: "XIGAMMA"},
{label: "XIGDUO"},
{label: "XIGRIS"},
{label: "XILERMIS"},
{label: "XILIARX"},
{label: "XIMEPEG"},
{label: "XIMLUCI"},
{label: "XIMOS"},
{label: "XIOP"},
{label: "XIPABETA"},
{label: "XIPADIU"},
{label: "XIPAGAMMA"},
{label: "XIPALPHA"},
{label: "XIPAMIDE WÖRWAG"},
{label: "XIPARETIC"},
{label: "XIPAWAG"},
{label: "XOFIGO"},
{label: "XOFLUZA"},
{label: "XOGEL ADULTE"},
{label: "XOGEL ENFANT"},
{label: "XOLAAM"},
{label: "XOLAIR"},
{label: "XOMOLIX"},
{label: "XONVEA"},
{label: "XOSPATA"},
{label: "XROMI"},
{label: "XTANDI"},
{label: "XULTOPHY"},
{label: "XYBILUN"},
{label: "XYDALBA"},
{label: "XYLANEST"},
{label: "XYLLOMAC"},
{label: "XYLOCAINE"},
{label: "XYLOCAINE VISQUEUSE"},
{label: "XYLOCARD"},
{label: "XYLOCONTACT"},
{label: "XYLOMÉTAZOLINE/IPRATROPIUM ACINO"},
{label: "XYLONOR"},
{label: "XYLOROLLAND AVEC ADRENALINE"},
{label: "XYLOROLLAND AVEC NORADRENALINE"},
{label: "XYLOROLLAND SANS VASOCONSTRICTEUR"},
{label: "XYMAC"},
{label: "XYNERTEC"},
{label: "XYREM"},
{label: "XYZALL"},
{label: "YALFORMET LP"},
{label: "YANIMO RESPIMAT"},
{label: "YANTIL"},
{label: "YANTIL LP"},
{label: "YARGESA"},
{label: "YAZ"},
{label: "YDRALBUM"},
{label: "YELLOX"},
{label: "YELNAC"},
{label: "YENTREVE"},
{label: "YERBALAXA"},
{label: "YERBATONA"},
{label: "YERVOY"},
{label: "YESCARTA"},
{label: "YOCOBIS"},
{label: "YOCORAL"},
{label: "YOHIMBINE HOUDE"},
{label: "YONDELIS"},
{label: "YOREXELLA"},
{label: "YRANICID ARSENICAL"},
{label: "YRANICID SANS ARSENIC"},
{label: "YRANOL EUGENOLE"},
{label: "YSE"},
{label: "YSE GLUTAMIQUE"},
{label: "YSELTY"},
{label: "YSOMEGA"},
{label: "YTRACIS"},
{label: "YTTRIGA"},
{label: "YUCCA COMPLEXE N°110"},
{label: "YUCCA FILAMENTOSA BOIRON"},
{label: "YUCCA FILAMENTOSA LEHNING"},
{label: "YUFLYMA"},
{label: "ZABDENO"},
{label: "ZACNAN"},
{label: "ZACOPERC"},
{label: "ZADITEN"},
{label: "ZADITEN L.P."},
{label: "ZADITEN SANS SUCRE"},
{label: "ZADYL"},
{label: "ZAGRAPA"},
{label: "ZALASTA"},
{label: "ZALDIAR"},
{label: "ZALERG"},
{label: "ZALERGONIUM"},
{label: "ZALLYRE"},
{label: "ZALMOXIS 5-20"},
{label: "ZALTRAP"},
{label: "ZALVISO"},
{label: "ZAMANOL"},
{label: "ZAMELINE"},
{label: "ZAMOCILLINE"},
{label: "ZAMUDOL"},
{label: "ZAMUDOL LP"},
{label: "ZANACODAR"},
{label: "ZANEA"},
{label: "ZANEXTRA"},
{label: "ZANIDIP"},
{label: "ZANOSAR"},
{label: "ZANTARAC"},
{label: "ZAPINOL"},
{label: "ZAPRILIS"},
{label: "ZAREDROP"},
{label: "ZARONTIN"},
{label: "ZARZIO"},
{label: "ZATUR"},
{label: "ZAVEDOS"},
{label: "ZAVESCA"},
{label: "ZAVICEFTA"},
{label: "ZAWEK"},
{label: "ZAXEF"},
{label: "ZAYITE"},
{label: "ZEA MAIS BOIRON"},
{label: "ZEBINIX"},
{label: "ZECLAR"},
{label: "ZEFFIX"},
{label: "ZEJULA"},
{label: "ZELBORAF"},
{label: "ZELFUSOR"},
{label: "ZELITREX"},
{label: "ZELMIRB"},
{label: "ZENALIA"},
{label: "ZENAPAX"},
{label: "ZENEFAX"},
{label: "ZENIUM"},
{label: "ZENTEL"},
{label: "ZEPATIER"},
{label: "ZEPOSIA"},
{label: "ZERBAXA"},
{label: "ZERCEPAC"},
{label: "ZERELLA"},
{label: "ZERENE"},
{label: "ZERIT"},
{label: "ZESSLY"},
{label: "ZESTORETIC"},
{label: "ZESTRIL"},
{label: "ZEULIDE"},
{label: "ZEVALIN"},
{label: "ZIACAINE"},
{label: "ZIAGEL ADULTE"},
{label: "ZIAGEL ENFANT"},
{label: "ZIAGEN"},
{label: "ZIDINOX"},
{label: "ZIDOVAL"},
{label: "ZIDOVUDINE ARROW"},
{label: "ZIEXTENZO"},
{label: "ZILFENE"},
{label: "ZILFENE NEOMYCINE"},
{label: "ZIMINO"},
{label: "ZINBRYTA"},
{label: "ZINC INJECTABLE"},
{label: "ZINC OLIGOSOL"},
{label: "ZINC-CUIVRE OLIGOSOL"},
{label: "ZINC-NICKEL-COBALT MICROSOL"},
{label: "ZINC-NICKEL-COBALT OLIGOSOL"},
{label: "ZINCUM METALLICUM BOIRON"},
{label: "ZINCUM METALLICUM LEHNING"},
{label: "ZINCUM METALLICUM WELEDA"},
{label: "ZINCUM PHOSPHORICUM BOIRON"},
{label: "ZINCUM VALERIANICUM BOIRON"},
{label: "ZINCUM VALERIANICUM WELEDA"},
{label: "ZINDACLINE"},
{label: "ZINFORO"},
{label: "ZINGIBER OFFICINALE BOIRON"},
{label: "ZINNAT"},
{label: "ZINPLAVA"},
{label: "ZIPEREVE"},
{label: "ZIPZOC"},
{label: "ZIRABEV"},
{label: "ZITHROMAX"},
{label: "ZITHROMAX MONODOSE"},
{label: "ZIZIA AUREA BOIRON"},
{label: "ZOCOR"},
{label: "ZODIN"},
{label: "ZOELY"},
{label: "ZOFENIL"},
{label: "ZOFENILDUO"},
{label: "ZOFENOPRIL VIATRIS"},
{label: "ZOFORA"},
{label: "ZOGILAC"},
{label: "ZOKINVY"},
{label: "ZOLACTA"},
{label: "ZOLADEX"},
{label: "ZOLAMIP"},
{label: "ZOLASOPT"},
{label: "ZOLGENSMA"},
{label: "ZOLITRAT"},
{label: "ZOLMITRIPTAN ACTAVIS"},
{label: "ZOLMITRIPTAN ARCHIE SAMUEL"},
{label: "ZOLMITRIPTAN ARROW GENERIQUES"},
{label: "ZOLMITRIPTAN BIOGARAN"},
{label: "ZOLMITRIPTAN CRISTERS"},
{label: "ZOLMITRIPTAN EG"},
{label: "ZOLMITRIPTAN EVOLUGEN"},
{label: "ZOLMITRIPTAN GLENMARK GENERICS"},
{label: "ZOLMITRIPTAN JUBILANT"},
{label: "ZOLMITRIPTAN LA COLINA"},
{label: "ZOLMITRIPTAN OPENING PHARMA"},
{label: "ZOLMITRIPTAN PHR"},
{label: "ZOLMITRIPTAN PHR LAB"},
{label: "ZOLMITRIPTAN QUALIFARMA"},
{label: "ZOLMITRIPTAN RATIO"},
{label: "ZOLMITRIPTAN RENANTOS"},
{label: "ZOLMITRIPTAN SANDOZ"},
{label: "ZOLMITRIPTAN SANOFI"},
{label: "ZOLMITRIPTAN SUBSTIPHARM"},
{label: "ZOLMITRIPTAN SUN"},
{label: "ZOLMITRIPTAN TEVA"},
{label: "ZOLMITRIPTAN TEVA CLASSICS"},
{label: "ZOLMITRIPTAN TEVA SANTE"},
{label: "ZOLMITRIPTAN VIATRIS"},
{label: "ZOLMITRIPTAN WELDING"},
{label: "ZOLMITRIPTAN ZENTIVA"},
{label: "ZOLMITRIPTAN ZYDUS"},
{label: "ZOLMITRIPTAN ZYDUS FRANCE"},
{label: "ZOLOFT"},
{label: "ZOLPIDEM ACTAVIS"},
{label: "ZOLPIDEM ALMUS"},
{label: "ZOLPIDEM ALTER"},
{label: "ZOLPIDEM ARROW"},
{label: "ZOLPIDEM BIOGARAN"},
{label: "ZOLPIDEM CRISTERS"},
{label: "ZOLPIDEM DAKOTA PHARM"},
{label: "ZOLPIDEM EG"},
{label: "ZOLPIDEM GNR"},
{label: "ZOLPIDEM QUALIMED"},
{label: "ZOLPIDEM SANDOZ"},
{label: "ZOLPIDEM SANOFI-SYNTHELABO FRANCE"},
{label: "ZOLPIDEM SUN"},
{label: "ZOLPIDEM TEVA"},
{label: "ZOLPIDEM TEVA SANTE"},
{label: "ZOLPIDEM VIATRIS"},
{label: "ZOLPIDEM ZENTIVA"},
{label: "ZOLPIDEM ZYDUS"},
{label: "ZOLPRAZE"},
{label: "ZOLSKETIL PEGYLATED LIPOSOMAL"},
{label: "ZOLTUM"},
{label: "ZOLTZICS"},
{label: "ZOMACTON"},
{label: "ZOMARIST"},
{label: "ZOMETA"},
{label: "ZOMIG"},
{label: "ZOMIGORO"},
{label: "ZOMIGSPRAY"},
{label: "ZOMORPH L.P."},
{label: "ZONDAR"},
{label: "ZONEGRAN"},
{label: "ZONISAMIDE ARROW"},
{label: "ZONISAMIDE CHANELLE"},
{label: "ZONISAMIDE MYLAN"},
{label: "ZONISAMIDE NEURAXPHARM"},
{label: "ZONISAMIDE PHARMAKI GENERICS"},
{label: "ZONISAMIDE SANDOZ"},
{label: "ZONISAMIDE TEVA"},
{label: "ZONTIVITY"},
{label: "ZOPHREN"},
{label: "ZOPICLONE ALTER"},
{label: "ZOPICLONE APOTEX"},
{label: "ZOPICLONE APOTEX FRANCE"},
{label: "ZOPICLONE ARROW"},
{label: "ZOPICLONE ARROW LAB"},
{label: "ZOPICLONE AVENTIS"},
{label: "ZOPICLONE BIOGARAN"},
{label: "ZOPICLONE CRISTERS"},
{label: "ZOPICLONE CRISTERS LAB"},
{label: "ZOPICLONE CRISTERS PHARMA"},
{label: "ZOPICLONE EG"},
{label: "ZOPICLONE ENIREX"},
{label: "ZOPICLONE QUALIMED"},
{label: "ZOPICLONE SANDOZ"},
{label: "ZOPICLONE SPECIA"},
{label: "ZOPICLONE TEVA"},
{label: "ZOPICLONE TEVA SANTE"},
{label: "ZOPICLONE VIATRIS"},
{label: "ZOPICLONE ZENTIVA"},
{label: "ZOPICLONE ZYDUS"},
{label: "ZORAC"},
{label: "ZORYON"},
{label: "ZOSTAVAX"},
{label: "ZOVIRAX"},
{label: "ZOXAN LP"},
{label: "ZUBSOLV"},
{label: "ZUMALGIC"},
{label: "ZUMENON"},
{label: "ZURAMPIC"},
{label: "ZUTECTRA"},
{label: "ZYBAN L.P."},
{label: "ZYCLARA"},
{label: "ZYDELIG"},
{label: "ZYKADIA"},
{label: "ZYLLT"},
{label: "ZYLORIC"},
{label: "ZYMA D2"},
{label: "ZYMAD"},
{label: "ZYMADUO"},
{label: "ZYMAFLUOR"},
{label: "ZYMIZINC"},
{label: "ZYMOPLEX"},
{label: "ZYNTEGLO"},
{label: "ZYPADHERA"},
{label: "ZYPREXA"},
{label: "ZYPREXA VELOTAB"},
{label: "ZYRTEC"},
{label: "ZYRTECSET"},
{label: "ZYTIGA"},
{label: "ZYVOXID"},
]