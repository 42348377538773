import React from "react";

const DrugsReport = ({drugs}) => {
    if(drugs === null || drugs.length === 0) return null
    return (
        <p>
            <span style={{fontWeight: "bold"}}>Médicaments : </span>
            {drugs.map((drug, index) => (
                <span className="chip" key={index}>{drug.label}</span>
            ))}
        </p>
    )
}
export default DrugsReport;