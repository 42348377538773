import Parser from "./Parser"
export default class Nidek extends Parser{

  parse(){
    let data = this.data
    let blocks = data.split("@")
    let raw_data = {}
    for(let i=1;i < blocks.length;i++){
      let title = blocks[i].split("\n")[0].trim()
      switch(title){
        case "RM":
          raw_data["AR"] = this.parseData(blocks[i])
          break;
        case "LM":
          raw_data["LM"] = this.parseData(blocks[i])
          break;
        case "RT":
          raw_data["RT"] = this.parseData(blocks[i])
          break;
        case "NT":
          raw_data["NT"] = this.parseData(blocks[i])
          break;
      }
    }
    return(this.process_raw(raw_data))

  }
  parseData(block){
    let sphere_cyl_axe_r = /^[fFO]R([0-9\.\-\+\s]{6})([0-9\.\-\+\s]{6})([0-9\.\-\+\s]{3})/gm
    let sphere_cyl_axe_l = /^[fFlO]L([0-9\.\-\+\s]{6})([0-9\.\-\+\s]{6})([0-9\.\-\+\s]{3})/gm
    let [od_sphere,od_cylindre,od_axe] = this.regex(block,sphere_cyl_axe_r)
    let [og_sphere,og_cylindre,og_axe] = this.regex(block,sphere_cyl_axe_l)
    let od_addition_regex = /^[Aa]R([0-9\.\-\+\s]{6})/gm
    let og_addition_regex = /^[Aa]L([0-9\.\-\+\s]{6})/gm
    let [od_addition] = this.regex(block,od_addition_regex)
    let [og_addition] = this.regex(block,og_addition_regex)
    let od_av_loin_regex = /^[Vv]R([0-9\.\-\+\s]{5})/gm
    let og_av_loin_regex = /^[Vv]L([0-9\.\-\+\s]{5})/gm
    let [od_av_loin] = this.regex(block,od_av_loin_regex)
    let [og_av_loin] = this.regex(block,og_av_loin_regex)
    let od_tension_regex = /^[T]R([0-9\.\-\+\s]{4})/gm
    let og_tension_regex = /^[T]L([0-9\.\-\+\s]{4})/gm
    let [od_tension] = this.regex(block,od_tension_regex)
    let [og_tension] = this.regex(block,og_tension_regex)
    let  data = {
      "od_tension": this.closest(this.tensions,od_tension),
      "og_tension": this.closest(this.tensions,og_tension),
      "od_sphere": this.closest(this.spheres,od_sphere),
      "og_sphere": this.closest(this.spheres,og_sphere),
      "od_cylindre": this.closest(this.cylindres,od_cylindre),
      "og_cylindre": this.closest(this.cylindres, og_cylindre),
      "od_axe": this.closest(this.axes,od_axe),
      "og_axe": this.closest(this.axes,og_axe),
      "od_addition": this.closest(this.additions,od_addition),
      "og_addition": this.closest(this.additions,og_addition),
      "od_av_loin": this.closest(this.avs,od_av_loin),
      "og_av_loin": this.closest(this.avs,og_av_loin)
    }
    return(data)
  }


}
