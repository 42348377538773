import React, {Component} from "react";
import Billing from "../../Billing/Billing";
import OrthoptistOptions from "./OrthoptistOptions";


function OrthoptistSettings({orthoptist_id}) {
        return (
            <div>
                <div className="row">
                    <div className="col xl7 m12">
                        <Billing orthoptist_id={orthoptist_id}/>
                    </div>
                    <div className="col xl5 m12">
                        <OrthoptistOptions orthoptist_id={orthoptist_id}/>
                    </div>
                </div>
            </div>
        )
}

export default OrthoptistSettings;
