import React, {Component} from "react";

export default class JustificationSelect extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="valign-wrapper">
                <a className={`btn waves-light btn-small z-depth-0 ${this.props.justify.toLowerCase() === "left" ? "blue darken-2" : "grey lighten-3 black-text"}`}
                   onClick={() => this.props.onChange("left")}
                   style={{borderRadius: "5px 0 0 5px", padding: "0 7px 0 7px", margin: 0}}><i
                    className="material-icons">format_align_left</i></a>
                <a className={`btn waves-light btn-small z-depth-0 ${this.props.justify.toLowerCase() === "center" ? "blue darken-2" : "grey lighten-3 black-text"}`}
                   onClick={() => this.props.onChange("center")}
                   style={{borderRadius: "0", padding: "0 7px 0 7px", margin: 0}}><i
                    className="material-icons">format_align_center</i></a>
                <a className={`btn waves-light btn-small z-depth-0 ${this.props.justify.toLowerCase() === "right" ? "blue darken-2" : "grey lighten-3 black-text"}`}
                   onClick={() => this.props.onChange("right")}
                   style={{borderRadius: "0 5px 5px 0", padding: "0 7px 0 7px", margin: 0}}><i
                    className="material-icons">format_align_right</i></a>
            </div>
        );
    }
}

JustificationSelect.defaultProps = {
    justify: ""
}