import React from "react";

class Regex {
    phoneNumberRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/g
    validatePhoneNumber = (phoneNumber) => {
        return phoneNumber.match(this.phoneNumberRegex)
    }

    emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    validateEmail = (email) => {
        return email.match(this.emailRegex)
    }

    regex_rpps = new RegExp(/^\d{11}$/);
    validateRpps = (rpps) => {
        return rpps.match(this.regex_rpps)
    }
    regex_adeli = new RegExp(/^\d{9}$/);
    validateAdeli = (adeli) => {
        return adeli.match(this.regex_adeli)
    }

    urlRegex = /((?:app\.temeoo\.com|app\.expert-vision\.eu|localhost:3000)(:8443)?(?:\/[\w~&=#@+%?!-]*)*)/gi
    safeUrlReplace = (text) => {
        text = " " + text.replace(/http(s)?:\/\//, "")
        // replace all urls with links and the rest with spans
        const parts = text.split(this.urlRegex).filter(part => !!part)
        return parts.map((part, index) => {
            part = part.trim()
            if(index === parts.length - 1 || !part) {
                if (index % 2 === 0) return part
                return <a key={index} href={"https://" + part} target="_blank">{part}</a>
            }
            if (index % 2 === 0) return part + " "
            return <a key={index} href={"https://" + part} target="_blank">{part}&nbsp;</a>
        })
    }

    odMesureRegex = /[OD|DROIT]\s*:\s*(?<od_sphere>plan|[+-]?\d*(?:\.\d*)?)\s*(\(\s*(?<od_cylindre>-?\d*(\.\d*)?)?\s*(à\s*(?<od_axe>\d*)\s*°)?\))?\s*(Add\s*\+?(?<od_addition>\d*(\.\d*)?))?/mi
    ogMesureRegex = /[OG|GAUCHE]\s*:\s*(?<og_sphere>plan|[+-]?\d*(?:\.\d*)?)\s*(\(\s*(?<og_cylindre>-?\d*(\.\d*)?)?\s*(à\s*(?<og_axe>\d*)\s*°)?\))?\s*(Add\s*\+?(?<og_addition>\d*(\.\d*)?))?/mi

    extractValues = (text) => {
        let values = {}
        let match_od = this.odMesureRegex.exec(text)
        if(match_od) {
            values.od_sphere = match_od.groups.od_sphere
            values.od_cylindre = match_od.groups.od_cylindre
            values.od_axe = match_od.groups.od_axe
            values.od_addition = match_od.groups.od_addition
        }
        let match_og = this.ogMesureRegex.exec(text)
        if(match_og) {
            values.og_sphere = match_og.groups.og_sphere
            values.og_cylindre = match_og.groups.og_cylindre
            values.og_axe = match_og.groups.og_axe
            values.og_addition = match_og.groups.og_addition
        }
        return values
    }
}

let regex = new Regex
global.Regex = regex
export default regex