import React from "react"
import axios from "axios"
import {Colors} from "./ColorEnums"
import {AlertTypes} from "./AlertTypesEnums";
import {toast} from "react-toastify";
import Loader from "../Shared/Loader";
import AlertTab from "./AlertTab";
import ButtonRow from "./ButtonRow";
import SelectedAlert from "./SelectedAlert";

const RESULT_PER_PAGE = 20
const SORT = {
    CHRONOLOGICAL: "chronological",
    ALPHABETICAL: "alphabetical"
}

const URLS_FOR_USER_TYPE = {
    orthoptist:{

    },
    extern:{
        get_alert_list: "/externes/alert/alert_list",
        get_alert_count: "/externes/alert/alert_count",
        archivate_alert: "externes/alerte/archive/"
    },
    admin:{
        get_alert_list:"/admin/alert/alert_list",
        get_alert_count: "/admin/alert/alert_count",
        archivate_alert: "/admin/alert/archive/"
    }
}

export default class Alerts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            current_filters: [],
            current_tab: AlertTypes.UNREAD.type,
            alert_counts: {},
            current_page: 1,
            max_pages: {},
            alert_lists: {},
            selected_alert: null,
            sorting_by: SORT.CHRONOLOGICAL,
            is_loaded: false,
        }
        axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector("meta[name='csrf-token']").content
    }


    componentDidMount() {
        var elems = document.getElementById("alert_tabs");
        M.Tabs.init(elems, {onShow: this.on_show});
        this.getAlertsCount()
        this.getAlerts()
    }

    componentWillUnmount() {
        M.Tabs.getInstance(document.getElementById("alert_tabs")).destroy();
        let tooltips = document.querySelectorAll('[id=alertSecretaryCommentTooltip]')
        if (M.Tooltip.getInstance(tooltips)) M.Tooltip.getInstance(tooltips).destroy()
    }

    on_show = (tab) => {
        const type_list = Object.values(AlertTypes).map((type) => type.type)
        if (type_list.includes(tab.id)) this.setState({current_tab: tab.id, current_page: 1})
    }

    getAlerts = (page = 1, type = "all", filters = []) => {
        this.deleteTooltips()
        axios.get(URLS_FOR_USER_TYPE[this.props.role].get_alert_list,
            {
                params: {
                    result_per_page: RESULT_PER_PAGE,
                    page: page,
                    type: type,
                    sorting: this.state.sorting_by,
                    filters: filters
                }
            })
            .then((data) => {
                let updated_alert_list = {...this.state.alert_lists}
                for (let type in data.data) {
                    updated_alert_list[type] = data.data[type]
                }
                this.setState({alert_lists: updated_alert_list},
                    () => {
                        M.Tooltip.init(document.querySelectorAll('[id=alertSecretaryCommentTooltip]'), {exitDelay: 0, enterDelay: 50})
                        this.setState({is_loaded: true}
                        )
                    })
            })
    }


    getAlertsCount = () => {
        axios.get(URLS_FOR_USER_TYPE[this.props.role].get_alert_count)
            .then((data) => {
                let alert_count = {...this.state.alert_counts}
                let max_pages = {...this.state.max_pages}
                for (let alert_type in data.data) {
                    max_pages[alert_type] = Math.floor(data.data[alert_type] / RESULT_PER_PAGE) + 1
                    alert_count[alert_type] = data.data[alert_type]
                }
                this.setState({alert_counts: alert_count, max_pages: max_pages})
            })
    }

    handleFilters = (selectedColor) => {
        let current_filters = this.state.current_filters

        if (current_filters.includes(selectedColor["gravity"])) {
            if (current_filters.indexOf(selectedColor["gravity"]) > -1) current_filters.splice(current_filters.indexOf(selectedColor["gravity"]), 1)
            this.setState({current_filters: current_filters})
        } else {
            current_filters.push(selectedColor["gravity"])
            this.setState({current_filters: current_filters})
        }
        this.pageChange(1)
    }


    handleSorting = (selectedSorting) => {
        if (selectedSorting !== this.state.sorting_by)
            this.setState({sorting_by: selectedSorting},
                () => this.pageChange(1, "all"))
    }

    pageChange = (selectedPage, tab = this.state.current_tab) => {
        if (selectedPage < 1 || selectedPage > this.state.max_pages[this.state.current_tab]) return
        this.getAlerts(selectedPage, tab, this.state.current_filters)
        this.setState({current_page: selectedPage})
    }

    selectAlert = (alert) => {
        this.setState({selected_alert: alert})
    }

    archivateAlert = () => {
        axios.delete(URLS_FOR_USER_TYPE[this.props.role].archivate_alert + this.state.selected_alert["alert"]["id"])
            .then((data) => {
                this.pageChange(1)
                this.getAlertsCount()
                this.setState({selected_alert: null},
                    () => this.getAlerts(this.state.current_page), "all", this.state.current_filters)
                toast.success("Alerte supprimée avec succès.")

            }).catch(() => toast.error("Erreur lors de la suppression de l'alerte."))
    }

    findCorrespondingAttribute = (type, value) => {
        const color_index = Colors.findIndex((color) => color.alert_type === value)
        return color_index > 0 ? Colors[color_index][type] : Colors.slice(-1)[0][type]
    }

    deSelectAlert = () => {
        this.setState({selected_alert: null})
    }

    handleChecked = (field, checked, alert_id) => {
        let new_selected_alert = {...this.state.selected_alert}
        new_selected_alert["alert"][field] = checked

        this.setState({selected_alert: new_selected_alert}, () => {
            this.getAlertsCount()
            this.getAlerts(this.state.current_page, "all", this.state.current_filters)
        })
    }

    handleSecretaryCommentChange = (alert_id, comment) => {
        let new_selected_alert = {...this.state.selected_alert}
        new_selected_alert["alert"]["secretary_comment"] = comment

        this.setState({selected_alert: new_selected_alert}, () => {
            this.getAlertsCount()
            this.getAlerts(this.state.current_page, "all", this.state.current_filters)
        })
    }

    deleteTooltips = () => {
        let tooltips = document.querySelectorAll('[id=alertSecretaryCommentTooltip]')
        for (let tooltip of tooltips) {
            if (M.Tooltip.getInstance(tooltip)) M.Tooltip.getInstance(tooltip).destroy()
        }
    }

    render() {
        return (
            <div className="center" style={{display: "flex", flexDirection: "column", gap: 20}}>
                <div>
                    <div style={{margin: "10px 0"}}>
                        <b style={{textAlign: "center", margin: "10px", fontSize: "1.3em"}}>Classer par ordre :</b>
                        <a className={`${this.state.sorting_by === SORT.CHRONOLOGICAL ? "blue darken-2 btn" : "white blue-text text-darken-2 btn"}`}
                           style={{margin: "5px", borderRadius: "20px"}}
                           onClick={() => this.handleSorting(SORT.CHRONOLOGICAL)}>
                            Chronologique
                        </a>
                        <a className={`${this.state.sorting_by === SORT.ALPHABETICAL ? "blue darken-2 btn" : "white blue-text text-darken-2 btn"}`}
                           style={{margin: "5px", borderRadius: "20px"}}
                           onClick={() => this.handleSorting(SORT.ALPHABETICAL)}>
                            Alphabétique
                        </a>
                    </div>
                    <div style={{display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
                        <b style={{textAlign: "center", margin: "10px", fontSize: "1.3em"}}>Filtres :</b>
                        <ButtonRow
                            handleFilters={this.handleFilters}
                            current_filters={this.state.current_filters}
                            colors={Object.keys(Colors)}
                        />
                    </div>
                </div>
                <div className="divider"/>
                <div>
                    {this.state.selected_alert ?
                        <SelectedAlert
                            deSelectAlert={this.deSelectAlert}
                            findCorrespondingAttribute={this.findCorrespondingAttribute}
                            handleChecked={this.handleChecked}
                            handleSecretaryCommentChange={this.handleSecretaryCommentChange}
                            archivateAlert={this.archivateAlert}
                            selected_alert={this.state.selected_alert}
                        />
                        : null}
                </div>
                <div className="container" style={{width: "80%"}}>
                    <ul id="alert_tabs" className="tabs">
                        {Object.keys(AlertTypes).map((type, index) => {
                            return (
                                <li className="tab" key={`tab_title_${index}`}>
                                    <a href={`#${AlertTypes[type].type}`} className={!index ? "active":""}>
                                        {`${AlertTypes[type].title} `}
                                        {`(${this.state.alert_counts[AlertTypes[type].type] ? this.state.alert_counts[AlertTypes[type].type] : 0})`}
                                    </a>
                                </li>)
                        })}
                    </ul>
                    {Object.keys(AlertTypes).map((type, index) => {
                        return (
                            <div id={`${AlertTypes[type].type}`} className="col s12" key={`tab_content_${index}`}>
                                {this.state.is_loaded ?
                                    <AlertTab
                                        title={AlertTypes[type].title}
                                        alert_type={type}
                                        alert_list={[...this.state.alert_lists[AlertTypes[type].type]]}
                                        selectAlert={this.selectAlert}
                                    />
                                    : <Loader/>}
                            </div>
                        )
                    })}

                </div>
                <br/>
                {this.state.alert_counts[this.state.current_tab] > 0 ?
                    <div>
                        Résultats
                        de {(this.state.current_page - 1) * RESULT_PER_PAGE + 1} à {this.state.current_page * RESULT_PER_PAGE < this.state.alert_counts[this.state.current_tab] ? this.state.current_page * RESULT_PER_PAGE : this.state.alert_counts[this.state.current_tab]} sur {this.state.alert_counts[this.state.current_tab]}
                        <br/>
                        <ul className="pagination"
                            style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <li>
                                <a className={this.state.current_page === 1 ? "disabled" : "waves-effect"}
                                   onClick={() => this.pageChange(this.state.current_page - 1)}
                                   style={{cursor: this.state.current_page === 1? "not-allowed": "pointer"}}
                                >
                                    <i className={`material-icons ${this.state.current_page === 1 ? "grey-text" : "blue-text text-darken-2"}`}>chevron_left</i>
                                </a>
                            </li>
                            <li style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                <div style={{fontSize: "1.1em"}}>Page {this.state.current_page}</div>
                            </li>
                            <li>
                                <a
                                    className={this.state.current_page === this.state.max_pages[this.state.current_tab] ? "disabled" : "waves-effect"}
                                    onClick={() => this.pageChange(this.state.current_page + 1)}
                                    style={{cursor: this.state.current_page === this.state.max_pages[this.state.current_tab]? "not-allowed": "pointer"}}>
                                    <i className={`material-icons ${this.state.current_page === this.state.max_pages[this.state.current_tab] ? "grey-text" : "blue-text text-darken-2"}`}>chevron_right</i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    : null
                }
            </div>

        )
    }
}