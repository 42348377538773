import React from "react"
import PropTypes from "prop-types"
import axios from "axios"
import { toast } from 'react-toastify';
import moment from "moment"
import Loader from "./Loader"
import ShowMesure from "../../syntheses/ShowMesures"
import { HashRouter as Router, Route, Link } from "react-router-dom"
import {JOB} from "../../Shared/Helper";

export default class ControleFlag extends React.Component {
  constructor(props) {
    super(props);
    this.loadData = this.loadData.bind(this)
    this.alerte = this.alerte.bind(this)
    this.validate = this.validate.bind(this)
    this.state = {
      patient: {
        nom: "",
        prenom: "",
        telephone: "",
        birthday: ""
      },
      age: "",
      images: [],
      id: "",
      commentaire: "",
      mesures: [],
      mesuresLoaded: false,
      orthoptiste: "",
      typedemesures: [],
      pathologies: [],
      loading: true
    };
    let csrfToken =document.querySelector("meta[name='csrf-token']").content
    axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
    this.loadData()
  }
  componentDidMount(){
  }
  loadData(){
    axios.post("/api/admin/controle/synthese/",{id: this.props.id}).then((data)=>{
      data= data.data
      this.setState({
        id: data.id,
        patient: data.patient,
        images: data.images,
        age: moment().diff(data.patient.birthday,"years"),
        commentaire: data.commentaire,
        orthoptiste: data.orthoptiste,
        pathologies: data.pathologies,
        loading: false,
      })
    })
    axios.post("/api/admin/controle/mesures/",{id: this.props.id}).then((data)=>{
      data= data.data
      this.setState({
        mesures: data.mesures,
        typedemesures: data.typedemesures,
        mesuresLoaded: true
      })
    })

  }
  validate(e){
    e.preventDefault()
    this.setState({
      loading: true,
      mesuresLoaded: false
    })
    axios.post("/api/admin/controle/accepter",{id: this.state.id}).then((data)=>{
      this.loadData()
    })
  }
  componentDidUpdate(){
    var elems = document.querySelectorAll('.materialboxed');
    var instances = M.Materialbox.init(elems);
  }
  alerte(){
    let alert =false
    let messages = []
    this.state.mesures.map((mesure)=>{
      if(mesure.od_tension > 21 || mesure.og_tension > 21){
        alert = true
        messages.push("Une tension trop élevée")
      }
      if(mesure.titre == 'Réfraction subjective'){
        if(!(["","0.9","1","1.0","12","16"].includes(mesure.og_av_loin)) || !(["","0.9","1","1.0","12","16"].includes(mesure.od_av_loin))){
          alert = true
          messages.push("Une acuité visuelle trop basse")
        }
      }
    })
    if(alert){
      messages = Array.from(new Set(messages))
      return(
        <div className="red white-text floating-alert">
          <h4 className="center">Problème détecté</h4>
          <ul>
            {messages.map((message,index)=>(
              <li key={index}>{message}</li>
            ))}
          </ul>
        </div>
      )
    }
  }

  render(){
    if(this.state.loading){
      return(
        <div className="valign-wrapper page">
          <div className="center-loader">
              <Loader />
          </div>
        </div>
        )
    }else{
    return(
      <div>
        <div className="row">
          <div className="col s6">
          {this.alerte()}
          <div className="row">
          <div className="col s3">
            <p className="center">Donnée patient</p>
            <ul>
              <li>{this.state.patient.prenom} {this.state.patient.nom}</li>
              <li>{this.state.age} ans</li>
              <li>{this.state.patient.telephone}</li>
              <li>Vu par {this.state.orthoptiste} </li>
            </ul>
          </div>
          <div className="col s3">
            <p className="center">Commentaire {JOB.toLowerCase()}</p>
            <p>{this.state.commentaire}</p>
            { this.state.pathologies.length > 0 ? (
                <div>
                  <h2 className="center red-text">Pathologies !</h2>
                  {this.state.pathologies.map((pathologie)=>(
                    <p key={pathologie.id} className="center red-text">{pathologie.pathologie}</p>
                    ))}
                </div>
              ) : (
                <div></div>
              )}

          </div>
        </div>
        <div className="col s12">
          <div className="row">
          {this.state.images.map((image,index)=>(
            <div key={index} className={"col s"+(12/this.state.images.length)}>
              <img className="responsive-img materialboxed" src={image} />
            </div>
            ))}
          </div>
        </div>
          </div>
          <div className="col s4">
            {!this.state.mesuresLoaded? (
                <p>Chargement en cours</p>
            ) : (
              <ShowMesure mesures={this.state.mesures} typedemesures={this.state.typedemesures} />
            )}
          </div>
          <div className="col s2 call-to-action">
            <div className="aligner">

              <div className="Aligner-item ">
                <p><a onClick={this.validate} className="btn btn-large green">Valider</a></p>
                <p>
                  <Link to="pathologie" className="btn btn-large red">Refuser</Link>
                </p>
              </div>
            </div>

          </div>

      </div>
        </div>
      )
    }
  }

}
