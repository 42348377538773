import React from "react"

export default function PrescriptionImage(){
    return(
        <a href="/orthoptiste/download/prescription?html=1" target="_blank" title="Editer SCOR en image"
           data-cy="DownloadPrescriptionPNG"
           className="btn green darken-2 z-depth-0 truncate">
            <i className="material-icons left">image</i>PNG
        </a>
    )
}
