import React, {useEffect} from "react";
import ReactToPrint from "react-to-print";
import Helper, {JOB} from "../../Shared/Helper";
import {Role} from "../../Shared/Enums/Scope";
import axios from "axios";
import Tooltip from "../../Shared/Tootip";
import {InvoiceStatus} from "../../Shared/Enums/Enums";

const _ = require('lodash');

function PrintInvoice(props) {
    let toPrint = null
    let toPrintRetro = null
    const pageStyle = `
@media print {
   body {
       display: block;
       box-sizing: border-box;   
       padding: 20mm 15mm;
       height: auto;
       print-color-adjust: exact; 
       -webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
       color-adjust: exact !important;                 /* Firefox 48 – 96 */
       print-color-adjust: exact !important;           /* Firefox 97+, Safari 15.4+ */
   }
  
    @page {
       size: auto;   /* auto is the initial value */
       margin: 0;  /* this affects the margin in the printer settings */
    }
    
    #primary-color {
        color: #ef7c6b;
    }
    
    #secondary-color {
        color: #de4791;
    }
    
    #secondary-bg-color {
        background-color: #de4791;
    }
}
`;
    const [appointments, setAppointments] = React.useState([])
    const [idToPrint, setIdToPrint] = React.useState(undefined)

    useEffect(() => {
        if (idToPrint) document.getElementById(`rtp-invoice-${idToPrint}`).click()
        setIdToPrint(undefined)
    }, [idToPrint])

    const print = (detailed = false) => {
        if (!detailed) {
            setAppointments([])
            setIdToPrint(props.invoice.id)
            // document.getElementById(`rtp-invoice-${props.invoice.id}`).click()
            return
        }
        axios.get(`/orthoptistes/paiements/invoice/${props.invoice.id}/details`).then((resp) => {
            // On corrige le bug de non distinct. (on a des doublons)
            const ids = new Set();
            const appts = resp.data.map((appt) => {
                if (ids.has(appt.id)) null
                ids.add(appt.id);
                return appt
            }).filter((appt) => appt !== null);
            setAppointments(appts)
            setIdToPrint(props.invoice.id)
        }).catch((error) => {
            setIdToPrint(props.invoice.id)
            // document.getElementById(`rtp-invoice-${props.invoice.id}`).click()
        })

    }

    const printRetro = () => {
        document.getElementById(`rtp-invoice-retro-${props.invoice.id}`).click()
    }

    const name = () => {
        if(props.orthoptist.invoice_label) return props.orthoptist.invoice_label
        return Helper.formatName(props.orthoptist.prenom, props.orthoptist.nom)
    }

    const display = () => {
        return <div ref={el => (toPrint = el)} style={{display: "flex", flexDirection: "column"}}>
            <div>
                {renderHeader()}
                <div style={{display: "flex", margin: "40px 0"}}>
                    <h5 style={{margin: 0}}><b>Facturé à
                        :</b> {name()}</h5>
                </div>
                <div style={{margin: "100px 0 0 0"}}>
                    {renderBody()}
                </div>
                <div id="fixed-div" style={{position: "fixed", top: "240px", left: "400px", opacity: 0.3}}>
                    {renderStamp()}
                </div>
            </div>
            <div>
                {renderDetails()}
            </div>
        </div>
    }

    const displayRetro = () => {
        return <div ref={el => (toPrintRetro = el)} style={{display: "flex", flexDirection: "column"}}>
            <div style={{height: "25.5cm"}}>
                {renderHeader(true)}
                <div style={{display: "flex", justifyContent: "space-around", margin: "40px 0"}}>
                    <div style={{display: "flex", flexDirection: "column", margin: "40px 0"}}>
                        <h5 style={{margin: 0}}><b>Au nom et pour le compte de :</b></h5>
                        <h5 style={{margin: 0}}>{name()}</h5>
                        <h5 style={{margin: 0}}>{props.orthoptist.rpps}</h5>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", margin: "40px 0"}}>
                        <h5 style={{margin: 0}}><b>Adressé à :</b></h5>
                        <h5 style={{margin: 0}}>Dr {Helper.formatName(props.ophtalmologist.prenom, props.ophtalmologist.nom)}</h5>
                        <h5 style={{margin: 0}}>RPPS :{props.ophtalmologist.rps}</h5>
                        <h5 style={{margin: 0}}>ADELI :{props.ophtalmologist.adeli}</h5>
                    </div>
                </div>
                <div style={{margin: "100px 0 0 0"}}>
                    {renderBodyRetro()}
                </div>
                <div id="fixed-div" style={{position: "fixed", top: "350px", left: "400px", opacity: 0.3}}>
                    {renderStamp()}
                </div>
            </div>
            <div>
                {renderDetails()}
            </div>
        </div>
    }

    const renderHeader = (retrocession = false) => {
        return (
            <div style={{display: "flex"}}>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <img height="50px" src={JOB === Role.OPTICIAN ? "" : "/imgs/logo-temeoo-new.svg"}/>
                    <h2 id="primary-color"
                        style={{
                            fontWeight: "normal",
                            left: retrocession ? 80 : 150,
                            position: "absolute",
                            top: 120
                        }}>{retrocession ? "Etat des rétrocessions" : "FACTURE"}</h2>
                </div>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "right", marginLeft: "auto"}}>
                    <h5 style={{fontWeight: "bold", margin: 0, marginLeft: "auto", marginBottom: "5px"}}>Temeoo</h5>
                    <h5 style={{margin: 0, marginLeft: "auto"}}>70 rue du Dr Yersin</h5>
                    <h5 style={{margin: 0, marginLeft: "auto"}}>59120 Loos</h5>
                    <h5 style={{margin: 0, marginLeft: "auto"}}>France</h5>
                    <h5 style={{margin: 0, marginLeft: "auto"}}>N° de TVA : FR42901319160</h5>
                </div>
            </div>
        )
    }

    const renderBody = () => {
        const total_plateforme = props.invoice.v2 ? (props.invoice.totalPlateforme) / 100 : (props.invoice.totalPlateforme - props.invoice.total_ophtalmologiste) / 100
        const fixed_amount = props.invoice.v2 ? (props.invoice.synthese.fixed_amount) / 100 : 0
        const variable_amount = props.invoice.v2 ? (props.invoice.synthese.variable_amount) / 100 : 0

        const total_ttc = total_plateforme
        const total_ht = (total_plateforme - fixed_amount - variable_amount) / 1.2
        const fixed_ht = fixed_amount / 1.2
        const variable_ht = variable_amount / 1.2
        const tva = total_ttc - total_ht - fixed_ht - variable_ht
        const date = Helper.formatDate(props.invoice.created_at, "DD/MM/YYYY")
        return <div style={{display: "flex", flexDirection: "column"}}>
            <h5 style={{fontWeight: "bold", margin: "10px 0"}}>
                Justificatif de paiement pour votre activité
                du {Helper.formatDate(props.invoice.from)} au {Helper.formatDate(props.invoice.to)}.
            </h5>
            <h5 style={{fontWeight: "bold", margin: "10px 0"}}>
                Montant prélevé : {total_ttc.toFixed(2)}€
            </h5>
            <div style={{display: "inline-flex", justifyContent: "space-between"}}>
                <h5 style={{fontWeight: "bold", margin: 0}}>Facture N°{props.invoice.id}</h5>
                <h5 id="secondary-color" style={{fontWeight: "bold", margin: 0}}>Émis le {date}</h5>
            </div>
            <div className="divider" id="secondary-bg-color" style={{margin: "20px 0", height: "3px"}}/>
            <div style={{backgroundColor: "#e2e2e2", padding: "0 10px"}}>
                <div style={{display: "flex", flexDirection: "column", padding: "20px 0"}}>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <h5 style={{margin: 0}}>Facture HT</h5>
                        <h5 style={{margin: 0}}>{total_ht.toFixed(2)}€</h5>
                    </div>
                    {fixed_ht ? (<div style={{display: "flex", justifyContent: "space-between"}}>
                        <h5 style={{margin: 0}}>Charges</h5>
                        <h5 style={{margin: 0}}>{fixed_ht.toFixed(2)}€</h5>
                    </div>) : <div/>}
                    {variable_ht ? (<div style={{display: "flex", justifyContent: "space-between"}}>
                        <h5 style={{margin: 0}}>Variable</h5>
                        <h5 style={{margin: 0}}>{variable_ht.toFixed(2)}€</h5>
                    </div>) : <div/>}
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <h5 style={{margin: 0}}>TVA (20%)</h5>
                        <h5 style={{margin: 0}}>{tva.toFixed(2)}€</h5>
                    </div>
                    <div id="secondary-color"
                         style={{display: "flex", justifyContent: "space-between", marginTop: "20px"}}>
                        <h5 style={{margin: 0}}><b>Total à payer TTC *</b></h5>
                        <h5 style={{margin: 0}}><b>{total_ttc.toFixed(2)}€</b></h5>
                    </div>
                    <h6 style={{fontStyle: "italic"}}>* Cette somme sera automatiquement prélevée sur votre compte</h6>
                </div>
            </div>
            <div className="divider" id="secondary-bg-color" style={{margin: "20px 0", height: "3px"}}/>
        </div>
    }

    const renderBodyRetro = () => {
        const debt = props.invoice.v2 ? (props.invoice.synthese.payed_debt) / 100.0 : 0

        return <div style={{display: "flex", flexDirection: "column"}}>
            <h5 style={{fontWeight: "bold", margin: "10px 0"}}>
                Etat des rétrocessions
                du {Helper.formatDate(props.invoice.from)} au {Helper.formatDate(props.invoice.to)}
            </h5>
            <div className="divider" id="secondary-bg-color" style={{margin: "20px 0", height: "3px"}}/>
            <div style={{backgroundColor: "#e2e2e2", padding: "0 10px"}}>
                <table>
                    <thead>
                    <tr>
                        <th>Descriptif</th>
                        <th>Montant</th>
                    </tr>
                    </thead>
                    <tbody>
                    {_.isEmpty(props.invoice.ophtalmologists_earnings) ? (<tr>
                            <th>Rétrocessions pour le
                                Dr {props.invoice.first_appointment_analyst.name}</th>
                            <th><b>{(props.invoice.total_ophtalmologiste / 100).toFixed(2)} €</b></th>
                        </tr>) :
                        (
                            Object.entries(props.invoice.ophtalmologists_earnings).map(([key, value], index) => {
                                return <tr key={index}>
                                    <th>Rétrocessions pour le Dr {key}</th>
                                    <th><b>{value} €</b></th>
                                </tr>
                            })
                        )}
                    {debt ? (<tr>
                            <th>Remboursement montant tampon</th>
                            <th>{debt.toFixed(2)}€</th>
                        </tr>
                    ) : <div/>}
                    {ophtalmologistes_earnings(debt)}
                    </tbody>
                </table>
            </div>
            <div className="divider" id="secondary-bg-color" style={{margin: "20px 0", height: "3px"}}/>
            {debt ? (<h6 style={{fontStyle: "italic"}}>Reste à rembourser
                : {((props.orthoptist.creance || 0) / 100.0).toFixed(2)}€</h6>) : <div/>}
        </div>
    }


    const ophtalmologistes_earnings = (debt) => {
        const length = Object.entries(props.invoice.ophtalmologists_earnings).length
        if (length === 0) return <div/>


        const totalRetro = Object.entries(props.invoice.ophtalmologists_earnings).reduce((acc, [key, value]) => {
            return acc + parseFloat(value)
        }, debt || 0);

        return <tr id="secondary-color">
            <th>Total</th>
            <th><b>{totalRetro.toFixed(2)}€</b></th>
        </tr>
    }

    const renderDetails = () => {
        if (appointments.length === 0) return null
        return (
            <div style={{pageBreakBefore: "always", display: "flex", flexDirection: "column", gap: 20, marginTop: 20}}>
                <h5 className="center">Détail des actes</h5>
                <table align="left" style={{
                    fontSize: "12px",
                    width: "100%",
                    fontFamily: "Courier, sans-serif",
                }}>
                    <thead align="left">
                    <tr>
                        <th>Nombre d'actes</th>
                        <th>Type</th>
                        <th>Prix</th>
                        <th>Dû</th>
                    </tr>
                    </thead>
                    <tbody align="left">
                    {Object.entries(appointments.reduce((acc, appointment) => {
                        if (appointment.act_price === 0) return acc
                        if (Object.keys(acc).includes(appointment.act_label)) {
                            acc[appointment.act_label].number++
                            acc[appointment.act_label].price += appointment.act_price
                            acc[appointment.act_label].to_pay += parseFloat(appointment.to_pay)
                            return acc
                        }
                        acc[appointment.act_label] = {
                            number: 1,
                            price: appointment.act_price,
                            to_pay: parseFloat(appointment.to_pay)
                        }
                        return acc
                    }, {}))
                        .map(([act_label, value], index) => {
                            return (
                                <tr key={index} style={{padding: 0, margin: 0}}>
                                    <th>{value.number}</th>
                                    <th>{act_label}</th>
                                    <th>{value.price}€</th>
                                    <th>{value.to_pay.toFixed(2)}€</th>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <h5 className="center">Détail des consultations</h5>
                <table align="left" style={{
                    fontSize: "12px",
                    fontFamily: "Courier, sans-serif",
                    width: "100%",
                    paddingTop: 30
                }}>
                    <thead align="left">
                    <tr>
                        <th>Date</th>
                        <th>Patient</th>
                        <th>Acte</th>
                        <th>Prix</th>
                        <th>Dû</th>
                    </tr>
                    </thead>
                    <tbody align="left">
                    {appointments.map((appointment, index) => {
                        return (
                            <tr className="left" key={index} style={{padding: 0, margin: 0}}>
                                <th className="left">{Helper.formatDate(appointment.date)}</th>
                                <th className="left">{appointment.patient_name}</th>
                                <th>{appointment.act_label}</th>
                                <th>{appointment.act_price}€</th>
                                <th>{appointment.to_pay}€</th>
                            </tr>
                        )
                    })
                    }
                    </tbody>
                </table>
            </div>
        )
    }

    const renderStamp = () => {
        if (props.invoice.status === InvoiceStatus.SUCCESS) {
            return <div style={{
                height: "100px",
                width: "300px",
                border: "solid 5px #5ca85e",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#5ca85e"
            }}>
                <i className="material-icons" style={{fontSize: "5rem"}}>check_circle</i>
                <span style={{fontWeight: "bolder", fontSize: "xxx-large", marginLeft: "10px"}}>PAYÉ</span>
            </div>
        }
    }

    return (
        <div>
            <div style={{display: "flex", justifyContent: "flex-end"}}>
                <Tooltip text="Imprimer la facture" position="top" id={`tooltip-print-invoice-${props.invoice.id}`}>
                    <a className="btn btn-small blue darken-2 z-depth-0" onClick={() => print(false)}><i
                        className="material-icons">receipt</i></a>
                </Tooltip>
                &nbsp;
                <Tooltip text="Imprimer la facture détaillée" position="top"
                         id={`tooltip-print-invoice-detailed-${props.invoice.id}`}>
                    <a className="btn btn-small blue darken-2 z-depth-0" onClick={() => print(true)}><i
                        className="material-icons">receipt_long</i></a>
                </Tooltip>
                &nbsp;
                <Tooltip text="Voir l'état des rétrocessions" position="top"
                         id={`tooltip-print-invoice-retrocessions-${props.invoice.id}`}>
                    <a className="btn btn-small blue darken-2 z-depth-0" onClick={() => printRetro(false)}><i
                        className="material-icons">remove_red_eye</i></a>
                </Tooltip>
            </div>
            <div className="hide">
                <ReactToPrint
                    pageStyle={pageStyle}
                    trigger={() => (
                        <a className="btn blue darken-2 z-depth-0" id={`rtp-invoice-${props.invoice.id}`}/>
                    )}
                    content={() => toPrint}
                />
                {display()}
            </div>
            <div className="hide">
                <ReactToPrint
                    pageStyle={pageStyle}
                    trigger={() => (
                        <a className="btn blue darken-2 z-depth-0" id={`rtp-invoice-retro-${props.invoice.id}`}/>
                    )}
                    content={() => toPrintRetro}
                />
                {displayRetro()}
            </div>
        </div>
    )
}

export default PrintInvoice