import React, {Component} from "react"
import {HashRouter as Router, Route} from "react-router-dom"
import Controle from "../Controle";


export default class Routeur extends Component {
    constructor(props) {
        super(props)
        this.state = {
            index: 0
        }
    }

    render() {
        return (
            <div>

                <React.Fragment>
                    <Router>
                        <Route path="/">
                            <div>
                                <div className="center">
                                </div>
                            </div>
                        </Route>
                        <Route path="/:id" exact component={Controle}/>
                    </Router>

                </React.Fragment>
            </div>
        );
    }
}
