import {TypesMesures} from "./Enums";

export const MesureTokens = {
    OD_TENSION: {token: "od_tension", format: v => v},
    OD_PACHYMETRY: {token: "od_pachymetrie", format: v => v},
    OD_SPHERE: {token: "od_sphere", format: v => v},
    OD_CYLINDRE: {token: "od_cylindre", format: v => v},
    OD_AXIS: {token: "od_axe", format: v => formatAxis(v)},
    OD_ADDITION: {token: "od_addition", format: v => formatAdd(v)},
    OD_LONGSIGTH: {token: "od_av_loin", format: v => formatAvLoin(v)},
    OD_SHORTSIGHT: {token: "od_parinaud", format: v => v},
    OD_STENOPEIC_HOLE: {token: "od_av_trou_stenopeique", format: v => v},
    OG_TENSION: {token: "og_tension", format: v => v},
    OG_PACHYMETRY: {token: "og_pachymetrie", format: v => v},
    OG_SPHERE: {token: "og_sphere", format: v => v},
    OG_CYLINDRE: {token: "og_cylindre", format: v => v},
    OG_AXIS: {token: "og_axe", format: v => formatAxis(v)},
    OG_ADDITION: {token: "og_addition", format: v => formatAdd(v)},
    OG_LONGSIGTH: {token: "og_av_loin", format: v => formatAvLoin(v)},
    OG_SHORTSIGHT: {token: "og_parinaud", format: v => v},
    OG_STENOPEIC_HOLE: {token: "og_av_trou_stenopeique", format: v => v},
}

export const MesureTypeTokens = {
    LONG_SIGHTED_GLASSES: {token: "lun_loin", title: TypesMesures.LONGSIGHT_GLASSES},
    SHORT_SIGHTED_GLASSES: {token: "lun_pres", title: TypesMesures.SHORTSIGHT_GLASSES},
    PROGRESSIVE_GLASSES: {token: "lun_progr", title: TypesMesures.PROGRESSIVE_GLASSES},
    LENSE_METER: {token: "lm", title: TypesMesures.LENSE_METER},
    SKIASCOPIE: {token: "skia", title: TypesMesures.SKIASCOPIE},
    AUTOREF: {token: "autoref", title: TypesMesures.AUTOREF},
    REF_SUBJ: {token: "ref_subj", title: TypesMesures.REF_SUBJ},
    FINAL_REF: {token: "ref_fin", title: TypesMesures.FINALE_REF},
    NO_SHORTSIGHT_CORRECTION: {token: "sans_pres", title: TypesMesures.NO_SHORTSIGHT_CORRECTION},
    NO_LONGSIGHT_CORRECTION: {token: "sans_loin", title: TypesMesures.NO_LONGSIGHT_CORRECTION},
    NO_CORRECTION: {token: "sans_correc", title: TypesMesures.NO_CORRECTION},
}

export const ReportTokens = {
    ...(Object.entries(MesureTypeTokens).reduce((acc, [mesureTypeToken_key, mesureTypeToken_value]) => {
        Object.entries(MesureTokens).forEach(([mesureToken_key, mesureToken_value]) => {
            acc[`${mesureTypeToken_key}_${mesureToken_key}`] = {
                token: `!${mesureTypeToken_value.token}_${mesureToken_value.token}!`,
                value: mesureToken_value.token,
                format: mesureToken_value.format,
                mesure_type: mesureTypeToken_value.title
            }
        })
        return acc
    }, {}))
}

const formatAxis = (value) => {
    if (!value) return value
    return value.toString() + "°"
}

const formatAdd = (value) => {
    if (!value) return value
    return value.toFixed(2)
}

const formatAvLoin = (value) => {
    if (!value) return value
    const floatValue = parseFloat(value);
    if (value.match(/f/i)) {
        return value + "/10"
    }
    if (parseFloat(value)) {
        return (floatValue * 10).toString() + "/10";
    }
    return value
}

