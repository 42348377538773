import React, {useEffect, useState} from "react";
import axios from "axios";
import {Link,useParams} from "react-router-dom";
import Modal from '@material-ui/core/Modal';


import {toast} from "react-toastify";
export function TeamCabinets(){
    const [cabinets, setCabinets] = useState([]);

    useEffect(() => {
        loadCabinets();
    }, []);
    const loadCabinets = () => {
        axios.get("/admin/console/team/cabinets").then((response) => {
            setCabinets(response.data);
        });
    }
    return (
        <div style={{marginLeft: "25px"}}>
            <h1 className="center">Gestion de l'équipe associé à un cabinet</h1>
            <table className="striped centered responsive-table">
                <thead>
                <tr>
                    <th> id</th>
                    <th>Cabinet</th>
                    <th>Localisation</th>
                    <th>Ophtalmologiste maître</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {cabinets.map((cabinet) => {
                    return (
                        <tr key={cabinet.id}>
                            <td>{cabinet.id}</td>
                            <td>{cabinet.titre}</td>
                            <td>{cabinet.localisation}</td>
                            <td>{cabinet.ophtalmologiste.prenom} {cabinet.ophtalmologiste.nom}</td>
                            <td>
                                <Link to={`/team/cabinets/${cabinet.id}`} className="btn-floating btn-small waves-effect waves-light blue"><i className="material-icons">edit</i></Link>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>

        </div>
    )
}

export function TeamCabinetsEdit(){
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    const [cabinet, setCabinet] = useState({});
    const [masterOphtalmologist, setMasterOphtalmologist] = useState({});//[id, nom, prenom]
    const [ophtalmologists, setOphtalmologists] = useState([]);
    const [availableOphtalmologists, setAvailableOphtalmologists] = useState([]);
    const [open, setOpen] = useState(false);
    const [ophtalmologistToAdd, setOphtalmologistToAdd] = useState(0);//id
    const {id} = useParams();
    useEffect(() => {
        loadOphtalmologists();
        loadAvailableOphtalmologists()
    }, []);
    const loadOphtalmologists = () => {
        axios.get("/admin/console/team/cabinets/details/"+id).then((response) => {
            setCabinet(response.data.cabinet);
            setMasterOphtalmologist(response.data.master);
            setOphtalmologists(response.data.ophtalmologists);
        });
    }
    const loadAvailableOphtalmologists = () => {
        axios.get("/admin/console/team/cabinets/available/"+id).then((response) => {
            setAvailableOphtalmologists(response.data);
        });
    }
    const handleOpen = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    }
    const handleSave = () => {
        axios.post("/admin/console/team/cabinets/add", {cabinet_id: id, ophtalmologist_id: ophtalmologistToAdd}).then((response) => {
            if(response.data.success){
                toast.success("Ophtalmologiste ajouté avec succès");
                loadOphtalmologists();
                loadAvailableOphtalmologists();
                handleClose();
            }else{
                toast.error("Une erreur est survenue");
            }
        });
    }
    const handleDelete = (ophtalmologist_id) => {
        axios.put("/admin/console/team/cabinets/remove", {cabinet_id: id, ophtalmologist_id: ophtalmologist_id}).then((response) => {
            if(response.data.success){
                toast.success("Ophtalmologiste supprimé avec succès");
                loadOphtalmologists();
                loadAvailableOphtalmologists();
            }else{
                toast.error("Une erreur est survenue");
            }
        });
    }
    return(
        <div>
            <h1 className="center">Gestion de l'équipe associé au cabinet {cabinet.titre}</h1>
            <div className="row">
                <p className="center">ce cabinet est géré par {masterOphtalmologist.prenom} {masterOphtalmologist.nom} ({masterOphtalmologist.id})</p>
            </div>
            <div className="row">
                <p style={{marginLeft: "15px"}}>
                    <a className={"btn-floating btn-small waves-effect waves-light green"} onClick={(e) => {
                        e.preventDefault();
                        handleOpen();
                    }}>
                        <i className="material-icons left">add</i> Ajouter un ophtalmologiste
                    </a>
                </p>
                <table className="striped bordered">
                    <thead>
                        <tr>
                            <th>id</th>
                            <th>Nom</th>
                            <th>Prénom</th>
                            <th>Email</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    {ophtalmologists.map((ophtalmologist) => {
                        return (
                            <tr key={ophtalmologist.id}>
                                <td>{ophtalmologist.id}</td>
                                <td>{ophtalmologist.nom}</td>
                                <td>{ophtalmologist.prenom}</td>
                                <td>{ophtalmologist.email}</td>
                                <td>
                                    <a className={"btn-floating btn-small waves-effect waves-light red"} onClick={(e) => {
                                        e.preventDefault();
                                        handleDelete(ophtalmologist.id);

                                    }}><i className="material-icons">delete</i></a>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="Ajouter un ophtalmologiste"
                aria-describedby="Ajouter un ophtalmologiste"
            >
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 600,
                    background: "white",
                    border: '0px solid #000',
                    padding: "20px"

                }}>
                    <h4 className="center">Ajouter un ophtalmologiste</h4>
                    <div className="row">
                        <div className="input-field col s12">
                            <select className="browser-default" onChange={(e) => {
                                setOphtalmologistToAdd(e.target.value);

                            }}>
                                <option value="" disabled selected>Choisir un ophtalmologiste</option>
                                {availableOphtalmologists.map((ophtalmologist) => {
                                    return (
                                        <option key={ophtalmologist.id} value={ophtalmologist.id}>{ophtalmologist.nom} {ophtalmologist.prenom}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12">
                            <a className="btn waves-effect waves-light" onClick={(e) => {
                                e.preventDefault();
                                handleSave();
                            }}>
                                <i className={"material-icons left"}>done</i>
                                Valider</a>
                        </div>
                    </div>
                </div>
            </Modal>

        </div>
    )

}