import React from 'react';
import MesureOverview from "../MesureOverview";
import {Mesures, TypesMesures} from "../../../../Shared/Enums/Enums";
import PrescriptionHistory from "./PrescriptionHistory";
import BrandedElement from "../../../../Shared/BrandedElement";

function AppointmentHistory({appointment}) {
    const [expanded, setExpanded] = React.useState(false);

    const renderAnamnesis = () => {
        if (!appointment.comment) return <h6><b>Anamnèse :</b> aucune</h6>;
        return <div>
            <h6 style={{fontWeight: "bolder"}}>Anamnèse :</h6>
            <h6>{appointment.comment}</h6>
        </div>
    }

    const renderPrivateComment = () => {
        if (!appointment.private_comment) return <h6><b>Commentaire privé :</b> aucun</h6>;
        return <div>
            <h6 style={{fontWeight: "bolder"}}>Commentaire privé :</h6>
            <h6>{appointment.private_comment}</h6>
        </div>
    }
    const renderOrthopticText = () => {
        if (!appointment.orthoptic_text) return <h6><b>Orthoptie :</b> aucune</h6>;
        return <BrandedElement temeoo>
            <div>
                <h6 style={{fontWeight: "bolder"}}>Orthoptie :</h6>
                <h6>{appointment.orthoptic_text}</h6>
            </div>
        </BrandedElement>
    }

    const renderMeasures = () => {
        return <div>
            <h6 style={{fontWeight: "bolder"}}>Mesures :</h6>
            <table>
                <tbody>
                {appointment.mesures.map(mesure => {
                    let type = ""
                    if (mesure.typedemesure.titre === TypesMesures.TENSION) type = Mesures.TENSION.value
                    if (mesure.typedemesure.titre === TypesMesures.PACHYMETRY) type = Mesures.PACHYMETRY.value
                    return <tr>
                        <td style={{fontSize: 12, padding: 0}}>{mesure.typedemesure.titre}</td>
                        <td style={{fontSize: 12, padding: 5}}><MesureOverview mesure={mesure} type={{name: type}}/>
                        </td>
                    </tr>

                })}
                </tbody>
            </table>
        </div>
    }

    const renderPrescriptions = () => {
        return <div style={{display: "flex", flexDirection: "column", gap: 10}}>
            {appointment.prescriptions.map((prescription, index) => {
                if (!prescription?.text) return null;
                return <div key={`prescription_${appointment.id}_${index}`}>
                    <PrescriptionHistory prescription={prescription}
                                         number={appointment.prescriptions.length > 1 ? index + 1 : ""}/>
                </div>
            })}
        </div>
    }

    const renderDetails = () => {
        if (!expanded) return null;
        return <div style={{display: "flex", flexDirection: "column", fontSize: 14}}>
            {renderAnamnesis()}
            {renderPrivateComment()}
            {renderOrthopticText()}
            {renderMeasures()}
            {renderPrescriptions()}
        </div>
    }

    return <div className="blue lighten-5" style={{borderRadius: 4, border: "thin solid #1976d2", padding: 10}}>
        <div className="valign-wrapper clickable" onClick={() => setExpanded(!expanded)}>
            <h6 style={{fontWeight: "bold"}}>Examen du : {Helper.formatDate(appointment.start)}</h6>
            <a className="btn-small btn-flat transparent" style={{marginLeft: "auto"}}>
                <i className={`material-icons ${expanded ? "flipped" : ""}`}
                   onClick={() => setExpanded(!expanded)}>expand_more</i>
            </a>
        </div>
        {renderDetails()}
    </div>
}

export default AppointmentHistory;