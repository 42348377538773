import {ALERT_COLORS} from "./AlertColorEnum";
import React from "react";
import Helper from "../../Shared/Helper";
import axios from "axios";
import Tootip from "../../Shared/Tootip";

export default function AlertCard(props) {

    function deleteNotification(e) {
        e.preventDefault()
        axios.delete(`/notifications/delete/${props.notification.id}`).then((resp) =>
            props.handleNotificationDeletion(parseInt(resp.data.id))
        )
    }

    return (
        <div className="card"
             style={{borderLeft: `solid 20px ${ALERT_COLORS[props.notification.alert.gravity]}`, marginBottom: "30px"}}>
            <i className="material-icons-outlined"
               style={{cursor: "pointer", position: "absolute", top: "5px", right: "5px"}}
               onClick={deleteNotification}>delete</i>
            <div className="card-content" style={{paddingBottom: "0px"}}>
                <div className="row">
                    <div className="col s3">
                        <p><a href={`/patients/${props.notification.patient.id}`}>
                            {Helper.formatName(props.notification.patient.first_name, props.notification.patient.last_name)}
                        </a></p>
                        {Helper.formatPhoneNumber(props.notification.patient.phone) || "Téléphone inconnu"}<br/>
                        Le {Helper.formatDate(props.notification.alert.created_at)}<br/>
                        Gravité: {Helper.toCamelCase(props.notification.alert.gravity)}<br/>
                        {props.notification.patient.email || "Email inconnu"} <br/>
                        Né(e): {Helper.formatDate(props.notification.patient.birthday)} ({Helper.dateToAge(props.notification.patient.birthday)} ans)
                    </div>
                    <div className="col s3" style={{display: "flex", flexDirection: "column"}}>
                        {props.notification.appointment.pathologies.length ?
                            <div>
                                <p><b>Pathologie(s) soupçonnée(s): </b></p>
                                <ul>
                                    {props.notification.appointment.pathologies.map((pathology, index) =>
                                        <li key={index}> - {pathology.pathologie}</li>)}
                                </ul>
                            </div>
                            : <p style={{textAlign: "center"}}>Aucune pathologie soupçonnée</p>
                        }

                        {props.notification.appointment.type === "alert" ?
                            <a className="btn blue darken-2 z-depth-0"
                               href={`/syntheses/rendez-vous/${props.notification.appointment.id}`}
                               style={{width: "fit-content", alignSelf: "center", marginTop: "10px"}}
                            >Synthèse du RDV</a>
                            : <p style={{marginTop: "15px"}}>RAS, l'ordonnance a été libérée</p>
                        }
                    </div>
                    <div className="col s3" style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        {props.notification.need_to_pay ?
                            <div className="center" style={{display: "flex", alignItems: "center"}}>
                                <i className="material-icons-outlined">warning</i>En attente de paiement.
                            </div> : null}
                        <br/>
                        <div className="center">
                            Compte rendu:<br/>
                            <div>
                                <Tootip id={`alert_card_orthoptist_oph_${props.notification.id}`} text="Compte-Rendu pour ophtalmologiste" position="bottom">
                                    <a className="btn-small blue darken-2 z-depth-0"
                                       target="_blank"
                                       href={props.notification.report_doctor}
                                       style={{margin: "2px", width: "100%"}}>
                                        OPH referent</a>
                                </Tootip>
                                {props.notification.report.allow_report?
                                    <Tootip id={`alert_card_orthoptist_doctor_${props.notification.id}`} text="Compte-Rendu pour médecin traitant" position="bottom">
                                        <a className="btn-small blue darken-2 z-depth-0"
                                           target="_blank"
                                           href={props.notification.report.report_link}
                                           style={{margin: "2px", width: "100%"}}>Prescripteur</a>
                                    </Tootip>
                                    :null
                                }
                            </div>
                        </div>

                    </div>
                    <div className="col s3">
                        {props.notification.alert.text ?
                            <div>
                                Commentaire:<br/>
                                <p>{props.notification.alert.text}</p>
                            </div>
                            : <div>Pas de commentaire</div>
                        }
                        <div className="divider"
                             style={{marginTop: "5px", marginBottom: "5px", backgroundColor: "#616161"}}/>
                        {props.notification.alert.risks ?
                            <div>
                                Risque(s):<br/>
                                <p>{props.notification.alert.risks}</p>
                            </div>
                            : <div>Pas de risque</div>
                        }
                        <div className="divider"
                             style={{marginTop: "5px", marginBottom: "5px", backgroundColor: "#616161"}}/>
                        Examen(s) à faire: <br/>
                        {JSON.parse(props.notification.alert.todo).join(", ")}
                    </div>
                </div>
            </div>

        </div>
    )
}