export const AlertTypes = {
    UNREAD: {
        type: "unread",
        title: "Non lues",
    },
    TO_RECONVOKE: {
        type: "to_reconvoke",
        title: "À reconvoquer",
    },
    RECONVOKED: {
        type: "reconvoked",
        title: "Reconvoqué"
    }
}