import React, { Component } from 'react';
import axios from "axios"
//import {Annotator} from 'image-labeler-react';

export default class EditAnalyseImage extends Component {
    constructor(props){
        super(props);
        this.imageID = this.imageID.bind(this);
        this.imagetag = this.imagetag.bind(this);
        this.handleClick = this.handleClick.bind(this);

        let data = this.props.edit_data
        let string_box = data.boxes
        let boxes = JSON.parse(string_box)
        let id = data.analysed_id

        this.state = {
            images: data.images,
            boxes: boxes,
            analyse_image: id,
            loading: false,
            response: [],
            alerte_id: data.alerte_id
        }
    }

    handleClick(e){
        let action = e.target.dataset.action
        let image_id = e.target.dataset.image_id
        let response = [...this.state.response,{image_id: image_id,etat: action}]
            axios.post("/analystes/images/update",{
                alerte_id: this.state.alerte_id,
                analyse_id: this.state.analyse_image,
                reponse: response
            }).then(
                window.location.href = '/analystes/images/all'
            )

    }
    imageID(){
        if(this.state.images.length>0){
            return(this.state.images[0].id)
        }else{
            return(-1)
        }
    }
    imagetag(){
        if(this.state.images.length>0){
            return(
                <div>
                    {/*
                                        <Annotator
                        height={600}
                        width={600}
                        imageUrl={this.state.images[0].url}
                        defaultBoxes={this.state.boxes}
                        asyncUpload={async (labeledData)=>{
                            let image_id = this.state.images[0].id
                            let action = "alerte"
                            let response = [...this.state.response,{
                                image_id: image_id,
                                etat: action,
                                boxes: labeledData.boxes,
                                height: labeledData.height,
                                width: labeledData.width}
                            ]
                                axios.post("/analystes/images/update",{
                                    alerte_id: this.state.alerte_id,
                                    analyse_id: this.state.analyse_image,
                                    reponse: response
                                }).then(
                                    window.location.href = '/analystes/images/all'
                                )
                            }
                        }
                        types={["glaucome","lésion","kérato"]}
                    /> */}
                </div>
            )
        }
    }



    render() {
        return (
            <div>
                <div className="container">
                    <div className="center">
                        {this.imagetag()}
                    </div>
                    {this.state.loading || this.state.images.length<=0 ? (
                        <div className='row'>
                            <p className="center">Chargement en cours</p>
                        </div>
                    ) : (
                        <div className='row' style={{margin:20}}>
                            <div className="col s3 center">
                                <a onClick={this.handleClick}
                                   data-action="indefini"
                                   data-image_id={this.imageID()}
                                   className="btn orange"> INDEFINI</a>
                            </div>


                            <div className="col s3 center">
                                <a onClick={this.handleClick}
                                   data-action="ok"
                                   data-image_id={this.imageID()}
                                   className="btn green"> OK</a>
                            </div>

                            <div className="col s3 center">
                                <form action="/analystes/images/destroy" method="post">
                                    <button type="submit" name={"data_image"} value={this.state.analyse_image} className="btn red">Supprimer</button>
                                </form>
                            </div>

                            <div className="col s3 center">
                                <a onClick={this.handleClick}
                                   data-action="inutile"
                                   data-image_id={this.imageID()}
                                   className="btn black"> INUTILE</a>
                            </div>
                        </div>
                        
                    )}
                </div>
            </div>
        );
    }
}
