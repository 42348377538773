import React from "react";
import Helper from "../Shared/Helper";

function DisplayFromCV(props) {
    return (
        <div>
            <p style={{marginTop: 0}}>Les patients suivants ont été trouvés avec la carte vitale</p>
            <ul className="collection" style={{borderRadius: "20px"}}>
                {props.data?.map((patient, index) => {
                    return (
                        <a key={index}
                           className="collection-item black-text"
                           onClick={() => props.onClick(patient)}
                        >
                            <div className="row" style={{margin: 0}}>
                                <div className="col s4">
                                    <h4 className="title" style={{margin: 0}}>
                                        {Helper.toCamelCase(patient.sPrenomPatient)} {patient.sNomUsuelPatient.toUpperCase()}
                                    </h4>
                                </div>
                                <div className="col s4">
                                    <h4 style={{margin: 0}}>{patient.dDateDeNaissance}</h4>
                                </div>
                                <div className="col s4">
                                    <h4 style={{margin: 0}}>{`AMO: ${patient.sCouvAMO}, AMC: ${patient.sCouvAMC}`}</h4>
                                </div>
                                <div className="col s4">
                                    <h4 style={{margin: 0}}>Code : {props.meta ? `${props.meta.sCodeRegime} ${props.meta.sCodeCaisse} ${props.meta.sCodeCentre}` : ""}</h4>
                                </div>

                            </div>
                        </a>
                    )
                })
                }
            </ul>
        </div>
    )
}

DisplayFromCV.defaultProps = {
    data: [],
}

export default DisplayFromCV;