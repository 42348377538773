import React, {useEffect, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import Modal from '@material-ui/core/Modal';


export default function PossibleExamens() {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    const [examens, setExamens] = useState([])
    const [open, setOpen] = useState(false);
    const [examenToAdd, setExamenToAdd] = useState("")

    useEffect(() => {
        loadExamens()
    }, [])
    const loadExamens = () => {
        axios.get("/admin/possible_examens/examens").then((data) => {
            setExamens(data.data.examens)
        })
    }
    const handleOpen = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    }

    function handleSave() {
        axios.post("/admin/possible_examens/examen", {title: examenToAdd}).then((response) => {
            if (response.data.success) {
                toast.success("Examen ajouté avec succès");
                loadExamens();
                handleClose();
            } else {
                toast.error("Une erreur est survenue");
                loadExamens();

            }
        });
    }

    return (
        <div className="container">
            <h1 className="center" id="main-title">Examens possibles</h1>
            <p className="center"> Vous pouvez supprimer un examen même si il a été utilisé, cela n'a aucun impact sur
                les dossiers</p>
            <p><a onClick={(e) => {
                e.preventDefault()
                handleOpen()
            }} className="btn blue darken-2 z-depth-0">Ajouter un examen</a></p>
            <table className="striped bordered">
                <thead>
                <tr>
                    <th>Nom</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {examens.map((examen, index) => {
                    return (
                        <tr key={index}>
                            <td>{examen.title}</td>
                            <td>
                                <a className="btn red darken-2 z-depth-0" onClick={(e) => {
                                    e.preventDefault();
                                    axios.delete("/admin/possible_examens/examen/" + examen.id).then((response) => {
                                        if (response.data.success) {
                                            toast.success("Examen supprimé avec succès");
                                            loadExamens();
                                        } else {
                                            toast.error("Une erreur est survenue");
                                            loadExamens();

                                        }
                                    });
                                }}>
                                    <i className="material-icons">delete</i>
                                </a>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="Ajouter un examen possible"
                aria-describedby="Ajouter un examen possible"
            >
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 600,
                    background: "white",
                    border: '0px solid #000',
                    padding: "20px"

                }}>
                    <h4 className="center">Ajouter un examen</h4>
                    <div className="row">
                        <div className="input-field col s12">
                            <input id="name" type="text" className="validate" value={examenToAdd} onChange={(e) => {
                                setExamenToAdd(e.target.value);
                            }}/>
                            <label htmlFor="name">Nom de l'examen</label>
                        </div>
                    </div>
                    <br/>
                    <a className="btn blue darken-2 z-depth-0" onClick={(e) => {
                        e.preventDefault();
                        handleSave();
                        handleClose()
                    }}>
                        <i className={"material-icons left"}>done</i>
                        Valider</a>
                </div>
            </Modal>
        </div>
    )
}
