import React from "react";
import {IS_OPTICIAN} from "./Helper";

// Children will be displayed considering props
function BrandedElement(props) {
    if(props.bypass) return props.children
    if(props.optician && IS_OPTICIAN) return props.children
    else if (props.temeoo && !IS_OPTICIAN) return props.children
    return null
}

BrandedElement.defaultProps = {
    optician: false,
    temeoo: false,
    bypass: false
}

export default BrandedElement