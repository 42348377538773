import React, {Fragment} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import LogDisplay from "../../Logs/LogDisplay";
import {User} from "../../../../Shared/Enums/Enums";

function Extern({externs}) {
    const {id} = useParams();
    const extern = externs.find((extern) => extern.id === parseInt(id))
    const nav = useNavigate();

    return <Fragment>
        <div className="valign-wrapper" style={{marginLeft: 10}}>
            <a className="btn-flat" style={{borderRadius: 20}} onClick={() => nav("/externs")}>
                <i className="material-icons">arrow_back</i>
            </a>
            <h3 className="valign-wrapper" style={{margin: 20}}><i className="material-icons left">alt_route</i>
                Gestion de {Helper.toCamelCase(extern.fullname)}
            </h3>
        </div>
        <div className="divider"/>
        <div className="row" style={{padding: 20, gap: 10, minHeight: 400}}>
            <div className="col s12 l6">
                <LogDisplay user_type={User.EXTERN.value} user={extern}/>
            </div>
        </div>
    </Fragment>
}

export default Extern;