import React from 'react';
import UserStatuses from "./UserStatuses";

function UserStatusesConsole() {
    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            <h3 className="valign-wrapper" style={{margin: 20}}><i className="material-icons left">flag</i>
            Etat des configurations</h3>
            <div className="divider"/>
            <div style={{ margin: 20}}>
                <UserStatuses/>
            </div>
        </div>
    );
}

export default UserStatusesConsole;