import React, {useState} from 'react';
import Error from "../Shared/Error";
import Helper from "../Shared/Helper";
import DocumentTile from "./DocumentTile";

function DocumentViewer({documents, metadata}) {
    const [selectedPdfBlob, setSelectedPdfBlob] = useState(null);

    const renderPDF = () => {
        if (!selectedPdfBlob) {
            return <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    gap: 10,
                    paddingTop: 0
                }}>
                <i className="material-icons large">pageview</i>
                <h5>Sélectionner un document à prévisualiser</h5>
            </div>
        }

        return <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "100%",
                gap: 10,
                paddingTop: 0
            }}>
            <iframe id="pdf-frame" src={window.URL.createObjectURL(selectedPdfBlob)}
                    title="pdf-viewer"
                    style={{height: "100%", width: "100%"}}/>
        </div>
    }

    if (metadata?.errorCode) {
        const default_message = "Vous n'avez pas le droit d'accéder à ce document."
        return <div id="full-height" style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 50
        }}>
            <Error errorText={metadata.errorPrompt || default_message}/>
            <div>
                <a className="btn blue darken-2 z-depth-0" onClick={() => window.history.back()}>
                    <i className="material-icons left">arrow_back</i>
                    Retour
                </a>
            </div>
        </div>
    }

    const appointment = metadata?.appointment;
    const orthoptist = appointment?.orthoptiste;
    const analyst = appointment?.analyst;
    const ok = appointment?.status_controle === 2

    const render_metadata = () => {
        if (!appointment) return null;
        return <li className="collection-item">
            <div style={{margin: 20, fontWeight: "bold"}}>
                <h5 style={{margin: 0, fontWeight: "bold"}}>
                    {appointment ? `Rendez-vous du ${Helper.formatDate(appointment.start)}` : ""}
                    {orthoptist ? ` avec ${Helper.formatName(orthoptist.prenom, orthoptist.nom)}` : ""}</h5>
                <h5
                    className={appointment.status_controle > 1 ? "valign-wrapper" : "hide"}
                    title={ok ? "Le document a été validé par l'ophtalmologiste" : "Le document n'a pas été validé par l'ophtalmologiste"}
                >
                    <i className={`material-icons left ${ok ? "green" : "red"}-text text-darken-2`}>{ok ? "done" : "error"}</i>
                    {`analysé par ${Helper.formatName(analyst.prenom, analyst.nom)}`}
                </h5>
            </div>
        </li>
    }

    const render_return_button = () => {
        const fallback_url = metadata?.fallback_url;
        if (!fallback_url) return null;
        return <a className="btn-flat" style={{margin: 10}} href={fallback_url}>
            <i className="material-icons left">arrow_back</i>
            Retour
        </a>
    }

    return (
        <div id="full-height" style={{width: "100%"}}>
            <div className="" style={{height: "100%"}}>
                <div className="row" style={{height: "100%"}}>
                    <div className="col xl3 m12 s12" style={{boxShadow: "2px 1px 0 0 #E4E4E4"}}>
                        {render_return_button()}
                        <ul className="collection" style={{borderColor: "transparent"}}>
                            {render_metadata()}
                            {documents.map((document, index) => {
                                return (
                                    <li id="hoverable" className="collection-item" key={index}
                                        style={{width: "100%"}}>
                                        <DocumentTile doc={document} metadata={metadata}
                                                      mobile={window.innerWidth < 992.99}
                                                      onPdfSelect={(blob) => setSelectedPdfBlob(blob)}/>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    <div className="col xl9 m12 s12 hide-on-med-and-down center">
                        {renderPDF()}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DocumentViewer;