import React from "react";
import Helper from "../../Shared/Helper";
import Tooltip from "../../Shared/Tootip";
import {Act} from "../../Shared/Enums/Enums";


export default function DocumentsList(props) {

    function renderReportButton() {
        const allowed_reports = [Act.RNM, Act.AMY8_5, Act.AMY_15, Act.AMY6_7, Act.AMY8_5_AMY_6_7_2, Act.AMY8_AMY_6_7_2, Act.AMY8_AO]
        if (allowed_reports.includes(props.act?.titre) && props.appointment.status_controle > 1) {
            return <a className="btn-small blue darken-2 z-depth-0"
                      title="CR Oph référent"
                      style={{width: "100%"}}
                      href={props.appointment ? `/patients/rapports/rapports?appointment_id=${props.appointment.id}&patient_id=${props.patient_id}` : ""}>
                <span>CR Oph référent</span>
            </a>
        } else {
            return <a className="btn-small blue darken-2 z-depth-0 disabled"
                      style={{width: "100%"}}>
                <span>CR Oph référent</span>
            </a>
        }
    }

    function renderRecapButton() {
        if (props.appointment.recap_link) {
            return <a className="btn-small blue darken-2 z-depth-0"
                      title="CR Prescripteur"
                      style={{width: "100%"}}
                      href={props.appointment && props.appointment.recap_link ? props.appointment.recap_link : ""}>
                <span>CR Prescripteur</span>
            </a>
        } else {
            return <a className="btn-small blue darken-2 z-depth-0 disabled"
                      style={{width: "100%"}}>
                <span>CR Prescripteur</span>
            </a>
        }
    }

    function renderSyntheseButton() {
        if (props.act) {
            return <a className="btn-small blue darken-2 z-depth-0"
                      title={`Voir la synthèse de l'examen`}
                      href={props.appointment ? `/syntheses/rendez-vous/${props.appointment.id}` : ""}
                      style={{width: "100%"}}>
                <span className="hide-on-med-and-down">Synthèse</span>
            </a>
        } else {
            return <a className="btn-small blue darken-2 z-depth-0 disabled"
                      style={{width: "100%"}}>
                <span>Synthèse</span>
            </a>
        }
    }

    function renderMenu() {
        if (props.appointment) {
            return <div
                style={{display: "flex", flexDirection: "column", gap: "0.75vh", height: "100%", marginTop: "2vh"}}>
                <div style={{margin: "0 auto", width: "90%"}}>{renderSyntheseButton()}</div>
                <div style={{margin: "0 auto", width: "90%"}}>{renderReportButton()}</div>
                <div style={{margin: "0 auto", width: "90%"}}>{renderRecapButton()}</div>
            </div>
        } else {
            return <div
                style={{display: "flex", flexDirection: "column", gap: "0.75vh", height: "100%", marginTop: "2vh"}}>
                <div style={{width: "90%", margin: "0 auto"}}>
                    <a style={{width: "100%"}}
                       className="btn-small blue darken-2 z-depth-0 disabled center truncate"><span>Synthèse</span></a>
                </div>
                <div style={{width: "90%", margin: "0 auto"}}>
                    <a style={{width: "100%"}} className="btn-small blue darken-2 z-depth-0 disabled center truncate"><span>CR Oph référent</span></a>
                </div>
                <div style={{width: "90%", margin: "0 auto"}}>
                    <a style={{width: "100%"}} className="btn-small blue darken-2 z-depth-0 disabled center truncate"><span>CR Prescripteur</span></a>
                </div>
            </div>
        }
    }

    return (
        <div style={props.style} className="card z-depth-1">
            <div className="center"
                 style={{
                     display: "flex",
                     justifyContent: "center",
                     alignItems: "center",
                     height: "20%",
                     margin: "1vh 1vw"
                 }}>
                Documents
                associés {props.appointment ? `au RDV du ${Helper.formatDate(props.appointment.start, "DD/MM/YYYY à hh:mm")}` : ""}
            </div>
            {renderMenu()}
        </div>
    )
}