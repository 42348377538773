import {useEffect, useState} from "react";

export const usePagination = (total, pageSize, onChange, initialPage = 1) => {
    const [page, setPage] = useState(initialPage);
    const [totalPage, setTotalPage] = useState(Math.ceil(total / pageSize));

    useEffect(() => {
        setTotalPage(Math.ceil(total / pageSize));
    }, [total, pageSize]);

    const changePage = (offset) => () => {
        setPage(prevPage => prevPage + offset);
    };

    useEffect(() => {
        onChange(page);
    }, [page]);

    // New function to reset the page
    const resetPage = () => {
        setPage(1);
    };

    return {
        page,
        totalPage,
        setNextPage: changePage(1),
        setPrevPage: changePage(-1),
        setFirstPage: changePage(1 - page),
        setLastPage: changePage(totalPage - page),
        isFirstPage: page === 1,
        isLastPage: page === totalPage,
        resetPage
    };
}
