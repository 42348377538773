import React from "react";

function LastPrescription(props) {

    const prescriptions = props.appointments.map((appointment)=>{
        if(appointment.prescription_id){
            return {prescription_text: appointment.prescription_text, created_at: appointment.prescription_created_at, id: appointment.prescription_id}
        }
    }).sort((a,b)=> {
        return new Date(b.created_at) - new Date(a.created_at)
    })

    return (
        <div className="card" style={props.style}>
            <h2 className="center" style={{marginTop: 0, marginBottom: 20}}>3 Dernières prescriptions</h2>
            <hr/>
            <div className='row' style={{margin: 20}}>
                {props.appointments.length > 0 ? (
                    prescriptions.slice(0, 3).map((prescription, index) => {
                        if(!prescription) return null
                        return <div key={index} className="col s4"
                                 style={{display: "flex", alignItems: "center", flexDirection: "column", gap: 20}}>
                                Le {prescription.created_at.split('T')[0]}
                                <div>
                                    {prescription == null || prescription.prescription_text.trim() === "" ? (
                                        <p className="center">Prescription vide</p>
                                    ) : (
                                        <div style={{textAlign: "left", justifySelf: "center"}}>
                                            {prescription.prescription_text.split('\n').map((str, index) => <p
                                                key={index}>{str}</p>)}
                                        </div>
                                    )}
                                </div>
                            </div>

                    })
                ) : (
                    <div className="col s12">
                        <p className="center" style={{ marginTop: '45px', marginBottom: 0}}>Aucune prescription trouvée.</p>
                    </div>
                )}
            </div>
        </div>
    )
}

export default LastPrescription