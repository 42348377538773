import React, {useEffect} from 'react';
import Loader from "../../../Shared/Loader";
import {RequestState} from "../../../Shared/StateHelper";
import Error from "../../../Shared/Error";
import axios from "axios";
import Helper from "../../../Shared/Helper";

const _ = require('lodash');

function ScheduledAppointments() {
    const [appointments, setAppointments] = React.useState([]);
    const [status, setStatus] = React.useState(RequestState.LOADING);

    useEffect(() => {
        get_appointments();
    }, []);

    const get_appointments = () => {
        axios.get("/admin/console/appointments/scheduled")
            .then(res => {
                setAppointments(res.data.appointments);
                setStatus(RequestState.SUCCESS);
            })
            .catch(err => {
                setStatus(RequestState.ERROR);
            })
    }

    if (status === RequestState.LOADING) return <div style={{marginTop: 20}}><Loader text=""/></div>
    if (status === RequestState.ERROR) return <div style={{marginTop: 20}}><Error
        errorText="Impossible de charger les examens"/></div>

    if (_.isEmpty(appointments)) return (
        <div className="container">
            <h4 className="alert-heading">Aucun examen programmé</h4>
            <p>Il n'y a aucun examen programmé pour le moment.</p>
        </div>)

    return (
        <div className="container">
            <div className="collection" style={{borderRadius: "20px"}}>
                {appointments.map((appointment) => {
                    return <div key={appointment.id} className="collection-item" style={{display: "flex"}}>
                        <div className="row" style={{width: "100%", margin: 10, marginBottom: 10}}>
                            <div className="col xl3">
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    margin: "0 10px",
                                    fontWeight: "bold"
                                }}>
                                    <span>{Helper.formatName(appointment.patient_firstname, appointment.patient_lastname)}</span>
                                    <span>{Helper.dateToAge(appointment.patient_birthday)} ans</span>
                                </div>
                            </div>
                            <div className="col xl3">
                                {appointment.patient_phone_number ?? "Aucun numéro de téléphone"}
                            </div>
                            <div className="col xl3">
                                <span style={{margin: "0 10px"}}>{Helper.formatDate(appointment.start)}</span>
                            </div>
                            <div className="col xl3">
                                <span style={{margin: "0 10px"}}>{appointment.office_name}</span>
                            </div>
                        </div>
                    </div>
                })}
            </div>
        </div>
    )
}

export default ScheduledAppointments;