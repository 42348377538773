import React from "react";
import moment from "moment";
import ClinicalData from "./ClinicalData";
import Anamnesis from "./Anamnesis";

function PatientInfo({patient, nb_rdv, appointement, actepossible, orthoptist}) {
    const getBirthday = () => {
        const today = new Date()
        const patientBirthday = new Date(patient.birthday)

        const diffTime = Math.abs(today - patientBirthday)
        return Math.floor(diffTime / (1000 * 60 * 60 * 24 * 365))
    };

    return (
        <div style={{height: "100%", display: "flex", flexDirection: "column"}}>
            <div className="valign-wrapper" style={{justifyContent: "space-evenly", gap: 20, marginBottom: 20}}>
                <h2 className="valign-wrapper" style={{margin: 0, fontSize: "1em"}}>
                    <a
                        className="btn btn-small blue darken-2 z-depth-0"
                        style={{width: '50px', fontSize: '0.80vw', margin: "0 10px"}}
                        href={`/secretariat/email?patient=${patient.id}`}
                        title="Contacter le patient">
                        <i className="material-icons">mail</i>
                    </a>
                    <b>Synthèse du patient</b>
                </h2>
                <h2 className="center" style={{margin: 0, fontSize: "1em"}}><b>Commentaires orthoptiste</b></h2>
                <h2 className="center" style={{margin: 0, fontSize: "1em"}}><b>Données cliniques</b></h2>
            </div>
            <div className="card-panel blue lighten-5"
                 style={{
                     borderRadius: "0px 0px 30px 30px",
                     borderTop: "thick solid #32a1ce",
                     paddingTop: 0,
                     overflowY: "scroll",
                     margin: 0,
                     height: "100%"
                 }}>
                <div className="row" style={{margin: 20}}>
                    <div className="col s12 l4"  style={{display: "flex", flexDirection: "column", gap: 5}}>
                        <div className="valign-wrapper" style={{fontSize: "1em", paddingLeft: 0, gap: 5}}>
                            <i className="material-icons blue-text text-darken-2 small">person</i>
                            {patient.prenom.charAt(0).toUpperCase() + patient.prenom.slice(1)}&nbsp;
                            {patient.nom.toUpperCase()}
                        </div>
                        <div className="valign-wrapper center" style={{fontSize: "1em"}}>
                            {patient.email}
                        </div>
                        <div className="valign-wrapper center" style={{fontSize: "1em"}}>
                            {patient.telephone}
                        </div>
                        <div className="valign-wrapper center" style={{fontSize: "1em"}}>
                            {getBirthday()} ans
                        </div>
                        <div className="valign-wrapper center" style={{fontSize: "1em"}}>
                            Acte : {actepossible?.titre}
                        </div>
                        <div className="valign-wrapper" style={{fontSize: "1em", gap: 5}}>
                            <i className="material-icons blue-text text-darken-2 small">medical_services</i>
                            {nb_rdv} rendez-vous
                        </div>
                        <div className="valign-wrapper" style={{fontSize: "1em", paddingLeft: 0}}>
                            Montant du rendez-vous : {actepossible?.prix}€
                        </div>
                        <div className="valign-wrapper" style={{fontSize: "1em", paddingLeft: 0}}>
                            {!orthoptist ? <span style={{fontSize: "0.8em"}}>Cette consultation n'a pas d'orthoptiste référé.</span>
                                :
                                <span>Vu par {Helper.formatName(orthoptist.prenom, orthoptist.nom)} le {moment(appointement.start).format("DD-MM-YYYY")}</span>}
                        </div>
                    </div>
                    <div className="col s12 l5">
                        <hr className="hide-on-large-only"/>
                        <Anamnesis appointement={appointement}/>
                    </div>
                    <div className="col s12 l3">
                        <hr className="hide-on-large-only"/>
                        <ClinicalData appointement={appointement}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PatientInfo;