import React, {useEffect} from "react";
import DatePicker from "../../../Shared/DatePicker";
import Helper from "../../../Shared/Helper";
import axios from "axios";
import {downloadFile} from "../../../Shared/download";

function SuspiciousAppointments({orthoptists}) {
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);

    useEffect(() => {
        const select = document.getElementById('orthoptists_select');
        const select_instance = M.FormSelect.init(select, {});

        return () => {
            select_instance.destroy();
        }
    }, [orthoptists])

    const getSuspiciousAppointments = () => {
        const selectedOrthoptists = M.FormSelect.getInstance(document.getElementById('orthoptists_select')).getSelectedValues();
        axios.get("/orthoptistes/validator/suspicious", {
            params: {
                start_date: Helper.formatDate(startDate, 'YYYY-MM-DD'),
                end_date: Helper.formatDate(endDate, 'YYYY-MM-DD'),
                orthoptist_ids: selectedOrthoptists
            }
        }).then(response => downloadFile(response.data, 'examens_suspects.csv'));
    }

    const renderOrthoptistsSelect = () => {
        return <div className="input-field col s12">
            <select id={`orthoptists_select`} multiple>
                <option value="" disabled>Tous les orthoptistes</option>
                {orthoptists.map(orthoptist => {
                    return <option id='listado' key={orthoptist.id}
                                   value={orthoptist.id}>{Helper.formatName(orthoptist.prenom, orthoptist.nom)}</option>
                })}
            </select>
        </div>
    }

    return (
        <div>
            <div style={{marginBottom: 10}}>
                <h3 className="valign-wrapper" style={{margin: 20}}><i className="material-icons left">receipt</i>Télécharger l'historique
                    des rendez-vous</h3>
                <h6 className="grey-text text-darken-2"  style={{fontStyle: "italic", paddingLeft: "2vw", margin: 0}}>L'ensemble des informations des examens réalisés entre les dates (pas de date = pas de limite)</h6>
                <div className="valign-wrapper container" style={{justifyContent: "space-between", flexWrap: "wrap"}}>
                    {renderOrthoptistsSelect()}
                    <DatePicker id="bills_start_date" txt="Date de début" onChange={(date) => setStartDate(date)}/>
                    <DatePicker id="bills_end_date" txt="Date de fin" onChange={(date) => setEndDate(date)}/>
                    <div className="hide-on-small-only">
                        <a className="btn z-depth-0 blue darken-2" onClick={getSuspiciousAppointments}>Export CSV</a>
                    </div>
                    <div className="center hide-on-med-and-up" style={{width: "100%", marginTop: 10}}>
                        <a className="btn z-depth-0 blue darken-2" onClick={getSuspiciousAppointments}>Export CSV</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SuspiciousAppointments;