import React, {useEffect} from "react";
import {toast} from "react-toastify";
import Tooltip from "../../../../Shared/Tootip";

function NewBlockButton(props) {
    useEffect(() => {
        const elems = document.getElementById(`new_block_modal_${props.id}`);
        M.Modal.init(elems, {});
        return () => {
            const elems = document.getElementById(`new_block_modal_${props.id}`);
            if (elems) {
                const instance = M.Modal.getInstance(elems);
                if (instance) instance.destroy();
            }
        }
    }, [])

    const addHeader = () => {
        const newHeaderBlock = {id: Date.now(), type: "HEADER", data: {text: ""}}
        props.onValidation(newHeaderBlock)
    }

    const addText = () => {
        const newTextBlock = {id: Date.now(), type: "TEXT", data: {text: ""}}
        props.onValidation(newTextBlock)
    }

    const addInput = () => {
        const newInputBlock = {id: Date.now(), type: "INPUT", data: []}
        props.onValidation(newInputBlock)
    }

    const addFooter = () => {
        const newFooterBlock = {id: Date.now(), type: "FOOTER", data: {text: ""}}
        props.onValidation(newFooterBlock)
    }

    const addDivider = () => {
        const newDividerBlock = {id: Date.now(), type: "DIVIDER", data: {withLine: true}}
        props.onValidation(newDividerBlock)
    }

    const addGroup = () => {
        const newGroupBlock = {id: Date.now(), type: "GROUP", data: {blocks: []}}
        props.onValidation(newGroupBlock)
    }

    const addImageBlock = () => {
        const newImageBlock = {id: Date.now(), type: "IMAGE", data: {url: undefined, alt: undefined, width: 100}}
        props.onValidation(newImageBlock)
    }

    const addFromClipboard = () => {
        if (!localStorage.getItem('COPIED_BLOCK')) toast.error("Aucun élément n'a été copié.")
        const pastedBlock = JSON.parse(localStorage.getItem('COPIED_BLOCK'))
        const block = Object.assign({}, pastedBlock,
            {
                id: Date.now(),
                name: `${pastedBlock.name}(copie)`,
                data: (() => {
                    switch (pastedBlock.type) {
                        case "INPUT":
                            return pastedBlock.data.map((input, index) => Object.assign({}, input, {
                                id: Date.now() + index,
                                name: `${input.name}(copie)`
                            }))
                        case "GROUP":
                            return {
                                blocks: pastedBlock.data.blocks.map((block, index) => {
                                    if (block.type === "INPUT") {
                                        return Object.assign({}, block, {
                                            data: block.data.map((input) => Object.assign({}, input, {
                                                id: Date.now() + (index + 1) * 100,
                                                name: `${input.name}(copie)`
                                            })),
                                            id: Date.now() + index,
                                            name: `${block.name}(copie)`
                                        })
                                    }
                                    return Object.assign({}, block, {
                                        id: Date.now() + index,
                                        name: `${block.name}(copie)`
                                    })
                                })
                            }
                        default:
                            return pastedBlock.data;
                    }
                })()
            })
        props.onValidation(block)
    }


    const renderButton = () => {
        if (!props.betweenBlocks) {
            return <div>
                <Tooltip id={`add_block_tt_top_${props.id}`} text="Ajouter un élément">
                    <a className={`btn btn-small blue darken-2 z-depth-0 modal-trigger ${props.disabled ? "disabled" : ""}`}
                       style={{margin: "0 0.5vw"}}
                       data-target={`new_block_modal_${props.id}`}>
                        <i className="material-icons">add_box</i>
                    </a>
                </Tooltip>
                <Tooltip id={`import_block_tt_top_${props.id}`} text="Coller un élément">
                    <a className={`btn btn-small green darken-2 z-depth-0 ${props.disabled ? "disabled" : ""}`}
                       style={{margin: "0 0.5vw"}} onClick={addFromClipboard}>
                        <i className="material-icons">content_paste</i>
                    </a>
                </Tooltip>
            </div>
        } else {
            return <div id="hide-if-not-hovered">
                <div className="divider blue darken-2"
                     style={{position: "relative", top: "20px", height: "3px", borderRadius: "2px"}}/>
                <Tooltip id={`add_block_tt_${props.id}`} text="Ajouter un élément">
                    <a className={`btn-floating btn-small blue darken-1 z-depth-0 modal-trigger ${props.disabled ? "disabled" : ""}`}
                       data-target={`new_block_modal_${props.id}`} style={{opacity: 1}}>
                        <i className="material-icons white-text">add</i>
                    </a>
                </Tooltip>
                <Tooltip id={`import_block_tt_${props.id}`} text="Coller un élément">
                    <a className={`btn-floating btn-small green darken-2 z-depth-0`}
                       style={{marginLeft: "10px", opacity: 1}} onClick={addFromClipboard}>
                        <i className="material-icons white-text">content_paste</i>
                    </a>
                </Tooltip>
            </div>
        }
    }

    return (
        <div className="center">
            {renderButton()}
            <div id={`new_block_modal_${props.id}`} className="modal">
                <div className="modal-header" style={{paddingTop: "2vh"}}>
                    <h4>Choisissez le type de bloc voulez-vous ajouter</h4>
                </div>
                <div className="modal-content"
                     style={{justifyContent: "center", display: "flex", flexWrap: "wrap", gap: 10}}>
                    <a className="modal-close btn blue darken-2 z-depth-0" onClick={addHeader}>En-tête</a>
                    <a className="modal-close btn blue darken-2 z-depth-0" onClick={addText}>Texte brut</a>
                    <a className="modal-close btn blue darken-2 z-depth-0" onClick={addInput}>Contenu
                        editable</a>
                    <a className="modal-close btn blue darken-2 z-depth-0" onClick={addFooter}>Pied de
                        page</a>
                    <a className="modal-close btn blue darken-2 z-depth-0" onClick={addDivider}>Séparateur</a>
                    <a className="modal-close btn blue darken-2 z-depth-0" onClick={addGroup}>Groupe</a>
                    <a className="modal-close btn blue darken-2 z-depth-0" onClick={addImageBlock}>Image</a>
                </div>
                <div className="modal-footer">
                    <a className="modal-close waves-effect waves-green btn-flat">Annuler</a>
                </div>
            </div>
        </div>
    );
}

NewBlockButton.defaultProps = {
    betweenBlocks: false
}

export default NewBlockButton