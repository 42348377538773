import React from 'react';

const EarningsByActMetric = ({pricing}) => {

    return <div style={{border: "1px solid lightgrey", borderRadius: "10px", width: 250, marginTop: 10}}>
        <p style={{marginLeft: 20, marginBottom: 0}}><b>Chiffre d'affaire moyen/acte</b></p>
        <p style={{margin: 20, fontSize: 18}}>
            <b>{pricing.nb_bills === 0 ? 0 : (pricing.earnings / pricing.nb_bills).toFixed(2)} €</b>
        </p>
    </div>
}

export default EarningsByActMetric;