import React from 'react';
import generatePdf from "./PDFManager";

function FillPDFButton(props) {

    const open_and_fill_PDF = async () => {
        await generatePdf(props.pdfURI, props.formData)
    }

    return (
        <a className={`btn btn-small ${props.color} z-depth-0 ${props.disabled ? "disabled" : ""}`} onClick={() => open_and_fill_PDF()}>
            <i className={`material-icons ${props.text === "" ? "" : "right"}`}>picture_as_pdf</i>
        </a>
    )
}

FillPDFButton.defaultProps = {
    formData: {},
    pdfURI: "",
    color: "blue darken-2",
    icon: "picture_as_pdf",
    text: "",
    disabled: false
}
export default FillPDFButton;