import React, {useEffect} from "react";
import Acces from "../../syntheses/Acces";
import Helper from "../../Shared/Helper";
import SendCodeToPatient from "./SendCodeToPatient";
import BrandedElement from "../../Shared/BrandedElement";

function PatientInfo({
                         patient,
                         cle,
                         orthoptiste_signed,
                         ophtalmologiste_signed,
                         secretaire_signed,
                         style,
                         office_name
                     }) {
    const getBirthday = () => {
        const today = new Date()
        const patientBirthday = new Date(patient.birthday)

        const diffTime = Math.abs(today - patientBirthday)
        return Math.floor(diffTime / (1000 * 60 * 60 * 24 * 365))
    };

    return (
        <div className="card" style={{...style, display: "flex", flexDirection: "column"}} data-cy="PatientInfoComponent">
            <div className="valign-wrapper" style={{justifyContent: "center", gap: 20, padding: 10}}>
                <h2 className="center" style={{margin: 0}}>Informations patient</h2>
                <a className="btn btn-small blue darken-2 z-depth-0"
                   data-cy="EditPatientButton"
                   style={{width: '50px', fontSize: '0.80vw'}}
                   href={`/patients/${patient.id}/edit`}>
                    <i className="material-icons">edit</i>
                </a>
            </div>
            <div className="divider"/>
            <div style={{
                display: "flex",
                flexDirection: "column",
                gap: 10,
                marginBlock: "2vh",
                paddingInline: 20,
                overflowY: "scroll",
                height: "100%"
            }}>
                <div className="col s12 valign-wrapper">
                    <i className="material-icons blue-text text-darken-2 small left">home</i>
                    {Helper.capitalizeFirstLetter(office_name)}
                </div>
                <div className="valign-wrapper">
                    <i className="material-icons small left" style={{color: "#1976D2"}}>person</i>
                &nbsp;
                {Helper.formatName(patient.prenom, patient.nom)}
                </div>
                <div className="valign-wrapper center">
                    <i className="material-icons blue-text text-darken-2 small left">key</i>
                    <BrandedElement temeoo bypass={!orthoptiste_signed}>
                        {orthoptiste_signed ? (
                            <b><a href={`/orthoptistes/connect_as_patient?key=${cle}`} target="_blank"
                                  title="Lien vers l'Espace patient">{cle}</a></b>
                        ) : ophtalmologiste_signed ? (
                            <b><a href={`/ophtalmologistes/connect_as_patient?key=${cle}`}
                                  target="_blank" title="Lien vers l'Espace patient">{cle}</a></b>
                        ) : secretaire_signed ? (
                            <b><a href={`/secretaires/connect_as_patient?key=${cle}`} target="_blank"
                                  title="Lien vers l'Espace patient">{cle}</a></b>
                        ) : null
                        }
                    </BrandedElement>
                     <BrandedElement optician>
                         {orthoptiste_signed ?<b>******</b> : null}
                    </BrandedElement>
                    <div className="valign-wrapper" style={{marginLeft: "auto", gap: 10}}>
                        <SendCodeToPatient patient={patient}/>
                        <Acces patient={patient} access_key={cle}/>
                    </div>
                </div>
                <div className="valign-wrapper">
                    <i className="material-icons blue-text text-darken-2 small left">mail</i>
                {patient.email}
                </div>
                <div className="valign-wrapper">
                    <i className="material-icons blue-text text-darken-2 small left">phone</i>
                {patient.telephone || "Aucun numéro de renseigné"}
                </div>
                <div className="valign-wrapper center">
                    <i className="material-icons blue-text text-darken-2 small left">
                        calendar_month
                    </i>{" "}
                {getBirthday()} ans
                </div>
                <div className="valign-wrapper">
                    <i className="material-icons blue-text text-darken-2 small left">house</i>
                {patient.address ?? "Aucune adresse de renseignée"}
                </div>
                {patient.num_carte_vitale != null ? (
                    <div className="valign-wrapper">
                        <i className="material-icons blue-text text-darken-2 small left">credit_card</i>
                    {patient.num_carte_vitale}
                    </div>
                ) : (
                    <div className="valign-wrapper">
                        <i className="material-icons blue-text text-darken-2 small left">credit_card</i>
                        Aucune carte vitale de renseignée
                    </div>
                )}
            </div>
        </div>
    );
}

export default PatientInfo;
