import React, {Component} from "react";

export default class InputText extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.type === "text-area") {
            return (
                <textarea
                    id="ui-text-area"
                    placeholder={this.props.placeholder}
                    className="browser-default"
                    data-cy="TextAreaInput"
                    style={{
                        ...this.props.style,
                        width: this.props.width,
                        height: this.props.height,
                        resize: this.props.v_resizable ? "" : "vertical",
                        fontSize: this.props.fontSize}}
                    value={this.props.value}
                    onChange={(e) => this.props.onChange(e)}
                    defaultValue={this.props.defaultValue}
                    disabled={this.props.disabled}
                />
            )
        } else if (this.props.type === "input") {
            return (
                <input
                    id="ui-input-text"
                    placeholder={this.props.placeholder}
                    type="text" className="browser-default"
                    defaultValue={this.props.defaultValue}
                    value={this.props.value}
                    onChange={(e) => this.props.onChange(e)}
                    style={Object.assign({}, this.props.style, {width: this.props.width, fontSize: this.props.fontSize})}
                    disabled={this.props.disabled}
                    data-cy="InputArea"
                />
            );
        } else return null;
    }
}

InputText.defaultProps = {
    onChange: () => {return null},
    width: "",
    height: "",
    type: "input",
    v_resizable: true,
    disabled: false,
    fontSize: "1rem",
    style: {},
    placeholder: "",
}