//import typedemesure
import {TypesMesures} from "../../Enums/Enums"
import socket from "../../socket";
const SX = '\x02';
const SH = '\x01';
const EB = '\x0f';
const ET = '\x04';
// SX  = start of text (STX) used to indicate the start of a sentence
// SH = start of header (SOH) used to indicate the start of a header
// EB = end of block (EOB) used to indicate the end of a block, for example end of a DRM block
// ET = end of transmission (EOT) used to indicate the end of a transmission

/*
usb engines send an object :
{
    data: string to send,
    waitForResponse: integer, number of seconds to wait for a return on the serial port , 0 means no wait
}
 */

export default function UsbEngines(machine,measures){
    const serialWriter = (data) => {
        socket.emit("web-to-desktop",
            {
                type: "USB",
                data: data,
                target: machine,
                machine: socket.bridge_uid
            })
    }
    switch (machine) {
        case "Nidek RT-2100": return new NIDEL_RT_2100_FSM(measures,serialWriter);
        case "Nidek RT-5100": return new NIDEL_RT_2100_FSM(measures,serialWriter);
        case "NIDEK RT-5100": return new NIDEL_RT_2100_FSM(measures,serialWriter);
        case "Pre Nidek RT-2100": return {data: PRE_NIDEK_RT_2100(),waitForResponse: 2000};
        default: return null;
    }
}
// FSM for NIDEK_RT_2100
class NIDEL_RT_2100_FSM{
    constructor(measures,serialWriter){
        this.state = "START";
        this.serialWriter = serialWriter;
        this.measures = measures;
    }
    next(message="") {
        switch (this.state) {
            case "START": {
                console.log("sending PRE_NIDEK_RT_2100")
                this.serialWriter({data: PRE_NIDEK_RT_2100(),waitForResponse: 2000});
                this.state = "WAIT_CTRL";
                return true;
            }
            case "WAIT_CTRL": {
                if(message.includes("CRL")){
                    console.log("sending NIDEK_RT_2100")
                    this.serialWriter({data: NIDEK_RT_2100(this.measures),waitForResponse: 0});
                    this.state = "END";
                }
                return true;// if no CTRL message keep looping on next messages
            }
            case "END": {
                return false;
            }
            default: return false
        }

    }
}

function NIDEK_RT_2100(measures){
    // I expect measures to be an arrary of Mesure objects
    //return `\x01DRM\x02OR- 0.50- 0.50165\x0FOL- 0.50- 0.50165\x0FDLM\x02 R- 0.75- 0.20155\x0F L- 0.75- 0.20155\x0F\x04`
    return(
    `${SH}${NIDEK_RT_2100_RM(measures)}${ET}`);
}
function PRE_NIDEK_RT_2100(){
    //"\001C**\002RS\017\004"
    //return "\x01C**\x02RS\x0f\x04";
    return `${SH}C**${SX}RS${EB}${ET}`;

}

function print(number,hasPolarity,afterDot,totalSpace){
    // ex -2 , true, 2,5 => -  2.00
    let result = "";
    let str = (Math.abs(number)).toFixed(afterDot);
    result += hasPolarity ? (number >0 ? "+" : number===0 ? " " : "-") : "";
    result += " ".repeat(totalSpace - ( ((hasPolarity ? 1 : 0) + str.toString().length)));
    result += str;
    return result;

}

function NIDEK_RT_2100_RM(measures){
    // RM = autorefactometer
   //loop on measures
    for(let i=0;i<measures.length;i++){
        let mesure = measures[i];
        if(mesure.titre === TypesMesures.AUTOREF){
            return (
            `DRM${SX}OR${print(mesure.od_sphere,true,2,6)}${print(mesure.od_cylindre,true,2,6)}${print(mesure.od_axe,false,0,3)}${EB}OL${print(mesure.og_sphere,true,2,6)}${print(mesure.og_cylindre,true,2,6)}${print(mesure.og_axe,false,0,3)}${EB}`);
        }
    }
    return "";
}
