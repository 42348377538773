import React, {Fragment} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import LogDisplay from "../../Logs/LogDisplay";
import {User} from "../../../../Shared/Enums/Enums";
import InternalMessage from "./InternalMessage"

function Orthoptist({orthoptists}) {
    const {id} = useParams();
    const orthoptist = orthoptists.find((orthoptist) => orthoptist.id === parseInt(id))
    const nav = useNavigate();

    return <>
        <div className="valign-wrapper" style={{marginLeft: 10}}>
            <a className="btn-flat" style={{borderRadius: 20}} onClick={() => nav("/orthoptists")}>
                <i className="material-icons">arrow_back</i>
            </a>
            <h3 className="valign-wrapper" style={{margin: 20}}><i className="material-icons left">preview</i>
                Gestion de {Helper.formatName(orthoptist.prenom, orthoptist.nom)}
            </h3>
        </div>
        <div className="divider"/>
        <div className="row" style={{padding: 20, gap: 10, minHeight: 400}}>
            <div className="col s12 l6">
                <LogDisplay user_type={User.ORTHOPTIST.value} user={orthoptist}/>
            </div>
            <div className="col s12 l6">
                <InternalMessage user={orthoptist}/>
            </div>
        </div>
    </>
}

export default Orthoptist;