import React from "react"
import PropTypes from "prop-types"
import axios from "axios"
import QRCode from "qrcode.react"
import {CENTRE_OPHTALMO_URL} from "../../Shared/Helper";

class PrintAcces extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      txt: "",
      key: ""
    };
  }
  componentDidMount(){

  }
  render () {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col s12">
          <p></p>
          </div>
          <div className="col s12 center">
            <QRCode value={`${CENTRE_OPHTALMO_URL}/list?key=`+this.props.apikey} />
          </div>
          <div className="col s10 offset-s1 ">
              <h2 className="center">Accès à vos prescriptions</h2>
              <p>{this.props.patient.prenom} {this.props.patient.nom}, vous pouvez accéder à vos prescriptions
                sur <br/> <b>{CENTRE_OPHTALMO_URL}</b>. <br/> Votre clé d'accès est:
              </p>
              <h1 className="center">{this.props.apikey}</h1>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PrintAcces
