import React from "react";
import Regex from "../../../../Shared/Validation/Regex";
import MesureStore from "../../MesureStore";

const PrescriptionHistory = ({prescription, number}) => {
    const [expanded, setExpanded] = React.useState(false);


    const extractValues = (text) => {
        const values = Regex.extractValues(text)
        const od_sphere = values.od_sphere === "plan" ? 0 : parseFloat(values.od_sphere)
        if(od_sphere) MesureStore.set_od_sphere(od_sphere)
        if(!isNaN(parseFloat(values.od_cylindre))) MesureStore.set_od_cylindre(parseFloat(values.od_cylindre))
        if(!isNaN(parseInt(values.od_axe))) MesureStore.set_od_axe(parseInt(values.od_axe))
        if(!isNaN(parseFloat(values.od_addition))) MesureStore.set_od_addition(parseFloat(values.od_addition))
        const og_sphere = values.og_sphere === "plan" ? 0 : parseFloat(values.og_sphere)
        if(og_sphere) MesureStore.set_og_sphere(og_sphere)
        if(!isNaN(parseFloat(values.og_cylindre))) MesureStore.set_og_cylindre(parseFloat(values.og_cylindre))
        if(!isNaN(parseInt(values.og_axe))) MesureStore.set_og_axe(parseInt(values.og_axe))
        if(!isNaN(parseFloat(values.og_addition))) MesureStore.set_og_addition(parseFloat(values.og_addition))
    }

    const renderExpanded = () => {
        if (!expanded) return null;
        return <div style={{marginInline: 40}}>
            <h6 style={{marginTop: 0, whiteSpace: "pre-wrap"}}>{prescription.text}</h6>
        </div>

    }

    return <div style={{display: "flex", flexDirection: "column", gap: 10, border: "thin solid #1976d2", borderRadius: 10, paddingInline: 0}}>
        <div className="valign-wrapper">
            <a className="btn-small btn-flat transparent" onClick={() => setExpanded(!expanded)} style={{flex: 1}}>
                <i className={`material-icons left ${expanded ? "flipped" : ""}`}>expand_more</i>
                Prescription {number}
            </a>
            <a className="btn-small btn-flat z-depth-0 transparent"
               title="Importer prescription dans la mesure actuelle"
               onClick={() => extractValues(prescription.text)}
            >
                <i className={`material-icons`}>publish</i>
            </a>
        </div>
        {renderExpanded()}
    </div>
};

export default PrescriptionHistory;