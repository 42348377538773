import React from "react";
import ShowMesures from "../syntheses/ShowMesures";
import DisplayMeasures from "../Orthoptistes/Appointment/Check/DisplayMeasures";
import {format_measures} from "../Appointments/Mesures/mesure_components/mesures";

const _ = require('lodash');
export default function SummaryDetails({images, measures_types, measures}) {
    const renderImages = () => {
        if (images && _.isEmpty(images)) return null
        return <>
            <h2 className="center-align">Fonds d'œil</h2>
            <div style={{display: "flex", gap: 20, alignItems: "center", margin: "0 20px", flexWrap: "wrap"}}>
                {images.map((image, index) => {
                    return (<div style={{maxWidth: "50%"}} key={index}><img className="responsive-img" src={image} style={{ maxHeight: 200}} alt=""/></div>)
                })}
            </div>
        </>
    }

    return (
        <>
            <div className="prevent-break">
                <h1 className="center-align">Informations détaillées</h1>
                {renderImages()}
            </div>
            <div className={`prevent-break container ${!measures.length ? "hide": ""}`}>
                <h2 className="center-align">Réfraction et tonométrie</h2>
                <DisplayMeasures measures={format_measures(measures, measures_types)} title={false}/>
            </div>
        </>
    )
}