import React from 'react';
import Helper from "../../../Shared/Helper";

const Scor = ({prescription, patient}) => {

    const renderWatermark = () => {
        return <div style={{
            zIndex: 1,
            color: "grey",
            opacity: 0.7,
            transform: "translate(10%) rotate(45deg)",
            transformOrigin: "left top",
            position: "absolute",
            top: 200,
            left: 200,
        }}>
            <h1 className="center" style={{margin: 0}}>ORDONNANCE NON VALABLE POUR PATIENT</h1>
            <h1 className="center" style={{margin: 0}}>À USAGE DE REMBOURSEMENT ORTHOPTISTE</h1>
        </div>
    }

    return <div style={{display: "flex", justifyContent: "center"}}>
        <div className="card page" style={{width: "210mm", height: "296mm", margin: 20}}>
            <div className="pdf-document" style={{display: "flex", flexDirection: "column", height: "270mm"}}>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <div dangerouslySetInnerHTML={{__html: prescription.header}}/>
                    <h5 style={{marginLeft: "auto"}}>Le, {prescription.date}</h5>
                    <h5>{Helper.formatName(patient.firstname, patient.lastname)},</h5>
                    <h5 style={{marginInline: 100, whiteSpace: "pre-wrap"}}>{prescription.text}</h5>
                </div>
                <div className="pdf-footer prevent-break"
                     style={{marginTop: "auto", display: "flex", flexDirection: "column", gap: 20}}>
                    <div>
                        {prescription.signature ?
                            <img style={{height: "150px", float: "right"}}
                                 src={prescription.signature} alt="Signature de l'ophtalmologiste"/>
                            : null
                        }
                    </div>
                </div>
                {renderWatermark()}
            </div>
        </div>

    </div>
}

export default Scor;