import React, {useEffect} from "react";
import SuspiciousAppointments from "./SuspiciousAppointments";

function DataConsole({orthoptists}) {

    useEffect(() => {
    }, [])


    return (
        <div id="full-height" style={{display: "flex", flexDirection: "column", overflowY: "scroll"}}>
            <h3 className="valign-wrapper" style={{margin: 20}}><i className="material-icons left">preview</i>Examens suspects</h3>
            <div className="divider"/>
            <div style={{marginLeft: 2}}>
                <SuspiciousAppointments orthoptists={orthoptists}/>
            </div>
        </div>
    );
}

export default DataConsole