import React, {Component} from 'react';
import Store from "./MessagesStore"
import InputText from "../Shared/UiKit/InputText";

export default class SendMessage extends Component {
    constructor(props) {
        super(props);
        this.send = this.send.bind(this)
        this.state = {
            message: ""
        }
    }

    send(e) {
        e.preventDefault()
        let message = this.state.message
        if (message.length > 0) {
            Store.send(message, this.props.appointement_id)
            this.setState({message: ""})
        }
    }

    render() {
        return (
            <div style={{display: "flex", flexDirection: "column", gap: 10, margin: 20}}>
                <InputText type="text-area"
                           onChange={(e) => this.setState({message: e.target.value})}
                           height="200px" width="100%"
                           value={this.state.message}
                />
                <div><a className="btn blue darken-2 z-depth-0 right" onClick={this.send}><i className="material-icons left">send</i>Envoyer</a>
                </div>
            </div>
        );
    }
}
