import React, { Component } from 'react';
import BridgeStore from "../BridgeStore"
import {observer} from "mobx-react"
const _ = require('lodash');
const debounceUpdate = _.debounce(BridgeStore.updateDirectory,800)
@observer
export default class InputDirectory extends Component {
  constructor(props) {
    super(props)
    this.state = {}

    this.init()
  }
  init(){
    if(BridgeStore.connected){
      BridgeStore.socket.on("selected_directory",(dir)=>{
      if(dir.directory != ""){
        BridgeStore.updateDirectory(dir.directory)
      }
    })
    }else{
      setTimeout(this.init,1000)
    }
  }
  handleInputDir(e){
    BridgeStore.directory = e.target.value
    debounceUpdate(e.target.value)
  }

  chooseDirectory(e){
    e.preventDefault()
    if(BridgeStore.connected) BridgeStore.socket.emit("select_directory",{})

  }
  render() {
    return (
            <div className="row">
              <div className="col s8">
                <div className="input-field">
                <input type="text" value={BridgeStore.directory} onChange={this.handleInputDir} />
                <label className={BridgeStore.directory=="" ? "" : "active"}> C:\Documents\navis </label>
                </div>
              </div>
              <div className="col s4">
                <div className="input-field">
                  <a className="btn" onClick={this.chooseDirectory}><i className="material-icons left"> drive_folder_upload</i> Sélection</a>
                </div>
              </div>
            </div>
    );
  }
}
