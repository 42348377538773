import React from "react"
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from "axios"
import Loader from "../Shared/Loader";
import {RequestState} from "../Shared/StateHelper";
import Helper from "../Shared/Helper"

export default class HomePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            appointments_list: [],
            request_state: RequestState.LOADING,
            page: 0
        }
    }

    componentDidMount() {
        this.loadData(this.state.page)
    }

    loadData = () => {
        axios.get("/secretariat/get_appointment_list", {params: {page: this.state.page}})
            .then((response) => {
                this.setState({
                    appointments_list: this.state.appointments_list.concat(response.data.appointments_list),
                    request_state: RequestState.SUCCESS,
                    page: this.state.page + 1
                })
            })
            .catch((error) => {
                this.setState({request_state: RequestState.ERROR})
            })
    }

    setAppointmentNotHonored = (appointment_id) => {
        axios.delete(`/secretariat/appointment_not_done/destroy/${appointment_id}`)
            .then((resp) => {
                let new_appointment_list = [...this.state.appointments_list]
                new_appointment_list = new_appointment_list.filter((appointment) => appointment.id !== appointment_id)
                this.setState({appointments_list: new_appointment_list})
            })
            .catch((error) => {
            })
    }

    render() {
        if (this.state.request_state === RequestState.LOADING) return <Loader/>
        if (this.state.request_state === RequestState.ERROR) return <div className="center">Une erreur est
            survenue.</div>
        if (this.state.request_state === RequestState.SUCCESS) return (
            <div className="container" style={{width: "90%"}}>
                <h2 className="center">Rendez-vous non présents</h2>
                <InfiniteScroll
                    next={this.loadData}
                    hasMore={true}
                    dataLength={this.state.appointments_list.length}>

                </InfiniteScroll>
                <table className="striped bordered">
                    <colgroup>
                        <col span="1" style={{width: "15%"}}/>
                        <col span="1" style={{width: "25%"}}/>
                        <col span="1" style={{width: "20%"}}/>
                        <col span="1" style={{width: "35%"}}/>
                        <col span="1" style={{width: "5%"}}/>
                    </colgroup>
                    <tbody>
                    {this.state.appointments_list.map((appointment, index) => {
                        return (
                            <tr key={index}>
                                <td className="center">{Helper.formatDate(appointment.start, "DD/MM/YYYY hh:mm")}</td>
                                <td>{appointment.office_name}</td>
                                <td>{Helper.formatName(appointment.first_name, appointment.last_name)}</td>
                                <td>{appointment.email}</td>
                                <td><a onClick={() => this.setAppointmentNotHonored(appointment.id)}
                                       style={{cursor: "pointer"}}><i
                                    className="material-icons">delete</i></a></td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        )
    }
}