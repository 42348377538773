import React from 'react';
import {Application} from "../../../../../Shared/Enums/Enums";
import CardValueDisplay from "../../../../../Shared/UiKit/CardValueDisplay";
import BorderedDisplay from "../../../../../Shared/UiKit/BorderedDisplay";
import useApplicationOverview from "./useApplicationOverview";
import {RequestState} from "../../../../../Shared/StateHelper";
import Loader from "../../../../../Shared/Loader";
import Error from "../../../../../Shared/Error";
import GeneralOverview from "./GeneralOverview";

const APP_NAME = Application.POINT_VISION;

function PointVisionOverview() {
    return <BorderedDisplay text={"Point Vision"}>
        <GeneralOverview appName={APP_NAME}/>
    </BorderedDisplay>
}

export default PointVisionOverview;