import React, {useEffect} from "react";

function DuplicatedPatientModal({patient, onValidation}) {
    useEffect(() => {
        const modal = document.getElementById('duplicated_patient_modal');
        const instance = M.Modal.init(modal, {});
        return () => {
            instance.destroy();
        }
    })
    useEffect(() => {
        if (patient) {
            const modal = document.getElementById('duplicated_patient_modal');
            const instance = M.Modal.getInstance(modal);
            instance.open();
        } else {
            const modal = document.getElementById('duplicated_patient_modal');
            const instance = M.Modal.getInstance(modal);
            instance.close();
        }
    }, [patient]);

    return <div id="duplicated_patient_modal" className="modal">
        <div className="modal-content">
            <h3 className="center">Ce patient existe déjà !</h3>
            <h5>{`Le patient ${Helper.formatName(patient?.prenom, patient?.nom)} avec l'email : ${patient?.email} 
            existe déjà. Vous pouvez soit modifier son nom, soit fusionner le dossier de la personne existante et ce dossier :
            cela aura pour effet de déplacer les rendez-vous précédants dans le profil actuel`}</h5>
        </div>
        <div className="modal-footer">
            <div style={{display: "flex", justifyContent: "space-around", margin: "0 20%", flexWrap: "wrap"}}>
                <a className="btn blue darken-2 z-depth-0" onClick={onValidation}><i className="material-icons left">merge</i>Fusionner</a>
                <a className="modal-close btn red darken-2 z-depth-0"><i className="material-icons left">cancel</i>Annuler</a>
            </div>
        </div>

    </div>
}

export default DuplicatedPatientModal;