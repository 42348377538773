import React, {Component} from "react";

export default class MonoCollapible extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        var elems = document.querySelectorAll('.collapsible');
        var instances = M.Collapsible.init(elems, {});
    }

    render() {
        return (
            <ul className="collapsible">
                <li>
                    <div className="collapsible-header"><i
                        className="material-icons">{this.props.icon}</i>{this.props.headerText}</div>
                    <div className="collapsible-body">
                        {this.props.children}
                    </div>
                </li>
            </ul>

        )
    }
}

MonoCollapible.defaultProps = {
    headerText: "",
    icon: ""
}