import { action, observable,computed, autorun } from "mobx"
import axios from "axios"
import { io } from 'socket.io-client'
import MesureStore from '../Appointments/Mesures/MesureStore'
import Nidek from "./Parsers/Nidek"

class BridgeStore{
  @observable connected=false
  @observable ip = ""
  @observable ready=false
  @observable snackbar_open = false
  @observable snackbar_message = "test"
  @observable statut_recu = false
  @observable serials = []
  @observable avaiable_usbs = []
  @observable app_id=""
  @observable onBoarding = false
  @observable usb_refracteur="auto";
  @observable force_usb_refracteur="auto";
  @observable uid_refracteur="";
  @observable type_refracteur="";
  @observable directory = "";

  refracteurs = ["Nidek RT-2100","Nidek RT-5100","Nidek RT-6100"]


  socket=null;
  #api_token=""
  #email = ""

  @action
  snackbar(message){
    this.snackbar_message = message
    this.snackbar_open = true
    setTimeout(()=> (this.snackbar_open = false),4000)
    return
  }
  @action
  updateAppointment(id){
    this.app_id = id
    if(this.socket != null){
      this.socket.emit("current_appointment",{id: id,s: "bs1_legacy"})
    }
  }
  @action saveRefrSettings(){
    axios.post("/api/v2/bridge/refracteur/update",{
      type_refracteur: this.type_refracteur,
      uid_refracteur: this.uid_refracteur,
      usb_refracteur: this.force_usb_refracteur
    })
  }
  @action update_type_refracteur(type){
    this.type_refracteur=type
    this.saveRefrSettings()
  }
  @action update_usb_refracteur(usb){
    this.force_usb_refracteur=usb
    this.usb_refracteur=usb
    this.saveRefrSettings()

  }
  subscribeMesureStore(){
    this.updateAppointment(MesureStore.appointement_id)
    MesureStore.subscribe(this)
  }
  @action updateDirectory(directory){
    this.directory=directory
    axios.post("/api/v2/bridge/directory/savedirectory",{directory: directory})
  }
  constructor(){
    this.subscribeMesureStore()
  }
  disconnect(){
    App.bridgechannel.unsubscribe()
    if(this.socket !== null){
      this.socket.disconnect()
    }
  }
  setCable(){
    var that = this
    App.bridgechannel = App.cable.subscriptions.create({channel: 'BridgeChannel'}, {
          received: function(data) {
              that.getRefraction(data["data"])
           }
        });
  }
  @action initUsb(){
    let socket = this.socket
    if(this.uid_refracteur != ""){
    socket.emit("start_serial",{
      baudrate: 2400,
      usb: this.force_usb_refracteur,
      eot: "", //pas encore dev
      uid: this.uid_refracteur,
      type: this.type_refracteur
    })
    }
  }
  @action initDirectory(){
    if(this.directory != ""){
          this.socket.emit("start_directory",{
      directory: this.directory,
      uid: "fond oeil"
    })
    }
  }

  @action
  initSocket(){
    if(this.ip != "" && !this.connected){
      this.socket = io("http://"+this.ip+":5000");
      var socket = this.socket
      socket.on('connect', ()=> {
        this.connected = true
        // this.snackbar("Connecté aux machines")
        socket.emit("statut",{})
        // socket.emit("select_directory",{})
        socket.emit("init_keys",{email: this.#email,api_token: this.#api_token})
        this.updateAppointment(this.app_id)
        this.initUsb()
        this.initDirectory()
      })
      socket.on("statut_classes",(data)=>{
        if(data.length>0){
          for (let i = 0; i < data.length; i++){
            if(data[i].type=="serial"){
              this.serials.append(data[i])
            }
          }
        }else{
          socket.emit("scan_usb_server")
        }
        this.statut_recu = true

      })
      socket.on("disconnect", ()=> {
        this.connected = false
        this.statut_recu = false
        // this.snackbar("Déconnexion des machines")

      })
      socket.on("erreur",(data)=>{
        this.snackbar(data.uid+": "+data.message)
      })
      socket.on("info",(data)=>{
        this.snackbar(data.uid+": "+data.message)
      })
      socket.on("scan_usb",(data)=>{
        this.avaiable_usbs = JSON.parse(data)
      })

      // socket.on("serial_data",(data)=>{
      //   console.log(data)
      //   this.getRefraction(data["data"])
      //   this.snackbar("réception de donnée de réfraction")

      // })
    }
    return;
  }

  @action
  connect(ip){
    this.ip = ip
    this.initSocket();
    axios.post("/api/v2/bridge/subscribe",{lan: ip}).then((data)=>{
    })
    return
  }


  @action
  init(ip,api_token,email){
    this.ip = ip || ""
    this.#email=email
    this.#api_token=api_token
    this.ready=true
    this.initSocket();
    axios.get("/api/v2/bridge/get").then((data)=>{
      data = data.data
      this.force_usb_refracteur = data.usb_refracteur|| ""
      this.uid_refracteur = data.uid_refracteur|| ""
      this.type_refracteur = data.type_refracteur|| ""
      this.directory = data.fo_dossier|| ""
      if(this.uid_refracteur ==""){
        this.onBoarding=true
      }
    })

  }

  @action getRefraction(data){
//     let r=`
/*NIDEK RT-5100 ID             DA2021/ 1/11
@RM
OR- 0.25- 0.50 45
OL- 0.50- 0.50175
PD58.0
@rm
OR- 0.50- 0.50 35
OL- 0.50- 0.50175
PD58.0
@RT
FR- 0.25- 0.50 45
FL- 0.50- 0.50175
AR+ 1.00
AL+ 1.00
VR 0.9
UR 0.9
VL 1.0
UL 1.0
PD58.0
Pd54.0
WD40
wd 40
TT 001
@KM
CR 8.09 7.90 10
CL 8.04 7.83175
@NT
TR19.0
TL21.5
tR2.50
    `*/
    let parsed = new Nidek(data)
    parsed.parseAndSend(this.app_id,()=> MesureStore.refresh())
  }

}
let bridgeStore = new BridgeStore();
global.BridgeStore = bridgeStore;
export default bridgeStore;
