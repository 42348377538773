import React from "react";
import {RequestState} from "./StateHelper";
import Loader from "./Loader";
import Error from "./Error";

function OverLoader(props) {

    const renderOnReload = () => {
        return (
            <div style={{display: "flex", justifyContent: "center"}}>
                <div style={{filter: "blur(2px)", width:"100%", pointerEvents: "none"}}>
                    {props.children}
                </div>
                <div style={{position: "absolute", alignSelf: "center"}}>
                    <Loader text={props.children.props.children ? "" : props.text} textColor={props.textColor}/>
                </div>
            </div>
        )
    }

    switch (props.state) {
        case RequestState.LOADING:
            return <Loader/>
        case RequestState.RELOADING:
            return renderOnReload()
        case RequestState.ERROR:
            return <Error text={props.errorText}/>
        case RequestState.SUCCESS:
        case RequestState.IDLE:
        default:
            if(!props.children.props.children || props.children.props.children.reduce((acc, node) => {
                if (node !== null) return false
                return acc
            }, true)) return <h5 className="center" style={{marginTop:"5vh"}}>{props.text}</h5>
            return props.children
    }

}

OverLoader.defaultProps = {
    errorText:"",
    text: "",
    textColor: ""
}

export default OverLoader;