import React from "react"
import axios from "axios"
import moment from "moment"
import {observer} from "mobx-react"
import ControleStore from "./ControleStore"
import {Link} from "react-router-dom"
import SendMessageToOrthoptiste from "./SendMessageToOrthoptiste"
import EditPrescription from "./EditPrescription"
import GeneralSummaryPanel from "./GeneralSummaryPanel";
import FundusDisplayPanel from "./FundusDisplayPanel";
import FundusDisplayVideoPanel from "./FundusDisplayVideoPanel";
import MessagesStore from "../../Messages/MessagesStore";
import Validator from "../../Shared/Validator";
import Loader from "../../Shared/Loader";
import ReportPrintButton from "../../Orthoptistes/Reports/ReportPrintButton";
import DisplayMeasures from "../../Orthoptistes/Appointment/Check/DisplayMeasures";
import {TypesMesures} from "../../Shared/Enums/Enums";
import {format_measures} from "../../Appointments/Mesures/mesure_components/mesures";
import AskForReworkButton from "../../ophtalmologiste/Appointment/AskForReworkButton";

const _ = require('lodash')

@observer
export default class Controle extends React.Component {
    constructor(props) {
        super(props);
        this.loadData = this.loadData.bind(this)
        this.validate = this.validate.bind(this)
        this.destroy = this.destroy.bind(this)
        this.back = this.back.bind(this)
        this.skip = this.skip.bind(this)
        this.show_prescriptions_secondaires = this.show_prescriptions_secondaires.bind(this)
        this.state = {
            generalSummary: {
                name: "",
                age: "",
                phone: "",
                date: "",
                acts: [],
                comment: "",
                orthoptic_text: "",
                stereoscopy: "",
                orthoptist: "",
                history: "",
                pathologies: [],
                priority: 0,
                bypass_reason: "",
                questions67: {
                    amy6_diabete: undefined,
                    annee_decouverte_diabete: undefined,
                    hb1ac: undefined,
                    suivi_ophtalmique: undefined,
                    ophtalmologiste_dernier_examen: undefined,
                    hta: undefined,
                }
            },
            mesures: [],
            images: [],
            videos: [],
            skiacol: false,
            prescription: "",
            prescriptions_secondaires: [],
            id: "",
            mesuresLoaded: false,
            typedemesures: [],
            pathologies: [],
            orthoptiste_id: "",
            mesure_key: 0,
            priority: 0,
            patient_id: undefined,
            already_checked: false,
            report_available: false,
            medical_report: {},
            act: {},
            start_time: 0,
            patient: {},

            loading: true
        };
        let csrfToken = document.querySelector("meta[name='csrf-token']").content
        axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
        this.loadData(this.isSingleControl() ? this.props.appointmentId : NaN)

    }

    componentDidMount() {
    }

    loadData(id = NaN) {
        var that = this
        axios.post("/api/admin/controle/recherche_synthese", {
            skip: ControleStore.skip, specific: id
        }).then((data) => {
            data = data.data
            if (data.status == "all-done") {
                window.location.replace("/admin/controle_rdv")
            }
            this.setState({
                generalSummary: {
                    name: Helper.formatName(data.patient.prenom, data.patient.nom),
                    age: moment().diff(data.patient.birthday, "years"),
                    phone: data.patient.telephone,
                    history: data.patient.history ?? "",
                    date: data.date,
                    acts: data.actes,
                    comment: data.commentaire,
                    orthoptic_text: data.orthoptic_text,
                    stereoscopy: data.stereoscopy,
                    orthoptist: data.orthoptiste,
                    pathologies: data.pathologies,
                    priority: data.priority,
                    questions67: data.questions67,
                    bypass_reason: data.bypass_reason
                },
                images: data.images,
                videos: data.videos,
                id: data.id,
                orthoptiste_id: data.orthoptiste_id,
                priority: data.priority,
                mesuresloaded: false,
                loading: false,
                patient_id: data.patient.id,
                already_checked: data.already_checked,
                report_available: data.report_available,
                medical_report: data.report,
                act: data.act,
                start_time: performance.now(),
                patient: data.patient
            })
            ControleStore.app_id = data.id
            var app_id = data.id
            axios.get("/api/admin/controle/mesures?id=" + data.id).then((data) => {
                data = data.data
                this.setState({
                    mesures: data.mesures,
                    typedemesures: data.typedemesures,
                    skiacol: data.prescription.includes("skiacol"),
                    prescription: data.prescription,
                    prescriptions_secondaires: data.prescriptions_secondaires,
                    mesuresLoaded: true,
                    mesure_key: app_id
                })
                setTimeout(function () {
                    that.resizeForm()
                }, 100)
            })
            MessagesStore.loadMessages(this.state.orthoptiste_id, this.state.id);
            MessagesStore.hasNewMessage(this.state.orthoptiste_id, this.state.id);
        })

    }

    isSingleControl = () => {
        return !isNaN(parseInt(this.props.appointmentId))
    }

    back() {
        if (ControleStore.history.length > 0) {
            this.loadData(ControleStore.backToHistory())
        }
    }

    validate(e) {
        e.preventDefault()
        this.setState({
            loading: true,
            mesuresLoaded: false
        })
        let elapsed_time = performance.now() - this.state.start_time
        ControleStore.addHistory(this.state.id)
        axios.post("/api/admin/controle/accepter", {id: this.state.id, elapsed_time: elapsed_time}).then((data) => {
            if (this.isSingleControl()) {
                window.location.href = this.props.fallback || `/patients/${this.state.patient_id}`
            } else this.loadData()
        })
    }

    handleRework = () => {
        if (this.isSingleControl()) {
            window.location.href = this.props.fallback || `/patients/${this.state.patient_id}`
        } else this.skip()
    }

    resizeForm() {
        try {
            M.textareaAutoResize(document.querySelector('#prescription'));

        } catch (error) {
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props !== prevProps) {
            this.resizeForm()
        }
        document.getElementById('controle-view').scrollIntoView({block: "start"});

    }

    skip() {
        ControleStore.addHistory(ControleStore.app_id)
        ControleStore.skip = [...ControleStore.skip, ControleStore.app_id]
        this.loadData()
    }

    destroy(e) {
        e.preventDefault()
        this.setState({
            loading: true,
            mesuresLoaded: false
        })
        axios.post("/api/admin/controle/destroy", {id: this.state.id}).then((data) => {
            this.loadData()
        })
    }

    show_prescriptions_secondaires() {
        if (this.state.prescriptions_secondaires !== []) {
            return (
                <div className="row">
                    {this.state.prescriptions_secondaires.map((prescription) => (
                        <div className="col s4" style={{whiteSpace: 'pre-wrap'}} key={prescription.id}>
                            <h3 className="center"> prescription secondaire </h3>
                            {prescription.text}
                        </div>
                    ))}

                </div>
            )
        }
    }

    render() {
        if (this.state.loading) {
            return <div id="full-height"
                        style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                <Loader wide/>
            </div>
        } else if (this.state.already_checked && this.isSingleControl()) {
            return (<div className="center" style={{padding: '2vw'}}>
                <h3>Ce patient a déjà été contrôlé</h3>
                <a className="btn blue darken-2 z-depth-0" onClick={() => window.history.back()}><i
                    className="material-icons left">chevron_left</i>
                    Page précédente
                </a>
                <a className="btn blue darken-2 z-depth-0" onClick={() => this.setState({already_checked: false})}>
                    <i className="material-icons right">play_arrow</i>
                    Contrôler quand même
                </a>
            </div>)
        } else {
            return (
                <div id="full-height" style={{fontFamily: "Arial"}}>
                    <div style={{display: "flex", gap: 20, width: "100%", padding: 20}}>
                        <div style={{display: "flex", gap: 20, width: "calc(100vw - 200px)"}}>
                            <div style={{width: "60%"}}>
                                <div style={{minHeight: "350px"}}>
                                    <GeneralSummaryPanel
                                        summary={this.state.generalSummary}
                                        mesures={this.state.mesures}
                                        medical_report={this.state.medical_report}
                                        act={this.state.act}
                                        patient={this.state.patient}
                                    />
                                </div>
                                <div style={{minHeight: "50%"}}>
                                    <div className={`center ${this.state.report_available ? "" : "hide"}`}>
                                        <ReportPrintButton appointment_id={this.state.id}
                                                           disabled={!this.state.report_available}
                                        />
                                    </div>
                                    <div>
                                        <FundusDisplayPanel images={this.state.images}
                                                            key={"img-" + this.state.mesure_key}/>
                                    </div>
                                    <div>
                                        <FundusDisplayVideoPanel videos={this.state.videos}
                                                                 key={"vid-" + this.state.mesure_key}/>
                                    </div>
                                </div>
                            </div>
                            <div style={{width: "40%", height: "100%"}}>
                                <div style={{minHeight: "350px"}}>
                                    {!this.state.mesuresLoaded ? (
                                        <p>Chargement en cours</p>
                                    ) : (
                                        <>
                                            <h2 className="flex center">Mesures</h2>
                                            <hr/>
                                            <DisplayMeasures
                                                key={"s-" + this.state.mesure_key} title={false}
                                                measures={format_measures(this.state.mesures, this.state.typedemesures)}
                                            />
                                        </>
                                    )}
                                    {this.state.skiacol ? (
                                        <h2 className="blue-text center"> Prescription de skiacol </h2>
                                    ) : (
                                        <span></span>
                                    )}
                                </div>
                                <div style={{minHeight: "50%"}}>
                                    <EditPrescription
                                        key={"p-" + this.state.mesure_key}
                                        prescription={this.state.prescription}
                                        prescription_secondaire={this.state.prescriptions_secondaires}
                                        app_id={this.state.id}
                                        mesures={this.state.mesures}
                                        typedemesures={this.state.typedemesures}
                                        priority={this.state.priority}
                                    />
                                </div>
                            </div>
                        </div>
                        <div id="full-height" style={{
                            width: "150px",
                            position: "fixed",
                            right: 15,
                        }}>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 20,
                                paddingTop: this.isSingleControl() ? "25vh" : "15vh",
                                overflowY: "scroll",
                                height: "100%"
                            }}>
                                <a onClick={this.validate} style={{borderRadius: "10px"}}
                                   className=" btn-large green truncate-nohide z-depth-0"><i
                                    className="material-icons right hide">done</i>Valider</a>

                                <Link to="pathologie" style={{borderRadius: "10px"}}
                                      className=" btn-large red truncate-nohide z-depth-0"><i
                                    className="material-icons right hide">clear</i>Refuser</Link>
                                <AskForReworkButton appointment_id={this.state.id}
                                                    onValidation={this.handleRework}
                                                    big
                                />
                                {/* Case : single check TODO do it cleaner*/}
                                {this.isSingleControl() ? (<div/>) : (
                                    <div style={{display: "flex", flexDirection: "column", gap: 20}}>
                                        <a onClick={this.skip} style={{borderRadius: "10px"}}
                                           className=" btn-large blue truncate-nohide z-depth-0"><i
                                            className="material-icons right hide">navigate_next</i>Passer</a>

                                        <a onClick={this.back} style={{borderRadius: "10px"}}
                                           className={" btn-large truncate blue z-depth-0 " + (ControleStore.hasHistory ? "" : "disabled")}>
                                            <i className="material-icons right hide">arrow_back_ios</i>Retour</a>
                                        <Validator
                                            onValidation={this.destroy}
                                            text="Voulez-vous vraiment supprimer cette prescription ?"
                                            detail="Cette prescription sera définitivement supprimée."
                                            validationColor="red" abortColor="black" validationText="Supprimer"
                                            validationIcon="delete" abortIcon="close"
                                        >
                                            <a style={{borderRadius: "10px"}}
                                               className="btn-large black truncate-nohide z-depth-0"><i
                                                className="material-icons right hide">delete</i>
                                                Supprimer
                                            </a>
                                        </Validator>
                                    </div>)}
                                <SendMessageToOrthoptiste
                                    orthoptiste={this.state.generalSummary.orthoptist}
                                    orthoptiste_id={this.state.orthoptiste_id}
                                    patient={this.state.generalSummary.name}
                                    date={this.state.generalSummary.date}
                                    appointement_id={this.state.id}
                                    app_id={this.state.mesure_key}
                                    key={"msg-" + this.state.mesure_key}/>
                            </div>
                        </div>

                    </div>
                </div>
            )
        }
    }

}
