import React from "react";
import CVStore from "./CVStore";
import {Observer} from "mobx-react";

const DisplayMultiplePatients = () => {
    const formatString = (input) => {
        if (!input || input.trim() === "" || input === "Erreur") return "-"
        else return input
    }

    const renderData = (title, data) => {
        return (<div className="col xl6 s12" style={{padding: "2vh 0"}}>
            <h4 style={{marginBottom: "2vh"}}>{title}</h4>
            <h5 style={{margin: "0"}}>{formatString(data)}</h5>
        </div>)
    }

    const renderPatientData = (patient) => {
        return (
            <div className="row container center-align" style={{backgroundColor: "#FFF", borderRadius: "10px"}}>
                {renderData("Nom usuel", patient.sNomUsuelPatient)}
                {renderData("Nom de famille", patient.sNomFamillePatient)}
                {renderData("Prénom", patient.sPrenomPatient)}
                {renderData("Adresse", patient.sAdressePatient)}
                {renderData("Num. Securite Sociale", patient.sNumeroSecuriteSocialePatient)}
                {renderData("Né le", Helper.formatDate(patient.dDateDeNaissance, "DD/MM/YYYY") )}
                {renderData("Couv. obligatoire", patient.sCouvAMO)}
                {renderData("Expire le", Helper.formatDate(patient.sDateFinAMO, "DD/MM/YYYY"))}
                {renderData("Couv. complémentaire", patient.sCouvAMC)}
                {renderData("Expire le", Helper.formatDate(patient.sDateFinAMC, "DD/MM/YYYY"))}
                {renderData("Num. Médecin traitant", patient.nCodeDeclarationMT)}
                {renderData("Expiration Carte Vitale", patient.dDateFinValiditeCV)}
                {renderData("Code patient", `${CVStore.currentCVMeta.regime_code} ${CVStore.currentCVMeta.fund_code} ${CVStore.currentCVMeta.centre_code} `)}
            </div>
        )
    }

    return (
        <Observer>
            {() => <div>
                <div className="input-field"
                     style={{"display": "flex", "justifyContent": "space-between"}}>
                    <p className="center">Sélectionner le patient</p>
                    <select className="browser-default"
                            style={{"width": "50%"}}
                            defaultValue={CVStore.selectedPatient}
                            onChange={(e) => CVStore.selectPatient(CVStore.patientList[e.target.value])}>
                        <option disabled value="">Patient</option>
                        {CVStore.patientList.map((patient, index) => (
                            <option value={index}
                                    key={index}>{patient.sPrenomPatient} {patient.sNomFamillePatient}</option>
                        ))}
                    </select>
                    <a className="btn blue darken-2 z-depth-0" style={{"marginLeft": "10px"}}
                       onClick={() => CVStore.udpatePatient()}>Continuer</a>
                </div>
                <div style={{display: "flex", justifyContent: "center", padding: "1vh 0"}}>
                    {renderPatientData(CVStore.selectedPatient)}
                </div>
            </div>
            }
        </Observer>
    );
}

export default DisplayMultiplePatients