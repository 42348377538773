import React from "react"
import moment from "moment"
import {observer} from "mobx-react"
import MesureStore from '../MesureStore'
import BorderedDisplay from "../../../Shared/UiKit/BorderedDisplay";
import PreviousMeasureModal from "./PreviousMeasureModal";

@observer
class AnciennesMesures extends React.Component {
    constructor(props) {
        super(props);
        this.load = this.load.bind(this)
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.state = {
            open: false,
            prescription: "",
            comment: "",
            private_comment: "",
            start: moment.now()
        };
    }

    openModal() {
        this.setState({open: true});
    }

    closeModal() {
        this.setState({open: false});
    }

    load(e) {
        let appIndex = e.target.attributes.getNamedItem("data-appindex").value
        let typedemesure_id = e.target.value
        MesureStore.typedemesure = typedemesure_id
        MesureStore.importFromOtherAppointment(typedemesure_id, appIndex)
        MesureStore.loadKindsMesures()
        this.props.importMesure(typedemesure_id)
    }


    render() {
        return (
            <div style={{margin: "20px 0"}}>
                <BorderedDisplay text={"Anciens RDV"}>
                    <table className="striped bordered">
                        <tbody>
                        {MesureStore.last_appointements.map((app) => (
                            <tr key={app.id}>
                                <PreviousMeasureModal appointment_id={app.id}
                                                      text={moment(app.start).format("DD-MM-YYYY")}/>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </BorderedDisplay>
            </div>
        );
    }
}

export default AnciennesMesures
