import React, { Component } from 'react';
import ImageModal from "./ImageModal"
export default class Master extends Component {
  constructor(props) {
    super(props);
    this.shareImage = this.shareImage.bind(this)
    this.startCable = this.startCable.bind(this)
    this.state = {
      current: null
    }
    this.startCable()
  }
  startCable() {
    var that = this;
    App.board = App.cable.subscriptions.create({channel: 'WhiteboardChannel'}, {
          received: function(data) {
            if(data["target"]=="image"){
              if(data["url"] != ""){
                that.setState({
                  current: data['url']
                })
                App.modal.open()
              }
            }
        }
    })
  }
  sendCloseValue(){
    App.board.send({target: "image",url: ''})

  }
  componentDidMount(){
    M.Modal._count = 0;
    var elems = document.querySelectorAll('.modal');
    App.modal = M.Modal.init(elems, {
      onCloseStart: this.sendCloseValue
    })[0];
  }
  shareImage(e){
    e.preventDefault();
    var img = e.target.dataset.img

    App.board.send({target: "image",url: img})
  }
  render() {
    return (
      <div>
        {this.props.images.map((img,id)=>(
            <a href="#" onClick={this.shareImage} data-img={img} key={id}>
            <div className="col s12"  data-img={img}>
              <img
              className="materialboxed full-div-img"
              src={img}
              onClick={this.shareImage}
              data-img={img}
              />
            </div>
            </a>
          ))}

          <ImageModal image={this.state.current}/>

      </div>
    );
  }
}
