import React, {useEffect, useState} from "react";
import CVStore from "../CVStore";
import {observer, Observer} from "mobx-react";
import BorderedDisplay from "../../Shared/UiKit/BorderedDisplay";
import SelectPrescriptor from "../../Prescriptor/SelectPrescriptor";
import Preferences from "../../Shared/Preferences/Preferences";

const EditFse = observer((props) => {
    const _ = require('lodash');
    const firstAct = () => {
        if (CVStore?.fse?.acts?.length > 0) return CVStore.fse.acts[0]
        else return {}
    }
    const [selectedAct, setSelectedAct] = useState(firstAct())
    const [testUser, setTestUser] = React.useState(false);

    useEffect(() => {
        Preferences.get("test_user").then(pref => setTestUser(pref ?? false))
    })


    const computePrice = () => {
        return (CVStore?.fse?.acts?.reduce((acc, act) => {
            let assocCode = parseInt(act.associationCode)
            if ([1, 2].includes(assocCode)) return acc + act.prix / assocCode;
            else return acc
        }, 0) * ((CVStore.delayedAppointments?.length || 0) + 1)).toFixed(2)
    }

    const renderActList = (acts) => {
        const selected = _.isEmpty(selectedAct) ? (acts ? acts[0] : {}).titre : selectedAct.titre
        return (<div className="collection tt-act-collection" style={{borderRadius: "10px"}}>
            {acts.concat((new Array(6)).fill({})).slice(0, 6).map((act, index) =>
                <a key={index} style={{height: "42px"}}
                   className={`collection-item ${act.titre === selected ? "active" : ""} `}
                   onClick={() => _.isEmpty(act) ? null : setSelectedAct(act)}
                >
                    {act.titre}
                </a>
            )}
        </div>)
    }

    const renderAct = (act) => {
        if (_.isEmpty(act)) return null
        return (
            <div>
                <h2 className="center" style={{paddingTop: "1vh"}}>
                    {act.titre}
                </h2>
                <div className="row">
                    <div className="col s6">
                        <h6 style={{margin: 0, padding: "1vh"}}>Nomenclature</h6>
                        <h2 style={{
                            margin: 0,
                            padding: "1vh"
                        }}>NGAP</h2>
                    </div>
                    <div className="col s6">
                        <h6 style={{margin: 0, padding: "1vh"}}>Prix</h6>
                        <h2 style={{
                            margin: 0,
                            padding: "1vh"
                        }}>{act.prix}</h2>
                    </div>
                    <div className="col s6">
                        <h6 style={{margin: 0, padding: "1vh"}}>Code</h6>
                        <h2 style={{
                            margin: 0,
                            padding: "1vh"
                        }}>{act.actCode}</h2>
                    </div>
                    <div className="col s6">
                        <h6 style={{margin: 0, padding: "1vh"}}>Coefficient</h6>
                        <h2 style={{
                            margin: 0,
                            padding: "1vh"
                        }}>{act.coefficient}</h2>
                    </div>
                </div>
            </div>)
    }

    const renderPrescriptorPanel = () => {
        // Display only if user tester TODO: remove
        if (!testUser) return null
        return <BorderedDisplay text="Prescription">
            <div style={{display: "flex", flexDirection: "column", gap: 20}}>
                <div className="valign-wrapper" style={{gap: 20}}>
                    <h6 style={{whiteSpace: "nowrap"}}>Date de la prescription</h6>
                    <input type="date" style={{borderRadius: 4, border: "1px solid #707070"}}
                           onChange={e => CVStore.prescriptionDate = e.target.value}
                    />
                </div>
                <SelectPrescriptor selectedPrescriptor={CVStore.selectedPrescriptor}
                                   onSelect={(prescriptor) => CVStore.selectedPrescriptor = prescriptor}
                />
            </div>
        </BorderedDisplay>
    }

    const selected = _.isEmpty(selectedAct) ? (CVStore?.fse?.acts ? CVStore?.fse?.acts[0] : {}) : selectedAct
    return <div style={{display: "flex", flexDirection: "column", height: "100%", gap: 20}}>
        <div className="row" style={{gap: 20}}>
            <div className="col xl5 s12" style={{display: "flex", flexDirection: "column", gap: 20}}>
                <BorderedDisplay text="Mes informations">
                    {/*<h5>*/}
                    {/*    {`RPPS : ${CVStore.fse.rpps}`}*/}
                    {/*</h5>*/}
                    <h5>
                        {`Num. Facturation : ${CVStore.fse.adeli}`}
                    </h5>
                    <h5>
                        {`Num. Sécu. Patient : ${CVStore.fse.patient_ssn}`}
                    </h5>
                </BorderedDisplay>
                {renderPrescriptorPanel()}
            </div>

            <div className="col xl7 s12">
                <BorderedDisplay text="Actes">
                    {CVStore?.fse?.acts?.length > 0 ? (
                        <div className="row">
                            <div className="col s4">
                                {renderActList(CVStore.fse.acts)}
                            </div>
                            <div className="col s8 card z-depth-0" style={{borderRadius: "10px"}}>
                                {renderAct(selected)}
                            </div>
                        </div>
                    ) : (<h4 className="center">Aucun acte renseigné</h4>)}
                </BorderedDisplay>
            </div>
        </div>
        <div style={{width: "100%", marginTop: "auto", display: "flex"}}>
            <div style={{display: "flex", flexDirection: "column"}}>
                <h2>{`Quantité : ${(CVStore.delayedAppointments?.length ?? 0) + 1}`}</h2>
                <h2>{`Total à régler : ${computePrice()}€`}</h2>
            </div>
            <div style={{display: "flex", flexDirection: "column", gap: 10, marginLeft: "auto", alignSelf: "flex-end"}}>
                {props.onClick ?
                    <a className={`btn blue darken-2 z-depth-0 ${CVStore?.fse?.acts?.length === 0 ? "disabled" : ""}`}
                       onClick={() => props.onClick()}
                    >
                        <i className="material-icons right">rss_feed</i>
                        Créer fiche de soin
                    </a> : null
                }
            </div>
        </div>
    </div>
})

export default EditFse