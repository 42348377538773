import {observer} from "mobx-react";
import React, { Component } from 'react';
import BridgeStore from "../../BridgeStore";


@observer
export default class Form extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.renderPrerenderedMachine = this.renderPrerenderedMachine.bind(this)
        this.addMachine = this.addMachine.bind(this)
        this.state = {
            invalid:true,
            value: '',
            marque:'',
            modele:'',
            baudrate: 0,
            eot:'',
            eol:'',
            eol_bin: false,
            eot_bin:false,
            parser:0,
        };


    }



    renderPrerenderedMachine(){
        return(
            <div className="row">
                <div className="col s6">
                    <div className="form-group">
                        <label>Marque</label>
                        <input type="text" className="form-control" value={this.state.marque} readOnly={true} required/>
                    </div>
                </div>
                <div className="col s6">
                    <div className="form-group">
                        <label>Modèle</label>
                        <input type="text" className="form-control" value={this.state.modele} readOnly={true} required/>
                    </div>
                </div>
                <div className="col s6">
                    <div className="form-group">
                        <label>End of line</label>
                        <input type="text" className="form-control" value={this.state.eol}  readOnly={true} required/>
                    </div>
                </div>
                <div className="col s6">
                    <div className="form-group">
                        <label>End of transmission</label>
                        <input type="text" className="form-control" value={this.state.eot} readOnly={true} required/>
                    </div>
                </div>
                <div className="col s8">
                    <div className="form-group">
                        <label>Baudrate</label>
                        <input type="number" className="form-control" value={this.state.baudrate} readOnly={true} required/>
                    </div>
                </div>
                <div className="col s4">
                    <a href="/orthoptistes/v2/bridge" onClick={this.addMachine} disabled={this.state.invalid} className="btn btn-large mb-2 align-bottom" style={{margin: 10}}><i className="material-icons right">send</i>Ajouter</a>
                </div>
            </div>)
    }

    handleChange(event) {
        let value = event.target.value
        if (event.target.value ===""){
            this.setState({
                invalid:true,
                value: value,
                marque:"",
                modele:"",
                baudrate:0,
                eot:0,
                eol:0,
                eot_bin:false,
                eol_bin:false,
                parser:0

            });
        }
        else{
            this.setState({
                invalid:false,
                value: value,
                marque:this.props.db_machines[value].marque,
                modele:this.props.db_machines[value].modele,
                baudrate:this.props.db_machines[value].baudrate,
                eot:this.props.db_machines[value].eot,
                eol:this.props.db_machines[value].eol,
                eot_bin: this.props.db_machines[value].eot_bin,
                eol_bin: this.props.db_machines[value].eol_bin,
                parser: this.props.db_machines[value].parser
            });
        }


    }

    addMachine(e){
        e.preventDefault()
        BridgeStore.addNewMachine(this.state.baudrate, this.props.usb.desc, this.props.usb.hwid, this.state.marque, this.state.modele, this.state.eol, this.state.eot, this.props.usb.port, this.state.eot_bin, this.state.eol_bin, this.state.parser,()=>{window.location.replace("/orthoptistes/v2/bridge")})
    }

    render() {
        return (
            <div>
                <div className="form-group">
                    <select onChange={this.handleChange} value={this.state.value} className="browser-default">
                        <option value="">Marque et modele de la machine</option>
                        {this.props.db_machines.map((mesure,index)=>(
                            <option key={index} value={index}>{mesure.marque + " " + mesure.modele}</option>
                        ))}
                    </select>
                </div>
                {this.renderPrerenderedMachine()}
            </div>
        );
    }
}

