import React, { Component } from 'react';
import axios from "axios"
//import {Annotator} from 'image-labeler-react';

export default class AnalyseImage extends Component {
  constructor(props){
    super(props);
    this.loadImage = this.loadImage.bind(this);
    this.imageID = this.imageID.bind(this);
    this.imagetag = this.imagetag.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      images: [],
      loading: true,
      response: [],
      alerte_id: ""
    }
    this.loadImage()
  }
  loadImage(){
    axios.get("/analystes/images/get").then((data)=>{
      this.setState({
        images: data.data.images,
        alerte_id: data.data.alerte_id,
        loading: false,
        response: []
      })
    })
  }
  handleClick(e){
    let action = e.target.dataset.action
    let image_id = e.target.dataset.image_id
    let new_images = [...this.state.images]

      new_images.splice(0,1)
    let response = [...this.state.response,{image_id: image_id,etat: action}]
    this.setState({
      images: new_images,
      response: response
    })
    if(new_images.length== 0){
    axios.post("/analystes/images/classify",{
      alerte_id: this.state.alerte_id,
      reponse: response
    }).then((data)=>{
      this.loadImage()
    })
    }
  }
  imageID(){
    if(this.state.images.length>0){
      return(this.state.images[0].id)
    }else{
      return(-1)
    }
  }
  imagetag(){
  if(this.state.images.length>0){
      return(
        <div>
            {/*<Annotator
            height={600}
            width={600}
            imageUrl={this.state.images[0].url}
            asyncUpload={async (labeledData)=>{

                if (labeledData.boxes.length == 0){
                    let conf = window.confirm("Votre analyse ne comporte pas de label êtes vous sûr de vouloir l'uploader ?")
                    if (!conf){
                        return Promise<null> ret;
                    }
                }
              let image_id=this.state.images[0].id
              let action = "alerte"
              let new_images = [...this.state.images]
              new_images.splice(0,1)
              let response = [...this.state.response,{
                image_id: image_id,
                etat: action,
                boxes: labeledData.boxes,
                height: labeledData.height,
                width: labeledData.width}
              ]
              this.setState({
                images: new_images,
                response: response
              })
                console.log(new_images.length)

              if(new_images.length== 0){
              axios.post("/analystes/images/classify",{
                alerte_id: this.state.alerte_id,
                reponse: response
              }).then((data)=>{
                this.loadImage()
              })
              }
            }}
            types={["glaucome","lésion","kérato"]}
            /> */}
        </div>
        )
    }
  }
  render() {
    let url = "/analystes/images/all"
    return (
      <div>
        <div className="container">
            <div className="row">

                <div className="col s10">

                    <div className="center">
                        {this.imagetag()}
                    </div>
                    {this.state.loading || this.state.images.length<=0 ? (
                        <div className='row'>
                            <p className="center">Chargement en cours</p>
                        </div>
                    ) : (
                        <div className='row' style={{margin:10}}>
                            <div className="col s4 center">
                                <a onClick={this.handleClick}
                                   data-action="indefini"
                                   data-image_id={this.imageID()}
                                   className="btn orange"> INDEFINI</a>
                            </div>

                            <div className="col s4 center">
                                <a onClick={this.handleClick}
                                   data-action="ok"
                                   data-image_id={this.imageID()}
                                   className="btn green"> OK</a>
                            </div>

                            <div className="col s4 center">
                                <a onClick={this.handleClick}
                                   data-action="inutile"
                                   data-image_id={this.imageID()}
                                   className="btn black"> INUTILE</a>
                            </div>
                        </div>
                    )}
                </div>
                <div className="col s2" style={{marginTop:40}}>
                    <div className="center">
                        <a href={url} className="btn"> <i
                            className="material-icons left">sentiment_satisfied_alt</i>Mes analyses</a>
                    </div>
                </div>
            </div>
        </div>
      </div>
    );
  }
}
