import React, {useState} from 'react';
import Error from "../Shared/Error";
import Helper from "../Shared/Helper";
import axios from "axios";

function Recovery({patients, offices, temp_key}) {
    if (!patients || !offices) {
        return <div className="center" style={{marginTop: 200}}>
            <Error errorText="Le lien que vous avez suivi a malheureusement expiré"/>
            <a className="btn blue darken-2 z-depth-0" href="/" style={{margin: 40}}><i
                className="material-icons left">chevron_left</i>Retour à la page de connexion</a>
        </div>;
    }

    const [selectedPatientId, setSelectedPatientId] = useState(patients.length === 1 ? patients[0].id : null);
    const [selectedOfficeId, setSelectedOfficeId] = useState(offices.length === 1 ? offices[0].id : null);

    const connect = () => {
        if (!selectedOfficeId || !selectedPatientId) {
            return;
        }

        axios.get('recovery/connect', {params: {
            office_id: selectedOfficeId,
            patient_id: selectedPatientId,
            temp_key: temp_key
        }}).then(_ => window.location = '/list')
    }

    const renderPatientInput = () => {
        if (patients.length > 1) {
            return <div className="input-field" style={{minWidth: 300}}>
                <select id="office" className="browser-default" onChange={e => setSelectedPatientId(e.target.value)}>
                    <option value="" disabled selected>Choisissez votre nom</option>
                    {patients.map(patient => <option key={patient.id}
                        value={patient.id}>{Helper.formatName(patient.prenom, patient.nom)}</option>)}
                </select>
            </div>
        }
        return null
    }

    const renderOfficeInput = () => {
        if (offices.length > 1) {
            return <div className="input-field" style={{minWidth: 300}}>
                <select id="office" className="browser-default" onChange={e => setSelectedOfficeId(e.target.value)}>
                    <option value="" disabled selected>Choisissez votre cabinet</option>
                    {offices.map(office => <option key={office.id}
                        value={office.id}>{office.titre}</option>)}
                </select>
            </div>
        }
        return null
    }

    return (
        <div>
            <h1 className="center">Qui êtes vous ?</h1>
            <h6 className="center">Plusieurs profils semblent correspondre à votre adresse email, aidez-nous à vous
                retrouver !</h6>
            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                {renderPatientInput()}
                {renderOfficeInput()}
                <a className="btn blue darken-2 z-depth-0" style={{marginTop: 10}}
                   disabled={!selectedPatientId && !selectedOfficeId} onClick={connect}>Valider</a>
            </div>

        </div>
    );
}

export default Recovery;