import Helper from "../Shared/Helper";
import ConvoqueBox from "../secretariat/alerts/ConvoqueBox";
import HonoreBox from "../secretariat/alerts/HonoreBox";
import SecretaryComment from "../secretariat/alerts/SecretaryComment";
import React from "react";


export default class SelectedAlert extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="container" style={{width: "80%"}}>
                <div style={{borderRadius: "10px"}}
                     className={`card ${this.props.findCorrespondingAttribute("secondary_color", this.props.selected_alert["alert"]["gravity"])}`}>
                    <div className="card-content black-text">
                                        <span className="card-title"
                                              style={{position: "absolute", right: "10px", top: "10px"}}>
                                            <i className="material-icons right" style={{cursor: "pointer"}}
                                               onClick={this.props.deSelectAlert}>close</i>
                                            <i className="material-icons activator right"
                                               style={{cursor: "pointer"}}>more_vert</i>
                                        </span>
                        <br/>
                        <div className="row">
                            <div className="col s3 left" style={{textAlign: "left"}}>
                                <a
                                    href={`/patients/${this.props.selected_alert["patient"]["id"]}`}>
                                    <div
                                        className={this.props.findCorrespondingAttribute("text_color", this.props.selected_alert["alert"]["gravity"])}
                                        style={{fontSize: 20}}>
                                        <b>{this.props.selected_alert["patient"]["last_name"]} {this.props.selected_alert["patient"]["first_name"]}</b>
                                    </div>
                                </a>
                                <ul>
                                    <li>Age: {this.props.selected_alert["patient"]["birthday"] ? Helper.dateToAge(this.props.selected_alert["patient"]["birthday"]) + " ans" : "Non renseigné"}</li>
                                    <li>Téléphone: {this.props.selected_alert["patient"]["phone"] ? this.props.selected_alert["patient"]["phone"] : "Non renseigné"}</li>
                                    <li>Vu
                                        par: {this.props.selected_alert["appointment"]["orthoptist_full_name"] ? this.props.selected_alert["appointment"]["orthoptist_full_name"] : "Non renseigné"}</li>
                                    <li>Le: {this.props.selected_alert["alert"]["created_at"] ? this.props.selected_alert["alert"]["created_at"] : "Non renseigné"}</li>
                                </ul>
                            </div>
                            <div className="col s3">
                                {this.props.selected_alert["appointment"]["pathologies"].length ?
                                    <div>
                                        <b>Pathologies soupsçonnées:</b>
                                        <ul>
                                            <div style={{textAlign: "left"}}>
                                                {this.props.selected_alert["appointment"]["pathologies"].map((pathology, index) => {
                                                    return <li key={index}> - {pathology}</li>
                                                })}
                                            </div>
                                        </ul>
                                    </div>
                                    :
                                    <b>Aucune pathologie soupçonnée</b>
                                }
                            </div>
                            <div className="col s3 center">
                                <a href={`/syntheses/rendez-vous/${this.props.selected_alert["appointment"]["id"]}`}
                                   className={`z-depth-2 flat ${this.props.findCorrespondingAttribute("button_color", this.props.selected_alert["alert"]["gravity"])} btn`}
                                   style={{borderRadius: "20px"}}
                                >Synthèse du RDV</a>
                                <ul style={{textAlign: "left", paddingLeft: "20%"}}>
                                    <li><ConvoqueBox alert={this.props.selected_alert["alert"]["id"]}
                                                     checked={this.props.selected_alert["alert"]["convoked"]}
                                                     onChange={(checked) => this.props.handleChecked("convoked", checked, this.props.selected_alert["id"])}
                                    />
                                    </li>
                                    <li><HonoreBox alert={this.props.selected_alert["alert"]["id"]}
                                                   checked={this.props.selected_alert["alert"]["honored"]}
                                                   onChange={(checked) => this.props.handleChecked("honored", checked, this.props.selected_alert["id"])}
                                    />
                                    </li>
                                </ul>
                            </div>
                            <div className="col s3 center center">
                                <a className={`btn-floating ${this.props.findCorrespondingAttribute("button_color", this.props.selected_alert["alert"]["gravity"])}`}
                                   href={`/secretariat/email?patient=${this.props.selected_alert["patient"]["id"]}`}
                                ><i className="material-icons-outlined">email</i></a>
                                <SecretaryComment alert_id={this.props.selected_alert["alert"]["id"]}
                                                  comment={this.props.selected_alert["alert"]["secretary_comment"]}
                                                  onChange={this.props.handleSecretaryCommentChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        className={`card-reveal ${this.props.findCorrespondingAttribute("secondary_color", this.props.selected_alert["alert"]["gravity"])}`}
                        style={{textAlign: "center"}}>
                        <span className="card-title"
                              style={{position: "absolute", right: "10px", top: "10px"}}>
                                            <i className="material-icons right" style={{cursor: "pointer"}}>close</i>
                        </span>
                        <div className="row">
                            <div className="col s5" style={{textAlign: "left"}}>
                                <a href={`/patients/${this.props.selected_alert["patient"]["id"]}`}
                                   className={this.props.findCorrespondingAttribute("text_color", this.props.selected_alert["alert"]["gravity"])}
                                   style={{fontSize: 20}}>
                                    <b>{this.props.selected_alert["patient"]["last_name"]} {this.props.selected_alert["patient"]["first_name"]}</b>
                                </a>
                                <ul>
                                    <li>Nom: {this.props.selected_alert["patient"]["last_name"]} </li>
                                    <li>Prénom: {this.props.selected_alert["patient"]["first_name"]} </li>
                                    <li>Email: {this.props.selected_alert["patient"]["email"]} </li>
                                    <li>Téléphone: {this.props.selected_alert["patient"]["phone"]} </li>
                                    <li>Date de
                                        naissance: {Helper.formatDate(this.props.selected_alert["patient"]["birthday"])} </li>
                                    <li>Age: {this.props.selected_alert["patient"]["birthday"] ? Helper.dateToAge(this.props.selected_alert["patient"]["birthday"]) + " ans" : "Non renseigné"}</li>
                                </ul>

                            </div>
                            <div className="col s4" style={{textAlign: "left"}}>
                                <b>Détails du rendez-vous: </b>
                                <ul>
                                    <li>Montant du
                                        rendez-vous: {this.props.selected_alert["appointment"]["amount_due"]}€
                                    </li>
                                    <li>Vu
                                        par: {this.props.selected_alert["appointment"]["orthoptist_full_name"] ? this.props.selected_alert["appointment"]["orthoptist_full_name"] : "Non renseigné"}</li>
                                    <li>Le: {this.props.selected_alert["alert"]["created_at"] ? this.props.selected_alert["alert"]["created_at"] : "Non renseigné"}</li>
                                    <li>Gravité: {this.props.findCorrespondingAttribute("name", this.props.selected_alert["alert"]["gravity"])}</li>
                                </ul>
                            </div>
                            <div className="col s3 center">
                                <a className="waves-effect waves-light red btn"
                                   onClick={() => {
                                       this.props.archivateAlert()
                                   }}
                                >Archiver</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}