import React, { Component } from 'react';

export default class ImageModal extends Component {
  constructor(props){
    super(props)
    this.state={}

  }
  render() {
    return (
      <div>
         <div id="image-modal" className="modal image-modal-meet">
              <div className="modal-content">
              <div className="row">
                <div className="col s12">
                    <img className="responsive-img" src={this.props.image} />
                </div>
              </div>
              <div className="modal-footer">
                <a href="#!" className="modal-close waves-effect waves-green btn-flat">Fermer</a>
              </div>
            </div>
          </div>
      </div>
    );
  }
}
