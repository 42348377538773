import React, {useEffect, useState} from "react";
import IndividualNumber from "./IndividualNumber";
import PinInput from "react-pin-input";

function Pincode({onChange, initialValue,onComplete}) {
    return <div style={{display: "flex"}}>
        <PinInput length={6}
                  focus={window.innerWidth > 992.99}
                  autoselect type="custom"
                  validate={value => {
                      if (/^[a-zA-Z0-9]+$/.test(value)) {
                          return value
                      } else return ""
                  }}
                  onChange={onChange}
                  onComplete={onComplete}
                  initialValue={initialValue}
        />
    </div>

}

Pincode.defaultProps = {
    onChange: () => null,
    onComplete: () => null,
    initialValue: ""
}

export default Pincode;