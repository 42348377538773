import React, {useEffect} from 'react'
import PropTypes from "prop-types";
function Switch(props) {
  return (
    <div className="switch">
      <label>
          {props.left}
        <input type="checkbox" id={props.id} checked={props.value} onChange={(e) => props.onChange(e.target.checked)}/>
        <span className="lever"></span>
          {props.right}
      </label>
    </div>
  )
}

Switch.propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
}

Switch.defaultProps = {
    left: "Off",
    right: "On"
}

export default Switch