import React from "react";
import ActsAnalyses from "./ActsAnalyses";

function ActsStatistics() {

    return (
        <div id="full-height" style={{display: "flex", flexDirection: "column", overflowY: "scroll"}}>
            <h3 className="valign-wrapper" style={{margin: 20}}><i className="material-icons left">preview</i>Analyses
            </h3>
            <div className="divider"/>
            <div >
                <ActsAnalyses/>
            </div>
            <div className="divider"/>
        </div>
    );
}

export default ActsStatistics