import React from "react";
import Cookies from 'universal-cookie';

// Element that authorize or rejects children considering scope
// Scope is defined by scope cookie and defines user roles
// Props :
//  scope : render if at least one user role is in that least
//          if scope is empty, everything's authorized
//  exclude : do not render if at least one user role is in that list
function ScopedElement(props) {
    const userScope = () => {
        const cookies = new Cookies();
        const userScope = cookies.get("scope")
        return userScope === undefined ? [] : userScope.split("&")
    }

    const rejected = () => {
        return props.hidden ||  userScope().filter((role) => props.exclude.includes(role)).length > 0
    }

    const authorized = () => {
        // authorized scope is empty : everything's authorized
        if (props.scope.length === 0) return true
        return userScope().filter((role) => props.scope.includes(role)).length > 0
    }

    if(rejected()) return null
    else if (authorized()) return props.children
    return null
}

ScopedElement.defaultProps = {
    scope: [],
    exclude: []
}

export default ScopedElement