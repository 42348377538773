import React, {Component} from 'react';
import {SCOPE} from "../Shared/Helper";

export default class AskFor2FA extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const elems = document.getElementById("2FA_modal");
        M.Modal.init(elems, {});
        M.Modal.getInstance(document.getElementById("2FA_modal")).open()
    }

    componentWillUnmount() {
        M.Modal.getInstance(document.getElementById("2FA_modal")).destroy()
    }

    render() {
        return (
            <div>
                <div id="2FA_modal" className="modal">
                    <div className="modal-content">
                        <h1 className="red-text center">ATTENTION</h1>
                        <p>Pour votre sécurité, {this.props.software} vous demande d'activer la double authentification.</p>
                        <p>Pour ce faire, merci de vous rendre dans le menu "Gestion 2FA" en haut à droite votre écran au d'appuyer sur le bouton si dessous</p>
                    </div>
                    <div className="modal-footer center-align">
                        <a href="/orthoptistes/administration#/two_factor_auth" className="btn-flat">Je m'en occupe</a>
                    </div>
                </div>
            </div>
        )
    }
}