import React, {useEffect} from 'react';
import axios from "axios";
import {toast} from "react-toastify";
import Loader from "../../../../Shared/Loader";
import {EmailTemplates} from "../../../../Shared/Enums/Enums";

import {useParams} from "react-router-dom";
import BorderedDisplay from "../../../../Shared/UiKit/BorderedDisplay";

function OphtalmologistEmails({ophtalmologist}) {
    const {id} = useParams();
    const [templates, setTemplates] = React.useState(Object.keys(EmailTemplates).reduce((acc, template) => {
        acc[template] = undefined
        return acc
    }, {}))
    const refs = [React.createRef(), React.createRef(), React.createRef()]
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    const loadData = () => {
        axios.get(`/admin/console/ophtalmologist/emails/${id}`).then((response) => {
            setTemplates(response.data.templates)
        }).catch((error) => {
            toast.error("Une erreur est survenue.")
        })
    }
    useEffect(() => {
        loadData()

    }, [id])

    useEffect(() => {
        for (let i = 0; i < refs.length; i++) {
            try {
                M.Forms.textareaAutoResize(refs[i].current)
            } catch (e) {
                //Ignored
            }
        }
    }, [refs[0], refs[1], refs[2]]);

    const saveTemplate = (template) => {
        axios.put("/admin/console/ophtalmologist/emails", {
            id: id,
            key: template,
            content: templates[template]
        }).then(_ => toast.success("Modifications enregistrées avec succès."))
            .catch(_ => toast.error("Une erreur est survenue."))
    }

    return (
        <div className="row" style={{width: "100%", padding: 20}}>
            {Object.keys(templates).map((template, index) => {
                const data = templates[template]
                return (
                    <div className="col s12 l4" key={index} style={{paddingLeft: "20px"}}>
                        <BorderedDisplay text={EmailTemplates[template].name}>
                            <h6>{EmailTemplates[template].description}</h6>
                            <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                                {
                                    data !== undefined ? <div className="input-field outlined">
                                            <textarea ref={refs[index]} id={template} className="materialize-textarea"
                                                      value={data}
                                                      onChange={(e) => {
                                                          const newTemplates = {...templates}
                                                          newTemplates[template] = e.target.value
                                                          setTemplates(newTemplates)
                                                      }}
                                                      style={{height: "200px"}}
                                            />
                                    </div> : <div className="col s12 l4 valign-wrapper" style={{height: "200px"}}>
                                        <Loader text="" size="small"/>
                                    </div>
                                }
                                <a className="btn blue darken-2 z-depth-0"
                                   onClick={() => saveTemplate(template)}>Enregistrer</a>
                            </div>
                        </BorderedDisplay>
                    </div>
                )
            })}
        </div>
    )
}

export default OphtalmologistEmails;