import React from "react";

function Alert({appointement}) {
    return (
        <div style={{ height: '100%' }}>
            <div className="valign-wrapper" style={{ justifyContent: 'center', gap: 20, marginBottom: 20 }}>
                <h2 className="center" style={{ margin: 0 }}>Alertes</h2>
            </div>
            <hr />
            { appointement.alert === null ?
                (
                    <div style={{display: "flex", justifyContent: "center", marginTop: "20px"}}>
                        <span style={{ color: "green", fontSize: "1.2em"}}>Aucune alerte n'a été signalée.</span>
                    </div>
                )
            :
                (
                    <div style={{ height: 'calc(100% - 70px)' }}>
                        <table className="striped bordered" style={{ width: '100%', height: '100%' }}>
                            <tbody>
                            <tr style={{ height: '20%' }}>
                                <th style={{width: "60px", marginLeft: "20px"}}>Gravité</th>
                                <td>{appointement.alert.comment.gravite}</td>
                            </tr>
                            <tr style={{ height: '50%' }}>
                                <th>Risques</th>
                                <td>{appointement.alert.comment.risque}</td>
                            </tr>
                            <tr style={{ height: '30%' }}>
                                <th>Examens à réaliser</th>
                                <td>{appointement.alert.comment.todo.replace(/[\[\]"]/g, '')}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                )
            }
        </div>
    )
}

export default Alert;