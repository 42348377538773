import React from 'react';
import MesureStore from "../../Appointments/Mesures/MesureStore";
import Helper from "../../Shared/Helper";

function AppointmentAlert() {
    const [expanded, setExpanded] = React.useState(true);

    if (!expanded) return <div className="red white-text center alert-consult"
                               style={{zIndex: 2, borderRadius: "0 20px 0 0", paddingBottom: 0, paddingLeft: 0}}>
        <a className="btn-small btn-floating transparent z-depth-0 flash" onClick={() => setExpanded(true)}>
            <i className="material-icons white-text">warning</i>
        </a>
    </div>

    return MesureStore.needAlert[0] ? (
        <div className="red white-text center alert-consult" style={{zIndex: 2, borderRadius: "0 20px 0 0"}}>
            <a style={{position: "absolute", top: 0, right: 0, padding: 0}}
               className="btn-small btn-floating transparent z-depth-0 tiny" onClick={() => setExpanded(false)}>
                <i className="material-icons white-text">close</i>
            </a>
            <div style={{position: "relative"}}>
                <div style={{paddingRight: 30}}>
                    <h5 style={{marginBlock: 5, fontSize: 16}}>
                        <b>{Helper.capitalizeFirstLetter(MesureStore.needAlert[1].join(" et ").toLowerCase())}</b>
                    </h5>
                    <p style={{margin: 6}}>
                        Veuillez cocher une<b> demande d'analyse prioritaire</b> <br/>dans l'onglet Pathologies<br/>
                    </p>
                </div>
            </div>
        </div>
    ) : (
        <div></div>
    )
}

export default AppointmentAlert;