import React, {Component} from 'react';
import {Line} from 'react-chartjs-2'
import {Chart, registerables} from 'chart.js'
import axios from "axios";
import Loader from "../../../Shared/Loader";

Chart.register(...registerables)

const options = {
    legend: {
        display: false,
    }
}
export default class NombreRdvs extends Component {
    constructor(props) {
        super(props)
        this.loadData = this.loadData.bind(this)
        this.state = {
            data: [],
            loading: true
        }
        this.loadData();
    }

    loadData() {
        axios.get("/admin/statistiques/api/lastRdvs").then((data) => {
            this.setState({
                data: data.data,
                loading: false
            })
        })
    }

    render() {
        return (
            <div className="horizontal" style={{justifyContent: "center"}}>
                {this.state.loading ? (
                    <div style={{width: "100%", height: "50vh", display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <Loader wide/>
                    </div>
                ) : (
                    <div>
                        <Line
                            width={window.screen.width * 0.9}
                            height={window.screen.height * 0.7}
                            options={{maintainAspectRatio: false}}
                            data={this.state.data} options={options}/>

                    </div>
                )}
            </div>

        );
    }
}
