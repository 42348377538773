import React, {useEffect} from "react"
import 'react-toastify/dist/ReactToastify.css'
import {Observer} from "mobx-react"
import CVStore, {CVState} from "./CVStore";
import SelectPatient from "./SelectPatient";
import DisplayPatient from "./DisplayPatient";
import DisplayMultiplePatients from "./DisplayMultiplePatients";
import ChooseMode from "./ChooseMode";
import EditFse from "./Fse/EditFse";
import {RequestState} from "../Shared/StateHelper";
import MesureStore from "../Appointments/Mesures/MesureStore";

const CarteVitaleButton = (props) => {

    useEffect(() => {
        mount(props.bridge_uid)
        const elems = document.getElementById('cv_modal');
        M.Modal.init(elems, {
            onCloseEnd: reset,
            startingTop: "10%",
            endingTop: "2%"
        })
        CVStore.appointment_id = MesureStore.appointement_id
        return () => {
            unmount()
            try{
                M.Modal.getInstance(document.getElementById('cv_modal')).destroy();
            }catch (e) {

            }
            CVStore.init()
            CVStore.unsubscribe()
        }
    }, [])

    const closeModal = () => {
        M.Modal.getInstance(document.getElementById('cv_modal'))?.close()
    }


    useEffect(() => {
        CVStore.delayedAppointments = props.delayedAppointments
    }, [props.delayedAppointments])

    const mount = () => {
        CVStore.connect(props.bridge_uid)
        CVStore.subscribe()
    }

    const reset = () => {
        // CVStore.reset()
    }

    const unmount = () => {
        CVStore.disconnect()
        // CVStore.reset()
    }

    const renderState = (state) => {
        switch (state) {
            case CVState.INITIAL:
                return <ChooseMode uid={props.bridge_uid}/>
            case CVState.SELECT_PATIENT:
                return <SelectPatient/>
            case CVState.DISPLAY_MULTIPLE_PATIENTS:
                return <DisplayMultiplePatients/>
            case CVState.DISPLAY_PATIENT:
                return <DisplayPatient uid={props.bridge_uid}/>
            case CVState.EDIT_FSE:
                return <EditFse uid={props.bridge_uid} orthoptist={props.orthoptist} onClick={() => CVStore.fseCreationRequest(props.bridge_uid, CVStore.delayedAppointments)}/>
            default:
                return null
        }
    }

    return (
        <Observer>
            {() => <React.Fragment>
                {CVStore.fseRequestState === RequestState.SUCCESS && closeModal()} {/*Close modal when request is successful*/}
                <a className={`btn modal-trigger z-depth-0 blue darken-2 ${props.disabled ? 'disabled' : ''}`}
                   style={{width: "100%", margin: 0}}
                   data-target="cv_modal"
                   onClick={() => CVStore.initFse(props.orthoptist, props.acts, props.patient, props.appointment_id)}>Télétransmission</a>
                <div id="cv_modal" className="modal modal-fixed-footer" style={{borderRadius: "20px", overflow: "hidden"}}>
                    <div className="modal-content"
                         style={{paddingBottom: 10}}>
                        <div style={{height: "100%"}}>
                            {renderState(CVStore.state)}
                        </div>
                    </div>
                    <div className="modal-footer"
                         style={{paddingRight: "2vw", display: "flex", justifyContent: "right"}}>
                        <div style={{padding: "0 1vw"}}>
                            <a className={`btn blue darken-2 z-depth-0 ${CVStore.previousStates.length === 0 ? "disabled" : ""}`}
                               style={{padding: "0 1vw"}} onClick={() => CVStore.back()}>Retour</a>
                        </div>
                        <a className="btn z-depth-0 red darken-2 modal-close">Annuler</a>
                    </div>
                </div>
            </React.Fragment>}
        </Observer>
    )
}

CarteVitaleButton.defaultProps = {
    disabled: true
}

export default CarteVitaleButton