import React from 'react';
import {usePDF} from "use-pdf";

const PDFViewer = ({ pdfData }) => {
    if(!pdfData) return null
    const { loading, error, url } = usePDF(pdfData);

    if (loading) {
        return <div>Rendering PDF...</div>;
    }

    if (error) {
        return <div>Error rendering PDF</div>;
    }

    return <iframe title="PDF" src={url} />;
};

export default PDFViewer;
