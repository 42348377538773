import React, {Component} from "react";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import Notification from "./Notification";
import {toast} from "react-toastify";
import {notification} from "antd";
import Loader from "../../Shared/Loader";
import BrandedElement from "../../Shared/BrandedElement";

const _ = require('lodash')
export default class NotificationSlider extends Component {
    constructor(props) {
        super(props);
        this.hoverOnNotif = this.hoverOnNotif.bind(this)
        this.open = this.open.bind(this)
        this.loadNotifs = this.loadNotifs.bind(this)
        this.fetchMore = this.fetchMore.bind(this)
        this.debounceMarkAsRead = _.debounce(this.markAsRead, 2000)
        this.state = {
            notifications: [],
            unreadCount: 0,
            hasMore: true,
            readNotifications: [],
            page: 0
        }
    }

    componentDidMount() {
        this.loadNotifs(false)
        const options = {
            edge: "right",
            draggable: "false",
            onCloseEnd: () => this.debounceMarkAsRead.flush()
        }
        var elem = document.getElementById('slide-notif');
        if (elem !== null) {
            M.Sidenav.init(elem, options);
        }
    }

    open() {
        document.getElementById("notif-header").scrollIntoView()
        M.Sidenav.getInstance(document.getElementById('slide-notif')).open()
    }

    componentWillUnmount() {
        var elem = document.getElementById('slide-notif');
        if (elem) {
            var instance = M.Sidenav.getInstance(elem);
            if (instance) instance.destroy();
        }
        this.debounceMarkAsRead.flush()
    }

    loadNotifs(fetchMore) {
        axios.get(`/notifications/orthoptist/notifications?page=${this.state.page + 1}`).then(resp => {
                this.setState({
                    notifications: this.state.notifications.concat(resp.data.notifications),
                    unreadCount: fetchMore ? this.state.unreadCount : resp.data.unreadCount,
                    hasMore: resp.data.hasMore,
                    page: this.state.page + 1
                })
            }
        ).catch(err => {
            if (!fetchMore) toast.error("Impossible de charger les notifications")
        })
    }

    fetchMore() {
        this.loadNotifs(true)
    }

    markAsRead() {
        if (this.state.readNotifications.length !== 0) {
            axios.patch("/notifications/orthoptist/notifications/read", {
                notifications: this.state.readNotifications
            }).then(resp => {
                this.setState({
                    readNotifications: []
                })
            }).catch(err => {
            })
        }
    }

    hoverOnNotif(notif) {
        if (notif.read === false) {
            this.setState({
                readNotifications: this.state.readNotifications.concat(notif.id),
                notifications: this.state.notifications.map((elem) => {
                    if (elem.id === notif.id) {
                        return Object.assign({}, elem, {read: true})
                    } else {
                        return elem
                    }
                }),
                unreadCount: this.state.unreadCount - 1
            }, () => {
                this.debounceMarkAsRead()
            })
        }
    }

    render() {
        if (this.state.notifications) {
            return (
                <BrandedElement temeoo>
                    <div>
                        <a className="btn black white-text z-depth-0 notification-button" onClick={this.open}>
                            <i className={`material-icons`}>notifications</i>
                            {this.state.unreadCount > 0 ? (
                                <span className="notification-badge">{this.state.unreadCount}</span>) : (<div/>)}
                        </a>
                        <ul id="slide-notif" className="sidenav black-text collection with-header" style={{height: "100vh"}}>
                            {/*style={{overflow: "auto"}}> /!*, width: "min-content"}}>*!/*/}
                            <li id="notif-header" className="collection-header">
                                <div className="center-align">
                                    <h3 style={{color: "#584EE6", margin: "1vh"}}>Notifications</h3>
                                </div>
                            </li>
                            {this.state.notifications.length === 0 ? (
                                <li className="collection-item">
                                    <div className="center-align">
                                        <h5 style={{color: "#584EE6", margin: "1vh"}}>Aucune notification</h5>
                                    </div>
                                </li>
                            ) : (
                                <InfiniteScroll
                                    dataLength={this.state.notifications.length}
                                    next={this.fetchMore}
                                    hasMore={this.state.hasMore}
                                    loader={<Loader size="small" text=""/>}
                                    scrollableTarget="slide-notif"
                                    style={{overflow: "hidden"}}
                                >
                                    {this.state.notifications.filter(notif => ["ALERT", "BILLING_ERROR", "INVOICE_READY", "STRIPE_NOT_INITIALIZED"].includes(notif.alert_type))
                                        .map((notif, index) => <li
                                            className="collection-item"
                                            onMouseEnter={(e) => this.hoverOnNotif(notif)}
                                            key={index}
                                            style={{backgroundColor: `${notif.read === true ? "#FFFFFF" : "rgb(205, 202, 255)"}`}}>
                                            <Notification type={notif.alert_type} date={notif.created_at}/>
                                        </li>
                                        )}
                                </InfiniteScroll>)}

                        </ul>
                    </div>
                </BrandedElement>
            )
        } else return null;
    }
}