//import {Annotator} from "image-labeler-react"
import React, {Component} from "react";
/*
un composant permettant d afficher un oeil dans une card
verifie le css, ajoute dans all.html.erb ;-)
*/
export default class ShowEye extends Component{
  constructor(props){
    super(props)
  }
  imagetag(url,parsed_box){
  return (
  <div className="card-img-top" style={{pointerEvents: "none"}}>
      {/*<Annotator
        height={200}
        width={200}
        imageUrl={url}
        types={[]}
        showButton={false}
        defaultBoxes={parsed_box}
        disableAnnotation={true}

    /> */}
    </div>
    )
  }

  render(){
  let value = this.props.value
  let box = value.boxes
  let url = value.img_url
  let parsed_box = JSON.parse(box)
  let etat = value.etat
  let id = value.id
  let edit_url = "/analystes/images/edit?data_image=" + id
    return(
      <div className="col s4">
          <div className={"card etat-"+etat}>
              {this.imagetag(url, parsed_box)}
                  <div className="card-body">
                        <div className="btn_display">
                          <a href={edit_url} className="btn green">Edit</a>
                      </div>

                  </div>
          </div>
      </div>

    )
  }
}
