import {useState} from "react";
import socket from "../../../../../../Shared/socket";
import {displayMedocError} from "../../../../../../Shared/Enums/ErrorCodes";

const useCvRequest = () => {
    const [data, setData] = useState([]);
    const [meta, setMeta] = useState({});

    const sendCVRequest = (uid) => {
        return new Promise((resolve, reject) => {
            if (!uid) reject("No UID provided");

            socket.emit("cvInputRequest", {machine: uid});
            const timeout = setTimeout(() => {
                reject("Timeout")
            }, 20 * 1000)

            const responseHandler = (resp) => {
                if (resp.status === "success") {
                    console.debug("Received CV data: ", resp.data, resp.meta);
                    setData(resp.data);
                    setMeta(resp.meta);
                    resolve(resp);
                    clearTimeout(timeout)
                } else {
                    console.debug("Error on CV retrieving with error: ", displayMedocError(resp.errorCode))
                    reject(resp);
                    clearTimeout(timeout)
                }
                socket.off("cvInputResponse", responseHandler)
            }
            socket.on("cvInputResponse", responseHandler)
        })
    }

    return {sendCVRequest, data, meta}
}

export default useCvRequest;