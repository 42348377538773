import React from "react"
import {observer} from "mobx-react"
import MesureStore from '../MesureStore'
import PrescriptionStore from '../../PrescriptionStore'
import MesureOverview from "./MesureOverview";
import {TypesMesures} from "../../../Shared/Enums/Enums";
import {isLocked, LockingElement} from "../../AppointmentValidation";

const mesure_types = require("./MesureTypes.json")

@observer
class ActiveTabs extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hoveredId: undefined,
            selectedId: undefined
        }
    }

    componentDidMount() {
        PrescriptionStore.sourceData = MesureStore.typedemesure
    }

    render() {
        if (this.props.delete) {
            return (
                <div style={{display: "flex", flexDirection: "column"}}>
                    <h3 className="center">Types de mesures</h3>
                    <ul className="collection">
                        {MesureStore.existingMesures.map((mesure) => {
                            const type = mesure_types.find(type => TypesMesures[type.name] === mesure.titre)
                            const locked = mesure.titre === TypesMesures.REF_SUBJ && isLocked(LockingElement.LEFT_STENOPEIC_HOLE, LockingElement.RIGHT_STENOPEIC_HOLE)
                            return <li className="collection-item" key={mesure.id}
                                       onMouseEnter={() => this.setState({hoveredId: mesure.id})}
                                       onMouseLeave={() => this.setState({hoveredId: undefined})}
                                       data-cy="ExistingMeasure"
                                       style={{
                                           display: "flex",
                                           alignItems: "center",
                                           padding: 0,
                                           cursor: "pointer",
                                           backgroundColor: MesureStore.typedemesure === mesure.id ? "#d1dfff" : this.state.hoveredId === mesure.id ? "#eeeeee" : ""
                                       }}
                            >
                                <div style={{
                                    width: "100%",
                                    margin: "10px 20px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                }}
                                     onClick={() => this.setState({selectedId: mesure.id}, () => this.props.clickFunc(mesure))}>
                                    <span className={locked ? "red-text" : "blue-text"}>
                                        {Helper.capitalizeFirstLetter(mesure.titre)}
                                    </span>
                                    <MesureOverview mesure={mesure} type={type}/>
                                </div>
                                <a className="secondary-content btn-flat btn-small transparent"
                                   onClick={(e) => {
                                       e.preventDefault();
                                       this.props.delete(mesure)
                                   }}
                                   style={{marginLeft: "auto"}}
                                   data-cy="DeleteMeasureButton">
                                    <i className="material-icons">delete</i>
                                </a>
                            </li>
                        })}
                    </ul>
                </div>
            );
        } else {
            const mesures = MesureStore.existingMesures.filter(mesure => !mesure_types.find(type => TypesMesures[type.name] === mesure.titre)?.unprescriptable)
            return (
                <div style={{display: "flex", flexDirection: "column"}}>
                    <h3 className="center">Types de mesures</h3>
                    {MesureStore.typedemesure ?
                        <div style={{height: "2.3em"}}></div> :
                        <h6 className="red-text center" style={{marginBottom: "0.7em"}}>Aucune type de mesure n'est
                            sélectionné</h6>
                    }
                    <div className="collection" style={{margin: 0}}>
                        {mesures.map((mesure) => {
                            const type = mesure_types.find(type => TypesMesures[type.name] === mesure.titre)
                            return <div key={mesure.id} style={{
                                display: "flex",
                                alignItems: "center",
                                backgroundColor: MesureStore.typedemesure === mesure.id ? "#d1dfff" : this.state.hoveredId === mesure.id ? "#eeeeee" : ""
                            }}
                                        onMouseEnter={() => this.setState({hoveredId: mesure.id})}
                                        onMouseLeave={() => this.setState({hoveredId: undefined})}
                                        onClick={() => {
                                            this.setState({selectedId: mesure.id}, () => {
                                                this.props.clickFunc(mesure)
                                            })
                                        }} className="collection-item">
                                <div style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                }}
                                     onClick={() => this.setState({selectedId: mesure.id}, () => this.props.clickFunc(mesure))}>
                                    <span className="blue-text"> {Helper.capitalizeFirstLetter(mesure.titre)}</span>
                                    <MesureOverview mesure={mesure} type={type}/>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            );
        }
    }
}

export default ActiveTabs
