export const Filter = {
    EQUAL: "_eq",
    NOT_EQUAL: "_not_eq",
    MATCHES: "_matches",
    NOT_MATCHES: "_does_not_match",
    MATCHES_ANY: "_matches_any",
    MATCHES_ALL: "_matches_all",
    NOT_MATCHES_ANY: "_does_not_match_any",
    NOT_MATCHES_ALL: "_does_not_match_all",
    LESS_THAN: "_lt",
    LESS_THAN_OR_EQUAL: "_lteq",
    GREATER_THAN: "_gt",
    GREATER_THAN_OR_EQUAL: "_gteq",
    PRESENT: "_present",
    BLANK: "_blank",
    NULL: "_null",
    NOT_NULL: "_not_null",
    IN: "_in",
    NOT_IN: "_not_in",
    LESS_THAN_ANY: "_lt_any",
    LESS_THAN_OR_EQUAL_ANY: "_lteq_any",
    GREATER_THAN_ANY: "_gt_any",
    GREATER_THAN_OR_EQUAL_ANY: "_gteq_any",
    LESS_THAN_ALL: "_lt_all",
    LESS_THAN_OR_EQUAL_ALL: "_lteq_all",
    GREATER_THAN_ALL: "_gt_all",
    GREATER_THAN_OR_EQUAL_ALL: "_gteq_all",
    NOT_EQUAL_ALL: "_not_eq_all",
    START: "_start",
    NOT_START: "_not_start",
    START_ANY: "_start_any",
    START_ALL: "_start_all",
    NOT_START_ANY: "_not_start_any",
    NOT_START_ALL: "_not_start_all",
    END: "_end",
    NOT_END: "_not_end",
    END_ANY: "_end_any",
    END_ALL: "_end_all",
    NOT_END_ANY: "_not_end_any",
    NOT_END_ALL: "_not_end_all",
    CONTAINS: "_cont",
    CONTAINS_ANY: "_cont_any",
    CONTAINS_ALL: "_cont_all",
    NOT_CONTAINS: "_not_cont",
    NOT_CONTAINS_ANY: "_not_cont_any",
    NOT_CONTAINS_ALL: "_not_cont_all",
    CONTAINS_CASE_INSENSITIVE: "_i_cont",
    CONTAINS_ANY_CASE_INSENSITIVE: "_i_cont_any",
    CONTAINS_ALL_CASE_INSENSITIVE: "_i_cont_all",
    IS_TRUE: "_true",
    IS_FALSE: "_false",
}

export const Sort = {
    ASC: "_asc",
    DESC: "_desc",
}

/* filter example :
 {
    name: {
        Filter.EQUALS: "John"
    },
    date: {
        Filter.GREATER_THAN: "2020-01-01"
    }
}

Needs to be converted to :
{
    name_eq: "John",
    date_gt: "2020-01-01"
}
*/

export const construct_filter = (filter) => {
    return Object.entries(filter).reduce((acc, [key, value]) => {
        Object.entries(value).forEach(([filter_type, filter_value]) => {
            acc[key + filter_type] = filter_value
        })
        return acc
    }, {})
}


