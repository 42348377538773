import React, { Component } from 'react';

import BridgeStore from "./BridgeStore"
import Statut from "./Pages/Statut"
import {observer} from "mobx-react"
import Onboarding from "./OnBoarding/Onboarding"
import ParametreRefracteur from "./Pages/ParametreRefracteur"
import ParametreDirectory from "./Pages/ParametreDirectory"

@observer
export default class Bridge extends Component {
  constructor(props) {
    super(props);

    BridgeStore.init(props.bridge_ip,props.api_token,props.email)
  }
  render() {
    if(BridgeStore.ready){
      if(BridgeStore.onBoarding){
        return(
          <div>
          <Onboarding />
          </div>
          )
      }else{
      return (
        <div>
            <div className="row">
              <div className="col m12 s12 card white">
                <Statut />

              </div>
            </div>
              <div className="row">
                <div className="col s12 m6">
                  <ParametreRefracteur />
                </div>
                <div className="col s12 m6">
                  <ParametreDirectory />
                </div>
          </div>
          <div className="container">

            <a className="btn mauve right" onClick={(e)=>BridgeStore.onBoarding= true}>
            <i className="material-icons left">build</i>
            Relancer l'aide au démarrage
            </a>
          </div>
        </div>
      );
    }

    }else{
      return(
        <div>
          Initialisation
        </div>
      )
    }
  }
}
