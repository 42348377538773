import React from "react"
import PropTypes from "prop-types"
import axios from "axios"
import {JOB} from "./Shared/Helper";

class AddOrthoptiste extends React.Component {
    constructor(props) {
        super(props);
        this.saveAgenda = this.saveAgenda.bind(this)
        this.submit = this.submit.bind(this)
        this.state = {
            clicked: false,
            data: [],
            loading: true,
            done: false,
            email: "",
            pass: "",
            nom: "",
            prenom: "",
            cabinet_id: this.props.cabinet_id
        };
    }

    saveAgenda(e) {
        e.preventDefault()
        let id = e.target.id
        let cabinet = this.state.data[id]
    }

    componentDidMount() {
        var elems = document.getElementById("new_ortho_modal");
        var instances = M.Modal.init(elems, {});
    }

    componentWillUnmount() {
        M.Modal.getInstance(document.getElementById("new_ortho_modal")).destroy();
    }

    submit(e) {
        e.preventDefault()
        axios.post("/my_orthoptistes.json", {
            orthoptistes: {
                cabinet_id: this.state.cabinet_id,
                email: this.state.email,
                password: this.state.pass,
                nom: this.state.nom,
                prenom: this.state.prenom
            }
        }).then((data) => {
            location.reload()
        })
    }

    render() {
        return (
            <React.Fragment>
                <div>
                    <a className="waves-effect waves-light btn modal-trigger"
                       data-target="new_ortho_modal">Nouvel {JOB}</a>
                    <div id="new_ortho_modal" className="modal">
                        <div className="modal-content">
                            <h4>Veuillez remplir le formulaire</h4>
                            <form action="" onSubmit={this.submit}>
                                <div className="container">
                                    <div className="input-field">
                                        <input type="email" value={this.state.email}
                                               onChange={(e) => this.setState({email: e.target.value})}/>
                                        <label htmlFor="email">Email</label>
                                    </div>
                                    <div className="input-field">
                                        <input type="text" id="prenom" value={this.state.prenom}
                                               onChange={(e) => this.setState({prenom: e.target.value})}/>
                                        <label htmlFor="prenom">prenom</label>
                                    </div>
                                    <div className="input-field">
                                        <input type="text" id="nom" value={this.state.nom}
                                               onChange={(e) => this.setState({nom: e.target.value})}/>
                                        <label htmlFor="nom">nom</label>
                                    </div>
                                    <div className="input-field">
                                        <input type="text" id="pass" value={this.state.pass}
                                               onChange={(e) => this.setState({pass: e.target.value})}/>
                                        <label htmlFor="pass">Mot de passe</label>
                                    </div>
                                    <button className="btn waves-effect waves-light" type="submit" name="action">Valider
                                        <i className="material-icons right">send</i>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <a className="modal-close waves-effect waves-green btn-flat">Annuler</a>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default AddOrthoptiste
