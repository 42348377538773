import moment from "moment/moment";

export const User = {
    ORTHOPTIST: {name: "Orthoptiste", value: "orthoptist"},
    OPHTALMOLOGIST: {name: "Ophtalmologiste", value: "ophtalmologist"},
    SECRETARY: {name: "Secrétaire", value: "secretary"},
    EXTERN: {name: "Externe", value: "extern"},
    INTERNAL_USER: {name: "InternalUser", value: "internal_user"},
    PATIENT: {name: "Patient", value: "patient"},
}

export const EmailTemplates = {
    mail_prescription_before_validation: {
        name: "Examen",
        description: "Email envoyé au patient avant validation de la prescription"
    },
    mail_alert_ophtalmologiste: {
        name: "Alerte",
        description: "Email envoyé au patient si l'ophtalmologiste n'a pas validé la prescription"
    },
    mail_prescription: {name: "Validation", description: "Email envoyé au patient après validation de la prescription"},
}
export const Act = {
    RNM: "RNM",
    RNO: "RNO",
    AMY6_7: "Amy 6.7 (diabète)",
    AMY8_5: "Amy 8.5",
    AMY8_5_AMY_6_7_2: "Amy 8.5 + Amy 6.7/2",
    AMY8_AMY_6_7_2: "Amy 8 + Amy 6.7/2",
    AMY_8_5_HN: "Amy 8.5 + HN",
    AMY_15: "AMY15 + AO",
    AMY8_AO: "AMY8 + AO",
    DEPISTAGE: "Dépistage",
    NOTHING: "Rien | absent",
    ONLY_PRESCRITPION: "Prescription seule",
}

export const ActNature = {
    TELEMEDICAL: {
        code: 0,
        name: "TELEMEDICAL",
        short: "Télémédecine",
        description: "Actes de télémédecine et de télé-expertise"
    },
    ORTHOPTIC_EVALUATION: {
        code: 1,
        name: "ORTHOPTIC_EVALUATION",
        short: "Bilan orthoptique",
        description: "Bilans orthoptiques"
    },
    REEDUCATION: {code: 2, name: "REEDUCATION", short: "Rééducation", description: "Actes de rééducation"},
    FUNCTIONAL_EXPLORATION: {
        code: 3,
        name: "FUNCTIONAL_EXPLORATION",
        short: "Exploration fonctionnelle",
        description: "Actes d'exploration fonctionnelle"
    },
    NURSE: {code: 4, name: "NURSE", short: "Infirmerie", description: "Actes infirmiers"},
    SCREENING: {code: 5, name: "SCREENING", short: "Dépistage", description: "Actes de dépistage"},
}

export const Sort = {
    UP: "UP",
    DOWN: "DOWN",
    NONE: "NONE"
}
export const Side = {
    LEFT: "Gauche",
    RIGHT: "Droite",
}

export const TypesMesures = {
    TENSION: "Tension",
    PACHYMETRY: "Pachymétrie",
    LONGSIGHT_GLASSES: "Lunettes de loin",
    SHORTSIGHT_GLASSES: "Lunettes de près",
    PROGRESSIVE_GLASSES: "Lunettes progressives",
    LENSE_METER: "LM",
    NO_CORRECTION: "Sans correction",
    AUTOREF: "Autorefractomètre",
    REF_SUBJ: "Réfraction subjective",
    FINALE_REF: "Réfraction finale",
    SKIASCOPIE: "Skiascopie",
}

export const Mesures = {
    SPHERE: {name: "Sphère", value: "SPHERE"},
    CYLINDER: {name: "Cylindre", value: "CYLINDER"},
    AXIS: {name: "Axe", value: "AXIS"},
    ADDITION: {name: "Addition", value: "ADDITION"},
    ACUITY: {name: "AV loin", value: "ACUITY"},
    PARINAUD: {name: "Parinaud", value: "PARINAUD"},
    TENSION: {name: "Tension", value: "TENSION"},
    PACHYMETRY: {name: "Pachymétrie", value: "PACHYMETRY"},
    STENOPEIC_HOLE: {name: "av_trou_stenopeique", value: "STENOPEIC_HOLE"},
}

export const QuestionType = {
    TEXT: "TEXT",
    YES_NO: "YES_NO",
    INPUT: "INPUT",
}

export const Logical = {
    YES: "Oui",
    NO: "Non",
}

export const ActType = {
    NGAP: "NGAP",
    CCAM: "CCAM",
}

export const RouteurState = {
    LOADING: "LOADING",
    IN_APPOINTMENT: "IN_APPOINTMENT",
    TYPE_SELECTION: "TYPE_SELECTION", // Orthoptist choses if going for chained act or not
    ACT_SELECTION: "ACT_SELECTION", // Selecting act
    CHECK: "CHECK", // Checking if everything is ok
}

export const PrescriptionType = {
    GLASSES_AND_FRAME: "Monture + verres",
    LONG_SIGHTED_GLASSES: "Verres de loin",
    SHORT_SIGHTED_GLASSES: "Verres de près",
    PROGRESSIVE_GLASSES: "Verres progressifs",
    DEGRESSIVE_GLASSES: "Verres dégressifs",
    NO_CORRECTION: "Aucune correction",
    ORTHOPTIC_EVALUATION: "Bilan orthoptique",
    VISUAL_ACUITY_CHECK: "Contrôle d'acuité visuelle",
    SKIACOL: "Skiacol",
}

export const InvoiceStatus = {
    WAITING: "WAITING",
    PENDING: "PENDING",
    SUCCESS: "SUCCESS",
    FAILED: "FAILED",
}

export const TeletransmissionStatus = {
    WAITING: 0,
    PENDING: 1,
    SUCCESS: 2,
    FAILED: 3
}

export const SpecialtyCodes = {
    // Those first 4 are ther most used
    GENERAL_PRACTIONNER: {name: "Médecin généraliste", code: 1},
    OPHTALMOLOGIST: {name: "Ophtalmologiste", code: 15},
    PEDIATRIST: {name: "Pédiatre", code: 12},
    ORTHOPTIST: {name: "Orthoptiste", code: 29},
    // Other ones in code order
    ANESTHESIST: {name: "Anesthésiste", code: 2},
    CARDIOLOGIST: {name: "Cardiologue", code: 3},
    GENERAL_SURGEON: {name: "Chirurgien général", code: 4},
    DERMATOLOGIST: {name: "Dermatologue", code: 5},
    RADIOLOGIST: {name: "Radiologue", code: 6},
    GYNECOLOGIST: {name: "Gynécologue", code: 7},
    GASTROENTEROLOGIST: {name: "Gastro-entérologue", code: 8},
    INTERNAL_MEDECINE: {name: "Médecin interne", code: 9},
    NEUROSURGEON: {name: "Neurochirurgien", code: 10},
    OTORHINOLARYNGOLOGIST: {name: "Oto-rhino-laryngologiste", code: 11},
    PNEUMOLOGIST: {name: "Pneumologue", code: 13},
    RHEUMATOLOGIST: {name: "Rhumatologue", code: 14},
    UROLOGIST: {name: "Urologue", code: 16},
    NEUROPSYCHIATRIST: {name: "Neuropsychiatre", code: 17},
    STOMATOLOGIST: {name: "Stomatologue", code: 18},
    DENTIST: {name: "Chirurgien dentaire", code: 19},
    REANIMATION: {name: "Réanimateur", code: 20},
    MIDWIFE: {name: "Sage-femme", code: 21},
    NURSE: {name: "Infirmier", code: 24},
    PSYCHOLOGIST: {name: "Psychologue/Psychothérapeute", code: 25},
    PHYSIOTHERAPIST: {name: "Masseur-kinésithérapeute", code: 26},
    PODIATRIST: {name: "Pédicure", code: 27},
    SPEECH_THERAPIST: {name: "Orthophoniste", code: 28},
    LABORATORY: {name: "Laboratoire d'analyses médicales", code: 30},
    PHYSICAL_MEDICINE: {name: "Médecine physique et de réadaptation", code: 31},
    NEUROLOGIST: {name: "Neurologue", code: 32},
    PSYCHIATRIST: {name: "Psychiatre", code: 33},
    GERIATRIST: {name: "Gériatre", code: 34},
    NEPHROLOGIST: {name: "Néphrologue", code: 35},
    DENTOFACIAL_ORTHOPEDIST: {name: "Orthopédiste Dento-Facial", code: 36},
    ANATOMY_PATHOLOGIST: {name: "Anatomopathologiste", code: 37},
    CLINICAL_BIOLOGIST: {name: "Biologiste médical", code: 38},
    POLYVALENT_LAB: {name: "Laboratoire polyvalent", code: 39},
    ANATOMOPATHOLOGY_LAB: {name: "Laboratoire d'anatomopathologie", code: 40},
    ORTHOPEDIC_TRAUMATOLOGIST_SURGEON: {name: "Chirurgien Orthopédiste Traumatologue", code: 41},
    ENDOCRINOLOGIST: {name: "Endocrinologue", code: 42},
    CHILD_SURGEON: {name: "Chirurgie infantile", code: 43},
    MAXILOFACIAL_SURGEON: {name: "Chirurgie maxillo-faciale", code: 44},
    MAXILOFACIAL_STOMATOLOGIST_SURGEON: {name: "Chirurgie maxillo-faciale et stomatologie", code: 45},
    AESTETHIC_SURGEON: {name: "Chirurgie plastique reconstructrice et esthétique", code: 46},
    THORACIC_CARDIOVASCULAR_SURGEON: {name: "Chirurgie thoracique et cardio-vasculaire", code: 47},
    VASCULAR_SURGEON: {name: "Chirurgie vasculaire", code: 48},
    VISCERAL_DIGESTIVE_SURGEON: {name: "Chirurgie viscérale et digestive", code: 49},
    PHARMACIST: {name: "Pharmacien", code: 50},
    MUTUAL_PHARMACIST: {name: "Pharmacie mutualiste", code: 51},
    HOSPITAL_PHARMACIST: {name: "Pharmacie hospitalière", code: 52},
    SANITARY_TRANSPORTER: {name: "Transporteur sanitaire", code: 55},
    NON_SANITARY_TRANSPORTER: {name: "Transporteur non sanitaire", code: 56},
    OTHER_TRANSPORTER: {name: "Autres transporteurs", code: 57},
    COMPANY: {name: "Société", code: 60},
    ARTISAN: {name: "Artisan", code: 61},
    ASSOCIATION: {name: "Association", code: 62},
    EXTERNAL_PROSTHESIS: {name: "Orthèses & prothèses externes non orthopédiques", code: 63},
    MEDICAL_OPTICIAN: {name: "Optique médicale", code: 64},
    AUDIO_PROSTHESIS: {name: "Audioprothèses", code: 65},
    OCULAR_FACIAL_PROSTHESIS: {name: "Prothèses oculaires et faciales", code: 66},
    PODO_ORTHESIS: {name: "Podo-orthèses", code: 67},
    ORTHOPROSTHESIS: {name: "Orthoprothèses", code: 68},
    MEDICAL_GYNECOLOGY: {name: "Gynécologie médicale", code: 70},
    HEMATOLOGY: {name: "Hématologie", code: 71},
    NUCLEAR_MEDICINE: {name: "Médecine nucléaire", code: 72},
    MEDICAL_ONCOLOGY: {name: "Oncologie médicale", code: 73},
    RADIOTHERAPY_ONCOLOGY: {name: "Oncologie radiothérapique", code: 74},
    CHILD_ADOLESCENT_PSYCHIATRY: {name: "Psychiatrie de l'enfant et de l'adolescent", code: 75},
    RADIOTHERAPY: {name: "Radiothérapie", code: 76},
    OBSTETRICS: {name: "Obstétrique", code: 77},
    MEDICAL_GENETICS: {name: "Génétique médicale", code: 78},
    OBSTETRICS_MEDICAL_GYNECOLOGY: {name: "Gynécologie obstétrique et gynécologie médicale", code: 79},
    PUBLIC_HEALTH_SOCIAL_MEDICINE: {name: "Santé publique et médecine sociale", code: 80},
    TROPICAL_INFECTION_MEDICINE: {name: "Médecine des infections tropicales", code: 81},
    LEGAL_MEDICINE_MEDICAL_EXPERTISE: {name: "Médecine légale & expertises médicales", code: 82},
    EMERGENCY_MEDICINE: {name: "Médecine d'urgence", code: 83},
    VASCULAR_MEDICINE: {name: "Médecine vasculaire", code: 84},
    ALLERGOLOGY: {name: "Allergologie", code: 85},
}

export const PractitionerTypes = {
    INDEPENDENT: {name: "Libéral", code: 0},
    // DENTIST: {name: "Dentiste", code: 1}, // Not used, but Med'OC allows it.
    SALARIED: {name: "Salarié", code: 2},
    VOLUNTEER: {name: "Bénévole", code: 3},
}

export const InternalUserRole = {
    SUPER_ADMIN: {name: "Super Admin", value: "SUPER_ADMIN"},
    ADMIN: {name: "Admin", value: "ADMIN"},
    AUDITOR: {name: "Auditeur", value: "AUDITOR"},
    LOCKED: {name: "Désactivé", value: "LOCKED"},
}

export const UserRights = {
    USERS: {name: "Utilisateurs", value: "USERS"},
    ADMIN: {name: "Admin", value: "ADMIN"},
    APPOINTMENTS: {name: "Examens", value: "APPOINTMENTS"},
    BILLING: {name: "Tarifs", value: "BILLING"},
}

export const UserPermissions = {
    READ_WRITE: {name: "Lecture/Ecriture", value: "READ_WRITE"},
    READ_ONLY: {name: "Lecture seule", value: "READ_ONLY"},
    WRITE_ONLY: {name: "Ecriture seule", value: "WRITE_ONLY"}
}

export const UserStatus = {
    ACTIVE: {name: "Actif", value: "ACTIVE"},
    ARCHIVED: {name: "Archivé", value: "ARCHIVED"},
    LOCKED: {name: "Bloqué", value: "LOCKED"},
    TEST: {name: "Test", value: "TEST"}
}

export const LogTypes = {
    LOGIN: {name: "Connexions", value: "login", start_date: "15/06/2023"},
    SIGNOUT: {name: "Déconnexions", value: "sign_out", start_date: "26/09/2023"},
    OPHTALMOLOGIST_ACTION: {name: "Actions ophtalmologiste", value: "ophtalmologist_action", start_date: "15/06/2023"},
    UPDATE_PRESCRIPTION: {name: "Mises à jour de la prescription", value: "update_prescription", start_date: "09/07/2023"},
    SUBMIT_PRESCRIPTION: {name: "Envois de la prescription", value: "submit_prescription", start_date: "26/09/2023"},
    PATIENT_HN_PAYED: {name: "Paiement HN patient", value: "patient_hn_payed", start_date: "26/10/2023"}

}

export const Application = {
    TEMEOO: "TEMEOO",
    EXPERT_VISION: "ExpertVision",
    POINT_VISION: "PointVision"
}

export const TimeRange = {
    TODAY: {
        name: "Aujourd'hui",
        start: () => moment().format("YYYY-MM-DD"),
        end: () => moment().format("YYYY-MM-DD"),
        value: "TODAY"
    },
    WEEK: {
        name: "Cette semaine",
        start: () => moment().startOf('week').format("YYYY-MM-DD"),
        end: () => moment().endOf('week').format("YYYY-MM-DD"),
        value: "WEEK"
    },
    MONTH: {
        name: "Ce mois",
        start: () => moment().startOf('month').format("YYYY-MM-DD"),
        end: () => moment().endOf('month').format("YYYY-MM-DD"),
        value: "MONTH"
    },
    LAST_MONTH: {
        name: "Le mois dernier",
        start: () => moment().subtract(1, 'month').startOf('month').format("YYYY-MM-DD"),
        end: () => moment().subtract(1, 'month').endOf('month').format("YYYY-MM-DD"),
        value: "LAST_MONTH"
    },
    YEAR: {
        name: "Cette année",
        start: () => moment().startOf('year').format("YYYY-MM-DD"),
        end: () => moment().endOf('year').format("YYYY-MM-DD"),
        value: "YEAR"
    }
}

export const ConversationType = {
    GROUP: {value: "GROUP", name: "Conversation de groupe"},
    PRIVATE: {value: "PRIVATE", name: "Conversation privée"}
}

export const MessageLevelAlert = {
    NOTHING: "rien",
    BENIGN: "bénin",
    MEDIUM: "moyen",
    SEVERE: "grave",
    RPD: "présence de rpd",
    NO_RPD: "pas de rpd",
    REDIRECT: "redirection",
    MAIL: "mail",
    MAIL_SMS: "mail-sms",
}
