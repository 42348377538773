import React, {Component} from 'react';
import ShowConversation from "./ShowConversation"
import SendMessage from "./SendMessage"
import Popup from "./Popup"
import MessagesStore from "./MessagesStore";

export default class Orthoptiste extends Component {
    componentDidMount() {
        MessagesStore.loadMessages(undefined, this.props.appointement_id)
        MessagesStore.messagesRead(undefined, this.props.appointement_id)
    }

    render() {
        return (
            <div style={{margin: 10}}>
                <Popup/>
                <h1 className="center"> Votre messagerie </h1>
                <div style={{display: "flex", flexDirection: "column", gap: 20}}>
                    <SendMessage type="orhtoptiste" appointement_id={this.props.appointement_id}/>
                    <ShowConversation target="orthoptiste" appointement_id={this.props.appointement_id}/>
                </div>
            </div>
        );
    }
}
