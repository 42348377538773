import React from "react";

/*disabled = {this.state.current_value || this.props.index !== this.props.current_index}*/

export default class IndividualNumber extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            current_value: "",
            accessed: false
        }
    }

    handleInputChange = (event) => {
        let value = event.target.value
        if (value.length) {
            this.props.handleValueChanges(this.props.index, value)
            event.target.nextSibling ? event.target.nextSibling.focus() : event.target.blur()
        }
        event.target.value = value
    }

    handleKeyPress = (event) => {
        if (event.key === "Backspace" && !this.state.current_value) event.target.previousSibling ? event.target.previousSibling.focus() : null
    }

    handleFocus = (event) => {
        if(event.target.value){
            event.target.value = ""
            this.setState({accessed: true, current_value: null})
            this.props.handleValueChanges(this.props.index, "")
        }
    }

    render() {
        return (
            <input
                className="pinlogin-field"
                maxLength={1}
                onKeyDown={this.handleKeyPress}
                onChange={this.handleInputChange}
                onFocus={this.handleFocus}
            />
        )
    }
}