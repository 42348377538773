import React, {Component} from 'react';
import MonoStatDisplay from "../MonoStatDisplay";
import axios from "axios";
import {toast} from "react-toastify";
import StatDoughnut from "../StatDoughnut";
import PercentDoughnut from "../PercentDoughnut";
import Loader from "../../../Shared/Loader";


const defaultState = {
    nbNewMessages: undefined,
    nbPendingPrescr: undefined,
    nbAlerts: undefined,
    prescriptionsByTime: undefined,
    quickPrescriptions: -1,
    recalledPatients: -1
}
export default class GeneralStatistics extends Component {
    constructor(props) {
        super(props)
        this.getNewMessages = this.getNewMessages.bind(this)
        this.getNbPrescriptions = this.getNbPrescriptions.bind(this)
        this.getNbAlerts = this.getNbAlerts.bind(this)
        this.getPrescriptionsByTime = this.getPrescriptionsByTime.bind(this)
        this.getQuickPrescription = this.getQuickPrescription.bind(this)
        this.getRecalledPatients = this.getRecalledPatients.bind(this)
        this.state = defaultState
    }

    componentDidMount() {
        this.getNewMessages()
        this.getNbPrescriptions()
        this.getNbAlerts()
        this.getPrescriptionsByTime()
        this.getQuickPrescription()
        this.getRecalledPatients()
    }

    getNbPrescriptions() {
        axios.get("/admin/statistiques/api/nbPendingPrescription")
            .then(resp => {
                let nb = resp.data.number ? resp.data.number : 0;
                this.setState({
                        nbPendingPrescr: nb
                    }
                )
            })
            .catch(error => {
                toast.error("Impossible de récupérer les données du nombre de prescriptions en cours")
            })
    }

    getNbAlerts() {
        axios.get("/admin/statistiques/api/nbAlerts")
            .then(resp => {
                let nb = resp.data.number ? resp.data.number : 0;
                this.setState({
                        nbAlerts: nb
                    }
                )
            })
            .catch(error => {
                toast.error("Impossible de récupérer les données du nombre d'alertes.")
            })
    }

    getNewMessages() {
        axios.get("/admin/statistiques/api/newMessages")
            .then(resp => {
                let nb = resp.data.messages ? resp.data.messages.length : 0;
                this.setState({
                        nbNewMessages: nb
                    }
                )
            })
            .catch(error => {
                toast.error("Impossible de récupérer les données des messages reçus.")
            })
    }

    getPrescriptionsByTime() {
        axios.get("/admin/statistiques/api/prescriptionsByTime")
            .then(resp => {
                const arr = resp.data.data
                this.setState({
                        prescriptionsByTime: arr
                    }
                )
            })
            .catch(error => {
                toast.error("Impossible de récupérer les données des prescriptions en cours.")
            })
    }

    getQuickPrescription() {
        axios.get("/admin/statistiques/api/quickPrescriptions")
            .then(resp => {
                this.setState({
                        quickPrescriptions: resp.data.data
                    }
                )
            })
            .catch(error => {
                toast.error("Impossible de récupérer les données des ordonnances rapides.")
            })
    }

    getRecalledPatients() {
        axios.get("/admin/statistiques/api/recalledPatients")
            .then(resp => {
                this.setState({
                    recalledPatients: resp.data.data
                })
            })
            .catch(error => {
                toast.error("Impossible de récupérer les données des patients reconvoqués")
            })
    }

    render() {
        if (this.state === defaultState) {
            return <div id="full-height"  style={{width: "100%", height: "50vh", display: "flex", alignItems: "center", justifyContent: "center"}}>
                <Loader wide/>
            </div>
        }
        return (
            <div style={{width: "100%"}}>
                <div className="row" style={{padding: "7vh 0.1vw 0.1vw 0"}}>
                    <div className="col s12 xl4 center-align" style={{padding: "0 0.1vw 10px 0.1vw", display: "flex", justifyContent: "center"}}>
                        <div style={{width: "80%"}}>
                            <MonoStatDisplay
                                link="/admin/controle_rdv/controler"
                                label="Prescriptions"
                                value={this.state.nbPendingPrescr}
                                logo="edit_note"
                                color="blue"/>
                        </div>
                    </div>
                    <div className="col s12 xl4" style={{padding: "0 0.1vw 10px 0.1vw", display: "flex", justifyContent: "center"}}>
                        <div style={{width: "80%"}}>
                            <MonoStatDisplay
                                link="/admin/controle_rdv/controler"
                                label="Alertes"
                                value={this.state.nbAlerts}
                                logo="info"
                                color="red"/>
                        </div>
                    </div>
                    <div className="col s12 xl4" style={{padding: "0 0.1vw 0 0.1vw", display: "flex", justifyContent: "center"}}>
                        <div style={{width: "80%"}}>
                            <MonoStatDisplay
                                link="/messages/ophtalmologiste/conversations"
                                label="Messages"
                                value={this.state.nbNewMessages}
                                logo="email"
                                color="green"/>
                        </div>
                    </div>
                </div>
                <div className="row" style={{padding: "7vh 0.1vw 0.1vw 0", maxHeight: "50vh"}}>
                    <div className="col s12 xl4 flex">
                        <div style={{width: "70%", margin: "auto"}}>
                            {JSON.stringify(this.state.prescriptionsByTime) === JSON.stringify([0, 0, 0, 0, 0]) ? (
                                <h2 className="center-align">Vous êtes à jour dans la lecture de vos dossiers.</h2>
                            ) : (
                                <StatDoughnut
                                    labels={["1-2 jours", "3-4 jours", "5-6 jours", "7-8 jours", "8+ jours"]}
                                    data={this.state.prescriptionsByTime}
                                    title={"Délai de prescription"}
                                />
                            )}
                        </div>
                    </div>
                    <div className="col s12 xl4">
                        <div style={{paddingTop: "0", width: "70%", margin: "auto"}}>
                            <PercentDoughnut percent={this.state.quickPrescriptions}
                                             title="Ordonnances RNM délivrées sous 8 jours"/>
                        </div>
                    </div>
                    <div className="col s12 xl4">
                        <div style={{paddingTop: "0", width: "70%", margin: "auto"}}>
                            <PercentDoughnut percent={this.state.recalledPatients} title="Patients reconvoqués"/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
