import React, {Component} from "react";

export default class NotificationOverview extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div/>
        )
    }
}