export const TABLE_COLUMNS = {
    date: {
        "sm": "Date",
        "md": "Date",
        "lg": "Date du RDV"
    },
    payment: {
        "sm": "Paiement",
        "md": "Paiement",
        "lg": "Paiement"
    },
    prescription: {
        "sm": "Ordonnance",
        "md": "Ordonnance",
        "lg": "Ordonnance"
    },
    secondary:{
        "sm": "Secondaire(s)",
        "md": "Secondaire(s)",
        "lg": "Prescription(s) secondaire(s)"
    },
    report:{
        "sm": "Rapport",
        "md": "Rapport",
        "lg": "Rapport"
    },
    //rating:{
    //    "sm": "Note",
    //    "md": "Note",
    //    "lg": "Votre note"
    //}
}