import React, {useEffect} from "react";

function Images({ images_url }) {

    useEffect(() => {
        var elems = document.querySelectorAll('.materialboxed');
        var instances = M.Materialbox.init(elems, {});

        return () => {
            instances.forEach((instance) => {
                instance.destroy();
            });
        };
    }, [images_url])
    const renderNoImage = () => {
        return <h4 style={{ textAlign: "center" }}>Pas de fond d'oeil disponible :(</h4>;
    };

    const renderImages = () => {
        let imgs = images_url;
        let chunks = [];
        for (let i=0; i<imgs.length; i+=4){
            chunks.push(imgs.slice(i, i+4))
        }
        return (
                <div className="card-panel black" style={{borderRadius: '30px', height: "100%", margin: 0, overflowY: "scroll"}}>
                    {
                        chunks.map((chunk, i) => (
                            <div key={i} className="row card-panel black" style={{borderRadius: '30px', margin: 0, gap: 20}}>
                                {chunk.map((image, index) => (
                                    <div key={index} className={chunk.length === 1 ? "col s12" : "col s" + (12 / chunk.length)} style={{height: "100%"}}>
                                        <img className="responsive-img materialboxed" src={image} style={{maxHeight: "250px"}}/>
                                    </div>
                                ))
                                }
                            </div>
                        ))
                    }
                </div>
            );
    };

    return images_url.length === 0 ? renderNoImage() : renderImages()
}

export default Images;