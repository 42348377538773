import React from 'react';
import {Side, TypesMesures} from "../../../Shared/Enums/Enums";
import {formatAcuities, formatRetinos} from "../../../Appointments/Mesures/mesure_components/mesures";

function DisplayMeasures({measures, title}) {
    const retinos = (mesure, side) => {
        return formatRetinos(mesure, side)
    }

    const acuities = (mesure, side) => {
        return formatAcuities(mesure, side)
    }

    const renderMeasure = (measure, side) => {
        const right = side === Side.RIGHT;
        if (measure.name === TypesMesures.TENSION) return <div>{right ? measure.od_tension : measure.og_tension}</div>
        if (measure.name === TypesMesures.PACHYMETRY) return <div>{right ? measure.od_pachymetrie : measure.og_pachymetrie} µm</div>
        return <>
            <div>{retinos(measure, side)}</div>
            <div>{acuities(measure, side)}</div>
        </>
    }

    const displayTitle = () => {
        if (!title) return null
        return <div className="valign-wrapper" style={{gap: 20}}>
            <i className="material-icons red darken-2 white-text"
               style={{padding: 10, borderRadius: 25, fontSize: 16}}>list_alt</i>
            <h4 style={{margin: 0}}>Mesures</h4>
        </div>
    }

    return <div style={{display: "flex", flexDirection: "column", gap: 10}}>
        {displayTitle()}
        <div className="tabs-mesures-synthese-v2">
            <table className="striped bordered">
                <tbody>
                <tr>
                    <th>Titre</th>
                    <th>Droite</th>
                    <th>Gauche</th>
                </tr>
                </tbody>
                <tbody>
                {measures.map((measure, index) => {
                    return <tr key={index}>
                        <th>{measure.name}</th>
                        <td style={{padding: "1vh 0.2vw"}}>
                            {renderMeasure(measure, Side.RIGHT)}
                        </td>
                        <td style={{padding: "1vh 0.2vw"}}>
                            {renderMeasure(measure, Side.LEFT)}
                        </td>
                    </tr>
                })}
                </tbody>
            </table>
        </div>
    </div>
}

DisplayMeasures.defaultProps = {
    title: true
}

export default DisplayMeasures;