import React from "react";
import ActionMedia from "./ActionMedia";
import Loader from "./Loader";
import axios from "axios";
import MesureStore from "../Appointments/Mesures/MesureStore";

export default class ImageSelectionModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedAppointmentId: null,
            previousAppointments: null,
            previousAppointmentsImages: {},
            selectedImages: []
        }
    }

    componentDidMount() {
        this.setState({
            selectedAppointmentId: this.props.previousAppointments[0].id,
            previousAppointments: this.props.previousAppointments,
        }, () => this.loadImages(this.state.selectedAppointmentId))

        var modal = document.getElementById("previousAppointmentsPhotosModal")
        M.Modal.init(modal, {
            onOpenStart: this.onModalOpen,
            onCloseEnd: this.unselectAll
        })
    }

    componentWillUnmount() {
        M.Modal.getInstance(document.getElementById("previousAppointmentsPhotosModal")).destroy()
    }


    loadImages = (id) => {
        if (this.state.previousAppointmentsImages[id]) {
            this.setState({selectedAppointmentId: id})
        } else if (id) {
            axios.get("/api/images?id=" + id, {params: {appointment_id: MesureStore.appointement_id}}).then((resp) => {
                let appointmentsImages = this.state.previousAppointmentsImages
                appointmentsImages[id] = resp.data.images
                this.setState({
                    previousAppointmentsImages: appointmentsImages,
                    selectedAppointmentId: id
                })
            })
        }
    }

    exportAll = () => {
        this.props.checkImagesBeforeAttaching(this.state.previousAppointmentsImages[this.state.selectedAppointmentId])
        M.Modal.getInstance(document.getElementById("previousAppointmentsPhotosModal")).close()
    }

    exportSelected = () => {
        this.props.checkImagesBeforeAttaching(this.state.selectedImages)
        M.Modal.getInstance(document.getElementById("previousAppointmentsPhotosModal")).close()
    }

    onSelectImage = (selected_image) => {

        let selectedImages = this.state.selectedImages

        if (!this.checkIfImageSelected(selected_image)) {
            selectedImages.push(selected_image)
            this.setState({selectedImages: selectedImages})
        } else {
            selectedImages = selectedImages.filter((image) => {
                return selected_image["img_id"] !== image["img_id"]
            })
            this.setState({selectedImages: selectedImages})
        }
    }

    unselectAll = () => {
        this.setState({selectedImages: []})
    }

    checkIfImageSelected = (image) => {
        return this.state.selectedImages.filter((i) => image["img_id"] === i["img_id"]).length !== 0
    }

    onModalOpen = () => {
        this.loadImages(this.state.selectedAppointmentId)
        this.unselectAll()
    }

    render() {
        return (
            <div className="center">
                <a className="btn modal-trigger blue darken-2 z-depth-0"
                   data-target="previousAppointmentsPhotosModal">
                    Historique
                </a>
                <div id="previousAppointmentsPhotosModal" className="modal" style={{width: "70%", height: "70%"}}>
                    <div className="modal-content">
                        <div>
                            <h1 className="center-align">Anciennes consultations</h1>
                        </div>
                        <div className="row">
                            <div className="col s3 center">
                                <h3 className="center-align">Date des consultations</h3>
                                <div>
                                    {this.state.previousAppointments ? this.state.previousAppointments.map((appointment, index) => (
                                        <div key={index}>
                                            <a style={{
                                                cursor: "pointer",
                                                fontSize: "1.25em",
                                                padding: "5px",
                                                backgroundColor: appointment.id === this.state.selectedAppointmentId ? "#AEEAFF" : "",
                                                borderRadius: "10px"
                                            }}
                                               onClick={() => this.loadImages(appointment.id)}>
                                                {new Date(appointment.end_date).toLocaleDateString("en-GB",)}
                                            </a>
                                        </div>
                                    )) : null}
                                </div>
                            </div>
                            <div className="col s9">
                                <h3 className="center">Images</h3>
                                <div style={{maxHeight: "45vh", overflowY: "scroll"}}>
                                    {this.state.previousAppointmentsImages[this.state.selectedAppointmentId] ?
                                        this.state.previousAppointmentsImages[this.state.selectedAppointmentId].map((img, index) => (
                                            <div key={index} className="col s6"
                                                 style={{
                                                     display: "flex",
                                                     justifyContent: "center",
                                                     padding: "1vh",
                                                     borderWidth: "5px",
                                                 }}
                                                 onClick={() => this.onSelectImage(img)}>
                                                <ActionMedia
                                                    src={img}
                                                    icon=""
                                                    position="top-right"
                                                    isSelected={this.checkIfImageSelected(img)}
                                                />
                                            </div>
                                        ))
                                        : <Loader/>
                                    }
                                </div>
                            </div>
                        </div>

                        <footer className="center"
                                style={{
                                    position: "absolute",
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "center",
                                    bottom: "5%",
                                    right: "0%"
                                }}>
                            <a className="btn modal-close red z-depth-0" style={{cursor: "pointer", margin: "10px"}}>Fermer</a>
                            <a className="btn waves-effect grey z-depth-0"
                               disabled={this.state.selectedImages.length === 0}
                               style={{margin: "10px"}}
                               onClick={this.unselectAll}
                            >
                                Désélectionner tout
                            </a>
                            <a className="btn darken-2 z-depth-0"
                               disabled={this.state.selectedImages.length === 0}
                               style={{margin: "10px"}}
                               onClick={this.exportSelected}
                            >
                                Importer {this.state.selectedImages.length} image(s)
                                <i className="material-icons right">send</i>
                            </a>
                            <a className="btn blue darken-2 z-depth-0"
                               style={{margin: "10px"}}
                               onClick={this.exportAll}>Importer la consultation
                                <i className="material-icons right">send</i>
                            </a>
                        </footer>
                    </div>
                </div>
            </div>

        )
    }
}