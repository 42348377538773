import React, {useEffect} from "react";
import axios from "axios";
import toastr from "../../../Shared/Toastr";
import DatePicker from "../../../Shared/DatePicker";

function ActsAnalyses() {
    const [analyses, setAnalyses] = React.useState([])
    const [filter, setFilter] = React.useState({})

    useEffect(() => {
        getAnalyses()
    }, []);

    // Set filter to current year if no year is selected
    // Sets it to the first day of the year and the last day of the year
    // If year is selected, sets it to the first day of the year and the last day of the year
    const setFilterToYear = (input = null) => {
        const date = input ? new Date(`${input}-01-01`) : new Date()
        const year = date.getFullYear()
        setFilter({
            start_date: Helper.formatDate(new Date(year, 0, 1), "YYYY-MM-DD"),
            end_date: input ? Helper.formatDate(new Date(year, 11, 31), "YYYY-MM-DD") : Helper.formatDate(new Date(), "YYYY-MM-DD")
        })
    }

    const getAnalyses = () => {
        axios.get("/orthoptistes/statistics/acts", {params: {filter: filter}})
            .then(resp => setAnalyses(resp.data.analyses))
            .catch(err => toastr.error("Erreur lors de la récupération des analyses"))
    }

    const renderFilter = () => {
        return <div className="valign-wrapper col s12" style={{gap: 20, margin: 10}}>
            <a className="btn blue darken-2 z-depth-0" onClick={() => setFilterToYear()}>Cette année</a>
            <a className="btn blue darken-2 z-depth-0"
               onClick={() => setFilterToYear(new Date().getFullYear() - 1)}>L'année dernière</a>
            Il y a {
            <select className="browser-default" defaultValue={0} style={{width: "auto"}}
                    onChange={(e) => setFilterToYear(new Date().getFullYear() - e.target.value)}>
                <option disabled  value={0}></option>
                {[2,3,4,5,6,7,8,9,10].map((value, index) => <option key={index} value={value}>{value} ans</option>)}
            </select>
        }
            <div className="valign-wrapper">
                <input type="date" value={filter.start_date || ""}
                       onChange={(e) => setFilter({...filter, start_date: e.target.value})}/>
            </div>
            <div className="valign-wrapper">
                <input type="date" value={filter.end_date || ""}
                       onChange={(e) => setFilter({...filter, end_date: e.target.value})}/>
            </div>
            <a className="btn blue darken-2 z-depth-0" onClick={getAnalyses}>
                <i className="material-icons left">search</i>
                Appliquer
            </a>
        </div>

    }

    return (
        <div className="row" style={{margin: "10px 50px", gap: 10}}>
            {renderFilter()}
            <div className="col s12" style={{border: "solid thin lightgrey", borderRadius: "20px 20px 0 0"}}>
                <table className="highlight">
                    <thead>
                    <tr>
                        <td>Acte</td>
                        <td>Nombre d'analyses</td>
                        <td>Nombre d'alertes</td>
                        <td>Nombre d'alertes graves</td>
                    </tr>
                    </thead>
                    <tbody>
                    {Object.entries(analyses).map(([name, value], index) => {
                        return <tr key={index}>
                            <td>{name}</td>
                            <td>{value.select_analysed_appointments}</td>
                            <td>{value.select_alerted_appointments}</td>
                            <td>{value.select_serious_appointments}</td>
                        </tr>
                    })}
                    </tbody>
                </table>
            </div>
        </div>

    );
}

export default ActsAnalyses