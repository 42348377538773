import React from 'react';
import useApplicationOverview from "./useApplicationOverview";
import {RequestState} from "../../../../../Shared/StateHelper";
import Loader from "../../../../../Shared/Loader";
import Error from "../../../../../Shared/Error";
import CardValueDisplay from "../../../../../Shared/UiKit/CardValueDisplay";

function GeneralOverview({appName}) {
    const {overview, requestState} = useApplicationOverview(appName);

    if (requestState === RequestState.LOADING) return <Loader wide/>
    if (requestState === RequestState.ERROR) return <Error/>

    return <div className="center" style={{display: "flex", alignItems: "center",
        justifyContent: "space-evenly", flexWrap: "wrap-reverse", gap: 20}}>
        <CardValueDisplay text={"Revenu mois précédent"} value={`${overview.last_month_amount}€`}/>
        <CardValueDisplay text={"Revenu mensuel"} value={`${overview.current_amount}€`}/>
    </div>
}

export default GeneralOverview;