import {useEffect, useState} from "react";
import axios from "axios";

function usePrescription(appointment_id) {
    const [prescriptions, setPrescriptions] = useState(undefined);

    useEffect(() => {
        if (appointment_id) {
            loadPrescriptions();
        }
    }, []);
    const loadPrescriptions = () => {
        axios.post("/api/loadPrescriptions", {appointement_id: appointment_id}).then((resp) => {
            setPrescriptions(resp.data.prescriptions ?? [])
        })
    }

    return {prescriptions};
}

export default usePrescription;