import React, {useEffect} from 'react';
import axios from "axios";

function MessengerButton() {
    const [count, setCount] = React.useState(0);

    useEffect(() => {
        getNotificationCount();
    }, []);

    const getNotificationCount = () => {
        axios.get("/messenger/unread?count=true").then(response => {
            setCount(response.data)
        })
    }
    const tooltip = () => count > 0 ? "Vous avez " + count + " nouveaux messages non lus" : "Aucun nouveau message"

    return <>
        <a className="btn transparent black-text z-depth-0" title={tooltip()}
           href={"/messenger"}>
            <i className={`material-icons`}>chat</i>
            {count > 0 ? (
                <span className="notification-badge">{count}</span>) : (<div/>)
            }
        </a>
    </>
}

export default MessengerButton;