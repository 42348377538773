import React, {useEffect, useState} from "react";
import Helper, {JOB} from "../Shared/Helper";
import moment from "moment/moment";
import Tooltip from "../Shared/Tootip";
import EditButton from "../Shared/EditButton";
import MesureStore from "../Appointments/Mesures/MesureStore";
import {Observer} from "mobx-react";
import axios from "axios";
import {toast} from "react-toastify";
import Regex from "../Shared/Validation/Regex";
import CancelButton from "../Orthoptistes/Appointment/CancelButton";
import Cookies from "js-cookie";
import {isLocked, LockingElement} from "../Appointments/AppointmentValidation";
import BypassConstraintsButton from "../Orthoptistes/Appointment/BypassConstraintsButton";
import AppointmentValidationButton from "../Orthoptistes/Appointment/AppointmentValidationButton";
import {RouteurState} from "../Shared/Enums/Enums";
import {Role} from "../Shared/Enums/Scope";
import PrescriptionStore from "../Appointments/PrescriptionStore";

function PatientOverview({state, onCheck, onBack}) {
    const [patient, setPatient] = useState({})

    useEffect(() => {
        setPatient(MesureStore.patient)
        const nav = document.getElementById("patient_details_nav")
        const instance = M.Sidenav.init(nav, {})
        const modal = document.getElementById("patient_details_edit_modal")
        const modal_instance = M.Modal.init(modal, {})
        document.getElementById("patient_has_identifier_checkbox").checked = !MesureStore.patient.has_identifiers
        return () => {
            instance.destroy()
            modal_instance.destroy()
        }
    }, [MesureStore.patient])

    const openModal = () => {
        const modal = document.getElementById("patient_details_edit_modal")
        const modal_instance = M.Modal.getInstance(modal)
        modal_instance.open()
    }
    const closeModal = () => {
        const modal = document.getElementById("patient_details_edit_modal")
        const modal_instance = M.Modal.getInstance(modal)
        modal_instance.close()
    }

    const is_birthday = () => {
        return moment(MesureStore.patient.birthday).format("DDMM") === moment().format("DDMM")
    }

    const format_details = () => {
        const patient = MesureStore.patient
        let string = ""
        if (patient.email) string += `Email : ${patient.email}</br>`
        return string
    }

    const validateDetails = () => {
        const email = patient.email ?? ""
        if (email.length !== 0 && !Regex.validateEmail(email ?? "")) {
            toast.error("Email invalide")
            return
        }
        patient.has_identifiers = !document.getElementById("patient_has_identifier_checkbox").checked

        editPatient(patient)
        closeModal()
    }

    const edit_ssn = (value) => {
        const ssn = value ?? ""
        if (ssn.length !== 0 && !Helper.checkSsn(ssn)) {
            toast.error("Numéro de sécurité sociale invalide")
            return
        }
        if (ssn.length !== 0 && !Helper.checkSsnKey(ssn)) {
            toast.error("La clé et le numéro de sécurité sociale ne correspondent pas")
            return
        }

        editPatient({num_carte_vitale: value})
    }

    const edit_patient_code = (value) => {
        const code = value.replaceAll(" ", "") ?? ""

        if (code === "") {
            editPatient({regime_code: null, fund_code: null, centre_code: null})
            return
        }

        const regime_code = code.substring(0, 2)
        const fund_code = code.substring(2, 5)
        const centre_code = code.substring(5, 9)

        if (regime_code.length !== 2 || fund_code.length !== 3 || centre_code.length !== 4) {
            toast.error("Le code patient doit être formatté comme suit : 01 123 1234")
            return
        }

        editPatient({regime_code: regime_code, fund_code: fund_code, centre_code: centre_code})
    }

    const editPatient = (data) => {
        MesureStore.patient = {...MesureStore.patient, ...data}
        axios.post('/api/v3/patients/update', {...MesureStore.patient, ...data}).then(res => {
            MesureStore.patient = res.data.patient
        })
    }

    const renderEditPatientDetailsModal = () => {
        return <div id="patient_details_edit_modal" className="modal fixed-footer-modal">
            <div className="modal-content">
                <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                    <label htmlFor="email">Email</label>
                    <input type="text" id="email" value={patient.email}
                           onChange={(e) => setPatient({...patient, email: e.target.value})}/>
                    <label style={{padding: "0 1vh"}}>
                        <input type="checkbox" className="filled-in" id="patient_has_identifier_checkbox"/>
                        <span>Ce patient ne dispose pas de numéro de sécurité sociale ou de code caisse maladie. </span>
                        <span>&nbsp; Cette option est à utiliser avec précaution</span>
                    </label>
                </div>
            </div>
            <div className="modal-footer">
                <div style={{display: "flex", gap: 10, justifyContent: "flex-end", marginRight: 10}}>
                    <a className="btn btn-small blue darken-2 z-depth-0" onClick={validateDetails}>Valider</a>
                    <a className="modal-close btn btn-small red darken-2 z-depth-0">Annuler</a>
                </div>
            </div>
        </div>
    }

    const renderNavigationButtons = () => {
        return <AppointmentValidationButton
            disabled={!MesureStore.completed}
            onValidation={() => {
                MesureStore.setStatus(true).then(() => {
                    window.location.href = "/agenda/index"
                })
            }}
            backButton={state === RouteurState.CHECK}
            onCheck={onCheck} onBack={onBack}
        />
    }

    const renderLock = () => {
        if(state !== RouteurState.IN_APPOINTMENT) return null
        if (PrescriptionStore.hasSkiacol()) {
            return <Tooltip id="exam_skiacol_lock"
                            text="La prescription comporte du skiacol, cela a pour effet de libérer les contraintes de l'examen en cours"
                            position="bottom">
                <h1 className="center black-text text-darken-2"
                    style={{margin: 0}}>
                    <i className="material-icons">lock</i>
                </h1>
            </Tooltip>
        }
        if (MesureStore.completed) return null
        return <Tooltip id="exam_lock" text={MesureStore.lockedElementToString()} position="left">
            <h1 className="center red-text text-darken-2" style={{margin: 0}}>
                <i className="material-icons">lock</i>
            </h1>
        </Tooltip>
    }

    return (
        <Observer>
            {() => <div className="nav-wrapper" id="patient_details_nav">
                <div className="card grey lighten-4 valign-wrapper" style={{margin: 0, gap: 8, borderRadius: 0}}>
                    {renderLock()}
                    <h5 style={{margin: 0}}>Consultation de</h5>
                    <EditButton text={MesureStore.patient.prenom}
                                onValidation={(value) => editPatient({prenom: value})}>
                        <h5 style={{margin: 0}}>{Helper.formatName(MesureStore.patient.prenom, "")}</h5>
                    </EditButton>
                    <EditButton text={MesureStore.patient.nom} onValidation={(value) => editPatient({nom: value})}>
                        <h5 style={{margin: 0}}>{Helper.formatName("", MesureStore.patient.nom)}</h5>
                    </EditButton>
                    <EditButton type="date" text={Helper.formatDate(MesureStore.patient.birthday, "YYYY-MM-DD")}
                                onValidation={(value) => editPatient({birthday: Helper.formatDate(value, "YYYY-MM-DD")})}
                    >
                        <h5 style={{margin: 0}}>{`(${Helper.dateToAge(MesureStore.patient.birthday)} ans${is_birthday() ? " 🎂" : ""})`}</h5>
                    </EditButton>
                    {MesureStore.patient.gender !== null ?
                        <EditButton type="select" options={["Homme", "Femme"]}
                                    text={MesureStore.patient.gender ? "Femme" : "Homme"}
                                    onValidation={(value) => editPatient({gender: value === "Femme"})}
                        >
                            <i style={{margin: 0}}
                               className="material-icons tiny">{MesureStore.patient.gender ? "female" : "male"}</i>
                        </EditButton>
                        :
                        <EditButton type="select" options={["Homme", "Femme"]}
                                    onValidation={(value) => editPatient({gender: value === "Femme"})}>
                            <i style={{margin: 0}} className="material-icons tiny">help</i>
                        </EditButton>
                    }
                    <EditButton text={MesureStore.patient.num_carte_vitale}
                                onValidation={(value) => edit_ssn(value)}>
                        <h5 style={{
                            margin: 0,
                            fontWeight: isLocked(LockingElement.SSN) ? "bold" : ""
                        }}
                            className={isLocked(LockingElement.SSN) ? "red-text" : ""}>
                            {Helper.formatSsn(MesureStore.patient.num_carte_vitale) || "Num. Sécu inconnu"}
                        </h5>
                    </EditButton>
                    <EditButton
                        text={`${MesureStore.patient.regime_code ?? ""}${MesureStore.patient.fund_code ?? ""}${MesureStore.patient.centre_code ?? ""}`}
                        onValidation={(value) => edit_patient_code(value)}>
                        <h5 style={{
                            margin: 0,
                            fontWeight: isLocked(LockingElement.PATIENT_CODE) ? "bold" : ""
                        }}
                            className={isLocked(LockingElement.PATIENT_CODE) ? "red-text" : ""}>
                            {Helper.formatPatientCodes(MesureStore.patient.regime_code, MesureStore.patient.fund_code, MesureStore.patient.centre_code) || "Code patient inconnu"}
                        </h5>
                    </EditButton>
                    <div className={format_details() === "" ? "hide" : "valign-wrapper"}>
                        <Tooltip text={format_details()} id="patient_details_tt" position="right">
                            <i className="material-icons black-text tiny"
                               style={{cursor: "pointer", borderRadius: "50%"}} onClick={openModal}>pending</i>
                        </Tooltip>
                    </div>
                    <div style={{
                        marginLeft: "auto",
                        display: "flex",
                        gap: 10,
                        flexWrap: "wrap-reverse",
                        justifyContent: "right"
                    }}>
                        <BypassConstraintsButton/>
                        {renderNavigationButtons()}
                    </div>
                </div>
                {renderEditPatientDetailsModal()}
            </div>
            }
        </Observer>
    );
}

export default PatientOverview;