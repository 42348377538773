import React, {useEffect} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import ActList from "./ActList";

function ActsConsole() {
    const [acts, setActs] = React.useState([])


    useEffect(() => {
        get_acts()
    }, [])

    const get_acts = () => {
        axios.get('/admin/console/acts').then((resp) => {
            setActs(resp.data.acts)
        }).catch((err) => {
            toast.error("Impossible de charger les actes")
        })
    }

    return (
        <div id="full-height" style={{display: "flex", flexDirection: "column", overflowY: "scroll"}}>
            <h3 className="valign-wrapper" style={{margin: 20}}><i className="material-icons left">preview</i>Actes orthoptiques
            </h3>
            <div className="divider"/>
            <div style={{marginLeft: 2}}>
                <ActList acts={acts.filter(act => !act.ophtalmological)}/>
            </div>
            <div className="divider"/>
            <h3 className="valign-wrapper" style={{marginLeft: 20}}><i className="material-icons left">visibility</i>Actes ophtalmologiques</h3>
            <div style={{marginLeft: 2}}>
                <ActList acts={acts.filter(act => act.ophtalmological)} ophtalmological/>
            </div>
            <div className="divider"/>
        </div>
    );
}

export default ActsConsole