import React, {useEffect} from "react";

const NB_IMAGE_BY_ROW = 2

function FundusDisplayPanel({images}) {

    useEffect(() => {
        var elems = document.querySelectorAll('.display_img');
        var instances = M.Materialbox.init(elems, {});

        return () => {
            instances.forEach((instance) => {
                instance.destroy();
            });
        };
    }, [images])

    const renderNoImage = () => {
        return (
            <h4 style={{textAlign: "center"}}>Pas de fond d'oeil disponible :(</h4>
        )
    }

    const renderImages = () => {
        const imgs = images;
        let chunks = [];
        for (let i = 0; i < imgs.length; i += NB_IMAGE_BY_ROW) {
            chunks.push(imgs.slice(i, i + NB_IMAGE_BY_ROW))
        }
        return (
            <div className="black" style={{borderRadius: '30px', padding: 10}}>
                {
                    chunks.map((chunk, i) => (
                        <div key={i} className="row black" style={{borderRadius: '30px'}}>
                            {chunk.map((image, index) => {
                                return <div key={index}
                                            className={chunk.length === 1 ? "col s6 push-s3" : "col s" + (12 / chunk.length)}>
                                    <img className="responsive-img materialboxed display_img" src={image}/>
                                </div>
                            })}
                        </div>
                    ))
                }

            </div>

        )
    }


    if (images.length === 0) {
        return (renderNoImage())
    } else {
        return (renderImages())
    }

}

export default FundusDisplayPanel