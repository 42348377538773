import React, {Component} from "react";
import DisplayBlocks from "./DisplayBlocks";

export default class DisplayDivider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: {}
        }
    }

    componentDidMount() {
        this.setState({block: this.props.block})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props !== prevProps) {
            this.setState({block: this.props.block})
        }
    }

    render() {
        if (this.state.block.data) {
            return (
                <div style={{
                    padding: `${this.props.block.data.padding}px 1vw`,
                }}>
                    {this.state.block.data.withLine ? (<div className="divider"/>) : <div/>}
                </div>
            );
        } else return null
    }

    static preview(block) {
        return <div style={{
            padding: `${block.data.padding}px 1vw`,
        }}>
            {block.data.withLine ? (<hr style={{borderTop: "2px solid #bbb"}}/>) : <div/>}
        </div>
    }
}