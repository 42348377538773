import React from "react"
import axios from "axios"
import {observer} from "mobx-react"
import moment from "moment"
import MesureStore from '../MesureStore'
import {CENTRE_OPHTALMO_URL} from "../../../Shared/Helper";

const isUpper = (ki) => {
    return ki == ki.toUpperCase() && ki != ki.toLowerCase();
}

@observer
class PrintAcces extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            txt: "",
            key: []
        };
    }

    componentDidMount() {
        moment.locale("fr")
        axios.get("/api/getUserKey", {params: {appointement_id: MesureStore.appointement_id}}).then((data) => {
            this.setState({key: data.data.key.split("")})
        })

    }

    render() {
        return (

            <div style={{display: "flex", flexDirection: "column", margin: 20}}>
                <h2 style={{alignSelf: "center"}}>Accès à vos prescriptions</h2>
                <p>{MesureStore.patient.prenom} {MesureStore.patient.nom}, vous pouvez accéder à vos prescriptions sur le site :</p>
                <h4 style={{alignSelf: "center"}}>{CENTRE_OPHTALMO_URL}</h4>
                <h3>Votre clé d'accès : </h3>
                <h1 style={{alignSelf: "center"}}>
                    {this.state.key.map((ki, index) => (
                        <span key={index} style={{textDecoration: isUpper(ki) ? "underline" : "none"}}>
                      {ki}
                     </span>
                    ))}
                </h1>

                <h5>* Les lettres majuscules sont soulignées</h5>
                {MesureStore.orthoptiste.genererapport === true && MesureStore.cabinet.email ? (
                    <div className="center">
                        <p className="center">Vous avez un problème ? Vous pouvez nous contacter
                            par <b>email</b> à cet adresse :<br/>
                        </p>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <b>{MesureStore.cabinet.email}</b>
                        </div>
                    </div>
                ) : (
                    <div></div>
                )}
            </div>

        );
    }
}

export default PrintAcces
