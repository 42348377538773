import React, {useEffect} from "react";
import axios from "axios";
import MesureStore from "../MesureStore";
import moment from "moment/moment";
import {JOB} from "../../../Shared/Helper";
import Loader from "../../../Shared/Loader";
import ShowMesures from "../../../syntheses/ShowMesures";

function PreviousMeasureModal({text, appointment_id}) {
    const modal_id = `modal_history_${appointment_id}`
    const [prescription, setPrescription] = React.useState(undefined)

    useEffect(() => {
        const modal = document.getElementById(modal_id);
        const instance = M.Modal.init(modal);
        return () => instance?.destroy()
    }, [])

    useEffect(() => {
        if (prescription) {
            openModal()
        }
    }, [prescription])

    const openModal = () => {
        const modal = document.getElementById(modal_id);
        const instance = M.Modal.init(modal);
        instance.open()
    }
    const openPrescription = () => {
        if (prescription) {
            openModal()
            return
        }
        axios.get(`/api/appointement/prescriptionrdv/${appointment_id}`, {params: {appointment_id: MesureStore.appointement_id}}).then((resp) => {
            setPrescription({
                prescription: resp.data.text,
                comment: resp.data.comment,
                private_comment: resp.data.private_comment,
                mesures: resp.data.mesures,
                start: resp.data.date,
                open: true
            })
        })
    }

    return <>
        <td className="center">
            <a className="btn-flat" onClick={openPrescription}>
                <i className="material-icons left">search</i>{text}
            </a>
        </td>
        <div id={modal_id} className="modal">
            {
                prescription ? (
                    <div className="modal-content" style={{height: "100%"}}>
                        <h1 className="center">Examen du {moment(prescription.start).format("DD-MM-YYYY")}</h1>
                        <div className="container" style={{display: "flex", flexDirection: "column", padding: 20}}>
                            {prescription.prescription ?
                                <>
                                    <h3 style={{marginBlock: "20px 10px"}}>Mesures</h3>
                                    <ShowMesures mesures={prescription.mesures} typedemesures={prescription.mesures.map(m => {
                                        return {
                                            id: m.mesure_id,
                                            titre: m.mesure_name
                                        }
                                    })}/>
                                </> : null
                            }
                            {prescription.prescription ?
                                <>
                                    <h3 style={{marginBlock: "20px 10px"}}>Prescription</h3>
                                    <h5 style={{whiteSpace: "pre-line"}}>{prescription.prescription}</h5>
                                </> : null
                            }
                            {prescription.comment ?
                                <>
                                    <h3 style={{marginBlock: "20px 10px"}}> Commentaire {JOB.toLowerCase()}-ophtalmo </h3>
                                    <h5>{prescription.comment}</h5>
                                </> : null
                            }

                            {prescription.private_comment ?
                                <>
                                    <h3 style={{marginBlock: "20px 10px"}}> Commentaire privé </h3>
                                    <h5 style={{textAlign: "100px"}}>{prescription.private_comment}</h5>
                                </> : null
                            }

                        </div>
                    </div>
                ) : <Loader/>
            }
        </div>
    </>
}

export default PreviousMeasureModal;