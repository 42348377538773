import React from 'react';

function DisplayPrescriptions({prescriptions}) {
    if(prescriptions.length === 0) return <h3 className="center">Aucune prescription n'a été renseignée.</h3>;
    const [selectedId, setSelectedId] = React.useState(prescriptions[0].id);

    const renderSelector = () => {
        if(prescriptions.length <= 1) return null;
        return prescriptions.map((prescription, index) => {
            return <a
                key={index} style={{borderRadius: 20}}
                className={`btn-small z-depth-0 ${prescription.id === selectedId ? "blue darken-2" : "grey"}`}
                onClick={() => setSelectedId(prescription.id)}
            >
                {index + 1}
            </a>
        })
    }

    return <div style={{display: "flex", flexDirection: "column", gap: 10}}>
        <div style={{display: "flex", alignItems: "center"}}>
            <div className="valign-wrapper" style={{gap: 20}}>
                <i className="material-icons red darken-2 white-text"
                   style={{padding: 10, borderRadius: 25, fontSize: 16}}>note_alt</i>
                <h4 style={{margin: 0}}>Prescription</h4>
            </div>
            <div style={{marginLeft: "auto"}}>
                {renderSelector()}
            </div>
        </div>
        <div
            className="card z-depth-0 blue lighten-5"
            style={{borderRadius: "0 0 30px 30px", borderTop: "thick solid #32a1ce"}}
        >
            <h5 style={{whiteSpace: "pre-wrap"}}>
                {prescriptions.find(prescription => prescription.id === selectedId).text}
            </h5>
        </div>
    </div>
}

export default DisplayPrescriptions;