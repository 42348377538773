import React from "react"
import axios from "axios"
import Popup from "reactjs-popup";
import 'react-toastify/dist/ReactToastify.css'
import {observer} from "mobx-react"
import MesureStore from '../MesureStore'

@observer
class NewDataPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      open: false,
      source: "",
      sourceType: "",
      params: {},
      path : "",
      content: "",
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.refuseData = this.refuseData.bind(this);
    this.validateData = this.validateData.bind(this);
  }
  openModal() {
    this.setState({ open: true });
  }
  closeModal() {
    this.setState({ open: false });
  }
  validateData(e){
    e.preventDefault()
    this.state.params["app_id"] = this.props.appointement.id
    this.state.params["id"] = this.props.orthoptiste.id
    this.state.params["content"] = this.state.content
    this.state.params["sourceType"] = this.state.sourceType
    this.state.params["modele"] = this.state.source
    axios({method: 'post', url: "/orthoptistes/parser/saveData", data: this.state.params}).then((data)=>{
      this.setState({
        open: false,
        id: ""
      })
      }
    )
  }

  refuseData(e){
    e.preventDefault()
    this.setState({
      id: "",
      open: false
    })
  }
  componentDidMount(){
    var that = this;
      App.navis2 = App.cable.subscriptions.create({channel: "NavisChannel", id: this.props.appointement.id}, {
        received: function(data){
          data = data.data
          if(data.type=="pending"){
            that.setState({open: true, source: data.source, content: data.content, sourceType: data.sourceType})
          }

          if (data.type == "receive") {
            if (data.reload == "mesures"){
              MesureStore.refresh()
            }
          }
        }
      })
      global.navis=App.navis

  }
  componentWillUnmount(){
    if(this.props.orthoptiste.navis_id != undefined || this.props.orthoptiste.navis_id != ""){
      try{
        App.navis2.unsubscribe()
      }
      catch{
      }
    }
  }

  render () {
    return (
      <React.Fragment>
        <Popup modal closeOnDocumentClick open={this.state.open} onClose={this.closeModal}>
          <div className="col s12">
            <h1 className="center">Nouvelle récéption de données</h1>
            <p className="center">Une demande d'envoi de données depuis un(e) {this.state.source} a été réceptionnée, faut il l'importer dans ce dossier ? </p>
            <div className="container">
              <div className="row">
                <div className="col s6" style={{display:"flex", justifyContent: "center"}}>
                  <a onClick={this.refuseData} className="btn red white-text"><i className="material-icons left">delete_sweep</i>Refuser</a>
                </div>
                <div className="col s6" style={{display:"flex", justifyContent: "center"}}>
                  <a onClick={this.validateData} className="btn green white-text"><i className="material-icons left">done</i>Valider</a>
                </div>
              </div>
            </div>
          </div>
        </Popup>
      </React.Fragment>
    )

  }

}

export default NewDataPopup
