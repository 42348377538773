import React, {Component} from "react";

export default class MultiSelector extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this)
        this.state = {
            selectedData: []
        }
    }

    componentDidMount() {
        var elems = document.querySelectorAll('.chips');
        var instances = M.Chips.init(elems, {
            autocompleteOptions: {data: this.props.data},
            onChipAdd: ()=> this.onChange(elems[0]),
            onChipDelete: ()=> this.onChange(elems[0]),
        });

    }

    onChange(elem) {
        const arr = M.Chips.getInstance(elem).chipsData
        let res = []
        arr.map(elem => res.push(elem.tag))
        arr.map(elem => console.log(this.props.data.hasOwnProperty(elem.toString())))
        this.props.onChange(res)
    }

    render() {
        return (
            <div className="row valign-wrapper">
                <div className="col">
                    <span>{this.props.txt}</span>
                </div>
                <div className="col">
                    <div className="chips chips-autocomplete" />
                </div>
            </div>
        )
    }
}

MultiSelector.defaultProps = {
    txt: "",
    data: {"aaaaaaa": null, "bbbbbbbb": null, "ccccccccc": null},
    onChange: () => null
}