import React, {Component} from "react";
import Tooltip from "../../../Shared/Tootip";
import ReportsStore from "./ReportsStore";

export default class SelectTemplate extends Component {
    constructor(props) {
        super(props);
        this.selectTemplate = this.selectTemplate.bind(this)
        this.hasTemplates = this.hasTemplates.bind(this)
        this.state = {
            newTemplateName: "",
            editTemplateName: "",
            selectedTemplate: {}
        }
    }

    componentDidMount() {
        this.setState({
            selectedTemplate: this.props.selectedTemplate,
            editTemplateName: this.props.selectedTemplate.name
        })
        ReportsStore.selectedTemplateId = this.props.selectedTemplate.id
        const addModal = document.getElementById("add_template_modal");
        M.Modal.init(addModal, {});
        const editModal = document.getElementById("edit_template_modal");
        M.Modal.init(editModal, {});
        const deleteModal = document.getElementById("delete_template_modal");
        M.Modal.init(deleteModal, {});
    }

    componentWillUnmount() {
        M.Modal.getInstance(document.getElementById("add_template_modal")).destroy()
        M.Modal.getInstance(document.getElementById("edit_template_modal")).destroy()
        M.Modal.getInstance(document.getElementById("delete_template_modal")).destroy()
        M.FormSelect.getInstance(document.getElementById("template_select")).destroy()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            this.setState({
                selectedTemplate: this.props.selectedTemplate,
                editTemplateName: this.props.selectedTemplate ? this.props.selectedTemplate.name : ""
            }, () => {
                M.FormSelect.init(elem, {});
                ReportsStore.selectedTemplateId = this.props.selectedTemplate?.id
            })

        }
    }

    export = (template) => {
        let dataStr = JSON.stringify(Object.assign({}, template, {id: undefined}));
        let dataUri = 'data:application/json;charset=utf-8,' + encodeURIComponent(dataStr);

        let exportFileDefaultName = `rapport-${template.name}.json`;

        let linkElement = document.createElement('a');
        linkElement.setAttribute('href', dataUri);
        linkElement.setAttribute('download', exportFileDefaultName);
        linkElement.click();
    }

    import = (event) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const template = JSON.parse(event.target.result)
            this.props.importTemplate(Object.assign({}, template, {id: undefined}))
        }
        reader.readAsText(event.target.files[0]);
        M.Modal.getInstance(document.getElementById("add_template_modal")).close()
    }


    selectTemplate(event) {
        this.setState({
            selectedTemplate: this.props.data.templates[event.target.value],
            editTemplateName: this.props.data.templates[event.target.value].name
        }, () => this.props.onChoose(this.state.selectedTemplate))
    }

    hasTemplates() {
        return !(!this.props.data || !this.props.data.templates || this.props.data.templates.length === 0);
    }

    renderSelect = (templates) => {
        return (
            <div>
                <div className={`${templates.length > 0 ? "" : "hide"}`}>
                    <select id="template_select" className="browser-default"
                            onChange={(event) => this.selectTemplate(event)}>
                        {templates.map((template, index) =>
                            (<option key={index} value={index}>{template.name}</option>)
                        )}
                    </select>
                </div>
                <div className={`${templates.length === 0 ? "" : "hide"}`}>
                    Aucun compte rendu existant
                </div>
            </div>

        )
    }

    render() {
        const templates = (this.props.data !== undefined && this.props.data.templates !== undefined) ? this.props.data.templates : []
        return (
            <div className="" style={{boxShadow: "0px 3px 0px 0px rgba(0, 0, 0, 0.10)", marginBottom: "3px", padding: "10px 0"}}>
                <div className="row valign-wrapper" style={{marginBottom: "0", padding: "0 1vw 0 1vw"}}>
                    <div className="col s5">Type de compte rendu</div>
                    <div className="col s7 valign-wrapper" style={{display: "flex"}}>
                        <div style={{maxWidth: "75%"}}>
                            {this.renderSelect(templates)}
                        </div>
                        <div style={{marginLeft: "auto", paddingLeft: "1vw"}} className="valign-wrapper">
                            <Tooltip id="create_new_template" text="Nouveau compte rendu.">
                                <a className="btn waves-light btn-small z-depth-0 transparent blue-text darken-2 modal-trigger"
                                   data-target="add_template_modal"
                                   style={{borderRadius: "2px 0 0 2px", padding: "0 7px 0 7px"}}><i
                                    className="material-icons">add</i></a>
                            </Tooltip>
                            <Tooltip id="edit_template" text="Renommer ce compte rendu.">
                                <a className={`btn waves-light btn-small z-depth-0 transparent ${this.hasTemplates() ? "orange-text darken-2 modal-trigger" : "grey-text"}`}
                                   data-target="edit_template_modal"
                                   style={{borderRadius: "0", padding: "0 7px 0 7px"}}><i
                                    className="material-icons">edit</i></a>
                            </Tooltip>
                            <Tooltip id="delete_template" text="Supprimer ce compte rendu.">
                                <a className={`btn waves-light btn-small z-depth-0 transparent ${this.hasTemplates() ? "red-text darken-2 modal-trigger" : "grey-text"}`}
                                   data-target="delete_template_modal"
                                   style={{borderRadius: "0 2px 2px 0", padding: "0 7px 0 7px"}}><i
                                    className="material-icons">delete_forever</i></a>
                            </Tooltip>
                            <Tooltip id="download_template" text="Télécharge ce compte rendu.">
                                <a className={`btn waves-light btn-small z-depth-0 transparent ${this.hasTemplates() ? "green-text darken-2 modal-trigger" : "grey-text"}`}
                                   onClick={() => this.export(this.state.selectedTemplate)}
                                   style={{borderRadius: "0 2px 2px 0", padding: "0 7px 0 7px"}}><i
                                    className="material-icons">download</i></a>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                {/*CREATE BUTTON*/}
                <div id="add_template_modal" className="modal" onKeyDown={(event) => {
                    if (event.key === "Enter") {
                        this.props.addTemplate(this.state.newTemplateName)
                        var elem = document.getElementById('add_template_modal');
                        var instance = M.Modal.getInstance(elem)
                        instance.close()
                    }
                }}>
                    <div className="modal-content">
                        <h5>Créer un nouveau compte-rendu</h5>
                        <div className="valign-wrapper row">
                            <div className="col s2">
                                <h5>{"Nom :"}</h5>
                            </div>
                            <div className="col s10">
                                <input type="text"
                                       onChange={(e) => this.setState({newTemplateName: e.target.value})}/>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer valign-wrapper" style={{justifyContent: "center"}}>
                        <div style={{padding: "0 1vw"}}>
                            <a className="modal-close btn blue darken-2 z-depth-0"
                               onClick={() => this.props.addTemplate(this.state.newTemplateName)}>Créer</a>
                        </div>
                        <div style={{padding: "0 1vw"}}>
                            <span className="btn btn-file green darken-2 z-depth-0">
                                Importer
                                <input type="file" accept=".json"
                                       onInput={(e) => this.import(e)}/>
                            </span>
                        </div>
                        <div style={{padding: "0 1vw"}}>
                            <a className="modal-close btn red z-depth-0">Annuler</a>
                        </div>
                    </div>
                </div>
                {/*EDIT BUTTON*/}
                <div id="edit_template_modal" className="modal" onKeyDown={(event) => {
                    if (event.key === "Enter") {
                        this.props.editTemplateName(this.state.editTemplateName)
                        M.Modal.getInstance(document.getElementById('edit_template_modal')).close()
                    }
                }}>
                    <div className="modal-content">
                        <h5>Changer le nom de ce compte-rendu</h5>
                        <div className="valign-wrapper row">
                            <div className="col s2">
                                <h5>{"Nom :"}</h5>
                            </div>
                            <div className="col s10" style={{marginLeft: 0}}>
                                <input type="text" defaultValue={this.state.editTemplateName}
                                       style={{maxWidth: "80%"}}
                                       onChange={(e) => this.setState({editTemplateName: e.target.value})}/>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer valign-wrapper" style={{justifyContent: "center"}}>
                        <a className="modal-close btn blue darken-2 z-depth-0"
                           onClick={() => this.props.editTemplateName(this.state.editTemplateName)}>Modifier</a>
                        <div style={{paddingLeft: "3vw"}}>
                            <a className="modal-close btn red z-depth-0">Annuler</a>
                        </div>
                    </div>
                </div>
                {/*DELETE BUTTON*/}
                <div id="delete_template_modal" className="modal">
                    <div className="modal-content">
                        Voulez-vous vraiment supprimer ce compte rendu ?
                    </div>
                    <div className="modal-footer valign-wrapper" style={{justifyContent: "center"}}>
                        <a className="modal-close btn red z-depth-0"
                           onClick={() => this.props.deleteTemplate()}>Supprimer</a>
                        <div style={{paddingLeft: "3vw"}}>
                            <a className="modal-close btn black z-depth-0">Annuler</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

SelectTemplate.defaultProps = {
    data: {templates: []}
}