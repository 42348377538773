import React, {useState} from "react";
import Tooltip from "../../../Shared/Tootip";
import Toastr from "../../../Shared/Toastr";
import {toast} from "react-toastify";
import axios from "axios";


export default function SecurityPanel(props) {

    let csrfToken = document.querySelector("meta[name='csrf-token']").content
    axios.defaults.headers.common['X-CSRF-Token'] = csrfToken

    const [current_clicked, setCurrentClicked] = useState(null)
    const [old_password, setOldPassword] = useState("")
    const [new_password, setNewPassword] = useState("")
    const [confirmation_password, setConfirmationPassword] = useState("")
    const [error_message, setErrorMessage] = useState(null)

    function attemptingPasswordModification() {
        return !!(old_password || new_password || confirmation_password);
    }

    function isValidToSubmit() {
        if (!old_password || old_password.length < 6) return false
        if (!new_password || new_password.length < 6) return false
        if (!confirmation_password) return false
        if (old_password === new_password) return false
        if (new_password !== confirmation_password) return false
        return true
    }

    function formatSubmitTooltip() {
        let initial_text = "Veuillez vérifier les éléments suivants pour enregistrer:"
        if (!old_password) initial_text += "\n- Mot de passe actuel manquant."
        if (old_password && old_password.length < 6) initial_text += "\n- Mot de passe actuel de 6 caractères ou plus"
        if (!new_password) initial_text += "\n- Nouveau mot de passe manquant."
        if (new_password && new_password.length < 6) initial_text += "\n- Nouveau mot de passe de 6 caractères ou plus"
        if (!confirmation_password) initial_text += "\n- Confirmation du mot de passe manquante."
        if (old_password && new_password && old_password === new_password) initial_text += "\n- Ancien et nouveau mot de passe identiques."
        if (old_password && new_password && new_password !== confirmation_password) initial_text += "\n- Nouveau mot de passe et confirmation ne correspondent pas."
        return initial_text
    }

    function updatePassword() {
        axios.post(`${props.url_prefix}/administration/update_password`, {
            old_password: old_password,
            new_password: new_password,
            confirmation_password: confirmation_password
        })
            .then((response) => {
                setErrorMessage(null)
                toast.success("Mot de passe modifié.")
            })
            .catch((error) => {
                setErrorMessage(error.response.data.errorMessage)
                toast.error("Erreur lors du changement de mot de passe")
            })
    }

    function handleOnEyeClick(id) {
        if (current_clicked === id) setCurrentClicked(null)
        else setCurrentClicked(id)
    }

    const FIELDS = [
        {
            category_title: "Modification du mot de passe",
            cy_data: "PasswordModification",
            size: 45,
            fields: [
                {
                    id: "old_password",
                    title: "Mot de passe actuel",
                    var_name: old_password,
                    setter: setOldPassword,
                    cy_data: "OldPassword",
                    error_rendering: renderOldPasswordError,
                    tooltip_info: "Veuillez saisir votre mot de passe actuel.",
                },
                {
                    id: "new_password",
                    title: "Nouveau mot de passe",
                    var_name: new_password,
                    setter: setNewPassword,
                    cy_data: "NewPassword",
                    error_rendering: renderNewPasswordError,
                    tooltip_info: "Veuillez saisir votre nouveau mot de passe."
                },
                {
                    id: "confirmation_password",
                    title: "Confirmation du nouveau mot de passe",
                    var_name: confirmation_password,
                    setter: setConfirmationPassword,
                    cy_data: "ConfirmationPassword",
                    error_rendering: renderConfirmationPasswordError,
                    tooltip_info: "Veuillez confirmer votre nouveau mot de passe."
                }
            ]
        }
    ]

    function renderOldPasswordError() {
        if (attemptingPasswordModification()) {
            if (!old_password) return (
                <div className="red-text"
                     data-cy="MissingOldPassword"
                     style={{display: "flex", alignItems: "center", gap: "0.5vw"}}>
                    <i className="material-icons">error</i>Veuillez saisir votre mot de passe actuel.
                </div>
            )
            if (old_password.length < 6) return (
                <div className="red-text"
                     data-cy="ShortOldPassword"
                     style={{display: "flex", alignItems: "center", gap: "0.5vw"}}>
                    <i className="material-icons">error</i>Votre mot de passe doit être supérieur à 6 caractères.
                </div>
            )
        }
        if(error_message && error_message === "wrong_password") return (
            <div className="red-text"
                 data-cy="IncorrectOldPassword"
                 style={{display: "flex", alignItems: "center", gap: "0.5vw"}}>
                <i className="material-icons">error</i>Mot de passe incorrect, veuillez saisir votre mot de passe actuel.
            </div>
        )
        return null
    }

    function renderNewPasswordError() {
        if (attemptingPasswordModification()) {
            if (!new_password) return (
                <div className="red-text"
                     data-cy="MissingNewPassword"
                     style={{display: "flex", alignItems: "center", gap: "0.5vw"}}>
                    <i className="material-icons">error</i>Veuillez saisir un nouveau mot de passe.
                </div>
            )
            if (new_password.length < 6) return (
                <div className="red-text"
                     data-cy="ShortNewPassword"
                     style={{display: "flex", alignItems: "center", gap: "0.5vw"}}>
                    <i className="material-icons">error</i>Votre mot de passe doit être supérieur à 6 caractères.
                </div>
            )
            if (new_password === old_password) return (
                <div className="red-text"
                     data-cy="IdenticalPasswords"
                     style={{display: "flex", alignItems: "center", gap: "0.5vw"}}>
                    <i className="material-icons">error</i>Votre nouveau mot de passe ne doit pas être identique a
                    l'ancien.
                </div>
            )
        }
        return null
    }

    function renderConfirmationPasswordError() {
        if (attemptingPasswordModification()) {
            if (!confirmation_password) return (
                <div className="red-text"
                     data-cy="MissingConfirmationPassword"
                     style={{display: "flex", alignItems: "center", gap: "0.5vw"}}>
                    <i className="material-icons">error</i>Veuillez confirmer votre nouveau mot de passe.
                </div>
            )
            if (confirmation_password.length < 6) return (
                <div className="red-text"
                     data-cy="ShortConfirmationPassword"
                     style={{display: "flex", alignItems: "center", gap: "0.5vw"}}>
                    <i className="material-icons">error</i>Votre mot de passe doit être supérieur à 6 caractères.
                </div>
            )
            if (new_password !== confirmation_password) return (
                <div className="red-text"
                     data-cy="NotIdenticalPasswords"
                     style={{display: "flex", alignItems: "center", gap: "0.5vw"}}>
                    <i className="material-icons">error</i>Votre nouveau mot de passe et la confirmation ne sont pas
                    identiques.
                </div>
            )
        }
        return null
    }

    return (
        <div className="container" style={{width: "100%", padding: 20, height: "100%"}}>
            <h1 className="center">Sécurité</h1>
            {FIELDS.map((category, category_index) => {
                return (
                    <div key={category_index} data-cy={category.cy_data}>
                        <h2>{category.category_title}</h2>
                        <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                            {category.fields.map((field, field_index) => {
                                return (
                                    <div key={field_index} className="row"
                                         style={{display: "flex", flexDirection: "column"}}>
                                        <div style={{
                                            display: "flex",
                                            alignItems: "center",
                                            paddingBottom: field_index ? 0 : 20
                                        }}>
                                            <Tooltip id={`administrator_${field.id}`} position="right"
                                                     text={field.tooltip_info}>
                                                <div
                                                    className="input-field outlined"
                                                    style={{marginRight: 20, minWidth: `${category.size}%`}}>
                                                    <input
                                                        data-cy={field.cy_data}
                                                        id={field.id}
                                                        type={current_clicked === field.id ? `text` : "password"}
                                                        defaultValue={field.var_name}
                                                        onChange={(e) => field.setter(e.target.value)}
                                                        className="validate"
                                                    />
                                                    <label htmlFor={field.var_name}>{field.title}</label>
                                                    <div id="hoverable">
                                                        <i className="material-icons"
                                                           data-cy={`ShowPassword${field.cy_data}`}
                                                           onClick={() => handleOnEyeClick(field.id)}
                                                           style={{position: "absolute", right: "5%", top: "30%", cursor: "pointer"}}
                                                        >{current_clicked === field.id ? "visibility" : "visibility_off"}</i>
                                                    </div>
                                                </div>
                                            </Tooltip>

                                            {field.error_rendering()}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )
            })}
            {isValidToSubmit() ?
                <a className="btn blue darken-2 z-depth-0"
                   style={{margin: "auto", marginTop: "1vh"}}
                   onClick={updatePassword}
                   data-cy="EnableSaveButton"
                >Enregistrer mes modifications</a>
                :
                <Tooltip id="missing_info_tooltip" position="right" text={formatSubmitTooltip()}>
                    <div style={{width: "fit-content", height: "fit-content"}} data-cy="DisabledSaveButton">
                        <a className="btn blue darken-2 z-depth-0 disabled"
                           style={{margin: "auto", marginTop: "1vh"}}
                           onClick={updatePassword}
                        >Enregistrer mes modifications</a>
                    </div>
                </Tooltip>
            }
        </div>
    )
}