import React, {useEffect, useState} from 'react'
import axios from 'axios'
import ShowMesures from "../../syntheses/ShowMesures";
import {t, i18n} from "../../I18n/i18n";
import moment from "moment";
import DisplayMeasures from "../../Orthoptistes/Appointment/Check/DisplayMeasures";
import {format_measures} from "../../Appointments/Mesures/mesure_components/mesures";

export default function ScreeningReport(props) {
    const [appointment, setAppointment] = useState({
        status_controle: 0
    })
    const [mesures, setMesures] = useState([])
    const [comment, setComment] = useState({})
    const [ophtalmologist, setOphtalmologist] = useState("AN ERROR OCCURED")
    const [patient, setPatient] = useState({})
    const [typedemesures, setTypedemesures] = useState([])
    const [images, setImages] = useState([])
    const [signature, setSignature] = useState("")
    const [risques, setRisques] = useState([])
    const getData = () => {
        axios.get('/externes/rapports/data/' + props.appointment_id)
            .then(res => {
                setMesures(res.data.mesures)
                setPatient(res.data.patient)
                setTypedemesures(res.data.typedemesures)
                setComment(res.data.comment)
                setOphtalmologist(res.data.ophtalmologist)
                setImages(res.data.images)
                setSignature(res.data.signature)
                setRisques(res.data.comment == null ? [] : res.data.comment.risque.split(","))
                setAppointment(res.data.appointment)


            })
            .catch(err => {
            })
    }
    useEffect(() => {
        i18n.locale = "fr"
        getData()

    }, []);

    if (mesures.length == 0) {
        return (
            <div>
                <p className={"center"}> loading </p>
            </div>
        )
    }
    window.status = "ready"

    return (
        <div className="pdf-document">
            <div style={{padding: "20px"}}>
                <div className="row">
                    <div className="col s12">
                        <h1 style={{float: "left"}}><img src={props.logo} width="200px" alt=""/></h1>
                        <h1 style={{float: "right"}} className={"center"}>Rapport ophtalmologique</h1>

                    </div>
                </div>
                <div className="row">
                    <div className="col s6">
                        <h2 className={"center"}>{t("General information")}</h2>
                        <ul style={{paddingInlineStart: "0px"}}>
                            <li><b>{t("Name")}:</b> {patient.prenom} {patient.nom}</li>
                            <li><b>{t("Email")}:</b> {patient.email}</li>
                            <li><b>{t("Birthday")}:</b> {moment(patient.birthday).format("DD-MM-Y")} </li>
                            <li><b>{t("Controlled by")}</b> {ophtalmologist}</li>
                            <li><b>{t("Controlled the")}</b> {moment(appointment.analisis_date).format("DD-MM-Y")}</li>
                            <li>
                                {appointment.status_controle == 2 ? (
                                    <b>
                                        {t("Status")}: <span
                                        className="green-text">{t("NO DISEASE HAS BEEN DETECTED")}</span>
                                    </b>
                                ) : (
                                    <b>
                                        {t("Status")}: <span
                                        className="red-text">{t("FURTHER MEDICAL CHECK-UP IS NEEDED")}</span>
                                    </b>
                                )}
                            </li>
                        </ul>
                    </div>
                    <div className="col s6">
                        <h2 className={"center"}>{t("Refraction data")}</h2>
                        <DisplayMeasures title={false} measures={format_measures(mesures, typedemesures)}/>
                    </div>
                </div>

                <div style={{border: "#95a5a6 solid 0px", marginTop: "40px", marginBottom: "40px"}}>

                </div>

                <div style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-around",
                    background: "black",
                    marginLeft: "-20px",
                    marginRight: "-20px"
                }}>
                    {images.map((image, index) => {
                        return (
                            <div key={image.id} style={{
                                height: "300px",
                                display: "flex",
                                flexDirection: "line",
                                alignItems: "center",
                                padding: "10px",
                            }}>
                                <img src={image.url} height="250px" alt=""/>
                            </div>
                        )
                    })}
                </div>
                <div className="row">
                    {appointment.status_controle == 3 ? (
                        <div className="col s6">
                            <p>Au vu des ces informations, l'ophtalmologue suspecte la présence de</p>
                            <ul>
                                {risques.map((risque, index) => {
                                    return (
                                        <li key={index}>{risque}</li>
                                    )
                                })}
                            </ul>
                        </div>
                    ) : (
                        <div className="col s6"></div>
                    )}
                    <div className="col s6">
                        <img src={signature} style={{float: "right", height: "150px"}} alt=""/>
                    </div>
                </div>
            </div>
        </div>
    )
}