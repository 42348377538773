import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import OrthoptistSettings from "../../../Parameters/Orthoptists/OrthoptistSettings";

function OrthoptistPricing({orthoptists}) {
    const id = useParams().id;
    const orthoptist = orthoptists.find((orthoptist) => orthoptist.id === parseInt(id))
    const nav = useNavigate();

    return (
        <div id="full-height" style={{display: "flex", flexDirection: "column"}}>
            <div className="valign-wrapper" style={{marginLeft: 10}}>
                <a className="btn-flat" style={{borderRadius: 20}} onClick={() => nav("/orthoptists")}>
                    <i className="material-icons">arrow_back</i>
                </a>
                <h3 className="valign-wrapper" style={{margin: 20}}><i className="material-icons left">preview</i>
                    Gestion de {Helper.formatName(orthoptist.firstname, orthoptist.lastname)}
                </h3>
            </div>
            <div className="divider"/>
            <div style={{padding: 20, overflowY: "scroll"}}>
                <OrthoptistSettings orthoptist_id={id}/>
            </div>
        </div>
    );
}

export default OrthoptistPricing;