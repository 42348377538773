import React, {useEffect, useState} from "react";
import axios from "axios";
import BorderedDisplay from "../../../Shared/UiKit/BorderedDisplay";
import EditButton from "../../../Shared/EditButton";
import {toast} from "react-toastify";

function OphtalmologistPricing({ophtalmologist}) {
    const [pricing, setPricing] = useState([]);

    useEffect(() => {
        getPricing();
    }, [ophtalmologist])

    const getPricing = () => {
        axios.get(`/admin/console/ophtalmologist/${ophtalmologist.id}/prices`).then(res => {
            setPricing(res.data.pricing)
        }).catch(err => {
            toast.error("Impossible de charger les tarifs")
        })
    }

    const savePrices = (price, value) => {
        setPricing(pricing.map(p => {
            return p.act_id === price.act_id ? Object.assign({}, p, {price: parseFloat(value)}) : p
        }))
        axios.patch(`/admin/console/ophtalmologist/${ophtalmologist.id}/price`, {
            value: parseFloat(value),
            price_id: price.id,
            act_id: price.act_id
        }).then(() => {
            setPricing(pricing.map(p => {
                return p.act_id === price.act_id ? Object.assign({}, p, {price: parseFloat(value)}) : p
            }))
        }).catch(err => {
            toast.error("Impossible de sauvegarder les tarifs")
        })
    }

    return <div style={{margin: 20}}>
        <div>
            <BorderedDisplay text='Tarifs par actes'>
                {pricing.map((price, index) => {
                    return <div key={index}>
                        {index === 0 ? <div/> : <div className="divider"/>}
                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <span>{price.name}</span>
                            <EditButton color="transparent" textColor="blue"
                                        suffix="€"
                                        text={price.price.toFixed(2).toString()}
                                        onValidation={(v) => savePrices(price, v)}
                                        filter={(e) => {
                                            return e.target.value.match(/^\d*[.,]?\d{0,2}$/g)
                                        }}/>
                        </div>
                    </div>

                })}
            </BorderedDisplay>
        </div>
    </div>
}

export default OphtalmologistPricing;