import React, {Component} from "react";

export default class DisplayImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: {}
        }
    }

    componentDidMount() {
        this.setState({block: this.props.block})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props !== prevProps) {
            this.setState({block: this.props.block})
        }
    }

    render() {
        if (this.state.block.data) {
            const width = this.state.block.data.width ?? 100
            return <div style={{width: "100%", display: "flex", justifyContent: this.state.block.data.justify}}>
                <img src={ this.state.block.data.url} alt={ this.state.block.data.alt } style={{width: width.toString()+"%"}}/>
            </div>
        } else return null
    }

    static preview(block) {
        const width = block.data.width ?? 100
        return  <div style={{width: "100%", display: "flex", justifyContent: block.data.justify}}>
            <img src={ block.data.url} alt={ block.data.alt } style={{width: width.toString()+"%"}}/>
        </div>
    }
}