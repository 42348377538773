import React, {useEffect} from 'react';
import {User, UserStatus} from "../Enums/Enums";
import Helper from "../Helper";
import BrandedElement from "../BrandedElement";

function UserDropdown({user}) {
    useEffect(() => {
        const elems = document.querySelectorAll('.user-dropdown');
        const instances = M.Dropdown.init(elems, {coverTrigger: false, hover: true, constrainWidth: false});

        return () => {
            instances.forEach(instance => instance.destroy());
        }
    }, []);

    const initials = () => {
        return (user.firstname?.charAt(0)?.toUpperCase() ?? "") + (user.lastname?.charAt(0)?.toUpperCase() ?? "");
    }

    const link = (str) => {
        switch (user.type) {
            case User.ORTHOPTIST.value:
                return `/orthoptistes/${str}`;
            case User.OPHTALMOLOGIST.value:
                return `/ophtalmologistes/${str}`;
            case User.EXTERN.value:
                return `/externes/${str}`;
            case User.SECRETARY.value:
                return `/secretaires/${str}`;
            case User.INTERNAL_USER.value:
                return `/internal_users/${str}`;
        }
    }

    const color = () => {
        switch (user.status) {
            case UserStatus.LOCKED.value:
                return "#d32f2f";
            case UserStatus.ARCHIVED.value:
                return "#616161";
            case UserStatus.TEST.value:
                return "#616161";
            default:
                return "#1976d2";
        }
    }

    if (!user) return null;

    return (
        <>
            <a className='user-dropdown' data-target='user-dropdown'>
                <span className="circle" style={{backgroundColor: color(), color: "white"}}>
                    {initials()}
                </span>
            </a>
            <ul id='user-dropdown' className='dropdown-content'>
                <li className="user_dropdown_header">
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 10,
                        justifyContent: "center",
                        margin: 20
                    }}>
                        <h5 style={{fontWeight: "bold"}}>{Helper.formatName(user.firstname, user.lastname)}</h5>
                        <h5>{user.email}</h5>
                    </div>
                </li>
                <li className="divider" tabIndex="-1"></li>
                {
                    ![User.INTERNAL_USER.value].includes(user.type) ?
                        <li><a data-cy="OptionPanel" href={link("administration")}>Options</a></li> : null
                }
                <BrandedElement temeoo>
                    <li>
                        <a href="https://temeoo.atlassian.net/servicedesk/customer/portal/1" target="_blank">
                            Contacter le support
                        </a>
                    </li>
                </BrandedElement>
                <li><a data-cy="MainDisconnect" href={link("sign_out")} data-method={"delete"}>Déconnexion</a></li>
            </ul>
        </>
    );
}

export default UserDropdown;