import React from 'react';
import Helper from "../../../Shared/Helper";
import {ActNature} from "../../../Shared/Enums/Enums";
import MedicalReport from "../../../admin/Controle/Report/MedicalReport";
import telemedical_questions_json
    from "../../../Appointments/Mesures/mesure_components/Questions/telemedical_questions.json";
import nurse_question_json from "../../../Appointments/Mesures/mesure_components/Questions/nurse_questions.json";
import DrugsReport from "../../../admin/Controle/Report/DrugsReport";
import BrandedElement from "../../../Shared/BrandedElement";

function AppointmentDetails({patient, act, details}) {

    const renderAnamnesis = () => {
        if (!details.anamnesis) return null
        return <>
            <h6 style={{fontWeight: "bolder", marginTop: 0}}>Anamnèse :</h6>
            <h6 style={{whiteSpace: "pre-wrap"}}>{details.anamnesis}</h6>
        </>
    }

    const renderOrthopticText = () => {
        if (!details.orthoptic_text) return null
        return <BrandedElement temeoo>
            <h6 style={{fontWeight: "bolder"}}>Orthoptie :</h6>
            <h6 style={{whiteSpace: "pre-wrap"}}>{details.orthoptic_text}</h6>
        </BrandedElement>
    }

    const renderHistory = () => {
        if (!patient?.history) return null
        return <>
            <h6 style={{fontWeight: "bolder"}}>Antécédents :</h6>
            <h6 style={{whiteSpace: "pre-wrap"}}>{patient.history}</h6>
        </>
    }

    const renderPathologies = () => {
        if (!details?.pathologies || !details.pathologies.length) return null
        return <>
            <h5 className="red-text" style={{fontWeight: "bolder"}}>Pathologies suspectées !</h5>
            {details.pathologies.map((pathology, index) => {
                return <h6 className="red-text" key={index} style={{whiteSpace: "pre-wrap", fontWeight: "bolder"}}>- {pathology.pathologie}</h6>
            })}
        </>
    }

    const renderQuestions = () => {
        const report = details.report
        if (!report) return null
        switch (act?.nature) {
            case ActNature.TELEMEDICAL.name:
                return <MedicalReport report={report} json={telemedical_questions_json}/>
            case ActNature.NURSE.name:
                return <MedicalReport report={report} json={nurse_question_json}/>
            default:
                return null
        }
    }

    return <div style={{display: "flex", flexDirection: "column", gap: 10}}>
        <div className="valign-wrapper" style={{gap: 20}}>
            <i className="material-icons red darken-2 white-text"
               style={{padding: 10, borderRadius: 25, fontSize: 16}}>description</i>
            <h4 style={{marginBlock: 0}}>Informations générales</h4>
        </div>
        <div
            className="card z-depth-0 blue lighten-5 row"
            style={{borderRadius: "0 0 30px 30px", borderTop: "thick solid #32a1ce"}}
        >
            <div className="col s12 m4">
                <h6 style={{marginTop: 0}}>{Helper.formatName(patient?.prenom, patient?.nom)}</h6>
                <h6>{patient?.birthday ? `${Helper.dateToAge(patient?.birthday)} ans` : "Aucun date de naissance spécifiée"}</h6>
                <h6>{patient?.telephone}</h6>
                <h6>Acte : {act?.titre ?? "Aucun acte n'a été sélectionné"}</h6>
            </div>
            <div className="col s12 m4">
                {renderAnamnesis()}
                {renderOrthopticText()}
                {renderHistory()}
                {renderPathologies()}
            </div>
            <div className="col s12 m4">
                {renderQuestions()}
                <DrugsReport drugs={patient?.drugs ?? []}/>
            </div>
        </div>
    </div>
}

export default AppointmentDetails;