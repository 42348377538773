import React, {useEffect, useState} from "react"
import {Container, Grid, InputLabel, MenuItem, Select} from "@material-ui/core";
import axios from "axios"
import {Link} from "react-router-dom";

export default function DataPatient(props) {

    const [patient, setPatient] = useState({...props.patient})
    const [email, setEmail] = useState(patient.email == null ? "" : patient.email);
    const [name, setName] = useState(patient.prenom == null ? "" : patient.prenom);
    const [lastName, setLastName] = useState(patient.nom == null ? "" : patient.nom);
    const [gsm, setGsm] = useState(patient.telephone == null ? "" : patient.telephone);
    const [birthday, setBirthdate] = useState(patient.birthday == null ? new Date() : new Date(patient.birthday));
    const [alert, setAlert] = useState(-1)
    const [message, setMessage] = useState("")
    const [sex, setSex] = useState(patient.gender);
    const [nCarteVitale, setNCarteVitale] = useState(patient.num_carte_vitale == null || patient.num_carte_vitale.length === 0 ? props.numCarte : patient.num_carte_vitale)
    const [patientId, setPatientId] = useState(patient.id == null ? undefined : patient.id)

    useEffect(() => {
        setPatient(props.patient);
        setEmail(props.patient.email == null ? "" : props.patient.email)
        setName(props.patient.prenom == null ? "" : props.patient.prenom)
        setLastName(props.patient.nom == null ? "" : props.patient.nom)
        setGsm(props.patient.telephone == null ? "" : props.patient.telephone)
        setBirthdate(props.patient.birthday == null ? new Date() : new Date(props.patient.birthday))
        setSex(props.patient.gender)
        setMessage("")
        setAlert(-1)
        setPatientId(props.patient.id == null ? undefined : props.patient.id)
        setNCarteVitale(props.patient.num_carte_vitale == null || patient.num_carte_vitale.length === 0 ? props.numCarte : props.patient.num_carte_vitale)
    }, [props.patient, props.numCarte])


    const updatePatient = () => {
        if (email !== patient.email || name !== patient.prenom || lastName !== patient.nom || patient.gender !== sex || birthday !== patient.birthday) {
            axios.post("/api/v3/patients/update", {
                "nom": lastName,
                "prenom": name,
                "email": email,
                "birthday": birthday,
                "gender": sex,
                id: patient.id,
                "telephone": gsm,
                "num_carte_vitale": nCarteVitale?.replace(/\s/g,'')
            }).then((data) => {
                if (data.data.status === "done") {
                    setAlert(0)
                    setMessage("Le patient a correctement été mis a jour")
                    setPatient(data.data.patient)

                } else {
                    setAlert(1)
                    setMessage("Une erreur est survenue lors de la mis à jour du patient")
                }
                localStorage.setItem('data_patient', JSON.stringify(true));
            })
        } else {
            localStorage.setItem('data_patient', JSON.stringify(true));
        }
    }

    const createPatient = () => {
        axios.post("/api/v3/patients/create", {
            "nom": lastName,
            "prenom": name,
            "email": email,
            "birthday": birthday,
            "gender": sex,
            id: patient.id,
            "telephone": gsm,
            "num_carte_vitale": nCarteVitale?.replace(/\s/g,'')
        }).then((data) => {
            if (data.data.status === "done") {
                setAlert(0)
                setMessage("Le patient a correctement été créé")
                setPatientId(data.data.id)
            } else {
                setAlert(1)
                setMessage("Une erreur est survenue lors de la création du patient")
            }
            localStorage.setItem('data_patient', JSON.stringify(true));
        })
    }


    return (

        <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
            {alert === 1 ? (
                <div className="red white-text card center floating-alert">
                    <p>
                        {message}
                    </p>
                </div>
            ) : alert === 0 ? (
                <div className="green white-text card center floating-alert">
                    <p>
                        {message}
                    </p>
                </div>
            ) : props.patient.id === undefined ? (
                <div>
                    <p className="center">Aucun patient correspondant, creation d'un nouveau patient</p>
                </div>
            ) : (
                <div/>
            )}
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <div className="form-group">
                        <label>Prénom</label>
                        <input type="text" className="form-control" value={name} onChange={(e) => {
                            setName(e.target.value);
                        }} required/>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className="form-group">
                        <label>Nom</label>
                        <input type="text" className="form-control" onChange={(e) => {
                            setLastName(e.target.value);
                        }} value={lastName} required/>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className="form-group">
                        <label>Email</label>
                        <input type="text" className="form-control" onChange={(e) => {
                            setEmail(e.target.value);
                        }} value={email} required/>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className="form-group">
                        <label>Telephone</label>
                        <input type="text" className="form-control" value={gsm} onChange={(e) => {
                            setGsm(e.target.value);
                        }} required/>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className="form-group">
                        <label>Numéro de carte vitale</label>
                        <input type="text" className="form-control" value={nCarteVitale} onChange={(e) => {
                            setNCarteVitale(e.target.value);
                        }} required/>
                    </div>
                </Grid>

                <Grid item xs={6}>
                    <div className="form-group">
                        <label htmlFor="birthday">Date de naissance :</label>
                        <input type="date" id="birthday" name="birthday" value={birthday.toISOString().split('T')[0]}
                               onChange={(e) => setBirthdate(new Date(e.target.value))} required/>
                    </div>
                </Grid>
            </Grid>

            <Grid item xs={6}>
                <div className="form-group">
                    <InputLabel id="demo-simple-select-label">Sexe</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={sex ? "Homme" : "Femme"}
                        label="Sexe"
                        onChange={(e) => {
                            let gender = e.target.value === "Homme";
                            setSex(gender);
                        }
                        }
                    >
                        <MenuItem value={"Homme"}>Homme</MenuItem>
                        <MenuItem value={"Femme"}>Femme</MenuItem>
                    </Select>
                </div>
            </Grid>

            <div>
                {patientId === undefined ? (
                    <div>
                        <a href="#" onClick={createPatient} className="btn mb-2 align-bottom"
                           style={{marginTop: 10}}>Créer</a>
                    </div>
                ) : (
                    <div style={{"display": "flex", "justifyContent": "space-between"}}>
                        <div>
                            {props.cv ? (
                                <a href="#" onClick={updatePatient} className="btn mb-2 align-bottom"
                                   style={{marginTop: 10}}>Mettre à jour</a>
                            ) : (
                                <Link to="/actes">Actes</Link>
                            )}
                        </div>
                        {nCarteVitale !== patient.num_carte_vitale || !props.cv ? (
                            <div/>
                        ) : (
                            <div>
                                <a href={`/NewAppointement/${patientId}`} onClick={() => {
                                    localStorage.setItem('data_patient', JSON.stringify(true));
                                }} className="btn mb-2 align-bottom" style={{marginTop: 10}}><i
                                    className="material-icons right">send</i>Nouvelle Consultation</a>
                            </div>
                        )}
                    </div>

                )}


            </div>

        </Container>

    );
}

// <a href="/orthoptistes/v2/bridge" onClick={this.addMachine} disabled={this.state.invalid} className="btn btn-large mb-2 align-bottom" style={{margin: 10}}><i className="material-icons right">send</i>Ajouter</a>