import React from "react";
import CVStore, {CVState} from "./CVStore";
import {Observer} from "mobx-react";
import moment from "moment";

const SelectPatient = () => {

    const selectPatient = (patient) => {
        CVStore.selectPatient(patient)
        CVStore.setState(CVState.DISPLAY_PATIENT)
    }

    return (
        <Observer>
            {() => <div>
                <p className="center">Patients trouvés :</p>
                {CVStore.selectedPatient.id === undefined ? (
                    <div/>
                ) : (
                    <div>

                        <div style={{"display": "flex", "justifyContent": "space-between"}}>
                            {/*<button className="btn" onClick={this.back}><i*/}
                            {/*    className="material-icons left">arrow_back</i>Retour*/}
                            {/*</button>*/}
                            <button className="btn" onClick={() => CVStore.newPatient()}>Aucun patient correspondant
                            </button>
                        </div>
                        <table>
                            <thead>
                            <tr>
                                <th>Nom</th>
                                <th>Prenom</th>
                                <th>Email</th>
                                <th>Telephone</th>
                                <th>Né le</th>
                                <th colSpan="3"></th>
                            </tr>
                            </thead>

                            <tbody>
                            {CVStore.patientList.map((patient) => (
                                <tr key={patient.id}>
                                    <td>{patient.nom} </td>
                                    <td>{patient.prenom} </td>
                                    <td>{patient.email}</td>
                                    <td>{patient.telephone} </td>
                                    <td>{moment(patient.birthday).format("DD-MM-YYYY")}</td>
                                    <td><a
                                        className="btn btn-small blue darken-2 z-depth-0"
                                        onClick={() => selectPatient(patient)}>
                                        Choisir
                                    </a></td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
            }

        </Observer>
    );
}

export default SelectPatient