import React, {useEffect} from "react";
import Loader from "../../../Shared/Loader";
import BorderedDisplay from "../../../Shared/UiKit/BorderedDisplay";
import {hasFailed, isLoading, RequestState} from "../../../Shared/StateHelper";
import Error from "../../../Shared/Error";
import axios from "axios";
import {LogTypes} from "../../../Shared/Enums/Enums";

function LogDisplay({user_type, user}) {
    const [logs, setLogs] = React.useState([]);
    const [requestState, setRequestState] = React.useState(RequestState.LOADING);

    useEffect(() => {
        get_logs();
    }, []);

    const get_logs = () => {
        axios.get(`/admin/console/logs/${user_type}/${user.id}`)
            .then(res => {
                setLogs(res.data.logs);
                setRequestState(RequestState.SUCCESS);
            })
            .catch(err => {
                setRequestState(RequestState.ERROR);
            })
    }

    const renderLog = (log, index) => {
        switch(log.type) {
            case(LogTypes.LOGIN.value):
                return <div className="row">
                    <div className="col s1 center">
                        <i className="material-icons">login</i>
                    </div>
                    <div className="col s4">
                        Connexion
                    </div>
                    <div className="col s5" >
                        {Helper.formatDate(log.date, "DD/MM/YYYY HH:mm:ss")}
                    </div>
                    <div className="col s2">
                        {log.ip}
                    </div>
                </div>
            case(LogTypes.SIGNOUT.value):
                return  <div className="row" style={{width: "100%"}}>
                    <div className="col s1 center">
                        <i className="material-icons">logout</i>
                    </div>
                    <div className="col s4">
                        Deconnexion
                    </div>
                    <div className="col s5" >
                        {Helper.formatDate(log.date, "DD/MM/YYYY HH:mm:ss")}
                    </div>
                    <div className="col s2">
                        {log.ip}
                    </div>
                </div>
            case(LogTypes.OPHTALMOLOGIST_ACTION.value):
                const validated = log.data.action === "validated";
                return  <div className="row" style={{width: "100%"}}>
                    <div className="col s1 center">
                        <i className={`material-icons ${validated ? "green-text" : "red-text"} text-darken-2`}>{validated ? "done" : "close"}</i>
                    </div>
                    <div className="col s4">
                        {validated ? "Validation" : "Refus"} de prescription
                    </div>
                    <div className="col s5">
                        {Helper.formatDate(log.date, "DD/MM/YYYY HH:mm:ss")}
                    </div>
                    <div className="col s2">
                        {log.ip}
                    </div>
                </div>
            case(LogTypes.SUBMIT_PRESCRIPTION.value):
                return <div className="row" style={{width: "100%"}}>
                    <div className="col s1 center">
                        <i className="material-icons">send</i>
                    </div>
                    <div className="col s4">
                        Transmission de prescription
                    </div>
                    <div className="col s5" >
                        {Helper.formatDate(log.date, "DD/MM/YYYY HH:mm:ss")}
                    </div>
                    <div className="col s2">
                        {log.ip}
                    </div>
                </div>
            case(LogTypes.UPDATE_PRESCRIPTION.value):
                return <div className="row" style={{width: "100%"}}>
                    <div className="col s1 center">
                        <i className="material-icons">sync_alt</i>
                    </div>
                    <div className="col s4">
                        Édition de prescription
                    </div>
                    <div className="col s5" >
                        {Helper.formatDate(log.date, "DD/MM/YYYY HH:mm:ss")}
                    </div>
                    <div className="col s2">
                        {log.ip}
                    </div>
                </div>
        }
    }

    const renderLogs = () => {
        if(isLoading(requestState)) return <div className="valign-wrapper" style={{justifyContent: "center", height: "100%"}}>
            <Loader text="" size="small"/>
        </div>
        if(hasFailed(requestState) || !logs) return <div className="valign-wrapper" style={{justifyContent: "center", height: "100%"}}>
            <Error errorText="Les évènements ne sont pas disponibles"/>
        </div>
        if(logs.length === 0) return <div className="valign-wrapper" style={{justifyContent: "center", height: "100%"}}>
            <h4>Cet utilisateur n'a aucun évenemement récent</h4>
        </div>
        return <div className="collection">
            {logs.map((log, index) => <div className="collection-item" key={`log_${index}`}>
                {renderLog(log, index)}
            </div>)}
        </div>
    }


    return (
        <div className="log-display" style={{height: "100%"}}>
            <BorderedDisplay text="Évènements" height="100%">
                {renderLogs()}
            </BorderedDisplay>
        </div>
    );
}

export default LogDisplay;