const MedocErrorCode = {
    0: "No error",
    1: "Can't connect to MedOC",
    2: "Request timeouted",
    3: "MedOC raised an error",
    500: "Unknown error"
}

export const displayMedocError = (code) => {
    return MedocErrorCode[code]
}