import React, {useState} from 'react';
import Helper from "../../../../Shared/Helper";
import RestrictedElement from "../../../../Shared/RestrictedElement";
import {UserPermissions, UserRights} from "../../../../Shared/Enums/Enums";
import ResponsiveMoreButton from "../../../../Shared/UiKit/ResponsiveMoreButton";
import Loader from "../../../../Shared/Loader";
import StatusIcon from "../../../../Appointments/StatusIcon";
import axios from "axios";
import {toast} from "react-toastify";

function AppointmentList(props) {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    const [appointments, setAppointments] = useState(props.appointments)

    const updateAppointment = (appointment) => {
        axios.patch(`/admin/console/appointments/${appointment.id}`, {
            appointment: appointment
        }).then((response) => {
            setAppointments(appointments.map((appt) => {
                if (appt.id === appointment.id) {
                    return response.data
                }
                return appt
            }))
        }).catch((err) => {
            if (err.response.status === 401) {
                toast.error("Vous n'avez pas les droits pour effectuer cette action")
            } else toast.error("Impossible de modifier l'examen")
        })
    }

    const toggleLock = (appointment) => {
        appointment.override_lock = !appointment.override_lock
        updateAppointment(appointment)
    }

    const renderAppointment = (appointment) => {
        const overrideLock = appointment.override_lock
        const act = appointment.acts[0]
        return <div className="collection-item transparent" key={appointment.id}
                    style={{display: "flex", width: "100%", gap: 10}}>
            <div style={{
                width: "100%",
                margin: 0,
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: 10
            }}>
                <div className="valign-wrapper" style={{width: 30, margin: 0}}>
                    {appointment.need_to_pay ?
                        <i className="material-icons blue-text text-darken-2"
                           title="Cet examen est en attente de paiement">
                            attach_money
                        </i> : overrideLock ?
                            <i className="material-icons" title="Cet examen a été débloqué">
                                lock_open
                            </i> : null}
                </div>
                <div className="valign-wrapper" style={{width: 30, margin: 0}}>
                    <StatusIcon status={appointment.status_controle} act={act}/>
                </div>
                <div className="valign-wrapper" style={{width: 80, margin: 0}}>
                    <span>#{appointment.id}</span>
                </div>
                <div className="valign-wrapper" style={{width: 100, margin: 0}}>
                    <span>{Helper.formatDate(appointment.start)}</span>
                </div>
                <div className="valign-wrapper" style={{width: 300, margin: 0}}>
                    <span>{act?.titre ?? "Aucun acte"}</span>
                </div>
            </div>
            <RestrictedElement field={UserRights.APPOINTMENTS} permission={UserPermissions.WRITE_ONLY}>
                <div style={{display: "flex", gap: 10, width: "100%"}}>
                    <ResponsiveMoreButton id={`more_button_patient_${appointment.id}`} options={[
                        <a className="btn-small blue darken-2 z-depth-0" onClick={ () => toggleLock(appointment)}>
                            <i className="material-icons left">{overrideLock ? "lock" : "lock_open"}</i>
                            {overrideLock ? "Lever débloquage" : "Débloquer"}
                        </a>
                    ]}/>
                </div>
            </RestrictedElement>
        </div>
    }

    if (appointments === undefined) return <div
        style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "center", height: "100%"}}>
        <Loader wide/>
    </div>
    return <div style={{display: "flex", flexDirection: "column", gap: 20, height: "100%"}}>
        <h3 style={{margin: 0}}>Examens</h3>
        <div className="collection"
             style={{borderRadius: 5, height: "100%", overflowY: "scroll", margin: 0}}>
            {appointments.map((appointment) => {
                return renderAppointment(appointment)
            })}
        </div>
    </div>
}

export default AppointmentList;
