import React, {useState} from "react";
import moment from "moment";
import {Act, ActNature} from "../../Shared/Enums/Enums";
import Tooltip from "../../Shared/Tootip";
import axios from "axios";
import EditAppointmentButton from "./EditAppointmentButton";
import StatusIcon from "../../Appointments/StatusIcon";
import ResponsiveMoreButton from "../../Shared/UiKit/ResponsiveMoreButton";
import ExamRecapTabs from "./ExamRecapTabs";
import AskForReworkButton from "../../ophtalmologiste/Appointment/AskForReworkButton";

function ExamHistory({data, orthoptiste_signed, level_2, style}) {
    const [appointments, setAppointments] = useState(data.appointments)
    const [expandedAppointments, setExpandedAppointment] = useState([])

    let csrfToken = document.querySelector("meta[name='csrf-token']").content
    axios.defaults.headers.common['X-CSRF-Token'] = csrfToken

    const toggle_lock = (id) => {
        axios.post(`/admin/appointement/toggle_override_lock?id=${id}`).then(() => {
            setAppointments(appointments.map(appointement => {
                if (appointement.id === id) {
                    appointement.override_lock = !appointement.override_lock
                }
                return appointement
            }))
        })
    }

    const archive_appointment = (id) => {
        axios.patch(`/patients/appointment/archive/${id}`).then((resp) => {
            setAppointments(appointments.filter(appointement => appointement.id !== resp.data.id))
        })
    }

    const handleExpandedAppointments = (appointment_id) => {
        if (expandedAppointments.includes(appointment_id)) {
            let temp_array = expandedAppointments.filter((appointment) => appointment !== appointment_id)
            setExpandedAppointment(temp_array)
        } else {
            setExpandedAppointment([...expandedAppointments, appointment_id])
        }
    }


    const askForRework = (id) => {
        axios.post(`/admin/appointement/rework?id=${id}`).then(() => {
            setAppointments(appointments.map(appointement => {
                if (appointement.id === id) {
                    appointement.status_controle = 0
                }
                return appointement
            }))
        })
    }

    const renderStatus = (appointment, act) => {
        if (appointment.status_controle === -1) {
            return <i className="material-icons grey-text" title="Examen non pris en compte">do_not_disturb_on</i>
        }
        if (appointment.status_controle === 0) {
            return <i className="material-icons grey-text" title="Non transmis">cancel</i>
        }
        if (appointment.status_controle === 2) {
            return <i className="material-icons green-text" title="Prescription validée">done</i>
        }
        if (appointment.status_controle === 3) {
            return <i className="material-icons red-text" title="Alerte">report_problem</i>
        }
        // Unhandled case
        if (appointment.status_controle !== 1) {
            return null
        }
        if ((!act || (act.prix === 0 && act.nature !== ActNature.NURSE.code))) {
            return <i className="material-icons grey-text" title="Non transmis">cancel</i>
        }
        if (act && ![ActNature.TELEMEDICAL.code, ActNature.NURSE.code].includes(act.nature)) {
            return <i className="material-icons green-text" title="Examen effectué">done</i>
        }
        return <i className="material-icons orange-text" title="Validation en cours">more_time</i>
    }

    function renderActionsButtons(appointment, act) {
        const reworkable = ([2, 3].includes(appointment.status_controle))
            && act?.titre !== Act.ONLY_PRESCRITPION
            && appointment.orthoptiste_lastname !== null
        const isAbsent = act?.titre.trim() === Act.NOTHING || appointment.status_controle <= 0
        if (orthoptiste_signed) {
            const prescriptionButtons = appointment.prescription_text ? [
                <a className="btn-small blue darken-2 z-depth-0"
                   href={`/orthoptiste/download/prescription?appointment_id=${appointment.id}`}
                   target="_blank"><i className="material-icons left">picture_as_pdf</i>Télécharger SCOR en PDF</a>,
                <a className="btn-small blue darken-2 z-depth-0"
                   href={`/orthoptiste/download/prescription?html=1&appointment_id=${appointment.id}`}
                   target="_blank"><i className="material-icons left">image</i>Télécharger SCOR en image</a>
            ] : []
            return <div className="valign-wrapper" style={{justifyContent: "space-evenly"}}>
                <ResponsiveMoreButton id={`patient_history_appt_${appointment.id}_btns`} options={[
                    <EditAppointmentButton orthoptist_signed={orthoptiste_signed}
                                           appointment_id={appointment.id}
                                           appointment_status={appointment.status_controle}
                    />,
                    <a className={`btn-small green darken-2 z-depth-0 ${appointment.document_count || "disabled"} `}
                       target="_blank" href={`documents/${appointment.id}`}>
                        {appointment.document_count} document{appointment.document_count > 1 ? "s" : ""} disponible{appointment.document_count > 1 ? "s" : ""}
                    </a>,
                    <a className="btn-small blue darken-2 z-depth-0"
                       href={`/syntheses/rendez-vous/${appointment.id}`}
                       target="_blank"
                       title="Consulter la synthèse du rendez-vous associé.">
                        <i className="material-icons left hide-on-med-and-down">assignment</i>
                        <i className="material-icons hide-on-large-only">assignment</i>
                        <span
                            className="hide-on-med-and-down">Consulter synthèse</span>
                    </a>,
                    isAbsent ? <a className={`btn-small red darken-2 z-depth-0 ${isAbsent ? "hide" : ""}`}
                                  onClick={() => archive_appointment(appointment.id)}>
                        <i className="material-icons left hide-on-med-and-down">delete</i>
                        <i className="material-icons hide-on-large-only">delete</i>
                        <span className="hide-on-med-and-down">Supprimer</span>
                    </a> : null,
                    ...prescriptionButtons
                ]}/>
                <a className="btn-small btn-flat transparent"
                   onClick={() => handleExpandedAppointments(appointment.id)}>
                    <i className={`material-icons more-button ${expandedAppointments.includes(appointment.id) ? "flipped" : ""}`}>expand_more</i>
                </a>
            </div>
        }

        if (level_2) return <div className="valign-wrapper" style={{gap: 10, width: "100%"}}>
            <div>
                {appointment.status_controle === 3 ?
                    <Tooltip
                        id={`tooltipped_locked_button_${appointment.id}`}
                        text={`${appointment.override_lock ? "Bloquer l'ordonnance" : "Débloquer l'ordonnance"}`}>
                        <i id="hoverable" style={{cursor: "pointer"}}
                           className="material-icons black-text"
                           onClick={() => toggle_lock(appointment.id)}>{appointment.override_lock ? "lock_open" : "lock"}</i>
                    </Tooltip>
                    : <div style={{paddingRight: 24, height: "100%"}}/>
                }
            </div>
            <div style={{flex: 1}}>
                <ResponsiveMoreButton id={`patient_history_appt_${appointment.id}_btns`} options={[
                    <a className={`btn-small blue darken-2 z-depth-0 ${isAbsent ? "disabled" : ""}`}
                       href={`/admin/controle_rdv/${appointment.id}`}>
                        <i className="material-icons left hide-on-med-and-down">find_in_page</i>
                        <i className="material-icons hide-on-large-only">find_in_page</i>
                        <span>Contrôle prescription</span>
                    </a>,
                    <AskForReworkButton reworkable={reworkable}
                                        appointment_id={appointment.id}
                                        onValidation={(id) => {
                                            setAppointments((prevAppointments) => prevAppointments.map(appointement => {
                                                if (appointement.id === id) {
                                                    appointement.status_controle = 0
                                                }
                                                return appointement
                                            }))
                                        }}
                    />,
                    <a className={`btn-small green darken-2 z-depth-0 ${appointment.document_count || "disabled"} `}
                       target="_blank" href={`documents/${appointment.id}`} style={{width: 190}}
                    >
                        {appointment.document_count} document{appointment.document_count > 1 ? "s" : ""} disponible{appointment.document_count > 1 ? "s" : ""}
                    </a>,
                    <a className="btn-small blue darken-2 z-depth-0"
                       href={`/syntheses/rendez-vous/${appointment.id}`}
                       target="_blank"
                       title="Consulter la synthèse du rendez-vous associé.">
                        <i className="material-icons left hide-on-med-and-down">assignment</i>
                        <i className="material-icons hide-on-large-only">assignment</i>
                        <span
                            className="hide-on-med-and-down">Consulter synthèse</span>
                    </a>
                ]}/>
            </div>
        </div>

        return null
    }

    const renderAppointment = (appointment, index) => {
        if (!appointment.done) return <tr>{renderNewAppointment()}</tr>
        const act = data.actepossibles.find(act => act.id === appointment.id)
        let tooltipText = appointment.comment ? `Anamnèse :\n${appointment.comment}` : "Pas d'anamnèse"
        if (appointment.private_comment) tooltipText += `\n\nCommentaire privé :\n${appointment.private_comment}`
        return <>
            <Tooltip position="left"
                     key={appointment.id}
                     text={tooltipText}
                     id={`tooltip_detailed_appt_${index}`}>
                <tr key={appointment.id} data-cy="FirstPartAppointment">
                    <td>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-evenly'
                        }}>
                            {appointment.need_to_pay ?
                                <i className="material-icons blue-text text-darken-2"
                                   title="Le paiement HN est en attente"
                                >euro</i>
                                : null
                            }
                            <StatusIcon status={appointment.status_controle} act={act}/>
                        </div>
                    </td>
                    <td style={{textAlign: 'center'}}>
                        {moment(appointment.start).format("DD/MM/YYYY hh:mm")}
                    </td>
                    <td className="center">
                        {act ? act.titre : "Aucun acte associé"}
                    </td>
                    {appointment.orthoptiste_lastname === null ?
                        <td>/</td>
                        :
                        <td style={{textAlign: 'center'}}>{Helper.capitalizeFirstLetter(appointment.orthoptiste_lastname)}</td>}
                    <td>
                        {renderActionsButtons(appointment, act)}
                    </td>
                </tr>
            </Tooltip>
            <tr data-cy="SecondPartAppointment">
                {expandedAppointments.includes(appointment.id) ?
                    <ExamRecapTabs appointment={appointment}/>
                    : null}
            </tr>
        </>

    }

    const renderNewAppointment = () => {
        const appointments_todo = appointments.filter(appt => !appt.done)
        if (appointments_todo.length === 0) return null

        const todo = appointments_todo[0]

        return <>
            <td className="center" style={{width: "10%"}}><i className="material-icons black-text"
                                                             title="Nouvel examen">start</i></td>
            <td className="center" style={{width: "15%"}}>{Helper.formatDate(todo.start, "DD/MM/YYYY hh:mm")}</td>
            <td className="center" style={{width: "15%"}}>Nouvel examen</td>
            <td className="center" style={{width: "20%"}}></td>
            <td className="center" style={{width: "50%"}}>
                <a className="btn btn-small green darken-2 z-depth-0"
                   href={`/mesure/${todo.id}`} title={`Démarrer la consultation`}>
                    <i className="material-icons left">play_arrow</i>
                    Démarrer consultation
                </a>
            </td>
        </>

        return <div className="valign-wrapper" style={{justifyContent: "center", gap: 20, width: "100%"}}>
            <h5 style={{margin: 0, fontWeight: "bolder"}}>Un examen est prévu aujourd'hui
                à {moment(todo.start).format("HH:mm")}</h5>
            <a className="btn btn-small blue darken-2 z-depth-0"
               href={`/mesure/${todo.id}`} title={`Démarrer la consultation`}>
                <i className="material-icons left">play_arrow</i>Démarrer consultation
            </a>
        </div>
    }

    return (
        <div className="card" style={{...style}} data-cy="ExamHistoryComponent">
            <div style={{gap: 10, height: '100%'}}>
                <h2 className="center" style={{marginTop: 0, marginBottom: 10}}>Historique des examens</h2>
                <hr/>
                {appointments.length > 0 ? (
                    <div style={{height: "88%"}}>
                        <div style={{overflowY: "auto", height: "100%", gridRow: 1, gridColumn: 1}}>
                            <table className="highlight">
                                <thead>
                                <tr>
                                    <th className="center" style={{width: "10%"}}>Etat</th>
                                    <th className="center" style={{width: "15%"}}>Date</th>
                                    <th className="center" style={{width: "15%"}}>Type d'examen</th>
                                    <th className="center" style={{width: "10%"}}>Orthoptiste</th>
                                    <th className="center" style={{width: "50%"}}></th>
                                </tr>
                                </thead>
                                <tbody>
                                {appointments.map((appointment, index) => renderAppointment(appointment, index))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                ) : <p className="center">Aucun examen trouvé.</p>}
                {orthoptiste_signed ? (
                    <div className="center" style={{marginTop: "2vh", width: "100%"}}>
                        <a className="btn btn-small blue darken-2 z-depth-0"
                           title={`Lancer une nouvelle consultation`}
                           href={`/NewAppointement/${data.patient.id}`}>
                            <i className="material-icons left">add</i>Nouvelle consultation
                        </a>
                    </div>
                ) : null}
            </div>
        </div>

    );
}

export default ExamHistory