import React, { Component } from 'react';
import axios from "axios"

export default class SelectPrescripteur extends Component {
  constructor(props){
    super(props);
    this.loadPrescripteurs= this.loadPrescripteurs.bind(this)
    this.setPrescripteur = this.setPrescripteur.bind(this)
    this.state = {
      prescripteurs: [],
      selected: ""
    }
    this.loadPrescripteurs()
  }
  loadPrescripteurs(){
    axios.get("/api/getPrescripteurs").then((data)=>{
      this.setState({prescripteurs: data.data.prescripteurs,
        selected: data.data.prescripteur || ""})
    })
  }
  setPrescripteur(e){
    this.setState({selected: e.target.value})
    axios.post("/api/setPrescripteur",{prescripteur: e.target.value}).then((data)=>{

    })
  }
  render() {
    return (
      <div>
        <select onChange={this.setPrescripteur} value={this.state.selected} className="browser-default">
          <option value="" disabled> Médecin prescripteur </option>
          {this.state.prescripteurs.map((p)=>(
            <option key={p.id} value={p.id}>{p.nom}</option>
            ))}
        </select>
      </div>
    );
  }
}
