import React, {Component} from 'react';
import moment from "moment";

const _ = require('lodash');
export default class HelpButton extends Component {
    constructor(props) {
        super(props);
        this.id = `HelpButton-${moment.now()}`
    }

    componentDidMount() {
        const elems = document.getElementById(this.id);
        M.Modal.init(elems, {});
    }

    componentWillUnmount() {
        M.Modal.getInstance(document.getElementById(this.id)).destroy()
    }


    render() {
        return (
            <div>
                <a className="btn-flat btn-small transparent modal-trigger" data-target={this.id}>
                    <i className={`material-icons ${this.props.color} ${this.props.size === "" ? "" : this.props.size}`}>help</i>
                </a>
                <div id={this.id} className="modal">
                    <div className="modal-content" style={{backgroundColor: "#FFFFFF"}}>
                        <div className="center-align" style={{margin: "auto"}}>
                            {this.props.imgSrc === "" ? (<div/>) : (
                                <img className="materialboxed" src={this.props.imgSrc} style={{maxHeight:"70vh"}}/>)}
                            {this.props.videoSrc === "" ? (<div/>) : (
                                <video loop autoPlay muted style={{maxHeight: `${this.props.height}`, maxWidth: "50vw"}}>
                                    <source src={this.props.videoSrc} type="video/mp4"
                                    />
                                </video>
                            )}

                        </div>
                        <p style={{whiteSpace: "pre-wrap"}}>{this.props.text}</p>
                    </div>
                </div>
            </div>
        )
    }
}

HelpButton.defaultProps = {
    text: "",
    imgSrc: "",
    videoSrc: "",
    color: "",
    size: "",
    height: "200vh"
}