import React from "react";
import axios from "axios";
import ReactToPrint from "react-to-print";
import Helper, {JOB} from "../Shared/Helper";
import Loader from "../Shared/Loader";
import {Side, TypesMesures} from "../Shared/Enums/Enums";
import {formatRetinos} from "../Appointments/Mesures/mesure_components/mesures";

const pageStyle = `
@media print {
   body {
       display: block;
       box-sizing: border-box;   
       padding: 20mm 15mm;
       height: auto;
       fontFamily: Arial, sans-serif;
   }
   
   @page {
      size: auto;   /* auto is the initial value */
      margin: 0;  /* this affects the margin in the printer settings */
   }
}`;


export default class RNM_Rapport extends React.Component {

    constructor(props) {
        super(props);
        this.componentRef = React.createRef();
        this.state = {
            loaded: false,
            appointment_data: null
        }
    }

    componentDidMount() {
        this.getAppointmentData()
    }

    getAppointmentData = () => {
        axios.get("/api/rapports/rnm?appointment_id=" + this.props.appointment_id + "&patient_id=" + this.props.patient_id)
            .then((resp) => {
                this.setState({appointment_data: resp.data.appointment_data, loaded: true}, () => {
                    setTimeout(() => window.status = "ready", 100)
                })
            })
    }

    formatMeasures = (type, measures) => {
        if (type === "right") {
            let cylinder = ""
            if (measures.od_cylindre) cylinder = ` (${measures.od_cylindre})`
            let axis = ""
            if (measures.od_axe) axis = ` ${measures.od_axe}°`
            let od_parinaud = measures.od_parinaud ?? ""
            if (od_parinaud) od_parinaud = `P${od_parinaud}`
            else od_parinaud = ""
            let [od_av_loin, od_type] = this.processString(measures.od_av_loin)
            if (od_av_loin && parseInt(od_av_loin)) od_av_loin = `${(od_av_loin * 10) || od_av_loin}/10`
            let add = ""
            if(measures.od_addition) add = ` add ${measures.od_addition}`
            return `${measures.od_sphere ?? "plan"}${cylinder}${axis}${add} ${od_av_loin} ${od_parinaud}`
        } else {
            let cylinder = ""
            if (measures.og_cylindre) cylinder = ` (${measures.og_cylindre})`
            let axis = ""
            if (measures.og_axe) axis = ` ${measures.og_axe}°`
            let og_parinaud = measures.og_parinaud ?? ""
            if (og_parinaud) og_parinaud = `P${og_parinaud}`
            let [og_av_loin, og_type] = this.processString(measures.og_av_loin)
            if (og_av_loin && parseInt(og_av_loin)) og_av_loin = `${(og_av_loin * 10) || og_av_loin}/10`
            let add = ""
            if(measures.og_addition) add = ` add ${measures.og_addition}`
            return `${measures.og_sphere ?? "plan"}${cylinder}${axis}${add}${add} ${og_av_loin} ${og_parinaud}`
        }
    }

    processString = (input) => {
        /*
        If input is null or empty => return null and type null
        If input is float => return input converted to float and type float
        If input is int => return input converted to int and type int
        else return the initial string with type string
         */
        if (input === null || input === '') {
            return ['', null]
        } else if (!isNaN(input) && Number.isInteger(parseFloat(input))) {
            return [parseInt(input), "int"]
        } else if (!isNaN(input) && !Number.isInteger(parseFloat(input))) {
            return [parseFloat(input), "float"]
        } else {
            return [input, "string"]
        }
    }

    formatNumeric = (num) => {
        if(num == null) return "/"
        if(num < 0) return "NM"
        return num
    }

    formatList = (str) => {
        return str.replace(/\s*:\s*/g, ' :\n\t •  ').replace(/\s*\+\s*/g, '\n\t •  ')
    }

    renderConclusionText = () => {
        if (this.state.appointment_data["alert"]["comment"]["conclusion_text"]) {
            return <div>
                <div style={{marginBottom: "1vh", whiteSpace: "pre-wrap"}}>
                    {this.formatList(this.state.appointment_data["alert"]["comment"]["conclusion_text"])}
                </div>
            </div>
        }

        return <div>
            <div style={{marginBottom: "1vh"}}>
                A la lecture des examens réalisés, il apparait une suspicion
                de:
                {this.state.appointment_data["alert"]["comment"]["risk"].map((risk) => {
                    return <div style={{paddingLeft: "2vw"}}
                                key={risk}>- {risk}</div>
                })}
            </div>
            <div>
                Nous recommandons que ce patient soit reconvoqué afin
                d'effectuer le ou les examens suivant(s):
                {this.state.appointment_data["alert"]["comment"]["todo"].map((todo) => {
                    return <div style={{paddingLeft: "2vw"}}
                                key={todo}>- {todo}</div>
                })}
            </div>
        </div>
    }

    render() {
        const od_tension = this.formatNumeric(this.state.appointment_data?.tension?.od_tension)
        const og_tension = this.formatNumeric(this.state.appointment_data?.tension?.og_tension)
        const od_pachymetry = this.formatNumeric(this.state.appointment_data?.pachymetry?.od_pachymetry)
        const og_pachymetry = this.formatNumeric(this.state.appointment_data?.pachymetry?.og_pachymetry)
        return (
            <div>
                {this.state.loaded ?
                    <div style={{fontFamily: "Arial, sans-serif"}}>
                        <div className={this.props.print ? "" : "hide"}>
                            <ReactToPrint
                                trigger={() => {
                                    return (
                                        <a className="btn blue darken-2 z-depth-0"
                                           style={{position: "absolute", top: "5%", right: "5%"}}>
                                            Imprimer
                                        </a>)
                                }}
                                content={() => this.componentRef.current}
                                documentTitle="Compte Rendu"
                                pageStyle={pageStyle}>
                            </ReactToPrint>
                        </div>

                        <div ref={this.componentRef} id="RNMReport">
                            <div className="pdf-document" style={{display: "flex", flexDirection: "column", gap: 20, marginTop: 20}}>
                                <div>
                                    {/*HEADER*/}
                                    <div className="prevent-break"
                                        dangerouslySetInnerHTML={{__html: this.state.appointment_data["appointment"]["header"]}}/>
                                    {/*BODY TEXT*/}
                                    <div className="prevent-break">
                                        <p>Le {this.state.appointment_data["prescription"] ? this.state.appointment_data["prescription"]["date_prescription"] : Helper.formatDate(new Date)}
                                        </p>
                                        <p className="center" style={{"textDecoration": "underline"}}>Compte rendu de la
                                            consultation de télé-ophtalmologie</p>
                                        <p>
                                            Cher confrère, <br/>
                                            Veuillez trouver ci-dessous le compte rendu de la consultation
                                            {` du ${Helper.formatDate(this.state.appointment_data["appointment"]["start"])}
                                            de ${this.state.appointment_data["patient"]["name"]} ${this.state.appointment_data["patient"]["surname"]}
                                            né(e) le \n${Helper.formatDate(this.state.appointment_data["patient"]["birthday"])} reçu
                                            par ${this.state.appointment_data["orthoptist"]["name"] || ""} ${this.state.appointment_data["orthoptist"]["surname"] || ""}, 
                                            ${JOB.toLowerCase()}.`
                                            }

                                        </p>
                                    </div>
                                    {/*PATIENT INFOS*/}
                                    <div className="prevent-break" style={{display: "flex", flexDirection: "column"}}>
                                        <h3 style={{alignSelf: "center"}}>Coordonnées du patient</h3>
                                        <h5 style={{margin: 5}}>Téléphone
                                            : {this.state.appointment_data["patient"]["phone"] ? this.state.appointment_data["patient"]["phone"] : "Inconnu"}</h5>
                                        <h5 style={{margin: 5}}>Email
                                            : {this.state.appointment_data["patient"]["email"] ? this.state.appointment_data["patient"]["email"] : "Inconnu"}</h5>
                                    </div>
                                    {/*MEASURES*/}
                                    <div className="prevent-break" style={{display: "flex", flexDirection: "column"}}>
                                        <h3 style={{alignSelf: "center"}}>Mesures</h3>
                                        <table style={{fontFamily: "system-ui"}}>
                                            <tbody>
                                            {this.state.appointment_data ?
                                                this.state.appointment_data["measures"].map((mesure) => {
                                                    if([TypesMesures.TENSION, TypesMesures.PACHYMETRY].includes(mesure["title"])) return null
                                                    return (
                                                        <tr key={mesure["id"]}>
                                                            <td><b>{Helper.capitalizeFirstLetter(mesure["title"])}:</b>
                                                            </td>
                                                            <td>{this.formatMeasures("right", mesure["right_eye"])}</td>
                                                            <td>{this.formatMeasures("left", mesure["left_eye"])}</td>
                                                        </tr>
                                                    )
                                                })
                                                : null}
                                            <tr>
                                                <td><b>Tension</b></td>
                                                <td>{od_tension}</td>
                                                <td>{og_tension}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Pachymétrie</b></td>
                                                <td>{od_pachymetry}</td>
                                                <td>{og_pachymetry}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div>
                                    {/*PRESCRIPTION*/}
                                    <div className="prevent-break" style={{display: "flex", flexDirection: "column"}}>
                                        <h3 style={{alignSelf: "center"}}>Prescription
                                            ({this.state.appointment_data["appointment"]["status_control"] === 2 ? "Délivrée" : "Bloquée"})</h3>
                                        <p style={{"whiteSpace": "pre-wrap", fontSize: 14}}>
                                            {this.state.appointment_data["prescription"] ? this.state.appointment_data["prescription"]["text"] : "Pas de prescription"}
                                        </p>
                                    </div>
                                    {/*CONCLUSION*/}
                                    <div className="prevent-break" style={{display: "flex", flexDirection: "column"}}>
                                        <h3 style={{alignSelf: "center"}}>Conclusion</h3>
                                        {this.state.appointment_data["appointment"]["status_control"] === 2 ?
                                            <p>A la lecture des examens réalisés, aucune pathologie n'a été
                                                détectée.</p>
                                            :
                                            this.state.appointment_data["alert"] && this.state.appointment_data["alert"]["comment"] ?
                                                this.renderConclusionText()
                                                :
                                                <p>
                                                    A la lecture des examens réalisés, il apparait qu'il est nécessaire
                                                    que le patient ait des examens approfondis. Nous recommandons que ce
                                                    patient soit reconvoqué.
                                                </p>
                                        }
                                    </div>
                                    {/*SIGNATURE*/}
                                    <div className="right-align prevent-break" style={{paddingTop: 20}}>
                                        <img width="35%"
                                             src={this.state.appointment_data["ophthalmologist"]["signature"]}
                                             alt=""/>
                                    </div>
                                    {/*IMAGES (?)*/}
                                    {/*<div style={{*/}
                                    {/*    display: "flex",*/}
                                    {/*    flexWrap: "wrap",*/}
                                    {/*    justifyContent: "center",*/}
                                    {/*    gap: 10,*/}
                                    {/*    paddingTop: 20*/}
                                    {/*}}>*/}
                                    {/*    {this.state.appointment_data["appointment"]["images"].map((image) => {*/}
                                    {/*        return (*/}
                                    {/*            <div key={image["id"]} style={{maxWidth: "50%"}}>*/}
                                    {/*                <img src={image["service_url"]} alt="" className="responsive-img"*/}
                                    {/*                     style={{maxHeight: 200}}/>*/}
                                    {/*            </div>*/}
                                    {/*        )*/}
                                    {/*    })}*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    : <div id="full-height"
                           style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <Loader wide/>
                    </div>}
            </div>)
    }
}
