import React, {useEffect} from 'react'
import {usePagination} from "../Hooks/usePagination";

function Pagination({count, limit, onChange = () => null, limitButtons = false}) {
    const {page, totalPage, setNextPage,
        setPrevPage, setFirstPage, setLastPage,
        isFirstPage, isLastPage, resetPage} = usePagination(count, limit, (page) => onChange(page))

    useEffect(() => {
        resetPage()
    }, [count, limit])

    if(!limit || !count) return null

    return <div className="custom-pagination" style={{display: "flex", gap: 10}}>
        <a className={`btn-flat large-btn ${isFirstPage ? "disabled" : ""} ${limitButtons ? "" : "hide"}`}
           onClick={setFirstPage}><i className="material-icons">first_page</i></a>
        <a className={`btn-flat large-btn ${isFirstPage ? "disabled" : ""}`}
           onClick={setPrevPage}><i className="material-icons">chevron_left</i></a>
        <h6 style={{
            alignSelf: "center",
            margin: 0
        }}>{page} / {totalPage}</h6>
        <a className={`btn-flat large-btn ${isLastPage ? "disabled" : ""}`}
           onClick={setNextPage}><i className="material-icons">chevron_right</i></a>
        <a className={`btn-flat large-btn ${isLastPage ? "disabled" : ""} ${limitButtons ? "" : "hide"}`}
           onClick={setLastPage}><i className="material-icons">last_page</i></a>
    </div>
}

export default Pagination