import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import axios from "axios";
import Helper from "../../Shared/Helper";
import MessengerStore from "../MessengerStore";
import {ConversationType} from "../../Shared/Enums/Enums";

function ConversationHeader() {
    const {id} = useParams()
    const [users, setUsers] = useState([])
    const conversation = MessengerStore.conversations.find(c => c.id === parseInt(id))

    useEffect(() => {
        loadUsers()
    }, [id]);

    const loadUsers = () => {
        axios.get(`messenger/conversation/${id}/users`).then(resp => {
            setUsers(resp.data.users)
        })
    }

    const renderHeader = () => {
        switch (conversation.group_type) {
            case ConversationType.GROUP.value:
                return <h4 style={{margin: 0, gap: 20}} className="valign-wrapper">
                    <i className="material-icons">groups</i>
                    Conversation avec le cabinet {Helper.capitalizeFirstLetter(conversation.name)}
                </h4>
            case ConversationType.PRIVATE.value:
                return <h4 style={{margin: 0, gap: 20}} className="valign-wrapper">
                    <i className="material-icons">person</i>
                    Conversation avec {conversation.name}
                </h4>
        }
    }

    if(!conversation) return null

    return <div className="valign-wrapper" style={{minHeight: 100, padding: 20, boxShadow: "0px 1px 0px 0px rgba(0, 0, 0, 0.10)"}}>
        {renderHeader()}
        <div style={{marginLeft: "auto"}}>
            {users.map(user => <div key={user} className="chip" style={{marginRight: 5}}>{user}</div>)}
        </div>
    </div>
}

export default ConversationHeader;