import { action, observable,computed, autorun } from "mobx"
import axios from "axios"


class SetupStore{
  @observable type=null //doit etre nul, je force usb pour by passer la premiere etape
}

let setup = new SetupStore();
export default setup;
