import React, { Component } from 'react';
import BridgeStore from "../../BridgeStore"
import {observer} from "mobx-react"

@observer
export default class Statut extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }
    render() {
        return (
            <div>
                {BridgeStore.connected_usbs.some(e=>e.hwid == this.props.hwid) ? (
                    <b><h2 className="center bridge-statut bridge-Disponible">Actuellement connécté</h2></b>
                ) : (
                    <b><h2 className="center bridge-statut bridge-Indisponible">Inactif</h2></b>
                )}
            </div>
        );
    }
}
