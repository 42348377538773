import React, {useEffect, useState} from "react";
import axios from "axios";
import BorderedDisplay from "../../../../Shared/UiKit/BorderedDisplay";
import {MessageLevelAlert} from "../../../../Shared/Enums/Enums";
import PrescriptionStore from "../../../../Appointments/PrescriptionStore";
import InputText from "../../../../Shared/UiKit/InputText";

function InternalMessage({user}) {
    const [messages, setMessages] = useState([]);
    const [selectedMessage, setSelectedMessage] = useState({id: undefined, titre: "", message: ""})

    useEffect(() => {
        get_messages()
    }, []);

    const get_messages = () => {
        axios.get(`/admin/console/users/orthoptist/${user.id}/textmessage`)
            .then(response => {
                setMessages(response.data.messages)
            }).catch(_ => {
        })
    }

    const handlePossibilities = (event) => {
        const selectedValue = event.target.value;
        const foundMessage = messages.find(message => message.titre === selectedValue);
        setSelectedMessage(foundMessage || {id: undefined, titre: selectedValue, message: ""});
    }

    const handleTextareaChange = (event) => {
        setSelectedMessage((prevState) => ({
            ...prevState,
            message: event.target.value
        }));
    }

    const createMessage = () => {
        if (selectedMessage.message.length === 0) return

        axios.put(`/admin/console/users/orthoptist/textmessage`, {
            orthoptist_id: user.id,
            titre: selectedMessage.titre,
            message: selectedMessage.message
        }).then(response => {
            const newMessage = {
                id: response.data.message.id,
                titre: response.data.message.titre,
                message: response.data.message.message
            };
            setMessages([...messages, newMessage]);
            setSelectedMessage(newMessage)
        }).catch(_ => {
        })
    }

    const updateMessage = () => {
        if (selectedMessage.message.length === 0) {
            deleteMessage()
            return
        }
        axios.patch(`/admin/console/users/orthoptist//${selectedMessage.id}/textmessage`, {
            orthoptist_id: user.id,
            titre: selectedMessage.titre,
            message: selectedMessage.message
        }).then(response => {
            const updatedMessages = messages.map(message => {
                if (message.id === selectedMessage.id) {
                    return {
                        id: response.data.message.id,
                        titre: response.data.message.titre,
                        message: response.data.message.message
                    };
                }
                return message;
            });
            setMessages(updatedMessages);
        }).catch(_ => {
        })
    }

    const deleteMessage = () => {
        const titre = selectedMessage.titre
        axios.delete(`/admin/console/users/orthoptist/${selectedMessage.id}/textmessage`)
            .then(response => {
                setMessages(messages.filter(item => item.id !== selectedMessage.id))
                setSelectedMessage({id: undefined, titre: titre, message: ""});
            }).catch(_ => {
        })
    }

    const renderCreateOrEditButton = () => {
        if (selectedMessage.id) return <a className="btn green darken-2 z-depth-0" style={{width: "140px"}}
                                          onClick={updateMessage}>
            <i className="material-icons left">edit</i>
            Modifier
        </a>

        return <a className={`btn blue darken-2 z-depth-0 ${selectedMessage.titre === '' ? 'disabled' : ''}`}
                  style={{width: "140px"}} onClick={createMessage}>
            <i className="material-icons left">done</i>
            Créer
        </a>
    }

    return (
        <div className="log-display" style={{minHeight: "430px"}}>
            <BorderedDisplay text="Messages envoyé aux patients" height="100%">
                <div className="row" style={{marginBlock: 10}}>
                    <div className="col l6 valign-wrapper">
                        <p style={{margin: 0}}>Choisissez une gravité de message à créer ou modifier</p>
                    </div>
                    <div className="col s12 l6 valign-wrapper">
                        <select className="browser-default" onChange={handlePossibilities}
                                value={selectedMessage.titre}>
                            <option value="" disabled>Choisissez le type de message</option>
                            {Object.values(MessageLevelAlert).map((possibility_title, index) => (
                                <option key={index} value={possibility_title}>{possibility_title}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <InputText type="text-area" placeholder="Entrez le message personnalisé pour cette gravité"
                           onChange={handleTextareaChange} height={250} v_resizable={false}
                           value={selectedMessage.message} className=""/>
                <div className="row" style={{display: "flex", justifyContent: "space-between", marginTop: '15px'}}>
                    <a className={`btn red darken-2 z-depth-0 ${!selectedMessage.id ? "disabled" : ""}`}
                       style={{width: "140px"}} onClick={deleteMessage}>
                        <i className="material-icons left">delete</i>
                        Supprimer
                    </a>
                    {renderCreateOrEditButton()}
                </div>
            </BorderedDisplay>
        </div>
    );
}

export default InternalMessage;
