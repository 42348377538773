import React from "react"
import axios from "axios"
import {observer} from "mobx-react"
import {toast} from 'react-toastify';
import Loader from "../Shared/Loader";
import {compareNormalizedString} from "../Shared/Helper";
import Tooltip from "../Shared/Tootip";
import InputText from "../Shared/UiKit/InputText";
import MesureStore from "../Appointments/Mesures/MesureStore";

@observer
class Pathologies extends React.Component {
    constructor(props) {
        super(props);
        this.addSimplePathologie = this.addSimplePathologie.bind(this)
        this.addPrioritaryPathologie = this.addPrioritaryPathologie.bind(this)
        this.handeWriting = this.handeWriting.bind(this)
        this.sendPathologie = this.sendPathologie.bind(this)
        this.addManuelSimple = this.addManuelSimple.bind(this)
        this.addManuelPrioritary = this.addManuelPrioritary.bind(this)
        this.changeAlert = this.changeAlert.bind(this)
        this.destroyPathologie = this.destroyPathologie.bind(this)
        this.getPathologie = this.getPathologie.bind(this)
        this.sendAlert = this.sendAlert.bind(this)
        this.modalPop = this.modalPop.bind(this)
        this.state = {
            txt: "",
            loading: true,
            possibilitesPathologies: [],
            pathologies: [],
            alerted: false
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.pathologies.length !== prevState.pathologies.length) {
            MesureStore.pathologies = this.state.pathologies
        }
    }

    sendAlert() {
        axios.post("/api/pathologie/makeAlert", {
            pathologies: this.state.pathologies,
            alerted: this.state.alerted,
            appointment_id: MesureStore.appointement_id
        }).then(() => {
            toast.success('Alerte envoyée', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        })
    }

    addSimplePathologie(index) {
        let pathologie = this.state.possibilitesPathologies[index]
        pathologie = Object.assign({}, pathologie, {index: index})
        pathologie.needalert = false
        this.sendPathologie(pathologie)
    }

    addPrioritaryPathologie(index) {
        let pathologie = this.state.possibilitesPathologies[index]
        pathologie = Object.assign({}, pathologie, {index: index})
        pathologie.needalert = true
        this.sendPathologie(pathologie)
        this.sendAlert()
    }

    sendPathologie(pathologie) {
        axios.post("/api/addPathologie", {
            pathologie: pathologie,
            appointment_id: MesureStore.appointement_id
        }).then((data) => {
            this.setState({pathologies: data.data})
        })
    }

    addManuelSimple(e) {
        e.preventDefault();
        let bool = true
        for (var i = 0; i < this.state.possibilitesPathologies.length; i++) {
            if (this.state.txt.toLowerCase().replace(/\s+/g, "") === this.state.possibilitesPathologies[0].titre.toLowerCase().replace(/\s+/g, "")) {
                bool = false
            }
        }
        for (var j = 0; j < this.state.pathologies.length; j++) {
            if (this.state.txt.replace(/\s+/g, "").toLowerCase() === this.state.pathologies[j].pathologie.replace(/\s+/g, "").toLowerCase()) {
                bool = false
            }
        }
        if (bool) {
            this.sendPathologie({titre: this.state.txt, needalert: false})
            this.setState({txt: ""})
        } else {
            toast.error("Il existe déjà une pathologie de ce nom")
        }
    }

    addManuelPrioritary(e) {
        e.preventDefault();
        let bool = true
        for (var i = 0; i < this.state.possibilitesPathologies.length; i++) {
            if (this.state.txt === this.state.possibilitesPathologies[i].titre) {
                bool = false
            }
        }
        for (var j = 0; j < this.state.pathologies.length; j++) {
            if (this.state.txt === this.state.pathologies[j].pathologie) {
                bool = false
            }
        }
        if (bool) {
            this.sendPathologie({titre: this.state.txt, needalert: true})
            this.setState({txt: ""})
            this.sendAlert()
        } else {
            toast.error("Il existe déjà une pathologie avec ce nom")
            this.setState({txt: ""})
        }
    }

    componentDidMount() {
        axios.get("/api/getPossibilitesAndCurrents", {params: {appointment_id: MesureStore.appointement_id}}).then((data) => {
            this.setState({
                possibilitesPathologies: data.data[0],
                pathologies: data.data[1],
                alerted: data.data[2],
                loading: false
            })
        })
    }

    handeWriting(e) {
        this.setState({
            txt: e.target.value
        })
    }

    destroyPathologie(pathologyName) {
        const pathologie_to_destroy = this.getPathologie(pathologyName)
        axios.post("/api/destroyPathologie", {
            id: pathologie_to_destroy.id,
            appointment_id: MesureStore.appointement_id
        }).then((data) => {
            this.setState({pathologies: data.data})
        }).catch(() => {
        })
    }

    changeAlert(name, alerted) {
        const pathologie = this.getPathologie(name)
        axios.post("/api/updatePathologie", {
            id: pathologie.id,
            alert: alerted,
            appointment_id: MesureStore.appointement_id
        }).then((data) => {
                this.setState({pathologies: data.data})
            }
        )
    }

    modalPop() {
        var elems = document.getElementById("modal-help");
        var instance = M.Modal.init(elems, {});
        instance.open()
    }

    getPathologie(pathologieName) {
        const selected_pathologies = this.state.pathologies
        return selected_pathologies.find(pathologies => pathologies.pathologie === pathologieName)
    }


    render() {
        // Ok that one is a bit spicy
        // We want to display all the possible pathologies but some of them are already mentionned as "possible pathologies"
        // So we want to display the possible pathologies then to display the pathologies that are NOT in the possible pathologies
        // BUT we need to filter the pathologies that are in the possible pathologies
        // I construct an array with the possible possibilities and the pathologies that are not in the possible pathologies just after
        // and I format them so we have {name, alerted, base_pathology}
        // where name is the name of the pathology
        // alerted is a boolean that tells if the pathology is alerted or not
        // and base_pathology is a boolean that tells if the pathology is a base pathology or not
        // We need that last boolean because if the pathology is a base pathology, UI displays it slightly different
        // You're probably telling yourself that filtering the possible pathologies were simpler, but if we do that we lose the order of the pathologies
        const pathos = [
            // Here is the base pathology part
            ...this.state.possibilitesPathologies.map((possible_pathology) => {
                const corresponding_pathology = this.getPathologie(possible_pathology.titre)
                // We format the possible pathologies according to the plan
                return {
                    name: possible_pathology.titre,
                    alerted: corresponding_pathology ? corresponding_pathology.alert : false,
                    // We check if the possible pathology is in the pathologies if so, it's not counted as a base pathology anymore
                    base_pathology: !corresponding_pathology
                }
            }),
            // And there the pathology that are not in the possible pathologies
            ...this.state.pathologies
                // We filter the pathologies that are in the possible pathologies so they are not displayed twice
                .filter(pathology => !this.state.possibilitesPathologies.map(possible_pathology => possible_pathology.titre).includes(pathology.pathologie))
                // Then we format them according to the plan
                .map((pathology) => {
                    return {name: pathology.pathologie, alerted: pathology.alert, base_pathology: false}
                })
        ]

        if (this.state.loading) {
            return <div id="full-height"
                        style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                <Loader wide/>
            </div>
        } else {
            return (
                <div style={{margin: 20, marginInline: 40}}>
                    {/*<h1 style={{display: "flex", justifyContent: "center", alignItems: "center", gap: 10}}>Pathologie*/}
                    {/*    <a onClick={this.modalPop}*/}
                    {/*       className="btn btn-small z-depth-0 transparent modal-trigger"*/}
                    {/*       data-target="modal-help" style={{borderRadius: "2px 0 0 2px", padding: "0 7px 0 7px"}}>*/}
                    {/*        <i className="material-icons black-text">help</i>*/}
                    {/*    </a>*/}
                    {/*</h1>*/}
                    {/*<div id="modal-help" className="modal">*/}
                    {/*    <div className="modal-content center">*/}
                    {/*        <h4>Présentation côté Ophtalmo</h4>*/}
                    {/*        <div className="row" style={{gap: 20}}>*/}
                    {/*            <div className="col s6">*/}
                    {/*                <img src="/imgs/exempleIndiPdvOph.png" style={{width: '100%'}}/>*/}
                    {/*            </div>*/}
                    {/*            <div className="col s6">*/}
                    {/*                <img src="/imgs/exemplePrioPdvOph.png" style={{width: '100%'}}/>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="modal-footer">*/}
                    {/*        <a className="modal-close waves-effect waves-green btn-flat">C'est d'accord !</a>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="row center" style={{gap: 20}}>
                        <div className="col s12">
                            <table>
                                <tbody>
                                <tr>
                                    <th>Pathologie</th>
                                    <Tooltip id="pathology-normal-tt"
                                             text="Une indication sera ajoutée au dossier signalant que vous suspectez les pathologies suivantes.">
                                        <th style={{textAlign: "center"}}><i
                                            className="material-icons blue-text">notifications</i><p
                                            style={{marginTop: 0}}>Pathologie suspectée</p></th>
                                    </Tooltip>
                                    <Tooltip id="pathology-alerted-tt"
                                             text="Le dossier sera traité de façon prioritaire, et cette pathologie sera mise en avant.">
                                        <th style={{textAlign: "center"}}><i
                                            className="material-icons red-text">warning</i>
                                            <p style={{marginTop: 0}}>Demande d'analyse prioritaire</p></th>
                                    </Tooltip>
                                </tr>
                                {pathos.map((patho, index) => {
                                    return <tr key={index} data-cy="PossiblePatientPathology">
                                        <td>{patho.name}</td>
                                        <th style={{textAlign: "center"}}>
                                            <label>
                                                <input
                                                    id={`simple_box_${index}`}
                                                    type="checkbox"
                                                    className="filled-in"
                                                    checked={!patho.base_pathology}
                                                    data-cy="SuspectedPathologyCheckbox"
                                                    onClick={() => {
                                                        patho.base_pathology ? this.addSimplePathologie(index) : this.destroyPathologie(patho.name)
                                                    }}
                                                />
                                                <span></span>
                                            </label>
                                        </th>
                                        <th style={{textAlign: "center"}}>
                                            <label>
                                                <input
                                                    id={`alert_box_${index}`}
                                                    type="checkbox"
                                                    className="filled-in"
                                                    checked={patho.alerted}
                                                    data-cy="AlertPathologyCheckbox"
                                                    onClick={() => patho.base_pathology ? this.addPrioritaryPathologie(index) : this.changeAlert(patho.name, !patho.alerted)}
                                                />
                                                <span></span>
                                            </label>
                                        </th>
                                    </tr>
                                })}
                                </tbody>
                            </table>
                        </div>
                        <div className="col s12" style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 20,
                            alignItems: "center"
                        }}>
                            <div style={{width: 600, display: "flex", flexDirection: "column", gap: 20}}>
                                {/*<h5>Nouvelle pathologie souçconnée</h5>*/}
                                <InputText placeholder="Ajouter une pathologie soupçonnée"
                                           type="input" id="manuelData" onChange={this.handeWriting}
                                           value={this.state.txt}
                                           width="100%"/>
                                <div style={{
                                    display: "flex",
                                    gap: 20,
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexWrap: "wrap"
                                }}>
                                    <a className="btn blue darken-2 z-depth-0" onClick={this.addManuelSimple}
                                       name="action">Indication
                                        <i className="material-icons right">add_alert</i>
                                    </a>
                                    <a className="btn red darken-2 z-depth-0"
                                       onClick={this.addManuelPrioritary}
                                       name="action">Prioritaire
                                        <i className="material-icons right">notification_important</i>
                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default Pathologies