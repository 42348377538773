import React from 'react';
import axios from "axios";
import {toast} from "react-toastify";
import Validator from "../../Shared/Validator";

function SendCodeToPatient({patient}) {
    const sendEmail = () => {
        axios.put(`/patient/${patient.id}/send_key`).then(res => {
            toast.success("Email envoyé")
        }).catch(err => {
            if(err.response.status === 429) {
                toast.error("Un email a déjà été envoyé récemment.")
            } else {
                toast.error("Une erreur est survenue")
            }
        });
    }

    return (
        <Validator onValidation={sendEmail}
                   text={`Envoyer les clés de connexion à ${Helper.formatName(patient.prenom, patient.nom)} ?`}
                   detail="Un mail automatique sera envoyé à cette personne."
                   validationText="Envoyer" validationColor="blue darken-2" validationIcon="send"
                   abortColor="black darken-2" abortIcon="cancel" abortText="Annuler"
                   id="validate-patient_key_email">
            <a className="btn-small blue darken-2 z-depth-0"
               title="Envoyer les clés de connexion par email">
                <i className="material-icons">mail</i>
            </a>
        </Validator>
    );
}

export default SendCodeToPatient;