import React, {useEffect} from 'react';
import axios from "axios";
import MesureStore from "../../MesureStore";
import useDebounce from "../../../../Shared/Hooks";
import {loading, RequestState} from "../../../../Shared/StateHelper";
import {Observer} from "mobx-react";
import BrandedElement from "../../../../Shared/BrandedElement";

function EditComments(props) {
    const [data, setData] = React.useState(undefined)
    const [requestState, setRequestState] = React.useState(RequestState.IDLE)
    const [loaded, setLoaded] = React.useState(false)

    const debouncedSave = useDebounce(data, 500)

    useEffect(() => {
        if (data && loaded) {
            setRequestState(loading(requestState))
            saveData()
        }
        if(data) {
            setLoaded(true)
        }
    }, [debouncedSave])

    useEffect(() => {
        if (data && loaded) props.loading()
    }, [data]);

    useEffect(() => {
        fetchData()
        return () => saveData()
    }, []);

    const fetchData = () => {
        setRequestState(loading(requestState))
        axios.get("/api/appointement/CommentaireLibre", {params: {appointment_id: MesureStore.appointement_id}}).then((resp) => {
            setData({anamnesis: resp.data[0], private_comment: resp.data[1], orthoptic_text: resp.data[2]})
            setRequestState(RequestState.SUCCESS)
        })
    }


    const saveData = () => {
        if (!data) return
        axios.post("/api/appointement/saveCommentaireLibre", {
            text: data.anamnesis,
            private_comment: data.private_comment,
            orthoptic_text: data.orthoptic_text,
            appointment_id: MesureStore.appointement_id
        }).then((_) => {
            MesureStore.anamnesis = data.anamnesis
            MesureStore.orthoptic_text = data.orthoptic_text
            MesureStore.private_comment = data.private_comment
            props.loaded()
        })
    }


    return <Observer>
        {() => <div style={{display: "flex", flexDirection: "column", gap: 10}}>
            <div className="input-field outlined">
                <textarea
                    defaultValue={data?.anamnesis}
                    style={{resize: "none", padding: 10, height: 120, fontSize: 12}}
                    onChange={(e) => setData({...data, anamnesis: e.target.value ?? ""})}
                />
                <label>Anamnèse</label>
            </div>
            <BrandedElement temeoo>
                <div className="input-field outlined">
                <textarea
                    defaultValue={data?.orthoptic_text}
                    style={{resize: "none", padding: 10, height: 120, fontSize: 12}}
                    onChange={(e) => setData({...data, orthoptic_text: e.target.value ?? ""})}
                />
                    <label>Orthoptie</label>
                </div>
            </BrandedElement>

            <div className="input-field outlined">
                <textarea
                    defaultValue={data?.private_comment}
                    style={{resize: "none", padding: 10, height: 120, fontSize: 12}}
                    onChange={(e) => setData({...data, private_comment: e.target.value ?? ""})}
                />
                <label>Commentaire privé</label>
            </div>
        </div>
        }
    </Observer>
}

export default EditComments;