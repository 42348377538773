import React, {Component} from "react";

const json = require("../../../../../public/cerfa/pdfs.json")
const pdfs = JSON.parse(JSON.stringify(json));
export default class PdfViewer extends Component {
    constructor(props) {
        super(props);
        this.selectPdf = this.selectPdf.bind(this)
        this.state= {
            selectedPdf: ""
        }
    }

    selectPdf() {
        const src = document.getElementById('cerfa-pdf-select').value;
        this.setState({
            selectedPdf: src ? src : ""
        })
    }

    render() {
        return (
            <div className="row" style={{height: "100%"}}>
                <div className="col s4">
                    Document à éditer
                    <select id="cerfa-pdf-select" className="browser-default" defaultValue="" onChange={this.selectPdf}>
                        <option value="" disabled hidden>Choisir un document</option>
                        {pdfs.map((pdf, index) =>
                            <option key={index } value={pdf.src}>{pdf.name}</option>
                        )}
                    </select>
                </div>
                <div className="col s8 center">
                    <iframe src={this.state.selectedPdf} style={{height: "90vh", width: "50vw"}}/>
                </div>
            </div>
        );
    }

}
