import React from 'react';

const SearchBar = ({value, onChange}) => {
    return <div className="input-field outlined valign-wrapper"
                style={{borderRadius: 10, border: "thin solid #707070", height: "fit-content", lineHeight: 4}}>
        <input
            className="messenger-input"
            style={{paddingBlock: 0, border: "none", borderRadius: 25, height: 35, flex: 1}}
            type="text"
            placeholder="Rechercher"
            value={value}
            onChange={e => onChange(e.currentTarget.value)}
        />
        <i className="material-icons grey-text text-darken-2" style={{marginInline: 10, color: "black"}}>search</i>
    </div>
}

export default SearchBar;