import React from "react"
import moment from "moment";
import axios from "axios";
import {toast} from "react-toastify";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import EarningsMetric from "./Metrics/EarningsMetric";
import ActiveUsersMetric from "./Metrics/ActiveUsersMetric";
import EarningsByActMetric from "./Metrics/EarningsByActMetric";
import EarningsByOrthoptistMetric from "./Metrics/EarningsByOrthoptistMetric";
import AppointmentCountMetric from "./Metrics/AppointmentCountMetric";
import EquipedOfficesMetric from "./Metrics/EquipedOfficesMetric";

export default class Overview extends React.Component {

    constructor(props) {
        super(props);
        this.getEarnings = this.getEarnings.bind(this)
        this.dateOptions = {
            TODAY: {
                txt: "Aujourd'hui",
                start_date: moment(),
                end_date: moment()
            },
            WEEK: {
                txt: "Cette semaine",
                start_date: moment().startOf("isoWeek"),
                end_date: moment()
            },
            LAST_MONTH: {
                txt: "Mois dernier",
                start_date: moment().subtract(1, 'month').startOf('month'),
                end_date: moment().subtract(1, 'month').endOf('month')
            },
            MONTH: {
                txt: "Ce mois",
                start_date: moment().startOf('month'),
                end_date: moment()
            },
        }
        this.state = {
            selected_btn: this.dateOptions.TODAY,
            is_dinu: false,
            dinu: {
                earnings: 0,
                nb_active: 0,
                nb_bills: 0,
                offices: {}
            },
            no_dinu: {
                earnings: 0,
                nb_active: 0,
                nb_bills: 0,
                offices: {}
            },
            start_date: moment(),
            end_date: moment(),
            selected_values: [],
        };
    }

    componentDidMount() {
        this.getEarnings(this.dateOptions.TODAY)
    }

    getEarnings(dateOption) {
        axios.get("/admin/console/bills/earnings", {
            params: {
                start_date: dateOption.start_date.format("YYYY-MM-DD"),
                end_date: dateOption.end_date.format("YYYY-MM-DD"),
                orthoptist_ids: this.state.selected_values.map(values => values.id) || undefined
            }
        }).then((res) => {
            this.setState({
                dinu: res.data.dinu,
                no_dinu: res.data.no_dinu,
                start_date: dateOption.start_date,
                end_date: dateOption.end_date,
                selected_btn: dateOption
            })
        }).catch(err => {
            toast.error("Une erreur est survenue lors de la recherche des factures")
        })
    }

    monthlyPreview = (pricing) => {
        if (this.state.selected_btn !== this.dateOptions.MONTH) return null
        const get_nb_working_days = (start, end) => {
            const dates = []
            const date = new Date(start);
            const endDate = new Date(end);
            while (date <= endDate) {
                dates.push(new Date(date));
                date.setDate(date.getDate() + 1);
            }
            return dates.filter(date => date.getDay() !== 0 && date.getDay() !== 6).length
        }

        const nb_working_days = get_nb_working_days(this.state.start_date, this.state.end_date)
        const average = (pricing.earnings / nb_working_days)

        const total_month_days = get_nb_working_days(this.state.start_date, moment(this.state.start_date).endOf('month'))
        return (average * total_month_days).toFixed(2)
    }

    render() {
        const pricing = this.state.is_dinu ? this.state.dinu : this.state.no_dinu
        return (
            <>
                <div style={{
                    marginLeft: 30,
                    display: "flex",
                    alignItems: "center",
                    gap: 20,
                    flexWrap: "wrap",
                    width: "100%"
                }}>
                    {
                        Object.values(this.dateOptions).map((option, index) => {
                            const selected = this.state.selected_btn === option
                            const color = selected ? 'blue darken-2 white-text' : 'transparent black-text'
                            return <a
                                key={index}
                                className={`btn-flat btn-small ${color}`}
                                onClick={() => this.getEarnings(option)}
                                style={{border: '1px solid grey'}}
                            >
                                {option.txt}
                            </a>
                        })
                    }
                    <input style={{margin: 0, height: 30, maxWidth: 150}} type="date" id="start"
                           name="trip-start"
                           min="2021-01-01" max={moment().format("YYYY-MM-DD")}
                           onChange={e => this.setState({start_date: moment(e.target.value), selected_btn: undefined})}
                           value={this.state.start_date.format("YYYY-MM-DD")}/>
                    <p style={{margin: 0}}>au</p>
                    <input style={{margin: 0, height: 30, maxWidth: 150}} type="date" id="end" name="trip-end"
                           min="2021-01-01" max={moment().format("YYYY-MM-DD")}
                           onInput={e => this.setState({end_date: moment(e.target.value), selected_btn: undefined})}
                           value={this.state.end_date.format("YYYY-MM-DD")}/>
                    <Autocomplete
                        disablePortal
                        className="ui-autocomplete"
                        multiple
                        value={this.props.selected_values}
                        options={this.props.orthoptists.sort((a,b) => a.nom > b.nom).map((ortho) => {
                                return {
                                    id: ortho.id,
                                    label: `${Helper.formatName(ortho.prenom, ortho.nom)} #${ortho.id}`
                                }
                            }
                        )}

                        onChange={(_, array) => this.setState({selected_values: array})}
                        sx={{width: "300px"}}
                        renderInput={(params) => <TextField {...params} label="Sélectionner orthoptistes"/>}
                    />
                    <div>
                        <label>
                            <input type="checkbox" className="filled-in" checked={this.state.is_dinu}
                                   onChange={(e) => this.setState({is_dinu: e.target.checked})}/>
                            <span>Inclure Dinu</span>
                        </label>

                    </div>
                    <a className="btn-small blue darken-2 z-depth-0" onClick={() => this.getEarnings({
                        start_date: this.state.start_date,
                        end_date: this.state.end_date
                    })}>Valider</a>
                </div>
                <div style={{display: "flex", justifyContent: "space-around", flexWrap: "wrap", marginTop: 20}}>
                    <EarningsMetric earnings={pricing.earnings} monthly_preview={this.monthlyPreview(pricing)}/>
                    <ActiveUsersMetric pricing={pricing}/>
                    <EarningsByActMetric pricing={pricing}/>
                    <EarningsByOrthoptistMetric pricing={pricing}/>
                </div>
                <div style={{display: "flex", justifyContent: "space-around", flexWrap: "wrap", marginTop: 20}}>
                    <AppointmentCountMetric appointment_count={pricing.nb_bills}/>
                    <EquipedOfficesMetric offices={pricing.offices}/>
                    <div style={{width: 250}}/>
                </div>
            </>
        )
    }
}