import {useEffect, useState} from "react";
import axios from "axios";
import {RequestState} from "../../../../../Shared/StateHelper";

function useApplicationOverview(appName) {
    const [overview, setOverview] = useState({})
    const [requestState, setRequestState] = useState(RequestState.IDLE)

    useEffect(() => {
        get_overview()
    }, []);

    const get_overview = () => {
        setRequestState(RequestState.LOADING)
        axios.get(`/admin/console/logs/app/${appName}/overview`)
            .then((res) => {
                setOverview(res.data)
                setRequestState(RequestState.SUCCESS)
            })
            .catch((err) => {
                setRequestState(RequestState.ERROR)
            })
    }

    return {overview, requestState}
}

export default useApplicationOverview;