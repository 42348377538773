import React, {useEffect, useState} from "react";

export default function SimplePincodeMobile(props){
    const [pin_numbers, setPinNumbers] = useState(Array(props.pinCount).fill(""))
    useEffect(() => {
        props.handleValueChanges(0, pin_numbers.join("").replace(" ", ""))
    }, [pin_numbers])
    useEffect(() => {
        // copy if props.currentPin is not empty
        if (props.initialPin !== undefined && props.initialPin.length > 0 && props.initialPin.length === props.pinCount) {
            let arr = props.initialPin.split("")
            setPinNumbers(arr)
        }
    }, [])
    return (
        <div className="center pinlogin hide-on-med-and-up">
            {[...Array(props.pinCount).keys()].map((index) => {
                return (
                    <input id={"m_pin-" + index} className="pinlogin-field" key={index} width={50}
                           value={pin_numbers[index] || ""}
                           autoCorrect="off" autoCapitalize="off"
                           onChange={(e) => {
                               e.preventDefault();

                               let value = e.target.value
                               if (value.length > 1) {
                                   value = value.charAt(value.length - 1)
                               }
                               if (value.length === 0) {
                                   let temp_array = [...pin_numbers]
                                   temp_array[index] = ""
                                   setPinNumbers(temp_array)
                                   return;

                               }
                               if (value.match(/^[0-9a-zA-Z]$/)) {
                                   let temp_array = [...pin_numbers]
                                   temp_array[index] = value
                                   setPinNumbers(temp_array)

                               }
                               if (index < props.pinCount - 1) document.getElementById("m_pin-" + (index + 1)).focus()


                           }}/>
                )
            })}
        </div>
    )
}