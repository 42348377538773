import React from "react"
import AnciennesMesures from "./mesure_components/AnciennesMesures"
import Formulaire from "./mesure_components/Formulaire"
import ActiveTabs from "./mesure_components/ActiveTabs"
import {observer} from "mobx-react"
import MesureStore from './MesureStore'
import {TypesMesures} from "../../Shared/Enums/Enums";
import Helper from "../../Shared/Helper";
import {missingMesures} from "../AppointmentValidation";

@observer
class Mesure extends React.Component {
    constructor(props) {
        super(props);
        this.choixMesure = this.choixMesure.bind(this)
        this.choixMesureByTabs = this.choixMesureByTabs.bind(this)
        this.importMesure = this.importMesure.bind(this)
        this.deleteMesure = this.deleteMesure.bind(this)
        this.state = {
            typedemesure: "",
            mesureName: ""
        };
    }

    componentDidMount() {
        // MesureStore.patient_id = this.props.patient.id
        // MesureStore.appointement_id = this.props.appointement.id
        // MesureStore.loadKindsMesures()

    }

    choixMesure(e) {
        if (this.state.typedemesure != "") { //enregistrement
            MesureStore.saveMesure()
        }

        let m = "";
        MesureStore.typesdemesures.map((mesure, index) => {
            if (mesure.id == e.target.value) {
                m = mesure.titre
            }
        })

        this.setState({
            typedemesure: e.target.value,
            mesureName: m
        })
        MesureStore.updateForm(e.target.value)
        MesureStore.typedemesure = parseInt(e.target.value)
        MesureStore.loadKindsMesures()

    }

    choixMesureByTabs(mesure) {
        if (this.state.typedemesure != "") { //enregistrement
            MesureStore.saveMesure()
        }
        this.setState({
            typedemesure: mesure.id,
            mesureName: mesure.titre
        })
        MesureStore.updateForm(mesure.id)
        MesureStore.typedemesure = mesure.id
        MesureStore.loadKindsMesures()

    }

    deleteMesure(mesureType) {
        if (mesureType) {
            MesureStore.deleteMesure(mesureType.mesure_id)

            this.setState({
                typedemesure: mesureType.id != this.state.typedemesure ? this.state.typedemesure : ""
            })
        }
    }

    importMesure(id) {
        this.setState({typedemesure: id})
        MesureStore.loadKindsMesures()

    }

    render() {
        const mesures = MesureStore.typesdemesures.slice().sort((a, b) => {
            return Object.values(TypesMesures).indexOf(a.titre) > Object.values(TypesMesures).indexOf(b.titre) ? 1 : -1
        })
        return (
            <div style={{margin: 20}}>
                <div className="row">
                    <div className="col s12 m3">
                        <div className="bordered"></div>
                        <ActiveTabs clickFunc={this.choixMesureByTabs} delete={this.deleteMesure}/>
                        <select
                            onChange={this.choixMesure}
                            value={this.state.typedemesure}
                            className="browser-default"
                            data-cy="MesureSelection">
                            <option value="" data-cy="MesureOption">Choisir un type de mesure</option>
                            {mesures.map((mesure, index) => {
                                let prefix = ""
                                if(missingMesures().flat().includes(mesure.titre)) {
                                    const isFirstElement = missingMesures().reduce((acc, arr) => acc.concat(arr[0]), []).includes(mesure?.titre)
                                    prefix = isFirstElement ? "OBLIGATOIRE : " : "OU : "
                                }
                                if([TypesMesures.PACHYMETRY, TypesMesures.NO_CORRECTION, TypesMesures.AUTOREF, TypesMesures.FINALE_REF].includes(mesure?.titre)) {
                                    return [
                                        <option
                                    data-cy="MesureOption"
                                    key={index}
                                    value={mesure.id}>
                                    {prefix}{mesure.titre}</option>,
                                        <option key={index} data-cy="MesureOption" value={undefined} disabled>————————————————————————</option>,
                                    ]
                                }
                                return <option data-cy="MesureOption" key={index} value={mesure.id}>{prefix}{mesure.titre}
                                </option>
                            })}
                        </select>
                    </div>
                    <div className="col s12 m9" style={{padding: "40px"}}>
                        {this.state.typedemesure === "" ? (
                            <p className="center" data-cy="NoMesureTypeSelected">Aucun type de mesure n'est sélectionné</p>
                        ) : (
                            <React.Fragment>
                                <p className="center">{MesureStore.AvMesuresAnalisis}</p>
                                <Formulaire mesureName={Helper.capitalizeFirstLetter(this.state.mesureName)}/>
                            </React.Fragment>
                        )}
                    </div>
                    {/*<div className="col s12 m2">*/}
                    {/*    <div style={{display: "flex", flexDirection: "column"}}>*/}
                    {/*        <AnciennesMesures importMesure={this.importMesure}/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        );
    }
}

export default Mesure
