import {io} from "socket.io-client";
import "../../stylesheets/materialize.scss";

export const getEnvSafeDom = (name) => {
    const env = document.getElementById("ENV")
    if (env) return JSON.parse(env.getAttribute("data-env"))[name]
    else return null
}

class MasterSocket {
    #socket = null;
    bridge_uid = null;
    constructor() {
        let env = getEnvSafeDom("WS_URL")
        this.#socket = io(env);
        var that = this;
        window.addEventListener("beforeunload", function (e) {
            that.kill();
        }, false);
    }

    setBridgeUid(uid) {
        this.bridge_uid = uid;
    }
    emit(event, data) {
        if (this.#socket === undefined || this.#socket === null) {
            setTimeout(() => {
                this.#socket.emit(event, data)
            }, 500);
        } else this.#socket.emit(event, data)
    }

    on(event, callback) {
        if (this.#socket === undefined || this.#socket === null) {
            setTimeout(() => {
                this.#socket.on(event, callback) // could be dangerous
            }, 500);
        } else this.#socket.on(event, callback)
    }

    onAny(callback) {
        if (this.#socket === undefined || this.#socket === null) {
            setTimeout(() => {
                this.#socket.onAny(callback) // could be dangerous
            }, 500);
        } else this.#socket.onAny(callback)
    }

    off(event) {
        if (this.#socket) this.#socket.off(event)
    }

    offAny() {
        if (this.#socket) this.#socket.offAny()
    }

    kill() {
        if (this.#socket === undefined || this.#socket === null) {
            return
        }
        this.#socket.disconnect()
        this.#socket = null;

    }

    disconnect() {
        console.log("isn't allowed out of this component");
    }
}

let socket = new MasterSocket();
App.socket = socket;
export default socket