import React, {useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import {toast} from "react-toastify";
import ReportsStore from "../ReportsStore";
import JustificationSelect from "../JustificationSelect";

const ImageBlockEdit = (props) => {
    const [data, setData] = useState(props.data)
    useEffect(() => {
        setData(props.data)
    }, [props.data])

    const onDrop = (file) => {
        file = file[0]
        if (file.name.includes(".jpg") || file.name.includes(".jpeg") || file.name.includes(".JPG") || file.name.includes(".JPEG") || file.name.includes(".png") || file.name.includes(".PNG")) {
            upload(file)
            return
        }
        toast.error("Le fichier doit être une image")
    }
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    // Send formdata to server
    const upload = (image_file) => {
        ReportsStore.uploadImage(image_file).then(data => {
            setData(prevState => {
                return {...prevState, url: data.url}
            })
            props.onChange(data)
        })
    }

    const changeWidth = (width) => {
        const data = {
            ...props.data,
            width: width
        }
        setData(data)
        props.onChange(data)
    }

    const changeJustification = (justify) => {
        const data = {
            ...props.data,
            justify: justify
        }
        setData(data)
        props.onChange(data)
    }

    const renderThumbnail = () => {
        if (data.url) {
            return <img src={data.url} alt={data.name} style={{width: 100}}/>
        }
        return null
    }

    const renderOptions = () => {
        return <div className="valign-wrapper" style={{paddingInline: 20, width: "100%", gap: 20}}>
            <JustificationSelect
                justify={data.justify}
                onChange={(v) => changeJustification(v)}
            />
            <h6 style={{margin: 0}}>Largeur</h6>
            <input type="range" id={`divider_range_${props.id}}`} min="0" max="100"
                   onChange={(event) => changeWidth(event.target.value)}
                   defaultValue={data.width ? data.width : 100}
                   style={{
                       borderRadius: "15px",
                       backgroundColor: "#c2c0c2",
                       borderColor: "transparent"
                   }}/>
        </div>
    }

    const renderImageExisting = () => {
        if (!data.url) return null
        return <div>
            <div className="valign-wrapper">
                <div className="valign-wrapper" style={{justifyContent: "center", gap: 10}}>
                    <i className="material-icons">add_a_photo</i>
                    <h6>Pour changer d'image, cliquer ici ou glisser-déposer</h6>
                </div>
                <div style={{marginLeft: "auto"}}>
                    {renderThumbnail()}
                </div>
            </div>
        </div>
    }

    const renderImageEmpty = () => {
        if (data.url) return null
        return <div className="valign-wrapper" style={{justifyContent: "center", gap: 10}}>
            <i className="material-icons">add_a_photo</i>
            <h6>Pour ajouter une image, cliquer ici ou glisser-déposer</h6>
        </div>
    }

    if (!data) return null
    return <div style={{width: "100%"}}>
        {renderOptions()}
        <div className={isDragActive ? "blue lighten-5" : ""} {...getRootProps()} style={{width: "100%"}}>
            <input {...getInputProps()} />
            {renderImageExisting()}
            {renderImageEmpty()}
        </div>
    </div>
}

export default ImageBlockEdit