import React, {useEffect, useState} from "react";
import Helper from "../../Shared/Helper";
import axios from "axios";
import ConnecteurV3 from "../../BridgeV2/ConnecteurV3";
import Loader from "../../Shared/Loader";
import {TeletransmissionStatus} from "../../Shared/Enums/Enums";
import NoemiReturnButton from "../../CarteVitale/NoemiReturnButton";
import {toast} from "react-toastify";

const MAX_INTENTS = 50

function ListTeletransmission(props) {
    const {uid, orthoptist} = props;
    const [page, setPage] = useState(1);
    const [appointmentList, setAppointmentList] = useState([]);
    const [appointmentCount, setAppointmentCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState(TeletransmissionStatus.PENDING);
    const [toSync, setToSync] = useState([]);

    useEffect(() => {
        get_appointments_to_sync()
    }, [])

    useEffect(() => {
        setPage(1)
        document.getElementById('status-select').value = status
    }, [status])

    useEffect(() => {
        get_appointments()
    }, [page, status])


    const get_appointments = () => {
        setLoading(true)
        axios.get(`/orthoptistes/teletransmissions/all?status=${status}&page=${page}&nb_results=${MAX_INTENTS}`).then(res => {
            setAppointmentList(res.data.appointments)
            setAppointmentCount(res.data.count)
            setLoading(false)
            get_appointments_to_sync()
        })
    }
    const get_appointments_to_sync = () => {
        axios.get(`/orthoptistes/teletransmissions/sync`).then(res => {
            setToSync(res.data.appointments)
        })
    }
    const onNOEMIreturn = (resp) => {
        const isPayed = (data) => {
            if (data.tbstReglement.length === 0) return false
            if (!data.tbstReglement[0]) return false
            return data.tbstReglement[0].sMode === "VIR"
        }
        const noemi_returns = resp.map(data => {
            return {
                appointment_id: data.nIdfactureLogicielPartenaire,
                payed: isPayed(data)
            }
        })
        axios.patch("/orthoptistes/teletransmissions/sync", {noemi_returns: noemi_returns}).then(() => {
            toast.success("Les retours NOEMI ont été enregistrés avec succès")
            setStatus(TeletransmissionStatus.PENDING)
        }).catch(() => toast.error("Une erreur est survenue lors de l'enregistrement des retours NOEMI"))
    }


    const renderOptions = () => {
        return (
            <div style={{display: "flex", alignItems: "center", marginLeft: 22, flexWrap: "wrap"}}>
                <NoemiReturnButton bridge_uid={uid} rpps={orthoptist.numero_facturation}
                                   adeli={orthoptist.numero_facturation}
                                   invoices={toSync} disabled={!toSync?.length}
                                   onComplete={(resp) => onNOEMIreturn(resp)}
                />
                <div style={{display: "flex", gap: 10, whiteSpace: "nowrap", alignItems: "center"}}>
                    <h5 style={{margin: 0}}>État :</h5>
                    <select className="browser-default"
                            value={status} id={"status-select"}
                            onChange={(e) => setStatus(parseInt(e.target.value))}>
                        <option value={TeletransmissionStatus.PENDING}>En attente</option>
                        <option value={TeletransmissionStatus.FAILED}>Paiement échoué</option>
                        <option value={TeletransmissionStatus.SUCCESS}>Paiement validé</option>

                    </select>
                </div>
                <div style={{marginLeft: "auto", marginRight: 20}}>
                    {renderPagination()}
                </div>
            </div>
        )
    }


    const renderPagination = () => {
        return <div style={{display: "flex", flexDirection: "column"}}>
            <div style={{display: "flex", justifyContent: "center", alignContent: "center"}}>
                <h6 style={{
                    alignSelf: "center",
                    margin: 0
                }}>{appointmentCount} résultat{appointmentCount === 1 ? "" : "s"}</h6>
                <div className={appointmentCount <= MAX_INTENTS ? "hide" : ""}  style={{display: "flex", justifyContent: "center", alignContent: "center"}}>
                    <a className={`btn-small btn-flat transparent z-depth-0 ${page === 1 ? "disabled" : ""}`}
                       onClick={(e) => setPage(page - 1)}><i className="material-icons">chevron_left</i></a>
                    <h6 style={{
                        alignSelf: "center",
                        margin: 0
                    }}>{page} / {Math.ceil(appointmentCount / MAX_INTENTS)}</h6>
                    <a className={`btn-small btn-flat transparent z-depth-0 ${page === Math.ceil(appointmentCount / MAX_INTENTS) ? "disabled" : ""}`}
                       onClick={(e) => setPage(page + 1)}><i className="material-icons">chevron_right</i></a>
                </div>
            </div>
        </div>
    }

    const renderAppointment = (intent) => {
        return (
            <div className="row" style={{margin: "0 20px"}}>
                <span className="col s4">{Helper.formatName(intent.patient_firstname, intent.patient_lastname)}</span>
                <span className="col s4">{Helper.formatDate(intent.date)}</span>
                <span className="col s4">{intent.act}</span>
            </div>
        );
    }

    const renderAppointments = () => {
        if (loading) return <div style={{margin: 20}}><Loader size="small" text=""/></div>
        if (appointmentList.length === 0) return <h2 className="center">Aucun acte n'est disponible à la
            télétransmission actuellement</h2>

        return <ul style={{borderRadius: "20px"}} className="collection">
            {appointmentList.map((appointment, index) =>
                <li className="collection-item" key={index}>
                    {renderAppointment(appointment)}
                </li>
            )}
        </ul>
    }

    return <div>
        <h1 className="center">Liste des télétransmissions</h1>
        <div className="container">
            {renderOptions()}
            {renderAppointments()}
        </div>
        <ConnecteurV3 id={orthoptist.id} bridge_uid={uid}/>
    </div>
}

export default ListTeletransmission