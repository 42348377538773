import React, {useContext, useEffect, useRef, useState} from 'react';
import ConversationHeader from "./ConversationHeader";
import Messages from "./Messages";
import {useParams} from "react-router-dom";
import axios from "axios";
import MessageInput from "./MessageInput";
import MessengerStore from "../MessengerStore";
import MessageNotifier from "../MessageNotifier";
import {observer, Observer} from "mobx-react";

const Conversation = observer(({user_id}) => {
    const {id} = useParams()
    axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector("meta[name='csrf-token']").content
    const scrollRef = useRef(null)
    const messages = MessengerStore.conversation[id] || []

    useEffect(() => {
        loadMessages()
    }, [id]);

    useEffect(() => {
        const interval = setInterval(() => {
            messageRead(messages)
        }, 2000)
        scrollRef.current.scrollIntoView()
        return () => clearInterval(interval)
    }, [messages]);

    const loadMessages = () => {
        axios.get(`messenger/conversation/${id}/messages`).then(resp => {
            MessengerStore.addMessagesToConversation(resp.data.messages, id)
        })
    }

    const sendMessage = (message) => {
        axios.put(`messenger/conversation/${id}/send`, {message: message}).then(resp => {
            MessengerStore.addMessagesToConversation([resp.data.message], id)
        })
    }

    const messageRead = (messages) => {
        const nb_unread = MessengerStore.getUnreadByConversation(id)
        if (nb_unread > 0) {
            const message_id = messages[messages.length - 1].id
            axios.put(`messenger/conversation/${id}/read`, {message_id}).then(resp => {
                MessengerStore.fetchUnreadByConversation()
            })
        }
    }

    const onMessageReceived = (data) => {
        if (data.message.sender_id === user_id) return
        if (data.conversation_id !== parseInt(id)) return
        MessengerStore.addMessagesToConversation([data.message], id)
    }

    return <div id="full-height"
                    style={{display: "flex", flexDirection: "column", position: "relative", overflowY: "clip"}}>
            <ConversationHeader/>
            <div className="hoverable-scroll-box" style={{padding: 20, flex: 1, backgroundColor: "#e8e8e8", paddingBottom: 80}}>
                <Messages messages={messages}/>
                <div ref={scrollRef}></div>
            </div>
            <div style={{position: "absolute", bottom: 0, right: 0, left: 0}}>
                <MessageInput onSend={sendMessage}/>
            </div>
            <MessageNotifier conversation_id={id} onReceived={(data) => onMessageReceived(data)}/>
        </div>
})

export default Conversation;