import React from 'react'
import ModalButton from "../../../Shared/ModalButton";
import {QuestionType} from "../../../Shared/Enums/Enums";


function ShowMedicalReportButton({report, json}) {

    function checkReport() {
        return Object.entries(report).reduce((acc, [key, value]) => {
            if (key.includes("##")) return acc;

            if (value === true || (typeof value === 'string' && value.trim() !== '')) {
                return false;
            }

            return acc;
        }, true);
    }

    const renderValue = (value, type) => {
        if (type === "YES_NO") return value ? "Oui" : "Non"
        return value
    }

    const renderReport = () => {
        return <div className="row" style={{margin: 20}}>
            {json.map((category, index) => {
                return <div className="col s12 xl6" key={index}>
                    <h4 className="left-align" style={{margin: "20px 0"}}>{category.label}</h4>
                    {category.questions.map((question) => {
                            const question_id = question.label
                            if (!report[question_id]) return
                            return <div key={`${index}_${question_id}`}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <div>{question.label} :&nbsp;</div>
                                    <div>{renderValue(report[question_id], question.type)}</div>
                                </div>
                                {question.type === QuestionType.YES_NO && report[question_id] && question.subquestions.map((subquestion) => {
                                    const subquestion_id = `${question_id}##${subquestion.label}`
                                    if (!report[subquestion_id]) return
                                    return <div key={`${index}_${subquestion_id}`}>
                                        <div style={{display: "flex", alignItems: "center", paddingLeft: "20px"}}>
                                            <div>{subquestion.label} :&nbsp;</div>
                                            <div>{renderValue(report[subquestion_id], subquestion.type)}</div>
                                        </div>
                                    </div>
                                })}
                            </div>
                        }
                    )}
                </div>
            })}
        </div>
    }

    return <div className={checkReport() ? "hide" : ""}>
        <ModalButton text="Afficher questionnaire" id="report_control_modal">
            <h2>Questionnaire</h2>
            {renderReport()}
        </ModalButton>
    </div>
}

export default ShowMedicalReportButton