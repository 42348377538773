import React, {Component} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import {hasFailed} from "../Shared/StateHelper";
import DisplayFromCV from "./DisplayFromCV";
import CVButton from "../agenda/CVButton";
import r from "../Shared/Validation/Regex";
import Tooltip from "../Shared/Tootip";
import ConnecteurV3 from "../BridgeV2/ConnecteurV3";
import DuplicatedPatientModal from "./DuplicatedPatientModal";

const NUMBER_OF_TRIES = 0;
export default class EditPatient extends Component {
    constructor(props) {
        super(props);
        this.state = {
            first_name: '',
            last_name: '',
            phone: '',
            email: '',
            birth_date: undefined,
            address: '',
            amo: '',
            amo_expiration: '',
            amc: '',
            amc_expiration: '',
            ssn: '',
            regime_code: '',
            fund_code: '',
            centre_code: '',
            birth_rank: undefined,
            duplicate: undefined,
        }
    }

    componentDidMount() {
        if (this.props.patient) this.setState({
            first_name: this.props.patient.prenom,
            last_name: this.props.patient.nom,
            phone: this.props.patient.telephone,
            email: this.props.patient.email,
            birth_date: this.props.patient.birthday,
            address: this.props.patient.address,
            ssn: this.props.patient.num_carte_vitale,
            amo: this.props.patient.amo,
            amo_expiration: this.props.patient.amo_expiration,
            amc: this.props.patient.amc,
            amc_expiration: this.props.patient.amc_expiration,
            regime_code: this.props.patient.regime_code,
            fund_code: this.props.patient.fund_code,
            centre_code: this.props.patient.centre_code,
            birth_rank: this.props.patient.birth_rank,
        })
    }

    createPatient = (startAppt = false) => {
        if (this.checkFields()) {
            axios.post('/api/v3/patients/create', {
                prenom: this.state.first_name,
                nom: this.state.last_name,
                telephone: this.state.phone,
                email: this.state.email,
                birthday: this.state.birth_date,
                birth_rank: this.state.birth_rank || null,
                address: this.state.address,
                num_carte_vitale: this.state.ssn?.replace(/\s/g, '') ?? undefined,
                amo: this.state.amo,
                amo_expiration: this.state.amo_expiration,
                amc: this.state.amc,
                amc_expiration: this.state.amc_expiration,
                regime_code: this.state.regime_code,
                fund_code: this.state.fund_code,
                centre_code: this.state.centre_code,
            }).then((response) => {
                if (startAppt === true) window.location.href = "/NewAppointement/" + response.data.id;
                else window.location.href = `/patients/${response.data.id}`
            }).catch((err) => {
                toast.error(err.response.data.errorPrompt)
            })
        }
    }
    editPatient = () => {
        if (this.checkFields()) {
            axios.post('/api/v3/patients/update', {
                id: this.props.patient.id,
                prenom: this.state.first_name,
                nom: this.state.last_name,
                telephone: this.state.phone,
                email: this.state.email,
                birthday: this.state.birth_date,
                birth_rank: this.state.birth_rank || null,
                address: this.state.address,
                num_carte_vitale: this.state.ssn?.replace(/\s/g, '') ?? undefined,
                amo: this.state.amo,
                amo_expiration: this.state.amo_expiration,
                amc: this.state.amc,
                amc_expiration: this.state.amc_expiration,
                regime_code: this.state.regime_code,
                fund_code: this.state.fund_code,
                centre_code: this.state.centre_code,
            }).then((response) => {
                window.location.href = `/patients/${this.props.patient.id}`
            }).catch((err) => {
                if (err.response.status === 412) {
                    this.setState({duplicate: err.response.data.duplicate})
                    return
                }
                toast.error(err.response.data.errorPrompt)
            })
        }
    }

    mergeWithDuplicate = () => {
        axios.post('/api/v3/patients/merge', {
            id: this.props.patient.id,
            duplicate_id: this.state.duplicate.id,
        }).then((response) => {
            window.location.href = `/patients/${this.props.patient.id}`
        }).catch((err) => {
            toast.error(err.response.data.errorPrompt)
        })
    }

    checkFields = () => {
        if (this.state.first_name.length === 0 || this.state.last_name.length === 0) {
            toast.error('Veuillez remplir tous les champs');
            return false
        }
        if (this.state.phone.length !== 0) {
            const regex = r.validatePhoneNumber(this.state.phone)
            if (!regex) {
                toast.error('Numéro de téléphone invalide');
                return false
            }
        }
        if (this.state.birth_date === undefined) {
            toast.error('Date de naissance non remplie');
            return false
        }

        if (this.state.birth_date.length > 0) {
            const date = new Date(this.state.birth_date)
            if (isNaN(date.getTime())) {
                toast.error("La date de naissance n'est pas valide")
                return false
            }
        }
        if (this.state.email.length > 0) {
            const email = this.state.email
            if (!r.validateEmail(email)) {
                toast.error("L'email n'est pas valide")
                return false
            }
        }
        return true
    }

    renderFoundPatientsModalContent = (data, meta) => {
        return <div>
            <h1 className="center">Carte vitale</h1>
            <DisplayFromCV
                data={data}
                meta={meta}
                onClick={(patient) => this.setState({
                    first_name: patient.sPrenomPatient,
                    last_name: patient.sNomUsuelPatient,
                    birth_date: patient.dDateDeNaissance,
                    birth_rank: patient.nRangNaissance,
                    ssn: patient.sNumeroSecuriteSocialePatient,
                    amo: patient.sCouvAMO,
                    amo_expiration: patient.sDateFinAMO,
                    amc: patient.sCouvAMO,
                    amc_expiration: patient.sDateFinAMC,
                    regime_code: meta.sCodeRegime,
                    fund_code: meta.sCodeCaisse,
                    centre_code: meta.sCodeCentre,
                }, () => M.Modal.getInstance(document.getElementById('carte_vitale_modal')).close())}/>
        </div>
    }

    formatCode = () => {
        const regime_code = this.state.regime_code
        const fund_code = this.state.fund_code
        const centre_code = this.state.centre_code
        return `${regime_code}${fund_code ? " " : ""}${fund_code}${centre_code ? " " : ""}${centre_code}`
    }

    setCode = (inputStr) => {
        const value = inputStr.replace(/\D+/g, '');
        const chunks = [
            value.slice(0, 2),
            value.slice(2, 5),
            value.slice(5, 9),
        ]

        const [regime_code, fund_code, centre_code] = chunks
        this.setState({
            regime_code: regime_code,
            fund_code: fund_code,
            centre_code: centre_code,
        })
    }

    render() {
        return (
            <div>
                <div className="valign-wrapper" style={{justifyContent: "center"}}>
                    <h1 data-cy="PatientModificationPageTitle"
                        style={{paddingRight: "1vw"}}>{this.props.patient ? "Editer" : "Nouvelle"} fiche patient</h1>
                    {this.props.bridge_uid && this.props.user ? (
                        <>
                            <CVButton modalRender={(data, meta) => this.renderFoundPatientsModalContent(data, meta)}
                                      bridge_uid={this.props.bridge_uid}
                            />
                            <ConnecteurV3
                                id={this.props.user.id}
                                bridge_uid={this.props.bridge_uid}
                            />
                        </>
                    ) : (<div/>)}
                </div>
                <div>
                    <h6 className={`center red-text ${hasFailed(this.state.cv_state) ? "" : "hide"}`}
                        style={{fontStyle: "italic", margin: "0 0 2vh 0"}}>
                        Impossible de lire la Carte Vitale de votre patient. Vérifier que votre
                        carte CPS est insérée et que vous avez rentré votre code porteur sur Medoc.</h6>
                </div>
                <div className="row container">
                    <div className="col s12 xl6" style={{margin: "0 10px"}}>
                        <label htmlFor="first_name">Prénom</label>
                        <input data-cy="FirstName" type="text" id="first_name" defaultValue={this.state.first_name}
                               required
                               onChange={(e) => this.setState({first_name: e.target.value})}/>
                    </div>
                    <div className="col s12 xl6" style={{margin: "0 10px"}}>
                        <label htmlFor="last_name">Nom</label>
                        <input data-cy="LastName" type="text" id="last_name" defaultValue={this.state.last_name}
                               required
                               onChange={(e) => this.setState({last_name: e.target.value})}/>
                    </div>
                    <div className="col s12 xl6" style={{margin: "0 10px"}}>
                        <label htmlFor="phone">Téléphone</label>
                        <input data-cy="PhoneNumber" type="text" id="phone" defaultValue={this.state.phone}
                               onChange={(e) => this.setState({phone: e.target.value})}/>
                    </div>
                    <div className="col s12 xl6" style={{margin: "0 10px"}}>
                        <label htmlFor="email">Email</label>
                        <input data-cy="Email" type="text" id="email" defaultValue={this.state.email}
                               onChange={(e) => this.setState({email: e.target.value})}/>
                    </div>
                    <div className="col s12 xl6" style={{margin: "0 10px"}}>
                        <label htmlFor="email">Adresse</label>
                        <input data-cy="Address" type="text" id="address" defaultValue={this.state.address}
                               onChange={(e) => this.setState({address: e.target.value})}/>
                    </div>
                    <div className="col s12 xl6" style={{margin: "0 10px"}}>
                        <label htmlFor="birth_date">Date de naissance</label>
                        <input data-cy="Birthdate" type="date" id="birth_date"
                               defaultValue={this.state.birth_date || ""} required
                               onChange={(e) => this.setState({birth_date: e.target.value})}/>
                    </div>
                    <div className="col s12 xl6" style={{margin: "0 10px"}}>
                        <label htmlFor="ssn">Num. Sécu</label>
                        <input data-cy="SSN" type="text" id="ssn" defaultValue={this.state.ssn} required
                               onChange={(e) => this.setState({ssn: e.target.value})}/>
                    </div>
                    <div className="col s12 xl6" style={{margin: "0 10px"}}>
                        <label htmlFor="birth_rank">Rang de naissance</label>
                        <input data-cy="BirthRank" type="number" id="birth_rank" defaultValue={this.state.birth_rank}
                               required
                               onChange={(e) => this.setState({birth_rank: e.target.value})}/>
                    </div>
                    <div className="col s12 xl6" style={{margin: "0 10px"}}>
                        <label htmlFor="code" className="valign-wrapper">
                            Code caisse maladie&nbsp;
                            <Tooltip
                                text="Code interne sécu du patient : Code Régime, Code Caisse, et Code Centre, utile pour la télétransmission en mode dégradé"
                                id="code-help-tt">
                                <i className="material-icons material-icons-outlined tiny" data-cy="CCNTooltip">help</i>
                            </Tooltip>
                        </label>
                        <input data-cy="CCM" type="text" id="ssn" value={this.formatCode()}
                               onChange={(e) => this.setCode(e.target.value)}/>
                    </div>
                    <div className="col s12 center" style={{padding: "2vh 0", margin: "0 10px"}}>
                        {this.props.patient && this.props.bridge_uid !== undefined ? (
                            <a className="btn blue darken-2 z-depth-0"
                               onClick={() => this.editPatient()}
                               data-cy="EditPatientButton">
                                Sauvegarder<i className="material-icons left">person_add</i>
                            </a>
                        ) : (<div>
                                <a className={`btn blue darken-2 z-depth-0 ${this.props.bridge_uid === undefined ? "hide" : ""}`}
                                   onClick={() => this.createPatient(false)}
                                   data-cy="CreatePatientButton">
                                    Créer<i className="material-icons left">person_add</i>
                                </a>
                                &nbsp;
                                <a className="btn blue darken-2 z-depth-0"
                                   onClick={() => this.createPatient(true)}
                                   data-cy="CreatePatientAndStart">
                                    Commencer consultation<i className="material-icons left">add</i>
                                </a>
                            </div>
                        )}
                    </div>
                </div>
                <DuplicatedPatientModal patient={this.state.duplicate} onValidation={this.mergeWithDuplicate}/>
            </div>
        )
    }
}

EditPatient.defaultProps = {
    patient: null
}
