import React from "react";

function ClinicalData({appointement}) {
    return (
        <div style={{marginTop: "30%"}}>
            <div>
                <table>
                    <tbody>
                        <tr>
                            <td>Diabète</td>
                            <td>{appointement.diabete === false ? (<span>non</span>) : (<span>oui</span>)}</td>
                        </tr>
                        <tr>
                            <td>Glaucome familiaux</td>
                            <td>{appointement.glaucome_familiaux === false ? (<span>non</span>) : (<span>oui</span>)}</td>
                        </tr>
                        <tr>
                            <td>Cortisone</td>
                            <td>{appointement.cortisone === false ? (<span>non</span>) : (<span>oui</span>)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ClinicalData;