// Orthoptist validator
// This class is made to help orthoptists to validate their appointments
// It is purely temporary and will be deleted when the prescription bug phase is over

import React, {useEffect, useState} from 'react';
import {loading, RequestState} from "../../Shared/StateHelper";
import Loader from "../../Shared/Loader";
import Error from "../../Shared/Error";
import HelpButton from "../../Shared/HelpButton";
import axios from "axios";
import Validator from "../../Shared/Validator";
import {toast} from "react-toastify";
import Tooltip from "../../Shared/Tootip";

const MAX_RESULTS = 25

function AppointmentValidator({}) {
    const [requestStatus, setRequestStatus] = React.useState(RequestState.LOADING);
    const [appointments, setAppointments] = React.useState([]);
    const [appointmentCount, setAppointmentCount] = React.useState(0);
    const [checkedIds, setCheckedIds] = useState([]);
    const [page, setPage] = useState(1);
    const [filter, setFilter] = useState({});

    useEffect(() => {
        loadAppointments()
    }, [page, filter])

    useEffect(() => {
        if (document.getElementById('indeterminate-checkbox')) {
            document.getElementById('indeterminate-checkbox').indeterminate = checkedIds.length > 0 && checkedIds.length < appointments.filter(appt => appt.data.status_controle !== 1).length
        }
    }, [checkedIds])

    const check = (id) => {
        if (checkedIds.includes(id)) {
            setCheckedIds(checkedIds.filter(checkedId => checkedId !== id))
            return
        }
        setCheckedIds([...checkedIds, id])
    }

    const loadAppointments = () => {
        setRequestStatus(RequestState.LOADING);
        setCheckedIds([])
        axios.get('/orthoptistes/validator/list', {
            params: {
                page: page,
                nb_results: MAX_RESULTS,
                filter: filter
            }
        }).then(resp => {
            setAppointments(resp.data.appointments);
            setAppointmentCount(resp.data.count)
            setRequestStatus(RequestState.SUCCESS);
        }).catch(error => {
            setRequestStatus(RequestState.ERROR);
        });
    }

    const validate = (ids) => {
        if (ids.length === 0) return

        axios.post(`/orthoptistes/validator/validate`, {ids: checkedIds})
            .then(() => {
                if (ids.length > 1) {
                    toast.success("Les examens ont été validés avec succès")
                } else {
                    toast.success("L'examen a été validé avec succès")
                }
                loadAppointments()
                // intentList.filter(intent => ids.includes(intent.id)).forEach(intent => {
                //     validateIntent(intent.id)
                // })
            }).catch(() => {
            toast.error("Une erreur est survenue lors de la validation des examens")
        })
    }

    const renderPagination = () => {
        return <div style={{display: "flex", flexDirection: "column"}}>
            <div style={{display: "flex", justifyContent: "center", alignContent: "center"}}>
                <h6 style={{
                    alignSelf: "center",
                    margin: 0
                }}>{appointmentCount} résultat{appointments.length === 1 ? "" : "s"}</h6>
                <div style={{display: "flex", justifyContent: "center", alignContent: "center"}}>
                    <a id="hoverable" className={`btn-flat large-btn ${page === 1 ? "disabled" : ""}`}
                       onClick={(e) => setPage(page - 1)}><i className="material-icons">chevron_left</i></a>
                    <h6 style={{
                        alignSelf: "center",
                        margin: 0
                    }}>{page} / {Math.ceil(appointmentCount / MAX_RESULTS)}</h6>
                    <a id="hoverable"
                       className={`btn-flat large-btn ${page === Math.ceil(appointmentCount / MAX_RESULTS) ? "disabled" : ""}`}
                       onClick={(e) => setPage(page + 1)}><i className="material-icons">chevron_right</i></a>
                </div>
            </div>
        </div>
    }

    const renderOptions = () => {
        return <div style={{
            marginLeft: 22,
            display: "flex",
            alignItems: "center",
            gap: 15,
            flexWrap: "wrap",
            justifyContent: "center"
        }}>
            <label className="valign-wrapper">
                <input id="indeterminate-checkbox" type="checkbox"
                       checked={appointments.length && checkedIds.length === appointments.filter(appt => appt.data.status_controle !== 1).length}
                       disabled={!appointments.length}
                       onChange={() => {
                           if (checkedIds.length === appointments.filter(appt => appt.data.status_controle !== 1).length) {
                               setCheckedIds([])
                               return
                           }
                           setCheckedIds(appointments.filter(appt => appt.data.status_controle !== 1).map(appointment => appointment.data.id))
                       }}/>
                <span>{checkedIds.length === appointments.filter(appt => appt.data.status_controle !== 1).length ? "Désélectionner tout" : "Sélectionner tout"}</span>
            </label>
            <Validator onValidation={() => validate(checkedIds)}
                       text="Voulez-vous vraiment valider la sélection ?"
                       detail="Les examens sélectionnés seront considérés comme corrects."
                       validationText="Valider" validationColor="blue darken-2" validationIcon="check"
                       abortColor="black darken-2" abortIcon="cancel" abortText="Annuler"
                       id="validate-intents"
            >
                <a className={`btn btn-small blue darken-2 z-depth-0 ${checkedIds.length === 0 ? "disabled " : ""}`}>Marquer
                    sélection comme valide</a>
            </Validator>
            <label className="valign-wrapper">
                <input type="checkbox" className="filled-in"
                       checked={filter.suspicious}
                       onChange={() => setFilter({...filter, suspicious: !filter.suspicious})}/>
                <span title="
                Attention, Temeoo peut vous indiquer les potentiels examens à risque,
                mais cela ne remplacera jamais l'avis d'un praticien."
                >
                    Examens suspects seulement</span>
            </label>
            <div style={{marginLeft: "auto", marginRight: 20}}>
                {renderPagination()}
            </div>
        </div>
    }

    const renderAppointment = (appointment) => {
        const full_prescription = appointment.full_prescription
        appointment = appointment.data
        return <div className="row" style={{gap: 10}}>
            <div className="col s1 valign-wrapper">
                {appointment.status_controle === 1 ? <i className="material-icons grey-text text-darken-3" title="Examen non revu par un ophtalmologiste">pending</i>
                    : <label className={`valign-wrapper`} style={{marginTop: 5}}>
                    <input id={`checkbox-appt-${appointment.id}`} type="checkbox" className="filled-in"
                           checked={checkedIds.includes(appointment.id)} onChange={() => check(appointment.id)}/>
                    <span/>
                </label>}
            </div>
            <div className="col s1 valign-wrapper">
                {appointment.date}
            </div>
            <div className="col s2">
                <a className="btn-flat" href={`/patients/${appointment.patient_id}`}>
                    {Helper.formatName(appointment.patient_firstname, appointment.patient_lastname)}
                </a>
            </div>
            <div className="col s2">
                <div style={{display: "flex", flexWrap: "wrap", flexDirection: "column"}}>
                    <span>OD : {appointment.right_finale || appointment.right_subj}</span>
                    <span>OG : {appointment.left_finale || appointment.left_subj}</span>
                </div>
            </div>
            <Tooltip id={`tt_fullprescr_${appointment.id}`} text={full_prescription.trim()} position="right">
                <div className="col s2">
                    {
                        appointment.prescription_text?.includes("OG") ?
                            <div style={{display: "flex", flexDirection: "column"}}>
                        <span>
                            {appointment.prescription_text?.split("OG")[0]}
                        </span>
                                <span>
                            OG: {appointment.prescription_text?.split("OG")[1].replace(":", "")}
                        </span>
                            </div> : <span>{appointment.prescription_text}</span>
                    }
                </div>
            </Tooltip>
            <div className="col s3">
                {appointment.anamnesis.length > 50 ?
                    <Tooltip id={`tt_anamnesis_${appointment.id}`} text={appointment.anamnesis} position="left">
                        <span>{appointment.anamnesis.substring(0, 150)}...</span>
                    </Tooltip> : <span>{appointment.anamnesis}</span>}
            </div>
        </div>
    }

    const renderAppointments = () => {
        if (requestStatus === RequestState.LOADING) return <div style={{marginTop: 20}}><Loader text="" size="small"/>
        </div>
        if (requestStatus === RequestState.ERROR) return <div style={{marginTop: 20}}><Error
            errorText="Impossible de charger les rendez-vous"/></div>
        if (!appointments?.length) return <h4 className="center" style={{margin: 40}}>Aucun acte n'est disponible</h4>

        return <ul style={{borderRadius: "20px"}} className="collection">
            <li className="collection-item" key={-1}>
                <div className="row" style={{gap: 10}}>
                    <div className="col s1 offset-s1">
                        Date
                    </div>
                    <div className="col s2">
                        Patient
                    </div>
                    <div className="col s2">
                        Mesure
                    </div>
                    <div className="col s2 valign-wrapper">
                        Prescription&nbsp;<i className="material-icons tiny" title="Passer la souris sur les prescriptions pour les voir en entier">help</i>
                    </div>
                    <div className="col s4 valign-wrapper">
                        Anamnèse&nbsp;<i className="material-icons tiny" title="Passer la souris sur les anamnèse pour les voir en entier">help</i>
                    </div>
                </div>
            </li>
            {appointments.map((appointment, index) =>
                <li className="collection-item" key={index}
                    style={{backgroundColor: appointment.suspicious ? "#ffa5a5" : "transparent"}}>
                    {renderAppointment(appointment)}
                </li>
            )}
        </ul>
    }

    return <div style={{display: "flex", flexDirection: "column", gap: 20, margin: 20}}>
        <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            <h1 style={{margin: 0}}>Validation des prescriptions</h1>
            <HelpButton
                text="Un bug est présent sur le site et fausse certaines prescriptions, cette page est là pour vous aider à contrôler vos examens passés afin d'être sûr que les patients ont accès aux bonnes ordonnances."/>
        </div>
        {renderOptions()}
        {renderAppointments()}
    </div>
}

export default AppointmentValidator;