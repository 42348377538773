import React, {Component} from 'react';
import ReactToPrint from 'react-to-print';
import PrintAcces from "./PrintAcces"

export default class Acces extends Component {
    render() {
        return (
            <div>
                <ReactToPrint
                    trigger={() => <a className="btn blue darken-2 btn-small z-depth-0" style={{
                        width: '50px',
                        marginBottom: '1vh',
                        marginTop: '1vh',
                        fontSize: '0.80vw'
                    }}><i className="material-icons left">print</i></a>}
                    content={() => this.componentRefAcces}
                />
                <div className="hide">
                    <div ref={el => (this.componentRefAcces = el)} style={{margin: 20}}>
                        <PrintAcces patient={this.props.patient} access_key={this.props.access_key}/>
                    </div>
                </div>
            </div>
        );
    }
}
