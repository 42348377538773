import React from "react";
import axios from "axios";
import {RequestState} from "../Shared/StateHelper";
import Loader from "../Shared/Loader";
import ReactToPrint from "react-to-print";
import Helper from "../Shared/Helper";
import {Act} from "../Shared/Enums/Enums";

const pageStyle = `
@media print {
   body {
       display: block;
       box-sizing: border-box;   
       padding: 20mm 15mm;
       height: auto;
       fontFamily: Arial, sans-serif;
   }
   @page {
       size: auto;   /* auto is the initial value */
       margin: 0;  /* this affects the margin in the printer settings */
   }>
}`;
export default class MainPrescription extends React.Component {
    constructor(props) {
        super(props);
        this.componentRef = React.createRef();
        this.state = {
            prescription_data: null,
            requestState: RequestState.LOADING
        }
    }

    componentDidMount() {
        axios.get("/main_prescription", {params: {prescription_id: this.props.prescription_id}})
            .then((response) => {
                this.setState({
                    prescription_data: response.data["prescription"],
                    requestState: RequestState.SUCCESS
                }, () => {
                    window.status = "ready"
                })

            }).catch((error) => {
            this.setState({requestState: RequestState.ERROR})
        })
    }

    render() {
        if (this.state.requestState === RequestState.LOADING) return <div id="full-height" style={{padding: 100}}>
            <Loader wide/>
        </div>
        if (this.state.requestState === RequestState.ERROR) return <div>
            <p>Une erreur est survenue lors de la récupération de la prescription.</p>
        </div>
        const ophtalmo_name = Helper.formatName(this.state.prescription_data["ophtalmo_firstname"], this.state.prescription_data["ophtalmo_lastname"])
        const ortho_name = Helper.formatName(this.state.prescription_data["ortho_firstname"], this.state.prescription_data["ortho_lastname"])
        return (
            <div>
                {this.props.print == 0 ? null : (
                    <div className={this.props.print ? "" : "hide"}>
                        <ReactToPrint
                            trigger={() => {
                                return (
                                    <a className="btn blue darken-2 z-depth-0"
                                       style={{position: "absolute", top: "5%", right: "5%"}}>
                                        Imprimer
                                    </a>)
                            }}
                            content={() => this.componentRef.current}
                            documentTitle="Prescription"
                            pageStyle={pageStyle}
                        >
                        </ReactToPrint>
                    </div>
                )}
                <div className="container">
                    <div className="page" ref={this.componentRef}>
                        <div className="pdf-document"
                             style={{display: "flex", flexDirection: "column"}}>
                            <div style={{fontFamily: "Arial, sans-serif"}}>
                                <div dangerouslySetInnerHTML={{__html: this.state.prescription_data["header"]}}/>
                                {this.state.prescription_data.orthoptist_phone_number ?
                                    <div style={{marginBlock: "0px 20px", fontSize: 14, whiteSpace: "nowrap"}}>
                                        Pour toute information complémentaire sur votre dossier, veuillez contacter
                                        le {this.state.prescription_data.orthoptist_phone_number}
                                    </div> : <div/>}
                                <div>
                                    <p style={{
                                        textAlign: "right",
                                        fontSize: 14
                                    }}>{this.state.prescription_data["date"]}</p>
                                    <p style={{textAlign: "left", fontSize: 14}}>
                                        {this.state.prescription_data["patient_firstname"]} {this.state.prescription_data["patient_lastname"]}
                                    </p>
                                    <p style={{marginBottom: "40px"}}/>
                                    <p className="divider-xl"/>
                                    <div style={{margin: "auto", marginLeft: "50px"}}>
                                        <p style={{whiteSpace: "pre-wrap", fontSize: 14}}>
                                            {/*<div dangerouslySetInnerHTML={{__html: this.state.prescription_data["text"]}}/>*/}
                                            {this.state.prescription_data.text}
                                        </p>
                                        {this.state.prescription_data["override_lock"] && this.state.prescription_data.appointment_alerted ?
                                            <p style={{textAlign: "center"}}>Cette prescription ne vous dispense pas de
                                                la
                                                consultation d'un ophtalmologiste suite aux pathologies détectées
                                                lors de l'analyse.</p>
                                            : null
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="pdf-footer prevent-break"
                                 style={{marginTop: "auto", display: "flex", flexDirection: "column", gap: 20}}>
                                <div>
                                    {this.state.prescription_data["signature"] ?
                                        <img style={{height: "150px", float: "right"}}
                                             src={this.state.prescription_data["signature"]} alt=""/>
                                        : null
                                    }
                                </div>
                                <div className={this.state.prescription_data.act_name === Act.RNM ? "" : "hide"}>
                                    <p className="center" style={{
                                        marginInline: 40,
                                        fontSize: "0.6rem",
                                        fontWeight: "bolder",
                                        fontStyle: "italic"
                                    }}>
                                        Examen réalisé par {ortho_name} dans le cadre du protocole Muraine co-signé
                                        entre l'ARS
                                        la CPAM et {ophtalmo_name}, Article 51 de la loi HPST du 21 juillet 2009.
                                    </p>
                                    <p className="center" style={{fontSize: "0.5rem", fontStyle: "italic"}}>
                                        Membre d'une association de gestion agréée. Le réglement par chèque est accepté.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}