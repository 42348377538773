import {action, computed, makeObservable, observable} from "mobx"
import axios from "axios"

class ControleStore{
  @observable skip = []
  @observable app_id=""
  @observable history = []
  edit_prescription = false
  possible_examens = [
        {id: 0,title: "Fond d'oeil"},
        {id: 1,title: "OCT"},
        {id: 2,title: "Champ visuel"},
        {id: 3,title: "Angiographie"},
        {id: 4,title: "Topographie de cornée"}
      ];


  constructor() {
    this.loadPossibleExamens()
    makeObservable(this)
  }

  @action addHistory(id){
    this.history.push(id)
  }
  @action backToHistory(){
    return this.history.pop()
  }
  @computed get hasHistory(){
    return(this.history.length > 0)
  }

  loadPossibleExamens() {
    axios.get('/admin/possible_examens/examens').then((response) => {
        this.possible_examens = this.possible_examens.concat(response.data.examens)
    })
  }
}
let store = new ControleStore
global.ControleStore = store
export default store
