import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {isLoading, RequestState} from "../../../../../Shared/StateHelper";
import CVStore from "../../../../../CarteVitale/CVStore";
import BridgeStoreV2 from "../../../../../BridgeV2/BridgeStoreV2";
import {toast} from "react-toastify";
import axios from "axios";
import EditFse from "../../../../../CarteVitale/Fse/EditFse";
import MesureStore from "../../../MesureStore";
import useFseCreationRequest from "./hooks/useFseCreationRequest";
import {generatePdfFromUrl} from "../../../../../Shared/PDF/PDFManager";
import BrandedElement from "../../../../../Shared/BrandedElement";


const RawSCORButton = observer(() => {
    const {sendFseCreationRequest} = useFseCreationRequest()

    const sendRequest = async () => {
        const pdfPromise = generatePdfFromUrl("/api/appointment/scor?appointment_id=" + MesureStore.appointement_id)
        const scorData = await toast.promise(pdfPromise, {
            pending: "Génération du SCOR en cours ...",
            success: "SCOR enregistré avec succès !",
            error: "Erreur lors de l'enregistrement du SCOR."
        }).then((data) => {
            return data
        })


        const promise = sendFseCreationRequest(scorData, {scorOnly: true})
        promise.then(() => {
        }).catch((_) => {
        })
    }

    return <BrandedElement temeoo>
        <div style={{display: "flex", flexDirection: "column", gap: 10}}>
            <a className={`btn green darken-2 z-depth-0`}
               onClick={() => sendRequest()} title={"Enregistrer le SCOR sur son PC via TemeooDesktop"}>
                <i className="material-icons left">save</i>
                Enregistrer SCOR (PC)
            </a>
        </div>
    </BrandedElement>
});

export default RawSCORButton;

