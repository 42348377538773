import React, {Component} from 'react';
import {observer} from "mobx-react"
import BridgeStoreV2 from "./BridgeStoreV2"
import socket from "../Shared/socket";

@observer
export default class ConnecteurV3 extends Component {
    constructor(props) {
        super(props)
        this.sendTosocket();
    }

    sendTosocket(){
        try{
          this.uid = this.props.bridge_uid;
          socket.emit("machineID",{machine: this.uid});

          socket.emit("current_appointment",{
              machine: this.uid ,id: this.appointement_id,
              s: "cv3_l23"
          });
          socket.setBridgeUid(this.uid);


          socket.emit("identify",{
              machine: this.uid,
              orthoptiste: this.props.id
          });

          this.interval = setInterval(() => {
                socket.emit("current_appointment",{
                    machine: this.uid ,
                    appointement:BridgeStoreV2.app_id,
                    orthoptiste: this.props.id,
                    s: "cv3_l37"
                });
                socket.setBridgeUid(this.uid);

          }, 2000)

          BridgeStoreV2.init(this.uid, socket);
          BridgeStoreV2.subscribeMesureStore();

        } catch(e){
        }
    }

    componentWillUnmount(){
        /*
        if(this.socket !== null){
          this.socket.disconnect()
        }*/
        if (this.interval !== null){
            clearInterval(this.interval);
        }
    }
    render() {
        return (
            <div>
            </div>
        );
    }
}
