import React, {useEffect, useState} from "react";
import {RequestState} from "../Shared/StateHelper";
import Loader from "../Shared/Loader";
import Error from "../Shared/Error";
import axios from "axios";
import AddPrescriptorButton from "./AddPrescriptorButton";
import Helper from "../Shared/Helper";

function SelectPrescriptor({selectedPrescriptor, onSelect}) {
    const [state, setState] = useState(RequestState.LOADING);
    const [prescriptors, setPrescriptors] = useState([]);

    useEffect(() => {
        getPrescriptors();
    }, [])

    useEffect(() => {
        const elems = document.getElementById('prescriptors_autocomplete');
        const instances = M.Autocomplete.init(elems, {
            data: prescriptors.map((prescriptor) => {
                return {
                    id: prescriptor.id,
                    text: Helper.formatName(prescriptor.firstname, prescriptor.lastname)
                }
            }),
            onAutocomplete: (e) => {
                if (e?.length) handleSelect(e[0].id)
            }
        })
        return () => instances?.destroy();
    }, [prescriptors])

    const getPrescriptors = () => {
        axios.get("/prescripteurs").then(res => {
            setPrescriptors(res.data.prescriptors);
            setState(RequestState.SUCCESS);
        }).catch(err => {
            setState(RequestState.ERROR);
        })
    }

    const handleSelect = (id) => {
        const prescriptor = prescriptors.find(prescriptor => prescriptor.id === id)
        onSelect(prescriptor);
    }

    if (RequestState.LOADING === state) return <div style={{margin: 20}}><Loader text="" size="small"/></div>
    if (RequestState.ERROR === state) return <div style={{margin: 20}}>
        <Error errorText="Impossible de charger les données" size="small"/>
    </div>

    return (
        <div style={{display: "flex", flexDirection: "column", gap: 20}}>
            {
                selectedPrescriptor ? <div className="valign-wrapper" style={{gap: 10}}>
                        <h5 style={{margin: 0}}>Prescripteur sélectionné :</h5>
                        <div className="card blue darken-2 z-depth-0 valign-wrapper" style={{padding: "5px 10px"}}>
                            <h5 className="white-text" style={{margin: 0}}>
                                {Helper.formatName(selectedPrescriptor.firstname, selectedPrescriptor.lastname)}
                            </h5>
                        </div>
                    </div>
                    : <h5 style={{margin: 0}}>Sélectionner un prescripteur</h5>
            }
            <div className="input-field outlined">
                <i className="material-icons prefix">person</i>
                <input type="text" id="prescriptors_autocomplete" className="autocomplete"/>
                <label htmlFor="autocomplete-input">Prescripteurs enregistrés</label>
            </div>
            <AddPrescriptorButton onSave={(prescriptor) => {
                onSelect(prescriptor)
                getPrescriptors()
            }}/>
        </div>
    )
}

export default SelectPrescriptor;