import React from 'react';

const ActiveUsersMetric = ({pricing}) => {

    return <div style={{border: "1px solid lightgrey", borderRadius: "10px", width: 250, marginTop: 10}}>
        <p style={{marginLeft: 20, marginBottom: 0}}><b>Nombre utilisateur actif</b></p>
        <p style={{margin: 20, fontSize: 18}}><b>{pricing.nb_active}</b></p>
    </div>
}

export default ActiveUsersMetric;