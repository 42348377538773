import React, {useEffect, useState} from 'react'
import InputText from "../../Shared/UiKit/InputText";
import useDebounce from "../../Shared/Hooks";

function PatientHistory({history, onEdit, autosave}) {
    const [edit, setEdit] = useState(false)
    const [historyText, setHistoryText] = useState(history)
    // const debouncedEdit = useDebounce(() => onEdit(historyText), 1000)
    const debouncedEdit = useDebounce(historyText, 1000)

    useEffect(() => {
        setHistoryText(history)
    }, [history])

    useEffect(() => {
        if (autosave && historyText !== history) {
            onEdit(historyText)
        }
    }, [debouncedEdit])

    const onClick = () => {
        if (edit && historyText !== history) {
            onEdit(historyText)
        }
        setEdit(!edit)
    }

    const renderHistory = () => {
        if (edit || autosave) return <InputText type="text-area" v_resizable={false} height={200}
                                    value={historyText} onChange={e => setHistoryText(e.target.value)}/>

        if (!history) return <h5 className='center' style={{flexFlow: "wrap"}}>Aucun antécédent connu à ce jour.</h5>
        return history.split('\n').filter(i => i).map((item, i, arr) => <h5
            key={i}>{`${arr.length > 1 ? '-' : ""} ${item}`}</h5>)
    }

    const renderSaver = () => {
        if (autosave) return <i className={`rotating material-icons tiny grey-text ${historyText !== history ? "" : "hide"}`}>sync</i>

        return <a className="btn btn-small blue darken-2 z-depth-0" style={{marginBottom: 4}} onClick={onClick}>
            <i className="material-icons">{edit ? "done" : "edit"}
            </i></a>
    }

    return <div data-cy="AntecedentComponent">
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", gap: 20}}>
            <h2 style={{marginTop: 0, marginBottom: "10px"}}>Antécédents</h2>
            {renderSaver()}
        </div>
        <hr/>
        {renderHistory()}
    </div>
}

PatientHistory.defaultProps = {
    autosave: false
}

export default PatientHistory