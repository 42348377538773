import React from "react";
import axios from "axios";
import Loader from "../Shared/Loader";
import Helper from "../Shared/Helper";
import {RequestState} from "../Shared/StateHelper";
import ReactToPrint from "react-to-print";

const pageStyle = `
@media print {
   body {
       display: block;
       box-sizing: border-box;   
       padding: 20mm 15mm;
       height: auto;
       fontFamily: Arial, sans-serif;
   }
   @page {
      size: auto;   /* auto is the initial value */
      margin: 0;  /* this affects the margin in the printer settings */
   }
}`;

export default class ProofPayment extends React.Component {
    constructor(props) {
        super(props);
        this.componentRef = React.createRef();
        this.state = {
            payment_data: null,
            request_state: RequestState.LOADING
        }
    }

    componentDidMount() {
        axios.get("/payment_proof", {params: {id: this.props.appointment_id, brand: this.props.brand}})
            .then((response) => {
                this.setState({
                        payment_data: response.data["payment_proof"],
                        request_state: RequestState.SUCCESS
                    }, () => window.status = "ready"
                )
            })
            .catch((error) => {
                this.setState({request_state: RequestState.ERROR})
            })
    }

    render() {
        if (this.state.request_state === RequestState.LOADING) return <div id="full-height">
            <Loader wide/>
        </div>
        if (this.state.request_state === RequestState.ERROR) return <div className="center">Une erreur est
            survenue.</div>
        if (this.state.request_state === RequestState.SUCCESS) return (
            <div>
                <div className={this.props.print ? "" : "hide"}>
                    <ReactToPrint
                        trigger={() => {
                            return (
                                <a className="btn blue darken-2 z-depth-0"
                                   style={{position: "absolute", top: "5%", right: "5%"}}>Imprimer
                                </a>)
                        }}
                        content={() => this.componentRef.current}
                        documentTitle="Justificatif de payement"
                        pageStyle={pageStyle}
                    >
                    </ReactToPrint>
                </div>
                <div ref={this.componentRef}>
                    <div className="pdf-document">
                        <title>Facture</title>
                        <div className="invoice-box">
                            <table cellPadding="0" cellSpacing="0">
                                <thead>
                                <tr className="top">
                                    <td colSpan="2">
                                        <table>
                                            <thead>
                                            <tr>
                                                <td className="title">
                                                    {this.state.payment_data["logo"] ?
                                                        <img style={{width: "100%", maxWidth: "240px"}}
                                                             src={this.state.payment_data["logo"]} alt=""/>
                                                        : null
                                                    }
                                                </td>
                                                <td>
                                                    {this.props.brand === "WHITE" ?
                                                        <div>
                                                            {this.state.payment_data["stripe"]["id"]} <br/>
                                                            Dossier: {this.state.payment_data["appointment"]["id"]}<br/>
                                                            Crée: {this.state.payment_data["appointment"]["start"]}
                                                            <br/>
                                                            Docteur {Helper.formatName(this.state.payment_data.ophtalmologist?.firstname, this.state.payment_data?.ophtalmologist.name)}
                                                            <br/>
                                                            Chirurgien Ophtalmologiste <br/>
                                                            N° RPPS
                                                            : {this.state.payment_data["ophtalmologist"]["rps"]}<br/>
                                                            N° Finess
                                                            : {this.state.payment_data["ophtalmologist"]["adeli"]}<br/>
                                                        </div>
                                                        :
                                                        <div>
                                                            {this.state.payment_data["stripe"]["id"]}<br/>
                                                            Dossier: {this.state.payment_data["appointment"]["id"]}
                                                            <br/>
                                                            Crée le : {this.state.payment_data["appointment"]["start"]}<br/>
                                                        </div>

                                                    }
                                                </td>
                                            </tr>
                                            </thead>

                                        </table>
                                    </td>
                                </tr>

                                <tr className="information">
                                    <td colSpan="2">
                                        <table>
                                            <thead>
                                            <tr>
                                                <td>
                                                    {this.props.brand === "WHITE" ? "Opticien" : "Orthoptiste"}: {this.state.payment_data["appointment"]["orthoptist_fullname"]}<br/>
                                                    Ophtalmologiste référent:
                                                    Dr {this.state.payment_data["ophtalmologist"]["name"]}<br/>
                                                </td>
                                                <td>
                                                    {this.state.payment_data["appointment"]["patient_full_name"]}<br/>
                                                    {this.state.payment_data["appointment"]["email"]}<br/>
                                                    {this.state.payment_data["appointment"]["telephone"]}
                                                </td>
                                            </tr>
                                            </thead>

                                        </table>
                                    </td>
                                </tr>

                                <tr className="heading">
                                    <td>Titre</td>
                                    <td>Montant</td>
                                </tr>
                                <tr className="item">
                                    <td>{this.state.payment_data["appointment"]["possible_acts"]}</td>
                                    <td>{this.state.payment_data["stripe"]["amount_captured"]}€</td>
                                </tr>
                                <tr className="total">
                                    <td></td>
                                    <td>Total: {this.state.payment_data["stripe"]["amount_captured"]}€</td>
                                </tr>
                                <tr className="total">
                                    <td></td>
                                    <td>Par carte: {this.state.payment_data["stripe"]["amount_captured"]}€</td>
                                </tr>
                                <tr className="total">
                                    <td></td>
                                    <td>Solde restant: 0.00 €</td>
                                </tr>
                                </thead>

                            </table>
                            <h1 style={{textAlign: "center"}}>Facture acquittée</h1>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}