import React, {useEffect} from 'react';
import axios from "axios";
import MesureStore from "../../MesureStore";
import useDebounce from "../../../../Shared/Hooks";
import {loading, RequestState} from "../../../../Shared/StateHelper";
import {Observer} from "mobx-react";

function EditPatientHistory(props) {
    const {patient} = props
    const [history, setHistory] = React.useState(patient.history)
    const [requestState, setRequestState] = React.useState(RequestState.IDLE)
    const [loaded, setLoaded] = React.useState(false)

    const debouncedSave = useDebounce(history, 500)

    useEffect(() => {
        if (history !== undefined && loaded) {
            setRequestState(loading(requestState))
            updatePatient(history)
        }
        setLoaded(true)
    }, [debouncedSave])

    useEffect(() => {
        if (history !== undefined && loaded) props.loading()
    }, [history]);

    const updatePatient = (history) => {
        axios.patch("/api/v3/patients/history", {history: history, id: patient.id}).then(res => {
            setHistory(res.data.history)
            MesureStore.patient = {...MesureStore.patient, history: res.data.history}
            props.loaded()
        }).catch(err => {
            // I let that logger here but waiting for a generalized toastr system for the app or at least that page
            console.error(err)
        })
    }

    return <Observer>
        {() => <div style={{display: "flex", flexDirection: "column", gap: 10}}>
            <div className="input-field outlined">
                <textarea
                    defaultValue={patient.history}
                    style={{resize: "none", padding: 10, height: 120, fontSize: 12}}
                    onChange={(e) => setHistory(e.target.value ?? "")}
                />
                <label>Antécédents</label>
            </div>
        </div>
        }
    </Observer>
}

export default EditPatientHistory;