import {useEffect, useState} from "react";
import axios from "axios";

function useMedia(appointment_id) {
    const [images, setImages] = useState(undefined);
    const [videos, setVideos] = useState(undefined);

    useEffect(() => {
        if (appointment_id) {
            loadImages();
            loadVideos();
        }
    }, []);
    const loadVideos = () => {
        axios.get("/api/getVideos", {params: {appointment_id: appointment_id}}).then((resp) => {
            setVideos(resp.data.videos)

        })
    }

    const loadImages = () => {
        axios.get("/api/images", {params: {appointment_id: appointment_id}}).then((resp) => {
            setImages(resp.data.images)
        })
    }

    return {images, videos};
}

export default useMedia;