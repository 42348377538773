import React, {useEffect, useState} from "react";
import Switch from "../../../../Shared/Switch";
import BorderedDisplay from "../../../../Shared/UiKit/BorderedDisplay";
import {Logical, QuestionType} from "../../../../Shared/Enums/Enums";
import InputText from "../../../../Shared/UiKit/InputText";
import axios from "axios";
import {toast} from "react-toastify";
import useDebounce from "../../../../Shared/Hooks";
import MesureStore from "../../MesureStore";
import {Observer} from "mobx-react";
import StateButton from "../../../../Shared/StateButton";
import {loading, RequestState} from "../../../../Shared/StateHelper";
import {LockingElement} from "../../../AppointmentValidation";

const _ = require("lodash")
function MedicalQuestions({json}) {
    const [questions, setQuestions] = useState(undefined)
    const [loaded, setLoaded] = useState(false)
    const [firstYesNo, setFirstYesNo] = useState(undefined)
    const [requestState, setRequestState] = useState(RequestState.IDLE)

    useEffect(() => {
        fetchQuestions()
    }, [])

    const debouncedSave = useDebounce(questions, 100)

    useEffect(() => {
        if (loaded) {
            saveQuestions()
        }
        if (questions) setLoaded(true)
    }, [debouncedSave])

    const handleChange = (id, value) => {
        setQuestions(Object.assign({}, questions, {[id]: value}))
    }

    const fetchQuestions = () => {
        axios.get("/api/appointements/report", {params: {appointment_id: MesureStore.appointement_id}}).then((resp) => {
            setQuestions(resp.data.report ?? {})
            MesureStore.report = resp.data.report ?? {}
        }).catch((err) => {
            toast.error("Impossible de charger les questions")
        })
    }

    const saveQuestions = () => {
        setRequestState(loading(requestState))
        axios.patch("/api/appointements/report", {
            report: questions,
            appointment_id: MesureStore.appointement_id
        }).then((data) => {
            MesureStore.report = questions
            setRequestState(RequestState.IDLE)
        }).catch((err) => {
            toast.error("Impossible de sauvegarder les questions")
            setRequestState(RequestState.ERROR)
        })
    }

    const handleNothingToReport = () => {
        let allInputsToNo = Object.values(json).reduce((acc, category) => {
            category.questions.forEach((question) => {
                if (question.type === QuestionType.INPUT) {
                    if(question.options.includes(Logical.NO)) acc[question.label] = Logical.NO
                }
            })
            return acc
        }, {})
        allInputsToNo = _.isEmpty(allInputsToNo) ? {[firstYesNo]: false} : allInputsToNo

        setQuestions(allInputsToNo)
        setRequestState(RequestState.LOADING)
    }

    const renderCategory = (category, key) => {
        if (!category) return null
        return <div className="col s12 l6" key={key} style={{width: "100%", margin: "20px 0"}}>
            <BorderedDisplay text={category.label}>
                {category.questions?.map((question, index) => {
                    return <div key={`question_${category.label}_${index}`}>
                        {renderQuestion(question, key)}
                        <div className={`divider ${index === category.questions.length - 1 ? "hide" : ""}`}/>
                    </div>
                })}
            </BorderedDisplay>
        </div>
    }

    const renderQuestion = (question, key, id = question.label) => {
        const required = MesureStore.listLockingElements().includes(LockingElement[question.condition]?.prompt)
        switch (question.type) {
            case QuestionType.YES_NO:
                if (firstYesNo === undefined) setFirstYesNo(id)
                const yes_label = question.yes_label ?? "Oui"
                const no_label = question.no_label ?? "Non"
                return <div key={`${key}_${id}`}>
                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <h5 className={required ? "red-text" : ""}>{question.label}</h5>
                        <Switch id={`switch_${id}`} left={no_label} right={yes_label} onChange={(val) => handleChange(id, val)}
                                value={questions?.[id]}/>
                    </div>
                    <div style={{paddingLeft: "30px"}}>
                        {
                            questions?.[id] ?
                                (question.subquestions?.map((subquestion, index) => {
                                    return <div key={`subquestion_${question.label.label}_${index}`}>
                                        <div className="divider"/>
                                        {renderQuestion(subquestion, key, `${id}##${subquestion.label}`)}
                                    </div>
                                })) : null
                        }
                    </div>
                </div>
            case QuestionType.INPUT:
                return <div key={`${key}_${id}`}>
                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <h5 className={required ? "red-text" : ""}>{question.label}</h5>
                        <select className="browser-default" style={{maxWidth: "30%"}}
                                id={`select_${id}`} value={questions?.[id] || ""}
                                onChange={(e) => handleChange(id, e.target.value)}>
                            <option value="" disabled>Choisissez</option>
                            {question.options.map((option, index) => <option key={index}
                                                                             value={option}>{option}</option>)}
                        </select>
                    </div>
                </div>
            case QuestionType.TEXT:
                return <div key={`${key}_${id}`}>
                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <h5 className={required ? "red-text" : ""}>{question.label}</h5>
                        <InputText onChange={(event) => handleChange(id, event.target.value)} width="60%"
                                   value={questions?.[id] ?? ""} />
                    </div>
                </div>
            default:
                return null
        }
    }

    return <Observer>
        {() => {
            return <div className="center" style={{paddingInline: 30}}>
                <div style={{display: "flex", gap: 20, alignItems: "center", justifyContent: "center"}}>
                    <h2>Questionnaire</h2>
                    <StateButton color="blue darken-2" text="Rien à signaler"
                                 state={requestState}
                                 data_cy="NothingSpecial"
                                 onClick={handleNothingToReport}/>
                </div>
                <div style={{gap: 20}}>
                    <div className="row" style={{gap: 20}}>
                        <div className="col s12 l6">
                            {json.slice(0, json.length / 2).map((category, index) => renderCategory(category, index))}
                        </div>
                        <div className="col s12 l6">
                            {json.slice(json.length / 2, json.length).map((category, index) => renderCategory(category, index))}
                        </div>
                    </div>

                </div>
            </div>
        }}
    </Observer>
}

export default MedicalQuestions