import React from "react";
import {observer} from "mobx-react";
import {JOB} from "../../Shared/Helper";
import MedicalReport from "./Report/MedicalReport";
import DrugsReport from "./Report/DrugsReport";
import {ActNature} from "../../Shared/Enums/Enums";
import BBCode from "../../Shared/BBCode";

const telemedical_questions_json = require("../../Appointments/Mesures/mesure_components/Questions/telemedical_questions.json")
const nurse_question_json = require("../../Appointments/Mesures/mesure_components/Questions/nurse_questions.json")

@observer
class GeneralSummaryPanel extends React.Component {
    constructor(props) {
        super(props);
        this.isAlert = this.isAlert.bind(this)
    }

    isAlert() {
        let alert = false
        let messages = []
        this.props.mesures.map((mesure) => {
            if (mesure.od_tension > 21 || mesure.og_tension > 21) {
                alert = true
                messages.push("Une tension trop élevée")
            }
            if (mesure.typedemesure_id == 12) {
                if (!([null, "", "0.9", "1", "1.0", "12", "16","1.2","1.20","1.25","1.6"].includes(mesure.og_av_loin)) || !([null, "", "0.9", "1", "1.0", "12", "16","1.2","1.20","1.25","1.6"].includes(mesure.od_av_loin))) {
                    alert = true
                    messages.push("Une acuité visuelle trop basse")
                }
            }
        })
        if (alert) {
            messages = Array.from(new Set(messages))
            return (
                <div className="red white-text floating-alert">
                    <h4 className="center">Problème détecté</h4>
                    <ul>
                        {messages.map((message, index) => (
                            <li key={index}>{message}</li>
                        ))}
                    </ul>
                </div>
            )
        } else {
            return (<div></div>)
        }
    }

    renderReport = () => {
        if(!this.props.medical_report) return null
        if (this.props.act.nature === ActNature.TELEMEDICAL.name) return <MedicalReport report={this.props.medical_report} json={telemedical_questions_json} />
        if (this.props.act.nature === ActNature.NURSE.name) return <MedicalReport report={this.props.medical_report} json={nurse_question_json} />
        return null
    }

    render() {
        const alert = this.props.summary.priority === 1;
        const mystyle = {
            borderRadius: "0px 0px 30px 30px",
            borderTop: "thick solid #32a1ce",
        };
        if (alert == true) {
            mystyle.borderTop = "thick solid crimson"
        }
        return (
            <div>
                {this.isAlert()}
                <div className="row">
                    <div className="col s3 center-align" style={{fontSize: "0.8em"}}>
                        <h2><b>Patient</b></h2>
                    </div>
                    <div className="col s5 center-align" style={{fontSize: "0.8em"}}>
                        <h2><b>Commentaire {JOB.toLowerCase()}</b></h2>
                    </div>
                    <div className="col s4 center-align" style={{fontSize: "0.8em"}}>
                        <h2><b>Données cliniques</b></h2>
                    </div>
                </div>
                <div className={"row " + (alert ? "card-panel red lighten-4" : "card-panel blue lighten-5")}
                     style={mystyle}>
                    <div className="col s3">
                        <ul style={{paddingLeft: 5}}>
                            <li>{this.props.summary.name}</li>
                            <li>{this.props.summary.age} ans</li>
                            <li>{this.props.summary.phone}</li>
                            {this.props.summary.history ? <li style={{fontWeight:"20px"}}><b>Antécédents :</b>
                                {this.props.summary.history.split('\n').filter(i => i).map((item, i, arr) => <h6 key={"t"+i} style={{margin: 4}}>{`${arr.length > 1 ? '-' : ""} ${item}`}</h6>)}
                            </li> : <div/>}
                            <li style={{fontWeight:"20px"}}><b>Examiné par :</b><br/> {this.props.summary.orthoptist} </li>
                            <li><b>Dernière modification : </b><br/>{this.props.summary.date}</li>
                            <li><b>Actes : </b><br/>{this.props.summary.acts.join(", ")} </li>

                        </ul>
                    </div>
                    <div className="col s5">
                        {
                            this.props.summary.bypass_reason ? (
                                <div>
                                    <h5 className="bolder red-text">Contraintes désactivées</h5>
                                    <p className="bold red-text">{this.props.summary.bypass_reason}</p>
                                </div>
                            ) : (
                                <div></div>
                            )
                        }
                        {this.props.summary.stereoscopy ?( <>
                                <p style={{marginBottom: 0}}><b>Stéréoscopie :</b></p>
                                <p style={{marginTop: 0}}>{this.props.summary.stereoscopy}</p>
                            </>)
                            : <div/>
                        }

                        <BBCode text={this.props.summary.comment ?? ""}/>
                        <BBCode text={this.props.summary.orthoptic_text ?? ""}/>
                        {this.props.summary.pathologies.length > 0 ? (
                            <div>
                                <h2 className="center red-text">Pathologies !</h2>
                                {this.props.summary.pathologies.map((pathologie,index) => (
                                    <p key={"pat"+index}
                                       className="center red-text">{pathologie.pathologie}</p>
                                ))}
                            </div>
                        ) : (
                            <div></div>
                        )}
                    </div>
                    <div className="col s4">
                        {this.renderReport()}

                        {!this.props.summary.questions67.amy6_diabete ? <div/> : (
                            <ul>
                                <li><b>Diabète</b> : {this.props.summary.questions67.amy6_diabete} |
                                    Découverte {this.props.summary.questions67.annee_decouverte_diabete}</li>
                                <li><b>hb1ac</b> : {this.props.summary.questions67.hb1ac}</li>
                                <li><b>suivi ophtalmique</b> : {this.props.summary.questions67.suivi_ophtalmique}
                                </li>
                                <li><b>Dernier examen
                                    ophtalmo</b> : {this.props.summary.questions67.ophtalmologiste_dernier_examen}
                                </li>
                                <li><b>hta</b> : {this.props.summary.questions67.hta}</li>
                            </ul>
                        )}
                        <DrugsReport drugs={this.props.patient.drugs} />
                    </div>
            </div>
    </div>
    )
    }
}

export default GeneralSummaryPanel
