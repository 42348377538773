import React, {useEffect, useState} from "react";
import MesureOverview from "../../Appointments/Mesures/mesure_components/MesureOverview";
import {formatAcuities, formatRetinos} from "../../Appointments/Mesures/mesure_components/mesures";
import {Mesures, Side} from "../../Shared/Enums/Enums";
import BrandedElement from "../../Shared/BrandedElement";

export default function ExamRecapTabs(props) {

    const [displayed_prescription, setDisplayedPrescription] = useState(props.appointment.main_prescription)

    useEffect(() => {
        const instances = M.Tabs.init(document.getElementsByClassName(`tabs apt${props.appointment.id}`))
        return () => instances[0].destroy()
    }, []);

    function renderQuestions() {
        if (Object.keys(props.appointment.report).length === 0) {
            return <div style={{display: "flex", justifyContent: "center"}}>Rien à signaler.</div>
        }

        let formatted_report = Object.entries(props.appointment.report).reduce((acc, key_value_pair) => {
            if (typeof key_value_pair[1] === 'boolean') return acc
            let property_name = key_value_pair[0].trim().split("##")
            if (property_name.length > 1) {
                property_name = property_name.reverse().join(" ")
            } else {
                property_name = property_name[0]
            }
            acc[property_name] = key_value_pair[1]
            return acc
        }, {})

        return <div style={{display: "flex", justifyContent: "center"}} data-cy="QuestionsContainer">
            <table style={{width: "80%"}}>
                <tbody>
                {Object.entries(formatted_report).map((item, index) => {
                    return <tr key={`question_apt_${props.appointment.id}_${index}`}>
                        <td key={`question_apt_${props.appointment.id}_${index}_name`} style={{padding: 3}}>{item[0]}:
                        </td>
                        <td key={`question_apt_${props.appointment.id}_${index}_value`}
                            style={{padding: 3}}>{item[1]}</td>
                    </tr>
                })}
                </tbody>
            </table>
        </div>
    }

    function handleDisplayedPrescription(presription_level) {
        switch (presription_level) {
            case 0:
                setDisplayedPrescription(props.appointment.main_prescription)
                break
            default:
                setDisplayedPrescription(props.appointment.secondary_prescriptions.find((prescription) => prescription.level === presription_level))
        }
    }

    function renderTension(measure) {
        return <div style={{display: "flex", paddingBottom: "10px"}}>
            <div style={{width: "150px"}}>{measure.title}:</div>
            <div style={{display: "flex", flexDirection: "column"}}>
                {measure.od_tension ? <div style={{margin: 0}}>OD : {measure.od_tension < 0 ? "NM" : measure.od_tension}</div> : null}
                {measure.og_tension ? <div style={{margin: 0}}>OG : {measure.og_tension < 0 ? "NM" : measure.og_tension}</div> : null}
            </div>
        </div>

    }

    function renderPachymetry(measure) {
        return <div style={{display: "flex", paddingBottom: "10px"}}>
            <div style={{width: "150px"}}>{measure.title}:</div>
            <div style={{display: "flex", flexDirection: "column"}}>
                {measure.od_pachymetrie ? <div style={{margin: 0}}>OD : {measure.od_pachymetrie} µm</div> : null}
                {measure.og_pachymetrie ? <div style={{margin: 0}}>OG : {measure.og_pachymetrie} µm</div> : null}
            </div>
        </div>
    }

    function renderMesure(measure) {
        return <div style={{display: "flex", paddingBottom: "10px"}}>
            <div style={{width: "150px"}}>{measure.title}:</div>
            <div style={{display: "flex", flexDirection: "column"}}>
                <div style={{margin: 0}}>
                OD : {formatRetinos(measure, Side.RIGHT)} {formatAcuities(measure, Side.RIGHT, true)}
            </div>
                <div style={{margin: 0}}>
                    OG : {formatRetinos(measure, Side.LEFT)} {formatAcuities(measure, Side.LEFT, true)}
                </div>
            </div>
        </div>

    }

    function chooseMeasureRender(measure) {
        switch (measure.title) {
            case Mesures.TENSION.name :
                return renderTension(measure);
            case Mesures.PACHYMETRY.name :
                return renderPachymetry(measure);
            default :
                return renderMesure(measure);
        }
    }


    return <td colSpan={5}>
        <div style={{paddingLeft: "10px"}}>
            <ul className={`tabs apt${props.appointment.id}`}
                style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    marginBottom: "10px"
                }}>
                <li className="tab col s3"><a className="active" href={`#apt${props.appointment.id}_general_infos`}>Informations
                    générales</a></li>
                <li className="tab col s3"><a href={`#apt${props.appointment.id}_extra_infos`}>Informations
                    supplémentaires</a></li>
            </ul>
            <div id={`apt${props.appointment.id}_general_infos`} className="col s12" style={{display: "flex", flexDirection: "column", gap: 20}}>
                <b>Prescription:</b>
                <div style={{display: "flex", gap: "10px"}}>
                    <div style={{width: "50%", whiteSpace: "pre-wrap"}} data-cy="PrescriptionContainer">
                        {displayed_prescription ? displayed_prescription.text : "Pas de prescription"}
                    </div>
                    <div style={{display: "flex", flexDirection: "column", paddingLeft: "20px"}}>
                        <a className={`btn-small ${displayed_prescription === props.appointment.main_prescription ? "blue darken-4" : "blue darken-2"} z-depth-0 ${props.appointment.main_prescription ? "" : "disabled"}`}
                           style={{cursor: "pointer"}}
                           onClick={() => handleDisplayedPrescription(0)}>Primaire</a>
                        <a className={`btn-small ${displayed_prescription === props.appointment.secondary_prescriptions.find((prescription) => prescription.level === 1) ? "blue darken-4" : "blue darken-2"} z-depth-0 ${props.appointment.secondary_prescriptions.find((prescription) => prescription.level === 1) ? "" : "disabled"}`}
                           style={{cursor: "pointer"}}
                           onClick={() => handleDisplayedPrescription(1)}>Secondaire</a>
                        <a className={`btn-small ${displayed_prescription === props.appointment.secondary_prescriptions.find((prescription) => prescription.level === 2) ? "blue darken-4" : "blue darken-2"} z-depth-0 ${props.appointment.secondary_prescriptions.find((prescription) => prescription.level === 2) ? "" : "disabled"}`}
                           style={{cursor: "pointer"}}
                           onClick={() => handleDisplayedPrescription(2)}>Tertiaire</a>
                    </div>
                </div>
                <div>
                    <b>Anamnèse :</b>
                    <div data-cy="AnamnesisContainer" style={{whiteSpace: "pre-wrap"}}>{props.appointment.comment ? props.appointment.comment : "Pas d'anamnèse"}</div>
                </div>
                <div>
                    <b>Commentaire privé :</b>
                    <div data-cy="PrivateCommentContainer" style={{whiteSpace: "pre-wrap"}}>{props.appointment.private_comment ? props.appointment.private_comment : "Pas de commentaire privé"}</div>
                </div>
                <BrandedElement temeoo>
                    <div>
                        <b>Orthoptie :</b>
                        <div data-cy="OrthopticTextContainer" style={{whiteSpace: "pre-wrap"}}>{props.appointment.orthoptic_text ? props.appointment.orthoptic_text : "Pas d'orthoptie"}</div>
                    </div>
                </BrandedElement>
            </div>

            <div id={`apt${props.appointment.id}_extra_infos`} className="col s12">
                <div className="row">
                    <div className="col s6">
                        <div style={{display: "flex", justifyContent: "center", paddingBottom: "5px"}}><b>Mesures :</b></div>
                        {!props.appointment.measures || props.appointment.measures.length === 0 ?
                            <div data-cy="MeasuresContainer">Aucune mesures disponibles</div> :
                            <div data-cy="MeasuresContainer">
                                {props.appointment.measures.map((measures) => <div style={{paddingBottom: "2px"}}>
                                    {chooseMeasureRender(measures)}
                                </div>)}
                            </div>
                        }
                    </div>
                    <div className="col s6">
                        <div style={{display: "flex", justifyContent: "center", paddingBottom: "5px"}}>
                            <b>Questionnaire:</b>
                        </div>
                        {renderQuestions()}
                    </div>
                </div>
            </div>
        </div>

    </td>
}