import React, {useEffect} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import Helper from "../../../Shared/Helper";
import DatePicker from "../../../Shared/DatePicker";
import moment from "moment/moment";
import {generatePDFArchive} from "../../../Shared/PDF/PDFManager";
import FillPDFButton from "../../../Shared/PDF/FillPDFButton";

const _ = require('lodash');

function AppointmentList({orthoptists}) {
    const [page, setPage] = React.useState(1)
    const [appointments, setAppointments] = React.useState([])
    const [startDate, setStartDate] = React.useState(null)
    const [endDate, setEndDate] = React.useState(null)
    const [checkedIds, setCheckedIds] = React.useState([])
    const [blankPdf, setBlankPdf] = React.useState(false)

    useEffect(() => {
        const fs = document.getElementById('orthoptists_select_fs');
        const fs_instance = M.FormSelect.init(fs, {
            dropdownOptions: {onCloseEnd: getAppointments}
        });
        return () => {
            fs_instance.destroy();
        }
    }, [orthoptists])

    useEffect(() => {
        setPage(1)
    }, [startDate, endDate])

    useEffect(() => {
        getAppointments()
    }, [page, startDate, endDate])

    useEffect(() => {
        document.getElementById('indeterminate-checkbox').indeterminate = checkedIds.length > 0 && checkedIds.length < appointments.length
    }, [checkedIds])

    const check = (id) => {
        if (checkedIds.includes(id)) {
            setCheckedIds(checkedIds.filter(checkedId => checkedId !== id))
            return
        }
        setCheckedIds([...checkedIds, id])
    }

    const getAppointments = () => {
        const selectedOrthoptists = M.FormSelect.getInstance(document.getElementById('orthoptists_select_fs')).getSelectedValues();
        axios.get('/admin/console/teletransmission/appointments', {
            params: {
                page: page,
                orthoptist_ids: selectedOrthoptists,
                start_date: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
                end_date: endDate ? moment(endDate).format("YYYY-MM-DD") : null,
            }
        }).then(response => {
            setAppointments(response.data.appointments)
        }).catch(error => {
            toast.error("Une erreur est survenue lors de la récupération des rendez-vous")
        })
    }
    const getSignatures = (callback) => {
        axios.get('/admin/console/teletransmission/signatures', {
            params: {
                orthoptist_ids: checkedIds,
            }
        }).then(response => {
            callback(response.data.signatures)
        }).catch(error => {
            toast.error("Une erreur est survenue lors de la récupération des rendez-vous")
        })
    }


    const getFormData = (appointment) => {
        const cvNum = appointment.patient_ssn ? appointment.patient_ssn.replace(" ", "").substring(0, 13) : ""
        const cvKey = appointment.patient_ssn ? appointment.patient_ssn.replace(" ", "").substring(13, 15) : ""

        const formData = {
            'NomPrenom': Helper.formatName(appointment.patient_firstname, appointment.patient_lastname),
            'date': Helper.formatDate(appointment.analysis_date, "DD-MM-YYYY"),
            'NSS': cvNum,
            'NSScle': cvKey,
            'adresse': appointment.patient_address || "",
            'DateNaissance': moment(appointment.patient_birthday).format("DD-MM-YYYY"),
            'Medecin': blankPdf ? "" : `${Helper.formatName(appointment.analyst_firstname, appointment.analyst_lastname)}        ${appointment.analyst_adeli || ""}`,
            // 'Identifiant': appointment.analyst_adeli || "",
            'RequerantNomPrenomAdeliRPPS': `${Helper.formatName(appointment.orthoptist_firstname, appointment.orthoptist_lastname)}     ${appointment.orthoptist_adeli || ""}`,
            "DateActe1": Helper.formatDate(appointment.date, "DD-MM-YYYY"),
            "SignatureImpossible": true
        }
        const act = appointment.acts[0] ?? {}
        formData["DateActe1"] = Helper.formatDate(appointment.date, "DD-MM-YYYY")
        formData["CodeActe1"] = act.code
        formData["MontantActe1"] = act.price
        formData["MontantTotal"] = act.price

        return formData
    }

    const importSelected = async () => {
        const formDatas = appointments.filter(appointment => checkedIds.includes(appointment.id)).map(appointment => getFormData(appointment))
        const uri = blankPdf ? "/cerfa/Feuille_de_soin_vide.pdf" : "/cerfa/Feuille_de_soin.pdf"
        await generatePDFArchive(uri, formDatas)
    }


    const renderOrthoptistsSelect = (id, onChange) => {
        return <div className="input-field col s12">
            <select id={`orthoptists_select_${id}`} multiple onClose={onChange}>
                <option value="" disabled>Tous les orthoptistes</option>
                {orthoptists.map(orthoptist => {
                    return <option id='listado' key={orthoptist.id}
                                   value={orthoptist.id}>{Helper.formatName(orthoptist.prenom, orthoptist.nom)}</option>
                })}
            </select>
        </div>
    }

    const renderFilter = () => {
        return <div style={{display: "flex", flexWrap: "wrap"}}>
            <div style={{margin: "0 10px"}}>
                {renderOrthoptistsSelect('fs', getAppointments)}
            </div>
            <div style={{margin: "0 10px"}} className="valign-wrapper">
                <DatePicker id="bills_start_date" txt="Date de début" onChange={(date) => setStartDate(date)}/>
            </div>
            <div style={{margin: "0 10px"}} className="valign-wrapper">
                <DatePicker id="bills_end_date" txt="Date de fin" onChange={(date) => setEndDate(date)}/>
            </div>
        </div>
    }

    const renderOptions = () => {
        return <div style={{display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center"}}>
            <label className="valign-wrapper" style={{margin: "10px 20px"}}>
                <input id="indeterminate-checkbox" type="checkbox"
                       checked={checkedIds.length === appointments.length}
                       onChange={() => setCheckedIds(checkedIds.length === appointments.length ? [] : appointments.map(appt => appt.id))}
                />
                <span>{checkedIds.length === appointments.length ? "Désélectionner tout" : "Sélectionner tout"}</span>
            </label>
            <a className={`btn btn-small blue darken-2 z-depth-0 ${_.isEmpty(checkedIds) ? "disabled" : ""}`}
               onClick={importSelected}>Importer selection</a>
            <div className="hide-on-med-and-down" style={{marginLeft: "auto"}}>
                {renderPagination()}
            </div>
            <div className="hide-on-large-only">
                {renderPagination()}
            </div>
        </div>
    }

    const renderList = () => {
        return <div className="collection" style={{borderRadius: "20px"}}>
            {appointments.map((appointment) => {
                return <div key={`appt_${appointment.id}`} className="collection-item" style={{display: "flex"}}>
                    <label className="valign-wrapper" style={{marginTop: 10}}>
                        <input id={`checkbox-ephad-${appointment.id}`} type="checkbox" className="filled-in"
                               checked={checkedIds.includes(appointment.id)} onChange={() => check(appointment.id)}/>
                        <span/>
                    </label>
                    <div className="row" style={{width: "100%", margin: 10, marginBottom: 10, alignItems: "center"}}>
                        <div className="col xl1">
                            {appointment.status === 3 ? <i className="material-icons red-text text-darken-2">warning</i> : null}
                        </div>
                        <div className="col xl3">
                            <span style={{
                                margin: "0 10px",
                                fontWeight: "bold"
                            }}>{Helper.formatName(appointment.patient_firstname, appointment.patient_lastname)}</span>
                        </div>
                        <div className="col xl3">
                            <span style={{margin: "0 10px"}}>{Helper.formatDate(appointment.date)}</span>
                        </div>
                        <div className="col xl3">
                            <span style={{margin: "0 10px"}}>{appointment.act}</span>
                        </div>
                    </div>
                    <div style={{
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        width: "100px",
                        marginLeft: "auto"
                    }}>

                        <FillPDFButton formData={getFormData(appointment)}
                                       pdfURI={blankPdf ? "/cerfa/Feuille_de_soin_vide.pdf" : "/cerfa/Feuille_de_soin.pdf"}
                        />
                    </div>
                </div>
            })}
        </div>
    }

    const renderPagination = () => {
        return <div className="valign-wrapper">
            <label className="valign-wrapper">
                <input id="blank_pdf-checkbox" type="checkbox" className="filled-in"
                       checked={blankPdf}
                       onChange={() => setBlankPdf(!blankPdf)}/>
                <span>Feuille de soin vide</span>
            </label>
            <a id="hoverable" className={`btn btn-flat btn-small ${page === 1 ? "disabled" : ""}`}
               onClick={() => setPage(page - 1)}><i
                className="material-icons">chevron_left</i></a>
            <span style={{margin: "0 10px"}}>{page}</span>
            <a id="hoverable" className="btn btn-flat btn-small" onClick={() => setPage(page + 1)}><i
                className="material-icons">chevron_right</i></a>
        </div>
    }

    return (
        <div style={{margin: "0 20px"}}>
            <div className="container">
                {renderFilter()}
                {renderOptions()}
                {renderList()}
            </div>
        </div>
    )
}

export default AppointmentList