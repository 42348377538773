import React from "react"
import {observer} from "mobx-react"
import moment from "moment"
import MesureStore from '../MesureStore'
import PrescriptionStore from "../../PrescriptionStore"


@observer
class PrintPrescription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      txt: ""
    };
  }
  componentDidMount(){
    moment.locale("fr")
  }
  render () {
    return (
      <React.Fragment>
        <div className="container white">
              <h3 className="center">MALADIES ET CHIRURGIE DES YEUX</h3>

                <p className="center">170 Rue du Général de Gaulle <br/>
                59110 La Madeleine <br/>
                TEL : 03.20.32.16.13 </p>

                <p className='center'> <b>Docteur Dinu STANESCU-SEGALL </b> <br/>
                Ophtalmologiste <br/>
                Ancien Attaché CHU Hôtel-Dieu de Paris <br/>
                Ancien Retina fellow Moorfields Eye Hospital, Londres <br/>
                Ancien Retina fellow, Kings College de Londres <br/>
                Ancien Retina fellow, CHU Montréal <br/>
                DU Angiographie et Rétine <br/>
                DU Inflamations et infection oculaire <br/>
                Praticien Attaché Rétine, UZ Leuven, KUL <br/>
                <b> Consultation sur rendez-vous
                59 1 19266 1 </b> <br/> </p>

              <p>Le {moment().format('LLLL')}, France</p>
              <p>{MesureStore.patient.prenom} {MesureStore.patient.nom}</p>
              <p style={{whiteSpace: "pre-line"}}>{PrescriptionStore.prescriptionText}</p>
              <img src="/imgs/sign-public-img-12345.png" width="273" height="133" alt=""/>
        </div>
      </React.Fragment>
    );
  }
}

export default PrintPrescription
