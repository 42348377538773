import {registerables, Chart} from "chart.js";
import React from "react";
import {Bar} from "react-chartjs-2";
import moment from "moment"

Chart.register(...registerables);

export default class ProfitChart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dateofday : moment(),
            data: {
                labels: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
                datasets: [
                    {
                        data: [12, 19, 3, 5, 2, 31, 2, 19, 3, 5, 2, 3],
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)',
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)'
                        ],
                    },
                ],
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true
                    }
                }
            }
        };
    }

    render(){
        return(
            <div>
                <div style={{ display: "flex", marginTop: 30}}>
                    <select className="browser-default" style={{ height: 30, width: 150}}>
                        <option>CA HT par orthoptiste</option>
                        <option>CA HT moyen par orthoptiste</option>
                        <option>CA HT moyen par examen</option>
                        <option>CA HT par type d'acte</option>
                        <option>Nombre d'examens par orthoptiste</option>
                        <option>Evolution CA par rapport au mois précédent</option>
                    </select>
                    <select className="browser-default" style={{ height: 30, width: 150, marginLeft: 40}}>
                        <option>Orthoptiste</option>
                    </select>
                    <select className="browser-default" style={{ height: 30, width: 150, marginLeft: 40}}>
                        <option>Actes</option>
                    </select>
                    <input style={{ height: 30, marginLeft: 40, width: 120}} type="date" id="start" name="trip-start" min="2021-01-01" max={this.state.dateofday.format("YYYY-MM-DD")}/>
                    <p style={{ marginLeft: 20, marginBottom: 10}}>au</p>
                    <input style={{ height: 30, marginLeft: 20, width: 120}} type="date" id="end" name="trip-end" min="2021-01-01" max={this.state.dateofday.format("YYYY-MM-DD")}/>
                    <select className="browser-default" style={{ height: 30, width: 150, marginLeft: 40}}>
                        <option>Mois</option>
                        <option>Janvier</option>
                        <option>Février</option>
                        <option>Mars</option>
                        <option>Avril</option>
                        <option>Mai</option>
                        <option>Juin</option>
                        <option>Juillet</option>
                        <option>Août</option>
                        <option>Septembre</option>
                        <option>Octobre</option>
                        <option>Novembre</option>
                        <option>Décembre</option>
                    </select>
                </div>
                <div>
                    <div style={{ marginRight: 50 }}>
                        <Bar data={this.state.data} options={this.state.options}></Bar>
                    </div>
                </div>
            </div>
        )
    }
}