import React, { Component } from 'react';
import BridgeStore from "../../BridgeStore";
import SetupStore from "./SetupStore"
import {observer} from "mobx-react"
import Form from "./Form"
import { HashRouter as Router, Route, Link  } from "react-router-dom"
import Strips from "../../../Shared/Strips"

@observer
export default class Usb extends Component {
  constructor(props) {
    super(props);
    this.stepSwitching = this.stepSwitching.bind(this)
    this.scan = this.scan.bind(this)
    this.state = {
      step: 0,
      initial: [],
    };
  }

  scan(){
    setTimeout(()=>{
      BridgeStore.socket.emit("usbPorts",{machine: BridgeStore.uid})
      setTimeout(()=>{
        this.setState({
          initial:BridgeStore.connected_usbs
        })
      },1500)
    },1000)
  }
  stepSwitching(){
    switch(this.state.step){
      case 0:
        return (
          <div>
          <Strips>white mini</Strips>

          <div className="container">
                      <div>
                <Link to="/" className="btn teal btn-small" style={{margin:30}}><i className="material-icons left">arrow_back</i>Retour</Link>
            </div>
          <div className="center">
            <h1 className="center"> Connectez la machine </h1>
            <p>Veuillez connectez la machine , n'oubliez pas de l'allumer </p>
            <a className="btn" onClick={(e)=>{
              e.preventDefault()
              this.setState({step: 2})
              this.scan()
            }}>Continuer</a>
          </div>
          </div>
          </div>
        )
      case 2:
        let value = null;
        for (let i = 0; i < this.state.initial.length; i++){
          if (!BridgeStore.machines.some(e=>e.hwid == this.state.initial[i].hwid)){
            value = this.state.initial[i]
          }
        }
        return (
            <div className="container">

              {value==null ?(
                <div className="center">
                  Chargement ...
                </div>)
                :(
                  <div>
                      <Strips>white mini</Strips>
                      <Link to="/" className="btn teal btn-small" style={{margin:30}}><i className="material-icons left">arrow_back</i>Retour</Link>

                      <Form usb={value} db_machines={this.props.db_machines}/>

                  </div>

                )}
         </div>
        )
      default:
        return(<p> Une erreur est survenue </p>)
    }
  }
  render() {
    return (
      <div>
        {this.stepSwitching()}
      </div>
    );
  }
}
