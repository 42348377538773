import React, {useEffect, useState} from "react"
import {HashRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import GeneralTabs from "./GeneralPanels/GeneralTabs";
import {Role} from "../../Shared/Enums/Scope";

export default function OptionRouter(props) {
    const [links_list, setLinksList] = useState([])
    const [routes_list, setRoutesList] = useState([])

    useEffect(() => {
        renderedTabByUserType()
    }, [])

    useEffect(() => {
        const nav = document.getElementById("options_sidenav")
        const instance = M.Sidenav.init(nav, {edge: "left", draggable: true})
        const collapsibles = document.querySelectorAll('.collapsible');
        const collapsible_instances = M.Collapsible.init(collapsibles, {});
        return () => {
            instance.destroy();
            collapsible_instances.forEach(collapsible_instance => {
                collapsible_instance.destroy();
            })
        }

    }, [links_list, routes_list]);

    const TABS_TYPE = {
        GENERAL: GeneralTabs
    }

    const Tabs_TYPE_FOR_USER = {
        "Orthoptiste": [TABS_TYPE.GENERAL],
        "Ophtalmologiste": [TABS_TYPE.GENERAL],
        "Secretaire": [TABS_TYPE.GENERAL],
        "Externe": [TABS_TYPE.GENERAL]
    }

    const URL_PREFIX = {
        "Orthoptiste": "/orthoptistes",
        "Secretaire": "/secretaires",
        "Ophtalmologiste": "/ophtalmologistes",
        "Externe": "/externes"
    }

    const renderedTabByUserType = () => {
        let rendered_links = []
        let rendered_routes = []

        Tabs_TYPE_FOR_USER[props.user_type].map((tab_type) => {
            const [routes, links] = new tab_type(props, URL_PREFIX[props.user_type]).useComponents()
            rendered_links.push(links)
            rendered_routes.push(routes)
        })

        setLinksList(rendered_links)
        setRoutesList(rendered_routes)
    }


    return (
        <div id="full-height">
            <Router>
                <div style={{display: "flex", height: "100%"}}>
                    <div style={{
                        width: "300px",
                        boxShadow: "2px 0 0 0 #E4E4E4",
                        backgroundColor: "#f6f6f6",
                        height: "100%",
                    }}>
                        <ul id="options_sidenav" className="sidenav sidenav-fixed white" style={{overflow: "hidden", zIndex: 1}}>
                            {links_list.map((link_category) => link_category)}
                        </ul>
                    </div>
                    <div style={{width: "100%", marginLeft: 75}}>
                        <Routes>
                            {routes_list.map((route_category) => route_category)}
                            <Route key="root_route" path="/" element={<Navigate to={props.user_type === Role.SECRETARY? "/security" :"/personal_infos"}/>}/>
                        </Routes>
                    </div>
                </div>
            </Router>
        </div>

    )
}
