import React, {useEffect, useRef, useState} from "react"
import {RequestState} from "../Shared/StateHelper";
import axios from "axios";
import Loader from "../Shared/Loader";
import Helper from "../Shared/Helper"
import Toastr from "../Shared/Toastr";
import {toast} from "react-toastify"
import Validator from "../Shared/Validator";
import Tooltip from "../Shared/Tootip";

export default function MainPage(props) {

    const modal_element = useRef(null)

    const [text_alerts, setTextAlerts] = useState(null)
    const [request_state, setRequestState] = useState(RequestState.LOADING)
    const [new_alert_title, setNewAlertTitle] = useState("")
    const [new_alert_text, setNewAlertText] = useState("")

    useEffect(() => {
        axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector("meta[name='csrf-token']").content
        getTextAlertList()
        M.Modal.init(modal_element.current)
        return () => {
            let modal = M.Modal.getInstance(modal_element.current)
            modal.destroy()
        }
    }, []);

    function getTextAlertList() {
        axios.get("/admin/text_alerts/")
            .then((response) => {
                setTextAlerts(response.data.text_alerts)
                setRequestState(RequestState.SUCCESS)
            })
            .catch((error) => setRequestState(RequestState.ERROR))
    }

    function isSubmitable() {
        return !(!new_alert_title || !new_alert_text);
    }

    function renderErrorTooltip() {
        let initial_text = "Un élément est manquant pour la création du nouveau risque:"
        if(!new_alert_title) initial_text += "\n- Veuillez saisir le titre de l'alerte."
        if(!new_alert_text) initial_text += "\n- Veuillez saisir le message lié a cette alerte."
        return initial_text
    }

    function saveNewTextAlert(){
        if (!isSubmitable()) return

        axios.put("/admin/text_alerts/",{
            title: new_alert_title,
            message: new_alert_text
        })
            .then((response)=>{
                setNewAlertText("")
                setNewAlertTitle("")
                setTextAlerts([...text_alerts, response.data.new_alert])
            })
            .catch((error)=>toast.error("Erreur lors de l'enregistrement de la pathologie."))
    }
    function deleteTextAlert(id){
        axios.delete(`/admin/text_alerts/${id}`)
            .then((response) => {
                toast.success(`Pathologie ${text_alerts.find((alert)=>alert.id === id).title} supprimée.`)
                setTextAlerts([...text_alerts].filter((text_alert) => text_alert.id !== id))
            })
            .catch((error) => toast.error(`Erreur lors de la suppression de ${text_alerts.find((alert)=>alert.id === id).title}.`))
    }

    function renderAlertsText(){
        if(request_state === RequestState.LOADING) return <div className="center"><Loader wide text="Chargement en cours..."/></div>
        if(request_state === RequestState.ERROR) return <div className="center">Une erreur est survenue.</div>
        if(request_state === RequestState.SUCCESS) return (
            <table className="striped bordered">
                <thead>
                <tr>
                    <th className="center">Titre</th>
                    <th className="center">Message</th>
                    <th></th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {text_alerts.map((text, index) => {
                    return (
                        <tr key={index}>
                            <td>{Helper.capitalizeFirstLetter(text.title)}</td>
                            <td>{Helper.capitalizeFirstLetter(text.message)}</td>
                            <td>
                                <a className="btn blue darken-2 z-depth-0" href={`/admin/text_alerts/edit/${text.id}`}>
                                    <i className="material-icons">edit</i>
                                </a>
                            </td>
                            <td>
                                <Validator
                                    onValidation={() => deleteTextAlert(text.id)}
                                    text={`Voulez-vous vraiment supprimer ${Helper.capitalizeFirstLetter(text.title)} ?`}
                                    detail="Cette action est irréversible."
                                    validationText="Supprimer" validationColor="red darken-2" validationIcon="delete"
                                    abortColor="black darken-2" abortIcon="cancel" abortText="Annuler"
                                    id={`delete_text_alert_${text.id}`}>
                                    <a className="btn red darken-2 z-depth-0">
                                        <i className="material-icons">delete</i>
                                    </a>
                                </Validator>

                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        )
    }

    return(
        <div>
            <div className="container">
                <h1 className="center">Gestion des risques et alertes</h1>
                <a className="btn blue darken-2 z-depth-0 modal-trigger" data-target="create_pathology_modal">Nouvelle
                    pathologie</a>
                <div style={{marginBottom: "2vh"}}>
                    {renderAlertsText()}
                </div>
                <div id="create_pathology_modal" className="modal" ref={modal_element}>
                    <div className="modal-content">
                        <h1 className="center">Création d'un nouveau type de risque</h1>
                        <div className="row" style={{marginBottom: "2vh"}}>
                            <div className="input-field col s12 outlined">
                                <h6>Titre de la pathologie</h6>
                                <input
                                    id="title"
                                    type="text"
                                    placeholder="Entrez le titre de la nouvelle pathologie"
                                    value={new_alert_title}
                                    onChange={(e)=>setNewAlertTitle(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="input-field col s12 outlined">
                                <h6 style={{marginTop:"0"}}>Message d'alerte</h6>
                                <textarea id="alert_message"
                                          style={{minHeight: "150px", paddingTop:"1.5vh", resize: "none"}}
                                          placeholder="Entrez le message lié a cette pathologie."
                                          value={new_alert_text}
                                          onChange={(e)=>setNewAlertText(e.target.value)}
                                />
                            </div>
                        </div>
                        {isSubmitable()?
                            <div className="modal-footer">
                                <a className={`modal-close btn blue darken-2 z-depth-0 ${isSubmitable()? "" : "disabled"}`} style={{marginRight: "1vw"}}
                            onClick={saveNewTextAlert}>Enregistrer la pathologie</a>
                            <a className="modal-close btn red darken-2 z-depth-0">Annuler</a>
                            </div>
                            :
                            <Tooltip text={renderErrorTooltip()} id="error_new_text_alert">
                                <div className="modal-footer">
                                    <a className={`modal-close btn blue darken-2 z-depth-0 ${isSubmitable()? "" : "disabled"}`} style={{marginRight: "1vw"}}
                                       onClick={saveNewTextAlert}>Enregistrer la pathologie</a>
                                    <a className="modal-close btn red darken-2 z-depth-0">Annuler</a>
                                </div>
                            </Tooltip>
                        }
                    </div>
                </div>
            </div>
            <Toastr/>
        </div>
    )
}