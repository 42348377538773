import React, {Component} from "react";
import {isLoading, loading, RequestState} from "../Shared/StateHelper";
import StateButton from "../Shared/StateButton";
import {Role} from "../Shared/Enums/Scope";
import ScopedElement from "../Shared/ScopedElement";
import socket from "../Shared/socket";
import {displayMedocError} from "../Shared/Enums/ErrorCodes";

const NUMBER_OF_TRIES = 0;
export default class CVButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cv_state: RequestState.IDLE,
            cv_data: null,
            cv_meta: {},
            retries: 0
        }
        socket.on("cvInputResponse", (resp) => {
            if (resp.status === "success") {
                this.setState({cv_data: resp.data, cv_meta: resp.meta, cv_state: RequestState.SUCCESS})
                M.Modal.getInstance(document.getElementById('carte_vitale_modal')).open();

            } else {
                if (this.state.retries < NUMBER_OF_TRIES) {
                    this.setState({retries: this.state.retries + 1}, () => this.sendCVRequest(this.props.bridge_uid))
                } else {
                    console.debug("Error on CV retrieving with error: ", displayMedocError(resp.errorCode))
                    this.setState({cv_state: RequestState.ERROR})
                }
            }

        })
    }

    componentDidMount() {
        const elem = document.getElementById('carte_vitale_modal');
        M.Modal.init(elem, {onCloseStart: () => this.setState({cv_state: RequestState.IDLE})});
    }

    componentWillUnmount() {
        M.Modal.getInstance(document.getElementById('carte_vitale_modal')).destroy();
        socket.off("cvInputResponse")
    }

    openSocket = (uid) => {
        socket.emit("machineID", {machine: uid});
    }

    sendCVRequest = (uid) => {
        this.props.onClick()
        if (isLoading(this.state.cv_state) && this.state.retries === 0) return
        this.openSocket(uid);

        if (uid !== null) {
            socket.emit("cvInputRequest", {machine: uid});
            this.setState({cv_state: loading(this.state.cv_state)})
            setTimeout(() => {
                if (isLoading(this.state.cv_state)) this.setState({cv_state: RequestState.ERROR})
            }, 10*1000)
        } else {
            this.setState({cv_state: RequestState.ERROR})
        }
    }

    renderModal = () => {
        if (!this.state.cv_data && !this.state.cv_meta) return null
        return this.props.modalRender(this.state.cv_data, this.state.cv_meta)
    }

    render() {
        return (
            <div>
                <ScopedElement scope={[Role.CV_USER]}>
                    <StateButton text="Lire carte Vitale" icon="credit_card"
                                 side="left" color="blue darken-2"
                                 state={this.state.cv_state}
                                 onClick={() => this.setState({retries: 0}, () => this.sendCVRequest(this.props.bridge_uid))}
                    />
                </ScopedElement>
                <div id="carte_vitale_modal" className="modal" style={{width: "90%", borderRadius: "0"}}>
                    <div className="modal-content">
                        {this.renderModal()}
                    </div>
                    <div className="modal-footer">
                        <a className="modal-close btn red z-depth-0">Annuler</a>
                    </div>
                </div>
            </div>
        )
    }

}

CVButton.defaultProps = {
    modalRender: () => console.log("No modal render function provided"),
    onClick: () =>  null
}