import React from "react"
import {observer} from "mobx-react"
import MesureStore from "../MesureStore"
import {isLocked, LockingElement} from "../../AppointmentValidation";

const AvTrouStenopeique = observer((props) => {
    const handleChangeOG = (e) => {
        MesureStore.set_og_av_trou_stenopeique(e.target.value)
    }
    const handleChangeOD = (e) => {
        MesureStore.set_od_av_trou_stenopeique(e.target.value)
    }
    return (
        <div className="row">
            <div className="col s5">
                <div className="input-field outlined">
                    <input type="text" className="form-control" id="av_trou_stenopeique_od" value={MesureStore.od_av_trou_stenopeique} onChange={handleChangeOD} />
                    <label className={isLocked(LockingElement.RIGHT_STENOPEIC_HOLE) ? "red-text" : ""}>
                        AV avec trou sténopeïque OD
                    </label>
                </div>
            </div>
            <div className="col s2">

            </div>
            <div className="col s5">
                <div className="input-field outlined">
                    <input type="text" className="form-control" id="av_trou_stenopeique_og" value={MesureStore.og_av_trou_stenopeique} onChange={handleChangeOG} />
                    <label className={isLocked(LockingElement.LEFT_STENOPEIC_HOLE) ? "red-text" : ""}>
                        AV avec trou sténopeïque OG
                    </label>
                </div>
            </div>
        </div>
    );
})

export default AvTrouStenopeique