import React, {useEffect, useState} from "react";
import axios from "axios";
import {Link, useParams} from "react-router-dom";
import Modal from '@material-ui/core/Modal';
import {toast} from "react-toastify";

export default function TeamSecretaries(props) {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    const [secretaries, setSecretaries] = useState([]);
    const [open, setOpen] = useState(false);
    const [secretaryToAdd, setSecretaryToAdd] = useState({});

    const loadSecretaries = () => {
        axios.get("/admin/console/team/secretaries").then((response) => {
            setSecretaries(response.data);
        });
    }
    useEffect(() => {
        loadSecretaries();
    }, []);
    const handleOpen = () => {

        setOpen(true);

    }
    const handleClose = () => {
        setOpen(false);
    }
    const editForm = (e) => {
        let formElement = e.target.id;
        let value = e.target.value;
        setSecretaryToAdd({...secretaryToAdd, [formElement]: value});
    }
    const handleSave = () => {
        axios.post("/admin/console/team/secretary", {data: secretaryToAdd}).then((response) => {
            toast.success("Secretaire ajoutée avec succès");
            loadSecretaries(); // plus simple que de faire un concat en backend avec les infos ophtalmos
        }).catch((e) => {
            toast.error(e.response.data.errors.join(" et "));
        })
    }
    const handleDelete = (id) => {
        axios.delete("/admin/console/team/secretary/" + id).then((response) => {
            toast.success("Secretaire supprimée avec succès");
            loadSecretaries();
        }).catch((e) => {
            toast.error(e.response.data.errors.join(" et "));
        })
    }


    return (
        <div>
            <h1 className="center">Gestion des secrétaires</h1>
            <p style={{marginLeft: "15px"}}>
                <a className={"btn  waves-effect waves-light green"} onClick={(e) => {
                    e.preventDefault();
                    handleOpen();
                } }> <i className={"material-icons left"}>add</i>  secrétaire</a>
            </p>
            <table className="striped centered responsive-table">
                <thead>
                <tr>
                    <th> id</th>
                    <th>Email</th>
                    <th>Ophtalmologue</th>
                    <th><i className="material-icons left">delete</i></th>
                </tr>
                </thead>
                <tbody>
                    {secretaries.map((secretary) => {
                        return  (
                        <tr key={secretary.id}>
                            <td>{secretary.id}</td>
                            <td>{secretary.email}</td>
                            <td>{secretary.ophtalmologiste.prenom} {secretary.ophtalmologiste.nom}</td>
                            <td><a className={"red-text"} href="supprimer_secretaire" onClick={(e) => {
                                e.preventDefault();
                                handleDelete(secretary.id);
                            }}> <i className={"material-icons left"}>delete</i></a></td>
                        </tr>
                    )})}
                </tbody>
            </table>
            <Modal open={open}
                   onClose={handleClose}
                    style={{
                    border: 'none',
                }}>
                <div className="modal-content"
                     style={{
                         position: 'absolute',
                         top: '50%',
                         left: '50%',
                         transform: 'translate(-50%, -50%)',
                         width: 700,
                         background: "white",
                         border: 'none',
                         padding: "20px"

                     }}>
                    <h4>Ajouter un(e) secrétaire</h4>
                    <div className="row">
                        <div className="col s12">
                            <div className="input-field">
                                <input id="email" type="email" className="validate" onChange={editForm}/>
                                <label htmlFor="email">Adresse mail</label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12">
                            <div className="input-field">
                                <input id="password" type="text" className="validate" onChange={editForm} autoComplete="off"/>
                                <label htmlFor="password">Mot de passe</label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12">
                            <div className="input-field">
                                <select id="ophtalmologiste_id" className={"browser-default"} onChange={editForm}
                                value={secretaryToAdd.ophtalmologiste_id || ""}
                                >
                                    <option value="" disabled >Choisissez un ophtalmologiste</option>
                                    {props.ophtalmologists.map((ophtalmologist) => {
                                        return (
                                            <option key={ophtalmologist.id} value={ophtalmologist.id}>{ophtalmologist.prenom} {ophtalmologist.nom}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12">
                            <a className="waves-effect waves-light btn" onClick={handleSave}>Ajouter</a>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}