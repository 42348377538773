import React, {Component} from "react";
export default class DatePicker extends Component {
    constructor(props) {
        super(props);
        this.onClose = this.onClose.bind(this)
        this.onSelect = this.onSelect.bind(this)
        this.state = {
            date: undefined
        }
    }

    componentDidMount() {
        var elems = document.getElementById(`datepicker-${this.props.id}`);
        M.Datepicker.init(elems, {
            onClose: this.onClose,
            onSelect: this.onSelect,
            format: "dd/mm/yyyy",
            showClearBtn: true,
            i18n: {
                cancel: "Annuler",
                clear: "Effacer",
                done: "Valider",
                months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
                monthsShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Jun', 'Jul', 'Aoû', 'Sept', 'Oct', 'Nov', 'Déc'],
                weekDays: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
                weekdaysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
                weekdaysAbbrev: ['D', 'L', 'M', 'M', 'J', 'V', 'S']
            }
        })
    }

    componentWillUnmount() {
        M.Datepicker.getInstance(document.getElementById(`datepicker-${this.props.id}`)).destroy()
    }

    onClose() {
        const elems = document.getElementById(`datepicker-${this.props.id}`);
        this.props.onChange(M.Datepicker.getInstance(elems).toString() === "" ? undefined : this.state.date)
    }


    onSelect(v) {
        this.setState({date: v})
    }

    render() {
        return (
            <div style={{margin: 0, display: "flex", alignItems: "center", gap: 10}}>
                <div><span style={{margin: 0, whiteSpace: "nowrap"}}>{this.props.txt}</span></div>
                <div><input type="text" className={"datepicker"}
                                            id={`datepicker-${this.props.id}`}
                                            style={{width: "100px"}}/></div>
            </div>
        )
    }
}


DatePicker.defaultProps = {
    id: "ZBRA",
    txt: "",
    date: undefined,
    onChange: () => null
}