export const ALERT_COLORS = {
    "rien": "#2ecc71",
    "bénin": "#3498db",
    "moyen": "#f39c12",
    "grave": "#e74c3c",
    "pas de rpd": "#1abc9c",
    "redirection": "#2c3e50",
    "mail": "#9b59b6",
    "mail-sms": "#8e44ad",
    "": "#95a5a6"
}