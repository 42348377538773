import React from 'react';
import ScheduledAppointments from "./ScheduledAppointments";

function ScheduledAppointmentsConsole() {


    return (
        <div id="full-height" style={{display: "flex", flexDirection: "column", overflowY: "scroll"}}>
            <h3 className="valign-wrapper" style={{margin: 20}}>
                <i className="material-icons left">calendar_month</i>Prochains examens
            </h3>
            <div className="divider"/>
            <div style={{marginLeft: 2}}>
                <ScheduledAppointments/>
            </div>
        </div>
    )
}

export default ScheduledAppointmentsConsole;