import React from "react";
import CVStore, {CVState} from "./CVStore";
import {Observer} from "mobx-react";
import ADRIRights from "./ADRIRights";
import InputText from "../Shared/UiKit/InputText";
import Helper from "../Shared/Helper";
import {toast} from "react-toastify";

const DisplayPatient = (props) => {
    const [editSsn, setEditSsn] = React.useState(false);
    const [ssn, setSsn] = React.useState(CVStore.patient.num_carte_vitale || "");


    const edit_fse = () => {
        CVStore.fse.patient_firstname = CVStore.selectedPatient.prenom
        CVStore.fse.patient_lastname = CVStore.selectedPatient.nom
        CVStore.fse.patient_ssn = CVStore.selectedPatient.num_carte_vitale
        CVStore.setState(CVState.EDIT_FSE)
    }

    const handle_ssn_edit_click = () => {
        if (editSsn) {
            if (!Helper.checkSsn(ssn)) {
                toast.error("Le numéro de sécurité sociale doit être composé de 11 chiffres et d'une clé de 2 chiffres")
                return
            }
            CVStore.patient.num_carte_vitale = ssn
            CVStore.fse.patient_ssn = ssn
            setEditSsn(false)
        } else setEditSsn(true)
    }

    const fontSize = "1.2rem"
    return (
        <Observer>
            {() => <div style={{display: "flex", flexDirection: "column", height: "100%"}}>
                <div style={{paddingTop: "4vh"}} className="row">
                    <div className="col xl6 s12">
                        <div className="row" style={{margin: "0 2vw"}}>
                            <div className="col s12">
                                <label htmlFor="lastname">Nom</label>
                                <InputText id="lastname" width="100%" fontSize={fontSize}
                                           value={CVStore.patient.nom} disabled/>
                            </div>
                            <div className="col s12">
                                <label htmlFor="firstname">Prénom</label>
                                <InputText id="firstname" width="100%" fontSize={fontSize}
                                           value={CVStore.patient.prenom} disabled/>
                            </div>
                            <div className="col s12">
                                <label htmlFor="ssn">Num. Securité Sociale</label>
                                <div className="valign-wrapper">
                                    <InputText id="ssn" width="100%" fontSize={fontSize}
                                               style={{borderRadius: "2px 0 0 2px", height: "36px"}}
                                               onChange={(e) => setSsn(e.target.value)}
                                               value={ssn || ""} disabled={!editSsn}/>
                                    <a className={`btn-small ${editSsn ? "green" : "blue"} darken-2 z-depth-0`}
                                       style={{borderRadius: "0 2px 2px 0", margin: 0, height: 36, display: "flex", alignItems: "center"}}
                                       onClick={handle_ssn_edit_click}>
                                        <i className="material-icons">
                                            {editSsn ? "save" : "edit"}
                                        </i>
                                    </a>
                                </div>
                                <h6 className={`red-text italic ${(editSsn && !Helper.checkSsn(ssn)) || "hide"}`}>Le
                                    numéro de sécurité sociale doit être composé de 11 chiffres et d'une clé de 2
                                    chiffres</h6>
                                <h6 className={`red-text italic ${(editSsn && !Helper.checkSsnKey(ssn)) || "hide"}`}>Le
                                    numéro de sécurité sociale est invalide</h6>
                            </div>
                            <div className="col s12">
                                <label htmlFor="code">Code</label>
                                {
                                    !CVStore.patient.regime_code && !CVStore.patient.fund_code && !CVStore.patient.centre_code ?
                                        <InputText id="firstname" width="100%" fontSize={fontSize}
                                                   value={""} disabled/>
                                        :
                                        <InputText id="firstname" width="100%" fontSize={fontSize}
                                                   value={`${CVStore.patient.regime_code} ${CVStore.patient.fund_code} ${CVStore.patient.centre_code}`}
                                                   disabled/>}
                            </div>
                        </div>
                    </div>
                    <div className="col xl6 s12">
                        <ADRIRights uid={props.uid}/>
                    </div>
                </div>
                <a className={`btn ${CVStore.hasCV ? "" : "disabled"} z-depth-0 blue darken-2`}
                   style={{alignSelf: "flex-end", marginTop: "auto"}}
                   onClick={() => edit_fse()}
                ><i
                    className="material-icons right">chevron_right</i>Edition fiche de soin</a>
            </div>}
        </Observer>
    );
}

export default DisplayPatient