import React, {Component} from 'react';
import moment from 'moment'
import Helper from "../../Helper";
import InfiniteScroll from "react-infinite-scroll-component";

export default class SecretariatPatients extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.patients.length === 0) {
            return null
        } else {
            return (
                <div className="container center" style={{width: "90%", padding: 20}}>
                    <InfiniteScroll
                        next={this.props.search}
                        hasMore={true}
                        dataLength={this.props.patients.length}>
                        <table>
                            <tbody>
                            {this.props.patients.map((patient) => (
                                <tr key={patient.id}>
                                    <td>{Helper.formatName(patient.first_name, patient.last_name)} </td>
                                    <td>{patient.email}</td>
                                    <td>{Helper.formatPhoneNumber(patient.phone)} </td>
                                    <td style={{textAlign: "center"}}>{moment(patient.birthday).format("DD-MM-YYYY")}</td>
                                    <td><a className="btn blue darken-2 z-depth-0" href={`/secretariat/prescription/new/${patient.id}`}>
                                        <i className="material-icons left">description</i>Prescrire</a></td>
                                    <td>
                                        <a className="btn blue darken-2 z-depth-0" href={`/patients/${patient.id}`}>
                                            <i className="material-icons left">account_circle</i>Fiche patient</a>
                                    </td>
                                    <td>
                                        <a className="btn blue darken-2 z-depth-0" href={`/secretariat/email?patient=${patient.id}`}>
                                        <i className="material-icons left">mail</i>Envoyer mail</a>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </InfiniteScroll>

                </div>

            );
        }
    }
}

