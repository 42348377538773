import React, {useEffect, useState} from "react"
import Error from "../../Shared/Error";
import axios from "axios";
import {toast} from "react-toastify";

function RnmForm({hash}) {
    const [formData, setFormData] = useState({})

    useEffect(() => {
    }, [formData])

    const validateFormData = () => {
        if (!formData.name) {
            toast.error("Aucun nom n'a été fourni")
            return false
        }
        if (!formData.address) {
            toast.error("Aucune adresse n'a été fournie")
            return false
        }
        if (formData.major === undefined) {
            toast.error("Merci d'indiquer si le patient est majeur ou mineur")
            return false
        }
        if (formData.major === false && !formData.child_name) {
            toast.error("Le nom de la personne mineure n'a pas été fourni")
            return false
        }
        if (formData.accepted === undefined) {
            toast.error("Merci d'accepter ou non le bilan ophtalmologique")
            return false
        }
        if (!formData.city) {
            toast.error("Aucune ville n'a été fournie")
            return false
        }
        return true
    }

    const submit = () => {
        if (!validateFormData()) return
        axios.put('rnm/send', {data: formData, key: hash}).then(res => {
            toast.success("Formulaire envoyé avec succès")
        }).catch(err => {
            toast.error(err.response.data.errorPrompt)
        })
    }

    if (!hash) return <div style={{margin: "10vh"}}>
        <Error errorText="Le lien est invalide, contactez votre orthoptiste pour plus de détails"/>
    </div>

    return <div className="container" style={{display: "flex", flexDirection: "column", gap: 20, padding: "0 30px"}}>
        <h2 className="center">Information individuelle du CONSULTANT ou du responsable de
            l'enfant et recueil de son consentement</h2>
        <h5 className="grey-text text-darken-2" style={{margin: "0 5vw"}}>Madame, Monsieur,<br/><br/>

            Lors de votre prise de rendez-vous pour le renouvellement/adaptation de vos corrections optiques, il vous a
            été proposé un rendez-vous avec un orthoptiste.<br/><br/>

            Ces protocoles de coopération comportent à titre dérogatoire des transferts d’actes entre professionnels de
            santé. Ils sont autorisés par le Code de la santé publique (articles L 4011-1, L 4011-2, L 4011-3) qui a
            prévu leur évaluation et leur autorisation par les autorités de santé compétentes.<br/><br/>

            Dans le cas présent, le protocole de coopération concerne la réalisation d’un bilan d’ophtalmologie avec la
            participation d’un orthoptiste sans prescription préalable et en l’absence de l’ophtalmologiste, suivi de
            l’envoi dans les 8 jours de l’ordonnance de renouvellement de verres correcteurs faite par
            l’ophtalmologiste.<br/><br/>

            Ce protocole de coopération a reçu un avis favorable de la Haute Autorité de santé et est autorisé par votre
            Agence Régionale de Santé.<br/>

            Les examens réalisés par l’orthoptiste comprendront :<br/>

            – un interrogatoire pour éliminer les éventuelles contre-indications à l’application du protocole,<br/>

            – une mesure de la réfraction et de l’acuité visuelle,<br/>

            – un bilan des déséquilibres oculomoteurs,<br/>

            – une mesure du tonus oculaire,<br/>

            – une prise de rétinographies avec un rétinographe non mydriatique (RNM) sans instillation de collyre
            mydriatique.<br/><br/>


            Les résultats de ce bilan seront analysés via un dispositif de télémédecine par un
            ophtalmologiste.<br/><br/>

            A l’issu de son analyse :<br/>

            – soit l’ophtalmologiste vous adresse l’ordonnance de renouvellement de verres correcteurs dans les 8 jours
            suivant la réalisation du bilan ;<br/>

            – soit le bilan doit être complété et il vous propose un rendez-vous dans un délai adapté à votre
            problème.<br/><br/>

            En cas d’interrogation, il est toujours possible de joindre un ophtalmologiste.<br/><br/>

            Vous avez la possibilité de refuser la réalisation de ces examens par l’orthoptiste, et de demander une
            réorientation vers un ophtalmologiste qui vous proposera une consultation dès que possible.<br/><br/>

            Un refus de votre part ne changera pas vos relations avec l’ophtalmologiste ni avec l’orthoptiste.
        </h5>
        <div style={{padding: "0 10vw", display: "flex", flexDirection: "column", gap: 20}}>
            <div>
                <h6>(*) Je soussigné(e) :</h6>
                <div className="input-field outlined">
                    <input type="text" placeholder="Nom Prénom" value={formData.name || ""}
                           onChange={(e) => setFormData({...formData, name: e.target.value})}/>
                </div>
            </div>
            <div>
                <h6>(*) Résidant au :</h6>
                <div className="input-field outlined">
                    <input type="text" placeholder="Votre adresse" value={formData.address || ""}
                           onChange={(e) => setFormData({...formData, address: e.target.value})}/>
                </div>
            </div>
            <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                <h6>(*) Ayant la qualité de : </h6>
                <label>
                    <input name="major" type="radio" checked={formData.major === undefined ? false : formData.major}
                           onChange={(e) => setFormData({...formData, major: e.target.checked})}/>
                    <span>Patient majeur</span>
                </label>
                <label>
                    <input name="minor" type="radio" checked={formData.major === undefined ? false : !formData.major}
                           onChange={(e) => setFormData({...formData, major: !e.target.checked})}/>
                    <span>Représentant légal du patient mineur</span>
                </label>
            </div>
            <div className={formData.major === false || "hide"}>
                <h6>(*) Nom de la personne mineure</h6>
                <div className="input-field outlined">
                    <input type="text" placeholder="Nom Prénom" value={formData.child_name || ""}
                           onChange={(e) => setFormData({...formData, child_name: e.target.value})}/>
                </div>
            </div>
            <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                <h6>(*) Déclare avoir reçu une information claire sur ce protocole.</h6>
                <label>
                    <input name="accept" type="radio"
                           checked={formData.accepted === undefined ? false : formData.accepted}
                           onChange={(e) => setFormData({...formData, accepted: e.target.checked})}/>
                    <span>J’autorise les orthoptistes participant au protocole relatif au renouvellement/adaptation des
                        verres correcteurs, à réaliser le bilan ophtalmologique sans prescription préalable et en
                        l’absence de l’ophtalmologiste.</span>
                </label>
                <label>
                    <input name="deny" type="radio"
                           checked={formData.accepted === undefined ? false : !formData.accepted}
                           onChange={(e) => setFormData({...formData, accepted: !e.target.checked})}/>
                    <span>Je n’autorise pas les orthoptistes participant au protocole relatif au renouvellement/adaptation
                        des verres correcteurs, à réaliser le bilan ophtalmologique sans prescription préalable et en
                        l’absence de l’ophtalmologiste.</span>
                </label>
            </div>
            <div>
                <h6>(*) Fait à :</h6>
                <div className="input-field outlined">
                    <input type="text" placeholder="Ville" value={formData.city || ""}
                           onChange={(e) => setFormData({...formData, city: e.target.value})}/>
                </div>
            </div>
            <a className="btn blue darken-2 z-depth-0" onClick={submit}
               style={{marginBottom: 50}}>Signer et envoyer</a>
        </div>
    </div>
}

export default RnmForm