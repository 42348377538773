import React, {useEffect} from 'react';
import axios from "axios";
import {Application, LogTypes, TimeRange} from "../../../../Shared/Enums/Enums";
import {isLoading, loading, RequestState} from "../../../../Shared/StateHelper";
import Loader from "../../../../Shared/Loader";
import BarChart from "../../../Statistiques/BarChart";
import Helper from "../../../../Shared/Helper";
import Error from "../../../../Shared/Error";

function ApplicationMonitoring() {
    const [applicationName, setApplicationName] = React.useState(Application.EXPERT_VISION);
    const [logType, setLogType] = React.useState(LogTypes.OPHTALMOLOGIST_ACTION.value);
    const [displayedLogType, setDisplayedLogType] = React.useState(null);
    const [data, setData] = React.useState(null);
    const [requestState, setRequestState] = React.useState(RequestState.IDLE);
    const startDateRef = React.useRef(null);
    const endDateRef = React.useRef(null);

    useEffect(() => {
        startDateRef.current.value = TimeRange.MONTH.start();
        endDateRef.current.value = TimeRange.MONTH.end();
        getApplicationData();
    }, [])

    const getApplicationData = () => {
        const startDate = startDateRef.current.value;
        const endDate = endDateRef.current.value;
        setRequestState(loading(requestState))
        setDisplayedLogType(logType)
        axios.get(`/admin/console/logs/app/${applicationName}`, {
            params: {
                start_date: startDate || null,
                end_date: endDate || null,
                log_type: logType
            }
        }).then((response) => {
            setData(response.data);
            setRequestState(RequestState.SUCCESS)
        }).catch((error) => {
            setRequestState(RequestState.ERROR)
        });
    }

    const setTime = (range) => {
        startDateRef.current.value = range.start();
        endDateRef.current.value = range.end();
    }

    const renderFilter = () => {
        return <div className="valign-wrapper" style={{gap: 10, flexWrap: "wrap"}}>
            {/*APP NAME*/}
            <div>
                <select value={applicationName ?? ""} className="browser-default"
                        onChange={(e) => setApplicationName(e.target.value)}>
                    {Object.values(Application).map((app) => {
                        return <option key={app} value={app}>{app}</option>
                    })}
                </select>
            </div>
            <div>
                <select value={logType ?? ""} className="browser-default"
                        onChange={(e) => setLogType(e.target.value)}>
                    {Object.values(LogTypes).map((type) => {
                        return <option key={type.value} value={type.value}>{type.name}</option>
                    })}
                </select>
            </div>
            <div>
                <select className="browser-default" defaultValue=""
                        onChange={(e) => setTime(TimeRange[e.target.value])}>
                    <option value={""} disabled>Sélectionner une periode</option>
                    {Object.values(TimeRange).map((range, index) => {
                        return <option key={`time_range_${index}`} value={range.value}>{range.name}</option>
                    })}
                </select>
            </div>
            {/*START DATE*/}
            <h6>Du</h6>
            <div>
                <input type="date" ref={startDateRef}/>
            </div>
            {/*END DATE*/}
            <h6>au</h6>
            <div>
                <input type="date" ref={endDateRef}/>
            </div>
            <a className="btn blue darken-2 z-depth-0" onClick={getApplicationData}>Valider</a>
        </div>
    }

    const renderData = () => {
        if (requestState === RequestState.IDLE) return null;
        if (isLoading(requestState)) return <div className="center-block" style={{marginBlock: 50}}>
            <Loader text="Chargement des données" style={{width: "fit-content"}}
                    description="Cette tâche peut être un peu longue"
            />
        </div>
        if (requestState === RequestState.ERROR) return <div className="center-block" style={{marginBlock: 50}}>
            <Error errorText="Impossible de charger les données"/>
        </div>

        const labels = Object.keys(data.count_by_day).map((date) => Helper.formatDate(date))
        const datasets = [{
            label: "Nombre d'évènements",
            backgroundColor: '#1976d2',
            data: Object.values(data.count_by_day)
        }]
        const startDate = Helper.formatDate(startDateRef.current.value || Object.keys(data.count_by_day)[0])
        const endDate = Helper.formatDate(endDateRef.current.value || Object.keys(data.count_by_day).reverse()[0])

        const _displayedLogType = Object.values(LogTypes).find(type => type.value === displayedLogType)
        return <div className="row">
            <div className="col s12 l3" style={{display: "flex", flexDirection: "column", margin: 20, gap: 10}}>
                <div className="valign-wrapper" style={{gap: 20}}>
                    <h4 style={{margin: 0}}>{_displayedLogType.name}</h4>
                    <i className="material-icons tiny grey-text darken-2"
                       title={`Ces logs ont commencé à être récupérés le ${_displayedLogType.start_date}`}>
                        help
                    </i>
                </div>
                <label>Du {startDate} au {endDate}</label>
                <div className="card blue darken-2 z-depth-0 white-text"
                     style={{padding: 20, display: "flex", flexDirection: "column", gap: 20}}>
                    <h3 style={{margin: 0}}>Nombre d'évènements</h3>
                    <h1 className="center" style={{margin: 0}}>{data.total_count}</h1>
                </div>
            </div>
            <div className="col s12 l9">
                <div className="card blue lighten-5 z-depth-0" style={{height: 500, width: "100%"}}>
                    <BarChart data={{labels: labels, datasets: datasets}} withLegend={false}
                              legend={`${_displayedLogType.name} chez ${applicationName} par jours`}
                    />
                </div>
            </div>
        </div>
    }


    return (
        <div style={{display: "flex", flexDirection: "column", gap: 20}}>
            {renderFilter()}
            {renderData()}
        </div>
    );
}

export default ApplicationMonitoring;