import React, {useEffect} from "react";

//TODO : j'viens de me rendre compte que j'ai fumé en faisant ça, suffisait de wrap dans une div tooltipée
function Tooltip(props) {
    useEffect(() => {
        const elems = document.getElementById(props.id);
        M.Tooltip.init(elems, {enterDelay: 500});
        return () => {
            const tooltip = document.getElementById(props.id)
            if(tooltip) {
                const inst = M.Tooltip.getInstance(tooltip)
                if(inst) inst.destroy()
            }
        }
    }, [props.text])

    if(!props.text) return props.children

    return React.cloneElement(props.children, Object.assign({}, props.children.props, {
        id: props.id,
        "data-position": props.position,
        "data-tooltip": props.text,
        style: Object.assign({}, props.children.props.style, {pointerEvents: props.disabled ? "none" : ""}),
    }));
}

Tooltip.defaultProps = {
    id: "",
    position: "top",
    text: "",
    disabled: false
}

export default Tooltip