import React, {useEffect} from 'react';
import axios from "axios";
import DatePicker from "../../../Shared/DatePicker";
import Helper from "../../../Shared/Helper";
import {downloadFile} from "../../../Shared/download";
import StateButton from "../../../Shared/StateButton";
import {loading, RequestState} from "../../../Shared/StateHelper";

function BillingConsole({orthoptists}) {
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [authorizeButton, setAuthorizeButton] = React.useState(false);
    const [historyState, setHistoryState] = React.useState(RequestState.IDLE);

    const csrfToken = document.querySelector('[name=csrf-token]').content;
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    useEffect(() => {
        const bills = document.getElementById('orthoptists_select_bills');
        const resync = document.getElementById('orthoptists_select_resync');
        const invoices = document.getElementById('orthoptists_select_invoices');
        const bill_instance = M.FormSelect.init(bills, {});
        const invoices_instance = M.FormSelect.init(invoices, {});
        const resync_instance = M.FormSelect.init(resync, {});


        return () => {
            // bill_instance?.destroy();
            // invoices_instance?.destroy();
        }
    }, [orthoptists])

    const getBills = () => {
        const selectedOrthoptists = M.FormSelect.getInstance(document.getElementById('orthoptists_select_bills')).getSelectedValues();
        setHistoryState(loading(historyState))
        axios.get("/admin/console/bills", {
            params: {
                start_date: Helper.formatDate(startDate, 'YYYY-MM-DD'),
                end_date: Helper.formatDate(endDate, 'YYYY-MM-DD'),
                orthoptist_ids: selectedOrthoptists
            }
        }).then(response => {
            downloadFile(response.data, 'historique_examens.csv')
            setHistoryState(RequestState.SUCCESS)
        }).catch(_ => {
            setHistoryState(RequestState.ERROR)
        })
    }
    const getInvoices = () => {
        const selectedOrthoptists = M.FormSelect.getInstance(document.getElementById('orthoptists_select_invoices')).getSelectedValues();

        axios.get("/admin/console/invoices", {
            params: {
                orthoptist_ids: selectedOrthoptists
            }
        }).then(response => downloadFile(response.data, 'prochaine_facture.csv'));
    }

    const renderOrthoptistsSelect = (id) => {
        return <div className="input-field col s12">
            <select id={`orthoptists_select_${id}`} multiple>
                <option value="" disabled>Tous les orthoptistes</option>
                {orthoptists.map(orthoptist => {
                    return <option id='listado' key={orthoptist.id}
                                   value={orthoptist.id}>{Helper.formatName(orthoptist.prenom, orthoptist.nom)}</option>
                })}
            </select>
        </div>
    }
    const submitResync = () => {
        setAuthorizeButton(false);
        const selectedOrthoptists = M.FormSelect.getInstance(document.getElementById('orthoptists_select_resync')).getSelectedValues();
        axios.post("/admin/console/bills/resync", {
            start_date: Helper.formatDate(startDate, 'YYYY-MM-DD'),
            end_date: Helper.formatDate(endDate, 'YYYY-MM-DD'),
            orthoptist_ids: selectedOrthoptists
            }).then(response => {
                if (response.status === 200) {
                    M.toast({html: 'Synchronisation en cours', classes: 'green'})
                }
            }).catch(error => {
                M.toast({html: 'Une erreur est survenue', classes: 'red'})
            })
    }

    return (
        <div>
            <div style={{marginBottom: 10}}>
                <h3 className="valign-wrapper" style={{margin: 20}}><i className="material-icons left">receipt</i>Télécharger l'historique
                    des rendez-vous</h3>
                <h6 className="grey-text text-darken-2"  style={{fontStyle: "italic", paddingLeft: "2vw", margin: 0}}>L'ensemble des informations des examens réalisés entre les dates (pas de date = pas de limite)</h6>
                <div className="valign-wrapper container" style={{justifyContent: "space-between", flexWrap: "wrap"}}>
                    {renderOrthoptistsSelect("bills")}
                    <DatePicker id="bills_start_date" txt="Date de début" onChange={(date) => setStartDate(date)}/>
                    <DatePicker id="bills_end_date" txt="Date de fin" onChange={(date) => setEndDate(date)}/>
                    <div className="hide-on-small-only">
                        <StateButton side='left' state={historyState} onClick={getBills} text="Export CSV" icon="file_download" color="blue darken-2"/>
                    </div>
                    <div className="center hide-on-med-and-up" style={{width: "100%", marginTop: 10}}>
                        <StateButton side='left'  state={historyState} onClick={getBills} text="Export CSV" icon="file_download" color="blue darken-2"/>
                    </div>
                </div>
            </div>
            <div className="divider"/>
            <div style={{marginBottom: 10}}>
                <h3 className="valign-wrapper" style={{margin: 20}}><i className="material-icons left">euro</i>Donnée prochaine facture</h3>
                <h6 className="grey-text text-darken-2" style={{fontStyle: "italic", paddingLeft: "2vw", margin: 0}}>Ce qui sera réglé par un ou plusieurs orthoptistes à la prochaine échéance</h6>
                <div className="valign-wrapper container" style={{justifyContent: "space-between", flexWrap: "wrap"}}>
                    {renderOrthoptistsSelect("invoices")}
                    <div className="hide-on-small-only">
                        <StateButton side='left' state={RequestState.IDLE} onClick={getInvoices} text="Export CSV" icon="file_download" color="blue darken-2"/>
                    </div>
                    <div className="center hide-on-med-and-up" style={{width: "100%", marginTop: 10}}>
                        <StateButton side='left' state={RequestState.IDLE} onClick={getInvoices} text="Export CSV" icon="file_download" color="blue darken-2"/>
                    </div>
                </div>
            </div>
            <div className="divider"/>
            <div style={{marginBottom: 10}}>
                <h3 className="valign-wrapper" style={{margin: 20}}><i className="material-icons left">sync</i>Resynchroniser les montants d'un orthoptiste</h3>
                <h6 className="grey-text text-darken-2"  style={{fontStyle: "italic", paddingLeft: "2vw", margin: 0}}>
                    Applique rétroactivemnet les tarifs actuels d'un orthoptist pour les consultations déjà réalisées.
                    Attention, cette action est irréversible, cette action n'impacte <b>PAS</b> des consultations déjà facturée ou en cours de facturation.
                    <br/>Cette opération sera effectuée de manière <b>asynchrone</b>, patientez donc quelques minutes avant de voir le résultat
                </h6>
                <div className="valign-wrapper container" style={{justifyContent: "space-between", flexWrap: "wrap"}}>
                    <div className="row">
                        {renderOrthoptistsSelect("resync")}
                    </div>
                    <div className="row">
                        <div className="col s6">
                            <DatePicker id="sync_bills_start_date" txt="Date de début" onChange={(date) => setStartDate(date)}/>
                        </div>
                        <div className="col s6">
                            <DatePicker id="sync_bills_end_date" txt="Date de fin" onChange={(date) => setEndDate(date)}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s6 input-field">
                                <p>
                                  <label>
                                    <input type="checkbox"  checked={authorizeButton} onChange={(e)=> setAuthorizeButton(!authorizeButton)}/>
                                    <span>Je sais ce que je fais.</span>
                                  </label>
                                </p>
                        </div>
                        <div className="col s6 input-field">
                                <a className={!authorizeButton ? "disabled btn" : "btn z-depth-0 blue darken-2"} onClick={submitResync}>Resync</a>
                        </div>
                    </div>

                </div>
            </div>
            <div className="divider"/>
        </div>
    );
}

export default BillingConsole;