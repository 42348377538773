import React from "react";
import axios from "axios";
import Loader from "../Shared/Loader";
import SummaryDetails from "./SummaryDetails";
import {RequestState} from "../Shared/StateHelper";
import ReactToPrint from "react-to-print";
import Helper from "../Shared/Helper";

const pageStyle = `
@media print {
   body {
       display: block;
       box-sizing: border-box;   
       padding: 20mm 15mm;
       height: auto;
       fontFamily: Arial, sans-serif;
   }
   @page {
      size: auto;   /* auto is the initial value */
      margin: 0;  /* this affects the margin in the printer settings */
   }
}`;

export default class PatientOkaySummary extends React.Component {
    constructor(props) {
        super(props);
        this.componentRef = React.createRef();
        this.state = {
            summary_data: null,
            request_state: RequestState.LOADING
        }
    }

    componentDidMount() {
        axios.get("/okay_summary", {params: {id: this.props.appointment_id}})
            .then((response) => {
                this.setState({summary_data: response.data["summary"], request_state: RequestState.SUCCESS}, () => {
                    setTimeout(() => window.status = "ready", 100)
                })
            })
            .catch((error) => {
                this.setState({request_state: RequestState.ERROR})
            })
    }

    renderOphtalmogistComment = () => {
        if (!this.state.summary_data.ophtalmologist_comment) return null
        return <>
            <h4 style={{marginBlock: 10}}>Commentaire supplémentaire : </h4>
            <div style={{fontFamily: "Arial, sans-serif"}}>{this.state.summary_data.ophtalmologist_comment}</div>
        </>
    }

    render() {
        if (this.state.requestState === RequestState.LOADING) return <div id="full-height" style={{padding: 100}}>
            <Loader wide/>
        </div>
        if (this.state.request_state === RequestState.ERROR) return <div className="center">Une erreur est
            survenue.
        </div>
        if (this.state.request_state === RequestState.SUCCESS) return (
            <div>
                <div className={this.props.print ? "" : "hide"}>
                    <ReactToPrint
                        trigger={() => {
                            return (
                                <a className="btn blue darken-2 z-depth-0"
                                   style={{position: "absolute", top: "5%", right: "5%"}}>Imprimer
                                </a>)
                        }}
                        content={() => this.componentRef.current}
                        documentTitle="Compte Rendu Patient"
                        pageStyle={pageStyle}
                    >
                    </ReactToPrint>
                </div>
                <div className="page" ref={this.componentRef}>
                    <title>Récapitulatif du rendez-vous</title>
                    <div className="container" style={{display: "flex", flexDirection: "column"}}>
                        <div className="pdf-document" style={{width: "100%", display: "flex", flexDirection: "column"}}>
                            <div className="prevent-break">
                                <div dangerouslySetInnerHTML={{__html: this.state.summary_data["header"]}}/>
                                <p style={{textAlign: "center"}}>
                                    {`Suite au rendez-vous de ${this.state.summary_data["patient"]["last_name"]} ${this.state.summary_data["patient"]["first_name"]}`}
                                </p>
                                <div style={{fontFamily: "Arial, sans-serif"}}>
                                    Cher(e) Docteur, <br/>
                                    Dans le cadre d'un protocole de téléophtalmologie
                                    à la date du {Helper.formatDate(this.state.summary_data.date)},
                                    j'ai été amené à interpréter les données transmises
                                    par {this.state.summary_data["orthoptist_full_name"]} {this.props.brand === "WHITE" ? ", opticien" : ", orthoptiste"}.<br/>
                                    A la lecture des examens réalisés il n'apparait aucune anomalie oculaire. <br/>
                                    {this.state.summary_data["override_lock"] ?
                                        "Cette prescription ne vous dispense pas de la" +
                                        "consultation d'un ophtalmologiste suite aux pathologies détectées" +
                                        "lors de l'analyse.<br/>"
                                        : null
                                    }
                                    Bien cordialement, <br/><br/>
                                    <div style={{display: "flex", width: "100%"}}>
                                        {this.state.summary_data["signature"] ?
                                            <img style={{marginLeft: "auto"}}
                                                 src={this.state.summary_data["signature"]} height="150" alt=""/>
                                            : null
                                        }
                                    </div>
                                </div>
                            </div>
                            {this.renderOphtalmogistComment()}
                            <SummaryDetails
                                images={this.state.summary_data["images"]}
                                measures_types={this.state.summary_data["measure_types"]}
                                measures={this.state.summary_data["measures"]}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}