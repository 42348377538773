import React, {useEffect, useRef} from 'react';
import RightsStore from "../../RightsStore";
import Helper from "../../../../Shared/Helper";
import RestrictedElement from "../../../../Shared/RestrictedElement";
import {UserPermissions, UserRights} from "../../../../Shared/Enums/Enums";
import Loader from "../../../../Shared/Loader";
import Error from "../../../../Shared/Error";
import {Observer} from "mobx-react";
import axios from "axios";
import {construct_filter, Filter} from "../../../../Shared/FilterHelper";
import Pagination from "../../../../Shared/Pagination";
import useDebounce from "../../../../Shared/Hooks";
import {Role} from "../../../../Shared/Enums/Scope";
import ScopedElement from "../../../../Shared/ScopedElement";
import {toast} from "react-toastify";
import moment from "moment";
import MoreButton from "../../../../Shared/UiKit/MoreButton";
import {Link} from "react-router-dom";
import ResponsiveMoreButton from "../../../../Shared/UiKit/ResponsiveMoreButton";

const _ = require('lodash')
const qs = require('qs');
const NB_PATIENTS = 25

function Patients() {
    const modal_id = `console_patient_modal`
    const [patients, setPatients] = React.useState([])
    const [onEditPatient, setOnEditPatient] = React.useState({})
    const [count, setCount] = React.useState(1)
    const [filter, setFilter] = React.useState({})
    const [page, setPage] = React.useState(null)

    const birthdayRef = useRef(null)

    const debouncedPage = useDebounce(page, 500)
    const debouncedFilter = useDebounce(filter, 500)

    useEffect(() => {
        const modal = document.getElementById(modal_id);
        const modalInstance = M.Modal.init(modal, {});
        return () => {
            modalInstance?.destroy();
        }
    }, [patients, RightsStore.rights])

    useEffect(() => {
        getPatients()
    }, [debouncedPage, debouncedFilter])

    useEffect(() => {
        if (onEditPatient.birthday) birthdayRef.current.value = moment(onEditPatient.birthday).format("YYYY-MM-DD")
    }, [onEditPatient])

    const edit_patient = (patient) => {
        setOnEditPatient({...patient, onEdit: true})
        open_modal()
    }

    const open_modal = () => {
        setTimeout(() => {
            const modal = document.getElementById(modal_id);
            const modalInstance = M.Modal.getInstance(modal);
            modalInstance.open();
        }, 100)
    }

    const getPatients = () => {
        axios.get("/admin/console/users/patient/search", {
            params: {
                filter: construct_filter({...filter}),
                nb_results: NB_PATIENTS,
                page: page
            },
            paramsSerializer: params => {
                return qs.stringify(params, {arrayFormat: 'brackets'})
            }
        }).then((response) => {
                setPatients(response.data.patients)
                setCount(response.data.count)
            }
        )
    }

    const update_patient = (patient) => {
        axios.patch(`/admin/console/users/patient/${patient.id}`, {
            patient: {...patient, birthday: moment(birthdayRef.current.value)}
        }).then((resp) => {
            const office_name = resp.data.cabinet.titre
            setPatients(patients.map(pat => pat.id === patient.id ? {...resp.data, office_name: office_name} : pat))
            const modal = document.getElementById(modal_id);
            const modalInstance = M.Modal.getInstance(modal);
            modalInstance.close();
        }).catch((err) => {
            if (err.response.status === 401) {
                toast.error("Vous n'avez pas les droits pour effectuer cette action")
            } else toast.error("Impossible de créer l'orthoptiste")
        })
    }

    const connectAsPatient = (patient) => {
        axios.get(`/admin/console/users/internal/connect_as_patient/${patient.id}`).then((resp) => {
            window.open(resp.data.url, "_blank")
        }).catch((err) => {
            if (err.response.status === 401) {
                toast.error("Vous n'avez pas les droits pour effectuer cette action")
            } else toast.error("Impossible de créer l'orthoptiste")
        })
    }

    const renderModal = () => {
        return <div className="modal modal-fixed-footer" id={modal_id}>
            <div className="model-content container">
                <h3 className="center"
                    style={{marginTop: 20}}>Éditer patient</h3>
                <div className="row" style={{width: "100%", gap: 20, padding: 20}}>
                    <div className="col s12 valign-wrapper" style={{gap: 20}}>
                        <span style={{width: 100}}>Prénom</span>
                        <div className="input-field outlined" style={{width: "100%"}}>
                            <input type="text" className="validate" value={onEditPatient.prenom || ""}
                                   onChange={(e) => setOnEditPatient({
                                       ...onEditPatient,
                                       prenom: e.target.value
                                   })}/>
                        </div>
                    </div>
                    <div className="col s12 valign-wrapper" style={{gap: 20}}>
                        <span style={{width: 100}}>Nom</span>
                        <div className="input-field outlined" style={{width: "100%"}}>
                            <input type="text" className="validate" value={onEditPatient.nom || ""}
                                   onChange={(e) => setOnEditPatient({...onEditPatient, nom: e.target.value})}/>
                        </div>
                    </div>
                    <div className="col s12 valign-wrapper" style={{gap: 20}}>
                        <span style={{width: 100}}>Email</span>
                        <div className="input-field outlined" style={{width: "100%"}}>
                            <input type="text" className="validate" value={onEditPatient.email || ""}
                                   onChange={(e) => setOnEditPatient({...onEditPatient, email: e.target.value})}/>
                        </div>
                    </div>
                    <div className="col s12 valign-wrapper" style={{gap: 20}}>
                        <span style={{width: 100}}>Téléphone</span>
                        <div className="input-field outlined" style={{width: "100%"}}>
                            <input type="text" className="validate" value={onEditPatient.telephone || ""}
                                   onChange={(e) => setOnEditPatient({...onEditPatient, telephone: e.target.value})}/>
                        </div>
                    </div>
                    <div className="col s12 valign-wrapper" style={{gap: 20}}>
                        <span style={{width: 100}}>Date de naissance</span>
                        <div className="input-field outlined" style={{width: "100%"}}>
                            <input type="date" ref={birthdayRef}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer" style={{display: "flex", gap: 10, justifyContent: "right"}}>
                <a className="btn btn-small blue darken-2 z-depth-0"
                   onClick={() => update_patient(onEditPatient)}>Valider</a>
                <a className="modal-close btn btn-small red darken-2 z-depth-0">Annuler</a>
            </div>
        </div>
    }

    const renderPatient = (patient) => {
        return (
            <div className="collection-item transparent" key={patient.id}
                 style={{display: "flex", width: "100%", gap: 10}}>
                <div style={{
                    width: "100%",
                    margin: 0,
                    display: "flex",
                    alignItems: "center",
                    // flexWrap: "wrap",
                    gap: 10,
                }}>
                    <div style={{width: 100, margin: 0}}>
                        <span>#{patient.id}</span>
                    </div>
                    <div style={{width: 200, margin: 0}}>
                        <span>{Helper.formatName(patient.prenom, patient.nom)}</span>
                    </div>
                    <div style={{width: 100, margin: 0}}>
                        {patient.birthday ? <span>{Helper.dateToAge(patient.birthday)} ans</span> : null}
                    </div>
                    <div style={{width: 300, margin: 0}}>
                        <span>{patient.email}</span>
                    </div>
                    <div style={{width: 300, margin: 0}}>
                        <span>{patient.office_name}</span>
                    </div>
                    <RestrictedElement field={UserRights.USERS} permission={UserPermissions.WRITE_ONLY}>
                        <div style={{flex: 1}}>
                            <ResponsiveMoreButton id={`more_button_patient_${patient.id}`} options={[
                                <a className="btn btn-small blue darken-2 z-depth-0"
                                   onClick={() => edit_patient(patient)}>
                                    <i className="material-icons left">edit</i>Modifier</a>,
                                <Link to={`/patients/${patient.id}`} className="btn-small blue darken-2 z-depth-0">
                                    <i className="material-icons left">dashboard</i>
                                    Tableau de bord
                                </Link>,
                                <a onClick={() => connectAsPatient(patient)}
                                   className="btn-small blue darken-2 z-depth-0">
                                    <i className="material-icons left">key</i>
                                    Accès portail
                                </a>,
                            ]}/>
                        </div>
                    </RestrictedElement>
                </div>
            </div>
        )
    }

    const render = () => {
        if (RightsStore.can_do(UserRights.USERS, UserPermissions.READ_ONLY) === undefined) {
            return <div id="full-height"
                        style={{margin: 50}}>
                <Loader text=""/>
            </div>
        }
        if (!RightsStore.can_do(UserRights.USERS, UserPermissions.READ_ONLY)) {
            return <div id="full-height"
                        style={{margin: 50}}>
                <Error errorText="Vous n'avez pas les droits pour accéder à cette page"/>
            </div>
        }

        return <div id="full-height" style={{display: "flex", flexDirection: "column", gap: 20, padding: 20}}>
            <h3 className="valign-wrapper" style={{margin: 20}}>
                <i className="material-icons left">personal_injury</i>
                Patients
            </h3>

            <div className="divider"/>
            <div className="transparent" style={{display: "flex", flexDirection: "column", height: "80%"}}>
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <div className="input-field outlined">
                        <input type="text" style={{height: 40}} placeholder={"Rechercher"}
                               onChange={e => setFilter({
                                   ...filter,
                                   nom_prenom_email: {[Filter.CONTAINS_ALL_CASE_INSENSITIVE]: e.target.value.split(" ")}
                               })}
                        />
                    </div>
                    <Pagination count={count} limit={NB_PATIENTS} page={page} onChange={(page) => setPage(page)}/>
                </div>

                <div className="collection"
                     style={{borderRadius: 5, height: "100%", overflowY: "scroll"}}>
                    {patients.map((patient) => {
                        return renderPatient(patient)
                    })}
                </div>
            </div>
            <ScopedElement scope={[Role.SUPER_ADMIN, Role.ADMIN]}>
                {renderModal()}
            </ScopedElement>
        </div>
    }

    return <Observer>
        {() => render()}
    </Observer>
}

export default Patients;