import React, {Component} from 'react';
import axios from "axios"
import moment from "moment"
import Loader from "../../Shared/Loader";

const _ = require('lodash');

export default class Simulate extends Component {
    constructor(props) {
        super(props);
        this.start = this.start.bind(this);
        this.total = this.total.bind(this);
        this.loadData = this.loadData.bind(this);
        this.loadTarifs = this.loadTarifs.bind(this);
        this.bouncedData = _.debounce(this.loadData, 1000)
        this.end = this.end.bind(this);
        this.state = {
            paiements: [],
            start_date: moment().subtract(1, 'days').format("YYYY-MM-DD"),
            end_date: moment().format("YYYY-MM-DD"),
            tarifs: {},
            ready: false
        }
        this.loadTarifs()
        this.loadData()
    }

    loadTarifs() {
        this.setState({ready: false})
        axios.get("/orthoptistes/paiements/tarifs").then((data) => {
            this.setState({tarifs: data.data, ready: true})
        })
    }

    loadData() {
        this.setState({ready: false})
        axios.post("/orthoptistes/paiements/data", {
            start_date: this.state.start_date,
            end_date: this.state.end_date
        }).then((data) => {
            this.setState({paiements: data.data, ready: true})
        })
    }

    start(e) {
        this.setState({start_date: e.target.value})
        this.bouncedData()
    }

    end(e) {
        this.setState({end_date: e.target.value})
        this.bouncedData()
    }

    total = () => this.state.paiements.reduce((acc, p) => (acc + p.due / 100.0), 0).toFixed(2)

    renderData = () => {
        if (!this.state.ready) return <div style={{margin: 40}}>
            <Loader text="" size="small"/>
        </div>

        const tarifs = this.state.tarifs
        return <>
            <div style={{display: "flex"}}>
                <h6 className="valign-wrapper">
                    <i className="material-icons left blue-text text-darken-2">euro_symbol</i>
                    Total à payer : {this.total()} €
                </h6>
                <h6 className="green-text text-darken-2 valign-wrapper" style={{marginLeft: "auto"}}>
                    <i className="material-icons left">remove</i>
                    Examens déjà payés
                </h6>
            </div>
            <table className="bordered striped highlight" style={{margin: "0 10px"}}>
                <thead>
                <tr>
                    <th> Date</th>
                    <th> Patient</th>
                    <th> actes</th>
                    <th> montants a payer</th>
                </tr>
                </thead>
                <tbody>
                {this.state.paiements.map((p) => (
                    <tr key={p.id} className={p.paiement_id ? "green-text text-darken-2" : ""}>
                        <td> {moment(p.start).format("DD/MM/YYYY HH:mm")}</td>
                        <td> {p.patient_lastname} {p.patient_firstname} </td>
                        <td>{p.act_name}</td>
                        <td>{p.due / 100.0} €</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </>
    }

    render() {
        return (
            <div style={{display: "flex", flexDirection: "column", gap: 20}}>
                <h6 className="center"> Attention les valeurs entre la simulation et le paiement réel peuvent
                    varier</h6>
                <div className="row" style={{gap: 20}}>
                    <div className="col s6">
                        <div className="input-field outlined">
                            <input
                                type="date"
                                name="début comptage"
                                value={this.state.start_date}
                                onChange={this.start}
                            />
                        </div>
                    </div>
                    <div className="col s6">
                        <div className="input-field outlined">
                            <input
                                type="date"
                                name="fin"
                                value={this.state.end_date}
                                onChange={this.end}
                            />
                        </div>
                    </div>
                </div>
                {this.renderData()}
            </div>
        );
    }
}
