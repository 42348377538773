import React, {Component} from "react";
import Tooltip from "../../Shared/Tootip";
import PrintInvoice from "./PrintInvoice";
import Helper from "../../Shared/Helper";
import BillingPreview from "./BillingPreview";
import BrandedElement from "../../Shared/BrandedElement";
import {InvoiceStatus} from "../../Shared/Enums/Enums";

const _ = require('lodash')
export default class Overview extends Component {
    constructor(props) {
        super(props);
        this.renderLine = this.renderLine.bind(this)
        this.renderStatus = this.renderStatus.bind(this)
    }

    renderStatus(invoice) {
        switch (invoice.status) {
            case InvoiceStatus.WAITING:
                return <Tooltip id={`tooltip-invoice-waiting-${invoice.id}`} text="Le paiement va bientôt être traité.">
                    <i className="material-icons grey-text">schedule</i>
                </Tooltip>
            case InvoiceStatus.PENDING:
                return <Tooltip id={`tooltip-invoice-pending-${invoice.id}`}
                                text="Cette facture est en attente de paiement sur la plateforme Stripe.">
                    <i className="material-icons orange-text">pending</i>
                </Tooltip>
            case InvoiceStatus.SUCCESS:
                return <Tooltip id={`tooltip-invoice-success-${invoice.id}`} text="Cette facture a été réglée.">
                    <i className="material-icons green-text">done</i>
                </Tooltip>
            case InvoiceStatus.FAILED:
                return <Tooltip id={`tooltip-invoice-error-${invoice.id}`} text="Une erreur a empêché le paiement.">
                    <i className="material-icons red-text">error</i>
                </Tooltip>
            default: return null
        }
    }

    renderLine(invoice, index) {
        // v2
        if(invoice.v2) {

        }
        // legacy
        else {

        }
        const ophtalmo_earnings = Object.entries(invoice.ophtalmologists_earnings??{})
        let total_ophtalmo = !_.isEmpty(ophtalmo_earnings) ? ophtalmo_earnings.reduce((acc, [key, value]) => acc + parseFloat(value), 0) : invoice.total_ophtalmologiste/100.0
        total_ophtalmo = invoice.v2 ? total_ophtalmo : 0
        const debt = invoice.v2 && !_.isEmpty(ophtalmo_earnings) ? (invoice.synthese.payed_debt) || 0 : 0
        const total = invoice.totalPlateforme + debt

        return (
            <div className="row valign-wrapper" style={{margin: 0}}>
                <div className="col s1">{this.renderStatus(invoice)}</div>
                <div className="col s3">
                    {`${((total) / 100 + total_ophtalmo).toFixed(2)}€`}
                </div>
                <div className="col s6">
                    {`Facture du ${new Date(invoice.created_at).toLocaleDateString("fr")}`}
                </div>
                <div className="col s2">
                    <PrintInvoice invoice={invoice} orthoptist={this.props.orthoptist} ophtalmologist={this.props.ophtalmologist}
                                  ophtalmologist_name={Helper.formatName(this.props.ophtalmologist.prenom, this.props.ophtalmologist.nom)}/>

                </div>
            </div>
        )
    }

    renderInvoices() {
        if (_.isEmpty(this.props.invoices)) return <h2 className="center">Aucune facture n'est disponible
            actuellement.</h2>
        return (
            <div className="container">
                <h1 className="center" id="main-title">Factures</h1>
                <ul className="collection" style={{borderRadius: "20px"}}>
                    <div style={{paddingTop: "10px"}}/>
                    {this.props.invoices.map((invoice, index) =>
                        <li key={index} className="collection-item">{this.renderLine(invoice, index)}</li>
                    )}
                </ul>
            </div>
        )
    }

    render() {
        return (
            <BrandedElement temeoo>
                <div>
                    <BillingPreview/>
                </div>
                <div>
                    {this.renderInvoices()}
                </div>
            </BrandedElement>

        )
    }
}

Overview.defaultProps = {
    invoices: []
}