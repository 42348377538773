import {AlertTypes} from "./AlertTypesEnums";
import React from "react";
import {Colors} from "./ColorEnums";


class AlertTab extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        let tooltips = document.querySelectorAll('[id=alertSecretaryCommentTooltip]')
        if (tooltips) M.Tooltip.init(tooltips, {exitDelay: 0, enterDelay: 50})
    }



    findCorrespondingAttribute = (type, value) => {
        const color_index = Colors.findIndex((color) => color.alert_type === value)
        return color_index > 0 ? Colors[color_index][type] : Colors.slice(-1)[0][type]
    }

    render() {
        return (
            <div className="center" style={{backgroundColor: this.props.color}}>
                <h1>{this.props.title}</h1>
                {this.props.alert_list && this.props.alert_list.length > 0 ?
                    <div style={{display: "flex", flexWrap: "wrap"}}>
                        {this.props.alert_list.map((alert,index) =>
                            <div key={`${this.props.type}_${alert["appointment"]["id"]}_${index}`}
                                 style={{
                                     width: "23%",
                                     height: "50px",
                                     margin: "0.5vh 1%",
                                     borderRadius: "10px",
                                     display: "flex",
                                     wordBreak: "break-word",
                                     cursor: "pointer"
                                 }}
                                 className={`tooltipped ${this.findCorrespondingAttribute("secondary_color", alert["alert"]["gravity"])}`}
                                 id={alert["alert"]["secretary_comment"] ? "alertSecretaryCommentTooltip" : null}
                                 data-target="alertSecretaryCommentTooltip"
                                 data-position="top"
                                 data-tooltip={alert["alert"]["secretary_comment"]}>

                                <a
                                    style={{
                                        display: "grid",
                                        width: "100%",
                                        gridTemplateColumns: "80% 20%"
                                    }}
                                    className={`${this.findCorrespondingAttribute("text_color", alert["alert"]["gravity"])}`}
                                    onClick={() => this.props.selectAlert(alert)}>
                                    <div style={{
                                        gridColumn: 1,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}><b>{alert["patient"]["last_name"]} {alert["patient"]["first_name"]}</b>
                                    </div>
                                    <i style={{
                                        gridColumn: 2,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }} className={`material-icons ${this.findCorrespondingAttribute("text_color", alert["alert"]["gravity"])}`}>expand_circle_down</i>
                                </a>
                            </div>
                        )}
                    </div>
                    : <p>Cette catégorie d'alerte est vide.</p>
                }
            </div>
        )
    }
}

export default AlertTab