import React, {useEffect} from 'react';
import {RequestState} from "../../Shared/StateHelper";
import {TimeRange} from "../../Shared/Enums/Enums";
import axios from "axios";
import Loader from "../../Shared/Loader";
import Error from "../../Shared/Error";
import Helper from "../../Shared/Helper";
import {useNavigate} from "react-router-dom";

const GeneralEarnings = () => {
    const startDateRef = React.useRef(null);
    const endDateRef = React.useRef(null);
    const [data, setData] = React.useState([]);
    const [requestState, setRequestState] = React.useState(RequestState.IDLE);
    const nav = useNavigate();

    useEffect(() => {
        startDateRef.current.value = TimeRange.MONTH.start();
        endDateRef.current.value = TimeRange.MONTH.end();
        getData()
    }, [])

    const setTime = (range) => {
        startDateRef.current.value = range.start();
        endDateRef.current.value = range.end();
    }

    const getData = () => {
        setRequestState(RequestState.LOADING)
        axios.get("/admin/statistics/ophtalmologist/earnings_by_orthoptist", {
            params: {
                start_date: startDateRef.current.value,
                end_date: endDateRef.current.value
            }
        }).then((response) => {
            setData(response.data)
            setRequestState(RequestState.SUCCESS)
        }).catch((error) => {
            setRequestState(RequestState.ERROR)
        })
    }

    const renderFilter = () => {
        return <div className="valign-wrapper" style={{gap: 10, flexWrap: "wrap", justifyContent: "center"}}>
            <div>
                <select className="browser-default" defaultValue=""
                        onChange={(e) => setTime(TimeRange[e.target.value])}>
                    <option value={""} disabled>Sélectionner une periode</option>
                    {Object.values(TimeRange).map((range, index) => {
                        return <option key={`time_range_${index}`} value={range.value}>{range.name}</option>
                    })}
                </select>
            </div>
            {/*START DATE*/}
            <h6>Du</h6>
            <div>
                <input type="date" ref={startDateRef}/>
            </div>
            {/*END DATE*/}
            <h6>au</h6>
            <div>
                <input type="date" ref={endDateRef}/>
            </div>
            <a className="btn blue darken-2 z-depth-0" onClick={getData}>Valider</a>
        </div>
    }


    const renderOverview = () => {
        const {appt, hn, total} = data.reduce((acc, orthoptist) => {
            const appt_earnings = parseFloat(orthoptist.appointements_earnings ?? 0)
            const hn_earnings = parseFloat(orthoptist.hn_earnings ?? 0)
            const total_earnings = appt_earnings + hn_earnings
            acc.appt += appt_earnings
            acc.hn += hn_earnings
            acc.total += total_earnings
            return acc
        }, {appt: 0, hn: 0, total: 0})
        return <div className="valign-wrapper" style={{justifyContent: "space-evenly"}}>
            <div className="card blue darken-2 z-depth-0 white-text">
                <h3 style={{marginBlock: 10}}>Revenu examens</h3>
                <h2 className="center" style={{marginBlock: 10}}>{appt.toFixed(2)}€</h2>
            </div>
            <div className="card blue darken-2 z-depth-0 white-text">
                <h3 style={{marginBlock: 10}}>Revenu HN</h3>
                <h2 style={{marginBlock: 10}} className="center">{hn.toFixed(2)}€</h2>
            </div>
            <div className="card blue darken-2 z-depth-0 white-text">
                <h2 style={{marginBlock: 10}}>Revenu total</h2>
                <h1 style={{marginBlock: 10}} className="center">{total.toFixed(2)}€</h1>
            </div>
        </div>
    }

    const renderBody = () => {
        if (requestState === RequestState.LOADING) return <div style={{margin: 40}}>
            <Loader text="" size="small"/>
        </div>
        if (requestState === RequestState.ERROR) return <div style={{marginTop: 40}}>
            <Error errorText="Impossible de charger les rendez-vous"/>
        </div>
        if ((!data || !data.length)) return <h1 className="center">Faites votre recherche</h1>
        return <div style={{display: "flex", flexDirection: "column", gap: 20}}>
            {renderOverview()}
            <div style={{
                display: "flex",
                flexDirection: "column",
                gap: 20,
                marginInline: 40,
                borderRadius: 20,
                border: "thin solid #dddddd"
            }}>
                <table>
                    <thead>
                    <tr>
                        <th>Orthoptiste</th>
                        <th>Examens</th>
                        <th>Hors nomenclature</th>
                        <th>Total</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data.map((orthoptist, index) => {
                        const appt_earnings = parseFloat(orthoptist.appointements_earnings ?? 0)
                        const hn_earnings = parseFloat(orthoptist.hn_earnings ?? 0)
                        const total_earnings = appt_earnings + hn_earnings
                        return <tr key={`item_${index}`}>
                            <td>
                                <a className="btn-small btn-flat transparent"
                                   onClick={() => nav("detail/" + orthoptist.id)}>
                                    {Helper.formatName(orthoptist.first_name, orthoptist.last_name)}
                                </a>
                            </td>
                            <td>{appt_earnings.toFixed(2)} €</td>
                            <td>{hn_earnings.toFixed(2)} €</td>
                            <td>{total_earnings.toFixed(2)} €</td>
                        </tr>
                    })}
                    </tbody>
                </table>
            </div>
        </div>

    }

    return <div style={{display: "flex", flexDirection: "column", gap: 20, padding: 20}}>
        {renderFilter()}
        {renderBody()}
    </div>
}

export default GeneralEarnings;