import React, {Component} from "react";

export default class WhatsNew extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        var modals = document.getElementById("whatsnew_modal");
        M.Modal.init(modals, {});
    }

    componentWillUnmount() {
        M.Modal.getInstance(document.getElementById("whatsnew_modal")).destroy()
    }

    render() {
        return (
            <div className="fixed-action-btn">
                <a className="btn-floating btn-large modal-trigger pulse" data-target="whatsnew_modal"
                   style={{backgroundColor: "#f86a6d"}}>
                    <i className="large material-icons white-text">question_mark</i>
                </a>
                <div id="whatsnew_modal" className="modal bottom-sheet">
                    <div className="modal-content">
                        <h4>Quoi de neuf ?</h4>
                        <p>
                            Temeoo fait peau neuve ! Le logiciel évolue selon vos désirs, et nous avons besoin de vos
                            retours. Vous retrouverez ici les nouveautés du logiciel pour vous permettre de ne rien
                            louper.
                        </p>
                        <p>
                            Vous n'avez désormais plus besoin de cliquer sur votre profession
                            pour vous connecter, on le fait pour vous !
                        </p>
                        <p>
                            Attention cela dit, votre navigateur aura peut-être des difficultés à vous proposer votre
                            mail et mot de passe.<br/>
                            En cas d'oubli de votre mot de passe,  pas d'inquiétude, pour le retrouver, cliquez sur :<br/>
                            - les <b>3 points</b> en haut à droite de votre navigateur (Chrome)<br/>
                            - <b>Paramètres</b><br/>
                            - A gauche, sur <b>Saisie Automatique</b><br/>
                            - <b>Gestionnaire de Mots de Passe</b>, dans ce menu vous pourrez retrouver vos mots
                            de passe et les afficher à l'aide de la <b>petite icone en forme d'oeil  <i className="material-icons tiny">remove_red_eye</i></b>
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}