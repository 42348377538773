import React from 'react';
import {Application} from "../../../../../Shared/Enums/Enums";
import BorderedDisplay from "../../../../../Shared/UiKit/BorderedDisplay";
import GeneralOverview from "./GeneralOverview";

const APP_NAME = Application.EXPERT_VISION

function ExpertVisionOverview() {

    return <BorderedDisplay text={"Expert Vision"}>
        <GeneralOverview appName={APP_NAME}/>
    </BorderedDisplay>
}


export default ExpertVisionOverview;