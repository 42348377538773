import React, { Component } from 'react';
import { HashRouter as Router, Route, Link  } from "react-router-dom"
import Strips from "../../Shared/Strips"
export default class Installation extends Component {
  render() {
    return (
      <div className="container">
        <h2 className="center"> Installez et lancez l'utilitaire sur votre ordinateur
          ou démarrez le boitier "Temeoo"
        </h2>
        <div className="row">
          <div className="center">
            <a className="btn btn-large" target="_blank" href="/download/TEMEOO.exe"> Windows</a>
          </div>
        </div>
        <div class_name="row"><p> <br/></p></div>
        <div className="center">
          <Link className="btn btn-large mauve" to="/connect"><i className="material-icons left">done</i> Continuer </Link>
        </div>
        <Strips>white orthoptiste</Strips>
      </div>
    );
  }
}
