import React, { Component } from 'react';
import SetupStore from "./SetupStore"
import {observer} from "mobx-react"
import Usb from "./Usb"
import Dossier from "./Dossier"
import Typedemachine from "./Typedemachine"

@observer
export default class Step2 extends Component {
  render() {
    if(SetupStore.type=="usb"){
      return(<Usb db_machines={this.props.db_machines}/>)
    }
    else if (SetupStore.type == "dossier"){
      return(<Dossier db_machines_samba={this.props.db_machines_samba}/>)

    }
    else{
      return(<Typedemachine/>)
    }
  }
}
