import {observer} from "mobx-react"
import BridgeStore from "../BridgeStore"
import React, { Component } from 'react';
import axios from "axios"
import Statut from "./Statut"

export default class Connect extends Component {
  constructor(props) {
    super(props);
    this.load = this.load.bind(this)
    this.state = {
      bridges: []
    };
    this.load()
  }
  load(){
    axios.get("/api/v2/bridge/avaiable_bridges").then((data)=>{
      this.setState({bridges: data.data})
    })
  }
  render() {
    return (
      <div>
        {this.state.bridges.map((bridge)=>(
          <div className="card white" key={bridge.id}>
          <div className="card-content grey-text">
            <p>identifiant {bridge.uid} <br/></p>
            <p>{bridge.lan}</p>
            <Statut uid={bridge.uid} />
          </div>
          <div className="card-action">
        {BridgeStore.uid==bridge.uid ? (
            <a> Vous êtes inscrit à ce pont</a>
          ) : (
            <a onClick={(e)=>BridgeStore.connect(bridge.uid)}>Se connecter</a>
          )}
        </div>
      </div>
          ))}
      </div>
    );
  }
}
