import React, {useState, useEffect} from "react";
import Loader from "../Shared/Loader";
import axios from "axios";
import Helper from "../Shared/Helper"
import {RequestState} from "../Shared/StateHelper";
import Toastr from "../Shared/Toastr";
import {toast} from "react-toastify"
import Tooltip from "../Shared/Tootip";


export default function EditAlertText(props) {
    const [original_alert_text_title, setOriginalAlertTextTitle] = useState("")
    const [alert_text_title, setAlertTextTitle] = useState("")
    const [alert_text_message, setAlertTextMessage] = useState("")
    const [request_state, setRequestState] = useState(RequestState.LOADING)

    useEffect(() => {
        axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector("meta[name='csrf-token']").content
        getTextAlert()
    }, []);

    function isSubmitable() {
        return !(!alert_text_title || !alert_text_message);
    }

    function renderErrorTooltip() {
        let initial_text = "Un élément est manquant pour l'édition du risque:"
        if (!alert_text_title) initial_text += "\n- Veuillez saisir le titre de l'alerte."
        if (!alert_text_message) initial_text += "\n- Veuillez saisir le message lié a cette alerte."
        return initial_text
    }

    function getTextAlert() {
        axios.get(`/admin/text_alerts/${props.id}`)
            .then((response) => {
                setRequestState(RequestState.SUCCESS)
                setOriginalAlertTextTitle(response.data.text_alert.title)
                setAlertTextTitle(response.data.text_alert.title)
                setAlertTextMessage(response.data.text_alert.message)
            })
            .catch((error) => setRequestState(RequestState.SUCCESS))
    }

    function editMessage() {
        axios.patch(`/admin/text_alerts/${props.id}`, {
            title: alert_text_title,
            message: alert_text_message
        })
            .then((response) => {
                toast.success("Modifications enregistrées.")
                setTimeout(() => document.location.href = "/admin/text_alerts/index", 2000)
            })
            .catch((error) => toast.error("Erreur de l'édition de l'alerte."))
    }

    if (request_state === RequestState.LOADING) return <div className="center"><Loader text="Chargement en cours..."
                                                                                       wide/></div>
    if (request_state === RequestState.ERROR) return <div className="center">Une erreur est survenue.</div>
    if (request_state === RequestState.SUCCESS) return (
        <div className="container">
            <h1 className="center">Edition du message: {Helper.capitalizeFirstLetter(original_alert_text_title)}</h1>
            <div className="row" style={{marginBottom: "2vh"}}>
                <div className="input-field col s12 outlined">
                    <h6>Titre de la pathologie</h6>
                    <input
                        id="title"
                        type="text"
                        placeholder="Entrez le titre de la nouvelle pathologie"
                        value={alert_text_title}
                        onChange={(e) => setAlertTextTitle(e.target.value)}
                    />
                </div>
            </div>
            <div className="row">
                <div className="input-field col s12 outlined">
                    <h6 style={{marginTop: "0"}}>Message d'alerte</h6>
                    <textarea id="alert_message"
                              style={{minHeight: "150px", paddingTop: "1.5vh", resize: "none"}}
                              placeholder="Entrez le message lié a cette pathologie."
                              value={alert_text_message}
                              onChange={(e) => setAlertTextMessage(e.target.value)}
                    />
                </div>
            </div>
            {isSubmitable() ?
                <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
                    <a className="center btn blue darken-2 z-depth-0" onClick={editMessage}>Mettre à jour le message</a>
                </div> :
                <Tooltip id="update_text_alert_tootip" text={renderErrorTooltip()}>
                    <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
                        <a className="center btn blue darken-2 z-depth-0 disabled" onClick={editMessage}>Mettre à jour
                            le message</a>
                    </div>
                </Tooltip>
            }
            <Toastr/>
        </div>
    )
}