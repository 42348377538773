import React, {Component} from "react";
import BlockEdit from "./Blocks/BlockEdit";
import NewBlockButton from "./Blocks/NewBlockButton";
import DraggableElement from "../../../Shared/DraggableElement";

const _ = require('lodash');
export default class TemplateEdit extends Component {
    constructor(props) {
        super(props);
        this.onCreate = this.onCreate.bind(this)
        this.onChange = this.onChange.bind(this)
        this.onDelete = this.onDelete.bind(this)
        this.state = {
            template: {
                id: 0,
                name: "",
                blocks: []
            },
            expended: [],
            hoveredId: undefined
        }
    }

    componentDidMount() {
        if (this.props.template) {
            this.setState({
                template: Object.assign({}, this.state.template, {
                    id: this.props.template.id,
                    name: this.props.template.name,
                    blocks: this.props.template.blocks
                })
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.template && this.props !== prevProps) {
            this.setState({
                template: Object.assign({}, this.state.template, {
                    id: this.props.template.id,
                    name: this.props.template.name,
                    blocks: this.props.template.blocks
                })
            })
        }
    }

    onChange(block) {
        this.setState({
            template: Object.assign({}, this.state.template, {
                blocks: this.state.template.blocks.map(b => {
                    return b.id === block.id ? block : b
                })
            })
        }, () => this.props.onChange(this.state.template))
    }

    onCreate(block, index = null) {
        let blocks
        if (index !== null) {
            blocks = Object.assign(this.state.template.blocks)
            blocks.splice(index + 1, 0, block)
        } else {
            if (block.type === "HEADER") {
                blocks = [block].concat(this.state.template.blocks)
            } else if (block.type === "FOOTER") {
                blocks = this.state.template.blocks.concat(block)
            } else {
                const firstFooter = this.state.template.blocks.find(block => block.type === "FOOTER")
                if (firstFooter) {
                    blocks = Object.assign(this.state.template.blocks)
                    const index = this.state.template.blocks.indexOf(firstFooter)
                    blocks.splice(index, 0, block)
                } else {
                    blocks = this.state.template.blocks.concat(block)
                }
            }
        }
        this.setState({
            template: Object.assign({}, this.state.template, {
                blocks: blocks
            })
        }, () => this.props.onChange(this.state.template))
    }

    onDelete(block) {
        this.setState({
            template: Object.assign({}, this.state.template, {
                blocks: this.state.template.blocks.filter((b) => block !== b)
            })
        }, () => this.props.onChange(this.state.template))
    }

    moveBlockInGroup(index, groupId) {
        let newBlocks = Object.assign(this.state.template.blocks)
        const group = newBlocks.find(b => b.id === groupId)
        if (group.type !== "GROUP") return
        const block = newBlocks.splice(index, 1)[0]
        group.data.blocks.push(block)
        this.setState({
                expended: this.state.expended.concat(groupId),
                template: Object.assign({}, this.state.template, {
                    blocks: newBlocks
                })
            }, () => this.props.onChange(this.state.template)
        )
    }

    move(oldIndex, newIndex) {
        this.setState({dragging: false})
        if (this.state.hoveredId !== undefined) {
            this.moveBlockInGroup(oldIndex, this.state.hoveredId)
            return
        }
        if (oldIndex === newIndex) return
        const move = (arr, from, to) => {
            arr.splice(to, 0, arr.splice(from, 1)[0]);
            return arr;
        }
        this.setState({
            template: Object.assign({}, this.state.template, {
                blocks: move(this.state.template.blocks, oldIndex, newIndex)
            })
        }, () => this.props.onChange(this.state.template))
    }

    expendBlock(block) {
        if (this.state.expended.includes(block.id)) this.setState({expended: this.state.expended.filter(b => b !== block.id)})
        else this.setState({expended: this.state.expended.concat(block.id)})
    }

    renderBlocks() {
        if (this.state.template.blocks) {
            return (
                <DraggableElement list={this.state.template.blocks}
                                  onDragEnd={(index, newIndex) => this.move(index, newIndex)}
                                  onDragStart={() => this.setState({dragging: true})}
                                  context={!this.props.inGroup}>
                    {this.state.template.blocks.map((block, index) => (
                        <div key={index}>
                            <div style={{padding: "2vh 1vw 2vh 1vw", borderRadius: "20px"}} id={`block_${block.id}`}>
                                <BlockEdit block={block} onChange={(block) => this.onChange(block)}
                                           onDelete={(block) => this.onDelete(block)}
                                           expended={this.state.expended.includes(block.id)}
                                           onExpended={(block) => this.expendBlock(block)}
                                           isFirst={index === 0}
                                           isLast={index === this.state.template.blocks.length - 1}
                                           hovered={this.state.hoveredId === block.id && this.state.dragging}
                                           isHovered={(hovered) => this.setState({hoveredId: hovered ? block.id : undefined})}
                                           dragging={this.state.dragging}
                                />
                            </div>
                            <div style={{width: "100%"}}
                                 className={`center`}>
                                <NewBlockButton onValidation={(block) => this.onCreate(block, index)}
                                                betweenBlocks={true} id={index}/>
                                <div className={index === this.state.template.blocks.length - 1 ? "" : "hide"} style={{paddingBottom: "40px"}}/>
                            </div>
                        </div>
                    ))}
                </DraggableElement>
            )
        }
    }


    render() {
        return (
            <div className="transparent" style={{height: "100%", padding: "2vh 1vw 2vh 1vw"}}>
                <div className="center">
                    <NewBlockButton disabled={!this.props.template || _.isEmpty(this.props.template)}
                                    onValidation={(block) => this.onCreate(block)}
                                    id={`general_${this.state.template.id}`}
                                    inGroup={this.props.inGroup}
                    />
                </div>
                <div style={{paddingTop: "2vh"}}>
                    {this.renderBlocks()}
                </div>
            </div>
        );
    }
}

TemplateEdit.defaultProps = {
    name: "Template",
    inGroup: false
}