import React, {Component} from "react";
import TextBlockEdit from "./TextBlockEdit";
import InputBlockEdit from "./InputBlockEdit";
import HeaderBlockEdit from "./HeaderBlockEdit";
import FooterBlockEdit from "./FooterBlockEdit";
import InputText from "../../../../Shared/UiKit/InputText";
import DividerBlockEdit from "./DividerBlockEdit";
import {toast} from "react-toastify";
import Tooltip from "../../../../Shared/Tootip";
import GroupBlockEdit from "./GroupBlockEdit";
import ImageBlockEdit from "./ImageBlockEdit";

export default class BlockEdit extends Component {
    constructor(props) {
        super(props);
        this.init = this.init.bind(this)
        this.toggle = this.toggle.bind(this)
        this.onChange = this.onChange.bind(this)
        this.onDelete = this.onDelete.bind(this)
        this.changeName = this.changeName.bind(this)
        this.state = {
            showOptions: false,
            block: {}
        }
    }

    componentDidMount() {
        this.setState({
            block: this.props.block
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props !== prevProps) {
            this.setState({
                block: this.props.block
            })
        }
    }

    init() {
        const types = {
            "HEADER": {
                type: "En-tête",
                name: this.state.block.name ? this.state.block.name : "",
                render: <HeaderBlockEdit data={this.state.block.data} onChange={(data) => this.onChange(data)}/>
            },
            "INPUT": {
                type: "Contenu éditable",
                name: this.state.block.name ? this.state.block.name : "",
                render: <InputBlockEdit inputs={this.state.block.data} onChange={(data) => this.onChange(data)}/>
            },
            "TEXT": {
                type: "Texte",
                name: this.state.block.name ? this.state.block.name : "",
                render: <TextBlockEdit data={this.state.block.data} onChange={(data) => this.onChange(data)}/>
            },
            "FOOTER": {
                type: "Pied de page",
                name: this.state.block.name ? this.state.block.name : "",
                render: <FooterBlockEdit data={this.state.block.data} onChange={(data) => this.onChange(data)}/>
            },
            "DIVIDER": {
                type: "Séparateur",
                render: <DividerBlockEdit id={this.state.block.id} data={this.state.block.data}
                                          onChange={(data) => this.onChange(data)}/>
            },
            "GROUP": {
                type: "Groupe",
                name: this.state.block.name ? this.state.block.name : "",
                render: <GroupBlockEdit id={this.state.block.id} data={this.state.block.data}
                                        onChange={(data) => this.onChange(data)}/>
            },
            "IMAGE": {
                type: "Image",
                name: this.state.block.name ? this.state.block.name : "",
                render: <ImageBlockEdit id={this.state.block.id} data={this.state.block.data}
                                        onChange={(data) => this.onChange(data)}/>
            },
        }
        return types[this.state.block.type] || null
    }

    toggle(e) {
        e.preventDefault()
        this.props.onExpended(this.state.block)
    }

    changeName(name) {
        this.setState(
            {block: Object.assign({}, this.state.block, {name: name})},
            () => this.props.onChange(this.state.block)
        )
    }

    onChange(data) {
        this.setState({
            block: Object.assign({}, this.state.block, {data: data})
        }, () => this.props.onChange(this.state.block))
    }

    onDelete(e) {
        e.stopPropagation()
        this.props.onDelete(this.state.block)
    }

    async toClipboard(e, block) {
        e.stopPropagation()
        localStorage.setItem('COPIED_BLOCK', JSON.stringify(block));
        toast.success("Élément copié avec succès.")
    }

    color() {
        if(this.state.block.type === "DIVIDER") return "grey lighten-2"
        if(this.props.dragging && this.state.block.type === "GROUP") return "blue lighten-5"
        else return "white"
    }

    render() {
        const block = this.init()
        if (block) {
            return (
                <div className={`${this.color()} z-depth-1`}
                     style={{width: "100%"}}
                     onMouseEnter={() => this.props.isHovered(true)}
                     onMouseLeave={() => this.props.isHovered(false)}
                >
                    {/*HEAD*/}
                    <div style={{display: "flex"}}>
                        <a href="#" style={{width: "100%"}}
                           onClick={(e) => this.toggle(e)}
                           onMouseEnter={() => this.setState({showOptions: true})}
                           onMouseLeave={() => this.setState({showOptions: false})}
                        >
                            <div className="black-text valign-wrapper"
                                 style={{display: "flex", padding: "2vh 1vw 2vh 2vw"}}>
                                <div style={{margin: 0}}>
                                    <h5 style={{margin: "1vh 0"}}>{block.name || block.type}</h5>
                                    <h6 className={`${this.state.expended ? "hide" : ""}`}
                                        style={{paddingLeft: "1vw", color: "#6a6a6a"}}
                                    >{block.name ? block.type : ""}</h6>
                                </div>
                                <div style={{marginLeft: "auto"}}>
                                    <div className="valign-wrapper">
                                        <div id="hoverable">
                                            <Tooltip id={`copy_block_${this.state.block.id}`} text="Copier l'élément.">
                                                <div onClick={(e) => this.toClipboard(e, this.state.block)}
                                                     className={`btn-floating btn-small z-depth-0 transparent ${this.state.showOptions ? "" : "hide"}`}>
                                                    <i className="material-icons green-text">content_copy</i>
                                                </div>
                                            </Tooltip>
                                        </div>
                                        <div id="hoverable">
                                            <Tooltip id={`delete_block_${this.state.block.id}`}
                                                     text="Supprimer l'élément.">
                                                <div onClick={(e) => this.onDelete(e)}
                                                     className={`btn-floating btn-small z-depth-0 transparent ${this.state.showOptions ? "" : "hide"}`}>
                                                    <i className="material-icons red-text">delete</i>
                                                </div>
                                            </Tooltip>
                                        </div>
                                        <div className="btn-floating btn-small waves-light z-depth-0 transparent"
                                             style={{transition: "all 1s"}}>
                                            <i className="material-icons grey-text"
                                               style={{
                                                   transform: `rotate(${this.props.expended ? "90deg" : "0"})`,
                                                   transition: "all 0.5s"
                                               }}>
                                                chevron_right
                                            </i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    {this.props.expended ? (
                        <div>
                            <div className={`${this.state.block.type === "DIVIDER" ? "hide" : ""}`}
                                 style={{padding: "0 2vw 2vh 2vw"}}>
                                Nom
                                <InputText
                                    defaultValue={this.state.block.name}
                                    width="100%"
                                    onChange={(event) => this.changeName(event.target.value)}
                                />
                            </div>
                            <div className="black-text" style={{display: "flex", padding: "0 2vw 2vh 2vw"}}>
                                {block.render}
                            </div>
                        </div>
                    ) : null}
                </div>
            );
        } else return null;
    }
}

BlockEdit.defaultProps = {
    block: {}
}