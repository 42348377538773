import React, {Component} from "react";
import InputText from "../../../../Shared/UiKit/InputText";
import JustificationSelect from "../JustificationSelect";
import FontSelect from "../FontSelect";

export default class DividerBlockEdit extends Component {
    constructor(props) {
        super(props);
        this.changePadding = this.changePadding.bind(this)
        this.toggleWithLine = this.toggleWithLine.bind(this)
        this.state = {
            data: {
                padding: "",
                withLine: true
            }
        }
    }

    componentDidMount() {

        this.setState({data: this.props.data})
        document.getElementById(`divider_checkbox_${this.props.id}}`).checked = this.props.data.withLine
        document.getElementById(`divider_range_${this.props.id}}`).value = this.props.data.padding
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props !== prevProps) {
            this.setState({data: this.props.data})
        }
    }

    changePadding(event) {
        this.setState({
            data: Object.assign({}, this.state.data, {padding: event.target.value})
        }, () => this.props.onChange(this.state.data))
    }

    toggleWithLine() {
        this.setState({
            data: Object.assign({}, this.state.data, {withLine: !this.state.data.withLine})
        }, () => this.props.onChange(this.state.data))
    }


    render() {
        if (this.state.data) {
            return (
                <div style={{width: "100%"}}>
                    <div className="switch valign-wrapper">
                        <h5 style={{marginBottom: "1vh"}}>Avec ligne</h5>
                        <label>
                            <input type="checkbox"
                                   onChange={this.toggleWithLine}
                                   id={`divider_checkbox_${this.props.id}}`}/>
                            <span className="lever"></span>
                        </label>
                    </div>
                    <div className="valign-wrapper">
                        <h5>Espacement</h5>
                        <div style={{padding: "0 1vw", width: "100%"}}>
                            <input type="range" id={`divider_range_${this.props.id}}`} min="0" max="50"
                                   onChange={(event) => this.changePadding(event)}
                                   style={{
                                borderRadius: "15px",
                                backgroundColor: "#c2c0c2",
                                borderColor: "transparent"
                            }}/>
                        </div>
                    </div>
                </div>
            );
        } else return null;
    }
}