import React from "react"
import axios from "axios"
import {observer} from "mobx-react"
import MesureStore from '../MesureStore'
import PrescriptionStore from "../../PrescriptionStore"
import {PrescriptionType} from "../../../Shared/Enums/Enums";

@observer
class TypePrescription extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this)
        this.state = {
            typesdemesures: []
        };
    }

    componentDidMount() {
        axios.get("/api/typesPrescriptions")
            .then((data) => {
                this.setState({typesdemesures: data.data})
            })
    }


    handleClick(e) {
        e.preventDefault()
        PrescriptionStore.addPrescription(this.state.typesdemesures[e.target.id], MesureStore)

    }

    render() {
        const types = Object.values(PrescriptionType)
        const mesureSelected = !!MesureStore.typedemesure
        return (
            <div>
                <h3 className="center">Types</h3>
                <div style={{height: "1.8em"}}></div>
                <div className="collection">
                    {this.state.typesdemesures.sort((m1, m2) => types.indexOf(m1.titre) > types.indexOf(m2.titre)).map((type, index) => (
                        <a onClick={mesureSelected ? this.handleClick : () => null}
                           id={index}
                           key={type.id}
                           className={`collection-item ${mesureSelected ? "blue-text" : "grey-text"}`}
                           data-cy="PrescriptionType">{type.titre}</a>
                    ))}
                </div>
            </div>
        );
    }
}

export default TypePrescription
