import React from "react";
import PatientInfo from "./PatientInfo";
import ExamHistory from "./ExamHistory";
import PatientSideBar from "../../patient_data/SideBar/PatientSideBar";
import LastPrescription from "./LastPrescription";

export default function Overview({
                                     data,
                                     cle,
                                     orthoptiste_signed,
                                     ophtalmologiste_signed,
                                     secretaire_signed,
                                     level_2
                                 }) {
    return (
        <div id="full-height" style={{padding: "2vh 2vw", display: "grid", gridTemplateRows: "60% 38%", gridTemplateColumns: "30% 70%", gap: 20}}>
            <PatientSideBar
                style={{gridColumn: 1, gridRow: 2}}
                patient={data.patient}/>
            <PatientInfo
                style={{gridColumn: 1, gridRow: 1}}
                patient={data.patient}
                office_name = {data.office_name}
                cle={cle}
                orthoptiste_signed={orthoptiste_signed}
                ophtalmologiste_signed={ophtalmologiste_signed}
                secretaire_signed={secretaire_signed}/>
            <ExamHistory
                style={{gridColumn: 2, gridRow:"1 / span 2"}}
                data={data}
                orthoptiste_signed={orthoptiste_signed}
                level_2={level_2}/>
        </div>

    )
}