import {action, makeObservable, observable} from "mobx"

class ReportsStore {
    @observable selectedTemplateId = null

    constructor() {
        makeObservable(this)
    }

    @action
    uploadImage = (image_file) => {
        const formData = new FormData()
        formData.append("image_data", image_file)
        formData.append("template_id", this.selectedTemplateId)

        return fetch("/orthoptistes/reports/image", {
            method: "PUT",
            body: formData
        }).then(response => response.json())
    }
}

let store = new ReportsStore
global.ReportsStore = store
export default store