import React from "react";
import BillingOverview from "./BillingOverview";
import Simulate from "../Simulate";
import PricesOverview from "./PricesOverview";

function BillingManager({orthoptist, billing}) {
    return (
        <div className="full-height" style={{display: "flex", flexDirection: "column", gap: 20, padding: 20}}>
            <h1 className="center" style={{margin: 0}}>Gestion des paiements</h1>
            <BillingOverview last4={orthoptist.last4}
                             bankCode={orthoptist.bank_code}
                             bankTitle={orthoptist.bank_title}
                             lastPaymentDate={Helper.formatDate(orthoptist.last_payment)}
                             lastDue={billing.last_due}
                             nextDue={billing.next_due}
                             fixed={orthoptist.fixed_amount / 100.0}
                             variable={orthoptist.variable_amount / 100.0}
            />
            <div className="row" style={{gap: 20}}>
                <div className="col s12 l6 card">
                    <PricesOverview prices={billing.prices}
                                    fixed={orthoptist.fixed_amount / 100.0}
                                    variable={orthoptist.variable_amount / 100.0}
                    />
                </div>
                <div className="col s12 l6 card">
                    <Simulate/>
                </div>
            </div>
        </div>
    );
}

export default BillingManager;