import React from "react";

function ChoosePatientFromDB(props) {
    if (props.patients.length === 1) {
        const patient = props.patients[0];
        return (
            <div>
                <h3>Un patient correspond à la recherche</h3>
                <div className="row">
                    <div className="col s6">
                        <h5>Prénom</h5>
                        <h4>{Helper.toCamelCase(patient.prenom)}</h4>
                    </div>
                    <div className="col s6">
                        <h5>Nom</h5>
                        <h4>{Helper.toCamelCase(patient.nom)}</h4>
                    </div>
                </div>
                <a className="btn blue darken-2 z-depth-0" href={`/NewAppointement/${patient.id}`}>Commencer examen</a>
            </div>
        )
    } else if (props.patients.length > 1) {
        return (
            <div>
                <h3>Plusieurs patients correspondent à la recherche</h3>
                <ul className="collection" style={{borderRadius: "20px"}}>
                    {props.patients.map((patient, index) => {
                        return (
                            <li className="collection-item" key={index}>
                                <div className="valign-wrapper">
                                    {Helper.toCamelCase(patient.prenom)} {Helper.toCamelCase(patient.nom)}
                                    <a className="btn btn-small blue darken-2 z-depth-0" style={{marginLeft: "auto"}}
                                       href={`/NewAppointement/${patient.id}`}>Commencer
                                        examen</a>
                                </div>
                            </li>
                        )
                    })
                    }
                </ul>
            </div>
        )
    }
}

export default ChoosePatientFromDB;