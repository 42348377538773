import React, {Component} from "react";

export default class MultiChooseBox extends Component {
    constructor(props) {
        super(props);
        this.onSelection = this.onSelection.bind(this)
        this.isActive = this.isActive.bind(this)
        this.state = {
            selectedValue: undefined,
            values: []
        }
    }

    componentDidMount() {
        this.state.values = this.props.values.map(o => {
            return this.props.offset > o.length ? o.concat(new Array(this.props.offset - o.length + 1).fill("")) : o
        })
    }

    onSelection(i1, i2) {
        if (this.props.values[i1][i2] !== undefined) {
            let newValue = ""
            if (this.props.pickOnlyOne === true) {
                if (this.props.values[i1][i2].replace("*", "") !== this.props.selectedValue) {
                    newValue = this.props.values[i1][i2].replace("*", "")
                }
                const value = newValue
                this.props.onChoose(value)
            } else {
                if (this.props.values[i1][i2].replace("*", "") !== this.props.selectedValues[i1]) {
                    newValue = this.props.values[i1][i2].replace("*", "")
                }
                const values = this.props.selectedValues.map((v, index) => (index === i1 ? newValue : v))
                this.props.onChoose(values.join(''))
            }
        }
    }

    isActive(i1, value) {
        const v = value.replace("*", "")
        return this.props.pickOnlyOne === true ? this.props.selectedValue === v : this.props.selectedValues[i1] === v
    }

    render() {
        if (!this.props.hasValue && !this.props.mesureDisplayList.includes(this.props.type.value)) return null
        return (
            <div className="col xl2 s4" style={{borderRight: "thin lightgrey solid"}}>

                <div className="center-align">
                    <h2 className="center-align">{this.props.type.name}</h2>
                    <p className="grey-text center-align" style={{fontSize: "0.8em"}}>{this.props.subtitle}</p>
                    <div className="row" style={{fontSize: "small"}}>
                        {
                            this.state.values.map((subValues, i1) => (
                                <div key={i1} className={"collection col s" + 12 / this.props.values.length}
                                     style={{paddingLeft: '0px', paddingRight: '0px', borderRadius: "0px"}}
                                >
                                    {
                                        subValues.map((value, i2) => (
                                            <a key={`${i1}_${i2}`}
                                               className={"collection-item" + (this.isActive(i1, value) ? " active" : "")}
                                               style={{
                                                   padding: "0.5vh 0vw 0.5vh 0vw",
                                                   backgroundColor: value === "" ? "transparent" : ""
                                               }}
                                               onClick={(e) => this.onSelection(i1, i2)}>{
                                                value === "" ? <br/> : value.replace("*", this.props.suffix)
                                            }
                                            </a>
                                        ))
                                    }
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        )


    }
}

MultiChooseBox.defaultProps = {
    type: {},
    subtitle: "",
    suffix: "",
    offset: 0,
    values: [["+", "-"], ["1", "2", "3", "4", "5", "6", "7"], [".25", ".50", ".75"]],
    selectedValues: [],
    pickOnlyOne: false,
    onChoose: (e) => {
        return true
    },
}