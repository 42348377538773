import React, { Component } from 'react';

import BridgeStore from "./BridgeStore"
import {observer} from "mobx-react"

@observer
export default class ConnecteurV2 extends Component {
    constructor(props) {
        super(props)
        BridgeStore.init(props.bridge_uid,props.api_token,props.email,props.id)
    }
    componentWillUnmount(){
        //BridgeStore.disconnect()
    }
    render() {
        return (
            <div>
            </div>
        );
    }
}
