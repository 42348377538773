import React, { Component } from 'react';

export default class Background extends Component {
  constructor(props){
    super(props);
    this.state = {}
  }
  render() {
    return (
      <div></div>
    );
  }
}
