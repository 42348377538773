import React, {useEffect, useState} from "react";
import axios from "axios";
import {Link, useParams} from "react-router-dom";
import Modal from '@material-ui/core/Modal';
import {toast} from "react-toastify";

export function TeamOphtalmologists(props) {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    const [ophtalmologists, setOphtalmologists] = useState(props.ophtalmologists);
    const [open, setOpen] = useState(false);
    const [ophtalmologistToAdd, setOphtalmologistToAdd] = useState({});

    const handleOpen = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    }
    const editForm = (e) => {
        let formElement = e.target.id;
        let value = e.target.value;
        setOphtalmologistToAdd({...ophtalmologistToAdd, [formElement]: value});
    }

    const handleSave = () => {
        axios.post("/admin/console/team/ophtalmologist", {data: ophtalmologistToAdd}).then((response) => {
            toast.success("Ophtalmologiste ajouté avec succès");
            setOphtalmologists([...ophtalmologists, response.data.ophtalmologist]);
            handleClose();
        }).catch((_) => {
            toast.error("Une erreur est survenue");
        })
    }

    return (
        <div>
            <h1 className="center">Gestion des ophtalmologistes</h1>
            <p style={{marginLeft: "15px"}}>
                <a className={"btn-floating btn-small waves-effect waves-light green"} onClick={(e) => {
                    e.preventDefault();
                    handleOpen();
                }}>
                    <i className="material-icons left">add</i> Nouveau ophtalmologiste
                </a>
            </p>
            <table className="striped centered responsive-table">
                <thead>
                <tr>
                    <th> id</th>
                    <th>Nom</th>
                    <th>Prénom</th>
                    <th>Adresse mail</th>
                    <th>RPS</th>
                    <th>ADELI</th>
                </tr>
                </thead>
                <tbody>
                {ophtalmologists.map((ophtalmologist) => {
                    return (
                        <tr key={ophtalmologist.id}>
                            <td>{ophtalmologist.id}</td>
                            <td>{ophtalmologist.nom}</td>
                            <td>{ophtalmologist.prenom}</td>
                            <td>{ophtalmologist.email}</td>
                            <td>{ophtalmologist.rps}</td>
                            <td>{ophtalmologist.adeli}</td>

                        </tr>
                    )
                })}
                </tbody>
            </table>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="Nouvel ophtalmologiste"
                aria-describedby="Nouvel ophtalmologiste"
                style={{
                    border: 'none',
                }}
            >
                <div className="modal-content"
                     style={{
                         position: 'absolute',
                         top: '50%',
                         left: '50%',
                         transform: 'translate(-50%, -50%)',
                         width: 700,
                         background: "white",
                         border: 'none',
                         padding: "20px"

                     }}
                >
                    <h4 className="center">Nouvel ophtalmologiste</h4>
                    <div className="row">
                        <form className="col s12">
                            <div className="row">
                                <div className="input-field col s6">
                                    <input id="nom" type="text" className="validate" onChange={editForm}/>
                                    <label htmlFor="nom">Nom</label>
                                </div>
                                <div className="input-field col s6">
                                    <input id="prenom" type="text" className="validate" onChange={editForm}/>
                                    <label htmlFor="prenom">Prénom</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-field col s6">
                                    <input id="email" type="email" className="validate" onChange={editForm}/>
                                    <label htmlFor="email">Adresse mail</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-field col s6">
                                    <input id="rps" type="text" className="validate" onChange={editForm}/>
                                    <label htmlFor="rps">RPS</label>
                                </div>
                                <div className="input-field col s6">
                                    <input id="adeli" type="text" className="validate" onChange={editForm}/>
                                    <label htmlFor="adeli">ADELI</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-field col s6">
                                    <input id="password" type="password" className="validate" onChange={editForm}/>
                                    <label htmlFor="password">Mot de passe</label>
                                </div>
                                <div className="input-field col s6">
                                    <input id="password_confirmation" type="password" className="validate"
                                           onChange={editForm}/>
                                    <label htmlFor="password_confirmation">Confirmation du mot de passe</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-field col s6">
                                    <a className="btn waves-effect waves-light submit" onClick={(e) => {
                                        e.preventDefault();
                                        handleSave();
                                    }}>
                                        <i className="material-icons left">add</i> Ajouter
                                    </a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>

        </div>
    );

}