import React, {Component} from "react";

export default class ChipsInput extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this)
    }

    componentDidMount() {
        let data = []
        var elems = document.getElementById(`chips_${this.props.name}`);
        var instances = M.Chips.init(elems, {onChipAdd: ((e) => this.onChange(e)), onChipDelete: this.onChange});
        if(this.props.options) this.props.options.map((option) => instances.addChip({tag: option}))
    }

    onChange(e) {
        let options = []
        e.childNodes.forEach((node) => {
            node.firstChild ? options.push(node.firstChild.data) : null
        })
        this.props.onChange(options.sort((a,b)=> {
            if (parseFloat(a) === 0) {
                return -1
            } else if (parseFloat(b) === 0) {
                return 1
            } else if(parseFloat(a) && parseFloat(b)) {
                return a-b
            } else if (parseFloat(a) && !parseFloat(b)) {
                return -1
            } else if (parseFloat(b) && !parseFloat(a)) {
                return 1
            } else return 0
        }))
    }

    render() {
        return (
            <div className="chips chips-initial" id={`chips_${this.props.name}`}></div>
        );
    }
}

ChipsInput.defaultProps = {
    name:"",
    options: []
}