import React, { Component } from 'react';
import axios from 'axios'
import Scan from '../Pages/Scan'
import { HashRouter as Router, Route, Link  } from "react-router-dom"
import BridgeStore from "../BridgeStore"
import {observer} from "mobx-react"

@observer
export default class Connect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      local_search_status: 'Recherche en cours'
    }
    this.search()
  }
  search(){
    const instance = axios.create();
    instance.defaults.timeout = 1000;

    instance.get("http://127.0.0.1:5000/info").then((data)=>{
      this.setState({
        local_search_status: "Le logiciel Temeoo a été détecté, vous êtes déshormais connecté dessus"
      })
    BridgeStore.connect("127.0.0.1")
    }).catch((err)=>{
      this.setState({
        local_search_status: "Le logiciel Temeoo ne semble pas être lancé sur votre ordinateur"})
      setTimeout(()=>{
        this.search()
      }, 1000)
    })
  }
  render() {
    return (
      <div>
        <div className='container'>
          {BridgeStore.connected ? (
          <p style={{textAlign: 'right'}}><Link to="Unmount" className="btn btn-large mauve"> Continuer</Link></p>
          ) : (
            <span></span>
          )}
        </div>
        <div className="row">
          <div className="col s6">
            <h2 className="center"> Connexion avec le logiciel installé sur votre ordinateur</h2>
            <p className="center"> {this.state.local_search_status}</p>
          </div>
          <div className="col s6">
            <h2 className="center"> Vous possédez une box Temeoo ? sélectionnez la ici</h2>
            <Scan />
          </div>
        </div>
      </div>
    );
  }
}
