import React from "react"
import PropTypes from "prop-types"
import axios from "axios"

class LoadAccounts extends React.Component {
    constructor(props) {
        super(props);
        this.loadData = this.loadData.bind(this)
        this.saveAgenda = this.saveAgenda.bind(this)
        this.state = {
            clicked: false,
            data: [],
            loading: true,
            done: false,
            agenda: ""
        };
    }

    saveAgenda(e) {
        e.preventDefault()

        axios.post("/cabinets.json", {cabinet: {titre: this.state.agenda, doctolib_id: ""}}).then((data) => {
            this.setState({done: true})
        })
    }

    loadData() {
        this.setState({loading: false, clicked: true})

    }

    componentDidMount() {
        var elems = document.getElementById("new_cabinet_modal");
        var instances = M.Modal.init(elems, {});
    }

    componentWillUnmount() {
        M.Modal.getInstance(document.getElementById("new_cabinet_modal")).destroy();

    }

    showagendas() {
        if (this.state.done) {
            return (
                <p>Bien été sauvegardé</p>
            )
        } else {
            if (this.state.loading) {
                return (
                    <p>Chargement en cours </p>
                )
            } else {
                return (
                    <div>
                        <div className="row">
                            <div className="input-field col s12">
                                <input type="text" id="title" value={this.state.agenda}
                                       onChange={(e) => this.setState({agenda: e.target.value})}/>
                                <label htmlFor="title">Entrez le titre du cabinet</label>
                            </div>
                        </div>
                        <br/>
                        <a onClick={this.saveAgenda} className="btn blue darken-2 z-depth-0"> <i
                            className="material-icons left"> save </i> Sauver</a>
                    </div>
                )
            }

        }
    }

    render() {
        return (
            <React.Fragment>
                <div>
                    <a onClick={this.loadData} className="btn blue darken-2 z-depth-0 modal-trigger"
                       data-target="new_cabinet_modal">Nouveau cabinet</a>
                    <div id="new_cabinet_modal" className="modal">
                        <div className="modal-content">
                            {this.showagendas()}
                        </div>
                        <div className="modal-footer">
                            <a className="modal-close waves-effect waves-green btn-flat">Annuler</a>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default LoadAccounts
