import React, {useEffect, useState} from "react";
import axios from "axios";
import InputText from "../../Shared/UiKit/InputText";
import {toast} from "react-toastify";
import Tooltip from "../../Shared/Tootip";
import generatePdf from "../../Shared/PDF/PDFManager";
import Helper from "../../Shared/Helper";
import moment from "moment";

function ChainedActsRequest(props) {
    const [chainableActs, setChainableActs] = useState([]);
    const [selectedChainableId, setSelectedChainableId] = useState(-1);
    const [amountOfActs, setAmountOfActs] = useState(-1);
    // const [selectedChainableId, setSelectedChainableId] = useState(14);
    // const [amountOfActs, setAmountOfActs] = useState(12);

    useEffect(() => {
        const select = document.getElementById("chainable_acts_select");
        M.FormSelect.init(select, {});
        getChainableActs()
    }, [])
    const getChainableActs = () => {
        axios.get("/orthoptistes/chained_acts/chainable").then((resp) => {
            setChainableActs(resp.data);
            const select = document.getElementById("chainable_acts_select");
            M.FormSelect.init(select, {});
        })
    }

    const createActChain = () => {
        const patient_id = MesureStore.patient_id
        axios.put("/orthoptistes/chained_acts", {
            act_id: selectedChainableId,
            patient_id: patient_id,
            amount: amountOfActs
        }).then((resp) => {
            toast.success("La chaîne d'actes a été créée avec succès.")
            MesureStore.selectedChain = resp.data.chain
            MesureStore.chains.push(resp.data.chain)
        }).catch((err) => {
            if (err.response && err.response.status === 409) {
                toast.error("Ce patient est déjà dans une série pour cet acte.")
                return
            }
            toast.error("Une erreur est survenue lors de la création de la chaîne d'actes.")
        })
    }

    const handleAmountOfActsChange = (e) => {
        const v = e.target.value
        if (v.match(/^[0-9]*$/)) {
            setAmountOfActs(parseInt(v) || -1)
        }
    }

    const generatePDF = async () => {
        const cvNum = MesureStore.patient.num_carte_vitale ? MesureStore.patient.num_carte_vitale.replace(" ", "").substring(0, 13) : ""
        const cvKey = MesureStore.patient.num_carte_vitale ? MesureStore.patient.num_carte_vitale.replace(" ", "").substring(13, 15) : ""
        const act = chainableActs.find((a) => a.id === selectedChainableId)
        const patient = MesureStore.patient
        const orthoptiste = MesureStore.orthoptiste
        const formData = {
            'NOM ET PRENOM BEN': Helper.formatName(patient.prenom, patient.nom),
            'DATE RECEPT': Helper.formatDate(new Date(), "DDMMYYYY"),
            'NUM IMMAT BEN': cvNum,
            'CLE BEN': cvKey,
            'DATE NAISS BEN': moment(patient.birthday).format("DDMMYYYY"),
            'CODE ACTE': `${amountOfActs} ${act.actCode} ${act.coefficient / 100}`,
            'ACT SERIE': true,
            'NOM ET PRENOM PRAT': Helper.formatName(orthoptiste.prenom, orthoptiste.nom),
            'IDENT PRAT': orthoptiste.rpps,
            'DATE AP': Helper.formatDate(new Date(), "DDMMYYYY"),
        }
        await generatePdf('/cerfa/cerfa-12040_temeoo.pdf', formData)
    }

    const renderExistingActsWarning = () => {
        if (MesureStore.chains.length > 0) {
            const chainsNb = MesureStore.chains.length
            return <div>
                <h5 style={{marginBottom: 5}}>
                    {chainsNb === 1 ? "Ce patient est déjà dans une série d'actes :" :
                        `Ce patient est déjà dans ${chainsNb} séries d'actes :`}
                </h5>
                <div style={{marginLeft: 10}}>
                    {MesureStore.chains.map((chain, index) => {
                        return <div key={index}>
                            <h6>{chain.act.titre} : {chain.nb_remaining} restants sur {chain.amount}.</h6>
                        </div>
                    })}
                </div>

            </div>
        }
    }

    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            {renderExistingActsWarning()}
            <h5>Créer une nouvelle série d'actes</h5>
            <div>
                <h6>Type d'acte</h6>
                <select id="chainable_acts_select" className="browser-default" defaultValue=""
                        onChange={(e) => setSelectedChainableId(parseInt(e.target.value))}>
                    <option value="" disabled hidden={true}></option>
                    {chainableActs.map((act, index) => {
                        return <option key={index} value={act.id}>{act.titre}</option>
                    })}
                </select>
                <h6>Nombre d'actes</h6>
                <InputText width="100%" onChange={(e) => handleAmountOfActsChange(e)}
                           value={amountOfActs < 0 ? "" : amountOfActs}/>
            </div>
            <div style={{paddingTop: "10px", display: "flex"}}>
                <a className={`btn blue darken-2 z-depth-0 ${selectedChainableId < 0 || amountOfActs < 0 ? "disabled" : ""}`}
                   style={{width: "calc(100% - 60px)", borderRadius: "4px 0px 0 4px", margin: 0}}
                   onClick={createActChain}>Créer série</a>
                <Tooltip id="dap_tooltip" text="Editer une demande d'admission préalable"
                         disabled={selectedChainableId < 0 || amountOfActs < 0}>
                    <a className={`btn blue darken-4 z-depth-0 center-align ${selectedChainableId < 0 || amountOfActs < 0 ? "disabled" : ""}`}
                       style={{width: "60px", borderRadius: "0 4px 4px 0", margin: 0}}
                       onClick={() => generatePDF()}>
                        <i className="material-icons">picture_as_pdf</i></a>
                </Tooltip>
            </div>
        </div>
    );
}

export default ChainedActsRequest;