import React, { Component } from 'react';

export default class Mirror extends Component {
  constructor(props){
    super(props);
    this.detectKey = this.detectKey.bind(this);
    this.state = {
    }
  }

  detectKey(evt){
    evt = evt||window.event // IE support
    var c = evt.keyCode
    var ctrlDown = evt.ctrlKey||evt.metaKey // Mac support

    if (ctrlDown && c==77){
      App.mirror.send({
        url: window.location.href
      })
    }

    return true
  }
  componentDidMount(){
    document.addEventListener("keydown", this.detectKey, false);
    App.mirror = App.cable.subscriptions.create({channel: 'MirrorChannel',id: this.props.id}, {
          received: function(data) {
            data = data.data
              if(data.url !== window.location.href){
                window.location.href  = data.url
              }
            }

        });

        global.mirror=App.mirror
  }
  componentWillUnmount(){
    document.removeEventListener("keydown", this.detectKey, false);
    App.mirror.unsubscribe()

  }
  render() {
    return (
      <div></div>
    );
  }
}
