import {observer} from "mobx-react";
import React, { Component } from 'react';
import BridgeStore from "../../BridgeStore";
import {Link} from "react-router-dom";
import ReactPlayer from "react-player"


@observer
export default class SambaForm extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.renderPrerenderedMachine = this.renderPrerenderedMachine.bind(this)
        this.addMachine = this.addMachine.bind(this)
        this.state = {
            invalid:true,
            value: '',
            marque:'',
            modele:'',
            pattern:'',
            parser:0,
        };


    }



    renderPrerenderedMachine(){
        return(
            <div className="row">
                <div className="col s6">
                    <div className="form-group">
                        <label>Marque</label>
                        <input type="text" className="form-control" value={this.state.marque} readOnly={true} required/>
                    </div>
                </div>
                <div className="col s6">
                    <div className="form-group">
                        <label>Modèle</label>
                        <input type="text" className="form-control" value={this.state.modele} readOnly={true} required/>
                    </div>
                </div>

                <div className="col s6">
                    <div className="form-group">
                        <label>Pattern</label>
                        <input type="text" className="form-control" value={this.state.pattern} readOnly={true} required/>
                    </div>
                </div>

                <div className="col s4">
                    <a href="/orthoptistes/v2/bridge" onClick={this.addMachine} disabled={this.state.invalid} className="btn btn-large mb-2 align-bottom" style={{margin: 10}}><i className="material-icons right">send</i>Ajouter</a>
                </div>
              </div>
            )
    }

    handleChange(event) {
        let value = event.target.value
        if (event.target.value ===""){
            this.setState({
                invalid:true,
                value: '',
                marque:'',
                modele:'',
                pattern:'',
                parser:0,

            });
        }
        else{
            this.setState({
                invalid:false,
                value: value,
                marque:this.props.db_machines_samba[value].marque,
                modele:this.props.db_machines_samba[value].modele,
                pattern:this.props.db_machines_samba[value].pattern,
                parser: this.props.db_machines_samba[value].parser,
            });
        }


    }

    addMachine(){
        BridgeStore.addNewSamba(this.state.modele, this.state.marque, this.state.pattern, this.state.parser)
    }

    render() {
        return (
            <div className="container">
                <form onSubmit={this.addMachine}>
                    <div className="input-field">
                        <select onChange={this.handleChange} value={this.state.value} className="browser-default">
                            <option value="">Marque et modele de la machine</option>
                            {this.props.db_machines_samba.map((mesure,index)=>(
                                <option key={index} value={index}>{mesure.marque + " " + mesure.modele}</option>
                            ))}
                        </select>
                    </div>
                    {this.renderPrerenderedMachine()}
                </form>
            </div>
        );
    }
}


