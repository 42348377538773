import React from "react";
import Tooltip from "../../Shared/Tootip";
import BrandedElement from "../../Shared/BrandedElement";
import {InvoiceStatus} from "../../Shared/Enums/Enums";


const _ = require('lodash')
function Overview({invoices}) {

    const renderStatus = (invoice) => {
        switch (invoice.status) {
            case InvoiceStatus.WAITING:
                return <Tooltip id={`tooltip-invoice-waiting-${invoice.id}`} text="Le paiement va bientôt être traité.">
                    <i className="material-icons grey-text">schedule</i>
                </Tooltip>
            case InvoiceStatus.PENDING:
                return <Tooltip id={`tooltip-invoice-pending-${invoice.id}`}
                                text="Cette facture est en attente de paiement sur la plateforme Stripe.">
                    <i className="material-icons orange-text">pending</i>
                </Tooltip>
            case InvoiceStatus.SUCCESS:
                return <Tooltip id={`tooltip-invoice-success-${invoice.id}`} text="Cette facture a été réglée.">
                    <i className="material-icons green-text">done</i>
                </Tooltip>
            case InvoiceStatus.FAILED:
                return <Tooltip id={`tooltip-invoice-error-${invoice.id}`} text="Une erreur a empêché le paiement.">
                    <i className="material-icons red-text">error</i>
                </Tooltip>
            default: return null
        }
    }

    const renderLine = (invoice, index) => {
        return (
            <div className="row valign-wrapper" style={{margin: 0}} key={index}>
                <div className="col s1">{renderStatus(invoice)}</div>
                <div className="col s3">
                    {`${((invoice.totalPlateforme) / 100).toFixed(2)}€`}
                </div>
                <div className="col s6">
                    {`Facture du ${new Date(invoice.created_at).toLocaleDateString("fr")}`}
                </div>
            </div>
        )
    }

    const renderInvoices = () => {
        if (_.isEmpty(invoices)) return <h2 className="center">Aucune facture n'est disponible
            actuellement.</h2>
        return (
            <div className="container">
                <h1 className="center" id="main-title">Factures</h1>
                <ul className="collection" style={{borderRadius: "20px"}}>
                    <div style={{paddingTop: "10px"}}/>
                    {invoices.map((invoice, index) =>
                        <li key={index} className="collection-item">{renderLine(invoice, index)}</li>
                    )}
                </ul>
            </div>
        )
    }

    return (
        <BrandedElement temeoo>
            {renderInvoices()}
        </BrandedElement>
    )
}

export default Overview

Overview.defaultProps = {
    invoices: []
}