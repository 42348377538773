import React, {useEffect} from "react";
import ApplicationMonitoring from "./ApplicationMonitoring";
import ApplicationsOverview from "./Overviews/ApplicationsOverview";
import {RequestState} from "../../../../Shared/StateHelper";
import axios from "axios";
import Loader from "../../../../Shared/Loader";
import Error from "../../../../Shared/Error";

function ApplicationMonitoringConsole() {
    const [requestState, setRequestState] = React.useState(RequestState.LOADING)
    const [hasRights, setHasRights] = React.useState(false)

    useEffect(() => {
        has_rights()
    }, []);

    const has_rights = () => {
        axios.get("/admin/console/logs/authorized").then((res) => {
            setHasRights(res.data)
            setRequestState(RequestState.SUCCESS)
        }).catch((error) => {
            if(error.response.status === 403) {
                setHasRights(false)
                setRequestState(RequestState.SUCCESS)
                return
            }
            setRequestState(RequestState.ERROR)
        })
    }

    if (requestState === RequestState.LOADING) {
        return <div id="full-height" style={{display: "flex", flexDirection: "column", marginBlock: 100}}>
            <Loader/>
        </div>
    }

    if (requestState === RequestState.ERROR) {
        return <div id="full-height" style={{display: "flex", flexDirection: "column", marginBlock: 100}}>
            <Error errorText={"Une erreur est survenue lors de la récupération des données"}/>
        </div>
    }

    if (!hasRights) {
        return <div id="full-height" style={{display: "flex", flexDirection: "column", marginBlock: 100}}>
            <Error errorText={"Vous n'avez pas le droit d'accéder à cette page"}/>
        </div>
    }


    return <div id="full-height" style={{display: "flex", flexDirection: "column", overflowY: "scroll"}}>
        <h3 className="valign-wrapper" style={{margin: 20}}>
            <i className="material-icons left">query_stats</i>
            Suivi des applications
        </h3>
        <div className="divider"/>
        <div style={{margin: 20}}>
            <ApplicationsOverview/>
        </div>
        <div className="divider"/>
        <div style={{margin: 20}}>
            <ApplicationMonitoring/>
        </div>
    </div>
}

export default ApplicationMonitoringConsole