import {makeObservable,action, observable} from "mobx"
import axios from "axios"

class Store {
    @observable messages = []
    @observable orthoptiste_id = 0
    @observable newMessage = false

    constructor() {
        makeObservable(this)
    }

    @action unsubscribe() {
        if (App.messages != undefined) { // possible fixe
            App.messages.unsubscribe()

        }
    }

    @action send(message, appointement_id) {
        App.messages.send({
            message: message,
            orthoptiste: this.orthoptiste_id,
            appointement_id: appointement_id
        })
    }

    setCable(orhto = 0) {
        var that = this
        this.orthoptiste_id = orhto
        App.messages = App.cable.subscriptions.create({channel: 'MessagesChannel', id: orhto}, {
            received: function (data) {
                that.messages.unshift(data)
            }
        });
    }

    loadMessages(id = undefined, appointement_id) {
        if (id == undefined) {
            axios.get("/messages/orthoptiste/api/messages", {params: {appointement_id: appointement_id}}).then((data) => {
                this.messages = data.data.messages
            })

        } else {
            axios.get("/messages/ophtalmologiste/api/messages/" + id, {params: {appointement_id: appointement_id}}).then((data) => {
                this.messages = data.data.messages
            })
        }
    }

    hasNewMessage(id = undefined, appointement_id) {
        if (id == undefined) {
            // Not yet supported for orthoptists
        } else {
            axios.get("/messages/ophtalmologiste/api/messages/new/" + id, {params: {appointement_id: appointement_id}}).then((data) => {
                this.newMessage = data.data.newMessage
            })
        }
    }

    messagesRead(id = undefined, appointement_id) {
        if (id == undefined) {
            axios.patch(`/messages/orthoptiste/api/messages/${appointement_id}/read`).then((data) => {
                this.newMessage = false
            })
        } else {
            axios.patch(`/messages/ophtalmologiste/api/messages/${id}/${appointement_id}/read`).then((data) => {
                this.newMessage = false
            })
        }
    }
}

let store = new Store()
export default store
