import React from "react";
import RightsStore from "../admin/Console/RightsStore";
import {Observer} from "mobx-react";

function RestrictedElement({field, permission, children}) {

    const authorized = () => {
        return RightsStore.can_do(field, permission)
    }

    return <Observer>
        {() => {
            if (authorized()) return children
            return null
        }}
    </Observer>
}

RestrictedElement.defaultProps = {
    scope: []
}

export default RestrictedElement