import React, {useEffect} from "react";
import axios from "axios";
import Helper from "../Shared/Helper";
import {RequestState} from "../Shared/StateHelper";
import Loader from "../Shared/Loader";
import Error from "../Shared/Error";


function ListPatientAccess(props) {
    const [patient_data, setPatientData] = React.useState(null);
    const [appointments_list, setAppointmentsList] = React.useState([]);
    const [request_status, setRequestStatus] = React.useState(RequestState.LOADING);

    useEffect(() => {
        getAppointments()
    }, [])

    const getAppointments = () => {
        axios.get("/appointment_list", {params: {hide_useless_appointments: true}})
            .then((response) => {
                setPatientData(response.data.patient_data)
                setAppointmentsList(response.data.appointments_list)
                setRequestStatus(RequestState.SUCCESS)
            }).catch((_) => {
            setRequestStatus(RequestState.ERROR)
        })
    }

    const render_appointment = (appointment) => {
        if (appointment.need_to_pay) return <tr key={appointment["id"]}>
            <td className="center">
                {Helper.formatDate(appointment.start)}
            </td>
            <td className="center">
                <h5 className="center" style={{margin: 0}}>
                    <i className="material-icons left blue-text text-darken-2 hide-on-med-and-down">error</i>
                    <span className="hide-on-med-and-down">En attente de paiement</span>
                    <i className="material-icons blue-text text-darken-2 hide-on-large-only hide-on-extra-large-only">error</i>
                </h5>
            </td>
            <td className="center">
                <a className="btn blue darken-2 z-depth-0"
                   style={{overflow: "hidden", minWidth: 200}}
                   href={appointment.payment_infos.link}>Un paiement est en attente
                </a>
            </td>
        </tr>


        let status
        switch (appointment.status_controle) {
            case 2:
                status = <h5 className="center" style={{margin: 0}}>
                    <i className="material-icons left green-text text-darken-2 hide-on-med-and-down">done</i>
                    <span className="hide-on-med-and-down">Prescription disponible</span>
                    <i className="material-icons green-text text-darken-2 hide-on-large-only hide-on-extra-large-only">done</i>
                </h5>
                break
            case 3:
                status = <h5 className="center" style={{margin: 0}}>
                    <i className="material-icons left red-text text-darken-2 hide-on-med-and-down">error</i>
                    <span className="hide-on-med-and-down">Dossier en alerte</span>
                    <i className="material-icons red-text text-darken-2 hide-on-large-only hide-on-extra-large-only">error</i>
                </h5>
                break
            case -1:
                status = <h5 className="center" style={{margin: 0}}>
                    <span>Examen de test</span>
                </h5>
            default:
                status = <h5 className="center" style={{margin: 0}}>
                    <i className="material-icons left orange-text text-darken-2 hide-on-med-and-down">pending</i>
                    <span className="hide-on-med-and-down">En attente de validation</span>
                    <i className="material-icons orange-text text-darken-2 hide-on-large-only hide-on-extra-large-only">pending</i>
                </h5>
        }

        return <tr key={appointment.id}>
            <td className="center">
                {Helper.formatDate(appointment.start)}
            </td>
            <td className="center">
                {status}
            </td>
            <td className="center">
                <a className={`btn green darken-2 z-depth-0 ${appointment.document_count || "disabled"} `}
                   href={`documents/${appointment.id}`} style={{minWidth: 200}}
                >{appointment.document_count} document{appointment.document_count > 1 ? "s" : ""} disponible{appointment.document_count > 1 ? "s" : ""}</a>
            </td>
        </tr>
    }

    const render_appointments = () => {
        if (request_status === RequestState.LOADING) return <div id="full-height" style={{paddingTop: 200}}>
            <Loader text="" size="small"/>
        </div>

        if (request_status === RequestState.ERROR) return <div id="full-height" style={{paddingTop: 200}}>
            <Error errorText="Impossible de charger les informations"/>
        </div>

        if (!appointments_list.length) return <div className="center" style={{marginTop: "200px"}}>Aucun rendez-vous à
            afficher.</div>

        return <div>
            <h2 className="center" style={{marginTop: 0}}>Vos examens</h2>
            <table className="striped bordered" style={{width: "100%", overflowY: "scroll"}}>
                <thead>
                <tr>
                    <td className="center">Date de l'examen</td>
                    <td className="center">Status</td>
                    <td className="center">Action</td>
                </tr>
                </thead>
                <tbody style={{
                    textAlign: "center",
                    verticalAlign: "middle",
                }}>
                {appointments_list ? appointments_list.map((appointment) => {
                    return render_appointment(appointment)
                }) : null}
                </tbody>
            </table>
        </div>
    }

    const render_patient = () => {
        return <div className="card" style={{width: "100%", margin: 20}}>
            {
                !patient_data ? <Loader text="" size="small"/> : <div style={{display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "center"}}>
                    <div className="valign-wrapper" style={{justifyContent: "center", display: patient_data.gender ? "" : "none"}}>
                        <img style={{padding: 20, maxWidth: 200}} src={`/imgs/${patient_data.gender}.svg`}/>
                    </div>
                    <div>
                        <h2 style={{margin: "10px 0 0 20px"}}>{Helper.formatName(patient_data.first_name, patient_data.last_name)}</h2>
                        <h5 className="valign-wrapper">
                            <i className="material-icons left">cake</i>
                            {Helper.formatDate(patient_data.birthday)}
                        </h5>
                        <h5 className="valign-wrapper">
                            <i className="material-icons left">email</i>
                            {patient_data.email}
                        </h5>
                        <h5 className="valign-wrapper">
                            <i className="material-icons left">phone</i>
                            {patient_data.phone}
                        </h5>
                    </div>


                </div>
            }
        </div>
    }


    return (
        <div style={{
            height: "calc(100vh - 90px)",
            display: "flex",
            flexDirection: "column",
            paddingTop: 40,
        }}>
            <div className="row hide-on-med-and-down" style={{height: "100%", gap: 20, margin: "0 40px"}}>
                <div className="col l4 m12 s12 valign-wrapper">
                    {render_patient()}
                </div>
                <div className="col l8 m12 s12" style={{overflowY: "scroll"}}>
                    {render_appointments()}
                </div>
            </div>
            <div className="row hide-on-large-only hide-on-extra-large-only" style={{height: "100%", gap: 20, margin: 0}}>
                <div className="col l4 m12 s12 valign-wrapper">
                    {render_patient()}
                </div>
                <div className="col l8 m12 s12">
                    {render_appointments()}
                </div>
            </div>
        </div>
    )
}

export default ListPatientAccess;