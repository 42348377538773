import React from 'react';

function CardValueDisplay({text, value, color}) {
    return (
        <div className={`card-value-display card ${color} z-depth-0 white-text`}
             style={{padding: 20, display: "flex", flexDirection: "column", gap: 20}}>
            <h3 style={{margin: 0}}>{text}</h3>
            <h1 className="center" style={{margin: 0}}>{value}</h1>
        </div>
    );
}

CardValueDisplay.defaultProps = {
    color: "blue darken-2"
}

export default CardValueDisplay;