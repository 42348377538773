import React from "react";

/* on gere
[b]bold[/b]
[i]italic[/i]
[u]underline[/u]
[r]rayé[/r]
[u]souligné[/u]
[red]rouge[/red]
[br/] saut de ligne
 */
export default function BBCode(props) {
    const text = props.text
                    .replace(/\[b\](.*?)\[\/b\]/g, "<b>$1</b>")
                    .replace(/\[i\](.*?)\[\/i\]/g, "<i>$1</i>")
                    .replace(/\[u\](.*?)\[\/u\]/g, "<u>$1</u>")
                    .replace(/\[r\](.*?)\[\/r\]/g, "<s>$1</s>")
                    .replace(/\[red\](.*?)\[\/red\]/g, "<span style='color: red'>$1</span>")
                    .replace(/\[br\/\]/g, "<br/>")

    return(
        <div className="bbcode">
            <p dangerouslySetInnerHTML={{__html: text}}/>
        </div>
    )

}