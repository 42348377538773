import {Act, ActNature, Side, TypesMesures} from "../Shared/Enums/Enums";
import {JOB} from "../Shared/Helper";
import {Role} from "../Shared/Enums/Scope";
import PrescriptionStore from "./PrescriptionStore";
import MesureStore from "./Mesures/MesureStore";

const _ = require('lodash');

export const LockingElement = {
    RIGHT_TENSION: {prompt: "La tension droite n'a pas été renseignée", isValid: () => isTensionValid(Side.RIGHT)},
    LEFT_TENSION: {prompt: "La tension gauche n'a pas été renseignée", isValid: () => isTensionValid(Side.LEFT)},
    RIGHT_ACUITY: {prompt: "Remplir l'acuité droite des lunettes", isValid: () => isAcuityValid(Side.RIGHT)},
    LEFT_ACUITY: {prompt: "Remplir l'acuité gauche des lunettes", isValid: () => isAcuityValid(Side.LEFT)},
    RIGHT_PARINAUD: {prompt: "Remplir le parinaud droit des lunettes", isValid: () => isParinaudValid(Side.RIGHT)},
    LEFT_PARINAUD: {prompt: "Remplir le parinaud gauche des lunettes", isValid: () => isParinaudValid(Side.LEFT)},
    FUNDUS: {prompt: "Aucun fond d'oeil n'a été fourni", isValid: () => areFundusValid()},
    ACT: {prompt: "Aucun acte n'a été sélectionné", isValid: () => isActValid()},
    QUESTION: {prompt: "Remplir le questionnaire", isValid: () => isReportValid()},
    STEREOSCOPY: {prompt: "Remplir la stéréoscopie dans le questionnaire", isValid: () => isStereoscopyValid()},
    AMBLIOPIA: {prompt: "Remplir l'amblyopie dans le questionnaire", isValid: () => isAmbliopiaValid()},
    RIGHT_STENOPEIC_HOLE: {prompt: "Remplir le trou sténopéïque droit dans la ref subjective", isValid: () => isStenopeicHoleValid(Side.RIGHT)},
    LEFT_STENOPEIC_HOLE: {prompt: "Remplir le trou sténopéïque gauche dans la ref subjective", isValid: () => isStenopeicHoleValid(Side.LEFT)},
    DIABETES: {prompt: "Remplir le diabète dans le questionnaire", isValid: () => isDiabetesValid()},
    SSN: {prompt: "Renseigner le numéro de sécurité sociale", isValid: () => isSsnValid(), optional: true},
    PATIENT_CODE: {prompt: "Renseigner le code caisse du patient", isValid: () => isPatientCodeValid(), optional: true},
    PRESCRIPTION: {prompt: "Renseigner une ou plusieurs prescriptions", isValid: () => isPrescriptionValid()},
    AUTOREF: {
        prompt: "Aucune mesure d'autorefractométrie n'a été fournie",
        isValid: () => isMandatoryMesureValid([TypesMesures.AUTOREF])
    },
    REF: {
        prompt: "Aucune mesure de réfraction subjective ou finale n'a été fournie",
        // isValid: () => isMandatoryMesureValid(TypesMesures.FINALE_REF)
        isValid: () => isMandatoryMesureValid([TypesMesures.REF_SUBJ, TypesMesures.FINALE_REF])
    },
    GLASSES: {
        prompt: "Aucune mesure de lunettes n'a été fournie",
        isValid: () => isMandatoryMesureValid([TypesMesures.LONGSIGHT_GLASSES])
    },
}

export const ReportLockable = {
    STEREOSCOPY: "Stéréoscopie",
    DIABETES: "Diabète",
    AMBLIOPIA: "Amblyopie"
}

const isTensionValid = (side) => {
    if (MesureStore.selected_act?.titre === Act.AMY6_7) return true
    if (MesureStore.age <= 15) return true

    return MesureStore.existingMesures.reduce((acc, mesure) => {
        if (acc) return true
        const tension = side === Side.RIGHT ? mesure.od_tension : mesure.og_tension
        return ![null, ""].includes(tension);
    }, false)
}

const isAcuityValid = (side) => {
    if (MesureStore.selected_act?.titre === Act.AMY6_7) return true

    // const inpectedMesureTypes = [
    //     TypesMesures.LONGSIGHT_GLASSES,
    //     TypesMesures.PROGRESSIVE_GLASSES,
    //     TypesMesures.NO_CORRECTION,
    //     TypesMesures.LENSE_METER
    // ]
    // const mesures = MesureStore.existingMesures.filter(m => inpectedMesureTypes.includes(m.titre))
    // if(mesures.length === 0) return true

    return MesureStore.existingMesures.reduce((acc, mesure) => {
        if (acc) return true
        const acuity = side === Side.RIGHT ? mesure.od_av_loin : mesure.og_av_loin
        return ![null, ""].includes(acuity)
    }, false)
}
const isParinaudValid = (side) => {
    if (MesureStore.selected_act?.titre === Act.AMY6_7) return true
    if(MesureStore.age < 16) return true

    //
    // const inpectedMesureTypes = [
    //     TypesMesures.SHORTSIGHT_GLASSES,
    //     TypesMesures.PROGRESSIVE_GLASSES,
    //     TypesMesures.NO_CORRECTION,
    //     TypesMesures.LENSE_METER
    // ]
    //
    // const mesures = MesureStore.existingMesures.filter(m => inpectedMesureTypes.includes(m.titre))
    // if(mesures.length === 0) return true

    return MesureStore.existingMesures.reduce((acc, mesure) => {
        if (acc) return true
        const parinaud = side === Side.RIGHT ? mesure.od_parinaud : mesure.og_parinaud
        return ![null, ""].includes(parinaud)
    }, false)
}

const areFundusValid = () => MesureStore.images
const isActValid = () => MesureStore.act_saved

const isReportValid = () => {
    if (MesureStore.selected_act && MesureStore.selected_act?.nature !== ActNature.TELEMEDICAL.name) return true
    if (!MesureStore.report) return false
    return Object.keys(MesureStore.report).length !== 0;
}

const isStereoscopyValid = () => {
    return true

    if (JOB === Role.OPTICIAN) return true
    const needStereoscopy = MesureStore.existingMesures.reduce((acc, mesure) => {
        if (mesure.od_sphere < 0 || mesure.og_sphere < 0) return acc
        return acc || Math.abs(mesure.od_sphere - mesure.og_sphere) > 2
    }, false)

    return !(needStereoscopy && _.isEmpty(MesureStore.report?.[ReportLockable.STEREOSCOPY]))
}

const isReportLockableValid = (lockable) => MesureStore.report?.[lockable]

const isDiabetesValid = () => {
    const amy67acts = [
        Act.AMY8_5_AMY_6_7_2,
        Act.AMY8_AMY_6_7_2,
        Act.AMY6_7
    ]
    if (amy67acts.includes(MesureStore.selected_act?.titre)) return true
    return MesureStore.report?.[ReportLockable.DIABETES]
}

const isAmbliopiaValid = () => {
    const inpectedMesureTypes = [
        TypesMesures.REF_SUBJ,
        TypesMesures.FINALE_REF
    ]

    const mesures = MesureStore.existingMesures.filter(m => inpectedMesureTypes.includes(m.titre))
    if(mesures.length === 0) return true

    const min_av = mesures.reduce((acc, mesure) => {
        const min = Math.min(mesure.od_av_loin || 10, mesure.og_av_loin || 10)
        return Math.min(acc, min)
    }, 10)
    if (min_av >= 0.9) return true
    return MesureStore.report?.[ReportLockable.AMBLIOPIA]
}
const isStenopeicHoleValid = (side) => {
    const inpectedMesureTypes = [
        TypesMesures.REF_SUBJ,
        TypesMesures.FINALE_REF
    ]

    const mesures = MesureStore.existingMesures.filter(m => inpectedMesureTypes.includes(m.titre))

    const hasStenopeicHole = mesures.reduce((acc, mesure) => {
        const stenopeicHolePresent = side === Side.RIGHT ? !!mesure.od_av_trou_stenopeique : !!mesure.og_av_trou_stenopeique
        return acc || stenopeicHolePresent
    }, false)

    const min_av = mesures.reduce((acc, mesure) => {
        const acuityWithoutStrongness = (acuity) => {
            if(acuity.match(/^\d+[.,]?\d*[Ff]$/)) return parseInt(acuity.slice(0, -1))
        }
        const min = Math.min(parseInt(mesure.od_av_loin) || 10, parseInt(mesure.og_av_loin) || 10)
        return Math.min(acc, min)
    }, 10)
    if (min_av >= 0.9) return true
    return hasStenopeicHole
}

const isSsnValid = () => {
    if (MesureStore.age < 50) return true
    if (MesureStore.selected_act?.titre === Act.AMY6_7) return true
    return !!MesureStore.patient.num_carte_vitale
}
const isPatientCodeValid = () => {
    if (MesureStore.age < 50) return true
    if (MesureStore.selected_act?.titre === Act.AMY6_7) return true
    return MesureStore.patient.regime_code && MesureStore.patient.fund_code && MesureStore.patient.centre_code
}

const isPrescriptionValid = () => {
    if (MesureStore.selected_act?.titre !== Act.RNM) return true
    return !PrescriptionStore.isEmpty
}

const isMandatoryMesureValid = (type) => !missingMesures().flat().reduce((acc, mesure) => acc || type.includes(mesure), false)

export const missingMesures = () => {
    if(JOB === Role.OPTICIAN) return []
    if(MesureStore.selected_act?.titre === Act.AMY6_7) return []

    if (MesureStore.selected_act?.nature !== ActNature.TELEMEDICAL.name) return []
    let mesures = []
    const refMesures = [TypesMesures.REF_SUBJ, TypesMesures.FINALE_REF]
    if (!MesureStore.existingMesures.find(m => refMesures.includes(m.titre))) mesures.push(refMesures)
    if (!MesureStore.existingMesures.find(m => m.titre === TypesMesures.AUTOREF)) mesures.push([TypesMesures.AUTOREF])
    const glassesMesures = [
        TypesMesures.LONGSIGHT_GLASSES,
        TypesMesures.SHORTSIGHT_GLASSES,
        TypesMesures.PROGRESSIVE_GLASSES,
        TypesMesures.NO_CORRECTION,
        TypesMesures.LENSE_METER
    ]
    if (!MesureStore.existingMesures.find(m => glassesMesures.includes(m.titre))) mesures.push(glassesMesures)
    return mesures
}

export const isLocked = (...elements) => {
    if(JOB === Role.OPTICIAN) return isLockedForOptician(...elements)

    if (MesureStore.selected_act?.nature !== ActNature.TELEMEDICAL.name) return false
    return !elements.reduce((acc, element) => acc && element.isValid(), true)
}

export const isLockedForOptician = (...elements) => {
    const consideredElementsForOpticians = [LockingElement.RIGHT_ACUITY, LockingElement.LEFT_ACUITY, LockingElement.ACT]
    if(!consideredElementsForOpticians.reduce((acc, element) => acc || elements.includes(element), false)) return false

    if (MesureStore.selected_act?.nature !== ActNature.TELEMEDICAL.name) return false
    return !elements.reduce((acc, element) => acc && element.isValid(), true)
}