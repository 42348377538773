import React from 'react';
import AppointmentDetails from "./AppointmentDetails";
import Loader from "../../../Shared/Loader";
import FundusDisplayPanel from "../../../admin/Controle/FundusDisplayPanel";
import FundusDisplayVideoPanel from "../../../admin/Controle/FundusDisplayVideoPanel";
import DisplayMeasures from "./DisplayMeasures";
import DisplayPrescriptions from "./DisplayPrescriptions";

function AppointmentCheck({patient, act, appointment_id, details, media, measures, prescriptions}) {

    const renderMesures = () => {
        return <DisplayMeasures measures={measures}/>
    }

    const renderImages = () => {
        if (!media?.images) return <div style={{marginTop: 100}}><Loader text="" size="small"/></div>
        return <FundusDisplayPanel images={media.images} key={"img_preview_" + appointment_id}/>
    }

    const renderVideos = () => {
        if (!media?.videos) return <div style={{marginTop: 100}}><Loader text="" size="small"/></div>
        return <FundusDisplayVideoPanel videos={media.videos} key={"video_preview_" + appointment_id}/>
    }

    const renderMedias = () => {
        return <div style={{display: "flex", flexDirection: "column", gap: 20}}>
            <div className="valign-wrapper" style={{gap: 10}}>
                <i className="material-icons red darken-2 white-text"
                   style={{padding: 10, borderRadius: 25, fontSize: 16}}>visibility</i>
                <h4 style={{margin: 0}}>Imagerie</h4>
            </div>
            {renderImages()}
            {renderVideos()}
        </div>
    }

    const renderPrescriptions = () => {
        if (prescriptions === undefined) return <div style={{marginTop: 100}}><Loader text="" size="small"/></div>
        return <DisplayPrescriptions prescriptions={prescriptions}/>

    }

    return <div className="row" style={{gap: 20, width: "100%", padding: 20}}>
        <div className="col s12 m6" style={{display: "flex", flexDirection: "column", gap: 20}}>
            <AppointmentDetails act={act} patient={patient} details={details}/>
            {renderMedias()}
        </div>
        <div className="col s12 m6" style={{display: "flex", flexDirection: "column", gap: 20}}>
            {renderMesures()}
            {renderPrescriptions()}
        </div>
    </div>
}

export default AppointmentCheck;