import React from "react"
import axios from "axios"
import {toast} from 'react-toastify';


class ConvoqueBox extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this)
        this.state = {
            checked: false
        };
    }

    componentDidMount() {
        this.setState({
            checked: this.props.checked
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.checked !== this.props.checked) {
            this.setState({
                checked: this.props.checked
            })
        }
    }

    handleChange() {
        axios.post("/secretariat/api/setConvoqued", {
            alert: this.props.alert,
            convoque: !this.state.checked
        }).then((data) => {
            this.setState({checked: !this.state.checked}, () => this.props.onChange(this.state.checked))
            toast.success('Fait !', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        })
    }


    render() {

        return (
            <React.Fragment>
                <label>
                    <input checked={(this.state.checked ? "checked" : "")} onChange={this.handleChange}
                           type="checkbox"/>
                    <span>Convoqué</span>
                </label>

            </React.Fragment>
        );
    }
}

ConvoqueBox.defaultProps = {
    onChange: () => null
}

export default ConvoqueBox
