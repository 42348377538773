import axios from "axios"
export default class Parser{
  spheres = [0,0.25,0.5,0.75,1,1.25,1.5,1.75,2,2.25,2.5,2.75,3,3.25,3.5,3.75,4,4.25,4.5,4.75,5,5.25,5.5,5.75,6,6.25,6.5,6.75,7,7.25,7.5,7.75,8,8.25,8.5,8.75,9,9.25,9.5,9.75,10,10.5,11,11.5,12,12.5,13,13.5,14,14.5,15,16,17,18,19,20,21,22,23,24,25]
  cylindres = [0,0.25,0.5,0.75,1,1.25,1.5,1.75,2,2.25,2.5,2.75,3,3.25,3.5,3.75,4,4.25,4.5,4.75,5,5.25,5.5,5.75,6,6.25,6.5,6.75,7,7.25,7.5,7.75,8,8.25,8.5,8.75,9,9.25,9.5,9.75,10]
  axes = [0,5,10,15,20,25,30,35,40,45,50,55,60,65,70,75,80,85,90,95,100,105,110,115,120,125,130,135,140,145,150,155,160,165,170,175,180]
  additions = [3,2.75,2.5,2.25,2,1.75,1.5,1.25,1,0.75,0.5,0.0]
  avs = [0.05,0.1,0.2,0.3,0.4,0.5,0.6,0.7,0.8,0.9,1,12,16]
  tensions = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57]

  constructor(data){
    this.data = data;
  }
  closest(poss,value){
    if(value==undefined) return undefined;
    let mult = (value<0) ? -1 : 1
    value *= mult
    return(mult*(poss.reduce((a, b) => {
      return Math.abs(b - value) < Math.abs(a - value) ? b : a;
    })));

  }
  process_raw(data){
    if(data["NT"] ===undefined) {
      return(data)
    }else{
      let od_tension = data["NT"]["od_tension"]
      let og_tension = data["NT"]["og_tension"]
      for(let type of Object.keys(data)){
        if(type != "NT"){
          data[type]["od_tension"] =od_tension
          data[type]["og_tension"] =og_tension

        }
      }
    }
    return(data)
  }
  regex(str,regex){
    let m;
    let r = []
    while ((m = regex.exec(str)) !== null) {
        if (m.index === regex.lastIndex) {
            regex.lastIndex++;
        }
        m.forEach((match, groupIndex) => {
            if(groupIndex>0) r.push(parseFloat(match.replaceAll(' ','')));
        });
    }
    return(r);
  }
  parseAndSend(appointment,callback=()=>{}){
    this.sendMesures(this.parse(),appointment,callback)
  }

  sendMesures(data,appointment,callback=()=>{}){
    axios.post("/api/v2/bridge/refracteur/saveMesures",{
      data: data,
      app: appointment
    }).then((data)=>{
      callback()
    })
  }
}
