import React from 'react';
import ExpertVisionOverview from "./ExpertVisionOverview";
import PointVisionOverview from "./PointVisionOverview";

function ApplicationsOverview() {
    return (
        <div className="applications-overview row" style={{gap: 20}}>
            <div className="col s12 l6">
                <ExpertVisionOverview/>
            </div>
            <div className="col s12 l6">
                <PointVisionOverview/>
            </div>
        </div>
    );
}

export default ApplicationsOverview;