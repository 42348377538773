import React, {useEffect} from "react";
import DisplayFromCV from "../../../../../patient_data/DisplayFromCV";
import useCvRequest from "./hooks/useCvRequest";
import BridgeStoreV2 from "../../../../../BridgeV2/BridgeStoreV2";
import {toast} from "react-toastify";
import CVStore from "../../../../../CarteVitale/CVStore";
import MesureStore from "../../../MesureStore";
import {isLoading, loading, RequestState} from "../../../../../Shared/StateHelper";
import {observer} from "mobx-react";

const CarteVitaleButton = observer(({retracted}) => {
    const {sendCVRequest, data, meta} = useCvRequest()

    useEffect(() => {
        const elem = document.getElementById('carte_vitale_modal');
        const modal = M.Modal.init(elem, {onCloseStart: () => null});

        return () => modal.destroy();
    }, []);


    const sendRequest = () => {
        if (isLoading(CVStore.cvRequestState)) return
        CVStore.cvRequestState = loading(CVStore.cvRequestState)
        const promise = sendCVRequest(BridgeStoreV2.uid)
        toast.promise(
            promise,
            {
                pending: 'Récupération des données de la carte vitale...',
                success: 'Données de Carte Vitale reçues avec succés !',
                error: 'Impossible de récupérer les données de la carte vitale, Medoc et TemeooDesktop sont-ils bien lancés ?'
            }
        ).then((resp) => {
            CVStore.cvRequestState = RequestState.SUCCESS
            const filterPatient = (data) => {
                const currentPatient = MesureStore.patient
                if (!currentPatient) return data
                const filtered = data.filter((d) => d.sNomUsuelPatient.toLowerCase() === currentPatient.nom.toLowerCase() && d.sPrenomPatient.toLowerCase() === currentPatient.prenom.toLowerCase())
                return filtered.length > 0 ? filtered : data
            }
            const filtered = filterPatient(resp.data)
            // One possible choice : directly select the patient if there is only one
            if (filtered.length === 1) {
                patientSelected(filtered[0], resp.meta)
                return
            }
            M.Modal.getInstance(document.getElementById('carte_vitale_modal')).open()
        }).catch(() => {
            CVStore.cvRequestState = RequestState.ERROR
        })
    }

    const renderButton = () => {
        let color = "blue darken-2"
        if (CVStore.cvRequestState === RequestState.SUCCESS) color = "green darken-2"
        if (CVStore.cvRequestState === RequestState.ERROR) color = "red darken-2"
        if (retracted) return <a className={`btn-small ${color} z-depth-0`} style={{paddingInline: 4}}
                                 onClick={sendRequest}>
            <i className="material-icons">credit_card</i>
        </a>
        return <a className={`btn-small ${color} z-depth-0`} onClick={sendRequest}>
            <i className="material-icons left">credit_card</i>
            Lecture carte vitale
        </a>
    }

    const patientSelected = (patient, meta) => {
        CVStore.updatePatientV2(patient, meta)
        M.Modal.getInstance(document.getElementById('carte_vitale_modal')).close()
    }

    return <>
        {renderButton()}
        <div id="carte_vitale_modal" className="modal" style={{width: "90%", borderRadius: "0"}}>
            <div className="modal-content">
                <h1 className="center">Carte vitale</h1>
                <DisplayFromCV
                    data={data}
                    meta={meta}
                    onClick={(patient) => patientSelected(patient, meta)}/>
            </div>
            <div className="modal-footer">
                <a className="modal-close btn red darken-2 z-depth-0">Annuler</a>
            </div>
        </div>
    </>
})

CarteVitaleButton.defaultProps = {
    retracted: false
};

export default CarteVitaleButton;