import React, {useEffect} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import RightsStore from "../../../admin/Console/RightsStore";
import {UserPermissions, UserRights} from "../../../Shared/Enums/Enums";

export const useBillingSettings = (host) => {
    const [categories, setCategories] = React.useState(null)
    const [acts, setActs] = React.useState(null)

    useEffect(() => {
        getCategories()
    }, []);

    useEffect(() => {
        if (categories) getActs()
    }, [categories]);

    useEffect(() => {
        if (acts) {
            const autocomplete = document.getElementById('select_act_autocomplete')
            const instance = M.Autocomplete.init(autocomplete, {
                data: acts.map((act) => {
                    return {
                        id: act.id,
                        text: act.titre
                    }
                })
            });
            if(autocomplete) autocomplete.value = ""
            return () => {
                instance?.destroy()
            }
        }
    }, [acts]);

    const getCategories = () => {
        axios.get(host).then((response) => {
                setCategories(response.data)
            })
            .catch((error) => {
                toast.error("Une erreur est survenue lors de la récupération des catégories de facturation")
            })
    }

    const getActs = () => {
        if(!RightsStore.can_do(UserRights.BILLING, UserPermissions.READ_ONLY)) return
        axios.get(`${host}/acts`)
            .then((response) => {
                setActs(response.data)
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    return toast.error("Vous n'avez pas les droits pour effectuer cette action")
                }
                toast.error("Une erreur est survenue lors de la récupération des catégories de facturation")
            })
    }

    const createCategory = () => {
        if(!RightsStore.can_do(UserRights.BILLING, UserPermissions.WRITE_ONLY)) return
        const name = document.getElementById("new_category_input").value || null

        if (!name) return toast.error("Veuillez entrer un nom de catégorie")
        axios.put(host, {name: name})
            .then((_) => {
                toast.success("La catégorie a bien été créée")
                getCategories()
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    return toast.error("Vous n'avez pas les droits pour effectuer cette action")
                }
                toast.error("Une erreur est survenue lors de la création de la catégorie")
            })
    }

    const addAct = () => {
        if(!RightsStore.can_do(UserRights.BILLING, UserPermissions.WRITE_ONLY)) return
        const act_name = document.getElementById("select_act_autocomplete").value || null
        const act_id = acts.find((act) => act.titre === act_name)?.id || null

        if (!act_id) return toast.error("Veuillez entrer un nom d'acte valide")
        axios.post(`${host}/act`, {act_id: act_id})
            .then((_) => {
                toast.success("L'acte a bien été ajouté")
                getCategories()
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    return toast.error("Vous n'avez pas les droits pour effectuer cette action")
                }
                toast.error("Une erreur est survenue lors de l'ajout de l'acte")
            })
    }

    const editSetting = (setting) => {
        if(!RightsStore.can_do(UserRights.BILLING, UserPermissions.WRITE_ONLY)) return
        toast.promise(
            axios.patch(`${host}/setting/${setting.id}`, {setting: setting}),
            {
                pending: "Mise à jour des tarifs en cours",
                success: "Tarifs mis à jour",
                error: "Une erreur est survenue lors de la mise à jour des tarifs",
            }
        ).then((_) => {
            getCategories()
        })
            .catch((error) => {
                if(error.response.data.errorPrompt) return toast.error(error.response.data.errorPrompt)
                if (error.response.status === 401) {
                    return toast.error("Vous n'avez pas les droits pour effectuer cette action")
                }
                toast.error("Une erreur est survenue lors de la modification du paramètre")
            })

    }

    return { categories, acts, createCategory, addAct, editSetting };
}