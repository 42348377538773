import React, {useEffect} from "react";
import PropTypes from "prop-types";

function BorderedDisplay(props) {

    return (
        <div className={`bordered-display ${props.hidden ? "hide" : ""} ${props.color}` } style={{height: props.height}}>
            <h5 className={`${props.color} grey-text text-darken-2`}
                style={{
                    top: "-8px",
                    left: "30px",
                    position: "relative",
                    margin: 0,
                    width: "fit-content"
                }}>{props.text}</h5>
            <div style={{margin: "0 10px 10px 10px", height: props.height}}>
                {props.children}
            </div>
        </div>
    );
}

BorderedDisplay.propTypes = {
    text: PropTypes.string.isRequired,
}

BorderedDisplay.defaultProps = {
    color: "white",
    hidden: false,
    height: undefined
}
export default BorderedDisplay;