import React from "react"
import {CENTRE_OPHTALMO_URL} from "../Shared/Helper";

class PrintAcces extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            txt: "",
            key: ""
        };
    }

    render() {
        return (
            <div style={{display: "flex", flexDirection: "column", gap: 20}}>
                <h2 style={{alignSelf: "center"}}>Accès à vos prescriptions</h2>
                <p>
                    {this.props.patient.prenom} {this.props.patient.nom}, vous pouvez accéder à vos prescriptions sur
                </p>
                <h3 className="bolder" style={{alignSelf: "center"}}>{CENTRE_OPHTALMO_URL}</h3>

                <div style={{display: "flex", justifyContent: "center"}}>
                    <h3 style={{margin: 0}}>Votre clé d'accès :</h3>
                    <h1 style={{margin: "0 auto"}}>
                        {this.props.access_key.split("").map((char, index) => (
                            <span key={index} style={{textDecoration: char === char.toUpperCase() && Number.isNaN(parseInt(char)) ? "underline" : "none"}}>
                      {char}
                     </span>
                        ))}
                    </h1>
                </div>

                <h5 style={{fontStyle: "italic"}}>* Les lettres majuscules sont soulignées</h5>
                {/*<div className="center">*/}
                {/*  <img src="/imgs/recaptcha.png" className="responsive-img" alt=""/>*/}
                {/*</div>*/}
            </div>
        );
    }
}

export default PrintAcces
