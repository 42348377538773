import React, { Component } from 'react';
import SetupStore from "./SetupStore"
import {observer} from "mobx-react"
import Strips from "../../../Shared/Strips"
import { HashRouter as Router, Route, Link  } from "react-router-dom"

@observer
export default class Typedemachine extends Component {
  constructor(props) {
    super(props);
    this.choix = this.choix.bind(this)
    this.state = {
      invalid: true,
      usb_btn:"check_box_outline_blank",
      dossier_btn:"check_box_outline_blank"
    };
  }

  choix(e){
    e.preventDefault()
    let type = e.target.dataset.type
    SetupStore.type = type
    if (type == "usb"){
      this.setState({
        invalid: false,
        usb_btn:"check_box",
        dossier_btn:"check_box_outline_blank"
      })
    }else{
        this.setState({
        invalid: false,
        dossier_btn:"check_box",
        usb_btn:"check_box_outline_blank",
      })
    }

  }

  render() {
    return (
      <div>
        <Strips>white mini</Strips>
        <h1 className="center">Type de machine</h1>
        <div className="container">
          <div className="row">
            <div className="col s6 center">
              <p> Votre machine est connecté par un cable usb ? </p>
              <a data-type="usb" onClick={this.choix}  className={"btn btn-large"}> <i data-type="usb" className="material-icons right">{this.state.usb_btn}</i>
USB</a>
            </div>
            <div className="col s6 center">
              <p> Votre machine fonctionne avec un dossier partagé ?</p>
              <a data-type="dossier" onClick={this.choix} className={"btn btn-large"}> <i data-type="dossier" className="material-icons right">{this.state.dossier_btn}</i>
DOSSIER PARTAGÉ</a>
            </div>
          </div>
          <div className="row"></div>
          <div className="row">
            <div className="center">
                  <Link to="step2" disabled={this.state.invalid} className="btn btn-large">Continuer</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
