import React, {Component} from 'react';
import moment from 'moment';
import Helper from "../../Helper";
import InfiniteScroll from "react-infinite-scroll-component";

export default class OrthoptistePatients extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.patients.length === 0) {
            return null
        } else {
            return (
                <div className="container" style={{width: "100%", padding: 20}}>
                    <InfiniteScroll next={this.props.search} hasMore={true}
                                    dataLength={this.props.patients.length}
                                    style={{border: "thin solid #eeeeee", borderRadius: 30}}
                                    loader={<div className="center"></div>}>
                        <table>
                            <tbody>
                            {this.props.patients.map((patient) => (
                                <tr key={patient.id} data-cy="PatientRow">
                                    <td style={{paddingLeft: 20}} data-cy="PatientFullName">
                                        {Helper.formatName(patient.first_name, patient.last_name)}
                                    </td>
                                    <td data-cy="PatientEmail">{patient.email}</td>
                                    <td style={{whiteSpace: "nowrap"}}>
                                        <div style={{display: "flex", flexWrap: "wrap", gap: 20}}>
                                            <span data-cy="PatientPhoneNumber">{Helper.formatPhoneNumber(patient.phone)}</span>
                                            <span data-cy="PatientBirthday">{moment(patient.birthday).format("DD/MM/YYYY")}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div style={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            gap: 20,
                                            justifyContent: "center"
                                        }}>
                                            <a className="btn btn-small blue darken-2 z-depth-0 truncate"
                                               data-cy="NewAppointmentButton"
                                               title={`Nouvelle consultation avec ${Helper.formatName(patient.last_name, patient.first_name)}`}
                                               href={`/NewAppointement/${patient.id}`}>
                                                <i className="material-icons left">play_arrow</i>Consultation
                                            </a>
                                            <div style={{
                                                display: "flex",
                                                flexWrap: "wrap",
                                                gap: 20,
                                                justifyContent: "center"
                                            }}>

                                                <a className="btn-flat truncate" href={`/patients/${patient.id}`}
                                                   data-cy="PatientPageLink"
                                                   title={`Accéder à la fiche de ${Helper.formatName(patient.last_name, patient.first_name)}`}>
                                                    <i className="material-icons left">account_circle</i>
                                                    Fiche patient
                                                </a>
                                                <a className="btn-flat truncate" href={`/patients/${patient.id}/edit`}
                                                   data-cy="EditPatientLink"
                                                   title={`Modifier les informations de ${Helper.formatName(patient.last_name, patient.first_name)}`}>
                                                    <i className="material-icons left">edit</i>
                                                    Modifier informations
                                                </a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </InfiniteScroll>

                </div>

            );
        }
    }
}

