import React, {Component} from 'react';
import {observer} from "mobx-react"
import Store from "./MessagesStore"
import moment from "moment"

@observer
export default class ShowConversation extends Component {

    constructor(props) {
        super(props)
        this.state = {
            left: props.target == "orthoptiste" ? true : false
        }
    }

    componentWillUnmount() {
        Store.unsubscribe()
    }
    componentDidMount() {
        Store.loadMessages(this.props.target_id, this.props.appointement_id)
    }

    format(message) {
        return message
    }

    render() {
        const left = this.state.left
        return (
            <div style={{overflowY: "scroll", height: "100%"}}>
                <div style={{display: "flex", flexDirection: "column", gap: 20}}>
                    {Store.messages.map((message) => (
                        <div key={message.id}>
                            {message.sended_by_orthoptiste ? (
                                <div className="row">
                                    <div style={{"whiteSpace": "pre-line", borderRadius: "15px", paddingTop: "10px"}}
                                         className="card green lighten-4 col s8 offset-s1 z-depth-0">
                                        {this.format(message.message)}
                                        <p className="right grey-text"><i>
                                            {moment(message.created_at).format("MM/DD/YYYY h:mm:ss")}
                                        </i>
                                        </p>
                                    </div>
                                </div>
                            ) : (
                                <div className="row">
                                    <div style={{"whiteSpace": "pre-line", borderRadius: "15px", paddingTop: "10px"}}
                                         className="card blue lighten-4 col s7 offset-s4 z-depth-0">
                                        <span className="left">{this.format(message.message)}</span>
                                        <p className="right grey-text"><i>
                                            {moment(message.created_at).format("MM/DD/YYYY h:mm:ss")}
                                        </i>
                                        </p>

                                    </div>
                                </div>

                            )}
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}
