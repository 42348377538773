import "@bangle.dev/core/style.css";
import "@bangle.dev/tooltip/style.css";
import "@bangle.dev/react-menu/style.css";

import React, {useEffect} from "react";
import {BangleEditor, useEditorState} from "@bangle.dev/react";
import {Plugin, PluginKey,} from "@bangle.dev/core";
import {floatingMenu, FloatingMenu} from "@bangle.dev/react-menu";
import {
    bold,
    bulletList,
    heading,
    horizontalRule,
    image,
    italic, listItem, orderedList,
    strike,
    underline,
} from "@bangle.dev/base-components";
import {toHTMLString} from "@bangle.dev/utils";
import toastr from "../Toastr";
import {toast} from "react-toastify";

const menuKey = new PluginKey("menuKey");

function MarkdownEditor({defaultValue, onChange, noImages}) {
    const onEditorDocChange = (state) => {
        let htmlString = toHTMLString(state)
        if(noImages) {
            const regex = /<img.*?\/?>/g;
            if(htmlString.match(regex)) toast.error("Les images ne sont pas autorisées dans ce champ, elles ne seront pas enregistrées.")
            const subst = ``;
            htmlString = htmlString.replace(regex, subst);
        }
        onChange(htmlString)
    }

    const editorState = useEditorState({
        initialValue: defaultValue,
        specs: [
            bold.spec(),
            heading.spec(),
            italic.spec(),
            horizontalRule.spec(),
            image.spec(),
            underline.spec(),
            strike.spec(),
            listItem.spec(),
            orderedList.spec(),
            bulletList.spec(),
        ],
        plugins: () => [
            bold.plugins({markdownShortcut: false}),
            heading.plugins(),
            italic.plugins({markdownShortcut: false}),
            horizontalRule.plugins(),
            image.plugins(),
            underline.plugins(),
            strike.plugins(),
            listItem.plugins(),
            orderedList.plugins(),
            bulletList.plugins(),
            floatingMenu.plugins({
                key: menuKey,
            }),
            new Plugin({
                view: () => ({
                    update: (view, prevState) => {
                        if (!view.state.doc.eq(prevState.doc)) {
                            onEditorDocChange(view.state);
                        }
                    },
                }),
            })
        ],
    });

    useEffect(() => {
    }, [defaultValue])

    return <BangleEditor state={editorState}>
        <FloatingMenu menuKey={menuKey}/>
    </BangleEditor>
}


export default MarkdownEditor;

MarkdownEditor.defaultProps = {
    onChange: (_) => null,
    defaultValue: "",
    noImages: false,
};

