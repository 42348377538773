import React, { Component } from 'react';
import ImageModal from "./ImageModal"

export default class Slave extends Component {
  constructor(props) {
    super(props);
    this.startCable = this.startCable.bind(this)
    this.state = {
      current: null
    }

  this.startCable()
  }
  startCable() {
    var that = this;
    App.board = App.cable.subscriptions.create({channel: 'WhiteboardChannel'}, {
          received: function(data) {
            if(data["target"]=="image"){
              that.setState({
                current: data['url']
              })
              if( data["url"]==""){
                App.modal.close()
              }else{
                App.modal.open()
              }
            }

        }
    })
  }
  componentDidMount(){
    M.Modal._count = 0;
    var elems = document.querySelectorAll('.modal');
    App.modal = M.Modal.init(elems, {})[0];
  }
  render() {
    return (
      <div>
        <ImageModal image={this.state.current} />
      </div>
    );
  }
}
