import React, {useEffect} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import Loader from "../../../Shared/Loader";
import {ActNature} from "../../../Shared/Enums/Enums";
import InputText from "../../../Shared/UiKit/InputText";
import Tooltip from "../../../Shared/Tootip";

const _ = require('lodash');

function ActList(props) {
    const modal_id = `act_${props.ophtalmological ? "ophtalmo" : "ortho"}_modal`
    const [acts, setActs] = React.useState(props.acts)
    const [onEditAct, setOnEditAct] = React.useState({})
    const [description, setDescription] = React.useState(undefined)

    useEffect(() => {
        setActs(props.acts)
    }, [props.acts])

    useEffect(() => {
        const modal = document.getElementById(modal_id);
        const modalInstance = M.Modal.init(modal, {});
        return () => {
            modalInstance?.destroy();
        }
    }, [acts])


    const new_act = () => {
        setOnEditAct({})
        const modal = document.getElementById(modal_id);
        const modalInstance = M.Modal.getInstance(modal);
        modalInstance.open();
    }

    const edit_act = (act) => {
        setOnEditAct(act)
        document.getElementById(`${props.ophtalmological ? "ophtalmo" : "ortho"}_act_title`).value = act.titre
        document.getElementById(`${props.ophtalmological ? "ophtalmo" : "ortho"}_act_price`).value = act.prix
        if(!act.ophtalmological) document.getElementById(`${props.ophtalmological ? "ophtalmo" : "ortho"}_act_nature`).value = act.nature
        setDescription(act.description ?? "")
        const modal = document.getElementById(modal_id);
        const modalInstance = M.Modal.getInstance(modal);
        modalInstance.open();
    }

    const create_act = () => {
        const title = document.getElementById(`${props.ophtalmological ? "ophtalmo" : "ortho"}_act_title`).value
        const price = document.getElementById(`${props.ophtalmological ? "ophtalmo" : "ortho"}_act_price`).value
        const nature = props.ophtalmological ? undefined : document.getElementById(`${props.ophtalmological ? "ophtalmo" : "ortho"}_act_nature`).value
        axios.put('/admin/console/act', {
            titre: title,
            prix: price,
            nature: nature,
            description: description,
            ophtalmological: props.ophtalmological
        }).then((resp) => {
            setActs([...acts, resp.data.act])
        }).catch((err) => {
            if (err.response.status === 400) {
                toast.error("Ce nom existe déjà")
            } else toast.error("Impossible de créer l'acte")
        })
    }

    const update_act = () => {
        const price = document.getElementById(`${props.ophtalmological ? "ophtalmo" : "ortho"}_act_price`).value
        const nature =  onEditAct.ophtalmological ? undefined : document.getElementById(`${props.ophtalmological ? "ophtalmo" : "ortho"}_act_nature`).value
        axios.patch(`/admin/console/act/${onEditAct.id}`, {
            prix: price,
            nature: nature,
            description: description,
        }).then((resp) => {
            setActs(acts.map(act => act.id === onEditAct.id ? resp.data.act : act))
        }).catch((err) => {
            toast.error("Impossible de créer l'acte")
        })
    }

    const renderModal = () => {
        return <div className="modal modal-fixed-footer" id={modal_id}>
            <div className="model-content">
                <h3 className="center" style={{marginTop: 20}}>{_.isEmpty(onEditAct) ? "Nouvel" : "Éditer"} acte</h3>
                <div className="row" style={{width: "80%", display: "flex", flexWrap: "wrap", alignItems: "center"}}>
                    <div className="col s3">
                        Titre
                    </div>
                    <div className="input-field col s9">
                        <input id={`${props.ophtalmological ? "ophtalmo" : "ortho"}_act_title`} type="text"
                               className="validate" disabled={!_.isEmpty(onEditAct)}/>
                    </div>
                    <div className="col s3">
                        Prix
                    </div>
                    <div className="input-field col s9">
                        <input id={`${props.ophtalmological ? "ophtalmo" : "ortho"}_act_price`} type="number"
                               className="validate"/>
                    </div>
                    {
                        props.ophtalmological ?
                            <div/> : <>
                                <div className="col s3">
                                    Type
                                </div>
                                <div className="input-field col s9">
                                    <select id={`${props.ophtalmological ? "ophtalmo" : "ortho"}_act_nature`}
                                            className="browser-default">
                                        {
                                            Object.entries(ActNature).map(([key, value]) => {
                                                return <option key={key} value={key}>{value.short}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </>

                    }
                    <div className="col s3">
                        Description
                    </div>
                    <div className="input-field col s9">
                        <InputText type="text-area" value={description} onChange={(e) => setDescription(e.target.value)}
                                   height="200px"/>
                    </div>

                </div>
            </div>
            <div className="modal-footer" style={{display: "flex", gap: 10, justifyContent: "right"}}>
                {_.isEmpty(onEditAct) ?
                    <a className="btn btn-small blue darken-2 z-depth-0" onClick={create_act}>Créer</a>
                    :
                    <a className="btn btn-small blue darken-2 z-depth-0" onClick={update_act}>Valider</a>}
                <a className="modal-close btn btn-small red darken-2 z-depth-0">Annuler</a>
            </div>
        </div>
    }

    const renderAct = (act) => {
        return (
            <div className="collection-item transparent" key={act.id} style={{display: "flex", width: "100%"}}>
                <div style={{width: "100%", margin: 0, display: "flex", alignItems: "center", flexWrap: "wrap"}}>
                    <div style={{width: "180px", margin: 0}}>
                        <span>{act.titre}</span>
                    </div>
                    <div style={{width: "100px", margin: 0}}>
                        <span>{act.prix} €</span>
                    </div>
                    <div style={{width: "220px", margin: 0}} hidden={props.ophtalmological}>
                        <span>{ActNature[act.nature].short}</span>
                    </div>
                    <div style={{width: "220px", margin: 0}}>
                        <Tooltip text={act.description} id={`tt_description_${act.id}`}>
                            <span className="truncate">{act.description}</span>
                        </Tooltip>
                    </div>
                </div>
                <div style={{display: "flex", gap: 10, width: "fit-content"}}>
                    <a className="btn btn-small blue darken-2 z-depth-0 hide-on-small-only"
                       onClick={() => edit_act(act)}>Modifier</a>
                    <a className="btn btn-small blue darken-2 z-depth-0 hide-on-med-and-up"
                       onClick={() => edit_act(act)}><i
                        className="material-icons">edit</i></a>
                </div>
            </div>
        )
    }

    if (_.isEmpty(acts)) {
        return <div style={{margin: 20}}>
            <Loader size="tiny" text=""/>
        </div>
    }

    return <div className="container transparent">
        <div className="collection" style={{borderRadius: 20}}>
            {acts.sort((a, b) => ActNature[a.nature].short > ActNature[b.nature].short).map((act) => {
                return renderAct(act)
            })}
            <div className="collection-item" style={{display: "flex", width: "100%", justifyContent: "center"}}>
                <a className="btn btn-small blue darken-2 z-depth-0 modal-trigger" data-target={modal_id}
                   onClick={new_act}>
                    <i className="material-icons left">add</i>
                    Nouveau
                </a>
            </div>
        </div>
        {renderModal()}
    </div>
}

ActList.defaultProps = {
    ophtalmological: false
}

export default ActList;