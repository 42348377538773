import React, {Component} from 'react';
import axios from "axios";
import WhatsNew from "./WhatsNew";
import HelpButton from "../Shared/HelpButton";
import Pincode from "../Shared/Pincode/Pincode";
import {toast} from "react-toastify";
import Toastr from "../Shared/Toastr";

export default class LoginPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            email: "",
            password: "",
            rememberMe: false,
            errorMsg: "",
            otp: false,
            otp_pin: "",
            display_pincode: true,
            otp_code: "",
            backup_code: "",
        }
    }

    onKeyDown(e) {
        if (e.key === "Enter") {
            this.login()
        }
    }

    componentDidMount() {
        let els = document.getElementsByClassName("input")
        Array.prototype.forEach.call(els, el => el.style.backgroundColor = "red")
        let csrfToken = document.querySelector("meta[name='csrf-token']").content
        axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
        document.addEventListener("keydown", this.onKeyDown.bind(this))
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.onKeyDown.bind(this))
    }

    login = () => {
        axios.post("/login_and_otp", {
            email: this.state.email,
            password: this.state.password,
            remember_me: this.state.rememberMe,
            otp_pin: this.state.otp_pin,
            backup_code: this.state.backup_code

        }).then(resp => {
            let data = resp.data
            if (data.status === "LOGIN_DONE") {
                location.reload();
            } else if (data.status === "ERR") {
                this.setState({
                    errorMsg: "Identifiants de connexion invalides",
                    loading: false

                })
            } else if (data.status === "pending_otp") {
                this.setState({
                    otp: true,
                    loading: false,
                    errorMsg: ""
                })
            }
        }).catch(err => {
            if (err.response.status === 400) {
                this.setState({
                    errorMsg: "Identifiant ou mot de passe inconnu"
                })
            }
        })
    }

    handlePin = (value) => {
        this.setState({otp_pin: value}, () => {
            if (this.state.otp_pin.length === 6) this.login()
        })
    }

    renderLogin() {
        return (
            <div className="container">
                <h1 className="center" id="main-title">Bienvenue</h1>
                <form className="card center-align" onSubmit={this.login} style={{maxWidth: "500px", margin: "auto"}}>
                    <div className="input-field">
                        <input id="email" type="email"
                               value={this.state.email}
                               className="validate"
                               data-cy="EmailInput"
                               onChange={(e) => this.setState({email: e.target.value})}/>
                        <label htmlFor="email">Email</label>
                    </div>
                    <br/>
                    <div className="input-field">
                        <input id="pass"
                               type="password"
                               value={this.state.password}
                               className="validate"
                               data-cy="PasswordInput"
                               onChange={(e) => this.setState({password: e.target.value})}
                        />
                        <label htmlFor="pass">Mot de passe</label>
                    </div>
                    <p className="red-text" data-cy="error_login">{this.state.errorMsg}</p>
                    <p className="left-align">
                        <label>
                            <input type="checkbox" id="rememberMe"
                                   onChange={() => this.setState({rememberMe: document.getElementById("rememberMe").checked})}/>
                            <span>Se souvenir de moi</span>
                        </label>
                    </p>
                    <a className="btn z-depth-0"
                       type="submit"
                       style={{backgroundColor: "#f86a6d"}}
                       data-cy="ConnexionButton"
                       onClick={this.login}>Se
                        connecter</a>
                </form>
            </div>
        )
    }

    renderOtp() {
        return (
            <React.Fragment>
                {this.state.loading ? this.renderLoader() : (<div/>)}
                <h1 className="center" id="main-title">Double authentification</h1>
                {this.state.display_pincode ? <div className="valign-wrapper" style={{justifyContent: "center"}}>
                    <h6 className="center" style={{margin: 0}}>Entrez votre code à 6 chiffres</h6>
                    <HelpButton text="Sur votre téléphone, ouvrez votre application de gestion de mots de passe à usage unique
                    (comme Authy ou Google Authenticator), et ouvrez la page correspondant à l'application Temeoo, un mot de passe temporaire
                    s'affiche (il change toutes les 30 secondes !). Saisissez ce mot de passe ici pour valider votre authentification.
                    Vous n'aurez plus besoin de répéter cette étape avant un moment."
                                videoSrc={"/videos/authent_2FA.mp4"}
                                height="50vh"
                                size="small"/>
                </div> : null}
                <div style={{display: "flex", justifyContent: "center"}}>
                    {this.state.display_pincode ?
                        <Pincode onChange={this.handlePin}/>
                        : <div className="input-field col s12 outlined">
                            <h6>Utiliser un code de récupération:</h6>
                            <input
                                id="title"
                                type="text"
                                placeholder="Entrez un code de récupération"
                                onChange={(e) => this.setState({backup_code: e.target.value, errorMsg: ""})}
                            />
                        </div>}
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "1.25vh",
                    flexDirection: "column",
                    alignItems: "center"
                }}>
                    <span style={{cursor: "pointer"}}
                          onClick={() => this.setState({display_pincode: !this.state.display_pincode, errorMsg: ""})}>
                    {this.state.display_pincode ? "Je n'ai plus accès à mon authentificateur." : "J'ai accès à mon authentificateur."}
                    </span>
                    <p className="red-text" data-cy="error_login">{this.state.errorMsg}</p>
                    <a className={`btn blue darken-2 z-depth-0 ${this.state.otp_pin.length === 6 || this.state.backup_code.length === 6 ? "" : "disabled"}`}
                       onClick={this.login}
                       style={{width: "fit-content"}}
                    >
                        Se connecter
                    </a>
                </div>

            </React.Fragment>
        )
    }

    renderLoader() {
        return (
            <div className="progress" style={{margin: 0}}>
                <div className="indeterminate"></div>
            </div>
        )
    }

    render() {
        return (
            <div>
                {this.state.otp ? (<div/>) : this.renderLogin()}
                {(this.state.otp && !this.state.loading) ? this.renderOtp() : (<div/>)}
                {this.props.brand !== "WHITE" ? (<WhatsNew/>) : (<div/>)}
                <Toastr/>
            </div>
        )
    }
}
