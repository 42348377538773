import React, {useEffect, useState} from "react";

export default function SimplePincode(props){
    const [pin_numbers, setPinNumbers] = useState(Array(props.pinCount).fill(""))

    useEffect(() => {
        props.handleValueChanges(0, pin_numbers.join("").replace(" ", ""))
    }, [pin_numbers])
    useEffect(() => {
        // copy if props.currentPin is not empty
        if (props.initialPin !== undefined && props.initialPin.length > 0 && props.initialPin.length === props.pinCount) {
            let arr = props.initialPin.split("")
            setPinNumbers(arr)
        }
    }, [])





    return (
        <div className="center pinlogin hide-on-small-only">
            {[...Array(props.pinCount).keys()].map((index) => {
                return (
                    <input id={"pin-" + index} className="pinlogin-field" key={index} width={50}
                           value={pin_numbers[index] || ""}

                           readOnly={true}

                           onKeyUp={(e) => {
                               //let value = e.target.value
                               e.preventDefault();
                               if (e.key === "Delete" || e.key === "Backspace") {

                                   if (pin_numbers[index].length <= 0) {
                                       if (index > 0) {
                                           document.getElementById("pin-" + (index - 1)).focus()
                                       }
                                   } else {
                                       let temp_array = [...pin_numbers]
                                       temp_array[index] = ""
                                       setPinNumbers(temp_array)
                                   }
                                   return;
                               }
                               if (e.key === "Left" || e.key === "ArrowLeft") {

                                   if (index > 0) document.getElementById("pin-" + (index - 1)).focus()
                                   return;
                               }
                               if (e.key === "Tab" || e.key === "ArrowRight" || e.key === "Right") {

                                   if (index < props.pinCount - 1 && pin_numbers[index].length > 0) {
                                       document.getElementById("pin-" + (index + 1)).focus()
                                   }
                                   return;
                               }
                               if (e.key === "Backspace" || e.key === "Shift" || e.key === "Alt" || e.key === "Ctrl") return

                               // check that it a number or a letter upper or lower case
                               if (!e.key.match(/^[0-9a-zA-Z]$/)) return
                               let temp_array = [...pin_numbers]
                               temp_array[index] = e.key.toString()
                               setPinNumbers(temp_array)

                               // focus on next input
                               if (index < props.pinCount - 1) document.getElementById("pin-" + (index + 1)).focus()
                           }}/>
                )
            })}
        </div>
    )
}