import { action, observable,computed } from "mobx"
import moment from "moment"
import axios from "axios"

class FlagsStore{
  @observable flagships = []
  @action getFlags(){

  }
}
