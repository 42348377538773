import React from "react";
import axios from "axios";
import {JOB} from "../Shared/Helper";
import ReactToPrint from "react-to-print";
import Loader from "../Shared/Loader";

const pageStyle = `
@media print {
   body {
       display: block;
       box-sizing: border-box;   
       padding: 20mm 15mm;
       height: auto;
       fontFamily: Arial, sans-serif;
   }
   @page {
      size: auto;   /* auto is the initial value */
      margin: 0;  /* this affects the margin in the printer settings */
   }
}`;


export default class AMY67_Rapport extends React.Component {
    constructor(props) {
        super(props);
        this.componentRef = React.createRef();
        this.state = {
            loaded: false,
            appointment_data: null
        }
    }

    componentDidMount() {
        axios.get(`/api/rapports/amy67?appointment_id=${this.props.appointment_id}&patient_id=${this.props.patient_id}`)
            .then((resp) => {
                this.setState({appointment_data: resp.data.appointment_data, loaded: true}, () => {
                    setTimeout(() => window.status = "ready", 100)
                })
            })
    }

    formatDate = (date) => {
        if (typeof date == "string") {
            date = date.substring(0, 10)
            date = new Date(date)
        }

        return [
            date.getDate().toString().padStart(2, '0'),
            (date.getMonth() + 1).toString().padStart(2, '0'),
            date.getFullYear()
        ].join('-')
    }

    render() {
        return (
            <div>{this.state.loaded ?
                <div>
                    <div className={this.props.print ? "" : "hide"}>
                        <ReactToPrint
                            trigger={() => {
                                return (
                                    <a className="btn blue darken-2 z-depth-0"
                                       style={{position: "absolute", top: "5%", right: "5%"}}>
                                        Imprimer
                                    </a>)
                            }}
                            content={() => this.componentRef.current}
                            documentTitle="Compte Rendu RNM"
                            pageStyle={pageStyle}
                        >
                        </ReactToPrint>
                    </div>
                    <div style={{margin: "0 20vw"}}>
                        <div ref={this.componentRef} style={{width: "100%"}}>
                            <div className="pdf-document">
                                <div className="prevent-break" style={{display: "flex", flexDirection: "column", gap: 20, paddingTop: 20}}>
                                    <h2 style={{alignSelf: "center", margin: 0}}>
                                        Dépistage de la rétinopathie diabétique
                                    </h2>
                                    <h2 style={{alignSelf: "center", margin: 0}}>
                                        Compte rendu de la télé-expertise
                                    </h2>
                                    <h3 style={{alignSelf: "center"}}>Informations générales</h3>
                                    <h5 style={{margin: 0}}>
                                        <b>Date du dépistage
                                            : </b>{this.formatDate(this.state.appointment_data["appointment"]["start"])}
                                    </h5>
                                    <h5 style={{margin: 0}}>
                                        <b>{JOB.charAt(0).toUpperCase() + JOB.slice(1)} : </b>
                                        {this.state.appointment_data["orthoptist"]["name"] || ""} {this.state.appointment_data["orthoptist"]["surname"] || ""}
                                    </h5>
                                    <h3 style={{alignSelf: "center"}}>Identification du patient</h3>
                                    <h5 style={{margin: 0}}>
                                        <b>Nom d'usage : </b>
                                        {this.state.appointment_data["patient"]["name"]}
                                    </h5>
                                    <h5 style={{margin: 0}}>
                                        <b>Prénom : </b>
                                        {this.state.appointment_data["patient"]["surname"]}
                                    </h5>
                                    <h5 style={{margin: 0}}>
                                        <b>Date de naissance : </b>
                                        {this.formatDate(this.state.appointment_data["patient"]["birthday"])}
                                    </h5>
                                    <h4 style={{alignSelf: "center"}}>Données cliniques</h4>
                                    <h5 style={{margin: 0}}>
                                        <b>Diabète : </b>
                                        {this.state.appointment_data["appointment"]["amy67_diabetes"] || "-"}
                                    </h5>
                                    <h5 style={{margin: 0}}>
                                        <b>Année de découverte du diabète : </b>
                                        {this.state.appointment_data["appointment"]["diabetes_discovery"] || "-"}
                                    </h5>
                                    <h5 style={{margin: 0}}>
                                        <b>Dernière HBa1C : </b>
                                        {this.state.appointment_data["appointment"]["hb1ac"] || "-"}
                                    </h5>

                                    <h5 style={{margin: 0}}>
                                        <b>Suivi ophtalmologique : </b>
                                        {this.state.appointment_data["appointment"]["ophthalmic_follow_up"] || "-"}
                                    </h5>
                                    <h5 style={{margin: 0}}>
                                        <b>Ophtalmologiste : </b>
                                        {this.state.appointment_data["appointment"]["name_ophthalmologist"]}
                                    </h5>
                                    <h5 style={{margin: 0}}>
                                        <b>Date du dernier examen : </b>
                                        {this.state.appointment_data["appointment"]["last_exam_ophthalmologist"] || "-"}
                                    </h5>
                                </div>
                                <div className="center" style={{marginBlock: 40}}>
                                    {this.state.appointment_data["appointment"]["hasRPD"] ?
                                        <h4>L'oeil gauche ou/et l'oeil droit
                                            présentent des signes de rétinopathie diabétique</h4>
                                        :
                                        <h4>L'oeil gauche et l'oeil droit ne
                                            présentent pas de signes de rétinopathie diabétique</h4>
                                    }
                                </div>
                                {/*Pas d'images affichées ici, à décommenter si besoin*/}
                                {/*<div style={{*/}
                                {/*    display: "flex",*/}
                                {/*    flexWrap: "wrap",*/}
                                {/*    justifyContent: "center",*/}
                                {/*    gap: 20*/}
                                {/*}}>*/}
                                {/*    {this.state.appointment_data["appointment"]["images"].map((image) => {*/}
                                {/*        return (*/}
                                {/*            <div key={image["id"]}>*/}
                                {/*                <img src={image["service_url"]} alt=""*/}
                                {/*                     className="responsive-img"*/}
                                {/*                     width="25%"/>*/}
                                {/*            </div>*/}
                                {/*        )*/}
                                {/*    })}*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </div>
                : <div id="full-height"
                       style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Loader wide/>
                </div>
            }

            </div>
        )
    }
}