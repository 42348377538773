import React from "react";

function BillingOverview({last4, bankCode, bankTitle, lastPaymentDate, lastDue, nextDue, fixed, variable}) {
    return <div className="card row" style={{padding: 20}}>
        <div className="col s12 m4" style={{justifySelf: "center"}}>
            <h4>Mon moyen de paiement</h4>
            <h6 className="valign-wrapper">
                <i className="material-icons left blue-text text-darken-2">card_membership</i>
                {"Paiment SEPA"}
            </h6>
            <h6 className="valign-wrapper">
                <i className="material-icons left blue-text text-darken-2">credit_card</i>
                {"Numéro de carte : **** **** **** " + last4}
            </h6>
            <h6 className="valign-wrapper">
                <i className="material-icons left blue-text text-darken-2">account_balance</i>
                {"Banque : " + bankTitle}
            </h6>
            <h6 className="valign-wrapper">
                <i className="material-icons left blue-text text-darken-2">local_atm</i>
                {"Code banque : " + bankCode}
            </h6>
            <div className="valign-wrapper" style={{gap: 10}}>
                <a className="btn blue darken-2 z-depth-0" href="/orthoptistes/paiements/entrer_carte">
                    <i className="material-icons left">currency_exchange</i>
                    Changer
                </a>
                <a className="btn blue darken-2 z-depth-0" href="/orthoptistes/paiements/historique">
                    <i className="material-icons left">history</i>
                    Historique
                </a>
            </div>
        </div>
        <div className="col s12 m4"
             style={{justifySelf: "center", display: "flex", flexDirection: "column", height: "100%"}}>
            <h4>Mon prochain paiement</h4>
            <h6 className="valign-wrapper">
                <i className="material-icons left blue-text text-darken-2">calendar_month</i>
                {Helper.formatDate(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1))}
            </h6>
            <h6 className="valign-wrapper" title={`Consultations : ${nextDue}€${fixed ? `\nForfait : ${fixed}€` : ""}${variable ? `\nVariable : ${variable}€` : ""}`}>
                <i className="material-icons left blue-text text-darken-2">euro_symbol</i>
                {nextDue + fixed + variable} € (*)(**)
            </h6>
            <h6 style={{fontStyle: "italic", marginTop: "auto"}}>(*) Ceci est un prévisionnel qui va évoluer d'ici à la
                prochaine facture</h6>
            <h6 style={{fontStyle: "italic"}}>(**) Ceci prend en compte la part variable et le forfait.</h6>
        </div>
        <div className="col s12 m4"
             style={{justifySelf: "center", display: "flex", flexDirection: "column", height: "100%"}}>
            <h4>Mon dernier paiement</h4>
            <h6 className="valign-wrapper">
                <i className="material-icons left blue-text text-darken-2">calendar_month</i>
                {lastPaymentDate}
            </h6>
            <h6 className="valign-wrapper">
                <i className="material-icons left blue-text text-darken-2">euro_symbol</i>
                {lastDue} €
            </h6>
            <a className="btn blue darken-2 z-depth-0" style={{marginTop: "auto"}}
               href="/orthoptistes/paiements/overview">
                <i className="material-icons left">receipt</i>
                Factures
            </a>
        </div>
    </div>
}

export default BillingOverview;