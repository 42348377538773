import React from "react"
import {HashRouter as Router, Route, Routes} from "react-router-dom"
import Controle from "./Controle"
import Pathologie from "./Pathologie"
import 'react-toastify/dist/ReactToastify.css'
import ControleStore from "./ControleStore"

class Routeur extends React.Component {
    constructor(props) {
        super(props);
        ControleStore.edit_prescription = props.edit_prescription
        this.state = {}
    }



    componentWillUnmount() {}

    render() {
        return (
            <React.Fragment>
                <div id="controle-view">
                    <Router>
                        <Routes>
                        <Route path="/" exact element={<Controle appointmentId={this.props.id} fallback={this.props.fallback}/>}/>
                        <Route path="/pathologie" exact
                               element={<Pathologie textealertes={this.props.textealertes} appointmentId={this.props.id} fallback={this.props.fallback}/>}/>
                        </Routes>
                    </Router>
                </div>
            </React.Fragment>
        );
    }
}

export default Routeur
