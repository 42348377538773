import React, {useEffect, useState} from "react"
import {Container} from "@material-ui/core";
import MesureStore from "../Appointments/Mesures/MesureStore";
import axios from "axios"

const Actes = (props) => {

    const [actesList, setActesList] = useState([])


    useEffect(() => {
        axios.get("/api/getPossibilitesPayement", {params: {appointment_id: MesureStore.appointement_id}}).then((data) => {
            setActesList(data.data[1])
        })
    }, [])

    const validateData = (value) => {
        console.log(value)
        axios.post("/api/saveActesAndPayment", {
            payement_system_id: null,
            actes: [value],
            appointement_id: MesureStore.appointement_id
        })
        localStorage.setItem('actes', JSON.stringify(true));
    }


    return (
        <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
            <h1 className="center">Veuillez sélectionner un acte :</h1>
            <div className="row">
                {actesList.map((value, index) => (
                    <div key={index} className="col s3">
                        <a href="#" className="btn btn-large" onClick={() => validateData(value)}
                           style={{marginTop: 10, width: "100%"}}>{value.titre}</a>
                    </div>
                ))}

            </div>
        </Container>

    );
}

export default Actes

/*
          <a href="#" className="btn mb-2 align-bottom" style={{marginTop: 10}}>value</a>

 */
