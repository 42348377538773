import Validator from "../../Shared/Validator";
import React from "react";
import axios from "axios";

const AskForReworkButton = ({appointment_id, reworkable, onValidation, big}) => {
    const askForRework = (reason) => {
        reason = reason || ""
        axios.post(`/admin/appointement/rework?id=${appointment_id}`, {reason: reason}).then(() => {
            onValidation(appointment_id)
        })
    }

    const renderButton = () => {
        if(big) return <a style={{borderRadius: "10px"}} className="btn-large blue darken-2 truncate-nohide z-depth-0">
            Revoir
        </a>
        return <a className={`btn-small blue darken-2 z-depth-0 ${reworkable || "disabled"}`}>
            <i className="material-icons left hide-on-med-and-down">undo</i>
            <i className="material-icons hide-on-large-only">undo</i>
            <span>À retravailler</span>
        </a>
    }

    return <Validator id={`ask_for_rework_${appointment_id}`}
                      onValidation={(reason) => askForRework(reason)}
                      text="Voulez-vous vraiment demander à l'orthoptiste de retravailler ce dossier ?"
                      detail={`Ce dossier sera repassé en mode "examen à réaliser" et l'orthoptiste devra le modifier avant de le ressoumettre.`}
                      validationColor="blue darken-2" validationIcon="undo"
                      validationText="Demander à retravailler"
                      abortColor="black" abortIcon="close"
                      input placeholder="Indiquer une raison (facultatif)"
    >
        {renderButton()}
    </Validator>
};

AskForReworkButton.defaultProps = {
    reworkable: true,
    onValidation: () => {
    },
    big: false
}

export default AskForReworkButton;