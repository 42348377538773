export const Colors = [
    {
        "code": "#2ecc71",
        "name": "Rien",
        "alert_type": "rien",
        "gravity": 0,
        "text_color": "green-text",
        "button_color":"green lighten-2",
        "secondary_color": "green lighten-5",

    },
    {
        "code": "#3498db",
        "name": "Bénin",
        "alert_type": "bénin",
        "gravity": 1,
        "text_color": "light-blue-text",
        "button_color":"light-blue lighten-2",
        "secondary_color": "light-blue lighten-5",
    },
    {
        "code": "#f39c12",
        "name": "Moyen",
        "alert_type": "moyen",
        "gravity": 2,
        "text_color": "orange-text",
        "button_color":"orange lighten-2",
        "secondary_color": "orange lighten-5",

    },
    {
        "code": "#e74c3c",
        "name": "Grave",
        "alert_type": "grave",
        "gravity": 3,
        "text_color": "deep-orange-text",
        "button_color":"deep-orange lighten-2",
        "secondary_color": "deep-orange lighten-5",
    },
    {
        "code": "#1abc9c",
        "name": "Pas de RPD",
        "alert_type": "pas de rpd",
        "gravity": 4,
        "text_color": "teal-text",
        "button_color":"blue-grey lighten-3",
        "secondary_color": "teal accent-1",

    },
    {
        "code": "#2c3e50",
        "name": "Redirection",
        "alert_type": "redirection",
        "gravity": 5,
        "text_color": "blue-grey-text",
        "button_color":"blue-grey lighten-2",
        "secondary_color": "blue-grey lighten-4",
    },
    {
        "code": "#9b59b6",
        "name": "Mail",
        "alert_type": "mail",
        "gravity": 6,
        "text_color": "pink-text",
        "button_color":"pink lighten-3",
        "secondary_color": "pink lighten-4",
    },
    {
        "code": "#8e44ad",
        "name": "Mail-SMS",
        "alert_type": "mail-sms",
        "gravity": 7,
        "text_color": "purple-text",
        "button_color":"purple lighten-2",
        "secondary_color": "purple lighten-4",
    },
    {
        "code": "#95a5a6",
        "name": "Aucune information",
        "alert_type": "no_info",
        "gravity": 8,
        "text_color": "blue-grey-text",
        "button_color":"blue-grey lighten-2",
        "secondary_color": "blue-grey lighten-4",
    }
]