import React, {Fragment, useEffect, useState} from 'react';
import {HashRouter as Router, Link, NavLink, Route, Routes, useNavigate, useParams} from "react-router-dom";
import EmptyConversation from "./Conversation/EmptyConversation";
import Conversation from "./Conversation/Conversation";
import {observer, Observer} from "mobx-react";
import MessengerStore from "./MessengerStore";
import MessageNotifier from "./MessageNotifier";
import Helper from "../Shared/Helper";
import SearchBar from "./SearchBar";
import NewConversationButton from "./Conversation/NewConversationButton";

const _ = require('lodash');

const MessengerRouter = observer((props) => {
    const {user_id} = props;
    const unreadByConversation = MessengerStore.unreadByConversation;
    const [filter, setFilter] = useState('');
    const {id} = useParams();

    useEffect(() => {
        const instance = M.Sidenav.init(document.getElementById('messenger_sidenav'), {})
        return () => instance.destroy();
    }, []);

    useEffect(() => {
        MessengerStore.userId = user_id
    }, [user_id]);

    useEffect(() => {
        MessengerStore.conversations = props.conversations
    }, [props.conversations]);

    const renderUnreadBadge = (conversation_id) => {
        const unread_nb = unreadByConversation[conversation_id] || 0;
        if (!unread_nb) return null;
        return <span className="new badge blue darken-2" style={{borderRadius: 20}}
                     data-badge-caption="">{unread_nb}</span>
    }

    const renderDate = (conversation) => {
        const date = conversation.last_message_date
        if (!date) return ""
        return `- ${Helper.computeDate(new Date(date))}`
    }
    return <Router>
        <div id='full-height' style={{display: "flex"}}>
            <div className="hoverable-scroll-box" style={{minWidth: "300px", boxShadow: "2px 0 0 0 #E4E4E4"}}>
                <ul id="messenger_sidenav" className="sidenav sidenav-fixed white" style={{paddingBlock: 10, zIndex: 100}}>
                    <li style={{marginBottom: 10, marginInline: 20}} key="searchbar">
                        <div className="valign-wrapper" style={{gap: 20}}>
                            <SearchBar value={filter} onChange={setFilter}/>
                            <div style={{marginLeft: "auto"}}>
                                <NewConversationButton/>
                            </div>
                        </div>
                    </li>
                    <li key="searchbar_divider">
                        <div className="divider" key={`divider_searchbar`}
                             style={{marginBlock: 10}}></div>
                    </li>
                    {MessengerStore.conversations.filter(conv => conv.name.toLowerCase().includes(filter.toLowerCase()))
                        .sort((a, b) => new Date(b.last_message_date) - new Date(a.last_message_date))
                        .map((conversation) => {
                            const icon = conversation.group_type === "PRIVATE" ? "person" : "groups";
                            return <Fragment key={`messengernav_fragment_${conversation.id}`}>
                                <li key={`conversation_${conversation.name}`} className="messenger-navlink">
                                    <NavLink to={`/${conversation.id}`} style={{marginInline: "10px!important"}}
                                          className={`truncate`} key={`conversation_link_${conversation.name}`}
                                          title={`${conversation.name} ${renderDate(conversation)}`}>
                                        {renderUnreadBadge(conversation.id)}
                                        <i className="material-icons left">{icon}</i>
                                        {Helper.capitalizeFirstLetter(conversation.name)}
                                    </NavLink>
                                    <MessageNotifier conversation_id={conversation.id}
                                                     onReceived={MessengerStore.messageReceived}/>
                                </li>
                                <li>
                                    <div className="divider" key={`divider_${conversation.name}`}
                                         style={{marginBlock: 10}}>
                                    </div>
                                </li>
                            </Fragment>
                        })}
                </ul>
            </div>
            <div style={{width: "100%"}}>
                <Routes>
                    <Route key={`no_conversation_route`} path={`/`} element={<EmptyConversation/>}/>
                    <Route key={`conversation_route`} path={`/:id`} element={<Conversation user_id={user_id}/>}/>
                </Routes>
            </div>
        </div>
    </Router>
});

export default MessengerRouter;