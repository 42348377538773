import React, {useEffect} from "react"
import DisplayHeader from "./Display/DisplayHeader";
import DisplayInput from "./Display/DisplayInput";
import DisplayText from "./Display/DisplayText";
import DisplayFooter from "./Display/DisplayFooter";
import DisplayDivider from "./Display/DisplayDivider";
import DisplayGroup from "./Display/DisplayGroup";
import DisplayImage from "./Display/DisplayImage";
import PropTypes from "prop-types";

const ReportDocument = ({template, hiddenBlocks, values}) => {

    useEffect(() => {
        window.status = "ready"
    }, []);

    const compute = (block, hiddenBlocks, values) => {
        if (hiddenBlocks.includes(block.id)) return null;
        switch (block.type) {
            case "HEADER":
                return DisplayHeader.preview(block)
            case "INPUT":
                return DisplayInput.preview(block, values)
            case "TEXT":
                return DisplayText.preview(block)
            case "FOOTER":
                return DisplayFooter.preview(block)
            case "DIVIDER":
                return DisplayDivider.preview(block)
            case "IMAGE":
                return DisplayImage.preview(block)
            case "GROUP":
                return DisplayGroup.preview(block, hiddenBlocks, values)
        }
    }

    return <div className="valign-wrapper pdf-document"
                style={{
                    width: "21cm",
                    height: "100%",
                    justifyContent: "center",
                    fontSize: "12px",
                    fontFamily: "Arial"
                }}>
        <div style={{width: "90%", height: "90%", display: "flex", flexDirection: "column"}}>
            {template.blocks.map((block, index) =>
                <div key={index} style={{marginTop: `${block.type === "FOOTER" ? "auto" : ""}`}}>
                    {compute(block, hiddenBlocks, values)}
                </div>
            )}
        </div>
    </div>
}

ReportDocument.propTypes = {
    template: PropTypes.object.isRequired,
    hiddenBlocks: PropTypes.array.isRequired,
    values: PropTypes.object.isRequired
}

export default ReportDocument