import React from "react";

export default class ActionMedia extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isHovered: false,
        }
    }

    componentDidMount() {
        if (this.props.video || !this.props.magnifiable || this.props.src === undefined) return
        const img = document.getElementById(`boxed_img_${this.props.src["img_id"]}`)
        M.Materialbox.init(img, {})
    }

    componentWillUnmount() {
        if (this.props.video || !this.props.magnifiable || this.props.src === undefined) return
        const img = document.getElementById(`boxed_img_${this.props.src["img_id"]}`)
        const instance = img ? M.Materialbox.getInstance(img) : null
        if (instance) instance.destroy()
    }

    handlePosition = () => {
        switch (this.props.position) {
            case "top-right":
                return {top: "5%", right: "5%", position: "absolute", cursor: "pointer"}
            case "bottom-right":
                return {bottom: "5%", right: "5%", position: "absolute", cursor: "pointer"}
            case "bottom-left":
                return {bottom: "5%", left: "5%", position: "absolute", cursor: "pointer"}
            case "top-left":
                return {top: "5%", left: "5%", position: "absolute", cursor: "pointer"}
            default:
                return {}
        }
    }

    startHovering = () => {
        this.setState({isHovered: true})
    }
    endHovering = () => {
        this.setState({isHovered: false})
    }

    render() {
        const buttonPosition = this.handlePosition()
        if (this.props.src === undefined) {
            return (<span/>)
        } else {
            return (
                <div style={{position: "relative", width: "fit-content"}} onMouseEnter={this.startHovering}
                     onMouseLeave={this.endHovering}>
                    {this.props.video ? (<video className="responsive-video" loop autoPlay controls
                                                style={{
                                                    display: 'inline',
                                                    width: `${this.props.imgWidth}`,
                                                    maxWidth: "100%",
                                                    borderRadius: "30px"
                                                }}>
                            <source src={this.props.src.video} type="video/mp4"/>
                            Your browser does not support the video tag.
                        </video>)
                        :
                        (<img src={this.props.src["img"]} className={this.props.magnifiable ? "materialboxed" : ""}
                              id={`boxed_img_${this.props.src["img_id"]}`}
                              style={{display: 'inline', width: `${this.props.imgWidth}`, maxWidth: "100%"}}
                              alt=""/>)}
                    {this.state.isHovered ?
                        <a style={buttonPosition}
                           onClick={() => this.props.onClick(this.props.src.img_id || this.props.src.video_id)}>
                            <i className={`material-icons ${this.props.iconColor}-text`}>{this.props.icon}</i>
                        </a>
                        : null}
                    {this.props.isSelected ?
                        <a style={{bottom: "5%", left: "5%", position: "absolute", cursor: "pointer"}}>
                            <i className="material-icons white-text">check_box</i>
                        </a>
                        : null}
                </div>
            )
        }
    }
}

ActionMedia.defaultProps = {
    icon: "cancel",
    onClick: () => {
        return null
    },
    position: "up-right",
    iconColor: "white",
    imgWidth: "15vw",
    isSelected: false,
    video: false,
    magnifiable: false
}