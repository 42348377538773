import React from "react"
import {observer} from "mobx-react"
import ActiveTabs from "../mesure_components/ActiveTabs"

@observer
class Synthese extends React.Component {
  constructor(props) {
    super(props);
    this.choixData = this.choixData.bind(this)
  }
  choixData(){

  }
  componentDidMount(){
    // PrescriptionStore.loadIfExist(MesureStore.appointement_id)

  }
  render () {
    return (
      <React.Fragment>
        <div className="row grey white" >
          <h3 className="center">Synthèse rendez-vous</h3>
          <div className="row">
            <div className="col s2">
              <ActiveTabs clickFunc={this.choixData} />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Synthese
