import React from "react";
import RightsStore from "../../RightsStore";
import {UserPermissions, UserRights} from "../../../../Shared/Enums/Enums";
import EditButton from "../../../../Shared/EditButton";
import {useBillingSettings} from "../../../../Hooks/console/billing/useBillingSettings";
import PricingSettings from "../../PricingSettings";

const _ = require("lodash")
function BillingSettings() {
    const host = "/admin/console/orthoptists/billing_settings"
    const { categories, createCategory, addAct, editSetting } = useBillingSettings(host);

    const renderTable = () => {
        if (_.isEmpty(categories)) return <h4>Aucune catégorie n'a été créée</h4>

        return <table className="striped bordered responsive-table">
            <thead>
            <tr>
                <th>Actes</th>
                {categories.map((category) => {
                    return <th style={{paddingLeft: 30}} key={category.name}>{Helper.capitalizeFirstLetter(category.name)}</th>
                })}
            </tr>
            </thead>
            <tbody>
            {
                Object.entries(categories.reduce((acc, category) => {
                    category.billing_settings.forEach((setting) => {
                        const act_name = `${setting.actepossible.titre} (${setting.actepossible.prix}€)`
                        if (!acc[act_name]) {
                            acc[act_name] = []
                        }
                        acc[act_name].push(setting)
                    })
                    return acc
                }, {})).sort(([name, settings], [name2, settings2]) => name > name2)
                    .map(([name, settings]) => {
                    return <tr key={name}>
                        <td>{name}</td>
                        {settings.map((setting, index) => {
                            return <td key={`${name}_${index}`}>
                                <div style={{maxWidth: 100}}>
                                    <EditButton color="transparent" textColor="blue"
                                                suffix={"%"}
                                                disabled={!RightsStore.can_do(UserRights.BILLING, UserPermissions.WRITE_ONLY)}
                                                text={setting.percent.toFixed(2)}
                                                onValidation={(v) => editSetting({...setting, percent: v})}
                                                filter={(e) => e.target.value.match(/^\d*[.,]?\d{0,2}$/g)}/>
                                    <EditButton color="transparent" textColor="green"
                                                suffix={"€"}
                                                disabled={!RightsStore.can_do(UserRights.BILLING, UserPermissions.WRITE_ONLY)}
                                                text={(setting.percent * setting.actepossible.prix / 100).toFixed(2)}
                                                onValidation={(v) => editSetting({...setting, percent: v*100/setting.actepossible.prix})}
                                                filter={(e) => e.target.value.match(/^\d*[.,]?\d{0,2}$/g)}/>
                                </div>
                            </td>
                        })}
                    </tr>
                })
            }
            </tbody>
        </table>
    }

    return <PricingSettings icon = "price_change" title="Paramétrage de la facturation" tableRender={renderTable()}
                            categories={categories} addAct={addAct} createCategory={createCategory}/>


}

export default BillingSettings