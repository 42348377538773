import {ArcElement, Chart as ChartJS, Legend, Tooltip} from "chart.js";
import React, {Component} from "react";
import {Doughnut} from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export default class PercentDoughnut extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: {
                datasets: [
                    {
                        data: [0, 100],
                        backgroundColor: [
                            "#64b5f6",
                            "#b0bec5"
                        ],
                        borderWidth: 10,
                        borderRadius: 10,
                        cutout: "80%",
                        animation: {
                            animateRotate: false
                        }
                    },
                ],
            },
            options: {
                legend: {
                    display: false,
                }
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const round = Math.round(this.props.percent)
        if (prevState.data.datasets[0].data[0] !== round) {
            this.setState({
                data: Object.assign({}, this.state.data, {
                    datasets: [Object.assign({}, this.state.data.datasets[0], {
                        data: [round, 100 - round]
                    })]
                })
            })
        }
    }

    render() {
        const centerText = {
            id: 'centerText',
            afterDatasetsDraw(chart, args, options, cancelable) {
                const {ctx, chartArea: {top, width, height}} = chart
                ctx.save()
                ctx.font = "bolder 4em Roboto sans-serif"
                ctx.fillStyle = "#64b5f6"
                ctx.textAlign = "center"
                ctx.fillText(typeof chart.data.datasets[0].data[0] !== 'undefined' ? chart.data.datasets[0].data[0] + "%" : "", width / 2, height / 2 + top + 5)
            }
        }
        return (
            <div style={{
                margin: "auto"
            }}>
                <h2 className="center-align  grey-text text-darken-1"
                    style={{fontWeight: "bold", height: "50px"}}>{this.props.title}</h2>
                <Doughnut data={this.state.data} options={this.state.options} plugins={[centerText]}></Doughnut>
            </div>
        )
    }
}
