import { I18n } from "i18n-js";
import en from "./translations/en.json";
import fr from "./translations/fr.json";

const i18n = new I18n({
    en: en,
    fr: fr
});
i18n.defaultLocale = "fr";
i18n.locale = "fr";
i18n.fallbacks = true;
i18n.missingBehavior = "guess";


const t = i18n.t.bind(i18n);
export { i18n, t };
