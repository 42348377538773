import React from "react";
import axios from "axios";
import {loadStripe} from "@stripe/stripe-js";
import Loader from "../Shared/Loader";
import {RequestState} from "../Shared/StateHelper";

export default class Payment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            payment_data: null,
            requestState: RequestState.LOADING,
            stripePromise: null
        }
    }

    componentDidMount() {
        axios.get("/payment_data", {params: {id: this.props.appointment_id}})
            .then((response) => {
                this.setState({
                    payment_data: response.data["payment_data"],
                    stripePromise: loadStripe(response.data.stripe_pubkey)
                }, () => {
                    this.setState({requestState: RequestState.SUCCESS})
                })
            }).catch((error) => {
            this.setState({requestState: RequestState.ERROR})
        })
    }

    handleStripeCheckout = async () => {
        const stripe = await this.state.stripePromise;
        stripe.redirectToCheckout({sessionId: this.state.payment_data["session_id"]})
            .then((data) => {
                alert(data.error.message)
            })
    }

    render() {
        if (this.state.requestState === RequestState.LOADING) {
            return <Loader/>
        }
        if (this.state.requestState === RequestState.ERROR) {
            return <div className="center">
                <h1>Erreur</h1>
                <p>Une erreur est survenue lors de la récupération des données de paiement.</p>
            </div>
        }
        if (this.state.requestState === RequestState.SUCCESS) return (
            <div>
                <div className="center">
                    <h1>Paiement</h1>
                    <div className="container">
                        <p>
                            Afin de récupérer votre prescription ou la synthèse complète veuillez payez
                            les {this.state.payment_data["amount"]} €
                            (et {this.state.payment_data["fees"]}€ de frais de paiement) vous ayant été facturés.
                        </p>
                        <p>
                            Nous acceptons les paiements par toutes les cartes de crédit habituelles:
                            Visa, Mastercard, American Express...
                        </p>
                        <p className="btn btn-large z-depth-0" onClick={this.handleStripeCheckout}> Payer
                            maintenant</p>
                    </div>
                </div>
            </div>
        )
    }
}