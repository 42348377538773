import React from "react";

const Loader = (props) => {

    // if (props.wide) return <div className="valign-wrapper loader">
    //     <img height="85px" src="/imgs/lunettes.svg"/>
    //     <span className="reflect"></span>
    // </div>

    return (
        <div className={`${props.minimized || "container"} center-align`} style={props.style}>
            <div className={`preloader-wrapper ${props.size} active`}>
                <div className={`spinner-layer spinner-${props.color}-only`}>
                    <div className="circle-clipper left">
                        <div className="circle"></div>
                    </div>
                    <div className="gap-patch">
                        <div className="circle"></div>
                    </div>
                    <div className="circle-clipper right">
                        <div className="circle"></div>
                    </div>
                </div>
            </div>

            {props.show_text && !props.wide ? <h5 className={props.textColor}>{props.text}</h5> : null}
            {props.show_text && !props.wide ? <label className={props.textColor}>{props.description}</label> : null}
        </div>
    )
}

Loader.defaultProps = {
    text: "Chargement en cours ...",
    description: "",
    size: "big",
    color: "blue",
    show_text: true,
    style: {},
    textColor: "",
    wide: false,
    minimized: false
}

export default Loader