import React from 'react';
import Overview from "./Overview";
import ProfitChart from "./ProfitChart";

function DashboardConsole({orthoptists}) {
    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            <h3 className="valign-wrapper" style={{margin: 20}}><i className="material-icons left">attach_money</i>
                Chiffre d'affaires
            </h3>
            <div className="divider"/>
            <div style={{margin: 20}}>
                <Overview orthoptists={orthoptists}/>
            </div>
            <div className="divider"/>
            {/*<div style={{ margin: 20}}>*/}
            {/*    <ProfitChart/>*/}
            {/*</div>*/}
        </div>
    );
}

export default DashboardConsole;