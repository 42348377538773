import React, {Component} from "react";

export default class FontSelect extends Component {
    constructor(props) {
        super(props);
    }

    changeStyle(style) {
        this.props.onStyleChange(style)
    }

    changeWeight(weight) {
        this.props.onWeightChange(weight)
    }

    changeSize(size) {
        this.props.onSizeChange(size)
    }

    render() {
        return (
            <div className="valign-wrapper" style={{gap: 10}}>
                {/*STYLE*/}
                <div className="valign-wrapper">
                    <a className={`btn waves-light btn-small z-depth-0 ${this.props.style.toLowerCase() === "italic" ? "blue darken-2" : "grey lighten-3 black-text"}`}
                       onClick={() => this.changeStyle(this.props.style.toLowerCase() === "italic" ? "" : "italic")}
                       style={{borderRadius: "5px 0 0 5px", padding: "0 7px 0 7px", margin: 0}}><i
                        className="material-icons">format_italic</i></a>
                    {/*WEIGHT*/}
                    <a className={`btn waves-light btn-small z-depth-0 ${this.props.weight.toLowerCase() === "bold" ? "blue darken-2" : "grey lighten-3 black-text"}`}
                       onClick={() => this.changeWeight(this.props.weight.toLowerCase() === "bold" ? "" : "bold")}
                       style={{borderRadius: "0 5px 5px 0", padding: "0 7px 0 7px", margin: 0}}><i
                        className="material-icons">format_bold</i></a>
                </div>
                {/*SIZE*/}
                <div className="valign-wrapper">
                    <a className={`btn waves-light btn-small z-depth-0 ${this.props.size.toLowerCase() === "large" ? "blue darken-2" : "grey lighten-3 black-text"}`}
                       onClick={() => this.changeSize(this.props.size.toLowerCase() === "large" ? "" : "large")}
                       style={{borderRadius: "5px 0 0 5px", padding: "0 7px 0 7px", margin: 0}}><i
                        className="material-icons">text_increase</i></a>
                    <a className={`btn waves-light btn-small z-depth-0 ${this.props.size.toLowerCase() === "small" ? "blue darken-2" : "grey lighten-3 black-text"}`}
                       onClick={() => this.changeSize(this.props.size.toLowerCase() === "small" ? "" : "small")}
                       style={{borderRadius: "0 5px 5px 0", padding: "0 7px 0 7px", margin: 0}}><i
                        className="material-icons">text_decrease</i></a>
                </div>
            </div>
        );
    }
}

FontSelect.defaultProps = {
    size: "",
    weight: "",
    style: ""
}