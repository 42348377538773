import React from "react";
import OphtalmologistPricingConsole from "./OphtalmologistPricingConsole";

function PricingConsole({ophtalmologists}) {
    return (
        <div style={{display: "flex", flexDirection: "column", overflowY: "scroll"}}>
            <h3 className="valign-wrapper" style={{margin: 20}}><i className="material-icons left">visibility</i>Ophtalmologues
            </h3>
            <div className="divider"/>
            <div style={{marginLeft: 2}}>
                <OphtalmologistPricingConsole ophtalmologists={ophtalmologists}/>
            </div>
            <div className="divider"/>
            {/*<h3 className="valign-wrapper" style={{marginLeft: 20}}><i className="material-icons left">visibility</i>Orthoptistes</h3>*/}
            {/*<div className="divider"/>*/}
        </div>
    );
}

export default PricingConsole