import React from "react";
import {observer} from "mobx-react";
import {isLoading, loading, RequestState} from "../../../../../Shared/StateHelper";
import CVStore from "../../../../../CarteVitale/CVStore";
import BridgeStoreV2 from "../../../../../BridgeV2/BridgeStoreV2";
import {toast} from "react-toastify";
import useADRIRightsRequest from "./hooks/useADRIRightsRequest";

const _ = require('lodash')
const ADRIButton = observer(({retracted}) => {
    const {sendADRIRightsRequest} = useADRIRightsRequest()

    const sendRequest = () => {
        if (isLoading(CVStore.adriRequestState)) return
        CVStore.adriRequestState = loading(CVStore.adriRequestState)
        const promise = sendADRIRightsRequest(BridgeStoreV2.uid, CVStore.patient)
        toast.promise(
            promise,
            {
                pending: 'Récupération des droits ADRI...',
                success: 'Droits ADRI récupérés avec succés !',
                error: 'Impossible de récupérer les droits ADRI du patient, Medoc et TemeooDesktop sont-ils bien lancés ?'
            }
        ).then((rights) => {
            CVStore.adriRequestState = RequestState.SUCCESS
            CVStore.adriRights = rights
        }).catch(() => {
            CVStore.adriRequestState = RequestState.ERROR
        })
    }

    const renderButton = () => {
        let color = "blue darken-2"
        if (CVStore.adriRequestState === RequestState.SUCCESS) color = "green darken-2"
        if (CVStore.adriRequestState === RequestState.ERROR) color = "red darken-2"

        if (retracted) return <a className={`btn-small ${color} z-depth-0`} style={{paddingInline: 4}}
                                 onClick={sendRequest}>
            <i className="material-icons">medical_information</i>
        </a>

        return <a className={`btn-small ${color} z-depth-0`} onClick={sendRequest}>
            <i className="material-icons left">medical_information</i>
            Droits ADRI
        </a>
    }

    const renderRights = () => {
        if (_.isEmpty(CVStore.adriRights) || retracted) return null
        return <div style={{display: "flex", flexDirection: "column"}}>
            <h6>Couverture obligatoire : {CVStore.adriRights.couv_obligatoire}</h6>
            <h6>Fin de droits : {CVStore.adriRights.couv_obligatoire_expiration}</h6>
            <h6>Couverture complémentaire : {CVStore.adriRights.couv_complementaire}</h6>
            <h6>Fin de droits : {CVStore.adriRights.couv_complementaire_expiration}</h6>
        </div>
    }

    return <div style={{display: "flex", flexDirection: "column", gap: 10}}>
        {renderButton()}
        {renderRights()}
    </div>

});

ADRIButton.defaultProps = {
    retracted: false
};

export default ADRIButton;