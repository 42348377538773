import {HashRouter as Router, Link, Route, Routes} from "react-router-dom";
import DashboardConsole from "../../admin/Console/Dashboard/DashboardConsole";
import React from "react";
import ActsStatistics from "./Acts/ActsStatistics";

function StatisticsRouter() {
    const CATEGORIES = {
        ACTS: {name: "Actes", icon: "medical_services", path: "/", element: <ActsStatistics/>},
    }

    return (
        <Router>
            <div id='full-height' style={{display: "flex"}}>
                {/*To uncomment when the side bar will need more that 1 item*/}
                {/*    <div style={{width: "300px", boxShadow: "2px 0 0 0 #E4E4E4", backgroundColor: "#f6f6f6"}}>*/}
                {/*        <div className="collection" style={{marginTop: 2}}>*/}
                {/*            {Object.values(CATEGORIES).map((category, index) => {*/}
                {/*                return <Link to={category.path}*/}
                {/*                             key={index}*/}
                {/*                             className={`collection-item black-text`}*/}
                {/*                             style={{*/}
                {/*                                 display: "flex",*/}
                {/*                                 alignItems: "center",*/}
                {/*                                 justifyContent: "space-between"*/}
                {/*                             }}>*/}
                {/*                    <h6 className="hide-on-small-only">{category.name}</h6>*/}
                {/*                    <i className="material-icons grey-text text-darken-2 secondary_content">{category.icon}</i>*/}
                {/*                </Link>*/}
                {/*            })}*/}
                {/*        </div>*/}
                {/*    </div>*/}
                <div style={{width: "100%"}}>
                    <Routes>
                        {Object.values(CATEGORIES).map((category, index) => {
                            return <Route path={category.path} key={index} element={category.element}/>
                        })}
                    </Routes>
                </div>
            </div>
        </Router>

    );
}

export default StatisticsRouter;