import React, { Component } from 'react';
import Snackbar from '@material-ui/core/Snackbar';

import BridgeStore from "./BridgeStore"
import {observer} from "mobx-react"

@observer
export default class Connecteur extends Component {
  constructor(props) {
    super(props)
    BridgeStore.init(props.bridge_ip,props.api_token,props.email)
    BridgeStore.setCable()
  }
  componentWillUnmount(){
    BridgeStore.disconnect()
  }
  render() {
    return (
      <div>
        <Snackbar
          open={BridgeStore.snackbar_open}
          message={BridgeStore.snackbar_message}
          key={"message"}
          />
      </div>
    );
  }
}
