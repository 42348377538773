import React, {Component} from "react";
import axios from "axios";
import {toast} from "react-toastify";

const ISSUE_TYPE = {
    UNSET_TARIFICATION: "UNSET_TARIFICATION",
    UNSET_BANKING: "UNSET_BANKING",
    BAD_HN_PAYER: "BAD_HN_PAYER",
    BAD_PRIORITY_PAYER: "BAD_PRIORITY_PAYER"
}

export default class Issues extends Component {
    constructor(props) {
        super(props);
        this.renderUnsetTarification = this.renderUnsetTarification.bind(this)
        this.renderUnsetBanking = this.renderUnsetBanking.bind(this)
        this.renderBadHnPayer = this.renderBadHnPayer.bind(this)
        this.renderBadPriorityPayer = this.renderBadPriorityPayer.bind(this)
        this.renderFilter = this.renderFilter.bind(this)
        this.setFilter = this.setFilter.bind(this)
        this.state = {
            issues: [],
            filter: {
                show_unset_tarif: true,
                show_unset_banking: true,
                show_bad_payers: true,
            }
        }
    }

    componentDidMount() {
        this.getIssues()
        var elems = document.querySelectorAll('.collapsible');
        var instances = M.Collapsible.init(elems, {});
    }

    getIssues() {
        axios.get("/admin/parameters/issues").then(resp => {
            this.setState({
                issues: resp.data.issues
            })
        }).catch(err => {
            toast.error("Impossible de charger la liste des problèmes")
        })
    }

    renderUnsetTarification(issue, index) {
        if (this.state.filter.show_unset_tarif === true) {
            return (
                <li key={index} className="collection-item row valign-wrapper">
                    <div className="col s4">
                        <div><i className="material-icons red-text left">error</i>Tarifs non renseignés</div>
                    </div>
                    <div className="col s4">
                        <p>{`${issue.firstname} ${issue.lastname.toUpperCase()}`}</p>
                    </div>
                    <div className="col s4 right-align">
                        <a className="btn" href={`/admin/parameters/orthoptiste/${issue.orthoptist_id}`}>
                            <i className="material-icons right">link</i>
                            Voir le problème
                        </a>
                    </div>
                </li>
            )
        }
    }

    renderUnsetBanking(issue, index) {
        if (this.state.filter.show_unset_banking === true) {
            return (
                <li key={index} className="collection-item row valign-wrapper">
                    <div className="col s4">
                        <div><i className="material-icons red-text left">monetization_on</i>Stripe non configuré</div>
                    </div>
                    <div className="col s4">
                        <p>{`${issue.firstname} ${issue.lastname.toUpperCase()}`}</p>
                    </div>
                    <div className="col s4 right-align">
                        <a className="btn disabled">
                            <i className="material-icons right">comment</i>
                            Signaler
                        </a>
                    </div>
                </li>
            )
        }
    }

    renderBadHnPayer(issue, index) {
        if (this.state.filter.show_bad_payers === true) {
            return (
                <li key={index} className="collection-item row valign-wrapper">
                    <div className="col s4">
                        <div><i className="material-icons red-text left">money_off</i>Hors-Nemenclature non payée</div>
                        <p  style={{paddingLeft: "3vw"}}>{`(depuis ${issue.days_pending} jours)`}</p>
                    </div>
                    <div className="col s4">
                        <p>{`${issue.patient_firstname} ${issue.patient_lastname.toUpperCase()}`}</p>
                    </div>
                    <div className="col s4 right-align">
                        <a className="btn" href={`/patients/${issue.patient_id}`}>
                            <i className="material-icons right">info</i>
                            Fiche patient
                        </a>
                    </div>
                </li>
            )
        }
    }

    renderBadPriorityPayer(issue, index) {
        if (this.state.filter.show_bad_payers === true) {
            return (
                <li key={index} className="collection-item row valign-wrapper">
                    <div className="col s4">
                        <div><i className="material-icons red-text left">money_off</i>Priorité non payée</div>
                        <p  style={{paddingLeft: "3vw"}}>{`(depuis ${issue.days_pending} jours)`}</p>
                    </div>
                    <div className="col s4">
                        <p>{`${issue.patient_firstname} ${issue.patient_lastname.toUpperCase()}`}</p>
                    </div>
                    <div className="col s4 right-align">
                        <a className="btn" href={`/patients/${issue.patient_id}`}>
                            <i className="material-icons right">info</i>
                            Fiche patient
                        </a>
                    </div>
                </li>
            )
        }
    }


    setFilter() {
        this.setState({
            filter: Object.assign({}, this.state.filter, {
                show_unset_tarif: document.getElementById("unset_tarif").checked,
                show_unset_banking: document.getElementById("unset_banking").checked,
                show_bad_payers: document.getElementById("bad_payers").checked
            })
        })
    }

    renderFilter() {
        return (
            <ul className="collapsible">
                <li>
                    <div className="collapsible-header"><i className="material-icons">filter_list</i>Filtrer</div>
                    <div className="collapsible-body">
                        <p>
                            <label>
                                <input id="unset_tarif" type="checkbox" className="filled-in"
                                       checked={this.state.filter.show_unset_tarif}
                                       onChange={this.setFilter}/>
                                <span>Tarifs non renseignés</span>
                            </label>
                        </p>
                        <p>
                            <label>
                                <input id="unset_banking" type="checkbox" className="filled-in"
                                       checked={this.state.filter.show_unset_banking}
                                       onChange={this.setFilter}/>
                                <span>Paiements non configurés</span>
                            </label>
                        </p>
                        <p>
                            <label>
                                <input id="bad_payers" type="checkbox" className="filled-in"
                                       checked={this.state.filter.show_bad_payers}
                                       onChange={this.setFilter}/>
                                <span>Mauvais payeurs</span>
                            </label>
                        </p>
                    </div>
                </li>
            </ul>

        )
    }

    render() {
        return (
            <div className="container">
                {this.renderFilter()}
                <ul className="collection">
                    {
                        this.state.issues.map((issue, index) => {
                            if (issue.type.id === ISSUE_TYPE.UNSET_TARIFICATION) {
                                return this.renderUnsetTarification(issue.issue, index)
                            } else if (issue.type.id === ISSUE_TYPE.UNSET_BANKING) {
                                return this.renderUnsetBanking(issue.issue, index)
                            } else if (issue.type.id === ISSUE_TYPE.BAD_HN_PAYER) {
                                return this.renderBadHnPayer(issue.issue, index)
                            } else if (issue.type.id === ISSUE_TYPE.BAD_PRIORITY_PAYER) {
                                return this.renderBadPriorityPayer(issue.issue, index)
                            }
                        })
                    }
                </ul>
            </div>
        )
    }
}