import React from "react"
import axios from "axios"
import {toast} from 'react-toastify';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 300,
        },
    },
};


class WriteComment extends React.Component {
    constructor(props) {
        super(props);
        this.handeWriting = this.handeWriting.bind(this)
        this.sendComment = this.sendComment.bind(this)
        this.handleGravite = this.handleGravite.bind(this)
        this.handleMultiple = this.handleMultiple.bind(this)
        this.emptyWriting = this.emptyWriting.bind(this)
        this.state = {
            text: '',
            loading: false,
            done: false,
            gravite: "",
            possibilites: ["rien", "bénin", "moyen", "grave", "présence de rpd", "pas de rpd", 'redirection', "mail", "mail-sms"],
            fo: false,
            ang: false,
            oct: false,
            champVisuel: false,
            risques: this.props.textealertes,
            risque: "",
            deseases: [],


        };
    }


    emptyWriting(e) {
        e.preventDefault()
        this.setState({text: ""})
    }

    handeWriting(e) {
        this.setState({text: e.target.value})
    }

    handleGravite(e) {
        this.setState({
            gravite: e.target.value
        })
    }


    sendComment(e) {
        e.preventDefault()
        let s = this.state
        axios.post("/admin/alert/save/comment/", {
            risque: s.risque,
            oct: s.oct,
            ang: s.ang,
            champVisuel: s.champVisuel,
            fo: s.fo,
            id: this.props.alert,
            text: this.state.text,
            gravite: this.state.gravite
        }).then((data) => {
            toast.success('Message envoyé', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
            this.setState({done: true})
            window.location.replace(this.props.redirect_uri)
        })
    }

    componentDidMount() {
        this.setState({text: ""})
    }

    handleMultiple(e) {

        this.setState({
            deseases: e.target.value,
            risque: e.target.value.join(", ")
        })
    }

    render() {

        const {editorState} = this.state;
        if (this.state.done == false) {
            return (
                <React.Fragment>

                    <div className="col s8">
                        <div className="row">
                            <div className="col s9">
                                <form action="">
                                    <div className="row">
                                        <div className="input-field col s6">
                                            <textarea id="textarea1" rows="50" cols="30" style={{height: "60vh"}}
                                                      onChange={this.handeWriting} value={this.state.text}
                                                      className=""></textarea>
                                            <label htmlFor="textarea1"></label>
                                        </div>
                                        <div className="col s6">
                                            <div className="input-field">
                                                <select onChange={this.handleGravite} className="browser-default"
                                                        value={this.state.gravite} id="gravite">
                                                    <option value="" disabled>Gravité</option>
                                                    {this.state.possibilites.map((poss, index) => (
                                                        <option key={index} value={poss}>{poss}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            {/* <div className="input-field">
                        <select onChange={(e)=>this.setState({risque: e.target.value})} className="browser-default" value={this.state.risque} id="gravite">
                          <option value="" disabled>Risque</option>
                          {this.state.risques.map((risque,index)=>(
                            <option key={index} value={risque}>{risque}</option>
                            ))}
                        </select>
                      </div>*/}
                                            <FormControl style={{minWidth: "100%"}}>
                                                <InputLabel id="demo-mutiple-checkbox-label">Risque</InputLabel>
                                                <Select
                                                    labelId="demo-mutiple-checkbox-label"
                                                    id="demo-mutiple-checkbox"
                                                    multiple
                                                    input={<Input/>}
                                                    renderValue={(selected) => selected.join(', ')}
                                                    MenuProps={MenuProps}
                                                    value={this.state.deseases}
                                                    onChange={this.handleMultiple}
                                                >
                                                    {this.state.risques.map((name) => (
                                                        <MenuItem key={name} value={name}>
                                                            <Checkbox checked={this.state.deseases.indexOf(name) > -1}/>
                                                            <ListItemText primary={name}/>
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <div className="col s12">
                                                <p>
                                                    <label>
                                                        <input type="checkbox" checked={this.state.fo}
                                                               onChange={(e) => this.setState({fo: !this.state.fo})}/>
                                                        <span>Fond d’oeil</span>
                                                    </label>
                                                </p>
                                                <p>
                                                    <label>
                                                        <input type="checkbox" checked={this.state.oct}
                                                               onChange={(e) => this.setState({oct: !this.state.oct})}/>
                                                        <span>O.C.T</span>
                                                    </label>
                                                </p>
                                                <p>
                                                    <label>
                                                        <input type="checkbox" checked={this.state.champVisuel}
                                                               onChange={(e) => this.setState({champVisuel: !this.state.champVisuel})}/>
                                                        <span>Champ Visuel</span>
                                                    </label>
                                                </p>
                                                <p>
                                                    <label>
                                                        <input type="checkbox" checked={this.state.ang}
                                                               onChange={(e) => this.setState({ang: !this.state.ang})}/>
                                                        <span>Angiographie</span>
                                                    </label>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col s3">
                                {this.state.gravite == "" ? (
                                    <div></div>
                                ) : (
                                    <p className=""><a onClick={this.sendComment}
                                                       className="waves-effect blue waves-light btn"><i
                                        className="material-icons left">send</i>Envoyer</a></p>
                                )}
                                <p className=""><a onClick={this.emptyWriting} className="waves-effect red waves-light btn"><i
                                    className="material-icons left">delete</i>Vider</a></p>
                                {/*<p className=""><a onClick={this.modelOne} className="waves-effect green waves-light btn"><i className="material-icons left">label</i>dépl. imp.</a></p>*/}
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <div className="col s8">
                        <h1 className="green-text center">Commentaire enregistré</h1>
                    </div>
                </React.Fragment>
            )
        }
    }
}

export default WriteComment
