import React from "react";
import axios from "axios";
import {toast} from "react-toastify";

export default class TogglePriority extends React.Component {
    constructor(props) {
        super(props);
        this.changePriority = this.changePriority.bind(this)
        this.state = {
            priority: false,
            price: 0
        }
    }

    componentDidMount() {
        this.getPriorityPrice()
    }

    changePriority() {

        axios.patch("/orthoptistes/paiements/priority/toggle", {
            appointment_id: this.props.appointment_id
        }).then((data) => {
            this.setState({
                priority: data.data.enabled
            })
            document.getElementById('priority').checked = data.data.enabled
        })
    }

    getPriorityPrice() {
        axios.get("/orthoptistes/paiements/priority", {
            params: {appointment_id: this.props.appointment_id}
        }).then((resp) => {
            this.setState({
                price: resp.data.price,
                priority: resp.data.enabled
            })
            document.getElementById('priority').checked = resp.data.enabled
        }).catch(err => {
            toast.error("Impossible de charger les données de priorité")
        })
    }


    render() {
        return (
            <div className={this.state.price === 0 ? "grey-text card center-align" : "card center-align"}>
                Demander une analyse en priorité {this.state.price === 0 ? "" : `(${this.state.price}€)`}
                <div className="switch center-align">
                    <label>
                        Non
                        <input disabled={this.state.price === 0} id="priority" type="checkbox"
                               onChange={this.changePriority}/>
                        <span className="lever"></span>
                        Oui
                    </label>
                    {this.state.priority ? (
                        <div className="red-text" style={{paddingTop: "3vh"}}>
                            <i className="material-icons">report_problem</i>
                            <h5>Vous avez demandé une vérification prioritaire, ce qui va engager des frais
                                supplémentaires pour votre patient</h5>
                        </div>
                    ) : (<div/>)}
                </div>
            </div>
        )
    }
}
