import React, { Component } from 'react';
import SambaForm from "./SambaForm";
import { HashRouter as Router, Route, Link  } from "react-router-dom"
import Strips from "../../../Shared/Strips"

export default class Dossier extends Component {
  render() {
    return (
      <div>
      <Strips>white mini</Strips>
      <div className="container" style={{marginTop:42}}>
                      <Link to="/" className="btn teal btn-small" style={{margin:30}}><i className="material-icons left">arrow_back</i>Retour</Link>

          <SambaForm db_machines_samba={this.props.db_machines_samba}/>
      </div>
      </div>
    );
  }
}
